import React from 'react';
// import CardWrapper from '../../dashboard/component/CardWrapper';
import { Row, Col } from 'reactstrap';
import FPChip from '../../../components/common/new/FPChip/FPChip';
import '../agencyCard.css';
import CustomStarRatingComponent from '../../../components/common/StarComponent';
import {
  setDateFormat,
  setDecimalIndex,
  setToLocalStringNumber,
} from '../../../libs/utils';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { FPButton } from '../../../components/common/new';
import UpRight from '../../../images/svg/arrow-up-right.svg';
import newStore from '../../../images/newStore.png';

const AgencyCard = ({ details, onClick }) => {
  return (
    // <CardWrapper showDivider={false} className="agency-card-wrapper">
    <Row className="agency-card-wrapper">
      <Col xs={12} sm={12} md={12} >
        <header className="fp-header d-flex align-center justify-content-between">
          <div className="d-flex align-center gap-10">
            <CustomStarRatingComponent
              name="agency-rate"
              starCount={1}
              className="fp-size-18"
              value={setDecimalIndex(details.avg_rating)}
              editing={false}
            />
            <span className="avg-rating">
              {setDecimalIndex(details.avg_rating)}
            </span>
          </div>
          <p className="total-rating">
            Reviews: <span>{setToLocalStringNumber(details.total_rating)}</span>
          </p>
        </header>


        {/* <header className="fp-header d-flex align-center justify-content-between">
        <div className="d-flex gap-10">
          <CustomStarRatingComponent
            name="agency-rate"
            starCount={1}
            className="fp-size-18"
            value={setDecimalIndex(details.avg_rating)}
            editing={false}
          />
          <span className="avg-rating">
            {setDecimalIndex(details.avg_rating)}
          </span>
        </div>
        <p className="total-rating d-flex justify-content-end">
          Reviews: <span className=''>{setToLocalStringNumber(details.total_rating)}</span>
        </p>
      </header> */}


        <main className="agency-details-wrapper">
          <div className="agency-icon-container">
            {details.logo ? <img alt="detail" src={details.logo} /> : <img alt="store" src={newStore} />}
            <FPChip label={details.category} className="agency-category" />
          </div>
          <p className="joining-date">
            Join Since: <span>{setDateFormat(details.created_at)}</span>{' '}
          </p>
          <p className="agency-name">{details.name} </p>
          <div className="agency-location">
            <LocationOnOutlinedIcon style={{ color: '#F13A88' }} />
            <span>Total locations : {details.no_of_locations}</span>
          </div>
          <FPButton onClick={onClick} className="w-100">
            <span>Go To Dashboard</span>
            <img alt="right" src={UpRight} height={15} />
          </FPButton>
        </main>

      </Col>
    </Row>
  );
};

export default AgencyCard;
