import React from 'react';
import moment from "moment";
import "./styles.css";
import { Collapse, IconButton } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import { FPToolTip } from '../../../components/common/new';
import { snakeToCapital } from '../../../libs/utils';
import newCalendar from "../../../images/svg/newCalendar.svg";

const ViewActivityLog = ({logs}) => {
  return (
    <div id="activity-log">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="font-dark">Activity Log</h5>
      </div>
      <Collapse in={true}>
        <div id="sr-activity-log-content">
          <ul className="sr-timeline">
            {logs?.map((item, index) => {
              const { created_at, action, data: { description, title } } = item;
              return (
                <li key={index} className={`timeline-item ${action}`}>
                  <Row>
                    <Col className="action-box">
                      <div className="direction-l">
                        <div className={`flag flag-wrapper ${action}`}>
                          <p className="h6 font-dark fp-size-13 mb-1">{action}</p>
                          <div className="d-flex mb-2 fp-size-12 align-items-center gap-6">
                            <img src={newCalendar} alt="Calendar Icon" />
                            <p className="font-blue-gray m-0" style={{ lineHeight: 1 }}>
                              {moment(created_at).format("llll")}
                            </p>
                          </div>
                          <FPToolTip title={title}>
                            <p className="font-dark m-0 fp-size-13 d-inline">{snakeToCapital(description)}</p>
                          </FPToolTip>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
              );
            })}
          </ul>
        </div>
      </Collapse>
    </div>
  );
};

export default ViewActivityLog;
