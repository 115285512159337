import React from "react";
import { Chip } from "@mui/material";

const WhatsAppChips = ({ list, setSelected, selected }) => {

    const unreadMessages = list?.filter((item) => item?.unseen !== 0) || [];   
    const unreadLabel = unreadMessages.length > 99 ? "99+" : unreadMessages.length;

    return (
        <div>
            {/* Chips for selection */}
            <div className="wpchip-container">
                <Chip
                    label="All"
                    className={`wpchip ${selected === "All" ? "active" : ""}`}
                    onClick={() => setSelected("All")}
                />
                <Chip
                    label={`Unread${unreadMessages.length > 0 ? ` ${unreadLabel}` : ""}`}
                    className={`wpchip ${selected === "Unread" ? "active" : ""}`}
                    onClick={() => setSelected("Unread")}
                />
            </div>
        </div>
    );
};

export default WhatsAppChips;
