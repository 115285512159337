import React, { useState } from "react";
import google_svg from "../../../../../images/googlephoto.svg";
import emoji_svg from "../../../../../images/emoji.svg";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";
import BusinessList from "../BusinessList";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as listingUtils from "../../../../../libs/listingToast";

const UpdateDescription = (props) => {
  const {
    primaryDetail,
    getGmbListingUpdateListing,
    actions,
    business_id,
    queryStringData,
    busines_status,
    bulk_update_listing_data,
  } = props;
  const selectReso = 21;
  // const business_id = 4;

  const [description, setDescription] = useState(primaryDetail?.description);
  const [descriptionError, setDescriptionError] = useState(null);

  const updateDescriptionHandler = (e) => {
    const { value } = e.target;
    if (value.length > 750) {
      setDescriptionError("Description should be less than 750 characters");
    } else {
      setDescription(value);
      setDescriptionError(null);
    }
  };
  const handleSaveClick = async () => {
    const data = {
      type: "description",
      location_ids: selectedBusinessCount,
      description: description,
    };
    const restodetail = {
      business_id,
      selectReso,
    };
    console.log("description payload:", data);
    listingUtils?.displayMessage(
      "info",
      "Listing getting updated, It may take a few minutes."
    );
    await actions.getGmbListingBulkUpdateListing(data, restodetail);
    listingUtils?.displayMessage("close");
    if (bulk_update_listing_data) {
      listingUtils?.displayMessage("positive", "updated Successfully.");
    }
    // listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
    // await actions.getGmbListingRefreshListing(business_id, selectReso);

    // listingUtils?.displayMessage("close",); // Close the "refresh process" toast

    // listingUtils?.displayMessage("positive", "Listing updated successfully.");
    // await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };
  const [selectedBusinessList, setSelectedBusinessList] = useState([]);
  const [showBusiness, setShowBusiness] = useState(true);
  const handleHideBusinessScreen = () => {
    setShowBusiness(false);
  };

  const handleShowBusinessScreen = () => {
    setShowBusiness(true);
  };

  const [selectedBusinessCount, setSelectedBusinessCount] = useState([]); // State to hold the selected businesses count
  console.log("==================================", selectedBusinessCount);

  const handleSelectedBusinessCount = (selectBusiness) => {
    setSelectedBusinessCount(selectBusiness);
  };
  return (
    <>
      {showBusiness ? (
        <div>
          <BusinessList
            type="description"
            busines_status={busines_status}
            queryStringData={queryStringData}
            business_id={business_id}
            primaryDetail={null}
            selectedBusinessList={selectedBusinessList}
            onSelectedBusinessCountChange={handleSelectedBusinessCount}
          />
          {selectedBusinessCount?.length > 0 ? (
            <div
              className="d-flex mt-10 mb-15"
              style={{
                display: "flex",
                justifyContent: "end",
                fontWeight: "600",
                fontSize: "24px",
                color: "#1A2544",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "20px",
              }}
            >
              <FPButton label="Next" onClick={handleHideBusinessScreen} />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-9">
            <div
              className="listing-container"
              style={{ display: "grid", gap: "32px" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="listing-name">
                  Inroduce your business & business strengths
                </div>

                <FPButton
                  label="AI suggested Description"
                  size="small"
                  startIcon={<img src={emoji_svg} alt="emojiSvg" />}
                  star
                />
              </div>
              <div
                style={{
                  padding: "16px",
                  backgroundColor: "#F6F6F8",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div style={{ display: "grid", gap: "10px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Description
                  </div>
                  <textarea
                    id="w3review"
                    name="w3review"
                    rows="4"
                    placeholder={`Enter description`}
                    style={{
                      width: "100%",
                      borderRadius: "7px",
                      borderColor: "#BFC2CB",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#7A8193",
                      padding: "10px 14px",
                    }}
                    value={description}
                    onChange={updateDescriptionHandler}
                  />
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#7A8193",
                    }}
                  >
                    {description?.length ? description?.length : 0}/750 words
                  </div>
                  {descriptionError && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {descriptionError}
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  // paddingBottom: "10px",
                  // paddingTop: "10px",
                  paddingBlock: "20px",
                }}
              >
                <div>
                  <FPButton
                    label="Back"
                    size="small"
                    onClick={handleShowBusinessScreen}
                  />
                </div>
                <div>
                  <FPButton
                    onClick={handleSaveClick}
                    label="Update"
                    size="small"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <img src={google_svg} alt="verifiedImage" width={"100%"} />
          </div>
        </div>
      )}
    </>
  );
};

// export default UpdateDescription
const mapStateToProps = (state) => {
  return {
    //   gmc_categories_data: state.gmbListingLocation.gmc_categories_data,
    //   gmc_categories_error: state.gmbListingLocation.gmc_categories_error,
    bulk_update_listing_data: state.gmbListingLocation.bulk_update_listing_data,

  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDescription);
