import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import * as URLS from "../../libs/apiUrls";
import SelectComponent from "../common/Select";
import { InputField } from "react-semantic-redux-form";
import cookie from "react-cookies";
import FPLabel from "../common/new/forms/ui/FPLabel";
import { Box } from "@material-ui/core";
import { FPButton } from "../common/new";
import closeCancel from "../../images/svg/x.svg";
import { Form } from "semantic-ui-react";

const validate = (values) => {
  const error = {};
  if (!values.comp_location) {
    error.comp_location = "Please select a competitor branch location";
  }
  return error;
};

class CompetitorsLinkForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { compLoc: null };
    this.clearLink = this.clearLink.bind(this);
    this.submit = this.submit.bind(this);
  }
  submit(data) {
    const { postCompLinks } = this.props;
    const newData = { ...data };
    const { comp_location } = data;
    if (!comp_location) {
      return null;
    } else if (comp_location) {
      delete newData.comp_location;
      return postCompLinks(comp_location, newData).catch((err) => {
        const linkErrors = {
          google: err.google_link ? err.google_link : null,
          tripadvisor: err.tripadvisor_link ? err.tripadvisor_link : null,
          zomato: err.zomato_link ? err.zomato_link : null,
          swiggy: err.swiggy_link ? err.swiggy_link : null,
        };
        const errobj = {
          _error: linkErrors.non_field_errors,
          ...linkErrors,
        };
        throw new SubmissionError(errobj);
      });
    }
  }

  compLinksDetails = (value) => {
    this.setState({ compLoc: value });
    this.props.getCompLinks(value);
  };

  clearLink(e, type) {
    e.preventDefault();
    const { change } = this.props;
    change(type, "");
  }

  render() {
    const {
      match: {
        params: { business_id, branch_id },
      },
      submitting,
      handleSubmit,
      competitorSourceOptions,
    } = this.props;

    const { compLoc } = this.state;

    const locationFilterProps = {
      apiurl: `${URLS.BUSINESS_SETUP}${business_id}/competition/locations/?branch_id=${branch_id}`,
      selectedValue: this.state.compLoc ? this.state.compLoc : null,
    };

    const service_category = cookie.load("service_category");
    let filterSourceCompetitor = [];
    competitorSourceOptions &&
      competitorSourceOptions.map((competitor) => {
        if (competitor.category === service_category) {
          filterSourceCompetitor.push(competitor.web_portals.map((data) => data.provider));
        }
      });

    return (
      <Form onSubmit={handleSubmit(this.submit)} className="grid-form">
        <Box className="mb-4 border p-4" sx={{ borderRadius: "10px" }}>
          <FPLabel style={{ color: "#00B5AD" }}>Competitor Location</FPLabel>
          <Field
            key={compLoc}
            name="comp_location"
            placeholder="Select Location"
            props={locationFilterProps}
            component={SelectComponent}
            onChange={(e, val) => {
              this.compLinksDetails(val);
            }}
          />
        </Box>
        {filterSourceCompetitor &&
          Array.isArray(filterSourceCompetitor) &&
          filterSourceCompetitor.length > 0 &&
          filterSourceCompetitor[0].map((competitor, index) => {
            return (
              <>
                <FPLabel isRequired={false}>{`${
                  competitor.substr(0, 1).toUpperCase() + competitor.substr(1)
                } Link`}</FPLabel>

                <Field
                  key={index}
                  name={competitor}
                  placeholder={`Enter the ${competitor.substr(0, 1).toUpperCase() + competitor.substr(1)} Link`}
                  component={InputField}
                >
                  <input style={{ paddingRight: "30px" }} />
                  <img
                    src={closeCancel}
                    alt="Clear"
                    type="button"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={(e) => {
                      this.clearLink(e, `${competitor}`);
                    }}
                  />
                </Field>
              </>
            );
          })}
        <FPButton className="mt-4" label="Submit" type="submit" disabled={submitting} fullWidth />
      </Form>
    );
  }
}

const withReduxForm = reduxForm({
  form: "CompetitorsLinkForm",
  enableReinitialize: true,
  validate,
})(CompetitorsLinkForm);

CompetitorsLinkForm = connect((state) => {
  return {};
})(withReduxForm);

export default CompetitorsLinkForm;
