import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ListingDetail from "../../components/listing/ListingDetail/ListingDetailPage";
import * as locationActions from "../../actions/listing/listingLocationAction";
import LoaderComponent from "../../components/common/loaderComponent";
import queryString from "query-string";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";

// import { listingDetailJson } from "./listingdetailjson";

class ListingOverview extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // Trigger the API call when the component mounts
    this.props.actionsSelectTab.setSelectedTab(2);
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const queryParams = new URLSearchParams(search);
    const location_id = queryParams.get("branch");

    // if (queryParams?.gmb_location_type)
    this.props.actions.getGmbListingLoaction(business_id, "", "", "", "","","","","","","",location_id); // Replace 'yourProvider' with the actual provider
  }

  componentWillReceiveProps = (nextProps) => {
    const {
      location: { search: prevSearch },
    } = this.props;

    const {
      match: {
        params: { business_id },
      },
      location: { search: nextSearch },
    } = nextProps;

    if (prevSearch !== nextSearch) {
      const queryParams = queryString.parse(nextSearch);
      this.props.actions.getGmbListingLoaction(
        business_id,
        queryParams?.gmb_location_type,
        queryParams?.status_type,
        queryParams?.completion_status,
        queryParams?.openstatus,
        queryParams?.search,
        queryParams?.update_listing_type,
        queryParams?.is_food_menu,
        queryParams?.page_size,
        queryParams?.page,
        "",
        queryParams?.branch,
      );
    }
  };

  render() {
    const {
      route: {
        match: {
          params: { branch_id, business_id },
          // queryParams: { gmb_location_type },
        },
      },
    } = this.context.router;

    const { locationList, error, loader, filterBusinessCount } = this.props;
    return (
      <>
        {/* <ListingDetail /> */}
        <LoaderComponent loader={loader} />
        <ListingDetail
          location={this.props.location}
          history={this.props.history}
          listingDetailJson={locationList}
          filterBusinessCount={filterBusinessCount}
          business_id={business_id}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locationList: state.gmbListingLocation.location_list,
    filterBusinessCount: state.gmbListingLocation.filterBusinessCount,
    error: state.gmbListingLocation.location_error,
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    actions: bindActionCreators(locationActions, dispatch),
  };
};

ListingOverview.contextTypes = {
  router: PropTypes.object.isRequired,
};

ListingOverview.propTypes = {
  actions: PropTypes.object.isRequired,
  locationDetail: PropTypes.object,
  error: PropTypes.object,
  loader: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingOverview);

// export default ListingOverview
