import React, { useEffect } from "react";
import "./keywordposition.css";
import RootHeader from "../../../modules/listing/keywordPosition/RootHeader";
import RankingDashboard from "./RankingDashboard";
import TrafficAnalysis from "./TrafficAnalysis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as setSelectedTab from "../../../actions/common/setSelectedTabAction";
import SearchKeywordFilter from "../../../components/listing/keywordPosition/SearchKeywordFilter";

const KeywordPosition = (props) => {
  const {
    actions,
    location: { search, pathname },
    location,
    loader,
    history,
    match,
    match: {
      params: { business_id },
    },
    filterAction,
    filter,
  } = props;

  useEffect(() => {
    document.title = "Keyword Position";
    props.actionsSelectTab.setSelectedTab(2);
  }, [props.actionsSelectTab]);

  return (
    <div style={{ padding: "25px", display: "grid", gap: "40px" }}>
      <SearchKeywordFilter history={history} location={location} match={match} />
      <div>
        <RootHeader
          defaultValue={
            pathname === `/${business_id}/keyword-position`
              ? 0
              : pathname === `/${business_id}/traffic-analysis`
              ? 1
              : 0
          }
          history={history}
        />
      </div>
      {pathname === `/${business_id}/keyword-position` ? (
        <RankingDashboard />
      ) : pathname === `/${business_id}/traffic-analysis` ? (
        <TrafficAnalysis />
      ) : (
        ""
      )}
    </div>
  );
};

// export default KeywordPosition;

const mapStateToProps = (state) => {
  return {
    filter: state.filter,
    loader: state.common.loader,
    top_keyword_data: state.listingGeoGridRanker.top_keyword_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // filterAction: bindActionCreators(filterListActions, dispatch),
  actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(KeywordPosition);
