import React from 'react';
import {Row, Col} from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment/moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as NewsLetterActions from '../../../actions/newsLetter/newsLetterActions';
import TemplateSmsForm from '../../../components/campaign/templates/TemplateSmsForm';
import TemplateEmailForm from '../../../components/campaign/templates/TemplateEmailForm';
import FormData from 'form-data';

class NewsLetterEditPage extends React.PureComponent{

  constructor(props){
    super(props);
    this.submitData = this.submitData.bind(this);
  }


  submitData(data) {
    delete(data.background_image);
    delete(data.logo);
    let newData = new FormData();
    Object.keys(data).map((key)=>{
      if( key==='image' && typeof data[key]!='string' && data[key] !== null ) 
        newData.append(key,data[key],data[key] && data[key].filename);
      else if( key === 'image' && data['image']===null )
      {
        return true;
      }
      else 
        newData.append(key,data[key]);
    });
    const { match: { params: { business_id }} } = this.props;
    newData.append('action_type','Newsletter');
    return this.props.actions.postNewsLetter(newData, data.id, business_id);
  }

  componentDidMount() {
    // TO DO
    const { match: { params: { business_id }} } = this.props;
    const { route: { location: { search }}} = this.context.router;
    const { id } = queryString.parse(search);
    if (id) this.props.actions.getnewsLetterDetails({ branch_id: business_id, id });
  }

  render(){
    const { route: { location: { search }}} = this.context.router;
    const { id } = queryString.parse(search);
    const { newsLetter: { detail } } = this.props;
    const { type } = queryString.parse(search);

    const newData = {
      name: `NewsLetter${type==='sms'?'SMS':'Email'}` + moment().format('_DD-MM(HH:mm:ss)'),
      subject: 'Follow up',
      heading: 'How was your experience?',
      message: 'Thank you for choosing us! Please take a moment to leave us a review. Your responses will help us serve you better in the future.',
      btn_text: 'Click Here!',
      link:'https://famepilot.com',
    };
    if (id && (+id !== (detail && detail.id))) return null;
    const formData = id ? detail : newData;
    return(
      <Row className="content-area">
        <Col xs={12} sm={12} className="business-setup p-3">
          {type==='sms'?
            <TemplateSmsForm
              newsLetter={true}
              submitData={this.submitData}
              initialValues={formData}
            />
            :<TemplateEmailForm
              newsLetter={true}
              submitData={this.submitData}
              initialValues={formData}
            />}
        </Col>
      </Row>
    );
  }
}

NewsLetterEditPage.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  return {
    newsLetter: state.newsLetter,
    loader: state.common.loader
  };
};


NewsLetterEditPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  template:PropTypes.instanceOf(Object),
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(NewsLetterActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(NewsLetterEditPage);