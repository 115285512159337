import React from "react";
import { Dropdown } from "semantic-ui-react";
import ErrorMessage from "./new/forms/ui/ErrorMessage";

class LocationFiltersDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { searchQuery: "", value: "" };
    this.handleChange = this.handleChange.bind(this);
    this.timeout = 0;
    this.blurTimeout = 0;
  }

  handleChange(e, { value }) {
    const { input, handleFilterChange } = this.props;
    input.onChange(value);
    handleFilterChange(value);
  }

  onBlurHandler = (onBlur) => {
    this.blurTimeout = setTimeout(() => {
      onBlur();
    });
  };

  onFocusHandler = () => {
    clearTimeout(this.blurTimeout);
  };

  render() {
    const {
      dropDowntext,
      dropDownColor,
      providerFilters,
      selectedValue,
      width,
      input,
      handlFilterChange,
      serverError,
      ...rest
    } = this.props; //eslint-disable-line
    const value = selectedValue ? selectedValue : input.value;

    return (
      <span>
        {dropDowntext && (
          <div style={{ display: "inline" }}>
            {/* {this.props.icon && <Icon color={iconColor && iconColor} name={this.props.icon}></Icon>} */}
            {dropDowntext}:{" "}
          </div>
        )}
        <Dropdown
          className="location-filters"
          selectOnBlur={false}
          style={{ background: dropDownColor, width: width }}
          {...rest}
          value={value}
          onChange={this.handleChange}
          onBlur={() => this.onBlurHandler(input.onBlur)}
          onFocus={this.onFocusHandler}
          options={this.props.options}
        ></Dropdown>
        {rest.meta.touched && rest.meta.error && (
          <ErrorMessage>{rest.meta.error}</ErrorMessage>
        )}
        {serverError && <ErrorMessage>{serverError}</ErrorMessage>}
      </span>
    );
  }
}

export default LocationFiltersDropdown;
