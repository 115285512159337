import React, { useEffect, useState } from "react";
import * as URLS from "../../libs/apiUrls";
import { getAllParamsInArrayForm } from "../../libs/utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonAction from "../../actions/common/commonAction";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import * as filterListActions from "../../actions/common/filterListActions";
import { FPFilter, FPButton } from "../../components/common/new";
import AddIcon from "@material-ui/icons/Add";
import UploadFileModal from "../../components/common/new/UploadFileModal";
import * as utils from "../../libs/utils";
import axios from "axios";
import cookie from "react-cookies";

const CustomerFunnelFilter = (props) => {
  const { common, filter, filterAction, match, source, history, location, actions, commonActions, setLoader } = props;
  const [isBulkModal, setIsBulkModal] = useState(false);

  const fetchFilterList = async () => {
    const {
      params: { business_id },
    } = match;
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch("", `${URLS.BUSINESS_SETUP}${business_id}/branch/`),
        commonActions.cityOptions(business_id),
        commonActions.stateOptions(business_id),
        commonActions.branchTagOptions(business_id),
        actions.reviewSourceOptions({ business_id }),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };

  useEffect(() => {
    fetchFilterList();
  }, []); //eslint-disable-line

  const handleDefaultFilter = (list, key) => {
    const filter = location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable = defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list.map((item) => {
      item["isChecked"] = isKeyFilterAvailable && defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };

  const filterList = [
    {
      id: "1",
      label: "Date Duration",
      key: "date_type",
      type: "radio",
      isSearchable: false,
      list: filter.date_type && handleDefaultFilter(utils.removeLifetimeFilter(filter?.date_type?.choices), "date_type"),
    },
    {
      id: "2",
      label: "Provider",
      key: "provider",
      type: "checkbox",
      isSearchable: true,
      list: source && handleDefaultFilter(source.choices, "provider"),
    },
    {
      id: "3",
      label: "Location",
      key: "branch",
      type: "checkbox",
      isSearchable: true,
      list: filter.branch && handleDefaultFilter(filter.branch.choices, "branch"),
    },
    {
      id: "4",
      label: "City",
      key: "city",
      type: "checkbox",
      isSearchable: true,
      list: common.city_options && handleDefaultFilter(common.city_options.choices, "city"),
    },
    {
      id: "5",
      label: "State",
      key: "state",
      type: "checkbox",
      isSearchable: true,
      list: common.state_options && handleDefaultFilter(common.state_options.choices, "state"),
    },
    {
      id: "6",
      label: "Location Tag",
      key: "branch_tag",
      type: "checkbox",
      isSearchable: true,
      list:
        common.branch_tag_options &&
        handleDefaultFilter(
          common.branch_tag_options.results.map((item) => ({
            value: item.id,
            display_name: item.title,
          })),
          "branch_tag"
        ),
    },
  ].filter((item) => !!item);


  const funnelCsvUpload = (data) => {
    const authToken = cookie.load("authToken");
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const {
      match: {
        params: { business_id },
      },
    } = props;

    const url = `${utils.format(URLS.MENU_LIST, [business_id])}upload_csv/`;
    return axios
      .post(url, data, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err?.response?.data;
      });
  };

  const uploadFunnelFile = (data) => {
    const file = data.target.files[0];
    setLoader(true);
    const fileExt = file && file?.name.split(".").pop();
    const excelExt = ["xls", "xlsx", "xlsm", "csv"];
    if (file?.size > 2100000) {
      utils.displayMessage("negative", "File cannot be larger than 2mb.");
      setLoader(false);
    } else {
      if (excelExt.indexOf(fileExt) > -1) {
        const newDetail = new FormData();
        newDetail.append("file", file);
        return funnelCsvUpload(newDetail)
          .then((res) => {
            utils.displayMessage("positive", res.data.message);
            setLoader(false);
            setIsBulkModal(!isBulkModal)
          })
          .catch((err) => {
            let errors = err?.errors || [];
            if (errors && Array.isArray(errors)) {
              for (let index = 0; index < errors.length; index++) {
                if (index < 5) {
                  utils.displayMessage(
                    "negative",
                    errors[index]?.error
                      ? `Row ${errors[index]?.row} ` + errors[index]?.error
                      : `Row ${errors[index]?.row} Error`
                  );
                } else {
                  break;
                }
              }
            } else {
              utils.displayMessage("negative", "Error");
            }
            setLoader(false);
          });
      } else {
        document.getElementById("file").value = null;
        utils.displayMessage("info", "Invalid file type! Allowed extensions are .xls, .xlsx, .csv, .xlsm.");
        setLoader(false);
      }
    }
    return true;
  };

  const downloadFunnelTemplate = () => {
    const {
      match: {
        params: { business_id },
      },
    } = props;
    const url = `${utils.format(URLS.CUSTOMER_FUNNEL_SAMPLE_DOWNLOAD, [business_id])}`;
    return props.actions.downloadTemplate(url, 'customer_funnel_sample_file.csv');
  };

  return (
    <React.Fragment>
      <FPFilter 
        data={filterList} 
        className="mt-3" 
        history={history} 
        actionRender={() => (
          <FPButton
            label="Bulk Upload"
            variant="outline"
            type="button"
            style={{ height: "30px" }}
            onClick={() =>  setIsBulkModal(!isBulkModal)}
            endIcon={<AddIcon style={{ height: 16, width: 16 }} />}
          />
        )}
      />
      {<UploadFileModal 
        isOpen={isBulkModal}
        toggle={() => setIsBulkModal(!isBulkModal)}
        heading={"Upload File"}
        uploadExcel={uploadFunnelFile}
        downloadExcel={downloadFunnelTemplate}
        history={history}
        location={location}
        match={match}
      />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    filter: state.filter,
    source: state.review.review_source_options,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonAction, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
    actions: bindActionCreators(ReviewsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CustomerFunnelFilter);
