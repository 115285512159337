import React from "react";
import { Bar } from "react-chartjs-2";

const CompetitorsBarChart = ({
  dataKeys,
  dataSets,
  primaryYLabel,
  secondaryYLabel,
  tooltip,
  height,
  businessDetails,
  labelFontSize,
  legendSize
}) => {
  const plugins = [
    {
      beforeDraw: function (c) {
        var legends = c.legend.legendItems;
        legends.forEach(function (e, index) {
          e.fillStyle = dataSets[index].legendColor;
        });
      },
    },
    
  ];

  const data = {
    labels: dataKeys,
    datasets: dataSets,
  };

  const options = {
    scales: {
      "y-axis-1": {
        beginAtZero: true,
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: primaryYLabel,
        },
        grid: {
          borderDash: [7],
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      "y-axis-2": {
        beginAtZero: true,
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: secondaryYLabel,
        },
        grid: {
          display: false,
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value, index, values) {
            return dataKeys[index]?.substr(0, 20);
          },
          font: {
            weight: function (context) {
              const label = dataKeys[context.index];
              return label === businessDetails ? 'bold' : 'normal';
            },
            size: labelFontSize ? labelFontSize : 12,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontSize: 16,
          fontStyle: "normal",
          fontFamily: "Outfit",
          boxWidth: legendSize,
          boxHeight: legendSize,
          padding: 10,
          usePointStyle: true,
        },
      },
      tooltip: tooltip,
    },
    animation: {
      duration: 2000,
    },
    maintainAspectRatio: false,
  };

  return (
    <div style={{ height: height || "400px" }}>
      <Bar data={data} options={options} plugins={plugins} />
    </div>
  );
};

export default CompetitorsBarChart;