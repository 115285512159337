import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormFeedback} from 'reactstrap';

const AssignUserModal = (props) => {
  const { showModal, closeModal, teamList, selectedUser, userAssignnCallBack, userAssignError } = props;
  const [modal, setModal] = useState(false);
  const [addDescription, setAddDescription] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const [addSelectUserId, setSelectUserId] = useState(0);
  const [addErrorSelectUserId, setErrorSelectUserId] = useState('');

  function toggle(){
    setModal(false);
    closeModal(modal);
  }

  useEffect(() => {
    setModal(showModal);
  },[showModal]);

  const handleSubmit = () =>{
    if(addDescription === ''){
      setErrorDescription('Description field required');
    }
    if(addSelectUserId === 0){
      setErrorSelectUserId('Select user field required');
    }

    if(addDescription !== '' && addSelectUserId > 0){
      const formdata = new FormData();
      formdata.append('description',addDescription);
      formdata.append('assignees',addSelectUserId);
      formdata.append('messenger_id',selectedUser.id);
      userAssignnCallBack(formdata);
    }

  };

  const handleDescriptionInput = (e) =>{
    setAddDescription(e.target.value);
  };

  function handleSelectUser(e){
    setSelectUserId(e.target.value);
  }

  return(
    <div className="messenger-assign-modal">
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} className="messenger-assign-modal-title">Assign Conversation</ModalHeader>
        <ModalBody>
          <div className="mb-2">
            <Input
              id="exampleSelect"
              name="select"
              type="select"
              onChange={handleSelectUser}
            >
              <option>
                Enter a user or role to assign the conversation
              </option>
              {teamList && teamList.map((team)=>
                <option key={team.id} value={team.id}>
                  {team.first_name} {team.last_name}
                </option>
              )}
            </Input>
            <FormFeedback>{addErrorSelectUserId}</FormFeedback>

          </div>
          <div className="mb-2">
            <Label for="exampleUser">
              Description
            </Label>
            <Input
              id="exampleUser"
              type="text"
              onChange={handleDescriptionInput}
              placeholder='Enter Description...'
            />
            <FormFeedback>{errorDescription}</FormFeedback>
          </div>
          <FormFeedback>{userAssignError}</FormFeedback>

        </ModalBody>
        <ModalFooter>
          <Button type='button' color="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

AssignUserModal.propTypes = {
  showModal:PropTypes.bool.isRequired,
  closeModal:PropTypes.func.isRequired,
  teamList:PropTypes.array.isRequired,
  selectedUser:PropTypes.object.isRequired,
  userAssignnCallBack:PropTypes.func.isRequired,
  userAssignError:PropTypes.string.isRequired,
};
export default AssignUserModal;
