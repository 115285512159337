import React from 'react';
import {  Col, Row } from 'reactstrap';
import { Header, Grid, Segment, Image, Icon } from 'semantic-ui-react';
import Rater from 'react-rater';
import CustomRatingComponent from '../common/customRating';
import starActive from '../../images/reviewMethods/star_Color.png';
import starInActive from '../../images/reviewMethods/star_B_W.png';
import smileyHappyInActive from '../../images/reviewMethods/happy_B_W.png';
import smileyNeutralInActive from '../../images/reviewMethods/neutral_B_W.png';
import smileyUnHappyInActive from '../../images/reviewMethods/unhappy_B_W.png';
import FaceFour from "../../images/svg/FaceFour";
import FaceFive from "../../images/svg/FaceFive";
import FaceSix from "../../images/svg/FaceSix";
import FaceSeven from "../../images/svg/FaceSeven";
import FaceEight from "../../images/svg/FaceEight";


class SurveyPagePreview extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { businessLogo, include_comment, selectedBgColor, selectedFontType, Isvertical, selectedQueTextColor } = this.props;
    
    return (
      <Grid textAlign='center' style={{}} verticalAlign='middle'>
        <Grid.Column textAlign='left' text style={{ width: "100%" }}>
          <Segment stacked rounded style={{ height: '500px', borderRadius: '20px' ,backgroundColor:'white'}}>
            <Header as='h2' textAlign='center' style={{ marginBottom: '10px', color: 'darkgreen', fontSize: '10px' }}>
              <Image src={businessLogo ? businessLogo : ''} style={{maxHeight:'100px'}}/>
            </Header>
            <h4 style={{textAlign:'center', fontSize:'15px'}}>Page Title</h4>
            
            <Row style={{fontFamily:selectedFontType,backgroundColor:selectedBgColor, borderRadius:'20px',padding:'0 5px', margin:'10px 10px',  color: selectedQueTextColor}}>
              <Col sm="12" style={{display:'flex', justifyContent:'center', fontSize: '15px',marginTop:'10px'}}>{`1) How would you rate the taste and quality of our food ?`}</Col>
              <Col sm="12" style={{display:'flex', justifyContent:'center', fontSize: '15px',marginTop:'10px', marginBottom:'20px'}}>
                <span className="mr-4">
                  <FaceFour />
                </span>
                <span className="mr-4">
                  <FaceFive />
                </span>
                <span className="mr-4">
                  <FaceSix />
                </span>
                <span className="mr-4">
                  <FaceSeven />
                </span>
                <span className="mr-4">
                  <FaceEight />
                </span>
              </Col>
            </Row>

            <Row style={{fontFamily:selectedFontType,backgroundColor:selectedBgColor, borderRadius:'20px',padding:'0 5px', margin:'10px 10px',  color: selectedQueTextColor}}>
              <Col sm="12" style={{display:'flex', justifyContent:'center', fontSize: '15px',marginTop:'10px'}}>{`2) How would you rate our staff service & behaviour ?`}</Col>
              <Col sm="12" style={{display:'flex', justifyContent:'center', fontSize: '15px',marginTop:'10px', marginBottom:'20px'}}>
                <span className="mr-5" style={{display:'grid'}}>
                  <img width={25} className="review-method-img" src={smileyUnHappyInActive} />
                  <span  style={{maxWidth:'40px', marginTop:'10px', color: selectedQueTextColor, fontSize : Isvertical ? '15px' : '' }}>Bad</span>
                </span>
                <span className="mr-5"  style={{display:'grid'}}>
                  <img width={25} className="review-method-img" src={smileyNeutralInActive} />
                  <span  style={{maxWidth:'40px', marginTop:'10px', color: selectedQueTextColor, fontSize : Isvertical ? '15px' : ''}}>Avg</span>
                </span>
                <span  className="mr-5" style={{display:'grid'}}>
                  <img width={25} className="review-method-img" src={smileyHappyInActive} />
                  <span  style={{maxWidth:'40px', marginTop:'10px', color: selectedQueTextColor, fontSize : Isvertical ? '15px' : ''}}>Good</span>
                </span>
              </Col>
            </Row>

            <Row style={{fontFamily:selectedFontType,backgroundColor:selectedBgColor, borderRadius:'20px',padding:'0 5px', margin:'10px 10px',  color: selectedQueTextColor}}>
              <Col sm="12" style={{display:'flex', justifyContent:'center', fontSize: '15px',marginTop:'10px'}}>{`3) Do you think our products are value for money ?`}</Col>
              <Col>
                <Col sm="12" className="no-label" style={{display: 'flex', justifyContent:'center', fontSize: '15px', marginLeft:'4px',marginTop:'10px'}}>
                  <Rater total={5} rating={5}>
                    <CustomRatingComponent
                      activeImg={starActive}
                      inActiveImg={starInActive}
                      customClass="review-method-img"
                      imageWidth={25}
                      style={{marginRight:'12px'}}
                    />
                  </Rater>
                </Col>
                <Header as='h6' style={{fontFamily:selectedFontType, display: 'flex', justifyContent:'center',fontSize: '10px',alignItems:'center', marginTop:'5px',fontFamily:selectedFontType}}>
                  <h6 style={{fontFamily:selectedFontType,color: selectedQueTextColor}}>Very Poor</h6>
                  <p style={{marginTop:'3px', fontFamily:selectedFontType, color: selectedQueTextColor,}}><Icon name='caret right' size='large' />----------------<Icon name='caret left' size='large' /></p>
                  <h6 style={{fontFamily:selectedFontType,color: selectedQueTextColor}}>Excellent</h6>
                </Header>
              </Col>
            </Row>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default SurveyPagePreview;