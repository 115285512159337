import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Dropdown } from "semantic-ui-react";
import Pagination from "react-js-pagination";
import cookie from "react-cookies";
// import "../../styles/pagination.css";
import classNames from "classnames";

class ListingPaginationComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.state = {
      initialValue: 10,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      location: { search },
    } = nextProps;
    const { page_size: initialValue, page } = queryString.parse(search);
    if (
      initialValue != prevState.initialValue ||
      nextProps.pageSizeCookie != prevState.pageSizeCookie ||
      prevState.page !== page
    ) {
      return { initialValue, pageSizeCookie: nextProps.pageSizeCookie, page };
    }
    return {};
  }

  handlePageSizeChange = (size) => {
    const { pageSizeCookie } = this.state;
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, search },
    } = this.props;

    let pageSize = cookie.load("pageSize") || {};
    pageSize[pageSizeCookie] = size;
    cookie.save("pageSize", pageSize, { path: "/" });

    const querySearch = {
      ...queryString.parse(search),
      page_size: size,
      page: "1",
    };
    history.push({ pathname, search: queryString.stringify(querySearch) });
  };

  handlePageChange(page) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, search },
    } = this.props;
    const query = queryString.parse(search);
    history.push({
      pathname,
      search: queryString.stringify({ ...query, page }),
    });
  }

  render() {
    const { count, largeSizes, className } = this.props;
    const { initialValue, page } = this.state;
    // const { complaintPage } = this.props;
    let page_size = parseInt(initialValue);
    const options = [
      { key: "10", text: "10", value: "10" },
      { key: "25", text: "25", value: "25" },
      { key: "50", text: "50", value: "50" },
      { key: "100", text: "100", value: "100" },
    ];
    largeSizes &&
      options.push(
        ...[
          { key: "500", text: "500", value: "500" },
          { key: "1000", text: "1000", value: "1000" },
        ]
      );
    return (
      <div className={classNames("pagination-wrapper", className)}>
        <div className="d-flex align-items-center gap-10">
          <p className="mb-0">Display{""}</p>
          <Dropdown
            className="page-size-selector"
            onChange={(e, { value }) => this.handlePageSizeChange(value)}
            options={options}
            selection
            compact
            inline
            value={initialValue || "100"}
          />
        </div>
        <div className="d-flex justify-content-end align-items-center gap-10">
          <p className="mb-0">Total Records: {count || "00"}</p>
          <Pagination
            activePage={parseInt(page) || 1}
            itemsCountPerPage={page_size}
            prevPageText="&#8249;"
            nextPageText="&#8250;"
            linkClass="page-link"
            totalItemsCount={count}
            onChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

ListingPaginationComponent.defaultProps = {
  count: 0,
};

ListingPaginationComponent.propTypes = {
  count: PropTypes.number,
  location: PropTypes.object.isRequired,
};

ListingPaginationComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default ListingPaginationComponent;
