import React, { useState, useEffect, useRef } from "react";
import Chartwrapper from "../../modules/dashboard/component/Chartwrapper";
// import LoaderComponent from "../common/loaderComponent";
import { IoMdRefresh } from "react-icons/io";
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from "reactstrap";
import PerformancePieCard from "./PerformancePieCard";
import { Box, useMediaQuery } from "@material-ui/core";
import { DashboardRedirect } from "../../images/svg";
import { FPToolTip, NoData } from "../common/new";
import { CircularProgress, Skeleton } from "@mui/material";
import queryString from "query-string"

const CategoryPerformanceCard = (props) => {
  const {
    business_id,
    updateRouterState,
    params,
    apiRes,
    fetchTagData,
    loading,
    categoryPerformanceApiCalled
  } = props;
  const [tagsData, setTagsData] = useState({});
  // const [apiRes, setApiRes] = useState(null);
  // const [isLoading, setIsLoading] = useState(loading);
  // const url = format(`${apiUrls.TAGS_DATA}`, [business_id]);

  const isAllZero = (obj) => Object.values(obj).every((value) => value === 0);
  const isMdScreen = useMediaQuery("(max-width:1413px)");

  // const fetchTagData = async (params, isMdScreen) => {
  //   setIsLoading(true);
  //   await axios_instance
  //     .get(url, { params: { ...params } })
  //     .then((res) => {
  //       setApiRes(res.data);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    if (apiRes) {
      // Filter out items with all zero values
      const filteredData = Object.entries(apiRes).filter(
        ([_key, value]) => !isAllZero(value)
      );

      // Sort the filtered data based on positive value
      const sortedArray = filteredData.sort(
        (a, b) => b[1].positive - a[1].positive
      );

      // create array of arrays with max length 5
      const finalData = sortedArray.reduce((acc, item, index) => {
        const perSlider = isMdScreen ? 4 : 5;

        const chunkIndex = Math.floor(index / perSlider);
        if (!acc[chunkIndex]) {
          acc[chunkIndex] = [];
        }
        acc[chunkIndex].push(item);
        return acc;
      }, []);

      setTagsData(() => finalData);
      // setIsLoading(false);
    }
  }, [isMdScreen, apiRes]);

  // useEffect(() => {
  //   const fetch = async () => {
  //     await fetchTagData(queryString.parse(params));
  //   };
  //   fetch();
  // }, []);

  const updateData = (datePayload) => {
    fetchTagData(datePayload, true);
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === tagsData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? tagsData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const wrapperRef = useRef(null);
  const [skeletonCount, setSkeletonCount] = useState(5);

  useEffect(() => {
    const updateSkeletonCount = () => {
      if (window.innerWidth <= 1355) {
        setSkeletonCount(4);
      } else {
        setSkeletonCount(5);
      }
    };

    updateSkeletonCount();

    window.addEventListener("resize", updateSkeletonCount);
    return () => window.removeEventListener("resize", updateSkeletonCount);
  }, []);

  return (
    <>
      <div ref={wrapperRef}>
        <Chartwrapper
          onDateChange={updateData}
          className="fp-dashboard-chart-card h-100 mt-4"
          widthElevation={true}
          skeletonLoader={loading}
          headerTitle={
            <div className="d-flex align-items-center gap-6">
              <p className="m-0">Category Performance</p>
              <span
                className="cursor-pointer"
                onClick={() => {
                  updateRouterState(
                    `/${business_id}/insights?date_type=prior_30_day`,
                    { activeTab: "2" }
                  );
                }}
              >
                <DashboardRedirect />
              </span>
              <FPToolTip
                arrow={true}
                theme={"dark"}
                maxWidth={150}
                margin="5px 0px"
                placement={"bottom"}
                title={"Refresh Data"}
              >
                {loading ? <CircularProgress size={22} style={{ color: 'deeppink', marginTop: '2px'}}/>
                  :<IoMdRefresh 
                    size={23} 
                    style={{ color: 'deeppink', cursor: 'pointer', transition: 'transform 0.3s ease-in-out', }}
                    onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'} 
                    onClick={(e) => {
                      e.currentTarget.style.transform = 'rotate(180deg)'
                      fetchTagData(queryString.parse(params));
                    }}
                  /> 
                }
              </FPToolTip>
            </div>
          }
          descriptionStyle={{}}
          descriptionId="category_performance"
        >
          {loading ? (
            <>
              <div
                className="d-flex justify-content-center gap-20 py-3"
                style={{ minHeight: "230px" }}
              >
                {Array.from({ length: skeletonCount }).map((_, index) => (
                  <div
                    style={{
                      backgroundColor: "#F6F6F8",
                      border: "1px solid #BFC2CB",
                      borderRadius: "8px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                    }}
                    className="w-100 gap-6"
                  >
                    <Box style={{ color: "#646565", width: "100%" }}>
                      <Skeleton
                        width="30%"
                        height={20}
                        style={{ margin: "0 auto" }}
                      />
                    </Box>
                    <Skeleton
                      key={index}
                      variant="circular"
                      width={170}
                      height={170}
                    />
                  </div>
                ))}
              </div>
              <div className="w-100 my-2">
                <Skeleton
                  width="10%"
                  height={15}
                  style={{ margin: "0 auto" }}
                />
              </div>
            </>
          ) : tagsData.length > 0 ? (
            <>
              <Box
                sx={{
                  "& .performance-slider": {
                    paddingBottom: tagsData.length > 1 ? "43px" : 0,
                    height: "100%",
                  },
                  "& .carousel-control-prev, & .carousel-control-next": {
                    top: "auto",
                    height: "24px",
                    width: "24px",
                    bottom: "-4px",
                  },
                }}
              >
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  ride="carousel"
                  interval={null}
                  className="performance-slider"
                >
                  {tagsData?.map((providerList, parent_index) => {
                    return (
                      <CarouselItem
                        key={`${parent_index}-slider-section`}
                        onExiting={() => setAnimating(true)}
                        onExited={() => setAnimating(false)}
                      >
                        <div className="d-flex gap-20">
                          {providerList.map(([tag, performanceData], index) => (
                            <PerformancePieCard
                              key={`${tag}-${parent_index}-${index}-category-slider`}
                              chartData={performanceData}
                              tag={tag}
                            />
                          ))}
                        </div>
                      </CarouselItem>
                    );
                  })}
                  {tagsData.length > 1 && (
                    <div className="action-bar">
                      <CarouselIndicators
                        items={tagsData.map((_screenArray, index) => ({
                          key: index,
                        }))}
                        activeIndex={activeIndex}
                        onClickHandler={goToIndex}
                        className={"customIndicator"}
                      />
                      <CarouselControl
                        direction="prev"
                        directionText="Previous"
                        onClickHandler={previous}
                      />
                      <CarouselControl
                        direction="next"
                        directionText="Next"
                        onClickHandler={next}
                      />
                    </div>
                  )}
                </Carousel>
              </Box>
            </>
          ) :  
          categoryPerformanceApiCalled ? <NoData/>
          : (
            <div 
              className="text-center p-4 fp-size-16 underline" 
              style={{ textDecoration: 'underline', color: 'deeppink', cursor: 'pointer' }}
              onClick={() => fetchTagData(queryString.parse(params))} 
            >Click to Load Data</div>
          )}
        </Chartwrapper>
      </div>
    </>
  );
};

export default CategoryPerformanceCard;
