import * as types from '../../actions/actionTypes';

const initialState = {
  campaignDetail: {
    campaign: { template: {}, branch: {} },
    is_feedback_given: false,
    is_comment_added: false,
    review_id: '',
    member: {},
    links: {}
  },
  feedback: { id: '' }
};

export default function campaignDetails(state = initialState, action) {
  switch (action.type) {
    case types.POST_CAMPAIGN_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.POST_CAMPAIGN_FAILURE:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.GET_CAMPAIGN_SUCCESS:
      return Object.assign({}, state, { list: { ...action.data } });
    case types.GET_CAMPAIGN_FAILURE:
      return Object.assign({}, state, { list: {} });
    case types.GET_CAMPAIGN_DETAILS_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.GET_CAMPAIGN_DETAILS_FAILURE:
      return Object.assign({}, state, { detail: {} });
    case types.CREATE_CAMPAIGN_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.CREATE_CAMPAIGN_FAILURE:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.GET_ALL_CAMPAIGN_SUCCESS:
      return Object.assign({}, state, { campaignList: { ...action.data } });
    case types.GET_ALL_CAMPAIGN_FAILURE:
      return Object.assign({}, state, { campaignList: {} });
    case types.GET_CAMPAIGN_INFO_SUCCESS:
      return Object.assign({}, state, { campaignDetail: { ...state.campaignDetail, ...action.data } });
    case types.GET_CAMPAIGN_INFO_FAILURE:
      return Object.assign({}, state, { campaignDetail: {} });
    case types.SEND_FEEDBACK_SUCCESS:
      return Object.assign({}, state, { feedback: { ...action.data } });
    case types.SEND_FEEDBACK_FAILURE:
      return Object.assign({}, state, { feedback: {} });
    case types.REVIEW_INVITE_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.REVIEW_INVITE_FAILURE:
      return Object.assign({}, state, { detail: {} });
    case types.GET_INVITED_MEMBERS_SUCCESS:
      return Object.assign({}, state, { list: { ...action.data } });
    case types.GET_INVITED_MEMBERS_FAILURE:
      return Object.assign({}, state, { list: {} });
    case types.FOLLOW_UP_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.FOLLOW_UP_FAILURE:
      return Object.assign({}, state, action.data);
    case types.GET_CAMPAIGN_NEWSLETTER_SUCCESS:
      return Object.assign({}, state, { newsLetterCampaignList : { ...action.data } });
    case types.GET_CAMPAIGN_NEWSLETTER_FAILURE:
      return Object.assign({}, state, { newsLetterCampaignList: {} });
    case types.POST_NEWS_LETTER_CAMPAIGN_SUCCESS:
      return Object.assign({}, state, { nLCampaignDetail : { ...action.data } } );
    case types.POST_NEWS_LETTER_CAMPAIGN_FAILURE:
      return Object.assign({}, state, { nLCampaignDetail: { ...action.data } });
    case types.GET_NEWS_LETTER_INVITED_MEMBERS_SUCCESS:
      return Object.assign({}, state, { list : { ...action.data } } );
    case types.GET_NEWS_LETTER_INVITED_MEMBERS_FAILURE:
      return Object.assign({}, state, { list : { ...action.data } } );
    case types.GET_GENERIC_TEMPLATE_FEEDBACK_SUCCESS:
      return Object.assign({}, state, { campaignDetail: { ...state.campaignDetail, ...action.data } });
    case types.GET_GENERIC_TEMPLATE_FEEDBACK_FAILURE:
      return Object.assign({}, state, { campaignDetail: {} } );
    default:
      return state;
  }
}
