import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as commonAction from "../common/commonAction";
import cookie from "react-cookies";
import * as utils from "../../libs/utils";

export function reviewsDetailsSuccess(data) {
  return { type: types.REVIEWS_DETAILS_SUCCESS, data };
}

export function reviewsDetailsFailure(error) {
  let data = {};
  data.errors = error;
  return { type: types.REVIEWS_DETAILS_FAILURE, data };
}

export const reviewsAction = (data) => {
  const id = data.id;
  if (data.id) {
    delete data.id;
  }
  const { ...params } = data;

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(
        `${URLS.REVIEWS_DETAILS}${data.businessId}/review/${
          id ? `${id}/` : ""
        }`,
        { params }
      )
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(reviewsDetailsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(reviewsDetailsFailure(err));
      });
  };
};

export function postCommentsSuccess(data) {
  return { type: types.POST_COMMENTS_SUCCESS, data };
}

export function postCommentsFailure(data) {
  return { type: types.POST_COMMENTS_FAILURE, data };
}

export const postComments = (data) => {
  return (dispatch) => {
    return axios_instance
      .post(URLS.POST_COMMENTS, data)
      .then((res) => {
        dispatch(postCommentsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(postCommentsFailure(err));
        throw err.response.data;
      });
  };
};

export function reviewCsvUploadSuccess(data) {
  return { type: types.REVIEW_CSV_UPLOAD_SUCCESS, data };
}

export function reviewCsvUploadFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.REVIEW_CSV_UPLOAD_FAILURE, data };
}

export const reviewCsvUpload = (branchId, business_id, data, type) => {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const url =
    type === "swiggy"
      ? utils.format(URLS.SWIGGY_REVIEW_CSV, [business_id])
      : type === "zomato_ors"
      ? utils.format(URLS.ZOMATO_REVIEW_CSV, [business_id])
      : type === "magicpin"
      ? utils.format(URLS.MAGICPIN_REVIEW_CSV, [business_id])
      : URLS.REVIEW_CSV + branchId + "/";
  return (dispatch) => {
    return axios_instance
      .post(url, data, config)
      .then((res) => {
        dispatch(reviewCsvUploadSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(reviewCsvUploadFailure(err));
        throw err.response.data;
      });
  };
};

export const downloadTemplate = (url, filename) => {
  const token = cookie.load("authToken");
  return async (dispatch) => {
    dispatch(commonAction.showLoader());
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/csv",
        },
      });
      const blob = await res.blob();
      dispatch(commonAction.hideLoader());
      let winUrl = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = winUrl;
      a.download = filename || "famepilot.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {
      console.log(err);
      dispatch(commonAction.hideLoader());
    }
  };
};

export const downloadTemplateXlsx = (url, filename) => {
  const token = cookie.load("authToken");
  return async (dispatch) => {
    dispatch(commonAction.showLoader());
    try {
      return fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          // "Content-Type": "application/json",
        },
      })
      .then((res) => res.blob())
      .then((blob) => {
        const type = blob.type ? blob.type : 'csv';
        var url, a;
        if(type === 'text/csv') {
          url = window.URL.createObjectURL(blob);
          a = document.createElement("a");
          a.href = url;
          a.download = "famepilot.csv";
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else if(type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          url = window.URL.createObjectURL(blob);
          a = document.createElement("a");
          a.href = url;
          a.download = filename || "famepilot.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      })
      .catch((err) => {
        return err;
      });
    } catch (err) {
      console.log(err);
      dispatch(commonAction.hideLoader());
    }
  };
};


export const downloadCsvHandler = async (baseUrl, filename) => {
  try {
    const token = cookie.load("authToken");
    const res = await fetch(baseUrl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/csv",
      },
    });
    const blob = await res.blob();
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = filename || "famepilot.csv";
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (err) {
    console.log(err);
  }
};

export function statusUpdateSuccess(data) {
  return { type: types.STATUS_UPDATE_SUCCESS, data };
}

export function statusUpdateFailure(data) {
  return { type: types.STATUS_UPDATE_FAILURE, data };
}

export const statusUpdate = (id, data) => {
  const url = utils.format(URLS.STATUS_UPDATE, [id]);
  return (dispatch) => {
    return axios_instance
      .post(url, data)
      .then((res) => {
        dispatch(statusUpdateSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(statusUpdateFailure(err));
        throw err.response.data;
      });
  };
};

export function reviewSourceOptionsSuccess(data) {
  return { type: types.REVIEW_SOURCE_OPTIONS_SUCCESS, data };
}

export function reviewSourceOptionsFailure(data) {
  return { type: types.REVIEW_SOURCE_OPTIONS_FAILURE, data };
}

export const reviewSourceOptions = (param) => {
  const { reviewChannels, ...params } = param;
  const url = reviewChannels
    ? `${URLS.REVIEW_SOURCE}?${reviewChannels}`
    : `${URLS.REVIEW_SOURCE}`;
  return (dispatch) => {
    return axios_instance
      .get(url, { params })
      .then((res) => {
        dispatch(reviewSourceOptionsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(reviewSourceOptionsFailure(err.response.data));
        throw err.response.data;
      });
  };
};

// Reviews Form Action

export const postReviewsForm = (data, business_uuid) => {
  return (dispatch) => {
    const url = utils.format(URLS.REVIEWS_OFFLINE_FORM, [business_uuid]) + "/";
    return axios_instance
      .post(url, data)
      .then((res) => {
        dispatch(reviewsFormSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(reviewsFormFailure(err));
        throw err.response.data;
      });
  };
};

export function reviewsFormSuccess(data) {
  return { type: types.POST_REVIEWS_FORM_SUCCESS, data };
}
export function reviewsFormFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.POST_REVIEWS_FORM_FAILURE, error };
}

export function sourceSystemOrderDetailSettingSuccess(data) {
  return { type: types.SOURCE_SYSTEM_ORDER_DETAIL_SETTING_SUCCESS, data };
}

export function sourceSystemOrderDetailSettingFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.SOURCE_SYSTEM_ORDER_DETAIL_SETTING_FAILURE, data };
}

export const sourceSystemOrderDetailSetting = (businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.SOURCE_SYSTEM_ORDER_DETAIL, [businessId]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(sourceSystemOrderDetailSettingSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(sourceSystemOrderDetailSettingFailure(err));
        throw err.response.data;
      });
  };
};

//tag details
export function tagOptionsSuccess(data) {
  return { type: types.TAG_OPTIONS_SUCCESS, data };
}

export function tagOptionsFailure(data) {
  return { type: types.TAG_OPTIONS_FAILURE, data };
}

export const getTagOptions = (business_id) => {
  return (dispatch) => {
    return axios_instance
      .get(`${utils.format(URLS.GET_ALL_TAGS, [business_id])}`)
      .then((res) => {
        dispatch(tagOptionsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(tagOptionsFailure(err.response.data));
        throw err.response.data;
      });
  };
};
