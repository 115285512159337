import React from "react";
import cookie from "react-cookies";
import PropTypes from "prop-types";
import queryString from "query-string";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import * as CampaignActions from "../../actions/campaign/campaignActions";

import CampaignList from "../../components/campaign/CampaignList";
import LoaderComponent from "../../components/common/loaderComponent";
import LockedComponent from "../../components/common/LockedComponent/LockedComponent";

import * as utils from "../../libs/utils";
import ConfirmationModal from "../../components/common/ConfirmationModal";

class CampaignPage extends React.Component {
  constructor(props) {
    super(props);
    this.switchToAddTemplate = this.switchToAddTemplate.bind(this);
    this.goToAddCampaign = this.goToAddCampaign.bind(this);
    this.goToManageRecurringCampaign =
      this.goToManageRecurringCampaign.bind(this);
    this.fetchTabData = this.fetchTabData.bind(this);
    this.deleteCampaign = this.deleteCampaign.bind(this);
    // this.cloneCampaign = this.cloneCampaign.bind(this);
    this.state = { isAddCampaignOpen: false };
    this.viewInvitedMembers = this.viewInvitedMembers.bind(this);
    //PageSize Handling
    const pageSize = cookie.load("pageSize") || {};
    const {
      history,
      location: { pathname, search },
    } = props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        page_size: pageSize.campaigns_review || 10,
      }),
    });
  }

  componentDidMount() {
    this.fetchTabData();
    //Set Title
    document.title = "Campaign|Review Request";
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.fetchTabData();
    }
  }

  fetchTabData() {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    let parms = queryString.parse(search);
    if (Object.keys(parms).length === 0) {
      parms = { page_size: 10 };
    }
    this.props.actions
      .getAllCampaign(parms, business_id)
      .catch(
        (err) =>
          err === "lock" &&
          this.setState({ lock: "Campaigns Module is locked." })
      );
  }

  deleteCampaign(item) {
    const {
      match: {
        params: { business_id },
      },
      location: { pathname, search },
      campaign: { campaignList },
      history,
    } = this.props;
    const { page } = queryString.parse(search);

    return this.props.actions.deleteCampaign(item.id, business_id).then(() => {
      if (
        campaignList.total_pages.toString() === page &&
        campaignList.results.length === 1
      ) {
        const oldQuery = Object.assign(queryString.parse(search), { page: 1 });
        history.push({ pathname, search: queryString.stringify(oldQuery) });
        this.fetchTabData();
      } else {
        this.fetchTabData();
      }
    });
  }

  goToAddCampaign() {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    history.push(`/${business_id}/campaigns/review/add`);
  }
  goToManageRecurringCampaign() {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    history.push(`/${business_id}/recurring-campaigns/review/list`);
  }

  switchToAddTemplate(val) {
    const {
      history,
      match: {
        path,
        params: { branch_id, business_id, tabKey },
      },
    } = this.props;
    const url = path
      .replace(":business_id", business_id)
      .replace(":branch_id", branch_id)
      .replace(":tabKey", tabKey)
      .replace(":mode", val);
    history.push(url);
  }

  viewInvitedMembers(item) {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    history.push(`/${business_id}/campaigns/review/${item.id}/members`);
  }

  toggleGuestModal = () => {
    this.setState({ isGuestOpen: !this.state.isGuestOpen });
  };

  gotoGuestReviewInvite = () => {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    history.push(`/${business_id}/campaigns/review/guest_Review_invite`);
  };
  // cloneCampaign(data){
  //   const { match: { params: { business_id }}, location: { search } } = this.props;
  //   return this.props.actions.CampaignClone(data, business_id)
  //     .then(() => this.getCampaignList(this.props, queryString.parse(search)));
  // }

  toggleConfirm = (confirmationFunction) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      confirmationFunction,
    });
  };

  render() {
    const {
      campaign: { campaignList },
      business_userq,
    } = this.props;
    const { lock } = this.state;
    const ordering = queryString.parse(this.props.location.search).ordering;
    const user = business_userq || cookie.load("business_user");
    return (
      <div>
        <Row className="content-area">
          <LockedComponent message={lock} />
          <LoaderComponent loader={this.props.loader} />
          <Col xs={12} sm={12} className="business-setup p-3">
            <CampaignList
              switchToAddTemplate={this.switchToAddTemplate}
              list={campaignList}
              deleteCampaign={(item) =>
                this.toggleConfirm(() => this.deleteCampaign(item))
              }
              // cloneCampaign={this.cloneCampaign}
              goToAddCampaign={this.goToAddCampaign}
              goToManageRecurringCampaign={this.goToManageRecurringCampaign}
              location={this.props.location}
              history={this.props.history}
              viewInvitedMembers={this.viewInvitedMembers}
              ordering={ordering}
              sortFunction={(on) =>
                utils.toggleSort(this.props.history, this.props.location, on)
              }
              gotoGuestReviewInvite={this.gotoGuestReviewInvite}
              user={user}
              pageSizeCookie="campaigns_review"
              // showAction={false}
            />
          </Col>
          <ConfirmationModal
            header="Are you sure!"
            subHeader="Are you sure you want to delete the selected items? This action cannot be undone."
            isOpen={this.state.isConfirmOpen}
            toggle={this.toggleConfirm}
            onConfirm={this.state.confirmationFunction}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaign: state.campaign,
    loader: state.common.loader,
    userq: state.profile.user,
    business_userq: state.profile.business_user,
  };
};

CampaignPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  campaign: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(CampaignActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CampaignPage);
