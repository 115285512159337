import React, { useState, useEffect } from "react";
import { messageTypeOptions, sourceOptions } from "../../libs/constant";
import { getAllParamsInArrayForm, removeLifetimeFilter } from "../../libs/utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonAction from "../../actions/common/commonAction";
import * as filterListActions from "../../actions/common/filterListActions";
import { FPButton, FPFilter, FPToolTip } from "../../components/common/new";
import FormModal from "../../components/common/FormModal";
import { IconButton } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../images/svg/Download.svg";
import * as URLS from "../../libs/apiUrls";

const SocialCommentsFilter = (props) => {
  const { common, history, filter, source, pageSizeCookie, location, filterAction, match, branchFilterData, getFilterData } = props;
  const [isMoreThanNinety, setIsMoreThanNinety] = useState(false);

  const fetchFilterList = async () => {
    const {
      params: { business_id },
    } = match;
    try {
      await Promise.all([filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`)]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };

  const handleDefaultFilter = (list, key) => {
    const filter = location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable = defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list.map((item) => {
      item["isChecked"] = isKeyFilterAvailable && defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };
  useEffect(() => {
    fetchFilterList();
  }, []);

  const filterList = [
    {
      id: "1",
      label: "Date Duration",
      key: "date_type",
      type: "radio",
      isSearchable: false,
      list: filter.date_type && handleDefaultFilter(removeLifetimeFilter(filter?.date_type?.choices), "date_type"),
    },
    {
      id: "2",
      label: "Source",
      key: "source",
      type: "checkbox",
      isSearchable: true,
      list: sourceOptions && handleDefaultFilter(sourceOptions, "source"),
    },
    {
      id: "3",
      label: "Message Type",
      key: "message_type",
      type: "radio",
      isSearchable: false,
      list: messageTypeOptions && handleDefaultFilter(messageTypeOptions, "message_type"),
    },
    {
      id: "4",
      label: "Location",
      key: "branch",
      type: "checkbox",
      isSearchable: true,
      list: branchFilterData && handleDefaultFilter(branchFilterData, "branch"),
    },
  ];
  return (
    <React.Fragment>
      <FPFilter
        data={filterList}
        className="mt-3"
        history={history}
        pageSizeCookie={pageSizeCookie}
        getData={getFilterData}
        match={match}
        actionRender={() => (
          <FPToolTip title={"Download"}>
            <IconButton
              component="span"
              className="circular-button"
              // onClick={() => handleReviewDownloadFlow()}
            >
              <DownloadIcon />
            </IconButton>
          </FPToolTip>
        )}
        ticketFilter="TICKET"
      />
      {isMoreThanNinety && (
        <FormModal
          isOpen={isMoreThanNinety}
          toggle={() => setIsMoreThanNinety(false)}
          heading="Attention !"
          maxWidth="xs"
          showDivider={true}
          dialogActions={<FPButton size="small" onClick={() => setIsMoreThanNinety(false)} label="OK" />}
        >
          <p className="fp-size-16">Please contact to support to download data older than 90 days.</p>
        </FormModal>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    filter: state.filter,
    source: state.review.review_source_options,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonAction, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SocialCommentsFilter);
