import React,{useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const BaseTabs = withStyles({
  root: {
    border: ({variant}) => variant === 'round-fill' ? '1px solid #bfc2cb82' : 'unset',
    borderRadius: ({variant}) => variant === 'round-fill' ? '50px' : '0',
    // borderBottom: ({variant}) => variant !== 'round-fill' && '1px solid #bfc2cb82 !important',
    minHeight: ({size}) => size === 'small' ? '30px' : '40px',
  },
  indicator: {
    backgroundColor: ({theme}) => theme === 'secondary' ? '#00B5AD' : '#F13A88',
    height: ({variant}) => variant === 'round-fill' ? 0 : 2.5,
  },
})(Tabs);

const BaseTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    minWidth: ({ minTabWidth }) => (minTabWidth ? minTabWidth : 120),
    minHeight: ({size}) => size === 'small' ? '30px' : '39px',
    color: '#646565',
    '&:hover': {
      color:  ({theme}) => theme === 'secondary' ? '#00B5AD' : '#F13A88',
      opacity: 1,
    },
    '&:focus': {
      color:  ({theme}) => theme === 'secondary' ? '#00B5AD' : '#F13A88',
    },
    '& .MuiButtonBase-root': {
      marginRight: '0 !important',
      minHeight: ({size}) => size === 'small' ? '30px' : '39px',
    },
  },
  wrapper: {
    fontSize: ({size}) => size === 'small' ? '12px' : '14px',
    lineHeight: ({size}) => size === 'small' ? 1 : 1.5
  },
  selected: {
    '&.MuiButtonBase-root':{
      color:  ({theme, variant}) => variant === 'round-fill' ? 'white' : theme === 'secondary' ? '#00B5AD' : '#F13A88',
      background: ({theme, variant}) => variant === 'round-fill' ? theme === 'secondary' ? '#00B5AD' : '#F13A88' : 'transparent',
      transition: 'background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderRadius: ({variant}) => variant === 'round-fill' ?  '50px' : 0,
      margin: ({variant}) => variant === 'round-fill' ?  '3px' : 0
    }
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// PropsType
// tabLabels : ['' , ''] array of string
// theme : secondary
// variant : round-fill
// size : small
// defaultValue : index of tab
export default function ListingFPTab(props) {
  const { tabLabels, minTabWidth, onChange, defaultValue, theme, variant, size, className } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultValue || 0);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  

  const handleChange = (event, newValue) => {
    onChange && onChange(newValue);
    setValue(newValue);
  };

  return (
    <div className={`${classes.root} ${className}`}>
      <BaseTabs size={size} theme={theme} variant={variant} value={value} onChange={handleChange}>
        {tabLabels.map((tab, index) => (
          <BaseTab 
            size={size}
            className={`FPTab-${index+1}`}
            theme={theme}
            variant={variant}
            label={tab}
            key={`${index}-${tab}`}
            minTabWidth={minTabWidth}
            {...a11yProps(index)}
          />
        ))}
      </BaseTabs>
    </div>
  );
}
