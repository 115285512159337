import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";

import * as commonAction from "../common/commonAction";

export function teamAddSuccess(data) {
  return { type: types.TEAM_ADD_SUCCESS, data };
}

export function teamAddFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.TEAM_ADD_FAILURE, data };
}

export const teamAdd = (data, businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(`${utils.format(URLS.TEAM, [businessId])}`, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(teamAddSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(teamAddFailure(err));
        throw err.response.data;
      });
  };
};

export function assignMultiLocationSuccess(data) {
  return { type: types.ASSIGN_MULTI_LOCATIONS_SUCCESS, data };
}

export function assignMultiLocationFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.ASSIGN_MULTI_LOCATIONS_FAILURE, data };
}

export const assignMultiLocation = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(URLS.ASSIGN_MULTI_LOCATIONS, formData)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(assignMultiLocationSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(assignMultiLocationFailure(err));
        throw err.response.data;
      });
  };
};

export function teamListSuccess(data) {
  return { type: types.TEAM_LIST_SUCCESS, data };
}

export function teamListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.TEAM_LIST_FAILURE, data };
}

export const teamList = (params, businessId, showLoader=true) => {
  return (dispatch) => {
    if (showLoader) dispatch(commonAction.showLoader());
    if (!params.page_size) {
      params.page_size = "100";
    }

    return axios_instance
      .get(`${utils.format(URLS.TEAM, [businessId])}`, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(teamListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(teamListFailure(err));
        throw err.response.data;
      });
  };
};

export function teamDepartmentUsersSuccess(data) {
  return { type: types.TEAM_DEPARTMENTS_USERS_SUCCESS, data };
}

export function teamDepartmentUsersFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.TEAM_DEPARTMENTS_USERS_FAILURE, data };
}

export const teamDepartmentUsersAction = (params) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(URLS.TEAM_DEPARTMENTS_USERS, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(teamDepartmentUsersSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(teamDepartmentUsersFailure(err));
        throw err.response.data;
      });
  };
};

export function getTeamSuccess(data) {
  return { type: types.GET_TEAM_SUCCESS, data };
}

export function getTeamFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_TEAM_FAILURE, data };
}

export const getTeam = (params, businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${utils.format(URLS.TEAM, [businessId])}${params.id}/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getTeamSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getTeamFailure(err));
        throw err.response.data;
      });
  };
};

export function teamEditSuccess(data) {
  return { type: types.TEAM_EDIT_SUCCESS, data };
}

export function teamEditFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.TEAM_EDIT_FAILURE, data };
}

export const teamEdit = (data, businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .put(`${utils.format(URLS.TEAM, [businessId])}${data.id}/`, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(teamEditSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(teamEditFailure(err));
        throw err.response.data;
      });
  };
};

export function teamMemberDeleteSuccess(data) {
  return { type: types.TEAM_MEMBER_DELETE_SUCCESS, data };
}

export function teamMemberDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.TEAM_MEMBER_DELETE_FAILURE, data };
}

export const teamMemberDelete = (data, businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .delete(`${utils.format(URLS.TEAM, [businessId])}${data.id}/`, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(teamMemberDeleteSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(teamMemberDeleteFailure(err));
        throw err.response.data;
      });
  };
};
