/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import FPInput from "./new/forms/ui/FPInput";
import SearchIcon from "@material-ui/icons/Search";
import { FPToolTip } from "./new";
import { Button, Icon } from "semantic-ui-react";
import classNames from "classnames";
import { getParams } from "../../libs/utils";

class SearchComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.timeout = 0;
    this.state = {
      searchValue: queryString.parse(props?.history?.location?.search).search,
      ordering: getParams(location.search, "ordering"),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ordering !== this.props.ordering) {
      this.setState({
        ordering: getParams(location.search, "ordering"),
      });
    }
  }

  handleChange(data) {
    const searchText = data.target.value;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const {
        router: { history },
      } = this.context;
      const {
        location: { pathname, search },
      } = this.props;
      const query = queryString.parse(search);
      if (query.page) delete query.page;
      history.push({
        pathname,
        search: queryString.stringify({ ...query, search: searchText }),
      });
    }, 500);
    this.setState({ searchValue: searchText });
  }

  updateOrdering(sortOrder) {
    this.setState({ ordering: sortOrder });
    const {
      history,
      location: { pathname, search },
    } = this.props;
    let filters = { ...queryString.parse(search), ordering: sortOrder };
    history.push({ pathname, search: queryString.stringify(filters) });
  }

  handleActive(sorting) {
    return this.state.ordering === sorting;
  }

  render() {
    const { placeholder, className, ticketPage, isSorting, startIcon, endIcon, size } = this.props;
    return (
      <div className={`d-flex align-items-center gap-10 fp-search-wrapper ${className}`}>
        <FPInput
          type="text"
          placeholder={placeholder || "Search here..."}
          onChange={this.handleChange}
          value={this.state.searchValue}
          style={{ fontSize: "14px" }}
          startIcon={startIcon && <SearchIcon style={{ color: "#BFC2CB", fontSize: "18px" }} />}
          endIcon={endIcon && <SearchIcon style={{ color: "#BFC2CB", fontSize: "18px" }} />}
          size={size}
        />
        {isSorting && (
          <div className="d-flex align-items-center gap-10">
            <FPToolTip title="Oldest">
              <Button
                className={classNames("review-sort-btn", {
                  "fp-active-btn": this.handleActive(ticketPage ? "created_at" : "review_create"),
                })}
                onClick={() => this.updateOrdering(ticketPage ? "created_at" : "review_create")}
              >
                <Icon name="sort amount down" />
              </Button>
            </FPToolTip>
            <FPToolTip title={"Newest"}>
              <Button
                className={classNames("review-sort-btn", {
                  "fp-active-btn": this.handleActive(ticketPage ? "-created_at" : "-review_create"),
                })}
                onClick={() => this.updateOrdering(ticketPage ? "-created_at" : "-review_create")}
              >
                <Icon name="sort amount up" />
              </Button>
            </FPToolTip>
          </div>
        )}
      </div>
    );
  }
}

SearchComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default SearchComponent;
