import React, { useState, useRef } from "react";
import ListingFPInput from "../../../../../components/common/new/Listing/ListingFPInput";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";
import { Chip, Stack } from "@mui/material";
import "./Addmenu.css"; // Create a CSS file for your styles
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import BusinessList from "./BusinessList";
import * as utils from "../../../../../libs/utils";

const CreateMenu = (props) => {
  const {
    actions,
    selectReso,
    business_id,
    sectionIndexValue,
    bulk_update_listing_data,
  } = props;
  console.log(
    "bulk_update_listing_databulk_update_listing_data",
    bulk_update_listing_data
  );
  console.log(
    "indexValueindexValueindexValueindexValueindexValue",
    sectionIndexValue
  );
  const fileInputRef = useRef(null);
  const [selectedBusinessCount, setSelectedBusinessCount] = useState([]);
  const handleSelectedBusinessCount = (selectBusiness) => {
    setSelectedBusinessCount(selectBusiness);
  };

  // State to store input values
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [menuName, setMenuName] = useState("");
  const [menuDescription, setMenuDescription] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [itemSection, setItemSection] = useState("");

  const [selectedDietary, setSelectedDietary] = useState("");
  // Error states
  const [menuNameError, setMenuNameError] = useState("");
  const [menuDescriptionError, setMenuDescriptionError] = useState("");
  const [sectionNameError, setSectionNameError] = useState("");
  const [itemNameError, setItemNameError] = useState("");
  const [itemDescriptionError, setItemDescriptionError] = useState("");

  const validateMenuName = (value) => {
    if (value.length > 40) {
      setMenuNameError("Menu Name should be less than 40 characters");
    } else {
      setMenuName(value);
      setMenuNameError("");
    }
  };

  const validateMenuDescription = (value) => {
    if (value.length > 1000) {
      setMenuDescriptionError(
        "Menu Description should be less than 1000 characters"
      );
    } else {
      setMenuDescription(value);
      setMenuDescriptionError("");
    }
  };

  const validateItemName = (value) => {
    if (value.length > 40) {
      setItemNameError("Item Name should be less than 40 characters");
    } else {
      setItemName(value);
      setItemNameError("");
    }
  };

  const validateItemDescription = (value) => {
    if (value.length > 1000) {
      setItemDescriptionError(
        "Item Description should be less than 1000 characters"
      );
    } else {
      setItemDescription(value);
      setItemDescriptionError("");
    }
  };

  const validateSectionName = (value) => {
    if (value.length > 40) {
      setSectionNameError("Section Name should be less than 40 characters");
    } else if (value.trim() === "") {
      setSectionNameError("Section Name is required");
    } else {
      setItemSection(value);
      setSectionNameError("");
    }
  };

  const handleValueChange = (value, type) => {
    switch (type) {
      case "menuName":
        validateMenuName(value);
        break;
      case "menuDescription":
        validateMenuDescription(value);
        break;
      case "itemName":
        validateItemName(value);
        break;
      case "itemDescription":
        validateItemDescription(value);
        break;
      case "itemSection":
        validateSectionName(value);
        break;
      default:
        break;
    }
  };

  const handleChipClick = (dietary) => {
    // Toggle selection: if already selected, unselect it; otherwise, select it
    setSelectedDietary((prevDietary) =>
      prevDietary === dietary ? "" : dietary
    );
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const [uploadImage, setUploadImage] = useState([]);
  const [validPhoto, setValidPhoto] = useState([]);
  console.log("uploadImageuploadImageuploadImageuploadImage", uploadImage);
  console.log("validPhotovalidPhotovalidPhotovalidPhotovalidPhoto", validPhoto);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    const minFileSize = 15 * 1024; // 15 KB in bytes
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes
    const minWidth = 250;
    const minHeight = 250;
    files.forEach((file) => {
      const newPhotos = [];
      const validImages = [];
      if (!validImageTypes.includes(file.type)) {
        utils.displayMessage(
          "negative",
          "Please upload a valid Image file (JPG, JPEG, or PNG)"
        );
        return;
      } else if (file.size < minFileSize || file.size > maxFileSize) {
        utils.displayMessage(
          "negative",
          "Please upload an image between 15KB and 5MB"
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width < minWidth || img.height < minHeight) {
            utils.displayMessage(
              "negative",
              `Minimum resolution required is ${minWidth}x${minHeight}px`
            );
            return;
          }
          validImages.push(e.target.result);
          setValidPhoto(validImages);
          newPhotos.push(file);
          setUploadImage(newPhotos);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
    event.target.value = null;
  };

  const updateMenuItemImage = async () => {
    const restodetail = { business_id, selectReso };

    const newSection = [
      {
        sections: [
          {
            labels: [
              {
                displayName: itemSection,
                languageCode: "en",
              },
            ],
            items: [
              {
                labels: [
                  {
                    displayName: itemName,
                    description: itemDescription,
                    languageCode: "en",
                  },
                ],
                attributes: {
                  price: {
                    currencyCode: "INR",
                    units: itemPrice,
                  },
                  ...(selectedDietary && {
                    dietaryRestriction: selectedDietary,
                  }),
                  // ...(media_data && { mediaKeys: [media_data.name.split('/').pop()] })
                  // ...(media_key?.name && { mediaKeys: [media_key.name.split('/').pop()] })
                },
              },
            ],
          },
        ],
        // cuisines: ["Indian"],
        labels: [
          {
            displayName: menuName,
            description: menuDescription,
            languageCode: "en",
          },
        ],
      },
    ];

    // Copy `location_food_menu_data`
    const updatedMenuData = {};
    updatedMenuData.menus = newSection;

    const uploadFormData = new FormData();

    if (uploadImage?.length > 0) {
      uploadImage?.forEach((photo) => {
        console.log("photophotophotophotophotophoto", photo);
        uploadFormData.append("FOOD_AND_DRINK", photo);
      });
      uploadFormData.append("type", "updateMenu");
      uploadFormData.append("location_ids", selectedBusinessCount);
      uploadFormData.append("updateMenuData", JSON.stringify(updatedMenuData));
      const an = await actions.getGmbListingBulkUpdateListing(
        uploadFormData,
        restodetail,
        true
      );
    }
  };

  const [selectedBusinessList, setSelectedBusinessList] = useState([]);
  const [showBusiness, setShowBusiness] = useState(true);
  const handleHideBusinessScreen = () => {
    setShowBusiness(false);
  };

  const handleShowBusinessScreen = () => {
    setShowBusiness(true);
  };

  return (
    <>
      {showBusiness ? (
        <div>
          <BusinessList
            type="appointment_links"
            queryStringData={props?.queryStringData}
            business_id={props?.business_id}
            primaryDetail={null}
            selectedBusinessList={selectedBusinessList}
            onSelectedBusinessCountChange={handleSelectedBusinessCount}
          />
          {selectedBusinessCount?.length > 0 ? (
            <div
              className="d-flex justify-content-end mt-4 mb-2"
            >
              <FPButton label="Next" onClick={handleHideBusinessScreen} />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <>
          <div className="add-menu-container">
            <div className="menu-header">
              <h3>Create a new menu</h3>
              <p className="menu-subtext">
                This menu will be publicly visible on your profile
              </p>
            </div>
            <div className="menu-section">
              <label className="section-label">Add menu</label>
              <div className="input-group-listing-detail-menu">
                <ListingFPInput
                  placeholder={"Menu Name"}
                  value={menuName}
                  onChange={(e) =>
                    handleValueChange(e.target.value, "menuName")
                  }
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: menuNameError ? "space-between" : "end",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {menuNameError && (
                    <span style={{ color: "red" }}>{menuNameError}</span>
                  )}
                  <p>{menuName?.length} / 40</p>
                </div>
              </div>
              <div className="input-group-listing-detail-menu">
                <textarea
                  className="textAreaUI"
                  placeholder={"Menu Description"}
                  value={menuDescription}
                  onChange={(e) =>
                    handleValueChange(e.target.value, "menuDescription")
                  }
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: menuDescriptionError
                      ? "space-between"
                      : "end",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {menuDescriptionError && (
                    <span style={{ color: "red" }}>{menuDescriptionError}</span>
                  )}
                  <p>{menuDescription?.length} / 1000</p>
                </div>
              </div>
            </div>
            <div className="menu-section">
              <label className="section-label">Add menu section</label>
              <div className="input-group-listing-detail-menu">
                <ListingFPInput
                  placeholder={"Section Name"}
                  value={itemSection}
                  onChange={(e) =>
                    handleValueChange(e.target.value, "itemSection")
                  }
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: sectionNameError ? "space-between" : "end",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {sectionNameError && (
                    <span style={{ color: "red" }}>{sectionNameError}</span>
                  )}
                  <p>{itemSection?.length} / 40</p>
                </div>
              </div>
            </div>
            <div className="menu-items">
              <div className="menu-item-header">
                <label className="item-label">Add menu item</label>
                <p className="item-subtext">
                  Enter at least one item per section. You can add more later
                </p>
              </div>
              <div className="menu-item-grid">
                <div className="menu-item-inputs">
                  <div className="input-group-listing-detail-menu">
                    <ListingFPInput
                      placeholder={"Item name*"}
                      value={itemName}
                      onChange={(e) =>
                        handleValueChange(e.target.value, "itemName")
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: itemName ? "space-between" : "end",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      {itemNameError && (
                        <span style={{ color: "red" }}>{itemNameError}</span>
                      )}
                      <p>{itemName?.length} / 40</p>
                    </div>
                  </div>
                  <div
                    className="input-group"
                    style={{ marginBlock: "5px 10px" }}
                  >
                    <ListingFPInput
                      placeholder={"Item price (INR)"}
                      value={itemPrice}
                      onChange={(e) => setItemPrice(e.target.value)}
                      type="number"
                    />
                  </div>
                  <div className="input-group-listing-detail-menu">
                    <textarea
                      className="textAreaUI"
                      placeholder={"Item description"}
                      value={itemDescription}
                      onChange={(e) =>
                        handleValueChange(e.target.value, "itemDescription")
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: itemDescription
                          ? "space-between"
                          : "end",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      {itemDescriptionError && (
                        <span style={{ color: "red" }}>
                          {itemDescriptionError}
                        </span>
                      )}
                      <p>{itemDescription?.length} / 1000</p>
                    </div>
                  </div>
                </div>
                <div className="upload-container" onClick={handleDivClick}>
                  <div className="upload-grid">
                    <div className="upload-text">
                      <span className="upload-click">Drag Photo here or </span>
                      <span className="upload-or">Select Photo</span>
                    </div>
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      ref={fileInputRef}
                      className="file-input"
                      onChange={handleFileChange}
                      multiple
                    />
                  </div>
                  <div
                    // key={index}
                    style={{
                      position: "relative",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    {validPhoto.map((photo, index) => (
                      <>
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                            left: "50%",
                            top: "30%",
                          }}
                        >
                          <img
                            src={photo}
                            alt={`uploaded-${index}`}
                            style={{
                              width: "70px",
                              height: "70px",
                              objectFit: "cover",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="dietary-restrictions">
              <label className="dietary-label">Dietary restrictions</label>
              <p className="dietary-subtext">Select all that apply</p>
            </div>
            <Stack direction="row" spacing={1}>
              <Chip
                label="Vegetarian"
                variant={
                  selectedDietary === "Vegetarian" ? "filled" : "outlined"
                }
                onClick={() => handleChipClick("Vegetarian")}
                sx={{
                  backgroundColor:
                    selectedDietary === "Vegetarian" ? "#bfe0e2" : "#F0F1F3",
                  color:
                    selectedDietary === "Vegetarian" ? "#00b5ad" : "default",
                  fontSize: "18px",
                  fontWeight: "400",
                  padding: "12px",
                  border: "0",
                }}
              />
              <Chip
                label="Vegan"
                variant={selectedDietary === "Vegan" ? "filled" : "outlined"}
                onClick={() => handleChipClick("Vegan")}
                sx={{
                  backgroundColor:
                    selectedDietary === "Vegan" ? "#bfe0e2" : "#F0F1F3",
                  color: selectedDietary === "Vegan" ? "#00b5ad" : "default",
                  fontSize: "18px",
                  fontWeight: "400",
                  padding: "12px",
                  border: "0",
                }}
              />
            </Stack>
          </div>
          <div
            className="menu-footer"
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "600",
              fontSize: "24px",
              color: "#1A2544",
              position: "sticky",
              bottom: 0,
              backgroundColor: "#fff",
              zIndex: 1000,
              paddingBlock: "20px",
            }}
          >
            <FPButton
              label="Back"
              size="Small"
              onClick={handleShowBusinessScreen}
            />
            <FPButton label="Save" size="Small" onClick={updateMenuItemImage} />
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // location_food_menu_data: state.gmbListingLocation.location_food_menu_data,
    bulk_update_listing_data: state.gmbListingLocation.bulk_update_listing_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMenu);
