import React from "react";
import { Card } from "reactstrap";
import TopicsKeyword from "./TopicsKeyword";
import TopicsReports from "./TopicsReports";
import { Link } from "react-router-dom";
import LoaderComponent from "../common/loaderComponent";
import RightIcon from "./RightIcon";
import LimitModal from "../common/LimitModal";

class TopicsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownloadReviewModalOpen: false,
      reviewCount : 0
    };
  }
  handleReviewDownloadModal = (downloadFlag) => {
    this.setState({
      isDownloadReviewModalOpen: !this.state.isDownloadReviewModalOpen,
    });
    if (!downloadFlag) return;
    this.props.handleTopicsReviewDownload();
  };

  render() {
    const {
      loader,
      nouns,
      adjectives,
      checked,
      fullReview,
      reviewDetails,
      chunks,
      nounClick,
      adjectiveClick,
      getChunks,
      handleTopicsReviewDownload,
    } = this.props;
    const { isDownloadReviewModalOpen,reviewCount } = this.state;
    return (
      <div>
        <LoaderComponent loader={loader.nouns || loader.adjectives} />
        {Object.keys(nouns).length ? (
          ""
        ) : (
          <Card className="mt-2" style={{ textAlign: "center" }}>
            <Link
              to={{
                pathname: "central-setup",
                state: { tab: "6" },
              }}
              className="mt-4 mb-4"
            >
              Click<span> here </span> to Setup Insights First.
            </Link>
          </Card>
        )}
        <div>
          <RightIcon
            downloadReport={() => {
              this.setState({
                isDownloadReviewModalOpen: true,
                showButton: true,
                reviewCount: chunks?.count || 0
              });
            }}
            disableDownload={
              checked && checked.nouns && checked.nouns.size === 0
            }
          />
        </div>
        <div className="d-flex gap-10">
          <TopicsKeyword
            location={this.props.location}
            history={this.props.history}
            adjectives={adjectives}
            nouns={nouns}
            checked={checked}
            nounClick={nounClick}
            adjectiveClick={adjectiveClick}
            loader={loader}
          />
          <TopicsReports
            checked={checked}
            fullReview={fullReview}
            reviews={chunks}
            reviewDetails={reviewDetails}
            nouns={nouns}
            getChunks={getChunks}
            loader={loader.chunks}
            handleTopicsReviewDownload={handleTopicsReviewDownload}
          />
        </div>
        {isDownloadReviewModalOpen && (
          <LimitModal
            isOpen={isDownloadReviewModalOpen}
            isReviewsPage={true}
            toggle={() =>
              this.setState({
                isDownloadReviewModalOpen: !isDownloadReviewModalOpen,
              })
            }
            HeaderText={"Alrighty"}
            message={
              reviewCount < 1000 ? [ "Hey you are good to go. Click ok to download Insights Review Report", ] 
              : ["Download the first 1000 reviews. Click OK for your Insights Review Report.",]
            }
            handleDownloadModal={this.handleReviewDownloadModal}
          />
        )}
      </div>
    );
  }
}
export default TopicsPage;
