import * as types from "../../actions/actionTypes";

const filterReducer = (state = [], action) => {
  switch (action.type) {
    case types.GET_DATE_TYPE_SUCCESS:
      return Object.assign({}, state, { ["date_type"]: { ...action.data } });
    case types.GET_DATE_TYPE_FAILURE:
      return Object.assign({}, state, { ["date_type"]: [] });
    case types.ACCOUNT_GET_DATE_TYPE_SUCCESS:
      return Object.assign({}, state, {
        ["account_date_type"]: { ...action.data },
      });
    case types.ACCOUNT_GET_DATE_TYPE_FAILURE:
      return Object.assign({}, state, { ["account_date_type"]: [] });

    case types.GET_BRANCH_SUCCESS:
      return Object.assign({}, state, { ["branch"]: { ...action.data } });
    case types.GET_BRANCH_FAILURE:
      return Object.assign({}, state, { ["branch"]: [] });

    case types.GET_CITY_SUCCESS:
      return Object.assign({}, state, { ["city"]: { ...action.data } });
    case types.GET_CITY_FAILURE:
      return Object.assign({}, state, { ["city"]: [] });

    case types.GET_STATE_SUCCESS:
      return Object.assign({}, state, { ["state"]: { ...action.data } });
    case types.GET_STATE_FAILURE:
      return Object.assign({}, state, { ["state"]: [] });

    case types.GET_SERVICE_SUCCESS:
      return Object.assign({}, state, { ["service"]: { ...action.data } });
    case types.GET_SERVICE_FAILURE:
      return Object.assign({}, state, { ["service"]: [] });

    case types.GET_CONTENT_TYPE_SUCCESS:
      return Object.assign({}, state, {
        ["content"]: {
          default: action.data.default,
          choices: action.data.choices.map((item, index) => {
            item["value"] = index.toString();
            return item;
          }),
        },
      });
    case types.GET_CONTENT_TYPE_FAILURE:
      return Object.assign({}, state, { ["content"]: [] });

    case types.ALL_CURRENT_PAGE_FILTER_CONFIG:
      return Object.assign({}, state, {
        ["currentPageFilterConfig"]: { ...action.data },
      });

    case types.GET_LIST_BY_FILTER_SUCCESS:
      return Object.assign({}, state, { ["listBy"]: { ...action.data } });
    case types.GET_LIST_BY_FILTER_FAILURE:
      return Object.assign({}, state, { ["listBy"]: [] });

    // case types.GET_TICKET_LIST_BY_FILTER_SUCCESS:
    //   return Object.assign({}, state, { ["ticketlistBy"]: { ...action.data } });
    // case types.GET_TICKET_LIST_BY_FILTER_FAILURE:
    //   return Object.assign({}, state, { ["ticketlistBy"]: [] });
    case types.GET_LAST_ASYNC_TYPE_SUCCESS:
      const data =  { ...action.data }
      return Object.assign({}, state, { "last_synced_dates": data?.last_synced_dates || [], "last_synced_times": data?.last_synced_times || [] });
    case types.GET_LAST_ASYNC_TYPE_FAILURE:
      return Object.assign({}, state, { "last_synced_dates": [], "last_synced_times": [] });
    case types.GET_OUT_OF_STOCK_ITEMS_SUCCESS:
        return Object.assign({}, state, { "out_of_stock_items": [...action.data]});
    case types.GET_OUT_OF_STOCK_ITEMS_FAILURE:
        return Object.assign({}, state, { "out_of_stock_items": [] });
    default:
      return state;
  }
};

export default filterReducer;
