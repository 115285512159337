import React from "react";
import issueIcon from "../../images/svg/issue.svg";
import ServiceDropdown from "./ServiceDropdown";
import ServiceUserDetails from "./ServiceUserDetails";
import moment from "moment";

const ServiceActionBar = (props) => {
  const { item, ticketRequestTypeList, actions, match, departmentList, locationList, teamList, location, profileId } =
    props;
  return (
    <div className="mb-10">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div style={{ marginBottom: "5px" }}>
            <img src={issueIcon} />
            <span style={{ fontSize: "16px", fontWeight: "500", color: "#1B1C1D", marginLeft: "10px" }}>
              {item.service_request_type_name.name}
            </span>
          </div>
          <div>
            <ServiceUserDetails item={item} />
          </div>
        </div>
        <div>
          <ServiceDropdown
            item={item}
            ticketRequestTypeList={ticketRequestTypeList}
            departmentList={departmentList}
            actions={actions}
            match={match}
            locationList={locationList}
            teamList={teamList}
            location={location}
            profileId={profileId}
          />
        </div>
      </div>
      <div className="d-flex gap-6 justify-content-end align-items-center">
        {item.created_at && (
          <div>
            <span style={{ color: "black", fontSize: "12px" }}>Created at:</span>
            <span className="font-blue-gray" style={{ fontSize: "12px" }}>
              &nbsp;
              {moment(item.created_at).format("llll")}
            </span>
          </div>
        )}
        {item.updated_at && moment(item.updated_at).format("llll") !== moment(item.created_at).format("llll") && (
          <React.Fragment>
            <div className="fp-vertical-small-divider" />
            <div>
              <span style={{ color: "black", fontSize: "12px" }}>Last Updated:</span>
              <span className="font-blue-gray" style={{ fontSize: "12px" }}>
                &nbsp;
                {moment(item.updated_at).format("llll")}
              </span>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
export default ServiceActionBar;
