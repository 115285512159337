import React from "react";

import StarRatingComponent from "react-star-rating-component";
import { startRatingColors } from "../../libs/constant";

const CustomStarRatingComponent = (props) => {
  const { name, starColor = 5, starCount, value, style, className } = props;
  const isDecimal = Number.parseFloat(value).toFixed(2).split(".")[1] !== "00";
  const starRatingColorsIndex = isDecimal
    ? Math.floor(value)
    : Math.floor(value) - 1 || 0;
  // const starRatingColorsIndex =  Math.floor(value) - 1;
  const star_color =
    startRatingColors[starRatingColorsIndex < 0 ? 1 : starRatingColorsIndex] ||
    starColor;

  return (
    <StarRatingComponent
      name={name}
      starCount={starCount}
      starColor={star_color.toString()}
      emptyStarColor="var(--fp-secondary-outline)"
      value={typeof value === "string" ? parseFloat(value) : value}
      className={`star-wrap ${className}`}
      style={style}
      renderStarIcon={(index, value) => {
        return (
          <span>
            <i className={index <= value ? "fas fa-star" : "far fa-star"} />
          </span>
        );
      }}
      renderStarIconHalf={() => {
        return (
          <span>
            <span style={{ position: "absolute", color: star_color }}>
              <i className="far fa-star" />
            </span>
            <span style={{ color: star_color }}>
              <i className="fas fa-star-half" />
            </span>
          </span>
        );
      }}
      editing={false}
    />
  );
};

export default CustomStarRatingComponent;
