import classNames from "classnames";
import React, { useState } from "react";
import { FormFeedback } from "reactstrap";
import { Radio } from "semantic-ui-react";
import SubmitConfirmationModal from "./SubmitConfirmationModal";

const SwitchToggleComponent = (field) => {
  const {
    className,
    input,
    onChange,
    serverError,
    meta: { touched, error },
    smartReply,
    enableDelay,
  } = field;
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [nextToggleState, setNextToggleState] = useState(input.value);

  const handleChange = () => {
    setNextToggleState(!input.value); // Set the next state to be the opposite of the current value
    setConfirmOpen(true);
  };

  const handleConfirm = () => {
    setConfirmOpen(false);
    if (onChange) onChange(nextToggleState);

    input.onChange(nextToggleState);
  };

  const handleCancel = () => {
    setConfirmOpen(false);
  };

  return (
    <div className={classNames("d-inline-flex", className)}>
      <Radio {...field} toggle checked={input.value} value={input.value} onChange={handleChange} />
      {touched && error && <FormFeedback>{error}</FormFeedback>}
      {serverError && <FormFeedback>{serverError}</FormFeedback>}

      <SubmitConfirmationModal
        header="Are you sure!"
        type={!nextToggleState ? "warning" : "done"}
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader={
          smartReply
            ? !nextToggleState
              ? `Are you sure you want off ${enableDelay ? "delay": "auto"} Reply from smart template`
                  : `Are you sure you want on ${enableDelay ? "delay": "auto"} Reply from smart template?`
            : !nextToggleState
            ? `Are you sure you want off ${enableDelay ? "delay": "auto"} Reply from AI`
                  : `Are you sure you want on ${enableDelay ? "delay": "auto"} Reply from AI?`
        }
        isOpen={isConfirmOpen}
        toggle={handleCancel}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default SwitchToggleComponent;
