import React from "react";
import { IoIosLock } from "react-icons/io";

const LockedComponent = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center p-4 bg-gray-100" 
    >
      <IoIosLock className=" mb-4 p-2 " style={{ color: 'white', background: 'deeppink', fontSize: '164px', borderRadius: '6px'}} />
      <h1 className="text-2xl font-bold mb-2">This Page is Locked</h1>
      <p className="text-gray-600">You don't have permission to access this page.</p>
    </div>
  );
};

export default LockedComponent