import axios_instance from '../../libs/interseptor';
import * as types from '../actionTypes';
import * as URLS from '../../libs/apiUrls';
import * as utils from '../../libs/utils';

import * as commonAction from '../common/commonAction';

export function addKeywordSuccess(data) {
  return { type: types.ADD_KEYWORD_SUCCESS, data };

}

export function addKeywordFailure(err) {
  let data = {};
  data.errors = err.response.data;
  return { type: types.ADD_KEYWORD_FAILURE, data };

}

export const addKeyword = ({ business_id, keyword }) => {
  const data = { keyword };

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.post(utils.format(URLS.MENTIONS_KEYWORDS, [business_id]), data)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(addKeywordSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(addKeywordFailure(err));
          throw err.response.data;
        }
      );

  };
};

export function getMentionsDataSucess(data) {
  return { type: types.GET_MENTIONS_DATA_SUCCESS, data };

}

export function getMentionsDataFailure(err) {
  let data = {};
  data.errors = err.response.data;
  return { type: types.GET_MENTIONS_DATA_FAILURE, data };

}


export const getMentionsData = (business_id, params, notLoad) => {

  return (dispatch) => {
    !notLoad && dispatch(commonAction.showLoader());
    return axios_instance.get(utils.format(URLS.GET_MENTIONS_DATA, [business_id]), { params })
      .then(
        (res) => {
          !notLoad && dispatch(commonAction.hideLoader());
          dispatch(getMentionsDataSucess(res.data));
          return res;
        })
      .catch(
        (err) => {
          !notLoad && dispatch(commonAction.showLoader());
          dispatch(getMentionsDataFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getMentionsKeywordsSuccess(data) {
  return { type: types.GET_MENTIONS_KEYWORDS_SUCCESS, data };

}

export function getMentionsKeywordsFailure(err) {
  return { type: types.GET_MENTIONS_KEYWORDS_FAILURE, err };

}


export const getMentionsKeywords = (business_id) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(utils.format(URLS.MENTIONS_KEYWORDS, [business_id]))
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(getMentionsKeywordsSuccess(res.data));
          return res;
        })
      .catch(
        (err) => {
          console.log(err);
          dispatch(commonAction.showLoader());
          dispatch(getMentionsKeywordsFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function deleteMentionReviewSuccess(data) {

  return { type: types.DELETE_MENTION_REVIEW_SUCCESS, data };

}

export function deleteMentionReviewFailure(err) 
{
  let data = { };
  data.errors = err.response.data;
  return { type: types.DELETE_MENTION_REVIEW_FAILURE, data };

}

export const deleteMentionReview = (business_id, id) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.delete(`${utils.format(URLS.GET_MENTION_DATA, [business_id,id])}`)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(deleteMentionReviewSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.showLoader());
          dispatch(deleteMentionReviewFailure(err));
          throw err.response.data;
        }
      );
  };
};


export function deleteMentionKeywordSuccess(data) {

  return { type: types.DELETE_MENTION_KEYWORD_SUCCESS, data };

}

export function deleteMentionKeywordFailure(err) 
{
  let data = { };
  data.errors = err.response.data;
  return { type: types.DELETE_MENTION_KEYWORD_FAILURE, data };

}

export const deleteMentionKeyword = (business_id, id) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.delete(`${utils.format(URLS.MENTIONS_KEYWORD, [business_id,id])}`)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(deleteMentionKeywordSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.showLoader());
          dispatch(deleteMentionKeywordFailure(err));
          throw err.response.data;
        }
      );
  };
};


export function updateReviewStatusSuccess(data) {
  return { type: types.UPDATE_REVIEW_STATUS_SUCCESS, data };
}

export function updateReviewStatusFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.UPDATE_REVIEW_STATUS_FAILURE, data };
}

export const updateReviewStatus = (data) => {

  const { business_id, id, ...rest } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.GET_MENTION_DATA, [business_id,id]);
    return axios_instance.patch(url, rest)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(updateReviewStatusSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(updateReviewStatusFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getMentionProvidersSuccess(data) {

  return { type: types.GET_MENTIONS_PROVIDERS_SUCCESS, data };

}

export function getMentionProvidersFailure(err) {
  let data = {};
  data.errors = err.response.data;
  return { type: types.GET_MENTIONS_PROVIDERS_FAILURE, data };

}


export const getMentionProviders = (params) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(URLS.MENTION_PROVIDERS, {params})
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(getMentionProvidersSuccess(res.data));
          return res;
        })
      .catch(
        (err) => {
          dispatch(commonAction.showLoader());
          dispatch(getMentionProvidersFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getMentionsReportSuccess(data) {

  return { type: types.GET_MENTION_REPORT_SUCCESS, data };

}

export function getMentionReportFailure(err) {
  let data = {};
  data.errors = err.response.data;
  return { type: types.GET_MENTION_REPORT_FAILURE, data };

}

export const getMentionsReport = (business_id, params) => {

  return(dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(utils.format(URLS.MENTION_REPORT, [business_id]), { params } )
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(getMentionsReportSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.showLoader());
          dispatch(getMentionReportFailure(err));
          throw err.response.data;
        }
      );
  };
};

export const getStatusSuccess = (data) => {
  return { type: types.GET_STATUS_SUCCESS , data };
};

const getStatusFailure = (data) => {
  return { type: types.GET_STATUS_FAILURE , data };
};

export const getStatus = (business_id, params) => {
  return (dispatch) => {
    return axios_instance.get(utils.format(URLS.MENTION_STATUS,[business_id]), { params })
      .then((res) => {
        dispatch(getStatusSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(getStatusFailure(err));
        throw err.response.data;
      });
  };
};

//Mentions Social Connections Actions Starts From Here
const getMentionsSocialStatusSuccess = (data) => {
  return { type: types.MENTIONS_SOCIAL_STATUS_SUCCESS, data };
};

export const getMentionsSocialStatus = (business_id) => {
  return dispatch => {
    return axios_instance.get(utils.format(URLS.MENTIONS_SOCIAL_STATUS,[business_id]))
      .then(res => {
        dispatch(getMentionsSocialStatusSuccess(res.data));
        return res;
      })
      .catch(err => {
        throw err.response.data;
      });
  };
};

export const getAuthUrl = (business_id,provider) => {
  return () => {
    return axios_instance.get(`${utils.format(URLS.ALL_MENTIONS_SOCIAL_CONNECTIONS,[business_id,provider])}auth_url/`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw err.response.data;
      });
  };
};

const postMentionsSocialCodeSuccess = (data) => {
  return { type: types.MENTIONS_SOCIAL_CONNECT_SUCCESS, data };
};

export const postMentionsSocialCode = (data,business_id,provider) => {
  return dispatch => {
    return axios_instance.post(utils.format(URLS.ALL_MENTIONS_SOCIAL_CONNECTIONS,[business_id,provider]),data)
      .then(res => {
        dispatch(postMentionsSocialCodeSuccess(res.data,provider));
        return res;
      })
      .catch(err => {
        throw err.response.data;
      });
  };
};

export const mentionSocialLogout = (business_id,provider) => {
  return (dispatch) => {
    return axios_instance.post(`${utils.format(URLS.MENTIONS_SOCIAL_CONNECTIONS,[business_id,provider])}status`)
      .then((res) => {
        dispatch(postMentionsSocialCodeSuccess(res.data));
      })
      .catch(err => {
        throw err.response.data;
      });
  };
};