import * as types from "../../actions/actionTypes";

export default function insightsDetails(state = [], action) {
  switch (action.type) {
    case types.GET_WORD_CLOUD_DATA_SUCCESS:
      return Object.assign({}, state, { word_cloud: {...action.data} });
    case types.GET_LOCATION_GRADING_SUCCESS:
      return Object.assign({}, state, { location_grading: { ...action.data } });
    case types.GET_STAFF_PERFORMANCE_SUCCESS:
      return Object.assign({}, state, {
        staff_performance: { ...action.data },
      });
    case types.GET_NOUN_LIST_SUCCESS:
      return Object.assign({}, state, { noun_list: { ...action.data } });
    case types.GET_ADJ_LIST_SUCCESS:
      return Object.assign({}, state, { adj_list: { ...action.data } });
    case types.GET_CHUNK_LIST_SUCCESS:
      return Object.assign({}, state, { chunk_list: { ...action.data } });
    case types.GET_CHUNK_LIST_FAILURE:
      return Object.assign({}, state, { chunk_list: {} });
    case types.GET_MENU_PERFORMANCE_SUCCESS:
      return Object.assign({}, state, {
        menu_performance: action.data ? {...action.data } : {},
      });
    default:
      return state;
  }
}
