import React from "react";
import DateSelector from "../../../modules/dashboard/component/DateSelector";

const DateInputField = ({ input, meta, ...rest }) => {
  const handleChange = (value) => {
    input.onChange(value);
  };
  return (
    <div>
      <DateSelector {...rest} onChange={handleChange} isForLifeTimeSupported={false} mainClass="list-date-filter" />
      {meta.touched && meta.error && <span className="error-text">{meta.error}</span>}
    </div>
  );
};

export default DateInputField;
