import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'reactstrap';
import { InputField, SelectField} from 'react-semantic-redux-form';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import PhoneInputField from '../common/phonefields';
import * as utils from '../../libs/utils';
import * as BusinessActions from '../../actions/business/businessSetupActions';
import { FPButton } from '../common/new';
import FPLabel from '../common/new/forms/ui/FPLabel';
import FPSelect from '../common/new/forms/ui/FPSelect';
import AutoSelect from '../common/AutoSelect';
import { renderAutoSelectField } from '../common/RenderAutoSelectField';

const validate = (values) => {// eslint-disable-line
  const errors = {};
  if(!values.name) errors.name = 'Enter Name';
  else if(!/^[a-zA-Z\s]+$/i.test(values.name)){
    errors.name = 'Invalid Name';
  }
  if(!values.email && !values.phone){
    if (!values.email) errors.email ='Enter Email or Mobile Number';
    if (!values.phone) errors.phone = 'Enter Mobile Number or Email';
  }
  if(!values.branch){
    errors.branch = 'Select Branch';
  }
  return errors;
};

class ReviewInviteForm  extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount(){
    const { businessId } = this.props;
    this.props.businessActions.businessBranchOptions({ business: businessId }, true);
  }

  submit(data) {
    console.log('====================================');
    console.log("v", data);
    console.log('====================================');
    const { sendInvite, reset } = this.props;
    return sendInvite(data).then(() => {
      utils.displayMessage('positive', 'Invite sent successfully');
      reset();
    })
      .catch((err) => {
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
  }

  render() {
    const { submitting, handleSubmit, errors, branches } = this.props;

    const CustomInput = ({...rest}) => <InputField {...rest} className='w-100' />;
    return (
      <form className="grid-form mt-2" onSubmit={handleSubmit(this.submit)}>
        { errors && errors.non_field_errors
          ? errors.non_field_errors.map (
            (error, index) => (<p key={index} className="text-danger">{error}</p>)
          )
          : null
        }
        <section>
          <Row fluid className="py-2">
            <Col xs={12} sm={12} md={6}>
              <FPLabel>Name</FPLabel>
              <Field
                name="name"
                component={CustomInput}
                placeholder="Name"
                type="text"
                required
              />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <FPLabel>Email Address</FPLabel>
              <Field
                name="email"
                component={CustomInput}
                placeholder="E-mail address"
                type="email"
              />
            </Col>
          </Row>

          <Row fluid className="py-2 review-invite-form">
            <Col xs={12} sm={12} md={6}>
              <FPLabel>Phone</FPLabel>
              <Field
                name="phone"
                component={PhoneInputField}
                placeholder="Mobile Number"
                type="text"
              />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <FPLabel>Select Branch</FPLabel>
              <Field
                name="branch"
                component={renderAutoSelectField}
                placeholder="Branch"
                options={
                  branches &&
                  branches.choices.map(({ display_name, value }) => ({
                    display_name: display_name,
                    value,
                  }))
                }
                sx={{
                  "& .MuiInputBase-input": {
                    height: 21,
                  },
                  "& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                }}
              />
            </Col>
          </Row>

          <div className="mt-3 text-right">
            <FPButton
              size="small"
              label="Send Invite"
              type="submit"
              disabled={submitting}
            />
          </div>
        </section>
      </form>
    );
  }
}

ReviewInviteForm =  reduxForm({// eslint-disable-line
  form: 'ReviewInviteForm',
  validate,
  enableReinitialize:true,
})(ReviewInviteForm);

const mapStateToProps = (state) => {
  return {
    branches: state.business.branches,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    businessActions: bindActionCreators(BusinessActions,dispatch),
  };
}

export default connect(mapStateToProps,mapDispatchToProps,null,{forwardRef: true})(ReviewInviteForm);
