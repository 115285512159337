import React from "react";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import ListingFPTab from "../../../components/common/new/Listing/tabs/listingFPTabs";

const RootHeader = (props) => {
  const redirectTo = (url) => {
    setTimeout(() => {
      props.history.push(url);
    }, 400);
  };

  const handleRootTabChange = (value) => {
    switch (value) {
      case 0:
        redirectTo(`/${props.businessProfile?.business}/console-list`);
        break;
      case 1:
        redirectTo(`/${props.businessProfile?.business}/bulk-changes-log?page=1&page_size=10`);
        break;
      case 2:
        redirectTo(`/${props.businessProfile?.business}/bulk-product`);
        break;
      case 3:
        redirectTo(`/${props.businessProfile?.business}/bulk-menu`);
        break;
      default:
        break;
    }
  };

  return (
    <div className={"mb-4"}>
      <ListingFPTab
        className="border-0"
        tabLabels={["Console", "Bulk Changes Log", "Bulk Product Update", "Bulk Menu Update"]}
        border="0"
        minTabWidth={180}
        defaultValue={props.defaultValue}
        onChange={(value) => handleRootTabChange(value)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    businessProfile: state.profile.business_user,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(RootHeader);
