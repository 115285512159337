// OfflineStoreAlertsComponents.js
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import OfflineStoreAlertsFilter from "./OfflineStoreAlertsFilter";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Row, Col, TabPane, TabContent } from "reactstrap";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import PaginationComponent from "../../components/common/Pagination";
import { Box } from "@material-ui/core";
import { bindActionCreators } from "redux";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import * as OfflineStoreAlerts from "../../actions/OfflineStoreAlerts/OfflineStoreAlertsActions";
import queryString from "query-string";
import * as URLS from "../../libs/apiUrls";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import LoaderComponent from "../../components/common/loaderComponent";
import LimitModal from "../../components/common/LimitModal";

function OfflineStoreAlertsComponents(props) {
  const {
    history,
    location,
    match: {
      params: { business_id },
      params,
    },
    loader,
    actions,
    offlineData,
    reviewActions,
  } = props;

  const { search } = location;
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  useEffect(() => {
    document.title = "Offline Store Alerts";
    props.actionsSelectTab.setSelectedTab(3);
    const payload = queryString.parse(search);
    actions.OfflineStoreAlerts(payload, business_id);
  }, [search]);

  const getFilterData = (data) => {
    actions.OfflineStoreAlerts(data, business_id);
  };
  const toggleDownloadModal = () => {
    setIsDownloadModalOpen(!isDownloadModalOpen);
  };

  const handleDownloadModal = (downloadFlag) => {
    setIsDownloadModalOpen(!isDownloadModalOpen);
    if (!downloadFlag) return;
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = props;
    const url = `${URLS.OFFLINE_STORE_DATA.replace(
      "{}",
      business_id
    )}download/${search ? `${search}` : ""}`;
    return reviewActions.downloadTemplate(
      url,
      "famepilot-offline-store-alert-report.csv"
    );
  };

  return (
    <>
      <LoaderComponent loader={loader} />
      <div className="w-100 mt-3 h-100">
        <div className="align-center justify-content-between mx-3">
          <Box
            className="mb-0"
            sx={{ color: "#1B1C1D !important", fontSize: "12px" }}
          >
            Last Synced: {offlineData?.last_sync}
          </Box>
        </div>
        <Col>
          <OfflineStoreAlertsFilter
            history={history}
            location={location}
            match={props.match}
            downloadExcel={toggleDownloadModal}
            getFilterData={getFilterData}
          />
        </Col>
        <Col style={{ marginTop: "16px" }}>
          <CardWrapper showDivider={false}>
            <div className="ui-table-responsive searchHistoryTable">
              <TableContainer component={Paper} style={{ marginTop: "20px" }}>
                <Table className="fp-table fp-table-center">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Provider Name</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Outlet Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {offlineData &&
                      offlineData?.results &&
                      offlineData?.results?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.date}</TableCell>
                          <TableCell>{row.time}</TableCell>
                          <TableCell>{row.provider_name}</TableCell>
                          <TableCell>{row.location}</TableCell>
                          <TableCell>{row.status}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {!loader && !offlineData?.count && (
              <div
                className="my-4 fp-size-24 d-flex justify-content-center"
                style={{ fontWeight: "500" }}
              >
                No data found
              </div>
            )}
            <PaginationComponent
              count={offlineData?.count || 0}
              location={location}
              pageSizeCookie="offline-store-alerts"
              // onPageNumberChange={this.handlePagination}
              // onPageSizeChange={this.handlePagination}
              // largeSizes
            />
          </CardWrapper>
        </Col>
      </div>

      {isDownloadModalOpen && (
        <LimitModal
          isOpen={isDownloadModalOpen}
          isReviewsPage={true}
          toggle={() => setIsDownloadModalOpen(!isDownloadModalOpen)}
          HeaderText={"Offline Store Alerts Report"}
          message={[
            "Hey you are good to go. Click Ok to download Offline Store Alerts Report. ",
          ]}
          handleDownloadModal={handleDownloadModal}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
    offlineData: state.offlineStoreAlerts.offlineStoreAlerts,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    actions: bindActionCreators(OfflineStoreAlerts, dispatch),
    reviewActions: bindActionCreators(ReviewsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(OfflineStoreAlertsComponents);
