import React, { useState, useEffect } from "react";
import ListingFPInput from "../../../../../components/common/new/Listing/ListingFPInput";
import { Checkbox, Popup } from "semantic-ui-react";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";
import google_svg from "../../../../../images/googlephoto.svg";
import AddIcon from "@material-ui/icons/Add";
import red_delete_png from "../../../../../images/deleteRed.png";
import StarIcon from "@mui/icons-material/Star";
import BusinessList from "../BusinessList";
import * as listingUtils from "../../../../../libs/listingToast";
import validator from "validator";
import FPInput from "../../../../../components/common/new/forms/ui/FPInput";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoaderComponent from "../../../../../components/common/loaderComponent";
import * as placeActionType from "./getplaceActionType";

const UpdateAppointment = (props) => {
  const {
    business_id,
    queryStringData,
    busines_status,
    bulk_update_listing_data,
  } = props;

  const [selectedBusinessList, setSelectedBusinessList] = useState([]);
  const [showBusiness, setShowBusiness] = useState(true);
  const handleHideBusinessScreen = () => {
    setShowBusiness(false);
  };

  const handleShowBusinessScreen = () => {
    setShowBusiness(true);
  };

  const { primaryDetail, actions } = props;
  // const selectReso = 21;
  console.log(
    "placeLocationMetaDataplaceLocationMetaData2222",
    placeActionType?.placeActionTypeMetadata
  );
  const [inputFields, setInputFields] = useState({});
  const [originalFields, setOriginalFields] = useState({});
  const [useWebsiteUri, setUseWebsiteUri] = useState({});
  const [usePayload, setUsePayload] = useState([]);

  const [appointmentLinkList, setAppointmentLinkList] = useState([]);

  // useEffect(() => {
  //     if (business_id && selectReso) {
  //         actions.getGmbListingPlaceActionMetaData(business_id, selectReso);
  //     }
  // }, [actions, business_id, selectReso]);

  useEffect(() => {
    if (placeActionType?.placeActionTypeMetadata) {
      const placeActionTypeMetadata =
        placeActionType?.placeActionTypeMetadata?.placeActionTypeMetadata;

      const data = primaryDetail?.location_links
        ? primaryDetail?.location_links
        : [];
      if (data?.length > 0) {
        const validationMap = placeActionTypeMetadata?.reduce((acc, curr) => {
          acc[curr.placeActionType] = curr.displayName;
          return acc;
        }, {});
        const transformedData = data?.reduce((acc, curr) => {
          const { placeActionType, providerType, name, uri } = curr;

          if (!acc[placeActionType]) {
            acc[placeActionType] = {
              placeActionType,
              providerType,
              name,
              uriList: [],
              sameAsWebsiteURL: false,
            };
          }
          // uri check that is valid or not
          const isValidURL = validator.isURL(uri);
          //       // uri same as website url check
          const sameAsWebsiteURL = uri === primaryDetail?.websiteUri;
          acc[placeActionType].sameAsWebsiteURL = sameAsWebsiteURL;
          acc[placeActionType].uriList.push({
            uri,
            error: !isValidURL,
            type: "update",
          });

          return acc;
        }, {});

        // Ensure all validationList entries are included
        placeActionTypeMetadata.forEach(({ placeActionType, displayName }) => {
          if (!transformedData[placeActionType]) {
            transformedData[placeActionType] = {
              displayName,
              placeActionType,
              providerType: "",
              name: "",
              uriList: [
                {
                  uri: "",
                  error: false,
                  type: "create",
                },
              ],
              sameAsWebsiteURL: false,
            };
          } else {
            transformedData[placeActionType].displayName = displayName;
          }
        });
        const result = placeActionTypeMetadata.map(
          ({ placeActionType }) => transformedData[placeActionType]
        );
        setAppointmentLinkList(result);
      } else {
        setAppointmentLinkList(
          placeActionTypeMetadata?.map((placeActionType) => ({
            displayName: placeActionType.displayName,
            placeActionType: placeActionType.placeActionType,
            providerType: "AGGREGATOR_3P",
            name: "",
            uriList: [
              {
                uri: "",
                error: false,
                type: "create",
              },
            ],
            sameAsWebsiteURL: false,
          }))
        );
      }
    }
  }, [placeActionType?.placeActionTypeMetadata]);

  const handleAddLink = (location) => {
    const newList = [...appointmentLinkList];
    const appointmentIndex = newList.findIndex(
      (item) => item.placeActionType === location.placeActionType
    );
    if (appointmentIndex === -1) return;
    const uriList = newList[appointmentIndex].uriList;
    uriList?.push({ uri: "", error: false, type: "create" });
    newList[appointmentIndex].uriList = uriList;
    setAppointmentLinkList(newList);
  };

  const handleInputChange = (location, index, event) => {
    const { value } = event.target;
    const isValidURL = value?.length > 0 ? validator.isURL(value) : true;
    const newList = [...appointmentLinkList];
    const appointmentIndex = newList.findIndex(
      (item) => item.placeActionType === location?.placeActionType
    );
    if (appointmentIndex === -1) return;
    const uriList = newList[appointmentIndex].uriList;
    uriList[index].uri = value;
    uriList[index].error = !isValidURL;
    newList[appointmentIndex].uriList = uriList;
    setAppointmentLinkList(newList);
  };

  const handleRemoveField = (location, index) => {
    const newList = [...appointmentLinkList];
    const appointmentIndex = newList.findIndex(
      (item) => item.placeActionType === location?.placeActionType
    );
    if (appointmentIndex === -1) return;
    const uriList = newList[appointmentIndex].uriList;
    // uriList.splice(index, 1);
    if (uriList[index].type === "create") {
      uriList.splice(index, 1);
    } else if (uriList[index].type === "update") {
      uriList[index].type = "delete";
    }
    newList[appointmentIndex].uriList = uriList;
    setAppointmentLinkList(newList);
  };

  const handleSameAsWebsiteURLSwitch = (e, location) => {
    const { checked } = e.target;
    const newList = [...appointmentLinkList];
    const appointmentIndex = newList.findIndex(
      (item) => item.placeActionType === location?.placeActionType
    );
    if (appointmentIndex === -1) return;
    newList[appointmentIndex].sameAsWebsiteURL = checked;
    let uriList = newList[appointmentIndex].uriList;
    if (checked) {
      uriList?.forEach((item) => {
        const isValidURL = validator.isURL(
          primaryDetail?.websiteUri ? primaryDetail?.websiteUri : ""
        );
        item.uri = primaryDetail?.websiteUri;
        item.error = !isValidURL;
      });
    } else {
      const data = primaryDetail?.location_links;
      const transformedData = data?.reduce((acc, curr) => {
        const { placeActionType, providerType, name, uri } = curr;
        if (placeActionType === location?.placeActionType) {
          const isValidURL = validator.isURL(uri);
          acc.push({
            uri,
            error: !isValidURL,
            type: "update",
          });
        }
        return acc;
      }, []);
      if (transformedData?.length === 0) {
        uriList = [{ uri: "", error: false, type: "create" }];
      } else {
        uriList = transformedData;
      }
    }
    newList[appointmentIndex].uriList = uriList;
    setAppointmentLinkList(newList);
  };

  const addressString = [
    primaryDetail?.addressLines,
    primaryDetail?.locality,
    primaryDetail?.postalCode,
  ].join(",");

  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event, value) => {
    event.preventDefault();
    setChecked(!value);
  };

  const [UTMSource, setUTMSource] = useState("");
  const [UTMMedium, setUTMMedium] = useState("");

  const UTMSourceHandler = (event) => {
    setUTMSource(event.target.value);
  };
  const UTMMediumHandler = (event) => {
    setUTMMedium(event.target.value);
  };

  const convertPayload = (payload) => {
    const result = {
      type: "actionLinks",
      actionLinks: [],
      location_ids: selectedBusinessCount,
      // location_ids: {selectedBusinessCount},
    };
    payload.forEach((item) => {
      const { uriList } = item;
      if (uriList.length > 0) {
        uriList.forEach((uri) => {
          if (!uri.error && uri.uri !== "") {
            if (UTMSource && UTMMedium) {
              const url = new URL(uri.uri);
              url.searchParams.set(UTMSource, UTMMedium);
              uri.uri = url.toString();
            }
            result.actionLinks.push({
              uri: uri.uri,
              name: item.name,
              providerType: item.providerType,
              type: uri.type,
              placeActionType: item.placeActionType,
            });
          }
        });
      }
    });
    return result;
  };

  const [selectedBusinessCount, setSelectedBusinessCount] = useState([]); // State to hold the selected businesses count
  console.log("==================================", selectedBusinessCount);

  const handleSelectedBusinessCount = (selectBusiness) => {
    setSelectedBusinessCount(selectBusiness);
  };

  const updateAppointmentHandler = async () => {
    // Generate the payload
    const restodetail = {
      business_id,
      // selectReso,
    };
    const payload = convertPayload(appointmentLinkList);

    // const formData = new FormData()
    // formData.append('type', "actionLinks")
    // formData.append('location_ids', JSON.stringify(selectedBusinessCount))
    // formData.append('actionLinks', JSON.stringify(payload?.actionLinks))

    listingUtils?.displayMessage(
      "info",
      "Listing getting updated, It may take a few minutes."
    );
    await actions.getGmbListingBulkUpdateListing(payload, restodetail);
    listingUtils?.displayMessage("close");
    console.log(
      "bulk_update_listing_databulk_update_listing_databulk_update_listing_data",
      bulk_update_listing_data
    );
    if (bulk_update_listing_data) {
      listingUtils?.displayMessage("positive", "updated Successfully.");
    }

    // listingUtils?.displayMessage(
    //     "info",
    //     "Listing getting updated, It may take a few minutes."
    // );
    // await actions.getGmbListingRefreshListing(business_id, selectReso);
    // listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    // listingUtils?.displayMessage("positive", "Listing updated successfully.");
    // await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };

  return (
    <>
      {/* <LoaderComponent loader={props.loader} /> */}

      {showBusiness ? (
        <div>
          <BusinessList
            type="appointment_links"
            busines_status={busines_status}
            queryStringData={queryStringData}
            business_id={business_id}
            primaryDetail={null}
            selectedBusinessList={selectedBusinessList}
            onSelectedBusinessCountChange={handleSelectedBusinessCount}
          />
          {selectedBusinessCount?.length > 0 ? (
            // <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
            <div
              className="d-flex mt-10 mb-15"
              style={{
                display: "flex",
                justifyContent: "end",
                fontWeight: "600",
                fontSize: "24px",
                color: "#1A2544",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                // paddingBottom: "10px",
                // paddingTop: "10px",
                paddingBlock: "20px",
              }}
            >
              <FPButton label="Next" onClick={handleHideBusinessScreen} />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-9">
            <div
              className="listing-container"
              style={{ display: "grid", gap: "32px" }}
            >
              <div style={{ display: "grid", gap: "32px" }}>
                {appointmentLinkList?.map((location) => (
                  <div
                    style={{ display: "grid", gap: "12px" }}
                    key={location?.placeActionType}
                  >
                    <div style={{ display: "grid", gap: "4px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#1B1C1D",
                          }}
                        >
                          {location?.displayName}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            color: "#00B5AD",
                            fontWeight: "400",
                            fontSize: "16px",
                            alignContent: "center",
                            gap: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleAddLink(location)}
                        >
                          <AddIcon />
                          <div>add Link</div>
                        </div>
                      </div>
                      {location?.uriList?.map((field, findex) => {
                        if (field.type !== "delete") {
                          return (
                            <>
                              <div
                                key={findex}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <FPInput
                                  type="text"
                                  value={field?.uri}
                                  placeholder={`Enter ${location?.displayName} URL`}
                                  onChange={(event) =>
                                    handleInputChange(location, findex, event)
                                  }
                                  endIcon={
                                    <Popup
                                      trigger={<StarIcon color="disabled" />}
                                      position="top center"
                                      on="hover"
                                    >
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          fontSize: "12px",
                                          color: "#1B1C1D",
                                        }}
                                      >
                                        Set as preferred link
                                      </div>
                                    </Popup>
                                  }
                                />
                                <img
                                  src={red_delete_png}
                                  alt="deletePng"
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    color: "#FFFFFF",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleRemoveField(location, findex)
                                  }
                                />
                                {/* )} */}
                              </div>
                              {field?.error && (
                                <p style={{ color: "red" }}>
                                  Please enter a valid URL
                                </p>
                              )}
                            </>
                          );
                        }
                      })}
                    </div>
                    {location?.uriList?.length > 0 &&
                      location?.uriList?.some(
                        (item) => item.type !== "delete"
                      ) && (
                        <div style={{ display: "flex", gap: "8px" }}>
                          {/* <input
                                                        type="checkbox"
                                                        id={`same_as_url_${location?.placeActionType}`}
                                                        checked={
                                                            location?.sameAsWebsiteURL &&
                                                            primaryDetail?.websiteUri &&
                                                            !location?.uriList?.some(
                                                                (item) => item.uri !== primaryDetail?.websiteUri
                                                            )
                                                        }
                                                        onChange={(e) =>
                                                            handleSameAsWebsiteURLSwitch(e, location)
                                                        }
                                                    /> */}

                          {/* <label
                                                        htmlFor={`same_as_url_${location?.placeActionType}`}
                                                        style={{
                                                            cursor: "pointer",
                                                            fontWeight: "400",
                                                            fontSize: "12px",
                                                            color: "#7A8193",
                                                        }}
                                                    >
                                                        Same as website URL
                                                    </label> */}
                        </div>
                      )}
                  </div>
                ))}
              </div>

              <div style={{ display: "grid", gap: "32px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="listing-name">Add UTM source & Medium</div>
                  <Checkbox
                    toggle
                    checked={checked}
                    className="toggle-btn"
                    onChange={(event) => handleCheckboxChange(event, checked)}
                  />
                </div>
                <div className="row">
                  <div
                    className="col-6"
                    style={{ display: "grid", gap: "4px" }}
                  >
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#1B1C1D",
                      }}
                    >
                      UTM Source{" "}
                    </div>
                    <FPInput
                      disabled={!checked}
                      type="text"
                      placeholder={"Add UTM source eg. Google"}
                      onChange={UTMSourceHandler}
                    />
                  </div>
                  <div
                    className="col-6"
                    style={{ display: "grid", gap: "4px" }}
                  >
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#1B1C1D",
                      }}
                    >
                      UTM Medium{" "}
                    </div>
                    <FPInput
                      disabled={!checked}
                      type="text"
                      placeholder={"Add UTM medium eg. GMB"}
                      onChange={UTMMediumHandler}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "#fff",
                    zIndex: 1000,
                    // paddingBottom: "10px",
                    // paddingTop: "10px",
                    paddingBlock: "20px",
                  }}
                >
                  <div>
                    <FPButton
                      label="Back"
                      size="small"
                      onClick={handleShowBusinessScreen}
                    />
                  </div>
                  <div>
                    <FPButton
                      // disabled={}
                      onClick={updateAppointmentHandler}
                      label="Update URL's"
                      size="small"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <img src={google_svg} alt="verifiedImage" width={"100%"} />
          </div>
        </div>
      )}
    </>
  );
};

// export default UpdateAppointment
const mapStateToProps = (state) => {
  return {
    // placeLocationMetaData: state.gmbListingLocation.placeLocationMetaData,
    // placeLoationMetadata_error:
    //     state.gmbListingLocation.placeLoationMetadata_error,
    bulk_update_listing_data: state.gmbListingLocation.bulk_update_listing_data,
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAppointment);
