/* eslint-disable no-unused-vars */
import React, { forwardRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, withStyles, Slide } from "@material-ui/core";
import classNames from "classnames";
import { Divider } from "semantic-ui-react";

const useStyle = makeStyles(() => {
  return {
    header: {
      padding: ({ paddingDense }) => (paddingDense ? "20px" : "24px"),
      paddingBottom: "0",
    },
    body: {
      padding: ({ paddingDense }) => (paddingDense ? "0px 20px 0px 20px" : "24px"),
    },
    footer: {
      padding: ({ paddingDense }) => (paddingDense ? "20px" : "10px 24px"),
    },
    iconHeader: {},
  };
});

const DefaultDialog = withStyles({
  paper: {
    borderRadius: "12px",
    width: ({ width }) => (width ? width : "auto"),
    height: ({ height }) => (height ? height : "auto"),
  },
  scrollPaper: {
    alignItems: ({ alignment }) => (alignment === "top" ? "flex-start" : "center"),
    marginTop: ({ alignment }) => (alignment === "top" ? "60px" : "0px"),
  },
})((props) => <Dialog {...props} />);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const FormModal = (props) => {
  const {
    isOpen,
    toggle,
    heading,
    children,
    onClose,
    dialogActions,
    size,
    maxWidth,
    width,
    className,
    showDivider = false,
    showCloseBtn = true,
    actionClass,
    bodyClass,
    headerClass,
    headerWrapperClass,
    styleHeader = false,
    alignment,
    contentClass,
    subHeading,
    height,
    dividerClass,
    paddingDense = false,
    rightToLeft = true,
    ...rest
  } = props;

  const handleClose = () => {
    toggle();

    onClose && onClose();
  };

  const classes = useStyle({ width, paddingDense });

  return (
    <div>
      <DefaultDialog
        open={isOpen}
        onClose={handleClose}
        width={width}
        height={height}
        alignment={alignment}
        scroll="paper"
        className={classNames(className)}
        fullWidth={true}
        maxWidth={maxWidth || "md"}
        TransitionComponent={rightToLeft ? Transition : undefined}
        {...rest}
      >
        {(heading || showCloseBtn) && (
          <DialogTitle
            disableTypography
            id="scroll-dialog-title"
            className={classNames(classes.header, headerWrapperClass)}
          >
            <div className={classNames("d-flex justify-content-between align-item-start", headerClass)}>
              <div
                className={classNames("font-blue fp-size-20 weight-600 w-100", {
                  "mb-0": paddingDense,
                })}
                // onClick={toggle}
              >
                {heading && heading}
              </div>
              {showCloseBtn && (
                <CloseIcon role="button" style={{ color: "#1B1C1D" }} aria-label="close" onClick={toggle} />
              )}
            </div>
            {subHeading && subHeading}
          </DialogTitle>
        )}
        {showDivider && <Divider className={classNames("my-0 mx-3", dividerClass)} />}
        <DialogContent
          component="section"
          className={classNames(classes.body, bodyClass, {
            "pt-3": styleHeader,
            "mb-0": paddingDense,
          })}
        >
          <DialogContentText
            component="div"
            id="scroll-dialog-description"
            tabIndex={-1}
            className={classNames(contentClass)}
          >
            {children}
          </DialogContentText>
        </DialogContent>
        {dialogActions && (
          <DialogActions className={classNames(classes.footer, actionClass)}>{dialogActions}</DialogActions>
        )}
      </DefaultDialog>
    </div>
  );
};

export default FormModal;
