import React, { useState } from 'react'
import delete_png from "../../../../../images/delete.png";
import google_svg from "../../../../../images/googlephoto.svg";
import { Checkbox } from 'semantic-ui-react';
import { bindActionCreators } from "redux";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import FPButton from '../../../../../components/common/new/FPFilter/Listing/FPButton';
import AddIcon from "@material-ui/icons/Add";
import BusinessList from '../BusinessList';
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import * as listingUtils from "../../../../../libs/listingToast"
import { connect } from "react-redux";

const UpdateOpenHour = (props) => {

    const { primaryDetail, actions, business_id, queryStringData, busines_status, bulk_update_listing_data } = props;
    const selectReso = 21;

    const [checked, setChecked] = useState(true);
    const [periods, setPeriods] = useState([]);
    const [useDefaultTime, setUseDefaultTime] = useState(false);
    const [businessStatus, setBusinessStatus] = useState("OPEN");
    const [result, setResult] = useState([]);
    const [defaultTimeData, setUseDefaultTimeData] = useState({
        openTime: { hours: "00", minutes: "00" },
        closeTime: { hours: "00", minutes: "00" },
    });
    const weekDays = [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
    ];
    React.useEffect(() => {
        if (primaryDetail?.regularHours?.periods) {
            const data = primaryDetail?.regularHours?.periods;
            // setPeriods(data);
            const convertedArray = data?.reduce((acc, period) => {
                // if(period.openDay)
                acc[period.openDay] = acc[period.openDay] || [];
                acc[period.openDay].push(period);
                return acc;
            }, {});
            // Convert the groupedByDay object to the desired array format
            const conertedInArray = weekDays?.map((day) => ({
                day: day,
                time:
                    convertedArray[day] ||
                    [
                        // {
                        //   openTime: { hours: "00", minutes: "00" },
                        //   closeTime: { hours: "00", minutes: "00" },
                        // },
                    ],
                checked: !!convertedArray[day],
            }));
            setPeriods(conertedInArray);
        } else {
            const conertedInArray = weekDays.map((day) => ({
                day: day,
                time:
                    // convertedArray[day] ||
                    [
                        {
                            openTime: { hours: "10", minutes: "00" },
                            closeTime: { hours: "19", minutes: "00" },
                        },
                    ],
                checked: true,
            }));
            setPeriods(conertedInArray);
        }
    }, [primaryDetail?.regularHours?.periods]);

    // React.useEffect(() => {
    //   if (primaryDetail?.openInfo_status) {
    //     setBusinessStatus(primaryDetail?.openInfo_status);
    //   } else {
    //     setBusinessStatus("OPEN");
    //   }
    // }, [primaryDetail?.openInfo_status]);

    const handleDaySwitch = (event, value) => {
        const newData = [...periods];
        const dayIndex = newData.findIndex((period) => period.day === value?.day);

        if (dayIndex === -1) return;

        newData[dayIndex].checked = !value?.checked;

        if (newData[dayIndex].checked) {
            // Check if the day has existing time data
            if (primaryDetail?.regularHours?.periods?.length > 0) {
                const existingPeriods = primaryDetail?.regularHours?.periods.filter(
                    (period) => period.openDay === value.day
                );
                if (existingPeriods.length > 0) {
                    newData[dayIndex].time = existingPeriods.map((period) => ({
                        openTime: useDefaultTime
                            ? defaultTimeData?.openTime
                            : period.openTime,
                        closeTime: useDefaultTime
                            ? defaultTimeData?.closeTime
                            : period.closeTime,
                    }));
                } else {
                    newData[dayIndex].time = [
                        {
                            openTime: { hours: "10", minutes: "00" },
                            closeTime: { hours: "19", minutes: "00" },
                        },
                        // defaultTimeData,
                    ];
                }
            } else {
                newData[dayIndex].time = [
                    {
                        openTime: { hours: "10", minutes: "00" },
                        closeTime: { hours: "19", minutes: "00" },
                    },
                    // defaultTimeData,
                ];
            }
        } else {
            newData[dayIndex].time = [];
        }

        setPeriods(newData);
    };

    const createTimeData = (newValue) => ({
        hours: newValue.hour(),
        minutes: newValue.minute(),
    });
    console.log("periodsperiodsperiods<<<<<<<<<<<<<<<<<<<<<<<<", periods)
    const handleDefaultTimeChange = (type, newValue) => {
        if (useDefaultTime) {
            console.log("useDefault time here")
            if (type === "open" || type === "close") {
                const timeData = createTimeData(newValue);
                const key = type === "open" ? "openTime" : "closeTime";
                setUseDefaultTimeData({ ...defaultTimeData, [key]: timeData });
                console.log("set default time data")
            }
            console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>periods", periods)
            // setUseDefaultTimeData()
            // const updatedResult = periods.map((period) => ({
            //   ...period,
            //   time: period.time.map((timeEntry) => ({
            //     ...timeEntry,
            //     openTime:
            //       type === "open"
            //         ? { hours: newValue.hour(), minutes: newValue.minute() }
            //         : timeEntry.openTime,
            //     closeTime:
            //       type === "close"
            //         ? { hours: newValue.hour(), minutes: newValue.minute() }
            //         : timeEntry.closeTime,
            //   })),
            // }));
            const updatedResult = periods.map((period) => ({
                ...period,
                time: period.time.map((timeEntry) => {
                    const timeData = createTimeData(newValue);
                    return {
                        ...timeEntry,
                        openTime: type === "open" ? timeData : timeEntry.openTime,
                        closeTime: type === "close" ? timeData : timeEntry.closeTime,
                    };
                }),
            }));
            console.log("updatedResultupdatedResultupdatedResult", updatedResult);
            setPeriods(updatedResult);
        } else {
            const data = primaryDetail?.regularHours?.periods;
            // setPeriods(data);
            const convertedArray = data?.reduce((acc, period) => {
                // if(period.openDay)
                acc[period.openDay] = acc[period.openDay] || [];
                acc[period.openDay].push(period);
                return acc;
            }, {});
            // Convert the groupedByDay object to the desired array format
            const conertedInArray = weekDays.map((day) => ({
                day: day,
                time:
                    convertedArray[day] ||
                    [
                        // {
                        //   openTime: { hours: "00", minutes: "00" },
                        //   closeTime: { hours: "00", minutes: "00" },
                        // },
                    ],
                checked: !!convertedArray[day],
            }));
            setPeriods(conertedInArray);
            setUseDefaultTimeData({
                ...defaultTimeData,
                openTime: { hours: "00", minutes: "00" },
                closeTime: { hours: "00", minutes: "00" },
            });
        }
    };


    const handleUseDefaultTimeChange = (event, { checked }) => {
        setUseDefaultTime(checked);
        console.log(checked, "checkeddddddddddddddd");
        if (!checked) {
            setUseDefaultTimeData({
                ...defaultTimeData,
                openTime: { hours: "00", minutes: "00" },
                closeTime: { hours: "00", minutes: "00" },
            });
            if (primaryDetail?.regularHours?.periods) {
                const data = primaryDetail?.regularHours?.periods;
                // setPeriods(data);
                const convertedArray = data?.reduce((acc, period) => {
                    // if(period.openDay)
                    acc[period.openDay] = acc[period.openDay] || [];
                    acc[period.openDay].push(period);
                    return acc;
                }, {});
                // Convert the groupedByDay object to the desired array format
                const conertedInArray = weekDays.map((day) => ({
                    day: day,
                    time:
                        convertedArray[day] ||
                        [
                            // {
                            //   openTime: { hours: "00", minutes: "00" },
                            //   closeTime: { hours: "00", minutes: "00" },
                            // },
                        ],
                    checked: !!convertedArray[day],
                }));
                setPeriods(conertedInArray);
            }
        }
        // setBusinessStatus("OPEN");
        // const convertedArray = periods?.reduce((acc, period) => {
        //   // if(period.openDay)
        //   acc[period.openDay] = acc[period.openDay] || [];
        //   acc[period.openDay].push(period);
        //   return acc;
        // }, {});
        // const conertedInArray = weekDays.map((day) => ({
        //   day: day,
        //   time: convertedArray[day] || [
        //     {
        //       openTime: { hours: 0, minutes: 0 },
        //       closeTime: { hours: 0, minutes: 0 },
        //     },
        //   ],
        //   checked: !!convertedArray[day],
        // }));
        // // const updatedPeriods = periods.map((period) => ({
        // //   ...period,
        // //   openTime: { hours: 0, minutes: 0 },
        // //   closeTime: { hours: 24, minutes: 0 },
        // // }));
        // setPeriods(conertedInArray);
    };

    const updateBusinessHandler = (e) => {
        const { value } = e.target;
        setBusinessStatus(value);
        if (value === "open_full_time") {
            const convertedArray = periods?.reduce((acc, period) => {
                // if(period.openDay)
                acc[period.openDay] = acc[period.openDay] || [];
                acc[period.openDay].push(period);
                return acc;
            }, {});
            console.log("convertedArrayconvertedArrayconvertedArray", convertedArray)
            const conertedInArray = weekDays?.map((day) => ({
                day: day,
                time: convertedArray[day] || [
                    {
                        openTime: { hours: "12", minutes: 0 },
                        closeTime: { hours: "24", minutes: 0 },
                    },
                    // defaultTimeData,
                ],
                checked: true,
            }));
            console.log("conertedInArrayconertedInArrayconertedInArray", conertedInArray)
            setPeriods(conertedInArray);
        } else if (value === "OPEN") {
            const data = primaryDetail?.regularHours?.periods;
            // setPeriods(data);
            const convertedArray = data ? data?.reduce((acc, period) => {
                // if(period.openDay)
                acc[period.openDay] = acc[period.openDay] || [];
                acc[period.openDay].push(period);
                return acc;
            }, {}) : [];
            console.log("convertedArrayconvertedArrayconvertedArray", convertedArray)
            const conertedInArray = weekDays?.map((day) => ({
                day: day,
                time:
                    convertedArray[day] ||
                    [
                        {
                            openTime: { hours: "00", minutes: "00" },
                            closeTime: { hours: "00", minutes: "00" },
                        },
                    ],
                checked: true,
            }));
            console.log("conertedInArrayconertedInArrayconertedInArray", conertedInArray)
            setPeriods(conertedInArray);
        }
    };

    const handleOpenTimeChange = (day, type, newValue, index) => {
        const hours = dayjs(newValue).hour();
        const minutes = dayjs(newValue).minute();
        const newData = [...periods];
        const resultIndex = newData.findIndex((period) => period.day === day.day);
        if (resultIndex === -1) return;
        if (type === "openTime") {
            newData[resultIndex]["time"][index]["openTime"].hours = hours;
            newData[resultIndex]["time"][index]["openTime"].minutes = minutes;
        } else {
            newData[resultIndex]["time"][index]["closeTime"].hours = hours;
            newData[resultIndex]["time"][index]["closeTime"].minutes = minutes;
        }
        setPeriods(newData);
    };

    const handleDeleteClick = (day, index) => {
        // const newData = [...result];

        // const resultIndex = newData.findIndex((period) => period.day === day.day);
        // const updatedPeriods = periods.filter((period, i) => {
        //   return !(
        //     period.openDay === day &&
        //     // convertedArray[day].indexOf(period) === index &&
        //     resultIndex === index &&
        //     index !== 0
        //   );
        // });
        // setPeriods(updatedPeriods);
        // // const newData = [...result];
        // // const resultIndex = result.findIndex((weekday) => weekday.day === day.day);
        // if (resultIndex === -1) return;
        // newData[resultIndex]["time"].splice(index, 1);
        // setResult(newData);
        console.log(day, "4444444");
        const newData = [...periods];
        const dateIndex = newData.findIndex((data) => data?.day === day.day);
        console.log(dateIndex, "777777777777777777");
        if (dateIndex === -1) return;
        newData[dateIndex]["time"]?.splice(index, 1);
        setPeriods(newData);
    };

    const handleAddClick = (day) => {
        const newData = [...periods];
        const dateIndex = newData.findIndex((data) => data?.day === day.day);
        console.log(dateIndex, "777777777777777777");
        if (dateIndex === -1) return;
        newData[dateIndex]["time"].push({
            openTime: { hours: "10", minutes: "00" },
            closeTime: { hours: "19", minutes: "00" },
        });
        setPeriods(newData);
    };

    function convertHours(input) {
        console.log("inputinputinputinputinputinputinput", input)
        const result = {
            regularHours: {
                periods: [],
            },
            type: "regularHours",
            location_ids: selectedBusinessCount
        };

        input.forEach((dayEntry) => {
            if (dayEntry.checked) {
                dayEntry.time.forEach((timeEntry) => {
                    const period = {
                        openDay: timeEntry.openDay || dayEntry.day,
                        closeDay: timeEntry.closeDay || dayEntry.day,
                        openTime: timeEntry.openTime || {},
                        closeTime: timeEntry.closeTime || {},
                    };
                    result.regularHours.periods.push(period);
                });
            }
        });

        return result;
    }
    const updateOpenHourHandler = async () => {
        setUseDefaultTimeData({
            ...defaultTimeData,
            openTime: { hours: "00", minutes: "00" },
            closeTime: { hours: "00", minutes: "00" },
        });
        console.log("================================== ================================== periods", periods)
        const updatedCode = convertHours(periods);
        const restodetail = {
            business_id,
            selectReso,
        };
        console.log("open hours payload", updatedCode)
        listingUtils?.displayMessage(
            "info",
            "Listing getting updated, It may take a few minutes."
          );
        await actions.getGmbListingBulkUpdateListing(
            // { regularHours: { periods: periods }, type: "regularHours" },
            updatedCode,
            restodetail
        );
        listingUtils?.displayMessage("close");
        if (bulk_update_listing_data) {
          listingUtils?.displayMessage("positive", "updated Successfully.");
        }
        // listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
        // await actions.getGmbListingRefreshListing(business_id, selectReso);

        // listingUtils?.displayMessage("close",); // Close the "refresh process" toast

        // listingUtils?.displayMessage("positive", "Listing updated successfully.");
        // await actions.getGmbListingPrimaryDetail(business_id, selectReso);
    };


    const [selectedBusinessList, setSelectedBusinessList] = useState([]);
    const [showBusiness, setShowBusiness] = useState(true);
    const handleHideBusinessScreen = () => {
        setShowBusiness(false);
    }

    const handleShowBusinessScreen = () => {
        setShowBusiness(true);
    }

    const [selectedBusinessCount, setSelectedBusinessCount] = useState([]); // State to hold the selected businesses count
    console.log("==================================", selectedBusinessCount)

    const handleSelectedBusinessCount = (selectBusiness) => {
        setSelectedBusinessCount(selectBusiness);
    };

    return (
        <>
            {showBusiness ?
                <div>
                    <BusinessList business_id={business_id} busines_status={busines_status} type="opening_hours" queryStringData={queryStringData} selectedBusinessList={selectedBusinessList} onSelectedBusinessCountChange={handleSelectedBusinessCount} />
                    {selectedBusinessCount?.length > 0 ?
                        <div
                            className="d-flex mt-10 mb-15"
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                fontWeight: "600",
                                fontSize: "24px",
                                color: "#1A2544",
                                position: "sticky",
                                bottom: 0,
                                backgroundColor: "#fff",
                                zIndex: 1000,
                                paddingBlock: "20px"
                            }}    >
                            < FPButton
                                label="Next"
                                onClick={handleHideBusinessScreen}
                            />
                        </div>
                        : ""
                    }
                </div>
                :
                <div className="listing-container border-0 p-0">
                    <div className="listing-name">Specify your Business Hours</div>
                    <div
                        style={{
                            padding: "16px",
                            backgroundColor: "#F6F6F8",
                            borderRadius: "8px",
                        }}
                    >
                        <form action="/action_page.php">
                            <div style={{ display: "grid", gap: "15px" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        color: "#646565",
                                    }}
                                >
                                    <label
                                        htmlFor="OPEN"
                                        style={{ width: "100%", cursor: "pointer" }}
                                    >
                                        Open with Main Hours
                                    </label>
                                    <input
                                        style={{ color: "#F13A88" }}
                                        type="radio"
                                        id="OPEN"
                                        name="bussinessStatus"
                                        value="OPEN"
                                        checked={businessStatus === "OPEN"}
                                        onChange={updateBusinessHandler}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        fontWeight: "400",
                                        fontSize: "16px",
                                        color: "#646565",
                                    }}
                                >
                                    <label
                                        htmlFor="open_full_time"
                                        style={{ width: "100%", cursor: "pointer" }}
                                    >
                                        Open for 24 Hours
                                    </label>
                                    <input
                                        style={{ color: "#F13A88" }}
                                        type="radio"
                                        id="open_full_time"
                                        name="bussinessStatus"
                                        value="open_full_time"
                                        checked={businessStatus === "open_full_time"}
                                        onChange={updateBusinessHandler}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div
                        style={{
                            padding: "16px",
                            backgroundColor: "#F6F6F8",
                            borderRadius: "8px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingTop: "10px",
                            }}
                        >
                            <div className="listing-name">Set One Default Time</div>
                            <Checkbox
                                toggle
                                className="toggle-btn"
                                // checked={businessStatus !== "open_full_time"}
                                disabled={businessStatus === "open_full_time"}
                                onChange={handleUseDefaultTimeChange}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <div style={{ width: "100px" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileTimePicker
                                            disabled={
                                                businessStatus === "open_full_time" || !useDefaultTime
                                            }
                                            value={dayjs()
                                                .hour(defaultTimeData?.openTime?.hours)
                                                .minute(defaultTimeData?.openTime?.minutes)}
                                            ampm={false}
                                            openTo="hours"
                                            views={["hours", "minutes"]}
                                            format="HH:mm"
                                            onChange={(newValue) =>
                                                handleDefaultTimeChange("open", newValue)
                                            }
                                            slotProps={{ textField: { size: "small" } }}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div>-</div>
                                <div style={{ width: "100px" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileTimePicker
                                            disabled={
                                                businessStatus === "open_full_time" || !useDefaultTime
                                            }
                                            value={dayjs()
                                                .hour(defaultTimeData?.closeTime?.hours)
                                                .minute(defaultTimeData?.closeTime?.minutes)}
                                            minTime={dayjs().hour(defaultTimeData?.openTime?.hours)
                                                .minute(defaultTimeData?.openTime?.minutes)}

                                            ampm={false}
                                            openTo="hours"
                                            views={["hours", "minutes"]}
                                            format="HH:mm"
                                            onChange={(newValue) =>
                                                handleDefaultTimeChange("close", newValue)
                                            }
                                            slotProps={{ textField: { size: "small" } }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <FPButton
                                    label="Update"
                                    size="small"
                                    onClick={updateOpenHourHandler}
                                    disabled={
                                        primaryDetail?.recommendationReason ===
                                        "BUSINESS_LOCATION_SUSPENDED"
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            padding: "16px",
                            backgroundColor: "#F6F6F8",
                            borderRadius: "8px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                        }}
                    >
                        <div style={{ paddingTop: "10px" }}>
                            <div className="listing-name">Specify your Business Hours</div>
                        </div>
                        {periods?.length > 0 &&
                            periods?.map((openDay, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            border: "1px solid",
                                            borderRadius: "5px",
                                            borderColor: "#BFC2CB",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "start",
                                                padding: "10px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Checkbox
                                                    toggle
                                                    className="toggle-btn"
                                                    checked={openDay?.checked}
                                                    disabled={businessStatus === "open_full_time"}
                                                    onClick={(event) => handleDaySwitch(event, openDay)}
                                                />
                                                <span>{openDay?.day}</span>
                                            </div>
                                            <div
                                                style={{ display: "flex", flexFlow: "column", gap: "12px" }}
                                            >
                                                {openDay?.time?.map((openDay_values, idx) => {
                                                    const openDate = dayjs()
                                                        .hour(
                                                            openDay_values?.openTime?.hours
                                                                ? openDay_values?.openTime?.hours
                                                                : "12"
                                                        )
                                                        .minute(
                                                            openDay_values?.openTime?.minutes
                                                                ? openDay_values?.openTime?.minutes
                                                                : "00"
                                                        );
                                                    const closeDate = dayjs()
                                                        .hour(
                                                            openDay_values?.closeTime?.hours
                                                                ? openDay_values?.closeTime?.hours
                                                                : "12"
                                                        )
                                                        .minute(
                                                            openDay_values?.closeTime?.minutes
                                                                ? openDay_values?.closeTime?.minutes
                                                                : "00"
                                                        );
                                                    return (
                                                        <div
                                                            key={idx}
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: "10px",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <div style={{ width: "100px" }}>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <MobileTimePicker
                                                                        ampm={false}
                                                                        disabled={businessStatus === "open_full_time"}
                                                                        value={openDate}
                                                                        views={["hours", "minutes"]}
                                                                        format="HH:mm"
                                                                        slotProps={{ textField: { size: "small" } }}
                                                                        onChange={(newValue) =>
                                                                            handleOpenTimeChange(
                                                                                openDay,
                                                                                "openTime",
                                                                                newValue,
                                                                                idx
                                                                            )
                                                                        }
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                            <div>-</div>
                                                            <div style={{ width: "100px" }}>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <MobileTimePicker
                                                                        ampm={false}
                                                                        disabled={businessStatus === "open_full_time"}
                                                                        value={closeDate}
                                                                        views={["hours", "minutes"]}
                                                                        format="HH:mm"
                                                                        onChange={(newValue) =>
                                                                            handleOpenTimeChange(
                                                                                openDay,
                                                                                "closeTime",
                                                                                newValue,
                                                                                idx
                                                                            )
                                                                        }
                                                                        slotProps={{ textField: { size: "small" } }}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    gap: "2px",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                {idx === openDay?.time?.length - 1 ? (
                                                                    <>
                                                                        {businessStatus !== "open_full_time" && (
                                                                            <AddIcon
                                                                                style={{
                                                                                    color: "#667085",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => handleAddClick(openDay)}
                                                                            />
                                                                        )}
                                                                        {openDay?.time?.length > 1 && (
                                                                            <img
                                                                                src={delete_png}
                                                                                alt="deletePng"
                                                                                style={{
                                                                                    height: "16px",
                                                                                    width: "16px",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleDeleteClick(openDay, idx)
                                                                                }
                                                                            />
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <img
                                                                        src={delete_png}
                                                                        alt="deletePng"
                                                                        style={{
                                                                            height: "16px",
                                                                            width: "16px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => handleDeleteClick(openDay, idx)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            }
        </>
    )
}

// export default UpdateOpenHour
const mapStateToProps = (state) => {
    return {
        bulk_update_listing_data: state.gmbListingLocation.bulk_update_listing_data,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOpenHour);
