import React from 'react';
import { Col, Row } from 'reactstrap';
import SetupLoginSocialAccounts from "../../components/listing/setupLoginGmailAccount"

class ComingSoonPage extends React.Component {
  // constructor(props) {
  //   super(props);
    
  // }

  render() {
    
    return (
      <Row className="content-area dashboard-page">
        <Col xs={12} sm={12} md={12} className="business-setup py-4">
          <h1>Coming Soon </h1>
          <SetupLoginSocialAccounts />
        </Col>
      </Row>
    );
  }
}

export default ComingSoonPage;