/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { VictoryChart, VictoryAxis, VictoryBar, VictoryTheme } from "victory";
import CustomStarRatingComponent from "../common/StarComponent";
import { startRatingColors } from "../../libs/constant";
import Chartwrapper from "../../modules/dashboard/component/Chartwrapper";
import LoaderComponent from "../common/loaderComponent";
import { getReviewSummary } from "../../actions/overview/OverviewActions";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";

import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import CommentIcon from "../../images/svg/CommentIcon";
import { starRatingColor } from "../../libs/utils";
import * as utils from "../../libs/utils";
import cookie from "react-cookies";
import { Skeleton } from "@mui/material";

const ReviewSummary = (props) => {
  const { data, avg, total, businessId, dispatch, ratingData, loader } = props;
  const [isLoading, setIsLoading] = useState(loader);

  const filteredData = data
    ? data.map((entry, index) =>
        index === 0
          ? { x: entry.stars, y: 0 }
          : { x: entry.stars, y: entry.ratings }
      )
    : [];

  const updateData = async (datePayload) => {
    setIsLoading(() => true);
    await dispatch(getReviewSummary(businessId, datePayload));
    setIsLoading(() => false);
  };

  const chartRef = useRef(null);
  // const legendRef = useRef(null);

  const [chartDimension, setChartDimension] = useState({ height: 0, width: 0 });

  useEffect(() => {
    setTimeout(() => {
      if (chartRef && chartRef.current) {
        const { offsetHeight, offsetWidth } = chartRef.current;
        setChartDimension({ height: offsetHeight, width: offsetWidth });
      }
    }, 1000);
  }, [chartRef]);

  const {
    star_border_color: dining_border_color,
    star_bg_color: dining_bg_color,
  } = starRatingColor(+ratingData?.dining?.rating_avg || 0);
  const {
    star_border_color: delivery_border_color,
    star_bg_color: delivery_bg_color,
  } = starRatingColor(+ratingData?.delivery?.rating_avg || 0);

  const service_category = cookie.load("service_category");
  const isRestaurant = service_category && service_category === "Restaurant";

  return (
    <section style={{ flexGrow: 1 }} className="d-flex flex-column">
      {isRestaurant && (
        <CardWrapper showDivider={false} className="mb-4" widthElevation={true}>
          {loader ? (
            <div className="align-center dining_delivery">
              {[1, 2].map((_, index) => (
                <Box
                  key={index}
                  className="w-50 py-2"
                  sx={{ padding: index === 0 ? "0 10px 0 0" : "0 0 0 10px" }}
                >
                  <div
                    className="align-center justify-content-center flex-column"
                    style={{ gap: "2.5px" }}
                  >
                    <Skeleton width="40%" height={35} />
                    <Skeleton width="40%" height={35} />
                    <Skeleton width="40%" height={35} />
                  </div>
                </Box>
              ))}
            </div>
          ) : (
            <div className="align-center dining_delivery">
              <Box className="w-50 py-2" sx={{ paddingRight: "10px" }}>
                <Box
                  component="p"
                  sx={{
                    color: "#646565",
                    fontSize: "17px",
                    textAlign: "center",
                  }}
                >
                  Dining
                </Box>
                <div className="align-center justify-content-center gap-15 flex-column">
                  <div className="rating-tag gap-6 py-2 px-3">
                    <CommentIcon style={{ height: "12px", width: "12px" }} />
                    <span
                      className="review-info"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {ratingData?.dining?.review_count}
                    </span>
                  </div>
                  <span
                    className="location-tag py-0 px-3"
                    style={{
                      borderColor: dining_border_color,
                      backgroundColor: dining_bg_color,
                      fontSize: "12px",
                    }}
                  >
                    <CustomStarRatingComponent
                      name="locationRating"
                      starCount={1}
                      style={{ fontSize: "16px" }}
                      value={
                        Number(ratingData?.dining?.rating_avg.toFixed(1)) || 0
                      }
                      editing={false}
                    />
                    {Number(ratingData?.dining?.rating_avg.toFixed(1)) || 0}
                  </span>
                </div>
              </Box>
              <Box className="w-50 py-2" sx={{ paddingLeft: "10px" }}>
                <Box
                  component="p"
                  sx={{
                    color: "#646565",
                    fontSize: "17px",
                    textAlign: "center",
                  }}
                >
                  Delivery
                </Box>
                <div className="align-center justify-content-center gap-15 flex-column">
                  <div className="rating-tag gap-6 py-2 px-3">
                    <CommentIcon style={{ height: "12px", width: "12px" }} />
                    <span
                      className="review-info"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {ratingData?.delivery?.review_count}
                    </span>
                  </div>

                  <span
                    className="location-tag py-0 px-3"
                    style={{
                      borderColor: delivery_border_color,
                      backgroundColor: delivery_bg_color,
                      fontSize: "12px",
                    }}
                  >
                    <CustomStarRatingComponent
                      name="locationRating"
                      starCount={1}
                      style={{ fontSize: "16px" }}
                      value={Number(ratingData?.delivery?.rating_avg) || 0}
                      editing={false}
                    />
                    {Number(ratingData?.delivery?.rating_avg || 0).toFixed(1)}
                  </span>
                </div>
              </Box>
            </div>
          )}
        </CardWrapper>
      )}
      <section style={{ height: "auto", flexGrow: 1 }} ref={chartRef}>
        <Chartwrapper
          headerTitle="Review Summary"
          className="fp-dashboard-chart-card h-100"
          onDateChange={updateData}
          widthElevation={true}
          skeletonLoader={loader}
        >
          <div className="fp-dashboard-reviewSummary">
            {loader ? (
              <div className="fp-dashboard-reviewSummary">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Skeleton width="80%" height={35} />
                  <Skeleton width="30%" height={35} />
                  <Skeleton width="40%" height={35} />
                  <Skeleton width="50%" height={35} />
                  <Skeleton width="20%" height={35} />
                  <Skeleton width="60%" height={35} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Skeleton width="45%" height={40} />
                    <Skeleton width="45%" height={40} />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <VictoryChart
                  domainPadding={13}
                  height={
                    isRestaurant
                      ? chartDimension.height - 55
                      : chartDimension.height - 60
                  }
                  width={chartDimension.width - 30}
                >
                  <VictoryBar
                    horizontal
                    cornerRadius={{
                      top: isRestaurant ? 11 : 13,
                    }}
                    barRatio={isRestaurant ? 0.8 : 0.6}
                    data={filteredData}
                    labels={({ datum }) =>
                      datum.x === 0
                        ? `${
                            (data && data[0].ratings) || 0
                          } reviews are with no ratings.`
                        : datum.y
                    }
                    style={{
                      data: {
                        fill: ({ datum }) =>
                          datum.x === 0
                            ? "white"
                            : startRatingColors[datum.x - 1],
                      },
                      labels: { fill: "var(--secondary-blue-gray, #7A8193)" },
                    }}
                  />
                  <VictoryAxis
                    tickValues={[0, 1, 2, 3, 4, 5]}
                    theme={VictoryTheme.material}
                    tickFormat={(x) => `${x}`}
                    style={{
                      tickLabels: {
                        fill: "var(--secondary-blue-gray, #7A8193)",
                      },
                    }}
                  />
                </VictoryChart>
                <div className="d-flex gap-20 mt-3 mt-md-0">
                  <section className="fp-card-outline w-50 p-2 m-auto">
                    {/* <p
                    className="dashboard_sub_caption"
                    style={{ fontSize: '16px' }}
                  >
                    Average Rating
                  </p> */}
                    <div className="align-center justify-content-between flex-wrap">
                      <CustomStarRatingComponent
                        name="famepilot-review"
                        starCount={5}
                        value={avg ? avg : 0}
                        editing={false}
                        className="star-spacing"
                      />
                      <Box className="dash-text" sx={{ fontSize: "16px" }}>
                        {avg ? avg : 0}
                      </Box>
                    </div>
                  </section>
                  <section className="fp-card-outline w-50 p-2 align-center justify-content-between m-auto flex-wrap">
                    <Box
                      className="dashboard_sub_caption"
                      style={{ fontSize: "14px" }}
                    >
                      Reviews
                    </Box>
                    <Box className="dash-text" style={{ fontSize: "16px" }}>
                      {total ? total : 0}
                    </Box>
                  </section>
                </div>
              </div>
            )}
          </div>
        </Chartwrapper>
      </section>
    </section>
  );
};

export default connect()(ReviewSummary);
