import * as types from "../../actions/actionTypes";

export default function salesPageReducer(state=[], action) {
  switch (action.type) {
    case types.GET_SALES_TREND_OVERTIME_SUCCESS:
      return Object.assign({}, state, { salesOvertime: action.data });
    case types.GET_SALES_TREND_OVERTIME_FAILURE:
      return Object.assign({}, state, { salesOvertime: {}});
    
    case types.GET_SALES_TREND_LOCATION_SUCCESS:
        return Object.assign( {}, state, { salesLocation: action.data });
    case types.GET_SALES_TREND_LOCATION_FAILURE:
        return Object.assign({}, state, { salesLocation: {}});
    
    case types.GET_ORDERS_TREND_OVERTIME_SUCCESS:
      return Object.assign({}, state, { ordersOvertime: action.data });
    case types.GET_ORDERS_TREND_OVERTIME_FAILURE:
      return Object.assign({}, state, { ordersOvertime: {}});
    
    case types.GET_ORDERS_TREND_LOCATION_SUCCESS:
        return Object.assign({}, state, { ordersLocation: action.data });
    case types.GET_ORDERS_TREND_LOCATION_FAILURE:
        return Object.assign({}, state, { ordersLocation: {}});
    
    // case types.GET_DIETARY_TABLE_OVERTIME_SUCCESS:
    //   return Object.assign({}, state, { dietaryTableOvertime: action.data });
    // case types.GET_DIETARY_TABLE_OVERTIME_FAILURE:
    //   return Object.assign({}, state, { dietaryTableOvertime: []});
    
    default:
      return state;
  }
}