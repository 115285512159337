import React from 'react';

const FaceFillFive = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16Z"
        fill="#F2A94D"
        stroke="#F2A94D"
        stroke-width="2"
      />
      <path
        d="M8.75533 23.3515C8.78528 23.5268 8.90428 23.6799 9.0818 23.7713C9.39275 23.9315 9.79776 23.8716 10.0241 23.6318C11.9287 21.614 13.9356 20.5982 15.9895 20.6078C19.3792 20.6273 21.9341 23.4648 21.9587 23.4926C22.1741 23.7385 22.5777 23.806 22.8973 23.6497C23.0807 23.5601 23.2062 23.4074 23.2417 23.2308C23.2741 23.0701 23.2276 22.9038 23.1142 22.7745C23.0842 22.7403 22.3651 21.9274 21.1536 21.1121C19.5154 20.0096 17.7387 19.4212 16.0157 19.4103C15.9431 19.4098 15.871 19.4104 15.7986 19.4118C13.3534 19.4627 11.0333 20.6329 8.90286 22.8901C8.78099 23.0191 8.72729 23.1873 8.75533 23.3515Z"
        fill="#1B1C1D"
      />
      <path
        d="M9.99307 14.5925C11.2319 14.5925 12.2361 13.8325 12.2361 12.8949C12.2361 11.9573 11.2319 11.1973 9.99307 11.1973C8.75426 11.1973 7.75 11.9573 7.75 12.8949C7.75 13.8325 8.75426 14.5925 9.99307 14.5925Z"
        fill="#1B1C1D"
      />
      <path
        d="M22.0067 14.5456C23.2456 14.5456 24.2498 13.7856 24.2498 12.848C24.2498 11.9104 23.2456 11.1504 22.0067 11.1504C20.7679 11.1504 19.7637 11.9104 19.7637 12.848C19.7637 13.7856 20.7679 14.5456 22.0067 14.5456Z"
        fill="#1B1C1D"
      />
      <path
        d="M24.7661 9.54756H19.2474C18.9123 9.54756 18.6406 9.27589 18.6406 8.94077C18.6406 8.60566 18.9123 8.33398 19.2474 8.33398H24.7661C25.1012 8.33398 25.3729 8.60566 25.3729 8.94077C25.3729 9.27589 25.1012 9.54756 24.7661 9.54756Z"
        fill="#1B1C1D"
      />
      <path
        d="M12.7543 9.54756H7.2357C6.90059 9.54756 6.62891 9.27589 6.62891 8.94077C6.62891 8.60566 6.90059 8.33398 7.2357 8.33398H12.7543C13.0895 8.33398 13.3611 8.60566 13.3611 8.94077C13.3611 9.27589 13.0895 9.54756 12.7543 9.54756Z"
        fill="#1B1C1D"
      />
    </svg>
  );
};
export default FaceFillFive;