import React, { useState, useEffect } from "react";
import { Table } from "antd";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import queryString from "query-string";
import MenuPerformanceModal from "./MenuPerformanceModal";
import FormModal from "../common/FormModal";
import { downloadCsvHandler } from "../../actions/business/reviewsActions";
import { FPToolTip } from "../common/new";

const MenuPerformanceList = (props) => {
  const [sortData, setSortData] = useState([]);
  const {
    isCategoryWise,
    isLocationWise,
    isSubCategory,
    isNonVeg,
    hideItemName,
    menu_performance,
    businessId,
    location,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [reviewsData, setReviewData] = useState(null);
  const [modalHeader, setModalHeader] = useState("");
  const [modalSubHeader, setModalSubHeader] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [downloadParams, setDownloadParams] = useState("");
  const [urlParam, setUrlParam] = useState("");

  const loadData = () => {
    const newData = menu_performance.map((value, index) => {
      return { key: index, ...value };
    });
    setSortData(newData || []);
  };

  useEffect(() => {
    loadData();
  }, [menu_performance]);

  const onChange = (filters, sorter, extra) => {
    console.log("params", filters, sorter, extra);
  };

  const handleOnCell = (record, rowIndex, stars) => {
    return {
      onClick: (event) => {
        event.preventDefault();
        setReviewData(null);
        setIsOpen(true);
        setModalLoading(true);

        const {
          provider: provider_id,
          branch: location_id,
          ...rest
        } = { ...queryString.parse(location.search) };
        const { ...url_params } = { ...rest, provider_id, location_id };

        let str_param = "";
        Object.keys(url_params).map((val) => {
          if (url_params[val]) {
            if (str_param === "") str_param = val + "=" + url_params[val];
            else str_param = str_param + "&" + val + "=" + url_params[val];
          }
        });
        setModalSubHeader(stars);
        setModalHeader(record.Item_name);

        const main_params = `${
          record.Item_name ? "&item_name=" + record.Item_name : ""
        }${stars ? "&stars=" + stars : ""}${
          record.SubCategory ? "&sub_category=" + record.SubCategory : ""
        }${record.Category ? "&category=" + record.Category : ""}${
          record["Veg/Non-Veg"] ? "&veg_nonveg=" + record["Veg/Non-Veg"] : ""
        }${record.location ? "&location=" + record.Location : ""}`;

        setDownloadParams(main_params);
        setUrlParam(str_param);

        axios_instance
          .get(
            utils.format(
              `${URLS.INSIGHT_MENU_MODAL}?${str_param}${main_params}&page=1`,
              [businessId]
            )
          )
          .then((res) => {
            setReviewData(res.data);
            setModalLoading(false);
          })
          .catch((err) => {
            setModalLoading(false);
            console.log(err);
          });
      },
    };
  };
  const handlePageClick = (selectedPage) => {
    setModalLoading(true);
    axios_instance
      .get(
        utils.format(
          `${URLS.INSIGHT_MENU_MODAL}?${urlParam}${downloadParams}&page=${selectedPage.currentPage}&page_size=${selectedPage.perPage}`,
          [businessId]
        )
      )
      .then((res) => {
        setReviewData(res.data);
        setModalLoading(false);
      })
      .catch((err) => {
        setModalLoading(false);
        console.log(err);
      });
  };
  const handleDownload = () => {
    const baseUrl = utils.format(
      `${URLS.INSIGHT_MENU_MODAL_DOWNLOAD}?${urlParam}${downloadParams}`,
      [businessId]
    );
    downloadCsvHandler(baseUrl, "famepilot-menu-Reviews-report.csv");
  };

  const getColumns = () => {
    const baseColumns = [
      {
        title: "1 Star",
        dataIndex: "1_star",
        sorter: { compare: (a, b) => b["1_star"] - a["1_star"], multiple: 3 },
        align: "center",
        onCell: (record, rowIndex) => handleOnCell(record, rowIndex, 1),
        render: (text) => (
          <span
            className="menu-perform-star"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {text}
          </span>
        ),
      },
      {
        title: "2 Star",
        dataIndex: "2_star",
        sorter: { compare: (a, b) => b["2_star"] - a["2_star"], multiple: 3 },
        align: "center",
        onCell: (record, rowIndex) => handleOnCell(record, rowIndex, 2),
        render: (text) => (
          <span
            className="menu-perform-star"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {text}
          </span>
        ),
      },
      {
        title: "3 Star",
        dataIndex: "3_star",
        sorter: { compare: (a, b) => b["3_star"] - a["3_star"], multiple: 3 },
        align: "center",
        onCell: (record, rowIndex) => handleOnCell(record, rowIndex, 3),
        render: (text) => (
          <span
            className="menu-perform-star"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {text}
          </span>
        ),
      },
      {
        title: "4 Star",
        dataIndex: "4_star",
        sorter: { compare: (a, b) => b["4_star"] - a["4_star"], multiple: 3 },
        align: "center",
        onCell: (record, rowIndex) => handleOnCell(record, rowIndex, 4),
        render: (text) => (
          <span
            className="menu-perform-star"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {text}
          </span>
        ),
      },
      {
        title: "5 Star",
        dataIndex: "5_star",
        sorter: { compare: (a, b) => b["5_star"] - a["5_star"], multiple: 3 },
        align: "center",
        onCell: (record, rowIndex) => handleOnCell(record, rowIndex, 5),
        render: (text) => (
          <span
            className="menu-perform-star"
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {text}
          </span>
        ),
      },
      {
        title: "Average Rating",
        dataIndex: "average_rating",
        sorter: {
          compare: (a, b) => b["average_rating"] - a["average_rating"],
        },
        align: "center",
      },
      {
        title: "Ai Attribute",
        dataIndex: "ai_attribute",
        sorter: {
          compare: (a, b) =>
            a.ai_attribute ? a.ai_attribute.localeCompare(b.ai_attribute) : 0,
          multiple: 3,
        },
        render: (text) => (
          <FPToolTip title={text}>
            <span className="two-line">{text}</span>
          </FPToolTip>
        ),
      },
    ];

    if (!hideItemName) {
      baseColumns.unshift({
        title: "Item name",
        dataIndex: "Item_name",
        sorter: {
          compare: (a, b) =>
            a.Item_name ? a.Item_name.localeCompare(b.Item_name) : 0,
        },
        onCell: (record, rowIndex) => handleOnCell(record, rowIndex),
        render: (text) => (
          <FPToolTip title={text}>
            <span
              style={{
                cursor: "pointer",
                fontWeight: "500",
              }}
              className="d-inline-block text-truncate width-sm templateName"
            >
              {text}
            </span>
          </FPToolTip>
        ),
      });
    }

    if (isLocationWise) {
      baseColumns.unshift({
        title: "Locations",
        dataIndex: "Location",
        sorter: {
          compare: (a, b) =>
            a.Location ? a.Location.localeCompare(b.Location) : 0,
        },
        render: (text) => (
          <FPToolTip title={text}>
            <span className="d-inline-block text-truncate width-sm">
              {text}
            </span>
          </FPToolTip>
        ),
      });
    }

    if (isCategoryWise) {
      const index =
        isLocationWise && !hideItemName
          ? 2
          : !hideItemName || isLocationWise
          ? 1
          : 0;
      baseColumns.splice(index, 0, {
        title: "Category",
        dataIndex: "Category",
        align: "center",
        sorter: {
          compare: (a, b) =>
            a.Category ? a.Category.localeCompare(b.Category) : 0,
          multiple: 3,
        },
      });
    }

    if (isSubCategory) {
      const index =
        isLocationWise && !hideItemName && isCategoryWise
          ? 3
          : (isLocationWise && !hideItemName) ||
            (isLocationWise && isCategoryWise) ||
            (!hideItemName && isCategoryWise)
          ? 2
          : isLocationWise || !hideItemName || isCategoryWise
          ? 1
          : 0;
      baseColumns.splice(index, 0, {
        title: "Sub Category",
        dataIndex: "SubCategory",
        align: "center",
        sorter: {
          compare: (a, b) =>
            a.SubCategory ? a.SubCategory.localeCompare(b.SubCategory) : 0,
          multiple: 3,
        },
      });
    }

    if (isNonVeg) {
      const index = baseColumns.length - 7;
      baseColumns.splice(index, 0, {
        title: "Veg/Non-Veg",
        dataIndex: "Veg/Non-Veg",
        align: "center",
        sorter: {
          compare: (a, b) =>
            a["Veg/Non-Veg"]
              ? a["Veg/Non-Veg"].localeCompare(b["Veg/Non-Veg"])
              : 0,
          multiple: 3,
        },
      });
    }

    return baseColumns;
  };

  return (
    <div className="m-3">
      <Table
        columns={getColumns()}
        dataSource={sortData}
        onChange={onChange}
        pagination={false}
        showSorterTooltip={false}
      />
      <FormModal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        heading={
          <div className="font-blue" style={{ fontSize: "24px" }}>
            {modalSubHeader ? `${modalSubHeader} Star Reviews` : "All Reviews"}
            {modalHeader && (
              <span className="font-blue-gray">({modalHeader})</span>
            )}
          </div>
        }
        width={"800px"}
        showDivider={false}
      >
        <MenuPerformanceModal
          record={reviewsData && reviewsData}
          location={location}
          modalLoading={modalLoading}
          handleDownload={handleDownload}
          handlePageClick={handlePageClick}
        />
      </FormModal>
    </div>
  );
};

export default MenuPerformanceList;
