import React from "react";
import { Icon, Label } from "semantic-ui-react";

import CustomStarRatingComponent from "../common/StarComponent";
import { trendsDateType } from "../../libs/utils";
import { Box } from "@material-ui/core";

class ThirdPartyReview extends React.Component {
  render() {
    const {
      data,
      trendsData,
      filters: { date_type },
    } = this.props;
    const perc_third_avg_rating = trendsData && trendsData.perc_third_avg_rating;
    const perc_third_review_count = trendsData && trendsData.perc_third_review_count;
    const reviewSites = { ...data };
    delete reviewSites.avg;
    delete reviewSites.social_total_count;
    delete reviewSites.since_joining_count;

    return (
      <section>
        <div className="align-center p-0">
          <div className="w-50">
            <Box Component="span" className="dash-text d " sx={{ fontSize: "20px", marginBottom: "4px" }}>
              {(data && data.avg && data.avg.toFixed(2)) || 0}
            </Box>
            <CustomStarRatingComponent
              name="famepilot-review"
              starCount={5}
              className="fp-size-18"
              value={data && data.avg ? data.avg.toFixed(2) : 0}
              editing={false}
            />
          </div>
          <div className="w-50">
            {perc_third_avg_rating !== undefined && (
              <Label basic color={perc_third_avg_rating < 0 ? "red" : "green"} style={{ borderWidth: 0 }}>
                {perc_third_avg_rating == 0 ? null : (
                  <Icon name={perc_third_avg_rating < 0 ? "long arrow alternate down" : "long arrow alternate up"} />
                )}
                {`${perc_third_avg_rating && perc_third_avg_rating.toFixed(2)}% `}
                <span style={{ color: "#777777" }}>Vs {trendsDateType(date_type)}</span>
              </Label>
            )}
            <Box className="dashboard_caption" sx={{ fontSize: "20px", marginBottom: "4px" }}>
              {(data && data.social_total_count) || 0}
            </Box>
            <Box className="dashboard_sub_caption mt-1">Total Reviews</Box>

            {/* <div>
            {perc_third_review_count !== undefined && (
              <Label
                basic
                color={perc_third_review_count < 0 ? 'red' : 'green'}
                style={{ borderWidth: 0 }}
              >
                {perc_third_review_count == 0 ? null : (
                  <Icon
                    name={
                      perc_third_review_count < 0
                        ? 'long arrow alternate down'
                        : 'long arrow alternate up'
                    }
                  />
                )}
                {`${
                  perc_third_review_count && perc_third_review_count.toFixed(2)
                }% `}
                <span style={{ color: '#777777' }}>
                  Vs {trendsDateType(date_type)}
                </span>
              </Label>
            )}
            <p className='dashboard_caption'>{data && data.since_joining_count}</p>
            <p className='dashboard_sub_caption'>Since Joining</p>
          </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default ThirdPartyReview;
