import * as types from "../../actions/actionTypes";

export default function departmentReducer(state = [], action) {
  switch (action.type) {
    // case types.CREATE_DEPARTMENT_SUCCESS:
    //   return Object.assign({}, state, { detail: { ...action.data } });
    // case types.CREATE_DEPARTMENT_FAILURE:
    //   return Object.assign({}, state, { detail: { ...action.data } });
    case types.GET_DEPARTMENT_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.GET_DEPARTMENT_FAILURE:
      return Object.assign({}, state, { detail: { ...action.data } });
    // case types.EDIT_DEPARTMENT_SUCCESS:
    //   return Object.assign({}, state, { detail: { ...action.data } });
    // case types.EDIT_DEPARTMENT_FAILURE:
    //   return Object.assign({}, state, { detail: { ...action.data } });
    // case types.DELETE_DEPARTMENT_SUCCESS:
    //   return Object.assign({}, state, { detail: { ...action.data } });
    // case types.DELETE_DEPARTMENT_FAILURE:
    //   return Object.assign({}, state, { detail: {} });
    default:
      return state;
  }
}
