import { Box } from "@material-ui/core";
import FormAutoComplete from "../../../../components/common/new/forms/FormAutoSelect";

const FeedbackAutoComplete = ({
  options,
  placeholder,
  className,
  name,
  sx = {},
  ...props
}) => {
  return (
    <Box
      component="div"
      className="form-group mb-0"
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none !important",
        },
        "& .MuiFormControl-root": {
          borderRadius: "10px !important",
        },
        "& .MuiFormControl-root ": {
          backgroundColor: "#F6F6F8 !important",
        },
        ...sx,
      }}
    >
      <FormAutoComplete
        options={options}
        placeholder={placeholder}
        name={name}
        {...props}
      />
    </Box>
  );
};

export default FeedbackAutoComplete;
