import { Avatar, Box } from '@material-ui/core'
import React from 'react'
import UserImg from "../../../images/user-icon.png";
import { FPToolTip } from '../../../components/common/new';
import FPChip from '../../../components/common/new/FPChip/FPChip';

function ChatHeader({ selectedChat }) {
  const chipTextComponents = (location, PNumber) => {
    return (
      <div className="chip-text-container">
        {location && (
          <>
            <FPToolTip title={location}>
              <Box component="p" className="chip-location">
                {location}
              </Box>
            </FPToolTip>
            <div className="divider" />
          </>
        )}
        <Box component="p" className="chip-number">
          {PNumber}
        </Box>
      </div>
    );
  };
    
  return (
    <div className="header-chat-header">
        <div className='d-flex align-center gap-10'>

      <Avatar alt="profile picture" src={UserImg} className="header-user-avatar" />
      {selectedChat.user_name && (
        <FPToolTip style={{width: "105px"}} title={selectedChat.user_name}>
          <Box component="p" className="user-name">
            {selectedChat.user_name}
          </Box>
        </FPToolTip>
      )}
      { (selectedChat.user_name && selectedChat.user_contact) && <div className="divider" />}
      {selectedChat.user_contact && (
            <Box component="p" className="user-mobile" style={!selectedChat.user_name ? { color: "#1B1C1D", fontSize: "16px"} : {}}>
            {selectedChat.user_contact}
          </Box>
          )}

        </div>

      {/* <FPChip key={selectedChat?.contact_id} className="header-message-chip" label={chipTextComponents(selectedChat.location, selectedChat.user_contact)} /> */}
    </div>
  );
}

export default ChatHeader;