/* eslint-disable */
import React from "react";
import moment from "moment";

import { Row, Col } from "reactstrap";
import PaginationComponent from "../common/Pagination";
import SearchComponent from "../../components/common/SearchComponent";
import RootHeader from "../../modules/campaignsRoot/RootHeader";
import Header from "../../modules/campaignsRoot/campaigns/Header";
import "./campaignTemplate.css";
import { FPButton, FPToolTip } from "../common/new";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import SortIcon from "../../images/svg/Sort.svg";
import DeleteIcon from "../../images/svg/trash.svg";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { Checkbox } from "semantic-ui-react";
// import FileCopyIcon from '../../images/fileCopyIcon.png';
import { sortChangeColor } from "../../libs/utils";

class CampaignList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedLists: [],
      resetSelectedLists: false,
    };
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleResetSelectedLists = (value) => {
    this.setState({ resetSelectedLists: value });
  };

  handleCheckbox(event, data) {
    const { list } = this.props;
    let contacts = [...this.state.selectedLists];
    if (data.checked && data.id === "all") contacts = contacts.concat(list.results.map((item) => item.id));
    else if (!data.checked && data.id === "all") {
      contacts = contacts.filter((id) => !list.results.some((obj) => obj.id === id));
    } else if (data.checked && contacts.indexOf(data.id) < 0) contacts.push(data.id);
    else {
      const index = contacts.indexOf(data.id);
      if (index > -1) contacts.splice(index, 1);
    }
    this.setState({ selectedLists: contacts });
    this.handleResetSelectedLists && this.handleResetSelectedLists(false);
  }

  getDefaultTab() {
    switch (true) {
      case location.pathname.includes("/campaigns/review/list"):
        return 0;
      case location.pathname.includes("/campaigns/news-letter/list"):
        return 1;
      default:
        break;
    }
  }

  render() {
    const {
      list,
      deleteCampaign,
      goToAddCampaign,
      viewInvitedMembers,
      isCampaignNewsLetter,
      location,
      ordering,
      sortFunction,
      gotoGuestReviewInvite,
      user,
      pageSizeCookie,
      goToManageRecurringCampaign,
      // cloneCampaign,
      generic,
      showAction = true,
    } = this.props;
    const { selectedLists } = this.state;
    const sort =
      ordering && ordering[0] === "-" ? { on: ordering.slice(1), by: "descending" } : { on: ordering, by: "ascending" };
    return (
      <Row>
        <div className="w-100">
          <RootHeader defaultValue={0} history={this.props.history} />
          <Col className="d-flex justify-content-between align-items-center mb-4">
            <Header defaultValue={this.getDefaultTab()} history={this.props.history} />
            <section>
              <FPButton
                onClick={() => goToManageRecurringCampaign()}
                label="Manage Recurring Campaign"
                className="mr-2"
                backgroundColor="#1A2544"
                size="small"
                fontSize="12px"
              />
              {user && !user.single_location && !isCampaignNewsLetter && (
                <FPButton
                  onClick={() => gotoGuestReviewInvite()}
                  label="Guest Review Invite"
                  className="mr-2"
                  backgroundColor="#1A2544"
                  size="small"
                  fontSize="12px"
                />
              )}
              <FPButton
                onClick={() => goToAddCampaign()}
                startIcon={<AddOutlinedIcon style={{ width: 16, height: 16 }} />}
                label="Create Campaign"
                backgroundColor="#1A2544"
                size="small"
                fontSize="12px"
              />
            </section>
          </Col>
        </div>
        <Col sm="12">
          <CardWrapper
            contentClass="p-3"
            headerTitle={
              <div className="d-flex justify-content-between align-items-center">
                <span>Manage Campaign</span>
                <SearchComponent {...this.props} placeholder="Search Campaign" className="common-search" startIcon />
              </div>
            }
          >
            <div className="ui-table-responsive">
              <Table className="fp-table fp-table-center">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        id="all"
                        checked={
                          selectedLists &&
                          list?.results?.length > 0 &&
                          list.results.every((obj) => selectedLists.some((id) => obj.id === id))
                        }
                        onChange={this.handleCheckbox}
                      >
                        Select All
                      </Checkbox>
                    </TableCell>
                    <TableCell className="width-md-th" style={{ textAlign: "justify" }}>
                      Campaign Name
                    </TableCell>
                    <TableCell>Template</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Created by</TableCell>
                    <TableCell>Method</TableCell>
                    {!isCampaignNewsLetter && <TableCell>Status</TableCell>}
                    <TableCell
                      className="text-nowrap"
                      onClick={() => sortFunction("created_at")}
                      style={sort.on === "created_at" ? sortChangeColor("#1f9095", "#d9f0f0") : null}
                    >
                      Created on
                      {sort && sort.on === "created_at" && <SortIcon />}
                    </TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list &&
                    list.results &&
                    list.results.map((item, key) => {
                      return (
                        <TableRow key={key}>
                          <TableCell>
                            <Checkbox
                              id={item.id}
                              checked={selectedLists && selectedLists.indexOf(item.id) > -1}
                              onChange={this.handleCheckbox}
                            />
                          </TableCell>
                          <TableCell className="width-md" onClick={() => viewInvitedMembers(item)}>
                            <FPToolTip title={item.name} className="templateName">
                              <div
                                className=""
                                style={{
                                  width: "160px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textAlign: "justify",
                                }}
                              >
                                {item.name}
                              </div>
                            </FPToolTip>
                          </TableCell>
                          <TableCell>{item.template_name}</TableCell>
                          <TableCell>{item.type}</TableCell>
                          <TableCell>{item.created_by}</TableCell>
                          <TableCell>{item.method}</TableCell>
                          {!isCampaignNewsLetter && (
                            <TableCell>
                              {item.feedback_count}/{item.invite_count}
                            </TableCell>
                          )}
                          <TableCell onClick={() => sortFunction("created_at")}>
                            <span style={sort.on === "created_at" ? sortChangeColor("#1f9095") : null}>
                              {moment(item.created_at).format("DD-MM-YYYY")}
                            </span>
                          </TableCell>
                          <TableCell>
                            {!generic && (
                              <React.Fragment>
                                {/* <FPToolTip title={'Clone'}>
                              <img src={FileCopyIcon} onClick={() => cloneCampaign(item)} style={{cursor:'pointer', marginRight: '10px'}} />
                            </FPToolTip> */}

                                {!item.is_default && (
                                  <FPToolTip title={"Delete"}>
                                    <img src={DeleteIcon} onClick={() => deleteCampaign(item)} role="button" />
                                  </FPToolTip>
                                )}
                              </React.Fragment>
                            )}
                          </TableCell>
                          {/* <TableCell>
                        <FPToolTip title={'Delete'}><img src={DeleteIcon} onClick={() => deleteCampaign(item)} role='button' /></FPToolTip>
                      </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {list && list.results && !list.results.length && (
                    <TableRow>
                      <TableCell className="text-center" colSpan={7}>
                        No records Found{" "}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <PaginationComponent
              count={list && list.count}
              location={location}
              pageSizeCookie={pageSizeCookie}
              className="pb-0"
            />
          </CardWrapper>
        </Col>
      </Row>
    );
  }
}

export default CampaignList;
