import React from "react";
import CommentIcon from "../../../../images/svg/CommentIcon";
import CustomStarRatingComponent from "../../StarComponent";
import { setDecimalIndex } from "../../../../libs/utils";

const SliderCard = (props) => {
  const { data, redirectTo } = props;
  return (
    <div className="sliderCardItem">
      <div className="sliderImage cursor-pointer" onClick={redirectTo}>
        <img style={{ marginBottom: "4px" }} width={45} src={data.icon} alt="icon"/>
        <p style={{whiteSpace: 'nowrap'}}>{data.name}</p>
      </div>
      <div className="slider-tag-wrapper">
        <div className="rating-tag">
          <CommentIcon />
          <span className="review-info">{data.count}</span>
        </div>
        <div className="rating-tag">
          <CustomStarRatingComponent
            name="provider-review-rating"
            starCount={1}
            style={{ fontSize: "16px" }}
            value={setDecimalIndex(data.avg) || "1"}
            editing={false}
          />
          <span className="review-info">{setDecimalIndex(data.avg) || 0}</span>
        </div>
      </div>
    </div>
  );
};

export default SliderCard;
