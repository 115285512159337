/* eslint-disable */
import React, { useState } from "react";
import moment from "moment";
import { Icon, Card, Divider, Button } from "semantic-ui-react";
import DefaultUserIcon from "../../images/boy.png";
import fpIcon from "../../images/fp-icon.png";
import { IoLocationOutline } from "react-icons/io5";
import ImagesViewer from "react-images-viewer";
import loading from "../../images/Hourglass.png";
import { FPAvatar, FPBadge, FPButton, MessageBox } from "../common/new";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import order from "../../images/svg/like_trangle.svg";
import close from "../../images/svg/close.svg";
import { Box } from "@material-ui/core";
import "./complaints.css";
import location from "../../images/svg/LocationColor.svg";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import FormModal from "../common/FormModal";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { Input, Radio } from "antd";
import axios_instance from "../../libs/interseptor";
// import * as utils from '../../libs/utils';
import * as URLS from "../../libs/apiUrls";
import cookie from "react-cookies";
import { displayMessage } from "../../libs/utils";
import { Spin } from "antd";
import LoaderComponent from "../messenger/Common/LoaderComponent";
import queryString from "query-string";
import OrderDetailsModal from "./OrderDetailsModal";
import * as utils from "../../libs/utils";
import CustomerHistoryLog from "../common/ComplainCustomerHistoryLog/CustomerHistoryLog";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import MediaViewer from "../common/MediaViewer";
import FPChip from "../common/new/FPChip/FPChip";

const ComplaintAnswer = ({
  complaint,
  maxImage = 3,
  complaintOpen,
  isLoader,
  getData,
  search,
  businessId,
}) => {
  let images = [];
  const IMG_SET = [];
  for (var i = 0; i < complaint.extra_data.complaint_images.length; i++) {
    images.push(complaint.extra_data.complaint_images[i]);
  }

  let temp = images;

  const [isAllRefund, setIsAllRefund] = useState(false);
  const [isCustomRefund, setIsCustomRefund] = useState(false);
  const [isNoRefund, setIsNoRefund] = useState(false);
  const [customAmount, setCustomAmount] = useState(null);
  const [errCustomInput, setErrCustomInput] = useState(null);
  const [radioNoRefund, setRadioNoRefund] = useState(null);
  const [noRefundMessage, setNoRefundMessage] = useState(undefined);
  const [radioButtonData, setRadioButtonData] = useState({});
  const [loadingNoRefund, setLoadingNoRund] = useState(false);
  const [orderPopup, setOrderPopup] = useState(false);
  const [isOrderDetailFetching, setIsOrderDetailFetching] = useState(false);
  const [orderDetailData, setOrderDetailData] = useState(null);
  const user = cookie.load("user");

  const image_object = [];
  images?.map((item, idx) => {
    let obj = {
      id: `${idx + 1}`,
      count: `${idx + 1}`,
      target_link: item,
    };
    let objImg = {
      src: item,
    };
    image_object.push(obj);
    IMG_SET.push(objImg);
  });

  let len = Object.keys(complaint).length;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [error, setError] = useState(false);

  const handleImageError = () => {
    setError(true);
  };
  const handleImage = () => {
    setViewerIsOpen(true);
  };

  const gotoPrevImg = () => {
    setCurrentImageIndex(currentImageIndex - 1);
  };

  const gotoNextImg = () => {
    setCurrentImageIndex(currentImageIndex + 1);
  };

  const closeImgsViewer = () => {
    setViewerIsOpen(false);
  };

  const handleAllRefund = (complaint) => {
    const payload = {
      refund_type: "FULL_REFUND",
      complaint_id: complaint.id,
      provider_id: complaint?.provider_id,
    };
    axios_instance
      .post(`${URLS.REFUND_PROCESS}`, payload)
      .then((res) => {
        displayMessage("positive", res.data?.status);
        complaintOpen(complaint);
        setIsAllRefund(!isAllRefund);
        getData({ ...queryString.parse(search), businessId });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCustomRefund = (complaint) => {
    const payload = {
      refund_type: "CUSTOM_REFUND",
      complaint_id: complaint.id,
      custom_amt: customAmount,
      provider_id: complaint?.provider_id,
    };
    axios_instance
      .post(`${URLS.REFUND_PROCESS}`, payload)
      .then((res) => {
        displayMessage("positive", res.data?.status);
        complaintOpen(complaint);
        setIsCustomRefund(!isCustomRefund);
        getData({ ...queryString.parse(search), businessId });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNoRefund = (complaint) => {
    const payload = {
      refund_type: "NO_REFUND",
      complaint_id: complaint.id,
      refund_category: 80010,
      refund_message: radioNoRefund + " : " + noRefundMessage,
      provider_id: complaint?.provider_id,
    };
    axios_instance
      .post(`${URLS.REFUND_PROCESS}`, payload)
      .then((res) => {
        displayMessage("positive", res.data?.status);
        complaintOpen(complaint);
        setIsNoRefund(!isNoRefund);
        getData({ ...queryString.parse(search), businessId });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCustomAmount = (event) => {
    setCustomAmount(event.target.value);
    if (
      complaint.provider === "swiggy" &&
      event.target.value < (complaint.source_suggest_amt * 25) / 100
    ) {
      setErrCustomInput(
        `Swiggy allows custom refund of min 25% of Refundable Amount which is INR ${
          (complaint.source_suggest_amt * 25) / 100
        }`
      );
    } else if (isNaN(event.target.value)) {
      setErrCustomInput("Please select a valid input in numbers");
    } else if (event.target.value <= 15) {
      setErrCustomInput("Amount entered should be greater than 15 Rupees");
    } else {
      setErrCustomInput(null);
    }
  };

  const handleNoRefundClick = (complaint) => {
    setRadioNoRefund(null);
    setRadioButtonData({});
    setNoRefundMessage(undefined);
    setIsNoRefund(!isNoRefund);
    setLoadingNoRund(true);
    axios_instance
      .get(
        `${URLS.REFUND_PROCESS}?refund_type=NO_REFUND&complaint_id=${complaint.id}&provider_id=${complaint?.provider_id}`
      )
      .then((res) => {
        setLoadingNoRund(false);
        setRadioButtonData(res.data.result["80010"]);
      })
      .catch((err) => {
        setLoadingNoRund(false);
        console.log(err);
      });
  };

  const handleOrderDetails = (order_id) => {
    setOrderPopup(true);
    setIsOrderDetailFetching(true);
    axios_instance
      .get(
        `${utils.format(URLS.MENU_COMPLAINT_DETAILS, [
          businessId,
        ])}?order_id=${order_id}`
      )
      .then((res) => {
        setOrderDetailData(res.data);
        setIsOrderDetailFetching(false);
      })
      .catch((err) => {
        console.log(err);
        setOrderDetailData([]);
        setIsOrderDetailFetching(false);
      });
  };

  const toggleOrderDetailsModel = () => {
    setOrderPopup(false);
    setOrderDetailData(null);
  };

  const content_media = [
    ...(complaint?.extra_data?.complaint_images || []).filter(
      (item) => typeof item === "string" && item.trim() !== ""
    ),
    ...(complaint?.extra_data?.complaint_videos || []).filter(
      (item) => typeof item === "string" && item.trim() !== ""
    ),
  ];


const getStatusColor = (status) => ({
  New: "#228B22",
  Repeat: "#f8ae00",
  Lapsed: "#e16b16",
}[status]);

  return (
    len > 0 && (
      <>
        {isLoader ? (
          <div className="mt-4">
            <LoaderComponent showLoader={isLoader} />
          </div>
        ) : (
          <>
            <Box
              className=""
              sx={{
                border: "0.5px solid #BFC2CB",
                borderRadius: "6px",
                backgroundColor: "white",
              }}
            >
              <Box
                className="d-flex justify-content-between p-3"
                sx={{
                  backgroundColor: "#FAFAFB",
                  borderRadius: "8px 8px 0px 0px",
                }}
              >
                <div>
                  <Box
                    component="span"
                    sx={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#F04438",
                    }}
                  >
                    {complaint.complaint_type
                      ? complaint.complaint_type.replace(/_/g, " ")
                      : "Complaint"}
                  </Box>
                  <Box sx={{ fontSize: "13px", lineHeight: "1.6" }}>
                    {complaint.extra_data && (
                      <span style={{ fontWeight: "", color: "#8B8D8B" }}>
                        <span style={{ color: "#7A8193" }}>
                          Order ID:{" "}
                          <span
                            onClick={() =>
                              handleOrderDetails(complaint?.extra_data.order_id)
                            }
                            style={{ color: "#2375EF", cursor: "pointer" }}
                          >
                            {complaint?.extra_data.order_id
                              ? complaint?.extra_data.order_id
                              : ""}
                          </span>
                        </span>
                        {complaint.complaint_create_date && (
                            <span className="d-flex gap-5" style={{fontSize:"11px"}}>
                            <span>Complaint Create Date - </span>
                              {moment(complaint.complaint_create_date).format(
                                "D MMMM YYYY, hh:mm A "
                              )}{" "}
                            </span>
                        )}
                        {complaint.complaint_pull_date && (
                            <span className="d-flex gap-5" style={{fontSize:"11px"}}>
                             <span>Complaint Pull Date -{" "}</span>
                              {moment(complaint.complaint_pull_date).format(
                                "D MMMM YYYY, hh:mm A "
                              )}{" "}
                            </span>
                        )}
                      </span>
                    )}
                  </Box>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <span
                      style={{
                        display: "flex",
                        padding: "2px 10px",
                        borderRadius: "50px",
                        fontSize: "12px",
                        border: complaint.is_resolved
                          ? "2px solid #21BA45"
                          : "2px solid #ff4d4f",
                        color: complaint.is_resolved ? "#21BA45" : "#ff4d4f",
                        marginTop: "1px",
                        fontWeight: "600",
                      }}
                    >
                      {complaint.is_resolved ? "Resolve" : "Open"}
                    </span>
                  </div>

                  {complaint.provider && (
                    <span>
                      <img
                        src={
                          complaint.provider === "zomato"
                            ? "https://api.famepilot.com/static/images/zomato.png"
                            : complaint.provider === "swiggy"
                            ? "https://api.famepilot.com/static/images/swiggy.png"
                            : fpIcon
                        }
                        alt=""
                        height="24"
                        width="24"
                        onClick={() => {
                          complaint.reviewer && complaint.reviewer.profile_url
                            ? window.open(
                                complaint.reviewer.profile_url,
                                "_blank"
                              )
                            : null;
                        }}
                        style={{ margin: "0px" }}
                        className="cursor-pointer"
                      ></img>
                    </span>
                  )}
                </div>
              </Box>

              <Box className="p-3" sx={{ backgroundColor: "" }}>
                {complaint?.reviewer && (
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                      {complaint?.reviewer.profile_img_url &&
                      complaint?.reviewer.profile_img_url !== "" ? (
                        <img
                          src={complaint?.reviewer.profile_img_url}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                          alt="profile"
                        />
                      ) : (
                        <img
                          src={DefaultUserIcon}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                          alt="profile"
                        ></img>
                      )}

                      <div className="post-info" style={{ paddingLeft: "8px" }}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center", // Vertically align items
                              gap: "10px", // Optional: Add space between elements
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "500",
                                fontSize: "15px",
                                color: "#1B1C1D",
                              }}
                            >
                              {complaint?.reviewer?.name || ""}
                            </span>
                            {complaint?.reviewer?.customer_segmentation && (
                              <p
                                style={{
                                  color: "#1B1C1D",
                                  fontSize: "12px",
                                  backgroundColor: "#DDEFF0",
                                  border: "1px solid #438E96",
                                  padding: "1px 12px",
                                  borderRadius: "50px",
                                  margin: "0 5px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {utils.capitalizeWords(complaint?.reviewer?.customer_segmentation)}
                              </p>
                            )}{complaint?.reviewer?.customer_type && (
                            <FPChip
                              size="small"
                              variant="outlined"
                              className="high-value-assign-box-fp-chip"
                              label={
                                <div className="d-flex align-items-center justify-content-center">
                                  <FPBadge color={getStatusColor(utils.capitalizeWords(complaint?.reviewer?.customer_type))} size="11" />
                                  &nbsp;&nbsp;
                                  <span className="chip-text">{utils.capitalizeWords(complaint?.reviewer?.customer_type)}</span>
                                </div>
                              }
                            />)}
                          </div>

                          {complaint?.branch && (
                            <Box
                              className="d-flex gap-6 align-items-center"
                              sx={{
                                color: "#7A8193",
                                fontSize: "12px",
                                lineHeight: "2",
                              }}
                            >
                              <img src={location} />
                              {complaint?.branch}
                            </Box>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-10">
                      <FPButton
                        label="Full Refund"
                        type="button"
                        style={{ height: "25px" }}
                        backgroundColor="#21BA45"
                        onClick={() => setIsAllRefund(!isAllRefund)}
                        fontWeight="400"
                        fontSize="12px"
                        padding="0px 8px"
                      />

                      <FPButton
                        label="Custom Refund"
                        type="button"
                        backgroundColor={"#1890ff"}
                        style={{ height: "25px" }}
                        onClick={() => {
                          setCustomAmount(null);
                          setErrCustomInput(null);
                          setIsCustomRefund(!isCustomRefund);
                        }}
                        fontWeight="400"
                        fontSize="12px"
                        padding="0px 8px"
                      />
                      <FPButton
                        label="No Refund"
                        type="button"
                        backgroundColor={"#ff0000bd"}
                        style={{ height: "25px" }}
                        onClick={() => handleNoRefundClick(complaint)}
                        fontWeight="400"
                        fontSize="12px"
                        padding="0px 8px"
                      />
                    </div>
                  </div>
                )}

                <Box className="mt-2">
                  {complaint?.complaint_text &&
                    complaint?.complaint_text.trim() !== "" && (
                      <MessageBox isSelfBox={false} className={"mw-100"}>
                        <span className="fp-size-14">
                          {complaint?.complaint_text.trim()}
                        </span>
                      </MessageBox>
                    )}
                </Box>

                {content_media && (
                  <div className="d-flex align-items-center position-relative mt-3 mb-3">
                    <div className="d-flex flex-column align-items-center position-relative">
                      <div
                        className="attachment-preview-container"
                        style={{ opacity: error ? 0.5 : 1, display: "flex" }}
                      >
                        {content_media?.slice(0, 3)?.map((media, index) => {
                          const isVideo =
                            media?.endsWith(".mp4") || media?.endsWith(".webm");
                          return (
                            <div
                              className="complaints-attachment-preview"
                              style={{
                                marginLeft:
                                  index === 1 || index === 2 ? "-20px" : "",
                                zIndex:
                                  complaint?.extra_data.complaint_images
                                    .length - index,
                                position: "relative",
                              }}
                              key={index}
                            >
                              {isVideo ? (
                                <div
                                  style={{
                                    width: 70,
                                    height: 70,
                                    borderRadius: 35,
                                    overflow: "hidden",
                                    position: "relative",
                                  }}
                                  onClick={() => handleImage(index)}
                                >
                                  <video
                                    src={media}
                                    onError={handleImageError}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <PlayCircleIcon
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      fontSize: 30,
                                      color: "white",
                                    }}
                                  />
                                </div>
                              ) : (
                                <img
                                  onError={handleImageError}
                                  src={error ? DefaultUserIcon : media}
                                  style={{
                                    width: 70,
                                    height: 70,
                                    borderRadius: 35,
                                  }}
                                  onClick={() => handleImage(index)}
                                  alt={`attachment-${index}`}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {content_media?.length > 3 && (
                        <h6
                          style={{
                            position: "absolute",
                            top: "27px",
                            left: "180px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {complaint?.extra_data?.complaint_videos?.length > 0
                            ? `+${content_media?.length - 3} Photos & video`
                            : `+${content_media?.length - 3} Photos`}
                        </h6>
                      )}
                    </div>
                    {error && (
                      <p
                        style={{
                          fontSize: "15px",
                          color: "#1B1C1D",
                          marginLeft: "15px",
                        }}
                      >
                        <img
                          src={loading}
                          style={{
                            marginRight: "10px",
                            background: "#FEF0C7",
                            width: "23px",
                            height: "22px",
                            borderRadius: "20px",
                          }}
                        />
                        The source image links have been expired
                      </p>
                    )}

                    {/* {viewerIsOpen && (
                      <ImagesViewer
                        imgs={IMG_SET}
                        thumbnail={IMG_SET}
                        showThumbnails={true}
                        isOpen={viewerIsOpen}
                        currImg={currentImageIndex}
                        onClickPrev={gotoPrevImg}
                        onClickNext={gotoNextImg}
                        onClose={closeImgsViewer}
                        showImgCount={false}
                        theme={{}}
                        backdropCloseable={true}
                        width={1024}
                      />
                    )} */}
                  </div>
                )}
                {
                  <div
                    className="mt-4 mb-2"
                    style={{
                      color: "gray",
                      fontSize: "14px",
                      padding: "8px 10px",
                      backgroundColor: "#F2F9F9",
                      borderRadius: "10px",
                      flexDirection: "column",
                    }}
                  >
                    {complaint?.source_suggest_amt &&
                      !complaint?.is_resolved && (
                        <div>
                          Refundable Amount is &nbsp; &#8377;{" "}
                          {complaint?.source_suggest_amt}
                        </div>
                      )}
                    {complaint?.refund_processed && (
                      <div>
                        Actual Processed Refund: &#8377;{" "}
                        {complaint?.refund_processed || 0}
                      </div>
                    )}
                    {complaint?.is_resolved && (
                      <div>
                        {complaint?.refund_text ? complaint?.refund_text : ""}{" "}
                        {complaint?.refunded_by &&
                          (complaint?.refund_text
                            ? "by : "
                            : "Refund by : ")}{" "}
                        {complaint?.refunded_by}
                      </div>
                    )}
                    {complaint?.provider === "swiggy" && (
                      <div
                        className="mt-2"
                        style={{ color: "#12B76A", fontSize: "12px" }}
                      >
                        *Swiggy allows custom refund of min 25% of Refundable
                        Amount which is {" "}
                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: "13px",
                          }}
                        >
                          &#8377; {(complaint?.source_suggest_amt * 25) / 100}
                        </span>
                      </div>
                    )}
                  </div>
                }
                {complaint?.extra_data[complaint?.complaint_type] && (
                  <div
                    className="mt-4 mb-2 d-flex gap-10 flex-wrap"
                    style={{
                      color: "gray",
                      fontSize: "14px",
                      borderRadius: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "10.5px",
                        color: "#D92D20",
                        marginBottom: 0,
                        backgroundColor: "#FECDCA",
                        borderRadius: "50px",
                        padding: "2px 6px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {complaint?.complaint_type
                        ? `${complaint?.complaint_type.replace(/_/g, " ")}`
                        : "Complaint"}
                    </p>
                    {complaint?.extra_data[complaint?.complaint_type]
                      .flat()
                      .map((item, index) => (
                        <p
                          key={index}
                          style={{
                            marginBottom: 0,
                            border: "1px solid #F04438",
                            backgroundColor: "#FEF3F2",
                            borderRadius: "50px",
                            padding: "1px 6px",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "10.5px",
                          }}
                        >
                          {item}
                        </p>
                      ))}
                  </div>
                )}
                {/* {!complaint.is_resolved &&
              ((user.zomato_complaint_button && complaint.provider === "zomato") ||
                (user.swiggy_complaint_button && complaint.provider === "swiggy")) && ( */}
                {/* )} */}
                <Divider />
                <div className="d-flex gap-15 align-items-start">
                  <div className="orderItem text-nowrap">
                    <img
                      src={order}
                      style={{ height: "15px", width: "15px" }}
                    />
                    Ordered Items
                  </div>
                  <div className="d-flex gap-10 flex-wrap">
                    {complaint?.order_details &&
                      Array.isArray(complaint?.order_details) &&
                      complaint?.order_details?.map((order, index) => {
                        return (
                          <>
                            <div
                              key={`${order.ordered_product_name}&${index}`}
                              className="orderedItem"
                            >
                              {order.ordered_product_quantity && (
                                <>
                                  {order.ordered_product_quantity}
                                  <img
                                    src={close}
                                    style={{ height: "6px", width: "6px" }}
                                  />
                                  {order.ordered_product_name
                                    ? order.ordered_product_name
                                    : ""}
                                </>
                              )}
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </Box>
              {complaint?.reviewer?.customer_history?.sub_content?.length >
                0 && (
                <CustomerHistoryLog
                  logs={complaint?.reviewer?.customer_history?.sub_content}
                />
              )}
            </Box>
            <ConfirmationModal
              header="Are you sure!"
              style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
              subHeader={
                "Are you sure you want to give full refund for selected order. This action cannot be undone."
              }
              isOpen={isAllRefund}
              toggle={() => setIsAllRefund(!isAllRefund)}
              onConfirm={() => handleAllRefund(complaint)}
              success={true}
              buttonName={"Refund"}
            />

            <FormModal
              isOpen={isCustomRefund}
              toggle={() => setIsCustomRefund(!isCustomRefund)}
              heading={"Custom refund"}
              width="600px"
              contentClass="mb-0"
              bodyClass="mb-0"
            >
              <Input
                size="large"
                placeholder="Enter Refund Amount"
                value={customAmount}
                onChange={(event) => handleCustomAmount(event)}
                prefix={<FaIndianRupeeSign />}
              />
              {errCustomInput && (
                <div className="mt-2 font-size-12" style={{ color: "red" }}>
                  {errCustomInput}
                </div>
              )}
              <div
                className="my-4 d-flex justify-content-between align-item-start mb-3 w-100"
                style={{ gap: "12px" }}
              >
                <Button
                  className="w-50"
                  style={{
                    borderRadius: "8px",
                    width: "170px",
                    background: "none",
                    border: "2px solid #BFC2CB",
                    color: "#1B1C1D",
                  }}
                  type="submit"
                  color="black"
                  onClick={() => setIsCustomRefund(!isCustomRefund)}
                >
                  Cancel
                </Button>
                <Button
                  className="w-50 green"
                  style={{ borderRadius: "8px", width: "170px" }}
                  type="submit"
                  disabled={
                    !customAmount ||
                    isNaN(customAmount) ||
                    (isNaN(customAmount) && customAmount <= 15)
                  }
                  onClick={() => handleCustomRefund(complaint)}
                >
                  {" "}
                  Submit
                </Button>
              </div>
            </FormModal>

            {complaint.provider === "swiggy" ? (
              <ConfirmationModal
                header="Are you sure!"
                style={{ fontWeight: 600, fontSize: "18px" }}
                subHeader={
                  "Are you sure you want to give No refund for selected order. This action cannot be undone."
                }
                isOpen={isNoRefund}
                toggle={() => setIsNoRefund(!isNoRefund)}
                onConfirm={() => handleNoRefund(complaint)}
                success={false}
                buttonName={"No Refund"}
              />
            ) : (
              <FormModal
                isOpen={isNoRefund}
                toggle={() => setIsNoRefund(!isNoRefund)}
                heading={"No refund"}
                width="600px"
                contentClass="mb-0"
                bodyClass="mb-0"
              >
                {loadingNoRefund ? (
                  <div
                    className="mb-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Spin
                      size="large"
                      tip="Loading"
                      checked={loadingNoRefund}
                    />
                  </div>
                ) : (
                  Object.keys(radioButtonData)?.map((value) => {
                    return (
                      <div
                        className="mt-2"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="radio"
                            id={radioButtonData[value]}
                            name={"radio-no-refund"}
                            value={radioButtonData[value]}
                            onChange={(event) => {
                              setRadioNoRefund(event.target.value);
                              setNoRefundMessage(undefined);
                            }}
                          />
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "black",
                            }}
                          >
                            {radioButtonData[value]}
                          </span>
                        </div>
                        {radioButtonData[value] === radioNoRefund && (
                          <textarea
                            className="my-2 mx-4"
                            placeholder="Please enter reason for chosen action"
                            value={noRefundMessage}
                            style={{
                              height: "100px",
                              padding: "8px 16px",
                              border: "2px solid #ccc",
                              borderRadius: "6px",
                              backgroundColor: "#f8f8f8",
                              fontSize: "16px",
                              resize: "none",
                            }}
                            onChange={(event) =>
                              setNoRefundMessage(event.target.value)
                            }
                          />
                        )}
                      </div>
                    );
                  })
                )}
                <div
                  className="my-4 d-flex justify-content-between align-item-start mb-3 w-100"
                  style={{ gap: "12px" }}
                >
                  <Button
                    className="w-50"
                    style={{
                      borderRadius: "8px",
                      width: "170px",
                      background: "none",
                      border: "2px solid #BFC2CB",
                      color: "#1B1C1D",
                    }}
                    type="submit"
                    color="black"
                    onClick={() => setIsNoRefund(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="w-50 green"
                    style={{ borderRadius: "8px", width: "170px" }}
                    type="submit"
                    disabled={
                      !noRefundMessage ||
                      (noRefundMessage && noRefundMessage.trim().length <= 0) ||
                      !radioNoRefund
                    }
                    onClick={() => handleNoRefund(complaint)}
                  >
                    Submit
                  </Button>
                </div>
              </FormModal>
            )}

            <FormModal
              isOpen={viewerIsOpen}
              toggle={() => closeImgsViewer()}
              footer={null}
              width="1000px"
              height="80%"
              paddingDense={true}
              showCloseBtn={false}
              bodyClass={"p-0"}
              contentClass={"m-0 h-100"}
            >
              <MediaViewer mediaList={content_media} />
            </FormModal>

              <OrderDetailsModal
                open={orderPopup}
                toggle={toggleOrderDetailsModel}
                orderDetailData={orderDetailData}
                isOrderDetailFetching={isOrderDetailFetching}
              />
          </>
        )}
      </>
    )
  );
};

export default ComplaintAnswer;
