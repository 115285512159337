import React from "react";
import queryString from "query-string";
import classnames from "classnames";
import tagsvg from "../../images/svg/Tag.svg";
import "../../containers/business/mention.css";
import { Box } from "@material-ui/core";
import { VariableSizeList as List } from "react-window";

const ManageTags = ({
  tagsListing,
  toggleTagEditModal,
  toggleConfirm,
  reviewsPage,
  handleTagFilter,
  deleteTag,
  location: { search },
  bodyClass = false
}) => {
  const query = queryString.parse(search);
  const selectedTags = query.tag?.split(",");

  const handleEditClick = (tag) => toggleTagEditModal(tag);
  const handleDeleteClick = (tag) =>
    toggleConfirm(() => deleteTag(tag));

  const Row = ({ index, style }) => {
    const tag = tagsListing[index];
    const isSelected = selectedTags?.includes(tag.name);

    return (
      <div
        key={tag.id}
        style={style}
        className={`${bodyClass ? "mention-tag" : "filter-tags"}  d-flex justify-content-between align-items-center pr-3`}
      >
        <div
          className={classnames("d-flex gap-10", {
            "selected-tag": isSelected,
          })}
          style={{ color: reviewsPage ? "#1B1C1D" : "#F13A88" }}
        >
          <img src={tagsvg} alt="Tag Icon" />
          <div className="manageTagModelText">{tag.name}</div>
        </div>

        <div className="d-flex gap-30">
          <div
            role="button"
            className="manageTagModelText"
            onClick={() => handleEditClick(tag)}
          >
            Edit
          </div>
          <Box
            component="span"
            className="manageTagModelText"
            role="button"
            onClick={() => handleDeleteClick(tag)}
            sx={{ color: "#F04438" }}
          >
            Delete
          </Box>
        </div>
      </div>
    );
  };

  const calculateTextHeight = (text, width, fontSize = 16, lineHeight = 1.5) => {
    const tempDiv = document.createElement("div");
    Object.assign(tempDiv.style, {
      position: "absolute",
      visibility: "hidden",
      width: `${width}px`,
      fontSize: `${fontSize}px`,
      lineHeight: `${lineHeight}`,
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    });
    tempDiv.innerText = text?.name;
    document.body.appendChild(tempDiv);
    const height = tempDiv.offsetHeight;
    document.body.removeChild(tempDiv);
    return height;
  };

  const getItemSize = (index) => {
    const content = tagsListing[index];
    const listWidth = 500;
    return Math.max(40, Math.min(calculateTextHeight(content, listWidth), 200));
  };

  return (
    <div className="d-flex flex-column gap-15">
      {tagsListing?.length > 0 ? (
        <List
          height={525}
          itemCount={tagsListing.length}
          itemSize={getItemSize}
          width="100%"
          style={{ overflowY: "visible" }}
        >
          {Row}
        </List>
      ) : (
        <div className="no-tags" style={{ marginTop: "-24px", textAlign: "center" }}>
          <span>No tags found</span>
        </div>
      )}
    </div>
  );
};

export default ManageTags;
