import React from "react";
import moment from "moment";
import { Row, Col, Card } from "reactstrap";
import Highlighter from "react-highlight-words";
import {
  Divider,
  Icon,
  Image,
  Popup,
  Input,
  Dropdown,
} from "semantic-ui-react";
import ReactPlayer from "react-player";
import queryString from "query-string";
import instagram from "../../images/instagram-text.png";
import facebookText from '../../images/facebook-text-logo.png';
import classnames from "classnames";
import * as utils from "../../libs/utils";
import { CSSTransitionGroup } from "react-transition-group";
import FormModal from "../common/FormModal";
import ReplyTweetForm from "./ReplyTweetForm";
import cookie from "react-cookies";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import { Avatar, Box } from "@material-ui/core";
import "../../containers/business/mention.css";
import thumbicon from "../../images/svg/thumbpink.svg";
import messageChat from "../../images/svg/message-chat.svg";
import eyeIcon from "../../images/svg/eyes.svg";
import whatsapp from "../../images/svg/whatsapp.svg";
import twitter from "../../images/svg/twitter.svg";
import facebook from "../../images/svg/facebook.svg";
import copy from "../../images/svg/copy.svg";
import replyleft from "../../images/svg/Reply.svg";
import deleteicon from "../../images/svg/delete.svg";
import tagSvg from "../../images/svg/tag white.svg";
import crossClose from "../../images/svg/close.svg";
import replyforward from "../../images/svg/replay-forward2.svg";
import { FPButton, FPMenuBox, FPToolTip, NoData } from "../common/new";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import UserImg from "../../images/user-icon.png";
import ConfirmationModal from "../common/ConfirmationModal";
import AutoSelect from "../common/AutoSelect";
import AddNewTagModel from "./AddNewTagModel";
import AssignNewTagModel from "./AssignNewTagModel";
import TicketingTagsModal from "../tickets/TicketingTagsModal";

const FilterIcons = {
  all: { icon: "fas fa-globe-asia", text: "all" },
  social: { icon: "fas fa-users", text: "social" },
  blogs: { icon: "fas fa-rss", text: "blog" },
  news: { icon: "far fa-newspaper", text: "news" },
  web: { icon: "fas fa-share-alt", text: "web" },
  pinterest: { icon: "far fa-user", text: "pinterest" },
  quora: { icon: "far fa-user", text: "quora" },
};

const labelColors = {
  Positive: "#12B76A",
  Negative: "#F04438",
  Neutral: "#FDB022",
};
const shareLinks = {
  facebook: "https://www.facebook.com/sharer/sharer.php?u=",
  twitter: "https://twitter.com/intent/tweet?url=",
  whatsapp: "https://web.whatsapp.com/send?text=",
};

class MentionsDashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isopen: false,
      tagValue: "",
      relevantTags: [],
      error: null,
      playingId: null,
      selectedMention: null,
      isReplyTweetOpen: false,
      addNewTagModel: false,
      assignTagModal: false,
      tagSelectedItem: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { tags } = this.props;
    const { tags: prevTags } = prevProps;

    if (prevTags.length !== tags.length)
      this.setState({ relevantTags: [...tags] });
    else {
      for (let i = 0; prevTags && i < prevTags.length; i++) {
        if (tags[i].name !== prevTags[i].name) {
          this.setState({ relevantTags: [...tags] });
          break;
        }
      }
    }
  }

  // componentDidMount() {
  //   const { tags } = this.props;
  //   tags.length && this.setState({ relevantTags: [...tags] });
  // }

  handleShareButton = (e, data) => {
    if (shareLinks[data.type])
      window.open(`${shareLinks[data.type]}${data.linkToCopy}`, "_blank");
    else
      utils.displayMessage(
        "negative",
        "You dont have a link associated with this provider"
      );
  };

  handleLinkCopy = (e, link) => {
    utils.copyToClipboard(link);
  };

  handleTagChange = (e) => {
    const { tags } = this.props;
    const tagValue = e.target.value;
    this.setState({ tagValue, error: null });
    const newRelevantTags = tags.length
      ? tags.filter((tag) => {
          return tag.name.includes(tagValue);
        })
      : [];
    this.setState({ relevantTags: [...newRelevantTags] });
  };

  countFunction = (itemsCount) => {
    const countSimplified =
      itemsCount > 1000
        ? itemsCount > 1000000
          ? Math.trunc(itemsCount / 1000000) + "M"
          : Math.trunc(itemsCount / 1000) + "K"
        : itemsCount;
    return countSimplified;
  };

  // addTag = (review_id) => {
  //   const { tagValue } = this.state;
  //   if (tagValue) this.props.postTag({ name: tagValue });
  //   else this.setState({ error: "Please enter a tag name before submitting!" });
  // };

  addTag = async (review_id) => {
    const { tagValue } = this.state;
  
    if (tagValue.length > 254) {
      this.setState({
        error: "Ensure tag name has no more than 254 characters.",
      });
      return;
    }
  
    if (!tagValue) {
      this.setState({ error: "Please enter a tag name before submitting!" });
      return;
    }
  
    try {
      const { postTag } = this.props;
      const res = await postTag({ name: tagValue });
      if (res?.data) {
        await postTag({
          tag_name: res.data.name,
          object_id: review_id,
          tag_id: res.data.id,
          tagMethod: "add_tags/",
        });
        this.setState({ addNewTagModel: false });
        if (res && res.includes(res?.data?.tag_name)) return null;
      } else {
        this.setState({ error: "" });
      }
    } catch (error) {
      console.error("Error adding tag:", error);
      this.setState({ error: "An error occurred while adding the tag." });
    }
  };

  postTagOnReview = (data, tags) => {
    const { postTag } = this.props;
    if (tags && tags.includes(data.tag_name)) return null;
    else postTag(data);
    this.setState({ addNewTagModel: false });
  };

  handleOnPlay = (id) => {
    this.setState({ playingId: id });
  };

  handleEngageClick = (item) => {
    if (item.provider.provider_name === "youtube") {
      window.open(item.redirection_link, "", "height=800px, width=600px");
    } else {
      this.setState({ selectedMention: item.id, isReplyTweetOpen: true });
    }
  };

  handleReplyTweetModal = () => {
    this.setState({ isReplyTweetOpen: !this.state.isReplyTweetOpen });
  };
  handleAddTagModal = () => {
    this.setState({ addNewTagModel: !this.state.addNewTagModel });
  };
  handleAssignTagModal = () => {
    this.setState({ assignTagModal: !this.state.assignTagModal });
  };
  handleReplyPost = (data) => {
    let form_data = new FormData();
    form_data.append("mention", this.state.selectedMention);
    form_data.append("reply", data.comment);
    const token = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios_instance
      .post(
        utils.format(URLS.MENTION_REPLY, [this.props.business_id]),
        form_data,
        { headers: headers }
      )
      .then((res) => {
        this.setState({ selectedMention: null, isReplyTweetOpen: false });
        // alert(res.data.message);
      })
      .catch((err) => {
        this.setState({ selectedMention: null, isReplyTweetOpen: false });
        throw err.response.data;
      });
  };

  toggleConfirm = (confirmationFunction) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      confirmationFunction,
    });
  };
  handleTagButtonClick = (e, item) => {    
    this.setState({ tagSelectedItem: item,addNewTagModel: !this.state.addNewTagModel, error: null, relevantTags: [],});
  };
  render() {
    const {
      mentions,
      keyword,
      location: { search },
      handleTagFilter,
      postTag,
      tags,
      handleReviewSentiment,
      sentimentOptions,
    } = this.props;
    const { relevantTags, error, isConfirmOpen, confirmationFunction } =
      this.state;
    const sentimentChoices =
      sentimentOptions &&
      sentimentOptions
        .filter((item) => {
          return item.value !== "";
        })
        .map((item) => {
          return {
            text: item.display_name,
            label: {
              color: `${labelColors[item.value]}`,
              empty: true,
              circular: true,
              style: {
                backgroundColor: labelColors[item.value],
              },
            },
            value: item.value,
          };
        });
    let highlightArray = [];
    keyword && highlightArray.push(keyword.keyword);
    const searched = queryString.parse(search).search;
    const tagsList = relevantTags.length
      ? relevantTags
      : tags.length
      ? tags
      : null;
    searched && highlightArray.push(searched);
    return (
      <div>
        {keyword && keyword.approved && (
          mentions && mentions.results && mentions.results.length > 0 ? (
            mentions.results.map((item) => {
              const followers = this.countFunction(item.author.followers_count);
              const views = this.countFunction(item.views_count);
              const likes = this.countFunction(item.likes_count);
              const comments = this.countFunction(item.comment_count);
              const dislikes = this.countFunction(item.dislikes_count);
              const provider = item.provider.provider_name;
              const popupText =
                provider === "google search"
                  ? FilterIcons[item.types].text
                  : provider;
              const reviewIcon =
                provider === "google search"
                  ? FilterIcons[item.types].icon
                  : ["quora", "pinterest"].includes(provider) &&
                    FilterIcons[provider].icon;
              const tags = item.tag && item.tag.length ? item.tag : null;

              return (
                <Row className="mb-3" key={item.id} sm={12} xs={12}>
                  <Col sm={12} xs={12}>
                    <Card body className="px-4 pt-4 border">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex gap-15 align-items-center">
                          <div className="mentions-icon">
                            {item.author.profile_pic ? (
                              <Avatar src={item.author.profile_pic} alt="img" />
                            ) : (
                              <Avatar src={UserImg} alt="img" />
                            )}
                          </div>
                          <div>
                            <Box sx={{ fontSize: "16px", fontWeight: 500 }}>
                              {provider === 'facebook' ?
                                <div ><img style={{ height: '2em' }} src={facebookText} ></img></div>
                                : provider === 'instagram' ?
                                  <div ><img style={{ height: '2em' }} src={instagram} ></img></div>
                                  : <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.heading,
                                    }}
                                  ></p>
                              }
                            </Box>
                            <div
                              className="align-center gap-10 mention-container"
                              style={{
                                color: "#7A8193",
                              }}
                            >
                              {item.author.name && (
                                <div>{item.author.name}</div>
                              )}

                              <div className="">
                                {followers || 0}&nbsp;{" "}
                                {provider === "youtube"
                                  ? "Subscribers"
                                  : "Followers"}
                              </div>

                              <div className="cmticon">
                                <img src={thumbicon} />
                                {likes || 0}
                              </div>

                              {/* // TODO : Dislike section  */}
                              {/* <div className="cmticon">
                                <Icon name="thumbs down outline"></Icon>
                                {dislikes || 0}
                              </div> */}

                              <div className="cmticon">
                                <img src={messageChat} />
                                <span>{comments || 0}</span>
                              </div>

                              {views && provider === "youtube" && (
                                <div className="cmticon">
                                  <img src={eyeIcon} />
                                  {views}
                                  &nbsp;views
                                </div>
                              )}

                              {/* {views && provider === "youtube" && <div className="cmticon"> {views} views</div>} */}
                            </div>
                          </div>
                        </div>

                        <div
                          className="d-flex flex-column"
                          style={{ color: "#210a0a" }}
                        >
                          <div className="d-flex justify-content-end">
                            {item.provider.provider_name !==
                              "google search" && (
                              <img
                                style={{
                                  width: "35px",
                                  height:
                                    item.provider.provider_name === "youtube"
                                      ? "25px"
                                      : "30px",
                                  cursor: "pointer",
                                }}
                                className=""
                                src={
                                  item.provider.provider_name === "twitter"
                                    ? twitter
                                    : item.provider.logo
                                }
                                onClick={() => {
                                  window.open(
                                    item.redirection_link,
                                    "",
                                    "height=800px, width=600px"
                                  );
                                }}
                                alt={item.provider.provider_name}
                              />
                            )}
                          </div>
                          {item.created && (
                            <Box
                              component="span"
                              sx={{
                                color: "#646565",
                                marginTop: "5px",
                                fontSize: "13px",
                              }}
                            >
                              {/* <i
                                className="far fa-calendar-alt"
                                style={{
                                  fontSize: "1.3em",
                                  color: "#ef3d86",
                                }}
                              ></i> */}
                              {/* &nbsp;&nbsp; */}
                              {moment(item.created).format("DD-MM-YYYY")}
                            </Box>
                          )}
                        </div>
                      </div>
                      <Divider />
                      {item.media && item.provider
                        ? item.media.map((mediaItem, index) => {
                            let height;
                            let width;

                            if (mediaItem.media_type === "Image") {
                              if (item.provider === "twitter") {
                                height = "10em";
                                width = "20em";
                              } else {
                                height = "13em";
                                width = "13em";
                              }
                              return (
                                <img
                                  key={index}
                                  className="images-mentions"
                                  style={{ height: "auto", width: "13em" }}
                                  src={mediaItem.media_url}
                                  alt=""
                                />
                              );
                            } else {
                              if (item.provider === "instagram") {
                                height = "20em";
                                width = "20em";
                              } else if (item.provider === "youtube") {
                                height = "207px";
                                width = "368px";
                              }

                              return (
                                <ReactPlayer
                                  key={index}
                                  onPlay={() => {
                                    this.handleOnPlay(item.id);
                                  }}
                                  playing={this.state.playingId === item.id}
                                  controls={true}
                                  height={height}
                                  width={width}
                                  url={mediaItem.media_url}
                                />
                              );
                            }
                          })
                        : ""}

                      <Box
                        sx={{
                          color: "#7A8193",
                          marginTop: "10px",
                        }}
                      >
                        <Highlighter
                          highlightStyle={{
                            color: "green",
                            fontWeight: "bold",
                          }}
                          searchWords={highlightArray}
                          textToHighlight={item.description}
                        />
                      </Box>
                      <Divider />
                      <div className="align-center justify-content-between">
                        <div className="align-center gap-20">
                          <div className="align-center gap-10">
                            <Box
                              component="img"
                              sx={{ cursor: "pointer" }}
                              src={whatsapp}
                              onClick={(e) => {
                                this.handleShareButton(e, {
                                  type: "whatsapp",
                                  linkToCopy: item.redirection_link,
                                });
                              }}
                            />
                            <Box
                              component="img"
                              sx={{ cursor: "pointer" }}
                              src={facebook}
                              onClick={(e) => {
                                this.handleShareButton(e, {
                                  type: "facebook",
                                  linkToCopy: item.redirection_link,
                                });
                              }}
                            />
                            <Box
                              component="img"
                              sx={{ cursor: "pointer" }}
                              src={twitter}
                              onClick={(e) => {
                                this.handleShareButton(e, {
                                  type: "twitter",
                                  linkToCopy: item.redirection_link,
                                });
                              }}
                            />
                            <FPToolTip title={"Click to copy"}>
                              <img
                                src={copy}
                                onClick={(e) => {
                                  this.handleLinkCopy(e, item.redirection_link);
                                }}
                              />
                            </FPToolTip>
                          </div>
                          <div>
                            {provider !== "youtube" && (
                              <div className="">
                                {
                                  <div className="align-center">
                                    {provider !== "youtube" && (
                                      <div
                                        className="tagbtn"
                                        onClick={(e) =>
                                          this.handleTagButtonClick(e, item)
                                        }
                                      >
                                        <img src={tagSvg} alt="tag icon" />
                                        &nbsp; Tags
                                      </div>
                                    )}

                                    <CSSTransitionGroup
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                      transitionName="reviews-tags"
                                      transitionAppear={true}
                                      transitionAppearTimeout={700}
                                      transitionEnterTimeout={700}
                                      transitionLeaveTimeout={700}
                                    >
                                      {tags?.map((tag, index) => {
                                        return (
                                          <span
                                            className="mr-2 cursor-pointer align-center tagOption"
                                            key={index}
                                          >
                                            <div
                                              onClick={() => {
                                                handleTagFilter(tag);
                                              }}
                                            >
                                              {tag}
                                            </div>
                                            <div className="ml-2">
                                              <img
                                                src={crossClose}
                                                onClick={() => {
                                                  postTag({
                                                    object_id: item.id,
                                                    tag_name: tag,
                                                    tagMethod: "remove_tags/",
                                                  });
                                                }}
                                              />
                                            </div>
                                          </span>
                                        );
                                      })}
                                    </CSSTransitionGroup>
                                  </div>
                                }
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="align-center">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              className="mention-container"
                              onClick={() => {
                                window.open(
                                  item.redirection_link,
                                  "",
                                  "height=800px, width=600px"
                                );
                              }}
                              sx={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                marginRight: "10px", 
                                "&:not(:nth-last-child(-n+2))": {
                                  borderRight: "1px solid #bfc2cb",
                                  paddingRight: "10px",
                                },
                              }}
                            >
                              <img
                                src={replyleft}
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  marginRight: "6px",
                                }}
                              />
                              Engage
                            </Box>

                            {provider === "twitter" && (
                              <Box
                                className="mention-container"
                                onClick={() => {
                                  this.handleEngageClick(item);
                                }}
                                sx={{
                                  display: "flex",
                                  cursor: "pointer",
                                  alignItems: "center",
                                  marginRight: "10px",
                                  "&:not(:nth-last-child(-n+2))": {
                                    borderRight: "1px solid #bfc2cb",
                                    paddingRight: "10px",
                                  },
                                }}
                              >
                                <img
                                  src={replyforward}
                                  style={{ marginRight: "6px" }}
                                />
                                Reply
                              </Box>
                            )}

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                marginRight: "10px",
                                "&:not(:nth-last-child(-n+2))": {
                                  borderRight: "1px solid #bfc2cb",
                                  paddingRight: "10px",
                                },
                              }}
                              onClick={() =>
                                this.toggleConfirm(() =>
                                  this.props.deleteMention(item.id)
                                )
                              }
                            >
                              <img
                                src={deleteicon}
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  marginRight: "6px",
                                }}
                                alt="delete icon"
                              />
                              Delete
                            </Box>

                            {provider !== "youtube" && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                                }}
                              >
                                <Dropdown
                                  style={{ display: "flex" }}
                                  selectOnBlur={false}
                                  onChange={(e, { value }) => {
                                    handleReviewSentiment(value, {
                                      id: item.id,
                                      sentiment: item.sentiment,
                                    });
                                  }}
                                  className="align-center"
                                  trigger={
                                    <Box
                                      component="span"
                                      sx={{
                                        color: `${labelColors[item.sentiment]}`,
                                      }}
                                    >
                                      {item.sentiment}
                                    </Box>
                                  }
                                  options={sentimentChoices}
                                  value={item.sentiment}
                                ></Dropdown>
                              </Box>
                            )}
                          </Box>
                        </div>
                      </div>

                      {/* Below not added */}
                      {/* <Row>
                      {item.domain_name && (
                        <Col sm={4} xs={4} className="p-0">
                          <div
                            className="float-right mr-5"
                            style={{
                              color: "grey",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {item.domain_name}
                          </div>
                        </Col>
                      )}
                    </Row> */}
                    </Card>
                  </Col>
                </Row>
              );
            })
          ) : (
            <NoData className="mt-5" />
          )
        ) 
        // : (
        //   <CardWrapper
        //     showDivider={false}
        //     className="mb-4 text-center"
        //     style={{ borderRadius: "15px" }}
        //   >
        //     <Box
        //       sx={{ color: "#1A2544", padding: "0px 10px", fontSize: "14px" }}
        //     >
        //       Keyword has been added successfully. The data will be fetched as
        //       soon as Famepilot administration approve your keyword.
        //     </Box>
        //   </CardWrapper>
        // )
        }
        {this.state.isReplyTweetOpen && (
          <FormModal
            isOpen={this.state.isReplyTweetOpen}
            toggle={this.handleReplyTweetModal}
            heading="Write a reply"
            width="700px"
            headerClass={"d-flex align-items-center"}
            paddingDense={true}
          >
            <ReplyTweetForm handleReplyPost={this.handleReplyPost} />
          </FormModal>
        )}

        {/* <FormModal
          isOpen={this.state.addNewTagModel}
          toggle={this.handleAddTagModal}
          heading="Add New Tag "
          width="600px"
          headerClass={"d-flex align-items-center"}
          paddingDense={true}
        >
          <AddNewTagModel addTag={this.addTag} />
        </FormModal> */}
        {/* <FormModal
          isOpen={this.state.assignTagModal}
          showCloseBtn={false}
          heading={
            <div className="d-flex justify-content-between">
              <div>Assign a New Tag</div>
              <div className="d-flex align-items-center gap-10">
                <FPButton
                  label="Add Tag"
                  size="small"
                  style={{ width: "100px", height: "30px" }}
                />
                <FPButton
                  onClick={this.handleAddTagModal}
                  size="small"
                  label="Close"
                  style={{
                    width: "100px",
                    height: "30px",
                    color: "#1B1C1D",
                    border: "1px solid #BFC2CB",
                  }}
                  backgroundColor="#ffffff"
                />
              </div>
            </div>
          }
          width="600px"
          headerClass={"d-flex align-items-center"}
          paddingDense={true}
          showDivider={true}
        >
          <AssignNewTagModel />
        </FormModal> */}

        <TicketingTagsModal
            tagsList={tagsList}
            isReviewTagModalOpen={this.state.addNewTagModel}
            toggleReviewTagModal={this.handleAddTagModal}
            item={this.state.tagSelectedItem}
            postTagOnReview={this.postTagOnReview}
            error={error}
            handleTagChange={this.handleTagChange}
            addtag={this.addTag}
          />

        <ConfirmationModal
          header="Are you sure!"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          subHeader={`Do you think this mention is not relevant? This action can’t be undone.`}
          isOpen={isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={confirmationFunction}
        />
      </div>
    );
  }
}

export default MentionsDashboard;
