import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FPCheckbox } from "./new";
import CheckIcon from "@mui/icons-material/Check";
import { ListItem } from "@mui/material";
import { FixedSizeList } from "react-window";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const MemoizedListItem = React.memo(({ option, isSelected, multiple, selected, ...props }) => (
  <ListItem
    {...props}
    dense
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: isSelected ? "#F2F4F7 !important" : "transparent",
      transition: "background-color 0.3s ease",
      "&:hover": {
        backgroundColor: isSelected ? "#E1E6EB" : "#f5f5f5",
      },
    }}
  >
    {multiple && <FPCheckbox checked={selected} />}
    <span>{option?.display_name}</span>
    {!multiple && selected && (
      <CheckIcon
        fontSize="small"
        style={{
          color: "#F13A88",
          marginLeft: "auto",
        }}
      />
    )}
  </ListItem>
));

function ListboxComponent(props) {
  const { children, selectedIndex, ...other } = props;
  const itemData = React.Children.toArray(children);

  const listRef = React.useRef(null);

  React.useEffect(() => {
    if (listRef.current && selectedIndex >= 0) {
      listRef.current.scrollToItem(selectedIndex, "center");
    }
  }, [selectedIndex]);

  return (
    <div {...other}>
      <FixedSizeList
        height={33 * itemData.length > 300 ? 300 : 33 * itemData.length}
        width="100%"
        itemSize={33}
        itemCount={itemData.length}
        itemData={itemData}
        ref={listRef}
      >
        {({ index, style }) => <div style={style}>{itemData[index]}</div>}
      </FixedSizeList>
    </div>
  );
}

export default function VirtualizeAutoSelect({
  multiple = false,
  placeholder,
  value,
  variant,
  options,
  onChange,
  sx,
  ...rest
}) {
  const [selectedOptions, setSelectedOptions] = React.useState(multiple ? [] : null);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const selectedIndex = React.useMemo(() => {
    if (multiple) {
      const firstSelectedValue = selectedOptions?.[0]?.value;
      return options.findIndex((option) => option.value === firstSelectedValue);
    }
    return options.findIndex((option) => option.value === selectedOptions);
  }, [selectedOptions, options, multiple]);

  return (
    <Autocomplete
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          minWidth: "100%",
          transition:
            "border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "& .MuiOutlinedInput-notchedOutline.Mui-focused": {
          borderColor: "rgb(0, 142, 255) !important",
        },
        "& .MuiAutocomplete-tag": {
          backgroundColor: "#ddeff0",
          color: "#1b1c1d",
          fontSize: "14px",
        },
        "& .MuiChip-deleteIcon": {
          color: "#646565 !important",
        },
        "& .MuiAutocomplete-listbox": {
          maxHeight: 350,
        },
        "& .MuiAutocomplete-endAdornment": {
          display: "flex",
          alignItems: "center",
        },
        ...sx,
      }}
      disableCloseOnSelect={multiple}
      size="small"
      multiple={multiple}
      id="combo-box-demo"
      options={options}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      getOptionLabel={(option) => option?.display_name}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      ListboxComponent={(listboxProps) => <ListboxComponent {...listboxProps} selectedIndex={selectedIndex} />}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        const isSelected = multiple
          ? selectedOptions?.some((opt) => opt?.value === option?.value)
          : option?.value === selectedOptions;
        return (
          <>
            <MemoizedListItem
              key={props.key}
              {...optionProps}
              option={option}
              isSelected={isSelected}
              multiple={multiple}
              selected={selected}
            />
          </>
        );
      }}
      popupIcon={<KeyboardArrowDownIcon />}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} variant={variant} />}
      value={
        multiple
          ? options?.filter((option) => selectedOptions?.includes(option?.value))
          : options?.find((option) => option?.value === selectedOptions) || null
      }
      onChange={(event, value) => {
        setSelectedOptions(multiple ? value?.map((v) => v?.value) : value ? value?.value : null);
        onChange?.(event, multiple ? value?.map((v) => v?.value) : value ? value?.value : null);
      }}
      {...rest}
    />
  );
}
