/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  FPBadge,
  FPButton,
  FPMenuBox,
  FPToolTip,
} from "../../components/common/new";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import { Badge, makeStyles } from "@material-ui/core";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import "../review/userDetails.css";
import TicketComplete from "../../images/svg/TicketComplete";
import UserIcon from "../../images/svg/UserIcon";
import FPChip from "../../components/common/new/FPChip/FPChip";
import { snakeToCapital } from "../../libs/utils";
import { lightCommentColor } from "../../libs/constant";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  menuBox: {
    color: "#1B1C1D",
    fontSize: "13px",
    gap: 0,
    backgroundColor: "#F6F6F8",
    border: "unset",
    height: "30px",
  },
  button: {
    padding: "0 12px",
    fontSize: "13px",
    border: "unset",
    height: "30px",

    "& .MuiButton-label": {
      fontSize: "13px",
      fontWeight: "400",
    },
  },
}));

const TicketHeaderActivity = (props) => {
  const {
    item,
    replyDisabled,
    ticketActivity,
    toggleTicketModal,
    changeStatus,
    respondedMenubox,
  } = props;
  const [ticketResolveModal, setTicketResolveModal] = useState(false);

  const toggleConfirm = () => {
    setTicketResolveModal(!ticketResolveModal);
  };

  const serviceDelivery = [
    { label: "Delivery", value: "Delivery" },
    { label: "Dining", value: "Dining" },
    { label: "Takeaway", value: "Takeaway" },
  ];

  const statusData = [
    { label: "Responded", value: "Responded" },
    { label: "Not responded", value: "Not responded" },
    { label: "Deleted", value: "Deleted" },
  ];

  const uniqueCreatedBy = {};

  // Iterate through the comments array and mark each 'created_by' value as true in the uniqueCreatedBy object
  item &&
    item?.comment.length &&
    item?.comment.map((comment) => {
      uniqueCreatedBy[comment.created_by] = true;
    });

  const uniqueCreatedByArray = Object.keys(uniqueCreatedBy);

  const getColor = (name) => {
    const index = uniqueCreatedByArray.findIndex(
      (commenterName) => commenterName === name
    );
    return lightCommentColor[index + 1] || lightCommentColor[0];
  };

  const getStatusColor = (status) => {
    const statusColor = {
      new: "#2375ef",
      assigned: "#f8ae00",
      resolved: "#17b26a",
      reopened: "#e16b16",
      waiting_for_customer: "#FFDC54",
    };
    return statusColor[status];
  };

  const classes = useStyles({ isTicket: item?.ticket });
  const ticketId = item?.ticket_id && item?.ticket_id.split("ticket")[1];

  return (
    <div
      className="d-flex align-items-center gap-10 justify-content-end"
      style={{ height: "30px" }}
    >
      {respondedMenubox && (
        <FPMenuBox
          title={"Not responded"}
          options={statusData}
          className={classes.menuBox}
          rounded={true}
          size={"small"}
        />
      )}

      {!replyDisabled && item?.source_data?.service_type?.trim() && (
        <FPMenuBox
          title={
            item?.source_data?.service_type=== "dining" ? "Dining" : item?.source_data?.service_type
          }
          options={serviceDelivery}
          className={classes.menuBox}
          rounded={true}
          size={"small"}
        />
      )}

      {/* View ticket  */}
      <FPChip
        size="small"
        variant="outlined"
        style={{ height: "30px", borderColor: "#BFC2CB" }}
        label={
          <div className="d-flex align-items-center justify-content-center">
            <FPBadge color={getStatusColor(item?.status)} size="11" />
            &nbsp;&nbsp;
            <span
              style={{ color: "#1B1C1D", fontWeight: "400", fontSize: "13px" }}
            >
              {snakeToCapital(item?.status)}
            </span>
          </div>
        }
      />
      {item?.assignee_data && (
        <div
          style={{
            borderRadius: "30px",
            height: "30px",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #BFC2CB",
          }}
          className="d-flex flex-column gap-10 px-2"
        >
          <div className="d-flex gap-2 align-items-center">
            <UserIcon />
            <p
              className="ml-1"
              style={{ color: "#1B1C1D", fontWeight: "400", fontSize: "13px" }}
            >
              {item?.assignee_data.name || "Unassigned"}
            </p>
          </div>
        </div>
      )}
      {item?.source_data.ticket ? (
        <div className="d-flex">
          <FPToolTip title="View Ticket">
            <Badge
              badgeContent={
                item?.no_of_ticket_comments === 0
                  ? 0
                  : `+${item?.no_of_ticket_comments}`
              }
              color="primary"
              className="ticket-badge"
            >
              <FPButton
                disabled={!ticketActivity}
                onClick={() => toggleTicketModal(item)}
                startIcon={
                  <ConfirmationNumberOutlinedIcon
                    style={{ color: "#FFFFFF", width: "15px", height: "15px" }}
                  />
                }
                backgroundColor={ticketId ? "#F13A88" : "#F6F6F8"}
                textColor={ticketId ? "#FFFFFF" : "#1B1C1D"}
                label={ticketId}
                size={"small"}
                className={classes.button}
              />
            </Badge>
          </FPToolTip>
          <FPToolTip title="Resolve Ticket">
            <span
              style={{ marginLeft: 10 }}
              onClick={() => setTicketResolveModal(true)}
              className={classNames({
                "fp-disabled": item?.status === "resolved",
              })}
            >
              <TicketComplete />
            </span>
          </FPToolTip>
        </div>
      ) : (
        <div className="d-flex">
          <FPToolTip title="View Ticket">
            <Badge
              badgeContent={
                item?.no_of_ticket_comments &&
                (item?.no_of_ticket_comments === 0
                  ? 0
                  : `+${item?.no_of_ticket_comments}`)
              }
              color="primary"
              className="ticket-badge"
            >
              <FPButton
                disabled={!ticketActivity}
                onClick={() => toggleTicketModal(item)}
                startIcon={
                  <ConfirmationNumberOutlinedIcon
                    style={{ color: "#FFFFFF", width: "15px", height: "15px" }}
                  />
                }
                backgroundColor={ticketId ? "#F13A88" : "#F6F6F8"}
                textColor={ticketId ? "#FFFFFF" : "#1B1C1D"}
                label={ticketId}
                size={"small"}
                className={classes.button}
              />
            </Badge>
          </FPToolTip>
          <FPToolTip title="Resolve Ticket">
            <span
              style={{ marginLeft: 10 }}
              onClick={() => setTicketResolveModal(true)}
            >
              <TicketComplete />
            </span>
          </FPToolTip>
        </div>
      )}
      <ConfirmationModal
        header="Are you sure!"
        subHeader="Are you sure you want to resolve this ticket?"
        isOpen={ticketResolveModal}
        toggle={toggleConfirm}
        onConfirm={changeStatus}
        success
      />
    </div>
  );
};

export default TicketHeaderActivity;
