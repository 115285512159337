import React from "react";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { Avatar } from "@material-ui/core";
import { MessageBox } from "../../components/common/new";
import ChatFileShow from "../../components/messenger/Common/ChatFileShow";
import DefaultUserIcon from "../../images/boy.png";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import SrDoc from "../../images/svg/SrDoc.svg";
import SrPdf from "../../images/svg/SrPdf.svg";
import DescriptionIcon from "@mui/icons-material/Description";

const swapTags = (text) => {
  let displayText = text?.slice();
  const tags = text?.match(/@\{\{[^\}]+\}\}/gi) || []; //eslint-disable-line
  const spans = [];
  tags?.map((myTag) => {
    const idx = displayText.indexOf(myTag);
    spans.push(<span>{displayText?.slice(0, idx)}</span>);
    spans.push(
      <span className="comments-textarea__mention">
        {myTag?.slice(3, -2).split("||")[2]}
      </span>
    );
    displayText = displayText?.slice(idx + myTag.length, displayText.length);
  });
  displayText && spans?.push(<span>{displayText}</span>);
  return spans;
};

class SrCommentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserFileModal: false,
      selectAttchFile: null,
    };
  }

  closeUserFileModal = () => {
    this.setState({ showUserFileModal: false });
  };

  showUserFileModalFn = () => {
    const { attached_file } = this.props;
    let preview_data = {};
    let msg_type = "image";
    if (
      attached_file?.includes(".pdf") ||
      attached_file?.includes(".docx") ||
      attached_file?.includes(".doc") ||
      attached_file?.includes(".xlsx") ||
      attached_file?.includes(".xls") ||
      attached_file?.includes(".txt") ||
      attached_file?.includes(".csv")
    ) {
      msg_type = "file";
    }
    if (attached_file?.includes(".mp4") || attached_file?.includes(".webm")) {
      msg_type = "video";
    }
    preview_data.msg_type = msg_type;
    preview_data.attached_file = attached_file;
    preview_data.file_attached = attached_file;
    this.setState({ selectAttchFile: preview_data, showUserFileModal: true });
  };

  getFileIcon = (file) => {
    if (file.type?.includes("pdf"))
      return <img src={SrPdf} alt="PDF" className="file-icon" />;
    if (
      file.type?.includes("word") ||
      file.name?.endsWith(".doc") ||
      file.name?.endsWith(".docx")
    )
      return <img src={SrDoc} alt="Word" className="file-icon" />;
    if (file.type?.includes("audio"))
      return <AudiotrackIcon className="file-icon" />;
    if (file.type?.includes("video"))
      return <PlayCircleIcon className="file-icon" />;
    if (file.type?.includes("image"))
      return <DescriptionIcon className="file-icon" />;
    return <DescriptionIcon className="file-icon" />;
  };

  render() {
    const {
      description,
      backgroundColor,
      profile_pic,
      created_by,
      created_at,
      voice_comment,
      attached_files = [],
      createdByName,
      attached_Image,
      setFileAttachments,
      setOpenComment
    } = this.props;

    const openImagePreview = (file) => {
        setFileAttachments(file);
        setOpenComment(true);
    }
    return (
      <MessageBox
        backgroundColor={backgroundColor}
        isSelfBox={false}
        className={"w-100 max-w-100"}
        time={moment(created_at).format("llll")}
        captionClass="align-self-end"
      >
        <div className="">
          <div className="d-flex gap-10 align-center">
            <Avatar
              src={profile_pic || DefaultUserIcon}
              alt={created_by}
              className="sr-comment-avatar"
            />
            <p style={{ color: "#1B1C1D", fontSize: "12px" }}>
              {createdByName ? createdByName : ""}
            </p>
          </div>
          {voice_comment == null ? (
            <div className="align-self-center">
              {swapTags(description).map((i) => {
                return i;
              })}
              <div className="attachment-preview-container">
                {attached_Image?.slice(0, 3).map((file, index) => (
                  <div
                    className="attachment-preview"
                    style={{
                      marginLeft: index === 1 || index === 2 ? "-10px" : "",
                      zIndex: attached_Image.length - index,
                    }}
                    key={index}
                    onClick={() => openImagePreview(attached_Image)}
                  >
                    {/* Render thumbnail or file icon */}
                    {file ? (
                      <img
                        src={file.url}
                        alt={`attachment-${index}`}
                        className="attachment-thumbnail"
                      />
                    ) : (
                      <div
                        className="file-icon-wrapper"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f4f4f4",
                          borderRadius: "5px",
                        }}
                      >
                        {this.getFileIcon(file)}
                      </div>
                    )}
                  </div>
                ))}
                {attached_Image?.length > 3 && (
                  <h6
                    style={{
                      position: "absolute",
                      top: "5px",
                      left:
                        attached_Image.length > 2
                          ? "75px"
                          : attached_Image.length > 1
                          ? "70px"
                          : "45px",
                      whiteSpace: "nowrap",
                    }}
                  >{`+${attached_Image.length - 3} More`}</h6>
                )}
              </div>
              <div className="upload-section w-100">
                <div className="upload-area">
                  <div className="file-preview-container">
                    {attached_files?.slice(0, 3).map((file, index) => (
                      <div
                        className="file-preview-circle"
                        style={{
                          marginLeft: index === 1 || index === 2 ? "-10px" : "",
                          zIndex: attached_files.length - index,
                        }}
                        key={index}
                      >
                        {this.getFileIcon(file)}
                      </div>
                    ))}
                    {attached_files?.length > 3 && (
                      <h6
                        style={{
                          position: "absolute",
                          top: "7px",
                          left:
                            attached_files.length > 2
                              ? "75px"
                              : attached_files.length > 1
                              ? "70px"
                              : "45px",
                          whiteSpace: "nowrap",
                          marginBottom: "0px",
                        }}
                      >{`+${attached_files.length - 3} File`}</h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <audio src={voice_comment} controls />
            </div>
          )}
        </div>
        <ChatFileShow
          showModal={this.state.showUserFileModal}
          closeModal={this.closeUserFileModal}
          selectAttchFile={this.state.selectAttchFile}
        />
      </MessageBox>
    );
  }
}

export default SrCommentCard;