import React from "react";
import ReportsStackedBarChart from "../../components/common/ReportsStackedBarChart";
import { NoData } from "../../components/common/new";
import LoaderComponent from "../../components/common/loaderComponent";

const StarRatingGraph = (props) => {
  const { ratingDetails, loader ,businessDetails} = props;

  if (ratingDetails.length === 0) {
    return <NoData className="mb-3" />;
  }

  const ratingDetailsData = {
    labels:
      ratingDetails &&
      ratingDetails.map((item, index) =>
        index === 0 ? item.owner_name : item.competitor_name
      ),
    one_star_count:
      ratingDetails && ratingDetails.map((item) => item.one_star_count),
    two_star_count:
      ratingDetails && ratingDetails.map((item) => item.two_star_count),
    three_star_count:
      ratingDetails && ratingDetails.map((item) => item.three_star_count),
    four_star_count:
      ratingDetails && ratingDetails.map((item) => item.four_star_count),
    five_star_count:
      ratingDetails && ratingDetails.map((item) => item.five_star_count),
  };

  const calculateBarThickness = (itemsCount, chartWidth = 800) => {
    const maxBarsVisible = 20;
    const minThickness = 10;
    const maxThickness = 50;
    const dynamicThickness = chartWidth / (itemsCount * 3 );
    return Math.max(minThickness, Math.min(maxThickness, dynamicThickness));
  };
  const calculateFontSize = (itemsCount) => {
    const minFontSize = 8;
    const maxFontSize = 16; 
    const dynamicFontSize = maxFontSize - Math.min(itemsCount, 20) * 0.5;
    return Math.max(minFontSize, dynamicFontSize);
  };

  const labelFontSize = calculateFontSize(ratingDetails.length);

  const CHART_BACKGROUND_COLOR_2 = "#F13A88";
  const barThickness = calculateBarThickness(ratingDetails.length);
  return (
    <div>
      <LoaderComponent loader={loader} />
        <ReportsStackedBarChart
        ticks
        businessDetails={businessDetails}
        dataKeys={ratingDetailsData.labels}
        dataSets={[
          // {
          //   label: "Avg Rating",
          //   data: ratingDetailsData ? ratingDetailsData : [],
          //   type: "line",
          //   backgroundColor: "rgba(225, 33, 105, 0.1)",
          //   borderColor: CHART_BACKGROUND_COLOR_2,
          //   legendColor: CHART_BACKGROUND_COLOR_2,
          //   lineTension: 0.6,
          //   yAxisID: "y-axis-1",
          //   fill: {
          //     target: "origin",
          //   },
          // },
          {
            label: "1 Star",
            data: ratingDetailsData ? ratingDetailsData.one_star_count : [],
            type: "bar",
            backgroundColor: "#ff4545",
            hoverBackgroundColor: "#ff4545",
            legendColor: "#ff4545",
            barThickness: barThickness,
            // yAxisID: "y-axis-2",
          },
          {
            label: "2 Star",
            data: ratingDetailsData ? ratingDetailsData.two_star_count : [],
            type: "bar",
            backgroundColor: "#ffa534",
            hoverBackgroundColor: "#ffa534",
            legendColor: "#ffa534",
            barThickness: barThickness,
            // yAxisID: "y-axis-2",
          },
          {
            label: "3 Star",
            data: ratingDetailsData ? ratingDetailsData.three_star_count : [],
            type: "bar",
            backgroundColor: "#ffe234",
            hoverBackgroundColor: "#ffe234",
            legendColor: "#ffe234",
            barThickness: barThickness,
            // yAxisID: "y-axis-2",
          },
          {
            label: "4 Star",
            data: ratingDetailsData ? ratingDetailsData.four_star_count : [],
            type: "bar",
            backgroundColor: "#b7dd29",
            hoverBackgroundColor: "#b7dd29",
            legendColor: "#b7dd29",
            barThickness: barThickness,
            // yAxisID: "y-axis-2",
          },
          {
            label: "5 Star",
            data: ratingDetailsData ? ratingDetailsData.five_star_count : [],
            type: "bar",
            backgroundColor: "#57e32c",
            hoverBackgroundColor: "#57e32c",
            legendColor: "#57e32c",
            yAxisID: "y-axis-2",
            barThickness: barThickness,
          },
        ]}
        primaryYLabel="Ratings"
        secondaryYLabel="Count"
        labelFontSize={labelFontSize}
      />    
    </div>
  );
};

export default StarRatingGraph;
