import React, { useEffect, useState } from "react";
import * as setSelectedTab from "../../../actions/common/setSelectedTabAction";
import OverviewFilter from "./OverviewFilter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import complaintIcon from "../../../images/svg/complaintIcon.svg";
import requestIcon from "../../../images/svg/requestIcon.svg";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";
import DoughnutCharts from "./DoughnutCharts";
import PieChart from "./PieChart";
import CustomProgressBar from "./ProgressBar";
import Chartwrapper from "../../../modules/dashboard/component/Chartwrapper";
import SrChartWrapper from "./SrChartWrapper";
import StatusSummaryCard from "./StatusSummaryCard";
import { Box } from "@material-ui/core";
import AssigneeStatusTable from "./AssigneeStatusTable";
import cookie from "react-cookies";
import axios_instance from "../../../libs/interseptor";
import { format } from "../../../libs/utils";
import apiUrls from "../../../libs/apiUrls";
import queryString from "query-string";
import * as serviceRequestOverview from "../../../actions/ticket/serviceRequestOverviewActions";
import LoaderComponent from "../../../components/common/loaderComponent";
const ServiceRequestOverView = (props) => {
  const {
    history,
    location,
    match: {
      params: { business_id },
      params,
    },
    match,
    actions,
    loader,
    serviceRequestOverview,
  } = props;
  const { search } = location;
  const service_category = cookie.load("service_category");

  useEffect(() => {
    document.title = "TicketServiceOverView";
    props.actionsSelectTab.setSelectedTab(service_category !== "Restaurant" ? 3 : 4);
    const payload = queryString.parse(search);
    actions.ServiceRequestOverview(payload, business_id)
  }, [search]);

  const statusMapping = {
    assigned: "Assigned",
    in_queue: "in Queue",
    in_progress: "In Progress",
    waiting_for_customer: "Waiting for Customer",
    resolved: "Resolved",
    reopened: "Reopened",
  };
  const getCount = (data, status) => {
    const item = data?.find((d) => d.status === status);
    return item ? item.count : 0;
  };
  
  const statusSummaryData = Object.values(statusMapping)?.map((leftHeader) => {
    const status = Object.keys(statusMapping)?.find(
      (key) => statusMapping[key] === leftHeader
    );
  
    if (status) {
      return {
        leftHeader,
        serviceRequest: getCount(serviceRequestOverview?.serviceRequestOverview?.requests_status, status),
        complaints: getCount(serviceRequestOverview?.serviceRequestOverview?.complaints_status, status),
        rightHeader: getCount(serviceRequestOverview?.serviceRequestOverview?.requests_complaints_status, status),
      };
    }
  
    return {
      leftHeader,
      serviceRequest: 0,
      complaints: 0,
      rightHeader: 0,
    };
  });
  
  const [data, setData] = useState(null);
  const url = format(`${apiUrls.RESPONSE_RATE}`, [business_id]);

  const fetchData = async (params) => {
    // await axios_instance
    //   .get(url, { params})
    //   .then(({ data }) => {
    //     setData(data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchData(queryString.parse(params));
    };
    fetch();
  }, [params]);

  const updateData = (datePayload) => {
    const newParams = Object.assign(queryString.parse(params), datePayload);
    fetchData(newParams);
  };
  // console.log("data", data);

  const getFilterData= (data) => {
    const business_id  = data.businessId

    delete data.businessId
    
    actions.ServiceRequestOverview(data, business_id)
  }

  return (
    <>
    <LoaderComponent loader={loader} />
      <div>
        <OverviewFilter history={history} location={location} match={match} getFilterData={(data) => getFilterData(data)} />
      </div>
      <div className="d-flex justify-content-between mt-2 gap-10">
        <CardWrapper showDivider={false} contentClass={"sr-chartBox-shadows"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={requestIcon}
                alt="request icon"
                style={{ height: "32px", width: "32px", marginRight: "10px" }}
              />
              <p style={{ fontSize: "16px", fontWeight: "bold", margin: "0" }}>
                Service Requests
              </p>
            </div>
            <div style={{ fontSize: "20px", fontWeight: "bold", margin: "0", padding:"0px 10px"  }}>
            {serviceRequestOverview?.serviceRequestOverview?.service_requests_data?.total_count}
            </div>
          </div>

          <div className="d-flex justify-content-between gap-10 mt-2">
            <SrChartWrapper
              heading="Department"
              className="service-review-chart"
            >
              <DoughnutCharts data={serviceRequestOverview?.serviceRequestOverview?.service_requests_data?.departments} type="service_request" />
            </SrChartWrapper>
            <SrChartWrapper
              heading="Request Type"
              className="service-review-chart"
            >
              <PieChart data={serviceRequestOverview?.serviceRequestOverview?.service_requests_data?.request_types} type="service_request" />
            </SrChartWrapper>
          </div>
          <SrChartWrapper
            heading="Priority"
            className="service-review-chart mt-2"
          >
            <CustomProgressBar data={serviceRequestOverview?.serviceRequestOverview?.service_requests_data?.priorities} number={"402"} />
          </SrChartWrapper>
        </CardWrapper>

        <CardWrapper showDivider={false} contentClass={"sr-chartBox-shadows"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={complaintIcon}
                alt="Complaints"
                style={{ height: "30px", width: "30px", marginRight: "10px" }}
              />
              <p style={{ fontSize: "16px", fontWeight: "bold", margin: "0" }}>
                Complaints
              </p>
            </div>
            <div style={{ fontSize: "20px", fontWeight: "bold", margin: "0", padding:"0px 10px" }}>
            {serviceRequestOverview?.serviceRequestOverview?.complaints_data?.total_count}
            </div>
          </div>
          <div className="d-flex justify-content-between gap-10 mt-2">
            <SrChartWrapper
              heading="Department"
              className="service-review-chart"
            >
              <DoughnutCharts data={serviceRequestOverview?.serviceRequestOverview?.complaints_data?.departments} type="complaints" />
            </SrChartWrapper>
            <SrChartWrapper
              heading="Request Type"
              className="service-review-chart"
            >
              <PieChart data={serviceRequestOverview?.serviceRequestOverview?.complaints_data?.request_types} type="complaints" />
            </SrChartWrapper>
          </div>
          <SrChartWrapper
            heading="Priority"
            className="service-review-chart mt-2"
          >
            <CustomProgressBar data={serviceRequestOverview?.serviceRequestOverview?.complaints_data?.priorities} />
          </SrChartWrapper>
        </CardWrapper>
      </div>
      <div className="mt-2">
        <Chartwrapper
          headerTitle={"Status Summary"}
          showDivider={false}
          contentClass={"sr-chartBox-shadows"}
          onDateChange={updateData}
        >
          <Box className="d-flex w-100 gap-10">
            {statusSummaryData.map((data, index) => (
              <Box className="w-100">
                <StatusSummaryCard data={data} />
              </Box>
            ))}
          </Box>
          <CardWrapper headerTitle="Assignee Status" className="mt-4">
            <AssigneeStatusTable data={serviceRequestOverview?.serviceRequestOverview?.assignee_status} />
          </CardWrapper>
        </Chartwrapper>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
    serviceRequestOverview: state.serviceRequestOverview,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    actions: bindActionCreators(serviceRequestOverview, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ServiceRequestOverView);
