import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import FeedBackStarRating from "./FormContainer/FeedBackStarRating";
import { FormTextArea } from "../../../components/common/new/forms";
import { Box } from "@mui/material";
import FPLabel from "../../../components/common/new/forms/ui/FPLabel";
import FeedbackNpsNumber from "./FormContainer/FeedbackNpsNumber";
import FeedbackAutoComplete from "./FormContainer/FeedbackAutoComplete";
import CheckboxGroup from "./FormContainer/CheckboxGroup";
import RadioButtonGroup from "./FormContainer/RadioButtonGroup";
import FeedbackImageUpload from "./FormContainer/FeedbackImageUpload";
import FeedbackEmojiSelect from "./FormContainer/FeedbackEmojiSelect";

const SurveyPage = ({ item, meta }) => {
  const {
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormikContext();
  return (
    <>
      {item?.allQuestions?.map((question) => (
        <div key={question.question_id}>
          {question?.question_type === "RATING" && (
            <div className="survey-div">
              <FeedBackStarRating
                onRatingChange={(rating) =>
                  setFieldValue(question.question_id.toString(), rating)
                }
                values={values?.[question.question_id]}
                error={
                  touched?.[question.question_id] &&
                  errors?.[question.question_id]
                }
                title={question?.input}
                showLabel={true}
              />
            </div>
          )}

          {question?.question_type === "SHORT ANSWER" && (
            <>
              {question?.input && (
                <FPLabel
                  isRequired={question?.question_required}
                  className="mt-4"
                >
                  {question?.input}
                </FPLabel>
              )}
              <Box
                component="div"
                className="form-group mb-0"
                sx={{
                  "& .MuiBox-root": {
                    border: "none !important",
                    backgroundColor: "#F6F6F8 !important",
                    borderRadius: "10px !important",
                    padding: "11px 12px",
                  },
                }}
              >
                <FormTextArea
                  name={question.question_id.toString()}
                  placeholder="Write here..."
                  sx={{
                    "& .fp-textarea": {
                      fontSize: "12px",
                      color: "#646565",
                      backgroundColor: "#F6F6F8 !important",
                    },
                  }}
                />
              </Box>
            </>
          )}
          {question?.question_type === "PARAGRAPH" && (
            <>
              {question?.input && (
                <FPLabel
                  isRequired={question?.question_required}
                  className="mt-4"
                >
                  {question?.input}
                </FPLabel>
              )}
              <Box
                component="div"
                className="form-group mb-0"
                sx={{
                  "& .MuiBox-root": {
                    border: "none !important",
                    backgroundColor: "#F6F6F8 !important",
                    borderRadius: "10px !important",
                    padding: "11px 12px",
                  },
                }}
              >
                <FormTextArea
                  name={question.question_id.toString()}
                  placeholder="Write here..."
                  sx={{
                    "& .fp-textarea": {
                      fontSize: "12px",
                      color: "#646565",
                      backgroundColor: "#F6F6F8 !important",
                    },
                  }}
                />
              </Box>
            </>
          )}
          {question?.question_type === "NPS QUESTION" && (
            <div className="survey-div">
              <FeedbackNpsNumber
                onNpsChange={(rating) =>
                  setFieldValue(question.question_id.toString(), rating)
                }
                value={values?.[question.question_id]}
                error={
                  touched?.[question.question_id] &&
                  errors?.[question.question_id]
                }
                title={question?.input}
              />
            </div>
          )}
          {question?.question_type === "Drop-down" && (
            <>
              {question?.question_short_form && (
                <FPLabel
                  isRequired={question?.question_required}
                  className="mt-4"
                >
                  {question?.question_short_form}
                </FPLabel>
              )}
              <FeedbackAutoComplete
                options={
                  question?.options &&
                  question?.options?.map((item) => ({
                    display_name: item.title,
                    value: item.id,
                  }))
                }
                placeholder={question?.input}
                name={question.question_id.toString()}
              />
            </>
          )}

          {question?.question_type === "Checkbox" && (
            <>
              <CheckboxGroup
                title={question?.input}
                options={question?.options}
                value={values?.[question.question_id] || []}
                onSelectionChange={(value) =>
                  setFieldValue(question.question_id.toString(), value)
                }
              />
            </>
          )}

          {question?.question_type=== "Multiple Choice" && (
            <RadioButtonGroup
            title={question?.input}
            options={question?.options}
            value={values?.[question.question_id] || []}
            onSelectionChange={(value) =>
              setFieldValue(question.question_id.toString(), value)
            }
          />
          )}
          {question?.question_type=== "ATTACHMENT" && (
            <FeedbackImageUpload
            onChange={(e) => setFieldValue(question.question_id.toString(), e.target.value)}
            name={question.question_id.toString()}
            defaultValue={values?.[question.question_id] || []}
          />
          )}
          {question?.question_type=== "fiveFacesEmoji" && (
            <div className="survey-div">
              <FeedbackEmojiSelect
              title={question?.input}
              fiveEmoji={true}
              onEmojiChange={(rating) => setFieldValue(question.question_id.toString(), rating)}
              value={values?.[question.question_id.toString()]}
              error={touched?.[question.question_id] &&
                errors?.[question.question_id]}
            />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default SurveyPage;
