import React, { useEffect, useState } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { ListDescription } from "semantic-ui-react";
import FPInput from "../../common/new/forms/ui/FPInput";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import "./listing.css";
import google_svg from "../../../images/googlephoto.svg";
import AddIcon from "@material-ui/icons/Add";
import emoji_svg from "../../../images/emoji.svg";
import delete_png from "../../../images/delete_png.webp";
import { formatReviews } from "../../../libs/helper";
import duplicate_svg from "../../../images/dusplicate.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import * as listingUtils from "../../../libs/listingToast";
import LoaderComponent from "../../common/loaderComponent";
import UpdateQuestionList from "./UpdateQuestionList";
import CreateQuestion from "./CreateQuestion";
import * as URLS from "../../../libs/apiUrls";

const UpdateQuestion = ({ primaryDetail, actions, selectReso, business_id, loader }) => {
  const addressString = [primaryDetail?.addressLines, primaryDetail?.locality, primaryDetail?.postalCode].join(", ");

  const [showBusiness, setShowBusiness] = useState("question_answer_list");
  const [questionAnsewrList, setQuestionAnsewrList] = useState({});
  console.log("questionAnsewrListquestionAnsewrListquestionAnsewrList", questionAnsewrList);
  const handleHideBusinessScreen = (type) => {
    console.log("typetypetypetypetypetypetypetypetype", type);
    setShowBusiness(type);
  };

  const [createQuestion, setCreateQuestion] = useState();

  const handleCreateQuestion = () => {
    console.log("createQuestioncreateQuestioncreateQuestioncreateQuestion", createQuestion);
  };

  const handleQuestionChange = (e) => {
    setQuestionAnsewrList((prev) => ({
      ...prev,
      question: e.target.value,
    }));
  };

  // Handle answer input change
  const handleAnswerChange = (e, index, author_type) => {
    if (author_type === "MERCHANT") {
      const newAnswers = [...questionAnsewrList.answers];
      newAnswers[index] = {
        ...newAnswers[index],
        answer: e.target.value,
      };
      setQuestionAnsewrList((prev) => ({
        ...prev,
        answers: newAnswers,
      }));
    }
  };

  const handleUpdateQuestion = async () => {
    // Prepare the payload data
    const payload = {
      question: questionAnsewrList.question,
      answers: questionAnsewrList.answers,
    };
    console.log("payload update question", payload);
    // try {
    //   // Make the API call
    //   const response = await fetch('https://your-api-endpoint.com/submit', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(payload),
    //   });

    //   // Handle the response
    //   if (response.ok) {
    //     const data = await response.json();
    //     console.log('Submission successful:', data);
    //   } else {
    //     console.error('Submission failed:', response.statusText);
    //   }
    // } catch (error) {
    //   console.error('Error submitting data:', error);
    // }
  };

  const handleShowCreateQuestion = (type) => {
    setShowBusiness(type);
  };

  const handleShowBusinessScreen = () => {
    setShowBusiness("question_answer_list");
  };

  return (
    <>
      {/* <LoaderComponent loader={loader} /> */}
      <div className="listing-container" style={{ overflow: "hidden", gap: "32px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <div className="listing-header">
            <div className="listing-info">
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="listing-name">{primaryDetail?.title}</div>
                <img
                  src={
                    primaryDetail?.is_varified
                      ? verified_svg
                      : primaryDetail?.is_suspend
                      ? duplicate_svg
                      : primaryDetail?.is_duplicate
                      ? duplicate_svg
                      : ""
                  }
                  alt="verifiedImage"
                />
              </div>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(1)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{formatReviews(primaryDetail?.totalReviewCount)} Reviews</span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>{addressString}</ListDescription>
          </div>
        </div>
      </div>
      <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "calc(100% - 120px)",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "15px",
                paddingBottom: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  paddingBlock: "10px",
                }}
              >
                {showBusiness !== "create_question" ? (
                  <FPButton
                    label="Add Question"
                    size="small"
                    onClick={() => handleShowCreateQuestion("create_question")}
                    endIcon={<AddIcon />}
                  />
                ) : (
                  ""
                )}
                <FPButton
                  label="AI suggested Q&A's"
                  size="small"
                  startIcon={<img src={emoji_svg} alt="emojiSvg" />}
                  star
                />
              </div>
              {showBusiness === "question_answer_list" ? (
                <UpdateQuestionList
                  selectReso={selectReso}
                  business_id={business_id}
                  setHideBusinessScreen={handleHideBusinessScreen}
                  setQuestionAnsewrList={setQuestionAnsewrList}
                />
              ) : showBusiness === "update_question" ? (
                <div
                  style={{
                    padding: "16px",
                    backgroundColor: "#F6F6F8",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div style={{ display: "grid", gap: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <img
                          style={{ height: "auto", width: "25px " }}
                          src={questionAnsewrList?.author?.profilePhotoUri}
                          // src={`${URLS.GMB_LISTING_LOCATION}/proxy-image?url=https:${questionAnsewrList?.author?.profilePhotoUri}`}
                          // `${URLS.GMB_LISTING_LOCATION}/proxy-image?url=https:${answer?.author?.profilePhotoUri}`
                          alt=""
                        />
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "14px",
                            color: "#1B1C1D",
                          }}
                        >
                          {questionAnsewrList?.author?.displayName}
                        </span>
                      </div>
                      <div
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#1B1C1D",
                        }}
                      >
                        Question
                      </div>
                    </div>
                    <FPInput
                      type="text"
                      value={questionAnsewrList?.question}
                      onChange={handleQuestionChange}
                      placeholder={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. "}
                    />
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#7A8193",
                      }}
                    >
                      0 /1000 words
                    </div>
                  </div>
                  {questionAnsewrList?.answers?.length > 0 ? (
                    questionAnsewrList?.answers?.map((answer, index) => {
                      console.log("answeransweransweransweransweranswer", answer);
                      return (
                        <div style={{ display: "grid", gap: "10px" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <img
                                style={{ height: "auto", width: "25px " }}
                                src={
                                  answer?.author?.type !== "MERCHANT"
                                    ? answer?.author?.profilePhotoUri
                                    // ? `${URLS.GMB_LISTING_LOCATION}/proxy-image?url=https:${answer?.author?.profilePhotoUri}`
                                    : ""
                                }
                                alt=""
                              />
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  color: "#1B1C1D",
                                }}
                              >
                                {answer?.author?.type === "MERCHANT" ? "By Merchant" : answer?.author?.displayName}
                              </span>
                            </div>
                            <div
                              style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#1B1C1D",
                              }}
                            >
                              Answer
                            </div>
                          </div>
                          <textarea
                            id="w3review"
                            name="w3review"
                            rows="4"
                            value={answer?.answer}
                            onChange={(e) => handleAnswerChange(e, index, answer?.author?.type)}
                            placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                            style={{
                              width: "100%",
                              borderRadius: "7px",
                              borderColor: "#BFC2CB",
                              fontWeight: "400",
                              fontSize: "14px",
                              color: "#7A8193",
                              padding: "10px 14px",
                            }}
                          />
                          <div
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#7A8193",
                            }}
                          >
                            0 / 1000 words
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="listing-name">No Existing Answer</div>
                  )}
                  <div>
                    <div style={{ borderBottom: "1px solid #BFC2CB" }}></div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <img
                      src={delete_png}
                      alt=""
                      style={{
                        height: "16px",
                        width: "16px",
                        color: "#FFFFFF",
                      }}
                    />
                  </div>
                </div>
              ) : showBusiness === "create_question" ? (
                <CreateQuestion setCreateQuestion={setCreateQuestion} />
              ) : (
                ""
              )}
            </div>
            {showBusiness === "update_question" || showBusiness === "create_question" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  paddingBlock: "10px",
                }}
              >
                <FPButton
                  label="Back"
                  size="small"
                  onClick={handleShowBusinessScreen}
                  disabled={primaryDetail?.is_suspend}
                />
                {/* <FPButton
                  label="Update"
                  size="small"
                  onClick={
                    showBusiness === "create_question"
                      ? handleCreateQuestion
                      : showBusiness === "update_question"
                      ? handleUpdateQuestion
                      : ""
                  }
                  disabled={primaryDetail?.is_suspend}
                /> */}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

// export default UpdateQuestion;

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateQuestion);
