import React from "react";
import Select from "react-select";
import "react-select/dist/react-select.css";
import { Icon } from "semantic-ui-react";
import classNames from "classnames";
import ErrorMessage from "./new/forms/ui/ErrorMessage";

const SelectComponent = (field) => {
  const {
    input,
    options,
    label,
    className,
    onChange,
    serverError,
    meta: { touched, error },
    val,
    labelKey,
    icon,
    ruleCreation,
  } = field;
  const handleFocus = (ev) => {
    input.onFocus(ev);
    // if (( !options && getOptions ) || ( !options.length  && getOptions )) {
    //   getOptions();
    // }
  };
  const handleChange = (val) => {
    input.onChange(val);
    if (val === "") {
      input.onChange([]);
    }
    if (onChange) onChange(val);
  };

  const handleBlur = () => {
    input.onBlur();
  };

  const renderOption = (option) => {
    return ruleCreation ? (
      <div style={{ display: "flex" }}>
        {option[labelKey]}
        <div style={{ width: "5px" }}></div>
        {icon && <Icon name={icon.name} color={icon.color || "grey"} />}
      </div>
    ) : (
      <div>
        {icon && <Icon name={icon.name} color={icon.color || "grey"} />}
        {option[labelKey]}
      </div>
    );
  };

  return (
    <div>
      <div>{label}</div>
      <Select
        className={classNames("select__component", className)}
        {...field}
        {...input}
        options={options}
        menuContainerStyle={{ zIndex: 999 }}
        onFocus={handleFocus}
        onChange={(val) => handleChange(val)}
        onBlur={handleBlur}
        onBlurResetsInput={false}
        value={input.value ? input.value : val}
        removeSelected={false}
        optionRenderer={renderOption}
        valueRenderer={renderOption}
      />
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
      {serverError && <ErrorMessage>{serverError}</ErrorMessage>}
    </div>
  );
};

export default SelectComponent;
