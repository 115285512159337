import React from "react";
import { FPToolTip } from "../../../components/common/new";

const ProgressBar = ({ data = [] }) => { 
  if (!Array.isArray(data)) {
    console.error("Invalid data passed to ProgressBar. Expected an array.");
    return null;
  }

  const labels = data.map(item => item.priority); 
  const values = data.map(item => item.count); 
  const totalSum = values.reduce((sum, value) => sum + value, 0);

  const colors = {
    medium: "#FDA81B",
    urgent: "#E13F1A",
    high: "#F26E1A",
    low: "#858D9D",
  };
  const getColor = (priority) => colors[priority];

  return (
    <div className="sr-pr-container">
      <div className="sr-pr-progress-bar-container">
        <div className="sr-pr-progress-bar">
          {values.map((value, index) => {
            const zIndex = index + 1;

            return (
              <div
                key={index}
                className="sr-pr-progress-bar-segment"
                style={{
                  width: `${(value / totalSum) * 100}%`,
                  backgroundColor: getColor(labels[index]),
                  borderRadius: index === 0 ? "10px" : "0px 10px 10px 0px",
                  marginLeft: index === 0 ? 0 : "-10px",
                  zIndex: -(zIndex - 1),
                }}
              >
                <FPToolTip
                  title={
                    <div className="sr-pr-tooltip">
                      <span className="sr-pr-tooltip-label">{labels[index]}</span>
                      <br />
                      <span className="sr-pr-tooltip-value">
                        {value} ({Math.round((value / totalSum) * 100)}%)
                      </span>
                    </div>
                  }
                  followCursor={true}
                  arrow={true}
                  placement="top"
                  color={{
                    background: "#ffffff",
                    text: "#282c3f",
                    arrow: "#ffffff",
                  }}
                >
                  <p className="sr-pr-tooltip-hidden">{labels[index]}</p>
                </FPToolTip>
              </div>
            );
          })}
        </div>
        <div className="sr-pr-total">{totalSum}</div>
      </div>

      <div className="sr-pr-legend">
        {labels.map((label, index) => (
          <div key={index} className="sr-pr-legend-item">
            <span
              className="sr-pr-legend-color"
              style={{ backgroundColor: getColor(label) }}
            ></span>
            <span className="sr-pr-legend-label">{label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
