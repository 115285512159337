import React, { useContext, useEffect, useState } from "react";
import LoaderComponent from "../../../components/common/loaderComponent";
import { Box, FormControlLabel, Popover, Radio } from "@material-ui/core";
import { FPToolTip } from "../../../components/common/new";
import { NewFilter } from "../../../images/svg";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import queryString from "query-string";
import printIcon from "../../../images/Printer.png";
import downloadIcon from "../../../images/downloadReport.png";
import TrafficDrivingChart from "./TrafficDrivingChart";
import TrafficDrivingKeywordsTable from "./TrafficDrivingKeywordsTable";
import "./keywords.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as filterListActions from "../../../actions/common/filterListActions";
import * as URLS from "../../../libs/apiUrls";
import PerformanceFilter from "../Performance/PerformanceFilter";
import * as listingPerformance from "../../../actions/listing/listingPerformanceAction";
import * as setSelectedTab from "../../../actions/common/setSelectedTabAction";
import CommonPerformanceFilter from "../CommonPerformanceFilter";
import { SideNavContext } from "../../../Context/sideNavContext";

function KeywordsComponent(props) {
  const {
    actions,
    location: { search, pathname },
    location,
    loader,
    history,
    match,
    match: {
      params: { business_id },
    },
    filterAction,
    filter,
    listing_search_keywords,
  } = props;

  const printKeyword = () => {
    window.print();
  };

  const [view, setView] = useState("graph");
  const [showFilter, setShowFilter] = useState("false");
  const openFilter = Boolean(showFilter);
  const { isAccordionTabSelected } = useContext(SideNavContext);
  const toggleView = (viewVal) => {
    setView(viewVal);
  };
  const fetchFilterList = async () => {
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch(
          "",
          `${URLS.BUSINESS_SETUP}${business_id}/branch/?is_gmb_locations=true`
        ),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };

  const queryParams = new URLSearchParams(search);
  const currentPage = queryParams.get("page") || 1;
  const pageSize = queryParams.get("page_size") || 20;

  const Params = queryString.parse(search);
  const payload = {
    ...Params,
    page: Params.page === "graph" ? 1 : currentPage,
    page_size: Params.page === "graph" ? 20 : pageSize,
  };

  useEffect(() => {
    actions.getListingSearchKeywords(business_id, payload);
    isAccordionTabSelected();
  }, [search, business_id]);

  const exportSearchKeywords = async () => {
    const updatedPayload = { ...payload, download: true };

    try {
      await actions.getListingSearchKeywords(business_id, updatedPayload);
    } catch (error) {
      console.error("Export failed:", error);
    }
  };
  const getFilterData = (data) => {
    actions.getListingSearchKeywords(business_id, data);
  };
  useEffect(() => {
    document.title = "keywords";
    props.actionsSelectTab.setSelectedTab(2);
    setShowFilter(false);
  }, [props.actionsSelectTab]);

  useEffect(() => {
    fetchFilterList();
  }, []);

  useEffect(() => {
    setShowFilter(false);
  }, [search]);

  const onFilterBtnClick = (e) => {
    setShowFilter(e.currentTarget);
  };

  const closeFilterModel = () => {
    setShowFilter(!showFilter);
  };

  return (
    <>
      <LoaderComponent loader={loader} />
      <div className="mt-3">
        <>
          <div className="align-center justify-content-between mx-3">
            <Box
              className="mb-0"
              sx={{ color: "#1B1C1D !important", fontSize: "12px" }}
            >
              Last Updated on {listing_search_keywords?.last_update_date}
            </Box>
          </div>
          <div>
            <CommonPerformanceFilter
              history={history}
              location={location}
              match={match}
              getFilterData={getFilterData}
            />
          </div>
          {/* <div className="align-center justify-content-between mx-3">
            <Box
              className="mb-0"
              sx={{ color: "#1B1C1D !important", fontSize: "12px" }}
            >
              Last Updated on {listing_search_keywords?.last_update_date}
            </Box>
            <FPToolTip title="Filter">
              <div className="contact-filter-view" onClick={onFilterBtnClick}>
                <NewFilter
                  color="white"
                  style={{
                    height: "15px",
                    width: "15px",
                  }}
                />
              </div>
            </FPToolTip>
          </div> */}

          <CardWrapper
            className="mt-3"
            showDivider={false}
            headerTitle={
              <Box
                sx={{
                  color: "#1B1C1D",
                  fontSize: "22px",
                  fontWeight: 500,
                  marginTop: "5px",
                  marginBottom: "10px",
                }}
              >
                Traffic Driving Keywords
              </Box>
            }
          >
            <div className="performanceView">
              <div className="align-center justify-content-between">
                <Nav
                  tabs
                  className="box-shadow-none"
                  style={{ borderBottom: "none" }}
                >
                  <NavItem>
                    <NavLink
                      className={classnames({ active: view === "graph" })}
                      onClick={() => {
                        toggleView("graph");
                      }}
                    >
                      <FormControlLabel
                        value="Graph"
                        label="Graph"
                        control={<Radio />}
                        checked={view === "graph"}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: view === "table" })}
                      onClick={() => toggleView("table")}
                    >
                      <FormControlLabel
                        value="table"
                        control={<Radio />}
                        label="Table"
                        checked={view === "table"}
                      />
                    </NavLink>
                  </NavItem>
                </Nav>

                <div
                  className="d-flex justify-content-end gap-15"
                  style={{ height: "100%", borderRadius: "2px" }}
                >
                  <FPToolTip title={"Download"}>
                    <div
                      role="button"
                      onClick={exportSearchKeywords}
                      className="graph-Table-RightButton"
                    >
                      <img src={downloadIcon} alt="downloadIcon" />
                    </div>
                  </FPToolTip>
                  <FPToolTip title={"Print"}>
                    <div role="button">
                      <img
                        src={printIcon}
                        alt="printIcon"
                        onClick={printKeyword}
                      />
                    </div>
                  </FPToolTip>
                </div>
              </div>

              <TabContent className="w-100">
                {view === "graph" && (
                  <TrafficDrivingChart
                    listing_search_keywords={listing_search_keywords}
                  />
                )}
                {view === "table" && (
                  <TrafficDrivingKeywordsTable
                    listing_search_keywords={listing_search_keywords}
                    location={location}
                  />
                )}
              </TabContent>
            </div>
          </CardWrapper>
        </>
        {/* {showFilter && (
          <Popover
            open={openFilter}
            anchorEl={showFilter}
            onClose={closeFilterModel}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                borderRadius: 10,
                overflow: "visible",
              },
            }}
          >
            <PerformanceFilter
              location={props.location}
              onClose={closeFilterModel}
              history={props.history}
              locationOptions={filter?.branch?.choices}
              dateOptions={filter?.date_type?.choices}
            />
          </Popover>
        )} */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    filter: state.filter,
    loader: state.common.loader,
    listing_search_keywords: state.listingPerformance.listing_search_keywords,
  };
};

const mapDispatchToProps = (dispatch) => ({
  filterAction: bindActionCreators(filterListActions, dispatch),
  actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
  actions: bindActionCreators(listingPerformance, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(KeywordsComponent);
