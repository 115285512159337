import React, { useState } from "react";
import { Divider } from "semantic-ui-react";
import CardWrapper from "../dashboard/component/CardWrapper";
import { Avatar, Box, Popover } from "@material-ui/core";
import Redirect from "../../images/svg/Redirect.svg";
// import EyesIcon from "../../images/svg/EyeSvg.svg";
import Replay from "../../images/svg/replyIcon.svg";
import ThumbsUp from "../../images/svg/ThumsUp.svg";
import ThumbsDown from "../../images/svg/ThumsDown.svg";
import deleteIcon from "../../images/svg/deleteSvg.svg";
import AddComments from "./AddComments";
import moment from "moment";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import EmojiPicker from "emoji-picker-react";
import ThreadComponent from "./ThreadComponent";
import ImageSlider from "./ImageSlider";

const SocialCommentsCardPopup = (props) => {
  const { commentDetails, toggleTemplateOpen, getComments, pageData, socialCommentsActions } = props;
  const [commentReply, setCommentReply] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [commentData, setCommentData] = useState(null);
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);

  const handleReplyComment = (item) => {
    const req = {
      page_id: item.page_id,
      post_id: item.post_id,
      comment_text: replyText,
      comment_id: item.comment_id,
    };
    socialCommentsActions.postSocialComment(req).then((res) => {
      setReplyText("");
      setCommentReply(false);
      getComments(pageData);
    });
  };
  const toggleConfirm = () => {
    handleDeleteComment();
    setConfirm(false);
  };

  const handleClick = (data) => {
    setCommentData(data);
    setConfirm(!confirm);
  };

  const handleDeleteComment = () => {
    const deleteReq = {
      page_id: commentData.page_id,
      comment_id: commentData.comment_id,
      is_thread: false,
    };
    socialCommentsActions.deleteSocialComment(deleteReq).then((res) => {
      getComments(pageData);
    });
  };

  const handleReplyCommentChange = (e) => {
    setReplyText(e.target.value);
  };

  const handleCommentReply = () => {
    setCommentReply(!commentReply);
  };

  const handleEmojiSelect = ({ emoji }) => {
    setReplyText((preValue) => preValue + " " + emoji);
  };
  const handleClose = () => {
    setOpenEmojiPicker(null);
  };
  const open = Boolean(openEmojiPicker);

  const sliderImage = commentDetails.post_details.subattachments;

  return (
    <div className="row">
      <div className="col-md-6 pl-0">
        {sliderImage ? (
          <ImageSlider sliderImage={sliderImage} />
        ) : (
          <img
            alt=""
            src={commentDetails.post_details.image.src}
            style={{ width: "100%", height: "calc(100vh - 225px)", objectFit: "contain", borderRadius: "10px" }}
          />
        )}
      </div>
      <div className="col-md-6 px-0">
        <CardWrapper
          // key={`${item.id}-reviewCard`}
          className="fp-card-outline review-card-wrapper"
          showDivider={false}
        >
          <section className="review-card-body">
            <div className="d-flex gap-15">
              <Avatar src={commentDetails.post_from_profile_pic} />
              <div className="">
                <Box
                  component="span"
                  sx={{
                    color: "#1B1C1D",
                    fontSize: "15px",
                    fontWeight: 500,
                  }}
                >
                  {commentDetails.post_from_name}
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: "#7A8193",
                    display: "block",
                    fontSize: "13px",
                  }}
                >
                  Published by {commentDetails.post_details.published_by}
                </Box>
                <Box component="span" sx={{ color: "#7A8193", display: "block", fontSize: "15px" }}>
                  {commentDetails.post_message}
                </Box>
              </div>
            </div>
            <Divider />
            <div className="d-flex gap-20" style={{ maxHeight: "calc(100vh - 495px)", overflowY: "auto" }}>
              <div>
                <Avatar src={commentDetails.from_profile_pic} />
              </div>
              <div className="d-flex flex-column w-100">
                <div className="d-flex justify-content-between">
                  <div>
                    <Box
                      component="span"
                      sx={{
                        color: "#1B1C1D",
                        fontSize: "15px",
                        fontWeight: 500,
                      }}
                    >
                      {commentDetails.from_name}
                    </Box>
                    <Box
                      component="span"
                      sx={{
                        color: "#7A8193",
                        display: "block",
                        fontSize: "13px",
                      }}
                    >
                      {moment(commentDetails.created_time).format("llll")}
                    </Box>
                    <Box
                      component="span"
                      sx={{
                        color: "#7A8193",
                        display: "block",
                        fontSize: "15px",
                      }}
                    >
                      {commentDetails.message}
                    </Box>
                    <div className="d-flex gap-15 mt-2 align-items-center">
                      <Box component="div" sx={{ color: "#1B1C1D" }} className="d-flex align-items-center gap-10">
                        <div className="d-flex align-items-center gap-10">
                          <img src={ThumbsUp} alt="" />
                          <img src={ThumbsDown} alt="" />
                        </div>
                        <div
                          className="d-flex align-items-center gap-6 cursor-pointer"
                          onClick={() => handleCommentReply()}
                        >
                          <img src={Replay} style={{ height: "20px", width: "18px" }} alt="" /> Reply
                        </div>
                      </Box>
                    </div>
                  </div>
                  <div className="d-flex gap-10 align-items-baseline">
                    {/* <img src={EyesIcon} alt="EyesIcon" />                     */}
                    <img src={deleteIcon} alt="delete Icon" onClick={() => handleClick(commentDetails)} />
                  </div>
                </div>

                {commentDetails.threads.length > 0 &&
                  commentDetails.threads.map((item, i) => {
                    return (
                      <ThreadComponent
                        rpl={item}
                        key={i}
                        socialCommentsActions={socialCommentsActions}
                        getComments={getComments}
                        pageData={pageData}
                      />
                    );
                  })}
                {commentReply && (
                  <AddComments
                    item={commentDetails}
                    commentText={replyText}
                    handleCommentChange={handleReplyCommentChange}
                    toggleTemplateOpen={toggleTemplateOpen}
                    setOpenEmojiPicker={setOpenEmojiPicker}
                    handleReplyComment={handleReplyComment}
                  />
                )}
              </div>
            </div>

            <Divider />

            <div>
              <Box
                component="span"
                className="d-flex align-items-center gap-6"
                sx={{ fontSize: "16px", color: "#1B1C1D", cursor: "pointer" }}
                onClick={() => {
                  window.open(commentDetails.post_target_url, "_blank");
                }}
              >
                View Post <Redirect />
              </Box>
            </div>
          </section>
        </CardWrapper>
      </div>
      <ConfirmationModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader={`Are you sure you want to delete this comment?`}
        isOpen={confirm}
        toggle={() => setConfirm(!confirm)}
        onConfirm={() => toggleConfirm()}
      />
      <Popover
        open={open}
        anchorEl={openEmojiPicker}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <EmojiPicker
          skinTonesDisabled={true}
          width={300}
          size={10}
          lazyLoad={true}
          lazyLoadEmojis={true}
          onEmojiClick={handleEmojiSelect}
        />
      </Popover>
    </div>
  );
};

export default SocialCommentsCardPopup;
