/* eslint-disable */
import React, { useEffect } from "react";
import { FPFilter } from "../common/new";
// import { handleDefaultFilter } from "../../libs/utils";
import { Box } from "@material-ui/core";
import * as filterListActions from "../../actions/common/filterListActions";
import { getAllParamsInArrayForm, removeLifetimeFilter } from "../../libs/utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as URLS from "../../libs/apiUrls";
import * as commonAction from "../../actions/common/commonAction";
import * as TeamAction from "../../actions/team/teamAction";

const FilterComponent = (props) => {
  const {
    common,
    pageList,
    filter,
    branches,
    history,
    onFilterApply,
    filterReset,
    onReset,
    match,
    filterAction,
    commonActions,
    teamActions,
    teamList,
  } = props;
  const fetchFilterList = async () => {
    const {
      params: { business_id },
    } = match;
    const {
      match: { params },
    } = props;
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch(
          "",
          `${URLS.BUSINESS_SETUP}${business_id}/branch/`
        ),
        commonActions.cityOptions(business_id),
        teamActions.teamList(params, business_id),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };

  useEffect(() => {
    fetchFilterList();
  }, []);

  const handleDefaultFilter = (list, key) => {
    const filter = location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable =
      defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list?.map((item) => {
      item["isChecked"] =
        isKeyFilterAvailable &&
        defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };

  const filterList = [
    {
      id: 1,
      label: "Date Duration",
      key: "date_type",
      type: "radio",
      isSearchable: false,
      list:
        filter.date_type &&
        handleDefaultFilter(removeLifetimeFilter(filter?.date_type?.choices), "date_type"),
    },
    {
      id: 2,
      label: "Assigned to",
      key: "assign",
      type: "radio",
      isSearchable: false,
      list:
        teamList &&
        handleDefaultFilter(
          teamList &&
            teamList?.results?.map((item) => ({
              display_name: `${item.first_name} ${item.last_name}`,
              value: item.id,
            })),

          "assignees"
        ),
    },
    {
      id: 3,
      label: "Source",
      key: "platform",
      type: "radio",
      isSearchable: true,
      list: handleDefaultFilter(
        [
          {
            display_name: "Facebook",
            value: "facebook",
          },
          {
            display_name: "Instagram",
            value: "instagram",
          },
        ],
        "platform"
      ),
    },
    {
      id: 4,
      label: "Source Pages",
      key: "page_id",
      type: "radio",
      isSearchable: false,
      list:
        pageList &&
        handleDefaultFilter(
          pageList.map((item) => ({
            display_name: item.page_name,
            value: item.page_id,
          })),
          "page_id"
        ),
    },
    {
      id: 5,
      label: "Location",
      key: "branch_id",
      type: "radio",
      isSearchable: false,
      list:
        branches && Array.isArray(branches)
          ? handleDefaultFilter(branches, "branch_id")
          : [],
    },
    {
      id: 6,
      label: "City",
      key: "city",
      type: "radio",
      isSearchable: false,
      list:
        common.city_options &&
        handleDefaultFilter(common.city_options.choices, "city"),
    },
  ];

  return (
    <FPFilter
      data={filterList}
      className="mt-4"
      history={history}
      onApply={(queryObject) => onFilterApply(queryObject)}
      customResetFuc={({ history, pathname }) => {
        history.push({
          pathname,
          search: "",
        });
        onReset();
      }}
    />
  );
};

// export default FilterComponent;

const mapStateToProps = (state) => {
  return {
    common: state.common,
    filter: state.filter,
    teamList: state.team,

    // source: state.review.review_source_options,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonAction, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
    teamActions: bindActionCreators(TeamAction, dispatch),

    // actions: bindActionCreators(ReviewsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(FilterComponent);
