import React, { useState } from "react";
import { FPBadge, FPButton, FPToolTip } from "../../components/common/new";
import { Badge, makeStyles } from "@material-ui/core";
import FPChip from "../../components/common/new/FPChip/FPChip";
import { snakeToCapital } from "../../libs/utils";
import UserIcon from "../../images/svg/UserIcon";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import TicketComplete from "../../images/svg/TicketComplete";
import FormModal from "../../components/common/FormModal";
import CreateServiceRequest from "./CreateServiceRequest";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import queryString from "query-string";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  menuBox: {
    color: "#1B1C1D",
    fontSize: "13px",
    gap: 0,
    backgroundColor: "#F6F6F8",
    border: "unset",
    height: "27px",
  },
  button: {
    padding: "0 12px",
    fontSize: "13px",
    border: "unset",
    height: "27px",

    "& .MuiButton-label": {
      fontSize: "13px",
      fontWeight: "400",
    },
  },
}));

const ServiceDropdown = (props) => {
  const {
    item,
    ticketRequestTypeList,
    departmentList,
    actions,
    match,
    match: {
      params: { business_id },
    },
    locationList,
    teamList,
    location,
    profileId,
  } = props;
  const { search } = location;
  const [ticketResolveModal, setTicketResolveModal] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [comment, setComment] = useState("");
  const classes = useStyles({ isTicket: item.name });
  const [selectedFile, setSelectedFile] = useState(null);

  const params = {
    ...queryString.parse(search),
  };

  const toggleConfirm = () => {
    setTicketResolveModal(!ticketResolveModal);
  };

  const toggleEditClose = () => {
    setEditOpen(false);
  };
  const toggleEditOpen = () => {
    setEditOpen(true);
  };

  const editService = (data, id) => {    
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== 'attachment') {
        formData.append(key, data[key]);
      } else {
        const attachedFiles = data['attachment'];
        if (attachedFiles && attachedFiles.length > 0) {
          attachedFiles.forEach((file, index) => {
            if (file instanceof File) {
              formData.append(`attachment[${index}]`, file, file.name || 'unnamed-file');
            } else {
              console.warn('Attachment is not a valid File:', file);
            }
          });
        }
      }
    });
    
    actions
      .editServiceRequest(formData, business_id, id)
      .then(() => {
        toggleEditClose();
        actions.ServiceTicketListing(params, business_id);
      })
      .catch((error) => {
        console.error('Error during service request edit:', error);
      });
  };
  

  const addComment = (id, data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    // (comment || selectedFile) &&
    //   actions
    //     .addServiceRequestComment(business_id, id, formData)
    //     .then(() => {
    //       setComment("");
    //       setSelectedFile(null);
    //       actions.ServiceTicketListing(params, business_id);
    //     })
    //     .catch((err) => {
    //       setComment("");
    //       setSelectedFile(null);
    //     });
  };

  const getStatusColor = (status) => {
    const statusColor = {
      in_queue: "#5826ef",
      assigned: "#f8ae00",
      resolved: "#17b26a",
      reopened: "#e16b16",
      waiting_for_customer: "#FFDC54",
      in_progress: "#0096FF",
    };
    return statusColor[status];
  };

  const getBorderColor = (priority) => {
    const priorityColor = {
      low: "#858d9d",
      high: "#EF6820",
      medium: "#FDB022",
      urgent: "#D92D20",
    };
    return priorityColor[priority];
  };

  const getBgColor = (priority) => {
    const priorityColor = {
      low: "#d1d1d1",
      high: "#FEF6EE",
      medium: "#FFFAEB",
      urgent: "#FEE4E2",
    };
    return priorityColor[priority];
  };

  const handleCloseTicket = () => {
    const data = {
      status: "resolved",
    };
    editService(data, item.id);
  };

  return (
    <div className="d-flex align-items-center gap-10 justify-content-end" style={{ height: "27px" }}>
      <FPChip
        size="small"
        variant="outlined"
        style={{ height: "27px", borderColor: "#BFC2CB" }}
        label={
          <div className="d-flex align-items-center justify-content-center">
            <FPBadge color={getStatusColor(item.status)} size="11" />
            &nbsp;&nbsp;
            <span style={{ color: "#1B1C1D", fontWeight: "400", fontSize: "13px" }}>{snakeToCapital(item.status)}</span>
          </div>
        }
      />
      {item.assignees && (
        <div
          style={{
            borderRadius: "30px",
            height: "27px",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #BFC2CB",
          }}
          className="d-flex flex-column gap-10 px-2"
        >
          <div className="d-flex gap-2 align-items-center">
            <UserIcon />
            <p className="ml-1" style={{ color: "#1B1C1D", fontWeight: "400", fontSize: "13px" }}>
              {item?.assignee_name?.name?.split(" ")[0]}
            </p>
          </div>
        </div>
      )}
      {item.priority && (
        <FPChip
          size="small"
          variant="outlined"
          style={{
            height: "27px",
            borderColor: getBorderColor(item.priority),
            backgroundColor: getBgColor(item.priority),
          }}
          label={
            <div className="d-flex align-items-center justify-content-center">
              <FPBadge color={getBorderColor(item.priority)} size="11" />
              &nbsp;&nbsp;
              <span style={{ color: "#1B1C1D", fontWeight: "400", fontSize: "13px" }}>
                {snakeToCapital(item.priority)}
              </span>
            </div>
          }
        />
      )}
      <FPToolTip title="View Service Request">
        <Badge
          badgeContent={item.message_no && (item.message_no === 0 ? 0 : `+${item.message_no}`)}
          color="primary"
          className="ticket-badge"
        >
          <FPButton
            startIcon={<ConfirmationNumberOutlinedIcon style={{ color: "#FFFFFF", width: "15px", height: "15px" }} />}
            backgroundColor={"#F13A88"}
            textColor={"#FFFFFF"}
            label={`#SR${item.service_request_id.toString().padStart(2, "0")}`}
            size={"small"}
            className={classes.button}
            onClick={toggleEditOpen}
          />
        </Badge>
      </FPToolTip>
      <FPToolTip title="Resolve Service Request">
        <span
          style={{ marginLeft: 10 }}
          onClick={() => setTicketResolveModal(true)}
          className={classNames({
            "fp-disabled": item.status === "resolved",
          })}
        >
          <TicketComplete />
        </span>
      </FPToolTip>
      {editOpen && (
        <FormModal
          isOpen={editOpen}
          toggle={toggleEditClose}
          heading="View Service Request Details"
          width={"100%"}
          maxWidth={"lg"}
          headerClass="d-flex align-items-center"
          paddingDense
        >
          <CreateServiceRequest
            departmentList={departmentList}
            ticketRequestTypeList={ticketRequestTypeList}
            actions={actions}
            match={match}
            item={item}
            editService={editService}
            locationList={locationList}
            teamList={teamList}
            addComment={addComment}
            setComment={setComment}
            comment={comment}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            profileId={profileId}
          />
        </FormModal>
      )}
      <ConfirmationModal
        header="Are you Sure!"
        subHeader="Are you sure you want to resolve this Service Request?"
        isOpen={ticketResolveModal}
        toggle={toggleConfirm}
        success
        onConfirm={handleCloseTicket}
        style={{ fontWeight: 600, fontSize: "20px", color: "#1B1C1D" }}
      />
    </div>
  );
};

export default ServiceDropdown;
