import React, { useState } from "react";
import { FPButton, MessageBox } from "../common/new";
import FormModal from "../common/FormModal";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FPTextArea from "../common/new/forms/ui/FPTextArea";
import Highlighter from "react-highlight-words";
import { getParams } from "../../libs/utils";
import { ManageTemplatesIcon } from "../../images/svg";

const SmartResponseModel = (props) => {
  const {
    onSend,
    inteligentResponse,
    isOpen,
    toggle,
    handleCommentChange,
    selectedItem,
    responseOptions,
    manageTemplates,
    is_superuser,
    getResponse,
    description,
    location,
  } = props;

  const [activeRes, setActiveRes] = useState("");
  const search = getParams(location.search, "search") || "";
  return (
    <FormModal
      bodyClass="pb-0"
      isOpen={isOpen}
      toggle={toggle}
      width={"100%"}
      maxWidth={"lg"}
      heading="Your Smart Templates"
      paddingDense={true}
    >
      <form>
        <div>
          {description && (
            <MessageBox isSelfBox={false} className={"mw-100"}
            >
              <Highlighter
                highlightStyle={{
                  color: "green",
                  fontWeight: "bold",
                }}
                searchWords={[search]}
                textToHighlight={description}

              ></Highlighter>
            </MessageBox>
          )}
        </div>
        <div className="d-flex gap-10 mt-4">
          <div
            className="smart-response-model"
            style={{
              maxHeight: "470px",
              overflowY: "auto", 
              paddingRight: "10px",
              minWidth: "290px"
              }}
          >
            {responseOptions &&
              responseOptions.map((item, index) => (
                <div key={index}>
                  <FPButton
                    label={
                      item.display_name ? item.display_name : "Smart Responses"
                    }
                    variant="outline"
                    style={{
                      width: "fitContent",
                      borderRadius: "15px",
                      marginBottom: "10px",
                      color: "#1B1C1D",
                      padding: "10px",
                      backgroundColor:
                        item.value === activeRes.value ? "#ffffff" : "#F6F6F8",
                      borderColor:
                        item.value === activeRes.value ? "#438e96" : "#bfc2cb",
                    }}
                    size="small"
                    onClick={() => {
                      getResponse(item);
                      setActiveRes(item);
                    }}
                  />
                </div>
              ))}
          </div>

          <div className="w-100 ">
            <FPTextArea
              autoHeight
              value={inteligentResponse}
              onChange={handleCommentChange}
              placeholder="Comment"
              // style={{
              //   fontSize: '14px',
              //   background: 'rgb(246, 246, 248)',
              //   height: '178px',
              //   padding: '16px',
              //   border: 'none',
              //   borderRadius: '10px',
              //   marginBottom: '10px',
              // }}
              sx={{
                // backgroundColor: "rgb(246, 246, 248)",
                borderRadius: "10px",
                // paddingBottom: "0",
                "& .fp-textarea": {
                  // backgroundColor: "rgb(246, 246, 248)",
                  fontSize: "14px",
                  border: "none",
                  maxHeight: 430,
                  minHeight: 430,
                  scrollbarWidth:"10px"
                  // marginBottom: "30px",
                },
              }}
              minRows={10}
            />
          </div>
        </div>
      </form>
      <div className="w-100 d-flex justify-content-between gap-10 mb-2 mt-2">
        <FPButton
          label="Manage Templates"
          size="small"
          backgroundColor="#438E96"
          onClick={manageTemplates}
          startIcon={<ManageTemplatesIcon />}
        />
        {
        selectedItem &&
          selectedItem.is_reply_allow === true &&
          (
            <FPButton
              type="submit"
              label="Send"
              size="small"
              endIcon={<SendOutlinedIcon />}
              onClick={onSend}
            />
          )}
      </div>
    </FormModal>
  );
};

export default SmartResponseModel;
