import React, { useEffect, useState } from 'react'
import { FPBadge, FPButton, FPMenuBox, FPToolTip } from '../../../components/common/new';
import FPChip from '../../../components/common/new/FPChip/FPChip';
import UserIcon from '../../../images/svg/UserIcon';
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import TicketComplete from '../../../images/svg/TicketComplete';
import classNames from "classnames";
import { lightCommentColor } from '../../../libs/constant';
import { snakeToCapital } from '../../../libs/utils';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import { Badge, makeStyles } from '@material-ui/core';


const EmailHeaderActivity = (props) => {
  const {
    item,
    replyDisabled,
    ticketActivity,
    toggleTicketModal,
    changeStatus,
    respondedMenubox,
  } = props;
  const [ticketResolveModal, setTicketResolveModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const useStyles = makeStyles((theme) => ({
    menuBox: {
      color: "#1B1C1D",
      fontSize: screenWidth < 1440 ? "11px" : "12px",
      gap: 0,
      backgroundColor: "#F6F6F8",
      border: "unset",
      padding: screenWidth < 1440 ? "5px 12px" : "5px 12px"
    },
    button: {
      padding: "5px 8px",
      fontSize: "11px",
      border: "unset",

      "& .MuiButton-label": {
        fontSize:screenWidth < 1440 ? "11px" : "12px",
        fontWeight: "400",
      },
    },
  }));

  const toggleConfirm = () => {
    setTicketResolveModal(!ticketResolveModal);
  };

  const serviceDelivery = [
    { label: "Delivery", value: "Delivery" },
    { label: "Dining", value: "Dining" },
    { label: "Takeaway", value: "Takeaway" },
  ];

  const statusData = [
    { label: "Responded", value: "Responded" },
    { label: "Not responded", value: "Not responded" },
    { label: "Deleted", value: "Deleted" },
  ];

  const uniqueCreatedBy = {};

  // Iterate through the comments array and mark each 'created_by' value as true in the uniqueCreatedBy object
  item &&
    item?.comment?.length &&
    item?.comment?.map((comment) => {
      uniqueCreatedBy[comment.created_by] = true;
    });

  const uniqueCreatedByArray = Object.keys(uniqueCreatedBy);

  const getColor = (name) => {
    const index = uniqueCreatedByArray.findIndex(
      (commenterName) => commenterName === name
    );
    return lightCommentColor[index + 1] || lightCommentColor[0];
  };

  const getStatusColor = (status) => {
    const statusColor = {
      new: "#2375ef",
      assigned: "#f8ae00",
      resolved: "#17b26a",
      reopened: "#e16b16",
      waiting_for_customer: "#FFDC54",
    };
    return statusColor[status];
  };

  const classes = useStyles({ isTicket: item?.ticket });
  const ticketId = item?.ticket_id && item?.ticket_id.split("ticket")[1];

  return (
    <div
      className="d-flex align-items-center justify-content-end"
      style={{ gap: screenWidth < 1440 ? "5px" : "10px" }}
    >
      {respondedMenubox && (
        <FPMenuBox
          title={item?.assignee_name}
          options={statusData}
          className={classes.menuBox}
          rounded={true}
          size={"small"}
          dropDownArrowMargin={screenWidth < 1440 ? "2px" : "10px"}
        />
      )}

      {/* {!replyDisabled && (
        <FPMenuBox
          title={
            ["offline", "customercare", "tripadvisor"].includes(
              item?.source_data?.provider_name
            )
              ? "Dining"
              : "Delivery"
          }
          options={serviceDelivery}
          className={classes.menuBox}
          rounded={true}
          size={"small"}
          dropDownArrowMargin={screenWidth < 1440 ? "2px" : "10px"}
        />
      )} */}

      {/* View ticket  */}
      <FPChip
        size="small"
        variant="outlined"
        style={{ height: screenWidth < 1440 ? "32px" : "30px", borderColor: "#BFC2CB" }}
        label={
          <div className="d-flex align-items-center justify-content-center">
            <FPBadge color={getStatusColor(item?.status)} size="11" />
            &nbsp;&nbsp;
            <span
              style={{ color: "#1B1C1D", fontWeight: "400", fontSize:screenWidth < 1440 ? "11px" : "12px" }}
            >
              {snakeToCapital(item?.status)}
            </span>
          </div>
        }
      />
      {item?.assignee_name && (
        <div
          style={{
            borderRadius: "30px",
            height: screenWidth < 1440 ? "32px" : "30px",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #BFC2CB",
          }}
          className="d-flex flex-column gap-10 px-2"
        >
          <div className="d-flex gap-2 align-items-center">
            <UserIcon />
            <p
              className="ml-1"
              style={{ color: "#1B1C1D", fontWeight: "400", fontSize:screenWidth < 1440 ? "11px" : "12px" }}
            >
              {item?.assignee_name || "Unassigned"}
            </p>
          </div>
        </div>
      )}
      {item?.source_data?.ticket ? (
        <div className="d-flex align-center">
          <FPToolTip title="View Ticket">
            <Badge
              badgeContent={
                item?.no_of_ticket_comments === 0
                  ? 0
                  : `+${item?.no_of_ticket_comments}`
              }
              color="primary"
              className="email-ticket-badge"
            >
              <FPButton
                disabled={!ticketActivity}
                onClick={() => toggleTicketModal(item)}
                startIcon={
                  <ConfirmationNumberOutlinedIcon
                    style={{ color: "#FFFFFF", width: "15px", height: "15px" }}
                  />
                }
                backgroundColor={ticketId ? "#F13A88" : "#F6F6F8"}
                textColor={ticketId ? "#FFFFFF" : "#1B1C1D"}
                label={ticketId}
                size={"small"}
                className={classes.button}
              />
            </Badge>
          </FPToolTip>
          <FPToolTip title="Resolve Ticket">
            <span
              style={{ marginLeft: 10 }}
              onClick={() => setTicketResolveModal(true)}
              className={classNames({
                "fp-disabled": item?.status === "resolved",
              })}
            >
              <TicketComplete />
            </span>
          </FPToolTip>
        </div>
      ) : (
        <div className="d-flex align-center">
          <FPToolTip title="View Ticket">
            {/* <Badge
              badgeContent={
                item?.no_of_ticket_comments &&
                (item?.no_of_ticket_comments === 0
                  ? 0
                  : `+${item?.no_of_ticket_comments}`)
              }
              color="primary"
              className="ticket-badge"
            > */}
              <FPButton
                disabled={!ticketActivity}
                onClick={() => toggleTicketModal(item)}
                startIcon={
                  <ConfirmationNumberOutlinedIcon
                    style={{ color: "#FFFFFF", width: "15px", height: "15px" }}
                  />
                }
                backgroundColor={ticketId ? "#F13A88" : "#F6F6F8"}
                textColor={ticketId ? "#FFFFFF" : "#1B1C1D"}
                label={ticketId}
                size={"small"}
                className={classes.button}
              />
            {/* </Badge> */}
          </FPToolTip>
          <FPToolTip title="Resolve Ticket">
            <span
              style={{ marginLeft: screenWidth < 1440 ? 5 :10 }}
              onClick={() => setTicketResolveModal(true)}
            >
              <TicketComplete width={screenWidth < 1440 ? 27 :30} height={screenWidth < 1440 ? 28: 30}/>
            </span> 
          </FPToolTip>
        </div>
      )}
      <ConfirmationModal
        header="Are you sure!"
        subHeader="Are you sure you want to resolve this ticket?"
        isOpen={ticketResolveModal}
        toggle={toggleConfirm}
        onConfirm={changeStatus}
        success
      />
    </div>
  );
};

export default EmailHeaderActivity