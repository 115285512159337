import React from 'react';
import { Row, Col, Card } from 'reactstrap';

const ServerErrorPage = () => {
  return (
    <Row className="align-items-center justify-content-center login-bg full-height" >
      <Col xs={12} sm={6}>
        <Row className="justify-content-center">
          <Col xs={10} sm={10}>
            <Card body outline>
              <div className="mx-5 py-3">
                <h2 className="text-center mt-4 heading-text">Server Error</h2>
                <h1 className="text-center text-lg">500</h1>
                <div className="text-center pt-3">
                  <p>Go back to <a href="/login">Login</a></p>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ServerErrorPage;
