import React from 'react';
import { Form, Header, Segment, Image, Grid } from 'semantic-ui-react';
import { Row, Col, Button } from 'reactstrap';
import surveyThanks from '../../images/reviewMethods/surveyThanks.png';
import offerImage from '../../images/black-friday.png';
import { BiSolidDiscount } from 'react-icons/bi';
import moment from 'moment';


class SurveyThanksPage extends React.Component {
  constructor(props) {
    super(props);
    this.copyCode = this.copyCode.bind(this);
    this.state = {copyCodeBtnText:'Copy Code'};
      
  }

  copyCode() {
    const copyText = document.getElementById('couponCodeButton');
    function listener(e) {
      e.clipboardData.setData('text/plain', copyText.innerHTML);
      e.preventDefault();
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.setState({ copyCodeBtnText: 'Code Copied' });
  }
  
  render() {
    const {business, initialValue, include_offer, offer_data, thanks_line, thanksPageImage} = this.props;

    let final_line_new = thanks_line && thanks_line.replace('{customer name}', initialValue.name);
    return (
      <div className='BackGorund__img'>
        <Grid textAlign='center' style={{}} verticalAlign='middle'>
          <Grid.Column textAlign='left' text style={{ maxWidth: 380}}>
            <Form size='large' >
              <Segment stacked rounded style={{ height: 'auto', borderRadius: '20px' ,backgroundColor:'white'}}>
                {thanksPageImage ? 
                  <div><img src={thanksPageImage} /></div>
                  : <div>
                      <Header as='h2' textAlign='center' style={{ marginBottom: '20px', color: 'darkgreen', fontSize: '32px' }}>
                        <Image src={business ? business.logo : ''} />
                      </Header>
                      {(include_offer && offer_data) ?
                        <div className='dashedBorderThankYouPage' style={{border: '1px dashed', borderRadius:'40px', padding:'5px', height:'auto'}}>
                          <Row className='mb-3'>
                            <Col md='7' style={{textAlign:'left', marginTop:'30px', fontSize:'20px', fontWeight:'bold', paddingLeft:'25px'}}>
                              {offer_data.offer}
                            </Col>
                            <Col md='5'>
                              <Image src={offerImage}  style={{height:'150px'}} />
                            </Col>
                          </Row>
                          <Row>
                            <Col md='3' style={{textAlign:'center'}}>
                              <BiSolidDiscount color='green' style={{width: '40px', height: '40px'}}/>
                            </Col>
                            <Col md='4' style={{border:'1px solid black', borderRadius:'5px'}}>
                              <p id='couponCodeButton' style={{marginBottom:'-10px', marginTop:'5px'}}>{offer_data.coupon_code}</p>
                              <small style={{fontSize:'6px'}}>{moment(offer_data.issued_at).format('MMM Do[,] YY [at] hh:mm A')}</small>
                            </Col>
                            <Col md='5' style={{textAlign:'center', marginTop:'3px'}}>
                              <Button color="success" onClick={this.copyCode}>{this.state.copyCodeBtnText}</Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col md='12' style={{padding:'23px'}}>
                              Instructions & Terms - <br />
                              {offer_data.terms}
                            </Col>
                          </Row>
                          {offer_data.terms_url && 
                            <Row style={{padding:'10px'}}>
                              <Col md='2'>
                                T&C:-
                              </Col>
                              <Col md='10'>
                                <a rel='noreferrer' href={offer_data.terms_url} target='_blank'>{offer_data.terms_url}</a>
                              </Col>
                            </Row>
                          }
                        </div>
                        :
                        <div>
                          <Image style={{height:'250px' , marginLeft:'25px'}} src={surveyThanks}/>
                          <Header as="h3" style={{color:'#0F62FE'}} textAlign='center'>
                            {final_line_new && final_line_new.split('\n').map((item, index)=>{
                              return (
                                <p key={index} style={{marginBottom:'0'}}>{item}</p>
                              );
                            })}
                          </Header>
                        </div>
                      }
                    </div>

                }


                {/* <Header as='h2' textAlign='center' style={{ marginBottom: '20px', color: 'darkgreen', fontSize: '32px' }}>
                  <Image src={business ? business.logo : ''} />
                </Header>
                {(include_offer && offer_data) ?
                  <div className='dashedBorderThankYouPage' style={{border: '1px dashed', borderRadius:'40px', padding:'5px', height:'auto'}}>
                    <Row className='mb-3'>
                      <Col md='7' style={{textAlign:'left', marginTop:'30px', fontSize:'20px', fontWeight:'bold', paddingLeft:'25px'}}>
                        {offer_data.offer}
                      </Col>
                      <Col md='5'>
                        <Image src={offerImage}  style={{height:'150px'}} />
                      </Col>
                    </Row>
                    <Row>
                      <Col md='3' style={{textAlign:'center'}}>
                        <BiSolidDiscount color='green' style={{width: '40px', height: '40px'}}/>
                      </Col>
                      <Col md='4' style={{border:'1px solid black', borderRadius:'5px'}}>
                        <p id='couponCodeButton' style={{marginBottom:'-10px', marginTop:'5px'}}>{offer_data.coupon_code}</p>
                        <small style={{fontSize:'6px'}}>{moment(offer_data.issued_at).format('MMM Do[,] YY [at] hh:mm A')}</small>
                      </Col>
                      <Col md='5' style={{textAlign:'center', marginTop:'3px'}}>
                        <Button color="success" onClick={this.copyCode}>{this.state.copyCodeBtnText}</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col md='12' style={{padding:'23px'}}>
                        Instructions & Terms - <br />
                        {offer_data.terms}
                      </Col>
                    </Row>
                    {offer_data.terms_url && 
                      <Row style={{padding:'10px'}}>
                        <Col md='2'>
                          T&C:-
                        </Col>
                        <Col md='10'>
                          <a rel='noreferrer' href={offer_data.terms_url} target='_blank'>{offer_data.terms_url}</a>
                        </Col>
                      </Row>
                    }
                  </div>
                  :
                  <div>
                    <Image style={{height:'250px' , marginLeft:'25px'}} src={surveyThanks}/>
                    <Header as="h3" style={{color:'#0F62FE'}} textAlign='center'>
                      {final_line_new && final_line_new.split('\n').map((item, index)=>{
                        return (
                          <p key={index} style={{marginBottom:'0'}}>{item}</p>
                        );
                      })}
                    </Header>
                  </div>
                } */}
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default SurveyThanksPage;