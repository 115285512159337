/**
 * Project - fbs-web-app
 * Created by vivekgusain on 10/2/19
 */
import React from "react";
import { Radio, Image } from "semantic-ui-react";
import { Row, Col } from "reactstrap";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";

import CustomRatingComponent from "../common/customRating";

import thumbsUp from "../../images/reviewMethods/like-outline.png";
import thumbsUpActive from "../../images/reviewMethods/like-filled.png";
import thumbDown from "../../images/reviewMethods/dislike-outline.png";
import thumbDownActive from "../../images/reviewMethods/dislike-filled.png";
import star from "../../images/reviewMethods/star_B_W.png";
import star_active from "../../images/reviewMethods/star_Color.png";
import FaceOne from "../../images/svg/FaceOne";
import FaceFillOne from "../../images/svg/FaceFillOne";
import FaceTwo from "../../images/svg/FaceTwo";
import FaceFillTwo from "../../images/svg/FaceFillTwo";
import FaceThree from "../../images/svg/FaceThree";
import FaceFillThree from "../../images/svg/FaceFillThree";
import FaceFour from "../../images/svg/FaceFour";
import FaceFillFour from "../../images/svg/FaceFillFour";
import FaceFive from "../../images/svg/FaceFive";
import FaceFillFive from "../../images/svg/FaceFillFive";
import FaceSix from "../../images/svg/FaceSix";
import FaceFillSix from "../../images/svg/FaceFillSix";
import FaceSeven from "../../images/svg/FaceSeven";
import FaceFillSeven from "../../images/svg/FaceFillSeven";
import FaceEight from "../../images/svg/FaceEight";
import FaceFillEight from "../../images/svg/FaceFillEight";
export default class ReviewMethodsOptions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      thumbsUpHover: false,
      thumbsDownHover: false,
      smileyHappyHover: false,
      smileyNeutralHover: false,
      smileyUnHappyHover: false,
      smileyFourHover: false,
      smileyFiveHover: false,
      smileySixHover: false,
      smileySevenHover: false,
      smileyEightHover: false,
      nps_array: Array(10).fill(false),
    };
  }

  handleHover = (stateName, value) => {
    this.setState({ [stateName]: value });
  };

  handleNpsHover = (index, value) => {
    let newArray = [...this.state.nps_array];
    newArray[index] = value;
    // newArray = newArray.fill(value, 0, index + 1);
    // console.log('newArray',newArray);
    this.setState({ nps_array: [...newArray] });
  };

  render() {
    const { reviewMethod, handleReviewMethod } = this.props;
    const {
      thumbsUpHover,
      thumbDownHover,
      smileyHappyHover,
      smileyNeutralHover,
      smileyUnHappyHover,
      smileyFourHover,
      smileyFiveHover,
      smileySixHover,
      smileySevenHover,
      smileyEightHover,
    } = this.state;
    return (
      <div className="review-methods">
        <Row>
          <Col>
            <h6 className="mb-2">Select Methods</h6>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex flex-column gap-10">
            {/* Recommendation */}
            <div className="d-flex justify-content-between align-items-start">
              <Radio
                name="radioGroup"
                className="review-select-radio"
                value="thumb"
                checked={reviewMethod === "thumb"}
                onChange={handleReviewMethod}
                label="Recommendation"
              />
              <div>
                <span
                  onMouseOver={() => this.handleHover("thumbDownHover", true)}
                  onMouseLeave={() => this.handleHover("thumbDownHover", false)}
                >
                  <Image
                    className={
                      reviewMethod === "thumb"
                        ? "review-img-active"
                        : "review-img-inactive"
                    }
                    src={thumbDownHover ? thumbDownActive : thumbDown}
                    size="mini"
                    inline
                    style={{height:'30px',width:'30px'}}
                  />
                </span>
                <span
                  onMouseOver={() => this.handleHover("thumbsUpHover", true)}
                  onMouseLeave={() => this.handleHover("thumbsUpHover", false)}
                >
                  <Image
                    className={`mr-2 ${
                      reviewMethod === "thumb"
                        ? "review-img-active"
                        : "review-img-inactive"
                    }`}
                    src={thumbsUpHover ? thumbsUpActive : thumbsUp}
                    size="mini"
                    inline
                    style={{height:'30px',width:'30px'}}
                  />
                </span>
              </div>
            </div>

            {/* 3-Faces Emoji Sentiments */}
            <div className="d-flex justify-content-between align-items-start">
              <Radio
                name="radioGroup"
                className="review-select-radio"
                value="smiley"
                checked={reviewMethod === "smiley"}
                onChange={handleReviewMethod}
                label="3-Faces Emoji Sentiments"
              />
              <div>
                <span
                  onMouseOver={() =>
                    this.handleHover("smileyUnHappyHover", true)
                  }
                  onMouseLeave={() =>
                    this.handleHover("smileyUnHappyHover", false)
                  }
                  className={
                    reviewMethod === "smiley"
                      ? "review-img-active"
                      : "review-img-inactive"
                  }
                >
                  {smileyUnHappyHover ? <FaceFillOne /> : <FaceOne />}
                </span>
                <span
                  onMouseOver={() =>
                    this.handleHover("smileyNeutralHover", true)
                  }
                  onMouseLeave={() =>
                    this.handleHover("smileyNeutralHover", false)
                  }
                  className={`mr-2 ${
                    reviewMethod === "smiley"
                      ? "review-img-active"
                      : "review-img-inactive"
                  }`}
                >
                  {smileyNeutralHover ? <FaceFillTwo /> : <FaceTwo />}
                </span>
                <span
                  onMouseOver={() => this.handleHover("smileyHappyHover", true)}
                  onMouseLeave={() =>
                    this.handleHover("smileyHappyHover", false)
                  }
                  className={`mr-2 ${
                    reviewMethod === "smiley"
                      ? "review-img-active"
                      : "review-img-inactive"
                  }`}
                >
                  {smileyHappyHover ? <FaceFillThree /> : <FaceThree />}
                </span>
              </div>
            </div>

            {/* Star Ratings */}
            <div className="d-flex justify-content-between align-items-start">
              <Radio
                name="radioGroup"
                className="review-select-radio"
                value="star"
                checked={reviewMethod === "star"}
                onChange={handleReviewMethod}
                label="Star Ratings"
              />
              <Rater total={5}>
                <CustomRatingComponent
                  activeImg={star_active}
                  inActiveImg={star}
                  customClass={`mr-2 ${
                    reviewMethod === "star"
                      ? "review-img-active"
                      : "review-img-inactive"
                  }`}
                  imageWidth={32}
                />
              </Rater>
            </div>

            {/* 5-Faces Emoji Sentiments */}
            <div className="d-flex justify-content-between align-items-start">
              <Radio
                name="radioGroup"
                className="review-select-radio"
                value="smiley-5"
                checked={reviewMethod === "smiley-5"}
                onChange={handleReviewMethod}
                label="5-Faces Emoji Sentiments"
              />
              <div>
                <span
                  onMouseOver={() => this.handleHover("smileyFourHover", true)}
                  onMouseLeave={() =>
                    this.handleHover("smileyFourHover", false)
                  }
                  className={
                    reviewMethod === "smiley-5"
                      ? "review-img-active"
                      : "review-img-inactive"
                  }
                >
                  {smileyFourHover ? <FaceFillFour /> : <FaceFour />}
                </span>
                <span
                  onMouseOver={() => this.handleHover("smileyFiveHover", true)}
                  onMouseLeave={() =>
                    this.handleHover("smileyFiveHover", false)
                  }
                  className={`mr-2 ${
                    reviewMethod === "smiley-5"
                      ? "review-img-active"
                      : "review-img-inactive"
                  }`}
                >
                  {smileyFiveHover ? <FaceFillFive /> : <FaceFive />}
                </span>
                <span
                  onMouseOver={() => this.handleHover("smileySixHover", true)}
                  onMouseLeave={() => this.handleHover("smileySixHover", false)}
                  className={`mr-2 ${
                    reviewMethod === "smiley-5"
                      ? "review-img-active"
                      : "review-img-inactive"
                  }`}
                >
                  {smileySixHover ? <FaceFillSix /> : <FaceSix />}
                </span>
                <span
                  onMouseOver={() => this.handleHover("smileySevenHover", true)}
                  onMouseLeave={() =>
                    this.handleHover("smileySevenHover", false)
                  }
                  className={`mr-2 ${
                    reviewMethod === "smiley-5"
                      ? "review-img-active"
                      : "review-img-inactive"
                  }`}
                >
                  {smileySevenHover ? <FaceFillSeven /> : <FaceSeven />}
                </span>
                <span
                  onMouseOver={() => this.handleHover("smileyEightHover", true)}
                  onMouseLeave={() =>
                    this.handleHover("smileyEightHover", false)
                  }
                  className={`mr-2 ${
                    reviewMethod === "smiley-5"
                      ? "review-img-active"
                      : "review-img-inactive"
                  }`}
                >
                  {smileyEightHover ? <FaceFillEight /> : <FaceEight />}
                </span>
              </div>
            </div>

            {/* nps score */}
            <div className="d-flex justify-content-between align-items-start">
              <Radio
                name="radioGroup"
                className="review-select-radio"
                value="nps_score"
                checked={reviewMethod === "nps_score"}
                onChange={handleReviewMethod}
                label="Net Promoter Score (NPS)"
              />
              <div className="nps-score">
                {this.state.nps_array.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: "inline-block",
                        maxHeight: "35px",
                        maxWidth: "35px",
                      }}
                      className={`mr-2 ${
                        reviewMethod === "nps_score"
                          ? "review-img-active"
                          : "review-img-inactive"
                      }`}
                      key={index}
                      onMouseOver={() => this.handleNpsHover(index, true)}
                      onMouseLeave={() => this.handleNpsHover(index, false)}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={`nps-method-circles ${
                          item ? "nps-score-active" : "nps-score-inactive"
                        }`}
                      >
                        <div>{index + 1}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
