import React from "react";
import { Row, Col } from "reactstrap";
import GoogleIcon from "../../images/svg/googleSocial.svg";
import Img1 from "../../images/svg/userSocial.svg";
import Img2 from "../../images/svg/doneSocial.svg";
import Img3 from "../../images/svg/fileSocial.svg";
import { images } from "../../images";

class SetupGGLSocialAccounts extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.branch_id !== prevState.branch_id) {
      return { branch_id: nextProps.branch_id };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      branch_id: this.props.branch_id,
    };
  }

  componentDidMount() {
    const { checkLoggedIn } = this.props;
    checkLoggedIn("google");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.branch_id !== this.state.branch_id) {
      const { checkLoggedIn } = this.props;
      checkLoggedIn("google");
    }
  }

  render() {
    const { getAuthUrl, isLoggedIn } = this.props;
    return (
      <div>
        <Row>
          <Col>
            <div className="d-flex flex-column text-center">
              <img src={GoogleIcon} alt="Google" height="40px" />
              <span className="mt-2" style={{ fontSize: "18px", fontWeight: "400" }}>
                Connect Google My Business.
              </span>
            </div>
            <Row className="my-5 py-4">
              <Col className="text-center">
                <img alt="img 1"  src={Img1} height="70" />
                <p className="mt-3 px-3" style={{ fontSize: "14px", color: "#646565", fontWeight: "400" }}>
                  Manage all your Google My Business reviews from your account.
                </p>
              </Col>
              <Col className="text-center">
                <img alt="img 2" src={Img2} height="70" />
                <p className="mt-3 px-3" style={{ fontSize: "14px", color: "#646565", fontWeight: "400" }}>
                  Share positive reviews to your Google Pages
                </p>
              </Col>
              <Col className="text-center">
                <img alt="img 3" src={Img3} height="70" />
                <p className="mt-3 px-3" style={{ fontSize: "14px", color: "#646565", fontWeight: "400" }}>
                  Publish content and engage with your Google followers
                </p>
              </Col>
            </Row>
            <div className="text-center mt-4 mb-3">
              <button
                className="text-center mt-3 cursor-pointer social-google-account"
                onClick={() => getAuthUrl("google")}
              >
                <img alt="google icon" src={images.googleIcon} style={{ height: "20px", width: "20px" }} />
                <span>{isLoggedIn && isLoggedIn.connected ? "Log out from Google" : "Login with Google"}</span>
              </button>
              {isLoggedIn && isLoggedIn.connected && isLoggedIn.name ? (
                <p className="mt-2">
                  Logged in with this email id: <strong>{isLoggedIn.name}</strong>
                </p>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SetupGGLSocialAccounts;
