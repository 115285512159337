import React, { Fragment } from 'react';
import {  Col, Row } from 'reactstrap';
import { Header, Grid, Segment, Image, Icon } from 'semantic-ui-react';
import {  Field } from 'redux-form';
import Rater from 'react-rater';
import TextAreaComponent from '../common/TextAreaComponent';
import CustomRatingComponent from '../common/customRating';
import starActive from '../../images/reviewMethods/star_Color.png';
import starInActive from '../../images/reviewMethods/star_B_W.png';
import thumbsUpActive from '../../images/reviewMethods/thumbs-up_Color.png';
import thumbsUpInActive from '../../images/reviewMethods/thumbs-up_B_W.png';
import thumbDownActive from '../../images/reviewMethods/thumb-down_Color.png';
import thumbDownInActive from '../../images/reviewMethods/thumb-down_B_W.png';
import smileyHappyInActive from '../../images/reviewMethods/happy_B_W.png';
import smileyNeutralInActive from '../../images/reviewMethods/neutral_B_W.png';
import smileyUnHappyInActive from '../../images/reviewMethods/unhappy_B_W.png';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import FaceFour from "../../images/svg/FaceFour";
// import FaceFillFour from "../../images/svg/FaceFillFour";
import FaceFive from "../../images/svg/FaceFive";
// import FaceFillFive from "../../images/svg/FaceFillFive";
import FaceSix from "../../images/svg/FaceSix";
// import FaceFillSix from "../../images/svg/FaceFillSix";
import FaceSeven from "../../images/svg/FaceSeven";
// import FaceFillSeven from "../../images/svg/FaceFillSeven";
import FaceEight from "../../images/svg/FaceEight";
// import FaceFillEight from "../../images/svg/FaceFillEight";



class ExperiencePagePreview extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { ratingType, body, businessLogo, include_comment, heading, selectedBgColor, selectedFontType, Isvertical, selectedQueTextColor, showReviewMethods } = this.props;
    
    return (
      <Grid textAlign='center' style={{}} verticalAlign='middle'>
        <Grid.Column textAlign='left' text style={{ width: "100%" }}>
          <Segment stacked rounded style={{ height: '500px', borderRadius: '20px' ,backgroundColor:'white'}}>
            {businessLogo && (<Header as='h2' textAlign='center' style={{ marginBottom: Isvertical ? '10px': '20px', color: 'darkgreen', fontSize: Isvertical ? '10px': '20px' }}>
              <Image src={businessLogo ? businessLogo : ''} style={{maxHeight:'100px'}}/>
            </Header>)}

            {ratingType === 'star' && showReviewMethods &&
              (
                <Col style={{paddingBottom : Isvertical ? '' : '10px', color: selectedQueTextColor,}}>
                  <Header as="h3" textAlign='center' style={{fontFamily:selectedFontType}}>
                    {heading}
                  </Header>
                  <div style={{fontFamily:selectedFontType, backgroundColor:selectedBgColor, padding:'10px 0', borderRadius:'20px', marginBottom:'20px'}}>
                    <Col sm="12" className="no-label" style={{display: 'flex', justifyContent:'center', fontSize: '15px', marginLeft:'4px',marginTop:'10px'}}>
                      <Rater total={5} rating={5}>
                        <CustomRatingComponent
                          activeImg={starActive}
                          inActiveImg={starInActive}
                          customClass="review-method-img"
                          imageWidth={25}
                          style={{marginRight:'12px'}}
                        />
                      </Rater>
                    </Col>
                    <Header as='h6' style={{fontFamily:selectedFontType, display: 'flex', justifyContent:'center',fontSize: '10px',alignItems:'center', marginTop:'5px'}}>
                      <h6 style={{fontFamily:selectedFontType,color: selectedQueTextColor}}>Very Poor</h6>
                      <p style={{marginTop:'3px', fontFamily:selectedFontType,color: selectedQueTextColor,}}><Icon name='caret right' size='big' />----------------<Icon name='caret left' size='big' /></p>
                      <h6 style={{fontFamily:selectedFontType,color: selectedQueTextColor}}>Excellent</h6>
                    </Header>
                  </div>
                </Col>
              )
            }
            {ratingType === 'thumb' && showReviewMethods &&
              (
                <Col style={{paddingBottom : Isvertical ? '' : '10px', color: selectedQueTextColor,}}>
                  <Header as="h3" textAlign='center' style={{fontFamily:selectedFontType}}>
                    {heading}
                  </Header>
                  <Col sm="12" style={{display: 'flex', justifyContent:'center', fontSize: '30px',marginTop:'30px', marginBottom:'20px', fontFamily:selectedFontType, backgroundColor:selectedBgColor, padding:'10px 0', borderRadius:'20px'}}>
                    <span className="mr-5">
                      <img width={50} className="review-method-img" src={ thumbsUpActive} />
                      <p style={{fontSize:'10px', marginLeft:'10px', marginTop:'10px'}}>Best</p>
                    </span>
                    <span >
                      <img width={50} className="review-method-img" src={thumbDownActive} />
                      <p style={{fontSize:'10px', marginLeft:'18px', marginTop:'10px'}}>Worst</p>
                    </span>
                  </Col>
                </Col>
              )
            }
            {ratingType === 'smiley' && showReviewMethods &&
            (
              <Col style={{paddingBottom : Isvertical ? '' : '10px'}}>
                <Header as="h3" textAlign='center' style={{marginBottom:'-22px', fontFamily:selectedFontType}}>
                    {heading}
                </Header>
                <Col sm="12" style={{display: Isvertical ? 'flow' : 'flex', justifyContent:'center', fontSize: '30px',marginTop: include_comment ? '30px' : '50px', marginBottom:'20px', padding:'0 30px 0 70px'}}>
                  <span className="mr-5" style={{backgroundColor:selectedBgColor, fontFamily:selectedFontType, borderRadius:'25px', padding: Isvertical ? '5px 5px 5px 30px':'15px 10px', fontSize: '15px', textAlign: Isvertical ? 'left' : 'center', display: Isvertical ? 'block' : 'grid', marginBottom : Isvertical ? '15px' : ''}}>
                    <img width={40} className="review-method-img" src={smileyUnHappyInActive} />
                    <span  style={{maxWidth:'40px', marginTop:'10px', color: selectedQueTextColor, marginLeft : Isvertical ? '30px' : '', fontSize : Isvertical ? '15px' : '' }}>Bad</span>
                  </span>
                  <span className="mr-5" style={{backgroundColor:selectedBgColor, fontFamily:selectedFontType, borderRadius:'25px', padding: Isvertical ? '5px 5px 5px 30px':'15px 10px', fontSize: '15px', textAlign: Isvertical ? 'left' : 'center', display:Isvertical ? 'block' : 'grid', marginBottom : Isvertical ? '15px' : ''}} >
                    <img width={40} className="review-method-img" src={smileyNeutralInActive} />
                    <span  style={{maxWidth:'40px', marginTop:'10px', color: selectedQueTextColor, marginLeft : Isvertical ? '30px' : '', fontSize : Isvertical ? '15px' : ''}}>Avg</span>
                  </span>
                  <span  className={Isvertical ? 'mr-5' : 'mr-2'} style={{backgroundColor:selectedBgColor, fontFamily:selectedFontType, borderRadius:'25px', padding: Isvertical ? '5px 5px 5px 30px':'15px 10px', fontSize: '15px', textAlign: Isvertical ? 'left' : 'center', display:Isvertical ? 'block' : 'grid', marginBottom : Isvertical ? '15px' : ''}}>
                    <img width={40} className="review-method-img" src={smileyHappyInActive} />
                    <span  style={{maxWidth:'40px', marginTop:'10px', color: selectedQueTextColor, marginLeft : Isvertical ? '30px' : '', fontSize : Isvertical ? '15px' : ''}}>Good</span>
                  </span>
                </Col>
              </Col>
            )
            }
            {ratingType === 'smiley-5' && showReviewMethods &&
            (
              <Col style={{paddingBottom : Isvertical ? '' : '10px'}}>
                <Header as="h3" textAlign='center' style={{marginBottom:'-22px'}}>
                  {heading}
                </Header>
                <Col sm="12" style={{display: Isvertical ? 'flow' : 'flex', justifyContent:'center', fontSize: '30px',marginTop: include_comment ? '30px' : '50px', marginBottom:'20px', padding:'0 50px'}}>
                  <span className="mr-3" style={{backgroundColor:selectedBgColor, fontFamily:selectedFontType, borderRadius:'25px', padding: include_comment ? Isvertical ? '5px 5px 5px 30px':'15px 10px' : Isvertical ? '20px 5px 20px 30px':'15px 10px', fontSize:'10px', textAlign: 'justify', display:Isvertical ? 'block' : 'grid', marginBottom : Isvertical ? '5px' : ''}}>
                    <FaceEight  style={{marginLeft:'5px', height: Isvertical ? '15px!important' : '28px', width: Isvertical ? '15px!important' : '28px' }}/>
                    <span  style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: selectedQueTextColor, marginLeft : Isvertical ? '30px' : '', fontSize : Isvertical ? '15px' : ''}}>VERY GOOD</span>
                  </span>
                  <span className="mr-3" style={{backgroundColor:selectedBgColor, fontFamily:selectedFontType, borderRadius:'25px', padding: include_comment ? Isvertical ? '5px 5px 5px 30px':'15px 10px' : Isvertical ? '20px 5px 20px 30px':'15px 10px', fontSize: '10px', textAlign: 'justify', display:Isvertical ? 'block' : 'grid', marginBottom : Isvertical ? '5px' : ''}}>
                    <FaceSeven  style={{marginLeft:'5px', height: Isvertical ? '15px!important' : '28px', width: Isvertical ? '15px!important' : '28px' }}/>
                    <span  style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: selectedQueTextColor, marginLeft : Isvertical ? '30px' : '', fontSize : Isvertical ? '15px' : ''}}>GOOD</span>
                  </span>
                  <span className="mr-3" style={{backgroundColor:selectedBgColor, fontFamily:selectedFontType, borderRadius:'25px', padding: include_comment ? Isvertical ? '5px 5px 5px 30px':'15px 10px' : Isvertical ? '20px 5px 20px 30px':'15px 10px', fontSize:'10px', textAlign: 'justify', display:Isvertical ? 'block' : 'grid', marginBottom : Isvertical ? '5px' : ''}}>
                    <FaceSix  style={{marginLeft:'5px', height: Isvertical ? '15px!important' : '28px', width: Isvertical ? '15px!important' : '28px' }}/>
                    <span  style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: selectedQueTextColor, marginLeft : Isvertical ? '30px' : '', fontSize : Isvertical ? '15px' : ''}}>AVG</span>
                  </span>
                  <span className="mr-3" style={{backgroundColor:selectedBgColor, fontFamily:selectedFontType, borderRadius:'25px', padding: include_comment ? Isvertical ? '5px 5px 5px 30px':'15px 10px' : Isvertical ? '20px 5px 20px 30px':'15px 10px', fontSize: '10px', textAlign: 'justify', display:Isvertical ? 'block' : 'grid', marginBottom : Isvertical ? '5px' : ''}} >
                    <FaceFive  style={{marginLeft:'5px', height: Isvertical ? '15px!important' : '28px', width: Isvertical ? '15px!important' : '28px' }}/>
                    <span  style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: selectedQueTextColor, marginLeft : Isvertical ? '30px' : '', fontSize : Isvertical ? '15px' : ''}}>BAD</span>
                  </span>
                  <span className="mr-3" style={{backgroundColor:selectedBgColor, fontFamily:selectedFontType, borderRadius:'25px', padding: include_comment ? Isvertical ? '5px 5px 5px 30px':'15px 10px' : Isvertical ? '20px 5px 20px 30px':'15px 10px', fontSize: '10px', textAlign: 'justify', display:Isvertical ? 'block' : 'grid', marginBottom : Isvertical ? '5px' : ''}}>
                    <FaceFour style={{marginLeft:'5px', height: Isvertical ? '15px!important' : '28px', width: Isvertical ? '15px!important' : '28px' }}/>
                    <span style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: selectedQueTextColor, marginLeft : Isvertical ? '30px' : '', fontSize : Isvertical ? '15px' : ''}}>VERY BAD</span>
                  </span>
                </Col>
              </Col>
            )
            }
            {
              ratingType === 'nps_score' && showReviewMethods &&
                (
                  <Fragment>
                    <Header as="h3" textAlign='center' style={{fontFamily:selectedFontType}}>
                      {heading}
                    </Header>
                    <Row >
                        <Col sm="12" className="nps-score-center" style={{marginTop:'40px', marginBottom:'30px'}}>
                          <div className="nps-meter">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => {
                              const selectedScore = 0;
                              let colorClass;

                              if (selectedScore && num <= selectedScore) {
                                if (selectedScore <= 6)
                                  colorClass = "nps-rating-colour-red filled newDesignNpsExp";
                                else if (selectedScore <= 8)
                                  colorClass =
                                    "nps-rating-colour-yellow filled newDesignNpsExp";
                                else
                                  colorClass = "nps-rating-colour-green filled newDesignNpsExp";
                              } else {
                                if (num <= 6)
                                  colorClass = "nps-rating-colour-red newDesignNpsExp";
                                else if (num <= 8)
                                  colorClass = "nps-rating-colour-yellow newDesignNpsExp";
                                else colorClass = "nps-rating-colour-green newDesignNpsExp";
                              }

                              return (
                                <span
                                  key={num}
                                  className={colorClass}
                                  // onClick={() =>
                                  //   this.setNpsScore(num)
                                  // }
                                  style={{"padding":"18px!important"}}
                                >
                                  {num}
                                </span>
                              );
                            })}
                          </div>
                        </Col>
                      
                      {/* <Col sm="12" className="nps-score-center" style={{ marginBottom:'15px'}}>
                        <div className='npsRatingDiv' style={{marginLeft:'20px'}}>
                          <span style={{position:'absolute', width:'30px', textAlign:'center',bottom:'0', left:'3px', fontSize:'10px'}}>Not likely</span>
                          <div id="speedometer">
                            <div className="wrapper">
                              <div className="indicator-wrapper">
                                <div className="indicator-wrapper-inner">
                                  <div id="indicator"></div>
                                </div>
                              </div>
                              <div className="bar bar-1"><span>1</span></div>
                              <div className="bar bar-2"><span>2</span></div>
                              <div className="bar bar-3"><span>3</span></div>
                              <div className="bar bar-4"><span>4</span></div>
                              <div className="bar bar-5"><span>5</span></div>
                              <div className="bar bar-6"><span>6</span></div>
                              <div className="bar bar-7"><span>7</span></div>
                              <div className="bar bar-8"><span>8</span></div>
                              <div className="bar bar-9"><span>9</span></div>
                              <div className="bar bar-10"><span>10</span></div>
                            </div>
                            <div className="needle" style={{transform: 'rotate(0deg)', left:'110px'}}></div>
                            <div className='semiCircleProgressBar'>
                              <SemiCircleProgressBar percentage={0} diameter={160} stroke={'#0f62fe'} strokeWidth={5} background={'#fff'} />
                            </div>
                          </div>
                          <span style={{position:'absolute', width:'35px', textAlign:'center',bottom:'0', right:'2px', fontSize:'10px'}}>Extremely Likely</span>
                        </div>
                      </Col> */}
                    </Row>
                  </Fragment>
                )
              }
            
            { (include_comment && body !== 'null') && 
            <p style={{ textAlign: 'justify', marginBottom: '20px', paddingLeft:'12px', paddingRight:'12px', fontSize:'10px', fontFamily:selectedFontType }}>
                {body}
            </p>}
            {/* { include_comment && */}
              <div style={{backgroundColor:selectedBgColor, fontFamily:selectedFontType, borderRadius:'25px', padding:'10px', color: selectedQueTextColor}}>
                <p style={{ textAlign: 'justify', paddingLeft:'12px', paddingRight:'12px', fontSize:'13px', color: selectedQueTextColor }}>
                What would you tell others about your experience?
                </p>
                <Field
                style={{backgroundColor:'white'}}
                name="description"
                type="text"
                placeholder='Write here...'
                component={TextAreaComponent}
                />
            </div>
            {/* } */}
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default ExperiencePagePreview;