import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Checkbox,
  Image,
  Dropdown,
  Form,
} from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  Row,
} from "reactstrap";
import classnames from "classnames";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import SocialPostList from "../../components/business/SocialPostList";
import SocialPostCalendra from "../../components/business/SocialPostCalendar";
import SocialPostNewForm from "../../components/business/SocialPostNewForm";
import SocialPostEventForm from "../../components/business/SocialPostEventForm";
import SocialPostOfferForm from "../../components/business/SocialPostOfferForm";
import LoaderComponent from "../../components/common/loaderComponent";
import DatePicker from "react-datepicker";
import ImageUpload from "../../images/Group36.png";
import moment from "moment";
import Instagram from "../../images/instagram_logo.png";
import Fb from "../../images/Facebook_Messenger_Logo.png";
import GMB from "../../images/google_business.png";
import MultiSelect from "../../components/common/SelectComponent";
import LockedComponent from "../../components/common/LockedComponent/LockedComponent";
import * as utils from "../../libs/utils";
import { FPButton, FPToolTip } from "../../components/common/new";
import { FPTab } from "../../components/common/new/tabs";
import { FaPlus } from "react-icons/fa6";
import FormModal from "../../components/common/FormModal";
import gmailgiftIcon from "../../images/svg/gmail business.svg";
import "./publisher.css";
import imgDrag from "../../images/svg/image_drag.svg";
import { Box } from "@material-ui/core";
import FPLabel from "../../components/common/new/forms/ui/FPLabel";
import deleteIcon from "../../images/svg/delete_grey.svg";
import calenderLogo from "../../images/svg/calender.svg";
import TimerLogo from "../../images/svg/Time.svg";
import trash from "../../images/svg/delete_White.svg";
import checkCircle from "../../images/svg/check_circle.svg";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import SearchComponent from "../../components/common/SearchComponent";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import * as filterListActions from "../../actions/common/filterListActions";
import queryString from "query-string";

const SocialPost = (props) => {
  const business_id = props.match.params.business_id;
  const [publisherTab, setpublisherTab] = useState(0);
  const [addPostModal, setaddPostModal] = useState(false);
  const [postTypeTab, setpostTypeTab] = useState("new");
  const [postedList, setpostedListb] = useState([]);
  const [scheduledList, setscheduledList] = useState([]);
  const [draftList, setdraftList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [locationloader, setlocationloader] = useState(false);
  const [branchTags, setbranchTags] = useState([]);
  const [branchLocationList, setbranchLocationList] = useState([]);
  const [applyTagsResponse, setapplyTagsResponse] = useState(false);
  const [editItem, seteditItem] = useState(null);
  const [editPostModal, seteditPostModal] = useState(false);
  const [recurringEditPost, setrecurringEditPost] = useState(false);
  const [editselectedImage, seteditselectedImage] = useState(null);
  const [editItemImage, seteditItemImage] = useState(null);
  const [editpostTitleValue, seteditpostTitleValue] = useState(null);
  const [editpostDescValue, seteditpostDescValue] = useState(null);
  const [selectedButton, setselectedButton] = useState("None");
  const [buttonUrl, setbuttonUrl] = useState(null);
  const [eventStartDate, seteventStartDate] = useState(null);
  const [eventEndDate, seteventEndDate] = useState(null);
  const [enableEventTime, setenableEventTime] = useState(false);
  const [eventStartTime, seteventStartTime] = useState(null);
  const [eventEndTime, seteventEndTime] = useState(null);
  const [voucherCode, setvoucherCode] = useState(null);
  const [offerLink, setofferLink] = useState(null);
  const [offerTerms, setofferTerms] = useState(null);
  const [scheduleDate, setscheduleDate] = useState(null);
  const [scheduleTime, setscheduleTime] = useState(0);
  const [selectedTags, setselectedTags] = useState([]);
  const [imageUrl, setimageUrl] = useState(null);
  const [lock, setLock] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [chooseLocationPopup, setchooseLocationPopup] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedLocationCount, setSelectedLocationCount] = useState(null);
  const [openSelectedLocation, setOpenSelectedLocation] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [deletePostId, setDeletePostId] = useState(null);
  const [titleError, setTitleError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [postOrBranch, setPostOrBranch] = useState("");
  const [postStatus, setpostStatus] = useState("posted");

  const button_options = [
    { key: "None", text: "None", value: "None" },
    { key: "Book", text: "Book", value: "Book" },
    { key: "Order Online", text: "Order Online", value: "ORDER" },
    { key: "Buy", text: "Buy", value: "SHOP" },
    { key: "Learn More", text: "Learn More", value: "LEARN_MORE" },
    { key: "Signup", text: "Signup", value: "SIGN_UP" },
    { key: "Call Now", text: "Call Now", value: "CALL" },
  ];
  const time_options = [
    { key: "0", text: "0", value: "0" },
    { key: "01", text: "01", value: "01" },
    { key: "02", text: "02", value: "02" },
    { key: "03", text: "03", value: "03" },
    { key: "04", text: "04", value: "04" },
    { key: "05", text: "05", value: "05" },
    { key: "06", text: "06", value: "06" },
    { key: "07", text: "07", value: "07" },
    { key: "08", text: "08", value: "08" },
    { key: "09", text: "09", value: "09" },
    { key: "10", text: "10", value: "10" },
    { key: "11", text: "11", value: "11" },
    { key: "12", text: "12", value: "12" },
    { key: "13", text: "13", value: "13" },
    { key: "14", text: "14", value: "14" },
    { key: "15", text: "15", value: "15" },
    { key: "16", text: "16", value: "16" },
    { key: "17", text: "17", value: "17" },
    { key: "18", text: "18", value: "18" },
    { key: "19", text: "19", value: "19" },
    { key: "20", text: "20", value: "20" },
    { key: "21", text: "21", value: "21" },
    { key: "22", text: "22", value: "22" },
    { key: "23", text: "23", value: "23" },
  ];

  const getAllPostList = (params) => {
    setLoader(true);
    axios_instance
      .get(
        `${
          URLS.SOCIAL_POST
        }/${business_id}/social-post-new/?post_status=draft&business_id=${business_id}&page=${
          params?.page ? params?.page : 1
        }&page_size=${params?.page_size ? params?.page_size : ""}`
      )
      .then((res) => {
        setdraftList(res.data);
        setLoader(false);
      });
    axios_instance
      .get(
        `${URLS.SOCIAL_POST}/${business_id}/social-post-new/?post_status=posted&business_id=${business_id}&page=${
          params?.page ? params?.page : 1
        }&page_size=${params?.page_size ? params?.page_size : ""}`
      )
      .then((res) => {
        setpostedListb(res.data);
        setLoader(false);
      });
    axios_instance
      .get(
        `${URLS.SOCIAL_POST}/${business_id}/social-post-new/?post_status=scheduled&business_id=${business_id}&page=${
          params?.page ? params?.page : 1
        }&page_size=${params?.page_size ? params?.page_size : ""}`
      )
      .then((res) => {
        setscheduledList(res.data);
        setLoader(false);
      });
  };

  useEffect(() => {
    const {
      location: { search },
    } = props;

    // if (prevSearch !== search) {
    const queryParams = queryString.parse(search);
    getAllPostList(queryParams);
    // }
  }, [props.location.search]);

  useEffect(() => {
    props.actionsSelectTab.setSelectedTab(1);
    document.title = "Social Post";
    axios_instance
      .get(utils.format(URLS.LOCKED_MODULES, [business_id]))
      .then((res) => {
        if (res.data[0]["scheduler"]) {
          setLock(
            "Publisher module is locked for you.Please Contact Famepilot admin"
          );
        }
      });

    getAllPostList();
    axios_instance
      .get(`${URLS.BRANCH_TAG}?business_id=${business_id}`)
      .then((res) => {
        setbranchTags(res.data.results);
      });
    axios_instance
      .get(
        `${URLS.BUSINESS_SETUP}${business_id}/enterprise/?page_size=1000&provider=google&status=active`
      )
      .then(async (res) => {
        const updateBranch = await res.data.results.map((branch) => ({
          ...branch,
          isChecked: false,
        }));
        setbranchLocationList(updateBranch);
      });

    props.filterAction.getBranch(
      "",
      `${URLS.BUSINESS_SETUP}${business_id}/branch/`
    );
  }, []);

  const handleBranchTagFilter = (tags) => {
    axios_instance
      .get(
        `${URLS.BUSINESS_SETUP}${business_id}/enterprise/?branch_tag=${tags}&page_size=1000&provider=google&status=active`
      )
      .then((res) => {
        const updateBranch = res.data.results.map((branch) => ({
          ...branch,
          isChecked: false,
        }));
        setbranchLocationList(updateBranch);
        setapplyTagsResponse(true);
        setlocationloader(false);
      });
  };

  const togglePostTypeTab = (value) => {
    setpostTypeTab(value);
  };

  const togglePublisherTabs = (value) => {
    setpublisherTab(value);
  };

  const addPostModalHandler = () => {
    setaddPostModal(true);
  };

  const createPostHandler = (data) => {
    setaddPostModal(false);
    setLoader(true);
    data.set("post_status", postStatus);
    axios_instance
      .post(`${URLS.SOCIAL_POST}/${business_id}/social-post-new/`, data)
      .then(() => {
        alert(
          "Created post has been started uploading, it will be updated on respective platform after some time."
        );
        getAllPostList();
        setLoader(false);
        setselectedTags([]);
        seteditItem(null);
      })
      .catch((error) => {
        console.error("Error creating post:", error);
        setLoader(false);
      });
  };
  const editPostHandler = (data) => {
    seteditPostModal(false);
    setLoader(true);
    if (postOrBranch === "post") {
      axios_instance
        .post(
          `${URLS.SOCIAL_POST}/${business_id}/social-post-new/branch_wise_update/`,
          data
        )
        .then(() => {
          alert(
            "Editing post has been started, it will be updated on respective platform after some time."
          );
          setEditStateNull();
          getAllPostList();
          setLoader(false);
          branchLocationList.map((item) => {
            item.isChecked = false;
          });
        });
    } else if (postOrBranch === "branch") {
      axios_instance
        .patch(
          `${URLS.SOCIAL_POST}/${business_id}/social-post-new/${editItem.id}/`,
          data
        )
        .then(() => {
          alert(
            "Editing post has been started, it will be updated on respective platform after some time."
          );
          setEditStateNull();
          getAllPostList();
          setLoader(false);
          branchLocationList.map((item) => {
            item.isChecked = false;
          });
        });
    }
    // axios_instance.post(url, data).then(() => {
    //   alert(
    //     "Editing post has been started, it will be updated on respective platform after some time."
    //   );
    //   setEditStateNull();
    //   getAllPostList();
    //   setLoader(false);
    //   branchLocationList.map((item) => {
    //     item.isChecked = false;
    //   });
    // });
  };

  const handleDeletePost = (editItem, type) => {
    const seriesPostIds = editItem?.branch_wise_post
      ?.map((d) => d?.id)
      .join(",");

    seteditPostModal(false);
    setLoader(true);
    if (type === "post") {
      axios_instance
        .delete(
          `${URLS.SOCIAL_POST}/${business_id}/social-post-new/branch_wise_delete/`,
          {
            data: { social_post_ids: seriesPostIds }, // Ensure the payload is sent inside `data`
          }
        )
        .then(() => {
          alert(
            "Editing post has been started, it will be updated on respective platform after some time."
          );
          setEditStateNull();
          getAllPostList();
          setLoader(false);
          branchLocationList.map((item) => {
            item.isChecked = false;
          });
        });
    } else if (type === "branch") {
      axios_instance
        .delete(
          `${URLS.SOCIAL_POST}/${business_id}/social-post-new/${editItem.id}/`
        )
        .then(() => {
          alert(
            "Editing post has been started, it will be updated on respective platform after some time."
          );
          setEditStateNull();
          getAllPostList();
          setLoader(false);
          branchLocationList.map((item) => {
            item.isChecked = false;
          });
        });

      // axios_instance
      //   .delete(
      //     `${URLS.SOCIAL_POST}/${business_id}/social-post-new/${editItem.id}/`
      //   )
      //   .then(() => {
      //     setEditStateNull();
      //     getAllPostList();
      //     setLoader(false);
      //     branchLocationList.map((item) => {
      //       item.isChecked = false;
      //     });
      //   });
    }
  };
  const setEditStateNull = () => {
    seteditItem(null);
    seteditItemImage(null);
    seteditpostTitleValue(null);
    seteditpostDescValue(null);
    setselectedButton("None");
    setbuttonUrl(null);
    seteventStartDate(null);
    seteventEndDate(null);
    setenableEventTime(false);
    seteventStartTime(null);
    seteventEndTime(null);
    setvoucherCode(null);
    setofferLink(null);
    setofferTerms(null);
    setscheduleDate(null);
    setscheduleTime(0);
    setselectedTags([]);
    seteditselectedImage(null);
  };

  const handlePostEdit = (event, item, type) => {
    setPostOrBranch(type);
    setEditStateNull();
    seteditItem(item);
    seteditItemImage(item.image);
    seteditpostTitleValue(item.title);
    seteditpostDescValue(item.description);
    if (item.add_button) {
      setselectedButton(item.button_text);
      setbuttonUrl(item.button_url);
    }
    if (item.post_type === "event") {
      seteventStartDate(item.event_start_date);
      seteventEndDate(item.event_end_date);
      setenableEventTime(item.event_time);
      seteventStartTime(item.event_start_time);
      seteventEndTime(item.event_end_time);
    }
    if (item.post_type === "offer") {
      seteventStartDate(item.offer_start_date);
      seteventEndDate(item.offer_end_date);
      setvoucherCode(item.coupon_code_name);
      setofferLink(item.link_offer);
      setofferTerms(item.terms_condition);
    }
    if (item.scheduled) {
      setscheduleDate(item.schedule_date);
      setscheduleTime(item.schedule_time.split(":")[0]);
    }
    const allBranches = item?.branch_wise_post?.map((post) => post.branch); // Extract 'branche' values
    branchLocationList?.forEach((branch) => {
      branch.isChecked = allBranches?.includes(branch.id) ? true : false;
    });

    // branchLocationList.map((branch) => {
    //   if (item.branches.includes(branch.id)) {
    //     branch.isChecked = true;
    //   } else {
    //     branch.isChecked = false;
    //   }
    // });
    var tag_array = [];
    item.branch_tags.map((item) => {
      tag_array.push(item.title);
    });
    setselectedTags(tag_array);
    seteditPostModal(true);
  };

  const toggleRecurringEditPost = () => {
    setrecurringEditPost(!recurringEditPost);
  };
  const editimageHandler = (event) => {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = function () {
      setimageUrl(reader.result);
    };
    seteditselectedImage(event.target.files[0]);
    seteditItemImage(event.target.files[0]);
  };
  const handleButtonDropDown = (event, data) => {
    setselectedButton(data.value);
  };
  const handleEventStartDate = (date) => {
    seteventStartDate(moment(date).format("YYYY-MM-DD"));
  };
  const handleEventEndDate = (date) => {
    seteventEndDate(moment(date).format("YYYY-MM-DD"));
  };
  const toggleEventTime = () => {
    setenableEventTime(!enableEventTime);
  };
  const handleScheduleDate = (date) => {
    setscheduleDate(moment(date).format("YYYY-MM-DD"));
  };
  const handleSelectBranchTag = (e, val) => {
    let tagArr;
    if (val.includes(",") && val !== "") {
      tagArr = val.split(",");
    } else if (val !== "") {
      tagArr = [val];
    } else {
      tagArr = [];
    }
    setselectedTags(tagArr);
  };
  const applyTags = () => {
    setlocationloader(true);
    handleBranchTagFilter(selectedTags);
  };

  // useEffect(()=>{
  //   setOpenSelectedLocation(true);
  //   setSelectedLocationCount(selectedLocationCount);
  // },[selectedLocationCount]);
  // console.log('selectedLocationCounts',selectedLocationCount);

  const handleSelectAll = () => {
    const updateconso = branchLocationList.map((branch) => ({
      ...branch,
      isChecked: !selectAll,
    }));
    setbranchLocationList(updateconso);
    setSelectAll(!selectAll);

    if (updateconso.length > 0) {
      setOpenSelectedLocation(!selectAll);
      setSelectedLocationCount(updateconso.length);
    }
  };

  const handleSingleBranchSelect = (item) => {
    const updatecoonso = branchLocationList.map((branch) =>
      branch.id === item ? { ...branch, isChecked: !branch.isChecked } : branch
    );

    const selectLocationCount = updatecoonso.filter(
      (branch) => branch?.isChecked
    );

    setbranchLocationList(updatecoonso);
    for (var i = 0; i < updatecoonso.length; i++) {
      if (!updatecoonso[i].isChecked) {
        setSelectAll(false);
        break;
      } else {
        setSelectAll(true);
      }
    }

    if (updatecoonso.length > 0) {
      setOpenSelectedLocation(true);
      setSelectedLocationCount(selectLocationCount.length);
    }
  };

  const handleEditSave = () => {
    var error_exist = false;
    const formData = new FormData();
    formData.append("business", business_id);
    formData.append("post_status", editItem.post_status);
    formData.append("platform", "google_business");
    formData.append("post_type", editItem.post_type);
    formData.append("series_id", editItem.series_id);

    // Title validation
    if (editpostTitleValue !== null) {
      formData.append("title", editpostTitleValue);
      const title_element = document.getElementById("edit_post_title");
      title_element.style.borderColor = "#22242626";
    } else {
      const title_element = document.getElementById("edit_post_title");
      title_element.style.borderColor = "red";
      error_exist = true;
    }

    // Image validation
    if (editselectedImage !== null) {
      formData.append("image", editselectedImage);
    }

    // Description validation
    if (editpostDescValue !== null) {
      formData.append("description", editpostDescValue);
    }

    // Button URL validation
    if (selectedButton !== "None") {
      formData.append("add_button", true);
      formData.append("button_text", selectedButton);
      if (buttonUrl !== null) {
        formData.append("button_url", buttonUrl);
        const title_element = document.getElementById("new_button_url");
        title_element.style.borderColor = "#22242626";
      } else {
        const title_element = document.getElementById("new_button_url");
        title_element.style.borderColor = "red";
        error_exist = true;
      }
    }

    if (editItem.post_type === "event") {
      if (eventStartDate !== null) {
        formData.append("event_start_date", eventStartDate);
        const title_element = document.getElementById("edit_event_start_date");
        title_element.style.borderColor = "#22242626";
      } else {
        const title_element = document.getElementById("edit_event_start_date");
        title_element.style.borderColor = "red";
        error_exist = true;
      }
      if (eventEndDate !== null) {
        formData.append("event_end_date", eventEndDate);
        const title_element = document.getElementById("edit_event_end_date");
        title_element.style.borderColor = "#22242626";
      } else {
        const title_element = document.getElementById("edit_event_end_date");
        title_element.style.borderColor = "red";
        error_exist = true;
      }
      if (enableEventTime) {
        formData.append("event_time", true);
        formData.append("event_start_time", eventStartTime);
        formData.append("event_end_time", eventEndTime);
      } else {
        formData.append("event_time", false);
      }
    }

    if (editItem.post_type === "offer") {
      if (eventStartDate !== null) {
        formData.append("offer_start_date", eventStartDate);
        const title_element = document.getElementById("edit_event_start_date");
        title_element.style.borderColor = "#22242626";
      } else {
        const title_element = document.getElementById("edit_event_start_date");
        title_element.style.borderColor = "red";
        error_exist = true;
      }
      if (eventEndDate !== null) {
        formData.append("offer_end_date", eventEndDate);
        const title_element = document.getElementById("edit_event_end_date");
        title_element.style.borderColor = "#22242626";
      } else {
        const title_element = document.getElementById("edit_event_end_date");
        title_element.style.borderColor = "red";
        error_exist = true;
      }
      if (voucherCode !== null) {
        formData.append("coupon_code_name", voucherCode);
      }
      if (offerLink !== null) {
        formData.append("link_offer", offerLink);
      }
      if (offerTerms !== null) {
        formData.append("terms_condition", offerTerms);
      }
    }

    // Schedule validation
    // if (editItem.post_status !== "posted") {
    if (scheduleDate !== null) {
      var final_time = scheduleTime.concat(":00:00");
      formData.append("schedule_date", scheduleDate);
      formData.append("schedule_time", final_time);
      formData.append("scheduled", true);
    }
    if (selectAll && selectedTags.length !== 0) {
      formData.append("branch_tags", selectedTags);
    } else if (selectAll && selectedTags.length === 0) {
      var branch_array = [];
      branchLocationList.map((item) => {
        branch_array.push(item.id);
      });
      formData.append("branches", branch_array);
    } else {
      var selected_branch_array = [];
      branchLocationList.map((item) => {
        if (item.isChecked) {
          selected_branch_array.push(item.id);
        }
      });
      formData.append("branches", selected_branch_array);
    }
    // }

    // If no validation errors, submit the form
    if (error_exist !== true) {
      editPostHandler(formData);
      setchooseLocationPopup(false);
    }
  };

  const toggleAddPostModal = () => {
    setaddPostModal(!addPostModal);
  };

  const toggleEditPostModal = () => {
    seteditPostModal(!editPostModal);
  };

  const deleteSelectedImg = () => {
    seteditItemImage(null);
    setimageUrl(null);
    seteditselectedImage(null);
    document.getElementById("edit_image").value = "";
  };

  // check validation before saving changes
  const chooseLocationHandler = () => {
    var error_exist = false;

    // Title validation
    if (editpostTitleValue === null || editpostTitleValue.trim() === "") {
      setTitleError(true);
      error_exist = true;
    } else {
      setTitleError(false);
    }

    // Button URL validation
    if (selectedButton !== "None" && !buttonUrl) {
      setUrlError(true);
      error_exist = true;
    } else {
      setUrlError(false);
    }

    if (!error_exist) {
      setchooseLocationPopup(true);
    }
  };

  const toggleLocationModal = () => {
    setchooseLocationPopup(!chooseLocationPopup);
  };

  const closeAlertHandler = () => {
    setOpenAlert(!openAlert);
  };

  const toggleConfirm = () => {
    setIsConfirm(false);
    handleDeletePost(deletePostId);
  };

  const postDeleteHandler = (editItem) => {
    setIsConfirm(!isConfirm);
    setDeletePostId(editItem);
  };

  const handlePostStatus = (status) => {
    setpostStatus(status);
  };

  return (
    <React.Fragment>
      <div className="py-4">
        <LoaderComponent loader={loader} />
        <LockedComponent message={lock} />
        <div className="align-center justify-content-between mb-2">
          <p className="mb-0 fp-card-header">Post Management</p>
          <FPButton
            className="d-flex ml-auto"
            label="Add Post"
            size="small"
            onClick={addPostModalHandler}
            endIcon={<FaPlus style={{ fontSize: "14px" }} />}
          />
        </div>

        {openAlert && (
          <div className="shadow alertMsg">
            <Box
              onClick={() => closeAlertHandler()}
              sx={{ fontWeight: 500, color: "#1B1C1D" }}
              className="align-center justify-content-between"
            >
              Social Posting has been added to Scheduled
              <img src={checkCircle} alt="check" />
            </Box>
          </div>
        )}

        <FPTab
          tabLabels={["Post List", "Calendar"]}
          minTabWidth={220}
          defaultValue={0}
          onChange={togglePublisherTabs}
        />

        <TabContent activeTab={publisherTab} className="mt-4">
          <TabPane tabId={0}>
            <SocialPostList
              // addPostModalHandler={addPostModalHandler}
              postedList={postedList}
              scheduledList={scheduledList}
              list_type={handlePostStatus}
              draftList={draftList}
              location={props.location}
              handlePostEdit={handlePostEdit}
              handlePostDelete={handleDeletePost}
              setOpenAlert={setOpenAlert}
              filter={props.filter}
            />
          </TabPane>
          <TabPane tabId={1}>
            <SocialPostCalendra />
          </TabPane>
        </TabContent>
      </div>

      {/* Add Post New Popup */}
      <FormModal
        isOpen={addPostModal}
        toggle={toggleAddPostModal}
        heading="Add Post"
        width={"700px"}
        headerClass="align-center"
      >
        <FPButton
          className=""
          label="Google Business"
          // size="large"
          textColor="#00B5AD"
          fontWeight={600}
          borderColor="#00B5AD"
          backgroundColor="#DDEFF0"
          startIcon={<img src={gmailgiftIcon} alt="gmail gift" />}
          style={{ border: "1px solid #00B5AD" }}
        />
        <Nav className="Publisher_Sub_Nav my-4">
          <NavItem>
            <NavLink
              className={classnames({ active: postTypeTab === "new" })}
              onClick={() => togglePostTypeTab("new")}
            >
              Whats New
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: postTypeTab === "event" })}
              onClick={() => togglePostTypeTab("event")}
            >
              Event
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: postTypeTab === "offer" })}
              onClick={() => togglePostTypeTab("offer")}
            >
              Offer
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={postTypeTab}>
          <TabPane tabId="new">
            <SocialPostNewForm
              setaddPostModal={setaddPostModal}
              createPostHandler={createPostHandler}
              business_id={business_id}
              branchTags={branchTags}
              branchLocationList={branchLocationList}
              handleBranchTagFilter={handleBranchTagFilter}
              applyTagsResponse={applyTagsResponse}
              setapplyTagsResponse={setapplyTagsResponse}
              setbranchLocationList={setbranchLocationList}
              setOpenAlert={setOpenAlert}
            />
          </TabPane>
          <TabPane tabId="event">
            <SocialPostEventForm
              setaddPostModal={setaddPostModal}
              createPostHandler={createPostHandler}
              business_id={business_id}
              branchTags={branchTags}
              branchLocationList={branchLocationList}
              handleBranchTagFilter={handleBranchTagFilter}
              applyTagsResponse={applyTagsResponse}
              setapplyTagsResponse={setapplyTagsResponse}
              setbranchLocationList={setbranchLocationList}
              setOpenAlert={setOpenAlert}
            />
          </TabPane>
          <TabPane tabId="offer">
            <SocialPostOfferForm
              setaddPostModal={setaddPostModal}
              createPostHandler={createPostHandler}
              business_id={business_id}
              branchTags={branchTags}
              branchLocationList={branchLocationList}
              handleBranchTagFilter={handleBranchTagFilter}
              applyTagsResponse={applyTagsResponse}
              setapplyTagsResponse={setapplyTagsResponse}
              setbranchLocationList={setbranchLocationList}
              setOpenAlert={setOpenAlert}
            />
          </TabPane>
        </TabContent>
      </FormModal>

      {/* Edit Post Popup */}
      <FormModal
        isOpen={editPostModal}
        toggle={toggleEditPostModal}
        heading="Edit Post"
        width={"700px"}
        headerClass="align-center"
      >
        <label className="imageDragBox" htmlFor="edit_image">
          <Box sx={{ margin: "6px 0px" }}>
            <img
              src={imgDrag}
              alt="drag"
              // src={selectedImage ? imageUrl : imgDrag}
              // style={{width: selectedImage ?  '100px': 'none',
              //   height: selectedImage ?  '100px' : 'none' }}
              // className={classnames({selectedImage ? '' : ''})}
            />
          </Box>
          <Box sx={{ color: "#7A8193" }}>
            <Box component={"span"} className="text-center">
              <Box
                component={"span"}
                sx={{ color: "#00B5AD", fontWeight: 500 }}
              >
                Click to upload
              </Box>{" "}
              or drag and drop
            </Box>

            <Box component={"span"} className="d-block">
              PNG, JPG (max. 720x720px)
            </Box>
            {/* {selectedImage ? selectedImage.name : "No file selected"} */}
          </Box>
          <input
            type="file"
            id="edit_image"
            onChange={(event) => {
              editimageHandler(event);
            }}
            accept=".png, .jpg, .jpeg"
            style={{ display: "none", visibility: "none" }}
          />
        </label>
        {editItemImage && (
          <div className="my-4 align-center gap-20">
            <div className="selectedImg">
              <img
                src={
                  editselectedImage
                    ? imageUrl
                    : editItemImage
                    ? editItemImage
                    : ImageUpload
                }
                alt="item"
              />
              <div className="d-flex flex-column">
                <Box
                  component="span"
                  sx={{
                    color: "#363A44",
                    fontSize: "16px",
                    fontWeight: 500,
                    display: "block",
                  }}
                >
                  Img
                </Box>
                <Box
                  component="span"
                  sx={{ color: "#667085", fontSize: "13px" }}
                >
                  150 MB - 100% Uploaded
                </Box>
              </div>
            </div>
            <FPToolTip title={"Delete"}>
              <img
                src={deleteIcon}
                onClick={() => deleteSelectedImg()}
                role="button"
                alt="delete"
              />
            </FPToolTip>
          </div>
        )}

        <Box
          className="align-center gap-15 mt-4 mb-3"
          style={{ fontSize: "14px", color: "#1B1C1D" }}
        >
          Setup as a Recurring Post (Optional)
          <Checkbox
            name="recurring_post"
            style={{ margin: "0px" }}
            toggle
            onChange={() => {
              toggleRecurringEditPost();
            }}
          />
        </Box>
        <div>
          <div className="my-4">
            <div className="d-flex justify-content-between">
                <FPLabel isRequired={true}>Title</FPLabel>
                {
                  editpostTitleValue?.length > 58 ? 
                  <span className="errorMsg">{editpostTitleValue?.length} / 58</span>
                  : 
                  <span>{editpostTitleValue?.length || 0} / 58</span>
                }
            </div>            
            <input
              className="input-Field"
              type="text"
              id="edit_post_title"
              name="title"
              placeholder="Title (max 58 characters)"
              value={editpostTitleValue}
              onChange={(event) => {
                seteditpostTitleValue(event.target.value);
              }}
            />
            {
              editpostTitleValue?.length > 58 ? 
              <span className="errorMsg">Title must not exceed 58 characters</span>
              :""
            }
            {titleError && (
              <span className="errorMsg">This field is required</span>
            )}
          </div>
          <div>
            <FPLabel isRequired={false}>Description </FPLabel>
            <textarea
              name="edit_post_desc"
              id="edit_post_desc"
              placeholder="Add post description here"
              rows={4}
              cols={40}
              value={editpostDescValue}
              onChange={(event) => {
                seteditpostDescValue(event.target.value);
              }}
              className="textAreaUI"
            />
          </div>
        </div>

        {editItem && editItem.post_type !== "offer" && (
          <div className="mt-3 mb-4">
            <FPLabel isRequired={false}>Add Button</FPLabel>
            <Dropdown
              id="edit_add_button"
              name="button_name"
              placeholder="None"
              fluid
              selection
              style={{ height: "38px", color: "#7A8193" }}
              options={button_options}
              value={selectedButton}
              onChange={handleButtonDropDown}
            />
          </div>
        )}
        {editItem &&
          editItem.post_type !== "offer" &&
          selectedButton !== "None" && (
            <div className="">
              <FPLabel>Add a link</FPLabel>
              <input
                className="input-Field"
                type="text"
                id="new_button_url"
                name="button_url"
                placeholder="Link of your button*"
                value={buttonUrl}
                onChange={(event) => {
                  setbuttonUrl(event.target.value);
                }}
                style={{ background: "#F6F6F8" }}
              />
              {urlError && (
                <span className="errorMsg">This field is required</span>
              )}
            </div>
          )}

        {editItem && editItem.post_type !== "update" && (
          <div className="eventDateSelector mt-3 mb-4">
            <FPLabel isRequired={false}>Add Event Date</FPLabel>
            <div className="d-flex col-12 p-0">
              <div className="col-6 pl-0">
                <DatePicker
                  id="edit_event_start_date"
                  name="event_start_date"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    handleEventStartDate(date);
                  }}
                  className="form-control form-control-sm"
                  type="text"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  value={eventStartDate !== null && eventStartDate}
                  placeholderText={"Start Date*"}
                />
                <img
                  src={calenderLogo}
                  alt="calender"
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "25%",
                    transform: "translate(-50%)",
                  }}
                />
              </div>
              <div className="col-6 pr-0">
                <DatePicker
                  id="edit_event_end_date"
                  name="event_end_date"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    handleEventEndDate(date);
                  }}
                  className="form-control form-control-sm"
                  type="text"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  value={eventEndDate !== null && eventEndDate}
                  placeholderText={"End Date*"}
                />
                <img
                  src={calenderLogo}
                  alt="calender"
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "25%",
                    transform: "translate(-50%)",
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {editItem && editItem.post_type === "event" && (
          <div className="align-center justify-content-between">
            <FPLabel isRequired={false} className="mb-0">
              Add Event Time
            </FPLabel>
            <Checkbox
              name="event_time_enable"
              toggle
              checked={enableEventTime}
              value={enableEventTime}
              onChange={() => {
                toggleEventTime();
              }}
            />
          </div>
        )}
        {editItem && editItem.post_type === "event" && enableEventTime && (
          <div className="d-flex col-12 p-0 mt-4">
            <div className="col-6 pl-0">
              <FPLabel isRequired={false}>Start Time</FPLabel>
              <input
                type="text"
                name="start_time"
                placeholder="Start Time"
                value={eventStartTime}
                onChange={(event) => {
                  seteventStartTime(event.target.value);
                }}
                className="input-Field"
              />
              <img
                src={TimerLogo}
                alt="timer"
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "50%",
                  transform: "translate(-50%)",
                }}
              />
            </div>
            <div className="col-6 pr-0">
              <FPLabel isRequired={false}>End Time</FPLabel>
              <input
                type="text"
                name="end_time"
                placeholder="End Time"
                value={eventEndTime}
                onChange={(event) => {
                  seteventEndTime(event.target.value);
                }}
                className="input-Field"
              />
              <img
                src={TimerLogo}
                alt="timer"
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "50%",
                  transform: "translate(-50%)",
                }}
              />
            </div>
          </div>
        )}

        {editItem && editItem.post_type === "offer" && (
          <>
            <div>
              <FPLabel isRequired={false}>Voucher Code</FPLabel>
              <input
                type="text"
                placeholder="Voucher Code"
                value={voucherCode}
                onChange={(event) => {
                  setvoucherCode(event.target.value);
                }}
                className="input-Field"
              />
            </div>
            <div className="my-4">
              <FPLabel isRequired={false}>Add a link</FPLabel>
              <input
                type="text"
                placeholder="Link of your button"
                value={offerLink}
                onChange={(event) => {
                  setofferLink(event.target.value);
                }}
                className="input-Field"
              />
            </div>
            <div>
              <FPLabel isRequired={false}>Terms & Conditions</FPLabel>
              <textarea
                name=""
                rows={4}
                cols={40}
                value={offerTerms}
                onChange={(event) => {
                  setofferTerms(event.target.value);
                }}
                className="textAreaUI"
              />
            </div>
          </>
        )}
        {editItem && editItem.post_status !== "posted" && (
          // {editItem && editItem.post_status !== "posted" && (
          <Row className="eventDateSelector mt-4">
            <Col md="6">
              <FPLabel>Schedule Date</FPLabel>
              <DatePicker
                id="edit_schedule_date"
                name="schedule_date"
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  handleScheduleDate(date);
                }}
                className="form-control form-control-sm"
                type="text"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                value={scheduleDate !== null && scheduleDate}
                placeholderText={"Schedule Date"}
              />
              <img
                src={calenderLogo}
                alt="calender"
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "50%",
                  transform: "translate(-50%)",
                }}
              />
            </Col>
            <Col md="6">
              <FPLabel>
                Schedule Time{" "}
                <Box component="span" sx={{ color: "#7A8193" }}>
                  (24 hr)
                </Box>
              </FPLabel>
              <Dropdown
                id="edit_schedule_time"
                name="schedule_time"
                placeholder="Select Time"
                fluid
                selection
                options={time_options}
                value={scheduleTime}
                onChange={(event, data) => {
                  setscheduleTime(data.value);
                }}
              />
            </Col>
          </Row>
        )}

        <div className="align-center gap-15 float-right py-4">
          <FPButton
            className=""
            label="Delete"
            size="small"
            // onClick={() => handleDeletePost(editItem)}
            onClick={() => postDeleteHandler(editItem)}
            backgroundColor="#1B1C1D"
            startIcon={<img src={trash} alt="trash" />}
          />
          <FPButton
            className=""
            label="Choose Loactions"
            disabled={editpostTitleValue?.length > 58}
            size="small"
            onClick={() => {
              chooseLocationHandler();
            }}
            backgroundColor="#F13A88"
          />
        </div>
      </FormModal>

      <ConfirmationModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader={`Are you sure you want to delete this post`}
        isOpen={isConfirm}
        toggle={() => setIsConfirm(false)}
        onConfirm={() => toggleConfirm()}
      />

      <FormModal
        isOpen={chooseLocationPopup}
        toggle={toggleLocationModal}
        heading="Select Locations"
        width={"700px"}
        headerClass="align-center"
      >
        {editItem && postOrBranch !== "branch" && (
          <div className="my-4">
            <LoaderComponent loader={locationloader} />
            <Row>
              {/* pending */}
              <Col md="9">
                <Field
                  name="branch_tag"
                  component={MultiSelect}
                  placeholder="Branch Tag"
                  options={branchTags}
                  val={selectedTags}
                  onChange={handleSelectBranchTag}
                  labelKey="title"
                  valueKey="title"
                  multi
                  simpleValue
                />
              </Col>
              <Col md="3">
                <Button className="teal" onClick={applyTags}>
                  Apply
                </Button>
              </Col>
            </Row>

            <Box className="align-center gap-10 my-3" sx={{ color: "#1B1C1D" }}>
              <input
                type="checkbox"
                name="select_all"
                onChange={handleSelectAll}
                checked={selectAll}
                style={{ color: "#1B1C1D" }}
              />
              <div>
                <Box component="span" sx={{ fontWeight: 500 }}>
                  Locations
                </Box>
                &nbsp;
                {openSelectedLocation && (
                  <Box component="span" sx={{ fontWeight: 500 }}>
                    ({selectedLocationCount})
                  </Box>
                )}
              </div>
            </Box>
            <div className="mb-4">
              {/* <SearchComponent
                  // location={location}
                  // history={history}
                  className="fp-flex-grow-1"
                  // isSorting={true}
                  placeholder="Search"
                /> */}
            </div>
            <div className="">
              {branchLocationList.map((item, index) => {
                return (
                  <>
                    <Box
                      className="align-center gap-10"
                      sx={{ color: "#1B1C1D", height: "30px" }}
                    >
                      <input
                        type="checkbox"
                        name={`select_location_${item.id}`}
                        onChange={() => {
                          handleSingleBranchSelect(item.id);
                        }}
                        checked={item.isChecked}
                      />

                      <Box component="span">
                        {item.alias !== null
                          ? item.alias
                          : item.location_address}
                      </Box>
                    </Box>
                  </>
                );
              })}
            </div>
          </div>
        )}
        <div className="align-center float-right gap-15">
          <FPButton
            size="small"
            label="Cancel"
            variant="outline"
            borderColor="#BFC2CB"
            textColor="#1B1C1D"
            onClick={() => setchooseLocationPopup(false)}
            className="px-4"
          />
          <FPButton
            className="px-4"
            label="Post"
            size="small"
            onClick={handleEditSave}
            backgroundColor="#F13A88"
          />
        </div>
      </FormModal>
    </React.Fragment>
  );
};

const withReduxForm = reduxForm({
  // eslint-disable-line
  form: "SocialPost",
  enableReinitialize: true,
})(SocialPost);

const mapStateToProps = (state) => {
  return {
    filter: state.filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
  };
};

const ConnectedSocialPost = connect(
  mapStateToProps,
  mapDispatchToProps
)(withReduxForm);

export default ConnectedSocialPost;
