import React from "react";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from "redux-form";
import { Grid, Form } from "semantic-ui-react";
import MapComponent from "../common/googleMap/Map";
import MarkerComponent from "../common/googleMap/Marker";
import { GoogleApiWrapper } from "google-maps-react";
import LocationSearchInputComponent from "../common/LocationSearchInput";
import { SelectField } from "react-semantic-redux-form";
import FPLabel from "../common/new/forms/ui/FPLabel";
import { FPButton } from "../common/new";

const validate = (values) => {
  const errors = {};
  if (!values.branch_name) {
    errors.branch_name = "Enter the Competitor branch location";
  }
  if (!values.brand_name) {
    errors.brand_name = "Please select a brand";
  }
  return errors;
};

class BranchCompetitorsForm extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const {
      brandName,
      handleBranchCompetitors,
      editBranchCompetitor,
      initialValues,
      toggleLimitModal,
    } = this.props;
    let newData = {
      location_address: data.branch_name.address,
      location_coordinates: ` ${data.branch_name.coordinates.lat},${data.branch_name.coordinates.lng} `,
      competition: brandName,
    };
    if (initialValues && initialValues.id) {
      newData.id = initialValues.id;
      return editBranchCompetitor(newData).catch((err) => {
        const { location_address, competition, ...rest } = err;
        const errorObj = {
          branch_name: location_address,
          brand_name: competition,
          rest,
        };
        const errObj = {
          _error: errorObj.non_field_errors,
          ...errorObj,
        };
        throw new SubmissionError(errObj);
      });
    } else {
      return handleBranchCompetitors(newData).catch((err) => {
        err.non_field_errors && toggleLimitModal(err.non_field_errors);
        const { location_address, competition, ...rest } = err;
        const errorObj = {
          branch_name: location_address,
          brand_name: competition,
          rest,
        };
        const errObj = {
          _error: errorObj.non_field_errors,
          ...errorObj,
        };
        throw new SubmissionError(errObj);
      });
    }
  }

  render() {
    const {
      handleSubmit,
      google,
      submitting,
      locationAddress,
      competitorOptions,
      initialValues,
    } = this.props;
    const choices = competitorOptions
      ? competitorOptions.choices.map((item) => {
          return { value: item.value, text: item.display_name };
        })
      : [];
    let modifiedAddress;
    if (locationAddress && locationAddress.coordinates) {
      modifiedAddress = {
        shape_type: "Point",
        lat_long: {
          coordinates: [
            locationAddress.coordinates.lng,
            locationAddress.coordinates.lat,
          ],
        },
      };
    }
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column>
          <Form onSubmit={handleSubmit(this.submit)}>
            {/* {errors && errors.non_field_errors ?
              this.limitPopup(errors.non_field_errors)
              : null} */}
            <div className="mb-3">
              <FPLabel isRequired={false}>Brand Name</FPLabel>
              <Field
                name="brand_name"
                placeholder="Competitor Brand name"
                component={SelectField}
                options={choices && choices}
                disabled={initialValues ? true : false}
              />
            </div>
            <div className="">
              <FPLabel isRequired={false}>Branch Location</FPLabel>
              <Field
                name="branch_name"
                placeholder="Search branch competitor"
                component={LocationSearchInputComponent}
                required
                height= '60px'
              />
            </div>
            <MapComponent
              google={google}
              height="42vh"
              locations={modifiedAddress ? [modifiedAddress] : []}
            >
              {locationAddress && locationAddress.coordinates && (
                <MarkerComponent position={locationAddress.coordinates} />
              )}
            </MapComponent>
            <FPButton
              className="mt-4"
              label="Submit"
              type="submit"
              disabled={submitting}
              fullWidth
            />
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}
const withReduxForm = reduxForm({
  // eslint-disable-line
  form: "BranchCompetitorsForm",
  validate,
})(BranchCompetitorsForm);

const selector = formValueSelector("BranchCompetitorsForm");

BranchCompetitorsForm = connect((state) => {
  // eslint-disable-line
  const locationAddress = selector(state, "branch_name");
  const brandName = selector(state, "brand_name");
  return {
    locationAddress,
    brandName,
  };
})(withReduxForm);

export default GoogleApiWrapper({
  // eslint-disable-line
  apiKey: "AIzaSyDtiQSYOJ6tdOSXoM6ZWbpNAOcQgmLWDxY",
  libraries: ["places", "drawing"],
  version: 3.34,
})(BranchCompetitorsForm);
