import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import ServiceRequestFilter from "./ServiceRequestFilter";
import SearchComponent from "../../components/common/SearchComponent";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { FPButton } from "../../components/common/new";
import AddIcon from "@material-ui/icons/Add";
import ServiceRequestCard from "./ServiceRequestCard";
import FormModal from "../../components/common/FormModal";
import CreateServiceRequest from "./CreateServiceRequest";
import AutoSelect from "../../components/common/AutoSelect";
import { ReactComponent as DropdownIcon } from "../../images/svg/dropdownUpIcon.svg";
import queryString from "query-string";
import * as serviceTicketActions from "../../actions/ticket/serviceRequestAction";
import * as DepartmentActions from "../../actions/department/departmentActions";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import LoaderComponent from "../../components/common/loaderComponent";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import * as ticketActions from "../../actions/ticket/ticketActions";
import LimitModal from "../../components/common/LimitModal";
import cookie from "react-cookies";

const TicketServiceRequest = (props) => {
  const {
    history,
    location,
    match: {
      params: { business_id },
    },
    match,
    actions,
    serviceTicketData,
    departmentActions,
    department,
    loader,
    branchOwnersList,
    filter,
    reviewActions,
    userList,
  } = props;
  const user = userList || cookie.load("user");
  const { pathname, search } = location;
  const [open, setOpen] = useState(false);
  const [selectedOrdering, setSelectedOrdering] = useState("false");
  const [totalTeamMember, setTotalTeamMember] = useState([]);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const data = {};

  const params = {
    ...queryString.parse(search),
  };
  useEffect(() => {
    document.title = "TicketServiceRequest";
    const payload = queryString.parse(search);
    actions.ServiceTicketListing(payload, business_id);
  }, [search]);

  useEffect(() => {
    let querKey = Object.keys(queryString.parse(search));

    if (querKey.includes("ordering")) {
      setSelectedOrdering(queryString.parse(search).ordering);
    } else {
      setSelectedOrdering(null);
    }
  }, [search]);

  useEffect(() => {
    actions.ServiceRequestTypeList(business_id);
    departmentActions.getDepartment(data, business_id);
    props.ticketActions.getTicketStatusOptions();
    props.actionsSelectTab.setSelectedTab(4);
    return axios_instance.get(URLS.AGENCY_USERS + "?business_id=" + business_id).then((res) => {
      setTotalTeamMember(res.data.user_list);
    });
  }, []);
  const CloseToggle = () => {
    setOpen(false);
  };

  const sortOptions = [
    {
      display_name: "Oldest",
      value: "created_at",
    },
    {
      display_name: "Newest",
      value: "-created_at",
    },
  ];

  const toggleDownloadModal = () => {
    setIsDownloadModalOpen(!isDownloadModalOpen);
  };

  const handleDownloadModal = (downloadFlag) => {
    setIsDownloadModalOpen(!isDownloadModalOpen);
    if (!downloadFlag) return;
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = props;
    const url = `${URLS.TICKET}${business_id}/service_request/download/${search}&celery_download=` + false;
    return reviewActions.downloadTemplate(url, "famepilot-service-ticket-report.csv");
  };

  const updateOrdering = (sortOrder) => {
    setSelectedOrdering(sortOrder);

    let filters = { ...queryString.parse(search), ordering: sortOrder };

    history.push({ pathname, search: queryString.stringify(filters) });
  };

  const createService = (data) => {
    console.log("Original items: ", data);
  
    const formData = new FormData();
  
    Object.keys(data).forEach((key) => {
      if (key !== 'attachment') {
        formData.append(key, data[key]);
      } else {
        const attachedFiles = data['attachment'];
        if (attachedFiles && attachedFiles.length > 0) {
          attachedFiles.forEach((file, index) => {
            if (file instanceof File) {
              formData.append(`attachment[${index}]`, file, file.name || 'unnamed-file');
            } else {
              console.warn('Attachment is not a valid File:', file);
            }
          });
        }
      }
    });
  
    actions.createServiceTicket(formData, business_id).then(() => {
      CloseToggle();
      actions.ServiceTicketListing(params, business_id);
    });
  };

  const getFilterData= (data) => {
    const business_id  = data.businessId

    delete data.businessId
    
    actions.ServiceTicketListing(data, business_id)
  }
  
  
  return (
    <div>
      <LoaderComponent loader={loader} />
      <ServiceRequestFilter
        history={history}
        location={location}
        match={match}
        departmentList={department?.results}
        ticketRequestTypeList={serviceTicketData?.serviceRequestType?.results}
        teamList={
          totalTeamMember &&
          totalTeamMember.map((item) => ({
            display_name: `${item.first_name} ${item.last_name}`,
            value: item.id,
          }))
        }
        downloadExcel={toggleDownloadModal}
        getFilterData={(data) => getFilterData(data)}
      />
      <div className="my-20">
        <CardWrapper showDivider={false}>
          <section className="d-flex gap-20 align-items-center justify-content-between">
            <SearchComponent
              location={location}
              history={history}
              className="ticket-search"
              placeholder="Search Ticket"
              endIcon
              size="small"
            />
            <div className="d-flex align-items-center gap-6">
              <div>
                <AutoSelect
                  placeholder="Sort By"
                  options={sortOptions}
                  onChange={(event, option) => {
                    updateOrdering(option);
                  }}
                  value={selectedOrdering}
                  className="sortingBox"
                  sx={{
                    width: 100,
                    "& fieldset": { borderRadius: 33 },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#F13A88 !important",
                    },
                    "& .MuiInputBase-input": {
                      height: 10,
                      minWidth: "60px !important",
                      fontSize: "12px",
                      color: "#646565 !important",
                      "&::placeholder": {
                        color: "#646565",
                      },
                    },
                  }}
                  disableClearable
                  popupIcon={<DropdownIcon className="dropDownICon" />}
                />
              </div>
              <FPButton
                label="Create Service Request"
                size="small"
                style={{ height: "27px" }}
                endIcon={<AddIcon style={{ height: 15, width: 15, color: "white" }} />}
                onClick={() => setOpen(true)}
              />
            </div>
          </section>
        </CardWrapper>
      </div>
      <ServiceRequestCard
        ticketService={serviceTicketData.serviceTickets}
        ticketRequestTypeList={serviceTicketData?.serviceRequestType?.results}
        actions={actions}
        match={match}
        departmentList={department?.results}
        locationList={filter?.branch?.choices}
        teamList={
          totalTeamMember &&
          totalTeamMember.map((item) => ({
            display_name: `${item.first_name} ${item.last_name}`,
            value: item.id,
          }))
        }
        location={location}
        count={serviceTicketData?.serviceTickets?.count}
        pageSizeCookie="ticket_service_request"
        profileId={user?.id}
        createService={createService}
      />

      <FormModal
        isOpen={open}
        toggle={CloseToggle}
        heading="Create Service Request"
        width={"100%"}
        maxWidth={"lg"}
        headerClass="d-flex align-items-center"
        paddingDense
      >
        <CreateServiceRequest
          createService={createService}
          ticketRequestTypeList={serviceTicketData?.serviceRequestType?.results}
          requestTypeOption={serviceTicketData.serviceRequestType}
          locationList={filter?.branch?.choices}
          actions={actions}
          match={match}
          departmentList={department?.results}
          location={location}
          branchOwnersList={branchOwnersList && branchOwnersList.results}
        />
      </FormModal>
      {isDownloadModalOpen && (
        <LimitModal
          isOpen={isDownloadModalOpen}
          isReviewsPage={true}
          toggle={() => setIsDownloadModalOpen(!isDownloadModalOpen)}
          HeaderText={serviceTicketData?.serviceTickets?.count < 5000 ? "Alrighty" : "Attention!"}
          message={
            serviceTicketData?.serviceTickets?.count < 5000
              ? ["Hey you are good to go. Click ok to download ticket service request"]
              : ["You can only download 5000 requests at a time.Click ok to continue."]
          }
          handleDownloadModal={handleDownloadModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userList: state.profile.user,
    serviceTicketData: state.serviceTickets,
    department: state.department.detail,
    filter: state.filter,
    loader: state.common.loader,
    branchList: state.business.branch_list,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    actions: bindActionCreators(serviceTicketActions, dispatch),
    departmentActions: bindActionCreators(DepartmentActions, dispatch),
    ticketActions: bindActionCreators(ticketActions, dispatch),
    reviewActions: bindActionCreators(ReviewsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TicketServiceRequest);
