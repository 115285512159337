import React from "react";
import queryString from "query-string";
import { Card, Collapse } from "reactstrap";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import "../genericTemplate/genericTemplateForm.css";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import editIcon from "../../../images/svg/EditPen.svg";
import deleteIcon from "../../../images/svg/trash.svg";
import { FPToolTip } from "../../common/new";
import ConfirmationModal from "../../common/ConfirmationModal";

class GenericTemplateTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpenArray: [],
      contactId: null,
    };
  }

  componentDidMount() {
    const { genericTemplateDetails } = this.props;
    const newIsOpenArray =
      genericTemplateDetails &&
      genericTemplateDetails.map((item) => {
        return { isOpenStatus: false, name: item.template_name };
      });

    this.setState({ isOpenArray: [...newIsOpenArray] });
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
      genericTemplateDetails,
    } = this.props;
    const {
      location: { search: prevSearch },
      genericTemplateDetails: prevGenericTemplateDetails,
    } = prevProps;
    const query = queryString.parse(search);
    const prevQuery = queryString.parse(prevSearch);

    if (
      prevQuery.page_size !== query.page_size ||
      prevQuery.page !== query.page ||
      genericTemplateDetails.length !== prevGenericTemplateDetails.length
    ) {
      const { genericTemplateDetails } = this.props;
      const newIsOpenArray =
        genericTemplateDetails &&
        genericTemplateDetails.map((item) => {
          return { isOpenStatus: false, name: item.template_name };
        });

      this.setState({ isOpenArray: [...newIsOpenArray] });
    }
  }

  handleCardClick = (index) => {
    const { isOpenArray } = this.state;
    if (isOpenArray[index].isOpenStatus === false) isOpenArray[index].isOpenStatus = true;
    else isOpenArray[index].isOpenStatus = false;
    this.setState({ isOpenArray: [...isOpenArray] });
  };
  toggleConfirm = (id) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      contactId: id,
    });
  };

  render() {
    const { genericTemplateDetails, toggle } = this.props;
    const { isOpenArray, contactId } = this.state;
    const templateList = genericTemplateDetails && genericTemplateDetails.length ? genericTemplateDetails : null;
    return (
      <div className="mt-2">
        {templateList &&
          templateList.map((item, index) => {
            const extraData = item.contacts.length ? Object.keys(item.contacts[0].extra_data) : null;
            const contactsList = item.contacts && item.contacts.length ? item.contacts : null;
            return (
              <div key={index} className="m-2 mb-3">
                <Card
                  onClick={() => {
                    this.handleCardClick(index);
                  }}
                >
                  <div
                    className="px-2 py-3 d-flex align-items-center gap-6"
                    style={{ cursor: "pointer", backgroundColor: "#F2F9F9" }}
                  >
                    <span className="genericIcon d-inline-flex">
                      {isOpenArray.length &&
                        isOpenArray[index] &&
                        (isOpenArray[index].isOpenStatus ? (
                          <RemoveIcon style={{ width: 15, height: 15 }} />
                        ) : (
                          <AddIcon style={{ width: 15, height: 15 }} />
                        ))}
                    </span>
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color:
                          isOpenArray.length && isOpenArray[index] && isOpenArray[index].isOpenStatus ? "#00B5AD" : "",
                      }}
                    >
                      {item.template_name.toUpperCase()}
                    </span>

                    {/* <span style={{ letterSpacing: '1.2px', fontSize: '14px', fontWeight: '600'}}><Icon className="circle-icon" name={(item === id && active === true) ? 'minus circle' : 'plus circle'} />{heading.toUpperCase()}({totalCount}) - [Positive - {pos_count}, Negative - {neg_count}, Neutral - {neu_count}]</span> */}
                  </div>
                </Card>
                <Collapse isOpen={isOpenArray.length && isOpenArray[index] ? isOpenArray[index].isOpenStatus : false}>
                  <div className="ui-table-responsive">
                    <Table className="fp-table fp-table-center">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Created At</TableCell>
                          <TableCell>Gender</TableCell>
                          <TableCell>Unique_code</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                          {extraData &&
                            extraData.map((item, index) => {
                              return <TableCell key={index}>{item}</TableCell>;
                            })}
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contactsList &&
                          contactsList.map((contact) => {
                            return (
                              <TableRow key={contact.id}>
                                <TableCell>{contact.name}</TableCell>
                                <TableCell>{contact.created_at}</TableCell>
                                <TableCell>{contact.gender}</TableCell>
                                <TableCell>{contact.unique_code}</TableCell>
                                <TableCell>{contact.email}</TableCell>
                                <TableCell>{contact.contact_number}</TableCell>
                                {extraData &&
                                  extraData.map((field, index) => {
                                    return <TableCell key={index}>{contact.extra_data[field]}</TableCell>;
                                  })}
                                <TableCell>
                                  <div className="actionButton">
                                    <FPToolTip title={"Edit"}>
                                      <img
                                        src={editIcon}
                                        onClick={() => {
                                          toggle(contact, "Generic_Contacts");
                                        }}
                                        role="button"
                                      />
                                    </FPToolTip>

                                    <FPToolTip title={"Delete"}>
                                      <img
                                        src={deleteIcon}
                                        onClick={() => this.toggleConfirm(contact.id)}
                                        // onClick={() => { this.props.genericContactDelete(contact.id); }}
                                        role="button"
                                      />
                                    </FPToolTip>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </div>
                </Collapse>
              </div>
            );
          })}
        <ConfirmationModal
          header="Are you sure!"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          subHeader="Are you sure you want to delete the contact. This action cannot be undone."
          isOpen={this.state.isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={() => this.props.genericContactDelete(contactId)}
        />
      </div>
    );
  }
}

export default GenericTemplateTable;
