import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import PropTypes from "prop-types";
import InputComponent from "../common/InputComponent";
import MultiSelect from "../common/SelectComponent";
import SelectComponent from "../common/SelectComponent";
import * as utils from "../../libs/utils";
import "react-responsive-modal/styles.css";
import "./Form.module.css";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import FPLabel from "../common/new/forms/ui/FPLabel";
import RenderPrintStarRating from "./PrintStarRating";
import { FPBreadcrumbs, FPButton } from "../common/new";
import MultiSelectRedux from "./MultiSelectRedux";

const validate = (values) => {
  const errors = {};
  if (!values.rule_name) errors.rule_name = "Enter Rule Name";
  if (!values.assigned_to) errors.assigned_to = "Select Assignee";
  if (!values.rating_options) errors.rating_options = "Select Rating";

  return errors;
};

class RuleCreationForm extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      locations: [],
      locationIds: [],
      // isOpen: false,
      err: "",
      newMembers: [],
      serviceOptions: [
        // { id: 1, label: "All", value: "all" },
        { id: 2, label: "Dining", value: "dining" },
        { id: 3, label: "Delivery", value: "delivery" },
        { id: 4, label: "Takeaway", value: "takeaway" },
      ],
    };
  }

  componentDidMount() {
    this.setState({ newMembers: this.props.branchOwnersList });
    const { details } = this.props;
    details.pop();
    details.pop();
    let locId = [];
    const locationData = details.map((item) => {
      locId.push(item.id);
      return {
        label: item.branch_name,
        value: item.id,
      };
    });
    this.setState({ locations: locationData, locationIds: locId });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps?.branchOwnersList?.length !== this.state.newMembers?.length) {
      this.setState({ newMembers: nextProps.branchOwnersList });
    }
  }

  handleChange = (e) => {
    var myData = Object.keys(e).map((key) => {
      return e[key];
    });
    // myData.pop();
    const selectedLocations = myData.map((item) => item.value);
    this.props.filterOwnersForSelectedBranch(selectedLocations);
  };

  handleChangeService = (e) => {
    var myData = Object.keys(e).map((key) => {
      return e[key];
    });
    const selectedLocations = myData.map((item) => item.value);
    // this.props.filterOwnersForSelectedBranch(selectedLocations);
  };

  submit = (data) => {
    const {
      router: { history },
    } = this.context;
    let newData;
    if (data.id) {
      newData =
        data.locations !== undefined
          ? { ...data, business: this.props.businessId }
          : {
              ...data,
              business: this.props.businessId,
              locations: this.state.locationIds,
            };
      let locationIdsList;
      if (newData.locations && typeof data.locations[0] === "object") {
        locationIdsList = newData.locations.map((item) => item.value);
        newData.locations = locationIdsList;
      }
      if (newData.locations.length === 0) {
        newData.locations = this.state.locationIds;
      }
      if (typeof data.assigned_to === "object") {
        newData.assigned_to = data.assigned_to.value;
      } else {
        newData.assigned_to = data.assigned_to;
      }
      if (typeof data.followed_by[0] === "object") {
        let followedByIdList = data.followed_by ? data.followed_by.map((item) => item.value) : null;
        newData.followed_by = followedByIdList ? followedByIdList : [];
      }

      if (typeof data.rating_options !== "object") {
        newData.rating_options = data.rating_options?.split(",");
      } else {
        newData.rating_options = data.rating_options;
      }

      if (data.service_type?.length > 0) {
        if (typeof data.service_type[0] === "object") {
          let serviceTypeList = data.service_type ? data.service_type.map((item) => item.value) : [];
          newData.service_type = serviceTypeList;
        }
      } else {
        let serviceTypeList = this.state.serviceOptions.map((item) => item.value);
        newData.service_type = serviceTypeList;
      }

      if (typeof data.providers[0] === "object") {
        let providerIdList = data.providers ? data.providers.map((item) => item.value) : null;
        newData.providers = providerIdList ? providerIdList : [];
      }
      if (newData.content_choice && newData.content_choice.length == 0) {
        delete newData["content_choice"];
      } else {
        newData.content_choice = newData.content_choice?.join(",");
      }
    } else {
      newData =
        data.locations !== undefined
          ? { ...data, business: this.props.businessId }
          : {
              ...data,
              business: this.props.businessId,
              locations: this.state.locationIds,
            };
      let locationIdsList;
      if (data.locations) {
        locationIdsList = data.locations.map((item) => item.value);
        newData.locations = locationIdsList;
      }
      newData.locations = newData.locations.length === 0 ? this.state.locationIds : newData.locations;
      newData.assigned_to = data.assigned_to.value;
      const followedByIdList = data.followed_by ? data.followed_by.map((item) => item.value) : null;
      newData.followed_by = followedByIdList ? followedByIdList : [];
      if (typeof data.rating_options !== "object") {
        newData.rating_options = data?.rating_options?.split(",");
      } else {
        newData.rating_options = data.rating_options;
      }

      if (data.service_type) {
        if (typeof data.service_type[0] === "object") {
          let serviceTypeList = data.service_type ? data.service_type.map((item) => item.value) : [];
          newData.service_type = serviceTypeList;
        }
      } else {
        let serviceTypeList = this.state.serviceOptions.map((item) => item.value);
        newData.service_type = serviceTypeList;
      }

      const providerIdList = data.providers ? data.providers.map((item) => item.value) : null;
      newData.providers = providerIdList ? providerIdList : [];
      if (newData.content_choice && newData.content_choice.length == 0) {
        delete newData["content_choice"];
      } else {
        newData.content_choice = newData.content_choice?.join(",");
      }
    }
    return this.props
      .submitData(newData)
      .then(() =>
        history.push({
          pathname: "central-setup",
          state: { tab: "7" },
        })
      )
      .catch((err) => {
        const error = err.rule_name ? err.rule_name : err?.error?.[0];
        this.setState({ err: error });
        err && err.non_field_errors && err.non_field_errors.map((err) => utils.displayMessage("negative", err));
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
  };

  render() {
    const { star_border_color } = utils.starRatingColor(utils.setDecimalIndex());
    const { reviewContentTypeOptions, businessId, isEditMode, errors } = this.props;
    console.log("errors", errors);
    const breadCrumbList = [
      {
        label: "Central Centup",
        link: `/${businessId}/central-setup?page_size=10`,
      },
      {
        label: "Setup Ticket Rules",
        onClick: () =>
          this.context.router.history.push({
            pathname: "central-setup",
            state: { tab: "7" },
          }),
      },
      {
        label: "Ticket Assignment Rule",
        onClick: () =>
          this.context.router.history.push({
            pathname: "central-setup",
            state: { tab: "7" },
          }),
      },
      {
        label: isEditMode ? "Edit Assignment Rule" : "Add Assignment Rule",
        link: "",
      },
    ];

    return (
      <div>
        <FPBreadcrumbs list={breadCrumbList} className="my-4" />
        <CardWrapper headerTitle={isEditMode ? "Edit Assignment Rule" : "Add Assignment Rule"} contentClass="p-4">
          <form className="grid-form" onSubmit={this.props.handleSubmit(this.submit)}>
            {errors && errors.non_field_errors
              ? errors.non_field_errors.map((error, index) => (
                  <p key={index} className="text-danger">
                    {error}
                  </p>
                ))
              : null}
            <div className="mt-2">
              <div className="py-2">
                <FPLabel>Rule Name</FPLabel>
                <Field
                  name="rule_name"
                  component={InputComponent}
                  type="text"
                  serverError={errors && errors.rule_name}
                  placeholder="Rule Name"
                />
                <Field
                  label="Rating"
                  name="rating_options"
                  component={RenderPrintStarRating}
                  serverError={errors && errors.rating_options}
                />
                <FPLabel className="mt-4" isRequired={false}>
                  Locations
                </FPLabel>
                <Field
                  name="locations"
                  component={MultiSelect}
                  options={this.state.locations}
                  placeholder="Select Locations (All Locations by default)"
                  labelKey="label"
                  valueKey="value"
                  multi
                  required={false}
                  onChange={(e) => this.handleChange(e)}
                  className={"h-100"}
                />

                <FPLabel className="mt-4" isRequired={false}>
                  Service Type
                </FPLabel>
                <Field
                  name="service_type"
                  component={MultiSelect}
                  options={this.state.serviceOptions}
                  placeholder="Select Service (All Service by default)"
                  labelKey="label"
                  valueKey="value"
                  multi
                  required={false}
                  onChange={(e) => this.handleChangeService(e)}
                  className={"h-100"}
                />

                <FPLabel className="mt-4" isRequired={false}>
                  Provider
                </FPLabel>
                <Field
                  name="providers"
                  component={MultiSelect}
                  options={this.props.providerOptions}
                  placeholder="Select providers"
                  labelKey="display_name"
                  valueKey="value"
                  multi
                  className={"h-100"}
                />
                <div className="d-flex align-items-center mt-0 pb-2 pt-3 gap-20">
                  <FPLabel required={false}>Content Type</FPLabel>
                  <Field
                    name="content_choice"
                    component={MultiSelectRedux}
                    options={reviewContentTypeOptions}
                    className="primary cursor-pointer"
                    style={{ height: "20px", width: "20px" }}
                  />
                </div>
                <FPLabel className="mt-2">Assign To</FPLabel>
                <Field
                  name="assigned_to"
                  component={SelectComponent}
                  options={this.state.newMembers}
                  placeholder="Select Assignee"
                  labelKey="display_name"
                  valueKey="value"
                  serverError={errors && errors.assigned_to}
                />
                <FPLabel className="mt-4" isRequired={false}>
                  Cc.
                </FPLabel>
                <Field
                  name="followed_by"
                  component={MultiSelect}
                  options={this.state.newMembers}
                  placeholder="Select persons to follow-up ticket (if any)"
                  labelKey="display_name"
                  valueKey="value"
                  multi
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-2 gap-6">
              <FPButton
                type="text"
                size="small"
                label="Cancel"
                variant="outline"
                onClick={() =>
                  this.context.router.history.push({
                    pathname: "central-setup",
                    state: { tab: "7" },
                  })
                }
              />
              <FPButton type="submit" size="small" label="Submit" />
            </div>
            {/* <Modal
              open={this.state.isOpen}
              onClose={() => this.setState({ isOpen: false })}
              center
              closeOnOverlayClick={true}
              classNames={{
                modal: "customModal",
              }}
            >
              <h1
                style={{
                  color: "red",
                  margin: "15px 0",
                }}
              >
                Error!
              </h1>
              <h5
                style={{
                  padding: "20px",
                }}
              >
                {this.state.err}
              </h5>
            </Modal> */}
          </form>
        </CardWrapper>
      </div>
    );
  }
}

RuleCreationForm.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

RuleCreationForm = reduxForm({
  form: "RuleCreationForm",
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(RuleCreationForm);

// const selector = formValueSelector('RuleCreationForm');

export default RuleCreationForm;
