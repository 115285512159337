import React from "react";
import { CopyIcon, FeedbackLastIcon } from "../../../images/svg";
import FPInput from "../../../components/common/new/forms/ui/FPInput";
import { Box } from "@mui/material";
import { copyFeedBack } from "../../../libs/utils";
import google from "../../../images/google.png";
import tripAdvisor from "../../../images/tripadvisor.png";
import zomato from "../../../images/zomato.png";
import FPChip from "../../../components/common/new/FPChip/FPChip";
import { Avatar } from "@material-ui/core";

const GoodByPage = ({ links }) => {
  const getLogo = (title, logo) => {
    switch (title) {
      case "google":
        return google;
      case "zomato":
        return zomato;
      case "tripadvisor":
        return tripAdvisor;
      default:
        return logo;
    }
  };

  const createButtons = () => {
    return Object.entries(links).map(([key, value]) =>
      value.link ? (
        <div key={key}>
          <a href={value.link} target="_blank" rel="noopener noreferrer"></a>
        </div>
      ) : null
    );
  };

  return (
    <>
      {false ? (
        <>
          <div className="welcome-image-container">
            <FeedbackLastIcon style={{ width: "100%", height: "100%" }} />
          </div>
          <div>
            <p
              style={{
                fontWeight: "600",
                fontSize: "22px",
                textAlign: "center",
                color: "#1B1C1D",
              }}
              className="mb-0 mt-3"
            >
              Thanks for your feedback!
            </p>
            <p
              style={{
                fontWeight: "300",
                fontSize: "18px",
                textAlign: "center",
                color: "#1B1C1D",
              }}
            >
              By making your voice heard, you help us improve Wow Momo.
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="welcome-image-container">
            <FeedbackLastIcon style={{ width: "30%", height: "30%" }} />
          </div>
          <div>
            <p
              style={{
                fontWeight: "600",
                fontSize: "22px",
                textAlign: "center",
                color: "#1B1C1D",
              }}
              className="mb-0 mt-3"
            >
              Thanks for your feedback!
            </p>
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                textAlign: "center",
                color: "#1B1C1D",
              }}
            >
              We’ve one last request, would you please share your feedback with
              others on the platform link below.
            </p>
          </div>
          <Box
            component="div"
            className="form-group mt-3 mb-0 d-flex align-center gap-15"
            sx={{
              "& .MuiInputBase-input": {
                border: "none !important",
                backgroundColor: "#F6F6F8 !important",
                borderRadius: "10px !important",
                padding: "11px 12px",
                fontSize: "13px !important",
              },
            }}
          >
            <FPInput
              disable={true}
              value="It was a great experience with wow momo. The burger is extremely delicious"
              type="text"
              wrapperClass="mb-0"
            />
            <div
              style={{ width: "25px", height: "25px", cursor: "pointer" }}
              onClick={() =>
                copyFeedBack(
                  "It was a great experience with wow momo. The burger is extremely delicious"
                )
              }
            >
              <CopyIcon style={{ width: "100%", height: "100%" }} />
            </div>
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              lineHeight: "10px",
              marginBottom: "10px",
            }}
          >
            <p className="mb-0">Copy</p>
            <span style={{ fontSize: "30px" }}>&#8226;</span>
            <p className="mb-0">Tap👇</p>
            <span style={{ fontSize: "30px" }}>&#8226;</span>
            <p className="mb-0">Past</p>
          </div>
          <div>
            <div className="social-btn-group">{createButtons()}</div>
            <Box className="d-flex justify-content-center gap-6 flex-wrap FpChip-Box feed-back-urls">
              {Object.entries(links).map(
                ([key, value]) =>
                  value.link && (
                    <Box
                      sx={{
                        "& .MuiButtonBase-root": {
                          background: `${
                            value.title === "zomato"
                              ? "#FD3248"
                              : value.title === "tripadvisor"
                              ? "#34E0A1"
                              : "#FF4B26"
                          } !important`,
                          border: "none",
                          padding: "4px 9px 4px 0px !important",
                        },
                      }}
                    >
                      <FPChip
                        key={key}
                        avatar={
                          <Avatar
                            alt={`${value.title}-logo`}
                            src={getLogo(value.title, value.logo)}
                            className="mr-2"
                          />
                        }
                        label={`Review us on ${value.title}`}
                        variant="outlined"
                        className="Fp-Chip"
                        onClick={() => window.open(value.link, "_blank")}
                      />
                    </Box>
                  )
              )}
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default GoodByPage;
