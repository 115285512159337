import React from 'react';
import SearchComponent from '../../components/common/SearchComponent';
import { AddMarketIcon } from '../../images/svg';

const MarketingFilterComponent = (props) => {
  const { toggle } = props;
  return (
    <div className="text-right">
      <SearchComponent className="mr-3" {...props}/>
      <img
        src={AddMarketIcon}
        alt=""
        onClick={() => toggle('add')}
        height="28"
        width="28"
        title="Add Marketing Branch"
        className="cursor-pointer ml-2"
      />
    </div>
  );
};

export default MarketingFilterComponent;
