/* eslint-disable */
import React from 'react';
import moment from 'moment';
import { Table, Checkbox, Button } from 'semantic-ui-react';
import { Card, Row, Col, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import PaginationComponent from '../common/Pagination';
import BackIcon from '../../images/svg/backIcon.svg';
import * as utils from '../../libs/utils';

class InvitedMemberList extends React.PureComponent{
  constructor(props){
    super(props);
    this.state = {
      checkedList : [],
      all : false,
      toggle : true,
      showMessage: false,
      message: null,
      messageType: null,
      followUpLoader: false,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleFollowUp = this.handleFollowUp.bind(this);
  }

  componentDidUpdate(){
    const { showMessage } = this.state;
    if(showMessage) this.setState({ showMessage: false });
  }

  handleOnChange(event,data){
    if(data.id === 'all'){
      if(data.checked){
        this.setState({checkedList:[], unCheckedList:[], all:true, showMessage:false});
      }
      else{
        this.setState({checkedList:[], unCheckedList:[], all:false, showMessage:false});
      }
      return ;
    }
    const { checkedList, unCheckedList ,all } = this.state;
    if(!all){
      let list = checkedList;
      if(data.checked) list.push(data.id);
      else{
        let index = list.indexOf(data.id);
        index>-1?list.splice(index,1):null;
      }
      this.setState({checkedList: list, toggle:!this.state.toggle, showMessage:false});
    }
    else{
      let list = unCheckedList;
      if(!data.checked) list.push(data.id);
      else{
        let index = list.indexOf(data.id);
        index>-1?list.splice(index,1):null;
      }
      this.setState({unCheckedList: list, toggle:!this.state.toggle, showMessage:false});
    }
  }

  handleFollowUp(){
    const { all, checkedList, unCheckedList} = this.state;
    this.setState( { followUpLoader: true,showMessage: false });
    const data = {
      'all' : all,
      'contacts' : checkedList,
      'exclude_contacts' : unCheckedList
    };
    return (this.props.followUp(data)
      .then((res) => {
        this.setState({
          showMessage: true,
          message: res.data.invites,
          messageType: 'positive',
          followUpLoader: false,
          all: false,
          checkedList: [],
          unCheckedList: [],
        });
      })
      .catch((err) => {
        this.setState({ showMessage: true, message: err.invites, messageType: 'negative', followUpLoader: false});
        console.log(err);
      })
    );
  }

  render(){
    const { list, location, businessId, type } = this.props;
    const { checkedList, unCheckedList, all, showMessage, followUpLoader, messageType, message } = this.state;
    const isDefault = list && list.is_default;
    return (
      <Row>
        <Col sm="12" className="mb-2">
          <Card body className="py-2">
            <Row className="align-items-center">
              <Col md="8">
                <CardTitle className="mb-0 fw3">
                  <Link to={`/${businessId}/campaigns/${type}`} className="mr-2">
                    <img src={BackIcon} alt="" height="20" width="20" className="align-bottom"/>
                  </Link>
                  Invited Members ({ list && list.campaign_name ? list.campaign_name : '' })
                </CardTitle>
              </Col>
              { (type==='review' && !isDefault) && <Col md="4">
                <div className="text-right">
                  <Button
                    loading={followUpLoader}
                    className="ui button teal mb-0 mr-0"
                    onClick={this.handleFollowUp}>
                    Follow Up
                  </Button>
                  {showMessage && utils.displayMessage(messageType,message) && ''}
                </div>
              </Col>}
            </Row>
          </Card>
        </Col>
        <Col sm="12" className="mt-1">
          <Card body>
            <div className="ui-table-responsive">
              <Table unstackable>
                <Table.Header>
                  <Table.Row>
                    {(!isDefault && type==='review') && <Table.HeaderCell>
                      <Checkbox id='all' checked={all} onChange={this.handleOnChange}>Select All</Checkbox>
                    </Table.HeaderCell>}
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>{(list && list.method==='Email')?'Email':'Contact'}</Table.HeaderCell>
                    {list && list.method === 'Email and Sms' && <Table.HeaderCell>Email</Table.HeaderCell>}
                    {/* {!isDefault && <Table.HeaderCell>Status</Table.HeaderCell>} */}
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Created On</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {list && list.results && list.results.map((item, key) => (
                    <Table.Row key={key}>
                      {(!isDefault && type==='review') && <Table.Cell>
                        <Checkbox id={item.member.id} onChange={this.handleOnChange} checked={item.status==='active' && (all?(unCheckedList.indexOf(item.member.id)<0):(checkedList.indexOf(item.member.id)>-1))} disabled={item.status!=='active'}/>
                      </Table.Cell>}
                      <Table.Cell>{item.member.name}</Table.Cell>
                      <Table.Cell>{list.method === 'Email' ? item.member.email : item.member.contact_number}</Table.Cell>
                      {list && list.method === 'Email and Sms' && <Table.Cell>{item.member.email}</Table.Cell>}
                      <Table.Cell>{item.status==='active'?'1st request sent':(item.status==='in_process'?'2nd request sent':(item.status==='disabled'?'Unsubscribed':item.status))}</Table.Cell>
                      <Table.Cell>{moment(item.created_at).format('DD-MM-YYYY')}</Table.Cell>
                    </Table.Row>
                  ))}
                  { list && list.results  && !list.results.length && <Table.Row><Table.Cell className="text-center" colSpan={4}>No records Found </Table.Cell></Table.Row>}
                </Table.Body>
              </Table>
            </div>
          </Card>
          <PaginationComponent
            count={list && list.count}
            location={location}
            pageSizeCookie='invited_members'
          />
        </Col>
      </Row>
    );
  }
}

export default InvitedMemberList;
