import React from "react";
import { Field, reduxForm, SubmissionError, formValueSelector } from "redux-form";
import PhoneInputField from "../common/phonefields";
import ToggleComponent from "../common/ToggleComponent";
import { Row, Col } from "reactstrap";
import FPLabel from "../common/new/forms/ui/FPLabel";
import { Box, Divider } from "@material-ui/core";
import InputComponent from "../common/InputComponent";
import { FPButton } from "../common/new";
import { renderAutoSelectField } from "../common/RenderAutoSelectField";
import { connect } from "react-redux";

const validate = (values) => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = "Enter first name";
  } else if (!/^[a-zA-Z\s]+$/i.test(values.first_name)) {
    errors.first_name = "Invalid First Name";
  }
  if (!values.last_name) {
    errors.last_name = "Enter Last name";
  } else if (!/^[a-zA-Z\s]+$/i.test(values.last_name)) {
    errors.last_name = "Invalid Last Name";
  }
  if (!values.email) {
    errors.email = "Enter Email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.contact_number) errors.contact_number = "Enter contact number";
  if (!values.location || values.location?.length === 0) errors.location = "The team member must be linked with at least one location";
  return errors;
};

const MemberForm = (props) => {
  const {
    handleSubmit,
    addTeamMember,
    submitting,
    initialValues,
    branchList,
    department,
    designation,
    enableTicketCreation,
    errors,
  } = props;

  const submit = (data) => {
    const errors = validate(data);
    if (Object.keys(errors).length > 0) {
      throw new SubmissionError(errors);
    }
    if (!data.enable_sr_module){
      data.enable_sr_module = false;
    }
    return addTeamMember(data).catch((err) => {
      const errobj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errobj);
    });
  };

  const departmentList = department?.map((item) => ({
    value: item?.id,
    display_name: item?.name,
  }));
  const designationList = designation?.map((item) => ({
    value: item?.id,
    display_name: item?.name,
  }));

  return (
    <form onSubmit={handleSubmit(submit)} className="grid-form">
      {errors && errors.non_field_errors
        ? errors.non_field_errors.map((error, index) => (
            <p key={index} className="text-danger">
              {error}
            </p>
          ))
        : null}
      <Row>
        <Col sm="6">
          <FPLabel isRequired={true}>First Name</FPLabel>
          <Field name="first_name" component={InputComponent} placeholder="Enter First Name" type="text" />
        </Col>
        <Col sm="6">
          <FPLabel isRequired={true}>Last Name</FPLabel>
          <Field name="last_name" component={InputComponent} placeholder="Enter Last Name" type="text" />
        </Col>
        <Col sm="6">
          <FPLabel isRequired={true}>Email</FPLabel>
          <Field
            name="email"
            component={InputComponent}
            placeholder="Email"
            type="email"
            disabled={initialValues && initialValues.email}
          />
        </Col>
        <Col sm="6">
          <FPLabel isRequired={true}>Mobile Number</FPLabel>
          <Box sx={{ minHeight: "61px" }}>
            <Field name="contact_number" component={PhoneInputField} placeholder="Mobile Number" type="text" />
          </Box>
        </Col>
        <Col sm="6">
          <FPLabel isRequired={false}>Department</FPLabel>
          <Field
            name="department"
            component={renderAutoSelectField}
            options={departmentList}
            placeholder="Select Department"
          />
        </Col>
        <Col sm="6">
          <FPLabel isRequired={false}>Designation</FPLabel>
          <Field
            name="designation"
            component={renderAutoSelectField}
            options={designationList}
            placeholder="Select Designation"
          />
        </Col>
        <Col sm="12" className="mt-3">
          <FPLabel isRequired={true}>Location</FPLabel>
          <Field
            name="location"
            component={renderAutoSelectField}
            options={branchList?.choices?.length > 0 ? branchList.choices : []}
            placeholder="Select Location"
            multiple={true}
            limitTags={3}
            selectAll = {true}
          />
        </Col>
        <Col sm="12">
          <Box
            sx={{
              border: "1px solid  rgba(191, 194, 203, 1)",
              borderRadius: 8,
            }}
            className="px-3 py-3 mt-4"
          >
            <p style={{ color: "rgba(100, 101, 101, 1)", fontSize: "17px" }} className="mb-3">
              Permissions
            </p>
            <div className="permissionBox gap-10 mb-3">
              <div className="align-center justify-content-between">
                <p
                  style={{
                    color: "#1A2544",
                    fontSize: "15px",
                    fontWeight: 500,
                  }}
                  className="mb-0"
                >
                  Only Enable Service Request Module
                </p>
                <Field
                  name="enable_sr_module"
                  component={ToggleComponent}
                  type="checkbox"
                />
              </div>
            </div>
            <div className="permissionBox gap-10 mb-3">
              <p style={{ color: "#1A2544", fontSize: "15px", fontWeight: 500 }} className="mb-0">
                Reviews
              </p>
              <Divider className="" />
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between align-items-center w-50">
                  <FPLabel required={false} className="m-0">
                    <span className="permissionLable">Enable Review Reply</span>
                  </FPLabel>
                  <Field name="enable_review_reply" component={ToggleComponent} type="checkbox" />
                </div>
                <Divider orientation="vertical" flexItem className="mx-3" />
                <div className="d-flex justify-content-between align-items-center w-50">
                  <FPLabel required={false} className="m-0">
                    <span className="permissionLable">Enable Offline Forms</span>
                  </FPLabel>
                  <Field name="enable_offline_forms" component={ToggleComponent} type="checkbox" />
                </div>
              </div>
              <Divider className="" />
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between align-items-center w-50">
                  <FPLabel required={false} className="m-0">
                    <span className="permissionLable">Enable Customer Care Forms</span>
                  </FPLabel>
                  <Field name="enable_customer_care_forms" component={ToggleComponent} type="checkbox" />
                </div>
                <Divider orientation="vertical" flexItem className="mx-3" />
                <div className="d-flex justify-content-between align-items-center w-50">
                  <FPLabel required={false} className="m-0">
                    <span className="permissionLable">Enable Swiggy Forms</span>
                  </FPLabel>
                  <Field name="enable_swiggy_forms" component={ToggleComponent} type="checkbox" />
                </div>
              </div>
              <Divider className="" />
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between align-items-center w-50">
                  <FPLabel required={false} className="m-0">
                    <span className="permissionLable">Enable Zomato Forms</span>
                  </FPLabel>
                  <Field name="enable_zomato_forms" component={ToggleComponent} type="checkbox" />
                </div>
                <Divider orientation="vertical" flexItem className="mx-3" />
                <div className="d-flex justify-content-between align-items-center w-50">
                  <FPLabel required={false} className="m-0">
                    <span className="permissionLable">Enable Magic Pin Creation</span>
                  </FPLabel>
                  <Field name="enable_magic_pin_forms" component={ToggleComponent} type="checkbox" />
                </div>
              </div>
              <Divider className="" />
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between align-items-center w-50">
                  <FPLabel required={false} className="m-0">
                    <span className="permissionLable">Enable Contact Page</span>
                  </FPLabel>
                  <Field name="enable_contact_page" component={ToggleComponent} type="checkbox" />
                </div>
                <Divider orientation="vertical" flexItem className="mx-3 invisible" />
                <div className="d-flex justify-content-between align-items-center w-50"></div>
              </div>
            </div>

            <div className="permissionBox gap-10 mb-3">
              <div className="align-center justify-content-between">
                <p
                  style={{
                    color: "#1A2544",
                    fontSize: "15px",
                    fontWeight: 500,
                  }}
                  className="mb-0"
                >
                  Enable Ticket Creation
                </p>
                <Field name="enable_ticket_creation" component={ToggleComponent} type="checkbox" />
              </div>
              {enableTicketCreation && (
                <>
                  <Divider className="" />
                  <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between align-items-center w-50">
                      <FPLabel required={false} className="m-0">
                        <span className="permissionLable">Enable Ticket Activity</span>
                      </FPLabel>
                      <Field name="enable_ticket_activity" component={ToggleComponent} type="checkbox" />
                    </div>
                    <Divider orientation="vertical" flexItem className="mx-3" />
                    <div className="d-flex justify-content-between align-items-center w-50">
                      <FPLabel required={false} className="m-0">
                        <span className="permissionLable">Enable Ticket Close</span>
                      </FPLabel>
                      <Field name="enable_ticket_close" component={ToggleComponent} type="checkbox" />
                    </div>
                  </div>
                  <Divider className="" />
                  <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between align-items-center w-50">
                      <FPLabel required={false} className="m-0">
                        <span className="permissionLable">Enable Ticket Assign</span>
                      </FPLabel>
                      <Field name="enable_ticket_assign" component={ToggleComponent} type="checkbox" />
                    </div>
                    <Divider orientation="vertical" flexItem className="mx-3 invisible" />
                    <div className="d-flex justify-content-between align-items-center w-50"></div>
                  </div>
                </>
              )}
            </div>

            <div className="permissionBox gap-10 mb-3">
              <p style={{ color: "#1A2544", fontSize: "15px", fontWeight: 500 }} className="mb-0">
                Social
              </p>
              <Divider className="" />
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between align-items-center w-50">
                  <FPLabel required={false} className="m-0">
                    <span className="permissionLable">Enable Member Actions</span>
                  </FPLabel>
                  <Field name="enable_mention_action" component={ToggleComponent} type="checkbox" />
                </div>
                <Divider orientation="vertical" flexItem className="mx-3 invisible" />
                <div className="d-flex justify-content-between align-items-center w-50"></div>
              </div>
            </div>


            <div className="permissionBox gap-10 mb-3">
              <p style={{ color: "#1A2544", fontSize: "15px", fontWeight: 500 }} className="mb-0">
                Listing
              </p>
              <Divider className="" />
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between align-items-center w-50">
                  <FPLabel required={false} className="m-0">
                    <span className="permissionLable">Enable Listing Product</span>
                  </FPLabel>
                  <Field name="enable_listing_product" component={ToggleComponent} type="checkbox" />
                </div>
                <Divider orientation="vertical" flexItem className="mx-3 invisible" />
                <div className="d-flex justify-content-between align-items-center w-50"></div>
              </div>
            </div>

            <div className="permissionBox gap-10 mb-3">
              <p style={{ color: "#1A2544", fontSize: "15px", fontWeight: 500 }} className="mb-0">
                Marketplace
              </p>
              <Divider className="" />
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between align-items-center w-50">
                  <FPLabel required={false} className="m-0">
                    <span className="permissionLable">Enable Marketplace</span>
                  </FPLabel>
                  <Field name="enable_marketplace" component={ToggleComponent} type="checkbox" />
                </div>
                <Divider orientation="vertical" flexItem className="mx-3 invisible" />
                <div className="d-flex justify-content-between align-items-center w-50"></div>
              </div>
            </div>

            <div className="permissionBox gap-10 mb-1">
              <p style={{ color: "#1A2544", fontSize: "15px", fontWeight: 500 }} className="mb-0">
                WhatsApp
              </p>
              <Divider className="" />
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between align-items-center w-50">
                  <FPLabel required={false} className="m-0">
                    <span className="permissionLable">Enable WhatsApp</span>
                  </FPLabel>
                  <Field name="enable_whatsapp" component={ToggleComponent} type="checkbox" />
                </div>
                <Divider orientation="vertical" flexItem className="mx-3 invisible" />
                <div className="d-flex justify-content-between align-items-center w-50"></div>
              </div>
            </div>

          </Box>
        </Col>
        <Col>
          <FPButton
            type="submit"
            disabled={submitting}
            fullWidth
            label={"Submit"}
            className="mt-4"
            fontWeight="600"
            fontSize={16}
          />
        </Col>
      </Row>
    </form>
  );
};

// Form value selector
const selector = formValueSelector("MemberForm");

const mapStateToProps = (state) => ({
  enableTicketCreation: selector(state, "enable_ticket_creation"),
});

// Connecting Redux Form
export default connect(mapStateToProps)(
  reduxForm({
    form: "MemberForm",
    validate,
    enableReinitialize: true,
  })(MemberForm)
);
