import React from "react";
import BusinessListComponent from "../../components/admin/BusinessList";
import PropTypes from "prop-types";
import queryString from "query-string";
import cookie from "react-cookies";
import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import NavBar from "../../components/common/NavBar";
import LoaderComponent from "../../components/common/loaderComponent";
import { Box } from "@material-ui/core";

class BusinessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.getBusinessList = this.getBusinessList.bind(this);

    //PageSize Handling
    const {
      history,
      location: { pathname, search },
    } = props;
    const pageSize = cookie.load("pageSize") || {};
    history.replace({
      pathname,
      search: queryString.stringify({ ...queryString.parse(search), page_size: pageSize.business_list || 10 }),
    });
  }

  getBusinessList(params) {
    return this.props.actions.getAdminBusineesList(params);
  }

  componentWillMount() {
    // eslint-disable-line
    const {
      location: { search },
    } = this.props;
    this.getBusinessList(queryString.parse(search));
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-line
    const {
      location: { search: prevSearch },
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;
    if (prevSearch !== nextSearch) {
      this.getBusinessList(queryString.parse(nextSearch));
    }
    document.title = "famepilot | Admin Panel";
  }

  componentDidMount() {
    this.props.actions.clearBusiness();
  }

  render() {
    const {
      loader,
      businessList,
      match: {
        params: { business_id },
      },
    } = this.props;
    return (
      <div>
        <LoaderComponent loader={loader} />
        <NavBar match={this.props.match} history={this.props.history}>
          <Box
            sx={{
              margin: "20px -10px",
            }}
          >
            <BusinessListComponent
              businessList={businessList}
              businessId={business_id}
              {...this.props}
              count={businessList && businessList.count}
              location={this.props.location}
              pageSizeCookie="business_list"
            />
          </Box>
        </NavBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businessList: state.business.business,
    errors: state.business.errors,
    loader: state.common.loader,
  };
};

BusinessPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

BusinessPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPage);
