import React from "react";
import { Row, Card } from "reactstrap";
import { Formik, Form } from "formik";
import moment from "moment";
import queryString from "query-string";
import cookie from "react-cookies";
import { FPButton } from "../common/new";
import resetIcon from "../../images/Reset.png";
import FormAutoComplete from "../common/new/forms/FormAutoSelect";
import Flatpickr from "react-flatpickr";

class CompetitorsFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    const query = props.location ? queryString.parse(props.location.search) : {};
    this.initialValues = {
      location: +query.location || "",
      branch: query.branch ? query.branch.split(",").map(Number) : [],
      provider: query.provider ? query.provider.split(",").map(Number) : [],
      selected_date: query.selected_date || new Date(), // Single date field
    };
  }

  handleDateChange = (date, setFieldValue) => {
    setFieldValue("selected_date", date);
  };

  applyFilters = (values, { setSubmitting }) => {
    const { history, location } = this.props;
    const queryparams = queryString.parse(location.search);
    
    let filters = { ...queryparams };
    
    if (values.provider && values.provider.length) {
      filters.provider = values.provider.join(",");
    }
    
    if (values.branch && values.branch.length) {
      filters.branch = values.branch.join(",");
    }
    
    if (values.location) {
      filters.location = values.location;
    }

    filters.selected_date = moment(values.selected_date).format("YYYY-MM-DD");

    history.push({ pathname: location.pathname, search: queryString.stringify(filters) });
    setSubmitting(false);
  };

  resetFilters = (resetForm) => {
    const { history, location } = this.props;
    resetForm();
    history.push({ pathname: location.pathname, search: queryString.stringify({ page_size: queryString.parse(location.search).page_size, date_type: queryString.parse(location.search).date_type  }) });
  };

  render() {
    const { brandOptions, competitorSourceOptions, locationOptions, dateOptions } = this.props;
    const service_category = cookie.load("service_category");
    let filterSourceCompetitor = [];
    competitorSourceOptions?.forEach((competitor) => {
      if (competitor.category === service_category) {
        filterSourceCompetitor.push(
          competitor.web_portals.map((data) => ({ value: data.id, display_name: data.provider }))
        );
      }
    });
    return (
      <Card body className="py-3 form-label reportFilter">
        <Row className="m-0">
          <Formik initialValues={this.initialValues} onSubmit={this.applyFilters}>
            {({ values, handleChange, setFieldValue, resetForm }) => (
              <Form className="w-100">
                {/* Single Date Picker */}
                <div className="mt-2 side-filter">
                  <Flatpickr
                    className="input-width fp-date"
                    value={values.selected_date}
                    options={{ dateFormat: "Y-m-d" }} // Single date selection
                    onChange={([date]) => this.handleDateChange(date, setFieldValue)}
                  />
                </div>

                {/* Location Filter */}
                <div className="mt-2">
                  <FormAutoComplete
                    name="location"
                    placeholder="Select Location"
                    options={locationOptions}
                  />
                </div>
                <div className="mt-2 side-filter">
                  <FormAutoComplete
                    name="provider"
                    placeholder="Source"
                    options={filterSourceCompetitor?.[0] || []}
                    multiple={true}
                  />
                </div>
                <div className="mt-2 side-filter">
                  <FormAutoComplete
                    name="branch"
                    placeholder="Competitor Brands"
                    options={brandOptions}
                    multiple={true}
                  />
                </div>
                <div className="text-center mt-2 w-100 d-flex gap-10">
                  <FPButton className="w-50" label="Apply" type="submit" />
                  <FPButton
                    className="w-50"
                    label="Reset"
                    onClick={() => this.resetFilters(resetForm)}
                    backgroundColor="#00B5AD"
                    startIcon={<img src={resetIcon} alt="reset" />}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Row>
      </Card>
    );
  }
}

export default CompetitorsFilter;