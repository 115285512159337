import { List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { FPButton } from "../common/new";
import AddInboxCategoryModal from "./AddInboxCategoryModal";

const mockCategory = [
  {
    id: 0,
    name: "All",
    count: "35.5k",
    icon: "",
  },
  {
    id: 1,
    name: "Assign to me",
    count: "50",
    icon: "👨‍🦱",
  },
  {
    id: 2,
    name: "Important",
    count: "40",
    icon: "📌",
  },
  {
    id: 3,
    name: "Messages",
    count: "204",
    icon: "💬",
  },
  {
    id: 4,
    name: "Leads",
    count: "34",
    icon: "💥",
  },
  {
    id: 5,
    name: "Reviews",
    count: "234",
    icon: "⭐",
  },
  {
    id: 6,
    name: "Appointments",
    count: "12",
    icon: "📅",
  },
  {
    id: 7,
    name: "Spam",
    count: "124",
    icon: "🗞️",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 0,
  },
  wrapper: {
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#82cbcf1a",
    },
    "&.Mui-selected": {
      "&:hover": {
        backgroundColor: "#82cbcf1a !important",
      },
      backgroundColor: "#DDEFF0",
      "& .name": {
        color: "#438E96 !important",
      },
    },
  },
  icon: {
    minWidth: "26px",
    display: "inline-block",
    marginLeft: 8,
    color: "#000000",
  },
  name: {
    color: "#1B1C1D",
    fontWeight: 400,
    fontSize: 18,
  },
  count: {
    color: "#7A8193",
    minWidth: "unset",
    fontSize: 13,
  },
}));

const InboxCategory = () => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isAddCategoryOpen, setIsAddCategoryOpen] = useState(false);

  const toggleCategoryModal = () => {
    setIsAddCategoryOpen((preValue) => !preValue);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <>
      <List
        component="nav"
        className={classes.root}
        aria-label="inbox-category"
        //   dense={true}
      >
        {mockCategory.map((item, index) => {
          return (
            <ListItem
              button
              key={`${item.id}`}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
              className={classes.wrapper}
            >
              <ListItemText component="div">
                <span className={`${classes.name} name`}>{item.name}</span>
                <span className={classes.icon}>{item.icon}</span>
              </ListItemText>
              <ListItemIcon className={classes.count}>{item.count}</ListItemIcon>
            </ListItem>
          );
        })}
      </List>

      <FPButton fullWidth className="mt-3" onClick={toggleCategoryModal}>
        Create a new Inbox
      </FPButton>

      <AddInboxCategoryModal isOpen={isAddCategoryOpen} toggle={toggleCategoryModal} />
    </>
  );
};

export default InboxCategory;
