import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { connect } from 'react-redux';

import * as utils from '../../libs/utils';

import * as BusinessSetupActions from '../../actions/business/businessSetupActions';

import { Row, Col } from 'reactstrap';
import moment from 'moment/moment';
import SmartResponseForm from '../../components/business/SmartResponseForm';
import LoaderComponent from '../../components/common/loaderComponent';
import ConfirmationModal from '../../components/common/ConfirmationModal';
// import MacrosInfo from '../../components/common/MacrosInfo';

class SmartResponseEditPage extends React.Component{
  constructor(props){
    super(props);
    this.state={ options : []};
    this.submitData = this.submitData.bind(this);
    this.deleteResponse = this.deleteResponse.bind(this);
  }

  submitData(data) {
    const { match: { params: { business_id }} } = this.props;
    return this.props.actions.postSmartResponse(data, business_id);
  }

  componentDidMount() {
    // TO DO
    const { match: { params: { business_id }} } = this.props;
    const { route: { location: { search }}} = this.context.router;
    const { id } = queryString.parse(search);
    this.props.actions.allowedForOptions(business_id);
    this.props.actions.reviewTypeOptions(business_id);
    this.props.actions.reviewContentTypeOptions(business_id);
    if (id) this.props.actions.getResponseDetails({ business_id, id });
    this.props.actions.businessBranchOptions({business: business_id },true);
  }

  deleteResponse(id){
    const { history, match: { params: { business_id }} } = this.props;
    this.props.actions.responseDelete({business_id, id})
      .then(()=>{
        utils.displayMessage('positive','Response Deleted Successfully');
        history.push({
          pathname: 'central-setup',
          state: { tab: '3'}
        });
      })
      .catch((err)=>{
        utils.displayMessage('negative','Error Deleting Response');
        console.log(err);
      });
  }

  toggleConfirm = (confirmationFunction) => {
    this.setState({ isConfirmOpen: !this.state.isConfirmOpen, confirmationFunction });
  }

  render() {
    // const { route: { location: { search }}} = this.context.router;
    const { route: { match: {  params: { business_id} }, location: { search } } } = this.context.router;
    const { id } = queryString.parse(search);
    const { responseDetail, allowedForOptions, reviewTypeOptions, branchOptions, reviewContentTypeOptions } = this.props;
    const { isConfirmOpen, confirmationFunction } = this.state;
    const newData = {
      title: 'Smart_Response' + moment().format('_DD-MM(HH:mm:ss)'),
      content_choice : 'WITH_CONTENT,WITHOUT_CONTENT'
    };
    if (id && (+id !== (responseDetail && responseDetail.id))) return null;
    const formData = id ? responseDetail : newData;
    return (
      <Row className="content-area">
        <LoaderComponent loader={this.props.loader}/>
        <Col xs={12} className="business-setup p-3">
          <SmartResponseForm
            submitData={this.submitData}
            initialValues={formData}
            allowedForOptions={allowedForOptions}
            locationsList={branchOptions && branchOptions.choices}
            reviewTypeOptions={reviewTypeOptions && reviewTypeOptions.choices}
            deleteResponse={(id)=>this.toggleConfirm(()=>this.deleteResponse(id))}
            reviewContentTypeOptions = {reviewContentTypeOptions && reviewContentTypeOptions.choices}
            businessId={business_id}
          />
        </Col>
        
        
        <ConfirmationModal
          header="Are you sure!"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          subHeader='Are you sure you want to delete? This cannot be undone.'
          isOpen={isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={confirmationFunction}
        />
      </Row>
    );
  }
}

SmartResponseEditPage.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  return {
    responseDetail : state.business.response_details,
    allowedForOptions: state.business.allowed_for_options,
    reviewTypeOptions: state.business.review_type_options,
    branchOptions: state.business.branches,
    loader: state.common.loader,
    reviewContentTypeOptions: state.business.review_content_type_options
  };
};


SmartResponseEditPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(SmartResponseEditPage);
