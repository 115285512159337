import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SortIcon from "../../../../images/svg/Sort.svg";
import { setDateFormat } from "../../../../libs/utils";
import { FPButton, FPToolTip } from "../../../../components/common/new";
import { RxCross1 } from "react-icons/rx";
import { MdOutlineDone } from "react-icons/md";
import Popup from "reactjs-popup";

const BulkChangesStatus = ({ bulkChangesStatus }) => {
  console.log(
    "bulkChangesStatusbulkChangesStatus",
    bulkChangesStatus?.bulk_changes_status
  );

  return (
    <Table className="fp-table fp-table-center">
      <TableHead>
        <TableRow>
          <TableCell>Location</TableCell>
          <TableCell>
            IsCompleted
            {/* <SortIcon /> */}
          </TableCell>
          <TableCell>
            Status
            {/* <SortIcon /> */}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {bulkChangesStatus?.bulk_changes_status?.length > 0 ? (
          bulkChangesStatus?.bulk_changes_status?.map((data) => {
            return (
              <>
                <TableRow>
                  <TableCell>
                    <div>{data?.location_name}</div>
                  </TableCell>
                  <TableCell
                    className="width-md"
                    // onClick={() => viewInvitedMembers(item)}
                  >
                    {data?.is_completed === false ? (
                      <RxCross1
                        style={{
                          color: "red",
                          fontSize: "20px",
                          border: "1px solid",
                          borderRadius: "20px",
                        }}
                      />
                    ) : (
                      <MdOutlineDone
                        style={{
                          color: "green",
                          fontSize: "20px",
                          border: "1px solid",
                          borderRadius: "20px",
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <span
                      style={{
                        backgroundColor:
                        data?.success_req_count === data?.total_req_count
                            ? "green"
                            : "red",
                        padding: "10px",
                        borderRadius: "20px",
                        color: "white",
                      }}
                    >{`${data?.success_req_count} out of ${data?.total_req_count} requeste completed`}</span>
                  </TableCell>
                </TableRow>
              </>
            );
          })
        ) : (
          <TableRow>
            <TableCell className="text-center" colSpan={10}>
              No records Found{" "}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default BulkChangesStatus;
