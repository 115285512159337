import React from "react";
import complaintIcon from "../../images/svg/complaintIcon.svg";
import requestIcon from "../../images/svg/requestIcon.svg";

const ServiceCardHeader = (props) => {
  const { item } = props;

  return (
    <header className="review-card-header">
      <div className="d-flex justify-content-between">
        <section className="d-flex align-items-center gap-10">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              border: "0.5px solid",
              borderRadius: "12px",
              borderColor: item.is_request === false ? "#FEC84B" : "#32D583",
              backgroundColor: item.is_request === false ? "#FFF8E8" : "#F6FEF9",
              width: "70px",
              height: "45px",
              padding: "5px 0px",
            }}
          >
            {item.is_request === false ? (
              <img src={complaintIcon} style={{ height: "20px", width: "20px" }} />
            ) : (
              <img src={requestIcon} style={{ height: "20px", width: "20px" }} />
            )}
            <span style={{ fontSize: "12px", color: "#1B1C1D" }}>
              {item.is_request === true ? "Request" : "Complaint"}
            </span>
          </div>

          <div className="d-flex align-items-start gap-10">
            <div>
              <span className="review-title">{item?.location_name?.name}</span>
              <span className="d-block font-blue-gray">{item.department_name.name}</span>
            </div>
          </div>
        </section>
      </div>
    </header>
  );
};

export default ServiceCardHeader;
