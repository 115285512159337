import React from "react";
import FormModal from "../FormModal";
import downloadIcon from "../../../images/Download.png";
import uploadIcon from "../../../images/Upload.png";
import FPButton from "./FPButton";
import { Row, Col } from "reactstrap";

const UploadFileModal = ({
  isOpen,
  toggle,
  heading,
  uploadExcel,
  downloadExcel,
  showMessage,
  toggleMessage,
  message,
}) => {
  const uploadFile = (data) => {
    uploadExcel(data);
  };
  return (
    <div>
      <FormModal
        isOpen={isOpen}
        toggle={toggle}
        width={"480px"}
        heading={heading}
        bodyClass={'pb-2'}
      >
        <div className="d-flex gap-20 justify-content-between align-items-center">
          <label
            htmlFor="file"
            className="cursor-pointer mb-0 w-50 flex-grow-1"
          >
            <FPButton
              label="Upload File"
              className="pointer-events-none w-100"
              fontWeight={400}
              size='small'
            >
              <img
                src={uploadIcon}
                className=""
                style={{ marginRight: "6px", height: "16px", width: "16px" }}
              />
              Upload File
              <input id="file" hidden type="file" onChange={uploadFile} />
            </FPButton>
          </label>

          <div
            role="button"
            title="Download Sample Template"
            className="d-flex gap-10 w-50 mb-0 align-items-center font-blue"
            onClick={() => downloadExcel()}
          >
            <img
              src={downloadIcon}
              style={{ height: "16px", width: "16px", cursor: "pointer" }}
            />
            <span>Download sample template</span>
          </div>
          {/* <Row>
            {showMessage && (
              <Col sm="12 mt-2">
                <div className="ui negative message">
                  <i className="close icon" onClick={() => toggleMessage()}></i>
                  <div className="header">Error!</div>
                  <p>{message}</p>
                </div>
              </Col>
            )}
          </Row> */}
        </div>
      </FormModal>
    </div>
  );
};

export default UploadFileModal;
