import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as commonAction from "../common/commonAction";

export const OfflineStoreAlertsSuccess = (data) => {
  return { type: types.OFFLINE_STORE_ALERTS_SUCCESS, data };
};

export const OfflineStoreAlertsFailure = (data) => {
  return { type: types.OFFLINE_STORE_ALERTS_FAILURE, data };
};

export const OfflineStoreAlerts = (data, business_id) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.OFFLINE_STORE_DATA, [business_id]), {
        params,
      })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(OfflineStoreAlertsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(OfflineStoreAlertsFailure(err.response.data));
        throw err.response.data;
      });
  };
};
