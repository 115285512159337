import React, { useEffect, useRef, useState } from "react";
import Donut from "../common/new/Donut";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { findMaxAndIndex } from "../../libs/utils";
import { FPBadge, NoData } from "../common/new";

const colorList = ["#81E050", "#FAE25A", "#EC554E"];

const CategoryListGraph = ({ data }) => {
  const [graphData, setGraphData] = useState([]);

  const chartRef = useRef(null);
  const [chartDimension, setChartDimension] = useState({ height: 0, width: 0 });

  useEffect(() => {
    setTimeout(() => {
      if (chartRef && chartRef.current) {
        const { offsetHeight, offsetWidth } = chartRef.current;
        setChartDimension({ height: offsetHeight, width: offsetWidth });
      }
    });
  }, [chartRef, data]);

  useEffect(() => {
    setGraphData([]);
    data &&
      Object.keys(data).map((item) => {
        if (data[item]["Total"] > 0) {
          const newData = {
            labels: ["Positive", "Neutral", "Negative"],
            legends: ["Positive", "Neutral", "Negative"],
            totalValue: data[item]["Total"],
            name: data[item]["Categories"],
            datasets: [
              {
                data: [
                  data[item]["Positive"],
                  data[item]["Neutral"],
                  data[item]["Negative"],
                ],
                backgroundColor: colorList,
              },
            ],
          };
          setGraphData((preValue) => [...preValue, newData]);
        }
        return item;
      });
  }, [data]);

  return (
    <div className="mt-2 row gap-y-20 fp-row-wrapper min-height-300">
      {graphData?.length > 0 ? (
        graphData.map((item, index) => {
          const color = colorList[findMaxAndIndex(item.datasets[0].data).index];
          return (
            <div
              className="col-6 pr-10 pl-10"
              key={`${index}-graph-${chartDimension.height}`}
              ref={chartRef}
            >
              <CardWrapper showDivider={false}>
                <div
                  style={{ minHeight: "300px" }}
                  className="d-flex justify-content-center align-items-center flex-column"
                >
                  <div className="d-flex justify-content-center align-items-center responseHeadText gap-6">
                    <FPBadge color={color} />
                    <p style={{ color: color }} className="text-capitalize">
                      {item.name}
                    </p>
                  </div>
                  <Donut
                    data={item}
                    cutout={100}
                    width={chartDimension.width - 80}
                    height={chartDimension.height - 50}
                    centerText={{
                      text: item.totalValue,
                      label: "Insights",
                    }}
                    options={{
                      plugins: {
                        legend: {
                          position: "right",
                        },
                      },
                    }}
                  />
                </div>
              </CardWrapper>
            </div>
          );
        })
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default CategoryListGraph;
