/* eslint-disable */
import React from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";

import { Accordion, Icon, Menu, List, Popup } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import AvatarIcon from "../../images/user-icon.png";
import { Box, Collapse, Divider, Drawer, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Logo from "../../images/fp-logo-sm.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import "../../styles/sidebar.css";
import classNames from "classnames";
import {
  SRHome,
  SRoverview,
  FameAIAdsPlanner,
  OfflineStoreAlerts,
  MenuIcon,
  SalesIcon,
  OffersDiscounts,
  AdsIcon,
  CustomerFunnelIcon,
  PerformanceIcon,
  BulkUpdateIcon,
  ListingOverviewIcon,
  InboxIcon,
  MentionIcon,
  PublisherIcon,
  SocialCommentsIcon,
  UserIcon,
  OffersIcon,
  TicketsIcon,
  InsightsIcon,
  CompetitorsIcon,
  ComplaintsIcon,
  ReviewsIcon,
  DashboardIcon,
  EmailIcon,
  MessageChatIcon,
  MobilePlayStore,
  MobileAppStore,
  GooglePlaySideNavIcon,
  AppStoreSideNavIcon,
} from "../../images/svg";
import { SideNavContext } from "../../Context/sideNavContext";
import { BorderRight } from "@material-ui/icons";
import { FPToolTip } from "./new";

class SideNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      branch: null,
      activeIndex: "",
      count: null,
      isMenuSelect: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      (nextProps.routeParams || {}).branch_id &&
      nextProps.routeParams.branch_id !== prevState.branch
    ) {
      return { branch: nextProps.routeParams.branch_id };
    }
    return null;
  }

  isCampaignsActive() {
    return (
      location.pathname.includes("/campaigns/review/") ||
      location.pathname.includes("/recurring-campaigns/review/") ||
      location.pathname.includes("/campaigns/news-letter/") ||
      location.pathname.includes("/recurring-campaigns/news-letter/") ||
      location.pathname.includes("/templates/") ||
      location.pathname.includes("/templates/generic/")
    );
  }
  isFameAIAdsPlannerActive() {
    return (
      location.pathname.includes("/fameai-ads-planner") ||
      location.pathname.includes("/search-history")
    );
  }

  isADS() {
    return (
      location.pathname.includes("/ads") ||
      location.pathname.includes("/ads-campaign")
    );
  }

  isSetUpActive() {
    return (
      location.pathname.includes("/central-setup") ||
      location.pathname.includes("/business-profile")
    );
  }

  isBulkUpdateActive() {
    return (
      location.pathname.includes("/console-list") ||
      location.pathname.includes("/bulk-changes-log") ||
      location.pathname.includes("/bulk-product") ||
      location.pathname.includes("/bulk-menu")
    );
  }

  isGeoGridRankerActive() {
    return (
      location.pathname.includes("/geo-grid-ranker") ||
      location.pathname.includes("/scan-history")
    );
  }

  isKeywordPositionActive() {
    return (
      location.pathname.includes("/keyword-position") ||
      location.pathname.includes("/traffic-analysis")
    );
  }

  isListingDetailActive() {
    return (
      location.pathname.includes("/primary-listing-detail") ||
      location.pathname.includes("/update-category") ||
      location.pathname.includes("/update-phone-number") ||
      location.pathname.includes("/update-location") ||
      location.pathname.includes("/update-business-status") ||
      location.pathname.includes("/update-question-answer") ||
      location.pathname.includes("/update-attribute") ||
      location.pathname.includes("/update-description") ||
      location.pathname.includes("/update-open-hour") ||
      location.pathname.includes("/update-more-hour-tag") ||
      location.pathname.includes("/update-open-date") ||
      location.pathname.includes("/update-photos") ||
      location.pathname.includes("/update-website-link") ||
      location.pathname.includes("/update-appointment") ||
      location.pathname.includes("/update-social-media")
    );
  }

  getBusinessInfo = () => {
    const response = this.props.businessDetails;
    if (response) {
      this.setState({
        businessInfo: response,
        businessManager: response.data.business_manager,
        branch: response.data.cooprate_branch,
      });
      cookie.save("service_category", response.data.category, {
        path: "/",
        maxAge: 365 * 24 * 60 * 60,
      });
    }

    // axios_instance
    //   .get(`${URLS.BUSINESS_SETUP}${business_id}/`)
    //   .then((res) => {
    //     this.setState({
    //       businessInfo: res,
    //       businessManager: res.data.business_manager,
    //       branch: res.data.cooprate_branch,
    //     });
    //     cookie.save("service_category", res.data.category, { path: "/" });
    //     return res;
    //   })
    //   .catch((err) => {
    //     throw err;
    //   });
  };

  async componentDidMount() {
    const {
      match: {
        params,
        params: { business_id },
        url,
      },
    } = this.props;
    const paramsLength = Object.keys(params).length;
    const activePage = url.split("/")[paramsLength + 1];
    this.setState({ activeIndex: activePage });
    this.getBusinessInfo();
    this.fetchLocationData();
  }

  fetchLocationData = () => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    axios_instance
      .get(`${URLS.BUSINESS_SETUP}${business_id}/enterprise/`)
      .then((res) => {
        this.setState({ count: res.data.count });
      })
      .catch((err) => {
        console.log(err, "errrrrr");
      });
  };

  componentDidUpdate(prevProps) {
    // const {
    //   match: {
    //     params: { business_id: prevBusiness },
    //   },
    // } = prevProps;
    // const {
    //   match: {
    //     params: { business_id: nextBusiness },
    //   },
    // } = this.props;
    if (this.props.businessDetails !== prevProps.businessDetails) {
      this.getBusinessInfo();
    }
  }

  uploadLogo = (event) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    event.preventDefault();
    const userImage = event.target.files[0];
    let data = new FormData();
    data.append("logo", userImage, userImage.fileName);
    axios_instance
      .patch(`${URLS.BUSINESS_SETUP}${business_id}/`, data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((res) => {
        utils.displayMessage("positive", "Business Logo Updated");
        this.setState({
          businessInfo: res,
          businessManager: res.data.businessManager,
        });
      })
      .catch((err) => {
        utils.displayMessage("negative", err || "Error Updating Logo");
      });
  };

  getPageSize = (page) => {
    const pageSize = cookie.load("pageSize") || {};
    switch (page) {
      case "dashboard":
        return "?date_type=prior_30_day";
      case "reviews":
        return `?date_type=prior_30_day&page_size=${
          pageSize["reviews_page"] || 10
        }`;
      case "contacts":
        return `?page_size=${pageSize["contacts_page_all"] || 10}`;
      case "complaints":
        return `?date_type=prior_30_day&page_size=${
          pageSize[page + "_page"] || 10
        }`;
      case "mentions":
        return `?page_size=${pageSize["mentions_page"] || 10}`;
      case "templates/list":
        return `?page_size=${pageSize["templates_review"] || 10}`;
      case "templates/news-letter":
        return `?page_size=${pageSize["templates_newsletter"] || 10}`;
      case "campaigns/review":
      case "campaigns/review/list":
        return `?page_size=${pageSize["campaigns_review"] || 10}`;
      case "campaigns/news-letter":
        return `?page_size=${pageSize["campaigns_newsletter"] || 10}`;
      case "central-setup":
        return `?page_size=${pageSize["c_enterprise"] || 10}`;
        case "scheduler":
          return `?page_size=${pageSize["scheduler"] || 10}`;
  
        case "competitors":
        return `?&page_size=${
          pageSize["competitors_reviews"] || 10
        }`;
      case "insights":
        return `?page_size=${
          pageSize["insights_locations"] || 10
        }&date_type=prior_30_day`;
      case "tickets":
        return `?date_type=prior_30_day&page_size=${
          pageSize["tickets_page"] || 10
        }`;
      case "coupons":
        return `?page_size=${pageSize["offer_page"] || 10}`;
      case "coming-soon":
        return "coming-soon";
      case "review-email":
        return `?page=1&page_size=${pageSize["review_email_page"] || 10}`;
      case "social-comments":
        return `?date_type=prior_30_day&page_size=${
          pageSize["social_comments"] || 10
        }`;
      case "listing-overview":
        return `?page_size=${pageSize["listing_overview_page"] || 10}`;
      case "geo-grid-ranker":
        return `?page_size=${pageSize["listing_overview_page"] || 10}`;
      case "scan-credit-history":
        return `?page_size=${pageSize["scan-credit-history"] || 10}`;
      case "keyword-position":
        return `?page_size=${pageSize["keyword_position_page"] || 10}`;
      case "listing-detail":
        return `?page_size=${pageSize["listing-detail"] || 10}`;
      case "bulk-update":
      case "console-list":
        return `?page_size=${pageSize["bulk_update_page"] || 10}`;
      case "restro-pilot":
        return `?date_type=prior_30_day&type=${"daily"}`;
      case "performance":
        return `?date_type=prior_30_day&action_type=${"interactions"}&type=${"overtime"}`;
      case "discovery":
        return `?date_type=prior_30_day&action_type=${"discovery"}&type=${"overtime"}`;
      case "days-hours":
        return `?date_type=prior_30_day&action_type=${"days-hours"}&type=${"days"}`;
      case "keywords":
        return `?date_type=prior_3_month&action_type=${"keywords"}&type=${"overtime"}`;
      case "sales":
        return "?date_type=prior_30_day";
      case "ticket-service-request":
        return `?date_type=prior_30_day&page_size=${
          pageSize["ticket_service_request"] || 10
        }`;
      case "ticket-service-overview":
        return `?date_type=prior_30_day`;
      case "menu-market":
        return `?date_type=prior_30_day&page_size=${
          pageSize["menu_market"] || 10
        }`;
      case "offers-discount":
        return `?date_type=prior_30_day`;
      case "offline-store-alerts":
        return `?date_type=prior_30_day&page_size=${
          pageSize["social_comments"] || 10
        }`;
      case "ads":
        return `?date_type=prior_30_day`;
      case "search-history":
        return `?page_size=${pageSize["bulk_update_page"] || 10}`;
    }
    return false;
  };

  redirectTo = (match, page, branch) => {
    let url = "";
    if (page) {
      const {
        params: { branch_id, business_id },
      } = match;
      if (
        !branch_id &&
        page !== "dashboard" &&
        page !== "reviews" &&
        page !== "scheduler" &&
        page !== "messenger" &&
        page !== "reports" &&
        page !== "central-setup" &&
        page !== "complaints" &&
        page !== "competitors" &&
        page !== "review-email" &&
        page !== "insights" &&
        page !== "mentions" &&
        page !== "tickets" &&
        page !== "contacts" &&
        page !== "campaigns/review/list" &&
        page !== "campaigns/news-letter" &&
        page !== "ai-reports" &&
        page !== "coupons" &&
        page !== "coming-soon" &&
        page !== "social-comments" &&
        page !== "listing-overview" &&
        page !== "geo-grid-ranker" &&
        page !== "scan-credit-history" &&
        page !== "listing-detail" &&
        page !== "console-list" &&
        page !== "keyword-position" &&
        page !== "restro-pilot" &&
        page !== "sales" &&
        page !== "performance" &&
        page !== "discovery" &&
        page !== "ticket-service-request" &&
        page !== "days-hours" &&
        page !== "keywords" &&
        page !== "menu-market" &&
        page !== "offers-discount" &&
        page !== "offline-store-alerts" &&
        page !== "fameai-ads-planner" &&
        page !== "search-history" &&
        page !== "ads" &&
        page !== "ticket-service-overview" &&
        page !== "whatsapp-messages" &&
        page !== "messages-template" &&
        page !== "whatsapp-contacts" &&
        page !== "whatsApp-Campaigns" &&
        page !== "whatsApp-botFlow"
      ) {
        url = "/" + business_id + "/" + branch + "/" + page;
      } else if (
        page === "dashboard" ||
        page === "reviews" ||
        page === "scheduler" ||
        page === "messenger" ||
        page === "reports" ||
        page === "central-setup" ||
        page === "complaints" ||
        page === "competitors" ||
        page === "review-email" ||
        page === "insights" ||
        page === "mentions" ||
        page === "tickets" ||
        page === "contacts" ||
        page === "campaigns/review/list" ||
        page === "campaigns/news-letter" ||
        page === "ai-reports" ||
        page === "coupons" ||
        page === "coming-soon" ||
        page === "social-comments" ||
        page === "listing-overview" ||
        page === "geo-grid-ranker" ||
        page === "scan-credit-history" ||
        page === "listing-detail" ||
        page === "bulk-update" ||
        page === "console-list" ||
        page === "restro-pilot" ||
        page === "sales" ||
        page === "performance" ||
        page === "discovery" ||
        page === "ticket-service-request" ||
        page === "days-hours" ||
        page === "keywords" ||
        page === "menu-market" ||
        page === "offers-discount" ||
        page === "offline-store-alerts" ||
        page === "ads" ||
        page === "keyword-position" ||
        page === "fameai-ads-planner" ||
        page === "search-history" ||
        page === "ticket-service-overview" ||
        page === "whatsapp-messages" ||
        page === "messages-template" ||
        page === "whatsapp-contacts" ||
        page === "whatsApp-Campaigns" ||
        page === "whatsApp-botFlow"
      ) {
        url = "/" + business_id + "/" + page;
      } else if (!branch_id) {
        url = "/" + business_id + "/" + branch + "/" + page;
      } else {
        url = "/" + business_id + "/" + branch_id + "/" + page;
      }
    }
    return url;
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  static contextType = SideNavContext;

  normalMenuItem = (
    title,
    redirectTo,
    iconName,
    lock,
    className,
    activeItemClassName
  ) => {
    const { match, showSideNav } = this.props;
    const { branch } = this.state;
    const { isAccordionTabNotSelected } = this.context;

    return (
      <Menu.Item className={classNames("list-item", className)} key={title}>
        {redirectTo && !(lock === true) ? (
          <NavLink
            to={{
              pathname: this.redirectTo(match, redirectTo, branch),
              search: `${this.getPageSize(redirectTo)}`,
            }}
            onClick={() => {
              this.setState({ activeIndex: "" });
              isAccordionTabNotSelected();
            }}
            className={classNames(
              "nav-list-item",
              activeItemClassName,
              className
            )}
            activeClassName="active-side-nav"
          >
            <div className="mx-2 d-flex align-items-center gap-6">
              {typeof iconName === "string" ? (
                <i
                  title={!showSideNav ? title : ""}
                  className={`fas ${iconName} side-bar-icon`}
                />
              ) : (
                <div style={{ fontSize: "12px" }}>{iconName}</div>
              )}
              {showSideNav && (
                <p
                  className="side-bar-title"
                  style={{
                    marginTop: title === "overview" ? "2px" : undefined,
                  }}
                >
                  {title}
                </p>
              )}

              {/* {lock && <i className={`fa fa-lock sidenav-lock ${!showSideNav && 'collapsed'}`} />} */}
            </div>
          </NavLink>
        ) : (
          <a
            className="nav-list-item mx-2 d-flex align-items-center gap-6"
            style={{
              fontSize: typeof iconName === "string" ? "12px" : undefined,
            }}
          >
            {typeof iconName === "string" ? (
              <i className={`fas ${iconName} side-bar-icon`} />
            ) : (
              iconName
            )}
            {showSideNav && (
              <p
                className="side-bar-title d-flex gap-6 align-items-center"
                style={{ marginTop: title === "overview" ? "2px" : undefined }}
              >
                {title}
                {lock === true && (
                  <i
                    className={`fa fa-lock `}
                    style={{ fontSize: "15px", color: "#808080cf" }}
                  />
                )}
              </p>
            )}
          </a>
        )}
      </Menu.Item>
    );
  };

  accordianMenuItem = (
    redirectTo,
    iconName,
    nestedItems,
    isNavLink,
    className
  ) => {
    const { isMenuSelect, isAccordionTabSelected } = this.context;
    const { match, showSideNav } = this.props;
    const { branch, activeIndex } = this.state;
    const isChildItemActive = utils.isActivePage(redirectTo);
    return (
      <Menu.Item
        className={`${isNavLink ? "list-item" : ""} ${
          className ? className : ""
        }`}
      >
        <Accordion.Title
          active={activeIndex === redirectTo}
          index={redirectTo}
          onClick={showSideNav ? this.handleClick : null}
        >
          <List
            style={{ padding: "0px 0px" }}
            className={`${isMenuSelect ? "active-side-nav" : ""}`}
          >
            <List.Item className="nav-list-item accordion-list">
              <List.Content>
                <NavLink
                  to={{
                    pathname: isNavLink
                      ? this.redirectTo(match, redirectTo, branch)
                      : location.pathname,
                    search: isNavLink
                      ? `${this.getPageSize(redirectTo)}`
                      : location.search,
                  }}
                  activeClassName="active-side-nav"
                >
                  <span className="items-wrap">
                    {typeof iconName === "string" ? (
                      <i
                        title={!showSideNav ? title : ""}
                        className={`fas ${iconName} side-bar-icon`}
                      />
                    ) : (
                      <div
                        style={{
                          fontSize: "12px",
                          color: isMenuSelect ? "white" : "inherit",
                        }}
                      >
                        {iconName}
                      </div>
                    )}
                    {showSideNav && (
                      <span
                        className="items-text"
                        style={{
                          marginLeft: "4px !important",
                          color: isMenuSelect ? "white" : "inherit",
                        }}
                        title={redirectTo}
                      >
                        {redirectTo}
                      </span>
                    )}
                    {showSideNav && activeIndex === redirectTo ? (
                      <KeyboardArrowUpIcon
                        style={{
                          color: isMenuSelect ? "white" : "inherit",
                        }}
                      />
                    ) : (
                      <KeyboardArrowDownIcon
                        style={{
                          color: isMenuSelect ? "white" : "inherit",
                        }}
                      />
                    )}
                  </span>
                </NavLink>
              </List.Content>
            </List.Item>
          </List>
        </Accordion.Title>
        <Collapse in={activeIndex === redirectTo || isMenuSelect}>
          <Accordion.Content
            active={activeIndex === redirectTo || isMenuSelect}
          >
            {nestedItems &&
              nestedItems.map((item, i) => {
                if (item) {
                  return (
                    <div className="nested-list-item" key={i}>
                      <NavLink
                        to={{
                          pathname: this.redirectTo(match, item.page, branch),
                          search: `${this.getPageSize(item.page)}`,
                        }}
                        className="nav-list-item-options"
                        activeClassName="active-side-nav-acc"
                        onClick={() => isAccordionTabSelected()}
                      >
                        <Icon name={item.icon} />
                        <span>{item.title}</span>
                        {item.lock && <i className="fa fa-lock sidenav-lock" />}
                      </NavLink>
                    </div>
                  );
                }
              })}
          </Accordion.Content>
        </Collapse>
      </Menu.Item>
    );
  };

  customAccordionMenuItem = (title, redirectTo, iconName, lock, className) => {
    const { match, showSideNav } = this.props;
    const { branch } = this.state;
    return (
      <Menu.Item className={classNames("list-item", className)}>
        {redirectTo && (
          <NavLink
            to={{
              pathname: this.redirectTo(match, redirectTo, branch),
              search: `${this.getPageSize(redirectTo)}`,
            }}
            onClick={() => this.setState({ activeIndex: "" })}
            className="nav-list-item"
            activeClassName="active-side-nav"
          >
            {/* <i title={!showSideNav ? title : ''} className={`fas ${iconName} side-bar-icon`} /> */}
            {showSideNav && <span>{title}</span>}
            {/* {lock && <i className={`fa fa-lock sidenav-lock ${!showSideNav && 'collapsed'}`} />} */}
          </NavLink>
        )}
        {!redirectTo && (
          <a className="nav-list-item">
            <i className={`fas ${iconName} side-bar-icon`} />
            {showSideNav && <span>{title}</span>}
          </a>
        )}
      </Menu.Item>
    );
  };

  collapsedAccordianMenuItem = (index, iconName, className) => {
    const { activeIndex } = this.state;
    const isChildItemActive = location.pathname.includes(index.toLowerCase());

    return (
      <Menu.Item className={`list-item ${className ? className : ""}`}>
        <a
          className={`nav-list-item ${
            activeIndex === index || isChildItemActive ? "active-side-nav" : ""
          }`}
        >
          <i title={index} className={`fas ${iconName} side-bar-icon`} />
        </a>
      </Menu.Item>
    );
  };

  collapsedAccordianContent = (index, nestedItems) => {
    const { match } = this.props;
    const { branch } = this.state;
    return nestedItems.map((item, i) => {
      if (item) {
        return (
          <div className="nested-list-item" key={i}>
            <NavLink
              to={{
                pathname: this.redirectTo(match, item.page, branch),
                search: `${this.getPageSize(item.page)}`,
              }}
              onClick={() => this.setState({ activeIndex: index })}
              className="nav-list-item"
              activeClassName="active-side-nav"
            >
              <Icon name={item.icon} />
              <span>{item.title}</span>
            </NavLink>
          </div>
        );
      }
    });
  };

  render() {
    const {
      showSideNav,
      classes,
      isSideBarOpen,
      handleDrawerClose,
      defaultTab,
    } = this.props;
    const business_user = cookie.load("business_user") || {};
    const { lockedModules } = this.props;
    const user = cookie.load("user");
    const reviewList = [
      {
        title: "Dashboard",
        redirectTo: "dashboard",
        iconName: <DashboardIcon />,
        lock: lockedModules["dashboard"],
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Reviews",
        redirectTo: "reviews",
        iconName: <ReviewsIcon />,
        lock: lockedModules["reviews"],
        className: "",
        activeItemClassName: "",
      },
      lockedModules["complaints"] && {
        title: "Complaints",
        redirectTo: "complaints",
        iconName: <ComplaintsIcon />,
        lock: "",
        classNames: "",
        activeItemClassName: "",
      },
      {
        title: "Contacts",
        redirectTo: "contacts",
        iconName: <UserIcon />,
        lock: lockedModules["contacts"] || !user?.["enable_contact_page"],
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Campaigns",
        redirectTo: "campaigns/review/list",
        iconName: <AdsIcon />,
        lock: "",
        className: "",
        activeItemClassName: this.isCampaignsActive() ? "active-side-nav" : "",
      },
      {
        title: "Offers",
        redirectTo: "coupons",
        iconName: <OffersIcon />,
        lock: lockedModules["offers"],
        className: "",
        activeItemClassName: "",
      },
      {
        title: "reports",
        redirectTo: "reports",
        iconName: <SalesIcon />,
        lock: "",
        className: "",
        activeItemClassName: "",
      },
      {
        title: "insights",
        redirectTo: "insights",
        iconName: <InsightsIcon />,
        lock: lockedModules["insights"],
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Tickets",
        redirectTo: "tickets",
        iconName: <TicketsIcon />,
        lock: lockedModules["tickets"],
        className: "",
        activeItemClassName: "",
      },
      // lockedModules["ticket"] && {
      //   redirectTo: "Tickets",
      //   iconName: "fas fa-ticket-alt",
      //   nestedItems: [
      //     {
      //       title: "Reviews",
      //       page: "tickets",
      //     },
      //     // {
      //     //   title: "Aggregator Complaints",
      //     //   page: "aggregator-complaints",
      //     // },
      //     {
      //       title: "Service Requests",
      //       page: "ticket-service-request",
      //     },
      //   ],
      //   isNavLink: false,
      //   className: "",
      // },
      {
        title: "Competitors",
        redirectTo: "competitors",
        iconName: <CompetitorsIcon />,
        lock: lockedModules["competition"],
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Email",
        redirectTo: "review-email",
        iconName: <EmailIcon />,
        lock: "",
        className: "",
        activeItemClassName: "",
      },
    ].filter((item) => !!item);

    const socialList = [
      {
        title: "Inbox",
        redirectTo: "messenger",
        iconName: <InboxIcon />,
        lock: lockedModules["messenger"],
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Mention",
        redirectTo: "mentions",
        iconName: <MentionIcon />,
        lock: lockedModules["social_mentions"],
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Publisher",
        redirectTo: "scheduler",
        iconName: <PublisherIcon />,
        lock: lockedModules["scheduler"],
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Social Comments",
        redirectTo: "social-comments",
        iconName: <SocialCommentsIcon />,
        lock: lockedModules["social-comments"],
        className: "",
        activeItemClassName: "",
      },
      // {
      //   title: "reports",
      //   redirectTo: "reports",
      //   iconName: "fa-file-alt",
      //   lock: "",
      //   className: "",
      //   activeItemClassName: "",
      // },
      // lockedModules["ticket"] && {
      //   title: "tickets",
      //   redirectTo: "tickets",
      //   iconName: "far fa-ticket-alt",
      //   lock: lockedModules["ticket"],
      //   className: "",
      //   activeItemClassName: "",
      // },
    ];

    const Listing = [
      {
        title: "Overview",
        redirectTo: "listing-overview",
        iconName: <ListingOverviewIcon />,
        lock: !user?.["enable_listing_product"],
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Listings Details",
        redirectTo: "listing-detail",
        iconName: "fa-light fa-list",
        lock: !user?.["enable_listing_product"],
        className: "",
        activeItemClassName: this.isListingDetailActive()
          ? "active-side-nav"
          : "",
      },
      {
        title: "Bulk Update",
        redirectTo: "console-list",
        iconName: <BulkUpdateIcon />,
        lock: "",
        className: "",
        activeItemClassName: this.isBulkUpdateActive() ? "active-side-nav" : "",
      },
      {
        title: "Geo Grid Ranker",
        redirectTo: "geo-grid-ranker",
        iconName: "fa-solid fa-map",
        lock: !user?.["enable_listing_product"],
        className: "",
        activeItemClassName: this.isGeoGridRankerActive()
          ? "active-side-nav"
          : "",
      },
      {
        title: "Scan Credit",
        redirectTo: "scan-credit-history",
        iconName: "fa-solid fa-map",
        lock: !user?.["enable_listing_product"],
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Keyword Position",
        redirectTo: "keyword-position",
        iconName: "fas fa-list-alt",
        lock: !user?.["enable_listing_product"],
        className: "",
        activeItemClassName: this.isKeywordPositionActive()
          ? "active-side-nav"
          : "",
      },
      {
        redirectTo: "performance",
        iconName: <PerformanceIcon />,
        nestedItems: [
          {
            title: "Interactions",
            page: "performance",
          },
          {
            title: "Discovery",
            page: "discovery",
          },
          {
            title: "Days / Hours",
            page: "days-hours",
          },
          {
            title: "Keywords",
            page: "keywords",
          },
        ],
        isNavLink: false,
        className: "",
      },
    ];

    const restroPilotList = [
      {
        title: "Customer Funnel",
        redirectTo: "restro-pilot",
        iconName: <CustomerFunnelIcon />,
        lock: !user["enable_marketplace"],
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Ads",
        redirectTo: "ads",
        iconName: <AdsIcon />,
        lock: !user["enable_marketplace"],
        className: "",
        activeItemClassName: this.isADS() ? "active-side-nav" : "",
      },
      // {
      //   title: "FameAI Ads Planner",
      //   redirectTo: "fameai-ads-planner",
      //   iconName: <FameAIAdsPlanner />,
      //   lock: "",
      //   className: "",
      //   activeItemClassName: this.isFameAIAdsPlannerActive() ? "active-side-nav" : "",
      // },
      {
        title: "Discounts/Offers",
        redirectTo: "offers-discount",
        iconName: <OffersDiscounts />,
        lock: !user["enable_marketplace"],
        className: "",
        activeItemClassName: "",
      },
      // {
      //   title: "Customer Analysis",
      //   redirectTo: "customer-analysis",
      //   iconName: "fa-light fa-bullseye",
      //   lock: "",
      //   className: "",
      //   activeItemClassName: "",
      // },
      {
        title: "Sales",
        redirectTo: "sales",
        iconName: <SalesIcon />,
        lock: !user["enable_marketplace"],
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Offline Store Alerts",
        redirectTo: "offline-store-alerts",
        iconName: <OfflineStoreAlerts />,
        lock: !user["enable_marketplace"],
        className: "",
        activeItemClassName: "",
      },
      {
        redirectTo: "menu",
        iconName: <MenuIcon />,
        nestedItems: [
          {
            title: "Out of Stock Monitor",
            page: "menu-market",
          },
        ],
        isNavLink: false,
        className: "",
      },
    ];

    const serviceList = [
      {
        title: "Overview",
        redirectTo: "ticket-service-overview",
        iconName: <ListingOverviewIcon />,
        lock: "",
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Service Request",
        redirectTo: "ticket-service-request",
        iconName: <SRoverview />,
        lock: "",
        className: "",
        activeItemClassName: "",
      },
    ];

    const whatsAppList = [
      {
        title: "Messages",
        redirectTo: "whatsapp-messages",
        iconName: <MessageChatIcon />,
        lock: "",
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Message Templates",
        redirectTo: "messages-template",
        iconName: <MessageChatIcon />,
        lock: "",
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Contacts",
        redirectTo: "whatsapp-contacts",
        iconName: <UserIcon />,
        lock: "",
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Campaigns",
        redirectTo: "whatsApp-Campaigns",
        iconName: <SRoverview />,
        lock: "",
        className: "",
        activeItemClassName: "",
      },
      {
        title: "Bot Flow",
        redirectTo: "whatsApp-botFlow",
        iconName: <SRoverview />,
        lock: "",
        className: "",
        activeItemClassName: "",
      },
    ];

    const setupButton = [
      {
        title: "Setup",
        redirectTo: "central-setup",
        iconName: "fa-cog",
        lock: lockedModules["central-setup"],
        className: "SetupButton",
        activeItemClassName: this.isSetUpActive() ? "active-side-nav" : "",
      },
    ];
    const service_category = cookie.load("service_category");
    const locationCount =
      this.state.count !== null && this.state.count !== undefined
        ? this.state.count.toString()
        : "";

    const StoreButton = ({ children, Icon }) => (
      <div className="store-button">
        <Icon className="store-icon" />
        <div className="store-text">{children}</div>
      </div>
    );
    return (
      <React.Fragment>
        <Drawer
          variant="permanent"
          className={`${classes.drawer} ${
            isSideBarOpen ? classes.drawerOpen : classes.drawerClose
          }`}
          classes={{
            paper: `${
              isSideBarOpen ? classes.drawerOpen : classes.drawerClose
            }`,
          }}
        >
          <div
            className={`${classes.toolbar} d-flex justify-content-between ml-3`}
          >
            <Link to={"/"}>
              <img src={Logo} height="40" alt="famepilot logo" />
            </Link>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <div
            className={classNames("sidenav-wrapper", {
              "collapsed-sidenav-wrapper": !isSideBarOpen,
            })}
            data-sidebar-open={isSideBarOpen}
          >
            <div className="sidenav-items max-height ui menu vertical NavLeftIcon">
              {defaultTab === 0 && (
                <Box
                  sx={{
                    overflow: "auto",
                  }}
                >
                  {(business_user?.enable_sr_module
                    ? serviceList
                    : reviewList
                  ).map((item) => {
                    if (item?.nestedItems?.length > 0) {
                      return this.accordianMenuItem(
                        item.redirectTo,
                        item.iconName,
                        item.nestedItems,
                        item.isNavLink,
                        item.className
                      );
                    } else {
                      return this.normalMenuItem(
                        item?.title,
                        item?.redirectTo,
                        item?.iconName,
                        item?.lock,
                        "",
                        item?.activeItemClassName
                      );
                    }
                  })}
                </Box>
              )}

              {defaultTab === 1 && (
                <Box
                  sx={{
                    overflow: "auto",
                  }}
                >
                  {socialList.map((item) => {
                    return this.normalMenuItem(
                      item?.title,
                      item?.redirectTo,
                      item?.iconName,
                      item?.lock,
                      item?.activeItemClassName
                    );
                  })}
                </Box>
              )}

              {defaultTab === 2 && (
                <Box sx={{ overflow: "auto" }}>
                  {Listing.map((item) => {
                    if (item?.nestedItems?.length > 0) {
                      return this.accordianMenuItem(
                        item.redirectTo,
                        item.iconName,
                        item.nestedItems,
                        item.isNavLink,
                        item.className
                      );
                    } else {
                      return this.normalMenuItem(
                        item?.title,
                        item?.redirectTo,
                        item?.iconName,
                        item?.lock,
                        item?.activeItemClassName
                      );
                    }
                  })}
                </Box>
              )}

              {defaultTab === 3 && (
                <Box sx={{ overflow: "auto" }}>
                  {(service_category === "Restaurant"
                    ? restroPilotList
                    : serviceList
                  ).map((item) => {
                    if (item?.nestedItems?.length > 0) {
                      return this.accordianMenuItem(
                        item.redirectTo,
                        item.iconName,
                        item.nestedItems,
                        item.isNavLink,
                        item.className
                      );
                    } else {
                      return this.normalMenuItem(
                        item?.title,
                        item?.redirectTo,
                        item?.iconName,
                        item?.lock,
                        item?.activeItemClassName
                      );
                    }
                  })}
                </Box>
              )}

              {defaultTab === 4 && (
                <Box sx={{ overflow: "auto" }}>
                  {serviceList.map((item) => {
                    if (item?.nestedItems?.length > 0) {
                      return this.accordianMenuItem(
                        item.redirectTo,
                        item.iconName,
                        item.nestedItems,
                        item.isNavLink,
                        item.className
                      );
                    } else {
                      return this.normalMenuItem(
                        item?.title,
                        item?.redirectTo,
                        item?.iconName,
                        item?.lock,
                        item?.activeItemClassName
                      );
                    }
                  })}
                </Box>
              )}

              {defaultTab === 5 && (
                <Box
                  sx={{
                    overflow: "auto",
                  }}
                >
                  {whatsAppList.map((item) => {
                    if (item?.nestedItems?.length > 0) {
                      return this.accordianMenuItem(
                        item.redirectTo,
                        item.iconName,
                        item.nestedItems,
                        item.isNavLink,
                        item.className
                      );
                    } else {
                      return this.normalMenuItem(
                        item?.title,
                        item?.redirectTo,
                        item?.iconName,
                        item?.lock,
                        "",
                        item?.activeItemClassName
                      );
                    }
                  })}
                </Box>
              )}

              <div
                style={{ marginTop: "auto" }}
                className="currentLocationText"
              >
                {showSideNav ? (
                <div
                  style={{ display: "flex", gap: "10px", padding: "15px 18px" }}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.famepilotapp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MobilePlayStore
                      style={{
                        width: "100%",
                        height: "auto",
                        border: "1px solid black",
                        borderRadius: "5px",
                      }}
                    />
                  </a>

                  <a
                    href="https://apps.apple.com/in/app/famepilot/id6476922363"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MobileAppStore
                      style={{
                        width: "100%",
                        height: "auto",
                        border: "1px solid black",
                        borderRadius: "5px",
                      }}
                    />
                  </a>
                </div>) : (
                  <div
                  style={{ display:"flex", flexDirection:"column" ,gap: "10px",padding: "20px 25px", width:"72px" }}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.famepilotapp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FPToolTip title="GET IT ON GOOGLE PLAY">
                    <GooglePlaySideNavIcon
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "5px",
                      }}
                    />
                    </FPToolTip>
                  </a>

                  <a
                    href="https://apps.apple.com/in/app/famepilot/id6476922363"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FPToolTip title="DOWNLOAD ON THE APP STORE">

                    <AppStoreSideNavIcon  
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "5px",
                      }}
                    />
                    </FPToolTip>
                  </a>
                </div>
                ) }

                {showSideNav ? (
                  <p
                    className="mb-3 d-flex align-items-center "
                    style={{ marginLeft: "-16px" }}
                  >
                    <i
                      className="fas fa-map-marker-alt"
                      aria-hidden="true"
                      style={{
                        margin: "0px 12px",
                        marginLeft: "33px",
                        fontSize: "21px",
                      }}
                    />
                    <span
                      style={{
                        color: "#1B1C1D",
                        lineHeight: "18px",
                        fontSize: "15px",
                        fontWeight: "600",
                        marginLeft: "1px",
                        marginBottom: 0,
                        padding: "13px 7px !important",
                      }}
                    >
                      Current Locations {locationCount.slice(0, 4)}
                    </span>
                  </p>
                ) : (
                  <p className="mb-3 d-flex align-items-center">
                    {" "}
                    {/* Hide the text when showSideNav is false */}
                    <i
                      className="fas fa-map-marker-alt"
                      style={{
                        marginLeft: "23px",
                        marginRight: "5px",
                        fontSize: "21px",
                      }}
                    />
                    <span style={{ fontSize: "15px" }}>{this.state.count}</span>
                  </p>
                )}
                {/* Setup Button */}
                {setupButton.map((item) => {
                  return this.normalMenuItem(
                    item.title,
                    item.redirectTo,
                    item.iconName,
                    item.lock,
                    item.className,
                    item.activeItemClassName
                  );
                })}
              </div>
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routeParams: state.common.route_params,
    lockedModules: state.business.lockedModules || {},
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  SideNav
);
