import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { connect } from 'react-redux';
import * as BusinessSetupActions from '../../actions/business/businessSetupActions';
import { Row, Col } from 'reactstrap';
import LoaderComponent from '../../components/common/loaderComponent';
import MenuForm from '../../components/business/MenuForm';
import axios_instance from '../../libs/interseptor';
import * as utils from '../../libs/utils';
import * as URLS from '../../libs/apiUrls';

class MenuEditPage extends React.Component{
  constructor(props){
    super(props);
    this.state={ 
      options : [],
      menuDetails : {}
    };
    this.submitData = this.submitData.bind(this);
    this.getMenu = this.getMenu.bind(this);
  }

  submitData(data) {
    const { route: { location: { search }}} = this.context.router;
    const { id } = queryString.parse(search);
    const { match: { params: { business_id }} } = this.props;


    // const formData = new FormData();
    // formData.append('product_name', data.product_name);
    // formData.append('branch', data.branch);
    // formData.append('product_category', data.product_category);
    // formData.append('product_subcategory', data.product_subcategory);
    // formData.append('veg_nonveg', data.veg_nonveg);
    // formData.append('product_image_link', data.product_image_link);
    // formData.append('product_description', data.product_description);
    const dataSend   =  {
      product_name : data.product_name,
      branch : data.branch,
      product_category : data.product_category,
      product_subcategory : data.product_subcategory,
      veg_nonveg : data.veg_nonveg,
      product_image_link : data.product_image_link ? data.product_image_link : '',
      product_description : data.product_description
    };
    
    if (id) {
      //dataSend.id = id;
      return axios_instance.patch(`${utils.format(URLS.MENU_LIST, [business_id])}${id}/` , dataSend)
        .then((res) => {
          return res;
        }).catch((err) => {
          console.log(err);
        });
    } else {
      return axios_instance.post(`${utils.format(URLS.MENU_LIST, [business_id])}` , dataSend)
        .then((res) => {
          return res;
        }).catch((err) => {
          console.log(err);
        });
    }
  }

  getMenu(id) {
    const { match: { params: { business_id }} } = this.props;
    return axios_instance.get(`${utils.format(`${URLS.MENU_LIST}`, [business_id])}/${id}/`)
      .then((res) =>{
        this.setState({ menuDetails : res.data });
      }).catch((err)=> {
        this.setState({ menuDetails : {product_name : '', branch : '', product_category : '', product_subcategory : '', veg_nonveg : '', product_image_link : '', product_description : ''} });
        console.log(err);
      });
  }

  componentDidMount() {
    const { match: { params: { business_id }} } = this.props;
    const { route: { location: { search }}} = this.context.router;
    const { id } = queryString.parse(search);
    if (id) this.getMenu(id);
    this.props.actions.businessBranchOptions({business: business_id },true);
  }


  render() {
    const { route: { location: { search }}} = this.context.router;
    const { id } = queryString.parse(search);
    const { branchOptions} = this.props;
    const { menuDetails } = this.state;
    const newData = {
      product_name : '',
      branch : '',
      product_category : '',
      product_subcategory : '',
      veg_nonveg : '',
      product_image_link : '',
      product_description : ''
    };
    const menuData = menuDetails;
    if(id) {
      menuData.branch = menuData.branch_id ? menuData.branch_id : '';
    }
    console.log(menuData);
    const formData = id ? menuData : newData;

    return (
      <Row className="content-area">
        <LoaderComponent loader={this.props.loader}/>
        <Col xs={12} sm={12} className="business-setup p-3">
          <MenuForm
            submitData={this.submitData}
            initialValues={formData}
            isEditMode={!!id}
            locationsList={branchOptions && branchOptions.choices}
          />
        </Col>
      </Row>
    );
  }
}

MenuEditPage.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  return {
    branchOptions: state.business.branches,
    loader: state.common.loader,
  };
};


MenuEditPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(MenuEditPage);