import React from "react";

const FaceFillTwo = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16Z"
        fill="#FAE25A"
        stroke="#FAE25A"
        stroke-width="2"
      />
      <path
        d="M11.8801 13.541C12.3542 12.3964 11.8107 11.0843 10.6662 10.6102C9.52163 10.1361 8.20947 10.6796 7.73538 11.8241C7.26128 12.9687 7.80479 14.2808 8.94933 14.7549C10.0939 15.229 11.406 14.6855 11.8801 13.541Z"
        fill="#1B1C1D"
      />
      <path
        d="M22.1942 14.9627C23.4331 14.9627 24.4373 13.9584 24.4373 12.7196C24.4373 11.4808 23.4331 10.4766 22.1942 10.4766C20.9554 10.4766 19.9512 11.4808 19.9512 12.7196C19.9512 13.9584 20.9554 14.9627 22.1942 14.9627Z"
        fill="#1B1C1D"
      />
      <path
        d="M22.18 23.8607H9.8194C9.43806 23.8607 9.12891 23.5212 9.12891 23.1022C9.12891 22.6834 9.43806 22.3438 9.8194 22.3438H22.18C22.5613 22.3438 22.8705 22.6834 22.8705 23.1022C22.8705 23.5212 22.5614 23.8607 22.18 23.8607Z"
        fill="#1B1C1D"
      />
      <path
        d="M12.5649 8.93819H7.04624C6.71108 8.93819 6.43945 8.66651 6.43945 8.3314C6.43945 7.99628 6.71113 7.72461 7.04624 7.72461H12.5649C12.9 7.72461 13.1717 7.99628 13.1717 8.3314C13.1717 8.66651 12.9 8.93819 12.5649 8.93819Z"
        fill="#1B1C1D"
      />
      <path
        d="M24.9536 8.93819H19.4349C19.0998 8.93819 18.8281 8.66651 18.8281 8.3314C18.8281 7.99628 19.0998 7.72461 19.4349 7.72461H24.9536C25.2887 7.72461 25.5604 7.99628 25.5604 8.3314C25.5604 8.66651 25.2887 8.93819 24.9536 8.93819Z"
        fill="#1B1C1D"
      />
    </svg>
  );
};
export default FaceFillTwo;
