import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../actions/listing/listingLocationAction";
import LoaderComponent from "../../components/common/loaderComponent";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import LocationListingDetail from "../../components/listing/ListingDetail/LocationListingDetail";
import { ListDescription } from "semantic-ui-react";
import { Box } from "@material-ui/core";
import CustomStarRatingComponent from "../../components/common/StarComponent";
import duplicate_svg from "../../images/dusplicate.svg";
import verified_svg from "../../images/verified.svg";
import { FPSwitch } from "../../components/common/new/Listing/index";
import MenuListingDetail from "../../components/listing/ListingDetail/MenuListingDetail";
import { formatReviews } from "../../libs/helper";
import * as listingUtils from "../../libs/listingToast";
import classnames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import { IconButton } from "@material-ui/core";
import { ReactComponent as ResetIcon } from "../../images/svg/Reset.svg";
import { FPButton, FPToolTip } from "../../components/common/new";

class LocationListingDetailMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabKey: "",
      activeTab: "1",
    };
  }

  componentDidMount() {
    this.props.actionsSelectTab.setSelectedTab(2);
    const {
      match: {
        params: { business_id },
      },
      location: { state },
    } = this.props;

    this.props.actions.getGmbListingMenuDetail(business_id, state?.restoId);
    this.props.actions.getGmbListingPrimaryDetail(business_id, state?.restoId);
    this.props.actions.getGmbListingBasicDetail(business_id, state?.restoId);
    this.props.actions.getGmbListingGmbCategories(business_id, state?.restoId);
  }

  handleTabChange = (value) => {
    if (value) {
      this.setState({ tabKey: true });
    } else {
      this.setState({ tabKey: false });
    }
  };

  handleRefreshListing = async () => {
    const {
      match: {
        params: { business_id },
      },
      location: { state },
    } = this.props;
    listingUtils?.displayMessage(
      "info",
      "Listing getting updated, It may take a few minutes."
    );
    await this.props.actions.getGmbListingRefreshListing(
      business_id,
      state?.restoId
    );

    listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    listingUtils?.displayMessage("positive", "Listing updated successfully.");
    await this.props.actions.getGmbListingPrimaryDetail(
      business_id,
      state?.restoId
    );
    await this.props.actions.getGmbLocationFoodmenu(
      business_id,
      state?.restoId
    );
  };

  render() {
    const {
      loader,
      location_primary_detail,
      attribute_data,
      location: { state, search },
      location,
      location_menu_detail,
      gmc_categories_data,
      basic_detail_data,
      match: {
        params: { business_id },
      },
    } = this.props;
    const { tabKey, activeTab } = this.state;

    const addressString = [
      location_primary_detail?.results?.[0]?.addressLines,
      location_primary_detail?.results?.[0]?.locality,
      location_primary_detail?.results?.[0]?.postalCode,
    ].join(", ");

    return (
      <>
        <LoaderComponent loader={loader} />
        <div className="py-3">
          <div className="listing-container bg-white">
            <div className="listing-header">
              <div className="listing-info">
                <div style={{ display: "flex", gap: "8px" }}>
                  <div className="listing-name">
                    {location_primary_detail?.results?.[0]?.title}
                  </div>
                  <img
                    src={
                      // location_primary_detail?.results?.[0]
                      //   ?.recommendationReason === ""
                      //   ? verified_svg
                      //   : location_primary_detail?.results?.[0]
                      //     ?.recommendationReason ===
                      //     "BUSINESS_LOCATION_SUSPENDED"
                      //     ? duplicate_svg
                      //     : duplicate_svg
                      location_primary_detail?.results?.[0]?.is_varified
                        ? verified_svg
                        : location_primary_detail?.results?.[0]?.is_suspend
                        ? duplicate_svg
                        : location_primary_detail?.results?.[0]?.is_duplicate
                        ? duplicate_svg
                        : ""
                    }
                    alt="verifiedImage"
                  />
                </div>
                <div
                  style={{ display: "flex", gap: "8px", alignItems: "center" }}
                >
                  <div className="rating-container">
                    <CustomStarRatingComponent
                      name="rate"
                      starCount={5}
                      starColor="#FBBD08"
                      emptyStarColor="white"
                      value={
                        location_primary_detail?.results?.[0]?.averageRating
                      }
                      editing={false}
                    />
                  </div>
                  <span>
                    {location_primary_detail?.results?.[0]?.averageRating.toFixed(
                      1
                    )}
                  </span>
                  <div
                    style={{
                      height: "5px",
                      width: "5px",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <span>
                    {formatReviews(
                      location_primary_detail?.results?.[0]?.totalReviewCount
                    )}{" "}
                    Reviews
                  </span>
                </div>
              </div>
              <div className="view-listing-button">
                <FPButton
                  label="Refresh Listing"
                  size="small"
                  onClick={this.handleRefreshListing}
                />
              </div>
            </div>
            <div className="review-address">
              <ListDescription>{addressString}</ListDescription>
            </div>
          </div>
          <div
            className="listing-container border-0"
            style={{ padding: "0px 2px" }}
          >
            <Box sx={{ justifyContent: "space-between", display: "flex" }}>
              {location_primary_detail?.results?.[0]?.canHaveFoodMenus ===
              true ? (
                <FPSwitch
                  id="1"
                  noLabel="Menu Detail"
                  yesLabel="Primary Detail"
                  onChange={this.handleTabChange}
                />
              ) : (
                // < FPSwitch
                //   id="1"
                //   // noLabel="Menu Detail"
                //   yesLabel="Primary Detail"
                //   // onChange={this.handleTabChange}
                // />
                <Nav className="Setup_Sub_Nav">
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab })}>
                      Primary Detail
                    </NavLink>
                  </NavItem>
                </Nav>
              )}
            </Box>
          </div>
          {tabKey === true ? (
            <MenuListingDetail
              business_id={business_id}
              selectReso={state?.restoId}
              location_menu_detail={
                location_menu_detail?.results?.length > 0
                  ? location_menu_detail?.results?.[0]
                  : ""
              }
              history={this.props.history}
            />
          ) : (
            <LocationListingDetail
              history={this.props.history}
              business_id={business_id}
              selectReso={state?.restoId}
              basic_detail_data={basic_detail_data ? basic_detail_data : ""}
              primaryDetail={
                location_primary_detail?.results?.length > 0
                  ? location_primary_detail?.results?.[0]
                  : ""
              }
              gmc_categories_data={gmc_categories_data}
              attribute_data={attribute_data}
              search={search}
              location={location}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refresh_listing_error: state.gmbListingLocation.refresh_listing_error,
    refresh_listing_data: state.gmbListingLocation.refresh_listing_data,

    attribute_data: state.gmbListingLocation.attribute_data,
    attribute_error: state.gmbListingLocation.attribute_error,

    gmc_categories_data: state.gmbListingLocation.gmc_categories_data,
    gmc_categories_error: state.gmbListingLocation.gmc_categories_error,

    basic_detail_data: state.gmbListingLocation.basic_detail_data,
    basic_detail_error: state.gmbListingLocation.basic_detail_error,
    location_menu_detail: state.gmbListingLocation.location_menu_detail,
    location_primary_detail: state.gmbListingLocation.location_primary_detail,
    error_menu: state.gmbListingLocation.location_menu_detail_error,
    error_primary: state.gmbListingLocation.location_primary_detail_error,
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    actions: bindActionCreators(locationActions, dispatch),
  };
};

LocationListingDetailMenu.contextTypes = {
  router: PropTypes.object.isRequired,
};

LocationListingDetailMenu.propTypes = {
  actions: PropTypes.object.isRequired,
  locationDetail: PropTypes.object,
  error: PropTypes.object,
  loader: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationListingDetailMenu);
