export const images = {
    googleIcon : require('./googleIcon.png'),
    facebookIcon : require('./facebookIcon.png'),
    instagramIcon : require('./instagramIcon.png'),
    twitterIcon : require('./twitterIcon.png'),
    youtubeIcon: require('./youtubeIcon.png'),
    linkedinIcon : require('./linkedinIcon.png'),
    gmailIcon : require('./gmailIcon.png'),
    playstoreIcon : require('./playstoreIcon.png'),
    iosAppStoreIcon : require('./app-store.png'),
    zomatoIcon : require('./zomatoIcon.png'),
    swiggyIcon : require('./swiggyIcon.png'),
    bookingIcon : require('./bookingIcon.png'),
    agodaIcon : require('./svg/agodaIcon.svg'), 
    goMmtIcon : require('./goMmtIcon.png'),
    tripadvisorIcon : require('./tripAdvisorIcon.png') ,
    deliverooIcon : require('./deliverooPreview.png') ,
    XIcon : require('./X.jpg') 
}

export const socialKeys = {
    'google': 'Google',
    'zomato': 'Zomato',
    'gmail' : 'Gmail',
    'tripadvisor' : 'Tripadvisor',
    'facebook' : 'Facebook',
    'whatsappmessenger' : 'Whatsapp Messenger',
    'twitter' : 'Twitter',
    'practo' : 'Practo',
    'playstore' : 'Playstore',
    'iosappstore': 'IOS App Store',
    'igmessenger' : 'Instagram Messenger',
    'goibibo' : 'Goibibo',
    'swiggy' : 'Swiggy',
    'instagram' : 'Instagram',
    'quora' : 'Quora',
    'lybrate' : 'Lybrate',
    'tiktok' : 'tiktok',
    'mouthshut' : 'mouthshut',
    'dineout' : 'dineout',
    'booking' : 'booking',
    'typeform' : 'typeform',
    'inhouse' : 'inhouse',
    'magicpin' : 'magicpin',
    'zomato_ors' : 'zomato_ors',
    'pinterest' : 'pinterest',
    'youtube' : 'youtube',
    'google search' : 'google search',
    'expedia' : 'expedia',
    'hotels' : 'hotels',
    'makemytrip' : 'makemytrip',
    'rista' : 'rista',
    'ewards' : 'ewards',
    'reservego' : 'reservego',
    'app store' : 'app store',
    'web' : 'web',
    'grievanceofficer' : 'grievanceofficer',
    'pissedconsumer' : 'pissedconsumer',
    'consumercomplaints' : 'consumercomplaints',
    'sitejabber' : 'sitejabber',
    'voxya' : 'voxya',
    'yelp' : 'yelp',
    'opentable' : 'opentable',
    'news' : 'news',
    'blogs' : 'blogs',
    'amazon' : 'amazon',
    'offline' : 'offline',
    'eazydiner' : 'eazydiner',
    'flipkart' : 'flipkart',
    'talabat' : 'talabat',
    'fbmessenger' : 'Facebook Messenger',
    'famepilot' : 'Famepilot',
    'customercare' : 'customercare',
    'gmbmessenger' : 'GMB Messenger',
    'ubereats' : 'ubereats',
    'agoda' : 'agoda',
    'hostelworld' : 'hostelworld',
}

export const socialPlatform = [
    {
      id: "1",
      label: "Connect to Google",
      isConnected: "",
      count: "0",
      logo: images.googleIcon,
      backgroundColor: "#FF4B26",
    },
    // {
    //   id: "4",
    //   label: "Connect to Twitter",
    //   isConnected: "",
    //   count: "0",
    //   provider:"twitter",
    //   logo: images.twitterIcon,
    //   backgroundColor: "#03A9F4",
    // },
    {
      id: "5",
      label: "Connect to Youtube",
      isConnected: "",
      count: "0",
      provider:"youtube",
      logo: images.youtubeIcon,
      backgroundColor: "#F61C0D",
    },
    // {
    //   id: "6",
    //   label: "Connect to Linkedin",
    //   isConnected: "",
    //   count: "0",
    //   logo: images.linkedinIcon,
    //   backgroundColor: "#0077B5",
    // },
    {
      id: "9",
      label: "Connect to Zomato",
      isConnected: "",
      count: "0",
      logo: images.zomatoIcon,
      backgroundColor: "#FF3044",
    },
    {
      id: "10",
      label: "Connect to Swiggy",
      isConnected: "",
      count: "0",
      logo: images.swiggyIcon,
      backgroundColor: "#F0861D",
    },
    {
      id: "11",
      label: "Connect to Booking",
      isConnected: "",
      count: "0",
      logo: images.bookingIcon,
      backgroundColor: "#003680",
    },
    {
      id: "12",
      label: "Connect to Agoda",
      isConnected: "",
      count: "0",
      logo: images.agodaIcon,
      backgroundColor: "#A73D8E",
    },
    {
      id: "13",
      label: "Connect to Go MMT",
      isConnected: "",
      count: "0",
      logo: images.goMmtIcon,
      backgroundColor: "#2B82F2",
    },
    {
      id: "14",
      label: "Connect to TripAdvisor",
      isConnected: "",
      count: "0",
      logo: images.tripadvisorIcon,
      backgroundColor: "#00B487",
    },
    {
      id: "15",
      label: "Connect to Deliveroo",
      isConnected: "",
      count: "0",
      logo: images.deliverooIcon,
      backgroundColor: "#00C2CB",
    },
  ];

  export const providerColor = {
    'all': 'green',
    'google': '#FF4B26',
    'zomato': '#FF3044',
    'gmail' : '#ea5a38',
    'tripadvisor' : '#00B487',
    'facebook' : '#3975e8',
    'whatsappmessenger' : '#25D366',
    'twitter' : '#212121',
    'practo' : '#28338c',
    'instagram' : 'linear-gradient(49.12deg, #FFDD55 6.62%, #FF543E 50.06%, #C837AB 93.5%)',
    'swiggy' : '#F0861D',
    'booking' : '#003680',
    'makemytrip' : '#2B82F2',
    'playstore': '#34A853',         
    'igmessenger': '#0099FF',       
    'goibibo': '#FF6200',          
    'quora': '#B92B27',   
    'lybrate': '#FF5A5F', 
    'tiktok': '#010101', 
    'mouthshut': '#2C3E50', 
    'dineout': '#FF645A', 
    'typeform': '#333333',
    'inhouse': '#9B59B6',
    'magicpin': '#273C75', 
    'zomato_ors': '#D0021B',
    'pinterest': '#E60023',
    'youtube': '#FF0000',
    'google search': '#4285F4',
    'expedia': '#00355F',
    'hotels': '#FF5A5F',  
    'rista': '#D35400', 
    'ewards': '#F39C12',
    'reservego': '#1E90FF', 
    'app store': '#007AFF', 
    'web': '#5D6D7E', 
    'grievanceofficer': '#555555',
    'pissedconsumer': '#FF3300', 
    'consumercomplaints': '#E74C3C',
    'sitejabber': '#FF7F00', 
    'voxya': '#2C3E50',
    'yelp': '#AF0606', 
    'opentable': '#CC3333',
    'news': '#555555', 
    'blogs': '#9E9E9E', 
    'amazon': '#FF9900', 
    'offline': '#808080', 
    'eazydiner': '#D9534F', 
    'flipkart': '#2874F0', 
    'talabat': '#FF5722', 
    'fbmessenger': '#0084FF', 
    'customercare': '#4A4A4A',  
    'gmbmessenger': '#0F9D58',
    'ubereats': '#5FB709',
    'hostelworld': '#FF6600', 
    'famepilot' : '#ff1493',
    'agoda' : '#A73D8E',
    'gpsaccounts' : '#60b8f7',
    'linkedin' : '#0077B5'
  }
