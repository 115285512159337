import React, { useState, useEffect } from "react";
import { Table } from "antd";
import FormModal from "../common/FormModal";
import CategoryPerformanceModal from "./CategoryPerformanceModal.js";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import queryString from "query-string";
import { downloadCsvHandler } from "../../actions/business/reviewsActions";


const CategoryList = (props) => {
    const { 
        wordCloudData, 
        isKeywords, 
        isLocationWise, 
        isCityWise, 
        isStateWise, 
        businessId,
        location 
    } = props ;

    const [sortData, setSortData] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [reviewsData, setReviewData] = useState(null);
    const [modalHeader, setModalHeader] = useState("");
    const [modalSubHeader, setModalSubHeader] = useState("");
    const [modalLoading, setModalLoading] = useState(false);
    const [downloadParams, setDownloadParams] = useState("");
    const [urlParam, setUrlParam] = useState("");

  const loadData = () => {
    if (wordCloudData && Array.isArray(wordCloudData)) {
      const newData = wordCloudData.map((value, index) => ({ key: index, ...value }));
      setSortData(newData || []);
    }
  };

  useEffect(() => {
    loadData();
  }, [wordCloudData]);

  const onChange = (filters, sorter, extra) => {
    console.log("params", filters, sorter, extra);
  };

  const handleOnCell = (record, rowIndex, sentiment) => {
    // console.log("Record data: ", record);

    return {
      onClick: (event) => {
        event.preventDefault();
        setReviewData(null);
        setIsOpen(true);
        setModalLoading(true);

        const {
          provider: provider_id,
          branch: location_id,
          ...rest
        } = { ...queryString.parse(location.search) };
        const { ...url_params } = { ...rest, provider_id, location_id };

        let str_param = "";
        Object.keys(url_params).map((val) => {
          if (url_params[val]) {
            if (str_param === "") str_param = val + "=" + url_params[val];
            else str_param = str_param + "&" + val + "=" + url_params[val];
          }
        });
        setModalSubHeader(sentiment);
        setModalHeader(record.Categories);

        const main_params = `${
          record.Categories ? "&category=" + record.Categories : ""}${
            sentiment ? "&sentiment=" + sentiment : ""}${
            record.Locations? "&location=" + record.Locations: ""}${
            record.City? "&city=" + record.City: ""}${
            record.State? "&state=" + record.State: ""}${
            record.Keywords? "&keyword=" + record.Keywords: ""}`;

        setDownloadParams(main_params);
        setUrlParam(str_param);

        axios_instance
          .get(
            utils.format(
              `${URLS.INSIGHT_CATEGORY_REVIEWS}?${str_param}${main_params}&page=1`,
              [businessId]
            )
          )
          .then((res) => {
            console.log("Fetched API data: ", res.data);
            setReviewData(res.data);
            setModalLoading(false);
          })
          .catch((err) => {
            setModalLoading(false);
            console.log(err);
          });
      },
    };
  };

    const handlePageClick = (selectedPage) => {
        // console.log("Inside the handle page click.. ", selectedPage);
        setModalLoading(true);
        axios_instance
            .get(
            utils.format(
                `${URLS.INSIGHT_CATEGORY_REVIEWS}?${urlParam}${downloadParams}&page=${selectedPage.currentPage}&page_size=${selectedPage.perPage}`,
                [businessId]
            )
            )
            .then((res) => {
                setReviewData(res.data);
                setModalLoading(false);
            })
            .catch((err) => {
                setModalLoading(false);
                console.log(err);
            });
    };

  const handleDownload = () => {
    const baseUrl = utils.format(
      `${URLS.INSIGHT_CATEGORY_REVIEWS_DOWNLOAD}?${urlParam}${downloadParams}`,
      [businessId]
    );
    downloadCsvHandler(baseUrl, "famepilot-category-Reviews-report.csv");
  };


  let columns = [];

  if (isStateWise) {
    columns.push({
      title: "States",
      dataIndex: "State",
      sorter: (a, b) => (a.State ? a.State.localeCompare(b.State) : 0),
    });
  }

  if (isCityWise) {
    columns.push({
      title: "Cities",
      dataIndex: "City",
      sorter: (a, b) => (a.City ? a.City.localeCompare(b.City) : 0),
    });
  }

  if (isLocationWise) {
    columns.push({
      title: "Locations",
      dataIndex: "Locations",
      sorter: (a, b) => (a.Locations ? a.Locations.localeCompare(b.Locations) : 0),
    });
  }


  if (isKeywords) {
    columns.push({
      title: "Keywords",
      dataIndex: "Keywords",
      sorter: (a, b) => (a.Keywords ? a.Keywords.localeCompare(b.Keywords) : ""),
    });
  }


  columns.push({
    title: "Categories",
    dataIndex: "Categories",
    sorter: (a, b) => (a.Categories ? a.Categories.localeCompare(b.Categories) : 0),
    onCell: (record, rowIndex) => handleOnCell(record, rowIndex, undefined),
    render: (text) => (
        <span
            className="category-perform-star"
            style={{ textDecoration: "underline", cursor: "pointer" }}
            >
            {text}
        </span>
    ),
  });

  columns.push({
    title: "Positive",
    dataIndex: "Positive",
    sorter: (a, b) => b.Positive - a.Positive,
    onCell: (record, rowIndex) => handleOnCell(record, rowIndex, "positive"),
    render: (text) => (
        <span
            className="category-perform-star"
            style={{ textDecoration: "underline", cursor: "pointer" }}
            >
            {text}
        </span>
    ),
  });

  columns.push({
    title: "Negative",
    dataIndex: "Negative",
    sorter: (a, b) => b.Negative - a.Negative,
    onCell: (record, rowIndex) => handleOnCell(record, rowIndex, "negative"),
    render: (text) => (
        <span
            className="category-perform-star"
            style={{ textDecoration: "underline", cursor: "pointer" }}
            >
            {text}
        </span>
    ),
  });

  columns.push({
    title: "Neutral",
    dataIndex: "Neutral",
    sorter: (a, b) => b.Neutral - a.Neutral,
    onCell: (record, rowIndex) => handleOnCell(record, rowIndex, "neutral"),
    render: (text) => (
        <span
            className="category-perform-star"
            style={{ textDecoration: "underline", cursor: "pointer" }}
            >
            {text}
        </span>
    ),
  });

  columns.push({
    title: "Total",
    dataIndex: "Total",
    sorter: (a, b) => b.Total - a.Total,
  });

  return (
    <div className="m-3">
        <Table
            columns={columns}
            dataSource={sortData}
            onChange={onChange}
            pagination={false}
            showSorterTooltip={false}
        />

        <FormModal 
            isOpen={isOpen}
            toggle={() => setIsOpen(false)}
            heading={
            <div className="font-blue" style={{ fontSize: "24px" }}>
                {modalSubHeader ? `${modalSubHeader} Reviews` : "All Reviews"}
                {modalHeader && (
                <span className="font-blue-gray">({modalHeader})</span>
                )}
            </div>
            }
            width={"800px"}
            showDivider={false}
        >

        <CategoryPerformanceModal
            record={reviewsData}
            location={location}
            modalLoading={modalLoading}
            handleDownload={handleDownload}
            handlePageClick={handlePageClick}
        />
        </FormModal>

    </div>
  );
};

export default CategoryList;
