import React, { useState } from "react";
import { Carousel, CarouselItem, CarouselCaption, CarouselIndicators } from "reactstrap";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ImageSlider = ({ sliderImage }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === sliderImage.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? sliderImage.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = sliderImage.map((data, index) => (
    <CarouselItem key={index} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
      <img
        alt=""
        src={data.media.image.src}
        style={{ width: "100%", height: "calc(100vh - 225px)", objectFit: "contain", borderRadius: "10px" }}
      />
      <CarouselCaption captionText="" captionHeader="" />
    </CarouselItem>
  ));

  return (
    <div className="carousel-container">
      <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={false}>
        <CarouselIndicators items={sliderImage} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
      </Carousel>

      <div
        className="carousel-arrow-left"
        onClick={previous}
        style={{
          pointerEvents: activeIndex === 0 ? "none" : "auto",
          opacity: activeIndex === 0 ? 0.3 : 1,
        }}
      >
        <ArrowBackIosNewIcon style={{ height: "40px", width: "30px", color: "#1B1C1D" }} />
      </div>

      <div
        className="carousel-arrow-right"
        onClick={next}
        style={{
          pointerEvents: activeIndex === sliderImage.length - 1 ? "none" : "auto",
          opacity: activeIndex === sliderImage.length - 1 ? 0.3 : 1,
        }}
      >
        <ArrowForwardIosIcon style={{ height: "40px", width: "30px", color: "#1B1C1D" }} />
      </div>
    </div>
  );
};

export default ImageSlider;
