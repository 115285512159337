import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Box, Dialog, DialogActions, IconButton } from "@mui/material";
import AddMenu from "./AddMenu";
import CloseIcon from "@material-ui/icons/Close";
import google_image from "../../../../../images/google.png";
import vegetarian_img from "../../../../../images/greenLeaf.jpeg";
import AddIcon from "@mui/icons-material/Add";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import AddMenuSection from "./AddMenuSection";
import { getCurrencySymbol } from "../../../../../libs/helper";
import EditMenu from "./EditMenu";
import { FaEdit } from "react-icons/fa";
import EditMenuSection from "./EditMenuSection";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import CreateMenu from "./CreateMenu";

const FullMenu = (props) => {
  const { business_id, selectReso, actions, location_food_menu_data } = props;
  // console.log("location_food_menu_datalocation_food_menu_data", location_food_menu_data?.menus)

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  // useEffect(() => {
  //     actions.getGmbLocationFoodmenu(business_id, selectReso);
  // }, [business_id, selectReso, actions])

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [openTab, setOpenTab] = React.useState(null);
  const [indexValue, setIndexValue] = React.useState(0);
  const [sectionIndexValue, setSectionIndexValue] = React.useState(0);
  const [menuIndexValue, setMenuIndexValue] = React.useState(0);

  const handleClickOpen = (dialog, index) => {
    setOpenTab(dialog);
    setIndexValue(index);
    setSectionIndexValue(index);
    setOpen(true);
  };

  // const handleOpenEdit = (dialog, itemindex, sectionIndex, menuIndex) => {
  //     setOpenTab(dialog);
  //     setIndexValue(itemindex)
  //     setSectionIndexValue(sectionIndex)
  //     setMenuIndexValue(menuIndex)
  //     setOpen(true);
  // }

  // const handleDeleteMenuItem = async (itemIndex, sectionIndex, menuIndex) => {
  //     console.log("Deleting item", itemIndex, sectionIndex, menuIndex);

  //     const updatedMenuData = { ...location_food_menu_data };
  //     const restodetail = { business_id, selectReso };

  //     if (
  //         updatedMenuData?.menus?.[menuIndex]?.sections?.[sectionIndex]?.items?.[itemIndex]
  //     ) {
  //         // itemIndex > 0 ?
  //         //     updatedMenuData.menus[menuIndex].sections[sectionIndex].items.splice(itemIndex, 1)
  //         //     : updatedMenuData.menus[menuIndex].sections.splice(sectionIndex, 1)
  //         // : updatedMenuData.menus[menuIndex].sections[sectionIndex].items.splice(itemIndex, 1)
  //         console.log("updatedMenuData.menus[menuIndex].sections[sectionIndex]?.length", updatedMenuData.menus[menuIndex].sections?.length)
  //         console.log("updatedMenuData.menus[menuIndex].sections[sectionIndex].items", updatedMenuData.menus[menuIndex].sections[sectionIndex].items)
  //         updatedMenuData.menus[menuIndex].sections[sectionIndex].items === 1 ?
  //             updatedMenuData.menus[menuIndex].sections.splice(sectionIndex, 1)
  //             : updatedMenuData.menus[menuIndex].sections[sectionIndex].items.splice(itemIndex, 1)

  //         const updateCode = {
  //             type: "updateMenu",
  //             updateMenuData: updatedMenuData,
  //         };

  //         Swal.fire({
  //             title: 'Are you sure?',
  //             text: `Do you want to delete this Item?`,
  //             icon: 'warning',
  //             showCancelButton: true,
  //             confirmButtonText: 'Ok',
  //             cancelButtonText: 'Cancel'
  //         }).then(async (result) => {
  //             if (result.isConfirmed) { // Clear selected photos after deletion
  //                 await actions?.getGmbListingUpdateListing(updateCode, restodetail);
  //                 await actions.getGmbLocationFoodmenu(business_id, selectReso);
  //             }
  //         });

  //     } else {
  //         console.log("Item not found for deletion");
  //     }
  // };

  // const handleDeleteMenuItem = async (itemIndex, sectionIndex, menuIndex) => {
  //     console.log("Deleting item", itemIndex, sectionIndex, menuIndex);

  //     const updatedMenuData = { ...location_food_menu_data };
  //     const restodetail = { business_id, selectReso };

  //     if (
  //         updatedMenuData?.menus?.[menuIndex]?.sections?.[sectionIndex]?.items?.[itemIndex]
  //     ) {
  //         const sectionItems = updatedMenuData.menus[menuIndex].sections[sectionIndex].items;
  //         const menuSection = updatedMenuData.menus[menuIndex].sections;

  //         if (menuSection.length === 1 || sectionItems.length == 1) {
  //             updatedMenuData.menus.splice(menuIndex, 1)
  //         }
  //         // If the section has only one item, delete the entire section
  //         else if (sectionItems.length === 1) {
  //             updatedMenuData.menus[menuIndex].sections.splice(sectionIndex, 1);
  //         } else {
  //             // Otherwise, just delete the specific item
  //             sectionItems.splice(itemIndex, 1);
  //         }

  //         const updateCode = {
  //             type: "updateMenu",
  //             updateMenuData: updatedMenuData,
  //         };

  //         Swal.fire({
  //             title: 'Are you sure?',
  //             text: `Do you want to delete this Item?`,
  //             icon: 'warning',
  //             showCancelButton: true,
  //             confirmButtonText: 'Ok',
  //             cancelButtonText: 'Cancel'
  //         }).then(async (result) => {
  //             if (result.isConfirmed) {
  //                 // Perform deletion and fetch updated menu
  //                 await actions?.getGmbListingUpdateListing(updateCode, restodetail);
  //                 // await actions.getGmbLocationFoodmenu(business_id, selectReso);
  //             }
  //         });

  //     } else {
  //         console.log("Item not found for deletion");
  //     }
  // };

  // const handleOpenEditMenuSection = (dialog, sectionIndex, menuIndex) => {
  //     setOpenTab(dialog);
  //     setSectionIndexValue(sectionIndex)
  //     setMenuIndexValue(menuIndex)
  //     setOpen(true);
  // }

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <>
      <div
        className="bulk-full-menu-card shadow-sm mt-4"
        style={{
          cursor: "pointer",
          maxWidth: "fit-content",
          padding: "5px 20px !important",
        }}
        onClick={() => handleClickOpen("create_menu")}
      >
        <div
          style={{
            padding: "10px 0px 10px 0px",
            display: "flex",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "#e9e9e9",
              borderRadius: "5px",
              padding: "3px",
            }}
          >
            <AddIcon style={{ width: "20px", height: "20px", color: "gray" }} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              color: "gray",
            }}
          >
            <span style={{ fontSize: "16px", fontWeight: "500" }}>Create a Menu</span>
          </div>
        </div>
      </div>

      {openTab === "create_menu" && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <Box sx={style}>
            <div style={{ padding: "25px 25px 0 25px" }}>
              <div
                className="align-center justify-content-between mb-2"
                style={{
                  fontWeight: "600",
                  fontSize: "22px",
                  color: "#1A2544",
                }}
              >
                <p className="mb-0">Create Menu</p>
                <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
              </div>
              <CreateMenu
                business_id={business_id}
                queryStringData={props?.queryStringData}
                indexValue={indexValue}
                selectReso={selectReso}
                //  location_food_menu_data={location_food_menu_data}
              />
            </div>
          </Box>
        </Dialog>
      )}

      {openTab === "menu_item" && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <Box sx={style}>
            <div style={{ padding: "25px" }}>
              <div
                className="d-flex mt-10 mb-15"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "600",
                  fontSize: "24px",
                  color: "#1A2544",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  paddingBlock: "20px",
                }}
              >
                <p>Add Menu Itemm</p>
                <DialogActions>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions>
              </div>
              <AddMenu
                business_id={business_id}
                indexValue={indexValue}
                selectReso={selectReso}
                location_food_menu_data={location_food_menu_data}
              />
            </div>
          </Box>
        </Dialog>
      )}
      {openTab === "edit_menu" && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <Box sx={style}>
            <div style={{ padding: "25px" }}>
              <div
                className="d-flex mt-10 mb-15"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "600",
                  fontSize: "24px",
                  color: "#1A2544",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  paddingBlock: "20px",
                }}
              >
                <p>Edit Menu Itemm</p>
                <DialogActions>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions>
              </div>
              <EditMenu
                business_id={business_id}
                indexValue={indexValue}
                sectionIndexValue={sectionIndexValue}
                menuIndexValue={menuIndexValue}
                selectReso={selectReso}
                location_food_menu_data={location_food_menu_data}
              />
            </div>
          </Box>
        </Dialog>
      )}
      {openTab === "edit_menu_section" && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <Box sx={style}>
            <div style={{ padding: "25px" }}>
              <div
                className="d-flex mt-10 mb-15"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "600",
                  fontSize: "24px",
                  color: "#1A2544",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  paddingBlock: "20px",
                }}
              >
                <p>Edit Menu Section</p>
                <DialogActions>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions>
              </div>
              <EditMenuSection
                business_id={business_id}
                sectionIndexValue={sectionIndexValue}
                menuIndexValue={menuIndexValue}
                selectReso={selectReso}
                location_food_menu_data={location_food_menu_data}
              />
            </div>
          </Box>
        </Dialog>
      )}
      {openTab === "menu_section" && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <Box sx={style}>
            <div style={{ padding: "25px" }}>
              <div
                className="d-flex mt-10 mb-15"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "600",
                  fontSize: "24px",
                  color: "#1A2544",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  paddingBlock: "20px",
                }}
              >
                <p>Add Menu Section</p>
                <DialogActions>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions>
              </div>
              <AddMenuSection
                business_id={business_id}
                sectionIndexValue={sectionIndexValue}
                selectReso={selectReso}
                location_food_menu_data={location_food_menu_data}
              />
            </div>
          </Box>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    location_food_menu_data: state.gmbListingLocation.location_food_menu_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FullMenu);
