import React, { useState, useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import ClearIcon from "@mui/icons-material/Clear";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import SrDoc from "../../../../images/svg/SrDoc.svg";
import SrPdf from "../../../../images/svg/SrPdf.svg";
const CustomUploadComponent = (props) => {
  const { setIsOpen, setAttachments, attachments, setFiles, files } = props;

  const attachmentInputRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleAttachmentUpload = (event) => {
    const files = Array.from(event.target.files);
    setAttachments((prev) => [...prev, ...files]);
    event.target.value = "";
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setFiles((prev) => [...prev, ...files]);
    event.target.value = "";
  };

  const removeAttachment = (index) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const removeFile = (index) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const getFileIcon = (file) => {
    if (file.type.includes("pdf")) return <img src={SrPdf} alt="PDF" className="file-icon" />;
    if (file.type.includes("word") || file.name.endsWith(".doc") || file.name.endsWith(".docx")) return <img src={SrDoc} alt="Word" className="file-icon" />;
    if (file.type.includes("audio")) return <AudiotrackIcon className="file-icon" />;
    if (file.type.includes("video")) return <PlayCircleIcon className="file-icon" />;
    if (file.type.includes("image")) return <DescriptionIcon className="file-icon" />;
    return <DescriptionIcon className="file-icon" />;
  };


  const openImagePreview = (file, index) => {
    setIsOpen(true);
  };
  
  return (
    <div className="upload-ui-container w-100">
      <div className="upload-section w-100">
        <label className="upload-label">
          Attachment{" "}
          <span style={{ color: "#646565", fontSize: "11px" }}>
            (photo, audio, video)
          </span>
        </label>
        <div className="upload-area">
          <div className="attachment-preview-container">
            {attachments.slice(0, 3).map((file, index) => (
              <div
                className="attachment-preview"
                style={{
                  marginLeft: index === 1 || index === 2 ? "-10px" : "",
                  zIndex: attachments.length - index,
                }}
                key={index}
                onClick={() => openImagePreview(file)}
              >
                {/* Render thumbnail or file icon */}
                {file.type.includes("image") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`attachment-${index}`}
                    className="attachment-thumbnail"
                  />
                ) : (
                  <div
                    className="file-icon-wrapper"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#f4f4f4",
                      borderRadius: "5px",
                    }}
                  >
                    {getFileIcon(file)}
                  </div>
                )}
                {/* Remove icon */}
                <ClearIcon
                  className="clear-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeAttachment(index);
                  }}
                  sx={{
                    fontSize: "12px",
                    backgroundColor: "red",
                    color: "#fff"
                  }}
                />
              </div>
            ))}
            {attachments?.length > 3 && (
              <h6
                style={{
                  position: "absolute",
                  top: "5px",
                  left:
                    attachments.length > 2
                      ? "75px"
                      : attachments.length > 1
                      ? "70px"
                      : "45px",
                  whiteSpace: "nowrap",
                }}
              >{`+${attachments.length - 3} More`}</h6>
            )}
            <button
              type="button"
              style={{
                position: "absolute",
                left:
                  attachments.length > 3
                    ? "140px" :
                    attachments.length > 2
                     ? "80px" 
                    : attachments.length > 1
                    ? "70px"
                    : attachments.length === 0 ? "0px":'45px',
                    height:'27px',
                    width:attachments.length === 0 ? "":'30px',
              }}
              className={`upload-button ${
                attachments.length === 0 && "upload-new-file"
              }`}
              onClick={() => attachmentInputRef.current.click()}
            >
              {attachments.length > 0 ? (
                <AddIcon />
              ) : (
                <>
                  <AttachFileIcon fontSize="small" /> <span style={{fontSize: "12px"}}>Upload</span> 
                </>
              )}
            </button>
          </div>
          <input
            ref={attachmentInputRef}
            type="file"
            accept=".jpg,.jpeg,.png,.gif,.mp4,.mp3"
            hidden
            multiple
            onChange={handleAttachmentUpload}
          />
        </div>
      </div>

      {/* File Section */}
      <div className="upload-section w-100">
        <label className="upload-label">
          File{" "}
          <span style={{ color: "#646565", fontSize: "11px" }}>
            (pdf, xlsx, docx)
          </span>
        </label>
        <div className="upload-area">
          <div className="file-preview-container">
            {files?.slice(0, 3).map((file, index) => (
              <div
                className="file-preview-circle"
                style={{
                  marginLeft: index === 1 || index === 2 ? "-10px" : "",
                  zIndex: files.length - index,
                }}
                key={index}
              >
                {getFileIcon(file)}
                <ClearIcon
                  className="clear-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile(index);
                  }}
                  sx={{
                    fontSize: "12px",
                    backgroundColor: "red",
                    color: "#fff"
                  }}
                />
              </div>
            ))}
            {files?.length > 3 && (
              <h6
                style={{
                  position: "absolute",
                  top: "7px",
                  left:
                    files.length > 2
                      ? "75px"
                      : files.length > 1
                      ? "70px"
                      : "45px",
                  whiteSpace: "nowrap",
                  marginBottom: "0px",
                }}
              >{`+${files.length - 3} File`}</h6>
            )}
            <button
              style={{
                position: "absolute",
                left:
                files.length > 3
                  ? "140px" :
                  files.length > 2
                   ? "80px" 
                  : files.length > 1
                  ? "70px"
                  : files.length === 0 ? "0px":'45px',
                  height:'27px',
                  width:files.length === 0 ? "":'30px',
              }}
              type="button"
              className={`upload-button ${
                files.length === 0 && "upload-new-file"
              }`}
              onClick={() => fileInputRef.current.click()}
            >
              {files.length > 0 ? (
                <AddIcon />
              ) : (
                <>
                  <AttachFileIcon fontSize="small" /> <span style={{fontSize: "12px"}}>Upload</span> 
                </>
              )}
            </button>
          </div>
          <input
            ref={fileInputRef}
            type="file"
            accept=".pdf,.xlsx,.docx,.doc"
            hidden
            multiple
            onChange={handleFileUpload}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomUploadComponent;
