import React from "react";
import { LineChart } from "react-chartkick";
import "chartkick/chart.js";

import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import LoaderComponent from "../common/loaderComponent";
import { FPBadge } from "../common/new";
import { Skeleton } from "@mui/material";
class ComcastSentiment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    const { businessId, filters, loading } = this.props;
    let promoterData = {};
    let neutralData = {};
    let detractorData = {};
    try {
      let url = utils.format(URLS.NPS_STATS, [businessId]);
      if (filters) url = url + filters;
      const response = await axios_instance.get(url);
      const graphData = response.data ? response.data : [];
      const keys = Object.keys(graphData);
      keys.map((item) => {
        promoterData[item] = graphData[item].positive;
        neutralData[item] = graphData[item].neutral;
        detractorData[item] = graphData[item].negative;
      });
      const sentiments = [
        {
          name: "Promoter",
          data: promoterData,
          dataset: {
            pointBackgroundColor: "#99ff99",
            pointBorderColor: "#00802b",
            pointRadius: 2,
            pointHoverRadius: 4,
            hoverBackgroundColor: "#99ff99",
            hoverBorderColor: "#00802b",
          },
        },
        {
          name: "Neutral",
          data: neutralData,
          dataset: {
            borderDash: [],
            pointBackgroundColor: "#ffffb3",
            pointBorderColor: "#ffe234",
            pointRadius: 2,
            pointHoverRadius: 4,
            hoverBackgroundColor: "#ffffb3",
            hoverBorderColor: "#ffe234",
          },
        },
        {
          name: "Detractor",
          data: detractorData,
          dataset: {
            borderDash: [],
            pointBackgroundColor: "#ff9999",
            pointBorderColor: "#ff0000",
            pointRadius: 2,
            pointHoverRadius: 4,
            hoverBackgroundColor: "#ff4545",
            hoverBorderColor: "#ff0000",
          },
        },
      ];
      this.setState({ isLoading: false, sentiments: sentiments });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { isLoading, sentiments } = this.state;
const {loading} = this.props;
    const customChartOptions = {
      hover: {
        mode: "single",
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
            },
          },
        ],
      },
    };

    return (loading ? loading : isLoading) ? (
      <>
        <div className="nps-skeleton" style={{ padding: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              transform: "translate(0)",
            }}
          >
            <Skeleton width="40%" height={25} />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Skeleton
              width={"85%"}
              height={275}
              style={{ transform: "translate(0)" }}
            />
          </div>
        </div>
      </>
    ) : (
      <div>
        {/* <LoaderComponent loader={isLoading} rounded inline></LoaderComponent> */}

        <div className="d-flex gap-20 mb-2 justify-content-end">
          <FPBadge
            label="Promoter"
            textClass="trending-header"
            color="#57e32c"
          />
          <FPBadge
            label="Neutral"
            textClass="trending-header"
            color="#ffe234"
          />
          <FPBadge
            label="Detractor"
            textClass="trending-header"
            color="#ff4545"
          />
        </div>
        <LineChart
          data={sentiments}
          colors={["#57e32c", "#ffe234", "#ff4545"]}
          download="Sentiments"
          library={customChartOptions}
          legend={false}
        />
      </div>
    );
  }
}

export default ComcastSentiment;
