import React from 'react';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import CustomStarRatingComponent from '../../components/common/StarComponent';
import { snakeToCapital, starRatingColor } from '../../libs/utils';
import { Redirect } from '../../images/svg';

const TicketCardHeader = ({
  data,
}) => {
  const { source_data } = data;
  const {star_border_color, star_bg_color} = starRatingColor(source_data.rating);
  return (
    <header className="review-card-header">
      <div className="d-flex justify-content-between">
        <section className="d-flex align-item-center gap-10">
          {source_data.provider && <div
            className="provider-logo-wrapper"
          >
            <img src={source_data.provider} style={{ objectFit: 'contain' }} alt="" height="26" width="26" />
          </div>}

          <div className="d-flex align-items-start gap-10">
            <div>
              <span className="review-title">
                {snakeToCapital(data.branch)}
              </span>
              <span className="d-block font-blue-gray">
                {moment(source_data.created_at).format('llll')}
              </span>
            </div>
            <div className="review-time">
              {source_data.review_type === 'thumb' &&
                  <Icon
                    color='teal'
                    size='large'
                    name={source_data.is_thum_up ? 'thumbs up' : 'thumbs down'}
                  />}
              {source_data.review_type === 'smiley' &&
                  <Icon
                    color='teal'
                    size='large'
                  />}

              {source_data.review_type === 'star' &&
              // Add here
                  <div className="rating" style={{borderColor: star_border_color, backgroundColor: star_bg_color, width:'102px'}}>
                    <CustomStarRatingComponent
                      name="rate1"
                      starCount={5}
                      className="align-bottom"
                      starColor="#FBBD08"
                      emptyStarColor="#FFFFFF"
                      value={+source_data.rating}
                      editing={false}
                    />
                  </div>}
            </div>
          </div>
        </section>

        {source_data.provider_name === 'zomato' ||
        source_data.provider_name === 'tripadvisor' ? (
            <span
              role="button"
              title={`Redirect to ${source_data.provider_name}`}
              onClick={() => {
                window.open(source_data.review_link, '_blank');
              }}
            >
              <Redirect />
            </span>
          ) : source_data.provider_link ? (
            <span
              role="button"
              title={`Redirect to ${source_data.provider_name}`}
              onClick={() => {
                window.open(source_data.provider_link, '_blank');
              }}
            >
              <Redirect />
            </span>
          ) : (
            ''
          )}
      </div>
    </header>
  );
};

export default TicketCardHeader;
