import React from 'react';
import FPTab from '../../components/common/new/Listing/FPTabs';

const ListingHeader = ({ listinghandleRootTabChange, defaultTab }) => {
  return (
    <FPTab
      variant="round-fill"
      // tabLabels={['Video', 'Logo', 'Cover', 'Interior Photos', 'Exterior', 'Additional', 'Menu']}
      tabLabels={['Cover', 'Profile', 'Logo', 'Exterior', 'Interior', 'Product', 'At Work', 'Food And Drink', 'Menu', 'Common Area', 'Rooms', 'Team', 'Additional', "Video"]}
      className={'dashboard-tab-listing'}
      defaultValue={defaultTab || 0}
      onChange={(value) => listinghandleRootTabChange(value)}
    />
  );
};

export default ListingHeader;
