import React from 'react';
import FormModal from './FormModal';

class LimitModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { header, children, toggle, showCloseBtn = true,width, ...rest } = this.props;
    return (
      <FormModal
        isOpen={this.props.isOpen}
        width={width}
        toggle={toggle}
        heading={header}
        showDivider={false}
        showCloseBtn={showCloseBtn}
        className="ContactFormPopUp"
        {...rest}
      >
        {children}
      </FormModal>
    );
  }
}

export default LimitModal;