import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ListDescription } from "semantic-ui-react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react"; // Import Map and Marker from google-maps-react
import GoogleIcon from "@mui/icons-material/Google";
import CustomStarRatingComponent from "../../common/StarComponent";
import { formatReviews } from "../../../libs/helper";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import verified_svg from "../../../images/verified.svg";
import duplicate_svg from "../../../images/dusplicate.svg";
import google_svg from "../../../images/googlephoto.svg";
import Select from "react-select";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import { bindActionCreators } from "redux";
import FPInput from "../../common/new/forms/ui/FPInput";
import delete_png from "../../../images/delete_png.webp";
import ListingFPInput from "../../common/new/Listing/ListingFPInput";
import "./listing.css";

const UpdateLocation = (props) => {
  const { business_id, selectReso, actions } = props;
  const [savedLocations, setSavedLocations] = useState([]);
  const [mapCenter, setMapCenter] = useState([]);
  const [zoom, setZoom] = useState(5);
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([""]); // Manage multiple locations

  useEffect(() => {
    if (savedLocations?.length) {
      const service = new window.google.maps.places.PlacesService(document.createElement("div"));

      const updatedMapCenters = [];

      savedLocations.forEach((location) => {
        const request = {
          placeId: location?.placeId, // Make sure placeId exists
          fields: ["geometry"],
        };

        service.getDetails(request, (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const { lat, lng } = place.geometry.location;
            updatedMapCenters.push({ lat: lat(), lng: lng() });
            if (updatedMapCenters.length === savedLocations.length) {
              setMapCenter(updatedMapCenters); // Once all locations are processed
              setZoom(10); // Adjust the zoom level as necessary
            }
          } else {
            console.error("Error fetching place details:", status);
          }
        });
      });
    }
  }, [savedLocations]);

  useEffect(() => {
    if (props.primaryDetail?.serviceArea?.places?.placeInfos?.length) {
      setSavedLocations(props.primaryDetail?.serviceArea?.places?.placeInfos);
    } else {
      setSavedLocations([]);
    }
  }, [props.primaryDetail?.serviceArea?.places?.placeInfos]);

  useEffect(() => {
    if (props?.googleLocationData?.predictions) {
      const formattedOptions = props.googleLocationData.predictions.map((prediction) => ({
        label: prediction.description,
        value: prediction,
      }));
      setOptions(formattedOptions);
    }
  }, [props.googleLocationData]);

  const handleInputChange = (newValue) => setSearchValue(newValue);

  useEffect(() => {
    if (searchValue?.length > 2) {
      fetchSearchResults(searchValue);
    }
  }, [searchValue]);

  const fetchSearchResults = async (value) => {
    try {
      await props.actions.getGmbGoogleLocation(props?.business_id, props?.selectReso, value);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleSelectChange = (option) => {
    const updatedOptions = [...selectedOptions, option]; // Add new location to array
    setSelectedOptions(updatedOptions);

    const placeId = option?.value?.place_id;

    const service = new window.google.maps.places.PlacesService(document.createElement("div"));

    const request = {
      placeId: placeId,
      fields: ["geometry"],
    };

    service.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const { lat, lng } = place.geometry.location;
        const updateLatLng = [...mapCenter, { lat: lat(), lng: lng() }];
        setMapCenter(updateLatLng);
        setZoom(12);
      } else {
        console.error("Error fetching place details:", status);
      }
    });
  };

  const handleRemoveLocation = (index) => {
    const updatedOptions = selectedOptions.filter((_, i) => i !== index);
    setSelectedOptions(updatedOptions);
  };

  const handleRemoveSaveLocation = (index) => {
    const updateSaveLocation = savedLocations.filter((_, i) => i !== index);
    setSavedLocations(updateSaveLocation);
  };

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const addressString = [
    props.primaryDetail?.addressLines,
    props.primaryDetail?.locality,
    props.primaryDetail?.postalCode,
  ].join(", ");

  const locationUpdateHandler = async () => {
    // if (selectedOptions.length > 0) {
    const locationArray = [];
    selectedOptions?.forEach((loc) => {
      if (loc?.value?.place_id !== undefined) {
        locationArray.push({
          placeId: loc?.value?.place_id,
          placeName: loc.label,
          // lat: loc.address.coordinates.lat,
          // lng: loc.address.coordinates.lng,
        });
      }
    });
    const savedLocationsList = savedLocations?.length ? savedLocations : [];
    const mergedArray = [...savedLocationsList, ...locationArray];
    const data = {
      type: "ServiceArea",
      placeInfos: mergedArray,
    };

    const restodetail = {
      business_id,
      selectReso,
    };
    await actions.getGmbListingUpdateListing(data, restodetail);

    // listingUtils?.displayMessage(
    //   "info",
    //   "Listing getting updated, It may take a few minutes."
    // );
    // await actions.getGmbListingRefreshListing(business_id, selectReso);

    // listingUtils?.displayMessage("close"); // Close the "refresh process" toast
    // listingUtils?.displayMessage("positive", "Listing updated successfully.");
    await actions.getGmbListingPrimaryDetail(business_id, selectReso);
    // setLocationInputs([{}]);
    // }
  };

  return (
    <>
      <div className="listing-container">
        <div className="listing-header">
          <div className="listing-info">
            <div className="listing-name">{props.primaryDetail?.title}</div>
            <img
              src={
                props?.primaryDetail?.is_varified
                  ? verified_svg
                  : props?.primaryDetail?.is_suspend
                  ? duplicate_svg
                  : props?.primaryDetail?.is_duplicate
                  ? duplicate_svg
                  : ""
              }
              alt="statusImage"
            />
            <div className="rating-container">
              <CustomStarRatingComponent
                name="rate"
                starCount={5}
                starColor="#FBBD08"
                emptyStarColor="white"
                value={props.primaryDetail?.averageRating}
                editing={false}
              />
            </div>
            <span>{props.primaryDetail?.averageRating?.toFixed(1)}</span>
            <span>{formatReviews(props.primaryDetail?.totalReviewCount)} Reviews</span>
          </div>
        </div>
        <div className="review-address">
          <ListDescription>{addressString}</ListDescription>
        </div>
      </div>
      <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "calc(100% - 120px)",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "15px",
                paddingBottom: "5px",
                position: "relative",
              }}
            >
              <span className="listing-name">Complete your Business Information & Sync</span>
              <div style={{ display: "grid" }}>
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#1B1C1D",
                  }}
                >
                  Location Name
                </span>
                <FPInput type="text" placeholder={"Wat-a-Burger, Bhopal"} value={props.primaryDetail.title} />
              </div>
              <div className="d-flex justify-content-end">
                <a href={props.primaryDetail?.mapsUri} target="_blank" rel="noreferrer">
                  <FPButton label="Edit on Google" size="small" startIcon={<GoogleIcon />} />
                </a>
              </div>
              {savedLocations.length > 0 && (
                <div style={{ display: "grid", gap: "5px" }}>
                  {savedLocations?.map((selectValue, index) => {
                    return (
                      <>
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#1B1C1D",
                          }}
                        >
                          Service Areas {index + 1}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            {/* <Select
                            options={options}
                            onInputChange={handleInputChange}
                            onChange={handleSelectChange}
                            placeholder="Search and select..."
                            isSearchable={true}
                            value={options.find(
                              (option) =>
                                option.label === selectValue?.placeName
                            )} // Pass the correct option object
                          /> */}
                            <ListingFPInput value={selectValue?.placeName} />
                          </div>
                          <img
                            style={{ height: "16px", width: "16px" }}
                            src={delete_png}
                            alt=""
                            onClick={() => handleRemoveSaveLocation(index)}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
              <div style={{ display: "grid", gap: "5px" }}>
                {selectedOptions?.map((selectValue, index) => (
                  <>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#1B1C1D",
                      }}
                    >
                      Service Areas {savedLocations?.length + index + 1}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Select
                          name="aria-live-color"
                          options={options}
                          onInputChange={handleInputChange}
                          onChange={handleSelectChange}
                          // placeholder="Search and select..."
                          isSearchable={true}
                          value={selectValue}
                          // components={{ ClearIndicator: () => null }} // Use an arrow function to render nothing
                        />
                      </div>
                      {selectedOptions?.length > 1 && index !== 0 && (
                        <img
                          style={{ height: "16px", width: "16px" }}
                          src={delete_png}
                          alt=""
                          onClick={() => handleRemoveLocation(index)}
                        />
                      )}
                    </div>
                  </>
                ))}
              </div>
              <div style={{ height: "400px", width: "calc(100% - 120px)" }}>
                <Map
                  google={props.google}
                  zoom={10}
                  containerStyle={containerStyle}
                  center={mapCenter.length ? mapCenter[mapCenter.length - 1] : { lat: 0, lng: 0 }}
                  style={{ height: "100%", width: "100%" }}
                >
                  {mapCenter?.map((cent) => (
                    <Marker position={cent} />
                  ))}
                </Map>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "20px",
              }}
            >
              <FPButton
                label="Update"
                size="small"
                disabled={props?.primaryDetail?.is_suspend}
                onClick={locationUpdateHandler}
              />
            </div>
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  googleLocationData: state?.gmbListingLocation?.googleLocationData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default GoogleApiWrapper({
  apiKey: "AIzaSyDtiQSYOJ6tdOSXoM6ZWbpNAOcQgmLWDxY",
})(connect(mapStateToProps, mapDispatchToProps)(UpdateLocation));
