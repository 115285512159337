import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { bindActionCreators } from "redux";
import { Row, Col, TabContent, TabPane } from "reactstrap";
import { Card } from "semantic-ui-react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { reduxForm } from "redux-form";
import cookie from "react-cookies";
import * as OverviewActions from "../../actions/overview/OverviewActions";
import NetScoreCard from "../../components/overview/NetScoreCard";
import ThirdPartyReview from "../../components/overview/ThirdPartyReview";
import FirstPartyReview from "../../components/overview/FirstPartyReview";
import ReviewSummary from "../../components/overview/ReviewSummary";
import ComcastSentiment from "../../components/overview/ComcastSentiment";
// import LoaderComponent from "../../components/common/loaderComponent";
import apiUrls, * as URLS from "../../libs/apiUrls";
import LocationListComponent from "../../components/overview/TopLocations";
import WordCloudComponent from "../../components/overview/WordCloudComponent";
import OpenTicketTable from "../../components/overview/OpenTicketTable";
import axios_instance from "../../libs/interseptor";
import * as BusinessActions from "../../actions/business/businessSetupActions";
import FPSwitch from "../../components/common/new/FPSwitch/FPSwitch";
import { Box } from "@material-ui/core";
import "../../modules/dashboard/dashboard-page.css";
import { ReviewPieChart } from "../../modules/dashboard/component/chart";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import { FPFilter, FPToolTip, ReviewSlider } from "../../components/common/new";
import * as filterListActions from "../../actions/common/filterListActions";
import { serviceOptions, startRatingColors } from "../../libs/constant";
import { getAllParamsInArrayForm } from "../../libs/utils";
import ResponseChart from "../../modules/dashboard/component/chart/ResponseChart";
import SectionBarChart from "../../modules/dashboard/component/chart/SectionBarChart";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import { connect } from "react-redux";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CustomStarRatingComponent from "../../components/common/StarComponent";
import positiveDot from "../../images/svg/positiveDot.svg";
import negativeDot from "../../images/svg/negativeDot.svg";
import neutralDot from "../../images/svg/neutralDot.svg";
import CategoryPerformanceCard from "../../components/overview/CategoryPerformanceCard";
import ComplaintsCard from "../../components/overview/ComplaintsCard";
import MenuPerformanceCard from "../../components/overview/MenuPerformance";
import * as utils from "../../libs/utils";
import TrendingDateSelector from "../../modules/dashboard/component/TrendingDateSelector";
import MapIcon from "../../images/svg/mapIcon.svg";
import PrintIcon from "../../images/svg/PrintIcon.svg";
import * as routerStateAction from "../../actions/common/routerStateAction";
import * as commonAction from "../../actions/common/commonAction";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import * as TeamAction from "../../actions/team/teamAction";
import { CircularProgress, Skeleton } from "@mui/material";
import ReviewChannels from "./ReviewChannels";
import * as dashboardActions from "../../actions/common/dashboardAction";
import { IoMdRefresh } from "react-icons/io";

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(props.location.search);
    this.toggleTab = this.toggleTab.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.printDashboard = this.printDashboard.bind(this);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.updateRouterState = this.updateRouterState.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
    this.state = {
      tabKey: "dashboard",
      filterValue: "By Year",
      activeShape: null,
      showInfoWindow: false,
      shapeEvent: null,
      data: {},
      sentimentLoad: false,
      firstDataLoad: false,
      secondDataLoad: false,
      wordCloud_dataLoad: false,
      disableState: false,
      disableCity: false,
      date: {
        start_date: query.start_date ? query.start_date : new Date(),
        end_date: query.end_date ? query.end_date : new Date(),
        date_type: query.date_type,
      },
      displayLocationOverview: false,
      service_type: query.service_type ? query.service_type : undefined,
      defaultFilter: null,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      ratingData: {},
      reviewPerformanceData: null,
      keyWordDays: 30,
      initialCenter: { lat: 41.38068, lng: 1.082409 },
      categoryPerformanceData: {},
      responseChartData: null,
      barHeadData: {
        totalData: "",
        respondedData: "",
        notRespondedData: "",
      },
      SectionBarChartData: null,
      totalReviewsData: null,
      menuPerformanceData: {},
      topLoc: [],
      worstLoc: [],
      categoryPerfomancesLoader: false,
      openTicketLoader: false,
      topBottomLocationLoader: false,
      worldCloudApiCalled: false
    };
  }

  onMarkerClick = (props, marker, data) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      data,
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  getCenterFromList(list) {
    if (!list || list.length === 0) return { lat: 41.38068, lng: 1.082409 }; // Default center if list is empty

    let totalLat = 0;
    let totalLng = 0;

    list.forEach((item) => {
      if (item.lat_long && item.lat_long.coordinates) {
        totalLat += item.lat_long.coordinates[1];
        totalLng += item.lat_long.coordinates[0];
      }
    });

    const avgLat = totalLat / list.length;
    const avgLng = totalLng / list.length;

    return { lat: avgLat, lng: avgLng };
  }

  // 2 dashboard api

  fetchStats = (filter) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.setState({ firstDataLoad: true });
    this.props.OverviewActions.getStats(business_id, filter).then(
      () => {
        this.setState({ firstDataLoad: false });
      },
      () => {
        this.setState({ firstDataLoad: false });
      }
    );
  };

  // fetchTrends = (filter) => {
  //   const {
  //     match: {
  //       params: { business_id },
  //     },
  //   } = this.props;
  //   this.setState({ firstDataLoad: true });
  //   if (filter.date_type === "") delete filter.date_type;
  //   this.props.OverviewActions.getTrends(business_id, filter).then(
  //     () => {
  //       this.setState({ firstDataLoad: false });
  //     },
  //     () => {
  //       this.setState({ firstDataLoad: false });
  //     }
  //   );
  // };

  fetchLocations = (filter) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.setState({ firstDataLoad: true });
    this.props.OverviewActions.getLocations(business_id, filter).then(
      () => {
        this.setState({ firstDataLoad: false });
      },
      () => {
        this.setState({ firstDataLoad: false });
      }
    );
  };

  fetchWordCloud = (filter) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.setState({ wordCloud_dataLoad: true });
    this.props.dashboardActions.getWordCloud(business_id, filter).then(
      () => {
        this.setState({ wordCloud_dataLoad: false });
      },
      () => {
        this.setState({ wordCloud_dataLoad: false });
      }
    );
  };

  fetchOpenTickets = (filter) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.setState({ openTicketLoader: true });
    this.props.dashboardActions.getOpenTicket(business_id, filter)
      .then(() => {
        this.setState({ openTicketLoader: false });
      }).catch((err) => {
        console.log(err);
        this.setState({ openTicketLoader: false });
      })
  };

  fetchRatingData = async (filter) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.setState({ firstDataLoad: true });
    await this.props.OverviewActions.getCountAverage(business_id, filter).then(
      () => {
        this.setState({ firstDataLoad: false });
      },
      () => {
        this.setState({ firstDataLoad: false });
      }
    );
    // const url = utils.format(`${URLS.RATING_DATA}`, [business_id]);
    // axios_instance
    //   .get(url, { params: { ...filter } })
    //   .then((res) => {
    //     this.setState({ ratingData: res.data });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  // 1 Overall Brand Sentiment dagta
  fetchReviewData(filter) {
    this.setState({ sentimentLoad: true });
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const url = utils.format(`${URLS.REVIEW_PERFORMANCE}`, [business_id]);
    axios_instance
      .get(url, { params: { ...filter } })
      .then((res) => {
        this.setState({ reviewPerformanceData: res.data });
        this.setState({ sentimentLoad: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 3 Top 5 Complaints Breakdown data
  fetchComplainData = async (params) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const url = utils.format(`${apiUrls.REVIEW_COMPLAINTS}`, [business_id]);
    await axios_instance
      .get(url, { params: { ...params } })
      .then((res) => {
        this.setState({ categoryPerformanceData: res?.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchResponseChartData = async (params) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const url = `${apiUrls.RESPONSE_RATE}`.replace("{}", business_id);
    await axios_instance
      .get(url, { params: { ...params, report_type: "source" } })
      .then(({ data }) => {
        this.setState({ responseChartData: data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 5. Category Performance
  fetchTagData = async (query_params, isDateFilter) => {
    const { match: { params: { business_id } } } = this.props;

    this.setState({ 'categoryPerfomanceLoader': !isDateFilter });
    this.props.dashboardActions.categoryPerformance(business_id, query_params)
      .then((res) => {
        this.setState({ 'categoryPerfomanceLoader': false });
      }).catch((err) => {
        console.log(err);
        this.setState({ 'categoryPerfomanceLoader': false });
      })
  };

  fetchSectionBarChartData = async (params) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;

    const url = `${apiUrls.SECTION_HEADING}`.replace("{}", business_id);

    await axios_instance
      .get(url, { params: { ...params, report_type: "source" } })
      .then(({ data }) => {
        this.setState({ SectionBarChartData: data.section_details });
        this.setState({
          barHeadData: {
            totalData: data.section_heading?.total || "",
            respondedData: data.section_heading?.responded_count || "",
            notRespondedData: data.section_heading?.not_responded_count || "",
          },
        });
      })
      .catch((err) => {
        console.log("Bar-error", err);
      });
  };

  // 4. Menu Performance
  fetchMenuPerformanceData = async (params) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const url = `${apiUrls.MENU_PERFORMANCE}`.replace("{}", business_id);
    await axios_instance
      .get(url, { params: { ...params } })
      .then((res) => {
        this.setState({
          menuPerformanceData: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  totalReviews = async (params) => {
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    const url = utils.format(`${apiUrls.BUSINESS_REPORT}`, [business_id]);
    const queryParams = new URLSearchParams(search);
    const provider = queryParams.get("provider");
    const branch = queryParams.get("branch");
    const city = queryParams.get("city");
    const state = queryParams.get("state");
    const serviceType = queryParams.get("service_type");
    const user_filter = queryParams.get("team_department_member");

    await axios_instance
      .get(url, {
        params: {
          ...params,
          report_type: "source",
          provider: provider ? provider : "",
          ...(branch && { branch }),
          ...(city && { city }),
          ...(state && { state }),
          ...(serviceType && { service_type: serviceType }),
          team_department_member: user_filter ? user_filter : "",
        },
      })
      .then(({ data }) => {
        const dataArray = Object.entries(data);
        const sortedArray = dataArray.sort((a, b) => b[1].review - a[1].review);
        const filteredAndSortedArray = sortedArray.filter(
          (item) => item[1].review !== 0
        );
        const resultObject = Object.fromEntries(filteredAndSortedArray);
        this.setState({ totalReviews: resultObject });
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  topLocationData = async (params) => {
    const { match: { params: { business_id } } } = this.props;
    this.setState({ topBottomLocationLoader : true });
    this.props.dashboardActions.topBottomLocation(business_id, params)
      .then((res) => {
        this.setState({ topBottomLocationLoader : false });
      }).catch((err) => {
        console.log(err);
        this.setState({ topBottomLocationLoader : false });
      });
  
  };

  fetchData(filter) {
    if (this.state.tabKey === "dashboard") {
      this.setState({
        defaultFilter: getAllParamsInArrayForm(
          "?" + queryString.stringify(filter)
        ),
      });
      this.setState({
        firstDataLoad: true,
        secondDataLoad: true,
        // wordCloud_dataLoad: true,
      });
      Promise.all([
        this.fetchReviewData(filter),
        this.fetchStats(filter),
        this.fetchComplainData(filter),
        // Object.keys(this.props.categoryData).length <=0  && this.fetchTagData(filter),
        this.totalReviews(filter),
      ])
        .then(() => {
          this.setState({ firstDataLoad: false });
          setTimeout(() => {
            Promise.all([
              this.fetchResponseChartData(filter),
              this.fetchSectionBarChartData(filter),
              this.fetchMenuPerformanceData(filter),
            ]);
            this.setState({ secondDataLoad: false });
          }, 2000);
        })
        .then(() => {
          setTimeout(() => {
            let wordCloudPayload = JSON.parse(JSON.stringify({ ...filter }));
            if (
              wordCloudPayload &&
              Object.keys(wordCloudPayload).includes("branch_tag")
            ) {
              delete wordCloudPayload.branch_tag;
            }
            // this.fetchWordCloud(wordCloudPayload);
          }, 4000);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.setState({ firstDataLoad: false, secondDataLoad: false }); // Stop loader on error
        });
    } else {
      this.fetchLocations(filter);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      location: { search: nextSearch },
    } = nextProps;
    const {
      location: { search: prevSearch },
    } = this.props;
    const nextQuery = queryString.parse(nextSearch);
    if (prevSearch !== nextSearch) {
      this.fetchData(nextQuery);
      if (this.state.tabKey === "dashboard") {
        this.fetchTagData(nextQuery);
        this.fetchOpenTickets(nextQuery);
      }
      const date = {
        date_type: nextQuery.date_type,
        start_date: nextQuery.start_date || new Date(),
        end_date: nextQuery.end_date || new Date(),
      };
      this.setState({ date });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { business_id: prevBusiness },
      },
    } = prevProps;
    const {
      match: {
        params: { business_id: nextBusiness },
      },
      location: { search: nextSearch },
    } = this.props;
    const nextQuery = queryString.parse(nextSearch);
    if (prevBusiness !== nextBusiness) {
      this.fetchFilterList();
      this.fetchData(nextQuery);
      const date = {
        date_type: nextQuery.date_type,
        start_date: nextQuery.start_date || new Date(),
        end_date: nextQuery.end_date || new Date(),
      };
      this.setState({ date });
    }
  }

  toggleTab(tab) {
    const {
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    this.setState({ tabKey: tab }, () => {
      this.fetchData(query);
    });
  }

  handleMarkerClick(data, shape, event) {
    this.setState({
      activeShape: shape,
      showInfoWindow: true,
      shapeEvent: event,
      data,
    });
  }

  printDashboard() {
    window.print();
  }

  componentDidMount() {
    this.props.actions.setSelectedTab(0);
    const {
      location: { search },
    } = this.props;
    let query = {};
    if (search != "") query = queryString.parse(search);
    this.fetchData(query);
    // this.props.businessActions.getLockedModules(business_id);

    this.fetchFilterList();
    //Set Title
    document.title = "Dashboard";
    // this.props.OverviewActions.getDateFilter({ blank_req: true });
  }

  handleBranchChange(event, branch) {
    const {
      history,
      location: { search },
    } = this.props;
    let filter = { ...queryString.parse(search), branch };
    const url = window.location.pathname + "?" + queryString.stringify(filter);
    history.push(url);
  }

  redirectTo(to, provider) {
    const {
      history,
      location: { search },
    } = this.props;
    const filters = { ...queryString.parse(search), provider: provider.join() };
    history.push({ pathname: to, search: queryString.stringify(filters) });
  }

  handleTabChange = (value) => {
    setTimeout(() => {
      value ? this.toggleTab("feeds") : this.toggleTab("dashboard");
    }, 500);
  };

  fetchFilterList = async () => {
    const {
      filterAction,
      match,
      // actions,
      match: { params },
      teamActions,
    } = this.props;
    const {
      params: { business_id },
    } = match;
    try {
      await Promise.all([
        filterAction
          .getBranch("", `${URLS.BUSINESS_SETUP}${business_id}/branch/`)
          .then((res) => {
            if (res.data.choices.length > 5) {
              this.setState({ displayLocationOverview: true });
            }
          }),
        filterAction.getCity(
          "",
          `${URLS.BUSINESS_SETUP}${business_id}/address/city/`
        ),
        filterAction.getState(
          "",
          `${URLS.BUSINESS_SETUP}${business_id}/address/state/`
        ),
        teamActions.teamList(params, business_id),
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        this.props.review_actions.reviewSourceOptions({ business_id }),
        this.props.commonActions.branchTagOptions(business_id),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };

  handleDefaultFilter = (list, key) => {
    const { defaultFilter } = this.state;
    const isKeyFilterAvailable =
      defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list?.map((item) => {
      item["isChecked"] =
        isKeyFilterAvailable &&
        defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };

  handleFilterList = () => {
    const service_category = cookie.load("service_category");
    const { filter, common, teamList } = this.props;

    return [
      {
        id: "1",
        label: "Date Range",
        key: "date_type",
        type: "radio",
        isSearchable: false,
        list: filter.date_type
          ? this.handleDefaultFilter(utils.removeLifetimeFilter(filter?.date_type?.choices), "date_type")
          : [],
      },
      {
        id: "2",
        label: "Source",
        key: "provider",
        type: "checkbox",
        isSearchable: true,
        list:
          this.props.source &&
          this.handleDefaultFilter(this.props.source.choices, "provider"),
      },
      {
        id: "2",
        label: "User",
        key: "team_department_member",
        type: "radio",
        isSearchable: true,
        list:
          teamList?.results &&
          this.handleDefaultFilter(
            teamList?.results?.map((item) => ({
              display_name: `${item.first_name} ${item.last_name}`,
              value: item.id,
            })),
            "team_department_member"
          ),
      },

      {
        id: "3",
        label: "Location",
        key: "branch",
        type: "checkbox",
        isSearchable: true,
        isNotEllipsisLabel: true,
        list: filter.branch
          ? this.handleDefaultFilter(filter.branch.choices, "branch")
          : [],
      },
      {
        id: "4",
        label: "City",
        key: "city",
        type: "radio",
        isSearchable: true,
        list: filter.city
          ? this.handleDefaultFilter(filter.city.choices, "city")
          : [],
      },
      {
        id: "5",
        label: "State",
        key: "state",
        type: "radio",
        isSearchable: true,
        list: filter.state
          ? this.handleDefaultFilter(filter.state.choices, "state")
          : [],
      },
      service_category === "Restaurant" && {
        id: "6",
        label: "Service Type",
        key: "service_type",
        type: "radio",
        isSearchable: false,
        list: this.handleDefaultFilter(serviceOptions, "service_type"),
      },
      {
        id: "6",
        label: "Location Tag",
        key: "branch_tag",
        type: "checkbox",
        isSearchable: false,
        list:
          common.branch_tag_options &&
          this.handleDefaultFilter(
            common.branch_tag_options.results.map((item) => ({
              value: item.id,
              display_name: item.title,
            })),
            "branch_tag"
          ),
      },
    ].filter((item) => !!item);
  };

  updateRouterState(url, state) {
    this.props.routerStateAction.setRouterState(state);
    this.props.history.push(url);
  }

  render() {
    const {
      google,
      loaded,
      location: { search },
      overview: { stats, list },
      dashboard: { open_tickets, openTicketApiCalled, word_cloud, worldCloudApiCalled  },
      match: {
        params: { business_id },
      },
      history,
      business_userq,
      lockedModules,
      location,
      topBottomApiCalled,
      topLocations,
      bottomLocations,
      categoryPerformanceApiCalled,
    } = this.props;

    const {
      // activeShape,
      // showInfoWindow,
      // shapeEvent,
      data,
      tabKey,
      displayLocationOverview,
      // ratingData,
      reviewPerformanceData,
      // sentimentLoad,
      secondDataLoad,
      openTicketLoader,
      categoryPerfomanceLoader,
      topBottomLocationLoader,
      // worldCloudLoader,
    } = this.state;
    const service_category = cookie.load("service_category");

    const user = cookie.load("business_user") || business_userq;

    // const getMarkerColor = (rating) => {
    //   if (rating >= 0 && rating <= 2.5) return "red";
    //   else if (rating > 2.5 && rating <= 3.5) return "blue";
    //   else return "green";
    // };

    const getFillColor = (avgRating) => {
      if (avgRating < 1) return startRatingColors[0];
      if (avgRating < 2) return startRatingColors[1];
      if (avgRating < 3) return startRatingColors[2];
      if (avgRating < 4) return startRatingColors[3];
      return startRatingColors[4];
    };
    const filterList = this.handleFilterList();

    const totalSentiments = reviewPerformanceData?.sentiment_performance
      ? reviewPerformanceData?.sentiment_performance?.positive +
        reviewPerformanceData?.sentiment_performance?.neutral +
        reviewPerformanceData?.sentiment_performance?.negative
      : 0;

    const sentimentData = [
      {
        img: positiveDot,
        text: "Positive",
        rate: reviewPerformanceData?.sentiment_performance
          ? reviewPerformanceData?.sentiment_performance?.positive
          : 0,
        percent: reviewPerformanceData?.sentiment_performance?.positive
          ? (
              (reviewPerformanceData?.sentiment_performance?.positive /
                totalSentiments) *
              100
            ).toFixed(2) + "%"
          : "0%",
      },
      {
        img: negativeDot,
        text: "Negative",
        rate: reviewPerformanceData?.sentiment_performance
          ? reviewPerformanceData?.sentiment_performance?.negative
          : 0,
        percent: reviewPerformanceData?.sentiment_performance?.negative
          ? (
              (reviewPerformanceData?.sentiment_performance?.negative /
                totalSentiments) *
              100
            ).toFixed(2) + "%"
          : "0%",
      },
      {
        img: neutralDot,
        text: "Neutral",
        rate: reviewPerformanceData?.sentiment_performance
          ? reviewPerformanceData?.sentiment_performance?.neutral
          : 0,
        percent: reviewPerformanceData?.sentiment_performance?.neutral
          ? (
              (reviewPerformanceData?.sentiment_performance?.neutral /
                totalSentiments) *
              100
            ).toFixed(2) + "%"
          : "0%",
      },
    ];

    const dateType = queryString.parse(this.props.location.search).date_type;
    const params = this.props.location.search;

    return (
      <>
        {/* {this.state.firstDataLoad && (
          <LoaderComponent loader={this.state.firstDataLoad} fullScreen />
        )} */}
        <Row className="content-area dashboard-page">
          <Col xs={12} sm={12} md={12} className="business-setup py-4">
            <Box sx={{ justifyContent: "space-between", display: "flex" }}>
              {tabKey !== "mapview" && (
                <FPSwitch
                  id="1"
                  yesLabel="Dashboard"
                  defaultValue={tabKey === "dashboard" ? false : true}
                  noLabel="Feeds"
                  onChange={this.handleTabChange}
                />
              )}
              {tabKey === "dashboard" && (
                <div className="d-flex gap-15">
                  <FPToolTip title="Map View">
                    <img
                      src={MapIcon}
                      height="40px"
                      width="40px"
                      className="cursor-pointer"
                      onClick={() => this.toggleTab("mapview")}
                      alt="map"
                    />
                  </FPToolTip>
                  <FPToolTip title="Print">
                    <img
                      src={PrintIcon}
                      height="40px"
                      width="40px"
                      onClick={this.printDashboard}
                      className="cursor-pointer"
                      alt="print"
                    />
                  </FPToolTip>
                </div>
              )}
            </Box>
            <TabContent activeTab={tabKey}>
              {/* //*Dashboard view */}
              <TabPane tabId="dashboard">
                {/* //* Filter */}
                <FPFilter
                  data={filterList}
                  className="mt-4"
                  history={history}
                  getData={this.fetchData}
                />

                <main className="custom-container">
                  <Box
                    sx={{ width: "60%", flexGrow: "1" }}
                    className="d-flex flex-column"
                  >
                    <section className="mb-4">
                      <CardWrapper
                        headerTitle={
                          <div className="d-flex align-items-center gap-6">
                            {this.state.firstDataLoad ? (
                              <Skeleton width="50%" height={20} />
                            ) : (
                              <span>
                                Overall Brand Sentiment{" "}
                                <Box
                                  component="span"
                                  className="dash-text"
                                  sx={{ fontSize: "15px" }}
                                >
                                  ({totalSentiments})
                                </Box>
                              </span>
                            )}
                          </div>
                        }
                        widthElevation={true}
                      >
                        {this.state.firstDataLoad ? (
                          <div className="align-center gap-15">
                            {/* <Skeleton width="50%" height={20} /> */}
                            <div className="align-center col-12 p-0">
                              {[1, 2, 3].map((_, index) => (
                                <div
                                  key={index}
                                  className="align-center gap-6 col-4 pl-0 col-4 flex-wrap"
                                >
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={20}
                                  />
                                  <Skeleton width="30%" height={18} />
                                  <Skeleton width="20%" height={15} />
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div className="align-center gap-15">
                            <div className="align-center col-12 p-0">
                              {sentimentData?.map((brand, index) => (
                                <div
                                  key={index}
                                  className="align-center gap-6 col-4 pl-0 col-4 flex-wrap"
                                >
                                  <Box
                                    component="img"
                                    src={brand.img}
                                    sx={{ width: "20px", height: "20px" }}
                                  />
                                  <Box
                                    className="dash-text"
                                    sx={{ fontSize: "15px" }}
                                  >
                                    {brand.text} &nbsp;({brand.rate})
                                  </Box>
                                  <Box
                                    className="dashboard_sub_caption"
                                    sx={{ fontSize: "13px" }}
                                  >
                                    {brand.percent}
                                  </Box>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </CardWrapper>
                    </section>

                    <div className="d-flex gap-20 w-100 fp-flex-grow-1">
                      <section className="fp-dashboard-left">
                        <CardWrapper
                          widthElevation={true}
                          headerTitle={
                            <div className="d-flex align-items-center gap-6">
                              {this.state.firstDataLoad ? (
                                <Skeleton width="50%" height={22} />
                              ) : (
                                <>
                                  <span>Public Reviews</span>
                                  <FPToolTip
                                    arrow={true}
                                    theme={"dark"}
                                    maxWidth={150}
                                    margin="5px 0px"
                                    placement={"bottom"}
                                    title={
                                      "Reviews which are visible to your customers e.g. Google"
                                    }
                                  >
                                    <div>
                                      <InfoOutlinedIcon
                                        style={{
                                          color: "#646565",
                                          height: "18px",
                                          width: "18px",
                                        }}
                                      />
                                    </div>
                                  </FPToolTip>
                                </>
                              )}
                            </div>
                          }
                          onHeaderClick={() =>
                            this.redirectTo("reviews", stats.ratings.provider)
                          }
                          className="dashboard-review-card"
                        >
                          {this.state.firstDataLoad ? (
                            <div className="">
                              <div className="d-flex align-items-start gap-15">
                                <Skeleton width="100%" height={55} />
                                <Skeleton width="100%" height={55} />
                              </div>
                            </div>
                          ) : (
                            <ThirdPartyReview
                              data={stats && stats.social}
                              filters={queryString.parse(search)}
                            />
                          )}
                        </CardWrapper>
                        <ReviewSummary
                          key={`${dateType}-${service_category}`}
                          data={
                            stats &&
                            stats.ratings &&
                            Object.keys(stats.ratings.data).map((key) => ({
                              stars: +key,
                              ratings: stats.ratings.data[key],
                            }))
                          }
                          avg={
                            stats &&
                            stats.ratings &&
                            stats.ratings.avg.toFixed(1)
                          }
                          total={
                            stats &&
                            stats.ratings &&
                            (stats.ratings.total
                              ? +stats.ratings.total
                              : 0
                            ).toLocaleString()
                          }
                          businessId={business_id}
                          ratingData={stats && stats.count_avg}
                          loader={this.state.firstDataLoad}
                        />
                      </section>

                      <section className="fp-dashboard-left">
                        <CardWrapper
                          widthElevation={true}
                          headerTitle={
                            <div className="d-flex align-items-center gap-6">
                              {this.state.firstDataLoad ? (
                                <Skeleton width="50%" height={22} />
                              ) : (
                                <>
                                  <span>Private Reviews</span>
                                  <FPToolTip
                                    arrow={true}
                                    theme={"dark"}
                                    maxWidth={150}
                                    margin="5px 0px"
                                    placement={"bottom"}
                                    title={
                                      "Reviews which are internal to brand e.g. QR Code/Survey/Paper Feedback"
                                    }
                                    // open={true}
                                  >
                                    <InfoOutlinedIcon
                                      style={{
                                        color: "#646565",
                                        height: "18px",
                                        width: "18px",
                                      }}
                                    />
                                  </FPToolTip>
                                </>
                              )}
                            </div>
                          }
                          onHeaderClick={() =>
                            this.redirectTo(
                              "reviews",
                              stats.first_party_data.provider
                            )
                          }
                          className="dashboard-review-card"
                        >
                          {this.state.firstDataLoad ? (
                            <div className="">
                              <div className="d-flex align-items-start gap-15">
                                <Skeleton width="100%" height={55} />
                                <Skeleton width="100%" height={55} />
                              </div>
                            </div>
                          ) : (
                            <FirstPartyReview
                              data={stats && stats.first_party_data}
                              filters={queryString.parse(search)}
                            />
                          )}
                        </CardWrapper>

                        <ReviewPieChart
                          search={search}
                          businessId={business_id}
                          key={dateType}
                          loader={this.state.firstDataLoad}
                          fetchData={this.totalReviews}
                          data={this.state.totalReviews}
                        />
                      </section>
                    </div>
                  </Box>
                  <div className="fp-slider">
                    <ReviewChannels 
                    data={stats && stats.social}
                    history={this.props.history}
                    redirectTo={this.redirectTo}
                    loader={this.state.firstDataLoad}
                    />
                  </div>
                </main>
                {service_category === "Restaurant" && (
                  <Row className="dashboard-cards mt-0 mb-0 p-0">
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="6"
                      className="mt-4 pr-md-4 pl-0 pr-0"
                    >
                      <ComplaintsCard
                        business_id={business_id}
                        key={dateType}
                        params={params}
                        categoryPerformanceData={
                          this.state.categoryPerformanceData
                        }
                        fetchComplainData={this.fetchComplainData}
                        loader={this.state.firstDataLoad}
                      />
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="6" className="mt-4 p-0">
                      <MenuPerformanceCard
                        business_id={business_id}
                        updateRouterState={this.updateRouterState}
                        fetchMenuPerformanceData={this.fetchMenuPerformanceData}
                        menuPerformanceData={this.state.menuPerformanceData}
                        key={dateType}
                        params={params}
                        isLoading={this.state.firstDataLoad}
                      />
                    </Col>
                  </Row>
                )}

                <CategoryPerformanceCard
                  business_id={business_id}
                  updateRouterState={this.updateRouterState}
                  key={dateType}
                  params={params}
                  apiRes={this.props.categoryData}
                  fetchTagData={(query_params, isDateFilter=false) => this.fetchTagData(query_params, isDateFilter)}
                  categoryPerformanceApiCalled={categoryPerformanceApiCalled}
                  loading={categoryPerfomanceLoader || secondDataLoad}
                />

                <Row className="dashboard-cards mt-0 mb-0 p-0">
                  <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    className="mt-4 pr-md-4 pl-0 pr-0"
                  >
                    <NetScoreCard
                      data={stats && stats.promoter}
                      businessId={business_id}
                      key={dateType}
                      loading={secondDataLoad}
                    />
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="6" className="mt-4 p-0">
                    <CardWrapper
                      headerTitle="Last 10 Days Sentiments"
                      headerStyle={{ marginTop: "2px" }}
                      className="h-100"
                      widthElevation={true}
                      skeletonLoader={secondDataLoad}
                      redirectionLink={`/${business_id}/reports/review-section/sentiment/overtime/graph?date_type=prior_30_day&list=date`}
                    >
                      <ComcastSentiment
                        key={business_id}
                        businessId={business_id}
                        filters={search}
                        loading={secondDataLoad}
                      />
                    </CardWrapper>
                  </Col>
                </Row>

                {lockedModules && lockedModules.ticket && (
                  <CardWrapper
                    headerTitle={
                      <div className="d-flex w-full align-items-center gap-6 justify-content-between">
                        <p className="m-0">Ticket Status Summary</p>
                        <FPToolTip
                          arrow={true}
                          theme={"dark"}
                          maxWidth={150}
                          margin="5px 0px"
                          placement={"bottom"}
                          title={"Refresh Data"}
                        >
                          {secondDataLoad ? <CircularProgress size={22} style={{ color: 'deeppink', marginTop: '2px'}}/>
                            :<IoMdRefresh 
                              size={23} 
                              style={{ color: 'deeppink', cursor: 'pointer' }}
                              onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'}
                              onClick={(e) => {
                                e.currentTarget.style.transform = 'rotate(180deg)'
                                this.fetchOpenTickets(queryString.parse(params))
                              }}
                            /> 
                          }
                        </FPToolTip>
                      </div>
                    }
                    className="mt-4"
                    widthElevation={true}
                    skeletonLoader={secondDataLoad}
                    redirectionLink={`/${business_id}/reports/tickets-section/tickets/assignees/table`}
                  >
                    <OpenTicketTable
                      data={open_tickets}
                      openTicketApiCalled={openTicketApiCalled}
                      dataLoad={secondDataLoad || openTicketLoader}
                      businessId={business_id}
                      fetchOpenTickets={() => this.fetchOpenTickets(queryString.parse(params))}
                    />
                  </CardWrapper>
                )}

                <Row className="dashboard-cards mt-0 mb-0 p-0">
                  <Col
                    xs="12"
                    sm="12"
                    md="6"
                    lg="6"
                    className="mt-4 pr-md-4 pl-0 pr-0"
                  >
                    <ResponseChart
                      businessId={business_id}
                      params={params}
                      key={dateType}
                      data={this.state.responseChartData}
                      fetchResponseChartData={this.fetchResponseChartData}
                      loader={secondDataLoad}
                    />
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="6" className="mt-4 p-0">
                    <SectionBarChart
                      businessId={business_id}
                      params={params}
                      key={dateType}
                      data={this.state.SectionBarChartData}
                      barHeadData={this.state.barHeadData}
                      fetchSectionBarChartData={this.fetchSectionBarChartData}
                      loader={secondDataLoad}
                    />
                  </Col>
                </Row>

                {user &&
                  user.business_type === "enterprises" &&
                  displayLocationOverview && (
                    <LocationListComponent
                      key={business_id}
                      loaded={loaded}
                      locations={this.handleBranchChange}
                      google={google}
                      toggleTab={this.toggleTab}
                      businessId={business_id}
                      location={location}
                      loader={secondDataLoad || topBottomLocationLoader}
                      topLoc={topLocations}
                      params={params}
                      fetchTopBottomLocation={(query_params) => this.topLocationData(query_params)}
                      topBottomApiCalled={topBottomApiCalled}
                      worstLoc={bottomLocations}
                    />
                  )}

                <CardWrapper
                  headerTitle={
                    <>
                      <div className="d-flex justify-content-between align-items-center px-3">
                        <p className="d-flex gap-4">
                          Trending Keywords (
                          <span style={{ fontStyle: "italic" }}>
                            For Last {this.state.keyWordDays || 30} Days
                          </span>
                          )
                          <FPToolTip
                            arrow={true}
                            theme={"dark"}
                            maxWidth={150}
                            margin="5px 0px"
                            placement={"bottom"}
                            title={"Refresh Data"}
                          >
                            <IoMdRefresh 
                              size={23} 
                              style={{ color: 'deeppink', cursor: 'pointer', transition: 'transform 0.3s ease-in-out', }}
                              onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'} 
                              onClick={(e) => {
                                e.currentTarget.style.transform = 'rotate(180deg)'
                                this.fetchWordCloud(queryString.parse(params));
                              }}
                            /> 
                          </FPToolTip>
                        </p>
                        <div className="position-relative justify-content-md-end">
                          <TrendingDateSelector
                            onChange={(item) => {
                              this.setState({ keyWordDays: item.day });
                            }}
                            className="chart-date-filter"
                          />
                        </div>
                      </div>
                    </>
                  }
                  skeletonLoader={secondDataLoad}
                  className="mt-4"
                  onDateChange={() => {}}
                  widthElevation={true}
                  isRefreshButton={true}
                  handleRefreshButton={() => this.fetchWordCloud(queryString.parse(params))}
                >
                  <WordCloudComponent
                    data={word_cloud}
                    worldCloudApiCalled={worldCloudApiCalled}
                    dataLoad={this.state.wordCloud_dataLoad}
                    fetchWordCloud={() => this.fetchWordCloud(queryString.parse(params))}
                  />
                </CardWrapper>
              </TabPane>

              <TabPane tabId="feeds">
                <div>
                  <h1>feeds</h1>
                </div>
              </TabPane>

              {/* //*Map view */}
              <TabPane tabId="mapview">
                <Box
                  role="button"
                  onClick={() => this.toggleTab("dashboard")}
                  sx={{
                    color: "#1B1C1D",
                    display: "inline-flex",
                    alignItems: "center",
                    "&:hover": {
                      color: "#F13A88",
                    },
                    "& p": {
                      lineHeight: 1,
                    },
                  }}
                >
                  <ArrowBackIosIcon
                    style={{
                      width: "18px",
                      height: "18px",
                    }}
                  />
                  <Box component={"p"} className="fp-size-14">
                    Go to dashboard
                  </Box>
                </Box>
                <Row className="mt-4">
                  <Col xs={12} sm={12} md={12} className="mb-3">
                    <Card fluid>
                      <Card.Content>
                        <Card.Header>
                          <span>Map</span>
                        </Card.Header>
                        <Card.Description>
                          <div style={{ height: "700px" }}>
                            {loaded && tabKey === "mapview" && (
                              <Map
                                google={google}
                                zoom={5}
                                locations={list ? list : []}
                                onClick={this.onMapClicked}
                                initialCenter={
                                  list && list.length > 0
                                    ? this.getCenterFromList(list)
                                    : { lat: 41.38068, lng: 1.082409 }
                                }
                                style={{ height: "100%", width: "100%" }}
                              >
                                {list &&
                                  list.map((item) => {
                                    const avgRating = item.avg_rating;
                                    const fillColor = getFillColor(avgRating);

                                    const svgMarker = {
                                      path: "M13 0C19.6274 0 25 5.37258 25 12C25 14.866 23.9464 17.567 22.0711 19.6569L13 32L3.92893 19.6569C2.05361 17.567 1 14.866 1 12C1 5.37258 6.37258 0 13 0ZM13 7.5C10.5147 7.5 8.4375 9.57725 8.4375 12.0625C8.4375 14.5478 10.5147 16.625 13 16.625C15.4853 16.625 17.5625 14.5478 17.5625 12.0625C17.5625 9.57725 15.4853 7.5 13 7.5Z",
                                      fillColor: fillColor,
                                      fillOpacity: 1,
                                      strokeWeight: 2,
                                      rotation: 0,
                                      scale: 1,
                                      anchor: new google.maps.Point(13, 32),
                                      strokeColor: "#1B1C1D",
                                      strokeOpacity: 0.7,
                                    };
                                    return (
                                      <Marker
                                        onClick={(props, marker) =>
                                          this.onMarkerClick(
                                            props,
                                            marker,
                                            item
                                          )
                                        }
                                        position={{
                                          lat:
                                            item.lat_long.coordinates &&
                                            item.lat_long.coordinates[1],
                                          lng:
                                            item.lat_long.coordinates &&
                                            item.lat_long.coordinates[0],
                                        }}
                                        title={item.branch_name}
                                        name={item.branch_name}
                                        key={item.id}
                                        icon={svgMarker}
                                      />
                                    );
                                  })}
                                <InfoWindow
                                  marker={this.state.activeMarker}
                                  visible={this.state.showingInfoWindow}
                                >
                                  <div>
                                    {data.branch_name && (
                                      <a
                                        className="fp-size-16 text-underline font-dark weight-500"
                                        href={`/${business_id}/reviews?branch=${data.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Branch: {data.branch_name}
                                      </a>
                                    )}
                                    {data.no_of_review?.toString() && (
                                      <p className="fp-size-16 font-gray mt-2">
                                        Total Reviews: {data.no_of_review}
                                      </p>
                                    )}
                                    {data.avg_rating?.toString() && (
                                      <div className="d-flex align-items-center">
                                        <p className="fp-size-14 font-gray mr-2 mb-0 line-height-1">
                                          Avg Rating:{" "}
                                          {data.avg_rating.toFixed(2)}
                                        </p>
                                        <CustomStarRatingComponent
                                          name="rate"
                                          starCount={1}
                                          value={data.avg_rating || 1}
                                          editing={false}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </InfoWindow>
                              </Map>
                            )}
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </>
    );
  }
}

DashboardPage.propTypes = {
  OverviewActions: PropTypes.instanceOf(Object),
  overview: PropTypes.instanceOf(Object),
  businessActions: PropTypes.instanceOf(Object),
  filterAction: PropTypes.instanceOf(Object),
};

const DashboardComponent = reduxForm({
  form: "dashboardForm",
})(DashboardPage);

const mapStateToProps = (state) => {
  return {
    overview: state.overview,
    business_userq: state.profile.business_user,
    lockedModules: state.business.lockedModules,
    filter: state.filter,
    common: state.common,
    source: state.review.review_source_options,
    teamList: state.team,
    dashboard: state?.dashboard || {},
    categoryData: state?.dashboard?.categoryPerformance || {},
    categoryPerformanceApiCalled: state?.dashboard?.categoryPerformanceApiCalled || false,
    topLocations: state?.dashboard?.topLocations || [],
    bottomLocations: state?.dashboard?.bottomLocations || [],
    topBottomApiCalled: state?.dashboard?.topBottomApiCalled || false
  };
};

function mapDispatchToProps(dispatch) {
  return {
    OverviewActions: bindActionCreators(OverviewActions, dispatch),
    businessActions: bindActionCreators(BusinessActions, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
    actions: bindActionCreators(setSelectedTab, dispatch),
    routerStateAction: bindActionCreators(routerStateAction, dispatch),
    commonActions: bindActionCreators(commonAction, dispatch),
    review_actions: bindActionCreators(ReviewsActions, dispatch),
    teamActions: bindActionCreators(TeamAction, dispatch),
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(DashboardComponent);

export default GoogleApiWrapper({
  apiKey: "AIzaSyDtiQSYOJ6tdOSXoM6ZWbpNAOcQgmLWDxY",
  libraries: ["places", "drawing"],
  version: 3.34,
  // LoadingContainer: <LoaderComponent />
})(withConnect);
