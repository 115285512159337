import React from "react";
import { FPTab } from "../../components/common/new/tabs";
import { connect } from "react-redux";
import { Col } from "reactstrap";

const RootHeader = ({ defaultValue, businessProfile, history }) => {
  const redirectTo = (url) => {
    setTimeout(() => {
      history.push(url);
    }, 400);
  };

  const handleRootTabChange = (value) => {
    switch (value) {
      case 0:
        redirectTo(`/${businessProfile.id}/campaigns/review/list?page_size=10`);
        break;
      case 1:
        redirectTo(`/${businessProfile.id}/${businessProfile.cooprate_branch}/templates/list?page_size=10`);
        break;
      default:
        break;
    }
  };

  return (
    <Col className={"mb-4"}>
      <FPTab
        tabLabels={["Campaigns", "Templates"]}
        minTabWidth={220}
        defaultValue={defaultValue}
        onChange={(value) => handleRootTabChange(value)}
      />
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    businessProfile: state.business.detail,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(RootHeader);
