import axios_instance from '../../libs/interseptor';
import * as types from '../actionTypes';
import * as URLS from '../../libs/apiUrls';
import * as commonAction from '../common/commonAction';

export function loginSuccess(data) {
  return { type: types.LOGIN_SUCCESS, data};
}

export function loginFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.LOGIN_FAILURE, data };
}

export const loginAction = (data) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(URLS.LOGIN, data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(loginSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(loginFailure(err));
          throw err.response.data;
        }

      );
  };
};
