import React, { useState } from "react";
import "./styles.css";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import { Collapse, IconButton } from "@material-ui/core";
import { Col, Row } from "reactstrap";

function CustomerHistoryLog({ logs }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div id="complaints-activity-log" className="mb-2 complaints-activity" style={{ borderRadius: "8px" }}>
      <div className="d-flex justify-content-between align-items-center px-3">
        <p className="complaints-icon font-dark mb-0" style={{ fontSize: "16px", color: "#1B1C1D" }}>
          Customer History
        </p>
        <IconButton onClick={() => setIsOpen(!isOpen)} size="small">
          {isOpen ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
        </IconButton>
      </div>
      <Collapse in={isOpen}>
        <div
          id="complaints-activity-log-content"
          style={{ marginTop: "5px", overflowX: "hidden", borderRadius: "5px" }}
        >
          <ul className="complaints-timeline">
            {logs?.map((item, index) => (
              <li key={index}>
                <Row>
                  <Col className="complaints-action-box">
                    <div className="complaints-direction-l">
                      <div className="flag flag-wrapper">
                        <p className="" style={{ color: "#7A8193", fontSize: "12px", marginBottom: "8px" }}>
                          {item?.text ? item?.text : item}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
        </div>
      </Collapse>
    </div>
  );
}

export default CustomerHistoryLog;
