import { Divider } from "@material-ui/core";
import React, { useRef } from "react";
import DateRange from "./DateRange";
import { hasTruthyValue, setDateFormat, snakeToCapital } from "../../../../libs/utils";
import MultiAutoCompleteCheckbox from "./MultiAutoCompleteCheckbox";
import FPChip from "../FPChip/FPChip";
import classNames from "classnames";
import FPBadge from "../FPBadge/FPBadge";
import ActionButton from "./ActionButton";

const SelectedItems = (props) => {
  const {
    allFilterList,
    currentOpenFilterItem,
    formikConfig,
    isSelectAll,
    setIsSelectAll,
    ticketFilter,
    applyBtnText,
    hideResetBtn,
    onResetClick,
    actionRender,
    lightResetBtn,
    locationFilter,
    setCurrentOpenFilterItem,
    withContent,
    ReviewWidgetFilter,
    setSearchQuery,
    searchQuery
  } = props;

  

  const handleDateRange = (value, formikConfig) => {
    const { setFieldValue } = formikConfig;
    setFieldValue("date_type", [
      {
        display_name: `${setDateFormat(value.start_date)} to ${setDateFormat(value.end_date)}`,
        parentKey: "date_type",
        value: value.date_type,
      },
    ]);

    setFieldValue("start_date", [
      {
        display_name: "Start Date",
        parentKey: "date_type",
        value: value.start_date,
      },
    ]);

    setFieldValue("end_date", [
      {
        display_name: "End Date",
        parentKey: "date_type",
        value: value.end_date,
      },
    ]);
  };

  const handleFilterChange = (values, formikConfig) => {
    const { setFieldValue } = formikConfig;
    if (values?.length && values[0] && values[0].parentKey === "date_type" && values[0].value === "other") {
      setFieldValue("start_date", []);
      setFieldValue("end_date", []);
    }
  };
    if (ReviewWidgetFilter && !withContent) {
      const { setFieldValue, getFieldMeta } = formikConfig;

      const maxCharValue = getFieldMeta("max_char_len").value || [];
      const minCharValue = getFieldMeta("min_char_len").value || [];

      if (maxCharValue.length > 0 || minCharValue.length > 0) {
        setFieldValue("max_char_len", []);
        setFieldValue("min_char_len", []);
      }
    } 

  const handleRemove = ({ selectedFilterItem, formikConfig }) => {
    const { getFieldMeta, setFieldValue } = formikConfig;
    const { value } = getFieldMeta();
    if (selectedFilterItem.parentKey === "date_type" && selectedFilterItem.value === "other") {
      setFieldValue("date_type", []);
      setFieldValue("start_date", []);
      setFieldValue("end_date", []);
    } else if (locationFilter && selectedFilterItem.parentKey === "country") {
      setCurrentOpenFilterItem(null);
      setFieldValue("country", []);
      setFieldValue("state", []);
    } else if (
      locationFilter &&
      selectedFilterItem.parentKey === "provider" &&
      currentOpenFilterItem?.key === "status"
    ) {
      setCurrentOpenFilterItem(null);
      setFieldValue("provider", []);
      setFieldValue("status", []);
    } else if (locationFilter && selectedFilterItem.parentKey === "provider" && value.status.length > 0) {
      setCurrentOpenFilterItem(null);
      setFieldValue("provider", []);
      setFieldValue("status", []);
    } else {
      const parentKey = selectedFilterItem.parentKey;
      const newData = value[parentKey]?.filter((filter) => filter?.value !== selectedFilterItem?.value);
      setFieldValue(parentKey, newData);
    }
  };

  const unCheckedCheckbox = (selectedFilterItem, handleCheckboxChange) => {
    allFilterList.find((filter) => filter.key === selectedFilterItem.parentKey);
    handleCheckboxChange(selectedFilterItem);
  };
  const getStatusColor = (status) => {
    const statusColor = {
      in_queue: "#5826ef",
      new: "#2375ef",
      assigned: "#f8ae00",
      resolved: "#17b26a",
      reopened: "#e16b16",
      waiting_for_customer: "#FFDC54",
      new: "#228B22",
      repeat: "#f8ae00",
      lapsed: "#e16b16",
    };
    return statusColor[status];
  };

  const multiCheckRef = useRef();

  const isCountrySelected = formikConfig.values.country && formikConfig.values.country.length > 0;
  return (
    <React.Fragment>
      <Divider className="mt-2 mb-2" />
      {allFilterList &&
        allFilterList.map((filterItem, index) => (
          <div
            key={`${index}-fp-filter-MultiAutoCompleteCheckbox`}
            style={{
              display: currentOpenFilterItem?.key === filterItem.key ? "block" : "none",
            }}
          >
            {currentOpenFilterItem?.key === "date_type" && (
              <DateRange onChange={(value) => handleDateRange(value, formikConfig)} className="filter-data-range" />
            )}
            {filterItem.key === "state" && locationFilter ? (
              isCountrySelected && filterItem?.list?.length > 0 ? (
                <MultiAutoCompleteCheckbox
                  isSearchRealTime={filterItem.isSearchRealTime}
                  handleSearchApi={filterItem.handleSearchApi}
                  options={filterItem.list}
                  isSearchable={filterItem.isSearchable}
                  isNotEllipsisLabel={filterItem.isNotEllipsisLabel}
                  type={filterItem.type}
                  name={filterItem.key}
                  onChange={(value) => handleFilterChange(value, formikConfig)}
                  isSelectAll={isSelectAll}
                  setIsSelectAll={setIsSelectAll}
                  ticketFilter={ticketFilter}
                  ref={multiCheckRef}
                  currentOpenFilterItem={currentOpenFilterItem}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
              ) : (
                <span>No option Available</span>
              )
            ) : ( filterItem.isSearchRealTime ? true : filterItem?.list?.length > 0 ) ? (
              <MultiAutoCompleteCheckbox
                isSearchRealTime={filterItem.isSearchRealTime}
                handleSearchApi={filterItem.handleSearchApi}
                options={filterItem.list}
                isSearchable={filterItem.isSearchable}
                isNotEllipsisLabel={filterItem.isNotEllipsisLabel}
                type={filterItem.type}
                name={filterItem.key}
                onChange={(value) => handleFilterChange(value, formikConfig)}
                isSelectAll={isSelectAll}
                setIsSelectAll={setIsSelectAll}
                ticketFilter={ticketFilter}
                ref={multiCheckRef}
                currentOpenFilterItem={currentOpenFilterItem}
                searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
              />
            ) : (
              <span>No option Available</span>
            )}

            <Divider className="my-2" />
          </div>
        ))}

      <div className="align-center justify-content-between">
        <div>
          {hasTruthyValue(formikConfig.values) && (
            <section className="gap-10 d-flex flex-wrap cancleBtn">
              {Object.keys(formikConfig.values).map(
                (key) => {
                  let formikConfigValues = formikConfig?.values[key]
                  if (formikConfigValues && Array.isArray(formikConfigValues)) {
                    formikConfigValues = formikConfigValues?.filter((item, index, self) => 
                      index === self.findIndex((obj) => obj.display_name === item.display_name)
                    );
                  }
                  return (key !== "start_date" &&
                  key !== "end_date" &&
                  key &&
                  Array.isArray(formikConfigValues) && formikConfigValues?.map((selectedFilterItem, index) => {
                    return (
                      <FPChip
                        key={`${index}-selected-filter-list`}
                        avatar={
                          selectedFilterItem.parentKey === "status" && ticketFilter === "TICKET" ? (
                            <FPBadge className="pr-1" color={getStatusColor(selectedFilterItem.value)} size="11" />
                          ) : (
                            <></>
                          )
                        }
                        label={snakeToCapital(selectedFilterItem.display_name)}
                        iconColor={selectedFilterItem.parentKey === currentOpenFilterItem?.key && "#F13A88"}
                        variant="outlined"
                        size="small"
                        onDelete={() => {
                          handleRemove({
                            selectedFilterItem,
                            formikConfig,
                          });
                          multiCheckRef.current?.setIsSelectAll(false);
                          unCheckedCheckbox(selectedFilterItem, multiCheckRef.current?.handleCheckboxChange);
                        }}
                        className={classNames(
                          {
                            highlight: selectedFilterItem.parentKey === currentOpenFilterItem?.key,
                          },
                          "filterFPLable"
                        )}
                      />
                    );
                  }))
                }
              )}
            </section>
          )}
        </div>
        <div className="">
          <ActionButton
            applyBtnText={applyBtnText}
            hideResetBtn={hideResetBtn}
            onResetClick={onResetClick}
            actionRender={actionRender}
            lightResetBtn={!!lightResetBtn}
            applyButtonDisable={formikConfig?.values?.date_type ? !!(formikConfig?.values?.date_type?.length > 0) : true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SelectedItems;
