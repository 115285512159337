import React, { useEffect, useState } from "react";
import FormModal from "./FormModal";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import queryString from "query-string";
import TagIcon from "../../images/svg/Tag.svg";
import FPLabel from "./new/forms/ui/FPLabel";
import { Col, Row } from "reactstrap";
import { FPButton } from "./new";
import { displayMessage } from "../../libs/utils";
import VirtualizeAutoSelect from "./VirtualizeAutoSelect";
import useDebounce from "../../libs/hooks/useDebounce";
import { AutoComplete } from 'antd';

const FameAiTagModal = (props) => {
  const { open, toggle, business_id, selectedTag, location, getData, isNewTag, reviewId, tagOptions, getTagDetails } =
    props;
  const [groupOptions, setGroupOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [aspectOptions, setAspectOptions] = useState([]);
  const [attributeOptions, setAttributeOptions] = useState([]);

  const [categorySearchEvent, setCategorySearchEvent] = useState("");
  const [aspectSearchEvent, setAspectSearchEvent] = useState("");
  const [attributeSearchEvent, setAttributeSearchEvent] = useState("");

  const [tagDetail, setTagDetails] = useState(null);
  const [groupValue, setGroupValue] = useState(
    selectedTag?.tag_category_group_name ? selectedTag?.tag_category_group_name : ""
  );
  const [categoryValue, setCategoryValue] = useState(
    selectedTag?.tag_category_name ? selectedTag?.tag_category_name : ""
  );
  const [aspectValue, setAspectValue] = useState(
    selectedTag?.tag_category_aspect_name ? selectedTag?.tag_category_aspect_name : ""
  );
  const [attributeValue, setAttributeValue] = useState(
    selectedTag?.tag_category_attribute_name ? selectedTag?.tag_category_attribute_name : ""
  );
  const [sentimentValue, setSentimentValue] = useState(selectedTag?.sentiment ? selectedTag?.sentiment : "");

  useEffect(() => {
    setGroupValue(selectedTag?.tag_category_group_name ? selectedTag?.tag_category_group_name : "");
    setCategoryValue(selectedTag?.tag_category_name ? selectedTag?.tag_category_name : "");
    setAspectValue(selectedTag?.tag_category_aspect_name ? selectedTag?.tag_category_aspect_name : "");
    setAttributeValue(selectedTag?.tag_category_attribute_name ? selectedTag?.tag_category_attribute_name : "");
  }, [selectedTag]);

  const sentimentOptions = [
    { value: "positive", display_name: "Positive" },
    { value: "neutral", display_name: "Neutral" },
    { value: "negative", display_name: "Negative" },
  ];

  useEffect(() => {
    const groupSort = tagOptions.groups.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
    );

    const tagSort = {
      groups: groupSort,
    };
    setTagDetails(tagSort);

    setGroupOptions(
      groupSort.map((value) => {
        return { value: value.name, display_name: value.name };
      })
    );
  }, []); // eslint-disable-line

  const handleCategoryApi = (word) => {
    if(word && word !== '') {
      axios_instance.get(`${utils.format(URLS.SEARCH_TAG_CATEGORY, [business_id])}?word=${word}`)
        .then((res) => {
          const options = []
          res.data && res.data.map((value, index) =>  options.push({ id: value.id, value: String(value.name), label: value.name}))
          setCategoriesOptions(options);
        }).catch((err) => console.log(err));
    }
  }

  const handleAspectApi = (word) => {
    if(word && word !== '') {
      axios_instance.get(`${utils.format(URLS.SEARCH_TAG_ASPECT, [business_id])}?word=${word}`)
        .then((res) => {
          const options = []
          res.data && res.data.map((value) =>  options.push({ id: value.id , value: value.name, label: value.name}))
          setAspectOptions(options);
        }).catch((err) => console.log(err));
    }
  }

  const handleAttributeApi = (word) => {
    if(word && word !== '') {
      axios_instance.get(`${utils.format(URLS.SEARCH_TAG_ATTRIBUTE, [business_id])}?word=${word}`)
        .then((res) => {
          const options = []
          res.data && res.data.map((value) =>  options.push({ id: value.id , value: value.name, label: value.name}))
          setAttributeOptions(options);
        }).catch((err) => console.log(err));
    }
  }

  const handleGroupCategorySearch = (value) => {
    setGroupValue(value);
  };

  const handleCategorySearch = (value) => {
    setCategoryValue(value);
  };

  const handleAspectSearch = (value) => {
    setAspectValue(value);
  };

  const handleAttributeSearch = (value) => {
    setAttributeValue(value);
  };

  const handleSentimentSearch = (value) => {
    setSentimentValue(value);
  };

  const debouncedRequestCategory = useDebounce(() => {
    console.log("debound req, ")
    handleCategoryApi(categorySearchEvent);
  }, 1000);
  
  const debouncedRequestAspect = useDebounce(() => {
    handleAspectApi(aspectSearchEvent);
  }, 1000);

  const debouncedRequestAttribute = useDebounce(() => {
    handleAttributeApi(attributeSearchEvent);
  }, 1000);

  const handleAdd = () => {
    const obj = {
      tag_category_group_name: groupValue ? groupValue : "",
      tag_category_name: categoryValue ? categoryValue : "",
      tag_category_aspect_name: aspectValue ? aspectValue : "",
      tag_category_attribute_name: attributeValue ? attributeValue : "",
      sentiment: sentimentValue ? sentimentValue : "neutral",
    };
    if (!isNewTag) {
      axios_instance
        .patch(`${utils.format(URLS.ADD_EDIT_TAG_REVIEW, [business_id])}${selectedTag.id}/`, obj)
        .then((res) => {
          console.log(res);
          getData({
            ...queryString.parse(location.search),
            businessId: business_id,
          });
          displayMessage("positive", "Fam AI Tag Edited Successfully");
          toggle();
          getTagDetails(business_id);
        })
        .catch((err) => {
          console.log(err);
          displayMessage("negative", "Fame AI Tag Edit Failed");
        });
    } else {
      obj["review_id"] = parseInt(reviewId);
      axios_instance
        .post(`${utils.format(URLS.ADD_EDIT_TAG_REVIEW, [business_id])}`, obj)
        .then((res) => {
          console.log(res);
          getData({
            ...queryString.parse(location.search),
            businessId: business_id,
          });
          displayMessage("positive", "Fame AI Tag Created Successfully");
          toggle();
          getTagDetails(business_id);
        })
        .catch((err) => {
          console.log(err);
          displayMessage("negative", "Fame AI Tag Creation Failed");
        });
    }
  };

  console.log(categoriesOptions);

  return (
    <FormModal style={{zIndex:1000}} isOpen={open} showDivider={false} toggle={toggle} heading={"Fame AI Tag"} width="720px">
      <div
        className="top-tag-view"
        style={{
          borderWidth: "1.5px",
          borderColor:
            selectedTag?.sentiment === "positive"
              ? "#81E050"
              : selectedTag?.sentiment === "negative"
              ? "#EC554E"
              : selectedTag?.sentiment === "neutral"
              ? "#FAE25A"
              : "#BFC2CB",
        }}
      >
        <div className="d-flex align-items-center gap-6">
          <img src={TagIcon} alt="Tag Icon" />
          <span style={{ fontSize: "14px", color: "#1B1C1D" }}>Tag</span>
        </div>
        <span
          style={{
            color: "#1B1C1D",
            fontWeight: selectedTag === "" ? "400" : "500",
          }}
        >
          {groupValue && groupValue.trim().length > 0 ? groupValue : "Group Category"}
          &nbsp;- {categoryValue && (typeof(categoryValue)==='string') && categoryValue.trim().length > 0 ? categoryValue : "Category"}
          &nbsp;- {aspectValue && (typeof(aspectValue)==='string') && aspectValue.trim().length > 0 ? aspectValue : "Aspect"}
          &nbsp;- {attributeValue && (typeof(attributeValue)==='string') && attributeValue.trim().length > 0 ? attributeValue : "Attribute"}
        </span>
      </div>
      <div>
        <Row>
          <Col sm="6" className="mt-20">
            <FPLabel isRequired={false}>Group Category</FPLabel>
            <VirtualizeAutoSelect
              placeholder="Group Category"
              options={groupOptions}
              onChange={(event, option) => {
                handleGroupCategorySearch(option);
              }}
              value={groupValue}
            />
          </Col>
          <Col sm="6" className="mt-20">
            <FPLabel isRequired={false}>Category</FPLabel>
            
            <AutoComplete
              style={{ width: '100%', zIndex: 100000 }}
              options={categoriesOptions}
              placeholder="Category"
              onSearch={handleCategorySearch}
              onChange={( option) => {
                handleCategorySearch(option)
                setCategorySearchEvent(option);
                debouncedRequestCategory();
              }}
              value={categoryValue}
            />
          </Col>
          <Col sm="6" className="mt-20">
            <FPLabel isRequired={false}>Aspect</FPLabel>
            <AutoComplete
              style={{ width: '100%', zIndex: 100000 }}
              options={aspectOptions}
              placeholder="Aspect"
              onSearch={handleAspectSearch}
              onChange={( option) => {
                handleAspectSearch(option)
                setAspectSearchEvent(option);
                debouncedRequestAspect();
              }}
              value={aspectValue}
            />
          </Col>
          <Col sm="6" className="mt-20">
            <FPLabel isRequired={false}>Attribute</FPLabel>
            <AutoComplete
              style={{ width: '100%', zIndex: 100000 }}
              options={attributeOptions}
              placeholder="Attribute"
              onSearch={handleAttributeSearch}
              onChange={( option) => {
                handleAttributeSearch(option)
                setAttributeSearchEvent(option);
                debouncedRequestAttribute();
              }}
              value={attributeValue}
            />
          </Col>
          <Col sm="6" className="mt-20">
            <FPLabel isRequired={false}>Sentiment</FPLabel>
            <VirtualizeAutoSelect
              placeholder="Sentiment"
              options={sentimentOptions}
              onChange={(event, option) => {
                handleSentimentSearch(option);
              }}
              value={sentimentValue}
            />
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-end mt-20">
        <FPButton label="Submit" onClick={handleAdd} />
      </div>
    </FormModal>
  );
};

export default FameAiTagModal;
