import * as types from '../../actions/actionTypes';

export default function contact(state=[], action) {
  switch (action.type) {
    case types.TEMPLATE_LIST_SUCCESS:
      return Object.assign({}, state, { list: action.data });
    case types.TEMPLATE_LIST_FAILURE:
      return Object.assign({}, state, action.data);
    case types.GET_TEMPLATE_DETAILS_SUCCESS:
      return Object.assign({}, state, { detail: action.data });
    case types.GET_TEMPLATE_DETAILS_FAILURE:
      return Object.assign({}, state, { detail: action.data });
    case types.POST_TEMPLATE_SUCCESS:
      return Object.assign({}, state, { detail: {...action.data}});
    case types.TEMPLATE_DELETE_SUCCESS:
      return Object.assign({}, state, { detail: {...action.data}});
    case types.TEMPLATE_DELETE_FAILURE:
      return Object.assign({}, state, { detail: {}});
    case types.TEMPLATE_CLONE_SUCCESS:
      return Object.assign({}, state, { detail: {...action.data}});
    case types.TEMPLATE_CLONE_FAILURE:
      return Object.assign({}, state, { detail: {}});
    default:
      return state;
  }
}