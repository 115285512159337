import * as types from '../../actions/actionTypes';


const commonReducer = (state=[], action) => {
  switch (action.type){
    case types.CATEGORY_OPTIONS_SUCCESS:
      return Object.assign({}, state, {'categories': action.data});
    case types.CATEGORY_OPTIONS_FAILURE:
      return action.data;
    case types.BRAND_OPTIONS_SUCCESS:
      return Object.assign({}, state, {'brand_options': action.data});
    case types.BRAND_OPTIONS_FAILURE:
      return action.data;
    case types.RATING_OPTIONS_SUCCESS:
      return Object.assign({}, state, {'ratings': action.data});
    case types.RATING_OPTIONS_FAILURE:
      return action.data;
    case types.REPLY_OPTIONS_SUCCESS:
      return Object.assign({}, state, {'reply_options': action.data});
    case types.REPLY_OPTIONS_FAILURE:
      return action.data;
    case types.TAGS_OPTIONS_SUCCESS:
      return Object.assign({}, state, {'tag': action.data});
    case types.TAGS_OPTIONS_FAILURE:
      return action.data;
    case types.WEB_PORTAL_DROP_DOWN_SUCCESS:
      return Object.assign({}, state, {'web_portal': action.data});
    case types.WEB_PORTAL_DROP_DOWN_FAILURE:
      return action.data;
    case types.SCHEDULE_DROP_DOWN_SUCCESS:
      return Object.assign({}, state, {'schedules': action.data});
    case types.SCHEDULE_DROP_DOWN_FAILURE:
      return action.data;
    case types.SHOW_LOADER:
      return Object.assign({}, state, {'loader': true} );
    case types.HIDE_LOADER:
      return Object.assign({}, state, {'loader': false});
    case types.UPDATE_ROUTE_PARAMS:
      return Object.assign({}, state, {'route_params': { ...action.data }});
    case types.SENTIMENT_OPTIONS_SUCCESS:
      return Object.assign({},state, {'sentiment_options': {...action.data}});
    case types.SENTIMENT_OPTIONS_FAILURE:
      return action.data;
    case types.CITY_OPTIONS_SUCCESS:
      return Object.assign({},state, {'city_options': {...action.data}});
    case types.CITY_OPTIONS_FAILURE:
      return action.data;
    case types.STATE_OPTIONS_SUCCESS:
      return Object.assign({},state, {'state_options': {...action.data}});
    case types.STATE_OPTIONS_FAILURE:
      return action.data;
    case types.COUNTRY_OPTIONS_SUCCESS:
      return Object.assign({},state, {'country_options': {...action.data}});
    case types.COUNTRY_OPTIONS_FAILURE:
      return action.data;
    case types.BRANCH_TAG_OPTIONS_SUCCESS:
      return Object.assign({},state, {'branch_tag_options': {...action.data}});
    case types.BRANCH_TAG_OPTIONS_FAILURE:
      return action.data;
    default:
      return state;
  }
};

export default commonReducer;
