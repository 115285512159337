import React, { useEffect, useRef, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Sector, Text } from "recharts";
import "./overview.css";
import ReactSpeedometer from "react-d3-speedometer";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const OverallHealth = (props) => {
  const matches = useMediaQuery('(min-width:1441px)');
  const [speedometerDimension, setSpeedometerDimension] = useState({
    height: 0,
    width: 0,
  });

  const meterRef = useRef(null);

  useEffect(() => {
    if (meterRef && meterRef.current) {
      const { offsetHeight, offsetWidth } = meterRef.current;
      setSpeedometerDimension({ height: offsetHeight, width: offsetWidth });
    }
  }, [meterRef]);
  return (

    <div className="chart-container">

      <ReactSpeedometer
        height={matches ? 150 : 200}
        width={matches ? 250 : 350}
        minValue={0}
        maxValue={100}
        value={`${props?.health?.overall_progress.toFixed(2)}`}
        needleColor="#1A2544"
        needleHeightRatio={0.6}
        needleTransitionDuration={1000}
        segments={5}
        maxSegmentLabels={5}
        segmentColors={[
          "#ff4545",
          "#ffa534",
          "#ffe234",
          "#b7dd29",
          "#57e32c",
        ]}
        textColor="#1A2544"
        valueTextFontSize="25px"
        valueTextFontWeight="700"
      />
    </div>
  );
};

export default OverallHealth;
