import React from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Row, Col, Card, CardTitle } from "reactstrap";

import NavBar from "../../components/common/NavBar";
import MarketingList from "../../components/marketing/MarketingList";
import MarketingForm from "../../components/marketing/MarketingForm";
import MarketingFilter from "../../components/marketing/MarketingFilter";
import LoaderComponent from "../../components/common/loaderComponent";
import PaginationComponent from "../../components/common/Pagination";

import * as MarketingActions from "../../actions/agency/agencyActions";
import * as CommonActions from "../../actions/common/commonAction";

import FormModal from "../../components/common/FormModal";
// marketing lsiting page
class MarketingListPage extends React.Component {
  constructor(props) {
    super(props);
    this.redirectToBusinessPage = this.redirectToBusinessPage.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      add: false,
    };
    this.addOrEditMarketing = this.addOrEditMarketing.bind(this);
    this.marketingList = this.marketingList.bind(this);
  }

  UNSAFE_componentWillMount() {
    const {
      location: { search },
    } = this.props;
    this.props.actions.marketingList(queryString.parse(search));
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-line
    const {
      location: { search: prevSearch },
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;
    if (prevSearch !== nextSearch) {
      this.props.actions.marketingList(queryString.parse(nextSearch));
    }
  }

  redirectToBusinessPage(businessId, branchId) {
    let url;
    if (!branchId) {
      url = "/" + businessId + "/branch-setup";
    } else {
      url = "/" + businessId + "/" + branchId + "/business-profile";
    }
    this.props.history.push(url);
  }

  toggle(item) {
    const { isOpen } = this.state;
    if (!isOpen) {
      this.props.CommonActions.getCategoryOptions();
    }
    if (!isOpen && item.id) {
      this.setState({ add: false });
      this.props.actions.getMarketingBusiness(item);
    }
    if (item === "add") {
      this.setState({ add: true });
    }
    this.setState({ isOpen: !isOpen });
  }

  addOrEditMarketing(data, id) {
    const { add } = this.state;
    if (add) {
      return this.props.actions.marketingAdd(data).then(() => {
        this.marketingList();
        this.setState({ add: false });
        this.toggle();
      });
    }
    return this.props.actions.marketingEdit(data, id).then(() => {
      this.marketingList();
      this.toggle();
    });
  }

  marketingList(params) {
    this.props.actions.marketingList(params);
  }

  // redirect to business details page
  ToBusinessPage(businessId, branchId) {
    let url;
    if (!branchId) {
      url = "/" + businessId + "/branch-setup";
    } else {
      url = "/" + businessId + "/" + branchId + "/profile";
    }
    this.props.history.push(url);
  }

  render() {
    const { loader, marketing, categoryList, marketingDetail, businessList, business_all_list } = this.props;
    const { isOpen, add } = this.state;
    return (
      <div>
        <Row className="login-bg">
          <LoaderComponent loader={loader} />
          <Col xs={12} className="p-0">
            <NavBar match={this.props.match} history={this.props.history} businessList={business_all_list}>
              <Col xs={12} className="content-area">
                <Row>
                  <Col xs={12} sm={{ size: 10, offset: 1 }} className="business-setup p-3">
                    <Card body className="py-2">
                      <Row className="m-0">
                        <Col sm="8" className="p-0">
                          <CardTitle className="mb-0 fw3 pt-1">Marketing</CardTitle>
                        </Col>
                        <Col sm="4" className="p-0">
                          <MarketingFilter toggle={this.toggle} {...this.props} />
                        </Col>
                      </Row>
                    </Card>
                    <MarketingList
                      marketingList={marketing && marketing.results}
                      redirectToBusinessPage={this.redirectToBusinessPage}
                      toggle={this.toggle}
                    />
                    <PaginationComponent count={marketing && marketing.count} location={this.props.location} />
                  </Col>
                </Row>
              </Col>
            </NavBar>
          </Col>
        </Row>
        {isOpen && (
          <FormModal isOpen={isOpen} toggle={this.toggle} heading={add ? "Add Business" : "Edit Marketing"}>
            <MarketingForm
              addOrEditMarketing={this.addOrEditMarketing}
              categoryList={
                categoryList && categoryList.choices.map((item) => ({ text: item.display_name, value: item.value }))
              }
              initialValues={add ? {} : marketingDetail}
            />
          </FormModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    marketing: state.marketing,
    marketingDetail: state.marketing.detail,
    errors: state.business.errors,
    loader: state.common.loader,
    categoryList: state.common.categories,
    businessList: state.agency.business_list,
    business_all_list: state.agency.business_all_list
  };
};

MarketingListPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  CommonActions: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(MarketingActions, dispatch),
    CommonActions: bindActionCreators(CommonActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MarketingListPage);
