import React, { createContext, useState, useContext } from 'react';

export const SideNavContext = createContext();

export const SideNavProvider = ({ children }) => {
  const [isMenuSelect, setIsMenuSelect] = useState(false);

  const isAccordionTabSelected = () => setIsMenuSelect(true);

  const isAccordionTabNotSelected = () => setIsMenuSelect(false);

  return (
    <SideNavContext.Provider value={{ isMenuSelect, isAccordionTabSelected, isAccordionTabNotSelected }}>
      {children}
    </SideNavContext.Provider>
  );
};

export const useSideNav = () => useContext(SideNavContext);
