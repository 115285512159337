import React, { useState } from "react";
import { useField } from "formik";
import ErrorMessage from "./ui/ErrorMessage";
import FPLabel from "./ui/FPLabel";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./ui/mobile.input.css";

const FormSelect = ({ name, label, isRequired = true, ...rest }) => {
  const [field, meta, helpers] = useField(name);
  const [selectedCountry, setSelectedCountry] = useState("IN");
  const { setValue } = helpers;
  const onChange = (value) => {
    setValue(value);
  };

  return (
    <div className="mb-20">
      <FPLabel isRequired={isRequired} htmlFor={name}>
        {label}
      </FPLabel>
      <div className="position-relative">
        <PhoneInputWithCountrySelect
          {...field}
          id={name}
          placeholder="Enter phone number"
          international
          withCountryCallingCode
          name={name}
          onChange={(value) => onChange(value)}
          onCountryChange={(value) => setSelectedCountry(value)}
          defaultCountry="IN"
          countryCallingCodeEditable={false}
          className={`fp-mobile ${meta.touched && meta.error && "error"}`}
          {...rest}
        />
        {selectedCountry && (
          <span className="mobile-picker-country-name">{selectedCountry}</span>
        )}
      </div>
      {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  );
};
export default FormSelect;
