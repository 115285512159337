import React, { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import { ListDescription } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";
import GoogleIcon from "@mui/icons-material/Google";
import warning_svg from "../../../../../images/warning.svg";
import delete_png from "../../../../../images/delete.png";
import emoji_svg from "../../../../../images/emoji.svg";
import google_svg from "../../../../../images/googlephoto.svg";
import ListingFPInput from "../../../../../components/common/new/Listing/ListingFPInput";
import AddIcon from "@material-ui/icons/Add";
import select_svg from "../../../../../images/select.svg";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import BusinessList from "../BusinessList";
import * as listingUtils from "../../../../../libs/listingToast";

const UpdateAttribute = (props) => {
  const {
    primaryDetail,
    actions,
    attribute_data,
    business_id,
    queryStringData,
    busines_status,
    bulk_update_listing_data,
  } = props;
  const selectReso = 21;
  // const business_id = 4;

  const [selectedItems, setSelectedItems] = useState({});
  const [beforeSelectedItems, setBeforeSelectedItems] = useState([]);
  const [afterSelectedItems, setAfterSelectedItems] = useState({});

  useEffect(() => {
    if (attribute_data) {
      const updatedAttributeData = JSON.parse(JSON.stringify(attribute_data));
      Object.keys(updatedAttributeData).forEach((attribute) => {
        updatedAttributeData[attribute].forEach((item) => {
          if (item.valueType === "REPEATED_ENUM") {
            item.originalSelecte = item.selected;
            item.valueMetadata.forEach((enumItem) => {
              enumItem.selected = false; // Initialize selection state
            });
          } else if (item.valueType === "ENUM") {
            item.originalSelecte = item.selected;
            item.valueMetadata.forEach((enumItem) => {
              enumItem.selected = enumItem.value === item.selected; // Set selected state based on the initial selected value
            });
          } else {
            item.originalSelecte = item.selected;
          }
        });
      });
      console.log(updatedAttributeData, "updatedAttributeData");
      setSelectedItems(updatedAttributeData);
      setAfterSelectedItems(updatedAttributeData);
    }
  }, [attribute_data]);

  const handleChipClickService = (group, index, repeatedEnumIndex = null) => {
    const updatedItems = { ...afterSelectedItems };
    console.log("groupgroupgroupgroupgroupgroupgroupgroup", group);
    console.log("indexindexindexindexindexindexindexindex", index);
    if (repeatedEnumIndex !== null) {
      const item = updatedItems[group][index];
      console.log(
        "itemitemitemitemitemitemitemitemitemitemitemitemitemitem",
        item
      );
      const repeatedEnum = item.valueMetadata[repeatedEnumIndex];
      console.log("repeatedEnumrepeatedEnumrepeatedEnum", repeatedEnum);
      repeatedEnum.selected = !repeatedEnum.selected;
      item.originalSelecte = true;
      item.valueMetadata[repeatedEnumIndex] = repeatedEnum;
    } else {
      const item = updatedItems[group][index];

      updatedItems[group][index] = {
        ...item,
        selected: !item.selected,
      };
    }

    setAfterSelectedItems(updatedItems);
  };

  const handleSingleSelectChipClick = (
    group,
    index,
    repeatedEnumIndex,
    repeatedEnumData
  ) => {
    //only single select chip
    const updatedItems = { ...afterSelectedItems };
    const item = updatedItems[group][index];
    const repeatedEnum = item.valueMetadata[repeatedEnumIndex];
    updatedItems[group][index].valueMetadata = updatedItems[group][
      index
    ].valueMetadata.map((enumItem) => ({
      ...enumItem,
      selected: false,
    }));
    repeatedEnum.selected = !repeatedEnum.selected;
    updatedItems[group][index].valueMetadata[repeatedEnumIndex] = repeatedEnum;
    setAfterSelectedItems(updatedItems);
  };

  const addressString = [
    primaryDetail?.addressLines,
    primaryDetail?.locality,
    primaryDetail?.postalCode,
  ].join(",");

  useEffect(() => {
    actions.getGmbListingAttribute(business_id, selectReso);
  }, [actions, business_id, selectReso]);

  const renderChip = (
    item,
    idx,
    attribute,
    handleChipClickService,
    select_svg
  ) => (
    <Chip
      key={idx}
      label={item.displayName}
      onClick={() => handleChipClickService(attribute, idx)}
      deleteIcon={
        item?.selected ? <img src={select_svg} alt="selectImage" /> : null
      }
      onDelete={
        item?.selected ? () => handleChipClickService(attribute, idx) : null
      }
      variant={item?.selected ? "filled" : "outlined"}
      sx={{
        backgroundColor: item?.selected ? "#bfe0e2" : "#F0F1F3",
        color: item?.selected ? "#00b5ad" : "default",
        "& .MuiChip-deleteIcon": {
          color: item?.selected ? "white" : "default",
        },
        fontSize: "18px",
        fontWeight: "400",
        padding: "12px",
        border: "0",
      }}
    />
  );

  const renderRepeatedEnumChip = (
    repeatedEnum,
    indexenum,
    attribute,
    parentIndex,
    handleChipClickService,
    select_svg,
    valueType
  ) => (
    <Chip
      key={indexenum}
      label={repeatedEnum.displayName}
      onClick={() =>
        valueType === "ENUM"
          ? handleSingleSelectChipClick(
              attribute,
              parentIndex,
              indexenum,
              repeatedEnum
            )
          : handleChipClickService(attribute, parentIndex, indexenum)
      }
      deleteIcon={
        repeatedEnum?.selected ? (
          <img src={select_svg} alt="selectImage" />
        ) : null
      }
      onDelete={
        repeatedEnum?.selected
          ? () => handleChipClickService(attribute, parentIndex, indexenum)
          : null
      }
      variant={repeatedEnum?.selected ? "filled" : "outlined"}
      sx={{
        backgroundColor: repeatedEnum?.selected ? "#bfe0e2" : "#F0F1F3",
        color: repeatedEnum?.selected ? "#00b5ad" : "default",
        "& .MuiChip-deleteIcon": {
          color: repeatedEnum?.selected ? "white" : "default",
        },
        fontSize: "18px",
        fontWeight: "400",
        padding: "12px",
        border: "0",
        marginBottom: "15px",
        marginRight: "15px",
      }}
    />
  );

  const generatePayload = (items) => {
    const payload = [];

    Object.keys(items).forEach((attribute) => {
      items[attribute].forEach((item) => {
        console.log(item, "item");
        if (item.selected === true || item.originalSelecte === true) {
          if (item.valueType === "ENUM") {
            payload.push({
              name: item.parent,
              valueType: item.valueType,
              values: item.valueMetadata
                .filter((enumItem) => enumItem.selected)
                .map((enumItem) => enumItem.value),
            });
          } else if (item.valueType === "REPEATED_ENUM") {
            const selectRepeatEmum = item.valueMetadata.filter(
              (enumItem) => enumItem.selected
            );
            if (selectRepeatEmum?.length > 0) {
              payload.push({
                name: item.parent,
                valueType: item.valueType,
                repeatedEnumValue: {
                  setValues: item.valueMetadata
                    .filter((enumItem) => enumItem.selected)
                    .map((enumItem) => enumItem.value),
                  unsetValues: item.valueMetadata
                    .filter((enumItem) => !enumItem.selected)
                    .map((enumItem) => enumItem.value),
                },
              });
            }
          } else if (item.valueType === "BOOL") {
            payload.push({
              name: item.parent,
              valueType: item.valueType,
              values: [item.selected],
            });
          }
        }
        // else if (item.selected === false || item.valueType === "BOOL") {
        //   payload.push({
        //     name: item.parent,
        //     valueType: item.valueType,
        //     values: [item.selected],
        //   });
        // }
      });
    });

    return payload;
  };

  const updateAttributeHandler = async () => {
    console.log("afterSelectedItems", afterSelectedItems);
    const payload = generatePayload(afterSelectedItems);
    const restodetail = {
      business_id,
      selectReso,
    };
    // console.log("attribute payload", { attributes: payload, type: "updateAttributes" })
    listingUtils?.displayMessage(
      "info",
      "Listing getting updated, It may take a few minutes."
    );

    await actions.getGmbListingBulkUpdateListing(
      {
        attributes: payload,
        location_ids: selectedBusinessCount,
        type: "updateAttributes",
      },

      restodetail
    );
    listingUtils?.displayMessage("close");
    if (bulk_update_listing_data) {
      listingUtils?.displayMessage("positive", "updated Successfully.");
    }
    // listingUtils?.displayMessage(
    //   "info",
    //   "Listing getting updated, It may take a few minutes."
    // );
    // await actions.getGmbListingRefreshListing(business_id, selectReso);

    // listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    // listingUtils?.displayMessage("positive", "Listing updated successfully.");
    // await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };

  const [selectedBusinessList, setSelectedBusinessList] = useState([]);
  const [showBusiness, setShowBusiness] = useState(true);
  const handleHideBusinessScreen = () => {
    setShowBusiness(false);
  };

  const handleShowBusinessScreen = () => {
    setShowBusiness(true);
  };

  const [selectedBusinessCount, setSelectedBusinessCount] = useState([]); // State to hold the selected businesses count
  console.log("==================================", selectedBusinessCount);

  const handleSelectedBusinessCount = (selectBusiness) => {
    setSelectedBusinessCount(selectBusiness);
  };

  return (
    <>
      {showBusiness ? (
        <div>
          <BusinessList
            business_id={business_id}
            busines_status={busines_status}
            queryStringData={queryStringData}
            type="attributes"
            selectedBusinessList={selectedBusinessList}
            onSelectedBusinessCountChange={handleSelectedBusinessCount}
          />
          {selectedBusinessCount?.length > 0 ? (
            <div
              className="d-flex mt-10 mb-15"
              style={{
                display: "flex",
                justifyContent: "end",
                fontWeight: "600",
                fontSize: "24px",
                color: "#1A2544",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "20px",
              }}
            >
              <FPButton label="Next" onClick={handleHideBusinessScreen} />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-9">
            <div className="listing-container">
              <div className="listing-name">
                Select attributes which best describe your business
              </div>
              {Object.keys(selectedItems || {}).map((attribute, index) => {
                if (attribute !== "Place page URLs") {
                  const items = selectedItems[attribute];
                  return (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "#F6F6F8",
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                    >
                      <div style={{ display: "grid", gap: "8px" }}>
                        <div
                          style={{
                            fontWeight: "400",
                            fontSize: "18px",
                            color: "#1B1C1D",
                          }}
                        >
                          {attribute}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "15px",
                          }}
                        >
                          {items?.map((item, idx) => {
                            if (
                              // attribute === "Payments" &&
                              item.valueType === "ENUM" ||
                              item.valueType === "REPEATED_ENUM"
                            ) {
                              return (
                                <div className="enumOption">
                                  <p>{item?.displayName}</p>
                                  {Array.isArray(item?.valueMetadata)
                                    ? item.valueMetadata.map(
                                        (repeatedEnum, indexenum) =>
                                          renderRepeatedEnumChip(
                                            repeatedEnum,
                                            indexenum,
                                            attribute,
                                            idx,
                                            handleChipClickService,
                                            select_svg,
                                            item?.valueType
                                          )
                                      )
                                    : null}
                                </div>
                              );
                            }
                            // else if (item.valueType === "ENUM") {
                            //   return Array.isArray(item?.valueMetadata)
                            //     ? item.valueMetadata.map(
                            //         (repeatedEnum, indexenum) =>
                            //           renderRepeatedEnumChip(
                            //             repeatedEnum,
                            //             indexenum,
                            //             attribute,
                            //             idx,
                            //             handleChipClickService,
                            //             select_svg
                            //           )
                            //       )
                            //     : null;
                            // }
                            if (item.valueType !== "URL") {
                              return renderChip(
                                item,
                                idx,
                                attribute,
                                handleChipClickService,
                                select_svg
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  // paddingBottom: "10px",
                  // paddingTop: "10px",
                  paddingBlock: "20px",
                }}
              >
                <div>
                  <FPButton
                    label="Back"
                    size="small"
                    onClick={handleShowBusinessScreen}
                  />
                </div>
                <div>
                  <FPButton
                    onClick={updateAttributeHandler}
                    label="Update"
                    size="small"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <img src={google_svg} alt="verifiedImage" width="100%" />
          </div>
        </div>
      )}
    </>
  );
};

// export default UpdateAttribute
const mapStateToProps = (state) => {
  return {
    attribute_data: state.gmbListingLocation.attribute_data,
    attribute_error: state.gmbListingLocation.attribute_error,
    bulk_update_listing_data: state.gmbListingLocation.bulk_update_listing_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // getGmbListingUpdateListing: bindActionCreators(
  //   getGmbListingUpdateListing,
  //   dispatch
  // ),
  actions: bindActionCreators(locationActions, dispatch),
});

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators(locationActions, dispatch),
// });

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAttribute);
