import React from 'react';
import { Row, Col } from 'reactstrap';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as utils from '../../libs/utils';

import * as GenericTemplatesActions from '../../actions/genericTemplates/genericTemplatesActions';
import cookie from 'react-cookies';

import TemplateList from '../../components/campaign/templates/TemplateList';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import LoaderComponent from '../../components/common/loaderComponent';

class GenericTemplatesPage extends React.PureComponent {
  constructor(props)
  {
    super(props);
    this.switchToAddGenericTemplate = this.switchToAddGenericTemplate.bind(this);
    this.deleteGenericTemplate = this.deleteGenericTemplate.bind(this);
    this.cloneGenericTemplate = this.cloneGenericTemplate.bind(this);
    this.getGenericTemplatesList = this.getGenericTemplatesList.bind(this);
    this.searchTemplate = this.searchTemplate.bind(this);
    this.state = {
      lock: ''
    };
    //PageSize Handling
    const pageSize = cookie.load('pageSize') || {};
    const { history, location: { pathname,search }} = props;
    history.replace({ pathname, search: queryString.stringify({ ...queryString.parse(search), page_size: pageSize.templates_generic || 10 }) });
  }

  componentDidMount(){
    this.getGenericTemplatesList(this.props, queryString.parse(this.props.location.search));
    //Set Title
    document.title = 'Templates|Generic';
  }

  componentWillReceiveProps(nextProps) { // eslint-disable-line
    const { location: { search: prevSearch } } = this.props;
    const { location: { search: nextSearch } } = nextProps;
    if (prevSearch !== nextSearch) {
      this.getGenericTemplatesList(nextProps, queryString.parse(nextSearch));
    }
  }

  switchToAddGenericTemplate(val,branch) {
    const { history, match: { params: { branch_id, business_id }} } = this.props;
    val ? history.push(`/${business_id}/${branch || branch_id}/templates/generic/edit?${val}`) : history.push(`/${business_id}/${branch_id}/templates/generic/edit`);
    
  }
  getGenericTemplatesList(props,data){
    const { match: { params: { business_id }} } = props;
    this.props.actions.genericTemplatesList({ ...data, branch_id: business_id })
      .catch((err)=>err==='lock' && this.setState({lock: 'NewsLetter Module is locked.'}));
  }

  toggleConfirm = (confirmationFunction) => {
    this.setState({ isConfirmOpen: !this.state.isConfirmOpen, confirmationFunction });
  }

  deleteGenericTemplate(data){
    const { history, location: { pathname, search },  match: { params: { business_id }} } = this.props;
    return this.props.actions.genericTemplateDelete(data, business_id)
      .then(() => {
        const query = queryString.parse(search);
        if(query.page){
          history.push({pathname, search: `?page_size=${query.page_size}` });
        }
        else{
          this.getGenericTemplatesList(this.props,{page_size: query.page_size});
        }
      });
  }

  searchTemplate(data){
    const { match: { params: { branch_id }} } = this.props;
    this.props.actions.genericTemplatesList({ ...data, branch_id });
  }

  cloneGenericTemplate(data){
    const { match: { params: { business_id }}, location: { search } } = this.props;
    return this.props.actions.genericTemplateClone(data, business_id)
      .then(() => this.getGenericTemplatesList(this.props, queryString.parse(search)));
  }
  render()
  {
    const { location: { search } } = this.props;
    const querySearch = queryString.parse(search);

    return(
      <Row className="content-area">
        <LoaderComponent loader={this.props.loader} />
        <Col xs={12} sm={12} className="business-setup p-3" style={{width:'100px'}}>
          <TemplateList
            list ={this.props.genericTemplatesList}
            deleteTemplate={(item) => this.toggleConfirm(() => this.deleteGenericTemplate(item))}
            cloneTemplate={this.cloneGenericTemplate}
            switchToAddTemplate={this.switchToAddGenericTemplate}
            location={this.props.location}
            history={this.props.history}
            sortFunction={(on) => utils.toggleSort(this.props.history,this.props.location,on)}
            generic={true}
            ordering={querySearch.ordering}
            searchValue={querySearch.search}
            pageSizeCookie={'templates_generic'}
            showClone={false}
          />
        </Col>
        <ConfirmationModal
          header='Are you sure!'
          subHeader='Are you sure you want to delete the selected items? This action cannot be undone.'
          isOpen={this.state.isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={this.state.confirmationFunction}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    genericTemplatesList:state.genericTemplate.list,
    errors: state.business.errors,
    teamDetail: state.team.detail,
    loader: state.common.loader
  };
};


GenericTemplatesPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  CommonActions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  business:PropTypes.instanceOf(Object)
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(GenericTemplatesActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(GenericTemplatesPage);