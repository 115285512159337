import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Checkbox } from "semantic-ui-react";
import { FPToolTip } from "../common/new";
import editIcon from "../../images/svg/EditPen.svg";
import deleteIcon from "../../images/svg/trash.svg";
import ConfirmationModal from "../common/ConfirmationModal";
import PaginationComponent from "../common/Pagination";

const DepartmentList = (props) => {
  const { department, toggleDepartment, deleteDepartment, location, count, pageSizeCookie, handleTeamItemParams } =
    props;
  const [isConfirm, setIsConfirm] = useState(false);
  const [depsData, setDepsData] = useState(null);
  const [allCheckbox, setAllCheckBox] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  useEffect(() => {
    setAllCheckBox([]);
    setIsAllChecked(false);
  }, [department]);

  const toggleConfirm = () => {
    deleteDepartment(depsData);
    setIsConfirm(false);
  };

  const handleClick = (data) => {
    setDepsData(data);
    setIsConfirm(!isConfirm);
  };

  const handleAllChecbox = () => {
    const check_all = [];
    if (!isAllChecked) {
      department.map((value) => {
        return check_all.push(value.id);
      });
    }
    setAllCheckBox(check_all);
    setIsAllChecked(!isAllChecked);
  };

  const handleOneCheckbox = (id) => {
    let check_all = [];
    const item = allCheckbox.find((value) => value === id);
    if (item) {
      allCheckbox.map((value) => {
        if (value !== id) {
          check_all.push(value);
        }
        return value;
      });
      setAllCheckBox(check_all);
    } else {
      check_all = [...allCheckbox];
      check_all.push(id);
      setAllCheckBox(check_all);
    }
  };

  return (
    <div>
      <div className="ui-table-responsive">
        <Table className="fp-table fp-table-center">
          <TableHead>
            <TableRow>
              <TableCell>
              <span className="department-list">

                <Checkbox
                  checked={isAllChecked}
                  onClick={() => handleAllChecbox()}
                  label="S.No"
                  id="all"
                  className="d-flex"
                >
                  Select All
                </Checkbox>
              </span>
              </TableCell>
              <TableCell>Department</TableCell>
              <TableCell className="">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {department?.map((item, index) => {
              const isChecked = allCheckbox.find((value) => value === item.id) ? true : false;
              return (
                <TableRow key={index}>
                  <TableCell className="d-flex" style={{padding: "16px"}}>
                    <span className="department-list">
                      <Checkbox checked={isChecked} onClick={() => handleOneCheckbox(item.id)} label={index + 1}/>
                    </span>
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <div className="actionButton">
                      <FPToolTip title={"Edit"} className="me-3">
                        <img
                          src={editIcon}
                          onClick={() => {
                            toggleDepartment({
                              id: item.id,
                              name: item.name,
                            });
                          }}
                          role="button"
                          alt="edit"
                        />
                      </FPToolTip>
                      <FPToolTip title={"Delete"} className="me-3">
                        <img
                          src={deleteIcon}
                          onClick={() => {
                            handleClick(item);
                          }}
                          role="button"
                          alt="delete"
                        />
                      </FPToolTip>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <PaginationComponent
        count={count}
        location={location}
        pageSizeCookie={pageSizeCookie}
        onPageChange={(params) => handleTeamItemParams("Department", params)}
      />
      <ConfirmationModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader={`Are you sure you want to delete this department?`}
        isOpen={isConfirm}
        toggle={() => setIsConfirm(!isConfirm)}
        onConfirm={() => toggleConfirm()}
      />
    </div>
  );
};

export default DepartmentList;
