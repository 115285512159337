import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import LoaderComponent from "../common/loaderComponent";
import { useState } from "react";
import ReviewModel from "../common/ReviewModel";
import apiUrls from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import add from "../../images/plus.png";
import minus from "../../images/minus.png";
import PaginationComponent from "../common/Pagination";

const StaffPerformanceComponent = (props) => {
  const { staffPerformance, loader, location, match } = props;
  const { business_id } = match.params;

  const { search } = location;
  const query = new URLSearchParams(search);
  const isShowPercentages =
    query.get("show_percentages") === "true" ? true : false;

  const [expandedRow, setExpandedRow] = useState(null);
  const [reviewModelOpen, setReviewModelOpen] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [headerText, setHeaderText] = useState("Review");
  const [hoveredIndexes, setHoveredIndexes] = useState({
    staff: null,
    negative: null,
  });

  const [positiveHoveredIndexes, setPositiveHoveredIndexes] = useState(null);
  const [naturalHoveredIndexes, setNaturalHoveredIndexes] = useState(null);
  const [totalHoveredIndexes, setTotalHoveredIndexes] = useState(null);
  const [staffID, setStaffID] = useState(null);
  const [reviewType, setReviewType] = useState(null);
  const [staffName, setStaffName] = useState(null);

  const handlePositiveMouseEnter = (index) => {
    setPositiveHoveredIndexes(index);
  };

  const handlePositiveMouseLeave = () => {
    setPositiveHoveredIndexes(null);
  };

  const handleTotalMouseEnter = (index) => {
    setTotalHoveredIndexes(index);
  };

  const handleTotalMouseLeave = () => {
    setTotalHoveredIndexes(null);
  };

  const handleMouseEnter = (fieldName, index) => {
    setHoveredIndexes({
      ...hoveredIndexes,
      [fieldName]: index,
    });
  };

  const handleMouseLeave = (fieldName) => {
    setHoveredIndexes({
      ...hoveredIndexes,
      [fieldName]: null,
    });
  };

  const toggleRowExpansion = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const getStaffReview = (staffName, staffId, type) => {
    let text;
    if (type === 2) {
      text = "Positive Reviews";
    } else if (type === 1) {
      text = "Negative Reviews";
    } else if (type === 0) {
      text = "Neutral Reviews";
    } else {
      text = "Reviews";
    }
    setHeaderText(text);
    setReviewType(type);
    setStaffID(staffId);
    setStaffName(staffName);

    const { location } = props;
    const url = `${apiUrls.STAFF_INSIGHTS_REVIEW}${
      location ? location.search : ""
    }`;
    const params = {
      staff_id: staffId,
      // ...(type && { type }),
      type,
      business_id: business_id,
    };

    axios_instance
      .get(url, { params })
      .then((res) => {
        setReviewData(res.data);
        setReviewModelOpen(true);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div>
      <LoaderComponent loader={loader} />
      <CardWrapper
        headerTitle="Staff Performance"
        className="py-2"
      >
        <div className="ui-table-responsive">
          <Table className="fp-table fp-table-center">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>S.No</TableCell>
                <TableCell>Location Name</TableCell>
                <TableCell className="width-md-th">Name of Staff</TableCell>
                <TableCell textAlign="center">Positive</TableCell>
                <TableCell textAlign="center">Negative</TableCell>
                <TableCell textAlign="center">Neutral</TableCell>
                <TableCell textAlign="center">Total</TableCell>
                {isShowPercentages && (
                  <TableCell textAlign="center">Positive %</TableCell>
                )}
                {isShowPercentages && (
                  <TableCell textAlign="center">Negative %</TableCell>
                )}
                {isShowPercentages && (
                  <TableCell textAlign="center">Neutral %</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {staffPerformance.result && staffPerformance.result.length > 0 ? (
                staffPerformance.result.map((item, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell>
                        {item.staff_members.length > 0 && (
                          <img
                            src={expandedRow === index ? minus : add}
                            alt="action"
                            className="cursor-pointer"
                            onClick={() => toggleRowExpansion(index)}
                          />
                        )}
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {item.location_name} ({item.staff_members.length})
                      </TableCell>
                      <TableCell className="width-md">{"ALL"}</TableCell>
                      <TableCell textAlign="center">
                        {item.totalPositive}
                      </TableCell>
                      <TableCell textAlign="center">
                        {item.totalNegative}
                      </TableCell>
                      <TableCell textAlign="center">
                        {item.totalNeutral}
                      </TableCell>
                      <TableCell textAlign="center">{item.total}</TableCell>
                      {isShowPercentages && (
                        <TableCell textAlign="center">
                          {item.totalPositivePercentage}
                        </TableCell>
                      )}
                      {isShowPercentages && (
                        <TableCell textAlign="center">
                          {item.totalNegativePercentage}
                        </TableCell>
                      )}
                      {isShowPercentages && (
                        <TableCell textAlign="center">
                          {item.totalNeutralPercentage}
                        </TableCell>
                      )}
                    </TableRow>
                    {expandedRow === index &&
                      item.staff_members.map((staff, _index) => (
                        <React.Fragment key={_index}>
                          <TableRow style={{ backgroundColor: "#F2F9F9" }}>
                            <TableCell> </TableCell>
                            <TableCell>
                              {index + 1}.{_index + 1}
                            </TableCell>
                            <TableCell>{item.location_name}</TableCell>
                            <TableCell
                              onClick={() =>
                                getStaffReview(staff.name, staff.id)
                              }
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  hoveredIndexes.staff === _index
                                    ? "#ddeff0"
                                    : "transparent",
                              }}
                              onMouseEnter={() =>
                                handleMouseEnter("staff", _index)
                              }
                              onMouseLeave={() => handleMouseLeave("staff")}
                            >
                              {staff.name}
                            </TableCell>
                            <TableCell
                              textAlign="center"
                              onClick={() =>
                                getStaffReview(staff.name, staff.id, 2)
                              }
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  positiveHoveredIndexes === _index
                                    ? "#ddeff0"
                                    : "transparent",
                              }}
                              onMouseEnter={() =>
                                handlePositiveMouseEnter(_index)
                              }
                              onMouseLeave={() => handlePositiveMouseLeave()}
                            >
                              {staff.positive}
                            </TableCell>
                            <TableCell
                              textAlign="center"
                              onClick={() =>
                                getStaffReview(staff.name, staff.id, 1)
                              }
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  hoveredIndexes.negative === _index
                                    ? "#ddeff0"
                                    : "transparent",
                              }}
                              onMouseEnter={() =>
                                handleMouseEnter("negative", _index)
                              }
                              onMouseLeave={() => handleMouseLeave("negative")}
                            >
                              {staff.negative}
                            </TableCell>
                            <TableCell
                              textAlign="center"
                              onClick={() =>
                                getStaffReview(staff.name, staff.id, 0)
                              }
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  naturalHoveredIndexes === _index
                                    ? "#ddeff0"
                                    : "transparent",
                              }}
                              onMouseEnter={() =>
                                setNaturalHoveredIndexes(_index)
                              }
                              onMouseLeave={() =>
                                setNaturalHoveredIndexes(null)
                              }
                            >
                              {staff.neutral}
                            </TableCell>
                            <TableCell
                              textAlign="center"
                              onClick={() =>
                                getStaffReview(staff.name, staff.id)
                              }
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  totalHoveredIndexes === _index
                                    ? "#ddeff0"
                                    : "transparent",
                              }}
                              onMouseEnter={() => handleTotalMouseEnter(_index)}
                              onMouseLeave={() => handleTotalMouseLeave()}
                            >
                              {staff.total}
                            </TableCell>
                            {isShowPercentages && (
                              <TableCell textAlign="center">
                                {staff.positive_percentage}
                              </TableCell>
                            )}
                            {isShowPercentages && (
                              <TableCell textAlign="center">
                                {staff.negative_percentage}
                              </TableCell>
                            )}
                            {isShowPercentages && (
                              <TableCell textAlign="center">
                                {staff.neutral_percentage}
                              </TableCell>
                            )}
                          </TableRow>
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10} textAlign="center">
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <PaginationComponent
          count={staffPerformance?.result?.length}
          location={location}
          pageSizeCookie={"insight_staff_performance"}
          className="pb-0"
        />
      </CardWrapper>

      {reviewModelOpen && (
        <ReviewModel
          isOpen={reviewModelOpen}
          toggle={() => setReviewModelOpen(!reviewModelOpen)}
          headerText={headerText}
          reviewData={reviewData}
          businessId={business_id}
          staffId={staffID}
          type={reviewType}
          staffName={staffName}
          location={location}
        />
      )}
    </div>
  );
};

export default StaffPerformanceComponent;
