import * as types from "../../actions/actionTypes";

export default function designationReducer(state = [], action) {
  switch (action.type) {
    // case types.CREATE_DESIGNATION_SUCCESS:
    //   return Object.assign({}, state, { detail: { ...action.data } });
    // case types.CREATE_DESIGNATION_FAILURE:
    //   return Object.assign({}, state, { detail: { ...action.data } });
    case types.GET_DESIGNATION_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.GET_DESIGNATION_FAILURE:
      return Object.assign({}, state, { detail: { ...action.data } });
    // case types.EDIT_DESIGNATION_SUCCESS:
    //   return Object.assign({}, state, { detail: { ...action.data } });
    // case types.EDIT_DESIGNATION_FAILURE:
    //   return Object.assign({}, state, { detail: { ...action.data } });
    // case types.DELETE_DESIGNATION_SUCCESS:
    //   return Object.assign({}, state, { detail: { ...action.data } });
    // case types.DELETE_DESIGNATION_FAILURE:
    //   return Object.assign({}, state, { detail: {} });
    default:
      return state;
  }
}
