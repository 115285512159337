import React, { useEffect, useState } from "react";
import PaginationComponent from "../common/Pagination";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { Checkbox } from "semantic-ui-react";
import queryString from "query-string";
// css
import "./insight.css";
import LoaderComponent from "../common/loaderComponent";
import MenuPerformanceList from "./MenuPerformanceList";

const MenuPerformance = (props) => {
  const { menu_performance, loader, location, search } = props;
  const [isLocationWise, setIsLocationWise] = useState(false);
  const [isCategoryWise, setIsCategoryWise] = useState(false);
  const [isSubCategory, setIsSubCategory] = useState(false);
  const [isNonVeg, setIsNonVeg] = useState(false);
  const [hideItemName, setHideItemName] = useState(false);

  useEffect(() => {
    const { show_location_wise, show_category_wise, show_sub_category_wise, show_veg_non_veg_wise, hide_item_name } = {...queryString.parse(search)};

    setIsLocationWise( show_location_wise === 'true' ? true : false);
    setIsCategoryWise(show_category_wise  === 'true' ? true : false);
    setIsSubCategory(show_sub_category_wise  === 'true' ? true : false);
    setIsNonVeg(show_veg_non_veg_wise  === 'true' ? true : false);
    setHideItemName(hide_item_name  === 'true' ? true : false);
  }, [search]);

  const handleFilterChange = (val) => {
    if (val === "Location") {
      const filter = isLocationWise;
      setIsLocationWise(!isLocationWise);
      props.handleMenuCheck(!filter, isCategoryWise, isSubCategory, isNonVeg, hideItemName);
    } else if (val === "Category") {
      const filter = isCategoryWise;
      setIsCategoryWise(!isCategoryWise);
      props.handleMenuCheck(isLocationWise, !filter, isSubCategory, isNonVeg, hideItemName);
    } else if (val === "Sub-Category") {
      const filter = isSubCategory;
      setIsSubCategory(!isSubCategory);
      props.handleMenuCheck(isLocationWise, isCategoryWise, !filter, isNonVeg, hideItemName);
    } else if (val === "Veg/Non-Veg") {
      const filter = isNonVeg;
      setIsNonVeg(!isNonVeg);
      props.handleMenuCheck(isLocationWise, isCategoryWise, isSubCategory, !filter, hideItemName);
    } else if (val === "Item Name") {
      const filter = hideItemName;
      setHideItemName(!hideItemName);
      props.handleMenuCheck(isLocationWise, isCategoryWise, isSubCategory, isNonVeg, !filter);
    }
  };
  const test = ["Item Name", "Location", "Category", "Sub-Category", "Veg/Non-Veg"];
  return (
    <div>
      <LoaderComponent loader={loader} />
      <CardWrapper
        headerTitle={
          <div className="d-flex justify-content-between align-items-center">
            <span>Menu Performance</span>
            <div className="d-flex align-items-center gap-10">
              {test.map((val, index) => {
                return (
                  <div className="d-flex align-items-center gap-6">
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "rgba(27, 28, 29, 1)",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {val}
                    </span>
                    <Checkbox
                      toggle
                      value={
                        val === "Location"
                          ? "Location"
                          : val === "Category"
                          ? "Category"
                          : val === "Sub-Category"
                          ? "Sub-Category"
                          : val === "Veg/Non-Veg"
                          ? "Veg/Non-Veg"
                          : val === "Item Name" && "Item_name"
                      }
                      checked={
                        val === "Location"
                          ? isLocationWise
                          : val === "Category"
                          ? isCategoryWise
                          : val === "Sub-Category"
                          ? isSubCategory
                          : val === "Veg/Non-Veg"
                          ? isNonVeg
                          : val === "Item Name" && !hideItemName
                      }
                      onClick={() => handleFilterChange(val)}
                    ></Checkbox>
                  </div>
                );
              })}
            </div>
          </div>
        }
        className="py-2"
      >
        <div className="ui-table-responsive menu-table">
          <MenuPerformanceList
            menu_performance={(menu_performance && menu_performance.results && Array.isArray(menu_performance.results) && menu_performance.results ) || []}
            loading={loader}
            isLocationWise={isLocationWise}
            isCategoryWise={isCategoryWise}
            isSubCategory={isSubCategory}
            isNonVeg={isNonVeg}
            hideItemName={hideItemName}
            businessId={props.businessId}
            location={location}
          />
        </div>
        <PaginationComponent
          count={menu_performance?.count}
          location={location}
          pageSizeCookie={"menu_performance"}
          className="pb-0"
        />
      </CardWrapper>
    </div>
  );
};

export default MenuPerformance;
