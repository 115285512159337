import axios_instance from '../../libs/interseptor';
import * as types from '../actionTypes';
import * as URLS from '../../libs/apiUrls';
import * as commonAction from '../common/commonAction';

export function forgotPasswordSuccess(data) {
  return { type: types.FORGOT_PASSWORD_SUCCESS, data };
}

export function forgotPasswordFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.FORGOT_PASSWORD_FAILURE, data };
}

export const forgotPasswordAction = (data) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(URLS.FORGOT_PASSWORD, data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(forgotPasswordSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(forgotPasswordFailure(err));
          throw err.response.data;
        }
      );
  };
};
