import React, { useState, useRef } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";

function MediaViewer({ mediaList }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false); // State to control play icon visibility
  const videoRef = useRef(null); // Ref for the video element

  const resetVideoPlayback = () => {
    if (videoRef.current) {
      videoRef.current.pause(); // Stop the current video
      videoRef.current.currentTime = 0; // Reset playback to the beginning
    }
    setIsPlaying(false); // Reset play state
  };

  const handleNext = () => {
    if (currentIndex < mediaList.length - 1) {
      resetVideoPlayback(); // Reset video if transitioning from video
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      resetVideoPlayback(); // Reset video if transitioning from video
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleThumbnailClick = (index) => {
    resetVideoPlayback(); // Reset video if transitioning from video
    setCurrentIndex(index);
  };

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true); // Hide the play icon
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false); // Show the play icon when video ends
  };

  const getMediaUrl = (media) => {
    return media instanceof File ? URL.createObjectURL(media) : media;
  };

  const inferMediaType = (media) => {
    const url = media instanceof File ? media.name : media; // Get name or URL
    if (url.endsWith(".mp4") || url.endsWith(".webm")) return "video";
    if (url.endsWith(".mp3") || url.endsWith(".wav")) return "audio";
    if (url.endsWith(".jpg") || url.endsWith(".jpeg") || url.endsWith(".png") || url.endsWith(".gif")) return "image";
    return "unsupported";
  };

  const renderMedia = () => {
    const currentMedia = mediaList[currentIndex];
    const mediaUrl = getMediaUrl(currentMedia);
    const mediaType = inferMediaType(currentMedia);

    if (mediaType === "image") {
      return <img src={mediaUrl} alt="Preview" className="media-viewer-media" />;
    } else if (mediaType === "video") {
      return (
        <div className="video-container" style={{ position: "relative" }}>
          <video
            ref={videoRef} // Attach ref to video element
            className="media-viewer-media"
            onEnded={handleVideoEnd} // Show play icon when video ends
            controls
          >
            <source src={mediaUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {!isPlaying && (
            <PlayCircleIcon
              onClick={handlePlay} // Play video on click
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "100px",
                color: "white",
                cursor: "pointer",
                zIndex: 10,
              }}
            />
          )}
        </div>
      );
    } else if (mediaType === "audio") {
      return (
        <audio controls className="media-viewer-media">
          <source src={mediaUrl} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      );
    } else {
      return <p>Unsupported media format</p>;
    }
  };

  return (
    <div className="media-viewer-container h-100">
      {/* Main Media Display */}
      <div className="media-viewer-main">
        <ArrowBackIosNewIcon
          onClick={handlePrev}
          className={`arrow-icon left-arrow ${currentIndex === 0 ? "disabled-arrow" : ""}`}
        />
        {renderMedia()}
        <ArrowForwardIosIcon
          onClick={handleNext}
          className={`arrow-icon right-arrow ${currentIndex === mediaList.length - 1 ? "disabled-arrow" : ""}`}
        />
      </div>
      {/* Thumbnails */}
      <div className="preview-bar">
        {mediaList.map((media, index) => {
          const mediaUrl = getMediaUrl(media);
          const mediaType = inferMediaType(media);
          const isActive = index === currentIndex;

          return (
            <div
              key={index}
              onClick={() => handleThumbnailClick(index)}
              className={`thumbnail-container ${isActive ? "active-thumbnail" : ""}`}
            >
              {mediaType === "image" ? (
                <img src={mediaUrl} alt={`Thumbnail-${index}`} className="thumbnail-image" />
              ) : mediaType === "video" ? (
                <div className="thumbnail-icon-container">
                  <PlayCircleIcon className="thumbnail-icon" />
                </div>
              ) : mediaType === "audio" ? (
                <div className="thumbnail-icon-container">
                  <AudiotrackIcon className="thumbnail-icon" />
                </div>
              ) : (
                <p className="unsupported-thumbnail">Unsupported</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MediaViewer;
