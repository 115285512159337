import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Label, Row, Col, Input, FormGroup } from "reactstrap";
import { Divider, Checkbox, Radio } from "semantic-ui-react";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
  FieldArray,
} from "redux-form";
import queryString from "query-string";
import InputComponent from "../../common/InputComponent";
import TextAreaComponent from "../../common/TextAreaComponent";
import ReviewMethodsOptions from "../../common/ReviewMethodsOptions";
import MultipleButtonSelect from "../../common/MultipleButtonSelect";
import * as utils from "../../../libs/utils";
import QRCode from "qrcode.react";
import LoaderComponent from "../../common/loaderComponent";
// import ErrorModal from '../../messenger/Common/ErrorModal';
import {
  FPBreadcrumbs,
  FPButton,
  FPCheckbox,
  FPMenuBox,
  FPToolTip,
} from "../../common/new";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";
import BranchSelector from "../../business/BranchSelector";
import FPLabel from "../../common/new/forms/ui/FPLabel";
import { Collapse, Link, IconButton, Box } from "@material-ui/core";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import "./genericTemplateForm.css";
import ErrorMessage from "../../common/new/forms/ui/ErrorMessage";
import { AddOptionBtn, QuestionActions, RemoveBtn } from "./ActionsBtn";
import star from "../../../images/reviewMethods/star_B_W.png";
import star_active from "../../../images/reviewMethods/star_Color.png";
import CustomRatingComponent from "../../common/customRating";
import LinkIcon from "@material-ui/icons/Link";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import deleteIcon from "../../../images/svg/trash.svg";
import Rater from "react-rater";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FaceFour from "../../../images/svg/FaceFour";
import FaceFillFour from "../../../images/svg/FaceFillFour";
import FaceFive from "../../../images/svg/FaceFive";
import FaceFillFive from "../../../images/svg/FaceFillFive";
import FaceSix from "../../../images/svg/FaceSix";
import FaceFillSix from "../../../images/svg/FaceFillSix";
import FaceSeven from "../../../images/svg/FaceSeven";
import FaceFillSeven from "../../../images/svg/FaceFillSeven";
import FaceEight from "../../../images/svg/FaceEight";
import FaceFillEight from "../../../images/svg/FaceFillEight";
import SelectComponent from "../../common/SelectComponent";
import ExperiencePagePreview from "../../common/ExperiencePagePreview";
import SurveyPagePreview from "../../common/SurveyPagePreview";
import {
  AlphaPicker,
  HuePicker,
  SketchPicker,
  TwitterPicker,
} from "react-color";
import Wheel from "@uiw/react-color-wheel";
import ShadeSlider from "@uiw/react-color-shade-slider";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

// import e from 'express';

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = "Enter Name";
  if (!values.sub_type) errors.sub_type = "Enter Sub Type";
  if (!values.heading) errors.heading = "Enter Heading";
  if (values.thanks_line && !values.thanks_line.includes("{customer name}"))
    errors.thanks_line =
      "You can only change starting and ending text excluding {customer name}";
  return errors;
};

const CustomInput = ({ ...rest }) => (
  <InputComponent {...rest} className="w-100" />
);

class GenericTemplateForm extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      location: { search },
    } = this.props;
    const { id } = queryString.parse(search);
    this.submit = this.submit.bind(this);
    this.handleReviewMethod = this.handleReviewMethod.bind(this);
    this.deleteSurveyPageTitle = this.deleteSurveyPageTitle.bind(this);
    this.addSurveyPageTitleFn = this.addSurveyPageTitleFn.bind(this);
    this.choiceQuestinFunction = this.choiceQuestinFunction.bind(this);
    this.addMoreChoicesOfQuestionFn =
      this.addMoreChoicesOfQuestionFn.bind(this);
    this.deleteChoicesQuestionFn = this.deleteChoicesQuestionFn.bind(this);
    this.deleteMoreChoicesOfQuestionFn =
      this.deleteMoreChoicesOfQuestionFn.bind(this);
    this.myRef = [];
    this.state = {
      reviewChannel: [],
      isApplyToAll:
        props.initialValues && props.initialValues.applied_to_all
          ? props.initialValues.applied_to_all
          : false,
      showReviewMethods:
        props.initialValues && props.initialValues.symbol_type ? true : false,
      reviewGating: props.initialValues && props.initialValues.review_gating,
      generatedLink: "",
      current_id: null,
      error: "",
      landingPageBodyChars: 2000,
      reviewMethod:
        props.initialValues && props.initialValues.symbol_type
          ? props.initialValues.symbol_type
          : "thumb",
      is_delete: false,
      isLoading: false,
      imgSrc: null,
      image: null,
      logo: null,
      logoSrc: null,
      thanksPageImage: null,
      thanksPageImageSrc:
        props.initialValues && props.initialValues.thanks_page_image
          ? props.initialValues.thanks_page_image
          : null,
      include_location:
        props.initialValues && props.initialValues.include_location
          ? props.initialValues.include_location
          : false,
      include_email:
        props.initialValues && props.initialValues.include_email
          ? props.initialValues.include_email
          : false,
      include_dob:
        props.initialValues && props.initialValues.include_dob
          ? props.initialValues.include_dob
          : false,
      include_doa:
        props.initialValues && props.initialValues.include_doa
          ? props.initialValues.include_doa
          : false,
      include_gender:
        props.initialValues && props.initialValues.include_gender
          ? props.initialValues.include_gender
          : false,
      include_offer:
        props.initialValues && props.initialValues.include_offer
          ? props.initialValues.include_offer
          : false,
      include_comment:
        props.initialValues && props.initialValues.include_comment !== undefined
          ? props.initialValues.include_comment
          : true,
      show_city:
        props.initialValues && props.initialValues.show_city
          ? props.initialValues.show_city
          : false,
      show_state:
        props.initialValues && props.initialValues.show_state
          ? props.initialValues.show_state
          : false,
      enable_consent:
        props.initialValues && props.initialValues.enable_consent
          ? props.initialValues.enable_consent
          : false,
      show_store_options_in_uppercase:
        props.initialValues &&
        props.initialValues.show_store_options_in_uppercase
          ? props.initialValues.show_store_options_in_uppercase
          : false,
      consent_text:
        props.initialValues && props.initialValues.consent_text !== undefined
          ? props.initialValues.consent_text
          : null,
      surveySectionActivation: this.props.surveySectionActivation,
      surveySectionActivationNegative:
        this.props.surveySectionActivationNegative,
      dropdownOpen: false,
      AddQuestionDropdownOpen: false,
      ReAddQuestionOpen: false,
      addSurveyPagetitle: false,
      AddPageBelowForm: [
        {
          page: "",
          title: "",
          pageQuestionAddError: "",
          titleBoolean: true,
          edit: false,
          titleError: "",
          allQuestions: [],
        },
      ],
      initialForm: [
        {
          page: "",
          title: "",
          pageQuestionAddError: "",
          titleBoolean: true,
          edit: false,
          titleError: "",
          allQuestions: [],
        },
      ],
      MainDropdownIndex: 0,
      titleInputIndex: 0,
      choiceQuestinDropDown: 0,
      pageTitle: "",
      pageTitleIndex: 0,
      pageTitleError: "",
      pageQuestionInputIndex: 0,
      pageQuestionInputError: "",
      pageQuestionOptionInputIndex: 0,
      pageQuestionOptionInputError: "",
      // errorModalShow:false,
      // sendMessageError:'',
      surveyFormSubmit: false,
      activeIndex: -1,
      activeRatingIndex: -1,
      nps_array: Array(10).fill(false),
      smileyFourHover: false,
      smileyFiveHover: false,
      smileySixHover: false,
      smileySevenHover: false,
      smileyEightHover: false,
      queRgbBgColor: { r: 255, g: 255, b: 255, a: 1 },
      design_setting:
        props.initialValues &&
        props.initialValues.landaing_page_design &&
        Object.keys(props.initialValues.landaing_page_design)?.length > 0
          ? props.initialValues.landaing_page_design
          : {
              fonts: [
                {
                  name: "Outfit (sans-serif)",
                  selected: false,
                  value: "palatino, serif",
                },
                {
                  name: "Hanken Grotesk",
                  selected: false,
                  value: "Hanken Grotesk",
                },
                {
                  name: "Helvetica (sans-serif)",
                  selected: false,
                  value: "helvetica, sans-serif",
                },
                {
                  name: "Arial (sans-serif)",
                  selected: false,
                  value: "arial, sans-serif",
                },
                {
                  name: "Arial Black (sans-serif)",
                  selected: false,
                  value: "arial black, sans-serif",
                },
                {
                  name: "Verdana (sans-serif)",
                  selected: false,
                  value: "verdana, sans-serif",
                },
                {
                  name: "Tahoma (sans-serif)",
                  selected: false,
                  value: "'Tahoma', sans-serif",
                },
                {
                  name: "Trebuchet MS (sans-serif)",
                  selected: false,
                  value: "trebuchet ms, sans-serif",
                },
                {
                  name: "Impact (sans-serif)",
                  selected: false,
                  value: "impact, sans-serif",
                },
                {
                  name: "Gill Sans (sans-serif)",
                  selected: false,
                  value: "gill sans, sans-serif",
                },
                {
                  name: "Times New Roman (serif)",
                  selected: false,
                  value: "times new roman, serif",
                },
                {
                  name: "Georgia (serif)",
                  selected: false,
                  value: "georgia, serif",
                },
              ],
              background_color: [
                { name: "White", selected: false, code: "#FFFFFF" },
                { name: "Red", selected: false, code: "#FF0000" },
                { name: "Cyan", selected: false, code: "#00FFFF" },
                { name: "Silver", selected: false, code: "#C0C0C0" },
                { name: "Blue", selected: false, code: "#0000FF" },
                { name: "Gray", selected: false, code: "#808080" },
                { name: "DarkBlue", selected: false, code: "#00008B" },
                { name: "Black", selected: false, code: "#000000" },
                { name: "LightBlue", selected: false, code: "#ADD8E6" },
                { name: "Orange", selected: false, code: "#FFA500" },
                { name: "Purple", selected: false, code: "#800080" },
                { name: "Brown", selected: false, code: "#A52A2A" },
                { name: "Yellow", selected: false, code: "#FFFF00" },
                { name: "Maroon", selected: false, code: "#800000" },
                { name: "Lime", selected: false, code: "#00FF00" },
                { name: "Green", selected: false, code: "#008000" },
                { name: "Magenta", selected: false, code: "#FF00FF" },
                { name: "Olive", selected: false, code: "#808000" },
                { name: "Pink", selected: false, code: "#FFC0CB" },
                { name: "Aquamarine", selected: false, code: "#7FFFD4" },
              ],
              vertical: false,
              que_bg_color: "#FFFFFF",
              que_text_color: "#000000",
            },
      selected_font:
        props.initialValues &&
        props.initialValues.landaing_page_design &&
        Object.keys(props.initialValues.landaing_page_design)?.length > 0 &&
        props.initialValues.landaing_page_design.fonts.find(
          (font) => font.selected === true
        )
          ? props.initialValues.landaing_page_design.fonts.find(
              (font) => font.selected === true
            ).value
          : "palatino, serif",
      selected_bg_color:
        props.initialValues &&
        props.initialValues.landaing_page_design &&
        Object.keys(props.initialValues.landaing_page_design)?.length > 0 &&
        props.initialValues.landaing_page_design.background_color.find(
          (bg_color) => bg_color.selected === true
        )
          ? props.initialValues.landaing_page_design.background_color.find(
              (bg_color) => bg_color.selected === true
            ).code
          : "#FFFFFF",
      vertical_emoji: id
        ? props.initialValues.landaing_page_design.vertical
        : true,
      headingValue: props.initialValues && props.initialValues.heading,
      bodyValue: props.initialValues && props.initialValues.message,
      queBgColor:
        props.initialValues &&
        props.initialValues.landaing_page_design &&
        props.initialValues.landaing_page_design.que_bg_color
          ? props.initialValues.landaing_page_design.que_bg_color
          : "#ffffff",
      queTextColor:
        props.initialValues &&
        props.initialValues.landaing_page_design &&
        props.initialValues.landaing_page_design.que_text_color
          ? props.initialValues.landaing_page_design.que_text_color
          : "#000000",
    };
  }

  handleHover = (stateName, value) => {
    this.setState({ [stateName]: value });
  };

  handleNpsHover = (index, value) => {
    let newArray = [...this.state.nps_array];
    newArray[index] = value;
    this.setState({ nps_array: [...newArray] });
  };

  handleReviewChannel = (value) => {
    const providers =
      value &&
      value
        .filter((item) => {
          return item.status === "active";
        })
        .map((item) => {
          return item.id;
        });
    this.setState({ reviewChannel: providers });
  };

  handleReviewMethod(_e, { value }) {
    this.setState({ reviewMethod: value });
  }


  handleShowReviewMethods = (_e, { checked }) => {
    this.setState({ showReviewMethods: checked });
  };

  handleShowSurveySectionActivation = (_e, { checked }) => {
    this.setState({
      surveySectionActivation: checked,
      surveySectionActivationNegative: false,
    });
  };

  handleShowSurveySectionActivationNegative = (_e, { checked }) => {
    this.setState({
      surveySectionActivationNegative: checked,
      surveySectionActivation: false,
    });
  };

  toggle(index) {
    if (this.state.dropdownOpen === false) {
      this.setState({ dropdownOpen: true, MainDropdownIndex: index });
    } else {
      this.setState({ dropdownOpen: false, MainDropdownIndex: index });
    }
  }

  AddQuestionToggle(index) {
    if (this.state.AddQuestionDropdownOpen === false) {
      this.setState({
        AddQuestionDropdownOpen: true,
        MainDropdownIndex: index,
      });
    } else {
      this.setState({
        AddQuestionDropdownOpen: false,
        MainDropdownIndex: index,
      });
    }
  }

  ReAddQuestionList(cindex) {
    if (this.state.ReAddQuestionOpen === false) {
      this.setState({ ReAddQuestionOpen: true, choiceQuestinDropDown: cindex });
    } else {
      this.setState({
        ReAddQuestionOpen: false,
        choiceQuestinDropDown: cindex,
      });
    }
  }

  handleReviewGating = (_e, { checked }) => {
    this.setState({ reviewGating: checked });
  };

  handleApplyToAll = (e) => {
    const { checked } = e.target;
    this.setState({ isApplyToAll: checked });
  };

  handleTakeStoreDetails = (_e, { checked }) => {
    this.setState({ include_location: checked });
  };
  handleIncludeEmail = (_e, { checked }) => {
    this.setState({ include_email: checked });
  };
  handleIncludeDob = (_e, { checked }) => {
    this.setState({ include_dob: checked });
  };
  handleIncludeDoa = (_e, { checked }) => {
    this.setState({ include_doa: checked });
  };
  handleIncludegender = (_e, { checked }) => {
    this.setState({ include_gender: checked });
  };
  handleIncludeOffer = (_e, { checked }) => {
    this.setState({ include_offer: checked });
  };
  handleReviewComment = (_e, { checked }) => {
    this.setState({ include_comment: checked });
  };
  handleEnableConsent = (_e, { checked }) => {
    this.setState({ enable_consent: checked });
  };
  handleShowState = (_e, { checked }) => {
    this.setState({ show_state: checked });
  };
  handleShowCity = (_e, { checked }) => {
    this.setState({ show_city: checked });
  };
  handleShowUpperCaseOptions = (_e, { checked }) => {
    this.setState({ show_store_options_in_uppercase: checked });
  };

  addSurveyPageTitleFn(index) {
    let formValues = this.state.AddPageBelowForm;
    if (formValues[index].titleBoolean === false) {
      formValues[index].titleBoolean = true;
      this.setState({ formValues });
      this.forceUpdate();
    }
  }

  deleteSurveyPageTitle(index) {
    let formValues = this.state.AddPageBelowForm;
    if (formValues[index].titleBoolean === true) {
      formValues[index].titleBoolean = false;
      formValues[index].title = "";
      this.setState({ formValues, pageTitleError: "" });
      this.forceUpdate();
    }
  }

  addMorePageFunction() {
    let formValues = this.state.AddPageBelowForm;
    formValues.push({
      page: "",
      title: "",
      titleBoolean: true,
      edit: false,
      titleError: "",
      allQuestions: [],
    });
    this.setState({ formValues });
    this.forceUpdate();
  }

  surveyTitleInputHandler(i, e) {
    let formValues = this.state.AddPageBelowForm;
    if (e.target.value?.length > 0) {
      formValues[i]["title"] = e.target.value;
      formValues[i]["titleError"] = "";
    } else {
      formValues[i]["title"] = "";
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  submitSurveyTitleInputHandler(index, edit) {
    let formValues = this.state.AddPageBelowForm;
    if (edit === false) {
      formValues[index]["edit"] = true;
    } else {
      formValues[index]["edit"] = false;
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  questionInputHandler(i, qi, e) {
    let formValues = this.state.AddPageBelowForm;
    if (e.target.value?.length > 0) {
      formValues[i]["allQuestions"][qi]["questionError"] = "";
      formValues[i]["allQuestions"][qi]["input"] = e.target.value;
    } else {
      formValues[i]["allQuestions"][qi]["input"] = "";
      formValues[i]["allQuestions"][qi]["questionError"] =
        "Please enter question title.";
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  shortFormQuestionInputHandler(i, qi, e) {
    let formValues = this.state.AddPageBelowForm;
    if (e.target.value?.length > 0) {
      formValues[i]["allQuestions"][qi]["shortFormQuestion"] = e.target.value;
    } else {
      formValues[i]["allQuestions"][qi]["shortFormQuestion"] = "";
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  questionOptionInputHandler(i, qi, oi, e) {
    let formValues = this.state.AddPageBelowForm;
    if (e.target.value?.length > 0) {
      formValues[i]["allQuestions"][qi]["options"][oi]["option"] =
        e.target.value;
      formValues[i]["allQuestions"][qi]["options"][oi]["questionOptionError"] =
        "";
    } else {
      formValues[i]["allQuestions"][qi]["options"][oi]["option"] = "";
      formValues[i]["allQuestions"][qi]["options"][oi]["questionOptionError"] =
        "Please enter option";
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  ratingScoreInputHandler(i, qi, inputtype, e) {
    let formValues = this.state.AddPageBelowForm;
    if (e.target.value?.length > 0) {
      if (inputtype === "low") {
        formValues[i]["allQuestions"][qi]["lowRatingInputError"] = "";
        formValues[i]["allQuestions"][qi]["lowRatingInput"] = e.target.value;
      } else if (inputtype === "high") {
        formValues[i]["allQuestions"][qi]["HighRatingInputError"] = "";
        formValues[i]["allQuestions"][qi]["HighRatingInput"] = e.target.value;
      }
    } else {
      if (inputtype === "low") {
        formValues[i]["allQuestions"][qi]["lowRatingInputError"] =
          "Please enter low rating";
        formValues[i]["allQuestions"][qi]["lowRatingInput"] = "";
      } else if (inputtype === "high") {
        formValues[i]["allQuestions"][qi]["HighRatingInputError"] =
          "Please enter high rating";
        formValues[i]["allQuestions"][qi]["HighRatingInput"] = "";
      }
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  submitQuestionInputHandler(i, qi, edit, type) {
    let formValues = this.state.AddPageBelowForm;
    if (formValues[i]["allQuestions"][qi]["input"] === "" && edit === false) {
      formValues[i]["allQuestions"][qi]["questionError"] =
        "Please enter question title.";
    } else if (
      formValues[i]["allQuestions"][qi]["input"] !== "" &&
      edit === false
    ) {
      formValues[i]["allQuestions"][qi]["edit"] = true;
      formValues[i]["allQuestions"][qi]["doneError"] = "";
    } else if (
      formValues[i]["allQuestions"][qi]["input"] !== "" &&
      edit === true
    ) {
      formValues[i]["allQuestions"][qi]["edit"] = false;
    }
    if (type === "choices" || type === "checkbox" || type === "dropdown") {
      const options = formValues[i]["allQuestions"][qi]["options"];
      for (let ii = 0; ii < options?.length; ii++) {
        if (
          options[ii]["option"] === "" ||
          formValues[i]["allQuestions"][qi]["input"] === ""
        ) {
          if (options[ii]["option"] === "") {
            formValues[i]["allQuestions"][qi]["options"][ii][
              "questionOptionError"
            ] = "Please enter option";
            formValues[i]["allQuestions"][qi]["edit"] = false;
          }
        } else {
          formValues[i]["allQuestions"][qi]["options"][ii][
            "questionOptionError"
          ] = "";
        }
      }
    }
    if (type === "score" || type === "rating" || type === "fiveFacesEmoji") {
      if (
        formValues[i]["allQuestions"][qi]["lowRatingInput"] === "" &&
        edit === false
      ) {
        formValues[i]["allQuestions"][qi]["lowRatingInputError"] =
          "Please enter low rating.";
        formValues[i]["allQuestions"][qi]["edit"] = false;
      } else if (
        formValues[i]["allQuestions"][qi]["lowRatingInput"] !== "" &&
        formValues[i]["allQuestions"][qi]["input"] !== "" &&
        edit === false
      ) {
        formValues[i]["allQuestions"][qi]["edit"] = true;
      }
      if (
        formValues[i]["allQuestions"][qi]["HighRatingInput"] === "" &&
        edit === false
      ) {
        formValues[i]["allQuestions"][qi]["HighRatingInputError"] =
          "Please enter high rating.";
        formValues[i]["allQuestions"][qi]["edit"] = false;
      } else if (
        formValues[i]["allQuestions"][qi]["HighRatingInput"] !== "" &&
        formValues[i]["allQuestions"][qi]["input"] !== "" &&
        edit === false
      ) {
        formValues[i]["allQuestions"][qi]["edit"] = true;
      }
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  RemoveMoreSurveyPage(i) {
    setTimeout(() => {
      let formValues = this.state.AddPageBelowForm;
      if (formValues?.length === 1) {
        if (this.state.surveySectionActivation === true) {
          this.setState({
            surveySectionActivation: false,
            AddPageBelowForm: [
              {
                page: "",
                title: "",
                pageQuestionAddError: "",
                titleBoolean: true,
                edit: false,
                titleError: "",
                allQuestions: [],
              },
            ],
          });
        }
        if (this.state.surveySectionActivationNegative === true) {
          this.setState({
            surveySectionActivationNegative: false,
            AddPageBelowForm: [
              {
                page: "",
                title: "",
                pageQuestionAddError: "",
                titleBoolean: true,
                edit: false,
                titleError: "",
                allQuestions: [],
              },
            ],
          });
        }
      } else {
        formValues.splice(i, 1);
        this.setState({ formValues });
      }

      this.forceUpdate();
    }, 200);
  }

  choiceQuestinFunction(index, type) {
    let formValues = this.state.AddPageBelowForm;
    if (formValues[index]["allQuestions"]?.length <= 4) {
      if (type === "choices") {
        formValues[index]["allQuestions"].push({
          type: "choicesQues",
          input: "",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          options: [{ option: "", questionOptionError: "" }],
        });
      } else if (type === "checkbox") {
        formValues[index]["allQuestions"].push({
          type: "checkboxQues",
          input: "",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          options: [{ option: "", questionOptionError: "" }],
        });
      } else if (type === "dropdown") {
        formValues[index]["allQuestions"].push({
          type: "dropdownQues",
          input: "",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          options: [{ option: "", questionOptionError: "" }],
        });
      } else if (type === "shortAnswer") {
        formValues[index]["allQuestions"].push({
          type: "shortAnswerQues",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          input: "",
        });
      } else if (type === "paragraph") {
        formValues[index]["allQuestions"].push({
          type: "paragraphQues",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          input: "",
        });
      } else if (type === "rating") {
        formValues[index]["allQuestions"].push({
          type: "ratingQues",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          input: "",
          lowRatingInput: "",
          lowRatingInputError: "",
          HighRatingInput: "",
          HighRatingInputError: "",
          ratingChoice: 0,
          ratingChoiceError: "",
        });
      } else if (type === "score") {
        formValues[index]["allQuestions"].push({
          type: "scoreQues",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          input: "",
          lowRatingInput: "",
          lowRatingInputError: "",
          HighRatingInput: "",
          HighRatingInputError: "",
          ratingChoice: 0,
          ratingChoiceError: "",
        });
      } else if (type === "attachment") {
        formValues[index]["allQuestions"].push({
          type: "attachmentQues",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          input: "",
        });
      } else if (type === "fiveFacesEmoji") {
        formValues[index]["allQuestions"].push({
          type: "fiveFacesEmoji",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          input: "",
          lowRatingInput: "",
          lowRatingInputError: "",
          HighRatingInput: "",
          HighRatingInputError: "",
          ratingChoice: 0,
          ratingChoiceError: "",
        });
      }
      formValues[index]["pageQuestionAddError"] = "";
      this.setState({ formValues, AddQuestionDropdownOpen: false });
      this.forceUpdate();
    } else {
      // this.setState({errorModalShow:true, AddQuestionDropdownOpen:false, sendMessageError:'Only 5 questions can be added per page.'});
      utils.displayMessage(
        "negative",
        "Only 5 questions can be added per page"
      );
      this.forceUpdate();
    }
  }

  requiredQuestinFunction(e, index, qindex, type) {
    console.log(type);
    let formValues = this.state.AddPageBelowForm;
    formValues[index]["allQuestions"][qindex]["required"] = e.target.checked;
    this.setState({ formValues });
  }

  addMoreChoicesOfQuestionFn(index, i, type) {
    console.log(type);
    let formValues = this.state.AddPageBelowForm;
    formValues[index]["allQuestions"][i]["options"].push({
      option: "",
      questionOptionError: "",
    });
    this.setState({ formValues });
    this.forceUpdate();
  }

  deleteChoicesQuestionFn(index, i, type) {
    console.log(type);

    let formValues = this.state.AddPageBelowForm;
    formValues[index]["allQuestions"].splice(i, 1);
    this.setState({
      formValues,
      pageQuestionInputIndex: 0,
      pageQuestionInputError: "",
    });
    this.forceUpdate();
  }
  deleteMoreChoicesOfQuestionFn(index, chqi, ci, type) {
    console.log(type);

    let formValues = this.state.AddPageBelowForm;
    formValues[index]["allQuestions"][chqi]["options"].splice(ci, 1);
    this.setState({ formValues });
    this.forceUpdate();
  }

  submit(data) {
    let _this = this;
    const {
      router: {
        history,
        route: {
          match: {
            params: { branch_id, business_id },
          },
        },
      },
    } = this.context;
    const { initialValues } = this.props;
    const {
      reviewMethod,
      reviewChannel,
      is_delete,
      showReviewMethods,
      reviewGating,
      isApplyToAll,
      include_location,
      design_setting,
    } = this.state;
    var newData = data;
    newData.applied_to_all = isApplyToAll;
    newData.include_location = include_location;
    newData.include_email = this.state.include_email;
    newData.include_dob = this.state.include_dob;
    newData.include_doa = this.state.include_doa;
    newData.include_gender = this.state.include_gender;
    newData.include_offer = this.state.include_offer;
    newData.show_state = this.state.show_state;
    newData.show_city = this.state.show_city;
    newData.enable_consent = this.state.enable_consent;
    newData.show_store_options_in_uppercase =
      this.state.show_store_options_in_uppercase;
    newData.consent_text = this.state.consent_text;
    newData.include_comment = this.state.include_comment;
    if (showReviewMethods) {
      newData.symbol_type = reviewMethod ? reviewMethod : "";
    } else if (!showReviewMethods)
      newData.symbol_type && delete newData.symbol_type;
    if (!showReviewMethods && newData.symbol_type === null) {
      newData.symbol_type = "";
    }
    newData.review_gating = reviewGating === undefined ? false : reviewGating;
    newData.review_channels = reviewChannel;
    newData.template_type = "email_template";
    newData.subject = null;
    newData.message = data.message ? data.message : null;
    newData.is_delete = is_delete;
    newData.action_type = "Generic";
    newData.id = this.state.current_id
      ? this.state.current_id
      : initialValues.id
      ? initialValues.id
      : null;
    const url = "/templates/generic/list";
    if (
      this.state.surveySectionActivation ||
      this.state.surveySectionActivationNegative
    ) {
      let surveyQuestionForm = this.state.AddPageBelowForm;
      for (let si = 0; si < surveyQuestionForm?.length; si++) {
        if (surveyQuestionForm[si]["allQuestions"]?.length === 0) {
          surveyQuestionForm[si]["pageQuestionAddError"] =
            "Please add question.";
        } else {
          for (
            let qi = 0;
            qi < surveyQuestionForm[si]["allQuestions"]?.length;
            qi++
          ) {
            if (surveyQuestionForm[si]["allQuestions"][qi]["input"] === "") {
              surveyQuestionForm[si]["allQuestions"][qi]["questionError"] =
                "Please enter question title.";
            }
            if (
              surveyQuestionForm[si]["allQuestions"][qi]["type"] ===
                "choicesQues" ||
              surveyQuestionForm[si]["allQuestions"][qi]["type"] ===
                "checkboxQues" ||
              surveyQuestionForm[si]["allQuestions"][qi]["type"] ===
                "dropdownQues"
            ) {
              for (
                let ci = 0;
                ci <
                surveyQuestionForm[si]["allQuestions"][qi]["options"]?.length;
                ci++
              ) {
                if (
                  surveyQuestionForm[si]["allQuestions"][qi]["options"][ci][
                    "option"
                  ] === ""
                ) {
                  surveyQuestionForm[si]["allQuestions"][qi]["options"][ci][
                    "questionOptionError"
                  ] = "Please enter question option";
                }
              }
            }
            if (
              surveyQuestionForm[si]["allQuestions"][qi]["type"] ===
                "ratingQues" ||
              surveyQuestionForm[si]["allQuestions"][qi]["type"] ===
                "scoreQues" ||
              surveyQuestionForm[si]["allQuestions"][qi]["type"] ===
                "fiveFacesEmoji"
            ) {
              if (
                surveyQuestionForm[si]["allQuestions"][qi][
                  "HighRatingInput"
                ] === ""
              ) {
                surveyQuestionForm[si]["allQuestions"][qi][
                  "HighRatingInputError"
                ] = "Please enter high rating.";
              }
              if (
                surveyQuestionForm[si]["allQuestions"][qi]["lowRatingInput"] ===
                ""
              ) {
                surveyQuestionForm[si]["allQuestions"][qi][
                  "lowRatingInputError"
                ] = "Please enter low rating.";
              }
            }

            if (surveyQuestionForm[si]["allQuestions"][qi]["edit"] === false) {
              surveyQuestionForm[si]["allQuestions"][qi]["doneError"] =
                "Please submit survey questions.";
              break;
            }
          }
        }
      }

      this.setState({ surveyQuestionForm });
      let isIssueGenerated = false;
      let issueIndex = -1;
      var exit_loops = false;
      for (let si = 0; si < surveyQuestionForm?.length; si++) {
        if (surveyQuestionForm[si]["allQuestions"]?.length === 0) {
          isIssueGenerated = true;
          issueIndex = si;
          exit_loops = true;
          break;
        } else {
          for (
            let qi = 0;
            qi < surveyQuestionForm[si]["allQuestions"]?.length;
            qi++
          ) {
            if (surveyQuestionForm[si]["allQuestions"][qi]["input"] === "") {
              isIssueGenerated = true;
              issueIndex = si;
              exit_loops = true;
              break;
            } else if (
              surveyQuestionForm[si]["allQuestions"][qi]["type"] ===
                "choicesQues" ||
              surveyQuestionForm[si]["allQuestions"][qi]["type"] ===
                "checkboxQues" ||
              surveyQuestionForm[si]["allQuestions"][qi]["type"] ===
                "dropdownQues"
            ) {
              var isError = false;
              for (
                let ci = 0;
                ci <
                surveyQuestionForm[si]["allQuestions"][qi]["options"]?.length;
                ci++
              ) {
                if (
                  surveyQuestionForm[si]["allQuestions"][qi]["options"][ci][
                    "option"
                  ] === ""
                ) {
                  isIssueGenerated = true;
                  issueIndex = si;
                  isError = true;
                }
              }
              if (isError) {
                exit_loops = true;
                break;
              }
            } else if (
              surveyQuestionForm[si]["allQuestions"][qi]["type"] ===
                "ratingQues" ||
              surveyQuestionForm[si]["allQuestions"][qi]["type"] ===
                "scoreQues" ||
              surveyQuestionForm[si]["allQuestions"][qi]["type"] ===
                "fiveFacesEmoji"
            ) {
              if (
                surveyQuestionForm[si]["allQuestions"][qi][
                  "HighRatingInput"
                ] === ""
              ) {
                isIssueGenerated = true;
                issueIndex = si;
                exit_loops = true;
                break;
              } else if (
                surveyQuestionForm[si]["allQuestions"][qi]["lowRatingInput"] ===
                ""
              ) {
                isIssueGenerated = true;
                issueIndex = si;
                exit_loops = true;
                break;
              }
            }
            if (surveyQuestionForm[si]["allQuestions"][qi]["edit"] === false) {
              isIssueGenerated = true;
              issueIndex = si;
              exit_loops = true;
              break;
            }
          }
        }
        if (exit_loops) {
          break;
        }
      }
      if (isIssueGenerated) {
        this.myRef[issueIndex].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        this.setState({ surveyFormSubmit: false });
        this.forceUpdate();
      } else {
        _this.state.surveyFormSubmit = true;
        _this.forceUpdate();
      }
    }
    let new_design_data = design_setting;
    let selected_font_index = design_setting.fonts.findIndex(
      (fnt) => fnt.selected === true
    );
    let selected_bgcolor_index = design_setting.background_color.findIndex(
      (bgcolor) => bgcolor.selected === true
    );
    new_design_data.que_bg_color = this.state.queBgColor;
    new_design_data.que_text_color = this.state.queTextColor;

    if (selected_font_index == -1) {
      new_design_data.fonts[0]["selected"] = true;
    }
    if (selected_bgcolor_index == -1) {
      new_design_data.background_color[0]["selected"] = true;
    }

    newData = {
      ...newData,
      background_image: this.state.image,
      logo: this.state.logo ? this.state.logo : "",
      thanks_page_image: this.state.thanksPageImage
        ? this.state.thanksPageImage
        : "",
      image: "",
      surveyQuestion: JSON.stringify(this.state.AddPageBelowForm),
      SurveySectionCheckbox: this.state.surveySectionActivation,
      SurveySectionCheckboxforNegative:
        this.state.surveySectionActivationNegative,
      landaing_page_design: JSON.stringify(new_design_data),
      domain_name:
        window.location && window.location.host ? window.location.host : "",
    };
    if (this.state.surveyFormSubmit) {
      newData = {
        ...newData,
        surveyQuestion: JSON.stringify(this.state.AddPageBelowForm),
      };
    }
    if (
      this.state.surveySectionActivation ||
      this.state.surveySectionActivationNegative
    ) {
      if (this.state.surveyFormSubmit) {
        return this.props
          .submitData(newData)
          .then((res) => {
            if (newData.id) history.push(`/${business_id}/${branch_id}${url}`);
            else
              this.setState({
                generatedLink: res.data.return_link,
                current_id: res.data.id,
              });
          })
          .catch((err) => {
            // utils.displayMessage("negative", err.review_channels);
            const errobj = {
              _error: err.non_field_errors,
              ...err,
            };
            this.setState({ error: errobj });
            throw new SubmissionError(errobj);
          });
      } else {
        const errobj = {
          "Survey Questions": "Please Submit Survey Questions.",
        };
        this.setState({ error: errobj });
        throw new SubmissionError(errobj);
      }
    } else {
      return this.props
        .submitData(newData)
        .then((res) => {
          if (newData.id) history.push(`/${business_id}/${branch_id}${url}`);
          else
            this.setState({
              generatedLink: res.data.return_link,
              current_id: res.data.id,
            });
        })
        .catch((err) => {
          // utils.displayMessage("negative", err.review_channels);
          const errobj = {
            _error: err.non_field_errors,
            ...err,
          };
          this.setState({ error: errobj });
          throw new SubmissionError(errobj);
        });
    }
  }

  componentDidMount() {
    this.removeLastBox();
    var survey_pages_array = [];
    if (
      this.props.surveySectionActivation === true ||
      this.props.surveySectionActivationNegative === true
    ) {
      survey_pages_array = this.handleFormDataInitialize();
    } else {
      survey_pages_array.push({
        page: "",
        title: "",
        pageQuestionAddError: "",
        titleBoolean: true,
        edit: false,
        titleError: "",
        allQuestions: [],
      });
    }
    this.setState({ AddPageBelowForm: survey_pages_array });
  }

  componentDidUpdate() {
    // check for max charcter

    let { landingPageBody, change } = this.props;

    let landingPageBodyChars;

    if (landingPageBody && landingPageBody?.length > 2000) {
      landingPageBody = landingPageBody.substring(0, 2000);
      landingPageBodyChars = 0;
      change("message", landingPageBody);
    } else {
      landingPageBodyChars =
        2000 - (landingPageBody ? landingPageBody?.length : 0);
    }
    this.setState({ landingPageBodyChars });
  }

  downloadQR = (e) => {
    const canvas = document.getElementById("generatedCode");
    e.preventDefault();
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    // downloadLink.download = 'ReviewQRCode.png';
    downloadLink.download = this.props.initialValues.name + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  copyGeneratedLink = (link) => {
    utils.copyToClipboard(link);
  };

  ParametersFieldArray = ({ fields }) => {
    let isDisabled = fields?.length >= 4 ? true : false;
    return (
      <div>
        {fields &&
          fields.map((member, index) => {
            return (
              <Row key={index}>
                <Col sm="12">
                  <FPLabel isRequired={false} className="mb-1">
                    Label {index + 2}
                  </FPLabel>
                </Col>
                <Col sm="4" className="d-flex align-items-center gap-10 mb-2">
                  <Field
                    name={member}
                    type="text"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    placeholder="Enter Label"
                    component={CustomInput}
                  ></Field>
                  <RemoveBtn onClick={() => fields.remove(index)} />
                </Col>
              </Row>
            );
          })}
        <div>
          {isDisabled && (
            <ErrorMessage>You can only add upto 4 extra Fields!</ErrorMessage>
          )}
        </div>
        <FPButton
          size="small"
          disabled={isDisabled}
          fontWeight={400}
          label="Add Parameter/Fields"
          variant="default"
          startIcon={<AddOutlinedIcon style={{ width: 16, height: 16 }} />}
          onClick={(e) => {
            e.preventDefault();
            fields.push();
          }}
        />
      </div>
    );
  };

  bgSelect = (data) => {
    this.setState({
      image: data.target.files[0],
      imgSrc: URL.createObjectURL(data.target.files[0]),
    });
  };
  logoSelect = (data_1) => {
    this.setState({
      logo: data_1.target.files[0],
      logoSrc: URL.createObjectURL(data_1.target.files[0]),
    });
  };
  thanksImageSelect = (data_2) => {
    this.setState({
      thanksPageImage: data_2.target.files[0],
      thanksPageImageSrc: URL.createObjectURL(data_2.target.files[0]),
    });
  };
  uploader = () => {
    return (
      <input
        id="file"
        hidden
        type="file"
        onChange={(data) => this.bgSelect(data)}
      />
    );
  };
  logoUploader = () => {
    return (
      <input
        id="file_1"
        hidden
        type="file"
        onChange={(data_1) => this.logoSelect(data_1)}
      />
    );
  };
  thanksPageImageUploader = () => {
    return (
      <input
        id="file_2"
        hidden
        type="file"
        onChange={(data_2) => this.thanksImageSelect(data_2)}
      />
    );
  };
  // errorModalCallback(data){
  //   if(data === false){
  //     this.setState({errorModalShow:false});
  //   }
  // }

  questionList(pindex) {
    return [
      {
        label: "Single Choice",
        value: "choice",
        onClick: () => this.choiceQuestinFunction(pindex, "choices"),
      },
      {
        label: "Multiple Selection",
        value: "checkbox",
        onClick: () => this.choiceQuestinFunction(pindex, "checkbox"),
      },
      {
        label: "Dropdown",
        value: "dropdown",
        onClick: () => this.choiceQuestinFunction(pindex, "dropdown"),
      },
      {
        label: "Short Answer",
        value: "shortAnswer",
        onClick: () => this.choiceQuestinFunction(pindex, "shortAnswer"),
      },
      {
        label: "Long Answer",
        value: "paragraph",
        onClick: () => this.choiceQuestinFunction(pindex, "paragraph"),
      },
      {
        label: "Rating",
        value: "rating",
        onClick: () => this.choiceQuestinFunction(pindex, "rating"),
      },
      {
        label: "Net Promoter Score",
        value: "score",
        onClick: () => this.choiceQuestinFunction(pindex, "score"),
      },
      {
        label: "Attachment",
        value: "attachment",
        onClick: () => this.choiceQuestinFunction(pindex, "attachment"),
      },
      {
        label: "Five Faces Emoji",
        value: "fiveFacesEmoji",
        onClick: () => this.choiceQuestinFunction(pindex, "fiveFacesEmoji"),
      },
    ];
  }

  handleFormDataInitialize() {
    var survey_pages_array = [];
    this.props.surveyPages?.length &&
      this.props.surveyPages.map((page) => {
        var page_obj = {
          page_id: page.page_id,
          page: "",
          title: page.title,
          pageQuestionAddError: "",
          titleBoolean: true,
          edit: true,
          titleError: "",
        };
        var question_array = [];
        page.allQuestions.map((question) => {
          var question_type = "choicesQues";
          if (question.question_type == "Checkbox") {
            question_type = "checkboxQues";
          }
          if (question.question_type == "Drop-down") {
            question_type = "dropdownQues";
          }
          if (question.question_type == "SHORT ANSWER") {
            question_type = "shortAnswerQues";
          }
          if (question.question_type == "PARAGRAPH") {
            question_type = "paragraphQues";
          }
          if (question.question_type == "RATING") {
            question_type = "ratingQues";
          }
          if (question.question_type == "NPS QUESTION") {
            question_type = "scoreQues";
          }
          if (question.question_type == "ATTACHMENT") {
            question_type = "attachmentQues";
          }
          if (question.question_type == "fiveFacesEmoji") {
            question_type = "fiveFacesEmoji";
          }
          var question_obj = {
            question_id: question.question_id,
            type: question_type,
            input: question.input,
            required: question.question_required,
            edit: true,
            questionError: "",
            shortFormQuestion: question.question_short_form,
            doneError: "",
          };
          var option_array = [];
          if (
            question_type == "choicesQues" ||
            (question_type == "checkboxQues") |
              (question_type == "dropdownQues")
          ) {
            question.options.map((option) => {
              var option_obj = {
                id: option.id,
                option: option.title,
                questionOptionError: "",
              };
              option_array.push(option_obj);
            });
          }
          if (
            question_type == "ratingQues" ||
            question_type == "scoreQues" ||
            question_type == "fiveFacesEmoji"
          ) {
            question_obj["lowRatingInput"] = question.lowRatingInput;
            question_obj["HighRatingInput"] = question.HighRatingInput;
          }
          if (option_array?.length > 0) {
            question_obj["options"] = option_array;
          }
          question_array.push(question_obj);
        });
        page_obj["allQuestions"] = question_array;
        survey_pages_array.push(page_obj);
      });
    return survey_pages_array;
  }

  handleReset() {
    this.setState({
      isApplyToAll:
        this.props.initialValues && this.props.initialValues.applied_to_all
          ? this.props.initialValues.applied_to_all
          : false,

      //* Contact Page Details
      include_email:
        this.props.initialValues && this.props.initialValues.include_email
          ? this.props.initialValues.include_email
          : false,
      include_dob:
        this.props.initialValues && this.props.initialValues.include_dob
          ? this.props.initialValues.include_dob
          : false,
      include_doa:
        this.props.initialValues && this.props.initialValues.include_doa
          ? this.props.initialValues.include_doa
          : false,
      include_gender:
        this.props.initialValues && this.props.initialValues.include_gender
          ? this.props.initialValues.include_gender
          : false,
      include_offer:
        this.props.initialValues && this.props.initialValues.include_offer
          ? this.props.initialValues.include_offer
          : false,

      //* Landing Page Details
      include_location:
        this.props.initialValues && this.props.initialValues.include_location
          ? this.props.initialValues.include_location
          : false,
      include_comment:
        this.props.initialValues &&
        this.props.initialValues.include_comment !== undefined
          ? this.props.initialValues.include_comment
          : true,
      show_city:
        this.props.initialValues && this.props.initialValues.show_city
          ? this.props.initialValues.show_city
          : false,
      show_state:
        this.props.initialValues && this.props.initialValues.show_state
          ? this.props.initialValues.show_state
          : false,
      enable_consent:
        this.props.initialValues && this.props.initialValues.enable_consent
          ? this.props.initialValues.enable_consent
          : false,
      show_store_options_in_uppercase:
        this.props.initialValues &&
        this.props.initialValues.show_store_options_in_uppercase
          ? this.props.initialValues.show_store_options_in_uppercase
          : false,
      consent_text:
        this.props.initialValues &&
        this.props.initialValues.consent_text !== undefined
          ? this.props.initialValues.consent_text
          : null,

      //* Show Review Methods
      showReviewMethods:
        this.props.initialValues && this.props.initialValues.symbol_type
          ? true
          : false,
      reviewMethod:
        this.props.initialValues && this.props.initialValues.symbol_type
          ? this.props.initialValues.symbol_type
          : "thumb",
      reviewGating:
        this.props.initialValues && this.props.initialValues.review_gating,

      //* Review Channel
      surveySectionActivation: this.props.surveySectionActivation,
      surveySectionActivationNegative:
        this.props.surveySectionActivationNegative,
      reviewChannels: this.props.initialValues
        ? this.props.initialValues.review_channels
        : this.state.review_channels,
      AddPageBelowForm: this.handleFormDataInitialize(), // * form

      // * logos
      imgSrc: this.props.businessBg || this.state.imgSrc,
      logoSrc: this.props.businessLogo || this.state.logoSrc,
      // thanksPageImageSrc: this.props.businessLogo || this.state.logoSrc,
    });

    this.props.reset();
    utils.displayMessage("success", "Form reset successfully");
  }
  checkValidation(submitFunc) {
    return async (event) => {
      await submitFunc(event);
      utils.scrollToFirstElement("with-error");
    };
  }

  handleFontChange = (val) => {
    let old_setting = this.state.design_setting;
    let updated_setting_obj = old_setting.fonts.find((font_obj, font_index) => {
      if (font_obj.value == val) {
        let pre_obj = font_obj;
        pre_obj["selected"] = true;
        old_setting.fonts[font_index] = pre_obj;
      } else {
        let pre_selected_obj = font_obj;
        pre_selected_obj["selected"] = false;
        old_setting.fonts[font_index] = pre_selected_obj;
      }
    });
    this.setState({ design_setting: old_setting, selected_font: val });
  };

  handleBgColorChange = (val) => {
    let old_setting = this.state.design_setting;
    let updated_setting_obj = old_setting.background_color.find(
      (bg_obj, bg_index) => {
        if (bg_obj.code == val) {
          let pre_obj = bg_obj;
          pre_obj["selected"] = true;
          old_setting.background_color[bg_index] = pre_obj;
        } else {
          let pre_selected_obj = bg_obj;
          pre_selected_obj["selected"] = false;
          old_setting.background_color[bg_index] = pre_selected_obj;
        }
      }
    );
    this.setState({ design_setting: old_setting, selected_bg_color: val });
  };

  handleShowVerticalChange = (checked) => {
    let old_setting = this.state.design_setting;
    old_setting.vertical = checked;
    this.setState({ design_setting: old_setting, vertical_emoji: checked });
  };

  handleHeadingChange = (val) => {
    this.setState({ headingValue: val });
  };
  handleConsentChange = (val) => {
    this.setState({ consent_text: val });
  };

  handleBodyChange = (val) => {
    this.setState({ bodyValue: val });
  };

  rgbaToHex(r, g, b, a) {
    r = Math.max(0, Math.min(255, r));
    g = Math.max(0, Math.min(255, g));
    b = Math.max(0, Math.min(255, b));

    const rgbToHex = (value) => {
      let hex = value.toString(16);
      return hex?.length === 1 ? "0" + hex : hex;
    };

    const alphaToHex = (alpha) => {
      return Math.round(alpha * 255)
        .toString(16)
        .padStart(2, "0");
    };

    return `#${rgbToHex(r)}${rgbToHex(g)}${rgbToHex(b)}${alphaToHex(a)}`;
  }

  handleQueBgColor = (val) => {
    let design_con = this.state.design_setting;
    design_con.que_bg_color = val.hex;
    const hexColor = this.rgbaToHex(val.rgb.r, val.rgb.g, val.rgb.b, val.rgb.a);
    this.setState({
      queBgColor: hexColor,
      design_setting: design_con,
      queRgbBgColor: val.rgb,
    });
  };

  handleWheelQueBgColor = (val) => {
    let design_con = this.state.design_setting;
    design_con.que_bg_color = val.hex;
    const hexColor = this.rgbaToHex(
      val.rgba.r,
      val.rgba.g,
      val.rgba.b,
      val.rgba.a
    );
    this.setState({
      queBgColor: hexColor,
      design_setting: design_con,
      queRgbBgColor: val.rgba,
    });
  };

  handleQueTextColor = (value) => {
    let design_con = this.state.design_setting;
    design_con.que_text_color = value;
    this.setState({ queTextColor: value, design_setting: design_con });
  };

  removeLastBox() {
    const twitterPicker = document.querySelector(".custom-twitter-picker");
    if (twitterPicker) {
      const unwantedBox = twitterPicker.querySelector(
        'div[style*="background: rgb(240, 240, 240)"]'
      );
      if (unwantedBox) {
        unwantedBox.remove();
      }
    }
  }

  render() {
    const {
      submitting,
      handleSubmit,
      errors,
      initialValues,
      fieldArray,
      reviewChannels,
      nameDisabled,
      location: { search },
      businessLogo,
      businessBg,
    } = this.props;
    // eslint-disable-next-line
    const {
      landingPageBodyChars,
      reviewMethod,
      showReviewMethods,
      reviewGating,
      generatedLink,
      isApplyToAll,
      include_location,
      surveySectionActivation,
      surveySectionActivationNegative,
      dropdownOpen,
      AddQuestionDropdownOpen,
      AddPageBelowForm,
      MainDropdownIndex,
      ReAddQuestionOpen,
      choiceQuestinDropDown,
      design_setting,
      vertical_emoji,
      queBgColor,
      queTextColor,
      queRgbBgColor,
    } = this.state;
    const { id } = queryString.parse(search);
    const {
      router: {
        route: {
          match: {
            params: { branch_id, business_id },
          },
        },
      },
    } = this.context;

    const breadCrumbList = [
      {
        label: "Templates",
        link: `/${business_id}/${branch_id}/templates/list?page_size=10`,
      },
      {
        label: "Generic Templates",
        link: `/${business_id}/${branch_id}/templates/generic/list?page_size=10`,
      },
      {
        label: "Create Generic Templates",
        link: "",
      },
    ];
    return (
      <form
        className="grid-form generic-template-form"
        onSubmit={this.checkValidation(handleSubmit(this.submit))}
      >
        {errors && errors.non_field_errors
          ? errors.non_field_errors.map((error, index) => (
              <p key={index} className="text-danger">
                {error}
              </p>
            ))
          : null}
        {/* Title section */}
        <Row>
          <Col>
            <FPBreadcrumbs list={breadCrumbList} />
          </Col>
        </Row>
        <Row className="mt-20">
          <Col>
            <section className="d-flex flex-column gap-20">
              {/* Create Generic Templates */}
              <CardWrapper
                className="fp-card-outline-secondary"
                headerTitle={
                  <div className="d-flex justify-content-between">
                    <span className="font-secondary">
                      Create Generic Template Form
                    </span>
                    <div>
                      <FPButton
                        style={{ height: 26 }}
                        onClick={() => this.handleReset()}
                        label="Clear"
                        variant="outline"
                        className="mr-2"
                      />
                      <FPButton
                        style={{ height: 26 }}
                        type="submit"
                        disabled={
                          submitting ||
                          (!generatedLink && !initialValues.link) ||
                          !id
                        }
                        label="Save"
                      />
                    </div>
                  </div>
                }
                showDivider={false}
                contentClass="p-4"
              >
                <div className="d-flex align-items-center gap-10">
                  <div className="mt-3 generic-templates-branch col-6">
                    <BranchSelector
                      disabled={!!id}
                      className="height-48 w-100"
                    />
                  </div>
                  <div className="col-6">
                    <Box
                      sx={{
                        "& label": {
                          color: "#1B1C1D",
                          fontWeight: 500,
                        },
                      }}
                    >
                      <FPCheckbox
                        checked={isApplyToAll}
                        label="Apply to all branches"
                        disabled={initialValues.id || this.state.generatedLink}
                        onClick={this.handleApplyToAll}
                        className="primary"
                      />
                    </Box>
                  </div>
                </div>
              </CardWrapper>

              {/* Template Name */}
              <CardWrapper
                headerTitle="Template Name"
                contentClass="pb-2 pt-4 px-4"
              >
                <Row fluid>
                  <Col xs={12} sm={12} md={6}>
                    <FPLabel> Name </FPLabel>
                    <Field
                      name="name"
                      type="text"
                      component={InputComponent}
                      serverError={errors && errors.name}
                      disabled={
                        (initialValues && initialValues.is_default) ||
                        nameDisabled
                      }
                      placeholder="Add template name"
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <FPLabel>Sub Type</FPLabel>
                    <Field
                      name="sub_type"
                      type="text"
                      component={InputComponent}
                      serverError={errors && errors.name}
                      placeholder="Add Sub Type"
                    />
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col>
                    <FPLabel required={false}>Template Tags</FPLabel>
                    <Field
                      name="tags"
                      type="text"
                      component={InputComponent}
                      placeholder="Add Template Tags"
                    />
                  </Col>
                </Row>
              </CardWrapper>

              {/* Contact Page Details */}
              <CardWrapper
                headerTitle="Contact Page Details"
                contentClass="p-4"
              >
                <div className="d-flex flex-column gap-10">
                  <Row>
                    <Col xs={12} sm={12} md={6}>
                      <div className="d-flex justify-content-between align-items-start">
                        <FPLabel>Name</FPLabel>
                        <FPToolTip
                          title={"Name from Contact Page.It is mandatory!"}
                        >
                          <Checkbox toggle disabled={true} checked={true} />
                        </FPToolTip>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                      <div className="d-flex justify-content-between align-items-start">
                        <FPLabel>Mobile Number</FPLabel>
                        <FPToolTip
                          title={
                            "Mobile Number from Contact Page.It is mandatory!"
                          }
                        >
                          <Checkbox toggle disabled={true} checked={true} />
                        </FPToolTip>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={6}>
                      <div className="d-flex justify-content-between align-items-start">
                        <FPLabel required={false}>
                          Email
                          {/* Email {this.state.include_email.toString()}{" "} */}
                        </FPLabel>
                        <FPToolTip title={"Email from Contact Page."}>
                          <Checkbox
                            toggle
                            checked={this.state.include_email}
                            onClick={this.handleIncludeEmail}
                          />
                        </FPToolTip>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                      <div className="d-flex justify-content-between align-items-start">
                        <FPLabel required={false}>Date of Birth</FPLabel>
                        <FPToolTip title={"Date of Birth from Contact Page."}>
                          <Checkbox
                            toggle
                            checked={this.state.include_dob}
                            onClick={this.handleIncludeDob}
                          />
                        </FPToolTip>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={6}>
                      <div className="d-flex justify-content-between align-items-start">
                        <FPLabel required={false}>Date of Anniversary</FPLabel>
                        <FPToolTip
                          title={"Date of Anniversary from Contact Page."}
                        >
                          <Checkbox
                            toggle
                            checked={this.state.include_doa}
                            onClick={this.handleIncludeDoa}
                          />
                        </FPToolTip>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                      <div className="d-flex justify-content-between align-items-start">
                        <FPLabel required={false}>Gender</FPLabel>
                        <FPToolTip title={"Gender from Contact Page."}>
                          <Checkbox
                            toggle
                            checked={this.state.include_gender}
                            onClick={this.handleIncludegender}
                          />
                        </FPToolTip>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={6}>
                      <div className="d-flex justify-content-between align-items-start">
                        <FPLabel required={false}>Enable Consent</FPLabel>
                        <FPToolTip title={"Show consent text on Contact Page."}>
                          <Checkbox
                            toggle
                            checked={this.state.enable_consent}
                            onClick={this.handleEnableConsent}
                          />
                        </FPToolTip>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                      <div className="d-flex justify-content-between align-items-start">
                        <FPLabel required={false}>Include Offer</FPLabel>
                        <FPToolTip title={"Show Offer on Thank you Page."}>
                          <Checkbox
                            toggle
                            checked={this.state.include_offer}
                            onClick={this.handleIncludeOffer}
                          />
                        </FPToolTip>
                      </div>
                    </Col>
                  </Row>

                  {this.state.enable_consent && (
                    <Row fluid>
                      <Col xs={12} sm={12} md={12}>
                        <div className="d-flex gap-15">
                          <FPLabel required={false}>Consent Text</FPLabel>
                          <FPToolTip
                            arrow={true}
                            theme={"dark"}
                            maxWidth={150}
                            margin="5px 0px"
                            placement={"bottom"}
                            title={
                              "To add link on any word add {Link- https://google.com} just after the word."
                            }
                          >
                            <div>
                              <InfoOutlinedIcon
                                style={{
                                  color: "#646565",
                                  height: "18px",
                                  width: "18px",
                                }}
                              />
                            </div>
                          </FPToolTip>
                        </div>
                        <Field
                          name="consent_text"
                          type="text"
                          component={InputComponent}
                          serverError={errors && errors.consent_text}
                          onChange={(e, val) => {
                            this.handleConsentChange(val);
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              </CardWrapper>

              {/* Landing Page Details */}
              <CardWrapper
                headerTitle="Landing Page Details"
                contentClass="p-4"
              >
                <Row fluid>
                  <Col xs={12} sm={12} md={6}>
                    <FPLabel> Heading </FPLabel>
                    <Field
                      name="heading"
                      type="text"
                      component={InputComponent}
                      serverError={errors && errors.heading}
                      onChange={(e, val) => {
                        this.handleHeadingChange(val);
                      }}
                    />
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <FPLabel isRequired={false}> Thanks Line </FPLabel>
                    <Field
                      name="thanks_line"
                      type="text"
                      component={InputComponent}
                      serverError={errors && errors.heading}
                      placeholder="Add Thanks Line"
                    />
                  </Col>
                </Row>
                <Row fluid className="mt-3">
                  <Col xs={12} sm={12} md={6}>
                    <FPLabel isRequired={false}> Landing Page Heading </FPLabel>
                    <Field
                      name="landing_page_heading"
                      type="text"
                      component={InputComponent}
                      placeholder="Landing Page Heading"
                    />
                  </Col>
                </Row>
                <Row fluid className="mt-3">
                  <Col>
                    <FPLabel isRequired={false}> Body Text </FPLabel>
                    <Field
                      name="message"
                      type="text"
                      component={TextAreaComponent}
                      serverError={errors && errors.heading}
                      onChange={(e, val) => {
                        this.handleBodyChange(val);
                      }}
                    />
                    <small className="text-right mt-1 d-block">
                      {landingPageBodyChars} characters left.
                    </small>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12} sm={12} md={6}>
                    <div className="d-flex justify-content-between align-items-start">
                      <FPLabel isRequired={false}>
                        Display Store Name in Banner
                      </FPLabel>
                      <Checkbox
                        toggle
                        checked={include_location}
                        onClick={this.handleTakeStoreDetails}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="d-flex justify-content-between align-items-start">
                      <FPLabel isRequired={false}>
                        Show Review Comment Box
                      </FPLabel>
                      <Checkbox
                        toggle
                        checked={this.state.include_comment}
                        onClick={this.handleReviewComment}
                      />
                    </div>
                  </Col>
                </Row>
                {include_location && (
                  <Row className="mt-3">
                    <Col xs={12} sm={12} md={6}>
                      <div className="d-flex justify-content-between align-items-start">
                        <FPLabel isRequired={false}>Show State</FPLabel>
                        <Checkbox
                          toggle
                          checked={this.state.show_state}
                          onClick={this.handleShowState}
                        />
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                      <div className="d-flex justify-content-between align-items-start">
                        <FPLabel isRequired={false}>Show City</FPLabel>
                        <Checkbox
                          toggle
                          checked={this.state.show_city}
                          onClick={this.handleShowCity}
                        />
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} className="mt-3">
                      <div className="d-flex justify-content-between align-items-start">
                        <FPLabel isRequired={false}>
                          Show State and City In Upper Case
                        </FPLabel>
                        <Checkbox
                          toggle
                          checked={this.state.show_store_options_in_uppercase}
                          onClick={this.handleShowUpperCaseOptions}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </CardWrapper>

              {/* Show Review Methods */}
              <CardWrapper
                headerTitle={
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Review Methods</span>
                    <Checkbox
                      toggle
                      checked={showReviewMethods}
                      onClick={this.handleShowReviewMethods}
                    />
                  </div>
                }
                contentClass="p-4"
                showDivider={showReviewMethods}
              >
                <Collapse in={showReviewMethods}>
                  <Row>
                    <Col sm={12}>
                      <ReviewMethodsOptions
                        reviewMethod={reviewMethod}
                        handleReviewMethod={this.handleReviewMethod}
                      />
                    </Col>
                    {/* <Col sm={12}>
                      <div className="d-flex justify-content-between align-items-start mt-3">
                        <FPLabel required={false}>Review Gating</FPLabel>
                        <Checkbox
                          toggle
                          checked={reviewGating}
                          onClick={this.handleReviewGating}
                        />
                      </div>
                    </Col> */}
                  </Row>
                </Collapse>
              </CardWrapper>

              <CardWrapper headerTitle="Page Design" contentClass="p-4">
                <div className="d-flex flex-column gap-10">
                  <Row fluid>
                    <Col xs={12} sm={12} md={4}>
                      <div className="design_setting">
                        <Row fluid>
                          <Col xs={12} sm={12} md={12}>
                            <FPLabel> Font </FPLabel>
                            <Field
                              name="font_type"
                              component={SelectComponent}
                              labelKey="name"
                              valueKey="value"
                              options={design_setting.fonts}
                              val={this.state.selected_font}
                              simpleValue
                              onChange={(e, val) => {
                                this.handleFontChange(val);
                              }}
                            />
                          </Col>
                        </Row>
                        <Row fluid className="mb-5">
                          <Col xs={12} sm={12} md={12}>
                            <FPLabel className="mt-3">
                              {" "}
                              Background Color{" "}
                            </FPLabel>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "20px",
                                padding: "30px 12px",
                                border: "1px solid #22242626",
                                borderRadius: "20px",
                                boxShadow:
                                  "0px 1px 2px 0 rgba(34, 36, 38, 0.15)",
                              }}
                              className="h-100 w-100"
                            >
                              {/* <SketchPicker color={queBgColor} onChangeComplete={this.handleQueBgColor} /> */}
                              <div className="d-flex justify-content-center">
                                <Wheel
                                  color={queBgColor}
                                  onChange={(color) => {
                                    this.handleWheelQueBgColor(color);
                                  }}
                                  className="custom-wheel-picker"
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                <p style={{ margin: 0, fontSize: "14px" }}>
                                  HEX
                                </p>
                                <p
                                  style={{
                                    padding: "5px",
                                    border: "1px solid #7A8193",
                                    borderRadius: "10px",
                                    margin: 0,
                                    fontSize: "16px",
                                    color: "#7A8193",
                                  }}
                                >
                                  {queBgColor.slice(1)}
                                </p>
                              </div>

                              <div className="d-flex justify-content-center">
                                <AlphaPicker
                                  color={queRgbBgColor}
                                  onChangeComplete={this.handleQueBgColor}
                                />
                              </div>

                              <div className="d-flex justify-content-center">
                                <HuePicker
                                  color={queBgColor}
                                  onChangeComplete={this.handleQueBgColor}
                                />
                              </div>
                              <div className="d-flex justify-content-center items-center">
                                <TwitterPicker
                                  className="custom-twitter-picker"
                                  color={queBgColor}
                                  onChangeComplete={this.handleQueBgColor}
                                  triangle="hide"
                                  colors={[
                                    "#6091FF",
                                    "#69FEFF",
                                    "#7FFF6F",
                                    "#BF4B26",
                                    "#F43135",
                                    "#C73760",
                                    "#9629D4",
                                  ]}
                                />
                              </div>
                            </div>
                            {/* <Field
                              name="bg_color_code"
                              component={SelectComponent}
                              labelKey="name" 
                              valueKey="code"
                              options={design_setting.background_color}
                              val={this.state.selected_bg_color}
                              simpleValue
                              onChange={(e, val) => {
                                this.handleBgColorChange(val);
                              }}
                            /> */}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                      <div>
                        <div className="d-flex flex-column w-100">
                          <div>
                            <FPLabel required={false}>Text Color</FPLabel>
                          </div>
                          <div
                            style={{ gap: "10px" }}
                            className="d-flex justify-content-center flex-wrap"
                          >
                            <div
                              style={{
                                border: "1px solid #BFC2CB",
                                padding: "8px 40px",
                                borderRadius: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexGrow: "1",
                                width: "auto",
                              }}
                            >
                              <div className="radio-group">
                                <input
                                  type="radio"
                                  id="black"
                                  name="queTextColorRadio"
                                  value="black"
                                  checked={queTextColor === "#000000"}
                                  onChange={() =>
                                    this.handleQueTextColor("#000000")
                                  }
                                />
                                <label htmlFor="black">Black</label>
                              </div>
                            </div>
                            <div
                              style={{
                                border: "1px solid #BFC2CB",
                                padding: "8px 40px",
                                borderRadius: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexGrow: "1",
                                width: "auto",
                              }}
                            >
                              <div className="radio-group">
                                <input
                                  type="radio"
                                  id="white"
                                  name="queTextColorRadio"
                                  value="white"
                                  checked={queTextColor === "#FFFFFF"}
                                  onChange={() =>
                                    this.handleQueTextColor("#FFFFFF")
                                  }
                                />
                                <label htmlFor="white">White</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="mt-3">Experience Page Preview</p>
                      <ExperiencePagePreview
                        ratingType={reviewMethod}
                        body={this.state.bodyValue}
                        businessLogo={businessLogo && businessLogo}
                        include_comment={this.state.include_comment}
                        heading={this.state.headingValue}
                        selectedBgColor={this.state.queBgColor}
                        selectedFontType={this.state.selected_font}
                        Isvertical={this.state.design_setting.vertical}
                        selectedQueTextColor={this.state.queTextColor}
                        showReviewMethods={showReviewMethods}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={4}>
                      <div>
                        <div className="d-flex flex-column w-100">
                          <div>
                            <FPLabel required={false}>Show Vertical</FPLabel>
                          </div>
                          <div
                            className="d-flex justify-content-center flex-wrap"
                            style={{ gap: "10px" }}
                          >
                            <FPButton
                              label="On"
                              onClick={() =>
                                this.handleShowVerticalChange(true)
                              }
                              backgroundColor={
                                vertical_emoji ? "#F13A88" : "#ffffff"
                              }
                              textColor={vertical_emoji ? "#ffffff" : "#7A8193"}
                              variant={"outline"}
                              borderWidth={1}
                              borderColor={
                                vertical_emoji ? "#F13A88" : "#7A8193"
                              }
                              style={{
                                border: "1px solid #BFC2CB",
                                padding: "8px 40px",
                                borderRadius: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexGrow: "1",
                                width: "auto",
                              }}
                            />
                            <FPButton
                              label="Off"
                              onClick={() =>
                                this.handleShowVerticalChange(false)
                              }
                              backgroundColor={
                                !vertical_emoji ? "#F13A88" : "#ffffff"
                              }
                              textColor={
                                !vertical_emoji ? "#ffffff" : "#7A8193"
                              }
                              variant={"outline"}
                              borderWidth={1}
                              borderColor={
                                !vertical_emoji ? "#F13A88" : "#7A8193"
                              }
                              style={{
                                border: "1px solid #BFC2CB",
                                padding: "8px 40px",
                                borderRadius: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexGrow: "1",
                                width: "auto",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <p className="mt-3">Boday Text</p>
                      <SurveyPagePreview
                        businessLogo={businessLogo && businessLogo}
                        selectedBgColor={this.state.queBgColor}
                        selectedFontType={this.state.selected_font}
                        Isvertical={this.state.design_setting.vertical}
                        selectedQueTextColor={this.state.queTextColor}
                      />
                    </Col>
                  </Row>
                </div>
              </CardWrapper>

              {/* Review Channel */}
              <CardWrapper headerTitle="Review Channel" contentClass="p-4">
                <div className="d-flex flex-column gap-10">
                  {reviewChannels && reviewChannels ? (
                    <MultipleButtonSelect
                      reviewChannels={reviewChannels && reviewChannels}
                      handleReviewChannel={this.handleReviewChannel}
                      initialValues={
                        initialValues && initialValues.review_channels
                      }
                      serverError={errors && errors.name}
                    />
                  ) : (
                    ""
                  )}
                  {!this.state.generatedLink &&
                    this.state.error.review_channels && (
                      <p className="text-danger">
                        {this.state.error.review_channels}
                      </p>
                    )}
                </div>
                <div className="d-flex justify-content-between align-items-start mt-3">
                  <FPLabel required={false}>Review Gating</FPLabel>
                  <Checkbox
                    toggle
                    checked={reviewGating}
                    onClick={this.handleReviewGating}
                  />
                </div>
              </CardWrapper>
              {/* survey section */}
              <CardWrapper headerTitle="Survey Section" contentClass="p-4">
                <div className="d-flex justify-content-between align-items-start">
                  <FPLabel required={false}>Survey Section Activation</FPLabel>
                  <Checkbox
                    toggle
                    checked={surveySectionActivation}
                    onClick={this.handleShowSurveySectionActivation}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-start">
                  <FPLabel required={false}>
                    Survey Section Activation only in case of Negative Rating
                  </FPLabel>
                  <Checkbox
                    toggle
                    checked={surveySectionActivationNegative}
                    onClick={this.handleShowSurveySectionActivationNegative}
                  />
                </div>
                {(surveySectionActivation || surveySectionActivationNegative) &&
                  AddPageBelowForm.map((element, pindex) => (
                    <div
                      className="survey-section-div mb-3"
                      key={pindex}
                      ref={(ref) => (this.myRef[pindex] = ref)}
                    >
                      <Row>
                        <Col sm="3">
                          <div className="survey-section-page">
                            Page {pindex + 1} of {AddPageBelowForm?.length}
                          </div>
                        </Col>
                      </Row>
                      {element.titleBoolean === true ? (
                        <Row className="mt-3 mb-3">
                          <Col sm="12">
                            <div className="page-title-section">
                              <div className="page-title-input">
                                <Label for="surveyPageTitle" className="mt-0">
                                  {" "}
                                  Page title {" "} (optional){" "}
                                </Label>
                                <Input
                                  name="title"
                                  placeholder="Page title"
                                  type="text"
                                  value={element.title}
                                  onChange={(e) =>
                                    this.surveyTitleInputHandler(pindex, e)
                                  }
                                  readOnly={element.edit}
                                />
                                <p style={{ color: "red", fontSize: "12px" }}>
                                  {element.titleError}
                                </p>
                              </div>
                              <div className="mt-2 d-flex justify-content-end align-items-center gap-10">
                                <IconButton
                                  type="button"
                                  size="small"
                                  disabled={element.edit}
                                  onClick={() =>
                                    this.deleteSurveyPageTitle(pindex)
                                  }
                                  className="cursor-pointer"
                                >
                                  <img
                                    src={deleteIcon}
                                    role="button"
                                    className="cursor-pointer"
                                    alt="delete"
                                  />
                                </IconButton>
                                {element.edit === false ? (
                                  <FPButton
                                    type="button"
                                    onClick={() =>
                                      this.submitSurveyTitleInputHandler(
                                        pindex,
                                        element.edit
                                      )
                                    }
                                    size="small"
                                    label="Done"
                                  />
                                ) : (
                                  <FPButton
                                    endIcon={
                                      <EditOutlinedIcon
                                        style={{ height: 16, width: 16 }}
                                      />
                                    }
                                    type="button"
                                    onClick={() =>
                                      this.submitSurveyTitleInputHandler(
                                        pindex,
                                        element.edit
                                      )
                                    }
                                    size="small"
                                    label="Edit"
                                  />
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      {element.allQuestions?.length > 0 &&
                        element.allQuestions.map((question, qi) => {
                          //* Radio Question
                          if (question.type === "choicesQues") {
                            return (
                              <Row
                                className="mt-3 mb-3"
                                key={`${qi}-radio`}
                                id={`surveyQuestion${pindex}${qi}`}
                              >
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    {/* Title */}
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Choice question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) =>
                                              this.questionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.questionError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3">
                                      <Col sm={12}>
                                        <div className="choices-title-input pb-1">
                                          <FPLabel
                                            for="surveyPageTitle"
                                            isRequired={false}
                                          >
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) =>
                                              this.shortFormQuestionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <FPLabel
                                            className="mt-4 mb-0"
                                            isRequired={false}
                                          >
                                            Answer Options
                                          </FPLabel>

                                          <FPButton
                                            size="small"
                                            label={
                                              question.type === "choicesQues" &&
                                              this.questionList(pindex)[0].label
                                            }
                                            variant="outline"
                                            borderColor="#BFC2CB"
                                            textColor="#1B1C1D"
                                            className={"mt-2 mb-0"}
                                          />
                                        </div>
                                        <Divider className="mt-1 mb-0" />
                                      </Col>
                                    </Row>
                                    {/* options */}
                                    {question.options?.length > 0 &&
                                      question.options.map(
                                        (optiond, optionIndex) => (
                                          <Row
                                            className="mt-3"
                                            key={`${optionIndex}-radio-option`}
                                          >
                                            <Col>
                                              <div className="flex-between-center gap-10">
                                                <FormGroup
                                                  style={{
                                                    minHeight: "unset",
                                                  }}
                                                >
                                                  <Input
                                                    disabled={question.edit}
                                                    id="radioInput"
                                                    name="radio"
                                                    type="radio"
                                                    value="true"
                                                  />
                                                </FormGroup>
                                                <div className="question-choices-input flex-grow-1">
                                                  <Input
                                                    name="option"
                                                    placeholder="Option title"
                                                    type="text"
                                                    value={optiond.option}
                                                    onChange={(e) =>
                                                      this.questionOptionInputHandler(
                                                        pindex,
                                                        qi,
                                                        optionIndex,
                                                        e
                                                      )
                                                    }
                                                    readOnly={question.edit}
                                                  />
                                                </div>
                                                <div
                                                  style={{ width: "23px" }}
                                                  className="text-right"
                                                >
                                                  {optionIndex !== 0 && (
                                                    <RemoveBtn
                                                      disabled={
                                                        !(
                                                          question.edit ===
                                                          false
                                                        )
                                                      }
                                                      onClick={() =>
                                                        this.deleteMoreChoicesOfQuestionFn(
                                                          pindex,
                                                          qi,
                                                          optionIndex,
                                                          "choices"
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                              <ErrorMessage>
                                                {optiond.questionOptionError}
                                              </ErrorMessage>
                                            </Col>
                                          </Row>
                                        )
                                      )}
                                    {/* Actions */}
                                    <AddOptionBtn
                                      disabled={question.edit}
                                      onClick={() =>
                                        this.addMoreChoicesOfQuestionFn(
                                          pindex,
                                          qi,
                                          "choices"
                                        )
                                      }
                                    />
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDone={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "choices"
                                        )
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "choices"
                                        )
                                      }
                                      id={`choiceQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) =>
                                        this.requiredQuestinFunction(
                                          e,
                                          pindex,
                                          qi,
                                          "choices"
                                        )
                                      }
                                      onDelete={() =>
                                        this.deleteChoicesQuestionFn(
                                          pindex,
                                          qi,
                                          "choices"
                                        )
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * CheckBox Question
                          if (question.type === "checkboxQues") {
                            return (
                              <Row
                                className="mt-3 mb-3"
                                key={`${qi}-checkbox`}
                                id={`surveyQuestion${pindex}${qi}`}
                              >
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Checkbox question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) =>
                                              this.questionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.questionError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel
                                            for="surveyPageTitle"
                                            isRequired={false}
                                          >
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) =>
                                              this.shortFormQuestionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <FPLabel
                                            className="mt-4 mb-0"
                                            isRequired={false}
                                          >
                                            Answer Options
                                          </FPLabel>
                                          <FPButton
                                            size="small"
                                            label={
                                              question.type ===
                                                "checkboxQues" &&
                                              this.questionList(pindex)[1].label
                                            }
                                            variant="outline"
                                            borderColor="#BFC2CB"
                                            textColor="#1B1C1D"
                                            className={"mt-2 mb-0"}
                                          />
                                        </div>
                                        <Divider className="mt-1 mb-0" />
                                      </Col>
                                    </Row>
                                    {/* options */}
                                    {question.options?.length > 0 &&
                                      question.options.map(
                                        (optionCD, optionCIndex) => (
                                          <Row
                                            className="mt-3"
                                            key={`${optionCIndex}-checkbox-option`}
                                          >
                                            <Col>
                                              <div className="flex-between-center gap-10">
                                                <FormGroup
                                                  style={{
                                                    minHeight: "unset",
                                                  }}
                                                >
                                                  <Input
                                                    disabled={question.edit}
                                                    id="checkboxInput"
                                                    name="checkbox"
                                                    type="checkbox"
                                                    value="true"
                                                  />
                                                </FormGroup>
                                                <div className="question-choices-input flex-grow-1">
                                                  <Input
                                                    name="option"
                                                    placeholder="Option title"
                                                    type="text"
                                                    value={optionCD.option}
                                                    onChange={(e) =>
                                                      this.questionOptionInputHandler(
                                                        pindex,
                                                        qi,
                                                        optionCIndex,
                                                        e
                                                      )
                                                    }
                                                    readOnly={question.edit}
                                                  />
                                                </div>
                                                <div
                                                  style={{ width: "23px" }}
                                                  className="text-right"
                                                >
                                                  {optionCIndex !== 0 && (
                                                    <RemoveBtn
                                                      disabled={
                                                        !(
                                                          question.edit ===
                                                          false
                                                        )
                                                      }
                                                      onClick={() =>
                                                        this.deleteMoreChoicesOfQuestionFn(
                                                          pindex,
                                                          qi,
                                                          optionCIndex,
                                                          "checkbox"
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                              {optionCD.questionOptionError && (
                                                <ErrorMessage>
                                                  {optionCD.questionOptionError}
                                                </ErrorMessage>
                                              )}
                                            </Col>
                                          </Row>
                                        )
                                      )}
                                    {/* Actions */}
                                    <AddOptionBtn
                                      disabled={question.edit}
                                      onClick={() =>
                                        this.addMoreChoicesOfQuestionFn(
                                          pindex,
                                          qi,
                                          "checkbox"
                                        )
                                      }
                                    />
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() =>
                                        this.deleteChoicesQuestionFn(
                                          pindex,
                                          qi,
                                          "checkbox"
                                        )
                                      }
                                      id={`checkBoxQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) =>
                                        this.requiredQuestinFunction(
                                          e,
                                          pindex,
                                          qi,
                                          "checkbox"
                                        )
                                      }
                                      onDone={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "checkbox"
                                        )
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "checkbox"
                                        )
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * Dropdown Question
                          if (question.type === "dropdownQues") {
                            return (
                              <Row
                                className="mt-3 mb-3"
                                key={`${qi}-dropdown`}
                                id={`surveyQuestion${pindex}${qi}`}
                              >
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Drop down question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) =>
                                              this.questionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.questionError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel
                                            for="surveyPageTitle"
                                            isRequired={false}
                                          >
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) =>
                                              this.shortFormQuestionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <FPLabel
                                            className="mt-4 mb-0"
                                            isRequired={false}
                                          >
                                            Answer Options
                                          </FPLabel>
                                          <FPButton
                                            size="small"
                                            label={
                                              question.type ===
                                                "dropdownQues" &&
                                              this.questionList(pindex)[2].label
                                            }
                                            variant="outline"
                                            borderColor="#BFC2CB"
                                            textColor="#1B1C1D"
                                            className={"mt-2 mb-0"}
                                          />
                                        </div>
                                        <Divider className="mt-1 mb-0" />
                                      </Col>
                                    </Row>
                                    {/* options */}
                                    {question.options?.length > 0 &&
                                      question.options.map(
                                        (optionCD, optionCIndex) => (
                                          <Row
                                            className="mt-3"
                                            key={`${optionCIndex}-dropdown-option`}
                                          >
                                            <Col sm="12">
                                              <div className="flex-between-center gap-10">
                                                <Input
                                                  name="option"
                                                  placeholder="Option title"
                                                  type="text"
                                                  value={optionCD.option}
                                                  onChange={(e) =>
                                                    this.questionOptionInputHandler(
                                                      pindex,
                                                      qi,
                                                      optionCIndex,
                                                      e
                                                    )
                                                  }
                                                  readOnly={question.edit}
                                                />
                                                <div
                                                  style={{ width: "23px" }}
                                                  className="text-right"
                                                >
                                                  {optionCIndex !== 0 && (
                                                    <RemoveBtn
                                                      disabled={
                                                        !(
                                                          question.edit ===
                                                          false
                                                        )
                                                      }
                                                      onClick={() =>
                                                        this.deleteMoreChoicesOfQuestionFn(
                                                          pindex,
                                                          qi,
                                                          optionCIndex,
                                                          "dropdown"
                                                        )
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                              <ErrorMessage>
                                                {optionCD.questionOptionError}
                                              </ErrorMessage>
                                            </Col>
                                          </Row>
                                        )
                                      )}
                                    {/* Actions */}
                                    <AddOptionBtn
                                      disabled={question.edit}
                                      type="button"
                                      className="add-more-choices-option"
                                      onClick={() =>
                                        this.addMoreChoicesOfQuestionFn(
                                          pindex,
                                          qi,
                                          "dropdown"
                                        )
                                      }
                                    />
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() =>
                                        this.deleteChoicesQuestionFn(
                                          pindex,
                                          qi,
                                          "dropdown"
                                        )
                                      }
                                      id={`dropDownQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) =>
                                        this.requiredQuestinFunction(
                                          e,
                                          pindex,
                                          qi,
                                          "dropdown"
                                        )
                                      }
                                      onDone={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "dropdown"
                                        )
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "dropdown"
                                        )
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * Short Question
                          if (question.type === "shortAnswerQues") {
                            return (
                              <Row
                                className="mt-3 mb-3"
                                key={`${qi}-sortAnswer`}
                                id={`surveyQuestion${pindex}${qi}`}
                              >
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Short answer question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) =>
                                              this.questionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.questionError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="d-flex justify-content-between">
                                      <FPLabel
                                        className="mb-0"
                                        isRequired={false}
                                      >
                                        Answer Options
                                      </FPLabel>
                                      <FPButton
                                        size="small"
                                        label={
                                          question.type === "shortAnswerQues" &&
                                          this.questionList(pindex)[3].label
                                        }
                                        variant="outline"
                                        borderColor="#BFC2CB"
                                        textColor="#1B1C1D"
                                        className={"mt-2 mb-0"}
                                      />
                                    </div>
                                    <Divider className="mt-1 mb-0" />
                                    <Row className="mt-3 mb-2">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel
                                            for="surveyPageTitle"
                                            isRequired={false}
                                          >
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) =>
                                              this.shortFormQuestionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    {/* Actions */}
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() =>
                                        this.deleteChoicesQuestionFn(
                                          pindex,
                                          qi,
                                          "shortAnswer"
                                        )
                                      }
                                      id={`shortAnswerQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) =>
                                        this.requiredQuestinFunction(
                                          e,
                                          pindex,
                                          qi,
                                          "shortAnswer"
                                        )
                                      }
                                      onDone={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "shortAnswer"
                                        )
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "shortAnswer"
                                        )
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * Long Question
                          if (question.type === "paragraphQues") {
                            return (
                              <Row
                                className="mt-3 mb-3"
                                key={`${qi}-longQue`}
                                id={`surveyQuestion${pindex}${qi}`}
                              >
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Paragraph question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) =>
                                              this.questionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.questionError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="d-flex justify-content-between">
                                      <FPLabel
                                        className="mb-0"
                                        isRequired={false}
                                      >
                                        Answer Options
                                      </FPLabel>
                                      <FPButton
                                        size="small"
                                        label={
                                          question.type === "paragraphQues" &&
                                          this.questionList(pindex)[4].label
                                        }
                                        variant="outline"
                                        borderColor="#BFC2CB"
                                        textColor="#1B1C1D"
                                        className={"mt-2 mb-0"}
                                      />
                                    </div>
                                    <Divider className="mt-1 mb-0" />
                                    <Row className="mt-3 mb-2">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel
                                            for="surveyPageTitle"
                                            isRequired={false}
                                          >
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) =>
                                              this.shortFormQuestionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    {/* Actions */}
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() =>
                                        this.deleteChoicesQuestionFn(
                                          pindex,
                                          qi,
                                          "paragraph"
                                        )
                                      }
                                      id={`paragraphQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) =>
                                        this.requiredQuestinFunction(
                                          e,
                                          pindex,
                                          qi,
                                          "paragraph"
                                        )
                                      }
                                      onDone={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "paragraph"
                                        )
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "paragraph"
                                        )
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * Ratings Question
                          if (question.type === "ratingQues") {
                            return (
                              <Row
                                className="mt-3 mb-3"
                                key={`${qi}-ratingQue`}
                                id={`surveyQuestion${pindex}${qi}`}
                              >
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Rating question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) =>
                                              this.questionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.questionError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel
                                            for="surveyPageTitle"
                                            isRequired={false}
                                          >
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) =>
                                              this.shortFormQuestionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <FPLabel
                                            className="mt-4 mb-0"
                                            isRequired={false}
                                          >
                                            Answer Options
                                          </FPLabel>
                                          <FPButton
                                            size="small"
                                            label={
                                              question.type === "ratingQues" &&
                                              this.questionList(pindex)[5].label
                                            }
                                            variant="outline"
                                            borderColor="#BFC2CB"
                                            textColor="#1B1C1D"
                                            className={"mt-2 mb-0"}
                                          />
                                        </div>
                                        <Divider className="mt-1 mb-0" />
                                      </Col>
                                    </Row>
                                    <Row className="mt-3 mb-3">
                                      <Col sm="6">
                                        <div className="choices-title-input">
                                          <FPLabel for="exampleEmail">
                                            Low rating label
                                          </FPLabel>
                                          <Input
                                            name="lowRatingInput"
                                            placeholder="Low rating"
                                            type="text"
                                            value={question.lowRatingInput}
                                            onChange={(e) =>
                                              this.ratingScoreInputHandler(
                                                pindex,
                                                qi,
                                                "low",
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.lowRatingInputError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <div className="choices-title-input">
                                          <FPLabel for="exampleEmail">
                                            High rating label
                                          </FPLabel>
                                          <Input
                                            name="HighRatingInput"
                                            placeholder="High rating"
                                            type="text"
                                            value={question.HighRatingInput}
                                            onChange={(e) =>
                                              this.ratingScoreInputHandler(
                                                pindex,
                                                qi,
                                                "high",
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.HighRatingInputError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3 mb-3 nps-score">
                                      <Col sm="12">
                                        <CardWrapper
                                          contentClass="p-2"
                                          showDivider={false}
                                        >
                                          {/* eslint-disable-next-line: */}
                                          <div className="d-flex align-items-center gap-20 justify-content-center">
                                            {/* {Array(5)
                                                .fill(1)
                                                .map((_item, index) => {
                                                  return (
                                                    <div
                                                      key={`${index}-ratingLabel-key`}
                                                    >
                                                      <Label
                                                        for={`${index}-ratingLabel`}
                                                      >
                                                        <Rater total={1}>
                                                        <CustomRatingComponent
                                                          activeImg={
                                                            star_active
                                                          }
                                                          customClass="review-method-img"
                                                          // inActiveImg={this.state.activeRatingIndex == index ? star_active : star}
                                                          inActiveImg={star}
                                                          imageWidth={40}
                                                        />
                                                        </Rater>
                                                      </Label>
                                                      <div className="MuiCollapse-root MuiCollapse-hidden">
                                                        <Input
                                                          name="radio1"
                                                          type="radio"
                                                          id={`${index}-ratingLabel`}
                                                          style={{
                                                            position: "unset",
                                                            marginTop: "unset",
                                                            marginLeft: "unset",
                                                          }}
                                                          // onClick={() => this.setState({activeRatingIndex : index}) }
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                })} */}
                                            <Rater total={5}>
                                              <CustomRatingComponent
                                                activeImg={star_active}
                                                inActiveImg={star}
                                                customClass={`mr-2 ${
                                                  reviewMethod === "star"
                                                    ? "review-img-active"
                                                    : "review-img-inactive"
                                                }`}
                                                imageWidth={35}
                                              />
                                            </Rater>
                                          </div>
                                        </CardWrapper>
                                      </Col>
                                    </Row>
                                    {/* Action */}
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() =>
                                        this.deleteChoicesQuestionFn(
                                          pindex,
                                          qi,
                                          "rating"
                                        )
                                      }
                                      id={`ratingQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) =>
                                        this.requiredQuestinFunction(
                                          e,
                                          pindex,
                                          qi,
                                          "rating"
                                        )
                                      }
                                      onDone={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "rating"
                                        )
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "rating"
                                        )
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * NPS
                          if (question.type === "scoreQues") {
                            return (
                              <Row
                                className="mt-3 mb-3"
                                key={`${qi}-nps`}
                                id={`surveyQuestion${pindex}${qi}`}
                              >
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Score question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) =>
                                              this.questionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.questionError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                      {/* <Col sm="2">
                                      <div className="add-QuestionList">
                                        <Dropdown isOpen={choiceQuestinDropDown === qi ? ReAddQuestionOpen:false} toggle={()=>this.ReAddQuestionList(qi)} direction='down'>
                                          <DropdownToggle style={{width:'100%',backgroundColor:'#0077d2'}}>Net Promoter Score</DropdownToggle>
                                        </Dropdown>
                                      </div>
                                    </Col> */}
                                    </Row>
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="choices-title-input">
                                          <FPLabel
                                            for="surveyPageTitle"
                                            isRequired={false}
                                          >
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) =>
                                              this.shortFormQuestionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <FPLabel
                                            className="mt-4 mb-0"
                                            isRequired={false}
                                          >
                                            Answer Options
                                          </FPLabel>
                                          <FPButton
                                            size="small"
                                            label={
                                              question.type === "scoreQues" &&
                                              this.questionList(pindex)[6].value
                                            }
                                            variant="outline"
                                            borderColor="#BFC2CB"
                                            textColor="#1B1C1D"
                                            className={"mt-2 mb-0"}
                                          />
                                        </div>
                                        <Divider className="mt-1 mb-0" />
                                      </Col>
                                    </Row>
                                    <Row className="mt-3 mb-3">
                                      <Col sm="6">
                                        <div className="choices-title-input">
                                          <FPLabel for="exampleEmail">
                                            Low rating label
                                          </FPLabel>
                                          <Input
                                            name="lowRatingInput"
                                            placeholder="Low rating"
                                            type="text"
                                            value={question.lowRatingInput}
                                            onChange={(e) =>
                                              this.ratingScoreInputHandler(
                                                pindex,
                                                qi,
                                                "low",
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.lowRatingInputError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <div className="choices-title-input">
                                          <FPLabel for="exampleEmail">
                                            High rating label
                                          </FPLabel>
                                          <Input
                                            name="HighRatingInput"
                                            placeholder="High rating"
                                            type="text"
                                            value={question.HighRatingInput}
                                            onChange={(e) =>
                                              this.ratingScoreInputHandler(
                                                pindex,
                                                qi,
                                                "high",
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.HighRatingInputError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3 mb-3 nps-score">
                                      <Col sm="12">
                                        <CardWrapper
                                          contentClass="p-2"
                                          showDivider={false}
                                        >
                                          {/* eslint-disable-next-line: */}
                                          <div className="d-flex align-items-center gap-20 justify-content-center">
                                            {this.state.nps_array.map(
                                              (_item, index) => {
                                                return (
                                                  <div>
                                                    <div
                                                      style={{
                                                        display: "inline-block",
                                                        maxHeight: "35px",
                                                        maxWidth: "35px",
                                                      }}
                                                      className={`mr-2 ${
                                                        reviewMethod ===
                                                        "nps_score"
                                                          ? "review-img-active"
                                                          : "review-img-inactive"
                                                      }`}
                                                      key={index}
                                                      onMouseOver={() =>
                                                        this.handleNpsHover(
                                                          index,
                                                          true
                                                        )
                                                      }
                                                      onMouseLeave={() =>
                                                        this.handleNpsHover(
                                                          index,
                                                          false
                                                        )
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                          alignItems: "center",
                                                        }}
                                                        className={`nps-method-circles ${
                                                          _item
                                                            ? "nps-score-active"
                                                            : "nps-score-inactive"
                                                        }`}
                                                      >
                                                        <div>
                                                          {Number(index + 1)}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="MuiCollapse-root MuiCollapse-hidden">
                                                      <Input
                                                        name="radio1"
                                                        type="radio"
                                                        id={`${index}-npsLabel`}
                                                        style={{
                                                          position: "unset",
                                                          marginTop: "unset",
                                                          marginLeft: "unset",
                                                        }}
                                                        onClick={() =>
                                                          this.setState({
                                                            activeIndex: index,
                                                          })
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </CardWrapper>
                                      </Col>
                                    </Row>
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() =>
                                        this.deleteChoicesQuestionFn(
                                          pindex,
                                          qi,
                                          "score"
                                        )
                                      }
                                      id={`scoreQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) =>
                                        this.requiredQuestinFunction(
                                          e,
                                          pindex,
                                          qi,
                                          "score"
                                        )
                                      }
                                      onDone={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "score"
                                        )
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "score"
                                        )
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * Attachment
                          if (question.type === "attachmentQues") {
                            return (
                              <Row
                                className="mt-3 mb-3"
                                key={qi}
                                id={`surveyQuestion${pindex}${qi}`}
                              >
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mt-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Attachment question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) =>
                                              this.questionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.questionError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3 mb-2">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel
                                            for="surveyPageTitle"
                                            isRequired={false}
                                          >
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) =>
                                              this.shortFormQuestionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="d-flex justify-content-between">
                                      <FPLabel
                                        className="mb-0"
                                        isRequired={false}
                                      >
                                        Answer Options
                                      </FPLabel>

                                      <FPButton
                                        size="small"
                                        label={
                                          question.type === "attachmentQues" &&
                                          this.questionList(pindex)[7].label
                                        }
                                        variant="outline"
                                        borderColor="#BFC2CB"
                                        textColor="#1B1C1D"
                                        className={"mt-2 mb-0"}
                                      />
                                    </div>
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() =>
                                        this.deleteChoicesQuestionFn(
                                          pindex,
                                          qi,
                                          "attachment"
                                        )
                                      }
                                      id={`attachmentQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) =>
                                        this.requiredQuestinFunction(
                                          e,
                                          pindex,
                                          qi,
                                          "attachment"
                                        )
                                      }
                                      onDone={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "attachment"
                                        )
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "attachment"
                                        )
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * Five Faces Emoji

                          if (question.type === "fiveFacesEmoji") {
                            return (
                              <Row
                                className="mt-3 mb-3"
                                key={`${qi}-fiveFacesEmoji`}
                                id={`surveyQuestion${pindex}${qi}`}
                              >
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Five Faces Emoji question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) =>
                                              this.questionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.questionError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel
                                            for="surveyPageTitle"
                                            isRequired={false}
                                          >
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) =>
                                              this.shortFormQuestionInputHandler(
                                                pindex,
                                                qi,
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <FPLabel
                                            className="mt-4 mb-0"
                                            isRequired={false}
                                          >
                                            Answer Options
                                          </FPLabel>
                                          <FPButton
                                            size="small"
                                            label={
                                              question.type ===
                                                "fiveFacesEmoji" &&
                                              this.questionList(pindex)[8].label
                                            }
                                            variant="outline"
                                            borderColor="#BFC2CB"
                                            textColor="#1B1C1D"
                                            className={"mt-2 mb-0"}
                                          />
                                        </div>
                                        <Divider className="mt-1 mb-0" />
                                      </Col>
                                    </Row>
                                    <Row className="mt-3 mb-3">
                                      <Col sm="6">
                                        <div className="choices-title-input">
                                          <FPLabel for="exampleEmail">
                                            Low rating label
                                          </FPLabel>
                                          <Input
                                            name="lowRatingInput"
                                            placeholder="Low rating"
                                            type="text"
                                            value={question.lowRatingInput}
                                            onChange={(e) =>
                                              this.ratingScoreInputHandler(
                                                pindex,
                                                qi,
                                                "low",
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.lowRatingInputError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <div className="choices-title-input">
                                          <FPLabel for="exampleEmail">
                                            High rating label
                                          </FPLabel>
                                          <Input
                                            name="HighRatingInput"
                                            placeholder="High rating"
                                            type="text"
                                            value={question.HighRatingInput}
                                            onChange={(e) =>
                                              this.ratingScoreInputHandler(
                                                pindex,
                                                qi,
                                                "high",
                                                e
                                              )
                                            }
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>
                                            {question.HighRatingInputError}
                                          </ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3 mb-3 nps-score">
                                      <Col sm="12">
                                        <CardWrapper
                                          contentClass="p-2"
                                          showDivider={false}
                                        >
                                          {/* eslint-disable-next-line: */}
                                          <div className="d-flex align-items-center gap-20 justify-content-center">
                                            <div>
                                              <span
                                                onMouseOver={() =>
                                                  this.handleHover(
                                                    "smileyFourHover",
                                                    true
                                                  )
                                                }
                                                onMouseLeave={() =>
                                                  this.handleHover(
                                                    "smileyFourHover",
                                                    false
                                                  )
                                                }
                                                className="mr-2"
                                              >
                                                {this.state.smileyFourHover ? (
                                                  <FaceFillFour />
                                                ) : (
                                                  <FaceFour />
                                                )}
                                              </span>
                                              <span
                                                onMouseOver={() =>
                                                  this.handleHover(
                                                    "smileyFiveHover",
                                                    true
                                                  )
                                                }
                                                onMouseLeave={() =>
                                                  this.handleHover(
                                                    "smileyFiveHover",
                                                    false
                                                  )
                                                }
                                                className="mr-2"
                                              >
                                                {this.state.smileyFiveHover ? (
                                                  <FaceFillFive />
                                                ) : (
                                                  <FaceFive />
                                                )}
                                              </span>
                                              <span
                                                onMouseOver={() =>
                                                  this.handleHover(
                                                    "smileySixHover",
                                                    true
                                                  )
                                                }
                                                onMouseLeave={() =>
                                                  this.handleHover(
                                                    "smileySixHover",
                                                    false
                                                  )
                                                }
                                                className="mr-2"
                                              >
                                                {this.state.smileySixHover ? (
                                                  <FaceFillSix />
                                                ) : (
                                                  <FaceSix />
                                                )}
                                              </span>
                                              <span
                                                onMouseOver={() =>
                                                  this.handleHover(
                                                    "smileySevenHover",
                                                    true
                                                  )
                                                }
                                                onMouseLeave={() =>
                                                  this.handleHover(
                                                    "smileySevenHover",
                                                    false
                                                  )
                                                }
                                                className="mr-2"
                                              >
                                                {this.state.smileySevenHover ? (
                                                  <FaceFillSeven />
                                                ) : (
                                                  <FaceSeven />
                                                )}
                                              </span>
                                              <span
                                                onMouseOver={() =>
                                                  this.handleHover(
                                                    "smileyEightHover",
                                                    true
                                                  )
                                                }
                                                onMouseLeave={() =>
                                                  this.handleHover(
                                                    "smileyEightHover",
                                                    false
                                                  )
                                                }
                                                className="mr-2"
                                              >
                                                {this.state.smileyEightHover ? (
                                                  <FaceFillEight />
                                                ) : (
                                                  <FaceEight />
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </CardWrapper>
                                      </Col>
                                    </Row>
                                    {/* Action */}
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() =>
                                        this.deleteChoicesQuestionFn(
                                          pindex,
                                          qi,
                                          "fiveFacesEmoji"
                                        )
                                      }
                                      id={`ratingQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) =>
                                        this.requiredQuestinFunction(
                                          e,
                                          pindex,
                                          qi,
                                          "fiveFacesEmoji"
                                        )
                                      }
                                      onDone={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "fiveFacesEmoji"
                                        )
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(
                                          pindex,
                                          qi,
                                          question.edit,
                                          "fiveFacesEmoji"
                                        )
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }
                        })}
                      <Row className="">
                        <Col sm="12">
                          <div style={{ textAlign: "left" }}>
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {element.pageQuestionAddError}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex align-items-center justify-content-between">
                        <div
                          className="d-flex align-items-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.RemoveMoreSurveyPage(pindex)}
                        >
                          <img src={deleteIcon} role="button" />
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "400",
                              marginLeft: "7px",
                              color: "black",
                            }}
                          >
                            Delete Page
                          </span>
                        </div>
                        {/* <FPButton
                            size="small"
                            fontWeight={400}
                            label="Delete Page"
                            variant="default"
                            startIcon={<img src={deleteIcon} />}
                            onClick={() => this.RemoveMoreSurveyPage(pindex)}
                          /> */}
                        <div className="d-flex gap-10">
                          <FPMenuBox
                            title={"Add question"}
                            options={this.questionList(pindex)}
                            size={"small"}
                            startIcon={
                              <AddOutlinedIcon
                                style={{ width: 16, height: 16 }}
                              />
                            }
                            hideDropDownArrowIcon={true}
                            className={
                              "fp-select-as-small-btn fp-primary-bg fp-size-12"
                            }
                          />
                          <FPButton
                            size="small"
                            label="Add Page"
                            variant="outline"
                            borderColor="#BFC2CB"
                            textColor="#1B1C1D"
                            onClick={() => this.addMorePageFunction()}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </CardWrapper>

              {/* Upload Background Image */}
              <CardWrapper
                headerTitle="Upload Background Image"
                contentClass="p-4"
              >
                <LoaderComponent
                  loader={this.state.isLoading}
                ></LoaderComponent>
                <label htmlFor="file" className="cursor-pointer mb-0">
                  <FPButton
                    label="Upload File"
                    className="pointer-events-none w-100"
                    startIcon={<BackupOutlinedIcon />}
                    fontWeight={400}
                    size="small"
                    variant="outline"
                    borderColor="#00B5AD"
                  >
                    Browse Image
                    <Field
                      type="file"
                      name="background_image"
                      component={this.uploader}
                    />
                  </FPButton>
                </label>
                {this.state.imgSrc || businessBg ? (
                  <div
                    style={{ height: "200px", width: "450px" }}
                    className="mt-3"
                  >
                    <img
                      id="uploadedImg"
                      src={this.state.imgSrc || businessBg}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </CardWrapper>

              {/* Upload Logo */}
              <CardWrapper headerTitle="Upload Logo" contentClass="p-4">
                <LoaderComponent
                  loader={this.state.isLoading}
                ></LoaderComponent>
                <label htmlFor="file_1" className="cursor-pointer mb-0">
                  <FPButton
                    label="Upload File"
                    className="pointer-events-none w-100"
                    startIcon={<BackupOutlinedIcon />}
                    fontWeight={400}
                    size="small"
                    variant="outline"
                    borderColor="#00B5AD"
                  >
                    Browse Logo
                    <Field
                      type="file"
                      name="logo"
                      component={this.logoUploader}
                    />
                  </FPButton>
                </label>
                {this.state.logoSrc || businessLogo ? (
                  <div
                    style={{ height: "100px", width: "100px" }}
                    className="mt-3"
                  >
                    <img
                      id="uploadedLogo"
                      src={this.state.logoSrc || businessLogo}
                      style={{ height: "100%" }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </CardWrapper>

              {/* Upload Thanks Page Image */}
              <CardWrapper
                headerTitle="Upload Thanks Page Image"
                contentClass="p-4"
              >
                <LoaderComponent
                  loader={this.state.isLoading}
                ></LoaderComponent>
                <label htmlFor="file_2" className="cursor-pointer mb-0">
                  <FPButton
                    label="Upload File"
                    className="pointer-events-none w-100"
                    startIcon={<BackupOutlinedIcon />}
                    fontWeight={400}
                    size="small"
                    variant="outline"
                    borderColor="#00B5AD"
                  >
                    Browse Thanks Page Image
                    <Field
                      type="file"
                      name="thanks_page_image"
                      component={this.thanksPageImageUploader}
                    />
                  </FPButton>
                </label>
                {this.state.thanksPageImageSrc ? (
                  <div
                    style={{ height: "100px", width: "100px" }}
                    className="mt-3"
                  >
                    <img
                      id="uploadedThanksPageImage"
                      src={this.state.thanksPageImageSrc}
                      style={{ height: "100%" }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </CardWrapper>

              {/* Advanced Template Options */}
              <CardWrapper
                headerTitle="Advanced Template Options"
                contentClass="p-4"
              >
                <div className="d-flex align-item-center">
                  <span className="font-weight-bold">Label 1 :&nbsp;</span>
                  <span className="font-weight-normal">unique_code(uc)</span>
                </div>
                {(this.state.generatedLink || initialValues.return_link) &&
                  fieldArray &&
                  fieldArray.map((item, index) => {
                    return (
                      <div
                        className="d-flex align-item-center mt-2"
                        key={index}
                      >
                        <span className="font-weight-bold">
                          Label {index + 2} :&nbsp;
                        </span>
                        <span className="font-weight-normal">{item}</span>
                      </div>
                    );
                  })}
                {!(this.state.generatedLink || initialValues.return_link) && (
                  <Row className="mt-2">
                    <Col>
                      <FieldArray
                        serverError={errors && errors.name}
                        name="extra_fields"
                        component={this.ParametersFieldArray}
                      ></FieldArray>
                    </Col>
                  </Row>
                )}
                {(this.state.generatedLink || initialValues.return_link) && (
                  <div className="d-flex justify-content-center flex-column">
                    {/* //* QR Code */}
                    <div className="d-flex justify-content-center">
                      <div className="p-2 d-inline-flex border b-radius-8">
                        {/* <QRCode
                            value={
                              initialValues.return_link
                                ? initialValues.return_link
                                : this.state.generatedLink
                            }
                          /> */}
                        <QRCode
                          id="generatedCode"
                          // style={{ display: "none" }}
                          value={
                            initialValues.return_link
                              ? initialValues.return_link
                              : this.state.generatedLink
                          }
                          // size={200}
                          // includeMargin={true}
                        />
                      </div>
                    </div>
                    <a
                      className="text-center mt-2 mb-0"
                      id="generatedLink"
                      target="_blank"
                      href={
                        initialValues.return_link
                          ? initialValues.return_link
                          : this.state.generatedLink
                      }
                    >
                      Link :{" "}
                      {initialValues.return_link
                        ? initialValues.return_link
                        : this.state.generatedLink}
                    </a>
                    <div className="d-flex justify-content-center gap-10 mt-2">
                      {/* // * Download btn */}
                      <FPToolTip title={"Click to Download QR Code"}>
                        <FPButton
                          size="small"
                          style={{ height: 26 }}
                          variant="outline"
                          startIcon={<GetAppOutlinedIcon />}
                          label="Download"
                          onClick={this.downloadQR}
                        />
                      </FPToolTip>
                      {/* // * Copy btn */}
                      <FPToolTip title={"Click to Copy"}>
                        <FPButton
                          size="small"
                          style={{ height: 26 }}
                          variant="outline"
                          startIcon={<LinkIcon />}
                          label="Copy Link"
                          onClick={() =>
                            this.copyGeneratedLink(
                              initialValues.return_link
                                ? initialValues.return_link
                                : this.state.generatedLink
                            )
                          }
                        />
                      </FPToolTip>
                    </div>
                  </div>
                )}
              </CardWrapper>
              <div className="d-flex align-items-center justify-content-end gap-10">
                <Box
                  sx={{
                    "& label": {
                      color: "#1B1C1D",
                      fontWeight: 500,
                    },
                  }}
                >
                  <FPCheckbox
                    checked={isApplyToAll}
                    label="Apply to all branches"
                    disabled={initialValues.id || this.state.generatedLink}
                    onClick={this.handleApplyToAll}
                    className="primary"
                  />
                </Box>
                {this.state.generatedLink || initialValues.return_link ? (
                  <Link
                    target="_blank"
                    href={
                      initialValues.return_link
                        ? initialValues.return_link
                        : this.state.generatedLink
                    }
                  >
                    <FPButton
                      size="small"
                      label="Preview"
                      className="min-w-100px"
                    />
                  </Link>
                ) : (
                  <FPButton
                    size="small"
                    label="Preview"
                    disabled={true}
                    className="min-w-100px"
                  />
                )}
                <button
                  disabled={
                    this.state.generatedLink || initialValues.id ? true : false
                  }
                  className="fp-outline-btn"
                >
                  Generate Link
                </button>
                {/* <FPButton
                  type="submit"
                  className="min-w-100px"
                  size="small"
                  label="Generate Link"
                  disabled={
                    this.state.generatedLink || initialValues.id ? true : false
                  }
                  variant="outline"
                  borderColor="#BFC2CB"
                  textColor="#1B1C1D"
                /> */}
              </div>
            </section>
          </Col>
        </Row>

        {/* <ErrorModal showModal={errorModalShow} error={sendMessageError} modalCloseCallBack={this.errorModalCallback.bind(this)}/> */}
      </form>
    );
  }
}

GenericTemplateForm.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

GenericTemplateForm = reduxForm({
  // eslint-disable-line
  form: "GenericTemplateForm",
  validate,
  enableReinitialize: false,
})(GenericTemplateForm);

const selector = formValueSelector("GenericTemplateForm");

GenericTemplateForm = connect((state) => {
  // eslint-disable-line
  const landingPageBody = selector(state, "message");
  const fieldArray = selector(state, "extra_fields");

  return {
    landingPageBody,
    fieldArray,
  };
})(GenericTemplateForm);

export default GenericTemplateForm;
