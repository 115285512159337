import React, { useState } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { InputField } from "react-semantic-redux-form";
import { FPButton } from "../../common/new";
import { Box } from "@material-ui/core";
import LogoIcon from "../../../images/fp-icon.png";
import AuthLayout from "../../../containers/common/AuthLayout";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Enter Email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

const ForgotPasswordForm = (props) => {
  const { handleSubmit, handleForgotPassword, submitting, errors } = props;
  const [toShowError, setToShowError] = useState(false);
  const submit = (data) => {
    return handleForgotPassword(data).catch((err) => {
      setToShowError(true);
      const errobj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errobj);
    });
  };

  return (
    <>
      <AuthLayout
        logoIcon={LogoIcon}
        title={"Forgot Password"}
        form={
          <form size="large" onSubmit={handleSubmit(submit)} className="mb-4">
            {/* {toShowError && errors && errors.email
            ? errors.email.map((error, index) => (
                <div key={index} className="login-error-box">
                  {error}
                </div>
              ))
            : null} */}
            <Box
              sx={{ color: "#7A8193", fontSize: "14px", marginBottom: "20px" }}
            >
              Don’t worry we’ll send you a message to reset your password.
            </Box>
            <Field
              onChange={() => setToShowError(false)}
              name="email"
              // icon="user"
              // iconPosition="left"
              component={InputField}
              placeholder="Email"
              type="email"
              className="w-100 mb-1"
            />
            <FPButton
              label="Continue"
              type="submit"
              disabled={submitting}
              className="w-100"
              style={{ marginTop: "20px" }}
            />
          </form>
        }
        linkText={"Go back to login"}
      />
    </>
  );
};

export default reduxForm({
  form: "forgotPasswordForm",
  validate,
})(ForgotPasswordForm);
