import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CampaignActions from '../../actions/campaign/campaignActions';
import ReviewInviteForm from '../../components/campaign/ReviewInviteForm';
import { FPBreadcrumbs } from '../../components/common/new';
import CardWrapper from '../../modules/dashboard/component/CardWrapper';

const GuestReviewInvitePage = (props) => {
  const { match, campaignActions } = props;
  const { params } = match;
  const { business_id } = params;
  const sendInvite = (data) => {
    return campaignActions.reviewInvite(business_id, data);
  };

  const breadCrumbList = [
    {
      label: 'Campaigns',
      link: `/${business_id}/campaigns/review/list?page_size=10`
    },
    {
      label: 'Review Request',
      link: `/${business_id}/campaigns/review/list?page_size=10`
    },
    {
      label: 'Guest Review Invite',
      link: ''
    }
  ];

  return (
    <div>
      <FPBreadcrumbs list={breadCrumbList} className={'my-3'} />
      <CardWrapper headerTitle='Guest Review Invite' contentClass='p-4'>
        <ReviewInviteForm
          sendInvite={(data) => sendInvite(data)}
          businessId={business_id}
        />
      </CardWrapper>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    campaignActions: bindActionCreators(CampaignActions, dispatch),
  };
}
export default connect('', mapDispatchToProps,null,{forwardRef: true})(GuestReviewInvitePage);
