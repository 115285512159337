import { Tooltip, withStyles } from "@material-ui/core";
import React, { useState } from "react";

const MyComponent = React.forwardRef(function MyComponent(
  { children, ...rest },
  ref
) {
  return (
    <span {...rest} ref={ref}>
      {children}
    </span>
  );
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: (props) => props.bgColor || "#FFFFFF",
    color: (props) => props.textColor || "#646565",
    boxShadow: "2px -5px 15px -3px #0000000D, 0px 10px 15px -3px #0000001A",
    fontSize: 11,
    margin: (props) => props.margin || "5px 4px",
    fontWeight: "400",
    maxWidth: (props) => props.maxWidth || "auto",
  },
  arrow: {
    color: (props) => props.arrowColor || "#FFFFFF",
  },
}))((props) => <Tooltip {...props} />);

const FPToolTip = ({
  children,
  placement,
  title,
  arrow,
  color,
  followCursor,
  style,
  ...rest
}) => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    if (followCursor) {
      setCursorPosition({
        x: event.clientX,
        y: event.clientY,
      });
    }
  };

  const dynamicStyles = {
    bgColor: color?.background || "#FFFFFF",
    textColor: color?.text || "#646565",
    arrowColor: color?.arrow || "#FFFFFF",
  };

  return (
    <div style={style} onMouseMove={handleMouseMove}>
      <LightTooltip
        title={title}
        arrow={arrow}
        placement={placement || "top-start"}
        PopperProps={
          followCursor
            ? {
                anchorEl: {
                  getBoundingClientRect: () => ({
                    top: cursorPosition.y,
                    left: cursorPosition.x,
                    right: cursorPosition.x,
                    bottom: cursorPosition.y,
                    width: 0,
                    height: 0,
                  }),
                },
              }
            : undefined
        }
        {...dynamicStyles}
        {...rest}
      >
        <MyComponent>{children}</MyComponent>
      </LightTooltip>
    </div>
  );
};

export default FPToolTip;
