import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../actions/listing/listingLocationAction";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import { ListDescription } from "semantic-ui-react";
import CustomStarRatingComponent from "../../components/common/StarComponent";
import duplicate_svg from "../../images/dusplicate.svg";
import verified_svg from "../../images/verified.svg";
import { formatReviews } from "../../libs/helper";
import FPButton from "../../components/common/new/FPFilter/Listing/FPButton";
import CloseIcon from "@material-ui/icons/Close";
import google_svg from "../../images/googlephoto.svg";
import ListingHeader from "../../modules/dashboard/listingHeader";
import addicon_svg from "../../images/photo.svg";
import * as utils from "../../libs/utils";
import UploadPhoto from "./UploadPhoto";
import * as listingUtils from "../../libs/listingToast";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import "./updatePhoto.css";
import * as URLS from "../../libs/apiUrls";
import { FPSwitch } from "../../components/common/new/Listing";
import ImagesViewer from "react-images-viewer";
import { Avatar } from "antd";
import cookie from "react-cookies";
import LazyLoad from "react-lazyload";

class UpdatePhotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPhotos: [],

      viewerIsOpen: false,
      currentImageIndex: 0,

      tabKey: "",
      uploadedPhotos: [],
      savePhoto: [],
      alredySavePhoto: [],
      coverPhoto: [],
      profilePhoto: [],
      logoPhoto: [],
      exteriorPhoto: [],
      interiorPhoto: [],
      productPhoto: [],
      atWorkPhoto: [],
      foodAndDrinkPhoto: [],
      menuPhoto: [],
      commonAreaPhoto: [],
      roomsPhoto: [],
      teamPhoto: [],
      additionalPhoto: [],
      videos: [],
      mediaType: false,
      coverPhotoDisplay: [],
      profilePhotoDisplay: [],
      logoPhotoDisplay: [],
      exteriorPhotoDisplay: [],
      interiorPhotoDisplay: [],
      productPhotoDisplay: [],
      atWorkPhotoDisplay: [],
      foodAndDrinkPhotoDisplay: [],
      menuPhotoDisplay: [],
      commonAreaPhotoDisplay: [],
      roomsPhotoDisplay: [],
      teamPhotoDisplay: [],
      additionalPhotoDisplay: [],
      videosDisplay: [],
      defaultTab: 0,
    };
    this.fileInputRef = React.createRef();
    this.handleFileChange = this.handleFileChange.bind(this);
    this.UpdatePhotos = this.UpdatePhotos.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.gotoPrevImg = this.gotoPrevImg.bind(this);
    this.gotoNextIgotoNextImg = this.gotoNextIgotoNextImg.bind(this);
    this.closeImgsViewer = this.closeImgsViewer.bind(this);
    this.handleThumbnailClick = this.handleThumbnailClick.bind(this);
  }

  // handleImage() {
  //   this.setState({ viewerIsOpen: true });
  // }
  handleImage = (index) => {
    this.setState({
      viewerIsOpen: true,
      currentImageIndex: index,
    });
  };

  gotoPrevImg = () => {
    this.setState((prevState) => ({
      currentImageIndex:
        prevState?.currentImageIndex > 0
          ? prevState.currentImageIndex - 1
          : prevState.currentImageIndex,
    }));
  };

  gotoNextIgotoNextImg = () => {
    this.setState((prevState) => ({
      currentImageIndex:
        prevState.currentImageIndex < this.state.alredySavePhoto?.length - 1
          ? prevState.currentImageIndex + 1
          : prevState.currentImageIndex,
    }));
  };

  closeImgsViewer = () => {
    this.setState({ viewerIsOpen: false });
  };

  handleThumbnailClick = (index) => {
    this.setState({ currentImageIndex: index });
  };

  componentDidMount() {
    const { business_id, selectReso, type } = this.props;
    if (type === "coverphoto") {
      this.setState({ tabKey: "cover", defaultTab: 0 });
    } else if (type === "video") {
      this.setState({ tabKey: "video", defaultTab: 13 });
    } else if (type === "store_front") {
      this.setState({ tabKey: "exterior", defaultTab: 3 });
    }
    this.props.locationActions.getGmbListingMedia(business_id, selectReso);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tabKey !== this.state.tabKey) {
      this.updateAlredySavePhoto();
    }
  }

  updateAlredySavePhoto = () => {
    const { tabKey } = this.state;
    const { media_data } = this.props;

    const {
      ADDITIONAL = [],
      TEAM = [],
      ROOMS = [],
      COMMON_AREA = [],
      MENU = [],
      FOOD_AND_DRINK = [],
      AT_WORK = [],
      PRODUCT = [],
      INTERIOR = [],
      EXTERIOR = [],
      LOGO = [],
      PROFILE = [],
      COVER = [],
    } = media_data?.photos || [];

    const { video = [] } = media_data || [];

    let alredySavePhoto = [];
    switch (tabKey) {
      case "menu":
        alredySavePhoto = MENU;
        break;
      case "additional":
        alredySavePhoto = ADDITIONAL;
        break;
      case "profile":
        alredySavePhoto = PROFILE;
        break;
      case "cover":
        alredySavePhoto = COVER;
        break;
      case "logo":
        alredySavePhoto = LOGO;
        break;
      case "exterior":
        alredySavePhoto = EXTERIOR;
        break;
      case "interior":
        alredySavePhoto = INTERIOR;
        break;
      case "product":
        alredySavePhoto = PRODUCT;
        break;
      case "at_work":
        alredySavePhoto = AT_WORK;
        break;
      case "food_and_drink":
        alredySavePhoto = FOOD_AND_DRINK;
        break;
      case "common_area":
        alredySavePhoto = COMMON_AREA;
        break;
      case "rooms":
        alredySavePhoto = ROOMS;
        break;
      case "team":
        alredySavePhoto = TEAM;
        break;
      case "video":
        alredySavePhoto = video;
        break;
      default:
        alredySavePhoto = media_data;
    }

    this.setState({ alredySavePhoto });
  };

  handleRootTabChange = (value) => {
    this.setState({ defaultTab: value });
    if (value === 0) {
      this.setState({ tabKey: "cover" });
    } else if (value === 1) {
      this.setState({ tabKey: "profile" });
    } else if (value === 2) {
      this.setState({ tabKey: "logo" });
    } else if (value === 3) {
      this.setState({ tabKey: "exterior" });
    } else if (value === 4) {
      this.setState({ tabKey: "interior" });
    } else if (value === 5) {
      this.setState({ tabKey: "product" });
    } else if (value === 6) {
      this.setState({ tabKey: "at_work" });
    } else if (value === 7) {
      this.setState({ tabKey: "food_and_drink" });
    } else if (value === 8) {
      this.setState({ tabKey: "menu" });
    } else if (value === 9) {
      this.setState({ tabKey: "common_area" });
    } else if (value === 10) {
      this.setState({ tabKey: "rooms" });
    } else if (value === 11) {
      this.setState({ tabKey: "team" });
    } else if (value === 12) {
      this.setState({ tabKey: "additional" });
    } else if (value === 13) {
      this.setState({ tabKey: "video" });
    }
  };

  handleDivClick = () => {
    this.fileInputRef.current.click();
  };

  handleTabChange = (value) => {
    if (value) {
      this.setState({ mediaType: true });
    } else {
      this.setState({ mediaType: false });
    }
  };

  handleFileChange = (event) => {
    const { type } = this.props;
    const { tabKey } = this.state;
    const files = Array.from(event.target.files);
    if (tabKey === "video") {
      const validVideoTypes = ["video/mp4"];
      const maxVideoSize = 50 * 1024 * 1024; // 50 MB in bytes

      const validVideoFileObjects = [];

      files.forEach((file) => {
        const newVideos = [];
        const displayVideos = [];
        if (!validVideoTypes.includes(file.type)) {
          utils.displayMessage(
            "negative",
            "Please upload a valid Video file (MP4, WEBM, or OGG)"
          );
          return;
        } else if (file.size > maxVideoSize) {
          utils.displayMessage(
            "negative",
            "Please upload a video less than 50MB"
          );
          return;
        }
        // uploadFormData.append("VIDEOS", file);
        const videoURL = URL.createObjectURL(file);
        displayVideos.push(videoURL);
        validVideoFileObjects.push(file);
        newVideos.push(file);

        if (displayVideos.length === files.length) {
          this.setState((prevState) => ({
            videos: [...prevState.videos, ...newVideos],
            videosDisplay: [...prevState.videosDisplay, ...displayVideos],
          }));
        }
      });
      // event.target.value = null;
    } else {
      const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
      const minFileSize = 15 * 1024; // 15 KB in bytes
      const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes
      const minWidth = 250;
      const minHeight = 250;
      files.forEach((file) => {
        const newPhotos = [];
        const validImages = [];
        if (!validImageTypes.includes(file.type)) {
          utils.displayMessage(
            "negative",
            "Please upload a valid Image file (JPG, JPEG, or PNG)"
          );
          return;
        } else if (file.size < minFileSize || file.size > maxFileSize) {
          utils.displayMessage(
            "negative",
            "Please upload an image between 15KB and 5MB"
          );
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            if (img.width < minWidth || img.height < minHeight) {
              utils.displayMessage(
                "negative",
                `Minimum resolution required is ${minWidth}x${minHeight}px`
              );
              return;
            }

            // If the image is valid, add it to the state
            validImages.push(e.target.result);
            newPhotos.push(file);
            if (tabKey === "cover") {
              this.setState((prevState) => ({
                coverPhoto: [...prevState.coverPhoto, ...newPhotos],
                coverPhotoDisplay: [
                  ...prevState.coverPhotoDisplay,
                  ...validImages,
                ],
              }));
            } else if (tabKey === "profile") {
              this.setState((prevState) => ({
                profilePhoto: [...prevState.profilePhoto, ...newPhotos],
                profilePhotoDisplay: [
                  ...prevState.profilePhotoDisplay,
                  ...validImages,
                ],
              }));
            } else if (tabKey === "logo") {
              this.setState((prevState) => ({
                logoPhoto: [...prevState.logoPhoto, ...newPhotos],
                logoPhotoDisplay: [
                  ...prevState.logoPhotoDisplay,
                  ...validImages,
                ],
              }));
            } else if (tabKey === "exterior") {
              this.setState((prevState) => ({
                exteriorPhoto: [...prevState.exteriorPhoto, ...newPhotos],
                exteriorPhotoDisplay: [
                  ...prevState.exteriorPhotoDisplay,
                  ...validImages,
                ],
              }));
            } else if (tabKey === "interior") {
              this.setState((prevState) => ({
                interiorPhoto: [...prevState.interiorPhoto, ...newPhotos],
                interiorPhotoDisplay: [
                  ...prevState.interiorPhotoDisplay,
                  ...validImages,
                ],
              }));
            } else if (tabKey === "product") {
              this.setState((prevState) => ({
                productPhoto: [...prevState.productPhoto, ...newPhotos],
                productPhotoDisplay: [
                  ...prevState.productPhotoDisplay,
                  ...validImages,
                ],
              }));
            } else if (tabKey === "at_work") {
              this.setState((prevState) => ({
                atWorkPhoto: [...prevState.atWorkPhoto, ...newPhotos],
                atWorkPhotoDisplay: [
                  ...prevState.atWorkPhotoDisplay,
                  ...validImages,
                ],
              }));
            } else if (tabKey === "food_and_drink") {
              this.setState((prevState) => ({
                foodAndDrinkPhoto: [
                  ...prevState.foodAndDrinkPhoto,
                  ...newPhotos,
                ],
                foodAndDrinkPhotoDisplay: [
                  ...prevState.foodAndDrinkPhotoDisplay,
                  ...validImages,
                ],
              }));
            } else if (tabKey === "menu") {
              this.setState((prevState) => ({
                menuPhoto: [...prevState.menuPhoto, ...newPhotos],
                menuPhotoDisplay: [
                  ...prevState.menuPhotoDisplay,
                  ...validImages,
                ],
              }));
            } else if (tabKey === "common_area") {
              this.setState((prevState) => ({
                commonAreaPhoto: [...prevState.commonAreaPhoto, ...newPhotos],
                commonAreaPhotoDisplay: [
                  ...prevState.commonAreaPhotoDisplay,
                  ...validImages,
                ],
              }));
            } else if (tabKey === "rooms") {
              this.setState((prevState) => ({
                roomsPhoto: [...prevState.roomsPhoto, ...newPhotos],
                roomsPhotoDisplay: [
                  ...prevState.roomsPhotoDisplay,
                  ...validImages,
                ],
              }));
            } else if (tabKey === "team") {
              this.setState((prevState) => ({
                teamPhoto: [...prevState.teamPhoto, ...newPhotos],
                teamPhotoDisplay: [
                  ...prevState.teamPhotoDisplay,
                  ...validImages,
                ],
              }));
            } else if (tabKey === "additional") {
              this.setState((prevState) => ({
                additionalPhoto: [...prevState.additionalPhoto, ...newPhotos],
                additionalPhotoDisplay: [
                  ...prevState.additionalPhotoDisplay,
                  ...validImages,
                ],
              }));
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
      event.target.value = null;
    }
  };

  handleCheckboxChange = (photo) => {
    const { selectedPhotos } = this.state;
    if (selectedPhotos.includes(photo)) {
      this.setState({
        selectedPhotos: selectedPhotos.filter((p) => p !== photo),
      });
    } else {
      this.setState({
        selectedPhotos: [...selectedPhotos, photo],
      });
    }
  };

  handleSavedDeletePhoto = async () => {
    const { selectedPhotos } = this.state;
    const { business_id, selectReso, locationActions } = this.props;

    if (selectedPhotos.length === 0) return;

    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete ${selectedPhotos.length} photo(s)?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const mediaNames = selectedPhotos.map((photo) => photo.name);
        await locationActions.getGmbListingDeleteMedia(
          business_id,
          selectReso,
          { media_name: mediaNames }
        );

        listingUtils?.displayMessage(
          "info",
          "Listing getting updated, It may take a few minutes."
        );
        await locationActions.getGmbListingRefreshListing(
          business_id,
          selectReso
        );

        listingUtils?.displayMessage("close"); // Close the "refresh process" toast

        listingUtils?.displayMessage(
          "positive",
          "Listing updated successfully."
        );
        await locationActions.getGmbListingMedia(business_id, selectReso);

        this.setState({ selectedPhotos: [] }); // Clear selected photos after deletion
      }
    });
  };
  handleUploadedDeletePhoto = (photo, index, type) => {
    const {
      coverPhoto,
      profilePhoto,
      logoPhoto,
      exteriorPhoto,
      interiorPhoto,
      productPhoto,
      atWorkPhoto,
      foodAndDrinkPhoto,
      menuPhoto,
      commonAreaPhoto,
      roomsPhoto,
      teamPhoto,
      additionalPhoto,
      videos,
    } = this.state;
    if (coverPhoto.length > 0) {
      const newCoverPhoto = coverPhoto.filter((_, i) => i !== index);
      this.setState({ coverPhoto: newCoverPhoto });
    }
    if (profilePhoto.length > 0) {
      const newprofilePhoto = profilePhoto.filter((_, i) => i !== index);
      this.setState({ profilePhoto: newprofilePhoto });
    }
    if (logoPhoto.length > 0) {
      const newlogoPhoto = logoPhoto.filter((_, i) => i !== index);
      this.setState({ logoPhoto: newlogoPhoto });
    }
    if (exteriorPhoto.length > 0) {
      const newexteriorPhoto = exteriorPhoto.filter((_, i) => i !== index);
      this.setState({ exteriorPhoto: newexteriorPhoto });
    }
    if (interiorPhoto.length > 0) {
      const newinteriorPhoto = interiorPhoto.filter((_, i) => i !== index);
      this.setState({ interiorPhoto: newinteriorPhoto });
    }
    if (productPhoto.length > 0) {
      const newproductPhoto = productPhoto.filter((_, i) => i !== index);
      this.setState({ productPhoto: newproductPhoto });
    }
    if (atWorkPhoto.length > 0) {
      const newatWorkPhoto = atWorkPhoto.filter((_, i) => i !== index);
      this.setState({ atWorkPhoto: newatWorkPhoto });
    }
    if (foodAndDrinkPhoto.length > 0) {
      const newfoodAndDrinkPhoto = foodAndDrinkPhoto.filter(
        (_, i) => i !== index
      );
      this.setState({ foodAndDrinkPhoto: newfoodAndDrinkPhoto });
    }
    if (menuPhoto.length > 0) {
      const newmenuPhoto = menuPhoto.filter((_, i) => i !== index);
      this.setState({ menuPhoto: newmenuPhoto });
    }
    if (commonAreaPhoto.length > 0) {
      const newcommonAreaPhoto = commonAreaPhoto.filter((_, i) => i !== index);
      this.setState({ commonAreaPhoto: newcommonAreaPhoto });
    }
    if (roomsPhoto.length > 0) {
      const newroomsPhoto = roomsPhoto.filter((_, i) => i !== index);
      this.setState({ roomsPhoto: newroomsPhoto });
    }
    if (teamPhoto.length > 0) {
      const newteamPhoto = teamPhoto.filter((_, i) => i !== index);
      this.setState({ teamPhoto: newteamPhoto });
    }
    if (additionalPhoto.length > 0) {
      const newadditionalPhoto = additionalPhoto.filter((_, i) => i !== index);
      this.setState({ additionalPhoto: newadditionalPhoto });
    }
    if (videos.length > 0) {
      const newvideos = videos.filter((_, i) => i !== index);
      this.setState({ videos: newvideos });
    }
    this.setState((prevState) => {
      const updatedPhotos = prevState[type].filter((_, i) => i !== index);
      return { [type]: updatedPhotos };
    });
  };

  UpdatePhotos = async () => {
    const {
      coverPhoto,
      profilePhoto,
      logoPhoto,
      exteriorPhoto,
      interiorPhoto,
      productPhoto,
      atWorkPhoto,
      foodAndDrinkPhoto,
      menuPhoto,
      commonAreaPhoto,
      roomsPhoto,
      teamPhoto,
      additionalPhoto,
      videos,
    } = this.state;
    const { business_id, selectReso } = this.props;
    const restodetail = {
      business_id,
      selectReso,
    };
    const uploadFormData = new FormData();
    if (coverPhoto.length > 0) {
      coverPhoto.forEach((photo) => {
        uploadFormData.append(`COVER`, photo);
      });
    }
    if (profilePhoto.length > 0) {
      profilePhoto.forEach((photo) => {
        uploadFormData.append(`PROFILE`, photo);
      });
    }
    if (logoPhoto.length > 0) {
      logoPhoto.forEach((photo) => {
        uploadFormData.append(`LOGO`, photo);
      });
    }
    if (exteriorPhoto.length > 0) {
      exteriorPhoto.forEach((photo) => {
        uploadFormData.append(`EXTERIOR`, photo);
      });
    }
    if (interiorPhoto.length > 0) {
      interiorPhoto.forEach((photo) => {
        uploadFormData.append(`INTERIOR`, photo);
      });
    }
    if (productPhoto.length > 0) {
      productPhoto.forEach((photo) => {
        uploadFormData.append(`PRODUCT`, photo);
      });
    }
    if (atWorkPhoto.length > 0) {
      atWorkPhoto.forEach((photo) => {
        uploadFormData.append(`AT_WORK`, photo);
      });
    }
    if (foodAndDrinkPhoto.length > 0) {
      foodAndDrinkPhoto.forEach((photo) => {
        uploadFormData.append(`FOOD_AND_DRINK`, photo);
      });
    }
    if (menuPhoto.length > 0) {
      menuPhoto.forEach((photo) => {
        uploadFormData.append(`MENU`, photo);
      });
    }
    if (commonAreaPhoto.length > 0) {
      commonAreaPhoto.forEach((photo) => {
        uploadFormData.append(`COMMON_AREA`, photo);
      });
    }
    if (roomsPhoto.length > 0) {
      roomsPhoto.forEach((photo) => {
        uploadFormData.append(`ROOMS`, photo);
      });
    }
    if (teamPhoto.length > 0) {
      teamPhoto.forEach((photo) => {
        uploadFormData.append(`TEAM`, photo);
      });
    }
    if (additionalPhoto.length > 0) {
      additionalPhoto.forEach((photo) => {
        uploadFormData.append(`ADDITIONAL`, photo);
      });
    }
    if (videos.length > 0) {
      videos.forEach((video) => {
        uploadFormData.append(`VIDEOS`, video);
      });
    }
    uploadFormData.append("type", "mediaUpload");
    await this.props.locationActions.getGmbListingUpdateListing(
      uploadFormData,
      restodetail,
      true
    );
    listingUtils?.displayMessage(
      "info",
      "Listing getting updated, It may take a few minutes."
    );
    await this.props.locationActions.getGmbListingRefreshListing(
      business_id,
      selectReso
    );

    listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    listingUtils?.displayMessage("positive", "Listing updated successfully.");
    await this.props.locationActions.getGmbListingMedia(
      business_id,
      selectReso
    );
  };

  render() {
    const {
      tabKey,
      uploadedPhotos,
      defaultTab,
      alredySavePhoto,
      coverPhotoDisplay,
      profilePhotoDisplay,
      logoPhotoDisplay,
      exteriorPhotoDisplay,
      interiorPhotoDisplay,
      productPhotoDisplay,
      atWorkPhotoDisplay,
      foodAndDrinkPhotoDisplay,
      menuPhotoDisplay,
      commonAreaPhotoDisplay,
      roomsPhotoDisplay,
      teamPhotoDisplay,
      additionalPhotoDisplay,
      videosDisplay,
      selectedPhotos,
      mediaType,
    } = this.state;

    const { history, primaryDetail, business_id, type } = this.props;
    const addressString = [
      primaryDetail?.addressLines,
      primaryDetail?.locality,
      primaryDetail?.postalCode,
    ].join(", ");

    let IMG_SET = [];
    IMG_SET = alredySavePhoto
      ?.filter((photo) =>
        mediaType
          ? photo?.media_type === "Customer"
          : photo?.media_type === "Owner"
      )
      ?.map((photo, idx) => ({
        // You can add other properties if needed, e.g., id or count
        id: `${idx + 1}`,
        count: `${idx + 1}`,
        src: photo?.googleUrl ? photo?.googleUrl : "", // Use the googleUrl if available
      }));

    const authToken = cookie.load("authToken");

    return (
      <>
        <div
          className="listing-container"
          style={{ overflow: "hidden", gap: "15px" }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            <div className="listing-header">
              <div className="listing-info">
                <div style={{ display: "flex", gap: "8px" }}>
                  <div className="listing-name">{primaryDetail?.title}</div>
                  <img
                    src={
                      primaryDetail?.is_varified
                        ? verified_svg
                        : primaryDetail?.is_suspend
                        ? duplicate_svg
                        : primaryDetail?.is_duplicate
                        ? duplicate_svg
                        : ""
                    }
                    alt="verifiedImage"
                  />
                </div>
                <div
                  style={{ display: "flex", gap: "8px", alignItems: "center" }}
                >
                  <div className="rating-container">
                    <CustomStarRatingComponent
                      name="rate"
                      starCount={5}
                      starColor="#FBBD08"
                      emptyStarColor="white"
                      value={primaryDetail?.averageRating}
                      editing={false}
                    />
                  </div>
                  <span>{primaryDetail?.averageRating?.toFixed(1)}</span>
                  <div
                    style={{
                      height: "5px",
                      width: "5px",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <span>
                    {formatReviews(primaryDetail?.totalReviewCount)} Reviews
                  </span>
                </div>
              </div>
            </div>
            <div className="review-address">
              <ListDescription>{addressString}</ListDescription>
            </div>
          </div>
        </div>
        <div
          className="listing-container"
          style={{ display: "grid", gap: "24px" }}
        >
          <div className="UpdateScrollHeightSection">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <div
                className="d-flex flex-column"
                style={{
                  display: "grid",
                  gap: "15px",
                  paddingBottom: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <ListingHeader
                    history={history}
                    business_id={business_id}
                    defaultTab={defaultTab}
                    listinghandleRootTabChange={this.handleRootTabChange}
                  />
                </div>
                {tabKey !== "video" && (
                  <div
                    className="border"
                    style={{
                      padding: "50px",
                      borderRadius: "10px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        gap: "15px",
                        justifyItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={this.handleDivClick}
                    >
                      <img src={addicon_svg} alt="" />
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        ref={this.fileInputRef}
                        style={{ display: "none" }}
                        onChange={this.handleFileChange}
                        multiple
                      />
                      <UploadPhoto tabKey={tabKey || "interior_photo"} />
                    </div>
                    <div
                      className="uploaded-photos"
                      style={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                        borderTop:
                          uploadedPhotos.length > 0
                            ? "1px solid #BFC2CB"
                            : "none",
                        paddingTop: uploadedPhotos.length > 0 ? "10px" : "0",
                      }}
                    >
                      <div
                        // key={index}
                        style={{
                          position: "relative",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "5px",
                        }}
                      >
                        {tabKey === "cover" && coverPhotoDisplay?.length > 0
                          ? coverPhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    // onClick={() =>
                                    //   this.handleUploadedDeletePhoto(photo, index)
                                    // }
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "coverPhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : tabKey === "profile" &&
                            profilePhotoDisplay?.length > 0
                          ? profilePhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index + 1}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "profilePhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : tabKey === "logo" && logoPhotoDisplay?.length > 0
                          ? logoPhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "logoPhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : tabKey === "exterior" &&
                            exteriorPhotoDisplay?.length > 0
                          ? exteriorPhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "exteriorPhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : tabKey === "interior" &&
                            interiorPhotoDisplay?.length > 0
                          ? interiorPhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "interiorPhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : tabKey === "product" &&
                            productPhotoDisplay?.length > 0
                          ? productPhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "productPhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : tabKey === "at_work" &&
                            atWorkPhotoDisplay?.length > 0
                          ? atWorkPhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "atWorkPhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : tabKey === "menu" && menuPhotoDisplay?.length > 0
                          ? menuPhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "menuPhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : tabKey === "common_area" &&
                            commonAreaPhotoDisplay?.length > 0
                          ? commonAreaPhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "commonAreaPhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : tabKey === "rooms" && roomsPhotoDisplay?.length > 0
                          ? roomsPhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "roomsPhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : tabKey === "food_and_drink" &&
                            foodAndDrinkPhotoDisplay?.length > 0
                          ? foodAndDrinkPhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "foodAndDrinkPhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : tabKey === "team" && teamPhotoDisplay?.length > 0
                          ? teamPhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "teamPhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : tabKey === "additional" &&
                            additionalPhotoDisplay?.length > 0
                          ? additionalPhotoDisplay.map((photo, index) => (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    src={photo}
                                    alt={`uploaded-${index}`}
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        photo,
                                        index,
                                        "additionalPhotoDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                )}
                {tabKey === "video" && (
                  <div
                    className="border"
                    style={{
                      padding: "50px",
                      borderRadius: "10px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        gap: "15px",
                        justifyItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={this.handleDivClick}
                    >
                      <img src={addicon_svg} alt="" />
                      <input
                        type="file"
                        accept="video/mp4"
                        ref={this.fileInputRef}
                        style={{ display: "none" }}
                        onChange={this.handleFileChange}
                        multiple
                      />
                      <UploadPhoto tabKey={tabKey || "video"} />
                    </div>
                    <div
                      className="uploaded-photos"
                      style={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                        borderTop:
                          uploadedPhotos.length > 0
                            ? "1px solid #BFC2CB"
                            : "none",
                        paddingTop: uploadedPhotos.length > 0 ? "10px" : "0",
                      }}
                    >
                      {tabKey === "video" && videosDisplay?.length > 0
                        ? videosDisplay.map((video, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "5px",
                                  }}
                                >
                                  <video
                                    src={video}
                                    controls
                                    style={{
                                      width: "70px",
                                      height: "70px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                  <CloseIcon
                                    onClick={() =>
                                      this.handleUploadedDeletePhoto(
                                        video,
                                        index,
                                        "videosDisplay"
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      cursor: "pointer",
                                      color: "red",
                                      borderRadius: "50%",
                                      backgroundColor: "white",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                )}
                {tabKey === "video" ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(180px, 1fr))",
                      gap: "16px",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",

                        textAlign: "center",
                        padding: "10px 0px",
                        backgroundColor: "#F6F6F8",
                        borderRadius: "8px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#7A8193",
                        }}
                      >
                        Format
                      </span>
                      <h3
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#1B1C1D",
                        }}
                      >
                        video/mp4
                      </h3>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(180px, 1fr))",
                      gap: "16px",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "16px",
                        backgroundColor: "#F6F6F8",
                        borderRadius: "8px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#7A8193",
                        }}
                      >
                        Recommended Res.
                      </span>
                      <h3
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#1B1C1D",
                        }}
                      >
                        900 x 1200 px
                      </h3>
                    </div>
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "16px",
                        backgroundColor: "#F6F6F8",
                        borderRadius: "8px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#7A8193",
                        }}
                      >
                        Size
                      </span>
                      <h3
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#1B1C1D",
                        }}
                      >
                        15KB - 5MB
                      </h3>
                    </div>
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "16px",
                        backgroundColor: "#F6F6F8",
                        borderRadius: "8px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#7A8193",
                        }}
                      >
                        Format
                      </span>
                      <h3
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#1B1C1D",
                        }}
                      >
                        JPG & PNG
                      </h3>
                    </div>
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "16px",
                        backgroundColor: "#F6F6F8",
                        borderRadius: "8px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#7A8193",
                        }}
                      >
                        Minimum Res
                      </span>
                      <h3
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#1B1C1D",
                        }}
                      >
                        250 x 250 px
                      </h3>
                    </div>
                  </div>
                )}
                {alredySavePhoto?.length > 0 && (
                  <>
                    <FPSwitch
                      id="4"
                      noLabel="User"
                      yesLabel="Owner"
                      onChange={this.handleTabChange}
                    />
                    <div
                      className="border"
                      style={{
                        padding: "50px",
                        borderRadius: "10px",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "12px",
                      }}
                    >
                      {alredySavePhoto
                        ?.filter((photo) =>
                          mediaType
                            ? photo?.media_type === "Customer"
                            : photo?.media_type === "Owner"
                        )
                        ?.map((photo, index) => (
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <img
                              // src={`${URLS.GMB_LISTING_LOCATION}/proxy-image?url=${photo?.googleUrl}`}
                              src={
                                photo?.thumbnailUrl ? photo?.thumbnailUrl : ""
                              }
                              referrerPolicy="no-referrer"
                              alt={`uploaded-${index}`}
                              style={{
                                width: "70px",
                                height: "70px",
                                objectFit: "cover",
                                borderRadius: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => this.handleImage(index)} // Pass index to open correct image
                            />
                            {photo?.media_type === "Owner" ? (
                              <input
                                type="checkbox"
                                checked={selectedPhotos.includes(photo)}
                                onChange={() =>
                                  this.handleCheckboxChange(photo)
                                }
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  cursor: "pointer",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            ) : (
                              " "
                            )}
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  paddingBlock: "10px",
                  gap: "10px",
                }}
              >
                {selectedPhotos?.length > 0 && (
                  <div style={{ display: "grid", justifyContent: "end" }}>
                    <FPButton
                      label={`Delete Selected Photos (${selectedPhotos?.length})`}
                      size="small"
                      onClick={this.handleSavedDeletePhoto}
                      disabled={
                        primaryDetail?.recommendationReason ===
                        "BUSINESS_LOCATION_SUSPENDED"
                      }
                    />
                  </div>
                )}

                <FPButton
                  label="Update"
                  size="small"
                  onClick={this.UpdatePhotos}
                  disabled={
                    primaryDetail?.is_suspend || uploadedPhotos.length > 0
                  }
                />
              </div>
            </div>
            <div className="RightImageSectionDialog">
              <img src={google_svg} alt="verifiedImage" />
            </div>
          </div>
        </div>

        {this.state.viewerIsOpen && (
          <ImagesViewer
            imgs={IMG_SET.map((img) => ({
              ...img,
              customComponent: (
                <LazyLoad height={200} offset={100}>
                  <img
                    src={img.src + `authToken=${authToken}`}
                    alt="Custom Viewer"
                    loading="lazy"
                    referrerPolicy="no-referrer"
                    onLoad={(e) => {
                      console.log("Image loaded successfully", e.target.src);
                    }}
                    onError={(e) => {
                      console.log("Error loading image:", e.target.src);
                    }}
                  />
                </LazyLoad>
              ),
            }))}
            thumbnail={IMG_SET.map((img) => ({
              ...img,
              customComponent: (
                <LazyLoad height={100} offset={50}>
                  <img
                    src={img.src + `authToken=${authToken}`}
                    alt="Thumbnail"
                    loading="lazy"
                    referrerPolicy="no-referrer"
                    onError={(e) => {
                      console.log("Error loading thumbnail:", e.target.src);
                    }}
                  />
                </LazyLoad>
              ),
            }))}
            showThumbnails={true}
            referrerPolicy="no-referrer"
            isOpen={this.state.viewerIsOpen}
            currImg={this.state.currentImageIndex}
            onClickPrev={this.gotoPrevImg}
            onClickNext={this.gotoNextIgotoNextImg}
            onClose={this.closeImgsViewer}
            showImgCount={false}
            onClickThumbnail={this.handleThumbnailClick}
            theme={{}}
            backdropCloseable={true}
            lazyLoad={true}
            width={1024}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
    selectedTabupdatephoto: state.selectedTab.selectedTabupdatephoto,
    media_data: state.gmbListingLocation.media_data,
    media_error: state.gmbListingLocation.media_error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  locationActions: bindActionCreators(locationActions, dispatch),
  selectedTabActions: bindActionCreators(setSelectedTab, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePhotos);
