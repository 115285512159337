import React, { useState, useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Check } from "lucide-react";
import { FormFeedback } from "reactstrap";
import FPLabel from "../../../../components/common/new/forms/ui/FPLabel";

const EmojiContainer = styled(Box)(({ selected }) => ({
  width: 45,
  height: 45,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  background: selected ? "#5FAAB1" : "",
  transition: "all 0.3s ease",
  position: "relative",
}));

const SelectionMark = styled(Box)({
  position: "absolute",
  top: -17,
  left: "50%",
  transform: "translateX(-50%)",
  width: 20,
  height: 20,
  borderRadius: "50%",
  background: "#DDEFF0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "4px",
});

const emojiLabels = {
  sed: "Sed",
  angry: "Angry",
  neutral: "Neutral",
  happy: "Happy",
  very_happy: "Very Happy",
};

const FeedbackEmojiSelect = ({
  onEmojiChange,
  value,
  error,
  fiveEmoji = true,
  title,
  containerMargin = true,
  showLabel = true,
}) => {
  const [selectedEmoji, setSelectedEmoji] = useState(value || null);

  useEffect(() => {
    setSelectedEmoji(value);
  }, [value]);

  const emojis = [
    { value: "angry", emoji: "😡" },
    { value: "neutral", emoji: "😐" },
    { value: "happy", emoji: "🥰" },
  ];
  const FiveEmojis = [
    { value: "sed", emoji: "😟" },
    { value: "angry", emoji: "😡" },
    { value: "neutral", emoji: "😐" },
    { value: "happy", emoji: "🥰" },
    { value: "very_happy", emoji: "😄" },
  ];

  // const emojiList = fiveEmoji ? FiveEmojis : emojis;
  const handleEmojiSelect = (emojiValue) => {
    setSelectedEmoji(emojiValue);
    if (onEmojiChange) onEmojiChange(emojiValue);
  };

  return (
    <>
      {title && (
        <FPLabel isRequired={false} className="mt-2">
          {title}
        </FPLabel>
      )}
      <div>
        <Stack
          direction="row"
          spacing={3}
          justifyContent="center"
          sx={{ mb: containerMargin ? 1 : 0, mt: containerMargin ? 3 : 0, gap: "6px" }}
        >
          {(fiveEmoji ? FiveEmojis : emojis).map((emoji) => (
            <div
              key={emoji.value}
              style={{
                width: "60px",
                height: "60px",
                backgroundColor: selectedEmoji === emoji.value ? "#F6F6F8" : "",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "49px",
                margin: "0px",
              }}
            >
              <EmojiContainer
                selected={selectedEmoji === emoji.value}
                onClick={() => handleEmojiSelect(emoji.value)}
              >
                {selectedEmoji === emoji.value && (
                  <SelectionMark>
                    <Check size={16} color="#00CBCB" />
                  </SelectionMark>
                )}
                <Typography
                  variant="h2"
                  component="div"
                  sx={{ fontSize: "2.5rem", marginTop: "3px" }}
                >
                  {emoji.emoji}
                </Typography>
              </EmojiContainer>
            </div>
          ))}
        </Stack>
        {/* Show first and last labels ONLY when no emoji is selected */}
        {showLabel && !selectedEmoji && (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            maxWidth={(fiveEmoji ? FiveEmojis.length : emojis.length) * 60}
            mx="auto"
            mt={1}
          >
            <Typography variant="caption" sx={{ fontSize: 12 }}>
              {fiveEmoji ? "Sad" : "Angry"}
            </Typography>
            <Typography variant="caption" sx={{ fontSize: 12 }}>
              {fiveEmoji ? "Very Happy" : "Happy"}
            </Typography>
          </Box>
        )}


      </div>
      {/* Show selected emoji label only AFTER selection */}
      {showLabel && selectedEmoji && (
        <Box textAlign="center" mt={1}>
          <Typography
            variant="caption"
            sx={{
              fontSize: "14px",
              backgroundColor:
                selectedEmoji === "sed" || selectedEmoji === "angry"
                  ? "#EC554E" // Red for "sed" and "angry"
                  : selectedEmoji === "neutral"
                    ? "#FBC02D" // Yellow for "neutral"
                    : "#039855", // Green for "happy" and "very_happy"
              color: "Black",
              padding: "4px 8px",
              borderRadius: "4px",
            }}
          >
            {emojiLabels[selectedEmoji]}
          </Typography>
        </Box>
      )}


      {error && <FormFeedback>{error}</FormFeedback>}
    </>
  );
};

export default FeedbackEmojiSelect;
