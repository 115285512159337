import axios_instance from '../../libs/interseptor';
import * as types from '../actionTypes';
import * as URLS from '../../libs/apiUrls';

import * as commonAction from '../common/commonAction';
import * as utils from '../../libs/utils';

export function contactListSuccess(data) {
  return { type: types.CONTACT_LIST_SUCCESS, data };
}

export function contactListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.CONTACT_LIST_FAILURE, data };
}

export const contactList = (params, businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT, [businessId]);
    return axios_instance.get(url, { params })
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactListSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactListFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function contactIdsSuccess(data) {
  return { type: types.CONTACT_IDS_SUCCESS, data };
}

export function contactIdsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.CONTACT_IDS_FAILURE, data };
}

export const contactIds = (params, businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT_IDS, [businessId]);
    return axios_instance.get(url, { params })
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactIdsSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactIdsFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function contactAddSuccess(data) {
  return { type: types.CONTACT_ADD_SUCCESS, data };
}

export function contactAddFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.CONTACT_ADD_FAILURE, data };
}

export const contactAdd = (branchId, data) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT, [branchId]);
    return axios_instance.post(url, data)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactAddSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactAddFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function countryLocationSuccess(data) {
  return { type: types.COUNTRY_CONTACT_SUCCESS, data };
}

export function countryLocationFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.COUNTRY_CONTACT_FAILURE, data };
}

export const getCountryLocation = () => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(URLS.LOCATION_COUNTRY)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(countryLocationSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(countryLocationFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function countryRegionSuccess(data) {
  return { type: types.REGION_CONTACT_SUCCESS, data };
}

export function countryRegionFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.REGION_CONTACT_FAILURE, data };
}

export const getRegionLocation = (params) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(URLS.LOCATION_REGION, { params })
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(countryRegionSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(countryRegionFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function contactEditSuccess(data) {
  return { type: types.CONTACT_EDIT_SUCCESS, data };
}

export function contactEditFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.CONTACT_EDIT_FAILURE, data };
}

export const contactEdit = (branchId, data) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT_DETAIL, [branchId, data.id]);
    return axios_instance.patch(url, data)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactEditSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactEditFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function contactDeleteSuccess(data) {
  return { type: types.CONTACT_DELETE_SUCCESS, data };
}

export function contactDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.CONTACT_DELETE_FAILURE, data };
}

export const contactDelete = (branchId, data) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT_DETAIL, [branchId, data.id]);
    return axios_instance.delete(url, data)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactDeleteSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactDeleteFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function contactCsvUploadSuccess(data) {
  return { type: types.CONTACT_CSV_UPLOAD_SUCCESS, data };
}

export function contactCsvUploadFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.CONTACT_CSV_UPLOAD_FAILURE, data };
}

export const contactCsvUpload = (businessId, branchId, data) => {
  const config = {
    headers: {
      'Content-Type': 'none'
    }
  };
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(`${URLS.CONTACT}${URLS.UPLOAD_CSV}?branch=${branchId}`, [businessId]);
    return axios_instance.post(url, data, config)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactCsvUploadSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactCsvUploadFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getContactSuccess(data) {
  return { type: types.GET_CONTACT_SUCCESS, data };
}

export function getContactFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_CONTACT_FAILURE, data };
}

export const getContact = (branchId, data) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT_DETAIL, [branchId, data.id]);
    return axios_instance.get(url, data)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(getContactSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(getContactFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function deleteContactsSuccess(data) {
  return { type: types.CONTACT_DELETE_SUCCESS, data};
}

export function deleteContactsFailure(data) {
  return { type: types.CONTACT_DELETE_SUCCESS, data };
}

export const deleteContacts = (id,data) => {
  const url = utils.format(URLS.CONTACTS_DELETE,[id]);
  return (dispatch) => {
    return axios_instance.post(url,data)
      .then(
        (res) => {
          dispatch(deleteContactsSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(deleteContactsFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getContactTagGroupsSuccess(data) {
  return { type: types.GET_CONTACT_TAG_GROUPS_SUCCESS, data };
}

export function getContactTagGroupsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_CONTACT_TAG_GROUPS_FAILURE, data };
}

export const getContactTagGroups = (businessId) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT_TAGS_API, [businessId]);
    return axios_instance.get(url)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(getContactTagGroupsSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(getContactTagGroupsFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function contactTagGroupAddSuccess(data) {
  return { type: types.CONTACT_TAG_GROUP_ADD_SUCCESS, data };
}

export function contactTagGroupAddFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.CONTACT_TAG_GROUP_ADD_FAILURE, data };
}

export const contactTagGroupAdd = (businessId, data) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT_TAGS_API, [businessId]);
    return axios_instance.post(url, data)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactTagGroupAddSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(contactTagGroupAddFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function deleteTagGroupSuccess(data) {
  return { type: types.DELETE_TAG_GROUP_SUCCESS, data };
}

export function deleteTagGroupFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.DELETE_TAG_GROUP_FAILURE, data };
}

export const deleteTagGroup = (businessId, id) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT_TAGS_API, [businessId]);
    return axios_instance.delete(url+`${id}/`)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(deleteTagGroupSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(deleteTagGroupFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function editTagGroupNameSuccess(data) {
  return { type: types.EDIT_TAG_GROUP_NAME_SUCCESS, data };
}

export function editTagGroupNameFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.EDIT_TAG_GROUP_NAME_FAILURE, data };
}

export const editTagGroupName = (businessId, data, id) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT_TAGS_API, [businessId]);
    return axios_instance.patch(url+`${id}/`, data)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(editTagGroupNameSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(editTagGroupNameFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function editTagNameSuccess(data) {
  return { type: types.EDIT_TAG_NAME_SUCCESS, data };
}

export function editTagNameFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.EDIT_TAG_NAME_FAILURE, data };
}

export const editTagName = (businessId, data, id) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT_TAGS_OTHER_API, [businessId]);
    return axios_instance.patch(url+`${id}/`, data)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(editTagNameSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(editTagNameFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function removeEditTagNameError() {
  return { type: types.REMOVE_EDIT_TAGNAME_ERROR, data: null };
}

export function addTagNameSuccess(data) {
  return { type: types.ADD_TAG_NAME_SUCCESS, data };
}

export function addTagNameFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.ADD_TAG_NAME_FAILURE, data };
}

export const addTagName = (businessId, data) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT_TAGS_OTHER_API, [businessId]);
    return axios_instance.post(url + 'add_tags/', data)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(addTagNameSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(addTagNameFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function deleteTagSuccess(data) {
  return { type: types.DELETE_TAG_SUCCESS, data };
}

export function deleteTagFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.DELETE_TAG_FAILURE, data };
}

export const deleteTag = (businessId, tagData) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CONTACT_TAGS_OTHER_API, [businessId]);
    return axios_instance.post(url+'remove_tags/', tagData)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(deleteTagSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(deleteTagFailure(err));
          throw err.response.data;
        }
      );
  };
};
