import React from "react";
import "./central-branch.css";
import CustomStarRatingComponent from "../common/StarComponent";
import * as utils from "../../libs/utils";
import { Box } from "@material-ui/core";
import { Divider } from "antd";
import { FPToolTip } from "../common/new";

const secondSection = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  maxHeight: 170,
  overflow: "auto",
};

function TicketAssignmentRule(props) {
  const { assignMentRuleData, enterpriseList, branchList } = props;

  const ratingOptions = {
    one_rating: "1",
    two_rating: "2",
    three_rating: "3",
    four_rating: "4",
    five_rating: "5",
  };
  let allLocations = [];

  branchList &&
    branchList.map((item) => {
      allLocations.push({
        label: item.display_name,
        value: item.value,
      });
    });

  let allMembersList = [];

  enterpriseList.map((item) =>
    item.branch_owner.map((owners) =>
      allMembersList.push({
        display_name: owners.name,
        value: owners.id,
      })
    )
  );

  const jsonObject = allMembersList.map(JSON.stringify);

  const uniqueNames = new Set(jsonObject);

  allMembersList = Array.from(uniqueNames).map(JSON.parse);

  let locationMap = {};
  let membersMap = {};
  allLocations.map((item) => (locationMap[item.value] = item.label));
  allMembersList.map((item) => (membersMap[item.value] = item.display_name));

  return (
    <div>
      <div className="d-flex">
        <Box className="col-4" pl={0}>
          <span className="headText">Rule Name</span>
          <FPToolTip title={assignMentRuleData.rule_name}>
            <p className="font-dark text-capitalize text-truncate width-md" title={assignMentRuleData.rule_name}>
              {assignMentRuleData.rule_name}
            </p>
          </FPToolTip>
        </Box>
        <div className="col-5">
          <span className="headText" style={{ minWidth: "230px" }}>
            Rating
          </span>
          <div>
            {assignMentRuleData.rating_options
              .slice()
              .sort((a, b) => {
                const order = ["one_rating", "two_rating", "three_rating", "four_rating", "five_rating"];
                return order.indexOf(a) - order.indexOf(b);
              })
              .map((rating, i) => {
                const { star_border_color } = utils.starRatingColor(ratingOptions[rating]);
                return (
                  <Box key={rating} className="rating" sx={{ borderColor: star_border_color, padding: "4px 7px" }}>
                    <CustomStarRatingComponent
                      name={`rate-${i}`}
                      starCount={1}
                      className="align-bottom"
                      starColor="#FBBD08"
                      emptyStarColor="#FFFFFF"
                      value={ratingOptions[rating]}
                      editing={false}
                    />
                    <span style={{ marginLeft: "10px" }}>{ratingOptions[rating]}</span>
                  </Box>
                );
              })}
          </div>
        </div>
        <Box className="col-3" pr={0}>
          <span className="headText">Assigned To</span>
          <p className="textBold text-capitalize">{membersMap[assignMentRuleData.assigned_to]}</p>
        </Box>
      </div>

      <Divider />
      <Box className="d-flex" sx={{ height: 204 }}>
        <Box className="col-4" pl={0}>
          <Box component={"p"} className="font-dark" sx={{ fontSize: 17, fontWeight: 500, marginBottom: "10px" }}>
            Locations
          </Box>
          <Box sx={secondSection}>
            {assignMentRuleData?.locations?.length > 0
              ? assignMentRuleData.locations.map((i, index) => (
                  <p key={index} className="font-dark mb-0">
                    {locationMap[i]}
                  </p>
                ))
              : "-"}
          </Box>
        </Box>
        <Box
          sx={{
            borderLeft: "1px solid #0000000f",
            borderRight: "1px solid #0000000f",
            height: "100%",
            width: "1px",
          }}
        ></Box>
        <Box className="col-4" pr={0}>
          <Box component={"p"} className="font-dark" sx={{ fontSize: 17, fontWeight: 500, marginBottom: "10px" }}>
            Service Type
          </Box>
          <Box sx={secondSection}>
            {assignMentRuleData?.service_type?.length > 0
              ? assignMentRuleData?.service_type.map((i, index) => (
                  <p key={index} className="font-dark mb-0">
                    {i}
                  </p>
                ))
              : "-"}
          </Box>
        </Box>
        <Box
          sx={{
            borderLeft: "1px solid #0000000f",
            borderRight: "1px solid #0000000f",
            height: "100%",
            width: "1px",
          }}
        ></Box>
        <Box className="col-4" pr={0}>
          <Box component={"p"} className="font-dark" sx={{ fontSize: 17, fontWeight: 500, marginBottom: "10px" }}>
            Followed by
          </Box>
          <Box sx={secondSection}>
            {assignMentRuleData?.followed_by.length > 0
              ? assignMentRuleData?.followed_by.map((i, index) => (
                  <p key={index} className="font-dark mb-0">
                    <span>{membersMap[i]}</span>
                  </p>
                ))
              : "-"}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default TicketAssignmentRule;
