import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TabContent, TabPane, Row, Col } from "reactstrap";
import cookie from "react-cookies";
import queryString from "query-string";
import * as PaymentsActions from "../../actions/payments/PaymentsActions";
import InvoiceList from "../../components/payments/InvoiceList";
import PaginationComponent from "../../components/common/Pagination";
import LoaderComponent from "../../components/common/loaderComponent";
import { FPTab } from "../../components/common/new/tabs";

class PaymentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
    //PageSize Handling
    const pageSize = cookie.load("pageSize") || {};
    const {
      history,
      location: { pathname, search },
    } = props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        page_size: pageSize.payments_page || 10,
      }),
    });
  }

  componentDidMount() {
    const {
      match: {
        params: { business_id, agency_id },
      },
      location: { search },
    } = this.props;

    this.props.actions.getInvoiceList({
      businessId: business_id ? business_id : agency_id,
      ...queryString.parse(search),
    });
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-line
    const {
      location: { search: prevSearch },
      match: { params: prevParams },
    } = this.props;
    const {
      location: { search: nextSearch },
      match: { params: nextParams },
    } = nextProps;
    if (prevSearch !== nextSearch || prevParams !== nextParams) {
      this.props.actions.getInvoiceList({
        ...queryString.parse(nextSearch),
        businessId: nextParams.business_id
          ? nextParams.business_id
          : nextParams.agency_id,
      });
    }
  }

  toggleTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  payNow = (invoice_id) => {
    const {
      match: {
        params: { business_id, agency_id },
      },
      location: { search },
    } = this.props;
    const id = business_id ? business_id : agency_id;
    this.props.actions.getInvoiceOptions(id, invoice_id).then((res) => {
      const options = {
        ...res,
        handler: (response) => {
          this.props.actions
            .postPaymentDetails(business_id, {
              ...response,
              invoice: invoice_id,
            })
            .then(() => {
              this.props.actions.getInvoiceList({
                businessId: business_id,
                ...queryString.parse(search),
              });
            });
        },
      };
      let rzp = new window.Razorpay(options);
      rzp.open();
    });
  };

  downloadInvoice = (invoice_id) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions.downloadInvoice(business_id, invoice_id);
  };

  render() {
    const { invoices } = this.props;
    return (
      <Row className="">
        <LoaderComponent loader={this.props.loader} />
        <Col xs={12} sm={12} className="business-setup p-2">
          {invoices &&
            invoices.results.filter((i) => i.status === "overdue").length >
              0 && (
              <Row>
                <Col xs={12} sm={12} className="p-3">
                  <div
                    className="p-2 bg-danger text-white font-weight-bold rounded-sm"
                    style={{ fontSize: "15px" }}
                  >
                    <i className="fas fa-exclamation-circle text-white mr-2" />
                    Account is currently suspended due to non payment. Please
                    make payment using Pay Now to activate the account.
                  </div>
                </Col>
              </Row>
            )}
          <FPTab
            tabLabels={["Invoices", "Subscriptions"]}
            minTabWidth={220}
            defaultValue={this.state.activeTab - 1}
            onChange={(value) => this.toggleTab((value + 1).toString())}
          />
          <TabContent activeTab={this.state.activeTab} className="mt-4">
            <TabPane tabId="1">
              <InvoiceList
                invoices={invoices && invoices.results}
                payNow={this.payNow}
                downloadInvoice={this.downloadInvoice}
              />
              <PaginationComponent
                count={invoices && invoices.count}
                location={this.props.location}
                pageSizeCookie="payments_page"
              />
            </TabPane>
            <TabPane tabId="2"></TabPane>
          </TabContent>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoices: state.payments.invoices,
    loader: state.common.loader,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(PaymentsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PaymentsPage);
