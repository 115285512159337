import React from "react";
import Chart from "react-apexcharts";

class ApexChart extends React.Component {
  render() {
    const options = {
      series: this.props.series,
      options: {
        colors: ["#ff4545", "#ffe234", "#57e32c"],
        chart: {
          type: "bar",
          stacked: "true",
          height: "1000",
          width: "100%",
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
        },
        responsive: [
          {
            breakpoint: undefined,
            options: {},
          },
        ],
        plotOptions: {
          bar: {
            borderRadius: 4,
            barHeight: "50px",
            horizontal: true,
          },
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.props.dataKeys,
          title: {
            text: "Count",
            style: {
              colors: "rgba(122, 129, 147, 1)",
              fontFamily: "Outfit !important",
              fontWeight: 400,
            },
          },
        },
        yaxis: {
          title: {
            text: this.props.report === "count" ? "Tags" : "Date",
            style: {
              colors: "rgba(122, 129, 147, 1)",
              fontFamily: "Outfit !important",
              fontWeight: 400,
            },
          },
        },
        title: {
          display: false,
          // text: this.props.overtime?'Tags Report Overtime':'Tags Report By Count',
          // align: 'center',
          // style: {
          //   fontFamily: 'Segoe UI,Arial,sans-serif',
          //   fontWeight: 600,
          //   fontSize: '18px'
          // }
        },
        legend: {
          position: "bottom",
          markers: {
            width: 10,
            height: 10,
            radius: 5,
          },
        },
        grid: {
          // To remove both x-axis and y-axis grid lines
          show: false,
        },
      },
    };

    return (
      <div id="chart">
        <Chart
          options={options.options}
          series={options.series}
          colors={["#32CD32", "#D10000", "#FCAE1E"]}
          type="bar"
        />
      </div>
    );
  }
}

export default ApexChart;
