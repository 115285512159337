import React, { useState } from "react";
import "./central-branch.css";
import { Box } from "@material-ui/core";
import { FPBadge, FPCheckbox, FPButton } from "../common/new";
import { Divider } from "semantic-ui-react";
import { FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import FPSelect from "../common/new/forms/ui/FPSelect";
import { daysList, hourList } from "../../libs/constant";
import { FillClose } from "../../images/svg";
import ErrorMessage from "../common/new/forms/ui/ErrorMessage";
import FormAutoComplete from "../common/new/forms/FormAutoSelect";

function TicketEsacalationForm(props) {
  const {
    designation,
    initialData,
    onApply,
    addTicketEscalation,
    editTicketEscalation,
    escalationId,
    handleEscalationClose,
  } = props;

  const [isValidEmail, setIsValidEmail] = useState(true);

  // const validationSchema = yup.object().shape({
  //   status: yup.string().required().label("Status"),
  //   more_then_unit: yup.string().required().label("Hours/Days"),
  //   more_then_value: yup.string().required().label("Hours/Days"),
  //   send_reminder: yup.string().label(""),
  //   send_escalation: yup.string().label(""),
  //   escalate_to: yup
  //     .array()
  //     .of(yup.string().email("Invalid email address").required("Email address is required"))
  //     .test({
  //       name: "escalateCheck",
  //       message: "Please select either escalate_to or escalate_to_designation",
  //       test: function (value) {
  //         const { escalate_to_designation } = this.parent;
  //         return value.length > 0 || escalate_to_designation.length > 0;
  //       },
  //     }),
  //   escalate_to_designation: yup.array().test({
  //     name: "escalateCheck",
  //     message: "Please select either escalate_to or escalate_to_designation",
  //     test: function (value) {
  //       const { escalate_to } = this.parent;
  //       return value.length > 0 || escalate_to.length > 0;
  //     },
  //   }),
  // });

  const validationSchema = yup.object().shape({
    status: yup.string().required().label("Status"),
    more_then_unit: yup.string().required().label("Hours/Days"),
    more_then_value: yup.string().required().label("Hours/Days"),
    send_reminder: yup.string().label(""),
    send_escalation: yup.boolean().label("Send Escalation"), // Assuming send_escalation is a boolean
    escalate_to: yup
      .array()
      .of(yup.string().email("Invalid email address").required("Email address is required"))
      .when("send_escalation", {
        is: true,
        then: yup
          .array()
          .of(yup.string().email("Invalid email address").required("Email address is required"))
          .test({
            name: "escalateCheck",
            message: "Please select either escalate_to or escalate_to_designation",
            test: function (value) {
              const { escalate_to_designation } = this.parent;
              return value.length > 0 || escalate_to_designation.length > 0;
            },
          }),
        otherwise: yup.array().notRequired(),
      }),
    escalate_to_designation: yup.array().when("send_escalation", {
      is: true,
      then: yup.array().test({
        name: "escalateCheck",
        message: "Please select either escalate_to or escalate_to_designation",
        test: function (value) {
          const { escalate_to } = this.parent;
          return value.length > 0 || escalate_to.length > 0;
        },
      }),
      otherwise: yup.array().notRequired(),
    }),
  });

  const statusData = [
    { label: "New", color: "#2375ef", value: "new" },
    { label: "Assigned", color: "#f8ae00", value: "assigned" },
    { label: "Reopened", color: "#e16b16", value: "reopened" },
    {
      label: "Waiting for Customer",
      color: "#FFDC54",
      value: "waiting_for_customer",
    },
  ];

  const moreThanFirstList = [
    {
      display_name: "Hours",
      value: "hour",
    },
    {
      display_name: "Days",
      value: "day",
    },
  ];

  const escalateSecList = designation?.map((item) => ({
    value: item.id,
    display_name: item.name,
  }));
  const handleCheckboxChange = (e, setFieldValue, values) => {
    const { name, checked } = e.target;

    if (name === "send_escalation") {
      setFieldValue("send_escalation", checked);
      if (checked === false && !values.send_reminder) {
        setFieldValue("send_escalation", true);
      }
    } else if (name === "send_reminder") {
      setFieldValue("send_reminder", checked);
      if (checked === false && !values.send_escalation) {
        setFieldValue("send_reminder", true);
      }
    }
  };

  return (
    <div>
      <span className="escalation-title">Escalation Rule {escalationId}</span>
      <Formik
        initialValues={Object.assign(
          {
            status: "",
            more_then_unit: "",
            more_then_value: "",
            send_escalation: false,
            send_reminder: true,
            not_responded: false,
            escalate_to: [],
            escalate_to_designation: [],
          },
          initialData
        )}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const modifyData = {
            ...values,
            ["escalate_to"]: JSON.stringify(values.escalate_to),
            ["escalate_to_designation"]: JSON.stringify(values.escalate_to_designation),
          };
          isValidEmail && initialData && Object.keys(initialData).length > 0
            ? editTicketEscalation(modifyData, onApply)
            : addTicketEscalation(modifyData, onApply);
        }}
      >
        {({ values, errors, touched, setFieldValue, handleChange, handleSubmit, handleBlur }) => {
          return (
            <Form>
              <Box className="border mt-3 p-3" sx={{ borderRadius: "8px" }}>
                {/* Ticket-Status */}
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <div className="my-auto" style={{ minWidth: "150px" }}>
                      <span className="statusText">
                        If ticket is open with status
                        <Box component="span" sx={{ color: "red" }}>
                          *
                        </Box>
                      </span>
                    </div>
                    <div className="d-flex TicketStatusBox">
                      {statusData.map((item) => (
                        <FPBadge
                          label={item.label}
                          className={`ticketStatus ${values.status === item.value ? "selected" : ""}`}
                          textClass="trending-header"
                          color={item.color}
                          onClick={() => setFieldValue("status", item.value)}
                        />
                      ))}
                    </div>
                  </div>
                  {errors.status && touched.status && <ErrorMessage className="mt-0">{errors.status}</ErrorMessage>}
                </div>
                {/* More - Than */}
                {/* MoreThanSec value not display */}
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <div className="my-auto">
                      <span className="statusText">
                        from more than
                        <Box component="span" sx={{ color: "red" }}>
                          *
                        </Box>
                      </span>
                    </div>
                    <div className="d-flex gap-20" style={{ marginRight: "10px" }}>
                      <FormAutoComplete
                        name="more_then_unit"
                        placeholder={values.more_then_unit?.length === 0 && "Select Hours/Days"}
                        options={moreThanFirstList}
                        value={values.more_then_unit}
                        sx={{
                          width: 360,
                          "& fieldset": { borderRadius: 2 },
                          "& .MuiInputBase-input": {
                            minWidth: "360px !important",
                            fontSize: "12px",
                            color: "#646565 !important",
                            "&::placeholder": {
                              color: "#646565",
                            },
                          },
                        }}
                      />
                      <FormAutoComplete
                        name="more_then_value"
                        placeholder={values?.more_then_value?.length === 0 && "Select"}
                        options={
                          values.more_then_unit === "hour" ? hourList : values.more_then_unit === "day" ? daysList : []
                        }
                        value={values.more_then_value}
                        disabled={!values.more_then_unit?.length}
                        sx={{
                          width: 90,
                          "& fieldset": { borderRadius: 2 },
                          "& .MuiInputBase-input": {
                            minWidth: "90px !important",
                            fontSize: "12px",
                            color: "#646565 !important",
                            "&::placeholder": {
                              color: "#646565",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                  {(errors.more_then_unit || errors.more_then_value) &&
                    (touched.more_then_unit || touched.more_then_unit) && (
                      <ErrorMessage className="mt-0">{errors.more_then_unit || errors.more_then_value}</ErrorMessage>
                    )}
                </div>

                {/* Assignee - has */}
                <div className="mb-3">
                  <div className="d-flex gap-50">
                    <div className="my-auto" style={{ minWidth: "150px" }}>
                      <span className="statusText">and if assignee has</span>
                    </div>
                    <FPCheckbox
                      name="not_responded"
                      onChange={handleChange}
                      checked={values.not_responded}
                      label="Not Responded"
                      labelClassName="d-flex"
                    />
                  </div>
                </div>

                {/* send reminder */}
                <div className="mb-3">
                  <div className="d-flex gap-50">
                    <div className="my-auto" style={{ minWidth: "150px" }}>
                      <span className="statusText">Send Reminder</span>
                    </div>
                    <FPCheckbox
                      name="send_reminder"
                      onChange={(e) => handleCheckboxChange(e, setFieldValue, values)}
                      // onChange={handleChange}
                      checked={values.send_reminder}
                      label="Send Reminder"
                      labelClassName="d-flex"
                    />
                  </div>
                </div>

                {/* send escalation */}
                <div className="mb-3">
                  <div className="d-flex gap-50">
                    <div className="my-auto" style={{ minWidth: "150px" }}>
                      <span className="statusText">Send Escalation</span>
                    </div>
                    <FPCheckbox
                      name="send_escalation"
                      onChange={(e) => handleCheckboxChange(e, setFieldValue, values)}
                      // onChange={handleChange}
                      checked={values.send_escalation}
                      label="Send Escalation"
                      labelClassName="d-flex"
                    />
                  </div>
                </div>

                {/* Escalate */}
                <div
                  style={{
                    pointerEvents: !values.send_escalation ? "none" : "auto",
                    opacity: !values.send_escalation ? 0.5 : 1,
                  }}
                >
                  <div className="d-flex gap-50">
                    <div className="my-auto" style={{ minWidth: "150px" }}>
                      <span className="statusText">
                        then escalate to
                        <Box component="span" sx={{ color: "red" }}>
                          *
                        </Box>
                      </span>
                    </div>
                    <div className="d-flex flex-column w-100">
                      <FieldArray name="escalate_to">
                        {({ remove, push }) => (
                          <div className="d-flex gap-10 flex-wrap">
                            {values.escalate_to.length > 0 &&
                              values.escalate_to.map((escalate, index) => {
                                return (
                                  <Box
                                    key={index}
                                    sx={{
                                      fontSize: 14,
                                      display: "flex",
                                      backgroundColor: "#DDEFF0",
                                      color: "#1B1C1D",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      paddig: "0 10px",
                                      borderRadius: "120px",
                                      gap: "15px",
                                      minWidth: "120px",
                                      minWidth: "160px",
                                      padding: "0 10px",
                                    }}
                                  >
                                    <p className="m-0 line-height-1">{escalate}</p>
                                    <span
                                      role="button"
                                      className="cursor-pointer d-flex"
                                      onClick={() => remove(index)}
                                      style={{ marginTop: "1px" }}
                                    >
                                      <FillClose />
                                    </span>
                                  </Box>
                                );
                              })}
                            <>
                              <input
                                name="escalate_to"
                                className="morethanFirst-email"
                                placeholder="add email address"
                                type="email"
                                autoComplete="off"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    const email = e.target.value.trim();
                                    const emailExists = values.escalate_to.includes(email);
                                    if (email && yup.string().email().isValidSync(email)) {
                                      if (emailExists) {
                                        setIsValidEmail(false);
                                      } else {
                                        push(email);
                                        e.target.value = "";
                                        setIsValidEmail(true);
                                      }
                                    } else {
                                      setIsValidEmail(false);
                                    }
                                  }
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                  const email = event.target.value.trim();
                                  const emailExists = values.escalate_to.includes(email);
                                  yup.string().email().isValidSync(email) && !emailExists
                                    ? setIsValidEmail(true)
                                    : setIsValidEmail(false);
                                }}
                              />
                              {!isValidEmail && (
                                <ErrorMessage className="mt-2">
                                  {"Please enter a valid and unique email address."}
                                </ErrorMessage>
                              )}
                            </>
                          </div>
                        )}
                      </FieldArray>
                      <Divider className="w-100 mb-0 mt-2" />
                      <Box
                        sx={{
                          fontSize: "18px",
                          textAlign: "center",
                          marginTop: "9px",
                        }}
                      >
                        or/and
                      </Box>
                      <div className="d-flex flex-column">
                        <FormAutoComplete
                          multiple={true}
                          placeholder={values.escalate_to_designation.length === 0 && "Select Options"}
                          options={escalateSecList}
                          variant="standard"
                          name={"escalate_to_designation"}
                        />
                        <Divider className="w-100 m-0" />
                      </div>
                    </div>
                  </div>
                  {values.send_escalation &&
                    (errors.escalate_to || errors.escalate_to_designation) &&
                    (touched.escalate_to || touched.escalate_to_designation) && (
                      <ErrorMessage className="mt-2">
                        {errors.escalate_to || errors.escalate_to_designation}
                      </ErrorMessage>
                    )}
                </div>
              </Box>
              <div className="d-flex justify-content-end mt-3">
                <FPButton
                  label="Cancel"
                  size="small"
                  type="button"
                  variant="outline"
                  className="mr-2"
                  onClick={() => handleEscalationClose()}
                />
                <FPButton label="Submit" size="small" type="button" onClick={handleSubmit} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default TicketEsacalationForm;
