import { Box } from "@material-ui/core";
import React from "react";
import SearchComponent from "../../components/common/SearchComponent";
import { FPButton } from "../../components/common/new";
import { Send, TextAlign } from "../../images/svg";

const titleSx = {
  color: "#1B1C1D",
  fontSize: "18px",
  fontWeight: 500,
  marginBottom: 0,
};

const subTitleSx = {
  color: "#7A8193",
  fontSize: "16px",
  marginBottom: "10px !important",
};

const TemplateModal = () => {
  return (
    <div>
      <SearchComponent />
      <div className="mt-3">
        <Box component="p" sx={titleSx}>
          Review Request Template
        </Box>
        <Box component="p" sx={subTitleSx}>
          Thankyou for choosing us. Please take a moment to leave us a review.
        </Box>
      </div>
      <div>
        <Box component="p" sx={titleSx}>
          Survey Template
        </Box>
        <Box component="p" sx={subTitleSx}>
          We were wondering if you could spare few minutes to let us know more about your experience.
        </Box>
      </div>
      <div>
        <Box component="p" sx={titleSx}>
          Referral Template
        </Box>
        <Box component="p" sx={subTitleSx}>
          We were wondering if you could spare few minutes to let us know more about your experience.
        </Box>
      </div>
      <div>
        <Box component="p" sx={titleSx}>
          Survey Template
        </Box>
        <Box component="p" sx={subTitleSx}>
          We’d like to get your feedback to help improve our product. Your survey responses are only used...
        </Box>
      </div>
      <div>
        <Box component="p" sx={titleSx}>
          Customer Experience Template
        </Box>
        <Box component="p" sx={subTitleSx}>
          We were wondering if you could spare few minutes to let us know more about your experience.
        </Box>
      </div>
      <div>
        <div className="d-flex gap-10 justify-content-end mt-4">
          <FPButton label="Manage Templates" size="small" startIcon={<TextAlign />} backgroundColor="#438E96" />
          <FPButton label="Send" size="small" endIcon={<Send />} />
        </div>
      </div>
    </div>
  );
};

export default TemplateModal;
