import React from "react";
import { FormFeedback } from "reactstrap";
import Radio from "@material-ui/core/Radio";
import { FormControlLabel } from "@material-ui/core";
import FPLabel from "./new/forms/ui/FPLabel";

class RadioComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { checked } = this.props;
    this.handleChange(checked);
  }

  handleChange = (value) => {
    const { input } = this.props;
    input.onChange(value);
  };

  render() {
    const {
      options,
      input,
      label,
      meta: { touched, error },
      inline,
    } = this.props;
    if (!input.value) {
      input.value = false;
      this.handleChange(input.value);
    }
    return (
      <div>
        <div className="d-flex align-items-center">
          {/* <FPLabel className="m-0">{label}</FPLabel> */}
          <div className="d-flex flex-column">
            {options.map((item, key) => (
              <div key={key} className={inline ? "inline mr-4" : ""}>
                {/* <Radio
              label={item.display_name}
              checked={input.value===item.value}
              onChange={() => this.handleChange(item.value)}
            /> */}
                <FormControlLabel
                  control={<Radio />}
                  label={item.display_name}
                  checked={input.value === item.value}
                  onChange={() => this.handleChange(item.value)}
                />
              </div>
            ))}
          </div>
        </div>
        <FormFeedback className="-mt-10">{touched && error}</FormFeedback>
      </div>
    );
  }
}

export default RadioComponent;
