import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import MultiSelect from "../common/SelectComponent";
import FPLabel from "../common/new/forms/ui/FPLabel";
import { FPButton } from "../common/new";

const validate = (values) => {
  const errors = {};
  if (!values.user) {
    errors.user = "Please Select Team Member";
  }
  return errors;
};

const AssignMultiLocationForm = (props) => {
  const { handleSubmit, AssignMultiLocationSubmit, TeamMemberList, BranchList } = props;
  const submit = (data) => {
    return AssignMultiLocationSubmit(data).catch((err) => {
      const errobj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errobj);
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <div className="mb-3">
          <FPLabel isRequired={false}>Locations</FPLabel>
          <Field
            name="branch"
            component={MultiSelect}
            placeholder="Select Locations (All Locations by default)"
            options={BranchList}
            labelKey="display_name"
            valueKey="value"
            multi
            simpleValue
            style={{ marginBottom: "20px" }}
            className="h-100"
          />
        </div>
        <div>
          <FPLabel>Team Member</FPLabel>
          <Field
            name="user"
            component={MultiSelect}
            placeholder="Select Team Member"
            options={TeamMemberList}
            labelKey="display_name"
            valueKey="id"
            simpleValue
          />
        </div>
        <FPButton className="w-100 mt-4" label="Submit" type="submit" />
      </form>
    </>
  );
};

export default reduxForm({
  form: "AssignMultiLocationForm",
  enableReinitialize: true,
  validate,
})(AssignMultiLocationForm);
