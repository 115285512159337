/**
 * Project - fbs-web-app
 * Created by vivekgusain on 3/3/19
 */
import React from "react";
import FPChip from "../new/FPChip/FPChip";
// import { Card } from 'semantic-ui-react';
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import google from "../../../images/google.png";
import tripaAvisor from "../../../images/tripadvisor.png";
import zomato from "../../../images/zomato.png";

makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export default class SocialButtonsGroup extends React.PureComponent {
  createButton = () => {
    const { links } = this.props;

    let cards = [];
    for (let socialKey in links) {
      if (links[socialKey].link) {
        cards.push(
          <div key={socialKey}>
            <a
              href={links[socialKey].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <img
                src={links[socialKey].logo}
                width={150}
                alt={`${socialKey}-logo`}
              /> */}
            </a>
          </div>
        );
      }
    }

    return cards;
  };
  getLogo = (title, logo) => {
    if (title === "google") return google;
    if (title === "zomato") return zomato;
    if (title === "tripadvisor") return tripaAvisor;
    return logo;
  };

  render() {
    const { links } = this.props;
    return (
      <div>
        {/* <Card.Group className="social-btn-group" itemsPerRow={2} centered stackable>
         {this.createButton()}
       </Card.Group> */}
        <div
          className="social-btn-group"
          // itemsPerRow={2}
          // centered
          // stackable
        >
          {this.createButton()}
        </div>
        <div className="d-flex justify-content-center gap-6 flex-wrap FpChip-Box">
          {Object.entries(links).map(
            ([key, value]) =>
              value.link && (
                <FPChip
                  key={key}
                  avatar={
                    <Avatar
                      alt={`${value.title}-logo`}
                      src={this.getLogo(value.title, value.logo)}
                      className="FPChip-logo mr-2"
                    />
                  }
                  label={`Review us on ${value.title}`}
                  variant="outlined"
                  className="Fp-Chip"
                  onClick={() => window.open(value.link, "_blank")}
                />
              )
          )}
        </div>
      </div>
    );
  }
}
