import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FeedBackSelectStartIcon, FeedBackStartIcon } from '../../../../images/svg';
import { FormFeedback } from 'reactstrap';
import FPLabel from '../../../../components/common/new/forms/ui/FPLabel';

const starColors = ['#EC554E', '#F2A94D', '#FAE25A', '#BFDC50', '#81E050'];
const starLabels = ['Very Poor', 'Poor', 'Average', 'Good', 'Excellent'];

const StyledStarWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

const StyledStarButton = styled(IconButton)(({ index, rating, selectedColor, isSelected }) => ({
  padding: 5,
  '& .MuiSvgIcon-root': {
    fontSize: 44,
    color: isSelected ? selectedColor : 'transparent',
    stroke: isSelected ? selectedColor : starColors[index],
    strokeWidth: 1.5,
  },
}));

const Star = ({ index, rating, selectedStarIndex, onSelect,showLabel }) => {
  const isSelected = rating >= index + 1;
  const selectedColor = selectedStarIndex !== null ? starColors[selectedStarIndex] : '';

  return (
    <StyledStarWrapper>
      <StyledStarButton 
        index={index} 
        rating={rating} 
        selectedColor={selectedColor}
        isSelected={isSelected}
        onClick={() => onSelect(index + 1)}
      >
        {isSelected ? <FeedBackSelectStartIcon color={selectedColor}/> : <FeedBackStartIcon color={starColors[index]} />}
      </StyledStarButton>
    </StyledStarWrapper>
  );
};

const FeedBackStarRating = ({ onRatingChange, values, error, title, showLabel = true, containerMargin = true }) => {
  const [rating, setRating] = useState(values || 0);
  const [selectedStarIndex, setSelectedStarIndex] = useState(values ? values - 1 : null);

  const StarContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: containerMargin ? 24 : 0,
    marginBottom: containerMargin ? 16 : 0,
    gap: 10,
  });

  useEffect(() => {
    if (values) {
      setRating(values);
      setSelectedStarIndex(values - 1);
    }
  }, [values]);

  const handleSelect = (newRating) => {
    setRating(newRating);
    setSelectedStarIndex(newRating - 1);
    if (onRatingChange) {
      onRatingChange(newRating);
    }
  };

  return (
    <>
      {title && <FPLabel>{title}</FPLabel>}
      <StarContainer containerMargin={containerMargin}>
        {[...Array(5)].map((_, index) => (
          <Star 
            key={index} 
            index={index} 
            rating={rating} 
            selectedStarIndex={selectedStarIndex}
            onSelect={handleSelect} 
            showLabel={showLabel}
          />
        ))}
      </StarContainer>
      {showLabel && selectedStarIndex === null && (
        <Box display="flex" justifyContent="space-between" width="100%" maxWidth={250} mx="auto">
          <Typography variant="caption" sx={{ fontSize: 12 }}>Very Poor</Typography>
          <Typography variant="caption" sx={{ fontSize: 12 }}>Excellent</Typography>
        </Box>
      )}
      {/* Show only the selected label after selection */}
      {showLabel && selectedStarIndex !== null && (
        <Box textAlign="center" mt={1}>
          <Typography variant="caption" sx={{ fontSize: "14px", backgroundColor: starColors[selectedStarIndex], color: 'black', padding: '4px 8px', borderRadius: "4px" }}>
            {starLabels[selectedStarIndex]}
          </Typography>
        </Box>
      )}

      {error && <FormFeedback>{error}</FormFeedback>}
    </>
  );
};

export default FeedBackStarRating;
