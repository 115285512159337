import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import axios_instance from "../../libs/interseptor";
import { displayMessage } from "../../libs/utils";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import FormModal from "../common/FormModal";
import FPLabel from "../common/new/forms/ui/FPLabel";
import AutoSelect from "../common/AutoSelect";
import FPInput from "../common/new/forms/ui/FPInput";
import { CSSTransitionGroup } from "react-transition-group";
import FPChip from "../common/new/FPChip/FPChip";
import AIGenerated from "../../images/svg/AIGenerated.svg";
import ConfirmationModal from "../common/ConfirmationModal";
import { FPButton } from "../common/new";
import LoaderComponent from "../messenger/Common/LoaderComponent";

const AddAttributeModal = ({ initialValues, business_id, toggle, open }) => {
  const [attribute_name, setAttribute] = useState("");
  const [sentiment, setSentiment] = useState("");
  const [allData, setAllData] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [attributeId, setAttributeId] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    axios_instance
      .get(
        `${utils.format(URLS.MENU_ATTRIBUTE, [business_id])}/?product_name=${
          initialValues.product_name !== "" ? initialValues.product_name : ""
        }`
      )
      .then((res) => {
        setLoader(false);
        const new_data = [];
        res.data.results.map((val) => {
          val.attribute_name &&
            new_data.push({
              id: val.id ? val.id : "",
              attribute_name: val.attribute_name,
              sentiment: val.sentiment ? val.sentiment : "",
              is_ai_generated: val.is_ai_generated ? val.is_ai_generated : false,
            });
          setAllData([...new_data]);
        });
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }, []);

  const handleAdd = (event) => {
    event.preventDefault();
    const payload = {
      product_name: initialValues.product_name ? initialValues.product_name : "",
      attribute_name: attribute_name,
      sentiment: sentiment,
    };
    axios_instance
      .post(`${utils.format(URLS.MENU_ATTRIBUTE, [business_id])}/`, payload)
      .then((res) => {
        console.log(res);
        displayMessage("positive", "Attribute added succesfully !");
        setAllData([...allData, { attribute_name: attribute_name, sentiment: sentiment }]);
        setAttribute("");
        setSentiment(undefined);
      })
      .catch((err) => {
        console.log(err);
        displayMessage("negative", "Attribute not added !");
      });
  };

  const sentimentOptions = [
    { value: "positive", display_name: "Positive" },
    { value: "neutral", display_name: "Neutral" },
    { value: "negative", display_name: "Negative" },
  ];

  const handleDeleteAttribute = (index) => {
    let newData = [];
    allData.map((value, i) => {
      if (i !== index) {
        newData.push({ ...value });
      }
    });
    axios_instance
      .delete(`${utils.format(URLS.MENU_ATTRIBUTE, [business_id])}/${allData[index].id}/`)
      .then((res) => {
        console.log(res);
        displayMessage("positive", "Attribute deleted successfully !");
        setAllData([...newData]);
      })
      .catch((err) => {
        console.log(err);
        displayMessage("negative", "Attribute not deleted !");
      });
  };
  const handleSentimentSearch = (value) => {
    setSentiment(value);
  };
  const handleClick = (index) => {
    setAttributeId(index);
    setIsConfirm(true);
  };
  const toggleConfirm = () => {
    handleDeleteAttribute(attributeId);
    setIsConfirm(false);
  };
  return (
    <>
      <FormModal
        isOpen={open}
        showDivider={false}
        toggle={toggle}
        heading={
          <>
            <span style={{ fontSize: "20px !important" }} className="mr-2">
              Add Attribute
            </span>
            <span style={{ color: "gray", fontSize: "20px !important" }}>({initialValues.product_name})</span>
          </>
        }
        width="700px"
      >
        <Row>
          <Col sm="6" className="mt-20">
            <FPLabel isRequired={false}>Attribute</FPLabel>
            <FPInput
              placeholder="Attribute Name"
              onChange={(event) => {
                event.preventDefault();
                setAttribute(event.target.value);
              }}
              value={attribute_name}
            />
          </Col>
          <Col sm="6" className="mt-20">
            <FPLabel isRequired={false}>Sentiment</FPLabel>
            <AutoSelect
              placeholder="Sentiment"
              options={sentimentOptions}
              onChange={(event, option) => {
                handleSentimentSearch(option);
              }}
              value={sentiment}
            />
          </Col>
        </Row>
        {allData && Array.isArray(allData) && allData.length > 0 && (
          <div className="mt-15">
            <span style={{ fontSize: "14px", color: "#1B1C1D", fontWeight: "500", marginBottom: "10px" }}>
              Attributes
            </span>
            {loader ? (
              <LoaderComponent showLoader={loader} />
            ) : (
              <CSSTransitionGroup
                transitionName="reviews-tags"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}
                className="d-flex flex-wrap gap-10 align-items-center mt-10"
              >
                {allData.map((tag, index) => {
                  return (
                    <FPChip
                      key={`${index}-attributes-tags`}
                      size="small"
                      variant="outlined"
                      style={{
                        borderColor:
                          tag.sentiment === "positive"
                            ? "#81E050"
                            : tag.sentiment === "negative"
                            ? "#EC554E"
                            : tag.sentiment === "neutral"
                            ? "#FAE25A"
                            : "#BFC2CB",
                        borderWidth: "2px",
                      }}
                      className="review-tag-chip fp-size-13"
                      label={
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="cursor-pointer">{utils.snakeToCapital(tag.attribute_name)}</span>
                          &nbsp;
                          {tag.is_ai_generated && <img src={AIGenerated} />}
                        </div>
                      }
                      onDelete={tag.is_ai_generated ? null : () => handleClick(index)}
                    />
                  );
                })}
              </CSSTransitionGroup>
            )}
          </div>
        )}
        <div className="d-flex justify-content-between mt-20">
          <FPButton
            label="Cancel"
            onClick={(event) => {
              event.preventDefault();
              setAttribute("");
              setSentiment("");
            }}
            variant="outline"
          />
          <FPButton
            label="Add"
            onClick={(event) => {
              handleAdd(event);
            }}
            disabled={
              !sentiment ||
              (sentiment && sentiment.trim().length <= 0) ||
              (attribute_name && attribute_name.trim().length <= 0)
            }
          />
        </div>
      </FormModal>
      <ConfirmationModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader="Are you sure you want to delete this attribute?"
        isOpen={isConfirm}
        toggle={() => setIsConfirm(!isConfirm)}
        onConfirm={() => toggleConfirm()}
      />
    </>
  );
};

export default AddAttributeModal;
