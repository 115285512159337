import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import LoaderComponent from "../common/loaderComponent";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FPButton, FPToolTip } from "../common/new";
import * as utils from "../../libs/utils";
import AddIcon from "@material-ui/icons/Add";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import UploadFileModal from "../common/new/UploadFileModal";
import * as URLS from "../../libs/apiUrls";
import cookie from "react-cookies";
import add from "../../images/plus.png";
import minus from "../../images/minus.png";
import deleteIcon from "../../images/svg/trash.svg";
import ConfirmationModal from "../common/ConfirmationModal";
import axios from "axios";
import PaginationComponent from "../common/Pagination";
import MenuOutStock from "./setupMenu/MenuOutStock";

const MenuResponseList = (props) => {
  const { menuList, addMenu, loader, businessId, history, match, location, deleteMenu, pageSizeCookie } = props;

  const [expandedRow, setExpandedRow] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [menuId, setMenuId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("menu");

  const toggleRowExpansion = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const toggleUploadReview = () => {
    setIsOpen(!isOpen);
  };

  const menuCsvUpload = (data) => {
    const authToken = cookie.load("authToken");
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const {
      match: {
        params: { business_id },
      },
    } = props;

    const url = `${utils.format(URLS.MENU_LIST, [business_id])}upload_csv/`;
    return axios
      .post(url, data, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err?.response?.data;
      });
  };

  const uploadMenuFile = (data) => {
    const {
      history,
      location: { pathname },
    } = props;
    const file = data.target.files[0];
    setIsLoading(true);
    const fileExt = file && file?.name.split(".").pop();
    const excelExt = ["xls", "xlsx", "xlsm", "csv"];
    if (file?.size > 2100000) {
      utils.displayMessage("negative", "File cannot be larger than 2mb.");
      setIsLoading(false);
    } else {
      if (excelExt.indexOf(fileExt) > -1) {
        const newDetail = new FormData();
        newDetail.append("file", file);
        return menuCsvUpload(newDetail)
          .then((res) => {
            utils.displayMessage("positive", res.data.message);
            setIsLoading(false);
            history.push({
              pathname,
              search: "?page=1&page_size=10",
              state: { tab: "9" },
            });
            this.props.toggle();
            // this.context.router.history.push({pathname: 'central-setup',state: { tab: '9'} });
          })
          .catch((err) => {
            let errors = err?.errors || [];
            if (errors && Array.isArray(errors)) {
              for (let index = 0; index < errors.length; index++) {
                if (index < 5) {
                  utils.displayMessage(
                    "negative",
                    errors[index]?.error
                      ? `Row ${errors[index]?.row} ` + errors[index]?.error
                      : `Row ${errors[index]?.row} Error`
                  );
                } else {
                  break;
                }
              }
            } else {
              utils.displayMessage("negative", "Error");
            }
            setIsLoading(false);
          });
      } else {
        document.getElementById("file").value = null;
        utils.displayMessage("info", "Invalid file type! Allowed extensions are .xls, .xlsx, .csv, .xlsm.");
        setIsLoading(false);
      }
    }
    return true;
  };

  const downloadMenuTemplate = () => {
    const token = cookie.load("authToken");
    //dispatch(commonAction.showLoader());
    const {
      match: {
        params: { business_id },
      },
    } = props;
    const url = `${utils.format(URLS.MENU_LIST, [business_id])}sample_file_download/`;
    return fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/csv",
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        //dispatch(commonAction.hideLoader());
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "menu_sample.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        console.log(err);
        //dispatch(commonAction.hideLoader());
      });
  };

  const toggleConfirm = () => {
    deleteMenu(menuId);
    setIsConfirm(false);
  };

  const handleClick = (id) => {
    setMenuId(id);
    setIsConfirm(!isConfirm);
  };

  return (
    <div>
      <LoaderComponent loader={loader} />
      <div className="d-flex justify-content-between align-items-center mt-4 mb-4">
        <div className="d-flex gap-20 fp-size-16 align-items-center " style={{ fontWeight: 500 }}>
          <span
            style={{ cursor: "pointer", color: selectedTab === "menu" ? "deeppink" : "rgba(100, 101, 101, 1)" }}
            onClick={() => setSelectedTab("menu")}
          >
            Menu
          </span>
          <span
            style={{ cursor: "pointer", color: selectedTab === "stockMonitor" ? "deeppink" : "rgba(100, 101, 101, 1)" }}
            onClick={() => setSelectedTab("stockMonitor")}
          >
            Out of Stock Monitor
          </span>
        </div>
        {selectedTab === "menu" && (
          <div>
            <FPButton
              label="Add Menu"
              type="button"
              onClick={addMenu}
              style={{ height: "30px" }}
              endIcon={<AddIcon style={{ height: 16, width: 16, color: "white" }} />}
            />
            <FPButton
              label="Bulk Upload"
              variant="outline"
              type="button"
              style={{ height: "30px" }}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="ml-10"
              endIcon={<AddIcon style={{ height: 16, width: 16 }} />}
            />
          </div>
        )}
      </div>
      {isOpen && (
        <UploadFileModal
          isOpen={isOpen}
          toggle={toggleUploadReview}
          heading={"Upload Menu"}
          uploadExcel={uploadMenuFile}
          downloadExcel={downloadMenuTemplate}
          history={history}
          location={location}
          match={match}
        />
      )}
      {selectedTab === "menu" && (
        <CardWrapper showDivider={false} contentClass="p-3">
          <div className="ui-table-responsive">
            <Table className="fp-table fp-table-center">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>S.No</TableCell>
                  <TableCell>Location Name</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Sub Category</TableCell>
                  <TableCell>Veg/Non-Veg</TableCell>
                  <TableCell>description</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {menuList && menuList.results && Array.isArray(menuList.results) ? (
                  menuList.results.map((item, index) => (
                    <React.Fragment key={index}>
                      {
                        <TableRow>
                          <TableCell>
                            {item.location_id &&
                              (expandedRow === index ? (
                                <img
                                  src={minus}
                                  alt="-"
                                  onClick={() => toggleRowExpansion(index)}
                                  className="cursor-pointer"
                                />
                              ) : (
                                <img
                                  src={add}
                                  alt="+"
                                  onClick={() => toggleRowExpansion(index)}
                                  className="cursor-pointer"
                                />
                              ))}
                          </TableCell>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {item.location_name} ({item.products_count})
                          </TableCell>
                          <TableCell>{"ALL"}</TableCell>
                          <TableCell>{"ALL"}</TableCell>
                          <TableCell>{"ALL"}</TableCell>
                          <TableCell>{"ALL"}</TableCell>
                          <TableCell>{"ALL"}</TableCell>
                          <TableCell className="text-nowrap">{moment(item.created_at).format("DD-MM-YYYY")}</TableCell>
                          {expandedRow === index && <TableCell></TableCell>}
                        </TableRow>
                      }
                      {expandedRow === index &&
                        item?.products?.map((menu, _index) => (
                          <TableRow style={{ backgroundColor: "rgba(242, 249, 249, 1)" }}>
                            <TableCell> </TableCell>
                            <TableCell>
                              {index + 1}.{_index + 1}
                            </TableCell>
                            <TableCell>{item.location_name ? item.location_name : "Not filled"}</TableCell>
                            <TableCell>
                              <FPToolTip title={menu.product_name ? menu.product_name : "Not filled"}>
                                <span className="d-inline-block text-truncate width-sm">
                                  <Link to={`/${businessId}/menu?id=${menu.id}`} className="font-dark">
                                    {menu.product_name ? menu.product_name : "Not filled"}
                                  </Link>
                                </span>
                              </FPToolTip>
                            </TableCell>
                            <TableCell>{menu.product_category ? menu.product_category : "Not filled"}</TableCell>
                            <TableCell>{menu.product_subcategory ? menu.product_subcategory : "Not filled"}</TableCell>
                            <TableCell>{menu.veg_nonveg ? menu.veg_nonveg : "Not filled"}</TableCell>
                            <TableCell>{menu.product_description ? menu.product_description : "Not filled"}</TableCell>

                            <TableCell className="text-nowrap">
                              {moment(item.created_at).format("DD-MM-YYYY")}
                            </TableCell>

                            <TableCell>
                              <FPToolTip title="Delete">
                                <img src={deleteIcon} alt="delete" onClick={() => handleClick(menu.id)} />
                              </FPToolTip>
                            </TableCell>
                          </TableRow>
                        ))}
                    </React.Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="8" textAlign="center">
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <PaginationComponent count={menuList && menuList.count} location={location} pageSizeCookie={pageSizeCookie} />
          <ConfirmationModal
            header="Are you sure!"
            style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
            subHeader={`Are you sure you want to delete this staff?`}
            isOpen={isConfirm}
            toggle={() => setIsConfirm(!isConfirm)}
            onConfirm={() => toggleConfirm()}
          />
        </CardWrapper>
      )}
      {selectedTab === "stockMonitor" && <MenuOutStock businessId={businessId} />}
    </div>
  );
};

export default MenuResponseList;
