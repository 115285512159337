import React from 'react';
import { Table, Icon, Popup } from 'semantic-ui-react';
import { Card, UncontrolledTooltip } from 'reactstrap';
import LinksForm from './LinksForm';
import FormModal from '../common/FormModal';
import LoaderComponent from '../common/loaderComponent';

class CentralComplaintsSetup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, selectedItem: null };
    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal(selectedItem) {
    const { isOpen } = this.state;
    if (!isOpen) {
      this.setState({ selectedItem });
    }
    this.setState({ isOpen: !isOpen });
  }

  submit(data) {
    const { submitComplaintLinks } = this.props;
    return submitComplaintLinks(data)
      .then(() => this.toggleModal());
  }

  render() {
    const { complaintLinks, loader } = this.props;
    const { selectedItem } = this.state;
    return (
      <div>
        <LoaderComponent loader={loader}/>
        <Card body className="mt-2 mb-3">
          <div className="ui-table-responsive">
            <Table unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Review Channel</Table.HeaderCell>
                  <Table.HeaderCell>URL</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Status</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  complaintLinks && complaintLinks.length ?
                    complaintLinks.map((item) => (
                      <Table.Row key={item.web_portal.id}>
                        <Table.Cell>{item.web_portal.provider}</Table.Cell>
                        <Table.Cell>{item.link || 'Please Enter a URL' }</Table.Cell>
                        <Table.Cell textAlign='center' className="pt-1 pb-0 align-middle">
                          {item.is_active
                            ? <div>
                              <i className="far fa-check-circle fa-1x text-green" id={'tipId' + item.web_portal.id}></i>
                              <UncontrolledTooltip placement="right" target={'tipId' + item.web_portal.id}>
                                Active
                              </UncontrolledTooltip>
                            </div>
                            : <div>
                              <i className="far fa-times-circle fa-1x text-red" id={'tipId' + item.web_portal.id}></i>
                              <UncontrolledTooltip placement="right" target={'tipId' + item.web_portal.id}>
                                In-Active
                              </UncontrolledTooltip>
                            </div>
                          }
                        </Table.Cell>
                        <Table.Cell className="text-right">
                          <Popup
                            content='Edit'
                            trigger={<Icon name='edit' size='small' className='cursor-pointer' onClick={() => this.toggleModal(item)}/>}
                          />
                        </Table.Cell>
                      </Table.Row>))
                    : <Table.Row><Table.Cell className="text-center" colSpan={10}>No records Found </Table.Cell></Table.Row>
                }
              </Table.Body>
            </Table>
          </div>
        </Card>
          <FormModal
            heading={`Edit ${selectedItem?.web_portal?.provider} URL`}
            isOpen={this.state.isOpen}
            toggle={this.toggleModal}
          >
            <LinksForm
              submitComplaintLinks={this.submit}
              initialValues={selectedItem}
            />
          </FormModal>
      </div>
    );
  }
}

export default CentralComplaintsSetup;
