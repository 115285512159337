import React from "react";
import { getPercentage } from "../../libs/utils";

const DisplayPercentage = ({ totalValue, currentValue, style }) => {
  const percentage = getPercentage(totalValue, currentValue);

  if (percentage === 0) {
    return null;
  }
  return (
    <>
      <span style={style ? style : { fontSize: "10px", color: "#7A8193" }}>
        {`(${percentage}%)`}
      </span>
    </>
  );
};

export default DisplayPercentage;
