import React, { Fragment } from "react";
import UserImg from "../../../images/user-icon.png";
import { Avatar, Box } from "@material-ui/core";
import FPChip from "../../../components/common/new/FPChip/FPChip";
import { FPToolTip } from "../../../components/common/new";
import { color } from "highcharts";
import moment from "moment";
import { getFormattedDate } from "../../../libs/utils";

const MessageListComponent = (props) => {
  const { item, key, selected, onClick } = props;
  const formattedDate = getFormattedDate(item.msg_time, null, true);
  const chipTextComponents = (location, PNumber) => {
    return (
      <div className="chip-text-container" >
        {location && (
          <>
            <FPToolTip title={location}>
              <Box component="p" className="chip-location" sx={{fontSize: "10px"}}>
                {location}
              </Box>
            </FPToolTip>
            <div className="divider" />
          </>
        )}
        <Box component="p" className="chip-number" sx={{fontSize: "10px"}}>
          {PNumber} 
        </Box>
      </div>
    );
  };

  return (
    <Box
      onClick={onClick}
      className={`message-box ${selected ? "selected" : ""}`}
    >
      <div className="message-header-1">
        <div className="message-user">
          <Avatar
            alt="profile picture"
            src={item.userImg}
            className="user-avatar"
          />
          {item.user_name && (
            <FPToolTip style={{width: "105px"}} title={item.user_name} arrow>
              <p className="user-name">{item.user_name}</p>
            </FPToolTip>
          )}
          { (item.user_name && item.user_contact) && <div className="divider" />}

          {item.user_contact && (
            <Box component="p" className="user-mobile" style={!item.user_name ? { color: "#1B1C1D", fontSize: "16px"} : {}}>
            {item.user_contact}
          </Box>
          
          )}
        </div>
        <Box component="p" className="message-time">
        {formattedDate} {moment.utc(item?.msg_time).format("h:mmA")}
        </Box>
      </div>
      <div className="message-content">
        <p className="last-message">{item.message}</p>
        {item.unseen >= 1 && (
          <div className="unread-badge">
            <p>{item.unseen}</p>
          </div>
        )}
      </div>
      {/* <div>
        <FPChip
          key={key}
          className= {selected ? "message-chip" : ""}
          label={chipTextComponents(item?.location, item.user_contact)}
        />
      </div> */}
    </Box>
  );
};

export default MessageListComponent;
