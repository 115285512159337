import React from "react";
import BusinessProfileSetup from "./BusinessProfileSetup";
import BranchProfileSetup from "./BranchProfileSetup";
import RestaurantIDSetup from "./RestaurantIDSetup";
import SocialIDSetup from "./SocialAccountSetup";
import BranchSelector from "./BranchSelector";
import FPLabel from "../common/new/forms/ui/FPLabel";

let BusinessSetup = (props) => {
  const {
    isEditable,
    toggleState,
    handleBusinessSetup,
    isBusinessOwner,
    business_id,
    currentBranch,
    branchTagList,
    branchTagDataPost,
    rerenderParentCallback,
    blankField,
  } = props;
  return (
    <div>
        {/* <div className="mt-3 mb-4">
          <div className="mb-3">
            <span
              style={{
                fontSize: "18px",
                fontWeight: "400",
                color: "rgba(100, 101, 101, 1)",
              }}
            >
              Manage your Business Profile
            </span>
          </div>
          <div className="selectLocation">
            <div className="col-6">
              <FPLabel style={{ color: "#00B5AD", fontSize: "15px" }}>Select Location</FPLabel>
              <BranchSelector rerenderParentCallback={rerenderParentCallback} blankField={blankField} />
            </div>
          </div>
        </div> */}
      <div className="mb-4">
        <BusinessProfileSetup
          isEditable={isEditable["isProfile"]}
          handleBusinessSetup={handleBusinessSetup}
          toggleState={toggleState}
          isBusinessOwner={isBusinessOwner}
        />
      </div>
      <div className="mb-4">
        <BranchProfileSetup
          isEditable={isEditable["isBranchProfile"]}
          handleBusinessSetup={handleBusinessSetup}
          toggleState={toggleState}
          business_id={business_id}
          currentBranch={currentBranch}
          branchTagList={branchTagList}
          branchTagDataPost={branchTagDataPost}
        />
      </div>
      <div className="mb-4">
        <RestaurantIDSetup
          isEditable={isEditable["isIdSetup"]}
          handleBusinessSetup={handleBusinessSetup}
          toggleState={toggleState}
        />
      </div>
      <div>
        <SocialIDSetup
          isEditable={isEditable["isSocialProfiles"]}
          handleBusinessSetup={handleBusinessSetup}
          toggleState={toggleState}
        />
      </div>
    </div>
    // <Row>
    // <BusinessAboutSetup
    //   isEditable={isEditable['isAbout']}
    //   handleBusinessSetup={handleBusinessSetup}
    //   toggleState={toggleState}
    // />
    //     </Card>
    //   </Col> */}
    //   <Col sm="12" className="mt-2 mb-3">
    //     <Card body className="pt-2">
    //       <BusinessHoursSetup
    //         isEditable={isEditable['isHours']}
    //         handleBusinessSetup={handleBusinessSetup}
    //         toggleState={toggleState}
    //       />
    //     </Card>
    //   </Col>
    // </Row>
  );
};

export default BusinessSetup;
