import React from 'react';
import FPInput from "../common/new/forms/ui/FPInput";
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SortIcon from '@material-ui/icons/Sort';
import deleteIcon from "../../images/svg/trash.svg";
import editIcon from "../../images/svg/EditPen.svg";
import { FPToolTip } from '../common/new';
import { capitalizeWords, setDateFormatWithDash } from '../../libs/utils';

const PostModalContent = ({ selectedPost, handleTagSearch, value,handlePostEdit,postHandler,setIsPostModalOpen }) => {
  
  return (
    <div>
      <div className="postModalContainer">
        <img
          src={selectedPost?.image}
          alt="Post Image"
          style={{ width: "20%" }}
        />
        <div style={{ width: "100%" }}>
          <div className="postTitleDate">
            <p>{selectedPost?.title}</p>
            <p>{setDateFormatWithDash(selectedPost?.updated_at)}</p>
          </div>
          <div className="postTypePlatform">
            <div>
              <p style={{fontSize: "16px"}}>Post Type</p>
              <p style={{fontSize: "16px", fontWeight:"600"}}>{capitalizeWords(selectedPost?.post_type)}</p>
            </div>
            <div>
              <p style={{fontSize: "16px"}}>Platform</p>
              <p style={{fontSize: "16px", fontWeight:"600"}}>{capitalizeWords(selectedPost?.platform)}</p>
            </div>
          </div>
          <div className="postTypePlatform">
            <div>
              <p style={{fontSize: "16px"}}>Locations</p>
              <p style={{fontSize: "16px", fontWeight:"600"}}>{selectedPost?.branches.length}</p>
            </div>
            <div>
              <p style={{fontSize: "16px"}}>Branch Tags</p>
              <p style={{fontSize: "16px", fontWeight:"600"}}>{selectedPost?.branch_tags || "-"}</p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        <FPInput
          type="text"
          placeholder="Search Location"
          onChange={(e) => handleTagSearch(e, selectedPost)}
          value={value}
          style={{ fontSize: "14px !important" }}
          endIcon={<SearchIcon style={{ color: "#BFC2CB", fontSize: "18px" }} />}
        />
      </div>

      <div style={{ marginTop: "20px", maxHeight: '224px', overflowY: "auto" }}>
        <Table className="fp-table fp-location-table fp-table-center">
          <TableHead>
            <TableRow>
              <TableCell>Locations</TableCell>
              <TableCell>Progress Status <SortIcon /></TableCell>
              <TableCell>API Error </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedPost?.location?.map((item, i) => (
              <TableRow style={{ backgroundColor: item.is_posted === true  ? "#ECFDF3" : "#FEE4E2" }} key={i}>
              <TableCell>{item.display_name}</TableCell>
              <TableCell>{item.is_posted === true ? "Completed" : "Pending"}</TableCell>
              <TableCell>{item.api_error === true ? item.api_error_msg : "-"}</TableCell>
              <TableCell>
                  <div className="actionButton">
                    <FPToolTip title={"Edit"}>
                      <img
                        src={editIcon}
                        role="button"
                        alt="edit"
                        onClick={(event) => {
                          handlePostEdit(event, item.location, "branch");
                        }}
                      />
                    </FPToolTip>

                    <FPToolTip title={"Delete"}>
                      <img
                        src={deleteIcon}
                        role="button"
                        alt="delete"
                        onClick={() => postHandler(item.location, "branch")}
                      />
                    </FPToolTip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default PostModalContent;
