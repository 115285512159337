import React from "react";
import "./geoscancreadit.css";
import { formatDateWithOrdinal } from "../../../libs/utils";

const CoinUsageHistory = (props) => {
  const { coin_usage_history } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        paddingBottom: "20px",
      }}
    >
      <h2 style={{ fontSize: "21px", fontWeight: "500" }}>
        Coins Usage History
      </h2>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {coin_usage_history?.results?.length > 1 ? (
          coin_usage_history?.results?.map((data) => (
            <div className="coins-usage-history">
              <span className="coin-usage-history-title-value">
                {data?.module}
              </span>
              <span className="coin-usage-history-value">
                Used By : {data?.used_by__email}
              </span>
              <span className="coin-usage-history-value">
                {formatDateWithOrdinal(data?.created_date)}
              </span>
              <span
                style={{ fontSize: "14px", fontWeight: "500", color: "red" }}
              >
                {data?.use_credits}
              </span>
            </div>
          ))
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2 style={{ color: "#848485", fontSize:"18px" }}>
              No Usege History
            </h2>
          </div>
        )}
        {/* <div className="coins-usage-history">
          <span className="coin-usage-history-title-value">Geo Grid</span>
          <span className="coin-usage-history-value">
            Used By : fp.watburger@gmail.com
          </span>
          <span className="coin-usage-history-value">23rd may</span>
          <span style={{ fontSize: "14px", fontWeight: "500", color: "red" }}>
            -100
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default CoinUsageHistory;
