import React from "react";
import { Row, Col } from "reactstrap";
import { Card } from "semantic-ui-react";
import complaintIcon from "../../../images/svg/complaintIcon.svg";
import requestIcon from "../../../images/svg/requestIcon.svg";

function StatusSummaryCard(props) {
  const { data } = props;
  return (
    <Card className="sr-ticket-status">
      <Card.Header className="sr-header">
        <p className="sr-header-left">{data.leftHeader}</p>
        <p className="sr-header-right">{data.rightHeader}</p>
      </Card.Header>
      <Row className="time-section-wrapper">
        <div className="d-flex justify-content-between align-items-center w-100 my-2 mx-2">
          <div className="d-flex align-items-center">
            <span>
              <img
                src={requestIcon}
                alt="request icon"
                style={{ height: "15px", width: "15px", marginRight: "10px" }}
              />
            </span>
            <p style={{fontSize: "12px" }}>Service Request</p>
          </div>
          <div>
            <p style={{color: "#1B1C1D",fontSize: "14px", marginRight: "10px",fontWeight: "500"}}>{data.serviceRequest}</p>
          </div>
        </div>
      </Row>
      <Row className="time-section-wrapper">
        <div className="d-flex justify-content-between align-items-center w-100 my-2 mx-2">
          <div className="d-flex align-items-center">
            <img
              src={complaintIcon}
              alt="Complaints"
              style={{ height: "15px", width: "15px", marginRight: "10px" }}
            />
            <p style={{fontSize: "12px" }}>Complaints</p>
          </div>
          <div>
            <p style={{  color: "#1B1C1D",fontSize: "14px",marginRight: "10px",fontWeight: "500" }}>{data.complaints}</p>
          </div>
        </div>
      </Row>
    </Card>
  );
}

export default StatusSummaryCard;
