import React from "react";
import { Table } from "antd";
import { NoData } from "../../components/common/new";
import LoaderComponent from "../../components/common/loaderComponent";

const SentimentTable = ({ ratingDetails, loader, businessDetails }) => {
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
    },
    {
      title: "Business Name",
      dataIndex: "BusinessName",
    },
    {
      title: "Positive",
      dataIndex: "positive",
      sorter: {
        compare: (a, b) => a.positive - b.positive,
      },
    },
    {
      title: "Negative",
      dataIndex: "negative",
      sorter: {
        compare: (a, b) => a.negative - b.negative,
      },
    },
    {
      title: "Neutral",
      dataIndex: "neutral",
      sorter: {
        compare: (a, b) => a.neutral - b.neutral,
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      sorter: {
        compare: (a, b) => a.total - b.total,
      },
    },
  ];

  const dataSource = ratingDetails.map((object, index) => ({
    sNo: index + 1,
    BusinessName: object.competitor_name ? object.competitor_name : object.owner_name,
    positive: object.five_star_count + object.four_star_count,
    negative: object.one_star_count + object.two_star_count,
    neutral: object.three_star_count,
    total:
      object.five_star_count +
      object.four_star_count +
      object.one_star_count +
      object.two_star_count +
      object.three_star_count,
  }));

  if (dataSource.length === 0) {
    return <NoData className="mb-3" />;
  }
  return (
    <div className="px-3">
      <LoaderComponent loader={loader} />
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        showSorterTooltip={{ title: "" }}
        rowClassName={(record) => (businessDetails === record.BusinessName ? "highlight-row" : "")}
        className="fp-table-center"
      />
    </div>
  );
};

export default SentimentTable;
