/* eslint-disable */
import React from "react";
import { Row, Col, CardTitle } from "reactstrap";
import { Button, Divider } from "semantic-ui-react";
import LoaderComponent from "../../components/common/loaderComponent";
import FormModal from "../../components/common/FormModal";
import CouponList from "../../components/business/CouponList";
import * as URLS from "../../libs/apiUrls";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import queryString from "query-string";
import * as utils from "../../libs/utils";
import LockedComponent from "../../components/common/LockedComponent/LockedComponent";
import "../coupons/couponsPage.css";
import UploadFileModal from "../../components/common/new/UploadFileModal";
import { FPButton } from "../../components/common/new";
import cookie from "react-cookies";

class CouponsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadFileModalOpen: false,
      IsUploadFileLoading: false,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    this.props.actions.couponSummary(business_id);
    const queryParams = queryString.parse(search);
    this.getCouponList(queryParams);
    document.title = "Coupon Codes";
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({ IsUploadFileLoading: true });
    const {
      location: { search: prevSearch },
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;
    if (prevSearch !== nextSearch) {
      const queryParams = queryString.parse(nextSearch);
      this.getCouponList(queryParams);
    }
  };

  getCouponList(queryParams) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.actions
      .couponList({ ...queryParams, businessId: business_id })
      .then(() => {
        this.setState({ IsUploadFileLoading: false });
      });
  }

  toggleuploadFileModal = () => {
    const { uploadFileModalOpen } = this.state;
    this.setState({ uploadFileModalOpen: !uploadFileModalOpen });
  };

  downloadCouponSample() {
    const url = URLS.COUPON_SAMPLE;
    return this.props.actions.downloadCouponSample(url, "coupon-format.csv");
  }

  uploadCouponFile = (data) => {
    const file = data.target.files[0];
    const {
      history,
      location: { pathname, search },
      match: {
        params: { business_id },
      },
    } = this.props;
    const query = queryString.parse(search);
    const pageQuery = {
      page: query.page && query.page,
      page_size: query.page_size && query.page_size,
    };
    this.setState({ IsUploadFileLoading: true });
    const fileExt = file && file.name.split(".").pop();
    const newDetail = new FormData();
    newDetail.append("csv_file", file);
    if (file.size > 2100000) {
      utils.displayMessage("negative", "File cannot be larger than 2mb.");
      this.setState({ IsUploadFileLoading: false });
    } else {
      if (fileExt === "csv") {
        return this.props.actions
          .couponCsvUpload(business_id, newDetail)
          .then(() => {
            utils.displayMessage(
              "positive",
              "Coupon Code file has been uploaded, Coupons will be visible in some time."
            );
            this.props.actions
              .couponList({ ...pageQuery, businessId: business_id })
              .then(() => {
                this.setState({ IsUploadFileLoading: false });
              });
            this.toggleuploadFileModal();
            history.push({
              pathname,
              search: queryString.stringify({ ...pageQuery }),
            });
          })
          .catch((err) => {
            utils.displayMessage("negative", err.excel_file);
            this.setState({ IsUploadFileLoading: false });
          });
      } else {
        document.getElementById("file").value = null;
        utils.displayMessage(
          "info",
          "Invalid file type! Allowed extension is .csv"
        );
        this.setState({ IsUploadFileLoading: false });
      }
      return true;
    }
  };

  render() {
    const { couponListData, couponSummary, lockedModules, location } =
      this.props;
    const { uploadFileModalOpen, IsUploadFileLoading } = this.state;
    const lock =
      lockedModules && lockedModules.offers ? "Offers is Locked" : "";
    return (
      <div>
        <LockedComponent message={lock} />
        <LoaderComponent loader={IsUploadFileLoading} />
        <Row>
          <Col xs={12} sm={12} md={12} className="pt-3 px-4 business-setup">
            <CardTitle className="mb-0 fw3 d-flex justify-content-between align-items-center">
              <span className="mt-2 d-inline-block">Coupon Codes</span>
              <FPButton type="button" onClick={this.toggleuploadFileModal}>
                Upload Coupon File
              </FPButton>
            </CardTitle>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} className="px-4 business-setup">
            {couponSummary && (
              <div className="d-flex gap-20 mt-3 mb-2">
                {Object.keys(couponSummary).map((item, index) => {
                  return (
                    <div
                      className="couponSummary fp-flex-grow-1 fp-card-primary-elevation"
                      key={`${index}-contat`}
                    >
                      <span style={{ fontSize: "15px" }}>{item}</span>
                      <Divider className="mt-3 mb-1" />
                      <span className="cardCount">
                        {couponSummary && couponSummary[item]}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={12} sm={12} md={12} className="px-4 business-setup">
            <CouponList
              couponListData={couponListData && couponListData}
              location={location}
              onPageChange={this.getCouponList}
            />
          </Col>
        </Row>
          <UploadFileModal
            isOpen={uploadFileModalOpen}
            toggle={this.toggleuploadFileModal}
            heading={"Upload Coupons File"}
            uploadExcel={this.uploadCouponFile}
            downloadExcel={() => this.downloadCouponSample()}
          />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  couponListData: state.business.coupon_list_data,
  couponSummary: state.business.coupon_summary,
  lockedModules: state.business.lockedModules,
});

CouponsPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  couponListData: PropTypes.instanceOf(Object),
  couponSummary: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CouponsPage);
