import React from "react";
import { Grid } from "semantic-ui-react";
import { Form, Formik } from "formik";
import useRegisterForm from "./useRegisterForm";
import { Row, Col } from "reactstrap";
import { FormCheckbox, FormInput, FormSelect, FormCountryPicker } from "../../common/new/forms";
import { FPButton } from "../../common/new";

const RegisterForm = (props) => {
  const { userOptionList, categoryOptionList, handleRegister } = props;
  const { initialValue, validationSchema } = useRegisterForm();

  const onSubmit = (formValues) => {
    const snitizeValue = {};
    Object.keys(formValues).map((key) => {
      if (formValues[key] !== "" && formValues[key] !== null) {
        snitizeValue[key] = formValues[key];
      }
    });
    handleRegister(snitizeValue);
  };

  return (
    <Grid textAlign="center" verticalAlign="middle" className="mb-0">
      <Grid.Column className="register-form">
        <Formik initialValues={initialValue} validationSchema={validationSchema} onSubmit={(value) => onSubmit(value)}>
          {({ values }) => (
            <Form>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <FormInput name="first_name" label="First Name" />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <FormInput name="last_name" label="Last Name" />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={6}>
                  <FormInput name="email" label="Email" />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <FormCountryPicker name="contact_number" label="Mobile Number" />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <FormInput style={{ position: "relative" }} name="password" type="password" label="Password" />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <FormInput name="re_password" type="password" label="Confirm Password" />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <FormInput name="business_name" label="Business Name" />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <FormSelect name="business_type" label="Business Type" options={userOptionList} />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={values.business_category === "other" ? 6 : 12}>
                  {values.business_type !== "marketing" && (
                    <div>
                      <FormSelect name="business_category" label="Category" options={categoryOptionList} />
                    </div>
                  )}
                </Col>
                {values.business_category === "other" && values.business_type !== "marketing" && (
                  <Col xs={12} sm={12} md={values.business_category === "other" ? 6 : 12}>
                    <FormInput name="other_option" label="Other" />
                  </Col>
                )}
              </Row>

              <Row>
                <Col className="text-left">
                  <FormCheckbox
                    name="terms"
                    value={false}
                    label={
                      <div style={{ marginLeft: "5px" }}>
                        I agree to the&nbsp;
                        <a
                          href="https://famepilot.com/famepilot-privacy-policy/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Terms and Conditions
                          <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                        </a>
                      </div>
                    }
                  />
                </Col>
              </Row>

              <FPButton label="Submit" type="submit" className="w-100 b-radius-8" />
            </Form>
          )}
        </Formik>
      </Grid.Column>
    </Grid>
  );
};

export default RegisterForm;
