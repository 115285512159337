import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';

class CombinationChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(props) {
    const { dataKeys, primaryYLabel, secondaryYLabel, fontStyle, fontSize, boxWidth, notUsePoint, data, options, minYPrimary, maxYPrimary, title, minYSecondary } = props;
    return {
      chartData: {
        datasets: data,
        labels: dataKeys,
      },
      chartOptions: options ? options : {
        animation: {
          duration: 2000,
        },
        plugins: {
          title: {
            display: true,
            text: title,
            position: 'top',  
            fontStyle: 'bold',
            fontSize: 20,
            fontFamily: 'Outfit',
          },
          tooltip: {
            enabled: false, // Disable tooltips
          },
          legend: {
            position: 'top',
            labels: {
              usePointStyle: notUsePoint ? false : true,
              boxWidth: boxWidth || 5,
              fontStyle: fontStyle,
              fontSize: fontSize,
              fontFamily: 'Outfit',
            }
          }
        },
        scales: {
          x: {
            grid: {
              color: '#fafafa'
            },
            ticks: {
              callback: (value) => value
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              color: '#fafafa'
            }
          },
          primaryYAxis: {
            id: 'primary-y-axis',
            position: 'left',
            title: {
              display: true,
              text: primaryYLabel
            },
            fontStyle: 'italic',
            ticks: {
              min: minYPrimary,
              max: maxYPrimary
            },
            grid: {
              display: false
            },
          },
          secondaryYAxis: {
            id: 'secondary-y-axis',
            position: 'right',
            title: {
              display: true,
              text: secondaryYLabel,
            },
            grid: {
              display: false,
              color: '#fafafa'
            },
            ticks: {
              precision: 0,
              min: minYSecondary
            },
            display: secondaryYLabel ? true: false,
          }
        },
      },
    };      
  }

  render(){
    const { chartData, chartOptions } = this.state;
    const { chartType } = this.props;
    return(
      <div>
        {chartData && chartType==='pie' ? 
          <Pie data={chartData} 
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  labels: {
                    fontSize: 16,
                    fontStyle: "normal",
                    fontFamily: "Outfit",
                    boxWidth: 6,
                    boxHeight: 6,
                    padding: 10,
                    usePointStyle: true,
                  },
                }
              }
            }}
            height={400}
            /> :
          <Bar data={chartData} options={chartOptions} /> }
      </div>
    );
  }
}

export default CombinationChart;