import React, { useEffect, useState } from "react";
import { serviceOptions } from "../../libs/constant";
import * as URLS from "../../libs/apiUrls";
import { getAllParamsInArrayForm, getParams, removeLifetimeFilter } from "../../libs/utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonAction from "../../actions/common/commonAction";
import * as filterListActions from "../../actions/common/filterListActions";
import { FPButton, FPFilter, FPToolTip } from "../../components/common/new";
import FormModal from "../../components/common/FormModal";
import cookie from "react-cookies";
import { IconButton } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../images/svg/Download.svg";
import moment from "moment";

const ReviewsFilter = (props) => {
  const { common, history, filter, filterAction, match, source, downloadExcel, pageSizeCookie, tagsListing, categoryTagsListing, productNameList, getData } = props;

  const [isMoreThanNinety, setIsMoreThanNinety] = useState(false);

  const dateType = getParams(window.location.search, "date_type");
  const startDate = getParams(window.location.search, "start_date");
  const endDate = getParams(window.location.search, "end_date");
  const service_category = cookie.load("service_category");

  const fetchFilterList = async () => {
    const {
      params: { business_id },
    } = match;
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch("", `${URLS.BUSINESS_SETUP}${business_id}/branch/`),
        filterAction.getContentType("", `${URLS.CONTENT_OPTIONS}`),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };

  const handleReviewDownloadFlow = () => {
    if (dateType === "other") {
      const M_startDate = moment(startDate);
      const M_endDate = moment(endDate);
      const daysDifference = M_endDate.diff(M_startDate, "days");

      if (daysDifference > 90) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    } else {
      if (["prior_6_month", "current_year", "prior_12_months"].indexOf(dateType) > -1) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    }
  };

  useEffect(() => {
    fetchFilterList();
  }, []);

  const handleDefaultFilter = (list, key) => {
    const filter = window.location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable = defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list?.map((item) => {
      item["isChecked"] = isKeyFilterAvailable && defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };

  // const uniqueArray = tagsListing && Array.from(
  //   new Map(tagsListing.map((item) => [item.name, { ...item, name: item.name.trim() }])).values()
  // );
  // console.log("uniqueArray", uniqueArray);
  // console.log("tagListing", tagsListing);

  const CustomerType = [
    
    {
      value: "repeat",
      display_name: "Repeat",
      parentKey: "customer_type",
    },
    {
      value: "lapsed",
      display_name: "Lapsed",
      parentKey: "customer_type",
    },
    {
      value: "new",
      display_name: "New",
      parentKey: "customer_type",
    },
  ];

  const tagList =
    tagsListing &&
    tagsListing.map((item) => ({
      id: item.id,
      value: item.name,
      display_name: item.name,
    }));

  const filterList = [
    {
      id: "1",
      label: "Date Range",
      key: "date_type",
      type: "radio",
      isSearchable: false,
      list: filter.date_type && handleDefaultFilter(removeLifetimeFilter(filter?.date_type?.choices), "date_type"),
    },
    {
      id: "2",
      label: "Source",
      key: "provider",
      type: "checkbox",
      isSearchable: true,
      list: source && handleDefaultFilter(source.choices, "provider"),
    },
    {
      id: "3",
      label: "Rating",
      key: "rating",
      type: "checkbox",
      isSearchable: false,
      isNotEllipsisLabel: false,
      list: common.ratings && handleDefaultFilter(common.ratings.choices, "rating"),
    },
    {
      id: "4",
      label: "Status",
      key: "status",
      type: "checkbox",
      isSearchable: false,
      isNotEllipsisLabel:false,
      list: common.reply_options && handleDefaultFilter(common.reply_options.choices, "status"),
    },
    {
      id: "5",
      label: "Content",
      screenName: "content",
      key: "content",
      type: "radio",
      isSearchable: false,
      isNotEllipsisLabel: true,
      list:
        filter.content &&
        handleDefaultFilter(
          filter.content.choices?.filter((item) => {
            if (item.display_name === "All Content") return false;
            item.display_name = item.display_name.replace("Reviews ", "");
            return true;
          }),
          "content"
        ),
    },
    {
      id: "6",
      label: "Sentiment",
      key: "sentiment",
      type: "checkbox",
      isSearchable: false,
      list:
        common.sentiment_options &&
        handleDefaultFilter(
          common.sentiment_options.choices.filter((item) => item.display_name !== "Review Sentiment"),
          "sentiment"
        ),
    },
    {
      id: "7",
      label: "Customer Type",
      key: "customer_type",
      type: "checkbox",
      isSearchable: false,
      list: CustomerType && handleDefaultFilter(CustomerType, "customer_type"),
    },
    // TODO : manage location
    {
      id: "8",
      label: "Location",
      key: "branch",
      type: "radio",
      isSearchable: true,
      list: filter.branch && handleDefaultFilter(filter.branch.choices, "branch"),
    },
    {
      id: "9",
      label: "City",
      key: "city",
      type: "checkbox",
      isSearchable: true,
      // list: handleDefaultFilter(serviceOptions, "city"),
      list: common.city_options && handleDefaultFilter(common.city_options.choices, "city"),
    },
    {
      id: "10",
      label: "State",
      key: "state",
      type: "checkbox",
      isSearchable: true,
      list: common.state_options && handleDefaultFilter(common.state_options.choices, "state"),
    },
    // TODO : manage country
    {
      id: "11",
      label: "Country",
      screenName: "country",
      key: "country",
      type: "checkbox",
      isSearchable: true,
      list: common.country_options && handleDefaultFilter(common.country_options.choices, "country"),
    },
    {
      id: "12",
      label: "Location Tag",
      key: "branch_tag",
      type: "checkbox",
      isSearchable: true,
      list:
        common.branch_tag_options &&
        handleDefaultFilter(
          common.branch_tag_options.results.map((item) => ({
            value: item.id,
            display_name: item.title,
          })),
          "branch_tag"
        ),
    },
    service_category === "Restaurant" && {
      id: "13",
      label: "Service Type",
      key: "service_type",
      type: "radio",
      isSearchable: false,
      list: handleDefaultFilter(serviceOptions, "service_type"),
    },
    // {
    //   id: "13",
    //   label: "Review Tag",
    //   key: "tag",
    //   type: "checkbox",
    //   isSearchable: true,
    //   list:
    //     tagsListing &&
    //     handleDefaultFilter(
    //       tagsListing.map((item) => ({
    //         value: item.name,
    //         display_name: item.name,
    //       })),
    //       "tag"
    //     ),
    // },
  ].filter((item) => !!item);

  const formatCategoryTagsList = categoryTagsListing.map((val) =>  { return {"id": val.category_id, val: val.category_id, "display_name": val.group_name + " - " + val.category_name}});

  return (
    <React.Fragment>
      <FPFilter
        data={filterList}
        tagsListing={tagList}
        className="mt-3"
        isReviewTag
        isCategoryTag
        categoryTagsListing={formatCategoryTagsList}
        pageSizeCookie={pageSizeCookie}
        location={window.location}
        isProductName
        productNameList={productNameList}
        history={history}
        getData={getData}
        match={match}
        ticketFilter="REVIEW"
        actionRender={() => (
          <>
            {/* <FPButton
            size="small"
            variant="outline"
            onClick={() => handleReviewDownloadFlow()}
            label="Download Excel Report"
            endIcon={<CloudDownloadOutlinedIcon />}
          /> */}
            <FPButton
              size="small"
              variant="outline"
              // onClick={}
              label="Fame AI Summary"
              className="AI-Summary"
            />
            <FPToolTip title={"Download"}>
              <IconButton component="span" className="circular-button" onClick={() => handleReviewDownloadFlow()}>
                <DownloadIcon />
              </IconButton>
            </FPToolTip>
          </>
        )}
      />

      {isMoreThanNinety && (
        <FormModal
          isOpen={isMoreThanNinety}
          toggle={() => setIsMoreThanNinety(false)}
          heading="Attention !"
          maxWidth="xs"
          showDivider={true}
          dialogActions={<FPButton size="small" onClick={() => setIsMoreThanNinety(false)} label="OK" />}
        >
          <p className="fp-size-16">Please contact to support to download data older than 90 days.</p>
        </FormModal>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    filter: state.filter,
    source: state.review.review_source_options,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonAction, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ReviewsFilter);
