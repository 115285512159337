import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col } from "reactstrap";
import cookie from "react-cookies";
import NavBar from "../../components/common/NavBar";
import * as ProfileActions from "../../actions/accounts/profileAction";
import * as BusinessActions from "../../actions/business/businessSetupActions";
import * as AgencyActions from "../../actions/agency/agencyActions";
import queryString from "query-string";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import AppTemplateRouter from "../../AppTemplateRouter";
import ServiceTemplateRouter from "../serviceRequest/serviceTemplateRouter";
// import { Redirect, Route } from "react-router";

class AppTemplatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSideNav: cookie.load("showSideNav") === "true",
      isAgency: cookie.load("user").marketing_id ? "true" : false,
      agencyStaffId: cookie.load("user").id,
      isStaff:
        cookie.load("user").business_type != "marketing"
          ? cookie.load("user").multi_business_access && true
          : false,
      staff_business_list: {},
    };
  }

  componentDidMount() {
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.businessActions.getLockedModules(business_id);
    this.props.profileActions.getBusinessProfile(business_id);
    this.props.profileActions.getProfileInfo();
    if (this.state.isStaff) {
      !!cookie.load("user").marketing_id &&
        axios_instance
          .get(
            `${URLS.AGENCY_STAFF_DASHBOARD}?agency_id=${
              cookie.load("user").marketing_id
            }&user_id=${this.state.agencyStaffId}`
          )
          .then((res) => {
            this.setState({
              staff_business_list: { results: res.data.businessElements },
            });
          });
    } else {
      const agency_id = cookie.load("user").marketing_id;
      if (agency_id) {
        this.props.agencyActions.agencyAllBusiness(
          queryString.parse(search),
          agency_id
        );

        // this.props.agencyActions.agencyList(
        //   queryString.parse(search),
        //   agency_id
        // );
      }
    }
  }

  render() {
    const { businessList, business_all_list } = this.props;
    const user = cookie.load("user");

    return (
      <div>
        <Row className="login-bg">
          <Col xs={12} className="p-0">
            <NavBar
              match={this.props.match}
              history={this.props.history}
              isShowSideBar={true}
              businessList={
                this.state.isStaff
                  ? this.state.staff_business_list
                  : business_all_list
                  // : businessList
              }
              isStaff={this.state.isStaff}
            >
              {user?.enable_sr_module ? ( 
                <>
                  {/* <Route path="*">
                    <Redirect to={`/${this.props.match.params.business_id}/ticket-service-overview?date_type=prior_30_day`} />
                  </Route> */}
                  <ServiceTemplateRouter match={this.props.match} />
                </>
              ) : (
                <AppTemplateRouter match={this.props.match} />
              )}
            </NavBar>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  businessList: state.agency.business_list,
  business_all_list: state.agency.business_all_list
});

function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators(ProfileActions, dispatch),
    businessActions: bindActionCreators(BusinessActions, dispatch),
    agencyActions: bindActionCreators(AgencyActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AppTemplatePage);
