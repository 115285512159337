import React, { useState, useEffect, useContext } from "react";
import { Box, FormControlLabel, Popover, Radio } from "@material-ui/core";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import "./performance.css";
import { FPToolTip } from "../../../components/common/new";
import LineChartListing from "../../../components/listing/Performance/LineChartListing";
import { bindActionCreators } from "redux";
import * as listingPerformance from "../../../actions/listing/listingPerformanceAction";
import { connect } from "react-redux";
import InteractionTable from "./InteractionTable";
import * as setSelectedTab from "../../../actions/common/setSelectedTabAction";
import printIcon from "../../../images/Printer.png";
import downloadIcon from "../../../images/downloadReport.png";
import LoaderComponent from "../../../components/common/loaderComponent";
import * as URLS from "../../../libs/apiUrls";
import * as routerStateAction from "../../../actions/common/routerStateAction";
import * as filterListActions from "../../../actions/common/filterListActions";
import LocationLineChart from "./LocationLineChart";
import PerformanceFilter from "./PerformanceFilter";
import queryString from "query-string";
import { NewFilter } from "../../../images/svg";
import CommonPerformanceFilter from "../CommonPerformanceFilter";
import { SideNavContext } from "../../../Context/sideNavContext";

function PerformanceComponent(props) {
  const {
    actions,
    listing_count_data,
    listing_performance_data,
    loader,
    history,
    location: { search, pathname },
    location,
    match,
    match: {
      params: { business_id },
    },
    filterAction,
    filter,
  } = props;

  const nameMapping = {
    TOTAL_INTERACTIONS: "Total Interactions",
    CALL_CLICKS: "Calls Made",
    BUSINESS_BOOKINGS: "Bookings Made",
    BUSINESS_DIRECTION_REQUESTS: "Directions Requests",
    WEBSITE_CLICKS: "Website Clicks",
    BUSINESS_FOOD_MENU_CLICKS: "Menu Views",
  };
  const orderedKeys = [
    "TOTAL_INTERACTIONS",
    "CALL_CLICKS",
    "BUSINESS_BOOKINGS",
    "BUSINESS_DIRECTION_REQUESTS",
    "WEBSITE_CLICKS",
    "BUSINESS_FOOD_MENU_CLICKS",
  ];

  const [showFilter, setShowFilter] = useState("false");
  const openFilter = Boolean(showFilter);
  const [performanceNavTab, setPerformanceNavTab] = useState("overtime");
  const [view, setView] = useState("graph");
  const [selectedBox, setSelectedBox] = useState("TOTAL_INTERACTIONS");
  const { isAccordionTabSelected } = useContext(SideNavContext);
  const handleBoxClick = (boxName) => {
    setSelectedBox(boxName);
  };

  const fetchFilterList = async () => {
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch(
          "",
          `${URLS.BUSINESS_SETUP}${business_id}/branch/?is_gmb_locations=true`
        ),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };
  useEffect(() => {
    isAccordionTabSelected();
    fetchFilterList();
  }, []);

  const payload = queryString.parse(search);

  const queryParams = new URLSearchParams(search);
  let action_type = queryParams.get("action_type");

  if (action_type === null) {
    payload.action_type = "interactions";
  }

  let type = queryParams.get("type");
  if (type === null) {
    if (performanceNavTab === "overtime") {
      payload.type = "overtime";
    } else if (performanceNavTab === "location") {
      payload.type = "location";
    }
  }
  const handleDownload = async () => {
    const updatedPayload = { ...payload, download: true };
    try {
      await actions.getListingPerformanceMetrics(business_id, updatedPayload);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  useEffect(() => {
    document.title = "performance";
    props.actionsSelectTab.setSelectedTab(2);
    actions.getListingOverallCount(business_id, payload);
    actions.getListingPerformanceMetrics(business_id, payload);
    setShowFilter(false);
  }, [search]);

  const getFilterData = (data) => {
    actions.getListingOverallCount(business_id, payload);
    actions.getListingPerformanceMetrics(business_id, payload);
  };
  // useEffect(() => {
  //   document.title = "performance";
  //   props.actionsSelectTab.setSelectedTab(2);
  //   const payload = queryString.parse(search);
  //   const { type, ...rest } = payload;

  //   actions.getListingOverallCount(business_id, rest);
  //   actions.getListingPerformanceMetrics(business_id, payload);
  //   setShowFilter(false);
  // }, [search]);

  const toggleNavTab = (tab) => {
    const existingSearch = queryString.parse(search);
    history.push({
      pathname,
      search: queryString.stringify({ ...existingSearch, type: tab }),
    });
    setPerformanceNavTab(tab);
  };

  const toggleView = (viewVal) => {
    setView(viewVal);
  };

  const onFilterBtnClick = (e) => {
    setShowFilter(e.currentTarget);
  };

  const closeFilterModel = () => {
    setShowFilter(!showFilter);
  };

  const printIntraction = () => {
    window.print();
  };

  return (
    <>
      <LoaderComponent loader={loader} />
      <div className="mt-3">
        <>
          <div className="align-center justify-content-between mx-3">
            <Box
              className="mb-0"
              sx={{ color: "#1B1C1D !important", fontSize: "12px" }}
            >
              Last Updated on {listing_count_data?.last_update_date}
            </Box>
          </div>
          <div>
            <CommonPerformanceFilter
              history={history}
              location={location}
              match={match}
              getFilterData={getFilterData}
            />
          </div>
          {/* <div className="align-center justify-content-between mx-3">
            <Box
              className="mb-0"
              sx={{ color: "#1B1C1D !important", fontSize: "12px" }}
            >
              Last Updated on {listing_count_data?.last_update_date}
            </Box>
            <FPToolTip title="Filter">
              <div className="contact-filter-view" onClick={onFilterBtnClick}>
                <NewFilter
                  color="white"
                  style={{
                    height: "15px",
                    width: "15px",
                  }}
                />
              </div>
            </FPToolTip>
          </div> */}
          <div
            className="complaint-grid mt-3"
            style={{
              background: "rgb(220 220 220 / 3%)",
              padding: "5px 0px 10px 0px",
              marginBottom: "5px",
            }}
          >
            <div className="performance-box-grid-container">
              <div className="performance-complaint-grid">
                {orderedKeys.map((key, index) => {
                  const value = listing_count_data && listing_count_data[key];
                  const isSelected = selectedBox === key;
                  return (
                    <Box
                      className="performance-complaint-info-card"
                      key={index}
                      onClick={() => handleBoxClick(key)}
                      style={{
                        backgroundColor: isSelected ? "#FDF2F7" : "#fff",
                      }}
                    >
                      <FPToolTip title={nameMapping[key]}>
                        <Box className="performance-complaint-card-text">
                          {nameMapping[key]}
                        </Box>
                      </FPToolTip>
                      <div className="align-center justify-content-between">
                        <p className="complaint-card-num">{value}</p>
                      </div>
                    </Box>
                  );
                })}
              </div>
            </div>
          </div>
          <CardWrapper
            showDivider={false}
            headerTitle={
              <Box
                sx={{
                  color: "#1B1C1D",
                  fontSize: "16px",
                  fontWeight: 500,
                  marginTop: "5px",
                  marginBottom: "10px",
                }}
              >
                {(selectedBox === "TOTAL_INTERACTIONS"
                  ? ""
                  : nameMapping[selectedBox]) || ""}
              </Box>
            }
          >
            <div className="performaceGraphNavTab">
              <Nav
                tabs
                className="py-1 box-shadow-none"
                style={{ borderBottom: "none" }}
              >
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: performanceNavTab === "overtime",
                    })}
                    onClick={() => toggleNavTab("overtime")}
                  >
                    Overtime
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: performanceNavTab === "location",
                    })}
                    onClick={() => toggleNavTab("location")}
                  >
                    Location
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <div className="performanceView">
              <div className="align-center justify-content-between">
                <Nav
                  tabs
                  className="box-shadow-none"
                  style={{ borderBottom: "none" }}
                >
                  <NavItem>
                    <NavLink
                      className={classnames({ active: view === "graph" })}
                      onClick={() => {
                        toggleView("graph");
                      }}
                    >
                      <FormControlLabel
                        value="Graph"
                        label="Graph"
                        control={<Radio />}
                        checked={view === "graph"}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: view === "table" })}
                      onClick={() => toggleView("table")}
                    >
                      <FormControlLabel
                        value="table"
                        control={<Radio />}
                        label="Table"
                        checked={view === "table"}
                      />
                    </NavLink>
                  </NavItem>
                </Nav>

                <div
                  className="d-flex justify-content-end gap-15"
                  style={{ height: "100%", borderRadius: "2px" }}
                >
                  <FPToolTip title={"Download"}>
                    <div
                      role="button"
                      className="graph-Table-RightButton"
                      onClick={handleDownload}
                    >
                      <img src={downloadIcon} alt="" />
                    </div>
                  </FPToolTip>
                  <FPToolTip title={"Print"}>
                    <div role="button" onClick={printIntraction}>
                      <img src={printIcon} alt="" />
                    </div>
                  </FPToolTip>
                </div>
              </div>

              <TabContent activeTab={performanceNavTab}>
                {view === "graph" && (
                  <>
                    <TabPane tabId="overtime">
                      <CardWrapper className="p-3" showDivider={false}>
                        <LineChartListing
                          data={
                            listing_performance_data
                              ? listing_performance_data
                              : {}
                          }
                          selectedBox={selectedBox}
                          listing_count_data={
                            listing_count_data ? listing_count_data : {}
                          }
                        />
                      </CardWrapper>
                    </TabPane>
                    <TabPane tabId="location">
                      <CardWrapper className="p-3" showDivider={false}>
                        <LocationLineChart
                          data={
                            listing_performance_data
                              ? listing_performance_data
                              : {}
                          }
                          selectedBox={selectedBox}
                          listing_count_data={
                            listing_count_data ? listing_count_data : {}
                          }
                        />
                      </CardWrapper>
                    </TabPane>
                  </>
                )}
                {view === "table" && (
                  <>
                    <TabPane tabId="overtime">
                      <InteractionTable
                        performanceNavTab={performanceNavTab}
                        headerTitle="Overtime"
                        performanceDate={true}
                        data={
                          listing_performance_data
                            ? listing_performance_data
                            : {}
                        }
                        selectedBox={selectedBox}
                      />
                    </TabPane>
                    <TabPane tabId="location">
                      <InteractionTable
                        performanceNavTab={performanceNavTab}
                        performanceLocation={true}
                        headerTitle="Location"
                        data={
                          listing_performance_data
                            ? listing_performance_data
                            : {}
                        }
                        selectedBox={selectedBox}
                      />
                    </TabPane>
                  </>
                )}
              </TabContent>
            </div>
          </CardWrapper>
        </>
        {/* {showFilter && (
          <Popover
            open={openFilter}
            anchorEl={showFilter}
            onClose={closeFilterModel}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                borderRadius: 10,
                overflow: "visible",
              },
            }}
          >
            <PerformanceFilter
              location={props.location}
              onClose={closeFilterModel}
              history={props.history}
              locationOptions={filter?.branch?.choices}
              dateOptions={filter?.date_type?.choices}
            />
          </Popover>
        )} */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    listing_performance_data: state.listingPerformance.listing_Performance,
    listing_count_data: state.listingPerformance.listing_overAll_count,
    loader: state.common.loader,
    filter: state.filter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(listingPerformance, dispatch),
  actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
  routerStateAction: bindActionCreators(routerStateAction, dispatch),
  filterAction: bindActionCreators(filterListActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PerformanceComponent);
