import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as commonAction from "../common/commonAction";
import * as URLS from "../../libs/apiUrls";

export function getGmbLocationCreditUsageHistorySuccess(data) {
  return { type: types.GET_GMB_LOCATION_CREDIT_USAGE_HISTORY_SUCCESS, data };
}

export function getGmbLocationCreditUsageHistoryFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LOCATION_CREDIT_USAGE_HISTORY_FAILURE, data };
}

export const getGmbLocationCreditUsageHistory = (business_id) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/credit_usage_history`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationCreditUsageHistorySuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationCreditUsageHistoryFailure(err));
      });
  };
};
