import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { connect } from 'react-redux';
import * as BusinessSetupActions from '../../actions/business/businessSetupActions';
import { Row, Col } from 'reactstrap';
import LoaderComponent from '../../components/common/loaderComponent';
import StaffForm from '../../components/business/StaffForm';

class StaffEditPage extends React.Component{
  constructor(props){
    super(props);
    this.state={ options : []};
    this.submitData = this.submitData.bind(this);
  }

  submitData(data) {
    const { route: { location: { search }}} = this.context.router;
    const { id } = queryString.parse(search);
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('branch', data.branch);
    formData.append('department', data.department);
    formData.append('designation', data.designation);
    formData.append('aliases', data.aliases);
    if (id) {
      formData.append('id', id);
      return this.props.actions.putStaffResponse(formData, id);
    } else {
      return this.props.actions.postStaffResponse(formData);
    }
  }

  componentDidMount() {
    const { match: { params: { business_id }} } = this.props;
    const { route: { location: { search }}} = this.context.router;
    const { id } = queryString.parse(search);
    if (id) this.props.actions.getStaffDetails(id);
    this.props.actions.businessBranchOptions({business: business_id },true);
  }


  render() {
    const { route: { location: { search }} } = this.context.router;
    const { id } = queryString.parse(search);
    const { staffDetail, branchOptions} = this.props;
    const newData = {
      name: '',
      branch :'',
      department :'',
      designation :'',
      aliases :'',
    };
    const formData = id ? staffDetail : newData;

    return (
      <Row className="content-area">
        <LoaderComponent loader={this.props.loader}/>
        <Col xs={12} sm={12} className="business-setup p-3">
          <StaffForm
            submitData={this.submitData}
            initialValues={formData}
            locationsList={branchOptions && branchOptions.choices}
            isEditMode={!!id}
          />
        </Col>
      </Row>
    );
  }
}

StaffEditPage.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  return {
    staffDetail : state.business.staff_detail,
    branchOptions: state.business.branches,
    loader: state.common.loader,
  };
};


StaffEditPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(StaffEditPage);
