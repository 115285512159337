import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import "./central-branch.css";
import { FPButton } from "../common/new";
import TicketEsacalationForm from "./TicketEsacalationForm";
import ConfirmationModal from "../common/ConfirmationModal";
import { FaPlus } from "react-icons/fa6";
import { snakeToCapital } from "../../libs/utils";
import LoaderComponent from "../common/loaderComponent";

function TicketEscalation(props) {
  const {
    ticketEscalationList,
    handleEscalationOpen,
    handleEscalationClose,
    addTicketEscalation,
    editTicketEscalation,
    deleteTicketEscalation,
    designation,
    openEscalationForm,
    loader,
  } = props;
  const escalationLength = ticketEscalationList?.results?.length;
  const [edit, setEdit] = useState(null);
  const [escalationId, setEscalationId] = useState(1);
  const [isConfirm, setIsConfirm] = useState(false);
  const [escalationData, setEscalationData] = useState(null);

  const editHandler = (data, id) => {
    setEdit(data);
    setEscalationId(id);
    handleEscalationOpen();
  };

  const toggleConfirm = () => {
    deleteTicketEscalation(escalationData);
    setIsConfirm(false);
  };

  const handleClick = (data) => {
    setEscalationData(data);
    setIsConfirm(!isConfirm);
  };
  const getDesignationNames = (ids) => {
    return designation?.filter((d) => ids?.includes(d?.id))?.map((d) => d?.name);
  };

  return (
    <div className="mt-4">
      <LoaderComponent loader={loader} />
      {ticketEscalationList?.results?.length <= 0 && !openEscalationForm && (
        <div className="d-flex align-items-center justify-content-center">
          <div className="py-5">
            <span
              style={{
                fontSize: "16px",
                fontWeight: "400",
                color: "rgba(100, 101, 101, 1)",
              }}
            >
              No Escalation Rules Added
            </span>
            <FPButton
              className="d-flex mt-10"
              fullWidth
              label="Add Escalation Rule"
              fontWeight="400"
              fontSize={16}
              onClick={() => handleEscalationOpen()}
              endIcon={<FaPlus style={{ fontSize: "14px" }} />}
            />
          </div>
        </div>
      )}

      <div>
        {!openEscalationForm && (
          <>
            {ticketEscalationList?.results?.map((item, index) => {
              const escalateToNames = getDesignationNames(item?.escalate_to_designation) || [];
              const combinedList = [...item?.escalate_to, ...escalateToNames];
              return (
                <div>
                  <div key={item.id}>
                    <span className="escalation-title">Escalation Rule {index + 1}</span>
                    <Box className="border mt-3 p-3" sx={{ borderRadius: "8px" }}>
                      <div className="d-block gap-30">
                        <span className="escalationText">If ticket is open with status</span>
                        <span className="escalationColorText mx-4">{snakeToCapital(item.status)}</span>
                        <span className="escalationText">from more than</span>
                        <span className="escalationColorText mx-4">
                          {item.more_then_value}{" "}
                          {item.more_then_unit === "hour" ? "Hours" : item.more_then_unit === "day" ? "Days" : ""}
                        </span>
                        <span className="escalationText">and if assignee has</span>
                        <span className="escalationColorText mx-4">
                          {item.not_responded ? "Not Responded" : "Responded"}
                        </span>
                      </div>

                      <div
                        className="d-flex align-items-center flex-wrap mt-2"
                        style={{
                          columnGap: "15px",
                        }}
                      >
                        <p className="escalationText mb-1 mr-3 mt-0">then escalate to</p>
                        {combinedList.map((entry, index) => (
                          <p key={index} className="escalationColorText mb-1 mt-0">
                            {entry}
                          </p>
                        ))}
                      </div>
                    </Box>
                    <div className="mt-3 d-flex justify-content-end gap-20">
                      <FPButton size="small" variant="outline" label="Delete" onClick={() => handleClick(item)} />
                      <FPButton
                        type="submit"
                        size="small"
                        label="Edit"
                        className=""
                        onClick={() => editHandler(item, index + 1)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>

      {openEscalationForm && (
        <TicketEsacalationForm
          escalationId={!!edit ? escalationId : ticketEscalationList?.results?.length + 1 || 1}
          designation={designation}
          addTicketEscalation={addTicketEscalation}
          editTicketEscalation={editTicketEscalation}
          handleEscalationClose={() => {
            setEdit(null);
            handleEscalationClose();
          }}
          onApply={() => {
            setEdit(null);
            handleEscalationClose();
          }}
          initialData={edit}
          escalationLength={escalationLength}
        />
      )}

      <ConfirmationModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader={`Are you sure you want to delete this Escalation Rule?`}
        isOpen={isConfirm}
        toggle={() => setIsConfirm(!isConfirm)}
        onConfirm={() => toggleConfirm()}
      />
    </div>
  );
}

export default TicketEscalation;
