import * as types from '../../actions/actionTypes';
import cookie from "react-cookies";

export default function agencyReducer(state=[], action) {
  switch (action.type) {
    case types.MARKETING_ADD_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.MARKETING_ADD_FAILURE:
      return Object.assign({}, state, action.data);
    case types.AGENCY_LIST_SUCCESS:
      // const user = cookie.load("user");
      // const marketing_id = user.marketing_id;
      // let business_ids = [];
      // if(marketing_id) {
      //   business_ids.push(String(marketing_id));
      // }
      // action?.data?.results?.map((val) => business_ids.push(String(val.id)))
      // cookie.save('business_ids', business_ids, 
      //   { 
      //     path: '/',
      //     maxAge: 365 * 24 * 60 * 60,
      //   }
      // );
      return Object.assign({}, state,{business_list:{...action.data} });
    case types.AGENCY_LIST_FAILURE:
      return Object.assign({}, state, action.data);
    case types.AGENCY_ALL_BUSINESS_SUCCESS:
      const user = cookie.load("user");
      const marketing_id = user.marketing_id;
      let business_ids = [];
      if(marketing_id) {
        business_ids.push(String(marketing_id));
      }
      action?.data?.map((val) => business_ids.push(String(val.id)))
      cookie.save('business_ids', business_ids, 
        { 
          path: '/',
          maxAge: 365 * 24 * 60 * 60,
        }
      );
      return Object.assign({}, state,{business_all_list:[...action.data] });
    case types.AGENCY_ALL_BUSINESS_FAILURE:
      return Object.assign({}, state, action.data);
    case types.GET_MARKETING_BUSINESS_SUCCESS:
      return Object.assign({}, state, { detail: action.data });
    case types.GET_MARKETING_BUSINESS_FAILURE:
      return Object.assign({}, state, action.data);
    case types.MARKETING_EDIT_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.MARKETING_EDIT_FAILURE:
      return Object.assign({}, state, action.data);
    case types.AGENCY_MAPPING_LIST_SUCCESS:
      return Object.assign({},state, { mapping_list: action.data });
    case types.AGENCY_MAPPING_LIST_FAILURE:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
}
