import React, { useState } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { ListDescription } from "semantic-ui-react";
import FPInput from "../../common/new/forms/ui/FPInput";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import "./listing.css";
import CloseIcon from "@material-ui/icons/Close";
import google_svg from "../../../images/googlephoto.svg";
import GoogleIcon from "@mui/icons-material/Google";
import warning_svg from "../../../images/warning.svg";
import { formatReviews } from "../../../libs/helper";
import duplicate_svg from "../../../images/dusplicate.svg";
import BusinessList from "../../../containers/listing/bulkupdate/console/BusinessList";

const UpdatePhoneNumber = ({ primaryDetail, bulk = false }) => {
  const addressString = [primaryDetail?.addressLines, primaryDetail?.locality, primaryDetail?.postalCode].join(", ");

  return (
    <>
      {!bulk ? (
        <>
          <div className="listing-container" style={{ overflow: "hidden", gap: "10px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <div className="listing-header">
                <div className="listing-info">
                  <div style={{ display: "flex", gap: "8px" }}>
                    <div className="listing-name">{primaryDetail?.title}</div>
                    <img
                      src={
                        primaryDetail?.is_varified
                          ? verified_svg
                          : primaryDetail?.is_suspend
                          ? duplicate_svg
                          : primaryDetail?.is_duplicate
                          ? duplicate_svg
                          : ""
                      }
                      alt="verifiedImage"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  ></div>
                  <div className="rating-container">
                    <CustomStarRatingComponent
                      name="rate"
                      starCount={5}
                      starColor="#FBBD08"
                      emptyStarColor="white"
                      value={primaryDetail?.averageRating}
                      editing={false}
                    />
                  </div>
                  <span>{primaryDetail?.averageRating?.toFixed(1)}</span>
                  <div
                    style={{
                      height: "5px",
                      width: "5px",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <span>{formatReviews(primaryDetail?.totalReviewCount)} Reviews</span>
                </div>
              </div>
            </div>
            <div className="review-address">
              <ListDescription>{addressString}</ListDescription>
            </div>
          </div>
          <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
            <div className="UpdateScrollHeightSection">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <div
                  className="d-flex flex-column"
                  style={{
                    display: "grid",
                    gap: "15px",
                    paddingBottom: "5px",
                  }}
                >
                  <div className="listing-name">Add Primary & Additional Business Phone Numbers</div>
                  <div className="d-flex flex-column" style={{ gap: "4px" }}>
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#1B1C1D",
                      }}
                    >
                      Primary Phone Number
                    </div>
                    <FPInput type="text" value={primaryDetail?.phoneNumbers?.primaryPhone} />
                  </div>
                  {primaryDetail?.phoneNumbers?.additionalPhones?.length > 0
                    ? primaryDetail?.phoneNumbers?.additionalPhones?.map((additionalNumber) => {
                        return (
                          <div style={{ display: "grid", gap: "10px" }}>
                            <div
                              style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#1B1C1D",
                              }}
                            >
                              Additional Phone Number
                            </div>
                            <FPInput
                              type="text"
                              placeholder={"Enter Additional  Number (optional)"}
                              value={additionalNumber}
                            />
                          </div>
                        );
                      })
                    : ""}
                  <div
                    style={{
                      backgroundColor: "#FEE4E2",
                      borderRadius: "12px",
                      padding: "8px 16px",
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <img
                      src={warning_svg}
                      alt="warning-svg"
                      style={{
                        height: "16px",
                        width: "16px",
                      }}
                    />
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#F04438",
                      }}
                    >
                      Phone Number & Additional Number change might lead to suspension or re-verification Business
                      profile. If you are sure, please add the phone number directly from Google Business Profile
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <a href={primaryDetail?.mapsUri} target="_blank" rel="noreferrer">
                    <FPButton label="Edit on Google" size="small" startIcon={<GoogleIcon />} />
                  </a>
                </div>
              </div>
              <div className="RightImageSectionDialog">
                <img src={google_svg} alt="verifiedImage" />
              </div>
            </div>
          </div>
        </>
      ) : (
        <BusinessList />
      )}
    </>
  );
};

export default UpdatePhoneNumber;
