import React, { useEffect } from "react";
import ListingFilter from "./ListingFilter";
import SearchComponent from "../../common/SearchComponent";
import ListingTable from "./listingTable";
import ListingPaginationComponent from "../../common/new/Listing/listingPagination";
import "./listing.css";
import PaginationComponent from "../../common/Pagination";

const ListingDetail = (props) => {
  useEffect(() => {
    document.title = "listing-detail";
  });
  return (
    <>
      <div className="py-2">
        <ListingFilter
          history={props.history}
          location={props.location}
          listing={props?.listingDetailJson}
          business_id={props?.business_id}
        />
        <div className="bg-white my-3" style={{ padding: "15px", borderRadius: "16px" }}>
          <div>
            <div className="d-flex align-baseline gap-10 pb-10">
              <h2
                style={{
                  color: "#060658",
                  fontSize: "18px",
                  fontWeight: "500",
                  margin: "0",
                }}
              >
                Listings
              </h2>
              <h3
                style={{
                  color: "#F13A88",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                (Total {props?.filterBusinessCount} location)
              </h3>
            </div>
            <div className="border-bottom" style={{ paddingBlock: "10px" }}>
              <SearchComponent placeholder="Search Location" {...props} className="list-search" startIcon />
            </div>
          </div>
          <div className="mt-3">
            <div style={{ display: "grid" }}>
              {props?.listingDetailJson?.map((listing, index) => {
                return (
                  <ListingTable
                    key={index}
                    listing={listing}
                    business_id={props?.business_id}
                    history={props?.history}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <PaginationComponent
          count={props?.filterBusinessCount}
          location={props.location}
          pageSizeCookie={"listing_detail_page"}
        />
        {/* <ListingPaginationComponent
          count={props?.filterBusinessCount}
          location={props.location}
          pageSizeCookie={"listing_detail_page"}
          className="border-top"
        /> */}
      </div>
    </>
  );
};

export default ListingDetail;
