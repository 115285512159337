import axios_instance from '../../libs/interseptor';
import * as types from '../../actions/actionTypes';
import * as URLS from '../../libs/apiUrls';
import * as commonAction from '../common/commonAction';
import * as utils from '../../libs/utils';
import FormData from 'form-data';

export function profileSuccess(data) {
  return { type: types.PROFILE_UPDATE_SUCCESS, data};
}

export function profileFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.PROFILE_UPDATE_FAILURE, data };
}

export const profileAction = (data,businessId) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.patch(businessId?utils.format(URLS.BUSINESS_PROFILE,[businessId]):URLS.PROFILE, data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(profileSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(profileFailure(err));
          throw err.response.data;
        }
      );
  };
};

const getProfileInfoSucess = (data) => {
  return{
    type: types.PROFILE_INFO_SUCCESS,
    data: data
  };
};

const getProfileInfoFailure = (data) => {
  return{
    type: types.PROFILE_INFO_FAILURE,
    data:data
  };
};

export const getProfileInfo = () => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(URLS.PROFILE)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getProfileInfoSucess(res.data));
          if(res.data.business_type!=='marketing'){
            dispatch(getBusinessProfileSuccess(res.data));
          }
          return res;
        }
      ).catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getProfileInfoFailure(err));
          throw err;
        }
      );
  };
};

const getBusinessProfileSuccess = (data) => {
  return{type: types.BUSINESS_PROFILE_SUCCESS,data};
};

const getBusinessProfileFailure = (data) => {
  return{type: types.BUSINESS_PROFILE_FAILURE,data};
};

export const getBusinessProfile = (businessId) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.BUSINESS_PROFILE,[businessId]))
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getBusinessProfileSuccess(res.data));
          return res;
        }
      ).catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getBusinessProfileFailure(err));
          throw err;
        }
      );
  };
};

export const removeBusinessProfile = () => {
  return (dispatch) => {
    dispatch((function(){
      return {type: types.REMOVE_BUSINESS_PROFILE};
    })());
  };
};

export const uploadProfileImage = (image,businessId) => {
  let data = new FormData();
  data.append('avtar', image, image.fileName);
  return (dispatch) => {
    return axios_instance.patch(businessId?utils.format(URLS.BUSINESS_PROFILE,[businessId]):URLS.PROFILE, data,{
      headers: {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      }
    })
      .then((res) => {
        if(businessId)
          dispatch(getBusinessProfileSuccess(res.data));
        else
          dispatch(getProfileInfoSucess(res.data));
        return res;
      })
      .catch((err)=>{
        throw err;
      });
  };
};