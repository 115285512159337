import React from "react";
import { Card, CardTitle, Col } from "reactstrap";
import { Checkbox, Divider } from "semantic-ui-react";
import { Table, TableBody, TableCell, TableHead, TableRow, alpha } from "@material-ui/core";

const colors = ["#d09f83", "#c69dde", "#00b7d5", "#ff959a", "#c8a871"];

class SurveyTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // isOpenArray: [],
      // isOpenAllBranches: false,
      // openBranchIndex: null,
      // openPageIndex: [],
      // openPageQuestionIndex: [],
    };
  }

  // Function to handle click on "All Branches" card
  // handleAllBranchesClick = () => {
  //   this.setState((prevState) => ({
  //     isOpenAllBranches: !prevState.isOpenAllBranches,
  //     openBranchIndex: -1, // Reset open branch index when "All Branches" is clicked
  //   }));
  // };

  // Function to handle click on a branch card
  // handleCardClick = (index) => {
  //   this.setState((prevState) => ({
  //     openBranchIndex: prevState.openBranchIndex === index ? -1 : index, // Toggle open/close state of the clicked branch
  //     openPageIndex: null,
  //     openPageQuestionIndex: null,
  //   }));
  // };

  // Add handleQuestionClick to set the openPageQuestionIndex state
  // handleQuestionClick = (questionIndex) => {
  //   this.setState({ openPageQuestionIndex: questionIndex });
  // };

  // // Update handlePageClick to set the openPageIndex state
  // handlePageClick = (pageIndex) => {
  //   // console.log('**Clicked page index:', pageIndex);
  //   this.setState({
  //     openPageIndex: pageIndex,
  //     openPageQuestionIndex: null,
  //   });
  // };

  // Count Background Color (uniqueVal, Spread_Color)
  applyBackgroundColor(array, value, colorMap) {
    const orderIndex = array.indexOf(value);
    return alpha(colorMap, (100 - orderIndex * 20) / 100);
  }

  render() {
    const {
      tableData,
      displayLevel,
      isDisplayBranchWise,
      isDisplayPageWise,
      toggleDisplayLevel,
      surveyPreviousTable = true,
    } = this.props;
    // const {
    //   isOpenAllBranches,
    //   openBranchIndex,
    //   openPageIndex,
    //   openPageQuestionIndex,
    // } = this.state;

    let rowSpanQuestion = [];
    let rowSpanPage = [];
    let rowSpanBranch = [];

    let questionCountVal = {};
    let questionNamesArr = {};

    rowSpanQuestion =
      tableData &&
      tableData.ques &&
      tableData.ques.reduce((result, item, key) => {
        if (questionNamesArr[item.question] === undefined) {
          questionNamesArr[item.question] = key;
          result[key] = 1;
          questionCountVal[item.question] = [item.count];
        } else {
          const firstIndex = questionNamesArr[item.question];
          if (firstIndex === key - 1 || (item.question === tableData.ques[key - 1].question && result[key - 1] === 0)) {
            result[firstIndex]++;
            result[key] = 0;
            questionCountVal[item.question].push(item.count); // Add count value in array Using push Method to
            questionCountVal[item.question] = Array.from(
              new Set(
                questionCountVal[item.question].sort(function (a, b) {
                  return b - a;
                })
              )
            ); // got unique Value in questionCountVal & Sort in Descending-Order
          } else {
            result[key] = 1;
            questionNamesArr[item.question] = key;
            questionCountVal[item.question] = [item.count]; // Initialize new array if needed
            questionCountVal[item.question] = Array.from(
              new Set(
                questionCountVal[item.question].sort(function (a, b) {
                  return b - a;
                })
              )
            );
          }
        }
        return result;
      }, []);

    if (displayLevel === "page" || displayLevel === "both") {
      let pageNamesArr = {};
      rowSpanPage =
        tableData &&
        tableData.ques &&
        tableData.ques.reduce((result, item, key) => {
          if (pageNamesArr[item.page] === undefined) {
            pageNamesArr[item.page] = key;
            result[key] = 1;
          } else {
            const firstIndex = pageNamesArr[item.page];
            if (firstIndex === key - 1 || (item.page === tableData.ques[key - 1].page && result[key - 1] === 0)) {
              result[firstIndex]++;
              result[key] = 0;
            } else {
              result[key] = 1;
              pageNamesArr[item.page] = key;
            }
          }
          return result;
        }, []);
    }

    if (displayLevel === "branch" || displayLevel === "both") {
      let branchNamesArr = {};
      rowSpanBranch =
        tableData &&
        tableData.ques &&
        tableData.ques.reduce((result, item, key) => {
          if (branchNamesArr[item.branch] === undefined) {
            branchNamesArr[item.branch] = key;
            result[key] = 1;
          } else {
            const firstIndex = branchNamesArr[item.branch];
            if (firstIndex === key - 1 || (item.branch === tableData.ques[key - 1].branch && result[key - 1] === 0)) {
              result[firstIndex]++;
              result[key] = 0;
            } else {
              result[key] = 1;
              branchNamesArr[item.branch] = key;
            }
          }
          return result;
        }, []);
    }

    const colorMap = {};
    if (tableData && tableData.ques && tableData.ques.length) {
      const uniqueQuestions = Array.from(new Set(tableData.ques.map((item) => item.question)));
      uniqueQuestions.forEach((question, index) => {
        colorMap[question] = colors[index % colors.length];
      });
    }

    return (
      <div>
        <Card body className="pt-3 mb-3">
          <CardTitle className="mb-2 fw3">
            <div className="d-flex">
              <Col>Survey Report {displayLevel[0].toUpperCase() + displayLevel.slice(1)} Wise</Col>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-betwee">
                  <Col style={{ display: "flex", alignItems: "", minWidth: "53%" }}>
                    <h6>Page Wise</h6>
                    <span style={{ marginLeft: "10px", marginTop: "-2px" }}>
                      <Checkbox
                        toggle
                        value="page"
                        checked={isDisplayPageWise}
                        onChange={(e, checked) => {
                          toggleDisplayLevel("page", e, checked);
                        }}
                      ></Checkbox>
                    </span>
                  </Col>
                  <Col style={{ display: "flex", alignItems: "", minWidth: "53%" }}>
                    <h6>Branch Wise</h6>
                    <span style={{ marginLeft: "10px", marginTop: "-2px" }}>
                      <Checkbox
                        toggle
                        value="branch"
                        checked={isDisplayBranchWise}
                        onChange={(e, checked) => {
                          toggleDisplayLevel("branch", e, checked);
                        }}
                      ></Checkbox>
                    </span>
                  </Col>
                </div>
              </div>
            </div>
            <Divider></Divider>
          </CardTitle>

          {surveyPreviousTable && (
            <Table className="fp-table fp-table-center fp-table-border">
              <TableHead>
                <TableRow verticalAlign="middle" textAlign="center">
                  {(displayLevel === "branch" || displayLevel === "both") && <TableCell>Branch</TableCell>}
                  {(displayLevel === "page" || displayLevel === "both") && <TableCell>Page</TableCell>}
                  <TableCell>Question</TableCell>
                  <TableCell>Option</TableCell>
                  <TableCell>Count</TableCell>
                  <TableCell>%</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData && tableData.ques && tableData.ques.length ? (
                  tableData.ques.map((item, index) => {
                    const questionColor = colorMap[item.question];
                    const optionColor = questionColor;

                    // const EachQuestionCountVal = index > 0 && tableData.ques[index - 1].question === item['question'] ? item['count'] : null;
                    const countBgColor = this.applyBackgroundColor(
                      questionCountVal[item.question],
                      item["count"],
                      colorMap[item.question]
                    );

                    return (
                      <TableRow key={index}>
                        {(displayLevel === "branch" || displayLevel === "both") && rowSpanBranch[index] > 0 && (
                          <TableCell
                            className="branch_cell tableCell"
                            verticalAlign="middle"
                            textAlign="center"
                            rowSpan={rowSpanBranch[index]}
                          >
                            {item["branch"]}
                          </TableCell>
                        )}
                        {(displayLevel === "page" || displayLevel === "both") && rowSpanPage[index] > 0 && (
                          <TableCell
                            className="page_cell tableCell"
                            verticalAlign="middle"
                            textAlign="center"
                            rowSpan={rowSpanPage[index]}
                          >
                            {item["page"]}
                          </TableCell>
                        )}
                        {rowSpanQuestion[index] > 0 && (
                          <TableCell
                            className="question_cell tableCell"
                            verticalAlign=""
                            textAlign="center"
                            rowSpan={rowSpanQuestion[index]}
                          >
                            <span style={{ color: questionColor }}>
                              {" "}
                              <span style={{ fontSize: "20px" }}>&#9679;</span>
                              {item["question"]}{" "}
                            </span>
                            <br /> Total Count : {item["question_total_count"]}
                          </TableCell>
                        )}
                        <TableCell className="option_cell tableCell" verticalAlign="middle" textAlign="center">
                          <span style={{ color: optionColor }}>{item["option"]}</span>
                        </TableCell>
                        <TableCell
                          verticalAlign="middle"
                          textAlign="center"
                          className="tableCell"
                          style={{ backgroundColor: countBgColor }}
                        >
                          {item["count"]}
                        </TableCell>
                        <TableCell
                          verticalAlign="middle"
                          textAlign="center"
                          className="tableCell"
                          style={{ backgroundColor: countBgColor }}
                        >
                          {item["percentage"]}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell className="text-center" colSpan={4}>
                      No records Found{" "}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </Card>
      </div>
    );
  }
}

export default SurveyTable;
