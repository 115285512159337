import React, { useEffect, useState } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { Dropdown, ListDescription } from "semantic-ui-react";
import FPInput from "../../common/new/forms/ui/FPInput";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import "./listing.css";
import google_svg from "../../../images/googlephoto.svg";
import AddIcon from "@material-ui/icons/Add";
import delete_png from "../../../images/delete_png.webp";
import { formatReviews } from "../../../libs/helper";
import duplicate_svg from "../../../images/dusplicate.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";

const UpdateService = (props) => {
  const { primaryDetail, service_type_data, actions, business_id, selectReso, handleClose } = props;
  const [isDropdown, setIsDropdown] = useState(true);

  const dropdownInputHandler = () => {
    setIsDropdown((prevState) => !prevState); // Toggle between true and false
  };

  const [services, setServices] = useState([
    // { displayName: "", description: "", priceVlaue: "", PriceName: "", customdisplayName: "" }
  ]);
  const [updateService, setUpdateService] = useState([
    {
      id: new Date().getTime(),
      displayName: "",
      description: "",
      priceVlaue: "",
      PriceName: "",
      customdisplayName: "",
      category: "",
    },
  ]);

  useEffect(() => {
    if (primaryDetail?.services?.length) {
      setServices(primaryDetail?.services);
    } else {
      setServices([]);
    }
    // actions.getGmbServiceType(business_id, selectReso)
  }, [primaryDetail.service, primaryDetail?.services]);

  const handleAdditionalDeleteClick = (service) => {
    setServices(services?.filter((item) => item?.id !== service?.id));
  };

  const handleUpdateServiceDeleteClick = (service, index) => {
    // Ensure service.id is defined
    if (service?.id === undefined) {
      console.error("Service id is undefined");
      return;
    }

    setUpdateService((prevServices) => prevServices.filter((item) => item?.id !== service?.id));
  };

  const handleAddClick = () => {
    setUpdateService([
      ...updateService,
      {
        id: new Date().getTime(), // Generate a unique ID
        displayName: "",
        description: "",
        priceVlaue: "",
        PriceName: "",
        customdisplayName: "",
        category: categoryValue?.displayName,
      },
    ]);
  };

  const addressString = [primaryDetail?.addressLines, primaryDetail?.locality, primaryDetail?.postalCode].join(", ");

  const PerivousService = services?.map((service) =>
    service?.serviceTypeId ? service?.serviceTypeId : service?.category ? service?.category : ""
  );

  const AllServiceOptions = service_type_data
    ?.map((service) => {
      // if (!PerivousService.includes(service?.serviceTypeId)) {
      return {
        key: service?.serviceTypeId,
        text: service?.displayName,
        value: service,
      };
      // }
      // return null;
    })
    .filter(Boolean);

  const ServiceOptions = service_type_data
    ?.map((service) => {
      if (!PerivousService.includes(service?.serviceTypeId)) {
        return {
          key: service?.serviceTypeId,
          text: service?.displayName,
          value: service,
        };
      }
      return null;
    })
    .filter(Boolean);

  const [categoryValue, setCategoryValue] = useState([]);

  const handleCategorySelect = (categoryValue) => {
    const updatedUpdateService = updateService.map((service) => ({
      ...service,

      category:
        (service?.customdisplayName || service?.displayName?.displayName) && service?.category
          ? service?.category
          : categoryValue,
    }));
    setUpdateService(updatedUpdateService);
  };

  const handleCategoryChange = (field, value) => {
    const updatecategoryValue = [...categoryValue];
    updatecategoryValue[field] = value;
    setCategoryValue(updatecategoryValue);
    handleCategorySelect(value);
  };

  const [errorMessage, setErrorMessage] = useState({
    displayName: "",
    description: "",
  });

  const handleServiceChange = (index, field, value) => {
    const updatedServices = [...services];
    const updatedErrors = { ...errorMessage };

    if (field === "displayName") {
      if (value?.length <= 100) {
        updatedServices[index][field] = value;
        setServices(updatedServices);
        if (updatedErrors[index]?.displayName) {
          updatedErrors[index].displayName = "";
        }
      } else {
        if (!updatedErrors[index]) updatedErrors[index] = {};
        updatedErrors[index].displayName = "Service name cannot exceed 100 characters";
      }
    } else if (field === "description") {
      if (value?.length <= 1000) {
        updatedServices[index][field] = value;
        setServices(updatedServices);
        if (updatedErrors[index]?.description) {
          updatedErrors[index].description = "";
        }
      } else {
        if (!updatedErrors[index]) updatedErrors[index] = {};
        updatedErrors[index].description = "Description cannot exceed 1000 characters";
      }
    } else {
      // if (field === "displayName") {
      const updatedServices = [...services];
      updatedServices[index][field] = value;
      setServices(updatedServices);
      // if (updatedErrors[index]?.description) {
      //   updatedErrors[index].description = "";
      // }
      // }
      // setUpdateErrorMessage(updatedErrors);
    }
    setErrorMessage(updatedErrors);
  };

  // useEffect to log the errorMessage whenever it changes
  // useEffect(() => {
  //   console.log("Updated errorMessage:", errorMessage);
  // }, [errorMessage]);

  const [updateErrorMessage, setUpdateErrorMessage] = useState({
    displayName: "",
    description: "",
  });

  const handleUpdateServiceChange = (index, field, value) => {
    const updatedErrors = { ...updateErrorMessage }; // Copy of error messages

    if (field === "customdisplayName") {
      if (value?.length <= 100) {
        const updatedUpdateService = [...updateService];
        updatedUpdateService[index][field] = value;
        setUpdateService(updatedUpdateService);
        if (updatedErrors[index]?.displayName) {
          updatedErrors[index].displayName = "";
        }
      } else {
        if (!updatedErrors[index]) updatedErrors[index] = {};
        updatedErrors[index].displayName = "Service name cannot exceed 100 characters";
      }
    } else if (field === "description") {
      if (value?.length <= 1000) {
        const updatedUpdateService = [...updateService];
        updatedUpdateService[index][field] = value;
        setUpdateService(updatedUpdateService);
        if (updatedErrors[index]?.description) {
          updatedErrors[index].description = "";
        }
      } else {
        if (!updatedErrors[index]) updatedErrors[index] = {};
        updatedErrors[index].description = "Service name cannot exceed 100 characters";
      }
    } else {
      // if (field === "displayName") {
      const updatedUpdateService = [...updateService];
      updatedUpdateService[index][field] = value;
      setUpdateService(updatedUpdateService);
      // }
      setUpdateErrorMessage(updatedErrors);
    }
  };

  const handleDropdownChange = (index, field, value) => {
    handleUpdateServiceChange(index, field, value);
  };

  const handleInputChange = (index, field, value) => {
    handleUpdateServiceChange(index, field, value);
  };

  // useEffect(() => {
  //   // Update each service item with the selected category value
  //   console.log("dfasdfasdgfasdgasdgsd");
  //   const updatedServices = updateService.map((service) => ({
  //     ...service,
  //     category: categoryValue?.displayName || "", // Set category display name
  //   }));
  //   setUpdateService(updatedServices);
  // }, [categoryValue]);

  const formatServiceTypeId = (serviceTypeId) => {
    const formatted = serviceTypeId?.replace(/.*:/, "")?.split("_");

    return formatted?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))?.join(" ");
  };

  const PriceData = [
    { displayName: "NO_PRICE", value: "NO_PRICE" },
    { displayName: "FREE", value: "FREE" },
    { displayName: "FIXED", value: "FIXED" },
    // { displayName: "FROM", value: "FROM" }
  ];

  const PriceOptions = PriceData?.map((service) => ({
    key: service?.displayName,
    text: service?.displayName,
    value: service.displayName, // Adjust if `value` should be different
  }));

  let CategoryOption = [];

  // Map additional categories to the desired format
  const AdditionCategoryOption = primaryDetail?.category_name?.additional_category_list?.map((category) => ({
    key: category?.displayName,
    text: category?.displayName,
    value: category.name.split("/")[1], // Adjust if `value` should be different
  }));

  // Push additional categories (if any) into CategoryOption
  if (AdditionCategoryOption && AdditionCategoryOption.length > 0) {
    CategoryOption.push(...AdditionCategoryOption);
  }

  // Define the primary category option
  const PrimaryCategoryOption = {
    key: primaryDetail?.category_name?.primary,
    text: primaryDetail?.category_name?.primary,
    value: primaryDetail?.category_name?.primary_category_id,
  };

  // Push the primary category into CategoryOption
  CategoryOption.push(PrimaryCategoryOption);

  const addServiceHandler = async () => {
    let payload = [];

    // Process services array
    services?.forEach((service) => {
      let servicePayload;
      if (service?.serviceTypeId && !service?.customdisplayName) {
        servicePayload = {
          ...(service?.price_type === "FREE"
            ? {
                price: {
                  units: 0.0,
                  currencyCode: "INR",
                },
              }
            : service?.price_type === "FIXED"
            ? {
                price: {
                  units: service?.price,
                  currencyCode: "INR",
                },
              }
            : service?.price
            ? {
                price: {
                  units: service?.price,
                  currencyCode: "INR",
                },
              }
            : ""),
          structuredServiceItem: {
            serviceTypeId: service?.serviceTypeId,
            description: service?.description,
          },
        };
      } else {
        servicePayload = {
          ...(service?.price_type === "FREE"
            ? {
                price: {
                  units: 0.0,
                  currencyCode: "INR",
                },
              }
            : service?.price_type === "FIXED"
            ? {
                price: {
                  units: service?.price,
                  currencyCode: "INR",
                },
              }
            : service?.price
            ? {
                price: {
                  units: service?.price,
                  currencyCode: "INR",
                },
              }
            : ""),
          freeFormServiceItem: {
            category: service?.category ? service?.category?.split("/")[1] : categoryValue?.displayName,
            label: {
              displayName: service?.displayName ? service?.displayName : formatServiceTypeId(service?.serviceTypeId),
              description: service?.description,
            },
          },
        };
      }
      payload.push(servicePayload);
    });

    // Process updateService array
    updateService?.forEach((updateService) => {
      let updateServicePayload;
      if (updateService?.displayName?.serviceTypeId && !updateService?.customdisplayName) {
        updateServicePayload = {
          ...(updateService?.PriceName === "FREE"
            ? {
                price: {
                  units: 0.0,
                  currencyCode: "INR",
                },
              }
            : updateService?.PriceName === "FIXED"
            ? {
                price: {
                  units: updateService?.priceVlaue,
                  currencyCode: "INR",
                },
              }
            : ""),
          structuredServiceItem: {
            serviceTypeId: updateService?.displayName?.serviceTypeId,
            description: updateService?.description,
          },
        };
      } else {
        updateServicePayload = {
          ...(updateService?.PriceName === "FREE"
            ? {
                price: {
                  units: 0.0,
                  currencyCode: "INR",
                },
              }
            : updateService?.PriceName === "FIXED"
            ? {
                price: {
                  units: updateService?.priceVlaue,
                  currencyCode: "INR",
                },
              }
            : ""),
          freeFormServiceItem: {
            category: updateService?.category,
            label: {
              displayName: updateService?.customdisplayName,
              description: updateService?.description,
            },
          },
        };
      }
      if (updateService?.customdisplayName || updateService?.displayName?.serviceTypeId) {
        payload.push(updateServicePayload);
      }
    });

    if (payload?.length > 0) {
      await actions.getGmbLocationUpdateService(business_id, selectReso, {
        service_data: payload,
      });
    } else {
      await actions.getGmbLocationUpdateService(business_id, selectReso, {
        service_data: "",
      });
    }
    await actions.getGmbListingPrimaryDetail(business_id, selectReso);
    handleClose("businessService");
  };

  useEffect(() => {
    if (categoryValue?.displayName) {
      actions.getGmbServiceType(business_id, selectReso, categoryValue?.displayName);
    }
  }, [actions, business_id, categoryValue?.displayName, selectReso]);

  return (
    <>
      <div className="listing-container" style={{ overflow: "hidden", gap: "15px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <div className="listing-header">
            <div className="listing-info">
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="listing-name">{primaryDetail?.title}</div>
                <img
                  src={
                    primaryDetail?.is_varified
                      ? verified_svg
                      : primaryDetail?.is_suspend
                      ? duplicate_svg
                      : primaryDetail?.is_duplicate
                      ? duplicate_svg
                      : ""
                  }
                  alt="verifiedImage"
                />
              </div>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(1)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{formatReviews(primaryDetail?.totalReviewCount)} Reviews</span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>{addressString}</ListDescription>
          </div>
        </div>
      </div>
      <div className="listing-container" style={{ display: "grid", gap: "15px" }}>
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "calc(100% - 120px)",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "15px",
                paddingBottom: "5px",
              }}
            >
              {/* <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <FPButton
                  label="AI suggested Q&A's"
                  size="small"
                  startIcon={<img src={emoji_svg} alt="emojiSvg" />}
                  star
                />
              </div> */}
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  paddingBottom: "5px",
                }}
              >
                <div
                  style={{
                    padding: "16px",
                    backgroundColor: "#F6F6F8",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <div style={{ display: "grid", gap: "10px" }}>
                    <Dropdown
                      placeholder="Category Name"
                      fluid
                      search
                      selection
                      value={categoryValue[0]?.displayName}
                      options={CategoryOption}
                      onChange={(e, { value }) => handleCategoryChange("displayName", value)}
                    />
                  </div>
                </div>
                {categoryValue?.displayName && (
                  <>
                    {services?.length > 0 ? (
                      services.map((service, index) => {
                        const matchedJobType =
                          AllServiceOptions &&
                          AllServiceOptions.find((jobType) => jobType.value.serviceTypeId === service.serviceTypeId);
                        // const matchedJobType =
                        //   PerivousService &&
                        //   PerivousService.find(
                        //     (jobType) => jobType === service.serviceTypeId
                        //   );
                        if (service?.category || matchedJobType) {
                          if (categoryValue.displayName === service?.category?.split("/")[1] || matchedJobType) {
                            return (
                              <div
                                key={index}
                                style={{
                                  padding: "16px",
                                  backgroundColor: "#F6F6F8",
                                  borderRadius: "8px",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "16px",
                                }}
                              >
                                <div style={{ display: "grid", gap: "10px" }}>
                                  <div
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      color: "#1B1C1D",
                                    }}
                                  >
                                    Service Name
                                  </div>
                                  <FPInput
                                    type="text"
                                    placeholder="Enter Service Name."
                                    value={
                                      service?.displayName
                                        ? service?.displayName
                                        : formatServiceTypeId(service?.serviceTypeId)
                                    }
                                    disabled={service?.serviceitemType === "structuredServiceItem"}
                                    onChange={(e) => handleServiceChange(index, "displayName", e.target.value)}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "12px",
                                        color: "#7A8193",
                                      }}
                                    >
                                      {service?.displayName?.length ||
                                        formatServiceTypeId(service?.serviceTypeId).length}{" "}
                                      / 100 characters
                                    </span>
                                    {errorMessage[index]?.displayName && (
                                      <span style={{ color: "red" }}>{errorMessage[index].displayName}</span>
                                    )}
                                  </div>
                                </div>
                                <div style={{ display: "flex", gap: "10px" }}>
                                  {/* <div style={{ fontWeight: "400", fontSize: "14px", color: "#1B1C1D" }}>Service Name</div> */}
                                  <Dropdown
                                    fluid
                                    search
                                    selection
                                    options={PriceOptions}
                                    value={
                                      service.price_type // Show the current `price_type` if it exists
                                        ? service.price_type
                                        : service.price && service.currency_code // Default to "FIXED" if both price and currency_code are present
                                        ? "FIXED"
                                        : !service.price && service.currency_code // Default to "FREE" if only currency_code is present
                                        ? "FREE"
                                        : !service.price && !service.currency_code
                                        ? "NO_PRICE"
                                        : "" // Default to "NO_PRICE" if neither price nor currency_code is present
                                    }
                                    onChange={(e, { value }) => handleServiceChange(index, "price_type", value)}
                                    placeholder={`Price ${index + 1}`}
                                  />
                                  <FPInput
                                    value={service.price || ""}
                                    onChange={(e) => handleServiceChange(index, "price", e.target.value)}
                                    placeholder={"Price(INR)"}
                                    disabled={
                                      service.price_type === "FREE" ||
                                      service.price_type === "NO_PRICE" ||
                                      service.price_type === ""
                                    }
                                  />
                                  {/* <div style={{ fontWeight: "400", fontSize: "12px", color: "#7A8193" }}>1000 characters</div> */}
                                </div>

                                <div style={{ display: "grid", gap: "10px" }}>
                                  <div
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      color: "#1B1C1D",
                                    }}
                                  >
                                    Service Description
                                  </div>
                                  <textarea
                                    rows="4"
                                    style={{
                                      width: "100%",
                                      borderRadius: "7px",
                                      borderColor: "#BFC2CB",
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      color: "#7A8193",
                                      padding: "10px 14px",
                                    }}
                                    value={service?.description || ""}
                                    onChange={(e) => handleServiceChange(index, "description", e.target.value)}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "12px",
                                        color: "#7A8193",
                                      }}
                                    >
                                      {service?.description?.length || 0} / 1000 characters
                                    </span>
                                    {errorMessage[index]?.description && (
                                      <span style={{ color: "red" }}>{errorMessage[index].description}</span>
                                    )}
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <img
                                    onClick={() => handleAdditionalDeleteClick(service)}
                                    src={delete_png}
                                    alt="delete"
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        }
                      })
                    ) : (
                      <div className="listing-name">No Existing Service</div>
                    )}

                    {/* New Services */}
                    {/* {categoryValue?.displayName} */}
                    {updateService.map((service, index) => {
                      const matchedJobType =
                        AllServiceOptions && AllServiceOptions.find((jobType) => jobType === service.serviceTypeId);
                      if (service?.category === categoryValue?.displayName || matchedJobType) {
                        return (
                          <div
                            key={index}
                            style={{
                              padding: "16px",
                              backgroundColor: "#F6F6F8",
                              borderRadius: "8px",
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                            }}
                          >
                            <div style={{ display: "grid", gap: "10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: "#1B1C1D",
                                  }}
                                >
                                  Service Name
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  {ServiceOptions?.length > 0 && (
                                    <>
                                      <input
                                        style={{
                                          fontWeight: "400",
                                          fontSize: "14px",
                                          color: "#1B1C1D",
                                        }}
                                        id="custom_service"
                                        checked={!isDropdown}
                                        onClick={dropdownInputHandler}
                                        type="checkbox"
                                      />
                                      <label
                                        htmlFor="custom_service"
                                        style={{
                                          fontWeight: "400",
                                          fontSize: "14px",
                                          color: "#1B1C1D",
                                          cursor: "pointer",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        Custom Service
                                      </label>
                                    </>
                                  )}
                                </div>
                              </div>
                              {isDropdown && ServiceOptions?.length > 0 ? (
                                <Dropdown
                                  fluid
                                  search
                                  selection
                                  options={ServiceOptions}
                                  value={service.displayName || ""} // Ensure this matches the option value
                                  onChange={(e, { value }) => handleDropdownChange(index, "displayName", value)}
                                  placeholder={`Service ${index + 1}`}
                                />
                              ) : (
                                <>
                                  <FPInput
                                    value={service.customdisplayName || ""} // Ensure this matches the option value
                                    onChange={(e) => handleInputChange(index, "customdisplayName", e.target.value)}
                                    placeholder={`Service ${index + 1}`}
                                  />
                                  <input type="hidden" value={service?.category} />
                                </>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#7A8193",
                                  }}
                                >
                                  {service?.customdisplayName?.length || service?.displayName?.length || 0} / 100
                                  characters
                                </div>
                                {updateErrorMessage[index]?.displayName && (
                                  <span style={{ color: "red" }}>{updateErrorMessage[index].displayName}</span>
                                )}
                              </div>
                            </div>
                            <div style={{ display: "flex", gap: "10px" }}>
                              {/* Service Price */}
                              <Dropdown
                                fluid
                                search
                                selection
                                options={PriceOptions}
                                value={service.PriceName || ""} // Ensure this matches the option value
                                onChange={(e, { value }) => handleUpdateServiceChange(index, "PriceName", value)}
                                placeholder={`Price ${index + 1}`}
                              />
                              <FPInput
                                value={service.priceVlaue || ""}
                                onChange={(e) => handleUpdateServiceChange(index, "priceVlaue", e.target.value)}
                                placeholder={"Price(INR)"}
                                disabled={service.PriceName !== "FROM" && service.PriceName !== "FIXED"}
                              />
                            </div>
                            <div style={{ display: "grid", gap: "10px" }}>
                              <div
                                style={{
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  color: "#1B1C1D",
                                }}
                              >
                                Service Description
                              </div>
                              <textarea
                                rows="4"
                                style={{
                                  width: "100%",
                                  borderRadius: "7px",
                                  borderColor: "#BFC2CB",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  color: "#7A8193",
                                  padding: "10px 14px",
                                }}
                                value={service.description || ""}
                                onChange={(e) => handleInputChange(index, "description", e.target.value)}
                                placeholder="Enter Service Description."
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    color: "#7A8193",
                                  }}
                                >
                                  {service.description?.length || 0} / 1000 characters
                                </div>
                                {updateErrorMessage[index]?.description && (
                                  <span style={{ color: "red" }}>{updateErrorMessage[index].description}</span>
                                )}
                              </div>
                            </div>
                            {updateService?.length > 1 && (
                              <div className="d-flex justify-content-end">
                                <img
                                  src={delete_png}
                                  alt="delete"
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleUpdateServiceDeleteClick(service, index)}
                                />
                              </div>
                            )}
                          </div>
                        );
                      }
                    })}

                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <FPButton label="Add More" onClick={handleAddClick} size="small" endIcon={<AddIcon />} />
                    </div>
                  </>
                )}
              </div>
            </div>
            {categoryValue?.displayName && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  paddingBlock: "10px",
                }}
              >
                <FPButton
                  label="Update"
                  size="small"
                  disabled={primaryDetail?.is_suspend}
                  onClick={addServiceHandler}
                />
              </div>
            )}
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

// export default UpdateService;

const mapStateToProps = (state) => {
  return {
    service_type_data: state.gmbListingLocation.service_type_data,
    service_type_error: state.gmbListingLocation.service_type_error,
    updateServiceData: state.gmbListingLocation.updateServiceData,
    updateServiceError: state.gmbListingLocation.updateServiceError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateService);
