import { combineReducers } from "redux";
import login from "./accounts/loginReducer";
import forgotPassword from "./accounts/forgotPasswordReducer";
import register from "./accounts/registerReducer";
import changePassword from "./accounts/changePasswordReducer";
import profileReducer from "./accounts/profileReducer";
import businessSetup from "./business/businessSetupReducer";
import socialSetup from "./business/socialSetupReducer";
import reviewsDetails from "./business/reviewsReducer";
import teamReducer from "./team/teamReducer";
import contact from "./contact/contactReducer";
import template from "./template/templateReducer";
import newsLetter from "./newsLetter/newsLetterReducers";
import genericTemplate from "./genericTemplates/genericTemplatesReducer";
import agency from "./marketing/marketingReducer";
import campaign from "./campaign/campaignReducer";
import recurringCampaign from "./campaign/recurringCampaignReducer";
import overview from "./overview/overviewReducer";
import report from "./report/reportReducer";
import complaintDetails from "./business/complaintsReducer";
import { reducer as formReducer } from "redux-form";
import commonReducer from "./common/commonReducer";
import insightsReducer from "./insights/insightsReducer";
import competitorsDetails from "./competitors/competitorsReducer";
import mentionsReducer from "./mentions/mentionsReducer";
import ticketDetails from "./ticket/ticketReducer";
import serviceTicketDetails from "./ticket/serviceTicketReducer"
import paymentsReducer from "./payments/paymentsReducer";
import filterReducer from "./filterReducer/filterReducer";
import selectedTabReducer from "./tab/selectedTabReducer";
import socialLoginSetup from "./listing/socialLoginReducer";
import gmbListingOverviewReducer from "./listing/overviewReducer";
import gmbListingLocationReducer from "./listing/listingLocationReducer";
import departmentReducer from "./department/departmentReducer";
import designationReducer from "./designation/designationReducer";
import ticketEscalationReducer from "./ticketEscalation/ticketEscalationReducer";
import socialCommentsReducer from "./socialComments/socialCommentsReducers";
import socialProfileReducer from "./socialProfile/socialProfileReducers";
import RouterStateReducer from "./common/routerStateReducer";
import listingPerformanceReducer from "./listing/listingPerformanceReducer";
import gmbListingGeoGridRankerReducer from "./listing/geoGridRankerReducer";
import productFilterReducer from "./filterReducer/productFilterReducer";
import adsPerformanceReducer from "./restro-pilot/adsPerformanceReducer"
import serviceRequestOverview from "./ticket/serviceRequestOverviewReducer";
import OfflineStoreAlerts from "./OfflineStoreAlerts/OfflineStoreAlertsReducer";
import gmbListingCreditUsageHistoryReducer from "./listing/CreditUsageHistoryReducer";
import salesPageReducer from "./restro-pilot/salesPageReducer";
import reviewEmailList from "./email/reviewEmailReducer";
import whatsAppSection from "./whatsApp/whatsAppReducer";
import dashboardReducer from "./common/dashboardReducer";

const rootReducer = combineReducers({
  login: login,
  forgotPassword: forgotPassword,
  register: register,
  changePassword: changePassword,
  profile: profileReducer,
  business: businessSetup,
  socialSetup: socialSetup,
  review: reviewsDetails,
  team: teamReducer,
  socialLoginSetup: socialLoginSetup,
  form: formReducer,
  common: commonReducer,
  contact: contact,
  agency: agency,
  campaign: campaign,
  recurringCampaign: recurringCampaign,
  overview: overview,
  report: report,
  template: template,
  newsLetter: newsLetter,
  genericTemplate: genericTemplate,
  complaints: complaintDetails,
  insights: insightsReducer,
  competitors: competitorsDetails,
  mentions: mentionsReducer,
  tickets: ticketDetails,
  serviceTickets: serviceTicketDetails,
  reviewEmailList: reviewEmailList,
  serviceRequestOverview: serviceRequestOverview,
  payments: paymentsReducer,
  filter: filterReducer,
  selectedTab: selectedTabReducer,
  department: departmentReducer,
  designation: designationReducer,
  ticketEscalation: ticketEscalationReducer,
  socialComments: socialCommentsReducer,
  socialProfile: socialProfileReducer,
  routerState: RouterStateReducer,
  productItem: productFilterReducer,
  offlineStoreAlerts: OfflineStoreAlerts,
  whatsAppSection: whatsAppSection,

  // listing
  gmbListingOverview: gmbListingOverviewReducer,
  gmbListingLocation: gmbListingLocationReducer,
  listingPerformance: listingPerformanceReducer,
  listingGeoGridRanker: gmbListingGeoGridRankerReducer,
  gmbListingCreditUsageHistory: gmbListingCreditUsageHistoryReducer,

  // restropilot
  adsPeformance: adsPerformanceReducer,
  salesPage: salesPageReducer,

  // dashboard page
  dashboard: dashboardReducer
});

export default rootReducer;
