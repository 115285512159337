import React, { useState } from "react";
import { InputBase, alpha, makeStyles, withStyles } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import FPLabel from "./FPLabel";

const BootstrapInput = withStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: 38,
  },
  input: {
    borderRadius: "8px",
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #BFC2CB",
    fontSize: 13,
    width: "100%",
    padding: ({ size }) => {
      if (size === "large") {
        return "16px 12px";
      } else if (size === "small") {
        return "6.7px 12px";
      } else {
        return "10px 12px";
      }
    },
    paddingRight: ({ cssType, loginPass, loginEmail }) => (cssType === "password" ? loginPass ? "60px" : "45px" : loginEmail ? "30px": "12px"),
    paddingLeft: ({ startIcon }) => (startIcon ? "40px" : "12px"),
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderColor: ({ error }) => (error ? "#f44336" : "#BFC2CB"),
    "&:focus": {
      boxShadow: ({ error }) => `${alpha(error ? "#f44336" : "#008eff", 0.25)} 0 0 0 0.2rem`,
      borderColor: ({ error }) => (error ? "#f44336" : "#008eff"),
    },
  },
}))((props) => <InputBase {...props} />);

const useStyles = makeStyles(() => ({
  endIcon: {
    position: "absolute",
    right: "15px",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 10,
  },

  startIcon: {
    position: "absolute",
    left: "15px",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 10,
  },

  endBothIcon: {
    position: "absolute",
    right: "40px",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 10,
  },
}));

const FPInput = ({
  label,
  placeholder,
  name,
  type = "text",
  size = "default",
  error,
  startIcon,
  endIcon,
  isRequired = true,
  loginEmail = false,
  loginPass = false,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  
  return (
    <div className="w-100 d-inline-block">
      {label && (
        <FPLabel isRequired={isRequired} htmlFor={name || "bootstrap-input"}>
          {label}
        </FPLabel>
      )}
      <div className="position-relative me-5">
        {startIcon && <div className={classes.startIcon}>{startIcon}</div>}
        <BootstrapInput
          type={showPassword ? "text" : type}
          placeholder={placeholder ? placeholder : label || "Enter here..."}
          cssType={type}
          startIcon={!!startIcon}
          endIcon={!!endIcon}
          error={error}
          size={size}
          loginEmail={loginEmail}
          loginPass={loginPass}
          id={name || "bootstrap-input"}
          className={`fp-input ${endIcon ? classes.inputWithIcon : undefined}`}
          // className={startIcon ? classes.inputWithIcon : undefined}
          {...rest}
        />

        {endIcon && <div className={classes.endIcon}>{endIcon}</div>}
        {type === "password" && (
          <div className={endIcon && type === "password" ? classes.endBothIcon : classes.endIcon}>
            {showPassword ? (
              <VisibilityIcon
                role="button"
                style={{ color: "#BFC2CB", fontSize: "16px" }}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <VisibilityOffIcon
                role="button"
                style={{ color: "#BFC2CB", fontSize: "16px" }}
                onClick={() => setShowPassword(true)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FPInput;
