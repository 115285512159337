/* eslint-disable no-case-declarations */
import axios from "axios";
import cookie from "react-cookies";
import * as utils from "./utils";
import * as URLS from "./apiUrls"

let axios_instance = axios.create();

axios_instance.interceptors.request.use(
  (configuration) => {
    const config = configuration;
    const authToken = cookie.load("authToken");
    if (authToken) {
      // config.headers.Authorization = `Token ${authToken}`;
      config.headers.Authorization = `Bearer ${authToken}`;
      config.headers["ngrok-skip-browser-warning"] = "any";
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios_instance.interceptors.response.use(
  (response) => {
    return response;
  },    
  (error) => {
    switch (error.response.status) {
      case 401:
        const refreshToken = cookie.load("refreshToken");
        const payload = { 
          'refresh' : refreshToken
        }
        const refresh_call = localStorage.getItem('refresh_call')
        if(!refresh_call) {
          localStorage.setItem('refresh_call', true);
          axios.post(`${URLS.REFRESH_TOKEN}`, payload)
            .then((res) => {
              cookie.save("authToken", res.data.access, {
                path: "/",
                maxAge: 365 * 24 * 60 * 60,
              });
              cookie.save("refreshToken", res.data.refresh, {
                path: "/",
                maxAge: 365 * 24 * 60 * 60,
              });
              localStorage.removeItem('refresh_call');
              window.location.reload()
            }).catch((err) => {
              utils.displayMessage("negative", "Unauthorized");
              utils.clearCookie();
              localStorage.removeItem('refresh_call');
              window.location.href = "/login";
            });
        }
        break;

      case 402:
        utils.displayMessage("negative", "Payment is required");
        const user = cookie.load("business_user");
        window.location.href = `/${user.business}/payments`;
        break;

      case 403:
        // utils.displayMessage("negative", "Unauthorized");
        const err = { response: { data: "lock" } };
        return Promise.reject(err);

      case 404:
        if (!Array.isArray(error?.response?.data)) {
          // return Promise.reject(error);
          return Promise.reject(error);
        } else {
          utils.displayMessage("negative", "Not Found");
          return Promise.reject(error);
        }

      case 500:
        utils.displayMessage("negative", "Server Error");
        break;

      default:
        const errorMessages = error.response && error.response.data && Object.values(error.response.data)[0];
        const message = Array.isArray(errorMessages) ? errorMessages : errorMessages;
        message && utils.displayMessage("negative", message);
    }

    return Promise.reject(error);
  }
);

export default axios_instance;
