/* eslint-disable no-unused-vars */
import React, { memo, useMemo, useState } from "react";
import {
  FPButton,
  FPMenuBox,
  FPToolTip,
  FPView,
} from "../../components/common/new";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import { Badge, makeStyles } from "@material-ui/core";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import "./userDetails.css";
import TicketComplete from "../../images/svg/TicketComplete";
import { snakeToCapital } from "../../libs/utils";
import classNames from "classnames";
import { useCallback } from "react";
import cookie from "react-cookies";

const useStyles = makeStyles((theme) => ({
  menuBox: {
    color: "#1B1C1D",
    fontSize: "13px",
    gap: 0,
    backgroundColor: "#F6F6F8",
    border: "unset",
  },
  button: {
    padding: "0 12px",
    fontSize: "13px",
    border: "unset",

    "& .MuiButton-label": {
      fontSize: "13px",
      fontWeight: "400",
    },
  },
}));

const ReviewHeaderActivity = (props) => {
  const {
    item,
    replyDisabled,
    statuses,
    replyOptions,
    handleReplyOption,
    ticketActivity,
    toggleTicketModal,
    createTicket,
    changeStatus,
  } = props;
  const service_category = cookie.load("service_category");
  const modifyReplyOption = useMemo(
    () =>
      replyOptions
        ? replyOptions.map((option) => ({
            label: option.display_name,
            value: option.value,
            onClick: () => handleReplyOption(option.value, item.id),
          }))
        : [],
    [replyOptions]
  );

  const [ticketResolveModal, setTicketResolveModal] = useState(false);

  const toggleConfirm = useCallback(
    () => setTicketResolveModal(!ticketResolveModal),
    [ticketResolveModal]
  );

  const serviceDelivery = [
    { label: "Delivery", value: "Delivery" },
    { label: "Dining", value: "Dining" },
    { label: "Takeaway", value: "Takeaway" },
  ];

  const classes = useStyles({ isTicket: item.ticket });

  const provider = useMemo(
    () => [
      "swiggy",
      "offline",
      "magicpin",
      "dineout",
      "eazydiner",
      "ewards",
      "reservego",
      "rista",
      "zomato_ors",
      "inhouse",
    ],
    []
  );
  return (
    <div className="d-flex align-items-center gap-10">
      {![provider].includes(item.provider_name) && (
        <FPMenuBox
          title={statuses ? statuses[item.status] : "Not Responded"}
          options={modifyReplyOption}
          className={classes.menuBox}
          rounded={true}
          size={"small"}
        />
      )}

      {item.service_type && service_category === "Restaurant" &&(
        <FPMenuBox
          title={snakeToCapital(item.service_type)}
          options={serviceDelivery}
          className={classes.menuBox}
          rounded={true}
          size={"small"}
        />
      )}

      { ticketActivity && (
        <>
          <FPToolTip title={item.ticket ? "View Ticket" : "Create Ticket" }>
            <Badge
              badgeContent={
                (item.no_of_ticket_comments === 0 || !item.no_of_ticket_comments)
                  ? 0
                  : `+${item.no_of_ticket_comments}`
              }
              color="primary"
              className="ticket-badge"
            >
              <FPButton
                onClick={() =>
                  toggleTicketModal
                    ? item.ticket
                      ? toggleTicketModal(item)
                      : createTicket(item)
                    : {}
                }
                startIcon={
                  item.ticket ? (
                    <ConfirmationNumberOutlinedIcon
                      style={{
                        color: "#FFFFFF",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  ) : null
                }
                backgroundColor={"#F13A88"}
                textColor={"#FFFFFF"}
                label={item.ticket ? `#${item.ticket}` : "Create Ticket"}
                size={"small"}
                className={classes.button}
              />
            </Badge>
          </FPToolTip>
          <FPToolTip title="Resolve Ticket">
            <span
              style={{ marginLeft: 10 }}
              onClick={() => setTicketResolveModal(true)}
              className={classNames({
                "fp-disabled": item.ticket_status === "resolved",
              })}
            >
              <TicketComplete />
            </span>
          </FPToolTip>
        </>
      )}

      {ticketResolveModal && (
        <ConfirmationModal
          header="Are you sure!"
          subHeader="Are you sure you want to resolve this ticket?"
          isOpen={ticketResolveModal}
          toggle={toggleConfirm}
          onConfirm={changeStatus}
          success
        />
      )}
    </div>
  );
};

export default memo(ReviewHeaderActivity);
