import React, { useState } from 'react'
import google_svg from "../../../../../images/googlephoto.svg";
import emoji_svg from "../../../../../images/emoji.svg";
import FPButton from '../../../../../components/common/new/FPFilter/Listing/FPButton';
import AddIcon from "@material-ui/icons/Add";
import ListingFPInput from '../../../../../components/common/new/Listing/ListingFPInput';
import delete_png from "../../../../../images/delete.png";
import BusinessList from '../BusinessList';

const UpdateQuestion = (props) => {
  const { business_id, queryStringData, busines_status } = props
  const [selectedBusinessList, setSelectedBusinessList] = useState([]);
  const [showBusiness, setShowBusiness] = useState(true);
  const handleHideBusinessScreen = () => {
    setShowBusiness(false);
  }

  const handleShowBusinessScreen = () => {
    setShowBusiness(true);
  }

  const [selectedBusinessCount, setSelectedBusinessCount] = useState([]); // State to hold the selected businesses count
  console.log("==================================", selectedBusinessCount)

  const handleSelectedBusinessCount = (selectBusiness) => {
    setSelectedBusinessCount(selectBusiness);
  };

  return (
    <>
      {showBusiness ?
        <div>
          <BusinessList type="questions" business_id={business_id} busines_status={busines_status} queryStringData={queryStringData} primaryDetail={null} selectedBusinessList={selectedBusinessList} onSelectedBusinessCountChange={handleSelectedBusinessCount} />
          {selectedBusinessCount?.length > 0 ?
            <div
              className="d-flex mt-10 mb-15"
              style={{
                display: "flex",
                justifyContent: "end",
                fontWeight: "600",
                fontSize: "24px",
                color: "#1A2544",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "20px"
              }}    >
              < FPButton
                label="Next"
                onClick={handleHideBusinessScreen}
              />
            </div>
            : ""
          }

        </div>
        :
        <div className="row">
          <div className="col-12 col-lg-9">
            <div
              className="listing-container"
              style={{ display: "grid", gap: "32px" }}
            >
              <div
                className="d-flex "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <FPButton
                  label="Add Question"
                  size="small"
                  endIcon={<AddIcon />}
                />
                <FPButton
                  label="AI suggested Q&A's"
                  size="small"
                  startIcon={<img src={emoji_svg} alt="emojiSvg" />}
                  star
                />
              </div>
              <div
                style={{
                  padding: "16px",
                  backgroundColor: "#F6F6F8",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div style={{ display: "grid", gap: "10px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Question{" "}
                  </div>
                  <ListingFPInput
                    type="text"
                    placeholder={
                      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
                    }
                  />
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#7A8193",
                    }}
                  >
                    1000 words
                  </div>
                </div>
                <div style={{ display: "grid", gap: "10px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Answer
                  </div>
                  <textarea
                    id="w3review"
                    name="w3review"
                    rows="4"
                    placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                    style={{
                      width: "100%",
                      borderRadius: "7px",
                      borderColor: "#BFC2CB",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#7A8193",
                      padding: "10px 14px",
                    }}
                  />
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#7A8193",
                    }}
                  >
                    1000 words
                  </div>
                </div>
                <div>
                  <div style={{ borderBottom: "1px solid #BFC2CB" }}></div>
                </div>
                <div className="d-flex justify-content-end">
                  <img
                    src={delete_png}
                    alt=""
                    style={{ height: "16px", width: "16px", color: "#FFFFFF" }}
                  />
                </div>
              </div>
              <div className="listing-name">No Existing Question & Answer</div>

              <div style={{
                display: "flex",
                justifyContent: "space-between",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                // paddingBottom: "10px",
                // paddingTop: "10px",
                paddingBlock: "20px"
              }}>
                <div><FPButton
                  label="Back"
                  size="small"
                  onClick={handleShowBusinessScreen}
                /></div>
                <div><FPButton
                  label="Update"
                  size="small"
                /></div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <img src={google_svg} alt="verifiedImage" width={"100%"} />
          </div>
        </div>
      }
    </>
  )
}

export default UpdateQuestion