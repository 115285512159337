import React from "react";
import { FPBadge } from "../../../../components/common/new";
import { format } from "../../../../libs/utils";
import apiURL from "../../../../libs/apiUrls";
import StackedBarChart from "../../../../components/common/StackedBarChart";
import "../chart/chart_bar.css";
import Chartwrapper from "../Chartwrapper";
import { Skeleton } from "@mui/material";

function SectionBarChart(props) {
  const { businessId, barHeadData, data, fetchSectionBarChartData, loader } = props;

  const updateData = (datePayload) => {
    fetchSectionBarChartData(datePayload);
  };
  return (
    <div className="h-100">
      <Chartwrapper
        headerTitle="Provider wise Response Rate"
        // contentClass="mt-1"
        // headerStyle={{ marginTop: "5px" }}
        className="h-100"
        onDateChange={updateData}
        widthElevation={true}
        skeletonLoader={loader}
        // isForLifeTimeSupported={false}
      >
        {loader ? (
          <>
            <div className="d-flex gap-30 mt-3 mt-md-0 justify-content-between h-100">
              <section className="fp-card-outline w-50 headingBox">
                <Skeleton
                  variant="text"
                  width="40%"
                  height={20}
                  style={{ marginBottom: "10px", margin: "0 auto" }}
                />
                <Skeleton
                  variant="text"
                  width="60%"
                  height={25}
                  style={{ margin: "0 auto" }}
                />
              </section>
              <section className="fp-card-outline w-50 headingBox">
                <Skeleton
                  variant="text"
                  width="40%"
                  height={20}
                  style={{ marginBottom: "10px", margin: "0 auto" }}
                />
                <Skeleton
                  variant="text"
                  width="60%"
                  height={25}
                  style={{ margin: "0 auto" }}
                />
              </section>
              <section className="fp-card-outline w-50 headingBox">
                <Skeleton
                  variant="text"
                  width="40%"
                  height={20}
                  style={{ marginBottom: "10px", margin: "0 auto" }}
                />
                <Skeleton
                  variant="text"
                  width="60%"
                  height={25}
                  style={{ margin: "0 auto" }}
                />
              </section>
            </div>
            <div className="sectionHeadingBarChart mt-4 d-flex justify-content-center ">
              <Skeleton variant="rectangular" width="90%" height={200} />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex gap-30 mt-3 mt-md-0 justify-content-between h-100">
              <section className="fp-card-outline w-50 headingBox">
                <p
                  className="dashboard_sub_caption"
                  style={{ fontSize: "14px" }}
                >
                  Total Review
                </p>
                <p className="dash-text my-1" style={{ fontSize: "17px" }}>
                  {barHeadData?.totalData ? barHeadData?.totalData : 0}
                </p>
              </section>
              <section className="fp-card-outline w-50 headingBox">
                <div className="d-flex justify-content-between">
                  <p
                    className="dashboard_sub_caption"
                    style={{ fontSize: "14px" }}
                  >
                    Responded
                  </p>
                  <FPBadge textClass="trending-header" color="#57e32c" />
                </div>
                <p className="dash-text my-1" style={{ fontSize: "17px" }}>
                  {barHeadData?.respondedData ? barHeadData?.respondedData : 0}
                </p>
              </section>
              <section className="fp-card-outline w-50 headingBox">
                <div className="d-flex justify-content-between">
                  <p
                    className="dashboard_sub_caption"
                    style={{ fontSize: "14px" }}
                  >
                    Not Responded
                  </p>
                  <FPBadge textClass="trending-header" color="#ff4545" />
                </div>
                <p
                  className="dash-text my-1"
                  style={{ fontSize: "17px", flexDirection: "column" }}
                >
                  {barHeadData?.notRespondedData
                    ? barHeadData?.notRespondedData
                    : 0}
                </p>
              </section>
            </div>

            {data && (
              <div className="sectionHeadingBarChart">
                <StackedBarChart
                  title={""}
                  dataKeys={Object.keys(data)}
                  primaryYLabel="Count"
                  data={[
                    {
                      label: "Responded",
                      data: Object.values(data).map((item) => item.responded),
                      type: "bar",
                      backgroundColor: "#57e32c",
                      hoverBackgroundColor: "#57e32c",
                      barThickness: 35,
                      inflateAmount: 0,
                      borderRadius: 8,
                    },
                    {
                      label: "Not Responded",
                      data: Object.values(data).map(
                        (item) => item.not_responded
                      ),
                      type: "bar",
                      backgroundColor: "#ff4545",
                      hoverBackgroundColor: "#ff4545",
                      barThickness: 35,
                      borderRadius: 8,
                      inflateAmount: 0,
                    },
                  ]}
                />
              </div>
            )}
          </>
        )}
      </Chartwrapper>
    </div>
  );
}

export default SectionBarChart;
