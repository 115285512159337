/* eslint-disable */
import React from "react";
import moment from "moment";
import SortIcon from "../../../images/svg/Sort.svg";
import { Button, Checkbox } from "semantic-ui-react";
import PaginationComponent from "../../../components/common/Pagination";
import SearchComponent from "../../../components/common/SearchComponent";
import { Row, Col } from "reactstrap";
import RootHeader from "../../../modules/campaignsRoot/RootHeader";
import Header from "../../../modules/campaignsRoot/templates/Header";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { FPButton, FPMenuBox, FPToolTip } from "../../common/new";
import DeleteIcon from "../../../images/svg/trash.svg";
import FileCopyIcon from "../../../images/fileCopyIcon.png";
import InsertLinkOutlinedIcon from "@material-ui/icons/InsertLinkOutlined";
import * as utils from "../../../libs/utils";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import qrIcon from "../../../images/source/qrIcon.png";
import { Popover } from "@material-ui/core";
import QRCode from "qrcode.react";
import LinkIcon from "@material-ui/icons/Link";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import "../campaignTemplate.css";
import FPChip from "../../common/new/FPChip/FPChip";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";

class TemplateList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCreatedOnClicked: false,
      selectedLists: [],
      resetSelectedLists: false,
      qrModal: null,
      downloadQRCode: {},
    };
    this.editCampaign = this.editCampaign.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleResetSelectedLists = (value) => {
    this.setState({ resetSelectedLists: value });
  };

  handleQrModalClose = () => {
    this.setState({ qrModal: null });
  };

  handleQrModalOpen = (e, item) => {
    this.setState({ qrModal: e.currentTarget });
    this.setState({ downloadQRCode: item });
  };

  handleCheckbox(event, data) {
    const { list } = this.props;
    let contacts = [...this.state.selectedLists];
    if (data.checked && data.id === "all") contacts = contacts.concat(list.results.map((item) => item.id));
    else if (!data.checked && data.id === "all") {
      contacts = contacts.filter((id) => !list.results.some((obj) => obj.id === id));
    } else if (data.checked && contacts.indexOf(data.id) < 0) contacts.push(data.id);
    else {
      const index = contacts.indexOf(data.id);
      if (index > -1) contacts.splice(index, 1);
    }
    this.setState({ selectedLists: contacts });
    this.handleResetSelectedLists && this.handleResetSelectedLists(false);
  }

  editCampaign(item) {
    const { generic } = this.props;
    let url = "type=email&id=" + item.id;
    if (item.template_type === "msg_template") {
      url = "type=sms&id=" + item.id;
    }
    if (item.template_type === "whatsapp_template") {
      url = "type=whatsapp&id=" + item.id;
    } else if (generic) {
      url = "id=" + item.id;
    }
    generic ? this.props.switchToAddTemplate(url, item.branch) : this.props.switchToAddTemplate(url);
  }

  copyLink = (link) => {
    utils.copyToClipboard(link);
  };

  downloadQR = (link) => {
    const canvas = document.getElementById("generatedQrCode" + link.id);
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = link.name + ".png"; // Assuming link has a property `name`
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  getDefaultTab() {
    switch (true) {
      case location.pathname.includes("/templates/list"):
        return 0;
      case location.pathname.includes("/templates/news-letter/list"):
        return 1;
      case location.pathname.includes("/templates/generic/list"):
        return 2;
      default:
        break;
    }
  }
  toggleCreatedOnClick = () => {
    this.setState((prevState) => ({
      isCreatedOnClicked: !prevState.isCreatedOnClicked,
    }));
  };
  render() {
    const {
      switchToAddTemplate,
      newsLetter,
      list,
      deleteTemplate,
      cloneTemplate,
      ordering,
      generic,
      sortFunction,
      pageSizeCookie,
      showClone,
      location,
    } = this.props;
    const { selectedLists, downloadQRCode } = this.state;
    const open = Boolean(this.state.qrModal);
    const sort =
      ordering && ordering[0] === "-" ? { on: ordering.slice(1), by: "descending" } : { on: ordering, by: "ascending" };
    const templatesList = [
      {
        label: newsLetter ? "News Letter Email" : "Review Request Email",
        value: "type=email",
        onClick: () => switchToAddTemplate("type=email"),
      },
      {
        label: newsLetter ? "News Letter SMS" : "Review request SMS",
        value: "type=sms",
        onClick: () => switchToAddTemplate("type=sms"),
      },
      !newsLetter && {
        label: "Review request Whatsapp",
        value: "type=whatsapp",
        onClick: () => switchToAddTemplate("type=whatsapp"),
      },
    ].filter((item) => !!item === true);
    return (
      <Row>
        <div className="w-100">
          <RootHeader defaultValue={1} history={this.props.history} />
          <Col className="d-flex justify-content-between align-items-center mb-4">
            <Header defaultValue={this.getDefaultTab()} history={this.props.history} />
            {!generic && (
              <FPMenuBox
                title={newsLetter ? "Create NewsLetter" : "Create Template"}
                options={templatesList}
                size={"small"}
                startIcon={<AddOutlinedIcon style={{ width: 16, height: 16 }} />}
                hideDropDownArrowIcon={true}
                className={"fp-select-as-small-btn fp-size-12"}
              />
            )}
            {generic && (
              <FPButton
                startIcon={<AddOutlinedIcon style={{ width: 16, height: 16 }} />}
                label="Create Generic Template"
                onClick={() => {
                  switchToAddTemplate();
                }}
                backgroundColor="#1A2544"
                size="small"
                fontSize="12px"
              />
            )}
          </Col>
        </div>
        <Col sm="12">
          <CardWrapper
            contentClass="p-3"
            headerTitle={
              <div className="d-flex justify-content-between align-items-center">
                <span>
                  {newsLetter ? "Manage NewsLetter" : generic ? "Manage Generic Templates" : "Manage Templates"}
                </span>
                <SearchComponent {...this.props} placeholder="Search Template" className="common-search" startIcon />
              </div>
            }
          >
            <div className="ui-table-responsive">
              <Table className="fp-table fp-table-center">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        id="all"
                        checked={
                          selectedLists &&
                          list &&
                          list.results.every((obj) => selectedLists.some((id) => obj.id === id))
                        }
                        onChange={this.handleCheckbox}
                      >
                        Select All
                      </Checkbox>
                    </TableCell>
                    <TableCell className="width-md-th tableHeaderHover" style={{ textAlign: "justify", minWidth: "140px"}}>
                      Template Name
                    </TableCell>
                    <TableCell className="tableHeaderHover">Type</TableCell>
                    {generic && (
                      <TableCell
                        className="tableHeaderHover"
                        // style={{ minWidth: "100px" }}
                      >
                        Sub Type
                      </TableCell>
                    )}
                    {generic && (
                      <TableCell className="tableHeaderHover" style={{ minWidth: "140px" }}>
                        Review Gating <SortIcon />
                      </TableCell>
                    )}
                    {generic && (
                      <TableCell className="tableHeaderHover" style={{minWidth:"100px"}} >
                        Survey <SortIcon />
                      </TableCell>
                    )}
                    {generic && (
                      <TableCell
                        className="width-md-th tableHeaderHover"
                        // style={{ textAlign: "justify" }}
                      >
                        Branch
                      </TableCell>
                    )}
                    {generic && <TableCell className="tableHeaderHover" style={{minWidth:"100px"}}>QR Code</TableCell>}
                    <TableCell className="tableHeaderHover">Created By</TableCell>
                    {!generic && <TableCell className="tableHeaderHover">Method</TableCell>}
                    <TableCell
                      className={`tableHeaderHover ${ordering === "created_at" ? "sortCellStyleHeader" : ""}`}
                      sorted={sort && sort.on === "created_at" ? sort.by : null}
                      onClick={() => {
                        sortFunction("created_at");
                        this.toggleCreatedOnClick();
                      }}
                      style={{ minWidth: "123px" }}
                    >
                      Created On <SortIcon />
                      {/* {sort.on !== 'created_at' && <Icon name='sort' />} */}
                    </TableCell>
                    <TableCell className="tableHeaderHover">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list &&
                    list.results.map((item, index) => {
                      return (
                        <TableRow key={index} active={item.is_default}>
                          <TableCell>
                            <Checkbox
                              id={item.id}
                              checked={selectedLists && selectedLists.indexOf(item.id) > -1}
                              onChange={this.handleCheckbox}
                            />
                          </TableCell>
                          <TableCell className="width-md" onClick={() => this.editCampaign(item)}>
                            <FPToolTip title={item.name} className="templateName">
                              <div
                                className=""
                                style={{
                                  width: "120px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textAlign: "justify",
                                }}
                              >
                                {item.name}
                              </div>
                            </FPToolTip>
                          </TableCell>
                          <TableCell style={{minWidth:"100px"}}>{item.type}</TableCell>
                            
                          {generic && <TableCell style={{minWidth:"150px"}} className="text-truncate"><FPToolTip title={item.sub_type} >{item.sub_type}
                          </FPToolTip></TableCell>}
                          {generic && (
                            <TableCell>
                              <FPChip
                                label={
                                  <span className={item.review_gating ? "font-green" : "font-red"}>
                                    {item.review_gating ? "Active" : "InActive"}
                                  </span>
                                }
                                style={{
                                  border: item.review_gating ? "1px solid #57e32c" : "1px solid #ff4545",
                                  backgroundColor: item.review_gating ? "#F3FEE7" : "#FEF3F2",
                                }}
                              />
                            </TableCell>
                          )}
                          {generic && (
                            <TableCell>
                              <FPChip
                                label={
                                  <span className={item.survey_section ? "font-green" : "font-red"}>
                                    {item.survey_section ? "Active" : "InActive"}
                                  </span>
                                }
                                style={{
                                  border: item.survey_section ? "1px solid #57e32c" : "1px solid #ff4545",
                                  backgroundColor: item.survey_section ? "#F3FEE7" : "#FEF3F2",
                                }}
                              />
                            </TableCell>
                          )}
                          {generic && (
                            <TableCell className=" width-md">
                              <FPToolTip title={item.location_address}>
                                <div
                                  style={{
                                    width: "120px",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                  title={item.location_address}
                                >
                                  {item.location_address}
                                </div>
                              </FPToolTip>
                            </TableCell>
                          )}
                          {generic && (
                            <TableCell>
                              <div className="flex-d" style={{ minWidth: "85px" }}>
                                <Button
                                  icon
                                  style={{
                                    fontSize: "10px",
                                    background: "none",
                                  }}
                                  compact
                                  onClick={() => {
                                    this.copyLink(item.link);
                                  }}
                                >
                                  <InsertLinkOutlinedIcon />
                                </Button>
                                <Button
                                  icon
                                  style={{
                                    fontSize: "10px",
                                    background: "none",
                                  }}
                                  compact
                                  onClick={(e) => {
                                    this.handleQrModalOpen(e, item);
                                  }}
                                >
                                  <img src={qrIcon} style={{ marginLeft: 10 }}></img>
                                </Button>
                              </div>
                              <Popover
                                open={open}
                                anchorEl={this.state.qrModal}
                                onClose={this.handleQrModalClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                className="popup"
                              >
                                <div
                                  style={{
                                    backgroundColor: "white",
                                    padding: 10,
                                    textAlign: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      color: "#1B1C1D",
                                    }}
                                    className="mb-1"
                                  >
                                    {downloadQRCode.name}
                                  </p>
                                  <FPToolTip title={downloadQRCode.location_address}>
                                    <p
                                      style={{
                                        width: "230px",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        fontSize: "10px",
                                        fontWeight: "400",
                                        color: "#646565",
                                        marginBottom: "4px",
                                      }}
                                      title={downloadQRCode.location_address}
                                    >
                                      {downloadQRCode.location_address}
                                    </p>
                                  </FPToolTip>
                                  {/* <p style={{ fontSize:'10px',fontWeight:'400',color:'#646565' }} className='mb-1'>i{item.location_address}</p> */}
                                  <div
                                    style={{
                                      padding: "10px",
                                      borderRadius: "4px",
                                      border: "1px solid #BFC2CB",
                                    }}
                                  >
                                    <QRCode
                                      id={`generatedQrCode${downloadQRCode.id}`}
                                      value={downloadQRCode.return_link ? downloadQRCode.return_link : ""}
                                      size={200}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-center gap-10 mt-2">
                                    {/* // * Download btn */}
                                    <FPButton
                                      size="small"
                                      variant="outline"
                                      startIcon={<GetAppOutlinedIcon />}
                                      label="Download"
                                      onClick={() => {
                                        this.downloadQR(downloadQRCode);
                                      }}
                                    />
                                    {/* // * Copy btn */}
                                    <FPButton
                                      size="small"
                                      variant="outline"
                                      startIcon={<LinkIcon />}
                                      label="Copy Link"
                                      onClick={() => {
                                        this.copyLink(downloadQRCode.link);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Popover>
                            </TableCell>
                          )}
                          <TableCell>
                            <FPToolTip title={item.created_by}>
                              <span className="d-inline-block text-truncate width-sm">{item.created_by}</span>
                            </FPToolTip>
                          </TableCell>
                          {!generic && (
                            <TableCell>
                              {item.template_type === "email_template"
                                ? "Email"
                                : item.template_type === "whatsapp_template"
                                ? "WhatsApp"
                                : "SMS"}
                            </TableCell>
                          )}
                          <TableCell className={`tableHeaderHover ${ordering === "created_at" ? "sortCellStyle" : ""}`}>
                            {moment(item.created_at).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {!generic && (
                              <React.Fragment>
                                <div className="d-flex justify-content-center">
                                <FPToolTip title={"Clone"}>
                                  <img
                                    src={FileCopyIcon}
                                    onClick={() => cloneTemplate(item)}
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "10px",
                                    }}
                                  />
                                </FPToolTip>

                                {!item.is_default && (
                                  <FPToolTip title={"Delete"}>
                                    <img
                                      src={DeleteIcon}
                                      onClick={() => deleteTemplate(item)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </FPToolTip>
                                )}
                                </div>
                              </React.Fragment>
                            )}
                            {generic && (
                              <React.Fragment>
                                {showClone && (
                                  <FPToolTip title={"Clone"}>
                                    <img
                                      src={FileCopyIcon}
                                      onClick={() => cloneTemplate(item)}
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "10px",
                                      }}
                                    />
                                  </FPToolTip>
                                )}

                                {!item.is_default && (
                                  <FPToolTip title={"Delete"}>
                                    <img
                                      src={DeleteIcon}
                                      onClick={() => deleteTemplate(item)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </FPToolTip>
                                )}
                              </React.Fragment>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {list && !list.results.length && (
                    <TableRow>
                      <TableCell className="text-center" colSpan={generic ? 8 : 6}>
                        No records Found{" "}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <PaginationComponent count={list && list.count} location={location} pageSizeCookie={pageSizeCookie} />
          </CardWrapper>
        </Col>
      </Row>
    );
  }
}

export default TemplateList;
