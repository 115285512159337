import React, { useState } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { ListDescription } from "semantic-ui-react";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import "./listing.css";
import AddIcon from "@material-ui/icons/Add";
import google_svg from "../../../images/googlephoto.svg";
import select_svg from "../../../images/select.svg";
import { formatReviews } from "../../../libs/helper";
import duplicate_svg from "../../../images/dusplicate.svg";
import { Chip } from "@mui/material";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import delete_png from "../../../images/delete_png.webp";
import { newWeekDays } from "../../../libs/constant";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getGmbListingUpdateListing } from "../../../actions/listing/listingLocationAction";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import * as listingUtils from "../../../libs/listingToast";

const UpdateMorehoureTag = ({
  primaryDetail,
  business_id,
  selectReso,
  getGmbListingUpdateListing,
  actions,
}) => {
  const addressString = [
    primaryDetail?.addressLines,
    primaryDetail?.locality,
    primaryDetail?.postalCode,
  ].join(", ");

  const [selectedHourtag, setSelectedHourtag] = useState([]);
  const [selectedHourtagPeriods, setSelectedHourtagPeriods] = useState(null);
  console.log("selectedHourtagPeriodsselectedHourtagPeriodsselectedHourtagPeriods",selectedHourtagPeriods);
  

  // React.useEffect(() => {
  //   if (primaryDetail?.category_name?.moreHoursTypes) {
  //     setSelectedHourtag(primaryDetail?.category_name?.moreHoursTypes);
  //     // moreHours is an array of objects and hoursTypeId is the key to compare
  //     // and set the selected property to true
  //     const updatedItems = [...primaryDetail?.category_name?.moreHoursTypes];
  //     if (primaryDetail?.moreHours?.length > 0) {
  //       primaryDetail?.moreHours?.forEach((selectedTag) => {
  //         updatedItems?.forEach((moreTypeTag) => {
  //           if (moreTypeTag?.hoursTypeId === selectedTag?.hoursTypeId) {
  //             moreTypeTag.selected = true;
  //             moreTypeTag.periods = newWeekDays.map((day) => ({
  //               ...day,
  //               openTime: [...day.openTime],
  //               closeTime: [...day.closeTime],
  //               time: [...day.time],
  //             }));
  //             moreTypeTag?.periods?.forEach((day) => {
  //               selectedTag?.periods?.forEach((period) => {
  //                 if (
  //                   day.day === period.openDay ||
  //                   day.day === period.closeDay
  //                 ) {
  //                   day.type = "open";
  //                   day.openTime.push(period.openTime);
  //                   day.closeTime.push(period.closeTime);
  //                   day.time.push({
  //                     openTime: period.openTime,
  //                     closeTime: period.closeTime,
  //                   });
  //                 }
  //               });
  //             });
  //           } else {
  //             moreTypeTag.selected = false;
  //             moreTypeTag.periods = newWeekDays;
  //           }
  //         });
  //       });
  //     } else {
  //       updatedItems?.forEach((moreTypeTag, index) => {
  //         if (index === 0) {
  //           moreTypeTag.selected = true;
  //         }
  //         moreTypeTag.selected = false;
  //         moreTypeTag.periods = newWeekDays.map((day) => ({
  //           ...day,
  //           openTime: [...day.openTime],
  //           closeTime: [...day.closeTime],
  //           time: [...day.time],
  //         }));
  //       });
  //     }
  //     setSelectedHourtagPeriods(updatedItems?.find((item) => item.selected));
  //     setSelectedHourtag(updatedItems);
  //   } else {
  //     setSelectedHourtag([]);
  //   }
  // }, [primaryDetail?.category_name?.moreHoursTypes]);

  React.useEffect(() => {
    if (primaryDetail?.category_name?.moreHoursTypes) {
      setSelectedHourtag(primaryDetail?.category_name?.moreHoursTypes);

      const updatedItems = [...primaryDetail?.category_name?.moreHoursTypes];
      if (primaryDetail?.moreHours?.length > 0) {
        updatedItems.forEach((moreTypeTag) => {
          const selectedTag = primaryDetail.moreHours.find(
            (tag) => tag.hoursTypeId === moreTypeTag.hoursTypeId
          );
          if (selectedTag) {
            moreTypeTag.selected = true;
            moreTypeTag.periods = newWeekDays.map((day) => ({
              ...day,
              openTime: [...day.openTime],
              closeTime: [...day.closeTime],
              time: [...day.time],
            }));
            moreTypeTag.periods.forEach((day) => {
              selectedTag.periods.forEach((period) => {
                if (day.day === period.openDay || day.day === period.closeDay) {
                  day.type = "open";
                  day.openTime.push(period.openTime);
                  day.closeTime.push(period.closeTime);
                  day.time.push({
                    openTime: period.openTime,
                    closeTime: period.closeTime,
                  });
                }
              });
            });
          } else {
            moreTypeTag.selected = false;
            moreTypeTag.periods = newWeekDays.map((day) => ({
              ...day,
              openTime: [...day.openTime],
              closeTime: [...day.closeTime],
              time: [...day.time],
            }));
          }
        });
      } else {
        updatedItems.forEach((moreTypeTag) => {
          moreTypeTag.periods = newWeekDays.map((day) => ({
            ...day,
            openTime: [...day.openTime],
            closeTime: [...day.closeTime],
            time: [...day.time],
          }));
        });
      }

      setSelectedHourtagPeriods(
        updatedItems.find((item) => item.selected) || updatedItems[0]
      );
      setSelectedHourtag(updatedItems);
    } else {
      setSelectedHourtag([]);
    }
  }, [primaryDetail?.category_name?.moreHoursTypes]);

  const handleChipClickService = (tagData, index) => {
    const updatedItems = [...selectedHourtag];
    const tagIndex = updatedItems.findIndex(
      (item) => item.hoursTypeId === tagData.hoursTypeId
    );
    // console.log(updatedItems);
    // console.log(updatedItems[tagIndex], "updatedItems[tagIndex]");
    // updatedItems[tagIndex].selected = !updatedItems[tagIndex].selected;
    const moreTypeTag = updatedItems[tagIndex];
    // setSelectedHourtag(updatedItems);
    let selectedTagFind = null;
    if (primaryDetail?.moreHours?.length > 0) {
      selectedTagFind = primaryDetail?.moreHours?.find(
        (item) => item.hoursTypeId === moreTypeTag.hoursTypeId
      );
    }

    console.log(selectedTagFind, "selectedTagFind");
    console.log(updatedItems[tagIndex], "updatedItems[tagIndex]");
    // if (updatedItems[tagIndex].selected) {
    // primaryDetail?.moreHours?.length > 0 &&
    //   primaryDetail?.moreHours?.forEach((selectedTag) => {
    if (selectedTagFind) {
      // moreTypeTag.selected = true;
      moreTypeTag.periods = newWeekDays.map((day) => ({
        ...day,
        openTime: [...day.openTime],
        closeTime: [...day.closeTime],
        time: [...day.time],
      }));
      selectedTagFind?.periods?.forEach((period) => {
        moreTypeTag?.periods?.forEach((day) => {
          // console.log(day, "day");
          // console.log(period, "period");
          if (day.day === period.openDay || day.day === period.closeDay) {
            console.log(day, "day1111");
            console.log(period, "period11111");
            day.type = "open";
            day.openTime.push(period.openTime);
            day.closeTime.push(period.closeTime);
            day.time.push({
              openTime: period.openTime,
              closeTime: period.closeTime,
            });
          }
        });
      });
    } else {
      console.log(moreTypeTag, "moreTypeTag");
      // if moreTypeTag periods in particular day time array not emplay then selected true so that how to handle condition
      if (!moreTypeTag.selected) {
        moreTypeTag.periods = newWeekDays.map((day) => ({
          ...day,
          openTime: [...day.openTime],
          closeTime: [...day.closeTime],
          time: [...day.time],
        }));
      }
    }
    // });
    updatedItems[tagIndex] = moreTypeTag;
    setSelectedHourtag(updatedItems);
    setSelectedHourtagPeriods(updatedItems[tagIndex]);
    // } else {
    // const selectedHourtagPeriods = updatedItems.find((item) => item.selected);
    // console.log(selectedHourtagPeriods, "selectedHourtagPeriods leseeeee");
    // if (selectedHourtagPeriods) {
    //   setSelectedHourtagPeriods(selectedHourtagPeriods);
    // } else {
    // const updatedNewItems = [...primaryDetail?.category_name?.moreHoursTypes];
    // updatedNewItems?.forEach((moreTypeTag) => {
    //   primaryDetail?.moreHours?.length > 0 &&
    //     primaryDetail?.moreHours?.forEach((selectedTag) => {
    //       if (moreTypeTag?.hoursTypeId === selectedTag?.hoursTypeId) {
    //         moreTypeTag.selected = true;
    //       }
    //     });
    // });
    // setSelectedHourtag(updatedItems);
    // setSelectedHourtagPeriods(updatedItems?.find((item) => item.selected));
    // }
    // }
  };

  const handleAddClick = (period, timeValue, index) => {
    const updatedItems = [...selectedHourtag];
    const dayIndex = updatedItems.findIndex(
      (hour) => hour.hoursTypeId === selectedHourtagPeriods.hoursTypeId
    );
    if (dayIndex === -1) return;

    const periodIndex = updatedItems[dayIndex].periods.findIndex(
      (item) => item.day === period.day
    );
    if (periodIndex === -1) return;
    updatedItems[dayIndex].periods[periodIndex].time.push({
      openTime: { hours: "10", minutes: "00" },
      closeTime: { hours: "19", minutes: "00" },
    });
    setSelectedHourtag(updatedItems);
  };
  const handleDeleteClick = (period, timeValue, index) => {
    const updatedItems = [...selectedHourtag];
    const dayIndex = updatedItems.findIndex(
      (hour) => hour.hoursTypeId === selectedHourtagPeriods.hoursTypeId
    );
    if (dayIndex === -1) return;
    const periodIndex = updatedItems[dayIndex].periods.findIndex(
      (item) => item.day === period.day
    );
    if (periodIndex === -1) return;
    updatedItems[dayIndex].periods[periodIndex].time.splice(index, 1);
    setSelectedHourtag(updatedItems);
  };

  const handleOpenTimeChange = (period, type, newValue, timeValue, index) => {
    const hours = dayjs(newValue).hour();
    const minutes = dayjs(newValue).minute();
    const updatedItems = [...selectedHourtag];
    const dayIndex = updatedItems.findIndex(
      (hour) => hour.hoursTypeId === selectedHourtagPeriods.hoursTypeId
    );
    if (dayIndex === -1) return;
    const periodIndex = updatedItems[dayIndex].periods.findIndex(
      (item) => item.day === period.day
    );
    if (periodIndex === -1) return;
    updatedItems[dayIndex].periods[periodIndex].time[index][type]["hours"] =
      hours;
    updatedItems[dayIndex].periods[periodIndex].time[index][type]["minutes"] =
      minutes;
    setSelectedHourtag(updatedItems);
  };
  const handleCloseTimeChange = (period, type, newValue, timeValue, index) => {
    const hours = dayjs(newValue).hour();
    const minutes = dayjs(newValue).minute();
    const updatedItems = [...selectedHourtag];
    const dayIndex = updatedItems.findIndex(
      (hour) => hour.hoursTypeId === selectedHourtagPeriods.hoursTypeId
    );
    if (dayIndex === -1) return;
    const periodIndex = updatedItems[dayIndex].periods.findIndex(
      (item) => item.day === period.day
    );
    if (periodIndex === -1) return;
    updatedItems[dayIndex].periods[periodIndex].time[index][type]["hours"] =
      hours;
    updatedItems[dayIndex].periods[periodIndex].time[index][type]["minutes"] =
      minutes;
    setSelectedHourtag(updatedItems);
  };

  // Function to check if a specific day is included in the opening hours
  function isDayIncluded(hours, day) {
    return hours.some((hour) => hour.openDay === day && hour.closeDay === day);
  }

  const handleSelectPeriod = (e, period, periodsDay) => {
    const { checked } = e.target;
    const updatedItems = [...selectedHourtag];
    const dayIndex = updatedItems.findIndex(
      (hour) => hour.hoursTypeId === selectedHourtagPeriods.hoursTypeId
    );
    if (dayIndex === -1) return;
    const periodIndex = updatedItems[dayIndex].periods.findIndex(
      (item) => item.day === period.day
    );
    if (periodIndex === -1) return;
    if (!checked) {
      const selectedBranchItem =
        primaryDetail?.moreHours?.length > 0
          ? primaryDetail?.moreHours?.find(
              (item) => item.hoursTypeId === selectedHourtagPeriods.hoursTypeId
            )
          : null;
      if (
        selectedBranchItem &&
        isDayIncluded(selectedBranchItem?.periods, period?.day)
      ) {
        updatedItems[dayIndex]["selected"] = true;
        updatedItems[dayIndex].periods.forEach((item) => {
          selectedBranchItem?.periods.forEach((period) => {
            
            // if (item.day === period.openDay && item.day === period.closeDay) {
              if(period.openDay === periodsDay && period.closeDay === periodsDay && item.day === periodsDay){
              item.type = "open";
              item.openTime.push(period.openTime);
              item.closeTime.push(period.closeTime);
              item.time.push({
                openTime: period.openTime,
                closeTime: period.closeTime,
              });
            }
          });
        });
      } else {
        updatedItems[dayIndex]["selected"] = true;
        updatedItems[dayIndex].periods[periodIndex].type = "open";
        updatedItems[dayIndex].periods[periodIndex].time.push({
          openTime: { hours: "10", minutes: "00" },
          closeTime: { hours: "17", minutes: "00" },
        });
      }
    } else {
      updatedItems[dayIndex]["selected"] = false;
      updatedItems[dayIndex].periods[periodIndex].type = "closed";
      updatedItems[dayIndex].periods[periodIndex].time = [];
    }
    setSelectedHourtag(updatedItems);
  };

  const transformData = (input) => {
    const result = {
      type: "moreHours",
      moreHours: [],
    };

    input?.forEach((hourType) => {
      const newHourType = {
        hoursTypeId: hourType.hoursTypeId,
        periods: [],
      };

      hourType.periods.forEach((period) => {
        if (period.type === "open") {
          period.time.forEach((timePeriod) => {
            const newPeriod = {
              openDay: period.day,
              openTime: {
                hours: parseInt(timePeriod.openTime.hours),
                minutes: parseInt(timePeriod.openTime.minutes) || 0,
                seconds: 0,
                nanos: 0,
              },
              closeDay: period.day,
              closeTime: {
                hours: parseInt(timePeriod.closeTime.hours),
                minutes: parseInt(timePeriod.closeTime.minutes) || 0,
                seconds: 0,
                nanos: 0,
              },
            };
            newHourType.periods.push(newPeriod);
          });
        }
      });

      result.moreHours.push(newHourType);
    });

    return result;
  };

  const handleMoreHoursTagSave = async () => {
    const data = transformData(selectedHourtag);
    // You can add further logic to handle the selected chip values
    const restodetail = { business_id, selectReso };
    await getGmbListingUpdateListing(data, restodetail);
    listingUtils?.displayMessage(
      "info",
      "Listing getting updated, It may take a few minutes."
    );
    await actions.getGmbListingRefreshListing(business_id, selectReso);

    listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    listingUtils?.displayMessage("positive", "Listing updated successfully.");
    await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };
  console.log(selectedHourtag, "selectedHourtag");
  console.log(primaryDetail?.moreHours, "primaryDetail?.moreHours");
  return (
    <>
      <div
        className="listing-container"
        style={{ overflow: "hidden", gap: "15px" }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <div className="listing-header">
            <div className="listing-info">
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="listing-name">{primaryDetail?.title}</div>
                <img
                  src={
                    primaryDetail?.is_varified
                      ? verified_svg
                      : primaryDetail?.is_suspend
                      ? duplicate_svg
                      : primaryDetail?.is_duplicate
                      ? duplicate_svg
                      : ""
                  }
                  alt="verifiedImage"
                />
              </div>
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(2)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>
                  {formatReviews(primaryDetail?.totalReviewCount)} Reviews
                </span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>{addressString}</ListDescription>
          </div>
        </div>
      </div>
      <div
        className="listing-container"
        style={{ display: "grid", gap: "24px" }}
      >
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <div className="listing-name">
                Add more hours for accurate service/department hours
              </div>
              <div
                style={{
                  padding: "10px",
                  backgroundColor: "#F6F6F8",
                  borderRadius: "8px",
                }}
              >
                <div style={{ display: "grid", gap: "10px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Add Hour Tags
                  </div>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#646565",
                    }}
                  >
                    Select the service you offer to add time slots
                  </div>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "6px" }}
                  >
                    {selectedHourtag.map((item, index) => (
                      <Chip
                        key={item.displayName}
                        label={item.displayName}
                        onClick={() => handleChipClickService(item, index)}
                        deleteIcon={
                          item.selected ? (
                            <img src={select_svg} alt="selectImage" />
                          ) : null
                        }
                        onDelete={
                          item.selected
                            ? () => handleChipClickService(item, index)
                            : null
                        }
                        variant={item.selected ? "filled" : "outlined"}
                        sx={{
                          backgroundColor: item.selected
                            ? "#bfe0e2"
                            : "#F0F1F3",
                          color: item.selected ? "#00b5ad" : "default",
                          "& .MuiChip-deleteIcon": {
                            color: item.selected ? "white" : "default",
                          },
                          fontSize: "12px",
                          fontWeight: "400",
                          padding: "6px",
                          border: "0",
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>

              {selectedHourtagPeriods && (
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        margin: "0",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      {selectedHourtagPeriods?.displayName}
                    </p>
                    <p style={{ margin: "0", color: "#7A8193" }}>
                      Add hours for a typical week
                    </p>
                  </div>
                  {selectedHourtagPeriods?.periods?.map((period, pindex) => (
                    <div
                      key={pindex}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          fontWeight: "400",
                          fontSize: "18px",
                          color: "#1B1C1D",
                        }}
                      >
                        <span style={{ color: "#7A8193" }}>{period.label}</span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={period.type === "closed"}
                            onClick={(e) => {
                              handleSelectPeriod(e, period, period.day);
                            }}
                          />
                          <span>{period.type}</span>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        {period?.time?.map((timeValue, Timeindex) => {
                          const savedOpenTime = dayjs()
                            .hour(timeValue?.openTime?.hours || 12)
                            .minute(timeValue?.openTime?.minutes || 0);
                          const savedCloseTime = dayjs()
                            .hour(timeValue?.closeTime?.hours || 12)
                            .minute(timeValue?.closeTime?.minutes || 0);
                          return (
                            <div
                              key={`${timeValue}${Timeindex}`}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <div style={{ width: "100px" }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <MobileTimePicker
                                    ampm={false}
                                    onChange={(newValue) =>
                                      handleOpenTimeChange(
                                        period,
                                        "openTime",
                                        newValue,
                                        timeValue,
                                        Timeindex
                                      )
                                    }
                                    value={savedOpenTime}
                                    views={["hours", "minutes"]}
                                    format="HH:mm"
                                    slotProps={{ textField: { size: "small" } }}
                                  />
                                </LocalizationProvider>
                              </div>
                              <div>-</div>
                              <div style={{ width: "100px" }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <MobileTimePicker
                                    ampm={false}
                                    onChange={(newValue) =>
                                      handleCloseTimeChange(
                                        period,
                                        "closeTime",
                                        newValue,
                                        timeValue,
                                        Timeindex
                                      )
                                    }
                                    value={savedCloseTime}
                                    views={["hours", "minutes"]}
                                    format="HH:mm"
                                    slotProps={{ textField: { size: "small" } }}
                                  />
                                </LocalizationProvider>
                              </div>
                              {Timeindex === period?.time?.length - 1 ? (
                                <AddIcon
                                  style={{
                                    color: "#667085",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleAddClick(period, timeValue, Timeindex)
                                  }
                                />
                              ) : (
                                <img
                                  src={delete_png}
                                  alt="deletePng"
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleDeleteClick(
                                      period,
                                      timeValue,
                                      Timeindex
                                    )
                                  }
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "10px",
              }}
            >
              <FPButton
                label="Update"
                size="small"
                disabled={primaryDetail?.is_suspend}
                onClick={handleMoreHoursTagSave}
              />
            </div>
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

// export default UpdateMorehoureTag;
const mapDispatchToProps = (dispatch) => ({
  getGmbListingUpdateListing: bindActionCreators(
    getGmbListingUpdateListing,
    dispatch
  ),
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(UpdateMorehoureTag);
