import React from "react";

const PhotosMenu = () => {
    return (
        <>
            <h1 style={{height: "calc(100vh - 308px)",display: "flex", alignItems: "center",justifyContent: "center"}}>Photos Menu Page</h1>
        </>
    )
}

export default PhotosMenu