import React from "react";
import { Row, Col } from "reactstrap";
import BookingIcon from "../../images/booking-logo.png";
import Img1 from "../../images/svg/userSocial.svg";
import Img2 from "../../images/svg/doneSocial.svg";
import Img3 from "../../images/svg/fileSocial.svg";

class SetupBKNGSocialAccounts extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.branch_id !== prevState.branch_id) {
      return { branch_id: nextProps.branch_id };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.responseBooking = this.responseBooking.bind(this);
    this.state = {
      branch_id: this.props.branch_id,
    };
  }

  componentDidMount() {
    const { checkLoggedIn } = this.props;
    checkLoggedIn("booking");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.branch_id !== this.state.branch_id) {
      const { checkLoggedIn } = this.props;
      checkLoggedIn("booking");
    }
  }

  responseBooking() {
    const { isLoggedIn, logOut, getAuthUrl } = this.props;
    if (isLoggedIn && isLoggedIn.connected) {
      return logOut("booking");
    }
    return getAuthUrl("booking");
  }

  render() {
    const { isLoggedIn } = this.props;
    return (
      <div>
        <Row>
          <Col>
            <div className="text-center">
              <img src={BookingIcon} height="40" />
              <h5 className="mt-2 font-weight-normal">Connect your Booking Pages</h5>
            </div>
            <Row className="my-5 py-4">
              <Col className="text-center">
                <img src={Img1} height="70" />
                <p className="mt-3 px-3">Manage all your Booking reviews from your account.</p>
              </Col>
              <Col className="text-center">
                <img src={Img2} height="70" />
                <p className="mt-3 px-3">Share positive reviews to your Booking Pages</p>
              </Col>
              <Col className="text-center">
                <img src={Img3} height="70" />
                <p className="mt-3 px-3">Publish content and engage with your Booking followers</p>
              </Col>
            </Row>
            <div className="text-center mt-4 mb-3">
              <span
                className="text-center mt-3 button booking-button cursor-pointer"
                onClick={() => this.responseBooking()}
              >
                {isLoggedIn && isLoggedIn.connected ? "Log out from Booking" : "Login with Booking"}
              </span>
              {isLoggedIn && isLoggedIn.connected && isLoggedIn.name ? (
                <p className="mt-2">
                  Logged in with this email id: <strong>{isLoggedIn.name}</strong>
                </p>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SetupBKNGSocialAccounts;
