import React from "react";
import { Doughnut } from "react-chartjs-2";

const HyperLocalRanking = () => {
  const data = {
    datasets: [
      {
        data: [1, 5, 20, 0], // Values for the categories
        backgroundColor: ["#00C853", "#2979FF", "#FF3D00", "#AA00FF"], // Colors
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: "60%", // Adjust inner radius
    plugins: {
      legend: { display: false }, // Hide default legend
      tooltip: { enabled: true }, // Enable tooltips
    },
  };

  // Custom legend data
  const categories = [
    { name: "Branded", value: 1, color: "#00C853" },
    { name: "Navigational", value: 5, color: "#2979FF" },
    { name: "Transactional", value: 20, color: "#FF3D00" },
    { name: "Informational", value: 0, color: "#AA00FF" },
  ];

  const visibilityData = {
    datasets: [
      {
        data: [32, 68], // Score: 32% and Remaining: 68%
        backgroundColor: ["#8E24AA", "#EDEDED"], // Purple and Light Gray
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div
      className="row"
      style={{
        marginLeft: "0px",
        marginRight: "0px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <div
        
        style={{
          flex:"0 0 25%",
          border: "1px solid #ddd",
          borderRadius: "10px",
          padding: "15px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          display: "grid",
          alignItems: "center",
          minWidth: "310px",
        }}
      >
        <h4 style={{ margin: "0 0 10px", fontWeight: "bold" }}>
          Keywords Tracked{" "}
          <span style={{ color: "#aaa", fontSize: "14px" }}>
            in 2 Categories
          </span>
        </h4>
        <div style={{ display: "flex" }}>
          {/* Doughnut Chart */}
          <div style={{ width: "120px", height: "120px", marginRight: "20px" }}>
            <Doughnut data={data} options={options} />
          </div>

          {/* Custom Legend */}
          <div>
            {categories.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "4px",
                }}
              >
                <span
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: item.color,
                    borderRadius: "50%",
                    marginRight: "8px",
                  }}
                ></span>
                <span style={{ color: "#333", fontSize: "14px" }}>
                  {item.name} - {item.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div
        style={{
          flex:"0 0 25%",
          border: "1px solid #ddd",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          minWidth: "310px",
        }}
      >
        <h4 style={{ margin: "0 0 10px", fontWeight: "bold" }}>
          Average Rank{" "}
          <span style={{ color: "#aaa", fontSize: "14px" }}>
            for 20 Keywords
          </span>
        </h4>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div
              style={{ display: "flex", alignItems: "baseline", gap: "10px" }}
            >
              <p
                style={{
                  fontSize: "36px",
                  margin: "10px 0",
                  fontWeight: "bold",
                }}
              >
                14.3{" "}
              </p>
              <span style={{ color: "#00C853", fontSize: "16px" }}>+0.3%</span>
            </div>

            <p style={{ color: "#777", fontSize: "14px" }}>in Last 4 days</p>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p style={{ color: "#aaa", margin: "0" }}>Improved</p>
              <strong style={{ color: "#00C853", margin: "0" }}>
                +7 Keywords
              </strong>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <p style={{ color: "#aaa", margin: "0" }}>Decreased</p>
              <strong style={{ color: "#FF3D00", margin: "0" }}>
                -0 Keywords
              </strong>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          flex:"0 0 25%",
          border: "1px solid #ddd",
          borderRadius: "10px",
          padding: "20px",
          minWidth: "310px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h4 style={{ margin: "0 0 10px", fontWeight: "bold" }}>
          Visibility Score
        </h4>
        <div
          style={{
            // width: "100px",
            // height: "100px",
            display: "flex",
            margin: "0 auto 10px",
            alignItems:"center",
            gap:"10px"
          }}
        >
          <div style={{ width: "100px" }}>
            <Doughnut data={visibilityData} options={options} />
          </div>
          <div style={{ display: "flex", flexDirection:"column" }}>
            <h2
              style={{
                fontSize: "30px",
                fontWeight: "bold",
              }}
            >
              32%{" "}
              <span style={{ color: "#aaa", margin: "0" ,fontSize: "14px"}}>0%</span>
            </h2>
            <p style={{ textAlign: "center", color: "#777", fontSize: "14px" }}>
              Updated On : May 08
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HyperLocalRanking;
