import React from "react";
import AuthLayout from "./AuthLayout";
import LogoIcon from "../../images/Checkmark--filled.svg";

const RegisterSuccessfulPage = () => {
  return (
    <AuthLayout
      logoIcon={LogoIcon}
      title={"Registration Successful!"}
      textMessage={
        "You have successfully completed the registration procedure. Please your e-mail to confirm the registration."
      }
      linkText={"Go back to login"}
    />
  );
};

export default RegisterSuccessfulPage;
