/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { ListItemText, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import FPMenu from "../FPMenu/FPMenu";
import FPMenuItem from "../FPMenu/FPMenuItem";
import CheckIcon from "@mui/icons-material/Check";

/*
Options Type : 
{label, onClick, value?, }
*/
const useStyles = makeStyles((theme) => ({
  menuBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 15,
    borderRadius: ({ rounded }) => (rounded ? "50px" : "8px"),
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #BFC2CB",
    fontSize: "12px",
    width: "100%",
    padding: ({ size }) => (size === "small" ? "0 18px" : "0 12px"),
    height: ({ size }) => (size === "small" ? 32 : 38),
  },
  transparent: {
    backgroundColor: "transparent",
    border: "none",
    padding: ({ size }) => (size === "small" ? "0 8px" : "0 12px"),
  },
}));

export default function FPMenuBox({
  title,
  options,
  className,
  children,
  startIcon,
  size,
  menuWrapperWidth,
  rounded,
  hideDropDownArrowIcon = false,
  renderToAboveList,
  disabled,
  dropDownArrowMargin,
  showSelected,
  isLeft,
  showDivider = true,
  selectedValue,
  width,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuWidth, setMenuWidth] = useState(menuWrapperWidth || 0);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles({ size: size, rounded });

  const ref = useRef();

  useEffect(() => {
    setMenuWidth(`${ref.current.clientWidth || 0}px`);
  }, []);

  const handleItemChange = (option, value) => {
    option.onClick(value || ""), setAnchorEl(null);
  };

  return (
    <div>
      <div
        onClick={handleClick}
        className={classNames(classes.menuBox, className, {
          [classes.transparent]: className?.includes("transparent"),
        })}
        role="button"
        ref={ref}
        style={{ pointerEvents: disabled ? "none" : "auto", opacity: disabled ? 0.5 : 1 , width: width ? width : "100%",}}
      >
        <p className="mb-0 d-flex align-items-center gap-10">
          {startIcon && startIcon} <span>{title}</span>
        </p>
        {!hideDropDownArrowIcon &&
          (anchorEl ? (
            <KeyboardArrowUpIcon
              style={{
                marginLeft: `${dropDownArrowMargin ? dropDownArrowMargin : "10px"}`,
                marginRight: "-5px",
                opacity: dropDownArrowMargin ? 0.5 : 1,
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              style={{
                marginLeft: `${dropDownArrowMargin ? dropDownArrowMargin : "10px"}`,
                marginRight: "-5px",
                opacity: dropDownArrowMargin ? 0.5 : 1,
              }}
            />
          ))}
      </div>

      <FPMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        wrapperMinWidth={menuWidth}
        style={{ maxHeight: 296 }}
        isLeft={isLeft}
      >
        {renderToAboveList && renderToAboveList}
        {options &&
          options.map((option, index) => (
            <FPMenuItem
              key={`${index}-${option.label}-${option.value || ""}-fp-menu-box-item`}
              onClick={(value) => handleItemChange(option, value)}
              showDivider={showDivider ? index + 1 < options.length : false}
            >
              {option.startIcon && option.startIcon}
              <ListItemText primary={option.label} className={`${option.startIcon ? "px-2" : ""}`} />
              {showSelected && selectedValue === option.value && (
                <CheckIcon
                  fontSize="small"
                  style={{
                    color: "#F13A88",
                  }}
                />
              )}
            </FPMenuItem>
          ))}
        {children && children}
      </FPMenu>
    </div>
  );
}
