import React from "react";
import { Checkbox, FormControlLabel, Typography, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const CustomCheckbox = ({ label, checked, onChange, name }) => {
  return (
    <FormControlLabel
      control={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            padding:"0px 10px"
          }}
        >
          {/* Label before the checkbox */}
          <Typography sx={{ fontWeight: 400, }}>{label}</Typography>
          <Checkbox
            checked={checked}
            onChange={onChange}
            name={name}
            icon={<RadioButtonUncheckedIcon sx={{ color: "#e0e0e0" }} />}
            checkedIcon={<CheckCircleIcon sx={{ color: "#00BFA6" }} />}
          />
        </Box>
      }
      sx={{
        width: "100%",
        margin: 0,
       
      }}
    />
  );
};

export default CustomCheckbox;
