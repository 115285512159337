import React from "react";
import { Image } from "semantic-ui-react";
import photo_svg from "../../../../images/photo.svg";
import coverphoto_svg from "../../../../images/coverphoto.svg";
import video_svg from "../../../../images/video.svg";
import share_svg from "../../../../images/share.svg";
import { Card } from "reactstrap";
import { Box, Dialog, DialogActions, IconButton } from "@mui/material";
import UpdatePhotos from "./Models/UpdatePhotos";
import UpdateWebsiteLink from "./Models/UpdateWebsiteLink";
import CloseIcon from "@material-ui/icons/Close";
import UpdateSocialMedia from "./Models/UpdateSocialMedia";
import FormModal from "../../../../components/common/FormModal";

const SociallinkUpdate = (props) => {
  const { business_id, locationList, queryStringData, filterBusinessCount } = props;

  const [openTab, setOpenTab] = React.useState(null);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (dialog, scrollType) => {
    setOpenTab(dialog);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpenTab(null);
  };

  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <>
      <div className="listing-detail-card">
        <div className="d-flex align-items-baseline" style={{ padding: "16px 26px", gap: "5px" }}>
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#1A2544",
            }}
          >
            Logo, Photos, Videos & Social Links Update
          </h2>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#F13A88",
            }}
          >
            (for {filterBusinessCount} verified & connected listings)
          </h2>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "1rem",
            padding: " 0px 24px 24px 24px",
          }}
        >
          <Card
            className="detail-card-item detail-special-card"
            onClick={() => handleClickOpen("storefront_photo", "paper")}
          >
            <div className="mb-2">
              <img src={photo_svg} height="28px" alt="" className="successImg" />
            </div>
            <h2 className="bulk-business-heading">Bulk Storefront Photos Upload</h2>
            <span className="bulk-business-card-review">Upload interior, exterior, & other photos</span>
          </Card>

          <Card
            className="detail-card-item detail-special-card"
            onClick={() => handleClickOpen("social_media", "paper")}
          >
            <div className="mb-2">
              <img src={share_svg} height="28px" alt="" className="successImg" />
            </div>
            <h2 className="bulk-business-heading">Bulk Social Links Update</h2>
            <span className="bulk-business-card-review">Add social links to your profile in bulk</span>
          </Card>

          <Card className="detail-card-item detail-special-card" onClick={() => handleClickOpen("photos", "paper")}>
            <div className="mb-2">
              <img src={coverphoto_svg} height="28px" alt="" className="successImg" />
            </div>
            <h2 className="bulk-business-heading">Logo & Cover Photo Update</h2>
            <span className="bulk-business-card-review">Add logo & cover photo in all listings in one go</span>
          </Card>

          <Card className="detail-card-item detail-special-card" onClick={() => handleClickOpen("videos", "paper")}>
            <div className="mb-2">
              <img src={video_svg} height="28px" alt="" className="successImg" />
            </div>
            <h2 className="bulk-business-heading">Videos & Reels Upload</h2>
            <span className="bulk-business-card-review">Upload videos & reels & engage your customers</span>
          </Card>
        </div>
      </div>
      <FormModal
          isOpen={openTab === "photos"}
          toggle={handleClose}
          heading="Update Photos"
          onClose={handleClose}
          maxWidth="lg"
          fullWidth
          paddingDense={false}
          showCloseBtn={true}
          bodyClass={"py-0"}
        >
          <UpdatePhotos
            busines_status={true}
            queryStringData={queryStringData}
            type="photo"
            business_id={business_id}
          />
        </FormModal>
      <FormModal
          isOpen={openTab === "videos"}
          toggle={handleClose}
          heading="Update Videos"
          onClose={handleClose}
          maxWidth="lg"
          fullWidth
          bodyClass={"py-0"}
          paddingDense={false}
          showCloseBtn={true}
        >
          <UpdatePhotos
            busines_status={true}
            queryStringData={queryStringData}
            type="video"
            business_id={business_id}
          />
        </FormModal>
      <FormModal
          isOpen={openTab === "storefront_photo"}
          toggle={handleClose}
          heading="Update Storefront Photo"
          onClose={handleClose}
          maxWidth="lg"
          fullWidth
          bodyClass={"py-0"}
          headerClass="mt-10"
          paddingDense={false}
          showCloseBtn={true}
        >
          <UpdatePhotos
            busines_status={true}
            queryStringData={queryStringData}
            type="store_front"
            business_id={business_id}
          />
        </FormModal>
       <FormModal
          isOpen={openTab === "social_media"}
          toggle={handleClose}
          heading="Update Social Media Link"
          onClose={handleClose}
          maxWidth="lg"
          fullWidth
          bodyClass={"py-0"}
          paddingDense={false}
          showCloseBtn={true}
        >
          <UpdateSocialMedia
            busines_status={true}
            queryStringData={queryStringData}
            defaultvalue={13}
            business_id={business_id}
          />
        </FormModal>
    </>
  );
};

export default SociallinkUpdate;
