import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as commonAction from "../common/commonAction";
import * as URLS from "../../libs/apiUrls";
import { hasTruthyValue } from "../../libs/utils";

export function getGmbLocationGeoGridRankerSuccess(data) {
  return { type: types.GET_GMB_LOCATION_GEO_GRID_RANKER_SUCCESS, data };
}

export function getGmbLocationGeoGridRankerFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LOCATION_GEO_GRID_RANKER_FAILURE, data };
}

export const getGmbLocationGeoGridRanker = (
  business_id,
  location_id,
  grid_size,
  distance,
  keyword,
  city,
  state,
  branch_tag
) => {
  console.log("location_idlocation_idlocation_idlocation_id", location_id);

  let url = `${
    URLS.GMB_LISTING_LOCATION
  }/${business_id}/${location_id}/geo-grid/?grid_size=${
    grid_size ? grid_size : ""
  }&distance=${distance ? distance : ""}&keyword=${
    keyword ? keyword : ""
  }&branch_tag=${branch_tag ? branch_tag : ""}&city=${city ? city : ""}&state=${
    state ? state : ""
  }`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationGeoGridRankerSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationGeoGridRankerFailure(err));
      });
  };
};

export function getGmbLocationTopKeywordSuccess(data) {
  return { type: types.GET_GMB_LOCATION_TOP_KEYWORD_SUCCESS, data };
}

export function getGmbLocationTopKeywordFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LOCATION_TOP_KEYWORD_FAILURE, data };
}

export const getGmbLocationTopKeyword = (location_id) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${location_id}/top-keywords/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationTopKeywordSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationTopKeywordFailure(err));
      });
  };
};

export function getGmbLocationScanHistorySuccess(data) {
  return { type: types.GET_GMB_LOCATION_SCAN_HISTORY_SUCCESS, data };
}

export function getGmbLocationScanHistoryFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LOCATION_SCAN_HISTORY_FAILURE, data };
}

export const getGmbLocationScanHistory = (
  business_id,
  location_id,
  page_size,
  page,
  city,
  branch_tag,
  state,
  date_type
) => {
  let url = `${
    URLS.GMB_LISTING_LOCATION
  }/${business_id}/${location_id}/gmb_scan_history/?page=${
    page ? page : 1
  }&page_size=${page_size ? page_size : 10}&date_type=${
    date_type ? date_type : ""
  }&branch_tag=${branch_tag ? branch_tag : ""}&city=${city ? city : ""}&state=${
    state ? state : ""
  }`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationScanHistorySuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationScanHistoryFailure(err));
      });
  };
};

export function scanHistorySuccess(data) {
  return { type: types.USE_SCAN_HISTORY, data };
}


export const useScanHistoryData = (data) => {
  console.log("kkkkkkkkkkkkkkkkkkk",data);
  
  return (dispatch) => {
    dispatch(scanHistorySuccess(data));
  };
};
