import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Typography,
  IconButton,
  Box,
  LinearProgress,
} from "@mui/material";
import { TbRefresh } from "react-icons/tb";
import { FaStar } from "react-icons/fa";
const data = [
  { name: "Keyword 1", value: 400 },
  { name: "Keyword 2", value: 300 },
  { name: "Keyword 3", value: 200 },
  { name: "Keyword 4", value: 150 },
];

const KeywordPositionTracking = () => {
  const [rank, setRank] = useState("");

  const handleSetRank = (value) => {
    setRank(value);
  };

  return (
    <>
      <div style={{ display: "grid", gap: "20px" }}>
        <div>
          <h4 style={{ margin: "0 0 10px", fontWeight: "bold" }}>
            Keywords Position Tracking{" "}
            <span style={{ color: "#aaa", fontSize: "14px" }}>
              in new Delhi
            </span>
          </h4>
          <span style={{ fontSize: "14px", fontWeight: "500" }}>
            Official Google Maps Results for 20 Keywords
          </span>
        </div>
        {/* <div style={}> */}
        <button
          style={{ backgroundColor: "#a9acaf", border: "0px", padding: "5px" }}
        >
          Add Or Edit Keywords
        </button>
        {/* </div> */}
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <span style={{ fontWeight: "500", fontSize: "14px" }}>Sort By:</span>
          {[
            "Show All",
            "Rank in Top 3",
            "Rank in Top 5",
            "Rank in Top 10",
          ]?.map((topKeyword) => (
            <div
              key={topKeyword}
              style={{
                border: "1px solid",
                borderColor: topKeyword === rank ? "#54b1bf" : "#757371",
                cursor: "pointer",
                color: topKeyword === rank ? "white" : "",
                backgroundColor: topKeyword === rank ? "#54b1bf" : "",
                padding: "5px",
                paddingInline: "15px",
                borderRadius: "15px",
                width: "fit-content",
                // backgroundColor: "#54b1bf",
              }}
              onClick={() => handleSetRank(topKeyword)}
            >
              <span
                style={{
                  color: topKeyword === rank ? "white" :"#757371",
                  fontWeight: "500",
                }}
              >
                {topKeyword}
              </span>
            </div>
          ))}
        </div>

        <div style={{ paddingBlock: "16px" }}>
          <div className="ui-table-responsive">
            <Table className="fp-table fp-table-center">
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "left" }}>
                    Top Keyword{" "}
                    <span style={{ color: "#aaa", fontSize: "14px" }}>
                      in new Delhi
                    </span>
                  </TableCell>
                  <TableCell>Intent</TableCell>
                  <TableCell>Search Volume</TableCell>
                  <TableCell>Rank</TableCell>
                  <TableCell>Change in Rank</TableCell>
                  <TableCell>KI%</TableCell>
                  <TableCell>Trends</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>menu at burger king</span>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <FaStar
                          style={{ fontSize: "20px", color: "#b9b9b3" }}
                        />
                        <TbRefresh
                          style={{ fontSize: "20px", color: "#b9b9b3" }}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "12px",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#f59310",
                          paddingInline: "4px",
                          borderRadius: "5px",
                        }}
                      >
                        T
                      </span>
                      <span
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#a0f184",
                          paddingInline: "4px",
                        }}
                      >
                        B
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>5400</TableCell>
                  <TableCell>20+</TableCell>
                  <TableCell>
                    <div>
                      <span style={{ margin: "0 0 10px", fontWeight: "bold" }}>
                        0{" "}
                        <span style={{ color: "#aaa", fontSize: "14px" }}>
                          no chnage
                        </span>
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "red",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      ></span>
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        84
                      </span>
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>fast food food</span>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <FaStar
                          style={{ fontSize: "20px", color: "#b9b9b3" }}
                        />
                        <TbRefresh
                          style={{ fontSize: "20px", color: "#b9b9b3" }}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "12px",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#f59310",
                          paddingInline: "4px",
                          borderRadius: "5px",
                        }}
                      >
                        T
                      </span>
                      <span
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#a0f184",
                          paddingInline: "4px",
                        }}
                      >
                        B
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>5400</TableCell>
                  <TableCell>20+</TableCell>
                  <TableCell>
                    <div>
                      <span style={{ margin: "0 0 10px", fontWeight: "bold" }}>
                        0{" "}
                        <span style={{ color: "#aaa", fontSize: "14px" }}>
                          no chnage
                        </span>
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "red",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      ></span>
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        84
                      </span>
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>fast food restaurant</span>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <FaStar
                          style={{ fontSize: "20px", color: "#b9b9b3" }}
                        />
                        <TbRefresh
                          style={{ fontSize: "20px", color: "#b9b9b3" }}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "12px",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#f59310",
                          paddingInline: "4px",
                          borderRadius: "5px",
                        }}
                      >
                        T
                      </span>
                      <span
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#a0f184",
                          paddingInline: "4px",
                        }}
                      >
                        B
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>5400</TableCell>
                  <TableCell>20+</TableCell>
                  <TableCell>
                    <div>
                      <span style={{ margin: "0 0 10px", fontWeight: "bold" }}>
                        0{" "}
                        <span style={{ color: "#aaa", fontSize: "14px" }}>
                          no chnage
                        </span>
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "red",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      ></span>
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        84
                      </span>
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>fastfoodnearme</span>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <FaStar
                          style={{ fontSize: "20px", color: "#b9b9b3" }}
                        />
                        <TbRefresh
                          style={{ fontSize: "20px", color: "#b9b9b3" }}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "12px",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#f59310",
                          paddingInline: "4px",
                          borderRadius: "5px",
                        }}
                      >
                        T
                      </span>
                      <span
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#a0f184",
                          paddingInline: "4px",
                        }}
                      >
                        B
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>5400</TableCell>
                  <TableCell>20+</TableCell>
                  <TableCell>
                    <div>
                      <span style={{ margin: "0 0 10px", fontWeight: "bold" }}>
                        0{" "}
                        <span style={{ color: "#aaa", fontSize: "14px" }}>
                          no chnage
                        </span>
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "red",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      ></span>
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        84
                      </span>
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>fast food near me</span>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <FaStar
                          style={{ fontSize: "20px", color: "#b9b9b3" }}
                        />
                        <TbRefresh
                          style={{ fontSize: "20px", color: "#b9b9b3" }}
                        />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "12px",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#f59310",
                          paddingInline: "4px",
                          borderRadius: "5px",
                        }}
                      >
                        T
                      </span>
                      <span
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#a0f184",
                          paddingInline: "4px",
                        }}
                      >
                        B
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>5400</TableCell>
                  <TableCell>20+</TableCell>
                  <TableCell>
                    <div>
                      <span style={{ margin: "0 0 10px", fontWeight: "bold" }}>
                        0{" "}
                        <span style={{ color: "#aaa", fontSize: "14px" }}>
                          no chnage
                        </span>
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "4px",
                      }}
                    >
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "red",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      ></span>
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        84
                      </span>
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeywordPositionTracking;
