import React, { useEffect, useState } from "react";
import SearchComponent from "../../common/SearchComponent";
import "./overview.css";
import { Button } from "semantic-ui-react";
import OverViewFilterlist from "./Filterlist";
import BusinessSummary from "./BusinessSummary";
import OverallHealth from "./OverallHealth";
import CompletionStatus from "./CompletionStatus";
import { truncatedNumber } from "../../../libs/helper";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import * as listingUtils from "../../../libs/listingToast";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import classNames from "classnames";
import { FPToolTip } from "../../common/new";
import { IconButton } from "@material-ui/core";
import { ReactComponent as ResetIcon } from "../../../images/svg/Reset.svg";
import { Box } from "@mui/material";
import { DashboardRedirect } from "../../../images/svg";

const OverviewPage = (props) => {
  const [overall_progress, setOverallProgress] = useState(
    props?.overviewList?.completion_status?.overall_progress || 0
  );
  const { sync_data_data, syncDataStatus, filterData } = props;
  const [statusColor, setStatusColor] = useState("");

  const formattedAccountData = (filterData?.account_date_type?.results || []).map((item) => ({
    value: item.id,
    display_name: item.account_name,
  }));

  useEffect(() => {
    if (overall_progress <= 20) {
      setStatusColor("#ff4545");
    } else if (overall_progress <= 40) {
      setStatusColor("#ffa534");
    } else if (overall_progress <= 60) {
      setStatusColor("#ffe234");
    } else if (overall_progress <= 80) {
      setStatusColor("#b7dd29");
    } else {
      setStatusColor("#57e32c");
    }
  }, [overall_progress]);

  // const { overall_progress } = props?.overviewList?.completion_status;
  // const overall_progress = 85;

  const handleViewDetailsClick = () => {
    props?.history.push(`/${props?.business_id}/listing-detail`);
  };

  useEffect(() => {
    document.title = "listing-overview";
  }, []);

  useEffect(() => {
    if (props?.overviewList?.completion_status?.overall_progress) {
      setOverallProgress(props?.overviewList?.completion_status?.overall_progress);
    }
  }, [props?.overviewList?.completion_status]);

  const handleRefreshListing = async () => {
    // listingUtils?.displaySyncTost("info", "Refreshing Listing Details");
    await props.actions.getGmbListingSyncData(props.business_id);
  };

  useEffect(() => {
    props.actions.getGmbLocationSyncDataStatus(props.business_id, sync_data_data?.sync_data_id);
  }, []);

  // useEffect(() => {
  //   let interval;

  //   if (
  //     (sync_data_data?.status === "start" || syncDataStatus?.status === "processing") &&
  //     syncDataStatus?.status !== "suceess"
  //   ) {
  //     interval = setInterval(() => {
  //       props.actions.getGmbLocationSyncDataStatus(props.business_id, sync_data_data?.sync_data_id);
  //       if (syncDataStatus?.total_locations > 0 && syncDataStatus?.updated_locations > 0) {
  //         listingUtils?.displaySyncTost("close");
  //         listingUtils?.displaySyncTost(
  //           "info",
  //           "Refreshing Listing Details",
  //           `Processed ${syncDataStatus ? syncDataStatus?.updated_locations : 0} out of ${
  //             syncDataStatus ? syncDataStatus?.total_locations : 0
  //           } updates`,
  //           syncDataStatus?.updated_locations,
  //           syncDataStatus?.total_locations
  //         );
  //         // listingUtils?.displaySyncTost("info", "Refreshing Listing Details", `Accounts: ${syncDataStatus ? syncDataStatus?.updated_accounts
  //         //   : 0} /${syncDataStatus ? syncDataStatus?.total_accounts : 0} updates locations :${syncDataStatus ? syncDataStatus?.updated_locations : 0} /${syncDataStatus ? syncDataStatus?.total_locations : 0} updates `, syncDataStatus?.updated_locations, syncDataStatus?.total_locations,);
  //       } else {
  //         listingUtils?.displaySyncTost("close");
  //         listingUtils?.displaySyncTost("info", "Refreshing Listing Details");
  //       }
  //     }, 10000);
  //   }
  //   if (
  //     syncDataStatus?.status === "success" &&
  //     syncDataStatus?.success_count === 0 &&
  //     syncDataStatus?.status !== "processing"
  //   ) {
  //     listingUtils?.displaySyncTost("close");
  //     listingUtils?.displaySyncTost(
  //       "positive",
  //       "Account Connection Complete",
  //       `Found ${syncDataStatus ? syncDataStatus?.updated_locations : 0} Locations in ${
  //         syncDataStatus ? syncDataStatus?.total_locations : 0
  //       } Group`,
  //       syncDataStatus?.updated_locations,
  //       syncDataStatus?.total_locations
  //     );
  //     clearInterval(interval);
  //   }
  //   return () => clearInterval(interval);
  // }, [
  //   syncDataStatus?.status,
  //   sync_data_data?.status,
  //   sync_data_data?.sync_data_id,
  //   syncDataStatus?.updated_locations,
  //   syncDataStatus?.total_locations,
  //   syncDataStatus,
  //   props.actions,
  //   props.business_id,
  // ]);

  // useEffect(() => {
  //   let interval;

  //   console.log("syncDataStatus?.updated_locationssyncDataStatus?.updated_locations", syncDataStatus?.updated_locations)
  //   console.log("syncDataStatus?.total_locationssyncDataStatus?.total_locationssyncDataStatus?.total_locations", syncDataStatus?.total_locations)

  //   if (sync_data_data?.status === "start" || syncDataStatus?.status === "processing" && syncDataStatus?.status !== "success") {
  //     interval = setInterval(() => {
  //       props.actions.getGmbLocationSyncDataStatus(props.business_id, sync_data_data?.sync_data_id);

  //       // Check if data is available before accessing it
  //       if (syncDataStatus?.total_locations && syncDataStatus?.updated_locations) {
  //         listingUtils?.displaySyncTost("close");
  //         listingUtils?.displaySyncTost(
  //           "info",
  //           "Refreshing Listing Details",
  //           `Processed ${syncDataStatus?.updated_locations} out of ${syncDataStatus?.total_locations} updates`,
  //           syncDataStatus?.updated_locations,
  //           syncDataStatus?.total_locations
  //         );
  //       } else {
  //         listingUtils?.displaySyncTost("close");
  //         listingUtils?.displaySyncTost("info", "Refreshing Listing Details");
  //       }
  //     }, 10000);
  //   }

  //   if (syncDataStatus?.status === "success" && syncDataStatus?.status !== "processing") {
  //     listingUtils?.displaySyncTost("close");
  //     listingUtils?.displaySyncTost(
  //       "positive",
  //       "Account Connection Complete",
  //       `Found ${syncDataStatus?.total_locations} Locations in ${syncDataStatus?.total_locations} Group`,
  //       syncDataStatus?.updated_locations,
  //       syncDataStatus?.total_locations
  //     );
  //     clearInterval(interval);
  //   }

  //   return () => clearInterval(interval);
  // }, [syncDataStatus?.status, syncDataStatus?.updated_locations, syncDataStatus?.total_locations]);
  // useEffect(() => {

  //   // Rest of the effect logic
  // }, [syncDataStatus?.updated_locations, syncDataStatus?.total_locations]);

  // Prevent rendering if syncDataStatus is undefined or incomplete
  // if (!syncDataStatus || !syncDataStatus?.total_locations) {
  //   return null; // or display a loading spinner
  // }

  return (
    <React.Fragment>
      {/* {!lockedModules.listing &&
        <ListingLockedComponent message={"locked component"} />
      } */}
      {/* <div style={{ padding: "16px", filter: "blur(5px)", opacity: 0.8, color: "#000" }}> */}
      <div
        style={{
          padding: "16px",
          height: props.lock_height ? "calc(100vh - 75px)" : "",
          overflowY: props.lock_height ? "hidden" : "",
          filter: props.lock_height ? "blur(5px)" : "",
        }}
      >
        <OverViewFilterlist
          history={props.history}
          location={props.location}
          match={props.match}
          business_id={props.business_id}
          formattedAccountData={formattedAccountData}
          getData={props.getData}
        />
        {/* <div style={{ paddingTop: "20px" }}>
          <SearchComponent
            placeholder="Search Location"
            {...props}
            className="list-search"
            startIcon
          />
        </div> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // borderBottom: "1px solid #ccc",
            marginTop: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "10px 0",
            }}
          >
            <span
              style={{
                color: "#1A2544",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Listing Summary
            </span>

            <span
              style={{
                color: "#F13A88",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              {props?.overviewList?.selected_locations} Selected / {props?.overviewList?.total_locations} Total location
            </span>
          </div>

          <div>
            <FPButton
              label="Refresh Listing"
              size="small"
              disabled={sync_data_data?.status === "start" && syncDataStatus?.status !== "success"}
              onClick={handleRefreshListing}
            />
          </div>
        </div>

        {/* <div>
          <h2
            style={{
              color: "#646565",
              fontSize: "20px",
              fontWeight: "400",
              paddingBlock: "12px",
            }}
          >
            Business Summary
          </h2>
        </div> */}
        <div>
          <BusinessSummary
            average_rating={props?.overviewList?.average_rating}
            average_category={props?.overviewList?.average_category}
            photos={props?.overviewList?.photos}
            products={props?.overviewList?.products}
            questions={props?.overviewList?.questions}
            services={props?.overviewList?.services}
            google_updates={props?.overviewList?.google_updates}
            total_reviews={props?.overviewList?.total_reviews}
            business_id={props?.business_id}
          />
        </div>
        <div className="OverviewHealthStatusContainer">
          <div className="OverviewOverallHealth fp-card-elevation">
            <div className="flex align-baseline border-bottom" style={{ display: "flex", alignItems: "center" }}>
              <Box
                component="span"
                sx={{
                  color: "#646565",
                  fontWeight: "400",
                  fontSize: "17px",
                  marginTop: "3px",
                  marginBottom: "11px",
                }}
              >
                Overall Health
              </Box>
            </div>
            <OverallHealth health={props?.overviewList?.completion_status} />
          </div>

          <div className="OverviewCompletionStatus fp-card-elevation">
            <div className="border-bottom align-center gap-10 pb-2">
              <div className="align-center gap-10">
                <Box
                  component="span"
                  sx={{
                    color: "#646565",
                    fontWeight: "400",
                    fontSize: "17px",
                  }}
                >
                  Completion Status
                </Box>
                <div className="d-flex" style={{ gap: "8px", alignItems: "center" }}>
                  <h3
                    // className={statusColor}
                    style={{
                      color: statusColor,
                      margin: "0",
                      fontSize: "17px",
                    }}
                  >
                    {truncatedNumber(overall_progress)}%
                  </h3>
                  {overall_progress < 20 ? (
                    <div
                      className="badgelowcompletionstatus"
                      style={{
                        backgroundColor: "#ff4545",
                        padding: "4px 12px",
                        borderRadius: "15px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "white",
                        }}
                      >
                        Very Low
                      </p>
                    </div>
                  ) : overall_progress >= 20 && overall_progress < 40 ? (
                    <div
                      className="badgelowcompletionstatus"
                      style={{
                        backgroundColor: "#ffa534",
                        padding: "4px 12px",
                        borderRadius: "15px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "white",
                        }}
                      >
                        Low
                      </p>
                    </div>
                  ) : overall_progress >= 40 && overall_progress < 60 ? (
                    <div
                      className="badgemidcompletionstatus"
                      style={{
                        backgroundColor: "#ffe234",
                        padding: "4px 12px",
                        borderRadius: "15px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "white",
                        }}
                      >
                        Moderate
                      </p>
                    </div>
                  ) : overall_progress >= 60 && overall_progress < 80 ? (
                    <div
                      className="badgemidcompletionstatus"
                      style={{
                        backgroundColor: "#b7dd29",
                        padding: "4px 12px",
                        borderRadius: "15px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "white",
                        }}
                      >
                        High
                      </p>
                    </div>
                  ) : overall_progress >= 80 && overall_progress < 100 ? (
                    <div
                      className="badgehighcompletionstatus"
                      style={{
                        backgroundColor: "#57e32c",
                        padding: "4px 12px",
                        borderRadius: "15px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "white",
                        }}
                      >
                        Very High
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              <DashboardRedirect onClick={handleViewDetailsClick} style={{ cursor: "pointer" }} />
              {/* <FPButton
                label="View Details"
                backgroundColor="#FFFFFF"
                size="small"
                style={{ color: "#1B1C1D", height: "30px" }}
                variant="outline"
                onClick={handleViewDetailsClick}
              ></FPButton> */}
            </div>
            <div style={{ display: "flex", gap: "40px" }}>
              <CompletionStatus
                description={props?.overviewList?.completion_status?.description}
                logo_and_photos={props?.overviewList?.completion_status?.logo_and_photos}
                opening_date={props?.overviewList?.completion_status?.opening_date}
                opening_hours={props?.overviewList?.completion_status?.opening_hours}
                overall_progress={props?.overviewList?.completion_status?.overall_progress}
                phone_number={props?.overviewList?.completion_status?.phone_number}
                products={props?.overviewList?.completion_status?.products}
                q_and_a={props?.overviewList?.completion_status?.q_and_a}
                services={props?.overviewList?.completion_status?.services}
                websiteUri={props?.overviewList?.completion_status?.websiteUri}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    lockedModules: state.business.lockedModules || {},
    sync_data_data: state.gmbListingLocation.sync_data_data,
    syncDataStatus: state.gmbListingLocation.syncDataStatus,
    filterData: state.filter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(OverviewPage);
