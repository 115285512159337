import React from "react";

const FaceEight = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16Z"
        fill="white"
        stroke="#81E050"
        stroke-width="2"
      />
      <path
        d="M9.94424 14.6775C11.1831 14.6775 12.1873 13.6733 12.1873 12.4345C12.1873 11.1957 11.1831 10.1914 9.94424 10.1914C8.70543 10.1914 7.70117 11.1957 7.70117 12.4345C7.70117 13.6733 8.70543 14.6775 9.94424 14.6775Z"
        fill="#1B1C1D"
      />
      <path
        d="M22.6926 14.6842C23.9154 14.4857 24.7458 13.3335 24.5474 12.1107C24.3489 10.8879 23.1967 10.0574 21.9739 10.2559C20.7511 10.4544 19.9206 11.6065 20.1191 12.8294C20.3176 14.0522 21.4697 14.8826 22.6926 14.6842Z"
        fill="#1B1C1D"
      />
      <path
        d="M11.114 7.66008C11.0096 7.69148 10.8955 7.69508 10.7825 7.6647C7.95556 6.9047 6.70782 8.81261 6.69552 8.83191C6.5161 9.11501 6.14118 9.19895 5.85813 9.01949C5.57512 8.84007 5.49113 8.4652 5.67054 8.18215C5.71594 8.11055 6.13923 7.4675 7.01488 6.94111C7.82553 6.4539 9.19179 5.9804 11.0976 6.49274C11.4212 6.57975 11.613 6.91262 11.5261 7.23626C11.4694 7.44684 11.3086 7.60166 11.114 7.66008Z"
        fill="#1B1C1D"
      />
      <path
        d="M25.6334 9.07183C25.5294 9.03921 25.433 8.97806 25.3565 8.8895C23.4441 6.67316 21.3403 7.55124 21.3193 7.56028C21.0118 7.69376 20.6545 7.5527 20.521 7.24524C20.3876 6.93789 20.5286 6.58051 20.836 6.44708C20.9138 6.4133 21.6251 6.11892 22.6453 6.17507C23.5896 6.22713 24.986 6.60258 26.2752 8.09663C26.4942 8.35037 26.466 8.73351 26.2122 8.9525C26.0472 9.09497 25.8272 9.13279 25.6334 9.07183Z"
        fill="#1B1C1D"
      />
      <path
        d="M23.0824 19.8125C23.0824 24.2827 19.9112 27.9068 15.9998 27.9068C12.0885 27.9068 8.91797 24.2827 8.91797 19.8125H23.0824Z"
        fill="#1B1C1D"
      />
    </svg>
  );
};
export default FaceEight;
