import React from "react";
import { Row, Col } from "reactstrap";
import { Icon, Search } from "semantic-ui-react";
import { MentionsInput, Mention } from "react-mentions";
import cookie from "react-cookies";
import CommentCard from "../common/CommentCardComponent";
import { ActivityLogComponent } from "../tickets/ActivityLog/ActivityLogComponent";
import { capitalize, displayMessage, snakeToCapital } from "../../libs/utils";
import FormModal from "../common/FormModal";
import { FPBadge, FPButton, FPMenuBox } from "../common/new";
import FPChip from "../common/new/FPChip/FPChip";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import ErrorMessage from "../common/new/forms/ui/ErrorMessage";
import TicketCard from "../../modules/ticket/TicketCard";
import { lightCommentColor } from "../../libs/constant";
import TicketResolutionsSurveyForm from "./TicketResolutionsSurveyForm";
import TicketProductIssueReasonsMappings from "./TicketProductIssueReasonsMappings";
import ReactPaginate from "react-paginate";
import { Box } from "@material-ui/core";
import TicketActivity from "../../images/svg/ticketActivity.svg";
import attachment from "../../images/svg/attachment.svg";
class TicketingReviewsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filteredTeamList: props.teamList,
      disableAudio: false,
      disableComment: false,
      selectedOption: "comment",
      selected_file: null,
      ticketResolutionResponse:
        this.props.data &&
        this.props.data.resolution_data &&
        this.props.data.resolution_data.survey_response
          ? this.props.data.resolution_data.survey_response
          : null,
      isUserNotAssignee: false,
      isTicketClosed: false,
      attached_files_urls:
        this.props.data &&
        this.props.data.resolution_data &&
        this.props.data.resolution_data.survey_attachment
          ? this.props.data.resolution_data.survey_attachment
          : null,
      productIssueReasonsMappingData: this.props.data
        ? this.props.data.product_issue_reasons
        : null,
      ticketSurveySubmitted:
        this.props.data && this.props.data.ticket_survey_submitted
          ? true
          : false,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    const user = cookie.load("user") || {};
    const { data } = this.props;
    if (data && user) {
      if (data?.assignee_data.id != user.id) {
        this.setState({ isUserNotAssignee: true });
      }
      if (data?.status === "resolved") {
        this.setState({ isTicketClosed: true });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.data &&
      this.props.data.resolution_data != nextProps.data.resolution_data
    ) {
      this.setState({
        ticketResolutionResponse: nextProps.data.resolution_data.survey_response
          ? nextProps.data.resolution_data.survey_response
          : null,
      });
      this.setState({
        attached_files_urls: nextProps.data.resolution_data.survey_attachment
          ? nextProps.data.resolution_data.survey_attachment
          : null,
      });
    }
    if (
      this.props.data &&
      this.props.data.product_issue_reasons !=
        nextProps.data.product_issue_reasons
    ) {
      this.setState({
        productIssueReasonsMappingData: nextProps.data.product_issue_reasons,
      });
    }
    if (
      this.props.data &&
      this.props.data.ticket_survey_submitted !=
        nextProps.data.ticket_survey_submitted
    ) {
      this.setState({
        ticketSurveySubmitted: nextProps.data.ticket_survey_submitted,
      });
    }
  }

  handleInputChange = (e, description) => {
    if (description?.length > 0) {
      this.setState({ disableAudio: true });
    } else {
      this.setState({ disableAudio: false });
    }
    this.setState({ description });
  };

  handleFileChange = (event) => {
    this.setState({ selected_file: event.target.files[0] });
  };
  enableInput = () => {
    let disableComment = !this.state.disableComment;
    this.setState({ disableComment });
  };

  disableInput = (val) => {
    this.setState({ disableComment: val });
  };

  changeAssignee = (assignees) => {
    const {
      patchTicket,
      data: { id },
    } = this.props;
    patchTicket({ assignees }, id)
      .then((res) =>
        displayMessage(
          "positive",
          `Ticket Assigned to ${res.data?.assignee_data.name}`
        )
      )
      .catch((err) => {});
  };

  changeStatus = (status) => {
    const {
      patchTicket,
      data: { id },
    } = this.props;
    patchTicket({ status }, id).then((res) => {
      if (
        res.data?.status === "waiting_for_customer" ||
        res.data?.status === "assigned"
      ) {
        displayMessage(
          "positive",
          `Ticket ${
            res.data?.status === "waiting_for_customer"
              ? "Marked as Waiting for Customer"
              : "Marked as Assigned"
          } Successfully`
        );
      } else {
        displayMessage(
          "positive",
          `Ticket ${
            res.data?.status === "reopened" ? "Reopened" : "Closed"
          } Successfully`
        );
      }
    });
  };

  addComment = () => {
    this.setState({
      disableAudio: false,
    });
    const formData = new FormData();
    if (this.state.selected_file) {
      formData.append(
        "attached_file",
        this.state.selected_file,
        this.state.selected_file.name
      );
    }

    if (
      this.state.description !== undefined &&
      this.state.description &&
      typeof this.state.description === "string" &&
      this.state.description.trim()?.length > 0
    ) {
      formData.append("description", this.state.description);
    } else if (this.state.selected_file) {
      formData.append(
        "description",
        this.state.selected_file && this.state.selected_file.name
      );
    } else {
      formData.append("description", "");
    }
    this.props
      .addComment(formData, this.props.data.id)
      .then(() => {
        this.setState({ description: "", selected_file: null });
        document.getElementById("comment_attachement").value = ""; // Reset the file input field
      })
      .catch((err) => {
        console.error(err);
        this.setState({ description: "", selected_file: null });
        document.getElementById("comment_attachement").value = ""; // Reset the file input field
      });
  };

  handleChange = (_, data) => {
    const query = data.value;
    if (query) {
      const regex = new RegExp(`${query.trim()}`, "i");
      this.setState({
        filteredTeamList: this.props.teamList.filter(
          (item) => `${item.first_name} ${item.last_name}`.search(regex) >= 0
        ),
      });
    } else {
      this.setState({ filteredTeamList: this.props.teamList });
    }
  };
  handlePageClick(selectedPage) {
    this.props.handleSurveyPageChange(
      selectedPage.selected,
      this.state.productIssueReasonsMappingData?.length
    );
  }

  render() {
    // eslint-disable-next-line
    const {
      data,
      // data: { source_data },
      teamList,
      isOpen,
      toggle,
      businessId,
      singleTicketDetails,
      survey_current_page,
      survey_page_length,
      survey_pages,
      surveySubmitError,
      ticketResolutionEnabled,
      productIssueReasonsMappingEnabled,
      handleReplyOption,
      replyOptions,
      ticketPage,
    } = this.props;
    const {
      filteredTeamList,
      ticketResolutionResponse,
      attached_files_urls,
      ticketSurveySubmitted,
      productIssueReasonsMappingData,
      selectedOption
    } = this.state;
    const user = cookie.load("user") || {};
    const { enable_ticket_assign, enable_ticket_close } = user || {};
    var showSurveyPages = false;
    if (
      this.props.productMappingSubmitted ||
      productIssueReasonsMappingData?.length == 0 ||
      !productIssueReasonsMappingEnabled
    ) {
      showSurveyPages = true;
    }
    const assignerList = filteredTeamList
      ? filteredTeamList.map((item) => ({
          label: `${item.first_name} ${item.last_name}`,
          value: item.id,
          onClick: () => this.changeAssignee(item.id),
        }))
      : [];

    // Create an empty object to store unique values
    const uniqueCreatedBy = {};

    // Iterate through the comments array and mark each 'created_by' value as true in the uniqueCreatedBy object
    data &&
      data?.comment?.length &&
      data?.comment.map((comment) => {
        uniqueCreatedBy[comment.created_by] = true;
      });

    const uniqueCreatedByArray = Object.keys(uniqueCreatedBy);

    const getColor = (name) => {
      const index = uniqueCreatedByArray.findIndex(
        (commenterName) => commenterName === name
      );
      return lightCommentColor[index + 1] || lightCommentColor[0];
    };

    const getStatusColor = (status) => {
      const statusColor = {
        new: "#2375ef",
        assigned: "#f8ae00",
        resolved: "#17b26a",
        reopened: "#e16b16",
        waiting_for_customer: "#FFDC54",
      };
      return statusColor[status];
    };
    const waitingForCustomerOptions = [
      {
        label: "Mark as Waiting for Customer",
        value: "waiting_for_customer",
        onClick: () => this.changeStatus("waiting_for_customer"),
      },
    ];
    const assignedOptions = [
      {
        label: "Mark as Assigned",
        value: "assigned",
        onClick: () => this.changeStatus("assigned"),
      },
    ];

    const closeTicketModel = () => {
      toggle();
      this.setState({ selectedOption :  "comment"})
    }
    return (
      <>
      
      <FormModal
        isOpen={isOpen}
        toggle={closeTicketModel}
        width="1200px"
        maxWidth="lg"
        headerClass="-mt-10"
        bodyClass="pt-0"
        heading={
          <span style={{ fontSize: "18px !important" }}>
            View Ticket Activity
          </span>
        }
      >
        {/* // TODO : manage assigned */}
        {/* <span>
          {((data?.status === 'assigned') || (data?.status === 'waiting_for_customer')) ?<Dropdown
            className="icon primary review-status-dropdown ml-3"
            text={data?.status==='assigned'? 'Assigned' : 'Waiting for Customer'} 
            labeled
            button
            basic
            simple
          >
            <Dropdown.Menu style={{maxHeight: '30rem', overflow: 'auto'}}>
              {data?.status==='waiting_for_customer'&&<Dropdown.Item  onClick={()=>{this.changeStatus('assigned');}} >Mark  as  Assigned </Dropdown.Item>}
              {data?.status==='assigned'&&<Dropdown.Item onClick={()=>{this.changeStatus('waiting_for_customer');}} >Mark  as  Waiting  for  Customer </Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
            : 
            <FPChip size='small' variant='outlined' label={
              <div className='d-flex align-items-center justify-content-center'>
                <FPBadge color='#57e32c' size='11' />
                &nbsp;<span>New</span>
              </div>
            } />
          }
        </span> */}
        {/* //* Header */}
        <div
          className="d-flex justify-content-between"
          style={{ marginBottom: 15 }}
        >
          <div className="d-flex align-items-center gap-15">
            {data?.status === "assigned" ||
            data?.status === "waiting_for_customer" ? (
              <>
                {data?.status === "assigned" && (
                  <FPMenuBox
                    title={"Assigned"}
                    startIcon={<FPBadge color="#f8ae00" size="11" />}
                    options={waitingForCustomerOptions}
                    className="assign-box"
                  />
                )}
                {data?.status === "waiting_for_customer" && (
                  <FPMenuBox
                    title={"Waiting for customer"}
                    startIcon={<FPBadge color="#FFDC54" size="11" />}
                    options={assignedOptions}
                    className="assign-box"
                  />
                )}
              </>
            ) : (
              <FPChip
                size="small"
                variant="outlined"
                className="assign-box-fp-chip"
                label={
                  <div className="d-flex align-items-center justify-content-center">
                    <FPBadge color={getStatusColor(data?.status)} size="11" />
                    &nbsp;&nbsp;
                    <span style={{ fontSize: "12px" }}>
                      {snakeToCapital(data?.status)}
                    </span>
                  </div>
                }
              />
            )}
            <div className="d-flex align-items-center gap-6">
              <img src={TicketActivity} height="22px" width="22px" />
              <span style={{ fontSize: "21px", color: "#1B1C1D" }}>
                {capitalize(data?.ticket_id.replace("ticket", ""))}
              </span>
            </div>
          </div>

          {/* //* Assign dropdown */}
          {enable_ticket_assign && data?.status !== "resolved" && (
            <FPMenuBox
              title={data?.assignee_data.name || "Select Assignee"}
              options={assignerList}
              size={"small"}
              renderToAboveList={
                <Search
                  showNoResults={false}
                  onSearchChange={this.handleChange}
                  style={{ margin: ".5rem" }}
                />
              }
              className="assign-name-box"
            >
              {user.is_business_owner && (
                <div className="m-2">
                  <FPButton
                    label="Manage Team"
                    className="w-100 b-radius-8"
                    size="small"
                    backgroundColor="#438E96"
                    onClick={this.props.manageTeamList}
                  />
                </div>
              )}
            </FPMenuBox>
          )}
        </div>
        <div className="border p-3 b-radius-8">
          <div className="d-flex">
            <div className="fp-flex-grow-1">
              <TicketCard
                data={data}
                className="mb-15"
                handleReplyOption={handleReplyOption}
                replyOptions={replyOptions}
                ticketPage={ticketPage}
              />
              {/* //* Comment input  */}
              
            </div>
            {ticketResolutionEnabled && survey_pages &&(
              <Box
                className="ml-20"
                sx={{
                  border: "1px solid #22242626",
                  borderRadius: "8px",
                }}
              >
                {productIssueReasonsMappingEnabled &&
                  productIssueReasonsMappingData &&
                  productIssueReasonsMappingData?.length > 0 &&
                  !showSurveyPages && (
                    <TicketProductIssueReasonsMappings
                      productIssueReasonsMappingData={
                        productIssueReasonsMappingData
                      }
                      totalSurveyPageCount={survey_page_length}
                      survey_current_page={survey_current_page}
                      submitProductIssueReasonsMappingQuestions={
                        this.props.submitProductIssueReasonsMappingQuestions
                      }
                      issueReasonError={this.props.issueReasonError}
                      single_ticket_id={data && data.id}
                      isTicketClosed={this.state.isTicketClosed}
                      isUserNotAssignee={this.state.isUserNotAssignee}
                      surveyFormLoading={this.props.surveyFormLoading}
                      handleSurveyPageChange={this.props.handleSurveyPageChange}
                    />
                  )}
                {survey_page_length &&
                  ticketResolutionEnabled &&
                  showSurveyPages && (
                    <TicketResolutionsSurveyForm
                      survey_pages={survey_pages[survey_current_page]}
                      survey_current_page={survey_current_page}
                      getSurveyResponsefn={this.props.getSurveyResponse}
                      surveySubmitError={surveySubmitError}
                      totalSurveyPageCount={survey_page_length}
                      nextPageCount={survey_current_page + 1}
                      finalSubmitSurveyQuestin={
                        this.props.finalSubmitSurveyQuestin
                      }
                      business={businessId}
                      single_ticket_id={data && data.id}
                      ticketResolutionResponse={
                        ticketResolutionResponse &&
                        ticketResolutionResponse[survey_current_page]
                      }
                      handleSurveyPageChange={this.props.handleSurveyPageChange}
                      isUserNotAssignee={this.state.isUserNotAssignee}
                      surveyFormLoading={this.props.surveyFormLoading}
                      isTicketClosed={this.state.isTicketClosed}
                      attached_files_urls={attached_files_urls}
                      productIssueReasonsMappingData={
                        productIssueReasonsMappingData
                      }
                    />
                  )}
                <Row className="text-center">
                  <Col sm="12">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel="..."
                      pageCount={
                        productIssueReasonsMappingData?.length > 0
                          ? survey_page_length + 1
                          : survey_page_length
                      }
                      onPageChange={this.handlePageClick}
                      containerClassName={"ticketResolutionPagination"}
                      previousLinkClassName={"ticketResolutionPagination__link"}
                      nextLinkClassName={"ticketResolutionPagination__link"}
                      disabledClassName={"ticketResolutionPagination--disabled"}
                      activeClassName={"ticketResolutionPagination--active"}
                      forcePage={
                        survey_current_page == survey_page_length - 1 &&
                        productIssueReasonsMappingData?.length > 0 &&
                        survey_page_length != 1
                          ? survey_current_page + 1
                          : survey_current_page
                      }
                    />
                  </Col>
                </Row>
              </Box>
            )}
          </div>
          {/*  //* Reopen close ticket btn */}
          {enable_ticket_close && (
            <div className="d-flex justify-content-end mt-2">
              <FPButton
                size="small"
                onClick={() =>
                  this.changeStatus(
                    data?.status === "resolved" ? "reopened" : "resolved"
                  )
                }
                disabled={
                  this.props.restrictTicketClosingOnSurvey
                    ? ticketSurveySubmitted
                      ? false
                      : true
                    : false
                }
                label={
                  data?.status === "resolved" ? "Reopen Ticket" : "Close Ticket"
                }
                startIcon={
                  data?.status === "resolved" ? (
                    <LockOpenOutlinedIcon />
                  ) : (
                    <LockOutlinedIcon />
                  )
                }
              />
            </div>
          )}

          {/* //* Activity log  */}
        </div>
        <div className="my-4">

          <div className="radio-group">
            <input
              type="radio"
              id="comment"
              name="viewOption"
              value="comment"
              checked={selectedOption === "comment"}
              onChange={() => this.setState({ selectedOption :  "comment"})}
            />
            <label htmlFor="comment">Comment</label>

            <input
              type="radio"
              id="activityLog"
              name="viewOption"
              value="activityLog"
              checked={selectedOption === "activityLog"}
              onChange={() => this.setState({ selectedOption :  "activityLog"})}
            />
            <label htmlFor="activityLog">View Activity Log</label>
          </div>
        </div>
        {selectedOption === "comment" && (
        !this.props.disableTicketCommentBox && (
                <>
                  {data?.status !== "resolved" ? (
                    <Row className="d-flex m-0 p-0">
                      <Col className={"d-flex gap-10 p-0"}>
                        <div style={{ flexGrow: 1 }}>
                          <div className="position-relative">
                            <MentionsInput
                              value={this.state.description}
                              onChange={this.handleInputChange}
                              placeholder="Write a comment. Use @ to tag team members."
                              className="comments-textarea"
                              disabled={this.state.disableComment}
                            >
                              <Mention
                                trigger="@"
                                markup="@{{__user__||__id__||__display__}}"
                                displayTransform={(id, display) =>
                                  `@${display}`
                                }
                                data={
                                  teamList &&
                                  teamList.map((i) => ({
                                    id: i.id,
                                    display: i.first_name + " " + i.last_name,
                                  }))
                                }
                                className="comments-textarea__mention"
                                appendSpaceOnAdd
                              />
                            </MentionsInput>
                            <div
                              className="fp-attachment-icon"
                              style={{ top: "4px " }}
                            >
                              <label
                                htmlFor="comment_attachement"
                                className="cursor-pointer"
                                sx={"& ."}
                              >
                                <img src={attachment} alt="attachment-icon" />
                              </label>
                              <input
                                id="comment_attachement"
                                type="file"
                                style={{ display: "none" }}
                                onChange={this.handleFileChange}
                              />
                            </div>
                          </div>
                          {this.state.selected_file && (
                            <span>{this.state.selected_file.name}</span>
                          )}
                        </div>
                        <div>
                          <Box>
                            <FPButton
                              size="small"
                              backgroundColor="#1B1C1D"
                              onClick={this.addComment}
                              disabled={
                                !this.state.selected_file &&
                                (this.state.disableComment ||
                                  !this.state.description ||
                                  (this.state.description &&
                                    typeof this.state.description ===
                                      "string" &&
                                    this.state.description.trim()?.length <= 0))
                              }
                              className="b-radius-8"
                              label="Comment"
                            />
                          </Box>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <ErrorMessage>
                      Reopen Ticket to enable commenting.
                    </ErrorMessage>
                  )}

                  {data?.comment?.length > 0 && (
                    <div
                      style={{
                        // maxHeight: "200px",
                        // overflowY: "auto",
                        // overflowX: "hidden",
                        marginTop: 15,
                      }}
                      className="border p-2 b-radius-8 d-flex flex-column gap-10"
                    >
                      {data?.comment.map((item, index) => (
                        <CommentCard
                          key={index}
                          {...item}
                          backgroundColor={getColor(item.created_by || "")}
                        />
                      ))}
                    </div>
                  )}
                </>
              )
          )}

        {selectedOption === "activityLog" && (
            <div style={{border: "1px solid #BFC2CB",borderRadius: "16px",marginTop: "15px", padding: "15px"}}>

          <ActivityLogComponent logs={data?.action_logs} /></div>
          )}
      </FormModal>
      </>
    );
  }
}

export default TicketingReviewsModal;
