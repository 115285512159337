/* eslint-disable */

const SERVER_URL = process.env.REACT_APP_APIURL;
const SERVER_WEBSOCKET_URL = process.env.REACT_APP_SOCKET_URL;

module.exports = {
  PROFILE: `${SERVER_URL}/accounts/me/`,
  LOGIN: `${SERVER_URL}/accounts/login/`,
  REFRESH_TOKEN: `${SERVER_URL}/accounts/refresh/`,
  LOGOUT: `${SERVER_URL}/accounts/logout/`,
  FORGOT_PASSWORD: `${SERVER_URL}/accounts/forget_password/`,
  REGISTER: `${SERVER_URL}/accounts/register/`,
  AGENCY_BUSINESS_REGISTER: `${SERVER_URL}/accounts/{}/agency/setup/`,
  AGENCY_BUSINESS_LIST: `${SERVER_URL}/business/{}/agency/dashboard/`,
  AGENCY_BUSINESS_ALL: `${SERVER_URL}/business/{}/agency-business/`,
  REGISTER_CONFIRM: `${SERVER_URL}/accounts/register/confirm/`,
  RATING_OPTIONS: `${SERVER_URL}/accounts/user-rating/`,
  RESET_PASSWORD: `${SERVER_URL}/accounts/password/reset/`,
  SCHEDULE_OPTIONS: `${SERVER_URL}/accounts/alert-schedule/`,
  CHANGE_PASSWORD: `${SERVER_URL}/accounts/change_password/`,
  BUSINESS_TYPE: `${SERVER_URL}/accounts/business-type/`,
  TEAM: `${SERVER_URL}/accounts/{}/team/`,
  TEAM_DEPARTMENTS_USERS: `${SERVER_URL}/business/team-department-users`,

  CONTACT: `${SERVER_URL}/accounts/{}/contact-list/`,
  CONTACT_IDS: `${SERVER_URL}/accounts/{}/contact-ids/`,
  CONTACT_DETAIL: `${SERVER_URL}/accounts/{}/contact-list/{}/`,
  CONTACT_OPTIONS: `${SERVER_URL}/accounts/{}/contact-list/option/`,
  DOWNLOAD_CONTACT: `${SERVER_URL}/accounts/{}/contact-list/download_contacts/`,
  LOCATION_COUNTRY: `${SERVER_URL}/location/countries/`,
  LOCATION_REGION: `${SERVER_URL}/location/regions/`,
  ENTERPRISE_USER_LIST: `${SERVER_URL}/accounts/{}/team/option/`,
  SOCIAL_SETUP: `${SERVER_URL}/accounts/social/`,
  CONTACTS_DELETE: `${SERVER_URL}/accounts/{}/contact-list/contact_delete/`,
  CONTACTS_TEMPLATE: `${SERVER_URL}/accounts/contact-list-format/`,
  CONTACTS_FILTER: `${SERVER_URL}/accounts/contact/filter-options`,
  CONTACTS_FILTER_OPTIONS: `${SERVER_URL}/accounts/{}/contact-list/option/?option_name={}`,
  GMB_LISTING: `${SERVER_URL}/gmb-listing/`,
  BUSINESS_SETUP: `${SERVER_URL}/business/`,
  CATEGORY_OPTIONS: `${SERVER_URL}/business/category/`,
  WEB_PORTAL_OPTIONS: `${SERVER_URL}/business/web-portal/`,
  MARKETING: `${SERVER_URL}/business/marketing/`,
  REPORT: `/dashboard-report`,
  DOWNLOAD_REPORT: `${SERVER_URL}/business/{}/download-report/?report_category={}`,
  DOWNLOAD_TICKET_REPORT: `${SERVER_URL}/ticket/{}/reports/ticket_stats/`,
  SMART_RESPONSES: `${SERVER_URL}/response/`,
  STAFF_CREATE: `${SERVER_URL}/staff/`,
  SAMPLE_FILE_DOWNLOAD: `${SERVER_URL}/staff/sample_file_download?action=staff_sample`,
  STAFF_CSV_UPLOAD: `${SERVER_URL}/staff/upload_csv/`,
  STAFF_LIST: `${SERVER_URL}/staff/`,
  STAFF_PERFORMANCE_LIST: `${SERVER_URL}/staff/staff_insights/`,
  STAFF_PERFORMANCE_DOWNLOAD: `${SERVER_URL}/staff/staff_insights/download_csv/`,
  STAFF_INSIGHTS_REVIEW: `${SERVER_URL}/staff/staff_insights/staff_review/`,
  STAFF_REVIEWS_DOWNLOAD: `${SERVER_URL}/staff/staff_insights/download_staff_reviews/`,
  BUSINESS_PROFILE: `${SERVER_URL}/accounts/{}/profile/`,
  BUSINESS_REPORT: `${SERVER_URL}/business/{}/dashboard-report/`,
  MENU_LIST: `${SERVER_URL}/menu/{}/product/`,
  MENU_ATTRIBUTE: `${SERVER_URL}/menu/{}/product-attributes`,
  MENU_OUT_STOCK_ITEM: `${SERVER_URL}/restropilot/{}/out-of-stock/`,
  MENU_COMPLAINT_DETAILS: `${SERVER_URL}/menu/{}/comp-order-details/`,
  // RESPONSE_CHART_URL
  RESPONSE_RATE: `${SERVER_URL}/reviews/{}/reviews-responded-status/`,
  SECTION_HEADING: `${SERVER_URL}/reviews/{}/reviews-provider-status/`,

  BRANCH: "branch/",
  REVIEWS_DETAILS: `${SERVER_URL}/reviews/`,
  COMPLAINT_DETAILS: `${SERVER_URL}/reviews/{}/reviews-complaint/`,
  SOCIAL_REVIEW: `${SERVER_URL}/reviews/{}/social/?plan=social`,
  UPLOAD_CSV: "upload_csv/",
  POST_COMMENTS: `${SERVER_URL}/reviews/comments/`,
  REVIEW_CSV: `${SERVER_URL}/reviews/uploadcsv/`,
  SWIGGY_REVIEW_CSV: `${SERVER_URL}/reviews/{}/review/swiggy_upload_csv/`,
  ZOMATO_REVIEW_CSV: `${SERVER_URL}/reviews/{}/review/zomato_upload_csv/`,
  MAGICPIN_REVIEW_CSV: `${SERVER_URL}/reviews/{}/review/magicpin_upload_csv/`,
  CAMPAIGN: `${SERVER_URL}/campaign/`,
  CAMPAIGN_FOLLOW_UP: `${SERVER_URL}/campaign/{}/Review/{}/follow_up/?plan=review_generation`,
  CAMPAIGN_DETAIL: `${SERVER_URL}/campaign/{}/Review/?plan=review_generation`,
  ORDER_DETAILS: `${SERVER_URL}/menu/{}/order-details/`,

  RECURRING_CAMPAIGN: `${SERVER_URL}/campaign/{}/recurring/Review/`,
  NEWSLETTER_RECURRING_CAMPAIGN: `${SERVER_URL}/campaign/{}/recurring/Newsletter/`,

  CAMPAIGN_NEWSLETTER_DETAIL: `${SERVER_URL}/campaign/{}/Newsletter/?plan=newsletter_creation`,

  SEND_INVITE: `${SERVER_URL}/reviews/{}/invite/`,
  SOCIAL: `${SERVER_URL}/social/`,
  APP_STORE_BRANCH_SETUP_SOCIAL: `AppStorebranchSetup/`,
  FEEDBACK_INVITE: `${SERVER_URL}/reviews/invite/`,
  REVIEW_SOURCE: `${SERVER_URL}/reviews/source/`,
  STATUS: `status/`,
  REPLY_OPTIONS: `${SERVER_URL}/reviews/review-status/`,
  SENTIMENT_OPTIONS: `${SERVER_URL}/reviews/sentiment-filter/`,
  CONTENT_OPTIONS: `${SERVER_URL}/reviews/description-filter/`,
  STATUS_UPDATE: `${SERVER_URL}/reviews/{}/status-update/`,
  UNSUBSCRIBE: `${SERVER_URL}/reviews/invite/{}/unsubscribe/`,

  BUSINESS_LISTING: `${SERVER_URL}/business/business-listing/`,
  BRANCH_TAG: `${SERVER_URL}/business/branch-tag/`,
  LOCATION_COUNTRY: `${SERVER_URL}/location/countries/`,
  LOCATION_REGION: `${SERVER_URL}/location/regions/`,

  //Filters
  PRODUCT_ITEM_LIST: `${SERVER_URL}/menu/`,

  RELEVANT_COUNTRIES: `${SERVER_URL}/business/{}/address/country/`,
  RELEVANT_STATES: `${SERVER_URL}/business/{}/address/state`,
  RELEVANT_CITIES: `${SERVER_URL}/business/{}/address/city`,

  RECENT_PERFORMANCE: `${SERVER_URL}/business/dashboard/{}/comparative_performance/?date_type=today`,
  RECENT_REVIEWS: `${SERVER_URL}/business/dashboard/{}/recent_reviews/`,
  GENDER_STATISTICS: `${SERVER_URL}/business/dashboard/{}/gender_statistics/`,
  EMAIL_STATISTICS: `${SERVER_URL}/business/dashboard/{}/email_statistics/`,
  SMS_STATISTICS: `${SERVER_URL}/business/dashboard/{}/sms_statistics/`,
  STATUS_STATISTICS: `${SERVER_URL}/business/dashboard/{}/review_status_statistics/`,
  TOP_BOTTOM_FIVE_LOCATIONS: `${SERVER_URL}/business/dashboard/{}/top_and_least_five_locations/`,
  NPS_STATS: `${SERVER_URL}/business/dashboard/{}/nps/`,
  WORD_CLOUD: `${SERVER_URL}/business/dashboard/{}/word_cloud/`,

  TEMPLATE_LIST: `${SERVER_URL}/campaign/{}/template/Review/?plan=review_generation`,
  TEMPLATE_DETAILS: `${SERVER_URL}/campaign/{}/template/Review/{}/?plan=review_generation`,
  TEMPLATE_CLONE: `${SERVER_URL}/campaign/{}/template/Review/{}/clone/?plan=review_generation`,
  REVIEW_TEMPLATE: `${SERVER_URL}/reviews/offline-review-template/`,
  TEMPLATE_SUBTYPE: `${SERVER_URL}/campaign/{}/template/Generic/subtypes/`,

  GENERIC_TEMPLATES_LIST: ` ${SERVER_URL}/campaign/{}/template/Generic/`,
  GENERIC_TEMPLATE_DETAILS: `${SERVER_URL}/campaign/{}/template/Generic/{}/`,
  GENERIC_TEMPLATE_CLONE: `${SERVER_URL}/campaign/{}/template/Generic/{}/clone/`,
  GENERIC_TEMPLATE_CONTACTS_LIST: `${SERVER_URL}/campaign/{}/template/Generic/contacts/`,
  GENERIC_CONTACTS: `${SERVER_URL}/accounts/{}/Generic/contact-list/`,

  NEWSLETTER_LIST: `${SERVER_URL}/campaign/{}/template/Newsletter/?plan=newsletter_creation`,
  NEWSLETTER_DETAILS: `${SERVER_URL}/campaign/{}/template/Newsletter/{}/?plan=newsletter_creation`,
  NEWSLETTER_CLONE: `${SERVER_URL}/campaign/{}/template/Newsletter/{}/clone/?plan=newsletter_creation`,

  INS: `${SERVER_URL}/insights/`,
  INSIGHTS: `${SERVER_URL}/insights/{}/section/?plan=insights`,
  INSIGHTS_WORD_CLOUD: `${SERVER_URL}/insights/{}/topicset/sections/?plan=insights`,
  LOCATION_GRADING: `${SERVER_URL}/insights/{}/topicset/location_grading/?plan=insights`,
  INSIGHTS_NOUN_LIST: `${SERVER_URL}/insights/{}/topicset/noun_list/?plan=insights`,
  INSIGHTS_ADJECTIVE_LIST: `${SERVER_URL}/insights/{}/topicset/adj_list/?plan=insights`,
  INSIGHTS_CHUNK_LIST: `${SERVER_URL}/insights/{}/topicset/chunk_list/?plan=insights`,
  INSIGHTS_DATA: `${SERVER_URL}/insights/{}/section/{}/?plan=insights`,
  INSIGHTS_RECOMMENDED_KEYWORDS: `${SERVER_URL}/insights/{}/recommended/keywords`,
  INSIGHTS_REVIEW_DOWNLOAD: `${SERVER_URL}/insights/{}/topicset/download_insights_reviews/?plan=insights`,
  INSIGHTS_MENU_PERFORMANCE: `${SERVER_URL}/menu/{}/menu-analytics/menu_performance/`,
  INSIGHT_MENU_MODAL: `${SERVER_URL}/menu/{}/menu-analytics/item_reviews/`, 
  INSIGHT_CATEGORY_REVIEWS: `${SERVER_URL}/insights/{}/topicset/category_reviews`,
  INSIGHT_CATEGORY_REVIEWS_DOWNLOAD: `${SERVER_URL}/insights/{}/topicset/download_category_reivews`,
  INSIGHT_MENU_MODAL_DOWNLOAD: `${SERVER_URL}/menu/{}/menu-analytics/reivew_download/`,
  INSIGHTS_MENU_PERFORMANCE_DOWNLOAD: `${SERVER_URL}/menu/{}/menu-analytics/download/`,
  INSIGHTS_CATEGORIES_DOWNLOAD_CATEGORY: `${SERVER_URL}/insights/{}/topicset/download_category/`,

  COMPETITORS_LIST: `${SERVER_URL}/business/{}/competition/?plan=competitor_analysis`,
  COMPETITORS_DETAILS: `${SERVER_URL}/reviews/{}/competitor/?plan=competitor_analysis`,
  COMPETITOR_LINKS_DETAILS: `${SERVER_URL}/business/{}/{}/competition-link/?plan=competitor_analysis`,
  COMPLAINT_SOURCE_OPTIONS: `${SERVER_URL}/reviews/source/complaints/?business_id={}`,
  COMPETITOR_LOCATION_DATA: `${SERVER_URL}/business/{}/competitor-location/{}/?plan=competitor_analysis`,
  COMPETITORS_BRANCH_LIST: `${SERVER_URL}/business/{}/competitor-location/?plan=competitor_analysis`,
  COMPETITOR_SOURCE_OPTIONS: `${SERVER_URL}/categories/competitor-category-providers/`,
  COMPETITORS_OVERVIEW: `${SERVER_URL}/business/{}/competition-report/overview/?plan=competitor_analysis`,
  COMPETITORS_INSIGHTS: `${SERVER_URL}/business/{}/competition-report/word_cloud/?plan=competitor_analysis`,
  COMPETITORS_RATING: `${SERVER_URL}/business/{}/competition-report/competitor_rating/`,
  COMPETITOR_DATA: `${SERVER_URL}/business/{}/competition/{}/?plan=competitor_analysis`,
  COMPETITORS_OPTIONS: `${SERVER_URL}/business/{}/competition/options/?plan=competitor_analysis`,

  LOCKED_MODULES: `${SERVER_URL}/business/{}/settings/`,

  REVIEWS_OFFLINE_FORM: `${SERVER_URL}/reviews/open/{}`,
  MENTIONS_KEYWORD: `${SERVER_URL}/reviews/{}/mention-keywords/{}/?plan=social_mentions`,
  MENTIONS_KEYWORDS: `${SERVER_URL}/reviews/{}/mention-keywords/?plan=social_mentions`,
  GET_MENTION_DATA: `${SERVER_URL}/reviews/{}/mention/{}/?plan=social_mentions`,
  GET_MENTIONS_DATA: `${SERVER_URL}/reviews/{}/mention/?plan=social_mentions`,
  MENTION_PROVIDERS: `${SERVER_URL}/reviews/source/`,
  MENTION_STATUS: `${SERVER_URL}/reviews/{}/mention-keyword/status`,
  MENTIONS_SOCIAL_CONNECTIONS: `${SERVER_URL}/social/{}/{}/`,
  ALL_MENTIONS_SOCIAL_CONNECTIONS: `${SERVER_URL}/socialprofile/{}/{}/`,
  MENTIONS_SOCIAL_STATUS: `${SERVER_URL}/social/{}/provider/status`,
  MENTION_REPORT: `${SERVER_URL}/reviews/{}/mention/stats/`,
  MENTION_REPLY: `${SERVER_URL}/reviews/{}/mention/reply-tweet/`,
  REVIEW_QA: `${SERVER_URL}/reviews/{}/questions/`,
  REVIEW_QA_REPLY: `${SERVER_URL}/reviews/{}/{}/{}/answers/`,
  TAGS_API: `${SERVER_URL}/reviews/{}/{}/tags/`,
  NEGATIVE_REVIEWS: `${SERVER_URL}/reviews/{}/negative-reviews`,
  MENTION_DATA_DOWNLOAD: `${SERVER_URL}/reviews/{}/mention/`,
  CONTACT_TAGS_API: `${SERVER_URL}/reviews/{}/tags/group/`,

  CONTACT_TAGS_OTHER_API: `${SERVER_URL}/reviews/{}/contact/tags/`,
  GET_ALL_TAGS: `${SERVER_URL}/tags/{}/tag_groups/ `,
  ADD_EDIT_TAG_REVIEW: `${SERVER_URL}/tags/{}/review_aspects_attributes/`,
  GET_TAG_ACTIVITY: `${SERVER_URL}/tags/{}/review_aspects_attributes_logs/`,
  GET_PRODUCT_TAG: `${SERVER_URL}/menu/{}/product-reviews/update_opinion/`,
  SEARCH_TAG_CATEGORY: `${SERVER_URL}/tags/{}/search/category/`,
  SEARCH_TAG_ASPECT: `${SERVER_URL}/tags/{}/search/aspect/`,
  SEARCH_TAG_ATTRIBUTE: `${SERVER_URL}/tags/{}/search/attribute/`,

  // Zomato Login

  EMAIL_VERIFICATION: `${SERVER_URL}/accounts/zomato_automation/email_verification`,

  OTP_VERIFICATION: `${SERVER_URL}/accounts/zomato_automation/verify_otp`,

  // Reviews Ticket create

  CREATE_TICKET: `${SERVER_URL}/ticket/{}/`,
  SINGLE_TICKET_DETAILS: `${SERVER_URL}/ticket/{}/{}/`,
  TICKET: `${SERVER_URL}/ticket/`,
  GET_OPEN_TICKETS: `${SERVER_URL}/ticket/open-tickets/`,

  // Service Request Overview

  SERVICE_REQUEST_OVERVIEW: `${SERVER_URL}/ticket/{}/service_request_overview/`,

  // Service Ticket Create
  SERVICE_TICKET: `${SERVER_URL}/ticket/{}/service_request/`,
  EDIT_SERVICE_TICKET: `${SERVER_URL}/ticket/{}/service_request/{}/`,
  SERVICE_REQUEST_TYPE: `${SERVER_URL}/ticket/{}/service_request_types/`,
  SERVICE_UPDATE_REQUEST_TYPE: `${SERVER_URL}/ticket/{}/service_request_types/{}/`,
  SERVICE_REQUEST_COMMENTS: `${SERVER_URL}/ticket/{}/service_request_comments/{}/comment/`,

  //Payments
  PAYMENTS: `${SERVER_URL}/payments/{}/payments/`,
  PAYMENTS_INVOICE: `${SERVER_URL}/payments/{}/invoice/`,
  INVOICE_OPTIONS: `${SERVER_URL}/payments/{}/invoice/options/?id={}`,
  INVOICE_DOWNLOAD: `${SERVER_URL}/payments/{}/invoice/download/?invoice_id={}`,

  //messenger
  MESSENGER: `${SERVER_URL}/messenger/`,
  MESSENGER_CONVERSATION: `${SERVER_URL}/messenger/conversation/`,
  MESSENGER_ALL_CONVERSATION: `${SERVER_URL}/messenger/all-conversation/`,
  MESSENGER_CONVERSATION_MESSAGES: `${SERVER_URL}/messenger/conversation-messages/`,
  MESSENGER_CREATE_CONTACT: `${SERVER_URL}/messenger/create-contact/`,
  MESSENGER_ASSIGN_CONVERSATION: `${SERVER_URL}/messenger/assign-conversation/`,
  MESSENGER_SOCIAL_STATUS: `${SERVER_URL}/messenger/social-status`,

  //survey question submit
  SURVEY_QUESTION_SUBMIT: `${SERVER_URL}/reviews/submit-survey-question/`,
  INTELIGENT_RESPONSE: `${SERVER_URL}/reviews/intelligent-response`,
  AGENCY_USERS: `${SERVER_URL}/business/agency-users/`,
  TEAM_MAPPING: `${SERVER_URL}/accounts/{}/agency-staff/`,
  AGENCY_STAFF_DASHBOARD: `${SERVER_URL}/accounts/agency-staff-dashboard/`,
  ASSIGN_MULTI_LOCATIONS: `${SERVER_URL}/business/assign-multi-locations/`,
  BRANCH_SETUP_PLAYSTORE: `${SERVER_URL}/social/PlayStorebranchSetup/`,
  //Google post Scheduler
  SOCIAL_POST: `${SERVER_URL}/social`,
  //Coupon codes
  COUPON_SAMPLE: `${SERVER_URL}/business/sample-coupon-file/`,
  COUPON_LIST: `${SERVER_URL}/business/{}/coupon-list`,
  COUPON_UPLOAD_CSV: `${SERVER_URL}/business/{}/coupon-list/upload_csv/`,
  COUPON_SUMMARY: `${SERVER_URL}/business/{}/coupon-summary/`,

  SOURCE_SYSTEM_ORDER_DETAIL: `${SERVER_URL}/reviews/{}/source-system-order-details/`,

  // Ticket Resolution
  TICKET_RESOLUTION: `${SERVER_URL}/ticket/{}/ticket-resolution/`,
  TICKET_RESOLUTION_RESPONSE: `${SERVER_URL}/ticket/{}/resolution/{}/resolution-response/`,
  SUBMIT_TICKET_RESOLUTION: `${SERVER_URL}/ticket/submit-resolution/`,
  SUBMIT_MAPPING_QUESTIONS: `${SERVER_URL}/ticket/submit-product-mapping/`,

  // listing
  GMB_LISTING_LOCATION: `${SERVER_URL}/gmb-listing`,
  SOCIAL_PROFILE_INTEGRATION: `${SERVER_URL}/socialprofile/`,
  PERFORMANCE_LISTING_COUNT: `${SERVER_URL}/gmb-listing/{}/performance/overall-count/`,
  PERFORMANCE_LISTING_POPULAR_TIMES: `${SERVER_URL}/gmb-listing/{}/popular-times/`,
  PERFORMANCE_LISTING: `${SERVER_URL}/gmb-listing/{}/performance/metrics/`,

  // department
  GET_DEPARTMENT: `${SERVER_URL}/business/{}/department/`,
  DEPARTMENT: `${SERVER_URL}/business/{}/department/{}/`,
  GET_DESIGNATION: `${SERVER_URL}/business/{}/designation/`,
  DESIGNATION: `${SERVER_URL}/business/{}/designation/{}/`,

  // Ticket escalation
  GET_TICKET_ESCALATION: `${SERVER_URL}/ticket/{}/ticket_escalation_rules/`,
  TICKET_ESCALATION: `${SERVER_URL}/ticket/{}/ticket_escalation_rules/{}/`,

  //Review Widget
  REVIEW_WIDGET: `${SERVER_URL}/reviews/{}/widget/text_reviews/`,
  PROVIDER_WIDGET: `${SERVER_URL}/reviews/{}/widget/count_reviews/`,
  FAME_AI_CATEGORY_LIST: `${SERVER_URL}/tags/{}/tags_data/`,
  PRODUCT_NAME_LIST: `${SERVER_URL}/menu/{}/product/product_list/`,

  //Social Listing
  SOCIAl_PAGE_LOCATION_MAPPING: `${SERVER_URL}/socialprofile/page-location-mapping`,
  SOCIAL_PROFILE_COMMENTS: `${SERVER_URL}/socialprofile/facebook/comments/`,
  SAVE_COMMENTS: `${SERVER_URL}/socialprofile/facebook/publish/`,
  REPLY_COMMENTS: `${SERVER_URL}/socialprofile/facebook/comment/reply/`,
  DELETE_COMMENTS: `${SERVER_URL}/socialprofile/facebook/comment/delete/`,
  EDIT_COMMENTS: `${SERVER_URL}/socialprofile/facebook/comment/reply/edit/`,

  //dashboard-new
  // RATING_DATA: `${SERVER_URL}/business/{}/dashboard/`,
  TAGS_DATA: `${SERVER_URL}/tags/{}/tags_data/performance/`,
  REVIEW_PERFORMANCE: `${SERVER_URL}/reviews/{}/review-performance/`,
  REVIEW_COMPLAINTS: `${SERVER_URL}/reviews/{}/reviews-complaint/complaint_count/`,
  MENU_PERFORMANCE: `${SERVER_URL}/menu/{}/menu-analytics/top_bottom/`,

  REFUND_PROCESS: `${SERVER_URL}/reviews/refund_process/`,
  RESTRO_PILOT: `${SERVER_URL}/restropilot/{}/funnel/`,
  SALES_PAGE: `${SERVER_URL}/restropilot/{}/sales/`,
  OFFER_DISCOUNTS_PAGE: `${SERVER_URL}/restropilot/{}/discount/`,
  ADS_PAGE: `${SERVER_URL}/restropilot/{}/ads/`,
  ADS_ENGAGEMENT: `${SERVER_URL}/restropilot/{}/ads/engagement_level/`,
  ADS_ENGAGEMENT_TABLE: `${SERVER_URL}/restropilot/{}/ads/table_engagement/`,
  ADS_ENGAGEMENT_DOWNLOAD: `${SERVER_URL}/restropilot/{}/ads/download_engagement_level/`,
  ADS_DIETARY: `${SERVER_URL}/restropilot/{}/ads/dietary_preference/`,
  ADS_DIETARY_TABLE: `${SERVER_URL}/restropilot/{}/ads/table_dietary/`,
  ADS_DIETARY_DOWNLOAD: `${SERVER_URL}/restropilot/{}/ads/download_dietary_preference/`,
  ADS_SPEND: `${SERVER_URL}/restropilot/{}/ads/spending_potential/`,
  ADS_SPEND_TABLE: `${SERVER_URL}/restropilot/{}/ads/table_spending/`,
  ADS_SPEND_DOWNLOAD: `${SERVER_URL}/restropilot/{}/ads/download_spending_potential/`,
  ADS_ROI_TIME_SLOT: `${SERVER_URL}/restropilot/{}/ads/roi/`,
  ADS_ROI_TIME_SLOT_DOWNLOAD: `${SERVER_URL}/restropilot/{}/ads/download_roi/`,
  FILTER_LAST_SYNC: `${SERVER_URL}/restropilot/{}/out-of-stock-monitor/last_synced/`,
  FILTER_OUT_OF_STOCK_ITEMS: `${SERVER_URL}/restropilot/{}/out-of-stock-monitor/items/`,
  MENU_OUT_OF_STOCK_MONITOR_DOWNLOAD: `${SERVER_URL}/restropilot/{}/out-of-stock-monitor/download/`,
  MENU_OUT_OF_STOCK_MONITOR: `${SERVER_URL}/restropilot/{}/out-of-stock-monitor/`,
  MENU_OUT_OF_STOCK_MONITOR_REPORT: `${SERVER_URL}/restropilot/{}/out-of-stock-monitor/report/`,
  MENU_OUT_OF_STOCK_MONITOR_REPORT_DOWNLOAD: `${SERVER_URL}/restropilot/{}/out-of-stock-monitor/report_download/`,
  ADS_CAMPAIGN_ALL: `${SERVER_URL}/restropilot/{}/camp/all/`,
  ADS_CAMPAIGN_ACTIVE: `${SERVER_URL}/restropilot/{}/camp/active/`,
  ADS_CAMPAIGN_COMPLETED: `${SERVER_URL}/restropilot/{}/camp/completed/`,

  // OFFLINE STORE ALERTS
  OFFLINE_FILTER_LAST_SYNC: `${SERVER_URL}/restropilot/{}/offline-business-monitor/last_synced/`,
  OFFLINE_STORE_DATA: `${SERVER_URL}/restropilot/{}/offline-business-monitor/`,
  OFFLINE_STOCK_ITEM: `${SERVER_URL}/restropilot/{}/offline-business/`,
  TICKET_SEND_MAIL: `${SERVER_URL}/ticket/{}/{}/send_email/`,
  SALES_TREND_OVERTIME: `${SERVER_URL}/restropilot/{}/sales-trend/overtime/`,
  SALES_TREND_LOCATION: `${SERVER_URL}/restropilot/{}/sales-trend/location/`,
  ORDERS_TREND_OVERTIME: `${SERVER_URL}/restropilot/{}/order-trend/overtime/`,
  ORDERS_TREND_LOCATION: `${SERVER_URL}/restropilot/{}/order-trend/location/`,

  CUSTOMER_FUNNEL_SAMPLE_DOWNLOAD: `${SERVER_URL}/restropilot/{}/funnel/sample_file_download/`,
  CUSTOMER_FUNNEL_BULK_UPLOAD: `${SERVER_URL}/restropilot/{}/funnel/upload_csv/`,

  // Review Email
  REVIEWS_EMAIL: `${SERVER_URL}/msgs/{}/email/`,
  REVIEWS_EMAIL_DETAILS: `${SERVER_URL}/msgs/{}/email/thread/`,
  REVIEWS_SEND_EMAIL: `${SERVER_URL}/msgs/{}/email/send_email/`,

  // WhatsApp section API
  WHATSAPP_CONTACT_LIST : `${SERVER_URL}/whatsapp/{}/`,
  WHATSAPP_CONTACT_CON: `${SERVER_URL}/whatsapp/{}/conversation/`,
  WHATSAPP_SOCKET_URL: `${SERVER_WEBSOCKET_URL}/{}/whatsapp-chat/`,
};
