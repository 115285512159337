import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError, formValueSelector, FieldArray } from "redux-form";
import { Row, Col } from "reactstrap";
import {
  // Button, Form, Grid, Header, Segment, Icon, Popup,
  Divider,
  Form,
} from "semantic-ui-react";
// import { InputField } from "react-semantic-redux-form";

import ToggleComponent from "../../common/ToggleComponent";
import SelectComponent from "../../common/SelectComponent";
import PhoneInputField from "../../common/phonefields";
import * as utils from "../../../libs/utils";
import AvatarIcon from "../../../images/user-icon.png";
import EmailBox from "../../EmailBox/EmailBox.component";
import { FPButton } from "../../common/new";
import { Box } from "@material-ui/core";
import FPLabel from "../../common/new/forms/ui/FPLabel";
import dragDropIcon from "../../../images/upoad_Drag.png";
import deleteIcon from "../../../images/delete-circle.png";
import InputComponent from "../../common/InputComponent";

// form validation functions

const validate = (values) => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = "Enter First Name";
  } else if (!/^[a-zA-Z][a-zA-Z ]+$/i.test(values.first_name)) {
    errors.first_name = "Invalid First Name";
  }
  if (!values.last_name) {
    errors.last_name = "Enter Last Name";
  } else if (!/^[a-zA-Z][a-zA-Z ]+$/i.test(values.last_name)) {
    errors.last_name = "Invalid Last Name";
  }
  if (!values.email) {
    errors.email = "Enter Email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.contact_number) {
    errors.contact_number = "Enter Mobile Number";
  }

  if (values.communication_emails && values.communication_emails.length) {
    let emailArrayErrors = [],
      emailDuplicateCheck = {};
    values.communication_emails &&
      values.communication_emails.map((email, index) => {
        let emailError = [];
        if (!email || !email.length) {
          emailArrayErrors[index] = "Enter Email";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(email)) {
          emailArrayErrors[index] = "Invalid email address";
        }
        if (emailDuplicateCheck[email]) {
          emailArrayErrors[index] = "Email already entered";
        } else {
          emailDuplicateCheck[email] = 1;
        }
        if (values.communication_emails.length > 5) {
          emailArrayErrors[index] = emailError;
        }
        return emailError;
      });

    if (emailArrayErrors.length) {
      errors.communication_emails = emailArrayErrors;
    }
  }
  return errors;
};

// profile form for the user

class ProfileForm extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  saveProfile = (data) => {
    const newData = { ...data };
    if (typeof data.ratings_alert === "string") newData.ratings_alert = data.ratings_alert.split(",");
    if (typeof data.web_portal === "string") newData.web_portal = data.web_portal.split(",");
    if (typeof data.alerts_schedule === "string") newData.alerts_schedule = data.alerts_schedule.split(",");
    if (!data.web_portal) {
      newData.web_portal = [];
    }
    if (!data.ratings_alert) newData.ratings_alert = [];
    delete newData["avtar"];
    return this.props
      .profileSave(newData)
      .then((res) => {
        this.props.goBack();
      })
      .catch((err) => {
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
  };

  uploadImage = (event) => {
    event.preventDefault();
    const userImage = event.target.files[0];
    if (!userImage.name.match(/.(jpg|jpeg|png|gif)$/i)) {
      utils.displayMessage("negative", "Please upload a valid Image file");
      return;
    }
    if (userImage.size > 3150000) {
      utils.displayMessage("negative", "Image too large");
      return;
    }
    this.props
      .uploadImage(userImage)
      .then(() => {
        utils.displayMessage("positive", "Profile Picture Changed");
      })
      .catch(() => {
        utils.displayMessage("negative", "Error!");
      });
  };

  prevent = (e) => {
    e.preventDefault();
  };

  refreshPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  renderEmail = ({ fields }) => {
    // console.log(fields);
    // return (
    //   <div>
    //     {fields ? fields.map((email, index) => {
    //       return (
    //         <div key={email+index}>
    //           <Row>
    //             <Col sm="11" className="mb-1">
    //               <Field
    //                 name={email}
    //                 type="text"
    //                 component={EmailBox}
    //                 {...{
    //                   fields: fields
    //                 }}
    //               >
    //               </Field>
    //             </Col>
    //           </Row>
    //         </div>
    //       );
    //     }) : 'no field'}
    //     {error && <p className="text-danger">{error}</p>}

    //     {/* <Button
    //       className="mt-2 mb-2"
    //       type="text"
    //       onClick={(e) => {
    //         fields.push();
    //         this.prevent(e);
    //       }}
    //       disabled={fields.length === 5}
    //     >
    //       Add Email
    //     </Button> */}
    //   </div>
    // );
    return (
      <div className="col-12 p-0">
        <Field
          name="email"
          type="text"
          component={EmailBox}
          {...{
            fields: fields,
          }}
        ></Field>
      </div>
    );
  };

  render() {
    const {
      scheduleOptions,
      hideHeading,
      handleSubmit,
      submitting,
      errors,
      isEnabledEmail,
      initialValues,
      showEmailAlerts,
      showScheduleAlerts,
      headingText,
      goBack,
      enterpriseList
    } = this.props;
    return (
      <>
        {/* <Grid
          textAlign="center"
          verticalAlign="middle"
          className="profile-form"
        >
          <Grid.Column>
            {!hideHeading && (
              <Header as="h2" color="teal" textAlign="center">
                Tell us about yourself so we can personalize your experience.
              </Header>
            )}
            <Form
              onSubmit={handleSubmit(this.saveProfile)}
              className="grid-form"
            >
              {errors && errors.non_field_errors
                ? errors.non_field_errors.map((error, index) => (
                    <p key={index} className="text-danger">
                      {error}
                    </p>
                  ))
                : null}
              <Segment>
                <Grid>
                  <Grid.Row columns={1} divided>
                    <Grid.Column>
                      <div className="user-profile-container">
                        <img
                          className="user-profile-avatar"
                          src={initialValues.avtar || AvatarIcon}
                        />
                        <label className="overlay" htmlFor="file">
                          <Icon name="camera" size="small" />
                          Edit
                          <input
                            id="file"
                            hidden
                            type="file"
                            accept=".jpg, .jpeg, .png, .gif"
                            onChange={(e) => this.uploadImage(e)}
                          />
                        </label>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="m-0 pt-0">
                    <Grid.Column textAlign="left">
                      <div className="border p-2 pic-upload-info">
                        <p>
                          <strong>Tip</strong>: Your profile picture should be a
                          PNG, JPG, or GIF file under 3 MB in size. For the best
                          quality rendering, we recommend keeping the image at
                          about 500 by 500 pixels.
                        </p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Segment stacked textAlign="left">
                <Row className="px-2 py-2">
                  <Col xs={12} sm={5}>
                    <Field
                      name="first_name"
                      type="text"
                      component={InputField}
                      label="First Name"
                      placeholder="First Name"
                      serverError={errors && errors.first_name}
                      required
                    />
                    <Field
                      name="last_name"
                      type="text"
                      component={InputField}
                      label="Last Name"
                      placeholder="Last Name"
                      serverError={errors && errors.last_name}
                      required
                    />
                    <Field
                      name="email"
                      type="text"
                      component={InputField}
                      label="Email"
                      placeholder="Email"
                      disabled={initialValues.email}
                      serverError={errors && errors.email}
                    />

                    <Field
                      name="contact_number"
                      type="text"
                      component={PhoneInputField}
                      label="Contact Number"
                      placeholder="Contact Number"
                      serverError={errors && errors.contact_number}
                      required
                    />
                  </Col>
                  <Col xs={12} sm={7}>
                    {showScheduleAlerts && (
                      <Field
                        name="alerts_schedule"
                        label="Schedule Alerts"
                        component={SelectComponent}
                        labelKey="display_name"
                        valueKey="value"
                        options={scheduleOptions && scheduleOptions.choices}
                        serverError={errors && errors.alerts_schedule}
                        multi
                        simpleValue
                        required
                        disabled={!isEnabledEmail}
                      />
                    )}
                    {showEmailAlerts && (
                      <Field
                        name="email_alert"
                        label="Enable Email Alerts"
                        component={ToggleComponent}
                        serverError={errors && errors.email_alert}
                      />
                    )}
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        marginBottom: "1rem",
                      }}
                    >
                      <span>Communication Emails</span>
                      <span>
                        <Popup
                          content={
                            <div>
                              Please add email id to get all updates of reports
                            </div>
                          }
                          trigger={<Icon name="help circle"></Icon>}
                        ></Popup>
                      </span>
                    </div>
                    <FieldArray
                      name="communication_emails"
                      component={this.renderEmail}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={8} sm={4} className="mt-3">
                    <Form.Field
                      control={Button}
                      color="teal"
                      fluid
                      type="submit"
                      disabled={submitting}
                      onClick={() => this.refreshPage()}
                    >
                      Submit
                    </Form.Field>
                  </Col>
                </Row>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid> */}

        <Form onSubmit={handleSubmit(this.saveProfile)} className="grid-form mt-3">
          {errors && errors.non_field_errors
            ? errors.non_field_errors.map((error, index) => (
                <p key={index} className="text-danger">
                  {error}
                </p>
              ))
            : null}
          <div className="d-flex justify-content-between align-items-center mt-2 px-3">
            <span
              style={{
                fontSize: "17px",
                fontWeight: "500",
                color: "#101828",
              }}
            >
              {headingText}
            </span>
            <div className="d-flex gap-15">
              <FPButton size="small" variant="outline" onClick={() => goBack()} label="Cancel" />
              <FPButton
                label="Submit"
                size="small"
                type="submit"
                disabled={submitting}
                // onClick={() => this.refreshPage()}
              />
            </div>
          </div>
          <Divider />
          <div className="col-12 d-flex mt-4">
            <div className="col-3 d-flex flex-column">
              <Box
                component={"span"}
                sx={{
                  textAlign: "justify",
                  color: "#1B1C1D",
                  fontSize: "16px",
                }}
              >
                Your photo
              </Box>
              <Box component={"span"} sx={{ textAlign: "justify", fontSize: "14px" }}>
                This will be displayed on your profile.
              </Box>
            </div>
            <div className="col-9 d-flex gap-20">
              <img
                className="user-profile-avatar"
                src={enterpriseList?.logo || AvatarIcon}
                style={{
                  width: "70px",
                  height: "70px",
                  position: "relative",
                  borderRadius: "50%",
                  border: "1px solid #EAECF0",
                }}
              />
              <img
                src={deleteIcon}
                style={{
                  height: "26px",
                  width: "26px",
                  position: "absolute",
                  marginLeft: "46px",
                  marginTop: "42px",
                }}
              />
              <label
                className="text-center"
                style={{
                  width: "100%",
                  // minHeight: "50px",
                  border: "1px solid #D0D5DD",
                  backgroundColor: "",
                  padding: "25px 0",
                  cursor: "pointer",
                  borderRadius: "8px",
                }}
              >
                <div>
                  <img src={dragDropIcon} />
                </div>
                <Box className="">
                  <Box component={"span"} className="text-center" sx={{ fontSize: "14px" }}>
                    <Box component={"span"} sx={{ color: "#00B5AD", fontWeight: 500 }}>
                      Click to upload
                    </Box>{" "}
                    or drag and drop
                  </Box>

                  <Box component={"span"} className="d-block">
                    SVG, PNG, JPG or GIF (max. 800x400px)
                  </Box>
                </Box>
                <input
                  id="file"
                  hidden
                  type="file"
                  accept=".jpg, .jpeg, .png, .gif"
                  onChange={(e) => this.uploadImage(e)}
                />
              </label>
            </div>
          </div>
          <div className="px-3">
            <Divider />
            <div className="row">
              <div className="col-12 col-md-6">
                <FPLabel>First Name</FPLabel>
                <Field
                  name="first_name"
                  type="text"
                  component={InputComponent}
                  placeholder="First Name"
                  serverError={errors && errors.first_name}
                  required
                />
              </div>
              <div className="col-12 col-md-6">
                <FPLabel>Last Name</FPLabel>
                <Field
                  name="last_name"
                  type="text"
                  component={InputComponent}
                  placeholder="Last Name"
                  serverError={errors && errors.last_name}
                  required
                />
              </div>
            </div>
            <Divider className="mt-0" />
            <div className="row">
              <div className="col-12 col-md-6">
                <FPLabel isRequired={false}>Email</FPLabel>
                <Field
                  name="email"
                  type="text"
                  component={InputComponent}
                  placeholder="Email"
                  disabled={initialValues.email}
                  serverError={errors && errors.email}
                  style={{
                    border: "0.5px solid #BFC2CB",
                    borderRadius: "10px",
                    background: "#BFC2CB",
                  }}
                />
              </div>
              <div className="col-12 col-md-6">
                <FPLabel>Contact Number</FPLabel>
                <Field
                  name="contact_number"
                  type="text"
                  component={PhoneInputField}
                  placeholder="Contact Number"
                  serverError={errors && errors.contact_number}
                  required
                />
              </div>
            </div>
            <Divider className="mt-0" />
            <div className="my-4 row">
              <div className="col-12 col-md-6">
                <FPLabel>Communication Emails</FPLabel>
                <FieldArray name="communication_emails" component={this.renderEmail} />
              </div>
              <div className="col-12 col-md-6">
                {showScheduleAlerts && (
                  <>
                    <FPLabel required={false}>Schedule Alerts</FPLabel>
                    <Field
                      name="alerts_schedule"
                      component={SelectComponent}
                      labelKey="display_name"
                      valueKey="value"
                      options={scheduleOptions && scheduleOptions.choices}
                      serverError={errors && errors.alerts_schedule}
                      multi
                      simpleValue
                      required
                      disabled={!isEnabledEmail}
                    />
                  </>
                )}
                {showEmailAlerts && (
                  <div className="d-flex gap-20 mt-2 align-item-center">
                    <FPLabel required={false} className="">
                      Enable Email Alerts
                    </FPLabel>
                    <Field
                      name="email_alert"
                      component={ToggleComponent}
                      serverError={errors && errors.email_alert}
                      type="checkbox"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Form>
      </>
    );
  }
}

let ProfileFormComponent = reduxForm({
  form: "ProfileForm",
  enableReinitialize: true,
  validate,
})(ProfileForm);
const selector = formValueSelector("ProfileForm");

ProfileFormComponent = connect(
  (state) => ({
    isEnabledEmail: selector(state, "email_alert"),
    isEmptyAlert: selector(state, "alerts_schedule"),
    scheduleOptions: state.common.schedules,
  }) // bind account loading action creator
)(ProfileFormComponent);
export default ProfileFormComponent;
