import * as types from '../../actions/actionTypes';

export default function ticketEscalationReducer(state=[], action) {
  switch (action.type) {
    case types.CREATE_TICKET_ESCALATION_SUCCESS:
      return action.data;
    case types.CREATE_TICKET_ESCALATION_FAILURE:
      return action.data;
    case types.GET_TICKET_ESCALATION_SUCCESS:
      return Object.assign({}, state, { detail: action.data });
    case types.GET_TICKET_ESCALATION_FAILURE:
      return Object.assign({}, state, { detail: action.data });
    case types.EDIT_TICKET_ESCALATION_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.EDIT_TICKET_ESCALATION_FAILURE:
      return Object.assign({}, state, action.data);
    case types.DELETE_TICKET_ESCALATION_SUCCESS:
      return Object.assign({}, state, { detail: action.data });
    case types.DELETE_TICKET_ESCALATION_FAILURE:
      return Object.assign({}, state, { detail: action.data });
    default:
      return state;
  }
}