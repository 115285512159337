import axios_instance from '../../libs/interseptor';
import * as types from '../../actions/actionTypes';
import * as URLS from '../../libs/apiUrls';


const categoryPerformanceSucess = (data) =>{
  return {'type': types.CATEGORY_PERFORMANCE_SUCCESS, data:data};
};

const categoryPerformanceFailure = (data) => {
  return{'type':types.CATEGORY_PERFORMANCE_FAILURE, data:data};
};

export const categoryPerformance = (business_id, params) => {
  return (dispatch) => {
    return axios_instance.get(URLS.TAGS_DATA.replace('{}', business_id), {params: {...params}})
      .then((res) => {
        dispatch(categoryPerformanceSucess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(categoryPerformanceFailure(error));
        return error;
      });
  };
};

const topBottomLocationSucess = (data) =>{
  return {'type': types.TOP_BOTTOM_LOCATION_SUCCESS, data:data};
};

const topBottomLocationFailure = (data) => {
  return{'type':types.TOP_BOTTOM_LOCATION_FAILURE, data:data};
};

export const topBottomLocation = (business_id, params) => {
  return (dispatch) => {
    return axios_instance.get(URLS.TOP_BOTTOM_FIVE_LOCATIONS.replace('{}', business_id), {params: {...params}})
      .then((res) => {
        dispatch(topBottomLocationSucess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(topBottomLocationFailure(error));
        return error;
      });
  };
};

export function getOpenTicketSuccess(data) {
  return { type: types.GET_OPEN_TICKET_SUCCESS, data };
}

export function getOpenTicketFailure(data) {
  return { type: types.GET_OPEN_TICKET_FAILURE, data };
}

export const getOpenTicket = (id, filter) => {
  const url = `${URLS.GET_OPEN_TICKETS}?business_id=${id}`;
  return (dispatch) => {
    return axios_instance
      .get(url, { params: { ...filter } })
      .then((res) => {
        dispatch(getOpenTicketSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(getOpenTicketFailure(err));
        throw err.response.data;
      });
  };
};

export function clearDashboardApiDataSuccess(data=null) {
  return { type: types.CLEAR_DASHBOARD_API_SUCCESS, data };
}

export const clearDashboardApiData = () => {
  return async (dispatch) => {
    try {
      await dispatch(clearDashboardApiDataSuccess({}))
    } catch(err) {
      console.log(err)
    }
  };
};


export function getWordCloudSuccess(data) {
  return { type: types.GET_WORD_CLOUD_SUCCESS, data };
}

export function getWordCloudFailure(data) {
  return { type: types.GET_WORD_CLOUD_FAILURE, data };
}

export const getWordCloud = (id, filters) => {
  const url = URLS.WORD_CLOUD.replace('{}', id);
  return (dispatch) => {
    return axios_instance
      .get(url, { params: { ...filters } })
      .then((res) => {
        dispatch(getWordCloudSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(getWordCloudFailure(err));
        throw err.response.data;
      });
  };
};