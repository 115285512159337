import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
// import * as commonAction from "../common/commonAction";
import * as utils from "../../libs/utils";
// import cookie from "react-cookies";

export function getEngagementTableOvertimeSuccess(data) {
  return { type: types.GET_ENGAGEMENT_TABLE_OVERTIME_SUCCESS, data };
}

export function getEngagementTableOvertimeFailure(error) {
  let data = {};
  data.errors = error;
  return { type: types.GET_ENGAGEMENT_TABLE_OVERTIME_FAILURE, data };
}

export const getEngagementTableOvertime = (data) => {
  const { ...params } = data;
  return async (dispatch) => {
    return axios_instance.get(`${utils.format(URLS.ADS_ENGAGEMENT_TABLE, [data.business_id])}`, {params})
      .then((res) => {
        dispatch(getEngagementTableOvertimeSuccess(res.data));
      }).catch((err) => {
        dispatch(getEngagementTableOvertimeFailure(err));
      });
  }
}

export function getEngagementTableLocationSuccess(data) {
  return { type: types.GET_ENGAGEMENT_TABLE_LOCATION_SUCCESS, data };
}

export function getEngagementTableLocationFailure(error) {
  let data = {};
  data.errors = error;
  return { type: types.GET_ENGAGEMENT_TABLE_LOCATION_FAILURE, data };
}

export const getEngagementTableLocation = (data) => {
  const { ...params } = data;
  return async (dispatch) => {
    return axios_instance.get(`${utils.format(URLS.ADS_ENGAGEMENT_TABLE, [data.business_id])}`, {params})
      .then((res) => {
        dispatch(getEngagementTableLocationSuccess(res.data));
      }).catch((err) => {
        dispatch(getEngagementTableLocationFailure(err));
      });
  }
}

export function getSpendingTableOvertimeSuccess(data) {
  return { type: types.GET_SPENDING_TABLE_OVERTIME_SUCCESS, data };
}

export function getSpendingTableOvertimeFailure(error) {
  let data = {};
  data.errors = error;
  return { type: types.GET_SPENDING_TABLE_OVERTIME_FAILURE, data };
}

export const getSpendingTableOvertime = (data) => {
  const { ...params } = data;
  return async (dispatch) => {
    return axios_instance.get(`${utils.format(URLS.ADS_SPEND_TABLE, [data.business_id])}`, {params})
      .then((res) => {
        dispatch(getSpendingTableOvertimeSuccess(res.data));
      }).catch((err) => {
        dispatch(getSpendingTableOvertimeFailure(err));
      });
  }
}

export function getSpendingTableLocationSuccess(data) {
  return { type: types.GET_SPENDING_TABLE_LOCATION_SUCCESS, data };
}

export function getSpendingTableLocationFailure(error) {
  let data = {};
  data.errors = error;
  return { type: types.GET_SPENDING_TABLE_LOCATION_FAILURE, data };
}

export const getSpendingTableLocation = (data) => {
  const { ...params } = data;
  return async (dispatch) => {
    return axios_instance.get(`${utils.format(URLS.ADS_SPEND_TABLE, [data.business_id])}`, {params})
      .then((res) => {
        dispatch(getSpendingTableLocationSuccess(res.data));
      }).catch((err) => {
        dispatch(getSpendingTableLocationFailure(err));
      });
  }
}


// Dietary
export function getDietaryTableOvertimeSuccess(data) {
  return { type: types.GET_DIETARY_TABLE_OVERTIME_SUCCESS, data };
}

export function getDietaryTableOvertimeFailure(error) {
  let data = {};
  data.errors = error;
  return { type: types.GET_DIETARY_TABLE_OVERTIME_FAILURE, data };
}

export const getDietaryTableOvertime = (data) => {
  const { ...params } = data;
  return async (dispatch) => {
    return axios_instance.get(`${utils.format(URLS.ADS_DIETARY_TABLE, [data.business_id])}`, {params})
      .then((res) => {
        dispatch(getDietaryTableOvertimeSuccess(res.data));
      }).catch((err) => {
        dispatch(getDietaryTableOvertimeFailure(err));
      });
  }
}

export function getDietaryTableLocationSuccess(data) {
  return { type: types.GET_DIETARY_TABLE_LOCATION_SUCCESS, data };
}

export function getDietaryTableLocationFailure(error) {
  let data = {};
  data.errors = error;
  return { type: types.GET_DIETARY_TABLE_LOCATION_FAILURE, data };
}

export const getDietaryTableLocation = (data) => {

  const { ...params } = data;
  return async (dispatch) => {
    return axios_instance.get(`${utils.format(URLS.ADS_DIETARY_TABLE, [data.business_id])}`, {params})
      .then((res) => {
        dispatch(getDietaryTableLocationSuccess(res.data));
      }).catch((err) => {
        dispatch(getDietaryTableLocationFailure(err));
      });
  }
}