import React, { useState, useEffect } from "react";
import { Card, CardTitle, Col } from "reactstrap";
import { Checkbox, Divider } from "semantic-ui-react";
import { Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import "./reports.css";
import SurveyTextualTableQuestion from "./SurveyTextualTableQuestion";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles } from "@material-ui/core/styles";
import jsonata from "jsonata";
import { NoData } from "../common/new";

const query = `
{
  "groupedQuestions": $reduce(
    $distinct($.question),
    function ($accumulator, $question) {
      $append($accumulator, {
        "question": $question,
        "details": $[question=$question] {
          "option": option
        }
      })
    },
    []
  )
}
`;

const branchQuery = `{
  "groupedQuestions": [$map($distinct($.branch), function($branch) {
    {
      "branch": $branch,
      "details": [$map(
        $distinct($[branch=$branch].question), 
        function($question) {
          {
            "question": $question,
            "options": $distinct($[branch=$branch][question=$question].option)
          }
        }
)]
    }
  })]
}`;

const pageQuery = `{
  "groupedQuestions": [$map($distinct($.page), function($page) {
    {
      "page": $page,
      "details": [$map(
        $distinct($[page=$page].question), 
        function($question) {
          {
            "question": $question,
            "options": $distinct($[page=$page][question=$question].option)
          }
        }
      )]
    }
  })]
}`;

const bothQuery = `{
  "groupedQuestions": [$map(
  $distinct($.branch),
  function($branch) {
    {
      "branch": $branch,
      "details": [$map(
        $distinct($[branch=$branch].page),
        function($page) {
          {
            "page": $page,
            "details": [$map(
              $distinct($[branch=$branch][page=$page].question),
              function($question) {
                {
                  "question": $question,
                  "options": $distinct($[branch=$branch][page=$page][question=$question].option)
                }
              }
            )]
          }
        }
      )]
    }
  }
)]
}`;

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    position: "unset",
  },
  expanded: {
    margin: "0 !important",
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(146, 202, 206, 1)",
    boxShadow: "none",
    border: "unset",
    borderRadius: "5px",
    height: "45px !important",
    minHeight: "auto !important",
    "& .MuiSvgIcon-root.minus": {
      display: "none",
    },
    "& .MuiSvgIcon-root.add": {
      display: "block",
    },
    "&.Mui-expanded": {
      "& .MuiSvgIcon-root.minus": {
        display: "block !important",
      },
      "& .MuiSvgIcon-root.add": {
        display: "none !important",
      },
    },
  },
  content: {
    margin: "0px !important",
    fontSize: "14px",
    fontWeight: "400",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    gap: "3px",
    "& .MuiPaper-root": {
      margin: 0,
    },
  },
}))(MuiAccordionDetails);

const SurveyTextualTable = (props) => {
  const [groupedData, setGroupedData] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const { tableData, displayLevel, isDisplayBranchWise, isDisplayPageWise, toggleDisplayLevel } = props;

  const processData = async (data) => {
    const expression = jsonata(
      isDisplayBranchWise && isDisplayPageWise
        ? bothQuery
        : isDisplayBranchWise
        ? branchQuery
        : isDisplayPageWise
        ? pageQuery
        : query
    );
    const result = await expression.evaluate(data);
    setGroupedData(result.groupedQuestions);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    processData(tableData ? tableData.ques : {});
  }, [tableData]);

  return (
    <div>
      {groupedData && groupedData.length > 0 ? (
        <Card body className="pt-3 mb-3">
          <CardTitle className="mb-2 fw3">
            <div className="d-flex">
              <Col>Survey Report {displayLevel[0].toUpperCase() + displayLevel.slice(1)} Wise</Col>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between">
                  <Col style={{ display: "flex", alignItems: "", minWidth: "53%" }}>
                    <h6>Page Wise</h6>
                    <span style={{ marginLeft: "10px", marginTop: "-2px" }}>
                      <Checkbox
                        toggle
                        value="page"
                        checked={isDisplayPageWise}
                        onChange={(e, checked) => {
                          toggleDisplayLevel("page", e, checked);
                        }}
                      ></Checkbox>
                    </span>
                  </Col>
                  <Col style={{ display: "flex", alignItems: "", minWidth: "53%" }}>
                    <h6>Branch Wise</h6>
                    <span style={{ marginLeft: "10px", marginTop: "-2px" }}>
                      <Checkbox
                        toggle
                        value="branch"
                        checked={isDisplayBranchWise}
                        onChange={(e, checked) => {
                          toggleDisplayLevel("branch", e, checked);
                          setExpanded(false);
                        }}
                      ></Checkbox>
                    </span>
                  </Col>
                </div>
              </div>
            </div>
            <Divider></Divider>
          </CardTitle>

          {groupedData && groupedData.length ? (
            <Accordion expanded={expanded === "1"} onChange={handleChange("1")}>
              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                <div className="d-flex align-items-center">
                  <RemoveIcon className="minus" style={{ width: 15, height: 15 }} />
                  <AddIcon className="add" style={{ width: 15, height: 15 }} />
                  <Typography className="weight-500 ml-1" style={{ fontSize: "16px" }}>
                    All Branches
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className="flex-column p-0">
                <SurveyTextualTableQuestion
                  isDisplayBranchWise={isDisplayBranchWise}
                  isDisplayPageWise={isDisplayPageWise}
                  groupedData={groupedData}
                />
              </AccordionDetails>
            </Accordion>
          ) : null}
        </Card>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default SurveyTextualTable;
