import * as types from "../../actions/actionTypes";

export default function serviceTicketDetails(
  state = [],
  action
) {
  switch (action.type) {
    case types.SERVICE_TICKET_DETAILS_SUCCESS:
      return Object.assign({}, state, {serviceTickets: {...action.data}});
    case types.SERVICE_TICKET_DETAILS_FAILURE:
      return Object.assign({}, state, { errors: { ...state.errors, serviceTickets: { ...action.data } }});
    case types.SERVICE_REQUEST_TYPE_SUCCESS:
      return Object.assign({}, state, {serviceRequestType: {...action.data}});
    case types.SERVICE_REQUEST_TYPE_FAILURE:
      return Object.assign({}, state, { errors: { ...state.errors, serviceRequestType: { ...action.data } }});
    default:
      return state;
  }
}
