import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as commonAction from "../common/commonAction";

export const ServiceRequestOverviewSuccess = (data) => {
  return { type: types.SERVICE_REQUEST_OVERVIEW_SUCCESS, data };
};

export const ServiceRequestOverviewFailure = (data) => {
  return { type: types.SERVICE_REQUEST_OVERVIEW_FAILURE, data };
};

export const ServiceRequestOverview = (data, business_id) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.SERVICE_REQUEST_OVERVIEW, [business_id]), {
        params,
      })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(ServiceRequestOverviewSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ServiceRequestOverviewSuccess(err.response.data));
        throw err.response.data;
      });
  };
};
