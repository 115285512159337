import React from "react";
import PropTypes from "prop-types";

class App extends React.Component {
  render() {
    const isExcludedPath = [
      "/",
      "/login",
      "/register",
      "/forgot-password",
      "/register-successful",
      "/mail-sent-successful",
      "/accounts/password/reset",
      "/reset-password-success",
      `/${window.location.pathname.split("/")[1]}/branch-setup`,
      `/reviews/${window.location.pathname.split("/")[2]}/feedback/`,
      `/reviews/${window.location.pathname.split("/")[2]}/feedback`,
      `/reviews/${window.location.pathname.split("/")[2]}/new-feedback/`,
      `/reviews/${window.location.pathname.split("/")[2]}/new-feedback`,
    ].includes(window.location.pathname);
    const isResetPassswordPath = (window.location.pathname).includes('accounts/password/reset/')
    const isDensePedding = isExcludedPath || isResetPassswordPath; 
    const containerStyle = {
      padding: isDensePedding ? "0" : "75px 10px 0 10px",
    };
    return <div style={containerStyle}>{this.props.children}</div>;
  }
}

App.propTypes = {
  children: PropTypes.object.isRequired,
};

export default App;
