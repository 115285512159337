import React, { useEffect, useState } from "react";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";
import { TabContent, TabPane } from "reactstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SortIcon from "../../../images/svg/Sort.svg";
import { FPToolTip } from "../../../components/common/new";

// Mapping for user-friendly names
const nameMapping = {
  CALL_CLICKS: "Calls Made",
  BUSINESS_BOOKINGS: "Bookings Made",
  BUSINESS_DIRECTION_REQUESTS: "Directions Requests",
  WEBSITE_CLICKS: "Website Clicks",
  BUSINESS_FOOD_MENU_CLICKS: "Menu Views",
};

// Ordered keys for the table
const orderedKeys = [
  "CALL_CLICKS",
  "BUSINESS_BOOKINGS",
  "BUSINESS_DIRECTION_REQUESTS",
  "WEBSITE_CLICKS",
  "BUSINESS_FOOD_MENU_CLICKS",
];

function InteractionTable(props) {
  const { performanceNavTab, headerTitle, data, selectedBox } = props;
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedData, setSortedData] = useState({});

  useEffect(() => {
    setSortedData(data || {});
  }, [data]);


  if (!data || Object.keys(data).length === 0) {
    return (
      <CardWrapper headerTitle={headerTitle}>
        <p>No data available</p>
      </CardWrapper>
    );
  }

  const filteredData =
    selectedBox === "TOTAL_INTERACTIONS"
      ? sortedData
      : Object.fromEntries(
          Object.entries(sortedData).filter(
            ([_, values]) => values[selectedBox] !== undefined
          )
        );

  // Set the column headers based on the selectedBox
  const columnHeaders =
    selectedBox === "TOTAL_INTERACTIONS"
      ? orderedKeys // Use orderedKeys if TOTAL_INTERACTIONS is selected
      : [selectedBox];

  const handleSort = (header) => {
    const newOrder =
      sortColumn === header && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(header);
    setSortOrder(newOrder);

    const sorted = Object.entries(filteredData).sort(
      ([dateA, valuesA], [dateB, valuesB]) => {
        const valA = valuesA[header] || 0;
        const valB = valuesB[header] || 0;
        return newOrder === "asc" ? valA - valB : valB - valA;
      }
    );

    setSortedData(Object.fromEntries(sorted));
  };

  return (
    <div>
      <CardWrapper headerTitle={headerTitle}>
        <TabContent activeTab={performanceNavTab}>
          <TabPane tabId={performanceNavTab}>
            <div className="ui-table-responsive">
              <Table className="fp-table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center" }}
                      className={
                        selectedBox === "TOTAL_INTERACTIONS" && "width-md"
                      }
                    >
                      {headerTitle === "Overtime" ? "Date" : "Location"}
                    </TableCell>
                    {columnHeaders.map((header, index) => (
                      <TableCell key={index} style={{ textAlign: "center" }}>
                        <FPToolTip
                          title={
                            nameMapping[header] || header.replace(/_/g, " ")
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor:"pointer"
                            }}
                            onClick={() => handleSort(header)}
                          >
                            <span className="text-truncate width-sm">
                              {nameMapping[header] || header.replace(/_/g, " ")}
                            </span>
                            <span style={{ marginLeft: "5px" }}>
                              <SortIcon />
                            </span>
                          </div>
                        </FPToolTip>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(filteredData).map(([date, values]) => (
                    <TableRow key={date}>
                      <TableCell>
                        {selectedBox === "TOTAL_INTERACTIONS" ? (
                          <FPToolTip title={date}>
                            <div
                              style={{
                                textAlign: "center",
                              }}
                              className="d-inline-block text-truncate width-md"
                            >
                              {date}
                            </div>
                          </FPToolTip>
                        ) : (
                          <div style={{ textAlign: "center" }}>{date}</div>
                        )}
                      </TableCell>
                      {columnHeaders.map((header, index) => (
                        <TableCell key={index} style={{ textAlign: "center" }}>
                          {values[header] !== undefined ? values[header] : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </TabPane>
        </TabContent>
      </CardWrapper>
    </div>
  );
}

export default InteractionTable;
