import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import { connect } from "react-redux";
import LoaderComponent from "../../components/common/loaderComponent";
import { Col, Row } from "reactstrap";
import WhatsUpSideBar from "./components/WhatsUpSideBar";
import "./whatsAppPageStyles.css";
import WhatsUpChatComponent from "./components/WhatsUpChatComponent";
import * as whatsAppActions from "../../actions/whatsAppActions/whatsAppActions";
import { NoData } from "../../components/common/new";
import MessengerLoader from "../../components/messenger/Common/LoaderComponent";
import * as URLS from "../../libs/apiUrls";

const WhatsAppMessages = (props) => {
  const { location, match: { params: { business_id }, params, }, loader, action, contactList, contactChat } = props;
  // const { search } = location;
  const [selectedChat, setSelectedChat] = useState({});
  const [selectedChatUserData, setSelectedChatUserData] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [firstListLoader, setFirstListLoader] = useState(false);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [messages, setMessages] = useState([]);

  const selectedChatRef = useRef(selectedChat);

  useEffect(() => {
    selectedChatRef.current = selectedChat;
  }, [selectedChat]);

  useEffect(() => {
    setMessages(contactChat?.results || []);
  }, [contactChat]);

  useEffect(() => {
    props.actionsSelectTab.setSelectedTab(5);
    document.title = "WhatsApp Message";
    setFirstListLoader(true)
    action.WhatsAppListing(business_id, page).then((res) => {
      setFirstListLoader(false)
      if (res?.data && res?.data?.results) {
        setList(res?.data?.results);
        if (res?.data?.results.length > 0) setSelectedChat(res.data.results[0])
      }
      
    }).catch(() => setFirstListLoader(false));
  }, []);

  const ws = useRef(null);

  const updateListWithCount = (list, newMessage, selectedChat) => {
    return list.map((item) => {
      if (item?.contact_id === newMessage?.contact_id) {
        return { ...item, message: newMessage?.message, msg_time:newMessage?.msg_time, unseen: selectedChat ? 0 : (item.unseen || 0) + 1 };
      }
      return item;
    });
  };

  useEffect(() => {
    ws.current = new WebSocket(URLS.WHATSAPP_SOCKET_URL.replace('{}', business_id));

    ws.current.onopen = () => console.log("WebSocket Connected");

    ws.current.onmessage = (event) => {
      console.log("Received raw data:", event.data);
      try {
        const newMessage = JSON.parse(event.data);
        console.log("Received WebSocket Message:", newMessage);

        if (newMessage.type === "new message") {
          const userId = newMessage.id; 
          const isOpenChatMessage = selectedChatRef?.current?.contact_id === newMessage?.contact_id;

          if (isOpenChatMessage){
            setMessages((prevMessages) => ([
              newMessage,
              ...prevMessages,
            ]));
          }
          
          setList((prevList) => updateListWithCount(prevList, newMessage, isOpenChatMessage))
          
          if (selectedChatRef?.current?.contact_id === userId) {
            setSelectedChatUserData((prevChat) => ({
              ...prevChat,
              messages: [...(prevChat.messages || []), newMessage],
            }));
          }
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };

    ws.current.onerror = (error) => console.error("WebSocket Error:", error);

    ws.current.onclose = () => console.log("WebSocket Disconnected");

    return () => {
      ws.current.close();
    };
  }, []);

  const handleContactDetails = (data) => {
    setIsLoader(true);
    action.WhatAppDetails(data?.contact_id, business_id)
      .then(() => {
        setIsLoader(false);
        setList((prevList) => updateListWithCount(prevList, data , true))
      })
      .catch(() => setIsLoader(false));
    setSelectedChatUserData(data);
    setSelectedChat(data);
  };

  const fetchMoreData = () => {
    const nextPage = page + 1;
    action.WhatsAppListing(business_id, nextPage).then((res) => {
      if (res?.data && res?.data?.results) {
        setList((prevList) => [...prevList, ...res?.data?.results]);
        setPage(nextPage);
        if (!res?.data?.next) setHasMore(false);
      }
    });
  };

  return (
    <Row className="content-area">
      {firstListLoader ? (
        <LoaderComponent loader={firstListLoader} />
      ) : list && list.length !== 0 ? (
        <Col xs={12} sm={12} className="business-setup pt-3">
          <Row className="h-100">
            <Col sm="4" className="whats-app-left_side_bar">
              <WhatsUpSideBar
                list={list}
                selectedChat={selectedChat}
                handleContactDetails={handleContactDetails}
                fetchMoreData={fetchMoreData}
                hasMore={hasMore}
              />
            </Col>
            <Col sm="8">
              {isLoader ? (
                <div className="my-5">
                  <MessengerLoader showLoader={isLoader} />
                </div>
              ) : (
                <div className="right-sidebar-content">
                  <WhatsUpChatComponent
                    selectedChat={selectedChatUserData}
                    contactChat={messages || []}
                    isLoader={isLoader}
                    business_id={business_id}
                    ws={ws.current}
                  />
                </div>
              )}
            </Col>
            
          </Row>
        </Col>
      ) : (
        <NoData />
      )}
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
    contactList: state?.whatsAppSection?.contactList,
    contactChat: state?.whatsAppSection?.contactChat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    action: bindActionCreators(whatsAppActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WhatsAppMessages);
