import React, { useEffect } from "react";
import Prism from "prismjs";

const CodeHighlighter = ({ code }) => {
  useEffect(() => {
    Prism.highlightAll(); // Highlight all code blocks
  }, []);

  return (
    <div>
      <pre className="language-javascript custom-pre">
        <code className="language-javascript custom-code">{code}</code>
      </pre>
    </div>
  );
};

export default CodeHighlighter;
