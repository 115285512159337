import React, { useEffect, useState } from "react";
import Popover from "@material-ui/core/Popover";
import { FPButton, FPToolTip } from "../../components/common/new";
import filter from "../../images/Filter.png";
import { connect } from "react-redux";
import CompetitorsFilter from "../../components/competitors/CompetitorsFilter";
import queryString from "query-string";
import { Filter, NewFilter } from "../../images/svg";
import { bindActionCreators } from "redux";
import * as filterListActions from "../../actions/common/filterListActions";
import * as URLS from "../../libs/apiUrls";

const CompetitorFilterWrapper = (props) => {
  const {
    ratingOptions,
    brandOptions,
    competitorSourceOptions,
    match: {
      params: { business_id },
    },
    location,
    history,
    match,
    filter,
    filterAction,
  } = props;  
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchFilterList = async () => {
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch(
          "",
          `${URLS.BUSINESS_SETUP}${business_id}/branch/`
        ),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };
  useEffect(() => {
    fetchFilterList();
  }, []);

  const open = Boolean(anchorEl);

  const ratingOption = ratingOptions ? ratingOptions : {};
  const ratingsList =
    ratingOption.choices && ratingOption.choices.length
      ? ratingOption.choices.map((item) => ({
          text: item.display_name,
          value: item.value,
        }))
      : [];

  const handleCompetitorSearch = (params) => {
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = props;
    let query = queryString.parse(search);
    query = { business_id, params, ...query };
    delete query.businessId;
  };

  return (
    <div>
      {/* <FPToolTip title="Filter">
        <div className="filter-view" onClick={handleClick}>
          <Filter color="white" />
        </div>
      </FPToolTip>
      <FPButton
        label={"Filters"}
        startIcon={<img src={filter} alt="filter" />}
        backgroundColor="#1A2544"
        onClick={handleClick}
      /> */}
      <FPToolTip title="Filter">
        <div className="contact-filter-view" onClick={handleClick}>
          <NewFilter
            color="white"
            style={{
              height: "15px",
              width: "15px",
            }}
          />
        </div>
      </FPToolTip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={"rounded-popover"}
      >
        <CompetitorsFilter
          ratingOptions={ratingsList}
          brandOptions={brandOptions && brandOptions.choices}
          handleCompetitorSearch={handleCompetitorSearch}
          competitorSourceOptions={competitorSourceOptions}
          location={location}
          locationOptions={filter?.branch?.choices}
          match={match}
          history={history}
          dateOptions={filter?.date_type?.choices}
        />
      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ratingOptions: state.common.ratings,
  brandOptions: state.common.brand_options,
  competitorSourceOptions: state.competitors.competitor_source_options,
  filter: state.filter,
});

const mapDispatchToProps = (dispatch) => ({
  filterAction: bindActionCreators(filterListActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CompetitorFilterWrapper);
