// import * as types from '../actionTypes';
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as commonAction from "../common/commonAction";
import cookie from "react-cookies";

export const staffCsvUpload = (data) => {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const url = URLS.STAFF_CSV_UPLOAD;
  return () => {
    return axios_instance
      .post(url, data, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err.response.data;
      });
  };
};

export const downloadStaffTemplate = () => {
  const token = cookie.load("authToken");
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return fetch(URLS.SAMPLE_FILE_DOWNLOAD, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/csv",
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        dispatch(commonAction.hideLoader());
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "staff_sample.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        console.log(err);
        dispatch(commonAction.hideLoader());
      });
  };
};
