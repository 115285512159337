import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import PropTypes from 'prop-types';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: '50%',
    transform: 'translateY(-15px)',
    left: 'calc(-50% + 15px)',
    right: 'calc(50% + 15px)',
  },
  active: {
    '& $line': {
      backgroundColor: '#00B5AD',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#00B5AD',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#BFE0E2',
    borderRadius: 1,
  },
})(StepConnector);

const BaseStepLabel = withStyles({
  label:{
    marginTop: '5px !important'
  }
})(StepLabel);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ffffff',
    zIndex: 1,
    color: '#7A8193',
    width: 38,
    height: 38,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #BFC2CB',
  },
  active: {
    backgroundColor: '#00B5AD',
    color: '#ffffff',
    border: '1px solid #00B5AD',
  },
  completed: {
    backgroundColor: '#00B5AD',
    color: '#ffffff',
    border: '1px solid #00B5AD',
  },
  label:{
    lineHeight: 1,
    fontSize: 18,
    fontWeight: 600,
  }
});

function ColorlibStepIcon({ onClick, ...rest}) {
  const classes = useColorlibStepIconStyles();
  const { active, completed, icon } = rest;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
      role={completed && onClick ? 'button' : 'textbox'}
      onClick={() => completed && onClick ? onClick() : {}} 
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.label}>{icon}</div>}
    </div>
  );
}

export default function FPStepper(props) {
  const {activeStep, onStepClick, steps} = props;

  return (
    <div className='w-100'>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} style={{backgroundColor: 'transparent', padding: 0}}>
        {steps.map((label, index) => (
          <Step key={label}>
            <BaseStepLabel StepIconComponent={(props) => <ColorlibStepIcon {...props} onClick={() => onStepClick ? onStepClick(index) : {}} />}>{label}</BaseStepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

FPStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf((PropTypes.string).isRequired), // array of string
  onStepClick: PropTypes.func,
};