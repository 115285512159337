import React from "react";
import FPCheckbox from "../FPCheckbox";
import { snakeToCapital } from "../../../../libs/utils";
import CustomStarRatingComponent from "../../StarComponent";
import { starRatingColor } from "../../../../libs/utils";
import FPChip from "../FPChip/FPChip";
import FPBadge from "../FPBadge/FPBadge";

const CheckBoxWithTooltip = React.forwardRef(function MyComponent(props, ref) {
  const {
    option,
    arrayHelpers,
    handleOnChange,
    field,
    isNotEllipsisLabel,
    ticketFilter,
    ...rest
  } = props;
  const getStatusColor = (status) => {
    const statusColor = {
      in_queue: "#5826ef",
      new: option.parentKey === "customer_type" ? "#228B22": "#2375ef",
      assigned: "#f8ae00",
      resolved: "#17b26a",
      reopened: "#e16b16",
      waiting_for_customer: "#FFDC54",
      in_progress: "#0096FF",
      repeat: "#f8ae00",
      lapsed: "#e16b16",
    };
    return statusColor[status];
  };
  const { star_border_color, star_bg_color } = starRatingColor(option.value);
  return (
    <div {...rest} ref={ref}>
      <FPCheckbox
        checked={!!field?.value?.find((item) => item?.value === option?.value)}
        onChange={(event) => handleOnChange(event, option, arrayHelpers)}
        label={
          option.parentKey === "rating" ? (
            <div
              className="rating"
              style={{
                borderColor: star_border_color,
                backgroundColor: star_bg_color,
              }}
            >
              <CustomStarRatingComponent
                name="rate1"
                starCount={5}
                className="align-bottom"
                starColor="#FBBD08"
                emptyStarColor="#FFFFFF"
                value={option.value}
                editing={false}
              />
            </div>
          ) : (  ( ticketFilter === "TICKET" && option.parentKey === "status") || (ticketFilter === "REVIEW" && option.parentKey === "customer_type")) ? (
            <FPChip
              size="small"
              variant="outlined"
              avatar={
                <FPBadge color={getStatusColor(option.value)} size="11" />
              }
              label={snakeToCapital(option.display_name)}
            />
          ) : (
            snakeToCapital(option.display_name)
          )
        }
        labelClassName={
          isNotEllipsisLabel === false ? "filter-chip" : "available-filter-item-label"
        }
      />
    </div>
  );
});

export default CheckBoxWithTooltip;
