import React from "react";

const MenuHighlights = () => {
    return (
        <>
            <h1 style={{height: "calc(100vh - 308px)",display: "flex", alignItems: "center",justifyContent: "center"}}>Menu Highlights Pages</h1>
        </>
    )
}

export default MenuHighlights