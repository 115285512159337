import React from "react";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import ListingFPTab from "../../../components/common/new/Listing/tabs/listingFPTabs";

const RootHeader = (props) => {
  const redirectTo = (url) => {
    setTimeout(() => {
      props.history.push(url);
    }, 400);
  };

  console.log("props.businessProfileprops.businessProfileprops.businessProfile",props.businessProfile)

  const handleRootTabChange = (value) => {
    switch (value) {
      case 0:
        redirectTo(`/${props.businessProfile?.business}/geo-grid-ranker`);
        break;
      case 1:
        redirectTo(`/${props.businessProfile?.business}/scan-history`);
        break;
      // case 2:
      //   redirectTo(`/${props.businessProfile?.business}/bulk-product`);
      //   break;
      // case 3:
      //   redirectTo(`/${props.businessProfile?.business}/bulk-menu`);
      //   break;
      default:
        break;
    }
  };

  return (
    <div className={"mb-4"}>
      <ListingFPTab
        className="border-0"
        tabLabels={["Geo Grid Scan", "Scan History"]}
        // tabLabels={["Geo Grid Scan", "Scan History", "Schedule Gird"]}
        border="0"
        minTabWidth={180}
        defaultValue={props.defaultValue}
        onChange={(value) => handleRootTabChange(value)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    businessProfile: state.profile.business_user,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(RootHeader);
