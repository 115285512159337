import React from "react";
import moment from "moment/moment";
import Highlighter from "react-highlight-words";
import { Accordion, Loader, Segment, Dimmer } from "semantic-ui-react";
import CustomStarRatingComponent from "../common/StarComponent";
import { FPAvatar, FPToolTip, MessageBox } from "../common/new";
import UserDetails from "../../modules/review/UserDetails";
import dropDownArrow from "../../images/green_dropDownArrow.png";
import classNames from "classnames";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import "../../components/business/reviewList.css";
import { Divider } from "@material-ui/core";
import LoaderComponent from "../messenger/Common/LoaderComponent";

const color = {
  Positive: "#81E050",
  Negative: "#EC554E",
};

const TopicsReviewCard = (props) => {
  const { item, searchWords, handleClick, i, reviewDetails, reviewLoader, activeIndex, className } = props;

  const date = moment(item.review_create).format("LL");
  return (
    <CardWrapper
      className={classNames("fp-card-outline review-card-wrapper ticket-card-header", className)}
      showDivider={false}
      style={{ border: "2px solid #dee2e6" }}
    >
      <header className="review-card-header">
        <div className="d-flex justify-content-between">
          <section className="d-flex align-item-center gap-10">
            <FPToolTip title={item.provider || ""}>
              <div className="provider-logo-wrapper">
                <img src={item?.provider_image} alt={item?.provider || "provider"} height="24" width="24" />
              </div>
            </FPToolTip>
            <div className="d-flex align-items-start gap-10" bg-primary>
              <div>
                <span className="review-title">{item.branch}</span>
                <span className="d-block font-blue-gray">{date}</span>
              </div>
            </div>
            <div className="review-time">
              <div className="rating">
                <CustomStarRatingComponent
                  name="rate1"
                  starCount={5}
                  className="align-bottom"
                  starColor="#FBBD08"
                  emptyStarColor="#FFFFFF"
                  value={+item.review_rating}
                  editing={false}
                />
              </div>
            </div>
          </section>
        </div>
      </header>

      <section className="review-card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <FPAvatar
              alt={item?.reviewer?.name}
              src={item.reviewer.image}
              labelClass={"user-name"}
              subLabel={<UserDetails item={item} />}
              userName={item?.reviewer?.name?.toString()}
            />
          </div>
        </div>
        <MessageBox isSelfBox={false} className={"max-w-100"} time={moment(item.review_create).format("llll")}>
          <Highlighter
            highlightStyle={{
              borderBottom: `1px solid ${color[item.sentiment]}`,
              background: "transparent",
              fontWeight: 600,
              color: `${color[item.sentiment]}`,
            }}
            // className="weight-500"
            searchWords={searchWords}
            textToHighlight={item.chunk}
          ></Highlighter>
        </MessageBox>
      </section>
      <Divider className="mb-0" />
      <section style={{ padding: "10px 16px" }}>
        <Accordion>
          <Accordion.Title
            active={activeIndex === i}
            index={i}
            onClick={(e, index) => handleClick(e, index, item.review_id)}
            className="text-right"
          >
            <p className="font-secondary">
              {activeIndex === i ? "Hide Full Review" : "See Full Review"}
              <img
                src={dropDownArrow}
                style={{
                  width: "15px",
                  height: "16px",
                  margin: "0px 0px 0px 2px",
                  transform: activeIndex === i ? "rotate(180deg)" : "rotate(0deg)",
                }}
                alt="expand"
              />
            </p>
          </Accordion.Title>
          {reviewLoader ? (
            activeIndex === i && <LoaderComponent showLoader={reviewLoader} width={25} height={25} />
          ) : (
            <Accordion.Content active={activeIndex === i}>
              <Segment className="full-review mt-2">{reviewDetails}</Segment>
            </Accordion.Content>
          )}
        </Accordion>
      </section>
    </CardWrapper>
  );
};

export default TopicsReviewCard;
