import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError, formValueSelector } from "redux-form";

import InputComponent from "../common/InputComponent";
import * as utils from "../../libs/utils";
import SelectComponent from "../common/SelectComponent";
import { FPBreadcrumbs, FPButton } from "../common/new";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import FPLabel from "../common/new/forms/ui/FPLabel";

const validate = (values) => {
  const errors = {};
  if (!values.product_name) errors.product_name = "Enter Product Name";
  if (!values.branch) errors.branch = "Select branch";
  // if (!values.product_category)
  //   errors.product_category = "Enter Product Category";
  // if (!values.product_subcategory)
  //   errors.product_subcategory = "Enter Product Subcategory";
  // if (!values.veg_nonveg) errors.veg_nonveg = "Enter Veg/Non-Veg";
  // if (!values.product_image_link) errors.product_image_link = 'Enter Product Image Link';
  // if (!values.product_description)
  //   errors.product_description = "Enter Product Description";
  return errors;
};

class MenuForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const {
      router: { history },
    } = this.context;
    return this.props
      .submitData(data)
      .then(() => {
        history.push({
          pathname: "central-setup",
          state: { tab: "10" },
        });
      })
      .catch((err) => {
        err && err.non_field_errors && err.non_field_errors.map((err) => utils.displayMessage("negative", err));
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
  }

  render() {
    const { submitting, handleSubmit, errors, locationsList, isEditMode } = this.props;
    const breadCrumbList = [
      {
        label: "Central Setup",
        link: "",
      },
      {
        label: "Menu",
        onClick: () =>
          this.context.router.history.push({
            pathname: "central-setup",
            state: { tab: "10" },
          }),
      },
      {
        label: isEditMode ? "Edit Menu" : "Add Menu",
        link: "",
      },
    ];
    return (
      <div>
        <FPBreadcrumbs list={breadCrumbList} className="my-2 mb-4" />
        <CardWrapper headerTitle={isEditMode ? "Edit Menu" : "Add Menu"} contentClass="p-4">
          <form className="grid-form" onSubmit={handleSubmit(this.submit)}>
            <div className="mt-2">
              <FPLabel className="mt-4">Product Name</FPLabel>
              <Field
                name="product_name"
                type="text"
                required
                component={InputComponent}
                serverError={errors && errors.product_name}
                placeholder="Product Name"
              />
              <FPLabel>Branch</FPLabel>
              <Field
                name="branch"
                component={SelectComponent}
                labelKey="display_name"
                valueKey="value"
                placeholder="Select Branch"
                options={locationsList && locationsList}
                simpleValue
                serverError={errors && errors.branch}
                className="select-branch-dropdown"
                style={{
                  ".Select-value": {
                    maxWidth: "100%",
                  },
                }}
                required={false}
              />
              <FPLabel className="mt-4" required={false}>
                Product Category
              </FPLabel>
              <Field
                name="product_category"
                type="text"
                // required
                component={InputComponent}
                serverError={errors && errors.product_category}
                placeholder="Product Category"
              />
              <FPLabel required={false}>Product Sub Category</FPLabel>
              <Field
                name="product_subcategory"
                type="text"
                // required
                component={InputComponent}
                serverError={errors && errors.product_subcategory}
                placeholder="Product Sub Category"
              />
              <FPLabel required={false}>Veg/Non-Veg</FPLabel>
              <Field
                name="veg_nonveg"
                type="text"
                // required
                component={InputComponent}
                serverError={errors && errors.veg_nonveg}
                placeholder="Name"
              />
              <FPLabel required={false}>Product Image Link</FPLabel>
              <Field
                name="product_image_link"
                type="text"
                // required
                component={InputComponent}
                serverError={errors && errors.product_image_link}
                placeholder="Product Image Link"
              />
              <FPLabel required={false}>Product description</FPLabel>
              <Field
                name="product_description"
                type="text"
                // required
                component={InputComponent}
                // serverError={errors && errors.product_description}
                placeholder="Product Description"
              />
            </div>
            <div className="d-flex align-items-center justify-content-end gap-6 mt-2">
              <FPButton
                type="text"
                label="Cancel"
                size="small"
                variant="outline"
                onClick={() =>
                  this.context.router.history.push({
                    pathname: "central-setup",
                    state: { tab: "10" },
                  })
                }
              />

              <FPButton type="submit" label="Save" size="small" />
            </div>
          </form>
        </CardWrapper>
      </div>
    );
  }
}

MenuForm.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

MenuForm = reduxForm({
  // eslint-disable-line
  form: "MenuForm",
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(MenuForm);

const selector = formValueSelector("MenuForm");

MenuForm = connect((state) => {
  // eslint-disable-line
  const responseBody = selector(state, "content");
  const autoReply = selector(state, "auto");
  return {
    responseBody,
    autoReply,
  };
})(MenuForm);

export default MenuForm;
