import React from "react";
import { Button } from "semantic-ui-react";
import google_image from "../../../../images/googleimage.svg";
import "./bulkproduct.css";
import { FPButton } from "../../../../components/common/new";
import AddIcon from "@material-ui/icons/Add";

const BulkProductList = () => {
  return (
    <div>
      <div
        className="d-flex align-baseline gap-10"
        style={{ paddingBlock: "25px" }}
      >
        <h2
          style={{
            color: "#1A2544",
            fontSize: "18px",
            fontWeight: "500",
            marginBottom: "0px",
          }}
        >
          Bulk Product Update{" "}
        </h2>
        <h3
          style={{
            color: "#F13A88",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          (39 Selected / 54 Total listings)
        </h3>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(443px, 1fr))",
          gap: "24px",
        }}
      >
        <div
          style={{
            border: "1px solid",
            display: "grid",
            height: "479px",
            borderRadius: "15px",
            borderColor: "#BFC2CB",
            backgroundColor: "#FFFFFF",
          }}
        >
          <div
            className="bulk-menu-card"
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <div
              className="d-flex justify-content-between"
              style={{ padding: "10px", gap: "10px" }}
            >
              <div>
                <h2
                  style={{
                    fontWeight: "500",
                    fontSize: "15px",
                    marginBottom: "4px",
                  }}
                >
                  Add Menu Items
                </h2>
                <span style={{ color: "#7A8193", fontSize: "13px" }}>
                  Add your menu food items or inventory available at your store
                </span>
              </div>
              <div className="view-listing-button">
                {/* <Button
                  className="d-flex gap-10 flex-row-reverse"
                  style={{
                    backgroundColor: "#00B5AD",
                    color: "#FFF",
                    borderRadius: "50px",
                  }}
                  color="red"
                  content="Add"
                  icon="add"
                /> */}
                <FPButton
                  label="Add"
                  size="small"
                  backgroundColor="#00B5AD"
                  style={{ height: "27px" }}
                  // disabled={enable_ticket_creation}
                  // onClick={toggleCreateTicketModal}
                  endIcon={
                    <AddIcon
                      style={{ height: 15, width: 15, color: "white" }}
                    />
                  }
                />
              </div>
            </div>
            <div
              className="d-flex justify-content-center"
              style={{ padding: "10px", gap: "10px" }}
            >
              <img src={google_image} alt="googleImage" />
            </div>
          </div>
        </div>
        <div
          style={{
            border: "1px solid",
            display: "grid",
            height: "479px",
            borderRadius: "15px",
            borderColor: "#BFC2CB",
            backgroundColor: "#FFFFFF",
          }}
        >
          <div
            className="bulk-menu-card"
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <div
              className="d-flex justify-content-between"
              style={{ padding: "10px", gap: "10px" }}
            >
              <div>
                <h2
                  style={{
                    fontWeight: "500",
                    fontSize: "15px",
                    marginBottom: "4px",
                  }}
                >
                  Add Menu Images
                </h2>
                <span style={{ color: "#7A8193", fontSize: "13px" }}>
                  Update with your product page with latest product & stock
                </span>
              </div>
              <div className="view-listing-button">
                <FPButton
                  label="Add"
                  size="small"
                  style={{ height: "27px" }}
                  backgroundColor="#00B5AD"
                  endIcon={
                    <AddIcon
                      style={{ height: 15, width: 15, color: "white" }}
                    />
                  }
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <img src={google_image} alt="googleImage" />
            </div>
          </div>
        </div>
        <div
          style={{
            border: "1px solid",
            display: "grid",
            height: "479px",
            borderRadius: "15px",
            borderColor: "#BFC2CB",
            backgroundColor: "#FFFFFF",
          }}
        >
          <div
            className="bulk-menu-card"
            style={{ display: "flex", flexDirection: "column", gap: "24px" }}
          >
            <div
              className="d-flex justify-content-between"
              style={{ padding: "10px", gap: "10px" }}
            >
              <div>
                <h2 style={{ fontWeight: "500", fontSize: "15px" }}>
                  Name Menu Highlights
                </h2>
                <span style={{ color: "#7A8193", fontSize: "13px" }}>
                  Add your menu food items or inventory available at your
                </span>
              </div>
              <div className="view-listing-button">
                <FPButton
                  label="Add"
                  size="small"
                  style={{ height: "27px" }}
                  backgroundColor="#00B5AD"
                  endIcon={
                    <AddIcon
                      style={{ height: 15, width: 15, color: "white" }}
                    />
                  }
                />
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <img src={google_image} alt="googleImage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkProductList;
