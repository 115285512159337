import React from "react";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { Box } from "@material-ui/core";
import { FPButton } from "../common/new";
import YoutubeIcon from "../../images/youtubeNewLogo.png";
import InstagramIcon from "../../images/instagram_logo.png";
import TwitterIcon from "../../images/twitterNewIcon.png";

const SocialAccount = () => {
  const connections = [
    { id: "Instagram", icon: InstagramIcon, width: "60", height: "60" },
    { id: "Youtube", icon: YoutubeIcon, width: "60", height: "60" },
    { id: "Twitter", icon: TwitterIcon, width: "60", height: "60" },
  ];
  return (
    <CardWrapper showDivider={false} className="px-4 py-2 mb-5">
      <div className="socialAccountHead mb-4">
        <span>Connect Social Accounts</span>
        <span>Connect your Social Media to track your Brand Mentions</span>
      </div>
      <div className="row container mx-auto">
        {connections.map((item, index) => {
          return (
            <div className="d-flex flex-column col-4 text-center" key={index}>
              <div>
                <img width={item.width} height={item.height} src={item.icon} />
              </div>
              <Box component="span" className="mb-3 mt-2" sx={{ color: "#1B1C1D", fontSize: "14px" }}>
                {item.id}
              </Box>
              <div className="mt-1">
                <FPButton
                  size="small"
                  variant="outline"
                  label={"Connect"}
                  backgroundColor={"#F13A88"}
                  textColor="white"
                  borderColor="none"
                />
              </div>
            </div>
          );
        })}
      </div>
    </CardWrapper>
  );
};

export default SocialAccount;
