/* eslint-disable */
import React from "react";
import queryString from "query-string";
import { Row, Card } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import SelectComponent from "../../components/common/Select";
import MultiSelect from "../../components/common/SelectComponent";
import * as URLS from "../../libs/apiUrls";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cookie from "react-cookies";
import { FPButton } from "../common/new";
import ResetIcon from "../../images/Reset.png";
import DateSelector from "../../modules/dashboard/component/DateSelector";
import { Box } from "@material-ui/core";

class ReportsFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    const query = queryString.parse(props.location.search);
    this.toQuery = this.toQuery.bind(this);
    this.resetSource = this.resetSource.bind(this);
    this.handleFilterApply = this.handleFilterApply.bind(this);
    this.state = {
      city: query.city ? query.city.split(",") : [],
      state: query.state ? query.state.split(",") : [],
      provider: query.provider ? query.provider.split(",") : [],
      branchTag: query.branch_tag ? query.branch_tag.split(",") : [],
      tag: query.tag ? query.tag.split(",") : [],
      keyword: query.keyword ? query.keyword.split(",") : [],
      date: {
        start_date: query.start_date ? query.start_date : new Date(),
        end_date: query.end_date ? query.end_date : new Date(),
        date_type: query.date_type,
      },
      source: query.source ? query.source.split(",") : [],

      selectedDate: null,
      service_type: query.service_type ? query.service_type : undefined,
      filterApply: query,
      branch: query.branch ? parseInt(query.branch) : undefined,
      filter: {},
      newFilter: {},
    };
  }

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    this.setState({
      selectedDate: query.date_type ? moment(query.date_type).format("DD-MM-YYYY") : "",
    });
  }
  componentDidUpdate(prevProps) {
    const {
      location: { search: nextSearch },
    } = this.props;
    const {
      location: { search: prevSearch },
    } = prevProps;
    const { date } = this.state;
    const prevQuery = queryString.parse(prevSearch);
    const nextQuery = queryString.parse(nextSearch);
    if (prevQuery.date_type !== nextQuery.date_type) {
      this.setState({ date: { ...date, date_type: nextQuery.date_type } });
    }
  }

  toQuery(key, data) {
    console.log("key, data",{key, data});
    const {
      location: { search },
    } = this.props;

    if (key === "date_type") {
      this.setState({ newFilter: { ...this.state.newFilter, ...data } });
    } else if (key === "single_date_type") {
      this.setState({ newFilter: { ...this.state.newFilter, ["date_type"]: data } });
    } else {
      this.setState({ newFilter: { ...this.state.newFilter, [key]: data } });
    }
  }

  handleFilterApply() {
    const {
      history,
      location: { pathname, search },
      onApply,
    } = this.props;
    const currentFilters = queryString.parse(search);
    const mergeFilter = Object.assign(currentFilters, this.state.newFilter);

    history.push({
      pathname,
      search: queryString.stringify(mergeFilter),
    });
    onApply();
  }

  resetSource() {
    const {
      reset,
      history,
      location: { pathname, search },
      match: {
        params: { report, reportType },
      },
      onApply,
    } = this.props;
    let filters = queryString.parse(search);
    if (filters.branch || filters.date_type || filters.provider || filters.list_by || filters.source) {
      reset();
      delete filters.branch;
      delete filters.branch_tag;
      delete filters.provider;
      delete filters.end_date;
      delete filters.start_date;
      delete filters.list_by;
      delete filters.keyword;
      delete filters.tag;
      delete filters.date_type;
      delete filters.source;
      delete filters.service_type;
      delete filters.city;
      delete filters.state;
      this.setState({
        provider: [],
        tag: [],
        branchTag: [],
        selectedDate: moment().format("DD-MM-YYYY"),
        service_type: undefined,
      });
      filters.date_type =
        report === "standard"
          ? "prior_7_day"
          : report === "textual"
          ? "prior_7_day"
          : report !== "display-report"
          ? "prior_30_day"
          : moment().format("YYYY-MM-DD");
      reportType === "overtime" ? (filters.list = "date") : null;
      const url = pathname + "?" + queryString.stringify(filters);
      history.push(url);
      onApply();
    }
  }

  handleSingleDatePicker = (date) => {
    this.setState({ selectedDate: moment(date).format("DD-MM-YYYY") });
    this.toQuery("single_date_type", moment(date).format("YYYY-MM-DD"));
  };

  render() {
    const {
      ticket,
      showListByFilter,
      showLocationFilter,
      reviewSourceOptions,
      showListByLoctionFilter,
      showSourceFilter,
      location: { search },
      history,
      match: {
        params: { business_id },
      },
      keywordOptions,
      tagOptions,
      hideDateShowCalander,
      branchTagOptions,
      showTicketSourceFilter,
      ticketSourceOptions,
      branchList,
      listByData,
      common
    } = this.props;
    const { date, branch } = this.state;

    const branchFilterProps = {
      apiurl: `${URLS.BUSINESS_SETUP}${business_id}/branch/`,
      selectedValue: queryString.parse(search).branch ? parseInt(queryString.parse(search).branch) : null,
    };

    const listByFilterProps = {
      apiurl: ticket ? "ticket-listby-filter/" : `${URLS.BUSINESS_SETUP}listby-filter/`,
      selectedValue: queryString.parse(search).list ? queryString.parse(search).list : null,
    };

    const listByLocationFilerProps = {
      apiurl: `${URLS.BUSINESS_SETUP}listby-filter?report_type=location`,
      selectedValue: queryString.parse(search).list_by ? queryString.parse(search).list_by : null,
    };

    let tags = [];
    tagOptions && tagOptions.map((item) => tags.push({ value: item, display_name: item }));

    const service_category = cookie.load("service_category");
    const serviceOptions = [
      { value: "dining", display_name: "Dining" },
      { value: "delivery", display_name: "Delivery" },
      { value: "Takeaway", display_name: "Takeaway" },
    ];
    const selectedLocation = queryString.parse(search).branch ? parseInt(queryString.parse(search).branch) : null;

    const selectedListBy = queryString.parse(search).list ? queryString.parse(search).list : null;

    return (
      <Card body className="py-3 form-label reportFilter">
        <Row className="m-0">
          <form className="w-100">
            {showLocationFilter && (
              <div>
                <Field
                  name="location"
                  type="text"
                  component={SelectComponent}
                  placeholder="Location"
                  selectedValue={branch}
                  options={
                    branchList?.choices?.map((i) => ({
                      text: i.display_name,
                      value: i.value,
                    })) || []
                  }
                  onChange={(e, val) => {
                    this.setState({ branch: val });
                    this.toQuery("branch", val);
                  }}
                />
              </div>
            )}
            {hideDateShowCalander === false ? (
              <div className="mt-2 pt-1 side-filter">
                <DateSelector
                  dropDownClassName="m-0"
                  onChange={(date) => this.toQuery("date_type", date)}
                  isForLifeTimeSupported={false}
                />
              </div>
            ) : (
              <Box
                sx={{
                  "& .react-datepicker-wrapper": {
                    width: "100%",
                  },
                }}
                className="mt-2 pt-1 side-filter"
              >
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => this.handleSingleDatePicker(date)}
                  className="form-control form-control-sm"
                  placeholderText={"Select Date"}
                  // selected={this.state.selectedDate}
                  value={this.state.selectedDate !== null && this.state.selectedDate}
                />
              </Box>
            )}

            {showTicketSourceFilter && (
              <div className="mt-2 pt-1 side-filter">
                <Field
                  name="source"
                  className={"h-100"}
                  component={MultiSelect}
                  placeholder="Ticket Source"
                  onChange={(e, val) => this.toQuery("source", val)}
                  options={ticketSourceOptions}
                  val={this.state.source}
                  labelKey="display_name"
                  valueKey="value"
                  multi
                  simpleValue
                />
              </div>
            )}
            {showListByLoctionFilter && (
              <div className="mt-2 pt-1 side-filter">
                <Field
                  name="list_by"
                  component={SelectComponent}
                  placeholder="Select Location"
                  props={listByLocationFilerProps}
                  onChange={(e, val) => this.toQuery("list_by", val)}
                />
              </div>
            )}
            {keywordOptions && (
              <div className="mt-2 pt-1 side-filter">
                <Field
                  name="keyword"
                  placeholder="Select Keywords"
                  className={"h-100"}
                  component={MultiSelect}
                  onChange={(e, val) => this.toQuery("keyword", val)}
                  options={keywordOptions}
                  val={this.state.keyword}
                  labelKey="keyword"
                  valueKey="keyword"
                  multi
                  simpleValue
                />
              </div>
            )}

            {showSourceFilter && (
              <div className="mt-2 pt-1 side-filter">
                <Field
                  name="provider"
                  component={MultiSelect}
                  className={"h-100"}
                  placeholder={ticket ? "Review Provider" : "Source"}
                  onChange={(e, val) => this.toQuery("provider", val)}
                  options={reviewSourceOptions}
                  val={this.state.provider}
                  labelKey="display_name"
                  valueKey="value"
                  multi
                  simpleValue
                />
                {this.props.showTagFilter && (
                  <div className="mt-2 pt-1 side-filter">
                    <Field
                      name="tag"
                      component={MultiSelect}
                      className={"h-100"}
                      placeholder="Tags"
                      options={tags}
                      labelKey="display_name"
                      valueKey="value"
                      onChange={(e, val) => this.toQuery("tag", val)}
                      val={this.state.tag}
                      multi
                      simpleValue
                    />
                  </div>
                )}
              </div>
            )}
            <div className="mt-2 pt-1 side-filter">
              <Field
                name="branch_tag"
                component={MultiSelect}
                className={"h-100"}
                placeholder="Branch Tag"
                onChange={(e, val) => this.toQuery("branch_tag", val)}
                options={branchTagOptions}
                val={this.state.branchTag}
                labelKey="title"
                valueKey="id"
                multi
                simpleValue
              />
            </div>
            <div className="mt-2 pt-1 side-filter">
              <Field
                name="city"
                component={MultiSelect}
                className={"h-100"}
                placeholder="City"
                onChange={(e, val) => this.toQuery("city", val)}
                options={common?.city_options?.choices}
                val={this.state.city}
                labelKey="display_name"
                valueKey="value"
                multi
                simpleValue
              />
            </div>
            <div className="mt-2 pt-1 side-filter">
              <Field
                name="state"
                component={MultiSelect}
                className={"h-100"}
                placeholder="State"
                onChange={(e, val) => this.toQuery("state", val)}
                options={common?.state_options?.choices}
                val={this.state.state}
                labelKey="display_name"
                valueKey="value"
                multi
                simpleValue
              />
            </div>
            {showListByFilter && (
              <div className="mt-2 pt-1 side-filter">
                <Field
                  name="listBy"
                  component={SelectComponent}
                  options={
                    listByData?.choices?.map((i) => ({
                      text: i.display_name,
                      value: i.value,
                    })) || []
                  }
                  selectedValue={selectedListBy}
                  onChange={(e, val) => this.toQuery("list", val)}
                  placeholder="List By"
                />
              </div>
            )}
            {service_category && service_category === "Restaurant" && (
              <div className="mt-2 pt-1 side-filter">
                <Field
                  name="Service_type"
                  className={"h-100"}
                  component={MultiSelect}
                  type="text"
                  onChange={(e, val) => this.toQuery("service_type", val ? val : undefined)}
                  options={serviceOptions}
                  val={this.state.service_type}
                  placeholder="Service type"
                  labelKey="display_name"
                  valueKey="value"
                  simpleValue
                />
              </div>
            )}
            <div className="d-flex gap-10 mt-3">
              <FPButton
                type="button"
                label="Apply"
                size="large"
                fontSize="14px"
                className="w-50 button"
                onClick={() => this.handleFilterApply()}
              />
              <FPButton
                type="button"
                onClick={this.resetSource}
                label="Reset"
                size="large"
                fontSize="14px"
                backgroundColor="#00B5AD"
                className="button w-50"
                startIcon={<img src={ResetIcon} />}
              />
            </div>
          </form>
        </Row>
      </Card>
    );
  }
}

export default reduxForm({
  form: "reportFilterForm",
})(ReportsFilter);
