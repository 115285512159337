import React from "react";
import { Box } from "@material-ui/core";
import { FPButton } from "../common/new";
import { Formik, Form } from "formik";
import * as yup from "yup";
import FormAutoComplete from "../common/new/forms/FormAutoSelect";
import { daysList, hourList } from "../../libs/constant";
import ErrorMessage from "../common/new/forms/ui/ErrorMessage";

function TicketAutoResolutionForm(props) {
  const { initialData, onApply, addTicketEscalation, editTicketEscalation,  handleEscalationClose } = props;

  const validationSchema = yup.object().shape({
    more_then_unit: yup.string().required().label("Hours/Days"),
    more_then_value: yup.string().required().label("Hours/Days"),
  });

  const moreThanFirstList = [
    { display_name: "Hours", value: "hour" },
    { display_name: "Days", value: "day" },
  ];

  return (
    <div>
      <span className="escalation-title">Auto Resolution </span>
      <Formik
        initialValues={{
          more_then_unit: "",
          more_then_value: "",
          ...initialData,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          initialData && Object.keys(initialData).length > 0
            ? editTicketEscalation(values, onApply)
            : addTicketEscalation(values, onApply);
        }}
      >
        {({ values, errors, touched, handleSubmit }) => (
          <Form>
            <Box className="border mt-3 p-3" sx={{ borderRadius: "8px" }}>
              <div className="mb-3">
                <div className="d-flex justify-content-between">
                  <div className="my-auto">
                    <span className="statusText">
                      If ticket is open for more than
                      <Box component="span" sx={{ color: "red" }}>*</Box>
                    </span>
                  </div>
                  <div className="d-flex gap-20" style={{ marginRight: "10px" }}>
                    <FormAutoComplete
                      name="more_then_unit"
                      placeholder={values.more_then_unit.length === 0 && "Select Hours/Days"}
                      options={moreThanFirstList}
                      value={values.more_then_unit}
                      sx={{
                        width: 360,
                        "& fieldset": { borderRadius: 2 },
                        "& .MuiInputBase-input": {
                          minWidth: "360px !important",
                          fontSize: "12px",
                          color: "#646565 !important",
                          "&::placeholder": { color: "#646565" },
                        },
                      }}
                    />
                    <FormAutoComplete
                      name="more_then_value"
                      placeholder={values.more_then_value.length === 0 && "Select"}
                      options={values.more_then_unit === "hour" ? hourList : values.more_then_unit === "day" ? daysList : []}
                      value={values.more_then_value}
                      disabled={!values.more_then_unit.length}
                      sx={{
                        width: 90,
                        "& fieldset": { borderRadius: 2 },
                        "& .MuiInputBase-input": {
                          minWidth: "90px !important",
                          fontSize: "12px",
                          color: "#646565 !important",
                          "&::placeholder": { color: "#646565" },
                        },
                      }}
                    />
                  </div>
                </div>
                {(errors.more_then_unit || errors.more_then_value) &&
                  (touched.more_then_unit || touched.more_then_unit) && (
                    <ErrorMessage className="mt-0">
                      {errors.more_then_unit || errors.more_then_value}
                    </ErrorMessage>
                  )}
              </div>
              <div>
                <span className="escalationText">, then Resolve/Close the Ticket</span>
              </div>
            </Box>
            <div className="d-flex justify-content-end mt-3">
              <FPButton
                label="Cancel"
                size="small"
                type="button"
                variant="outline"
                className="mr-2"
                onClick={() => handleEscalationClose()}
              />
              <FPButton label="Submit" size="small" type="button" onClick={handleSubmit} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default TicketAutoResolutionForm;
