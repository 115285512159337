import React from "react";
import { Table } from "semantic-ui-react";
import { Row, Col } from "reactstrap";
import * as utils from "../../libs/utils";
// import LoaderComponent from "../common/loaderComponent";
import CustomStarRatingComponent from "../common/StarComponent";
import { starRatingColor } from "../../libs/utils";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { FPToolTip } from "../common/new";
import { Skeleton } from "@mui/material";
import queryString from "query-string"

class LocationListComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      topLoc: [],
      worstLoc: [],
      activeShape: null,
      showInfoWindow: false,
      shapeEvent: null,
      data: {},
    };
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
  }


  handleMarkerClick(data, shape, event) {
    this.setState({
      activeShape: shape,
      showInfoWindow: true,
      shapeEvent: event,
      data,
    });
  }

  renderTableRows(locations) {
    return locations.map((item, index) => {
      const { star_border_color, star_bg_color } = starRatingColor(
        utils.setDecimalIndex(item.avg_rating) || 0
      );
      return (
        <Table.Row key={index}>
          <Table.Cell>{index + 1}</Table.Cell>
          <Table.Cell>
            <FPToolTip title={item.location_address}>
              <div
                style={{
                  width: "250px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textAlign: "justify",
                }}
              >
                {item.location_name}
              </div>
            </FPToolTip>
          </Table.Cell>
          <Table.Cell>{item.rating_sum}</Table.Cell>

          <Table.Cell>
            <span
              className="location-tag"
              style={{
                borderColor: star_border_color,
                backgroundColor: star_bg_color,
                height: "25px",
                padding: "0px 7px",
                gap: "4px",
              }}
            >
              <CustomStarRatingComponent
                name="locationRating"
                starCount={1}
                style={{ fontSize: "14px" }}
                value={utils.setDecimalIndex(item.avg_rating) || 0}
                editing={false}
              />
              {utils.setDecimalIndex(item.avg_rating) || 0}
            </span>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    const { businessId, loader, topLoc, worstLoc, fetchTopBottomLocation, params, topBottomApiCalled } = this.props;
    return (
      <Row className="dashboard-cards mt-0 mb-0 p-0">
        <Col xs="12" sm="12" md="6" lg="6" className="mt-4 pr-md-4 pl-0 pr-0">
          <CardWrapper
            widthElevation={true}
            isRefreshButton={true}
            handleRefreshButton={() => fetchTopBottomLocation(queryString.parse(params))}
            redirectionLink={`/${businessId}/reports/review-section/reviews/location/graph?date_type=prior_30_day`}
            headerTitle={"Top 5 Locations"}
            className="h-100"
            dividerClass="mb-0"
            skeletonLoader={loader}
          >
            {/* <LoaderComponent loader={isLoading} rounded inline /> */}
            { topBottomApiCalled ? <div>
              {loader  ? (
                <Table singleLine stackable className="fp-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Skeleton
                          variant="text"
                          width="20%"
                          height={20}
                          style={{ margin: "0 auto" }}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell className="width-insights-th">
                        <Skeleton
                          variant="text"
                          width="80%"
                          height={20}
                          style={{ margin: "0 auto" }}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Skeleton
                          variant="text"
                          width="80%"
                          height={20}
                          style={{ margin: "0 auto" }}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Skeleton
                          variant="text"
                          width="80%"
                          height={20}
                          style={{ margin: "0 auto" }}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <Table.Row key={index}>
                        <Table.Cell style={{ textAlign: "center" }}>
                          <Skeleton
                            variant="text"
                            width="20%"
                            height={20}
                            style={{ margin: "0 auto" }}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: "center" }}>
                          <Skeleton
                            variant="text"
                            width="80%"
                            height={20}
                            style={{ margin: "0 auto" }}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: "center" }}>
                          <Skeleton
                            variant="text"
                            width="60%"
                            height={20}
                            style={{ margin: "0 auto" }}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: "center" }}>
                          <Skeleton
                            variant="text"
                            width="60%"
                            height={20}
                            style={{ margin: "0 auto" }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              ) : (
                <Table singleLine stackable className="fp-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell className="width-insights-th">
                        Location
                      </Table.HeaderCell>
                      <Table.HeaderCell>Reviews</Table.HeaderCell>
                      <Table.HeaderCell>Avg Rating</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {topLoc && topLoc.length ? (
                      this.renderTableRows(topLoc)
                    ) : (
                      <Table.Row>
                        <Table.Cell className="text-center" colSpan={4}>
                          No records Found
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              )}
            </div>
            : <div 
                className="text-center p-4 fp-size-16 underline" 
                style={{ textDecoration: 'underline', color: 'deeppink', cursor: 'pointer' }}
                onClick={() => fetchTopBottomLocation(queryString.parse(params))} 
              >Click to Load Data</div>
            }
          </CardWrapper>
        </Col>
        <Col xs="12" sm="12" md="6" lg="6" className="mt-4 p-0">
          <CardWrapper
            widthElevation={true}
            isRefreshButton={true}
            handleRefreshButton={() => fetchTopBottomLocation(queryString.parse(params))}
            redirectionLink={`/${businessId}/reports/review-section/reviews/location/graph?date_type=prior_30_day`}
            headerTitle={"Bottom 5 Locations"}
            className="h-100"
            dividerClass="mb-0"
            skeletonLoader={loader}
          >
            {/* <LoaderComponent loader={isLoading} rounded inline /> */}
            {topBottomApiCalled ? <div>
              {loader ? (
                <Table singleLine stackable className="fp-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Skeleton
                          variant="text"
                          width="20%"
                          height={20}
                          style={{ margin: "0 auto" }}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell className="width-insights-th">
                        <Skeleton
                          variant="text"
                          width="80%"
                          height={20}
                          style={{ margin: "0 auto" }}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Skeleton
                          variant="text"
                          width="80%"
                          height={20}
                          style={{ margin: "0 auto" }}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Skeleton
                          variant="text"
                          width="80%"
                          height={20}
                          style={{ margin: "0 auto" }}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <Table.Row key={index}>
                        <Table.Cell style={{ textAlign: "center" }}>
                          <Skeleton
                            variant="text"
                            width="20%"
                            height={20}
                            style={{ margin: "0 auto" }}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: "center" }}>
                          <Skeleton
                            variant="text"
                            width="80%"
                            height={20}
                            style={{ margin: "0 auto" }}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: "center" }}>
                          <Skeleton
                            variant="text"
                            width="60%"
                            height={20}
                            style={{ margin: "0 auto" }}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: "center" }}>
                          <Skeleton
                            variant="text"
                            width="60%"
                            height={20}
                            style={{ margin: "0 auto" }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              ) : (
                <Table singleLine stackable className="fp-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell className="width-insights-th">
                        Location
                      </Table.HeaderCell>
                      <Table.HeaderCell>Reviews</Table.HeaderCell>
                      <Table.HeaderCell>Avg Rating</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {worstLoc && worstLoc.length ? (
                      this.renderTableRows(worstLoc)
                    ) : (
                      <Table.Row>
                        <Table.Cell className="text-center" colSpan={4}>
                          No records Found{" "}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              )}
            </div> 
            :<div 
              className="text-center p-4 fp-size-16 underline" 
              style={{ textDecoration: 'underline', color: 'deeppink', cursor: 'pointer' }} 
              onClick={() => fetchTopBottomLocation(queryString.parse(params))}
            >Click to Load Data</div>
          }
          </CardWrapper>
        </Col>
      </Row>
    );
  }
}

export default LocationListComponent;
