import React from "react";
import { Row, Col } from "reactstrap";
import FacebookIcon from "../../images/svg/facebookSocial.svg";
import Img1 from "../../images/svg/userSocial.svg";
import Img2 from "../../images/svg/doneSocial.svg";
import Img3 from "../../images/svg/fileSocial.svg";
import FacebookLogin from "react-facebook-login";
import { images } from "../../images";

class SetupFBSocialAccounts extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.branch_id !== prevState.branch_id) {
      return { branch_id: nextProps.branch_id };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.state = {
      branch_id: this.props.branch_id,
    };
  }

  componentDidMount() {
    const { checkLoggedIn } = this.props;
    checkLoggedIn("facebook");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.branch_id !== this.state.branch_id) {
      const { checkLoggedIn } = this.props;
      checkLoggedIn("facebook");
    }
  }

  responseFacebook(response) {
    const { postCode, isLoggedIn, logOut } = this.props;
    if (isLoggedIn && isLoggedIn.connected) {
      return logOut("facebook");
    }
    return postCode({ code: response.accessToken, provider: "facebook" });
  }

  render() {
    const { isLoggedIn, logOut } = this.props;
    return (
      <div>
        <Row>
          <Col className="text-center facebook-button">
            <div className="d-flex flex-column text-center">
              <img src={FacebookIcon} alt="Facebook" height="40px" />
              <span className="mt-2" style={{ fontSize: "18px", fontWeight: "400" }}>
                Connect your Facebook Pages
              </span>
            </div>
            <Row className="my-5 py-4">
              <Col className="text-center">
                <img src={Img1} alt="manage all" height="70" />
                <p className="mt-3 px-3" style={{ fontSize: "14px", color: "#646565", fontWeight: "400" }}>
                  Manage all your Facebook reviews from your account.
                </p>
              </Col>
              <Col className="text-center">
                <img src={Img2} height="70" alt="img2" />
                <p className="mt-3 px-3" style={{ fontSize: "14px", color: "#646565", fontWeight: "400" }}>
                  Share positive reviews to your Facebook Pages
                </p>
              </Col>
              <Col className="text-center">
                <img src={Img3} height="70" alt="img3" />
                <p className="mt-3 px-3" style={{ fontSize: "14px", color: "#646565", fontWeight: "400" }}>
                  Publish content and engage with your Facebook followers
                </p>
              </Col>
            </Row>
            <div className="text-center mt-4 pt-3 mb-3">
              {isLoggedIn && !isLoggedIn.connected ? (
                <FacebookLogin
                  appId={process.env.REACT_APP_SOCIAL_AUTH_FACEBOOK_KEY}
                  fields="name,email,picture"
                  scope="email,pages_read_user_content,pages_manage_engagement"
                  auth_type="rerequest"
                  enable_profile_selector={true}
                  callback={this.responseFacebook}
                  textButton={"Login To Facebook"}
                  version="7.0"
                  icon={<img src={images.facebookIcon} style={{ height: "20px", width: "20px" }} />}
                />
              ) : (
                <button onClick={() => logOut("facebook")}>
                  <img src={images.facebookIcon} alt="Facebook Icon" style={{ height: "20px", width: "20px" }} />
                  <span>Log out from Facebook</span>
                </button>
              )}
              {isLoggedIn && isLoggedIn.connected && isLoggedIn.name ? (
                <p className="mt-2">
                  Logged in with this email id: <strong>{isLoggedIn.name}</strong>
                </p>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SetupFBSocialAccounts;
