import React from 'react'
import SearchKeywords from '../../../components/listing/keywordPosition/SearchKeywords'

const TrafficAnalysis = () => {
  return (
    <div>
        <SearchKeywords />
    </div>
  )
}

export default TrafficAnalysis