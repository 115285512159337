import React from "react";
import Wrapper from "./Wrapper";
import CompetitorsRating from "../../components/competitors/CompetitorsRating";
import CompetitorsProviderGraph from "./CompetitorsProviderGraph";

const ProviderRating = ({
  location,
  history,
  match,
  ratingDetails,
  loader,
  downloadRatingReport,
  businessDetails
}) => {
  return (
    <Wrapper
      title={"Provider wise Ratings"}
      subHeading={`* Above details is as on ${
        (ratingDetails && ratingDetails.fetch_date) || "YYYY-MM-DD"
      }`}
      location={location}
      history={history}
      match={match}
      onDownload={downloadRatingReport}
    >
      {(viewType) => (
        <>
          {viewType === "Graph" ? (
            <CompetitorsProviderGraph
              ratingDetails={ratingDetails && ratingDetails}
              loader={loader}
              businessDetails={businessDetails}
            />
          ) : (
            <CompetitorsRating
              ratingDetails={ratingDetails && ratingDetails}
              loader={loader}
              businessDetails={businessDetails}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default ProviderRating;
