import { Box, Divider } from "@material-ui/core";
import React, { useState } from "react";
// import { FPTab } from "../../../components/common/new/tabs";
import UserListActionBar from "../../../components/messenger/UsetListActionBar";
import MessageListComponent from "./MessageListComponent";
import WhatsAppChips from "./WhatsAppChips";
import EmptyUnread from "./EmptyUnread";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";

function WhatsUpSideBar({
  selectedChat,
  list,
  handleContactDetails,
  fetchMoreData,
  hasMore,
}) {
  const [showFilter, setShowFilter] = useState();
  const [selected, setSelected] = useState("All");

  const unreadMessages = list?.filter((item) => item?.unseen !== 0) || [];
  const hasUnread = unreadMessages.length > 0;
const handleSearchConversation =(search) => {
  console.log("search",search);
}
  return (
    <div>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#FAFAFB",
          borderRadius: 20,
          padding: 16,
          paddingRight: 0,
          position: "relative",
        }}
      >
        <div className="d-flex justify-content-between">
          <WhatsAppChips selected={selected} setSelected={setSelected} list={list}/>

          {/* Show EmptyUnread ONLY if Unread is selected and no unread messages */}
          <UserListActionBar
            toggleFilter={() => setShowFilter(!showFilter)}
            showFilter={showFilter}
            showThreeDot={false}
            showFilters={false}
            setSearchValue={handleSearchConversation}
          />
        </div>
        {selected == "Unread" ? (
          unreadMessages?.length > 0 ?
            unreadMessages.map((item) => (
              <MessageListComponent
                item={item}
                key={item.contact_id}
                selected={item.contact_id === selectedChat}
                onClick={() => handleContactDetails(item)}
              />
            )) :

            <EmptyUnread setSelected={setSelected} />
        ) : (
          <Box
            id="scrollableDiv"
            sx={{
              marginTop: "10px",
              maxHeight: "calc(100vh - 184px)",
              overflow: "auto",
              scrollbarGutter: "stable",
              marginRight: "4px",
              paddingRight: "4px",
            }}
          >
            <InfiniteScroll
              dataLength={list?.length}
              next={fetchMoreData}
              hasMore={hasMore}
              className="whats-app-infinite-scroll"
              loader={
                <Box display="flex" justifyContent="center" mt={2}>
                  <CircularProgress style={{ color: "green" }} />
                </Box>
              }
              scrollableTarget="scrollableDiv"
            >
              {list.map((item) => (
                <MessageListComponent
                  item={item}
                  key={item.contact_id}
                  selected={item.contact_id === selectedChat?.contact_id}
                  onClick={() => handleContactDetails(item)}
                />
              ))}
            </InfiniteScroll>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default WhatsUpSideBar;
