import React from "react";
import { Button } from "semantic-ui-react";
import google_image from "../../../images/googleimage.svg";
import "./listing.css";
import RootHeader from "../../../modules/listing/Menu/RootHeader";
const MenuListingDetail = ({ location_menu_detail, history, business_id, selectReso }) => {
  return (
    <>
      <div>
        <div>
          {/* <div
            className="d-flex align-baseline gap-10"
          >
            <h2
              style={{ color: "#060658", fontSize: "20px", fontWeight: "600" }}
            >
              Bulk Menu Update{" "}
            </h2>
            <h3
              style={{
                color: "rgb(233 70 133)",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              (39 Selected / 54 Total listings)
            </h3>
          </div> */}
          <RootHeader history={history} defaultValue={0} business_id={business_id} selectReso={selectReso} />
          {/* <div style={{ paddingBlock: "25px" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(443px, 1fr))",
                gap: "24px",
              }}
            >
              <div
                style={{
                  border: "1px solid",
                  // padding: "24px",
                  display: "grid",
                  // gap: "8px",
                  height: "479px",
                  borderRadius: "15px",
                  borderColor: "#BFC2CB",
                }}
              >
                <div className="bulk-menu-card" style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                  <div className="d-flex align-center justify-content-between">
                    <div>
                      <h2 style={{ fontWeight: "500px", fontSize: "16px" }}>
                        Add Menu Items
                      </h2>
                      <span style={{ color: "#7A8193", fontSize: "14px" }}>
                        Add your menu food items or inventory available at your
                        store
                      </span>
                    </div>
                    <div className="view-listing-button">
                      <Button
                        className="d-flex gap-10 flex-row-reverse"
                        style={{
                          backgroundColor: "#00B5AD",
                          color: "#FFF",
                          borderRadius: "50px",
                        }}
                        color="red"
                        content="Add"
                        icon="add"
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <img src={google_image} alt="googleImage" />
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid",
                  // padding: "24px",
                  display: "grid",
                  // gap: "8px",
                  height: "479px",
                  borderRadius: "15px",
                  borderColor: "#BFC2CB",
                }}
              >
                <div className="bulk-menu-card" style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                  <div className="d-flex align-center justify-content-between">
                    <div>
                      <h2 style={{ fontWeight: "500px", fontSize: "16px" }}>
                        Add Menu Images
                      </h2>
                      <span style={{ color: "#7A8193", fontSize: "14px" }}>
                        Update with your product page with latest product &
                        stock
                      </span>
                    </div>
                    <div className="view-listing-button">
                      <Button
                        className="d-flex gap-10 flex-row-reverse"
                        style={{
                          backgroundColor: "#00B5AD",
                          color: "#FFF",
                          borderRadius: "50px",
                        }}
                        color="red"
                        content="Add"
                        icon="add"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <img src={google_image} alt="googleImage" />
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid",
                  // padding: "24px",
                  display: "grid",
                  // gap: "8px",
                  height: "479px",
                  borderRadius: "15px",
                  borderColor: "#BFC2CB",
                }}
              >
                <div className="bulk-menu-card" style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                  <div className="d-flex align-center justify-content-between">
                    <div>
                      <h2 style={{ fontWeight: "500px", fontSize: "16px" }}>
                        Name Menu Highlights
                      </h2>
                      <span style={{ color: "#7A8193", fontSize: "14px" }}>
                        Add your menu food items or inventory available at your
                      </span>
                    </div>
                    <div className="view-listing-button">
                      <Button
                        className="d-flex gap-10 flex-row-reverse"
                        style={{
                          backgroundColor: "#00B5AD",
                          color: "#FFF",
                          borderRadius: "50px",
                        }}
                        color="red"
                        content="Add"
                        icon="add"
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <img src={google_image} alt="googleImage" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div>
          {/* <div
            class="ui four cards"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(183px, 1fr))",
              gap: "12px",
            }}
          > */}
          <div>
            {/* </Card> */}

            {/* {location_menu_detail?.categories?.length > 0
              ? location_menu_detail?.categories?.map((category) => {
                return (
                  <>
                    <div>
                      <h3 style={{ color: "black", display: "flex" }}>
                        {category?.displayName}
                      </h3>
                    </div>
                    <div
                      class="ui four cards"
                      style={{
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fill, minmax(183px, 1fr))",
                        gap: "12px",
                      }}
                    >
                      {category?.menu_items?.length > 0 ? (
                        category?.menu_items?.map((menItem) => {
                          return (
                            <>
                              {menItem?.mediaData?.length > 0 ? (
                                menItem?.mediaData?.map((mediaData) => {
                                  return (
                                    <>
                                      <div className="bulk-img-card-header">
                                        <img
                                          width="100%"
                                          height="auto"
                                          src={mediaData?.googleUrl}
                                          alt="bulkImage1"
                                        />
                                      </div>
                                    </>
                                  );
                                })
                              ) : (
                                // <div className="bulk-img-card-header">
                                //   Data Not Found
                                // </div>
                                ""
                              )}
                            </>
                          );
                        })
                      ) : (
                        // <div className="bulk-img-card-header">
                        //   Data Not Found
                        // </div>
                        ""
                      )}
                    </div>
                  </>
                );
              })
              : ""} */}
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default MenuListingDetail;
