import React from "react";
import { Bar } from "react-chartjs-2";
import { FPBadge } from "../../components/common/new";

const AdsStackedGraph = ({
  dataKeys,
  dataSets,
  primaryYLabel,
  secondaryYLabel,
}) => {
  function removeDuplicateLabels(labels) {
    const uniqueLabels = [];
    const seenLabels = new Set();
  
    labels.forEach(label => {
      if (!seenLabels.has(label.label)) {
        uniqueLabels.push(label);
        seenLabels.add(label.label);
      }
    });
    return uniqueLabels;
  }

  const chartLegends = removeDuplicateLabels(dataSets || []);
  const plugins = [
    {
      beforeDraw: function (c) {
        var legends = removeDuplicateLabels(c.legend.legendItems);
        legends.forEach(function (e, index) {
          e.fillStyle = dataSets[index]?.legendColor;
        });
      },
    },
  ];

  const data = {
    labels: dataKeys,
    datasets: dataSets,
  };
  const options = {
    animation: {
      duration: 2000,
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          fontSize: 16,
          fontStyle: "normal",
          fontFamily: "Outfit",
          boxWidth: 6,
          boxHeight: 6,
          padding: 10,
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font : {
            weight : function (context) {
            const label = dataKeys[context.index];
            return label === 'normal'; // 'bold'
          }
        }
        }
      },
      y: {
        beginAtZero: true,
        display: false,
        stacked: true,
        grid: {
          borderDash: [7],
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      "y-axis-1": {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: primaryYLabel,
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      "y-axis-2": {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: secondaryYLabel,
        },
        grid: {
          display: false,
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
    },
  };
  return (
    <div className="mb-4" style={{ height: "400px" }}>
      <div className="mb-2" style={{ height : '100%', overflowX : 'auto' }} >
        <Bar data={data} options={options} plugins={plugins} />
      </div>
      <div className="d-flex flex-wrap gap-10 mt-2 justify-content-center" >
        {chartLegends.map((item, index) => {
          return (
            <FPBadge
              color={item.legendColor}
              label={<span>{item.label}</span>}
              textClass="font-blue-gray fp-size-13"
              key={`${index}-review-legend`}
            />
          );
        })}
      </div>
    </div>
  );
};
export default AdsStackedGraph;
