import React from "react";
import { Image } from "semantic-ui-react";
import business_svg from "../../../../images/svg/business.svg";
import category_svg from "../../../../images/category.svg";
import hour_svg from "../../../../images/hour.svg";
import openingdate_svg from "../../../../images/openingdate.svg";
import { Card } from "reactstrap";
import { Box, Dialog, DialogActions, IconButton } from "@mui/material";
import UpdateCategory from "./Models/UpdateCategory";
import UpdateOpenDate from "./Models/UpdateOpenDate";
import UpdateOpenHour from "./Models/UpdateOpenHour";
import CloseIcon from "@material-ui/icons/Close";
import UpdateBusinessStatus from "./Models/UpdateBusinessStatus";
import FormModal from "../../../../components/common/FormModal";

const CategoriesUpdates = (props) => {
  const { business_id, queryStringData, filterBusinessCount } = props;

  const [openTab, setOpenTab] = React.useState(null);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (dialog, scrollType) => {
    setOpenTab(dialog);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpenTab(null);
  };

  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <>
      <div className="listing-detail-card">
        <div
          className="d-flex align-items-baseline"
          style={{ padding: "16px 26px", gap: "5px" }}
        >
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#1A2544",
            }}
          >
            Basic Business Details - Categories, Address, & Status
          </h2>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#F13A88",
            }}
          >
            (for {filterBusinessCount} verified & connected listings)
          </h2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "1rem",
            padding: " 0px 24px 24px 24px",
          }}
        >
          <Card
            className="detail-card-item detail-special-card "
            onClick={() => handleClickOpen("add_category", "paper")}
          >
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div className="mb-2">
              <img
                src={category_svg}
                height="28px"
                alt=""
                className="successImg"
              />
            </div>
            <h2 className="bulk-business-heading">
              Update Additional Categories
            </h2>
            <span className="bulk-business-card-review">
              Check & add categories based on search volume & relevancy
            </span>
          </Card>

          <Card
            className="detail-card-item detail-special-card "
            onClick={() => handleClickOpen("Opening_hour", "paper")}
          >
            <div className="mb-2">
              <img src={hour_svg} height="28px" alt="" className="successImg" />
            </div>
            <h2 className="bulk-business-heading">Opening & Closing Hours</h2>
            <span className="bulk-business-card-review">
              Adding opening hours improves completion rate by 75%
            </span>
            {/* <span className="bulk-business-card-review">
                  {primaryDetail?.phoneNumbers?.primaryPhone}
                </span> */}
          </Card>

          <Card
            className="detail-card-item detail-special-card "
            onClick={() => handleClickOpen("business_status", "paper")}
          >
            <div className="mb-2">
              <img
                src={business_svg}
                height="28px"
                alt=""
                className="successImg"
              />
            </div>
            <h2 className="bulk-business-heading">Business Status</h2>
            <span className="bulk-business-card-review">
              Update your business status to inform your customers
            </span>
          </Card>

          <Card
            className="detail-card-item detail-special-card "
            onClick={() => handleClickOpen("Opening_date", "paper")}
          >
            <div className="mb-2">
              <img
                src={openingdate_svg}
                height="28px"
                alt=""
                className="successImg"
              />
            </div>
            <h2 className="bulk-business-heading">Business opening date</h2>
            <span className="bulk-business-card-review">
              Add opening date & age to your brand to your profile
            </span>
          </Card>
        </div>
      </div>

      <FormModal
        isOpen={openTab === "add_category"}
        toggle={handleClose}
        heading="Update Category"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
        bodyClass={"py-0"}
      >
        <UpdateCategory
          queryStringData={queryStringData}
          busines_status={true}
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={openTab === "Opening_date"}
        toggle={handleClose}
        heading="Update Open Date"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
        bodyClass={"py-0"}
      >
        <UpdateOpenDate
          busines_status={true}
          queryStringData={queryStringData}
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={openTab === "Opening_hour"}
        toggle={handleClose}
        heading="Update Business Hours"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
        bodyClass={"py-0"}
      >
        <UpdateOpenHour
          busines_status={true}
          queryStringData={queryStringData}
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={openTab === "business_status"}
        toggle={handleClose}
        heading="Update Business Status"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
        bodyClass={"py-0"}
      >
        <UpdateBusinessStatus
          busines_status={true}
          queryStringData={queryStringData}
          business_id={business_id}
        />
      </FormModal>
    </>
  );
};

export default CategoriesUpdates;
