import React from "react";
import { Image } from "semantic-ui-react";
import service_svg from "../../../../images/service.svg";
import discription_svg from "../../../../images/description.svg";
import question_svg from "../../../../images/question.svg";
import listing_svg from "../../../../images/listing.svg";
import { Card } from "reactstrap";
import { Box, Dialog, DialogActions, IconButton } from "@mui/material";
import UpdateDescription from "./Models/UpdateDescription";
import UpdateQuestion from "./Models/UpdateQuestion";
import UpdateAttribute from "./Models/UpdateAttribute";
import CloseIcon from "@material-ui/icons/Close";
import FormModal from "../../../../components/common/FormModal";

const ServiceUpdate = (props) => {
  const { business_id, locationList, queryStringData, filterBusinessCount } =
    props;

  const [openTab, setOpenTab] = React.useState(null);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (dialog, scrollType) => {
    setOpenTab(dialog);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpenTab(null);
  };

  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <>
      <div className="listing-detail-card">
        <div
          className="d-flex align-items-baseline"
          style={{ padding: "16px 26px", gap: "5px" }}
        >
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#1A2544",
            }}
          >
            Service, FAQ’s Amenities & Description update
          </h2>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#F13A88",
            }}
          >
            (for {filterBusinessCount} verified & connected listings)
          </h2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "1rem",
            padding: " 0px 24px 24px 24px",
          }}
        >
          <Card
            className="detail-card-item detail-special-card "
            onClick={() => handleClickOpen("website_link", "paper")}
          >
            <div className="mb-2">
              <img
                src={service_svg}
                height="28px"
                alt=""
                className="successImg"
              />
            </div>
            <h2 className="bulk-business-heading">Add Business Services</h2>
            <span className="bulk-business-card-review">
              Improve your ranking by 10% with services & services description
            </span>
          </Card>

          <Card
            className="detail-card-item detail-special-card"
            onClick={() => handleClickOpen("description", "paper")}
          >
            <div className="mb-2">
              <img
                src={discription_svg}
                height="28px"
                alt=""
                className="successImg"
              />
            </div>
            <h2 className="bulk-business-heading">Short & Long Description</h2>
            <span className="bulk-business-card-review">
              Describe your business to improve profile strength & completion
              score
            </span>
          </Card>

          <Card
            className="detail-card-item detail-special-card "
            onClick={() => handleClickOpen("question_ans", "paper")}
          >
            <div className="mb-2">
              <img
                src={question_svg}
                height="28px"
                alt=""
                className="successImg"
              />
            </div>
            <h2 className="bulk-business-heading">Answer Business FAQ’s</h2>
            <span className="bulk-business-card-review">
              Add & update FAQ’s about common queries for listings
            </span>
          </Card>

          <Card
            className="detail-card-item detail-special-card "
            onClick={() => handleClickOpen("attributes", "paper")}
          >
            <div className="mb-2">
              <img
                src={listing_svg}
                height="28px"
                alt=""
                className="successImg"
              />
            </div>
            <h2 className="bulk-business-heading">
              Listing Attributes & Amenities
            </h2>
            <span className="bulk-business-card-review">
              Add details about parking, amenities, payment methods, etc.
            </span>
          </Card>
        </div>
      </div>

      <FormModal
        isOpen={openTab === "question_ans"}
        toggle={handleClose}
        heading="Update Question & Answer"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
        bodyClass={"py-0"}
      >
        <UpdateQuestion
          busines_status={true}
          queryStringData={queryStringData}
          business_id={business_id}
        />
      </FormModal>
      <FormModal
        isOpen={openTab === "attributes"}
        toggle={handleClose}
        heading="Update Attribute"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
        bodyClass={"py-0"}
      >
        <UpdateAttribute
          busines_status={true}
          queryStringData={queryStringData}
          business_id={business_id}
        />
      </FormModal>
      <FormModal
        isOpen={openTab === "description"}
        toggle={handleClose}
        heading="Update Description"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
        bodyClass={"py-0"}
      >
        <UpdateDescription
          busines_status={true}
          queryStringData={queryStringData}
          business_id={business_id}
        />
      </FormModal>
    </>
  );
};

export default ServiceUpdate;
