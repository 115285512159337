import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as URLS from '../../libs/apiUrls';
import * as SocialSetupActions from '../../actions/business/socialSetupActions';
import axios_instance from '../../libs/interseptor';
import { withRouter } from 'react-router';
import LoaderComponent from '../common/loaderComponent';
import { images } from "../../images";
class SetupGmailSocialAccounts extends React.Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.business_id !== prevState.business_id) {
      return { business_id: nextProps.business_id };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      business_id: this.props.business_id,
      btnText: 'Connect to Gmail',
      name: '',
      connected: false,
      loader: false
    };
  }

  componentDidMount() {
    // this.props.business!==undefined && this.checkLoggedIn();
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevState.business_id !== this.state.business_id) {
    //   this.checkLoggedIn();
    // }
  }

  gmailConnect(code){
    console.log("code", code);
    const details = {
      'code' : code,
      'business': this.state.business_id
    };
    return axios_instance.post(`${URLS.SOCIAL}gmail/`,details)
      .then(res=>{
        // this.checkLoggedIn();
        console.log(res);
        this.props.callSocialProfileApi();
        this.props.handleConnectProfile();
        window.removeEventListener('message',(data)=>console.log(data));
      })
      .catch(err=>{
        console.log(err);
        this.setState({ loader: false });
      });
  }

  connectSocial(data){
    console.log("data", data);
    this.gmailConnect(data.data.code);
  }


getAuthUrl(data) {
  this.setState({ loader: true });

  // Define a named function to handle the event
  const handleMessage = (event) => {
    if (event.origin !== window.location.origin) return; // Security check
    this.connectSocial(event);
    window.removeEventListener('message', handleMessage); // Remove listener after handling
  };

  this.props.SocialSetupActions.getAuthUrl({ provider: data })
    .then(() => {
      const { socialSetupList } = this.props;
      const authUrl = socialSetupList.auth_url;
      console.log("authUrl", authUrl);
      
      this.newWindow = window.open(authUrl, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400');
      this.setState({ provider: data, loader: false });

      // Remove any existing event listener before adding a new one
      window.removeEventListener('message', handleMessage);
      window.addEventListener('message', handleMessage);
    })
    .catch((error) => {
      console.log("error", error);
      this.setState({ loader: false });
    });
}
  render() {
    const { connectedStatus, toggleTab } = this.props;
    const cssStyle = { display: 'flex', gap: '10px', borderRadius: '25px', justifyContent: 'center', alignItems: 'center', background: '#ea5a38', cursor: 'pointer', textAlign: 'center'}
    return (
      <div className="my-2">
        <LoaderComponent loader={this.state.loader}/>
        <Row>
          <Col>
            <div className="text-center mb-2">
              <span className="google-button" style={cssStyle} onClick={() => this.getAuthUrl('gmail')} >
                  <div style={{ background: 'white', borderRadius: '4px'}}>
                    <img src={images.gmailIcon} alt='Gmail Icon' height="25"  style={{ padding: '4px'}}/>
                  </div>
                  <span>Connect to Gmail</span>
                </span>
              <div className='mt-2' onClick={toggleTab} style={{ textAlign: 'center', fontWeight: '600', textDecoration: 'underline', fontSize: '14px', cursor: 'pointer' }}>
                {connectedStatus ? connectedStatus : this.state.connected ? 1 : 0} profile already connected
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
    socialSetupList: state.socialSetup.list,
    isLoggedIn: state.socialSetup.isLoggedIn
  };
};

function mapDispatchToProps(dispatch) {
  return {
    SocialSetupActions: bindActionCreators(SocialSetupActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps,null,{forwardRef: true})(SetupGmailSocialAccounts));
