import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Select, alpha } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import FPMenuItem from "../../FPMenu/FPMenuItem";
import FPLabel from "./FPLabel";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    "& .MuiInputBase-root": {
      width: "100%",
      border: "1px solid",
      borderRadius: "8px",
      borderColor: ({ error }) => (error ? "#f44336" : "#BFC2CB"),
      height: 38,
    },
    "& .MuiSelect-select.MuiSelect-select": {
      width: " 100%",
      padding: " 8px 12px",
      position: " relative",
      fontSize: "12px",
      transition:
        " border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderRadius: "8px",
      backgroundColor: " #fff",
      paddingRight: "42px",
      textAlign: "left",
    },
    "& .MuiSelect-nativeInput": {
      all: "unset",
      width: "100%",
      height: "100%",
      pointerEvents: "none",
      position: "absolute",
      // opacity: (props) => (props.value ? "0 !important" : "0.6"),
      opacity: (props) => (props.value ? "0 !important" : "0.6"),
      left: "0",
      textAlign: "left",
      paddingLeft: "12px",
      outline: 0,
      border: 0,
      background: "transparent",
      paddingRight: 0,
    },
  },
  select: {
    width: "auto",
    fontSize: "12px",
    "&:focus": {
      boxShadow: ({ error }) =>
        `${alpha(error ? "#f44336" : "#008eff", 0.25)} 0 0 0 0.2rem`,
      borderColor: ({ error }) => (error ? "#f44336" : "#008eff"),
    },
  },
  selectIcon: {
    position: "absolute",
    color: "#bfc2cb",
    fontSize: "20px",
    pointerEvents: "none",
    right: "7px",
    top: "auto",
  },
  paper: {
    marginTop: "1px",
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

// Option : type {label, value}
const FPSelect = ({
  value,
  onChange,
  error,
  label,
  options,
  isRequired = true,
  children,
  placeHolder,
  wrapperClass,
  ...rest
}) => {
  const [val, setVal] = React.useState(value);
  const handleChange = (event) => {
    setVal(event.target.value);
    onChange && onChange(event, event.target.value);
  };

  const classes = useStyles({ value: val, error });
  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  return (
    <div className={classNames(wrapperClass)}>
      {label && <FPLabel isRequired={isRequired}>{label}</FPLabel>}
      <FormControl className={classes.formControl}>
        <Select
          value={val}
          onChange={handleChange}
          disableUnderline
          IconComponent={ExpandMoreRoundedIcon}
          placeholder={
            placeHolder ? placeHolder : `Select ${label ? label : "item"}`
          }
          MenuProps={menuProps}
          classes={{
            select: classes.select,
            icon: classes.selectIcon,
          }}
          {...rest}
        >
          {options &&
            options.map((item, index) => (
              <FPMenuItem
                showDivider={false}
                key={`${index}-${item.value}-fp-select-value`}
                value={item.value}
              >
                {item.label}
              </FPMenuItem>
            ))}
          {children && children}
        </Select>
      </FormControl>
    </div>
  );
};

export default FPSelect;
