import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, CardBody, Card } from 'reactstrap';

import * as CampaignActions from '../../actions/campaign/campaignActions';

import LoaderComponent from '../../components/common/loaderComponent';

class UnsubscribePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loader: true, name: '' };
  }

  componentDidMount(){
    const { match: { params: { id }} } = this.props;
    return this.props.actions.unsubscribe(id)
      .then((res)=>{
        this.setState({ loader: false, name: res.data.name });
      });
  }

  render() {
    const { loader, name } = this.state;
    return (
      <Row className="align-items-center justify-content-center login-bg full-height" >
        <LoaderComponent loader={loader} />
        <Col xs={12} sm={6}>
          <div>
            <Card className="text-center feedback-already-exist">
              <CardBody className="py-4 feedback-pink-bg">
                <Row className="py-3 feedback-title">
                  <Col className="col-12 pb-2 text-capitalize">
                    <h2>Hi {name},</h2>
                  </Col>
                  <Col className="col-12">
                    <b>You have successfully unsubscribed.</b>
                  </Col>
                  <Col className="col-12">
                    <b>Thanks for showing your interest.</b>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    );
  }
}


const mapStateToProps = (state) => ({
  campaign : state.campaign
});

UnsubscribePage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  campaign: PropTypes.instanceOf(Object),
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CampaignActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(UnsubscribePage);
