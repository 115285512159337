import React, { useEffect, useState } from "react";
import "./ReviewEmailStyle.css";
import SearchComponent from "../../components/common/SearchComponent";
import { FPAvatar, FPMenuBox } from "../../components/common/new";
import attachment from "../../images/svg/attachment.svg";
import { formatCustomDate } from "../../libs/utils";

function EmailInboxList({ list, setSelectedIndex, selectedIndex, props, setIndex }) {
  const [dropdownOptions] = useState([
    {
      label: "Recent",
      value: "recent",
    },
  ]);
useEffect(() => {
  setSelectedIndex(0)
} , [])

  return (
    <div className="email-inbox">
      <div className="email-inbox-header">
        <h2>Inbox</h2>
        <div className="select-recent">
          {/* <FPMenuBox
            title="Recent"
            options={dropdownOptions}
            size="small"
            rounded={false}
            startIcon={null}
            hideDropDownArrowIcon={false}
            className="transparent"
            dropDownArrowMargin="-15px"
          /> */}
        </div>
      </div>
      <div className="search-container">
        <SearchComponent {...props} className="fp-flex-grow-1" endIcon />
      </div>
      <div className="email-list">
        {list?.map((email, index) => (
          <div
            key={email.id}
            className="email-item"
            style={{backgroundColor: selectedIndex === index && "#F2F9F9"}}
            onClick={() => setIndex(email, index)}
          >
            <div className="email-content-wrapper">
              <FPAvatar avatarCenter={false} src={email?.avatar} labelClass={"user-name"} alt="" className="email-avatar" />
              <div className="email-content">
                <div className="email-header">
                  <div className="sender-name">{email?.sender_id}</div>
                  <div className="d-flex cursor-pointer m-0">
                    {email?.attachments > 0 && (

                  <img src={attachment} alt="attachment-icon" style={{height: "18px"}}/>
                    )}
                  <div className="email-time">{email.msg_create}</div>
                    </div>
                </div>
                <div className="email-subject">{email?.subject}</div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-truncate email-preview"  style={{width:"400px"}}>{email?.body}</div>
                  {email.count > 1 && (
                    <div className="email-badge">{email?.count ? email?.count : 0}</div>
                  )}
                </div>
              </div>
            </div>
          </div>  
        ))}
      </div>
    </div>
  );
}

export default EmailInboxList;
