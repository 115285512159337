import React, { useEffect, useState } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { ListDescription } from "semantic-ui-react";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import { Chip } from "@mui/material";
import "./listing.css";
import select_svg from "../../../images/select.svg";
import { formatReviews } from "../../../libs/helper";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import google_svg from "../../../images/googlephoto.svg";
import duplicate_svg from "../../../images/dusplicate.svg";
import * as listingUtils from "../../../libs/listingToast";

const NotificationList = (props) => {
  const {
    business_id,
    selectReso,
    updateDiffDataData,
    actions,
    primaryDetail,
  } = props;

  // useEffect(() => {
  //   actions.getGmbLocationUpdateDiffData(business_id, selectReso);
  // }, [actions, business_id, selectReso]);

  const [selectedItems, setSelectedItems] = useState({});
  console.log(
    "selectedItemsselectedItemsselectedItemsselectedItems",
    selectedItems?.actual_selected_attributes
  );
  const [afterSelectedItems, setAfterSelectedItems] = useState({});

  useEffect(() => {
    if (updateDiffDataData) {
      const updatedAttributeData = JSON.parse(
        JSON.stringify(updateDiffDataData)
      );
      Object.keys(updatedAttributeData)?.forEach((attribute) => {
        if (attribute === "updated_attributes")
          Object.keys(updatedAttributeData[attribute])?.forEach((item) => {
            console.log("itemitemitemitemitemitem", item);

            if (item.valueType === "BOOL") {
              item.originalSelecte = item.selected;
            }
          });
      });
      console.log(updatedAttributeData, "updatedAttributeData");
      setSelectedItems(updatedAttributeData);
      setAfterSelectedItems(updatedAttributeData?.updated_attributes);
    }
  }, [updateDiffDataData]);

  const handleChipClickService = (
    group,
    index,
    repeatedEnumIndex = null,
    type
  ) => {
    const updatedItems = { ...afterSelectedItems };

    // if (type === "delete_actual") {
    //   if (repeatedEnumIndex !== null) {
    //     // Handle repeated enum case
    //     const item = updatedItems[group][index];
    //     const repeatedEnum = item.valueMetadata[repeatedEnumIndex];
    //     repeatedEnum.selected = !repeatedEnum.selected;
    //     item.originalSelecte = true;
    //     item.valueMetadata[repeatedEnumIndex] = repeatedEnum;
    //   } else {
    //     // Handle non-repeated enum case (BOOL case)
    //     const item = updatedItems[group][index];

    //     // Get the current selection state from selectedItems
    //     let actual_select =
    //       selectedItems?.actual_selected_attributes?.[item?.parent];
    //     console.log("Current actual_select:", actual_select);

    //     if (actual_select) {
    //       // Clone the `actual_select` object before modifying it
    //       const updatedActualSelect = {
    //         ...actual_select,
    //         values: [!actual_select?.values[0]], // Toggle the boolean value
    //       };

    //       // Update the actual_selected_attributes in selectedItems
    //       const updatedSelectedItems = { ...selectedItems };
    //       updatedSelectedItems.actual_selected_attributes[item.parent] =
    //         updatedActualSelect;

    //       console.log("Updated actual_select:", updatedActualSelect);

    //       // Optionally update the updated_data field in afterSelectedItems

    //       // Update the afterSelectedItems state
    //       // setSelectedItems(updatedSelectedItems);
    //     }
    //   }
    // } else {
    if (repeatedEnumIndex !== null) {
      const item = updatedItems[group][index];
      console.log(
        "itemitemitemitemitemitemitemitemitemitemitemitemitemitem",
        item
      );
      const repeatedEnum = item.valueMetadata[repeatedEnumIndex];
      console.log("repeatedEnumrepeatedEnumrepeatedEnum", repeatedEnum);
      repeatedEnum.selected = !repeatedEnum.selected;
      item.originalSelecte = true;
      item.valueMetadata[repeatedEnumIndex] = repeatedEnum;
    } else {
      const item = updatedItems[group][index];
      console.log("itemitemitemitemitemitem          >>>>", item);
      item.updated_data = {
        name: item.parent,
        values: [!item.updated_data.values?.[0]],
        valueType: item.valueType,
      };
    }
    // }

    // Apply the updated items to the state
    setAfterSelectedItems(updatedItems);
  };

  const handleSingleSelectChipClick = (
    group,
    index,
    repeatedEnumIndex,
    repeatedEnumData
  ) => {
    //only single select chip
    const updatedItems = { ...afterSelectedItems };
    const item = updatedItems[group][index];
    const repeatedEnum = item.valueMetadata[repeatedEnumIndex];
    updatedItems[group][index].valueMetadata = updatedItems[group][
      index
    ].valueMetadata.map((enumItem) => ({
      ...enumItem,
      selected: false,
    }));
    repeatedEnum.selected = !repeatedEnum.selected;
    updatedItems[group][index].valueMetadata[repeatedEnumIndex] = repeatedEnum;
    setAfterSelectedItems(updatedItems);
  };

  const addressString = [
    primaryDetail?.addressLines,
    primaryDetail?.locality,
    primaryDetail?.postalCode,
  ].join(", ");

  const renderChip = (
    item,
    idx,
    attribute,
    handleChipClickService,
    select_svg,
    type
  ) => {
    console.log(
      "itemitemitemitemitemitemitemitemitemitemilsdufyhsdilfjd",
      item
    );
    const actual_select =
      selectedItems?.actual_selected_attributes?.[item?.parent]?.values?.[0];

    return (
      <Chip
        key={idx}
        label={item.displayName}
        // onClick={() => handleChipClickService(attribute, idx)}
        deleteIcon={
          item.updated_data?.values[0] ? (
            <img src={select_svg} alt="selectImage" />
          ) : null
        }
        onDelete={
          item.updated_data?.values[0]
            ? () => handleChipClickService(attribute, idx)
            : null
        }
        variant={item.updated_data?.values[0] ? "filled" : "outlined"}
        sx={{
          backgroundColor: item.updated_data?.values[0] ? "#bfe0e2" : "#F0F1F3",
          color: item.updated_data?.values[0] ? "#00b5ad" : "default",
          "& .MuiChip-deleteIcon": {
            color: item.updated_data?.values[0] ? "white" : "default",
          },
          fontSize: "18px",
          fontWeight: "400",
          padding: "12px",
          border: "0",
        }}
      />
    );
  };

  const renderChipDelete = (
    item,
    idx,
    attribute,
    handleChipClickService,
    select_svg,
    type
  ) => {
    const actual_select =
      selectedItems?.actual_selected_attributes?.[item?.parent]?.values?.[0];

    // Determine if the chip is in "selected" state when type is "delete_actual"
    // type === "delete_actual";
    return (
      <Chip
        key={idx}
        label={item?.displayName || "unknown"} // Fallback for label
        // onClick={() =>
        //   type === "delete_actual" || "delete_suggestion"
        //     ? handleChipClickService(attribute, idx, null, type)
        //     : null
        // }
        deleteIcon={
          type === "delete_actual" && actual_select ? (
            <img src={select_svg} alt="selectImage" />
          ) : null
        }
        onDelete={
          type === "delete_actual" && actual_select
            ? () => handleChipClickService(attribute, idx)
            : null
        }
        variant={
          type === "delete_actual" && actual_select ? "filled" : "outlined"
        }
        sx={{
          backgroundColor:
            type === "delete_actual" && actual_select ? "#bfe0e2" : "#F0F1F3",
          color:
            type === "delete_actual" && actual_select ? "#00b5ad" : "default",
          "& .MuiChip-deleteIcon": {
            color:
              type === "delete_actual" && actual_select ? "white" : "default",
          },
          fontSize: "18px",
          fontWeight: "400",
          padding: "12px",
          border: "0",
        }}
      />
    );
  };

  const renderRepeatedEnumChip = (
    repeatedEnum,
    indexenum,
    attribute,
    parentIndex,
    handleChipClickService,
    select_svg,
    valueType,
    type,
    update_type,
    item
  ) => {
    // For ENUM type
    console.log("dfsjdhfasjhdfhasdfasdfsd,type", type);
    if (update_type === "updated") {
      if (valueType === "ENUM") {
        console.log(
          "selectedItemsselectedItemsselectedItemsselectedItemsselectedItems",
          selectedItems
        );
        const actualSelect =
          selectedItems?.actual_selected_attributes?.[item?.parent]?.values ||
          [];
        console.log("actualSelectactualSelectactualSelect", actualSelect);
        let isSelected = actualSelect.includes(repeatedEnum.value);
        console.log(
          "isSelectedisSelectedisSelectedisSelectedisSelected",
          isSelected
        );
        // Handle the "delete_suggest" type if needed
        if (type === "delete_suggest") {
          isSelected = item.updated_data.values.includes(repeatedEnum.value);
        }

        console.log(
          "ENUM Value:",
          repeatedEnum.value,
          "isSelected:",
          isSelected
        );

        return (
          <Chip
            key={indexenum}
            label={repeatedEnum.displayName}
            // onClick={() =>
            //   update_type === "new"
            //     ? handleSingleSelectChipClick(
            //         attribute,
            //         parentIndex,
            //         indexenum,
            //         repeatedEnum
            //       )
            //     : handleChipClickService(attribute, parentIndex, indexenum)
            // }
            deleteIcon={
              isSelected &&
              (type === "delete_actual" || type === "delete_suggest") ? (
                <img src={select_svg} alt="selectImage" />
              ) : null
            }
            onDelete={
              isSelected &&
              (type === "delete_actual" || type === "delete_suggest")
                ? () =>
                    handleChipClickService(attribute, parentIndex, indexenum)
                : null
            }
            variant={
              isSelected &&
              (type === "delete_actual" || type === "delete_suggest")
                ? "filled"
                : "outlined"
            }
            sx={{
              backgroundColor:
                isSelected &&
                (type === "delete_actual" || type === "delete_suggest")
                  ? "#bfe0e2"
                  : "#F0F1F3",
              color:
                isSelected &&
                (type === "delete_actual" || type === "delete_suggest")
                  ? "#00b5ad"
                  : "default",
              "& .MuiChip-deleteIcon": {
                color:
                  isSelected &&
                  (type === "delete_actual" || type === "delete_suggest")
                    ? "white"
                    : "default",
              },
              fontSize: "18px",
              fontWeight: "400",
              padding: "12px",
              border: "0",
              marginBottom: "15px",
              marginRight: "15px",
            }}
          />
        );
      }

      // For REPEATED_ENUM type
      if (valueType === "REPEATED_ENUM") {
        const actual_select =
          selectedItems?.actual_selected_attributes?.[item?.parent]
            ?.repeatedEnumValue || {};
        const setValues = actual_select?.setValues || [];
        let isSelected = setValues.includes(repeatedEnum.value);

        if (type === "delete_suggest") {
          // Ensure updated_data.repeatedEnumValue.setValues is an array
          const updatedSetValues =
            item.updated_data.repeatedEnumValue?.setValues || [];
          isSelected = updatedSetValues.includes(repeatedEnum.value);
        }

        console.log(
          "REPEATED_ENUM Value:",
          repeatedEnum.value,
          "isSelected:",
          isSelected
        );

        return (
          <Chip
            key={indexenum}
            label={repeatedEnum.displayName}
            // onClick={() =>
            //   update_type === "new"
            //     ? handleSingleSelectChipClick(
            //         attribute,
            //         parentIndex,
            //         indexenum,
            //         repeatedEnum
            //       )
            //     : handleChipClickService(attribute, parentIndex, indexenum)
            // }
            deleteIcon={
              isSelected &&
              (type === "delete_actual" || type === "delete_suggest") ? (
                <img src={select_svg} alt="selectImage" />
              ) : null
            }
            onDelete={
              isSelected &&
              (type === "delete_actual" || type === "delete_suggest")
                ? () =>
                    handleChipClickService(attribute, parentIndex, indexenum)
                : null
            }
            variant={isSelected ? "filled" : "outlined"}
            sx={{
              backgroundColor: isSelected ? "#bfe0e2" : "#F0F1F3",
              color: isSelected ? "#00b5ad" : "default",
              "& .MuiChip-deleteIcon": {
                color: isSelected ? "white" : "default",
              },
              fontSize: "18px",
              fontWeight: "400",
              padding: "12px",
              border: "0",
              marginBottom: "15px",
              marginRight: "15px",
            }}
          />
        );
      }
    } else if (update_type === "deleted") {
      if (valueType === "ENUM") {
        const actual_select =
          selectedItems?.actual_selected_attributes?.[item?.parent]?.values ||
          [];
        const isSelected = actual_select.includes(repeatedEnum.value);

        console.log(
          "ENUM Value:",
          repeatedEnum.value,
          "isSelected:",
          isSelected
        );

        return (
          <Chip
            key={indexenum}
            label={repeatedEnum.displayName}
            // onClick={() =>
            //   update_type === "new"
            //     ? handleSingleSelectChipClick(
            //         attribute,
            //         parentIndex,
            //         indexenum,
            //         repeatedEnum
            //       )
            //     : handleChipClickService(attribute, parentIndex, indexenum)
            // }
            deleteIcon={
              isSelected && type === "delete_actual" ? (
                <img src={select_svg} alt="selectImage" />
              ) : null
            }
            onDelete={
              isSelected && type === "delete_actual"
                ? () =>
                    handleChipClickService(attribute, parentIndex, indexenum)
                : null
            }
            variant={
              isSelected && type === "delete_actual" ? "filled" : "outlined"
            }
            sx={{
              backgroundColor:
                isSelected && type === "delete_actual" ? "#bfe0e2" : "#F0F1F3",
              color:
                isSelected && type === "delete_actual" ? "#00b5ad" : "default",
              "& .MuiChip-deleteIcon": {
                color:
                  isSelected && type === "delete_actual" ? "white" : "default",
              },
              fontSize: "18px",
              fontWeight: "400",
              padding: "12px",
              border: "0",
              marginBottom: "15px",
              marginRight: "15px",
            }}
          />
        );
      }

      // For REPEATED_ENUM type
      if (valueType === "REPEATED_ENUM") {
        const actual_select =
          selectedItems?.actual_selected_attributes?.[item?.parent]
            ?.repeatedEnumValue || {};
        const setValues = actual_select?.setValues || [];
        const isSelected = setValues.includes(repeatedEnum.value);

        console.log(
          "REPEATED_ENUM Value:",
          repeatedEnum.value,
          "isSelected:",
          isSelected
        );

        return (
          <Chip
            key={indexenum}
            label={repeatedEnum.displayName}
            // onClick={() =>
            //   update_type === "new"
            //     ? handleSingleSelectChipClick(
            //         attribute,
            //         parentIndex,
            //         indexenum,
            //         repeatedEnum
            //       )
            //     : handleChipClickService(attribute, parentIndex, indexenum)
            // }
            deleteIcon={
              isSelected && type === "delete_actual" ? (
                <img src={select_svg} alt="selectImage" />
              ) : null
            }
            onDelete={
              isSelected && type === "delete_actual"
                ? () =>
                    handleChipClickService(attribute, parentIndex, indexenum)
                : null
            }
            variant={
              isSelected && type === "delete_actual" ? "filled" : "outlined"
            }
            sx={{
              backgroundColor:
                isSelected && type === "delete_actual" ? "#bfe0e2" : "#F0F1F3",
              color:
                isSelected && type === "delete_actual" ? "#00b5ad" : "default",
              "& .MuiChip-deleteIcon": {
                color:
                  isSelected && type === "delete_actual" ? "white" : "default",
              },
              fontSize: "18px",
              fontWeight: "400",
              padding: "12px",
              border: "0",
              marginBottom: "15px",
              marginRight: "15px",
            }}
          />
        );
      }
      return null;
    }
    return null;
  };
  const [itemSelectedItems, setItemSelectedItems] = useState(new Set());

  useEffect(() => {
    itemSelectedItems.forEach((item) => {
      console.log(item, "...............................");
    });
  }, [itemSelectedItems]);

  const handleCheckboxChange = (item, isChecked) => {
    setItemSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);

      if (isChecked) {
        newSelectedItems.add(item);
      } else {
        newSelectedItems.delete(item);
      }

      return newSelectedItems;
    });
  };

  const generatePayload = (items) => {
    const payload = [];
    const deletePayload = []; // To handle deletions separately

    items.forEach((item) => {
      if (item.update_type !== "deleted") {
        if (item.valueType === "ENUM") {
          const enumValues = item.updated_data?.values || [];

          if (enumValues.length > 0) {
            payload.push({
              name: item.parent,
              valueType: item.valueType,
              values: enumValues,
            });
          }
        } else if (item.valueType === "REPEATED_ENUM") {
          const selectedValues = item.valueMetadata
            .filter((enumItem) => enumItem.selected)
            .map((enumItem) => enumItem.value);
          const unselectedValues = item.valueMetadata
            .filter((enumItem) => !enumItem.selected)
            .map((enumItem) => enumItem.value);

          payload.push({
            name: item.parent,
            valueType: item.valueType,
            repeatedEnumValue: {
              setValues: selectedValues,
              unsetValues: unselectedValues,
            },
          });
        } else if (item.valueType === "BOOL") {
          if (item.updated_data?.values[0]) {
            payload.push({
              name: item.parent,
              valueType: item.valueType,
              values: [item.updated_data?.values[0]], // Accessing the selected value
            });
          }
        }
      }

      // Handle delete case
      else if (item.update_type === "deleted") {
        if (item.valueType === "ENUM") {
          deletePayload.push(item.parent);
        } else if (item.valueType === "REPEATED_ENUM") {
          deletePayload.push(item.parent);
        } else if (item.valueType === "BOOL") {
          deletePayload.push(item.parent);
        }
      }
    });

    return { payload, deletePayload };
  };

  const handleUPdateAttributes = async () => {
    // console.log("payload :", item);
    const { payload, deletePayload } = generatePayload(itemSelectedItems);
    const restodetail = {
      business_id,
      selectReso,
    };

    if (deletePayload.length > 0) {
      await actions.getGmbListingUpdateListing(
        {
          deleted_attrs: deletePayload,
          attributes: payload,
          type: "updateAttributes",
          notificationType: true,
        },

        restodetail
      );
    } else {
      await actions.getGmbListingUpdateListing(
        {
          attributes: payload,
          type: "updateAttributes",
          notificationType: true,
        },
        restodetail
      );
    }

    listingUtils?.displayMessage(
      "info",
      "Listing getting updated, It may take a few minutes."
    );
    await actions.getGmbListingRefreshListing(business_id, selectReso);

    listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    listingUtils?.displayMessage("positive", "Listing updated successfully.");
    await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };

  return (
    <>
      <div
        className="listing-container"
        style={{ overflow: "hidden", gap: "32px" }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <div className="listing-header">
            <div className="listing-info">
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="listing-name">{primaryDetail?.title}</div>
                <img
                  src={
                    primaryDetail?.is_varified
                      ? verified_svg
                      : primaryDetail?.is_suspend
                      ? duplicate_svg
                      : primaryDetail?.is_duplicate
                      ? duplicate_svg
                      : ""
                  }
                  alt="verifiedImage"
                />
              </div>
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(1)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>
                  {formatReviews(primaryDetail?.totalReviewCount)} Reviews
                </span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>{addressString}</ListDescription>
          </div>
        </div>
      </div>
      <div
        className="listing-container"
        style={{ display: "grid", gap: "24px" }}
      >
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "calc(100% - 120px)",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "32px",
                paddingBottom: "5px",
              }}
            >
              <div className="listing-name">
                Select attributes which best describe your business
              </div>
              {Object.keys(selectedItems || {}).map((attribute, index) => {
                if (attribute === "updated_attributes") {
                  return Object.keys(selectedItems[attribute] || {}).map(
                    (updateAttributes, idx) => {
                      if (updateAttributes === "new_attributes") {
                        return (
                          <div key={idx} className="enumOption">
                            <p>New Attributes - Suggested By Google</p>

                            {selectedItems?.[attribute]?.[updateAttributes]
                              ?.length > 0 ? (
                              selectedItems?.[attribute]?.[
                                updateAttributes
                              ]?.map((item, itemIdx) => {
                                // Log item for debugging
                                const isChecked = itemSelectedItems.has(item);

                                console.log("New Attribute Item:", item);
                                if (item?.valueType === "BOOL") {
                                  return (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        <input
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              item,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </div>
                                      {renderChip(
                                        item,
                                        itemIdx,
                                        attribute,
                                        handleChipClickService,
                                        select_svg
                                      )}
                                    </>
                                  );
                                } else if (
                                  item?.valueType === "ENUM" ||
                                  item?.valueType === "REPEATED_ENUM"
                                ) {
                                  return (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        <input
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              item,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </div>
                                      <p key={itemIdx}>{item?.displayName}</p>
                                      {Array.isArray(item?.valueMetadata) &&
                                        item.valueMetadata.map(
                                          (repeatedEnum, indexenum) =>
                                            renderRepeatedEnumChip(
                                              repeatedEnum,
                                              indexenum,
                                              attribute,
                                              itemIdx,
                                              handleChipClickService,
                                              select_svg,
                                              item?.valueType,
                                              item
                                            )
                                        )}
                                    </>
                                  );
                                }
                                return null; // Ensure a return for each branch
                              })
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                -- No New Attributes --
                              </div>
                            )}
                          </div>
                        );
                      } else if (updateAttributes === "updated_attributes") {
                        return (
                          <div key={idx} className="enumOption">
                            <p>Updated Attributes</p>
                            {selectedItems?.[attribute]?.[updateAttributes]
                              ?.length > 0 ? (
                              selectedItems?.[attribute]?.[
                                updateAttributes
                              ]?.map((item, itemIdx) => {
                                console.log("Updated Attribute Item:", item);
                                const isChecked = itemSelectedItems.has(item);

                                // Handle BOOL valueType
                                if (item?.valueType === "BOOL") {
                                  return (
                                    <>
                                      {/* <div key={itemIdx} className="enumOption"> */}
                                      {/* Render chips for updated attributes */}
                                      <p>Actual Attributes</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: "15px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p>{item?.groupDisplayName}</p>
                                          {/* <input
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              item,
                                              e.target.checked
                                            )
                                          }
                                        /> */}
                                        </div>

                                        {renderChip(
                                          item,
                                          itemIdx,
                                          attribute,
                                          handleChipClickService,
                                          select_svg,
                                          "update_actual"
                                        )}
                                      </div>
                                      <p>Suggested By Google to update</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: "15px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p>{item?.groupDisplayName}</p>
                                          <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={(e) =>
                                              handleCheckboxChange(
                                                item,
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </div>

                                        {renderChip(
                                          item,
                                          itemIdx,
                                          attribute,
                                          handleChipClickService,
                                          select_svg,
                                          "update_suggest"
                                        )}
                                      </div>
                                      {/* </div> */}
                                    </>
                                  );
                                }
                                // Handle ENUM or REPEATED_ENUM valueType
                                else if (
                                  item?.valueType === "ENUM" ||
                                  item?.valueType === "REPEATED_ENUM"
                                ) {
                                  return (
                                    <>
                                      {/* <div key={itemIdx} className="enumOption"> */}
                                      <p>Actual Attributes</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        {/* <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            item,
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                      </div>
                                      <p>{item?.displayName}</p>
                                      {Array.isArray(item?.valueMetadata) &&
                                        item.valueMetadata.map(
                                          (repeatedEnum, indexenum) =>
                                            renderRepeatedEnumChip(
                                              repeatedEnum,
                                              indexenum,
                                              attribute,
                                              itemIdx,
                                              handleChipClickService,
                                              select_svg,
                                              item?.valueType,
                                              "delete_actual",
                                              item?.update_type,
                                              item
                                            )
                                        )}
                                      <p>Suggested By Google to update</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        <input
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              item,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </div>
                                      <p>{item?.displayName}</p>
                                      {Array.isArray(item?.valueMetadata) &&
                                        item.valueMetadata.map(
                                          (repeatedEnum, indexenum) =>
                                            renderRepeatedEnumChip(
                                              repeatedEnum,
                                              indexenum,
                                              attribute,
                                              itemIdx,
                                              handleChipClickService,
                                              select_svg,
                                              item?.valueType,
                                              "delete_suggest",
                                              item?.update_type,
                                              item
                                            )
                                        )}
                                      {/* </div> */}
                                    </>
                                  );
                                }
                                return null; // Return null if none of the conditions match
                              })
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                -- No New Attributes --
                              </div>
                            )}
                          </div>
                        );
                      } else if (updateAttributes === "delete_attributes") {
                        return (
                          <div key={idx} className="enumOption">
                            <p>Delete Attributes</p>
                            {selectedItems?.[attribute]?.[updateAttributes]
                              ?.length > 0 ? (
                              selectedItems?.[attribute]?.[
                                updateAttributes
                              ]?.map((item, itemIdx) => {
                                const isChecked = itemSelectedItems.has(item);

                                if (item?.valueType === "BOOL") {
                                  return (
                                    <>
                                      {/* <div key={itemIdx} className="enumOption"> */}
                                      <p>Actual Attributes</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        {/* <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            item,
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                      </div>
                                      {renderChipDelete(
                                        item,
                                        itemIdx,
                                        attribute,
                                        handleChipClickService,
                                        select_svg,
                                        "delete_actual"
                                      )}
                                      {/* </div> */}
                                      <p>Suggested By Google to remove</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        <input
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              item,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </div>
                                      {renderChipDelete(
                                        item,
                                        itemIdx,
                                        attribute,
                                        handleChipClickService,
                                        select_svg,
                                        "delete_suggest"
                                      )}
                                      {/* </div> */}
                                    </>
                                  );
                                } else if (
                                  item.valueType === "ENUM" ||
                                  item.valueType === "REPEATED_ENUM"
                                ) {
                                  return (
                                    <>
                                      {/* <div key={itemIdx} className="enumOption"> */}
                                      <p>Actual Attributes</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        {/* <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            item,
                                            e.target.checked
                                          )
                                        }
                                      /> */}
                                      </div>

                                      <p>{item?.displayName}</p>
                                      {Array.isArray(item?.valueMetadata) &&
                                        item.valueMetadata.map(
                                          (repeatedEnum, indexenum) =>
                                            renderRepeatedEnumChip(
                                              repeatedEnum,
                                              indexenum,
                                              attribute,
                                              itemIdx,
                                              handleChipClickService,
                                              select_svg,
                                              item?.valueType,
                                              "delete_actual",
                                              item?.update_type,
                                              item
                                            )
                                        )}
                                      <p>Suggested By Google to remove</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        <input
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              item,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </div>
                                      <p>{item?.displayName}</p>
                                      {Array.isArray(item?.valueMetadata) &&
                                        item.valueMetadata.map(
                                          (repeatedEnum, indexenum) =>
                                            renderRepeatedEnumChip(
                                              repeatedEnum,
                                              indexenum,
                                              attribute,
                                              itemIdx,
                                              handleChipClickService,
                                              select_svg,
                                              item?.valueType,
                                              "delete_suggest",
                                              item?.update_type,
                                              item
                                            )
                                        )}
                                      {/* </div> */}
                                    </>
                                  );
                                }
                                return null; // Ensure a return for each branch
                              })
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                -- No New Attributes --
                              </div>
                            )}
                          </div>
                        );
                      }

                      return null; // Ensure a return for the `map`
                    }
                  );
                }
                return null; // Ensure a return for the `map`
              })}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                // paddingBottom: "10px",
                // paddingTop: "10px",
                paddingBlock: "20px",
              }}
            >
              <FPButton
                label="Update"
                size="small"
                onClick={handleUPdateAttributes}
                disabled={primaryDetail?.is_suspend}
              />
            </div>
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  // updateDiffDataData: state?.gmbListingLocation?.updateDiffDataData,
  attribute_data: state.gmbListingLocation.attribute_data,
  attribute_error: state.gmbListingLocation.attribute_error,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
