import { FormControl, FormControlLabel, RadioGroup } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FPRadio from "../FPRadio";
import { snakeToCapital } from "../../../../libs/utils";

const FPRadioGroup = ({ options: dataOption, name, defaultValue = "", onChange, disabled = false }) => {
  const [value, setValue] = useState(defaultValue);
  const [options, setOptions] = useState(dataOption);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setOptions(dataOption);
  }, [dataOption]);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange?.(event);
  };

  return (
    <FormControl component="fieldset" disabled={disabled}>
      <RadioGroup name={name} value={value} onChange={handleChange}>
        <section className="vertical-align flex-wrap gap-20 gap-y-10 filter-item-list-wrapper d-flex ml-10">
          {options.map((item) => (
            <FormControlLabel
              key={`${item.display_name}_${item.value}`}
              value={item.value}
              control={<FPRadio disabled={disabled} />}
              label={snakeToCapital(item.display_name)}
            />
          ))}
        </section>
      </RadioGroup>
    </FormControl>
  );
};

export default FPRadioGroup;
