import React, { useState, Fragment, useEffect } from "react";
import OfferDiscountFilter from "../../components/restro-pilot/OfferDiscountFilter";
import { Nav, NavItem, NavLink } from "reactstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import classnames from "classnames";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoPrintOutline } from "react-icons/io5";
import { FPToolTip } from "../../components/common/new";
import Radio from "@material-ui/core/Radio";
import OfferChart from "../../components/restro-pilot/OfferChart";
import OfferTable from "../../components/restro-pilot/OfferTable";
import OfferLocationChart from "../../components/restro-pilot/OfferLocationChart";
import OfferLocationTable from "../../components/restro-pilot/OfferLocationTable";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import { socialKeys, providerColor } from "../../images";
import LoaderComponent from "../../components/common/loaderComponent";

const offerData = [
  { id: "gross_sales_from_offers", title: "Gross Sales from Discounts/Offers" },
  { id: "discount_given", title: "Discounts Given" },
  { id: "orders_with_offers", title: "Orders Count With Discount/Offers" },
  // { id: "effective_discount", title: "Effective Discount/Offers" },
  { id: "discount_per_order", title: "Discount Per Order" },
  { id: "discount_percent", title: "Discount % of Revenue per Order" },
];


const OfferDiscountPage = (props) => {

  const search = props.location.search;
  const { match: { params: { business_id } } } = props;


  const { history, location, match } = props;
  const [activeSection, setActiveSection] = useState("overtime");
  const [view, setView] = useState("Graph");
  const [selectedCard, setSelectedCard] = useState(offerData[0].id);
  const [mainData, setMainData] = useState();
  const [overtimeLabels, setOvertimeLabels] = useState([]);
  const [locationLabels, setLocationLabels] = useState([]);
  const [overtimeData, setOvertimeData] = useState([]);
  const [locationsData, setLocationsData] = useState([]);
  const [overtimeDataTable, setOvertimeDataTable] = useState([]);
  const [locationsDataTable, setLocationsDataTable] = useState([]);
  const [overtimeProviderList, setOvertimeProviderList] = useState([]);
  const [lastSync, setLastSync] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Discounts/Offers";
    props.actions.setSelectedTab(3);
  }, []); //eslint-disable-line

  const handleApi = (res) => {
    setLastSync(res.data.last_synced);
    const overtime_label = [];
    res.data.overtime.all && Object.keys(res.data.overtime.all).map((val_label) => overtime_label.push(val_label));
    const locations_label = [];
    res.data.locations.all && Object.keys(res.data.locations.all).map((val_label) => locations_label.push(val_label));

    const provider_list = [];
    Object.keys(res.data.overtime).map((val_key) => provider_list.push(val_key));

    const over_data = [];

    Object.keys(res.data.overtime).map((val_key) => {
      const overtime_proider = []; 
      const provider_data = res.data.overtime[val_key];
      overtime_label.map((val) => overtime_proider.push(provider_data[val]));
      const color = providerColor[val_key] ? providerColor[val_key] : "gray";
      over_data.push({ label: socialKeys[val_key] || val_key , data: overtime_proider, borderColor: color, backgroundColor: color, tension: 0,  })
      return val_key;
    }); 

    const location_data = [];   

    Object.keys(res.data.locations).map((val_key) => {
      const location_proider = []; 
      const location = res.data.locations[val_key];
      locations_label.map((val) => location_proider.push(location[val]));
      const color = providerColor[val_key] ? providerColor[val_key] : "gray";
      location_data.push({ label: socialKeys[val_key] || val_key, data: location_proider, borderColor: color, backgroundColor: color, tension: 0,  })
      return val_key;
    })

    const overtime_table = [];
    overtime_label.map((val_label, index) => {
      const obj = {
        id: index,
        date: val_label
      }
      Object.keys(res.data.overtime).map((val) =>  obj[val] = res.data.overtime[val][val_label]);

      overtime_table.push(obj);
      return overtime_table;
    });

    const locations_table = [];
    locations_label.map((val_label, index) => {
      const obj = {
        id: index,
        location: val_label
      }
      Object.keys(res.data.locations).map((val) =>  obj[val] = res.data.locations[val][val_label]);

      locations_table.push(obj);
      return locations_table;
    });

    setMainData(res.data.totals);
    setOvertimeLabels(overtime_label);
    setLocationLabels(locations_label);
    setOvertimeData(over_data);
    setLocationsData(location_data);
    setOvertimeDataTable(overtime_table);
    setLocationsDataTable(locations_table);
    setOvertimeProviderList(provider_list);
  }

  useEffect(() => {
    setLoading(true);
    axios_instance.get(`${utils.format(URLS.OFFER_DISCOUNTS_PAGE, [business_id])}${search ? search+'&' : '?'}graph_option=${'gross_sales_from_offers'}`)
      .then((res) => {
        handleApi(res);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [search]); //eslint-disable-line

  const selectedCardTitle =
    offerData.find((item) => item.id === selectedCard)?.title || "Gross Sales from Discounts/Offers";

  const handleCardClick = (id) => {
    setSelectedCard(id);
    setLoading(true);
    axios_instance.get(`${utils.format(URLS.OFFER_DISCOUNTS_PAGE, [business_id])}${search ? search+'&' : '?'}graph_option=${id}`)
      .then((res) => {
        handleApi(res);
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  const handleDownlod = () => {
    const fileUrl = `${utils.format(URLS.OFFER_DISCOUNTS_PAGE, [business_id])}${search ? search+'&' : '?'}graph_option=${selectedCard}&is_download=${true}&is_overtime_download=${activeSection === 'overtime'}&is_location_download=${activeSection === 'location'}`
    return props.reviewsActions.downloadTemplate(fileUrl, "famepilot_discount_offer.csv");
  }

  return (
    <div className="mb-4">
      <LoaderComponent loader={loading}/> 
      <div className="mt-4" style={{ display: "flex", fontSize: "12px" }}>
        Last Synced: {lastSync}
      </div>
      <div className="mt-3">
        <OfferDiscountFilter history={history} location={location} match={match} />
      </div>
      <div
        className="business-summary-grid"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(220px, 1fr))",
          gap: "24px",
          marginBottom: "24px",
          padding: "10px 0",
        }}
      >
        {offerData.map((item, index) => (
          <div
            key={item.id}
            className={classnames("price-special-card shadow-sm", {
              "selected-card": selectedCard === item.id,
            })}
            onClick={() => handleCardClick(item.id)}
            style={{ backgroundColor: selectedCard === item.id ? "#FDF2F7" : "white", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <span style={{ fontSize: "16px", color: "#1B1C1D" }}>{item.title}</span>
            { mainData && mainData[item.id] &&<div className="price-card">
              <div className="d-flex flex-column" style={{ gap: "10px", overflowY: 'auto', maxHeight: '180px', padding: '2px 9px' }}>
                { mainData[item.id].map((val_item) => {
                  return <div className="d-flex align-items-center justify-content-between">
                    {val_item.provider_name === 'All' ?<span style={{ fontWeight: "400", fontSize: "14px", color: "#7A8193" }}>All</span>
                      : <img src={val_item.provider_logo} alt={val_item.provider_name} style={{ width: '25px' }} />}
                    <span style={{ fontWeight: "500", fontSize: "16px", color: "#1B1C1D" }}> 
                      { index!== 2 && index!== 3 && index!== 4 && "₹"}  { val_item?.total ? Intl.NumberFormat('en-IN').format( val_item.total) : 0} { index!== 2 && index!== 0 && index!== 1 && index!== 2 && index!== 3 && "%"}
                    </span>
                  </div>
                })}
              </div>
            </div>}
          </div>
        ))}
      </div>
      <CardWrapper showDivider={false} headerTitle={selectedCardTitle}>
        <div className="report-ThirdRow mb-4">
          <Nav tabs className="py-1 box-shadow-none" style={{ borderBottom: "none" }}>
            <Fragment>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeSection === "overtime" })}
                  onClick={() => setActiveSection("overtime")}
                >
                  Overtime
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeSection === "location" })}
                  onClick={() => setActiveSection("location")}
                >
                  Location
                </NavLink>
              </NavItem>
            </Fragment>
          </Nav>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "gray" }}>
            <FormControlLabel
              value="Graph"
              label="Graph"
              control={<Radio />}
              onClick={() => setView("Graph")}
              checked={view === "Graph"}
            />
            <FormControlLabel
              value="Table"
              label="Table"
              control={<Radio />}
              onClick={() => setView("Table")}
              checked={view === "Table"}
            />
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <FPToolTip title={"Download"} placement={"top"}>
              <MdOutlineFileDownload style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => handleDownlod()} />
            </FPToolTip>
            <FPToolTip title={"Print"} placement={"top"}>
              <IoPrintOutline style={{ fontSize: "18px", cursor: "pointer" }} onClick={() =>  window.print()} />
            </FPToolTip>
          </div>
        </div>

        {activeSection === "overtime" && view === "Graph" && <OfferChart labels={overtimeLabels || []} overtimeData={overtimeData || []}  />}
        {activeSection === "overtime" && view === "Table" && <OfferTable dataList={overtimeDataTable || []} provider_list={overtimeProviderList || []} />}
        {activeSection === "location" && view === "Graph" && <OfferLocationChart labels={locationLabels || []} locationsData={locationsData || []} />}
        {activeSection === "location" && view === "Table" && <OfferLocationTable offerList={locationsDataTable || []} provider_list={overtimeProviderList || []} />}
      </CardWrapper>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(setSelectedTab, dispatch),
    reviewsActions: bindActionCreators(ReviewsActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
  };
};

OfferDiscountPage.propTypes = {
  loader: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferDiscountPage);

