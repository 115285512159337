/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import queryString from 'query-string';
import * as CampaignActions from '../../actions/campaign/campaignActions';
import * as commonAction from '../../actions/common/commonAction';
import * as utils from '../../libs/utils';
import LoaderComponent from '../../components/common/loaderComponent';
import ExperienceForm from '../../components/campaign/ExperienceForm';
import ContactDetailPage from './ContactDetailPage';
import axios_instance from '../../libs/interseptor';
import * as URLS from '../../libs/apiUrls';
import NewSurveyPage from '../../components/campaign/NewSurveyPage';
import SurveyThanksPage from '../../components/campaign/SurveyThanksPage';
import SurveyMoreReview from '../../components/campaign/SurveyMoreReview';
import SurveyDuplicate from '../../components/campaign/SurveyDuplicate';

const DefaultDesignConfiguration = {
  font            : 'palatino, serif',
  bg_color        : '#FFFFFF',
  vertical        : false,
  que_bg_color    : '#FFFFFF',
  que_text_color  : '#000000'
};

class FeedbackPage extends React.Component {
  constructor(props) {
    super(props);
    this.state={hideExperiencePage:false, isError: false,branch_country:"", ucError: false,background_url:null, businessId:null, state:[], city:[], branch: [], getSurveyResponse:false, surveySubmitError:'', surveyPageQuestion:[], advance_template_idd:'', surveyQuestionShow:false, reviewId:'', thankUPage:false, isReviewNegative:false, positiveSurvey:false, negativeSurvey:false, moreReviewOn:false, submittedReview:'', review_channels:'', thanksLine:null, offer_data:null, isDuplicate:false, duplicateReviewer:null, landingPageHeading:null, designConfig:null, thanksPageImage:null, show_city:true, show_state:true, enable_consent:false, show_store_options_in_uppercase:false, consent_text:null};
    this.sendFeedback = this.sendFeedback.bind(this);
    this.submit = this.submit.bind(this);
    this.getSurveyResponse = this.getSurveyResponse.bind(this);
    this.surveyQuestionSubmit = this.surveyQuestionSubmit.bind(this);
    this.surveyShowOrNotSubmit = this.surveyShowOrNotSubmit.bind(this);
    this.finalSubmitSurveyQuestin = this.finalSubmitSurveyQuestin.bind(this);
  }

  getSurveyResponse(data){
    const req_que = [];
    const not_answered = [];
    this.state.survey_pages[this.state.survey_current_page]['allQuestions'].map((que, que_index) => {
      if(que.question_required){
        req_que.push(que_index);
      }
    });
    req_que.map(qu_index => {
      const result = data.SurveyQuestions.findIndex(question => question.index == qu_index);
      if (result < 0) {
        not_answered.push(qu_index+1);
      }
    });
    if (not_answered.length > 0){
      this.setState({ surveySubmitError :'Question No:- '+not_answered.join()+' need to be answered.'});
    }else{
      let d = this.state.surveyPageQuestion;
      d.push(data);
      this.setState({ survey_current_page: this.state.survey_current_page + 1, surveyPageQuestion: d, surveySubmitError :''});
    }
  }

  finalSubmitSurveyQuestin(new_data){
    var data = new_data.data;
    var attached_files = new_data.attached_files;
    const req_que = [];
    const not_answered = [];
    this.state.survey_pages[this.state.survey_current_page]['allQuestions'].map((que, que_index) => {
      if(que.question_required){
        req_que.push(que_index);
      }
    });
    req_que.map(qu_index => {
      const result = data.SurveyQuestions.findIndex(question => question.index == qu_index);
      if (result < 0) {
        not_answered.push(qu_index+1);
      }
    });
    if (not_answered.length > 0){
      this.setState({ surveySubmitError :'Question No:- '+not_answered.join()+' need to be answered.'});
    }else{
      this.setState({ survey_current_page: this.state.survey_current_page + 1, surveySubmitError: '' });
      if (this.state.reviewId && this.state.reviewId != ''){
        let survey_response_d = [...this.state.surveyPageQuestion, data];
        const formData = new FormData();
        formData.append('survey_response',JSON.stringify(survey_response_d));
        formData.append('review_id',this.state.reviewId);
        formData.append('template_id',this.state.advance_template_idd);
        
        if (attached_files){
          attached_files.map((file_it)=>{
            formData.append('attached_file',file_it, file_it.name);
          });
        }
        axios_instance.post(`${URLS.SURVEY_QUESTION_SUBMIT}`, formData)
          .then(res=>{
            if(res.status === 200){
              this.setState({ thankUPage: true });
            }
          })
          .catch(err=>console.log(err));
      }else{
        this.sendFeedback({description:null,rating:0})
          .then((res)=>{
            this.setState({reviewId:res.data.id,surveyQuestionShow:true, submittedReview:data.description});
            let survey_response_d = [...this.state.surveyPageQuestion, data];
            const formData = new FormData();
            formData.append('survey_response',JSON.stringify(survey_response_d));
            formData.append('review_id',this.state.reviewId);
            formData.append('template_id',this.state.advance_template_idd);
            
            if (attached_files){
              attached_files.map((file_it)=>{
                formData.append('attached_file',file_it, file_it.name);
              });
            }

            axios_instance.post(`${URLS.SURVEY_QUESTION_SUBMIT}`, formData)
              .then(res=>{
                if(res.status === 200){
                  this.setState({ thankUPage: true });
                }
              })
              .catch(err=>console.log(err));
          });
      }
    }
  }

  sendFeedback(data) {
    const { match: { params: { id, template_id }}, location : { search } } = this.props;
    const queryParams = queryString.parse(search);
    if(template_id)  return this.props.actions.sendFeedback(template_id,data, queryParams);
    else return this.props.actions.sendFeedback(id, data);
  }

  componentDidMount() {
    const {match: {params: { id, template_id}}, location: {search} } = this.props;
    const queryParams = search && queryString.parse(search);
    let bId;
    if(template_id){
      this.props.actions.getGenericTemplateFeedback(template_id, queryParams)
        .then((res) => {
          if (res.data && res.data.duplicate){
            this.setState({isDuplicate:true, duplicateReviewer:res.data.reviewer_name});
          }
          else if((!queryParams.uc || queryParams.uc === '{}' || queryParams.uc === '%7B%7D') && (!queryParams.contact_number || queryParams.contact_number === '' || queryParams.contact_number === '{}')){
            this.setState({ ucError: true, isError: true });
            if (res.data && res.data.business && res.data.business.background_image ){
              this.setState({background_url:res.data.business.background_image});
            }else{
              this.setState({background_url:'https://images.typeform.com/images/88dwhLUaSKWH/background/large'});
            }
            return ;
          }else{
            if(res.data.review_gating === true){
              this.setState({moreReviewOn:true});
            }
            if(res.data.show_city === false){
              this.setState({show_city:false});
            }
            if(res.data.show_state === false){
              this.setState({show_state:false});
            }
            if(res.data.enable_consent === true){
              this.setState({enable_consent:true});
            }
            if(res.data.show_store_options_in_uppercase === true){
              this.setState({show_store_options_in_uppercase:true});
            }
            if(res.data.enable_consent === true && res.data.consent_text !== null && res.data.consent_text !== ''){
              this.setState({consent_text:res.data.enable_consent});
            }
            if(res.data.advance_template.survey_section === true){
              this.setState({positiveSurvey:true});
            }
            if(res.data.advance_template.survey_section_negative){
              this.setState({negativeSurvey:true});
            }
            if (res.data.advance_template.survey_section === true || res.data.advance_template.survey_section_negative === true){
              this.setState({advance_template_idd: res.data.advance_template.id, survey_current_page: 0, survey_pages: res.data.survey_pages, survey_page_length:res.data.survey_pages.length});
            }
            if(res.data.review_gating === true){
              this.setState({review_channels:res.data.advance_template.review_channels});
            }
            if (res.data.include_comment === false && (!res.data.symbol_type || res.data.symbol_type === '' || res.data.symbol_type === null)){
              this.setState({hideExperiencePage:true, surveyQuestionShow:true, submittedReview:''});
            }
            this.setState({isError: false, ucError: false, });
          }
        })
        .catch((err) => {
          if(!queryParams.uc || queryParams.uc === '{}' || queryParams.uc === '%7B%7D'){
            this.setState({ ucError: true, isError: true });
          }
          else if(err.error==='Unique code is required.'){
            this.setState({ ucError: true, isError: true });
          }
          else{
            this.setState({isError: true});
            utils.displayMessage('negative', err.error);
          }
        });
    }
    else if(id) {
      this.setState({isError: false});
      this.props.actions.getFeedbackInfo(id);
    }
    axios_instance.get(`${URLS.REVIEWS_DETAILS}${template_id}/feedback/`)
      .then((res)=>
      {
        bId = res.data.business.id;
        axios_instance.get(utils.format(URLS.GENERIC_TEMPLATE_DETAILS,[res.data.business.id,template_id]))
          .then((res)=>
          {
            this.setState({positiveSurvey:res.data.survey_section === true ? true : false, negativeSurvey:res.data.survey_section_negative === true ? true : false, branch_country:res.data.branch_country, include_offer:res.data.include_offer,include_location: res.data.include_location,include_email: res.data.include_email,include_dob: res.data.include_dob,include_doa: res.data.include_doa,include_gender: res.data.include_gender,include_comment: res.data.include_comment, thanksLine:res.data.thanks_line !== 'null' ? res.data.thanks_line : '', landingPageHeading:res.data.landing_page_heading !== 'null' ? res.data.landing_page_heading : '', designConfig:res.data.design_config, thanksPageImage:res.data.thanks_page_image ? res.data.thanks_page_image : null, show_city:res.data.show_city, show_state:res.data.show_state, enable_consent:res.data.enable_consent, show_store_options_in_uppercase:res.data.show_store_options_in_uppercase, consent_text:res.data.consent_text !== 'null' ? res.data.consent_text : null});
            res.data.background_image?this.setState({background_url:res.data.background_image}):this.setState({background_url:'https://images.typeform.com/images/88dwhLUaSKWH/background/large'});
            res.data.survey_pages?this.setState({survey_pages: [...res.data.survey_pages]}):[];
            this.setState({survey_page_length:res.data.survey_pages.length});
            if (res.data.include_comment === false && (!res.data.symbol_type || res.data.symbol_type === '' || res.data.symbol_type === null)){
              this.setState({hideExperiencePage:true, surveyQuestionShow:true, submittedReview:''});
            }
            axios_instance.get(`${URLS.BUSINESS_SETUP}${bId}/address/state_fetch`)
              .then(res => this.setState({state: res.data.choices}))
              .catch(err => console.log(err));
            axios_instance.post(`${URLS.BUSINESS_SETUP}${bId}/address/city_fetch/`,{})
              .then(res=>this.setState({city: res.data.choices}))
              .catch(err=>console.log(err));
            axios_instance.post(`${URLS.BUSINESS_SETUP}${bId}/address/branch_fetch/`,{})
              .then(res=>this.setState({branch: res.data.choices}))
              .catch(err=>console.log(err));
          });
      })
      .catch((err)=>console.log(err, 'ee'));
  }

  submit(contact_number,name, stateName, cityName, branchId, email, selectedDob, selectedDoa, gender) {
    const {history, match: { params: {template_id} }, location: {pathname, search} } = this.props;
    let queryParams = search && queryString.parse(search);
    delete queryParams.uc;
    const newQueryParams = {...queryParams, 'contact_number':contact_number, 'name':name, 'state': stateName, 'city': cityName, 'branch': branchId, 'email':email, 'gender':gender, 'dob':selectedDob, 'anniversary':selectedDoa};
    history.push({
      pathname,
      search: queryString.stringify(newQueryParams)
    });
    this.props.actions.getGenericTemplateFeedback(template_id, newQueryParams)
      .then((res) => {
        if ((res.data.advance_template.include_comment === false || !res.data.advance_template.include_comment) && (!res.data.advance_template.symbol_type || res.data.advance_template.symbol_type === '' || res.data.advance_template.symbol_type === null)){
          this.setState({surveyQuestionShow:true, hideExperiencePage:true, submittedReview:''});
        }
        if (res.data.offer_detail){
          this.setState({offer_data:res.data.offer_detail});
        }
        if(res.data.review_gating === true){
          this.setState({moreReviewOn:true});
        }
        if(res.data.advance_template.survey_section === true){
          this.setState({positiveSurvey:true});
        }
        if(res.data.advance_template.survey_section_negative){
          this.setState({negativeSurvey:true});
        }
        if (res.data.advance_template.survey_section === true || res.data.advance_template.survey_section_negative === true){
          this.setState({advance_template_idd: res.data.advance_template.id, survey_current_page: 0, survey_pages: res.data.survey_pages, survey_page_length:res.data.survey_pages.length});
        }
        if(res.data.review_gating === true){
          this.setState({review_channels:res.data.advance_template.review_channels});
        }
        this.setState({isError: false, ucError: false, });
      })
      .catch((err) => {
        this.setState({isError: true});
        if(err.error==='Unique code is required.'){
          this.setState({ ucError: true });
        }
        else{
          utils.displayMessage('negative', err.error);
        }
      });
  }

  getCityBasedOnStates = (stateName, bId) => {
    const data = {
      'state' : stateName
    };
    axios_instance.post(`${URLS.BUSINESS_SETUP}${bId}/address/city_fetch/`,data)
      .then(res=>this.setState({city: res.data.choices}))
      .catch(err=>console.log(err));
  }

  getBranchBasedOnStatesAndCity = (stateName, cityName, bId) => {
    const data = {
      'state' : stateName,
      'city' : cityName
    };
    axios_instance.post(`${URLS.BUSINESS_SETUP}${bId}/address/branch_fetch/`,data)
      .then(res=>this.setState({branch: res.data.choices}))
      .catch(err=>console.log(err));
  }

  surveyQuestionSubmit = (success,rvid, data) =>{
    if(success){
      this.setState({ reviewId :rvid,surveyQuestionShow:true, submittedReview:data.description});
      if (data.review_type === 'thumb' && !data.is_thum_up){
        this.setState({ isReviewNegative :true});
      }
      if (data.review_type === 'star' && data.rating < 4 ){
        this.setState({ isReviewNegative :true});
      }
      if (data.review_type === 'smiley' && data.smiley_value < 2){
        this.setState({ isReviewNegative :true});
      }
      if (data.review_type === 'nps_score' && data.nps_score < 7){
        this.setState({ isReviewNegative :true});
      }
    }
  }

  surveyShowOrNotSubmit = (data) =>{
    if(data === false){
      this.setState({ surveyQuestionShow :true});
    }
  }

  render() {
    const { campaign: { campaignDetail : {campaign, advance_template, is_feedback_given, review_gating, is_comment_added, review_id, member, links, business}, feedback: {id}}, location } = this.props;
    const {match: { params: {template_id} }} = this.props;
    const {branch_country, include_location, include_email, include_dob, include_doa, include_gender, include_comment, include_offer, offer_data, duplicateReviewer, hideExperiencePage, designConfig } = this.state;
    return (
      <Row className="align-items-center justify-content-center login-bg template-bg full-height" style={{height: 'fit-content',backgroundImage:`url(${this.state.background_url})`}} >
        <LoaderComponent loader={this.props.loader} />
        { this.state.isDuplicate ? 
          <SurveyDuplicate 
            business={business}
            duplicateReviewer={duplicateReviewer && duplicateReviewer}
          />
          : this.state.ucError ? 
            <ContactDetailPage 
              submit={this.submit} 
              business={business} 
              id={template_id} 
              include_location={include_location}
              include_email={include_email}
              include_dob={include_dob}
              include_doa={include_doa}
              include_gender={include_gender}
              state = {this.state.state}
              city = {this.state.city}
              branch = {this.state.branch}
              branch_country = {branch_country}
              getCity = {this.getCityBasedOnStates}
              getBranch = {this.getBranchBasedOnStatesAndCity}
              landing_page_heading={this.state.landingPageHeading}
              show_city={this.state.show_city}
              show_state={this.state.show_state}
              enable_consent={this.state.enable_consent}
              consent_text={this.state.consent_text}
              show_store_options_in_uppercase={this.state.show_store_options_in_uppercase}
            /> 
            : !this.state.isError && this.state.surveyQuestionShow === false && !hideExperiencePage
              ? <ExperienceForm
                ratingType={campaign ? (campaign.template && campaign.template.symbol_type) : (advance_template || {}).symbol_type}
                sendFeedback={this.sendFeedback}
                review_gating={review_gating}
                initialValue={member || {}}
                links={links}
                template={campaign ? campaign.template : (advance_template || {})}
                body={campaign ? campaign.template.body : (advance_template || {}).message}
                history={this.props.history}
                location={location}
                id={id}
                businessDetails={campaign ? campaign.business : (advance_template || {}).business}
                is_feedback_given={is_feedback_given}
                is_comment_added={is_comment_added}
                review_id={review_id}
                surveyQuestionSubmit={this.surveyQuestionSubmit}
                surveyShowOrNot={this.state.survey_page_length}
                surveyShowOrNotSubmit={this.surveyShowOrNotSubmit}
                surveyQuestionShow={this.state.surveyQuestionShow}
                business={campaign ? campaign.business : (advance_template || {}).business}
                include_comment={include_comment}
                designConfig={(designConfig && designConfig !== null) ? designConfig : DefaultDesignConfiguration}
              />
              : this.state.positiveSurvey && this.state.survey_page_length > 0 && this.state.survey_current_page < this.state.survey_page_length && this.state.surveyQuestionShow && !this.state.thankUPage
                ?<NewSurveyPage
                  survey_pages={this.state.survey_pages[this.state.survey_current_page]}
                  survey_current_page={this.state.survey_current_page}
                  getSurveyResponsefn={this.getSurveyResponse}
                  surveySubmitError={this.state.surveySubmitError}
                  totalSurveyPageCount={this.state.survey_page_length}
                  nextPageCount={this.state.survey_current_page+1}
                  finalSubmitSurveyQuestin={this.finalSubmitSurveyQuestin}
                  business={business}
                  designConfig={(designConfig && designConfig !== null) ? designConfig : DefaultDesignConfiguration}
                />
                : this.state.negativeSurvey && this.state.isReviewNegative && this.state.survey_page_length > 0 && this.state.survey_current_page < this.state.survey_page_length && this.state.surveyQuestionShow && !this.state.thankUPage
                  ?<NewSurveyPage
                    survey_pages={this.state.survey_pages[this.state.survey_current_page]}
                    survey_current_page={this.state.survey_current_page}
                    getSurveyResponsefn={this.getSurveyResponse}
                    surveySubmitError={this.state.surveySubmitError}
                    totalSurveyPageCount={this.state.survey_page_length}
                    nextPageCount={this.state.survey_current_page+1}
                    finalSubmitSurveyQuestin={this.finalSubmitSurveyQuestin}
                    business={business}
                    designConfig={(designConfig && designConfig !== null) ? designConfig : DefaultDesignConfiguration}
                  />
                  : this.state.moreReviewOn && !this.state.isReviewNegative
                    ?<SurveyMoreReview
                      business={business}
                      submittedReview={this.state.submittedReview}
                      review_channels={links}
                      review_gating={review_gating}
                      initialValue={member || {}}
                      include_comment={include_comment}
                      include_offer={include_offer}
                      offer_data={offer_data}
                      thanks_line={this.state.thanksLine}
                    />
                    :<SurveyThanksPage 
                      business={business}
                      initialValue={member || {}}
                      include_offer={include_offer}
                      offer_data={offer_data}
                      thanks_line={this.state.thanksLine}
                      thanksPageImage={this.state.thanksPageImage}
                    />
        }
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  campaign : state.campaign,
  loader:state.common.loader,
  cityOptions: state.common.city_options,
  stateOptions: state.common.state_options,
  branchList: state.business.branchList
});

FeedbackPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  campaign: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  cityOptions: PropTypes.instanceOf(Object),
  stateOptions: PropTypes.instanceOf(Object),
  branchList: PropTypes.instanceOf(Object)
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CampaignActions, dispatch),
  commonActions: bindActionCreators(commonAction, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(FeedbackPage);
