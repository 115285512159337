import { Box, FormControlLabel, Popover, Radio } from "@material-ui/core";
import React, { useEffect, useState, useRef, useContext } from "react";
import { FPToolTip } from "../../../components/common/new";
import { NewFilter } from "../../../images/svg";
import "./dayshours.css";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import LoaderComponent from "../../../components/common/loaderComponent";
import queryString from "query-string";
import printIcon from "../../../images/Printer.png";
import downloadIcon from "../../../images/downloadReport.png";
import InteractionTable from "../Performance/InteractionTable";
import DayBarChart from "./DayBarChart";
import HoursBarChart from "./HoursBarChart";
import DayHoursChart from "./DashHoursChart";
import PerformanceFilter from "../Performance/PerformanceFilter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as filterListActions from "../../../actions/common/filterListActions";
import * as URLS from "../../../libs/apiUrls";
import DotChart from "./DashHoursChart";
import * as setSelectedTab from "../../../actions/common/setSelectedTabAction";
import * as listingPerformance from "../../../actions/listing/listingPerformanceAction";
import CommonPerformanceFilter from "../CommonPerformanceFilter";
import { SideNavContext } from "../../../Context/sideNavContext";

function DaysHours(props) {
  const ref = useRef();
  const {
    actions,
    location: { search, pathname },
    loader,
    history,
    match,
    match: {
      params: { business_id },
    },
    location,
    filterAction,
    filter,
    listing_popular_times,
  } = props;

  const queryParams = new URLSearchParams(search);
  const [performanceNavTab, setPerformanceNavTab] = useState("days");
  const [view, setView] = useState("graph");
  const [showFilter, setShowFilter] = useState("false");
  const { isAccordionTabSelected } = useContext(SideNavContext);
  const fetchFilterList = async () => {
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch(
          "",
          `${URLS.BUSINESS_SETUP}${business_id}/branch/?is_gmb_locations=true`
        ),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };
  useEffect(() => {
    isAccordionTabSelected();
    fetchFilterList();
  }, []);

  useEffect(() => {
    document.title = "days-hours";
    props.actionsSelectTab.setSelectedTab(2);
    setShowFilter(false);
  }, []);

  const toggleNavTab = (tab) => {
    const existingSearch = queryString.parse(search);
    history.push({
      pathname,
      search: queryString.stringify({ ...existingSearch, type: tab }),
    });
    setPerformanceNavTab(tab);
  };

  const toggleView = (viewVal) => {
    setView(viewVal);
  };

  const onFilterBtnClick = (e) => {
    setShowFilter(e.currentTarget);
  };

  const closeFilterModel = () => {
    setShowFilter(!showFilter);
  };
  const payload = queryString.parse(search);

  useEffect(() => {
    actions.getListingPopularTimes(business_id, payload);
  }, [search]);

  const exportPopularTimesCsv = async () => {
    const updatedPayload = { ...payload, download: true };
    await actions.getListingPopularTimes(business_id, updatedPayload);
    await actions.getListingPopularTimes(business_id, payload);
  };
  const getFilterData = (data) => {
    actions.getListingPopularTimes(business_id, data);
  };

  const printPopularTImes = () => {
    window.print();
  };

  return (
    <>
      <LoaderComponent loader={loader} />
      <div className="mt-3">
        <>
          <div className="align-center justify-content-between mx-3">
            <Box
              className="mb-0"
              sx={{ color: "#1B1C1D !important", fontSize: "12px" }}
            >
              Last Updated on {listing_popular_times?.last_update_date}
            </Box>
          </div>
          <div>
            <CommonPerformanceFilter
              history={history}
              location={location}
              match={match}
              getFilterData={getFilterData}
            />
          </div>
          {/* <div className="align-center justify-content-between mx-3">
            <Box
              className="mb-0"
              sx={{ color: "#1B1C1D !important", fontSize: "12px" }}
            >
              Last Updated on {listing_popular_times?.last_update_date}
            </Box>
            <FPToolTip title="Filter">
              <div className="contact-filter-view" onClick={onFilterBtnClick}>
                <NewFilter
                  color="white"
                  style={{
                    height: "15px",
                    width: "15px",
                  }}
                />
              </div>
            </FPToolTip>
          </div> */}
          <div
            className="complaint-grid mt-3"
            style={{
              background: "rgb(220 220 220 / 3%)",
              padding: "5px 0px 10px 0px",
              marginBottom: "5px",
            }}
          >
            <div className="performance-box-grid-container">
              <div className="performance-complaint-grid">
                <div
                  className="performance-complaint-info-card"
                  // key={index}
                  style={{
                    backgroundColor: "#fff",
                  }}
                >
                  <div className="performance-complaint-card-text">
                    <p style={{ color: "#1B1C1D", fontSize: "17px" }}>
                      Least Busy
                    </p>
                  </div>
                  <div style={{ border: "1px solid #F0F1F3" }}></div>
                  <div className="d-flex flex-row justify-content-between ">
                    <div>
                      <p
                        className="complaint-card-text"
                        style={{
                          color: "#7A8193",
                          fontSize: "17px",
                          marginBlock: "0px",
                        }}
                      >
                        Day
                      </p>
                      <p style={{ color: "#1B1C1D", fontSize: "17px" }}>
                        {listing_popular_times?.least_busy?.day}
                      </p>
                    </div>
                    <div>
                      <p
                        className="complaint-card-text"
                        style={{
                          color: "#7A8193",
                          fontSize: "17px",
                          marginBlock: "0px",
                        }}
                      >
                        Hours{" "}
                      </p>
                      <p style={{ color: "#1B1C1D", fontSize: "17px" }}>
                        {listing_popular_times?.least_busy?.hour}
                      </p>
                    </div>
                    <div>
                      <p
                        className="complaint-card-text"
                        style={{
                          color: "#7A8193",
                          fontSize: "17px",
                          marginBlock: "0px",
                        }}
                      >
                        Day & Hours{" "}
                      </p>
                      <p style={{ color: "#1B1C1D", fontSize: "17px" }}>
                        {listing_popular_times?.least_busy?.day}{" "}
                        {listing_popular_times?.least_busy?.hour}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="performance-complaint-info-card"
                  // key={index}
                  style={{
                    backgroundColor: "#fff",
                  }}
                >
                  <div className="performance-complaint-card-text">
                    <p style={{ color: "#1B1C1D", fontSize: "17px" }}>
                      Busiest
                    </p>
                  </div>
                  <div style={{ border: "1px solid #F0F1F3" }}></div>
                  <div className="d-flex flex-row justify-content-between ">
                    <div>
                      <p
                        className="complaint-card-text"
                        style={{
                          color: "#7A8193",
                          fontSize: "17px",
                          marginBlock: "0px",
                        }}
                      >
                        Day
                      </p>
                      <p style={{ color: "#1B1C1D", fontSize: "17px" }}>
                        {listing_popular_times?.busiest?.day}
                      </p>
                    </div>
                    <div>
                      <p
                        className="complaint-card-text"
                        style={{
                          color: "#7A8193",
                          fontSize: "17px",
                          marginBlock: "0px",
                        }}
                      >
                        Hours{" "}
                      </p>
                      <p style={{ color: "#1B1C1D", fontSize: "17px" }}>
                        {listing_popular_times?.busiest?.hour}
                      </p>
                    </div>
                    <div>
                      <p
                        className="complaint-card-text"
                        style={{
                          color: "#7A8193",
                          fontSize: "17px",
                          marginBlock: "0px",
                        }}
                      >
                        Day & Hours{" "}
                      </p>
                      <p style={{ color: "#1B1C1D", fontSize: "17px" }}>
                        {listing_popular_times?.busiest?.day}{" "}
                        {listing_popular_times?.busiest?.hour}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CardWrapper
            showDivider={false}
            headerTitle={
              <Box
                sx={{
                  color: "#1B1C1D",
                  fontSize: "16px",
                  fontWeight: 500,
                  marginTop: "5px",
                  marginBottom: "10px",
                }}
              >
                Popular Timings
              </Box>
            }
          >
            <div className="performaceGraphNavTab">
              <Nav
                tabs
                className="py-1 box-shadow-none"
                style={{ borderBottom: "none" }}
              >
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: performanceNavTab === "days",
                    })}
                    onClick={() => toggleNavTab("days")}
                  >
                    Day
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: performanceNavTab === "hours",
                    })}
                    onClick={() => toggleNavTab("hours")}
                  >
                    Hours
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: performanceNavTab === "days-hours",
                    })}
                    onClick={() => toggleNavTab("days-hours")}
                  >
                    Day & Hours{" "}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <div className="performanceView">
              <div className="align-center justify-content-between">
                <Nav
                  tabs
                  className="box-shadow-none"
                  style={{ borderBottom: "none" }}
                >
                  <NavItem>
                    <NavLink
                      className={classnames({ active: view === "graph" })}
                      onClick={() => {
                        toggleView("graph");
                      }}
                    >
                      <FormControlLabel
                        value="Graph"
                        label="Graph"
                        control={<Radio />}
                        checked={view === "graph"}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: view === "table" })}
                      onClick={() => toggleView("table")}
                    >
                      <FormControlLabel
                        value="table"
                        control={<Radio />}
                        label="Table"
                        checked={view === "table"}
                      />
                    </NavLink>
                  </NavItem>
                </Nav>

                <div
                  className="d-flex justify-content-end gap-15"
                  style={{ height: "100%", borderRadius: "2px" }}
                >
                  <FPToolTip title={"Download"}>
                    <div
                      role="button"
                      onClick={exportPopularTimesCsv}
                      className="graph-Table-RightButton"
                    >
                      <img src={downloadIcon} alt="downloadIcon" />
                    </div>
                  </FPToolTip>
                  <FPToolTip title={"Print"}>
                    <div role="button">
                      <img
                        src={printIcon}
                        alt="printIcon"
                        onClick={printPopularTImes}
                      />
                    </div>
                  </FPToolTip>
                </div>
              </div>

              <TabContent activeTab={performanceNavTab}>
                {view === "graph" && (
                  <>
                    <TabPane tabId="days">
                      <CardWrapper className="p-3" showDivider={false}>
                        <DayBarChart
                          listing_popular_times={listing_popular_times}
                        />
                      </CardWrapper>
                    </TabPane>
                    <TabPane tabId="hours">
                      <CardWrapper className="p-3" showDivider={false}>
                        <HoursBarChart
                          listing_popular_times={listing_popular_times}
                        />
                      </CardWrapper>
                    </TabPane>
                    <TabPane tabId="days-hours">
                      <CardWrapper className="p-3" showDivider={false}>
                        {/* <DayHoursChart /> */}
                        <DotChart
                          ref={ref}
                          listing_popular_times={listing_popular_times}
                        />
                      </CardWrapper>
                    </TabPane>
                  </>
                )}
                {view === "table" && (
                  <>
                    <TabPane tabId="overtime">
                      <InteractionTable
                        // performanceNavTab={performanceNavTab}
                        headerTitle="Overtime"
                        performanceDate={true}
                        // data={listing_performance_data ? listing_performance_data : {}}
                        // selectedBox={selectedBox}
                      />
                    </TabPane>
                    <TabPane tabId="location">
                      <InteractionTable
                        // performanceNavTab={performanceNavTab}
                        performanceLocation={true}
                        headerTitle="Location"
                        // data={listing_performance_data ? listing_performance_data : {}}
                        // selectedBox={selectedBox}
                      />
                    </TabPane>
                  </>
                )}
              </TabContent>
            </div>
          </CardWrapper>
        </>
        {/* {showFilter && (
          <Popover
            open={openFilter}
            anchorEl={showFilter}
            onClose={closeFilterModel}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                borderRadius: 10,
                overflow: "visible",
              },
            }}
          >
            <PerformanceFilter
              location={props.location}
              onClose={closeFilterModel}
              history={props.history}
              locationOptions={filter?.branch?.choices}
              dateOptions={filter?.date_type?.choices}
            />
          </Popover>
        )} */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    filter: state.filter,
    listing_popular_times: state.listingPerformance.listing_popular_times,
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  filterAction: bindActionCreators(filterListActions, dispatch),
  actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
  actions: bindActionCreators(listingPerformance, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(DaysHours);
