import * as types from '../../actions/actionTypes';


const dashboardReducer = (state=[], action) => {
  switch (action.type){
    case types.CATEGORY_PERFORMANCE_SUCCESS:
      return Object.assign({}, state, {
        categoryPerformance: action.data,
        categoryPerformanceApiCalled: true
      });
    case types.CATEGORY_PERFORMANCE_FAILURE:
      return action.data;
    case types.TOP_BOTTOM_LOCATION_SUCCESS:
      return Object.assign({}, state, {
        topLocations: action.data.top_locations, 
        bottomLocations: action.data.least_locations,
        topBottomApiCalled: true
      });
    case types.TOP_BOTTOM_LOCATION_FAILURE:
      return action.data;
    case types.GET_OPEN_TICKET_SUCCESS:
      return Object.assign({}, state, { 
        open_tickets: { ...action.data },
        openTicketApiCalled : true
      });
    case types.GET_OPEN_TICKET_FAILURE:
      return Object.assign({}, state, { open_tickets: {} });
    case types.GET_WORD_CLOUD_SUCCESS:
      return Object.assign({}, state, { 
        word_cloud: { ...action.data },
        worldCloudApiCalled: true
      });
    case types.GET_WORD_CLOUD_FAILURE:
      return Object.assign({}, state, { word_cloud: { positive: {}, negative: {}, neutral: {} } });
    case types.CLEAR_DASHBOARD_API_SUCCESS:
      return Object.assign({}, state, { 
        open_tickets: {},
        openTicketApiCalled : false,
        topLocations: [], 
        bottomLocations: [],
        topBottomApiCalled: false,
        categoryPerformance: [],
        categoryPerformanceApiCalled: false,
        word_cloud: {},
        worldCloudApiCalled: false
      });
    default:
      return state;
  }
};

export default dashboardReducer;
