import { Card, Divider } from "semantic-ui-react";
import SalesFilter from "../../components/restro-pilot/SalesFilter";
import { Line } from "react-chartjs-2";
import { Nav, NavItem, NavLink } from "reactstrap";
import React, { useEffect, useState, Fragment } from "react";
import classnames from "classnames";
import { IoTriangle } from "react-icons/io5";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import LoaderComponent from "../../components/common/loaderComponent";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import { MdOutlineFileDownload } from "react-icons/md";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { FPToolTip } from "../../components/common/new";
import { IoPrintOutline } from "react-icons/io5";
import { FPTab } from "../../components/common/new/tabs";
import * as salesActions from "../../actions/restro-pilot/salesPageActions";
import queryString from "query-string";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";


const SalesPage = (props) => {
  const search = props.location.search;
  const { salesAction, salesOvertime, salesLocation, ordersOvertime, ordersLocation } = props;
  const { match: { params: { business_id } } } = props;
  const [activeSection, setActiveSection] = useState("salesTrend");
  const [sourceOptions, setSourceOptions] = useState([]);
  const [mainData, setMainData] = useState({});
  const [loader, setLoader] = useState(false);
  const [salesOvertimeGraph, setSalesOvertimeGraph] = useState([]);
  const [salesOvertimeTable, setSalesOvertimeTable] = useState([]);
  const [salesOvertimeLabels, setSalesOvertimeLabels] = useState([]);
  
  const [salesLocationGraph, setSalesLocationGraph] = useState([]);
  const [salesLocationTable, setSalesLocationTable] = useState([]);
  const [salesLocationLabels, setSalesLocationLabels] = useState([]);

  const [ordersOvertimeGraph, setOrdersOvertimeGraph] = useState([]);
  const [ordersOvertimeTable, setOrdersOvertimeTable] = useState([]);
  const [ordersOvertimeLabels, setOrdersOvertimeLabels] = useState([]);
  
  const [ordersLocationGraph, setOrdersLocationGraph] = useState([]);
  const [ordersLocationTable, setOrdersLocationTable] = useState([]);
  const [ordersLocationLabels, setOrdersLocationLabels] = useState([]);

  // const [downloading, setDownloading] = useState(false);
  const [section , setSection] = useState("overtime");
  const [view, setView] = useState("Graph");

  useEffect(() => {
    document.title = "Sales";
    props.actions.setSelectedTab(3);
  }, []); //eslint-disable-line

  useEffect(() => {
    const params = {
      ...queryString.parse(search),
      business_id: business_id
    }
    setLoader(true);
    Promise.all([
      salesAction.getSalesTrendOvertime(params),
      salesAction.getSalesTrendLocation(params),
      salesAction.getOrdersTrendOvertime(params),
      salesAction.getOrdersTrendLocation(params),
    ]).then((res) => {
      setLoader(false);
    }).catch((err) =>  setLoader(false));
  }, [search]); // eslint-disable-line

  const handleData = (data, section) => {
    let labels = [];
      let graph_data = [];
      const table_data = [];

      Object.keys(data?.all).map((key) => labels.push(key));
      Object.keys(data).map((trend) => {
        const val_trend = data[trend];
        const value_sales = [];
        labels.map((value) => value_sales.push(val_trend[value]));
        const color = detail_provider[trend]?.progress_color ? detail_provider[trend]?.progress_color : "blue";
        const dataSet = {
          label: trend,
          data: [...value_sales],
          borderColor: color,
          backgroundColor: color,
          fill: false,
          tension: 0,
          borderWidth: 2,
        };
        return graph_data.push(dataSet);
      });
      labels.map((label) => {
        const table_row_data = {};
        table_row_data[section] = label;
        Object.keys(data).map((key) =>  table_row_data[key]= ( data[key][label] && data[key][label]) || 0 );
        return table_data.push(table_row_data);
      });
      return {
        labels: labels,
        table_data: table_data,
        graph_data: graph_data
      }

  }

  useEffect(() => {
    if(salesOvertime && salesOvertime?.all) {
      const { labels, table_data, graph_data } = handleData(salesOvertime, "date");
      setSalesOvertimeLabels(labels);
      setSalesOvertimeTable(table_data);
      setSalesOvertimeGraph(graph_data);
    }
  }, [salesOvertime]); // eslint-disable-line

  useEffect(() => {
    if(salesLocation && salesLocation?.all) {
      const { labels, table_data, graph_data } = handleData(salesLocation, "location");
      setSalesLocationLabels(labels);
      setSalesLocationTable(table_data);
      setSalesLocationGraph(graph_data);
    }
  }, [salesLocation]); // eslint-disable-line

  useEffect(() => {
    if(ordersOvertime && ordersOvertime?.all) {
      const { labels, table_data, graph_data } = handleData(ordersOvertime, "date");
      setOrdersOvertimeLabels(labels);
      setOrdersOvertimeTable(table_data);
      setOrdersOvertimeGraph(graph_data);

    }
  }, [ordersOvertime]); // eslint-disable-line

  useEffect(() => {
    if(ordersLocation && ordersLocation?.all) {
      const { labels, table_data, graph_data } = handleData(ordersLocation, "location");
      setOrdersLocationLabels(labels);
      setOrdersLocationTable(table_data);
      setOrdersLocationGraph(graph_data);
    }
  }, [ordersLocation]); // eslint-disable-line

  const printLineChart = (() =>  window.print());

  useEffect(() => {
    setLoader(true);
    axios_instance.get(`${utils.format(URLS.SALES_PAGE, [business_id])}${search}`)
      .then((res) => {
        setMainData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, [search]); // eslint-disable-line

  const css_provider = {
    title: {
      fontSize: "1.2vmax", 
      marginBottom: "4px"
    },
    twoCols: {
      display: "grid",
      gridTemplateColumns: "auto 30%",
      gap: "1%",
      alignItems: "center",
    },
    innerTwoCols: {
      display: "flex",
      gap: "1.8%",
      alignItems: "baseline",
      width: "100%"
    },
    figure: {
      fontSize: "clamp(12px, 1.0vmax, 24px)"
    },
    pctFigure: {
      fontSize: "0.8vmax",
      display: "flex",
      alignItems: "center",
      gap: "4px"
    },
    pastFigure: {
      color: "gray",
      fontSize: "0.8vmax",
      textAlign: "right",
    },
    progressBar: {
      height: "1.3vmax"
    },
    channelWiseColumns: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "28% 35% auto",
      gap: "1%",
      alignItems: "center",
    },
    textAlignRight: {
      textAlign: "right"
    },
    textAlignLeft: {
      textAlign: "left"
    },
    textAlignCenter: {
      textAlign: "center"
    }
  }

  const detail_provider = {
    all: {
      name: "All",
      progress_background: "#6ccdca",
      progress_color: "#6ccdca",
      style: { fontSize: "16px", fontWeight: "500" },
    },
    swiggy: {
      name: "swiggy",
      progress_background: "rgb(240 134 29 / 17%)",
      progress_color: "#F0861D",
      style: {
        fontFamily: "Arial, sans-serif",
        letterSpacing: "1px",
        fontSize: "1.2vmax",
        fontWeight: 900,
        color: "#F0861D",
        textShadow: "2px 2px 5px rgba(0, 0, 0, 0.15)",
      },
    },
    zomato: {
      name: "zomato",
      progress_background: "rgb(255 0 79 / 12%)",
      progress_color: "#FF004F",
      style: {
        fontFamily: "Arial, sans-serif",
        fontSize: "1.2vmax",
        fontWeight: 1000,
        color: "#FF004F",
        fontStyle: "italic",
        textShadow: "2px 2px 5px rgba(0, 0, 0, 0.15)",
      },
    },
    gmail: {
      progress_background: "coral",
      progress_color: "yellow",
      style: {},
    },
    instagram: {
      progress_background: "coral",
      progress_color: "yellow",
      style: {},
    },
    fbmessenger: {
      progress_background: "lightblue",
      progress_color: "skyblue",
      style: {},
    },
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        type: "linear",
        position: "left",
        grid: {
          borderDash: [7],
        },
        title: {
          display: true,
          text: activeSection==="salesTrend" ? "Total Sales" : "Total Orders",
        },

        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          size: 12,
          fontSize: 12,
          fontStyle: "normal",
          fillStyle: "red",
          fontFamily: "Outfit",
          boxWidth: 20,
          boxHeight: 10,
          padding: 20,
          usePointStyle: true,
        },
      },
    },
    animation: {
      duration: 2000,
    },
    maintainAspectRatio: false,
  };

  useEffect(() => {
    axios_instance
      .get(`${URLS.REVIEW_SOURCE}?business_id=${business_id}`)
      .then((res) => {
        const test = [];
        res.data && res.data.choices.map((data) => test.push({ value: data.value, display_name: data.display_name }));
        setSourceOptions(test);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business_id]);

  const handleDownload = () => {
    let fileUrl = '';
    let fileName = '';
    if(activeSection=== 'salesTrend' && section ==='overtime') {
      fileUrl= `${utils.format(URLS.SALES_TREND_OVERTIME, [business_id])}${search ? search + '&' : '?'}is_download=true`;
      fileName= 'famepilot-sales-trend-overtime.csv';
    } else if(activeSection=== 'salesTrend' && section ==='location') {
      fileUrl= `${utils.format(URLS.SALES_TREND_LOCATION, [business_id])}${search ? search + '&' : '?'}is_download=true`;
      fileName= 'famepilot-sales-trend-location.csv';
    } else if(activeSection=== 'ordersTrend' && section ==='overtime') {
      fileUrl= `${utils.format(URLS.ORDERS_TREND_OVERTIME, [business_id])}${search ? search + '&' : '?'}is_download=true`;
      fileName= 'famepilot-orders-trend-overtime.csv';
    } else if(activeSection=== 'ordersTrend' && section ==='location') {
      fileUrl= `${utils.format(URLS.ORDERS_TREND_LOCATION, [business_id])}${search ? search + '&' : '?'}is_download=true`;
      fileName= 'famepilot-orders-trend-location.csv';
    }

    props.reviewsActions.downloadTemplate(fileUrl, fileName);
  }

  const handleRootTabChange = (value) => {
    if(value===0) setActiveSection("salesTrend");
    else if(value===1) setActiveSection("ordersTrend")
  };

  return (
    <div>
      <LoaderComponent loader={loader} />
      <div className="mt-4" style={{ display: "flex", fontSize: "12px" }}>
        Last Period: {mainData?.past_start_date} - {mainData?.past_end_date}
      </div>
      <div className="mt-4">
        <SalesFilter history={props.history} reviewSourceOptions={sourceOptions} match={props.match} />
      </div>

      <div className="mt-4" style={{ display: "grid", gap: "20px", gridTemplateColumns: "repeat(auto-fit, minmax(220px, 1fr))" }}>
        <Card style={{ width: "100%", height: "100%" }} className="p-3">
          <div key={0} style={css_provider.main}>Total Delivered Orders</div>
          <div key={1} style={css_provider.twoCols}>
            <div style={css_provider.innerTwoCols}>
              <span style={css_provider.figure}>{mainData.total_delivered_orders}</span>
              <span style={css_provider.pctFigure}>
                {mainData.change_delivered_orders && mainData.change_delivered_orders >= 0 ? (
                  <IoTriangle style={{ color: "#38b500" }} />
                ) : (
                  <IoTriangle style={{ color: "#ff4d4f", rotate: "180deg" }} />
                )}
                <span>{mainData.change_delivered_orders}%</span>
              </span>
            </div>
            {
              <div style={css_provider.pastFigure}>
                vs Last Period: {mainData.past_delivered_orders ? mainData.past_delivered_orders : 0} orders
              </div>
            }
          </div>
          <Divider />
          <div style={css_provider.title}>
            Channel Wise Orders{" "}
          </div>

          {mainData.channel_wise_data &&
            mainData.channel_wise_data.map((val, index) => {
              const provider_name = val?.provider_name;
              return (
                <div
                  key={index}
                  className="mt-2"
                  style={css_provider.channelWiseColumns}
                >
                  <div style={{ textAlign: "center" }}>
                    {provider_name === "all" || provider_name === "swiggy" || provider_name === "zomato" ? (
                      <span style={detail_provider[provider_name]?.style}>{detail_provider[provider_name]?.name}</span>
                    ) : (
                      <img height="24" src={`https://api.famepilot.com/${val?.icon}`} alt="val" />
                    )}
                  </div>
                  <div
                    style={{
                      height: css_provider.progressBar.height,
                      backgroundColor: detail_provider[provider_name]
                        ? detail_provider[provider_name]?.progress_background
                        : "#add8e65e",
                      light: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: ((val?.orders && mainData.total_delivered_orders ) ? (val?.orders / mainData.total_delivered_orders ) *100 : 0) + "%",
                        backgroundColor: detail_provider[provider_name]?.name
                          ? detail_provider[provider_name]?.progress_color
                          : "skyblue",
                        borderRadius: "10px",
                      }}
                    ></div>
                  </div>
                  <div style={{...css_provider.figure, ...css_provider.textAlignRight}}>{val?.orders}</div>
                </div>
              );
            })}
        </Card>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Card
            style={{ width: "100%", height: "100%", margin: "0", borderBottom: "3px solid blue !important" }}
            className="p-3"
          >
            <div style={css_provider.main}>Total Sales</div>
            <div style={css_provider.twoCols}>
              <div style={css_provider.innerTwoCols}>
                <span style={css_provider.figure}>&#8377; {mainData.total_sales ? Intl.NumberFormat('en-IN').format(mainData.total_sales) : 0}</span>
                <span style={css_provider.pctFigure}>
                  {mainData.change_sales && mainData.change_sales >= 0 ? (
                    <IoTriangle style={{ color: "#38b500" }} />
                  ) : (
                    <IoTriangle style={{ color: "#ff4d4f", rotate: "180deg" }} />
                  )}
                  <span>{mainData.change_sales}%</span>
                </span>
              </div>
              <div style={css_provider.pastFigure}>
                vs Last Period: &#8377; {mainData.past_sales ? Intl.NumberFormat('en-IN').format(mainData.past_sales) : 0} sales
              </div>
            </div>
            <Divider/>
            <div style={css_provider.title}>
              Channel Wise Sales{" "}
            </div>

            {mainData.channel_wise_data &&
              mainData.channel_wise_data.map((val, index) => {
                const provider_name = val?.provider_name;
                return (
                  <div
                    key={index}
                    className="mt-2"
                    style={css_provider.channelWiseColumns}
                  >
                    <div style={css_provider.textAlignCenter}>
                      {provider_name === "all" || provider_name === "swiggy" || provider_name === "zomato" ? (
                        <span style={detail_provider[provider_name]?.style}>{detail_provider[provider_name]?.name}</span>
                      ) : (
                        <img height="24" src={`https://api.famepilot.com/${val?.icon}`} alt="val" />
                      )}
                    </div>
                    <div
                      style={{
                        height: css_provider.progressBar.height,
                        backgroundColor: detail_provider[provider_name]
                          ? detail_provider[provider_name]?.progress_background
                          : "#add8e65e",
                        light: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: ((val?.sales && mainData.total_sales ) ? (val?.sales / mainData.total_sales ) *100 : 0) + "%" ,
                          backgroundColor: detail_provider[provider_name]?.name
                            ? detail_provider[provider_name]?.progress_color
                            : "skyblue",
                          borderRadius: "10px",
                          textAlign: "right",
                        }}
                      ></div>
                    </div>
                    <div style={{...css_provider.figure, ...css_provider.textAlignRight}}>&#8377; {val?.sales ? Intl.NumberFormat('en-IN').format(val?.sales) : 0}</div>
                  </div>
                );
              })}
          </Card>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Card
            style={{ width: "100%", height: "100%", margin: "0", borderBottom: "3px solid blue !important" }}
            className="p-3"
          >
            <div style={css_provider.main}>Total AOV</div>
            <div style={css_provider.twoCols}>
              <div style={css_provider.innerTwoCols}>
                <span style={css_provider.figure}>&#8377; {mainData.total_AOV ? Intl.NumberFormat('en-IN').format(mainData.total_AOV) : 0}</span>
                <span style={css_provider.pctFigure}>
                  {mainData.change_AOV && mainData.change_AOV >= 0 ? (
                    <IoTriangle style={{ color: "#38b500" }} />
                  ) : (
                    <IoTriangle style={{ color: "#ff4d4f", rotate: "180deg" }} />
                  )}
                  <span>{mainData.change_AOV}%</span>
                </span>
              </div>
              <div style={css_provider.pastFigure}>
                vs Last Period: &#8377; {mainData.past_AOV ? Intl.NumberFormat('en-IN').format(mainData.past_AOV) : 0} AOV
              </div>
            </div>
            <Divider/>
            <div style={css_provider.title}>
              Channel Wise AOV{" "}
            </div>

            {mainData.channel_wise_data &&
              mainData.channel_wise_data.map((val, index) => {
                const provider_name = val?.provider_name;
                return (
                  <div
                    key={index}
                    className="mt-2"
                    style={css_provider.channelWiseColumns}
                  >
                    <div style={{ textAlign: "center" }}>
                      {provider_name === "all" || provider_name === "swiggy" || provider_name === "zomato" ? (
                        <span style={detail_provider[provider_name]?.style}>{detail_provider[provider_name]?.name}</span>
                      ) : (
                        <img height="24" src={`https://api.famepilot.com/${val?.icon}`} alt="val" />
                      )}
                    </div>
                    <div
                      style={{
                        height: css_provider.progressBar.height,
                        backgroundColor: detail_provider[provider_name]
                          ? detail_provider[provider_name]?.progress_background
                          : "#add8e65e",
                        light: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: ((val?.AOV && mainData.total_AOV ) ? (val?.AOV / mainData.total_AOV ) *100 : 0) + "%" ,
                          backgroundColor: detail_provider[provider_name]?.name
                            ? detail_provider[provider_name]?.progress_color
                            : "skyblue",
                          borderRadius: "10px",
                          textAlign: "right",
                        }}
                      ></div>
                    </div>
                    <div style={{...css_provider.figure, ...css_provider.textAlignRight}}>&#8377; {val?.AOV ? Intl.NumberFormat('en-IN').format(val?.AOV) : 0}</div>
                  </div>
                );
              })}
          </Card>
        </div>
      </div>
      <Card style={{ width: "100%" }} className="p-3 my-4">
        <div className="report-ThirdRow mb-4 ">
          <FPTab
            tabLabels={["Sales Trend", "Orders Trend"]}
            minTabWidth={100}
            defaultValue={activeSection=== "salesTrend" ? 0 : 1}
            onChange={handleRootTabChange}
          />
        </div>
        <div className="report-ThirdRow mb-4 d-flex justify-content-between gap-2" >
          <Nav tabs className="py-1 box-shadow-none" style={{ borderBottom: "none" }}>
            <Fragment>
              <NavItem>
                <NavLink
                  className={classnames({ active: section === "overtime" })}
                  onClick={() => setSection("overtime")}
                >
                  Overtime
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: section === "location" })}
                  onClick={() => setSection("location")}
                >
                  Location
                </NavLink>
              </NavItem>
            </Fragment>
          </Nav>
        </div>
        
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "gray" }}>
            <FormControlLabel
              value="Graph"
              label="Graph"
              control={<Radio />}
              onClick={() => setView("Graph")}
              checked={view === "Graph"}
            />
            <FormControlLabel
              value="Table"
              label="Table"
              control={<Radio />}
              onClick={() => setView("Table")}
              checked={view === "Table"}
            />
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <FPToolTip title={"Download"} placement={"top"}>
              <MdOutlineFileDownload
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={() => handleDownload()}
              />
            </FPToolTip>
            <FPToolTip title={"Print"} placement={"top"}>
              <IoPrintOutline onClick={() => printLineChart()} style={{ fontSize: "18px", cursor: "pointer" }} />
            </FPToolTip>
          </div>
        </div>

        <div className="mx-4">
          <div id="restro-print" style={{ height: "500px", overflowX: "auto" }}>
            {activeSection==='salesTrend' && section==='overtime' &&  view==='Graph' && 
              <Line
                datasetIdKey="id"
                data={{ datasets: [...salesOvertimeGraph], labels: salesOvertimeLabels }}
                options={options}
              />
            }
            {activeSection==='salesTrend' && section==='overtime' &&  view==='Table' &&
              <SalesTable data={salesOvertimeTable} />
            }
            {activeSection==='salesTrend' && section==='location' &&  view==='Graph' &&
              <Line
                datasetIdKey="id"
                data={{ datasets: [...salesLocationGraph], labels: salesLocationLabels }}
                options={options}
              />
            }
            
            {activeSection==='salesTrend' && section==='location' &&  view==='Table' &&
              <SalesTable data={salesLocationTable} />
            }

            {activeSection==='ordersTrend' && section==='overtime' &&  view==='Graph' &&
              <Line
                datasetIdKey="id"
                data={{ datasets: [...ordersOvertimeGraph], labels: ordersOvertimeLabels }}
                options={options}
              />
            }
            {activeSection==='ordersTrend' && section==='overtime' &&  view==='Table' &&
              <SalesTable  data={ordersOvertimeTable} />
            }
            {activeSection==='ordersTrend' && section==='location' &&  view==='Graph' &&
              <Line
                datasetIdKey="id"
                data={{ datasets: [...ordersLocationGraph], labels: ordersLocationLabels }}
                options={options}
              />
            }
            {activeSection==='ordersTrend' && section==='location' &&  view==='Table' &&
              <SalesTable data={ordersLocationTable} />
            }
          </div>
        </div>
      </Card>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(setSelectedTab, dispatch),
    reviewsActions: bindActionCreators(ReviewsActions, dispatch),
    salesAction: bindActionCreators(salesActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
    salesOvertime: state.salesPage.salesOvertime || {},
    salesLocation: state.salesPage.salesLocation || {},
    ordersOvertime: state.salesPage.ordersOvertime || {},
    ordersLocation: state.salesPage.ordersLocation || {},
  };
};

SalesPage.propTypes = {
  loader: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesPage);


const SalesTable = ({ data }) => {
  const options = [];
  data && data[0] && Object.keys(data[0]).map((val) => options.push(val));
  return (
    <div className="ui-table-responsive">
      {data && Array.isArray(data) && data.length > 0 ? <Table className="fp-table fp-table-center">
        <TableHead>
          <TableRow>
            {options && options.map((heading, i) => {
                const newHeadng = heading[0]?.toUpperCase() + heading.slice(1);
                return (
                  <TableCell key={i} className="tableHeaderHover">
                    {newHeadng}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((item, index) => {
            return (
              <TableRow key={index}>
                {options && options.map((val, index) =>  (
                  <TableCell key={index}>
                    {item[val]}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      : <h1 className="p-4" style={{ textAlign: 'center'}}>No Data Found</h1>}
    </div>
  )
}

