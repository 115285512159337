import React from 'react'
import { FixedSizeList as List } from 'react-window';
import { FPButton } from '../common/new';
import { Box } from '@material-ui/core';
import tagsvg from "../../images/svg/Tag.svg";
import SearchComponent from '../common/SearchComponent';

function AssignNewTagModel() {
    const ManageRequestType = [
      { value: "Food_Poor", display_name: "Food- Poor" },
      { value: "Chicken_cheese_fried_momo", display_name: "Chicken cheese fried momo" },
      { value: "Wrong_Items", display_name: "5_WRONG_ITEMS" },
      { value: "Peri_Peri_Fried_Chicken", display_name: "Peri peri fried chicken" },
      { value: "Other_Issues", display_name: "6 Other Issues" },
      { value: "Chicken_Delight_Chilli_Momo", display_name: "Chicken Delight Chilli Momo" },
    ];
  
    const Row = ({ index, style }) => {
      const tag = ManageRequestType[index];
      return (
        <div
          key={tag.value}
          style={style}
          className="filter-tags d-flex justify-content-between align-items-center pr-3 pb-0"
        >
          <div className="d-flex gap-10">
            <img src={tagsvg} alt="tag-icon" />
            <div className="manageTagModelText" style={{ fontSize: "14px" }}>
              {tag.display_name}
            </div>
          </div>
        </div>
      );
    };
  
    return (
      <React.Fragment>
        <div style={{ margin:"10px -5px"}}>
        <SearchComponent className="fp-flex-grow-1" endIcon />
        </div>
        <div className="d-flex flex-column gap-15">
          {ManageRequestType.length > 0 ? (
            <List height={500} itemCount={ManageRequestType.length} itemSize={45} width={"100%"}>
              {Row}
            </List>
          ) : (
            <div style={{ marginTop: "-24px", textAlign: "center" }}>
              <span>No tags found</span>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

export default AssignNewTagModel