import React from "react";
import { NoData } from "../../components/common/new";
import LoaderComponent from "../../components/common/loaderComponent";
import CompetitorsBarChart from "../../components/common/CompetitorsBarChart";

const SentimentGraph = ({ ratingDetails, loader, businessDetails }) => {
  const CHART_BACKGROUND_COLOR_1 = "#9E74E9";

  let sentimentData = {};
  sentimentData["positive"] = [];
  sentimentData["neutral"] = [];
  sentimentData["negative"] = [];
  sentimentData["total"] = [];

  let location_addresses = [];

  ratingDetails &&
    ratingDetails.data &&
    ratingDetails.data.star_ratings &&
    ratingDetails.data.star_ratings.map((object) => {
      sentimentData["positive"].push(
        object.five_star_count + object.four_star_count
      );
      sentimentData["neutral"].push(object.three_star_count);
      sentimentData["negative"].push(
        object.one_star_count + object.two_star_count
      );
      sentimentData["total"].push(
        object.five_star_count +
          object.four_star_count +
          object.one_star_count +
          object.two_star_count +
          object.three_star_count
      );
      location_addresses.push(
        object.competitor_name ? object.competitor_name : object.owner_name
      );
    });
  const calculateFontSize = (itemsCount) => {
    const minFontSize = 8; // Minimum font size
    const maxFontSize = 16; // Maximum font size
    const dynamicFontSize = maxFontSize - Math.min(itemsCount, 20) * 0.4;
    return Math.max(minFontSize, dynamicFontSize);
  };

  const calculateBarThickness = (itemsCount, chartWidth = 800) => {
    const minThickness = 10; 
    const maxThickness = 50; 
    const dynamicThickness = chartWidth / (itemsCount * 3); 
    return Math.max(minThickness, Math.min(maxThickness, dynamicThickness));
  };

  const calculateLineThickness = (itemsCount) => {
    const minLineThickness = 1; 
    const maxLineThickness = 4; 
    const dynamicLineThickness =
      maxLineThickness - Math.min(itemsCount, 20) * 0.1; 
    return Math.max(minLineThickness, dynamicLineThickness);
  };

  const calculateLegendFontSize = (itemsCount) => {
    const minLegendFontSize = 10; 
    const maxLegendFontSize = 14; 
    const dynamicLegendFontSize = maxLegendFontSize - Math.min(itemsCount, 20) * 0.2; 
    return Math.max(minLegendFontSize, dynamicLegendFontSize);
  };

  const calculateLegendSize = (itemsCount) => {
    const minBoxWidth = 1; 
    const maxBoxWidth = 2; 
    const dynamicBoxWidth = maxBoxWidth - Math.min(itemsCount, 100) * 0.2; 
    return Math.max(minBoxWidth, dynamicBoxWidth);
  };

  const fontSize = calculateFontSize(location_addresses.length);
  const barThickness = calculateBarThickness(location_addresses.length);
  const lineThickness = calculateLineThickness(location_addresses.length);
  const legendFontSize = calculateLegendFontSize(location_addresses.length);
  const legendSize = calculateLegendSize(location_addresses.length);

  return (
    <div className="pt-0 px-3 pb-3">
      <LoaderComponent loader={loader} />
      {ratingDetails?.data?.star_ratings?.length > 0 ? (
        <CompetitorsBarChart
          dataKeys={location_addresses}
          dataSets={[
            {
              label: "Positive",
              data: sentimentData.positive,
              backgroundColor: "rgba(50, 213, 131, 0.1)",
              borderColor: "#57e32c",
              yAxisID: "y-axis-1",
              lineTension: 0.6,
              type: "line",
              legendColor: "#57e32c",
              fill: {
                target: "origin",
              },
              borderWidth: lineThickness,
              pointRadius:legendSize,
            },
            {
              label: "Negative",
              data: sentimentData.negative,
              backgroundColor: "#ffe234",
              borderColor: "#ffe234",
              yAxisID: "y-axis-1",
              lineTension: 0.6,
              type: "line",
              legendColor: "#ffe234",
              borderWidth: lineThickness,
              pointRadius:legendSize,
            },
            {
              label: "Neutral",
              data: sentimentData.neutral,
              backgroundColor: "#ff4545",
              borderColor: "#ff4545",
              yAxisID: "y-axis-1",
              lineTension: 0.6,
              type: "line",
              legendColor: "#ff4545",
              borderWidth: lineThickness,
              pointRadius:legendSize,
            },
            {
              label: "Total Reviews",
              data: sentimentData.total,
              type: "bar",
              barThickness: barThickness,
              backgroundColor: location_addresses.map((label) =>
                label === businessDetails ? "#7F56D9" : CHART_BACKGROUND_COLOR_1
              ),
              hoverBackgroundColor: location_addresses.map((label) =>
                label === businessDetails ? "#7F56D9" : CHART_BACKGROUND_COLOR_1
              ),
              yAxisID: "y-axis-2",
              legendColor: CHART_BACKGROUND_COLOR_1,
            },
          ]}
          primaryYLabel="Ratings"
          secondaryYLabel="Reviews"
          businessDetails={businessDetails}
          labelFontSize={fontSize}
          legendSize={legendSize}
        />
      ) : (
        !loader && <NoData />
      )}
    </div>
  );
};

export default SentimentGraph;
