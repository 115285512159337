import React from "react";

const OpenStatus = (props) => {
  const convertedArray =
    props?.regularHours?.periods?.length > 0
      ? Object.groupBy(props.regularHours.periods, ({ openDay }) => openDay)
      : null;

  return (
    <>
      <div style={{ display: "grid", gap: "10px", padding: "10px" }}>
        <div
          style={{
            display: "flex",
            gap: "18px",
            borderBottom: "1px solid #BFC2CB",
            paddingBottom: "12px",
          }}
        >
          <div
            style={{
              color: "#646565",
              display: "flex",
              fontSize: "18px",
            }}
          >
            Opening & Closing Hours
          </div>
        </div>
        {props?.regularHours?.periods?.length > 0 ? (
          Object.keys(convertedArray).map((openDay) => {
            return (
              <div className="row d-flex align-center" style={{ gap: "10px" }}>
                <div
                  className="col-2"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#1B1C1D",
                  }}
                >
                  {openDay?.slice(0, 3)}:
                </div>
                {convertedArray[openDay].map((openDay_values, index, arr) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="10 d-flex align-items-center gap-6">
                        <div
                          className=""
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#1B1C1D",
                          }}
                        >
                          {openDay_values?.openTime?.hours ? openDay_values?.openTime?.hours : "12"}:
                          {openDay_values?.openTime?.minutes ? openDay_values?.openTime?.minutes : "00"}
                        </div>
                        <div>-</div>
                        <div
                          className=""
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#1B1C1D",
                          }}
                        >
                          {openDay_values?.closeTime?.hours ? openDay_values?.closeTime?.hours : "12"}:
                          {openDay_values?.closeTime?.minutes ? openDay_values?.closeTime?.minutes : "00"}
                        </div>
                        {index < arr.length - 1 && <span className="comma-spacing">,</span>}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            );
          })
        ) : (
          <div className="d-flex justify-content-center"> Data Not Found</div>
        )}
      </div>
    </>
  );
};

export default OpenStatus;
