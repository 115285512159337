import React, { forwardRef } from "react";
import { Card } from "reactstrap";
import "./listing.css";
import { CircularProgressbar } from "react-circular-progressbar";
import { Box, Dialog, DialogActions, IconButton, Slide } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import UpdatePhotos from "../../../containers/listing/UpdatePhotos";
import success_svg from "../../../images/success.svg";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const LogoPhotoVideo = ({ primaryDetail, basic_detail_data, business_id, selectReso }) => {
  const [open, setOpen] = React.useState({
    photos: false,
    video: false,
    coverphoto: false,
  });
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (dialog, scrollType) => {
    setOpen((prevState) => ({
      ...prevState,
      [dialog]: true,
    }));
    setScroll(scrollType);
  };

  const handleClose = (dialog) => {
    setOpen((prevState) => ({
      ...prevState,
      [dialog]: false,
    }));
  };
  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <div className="row detail-card-container">
      <div className="select-box-hover col-md-6 col-lg-3  p-1" onClick={() => handleClickOpen("photos", "paper")}>
        <Card className="detail-card-item detail-special-card cursor-pointer ">
          <div className="detail-card-content">
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div style={{ width: "28px" }}>
              {basic_detail_data?.storefront_photos === true ? (
                <img src={success_svg} height="28px" alt="" className="successImg" />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>Add Storefront photos</span>
            <span className="detail-business-card-review">Add new photos</span>
          </div>
        </Card>
      </div>
      <Dialog
        open={open.photos}
        onClose={() => handleClose("photos")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="custom-dialog"
        maxWidth="lg"
        fullWidth
        // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height:"90vh",
            width: "100%",
          },
        }}      >
        <Box sx={style}>
          <div style={{ padding: "20px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update Photos</span>
              <CloseIcon onClick={() => handleClose("photos")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("photos")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdatePhotos
              defaultvalue={3}
              type="store_front"
              business_id={business_id}
              primaryDetail={primaryDetail}
              selectReso={selectReso}
            />
          </div>
        </Box>
      </Dialog>

      <div className="select-box-hover col-md-6 col-lg-3  p-1" onClick={() => handleClickOpen("video", "paper")}>
        <Card className="detail-card-item detail-special-card cursor-pointer ">
          <div className="detail-card-content">
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div style={{ width: "28px" }}>
              {basic_detail_data?.videos === true ? (
                <img src={success_svg} height="28px" alt="" className="successImg" />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>Add Videos</span>
            <span className="detail-business-card-review">
              Engage with your customers by adding brand videos & reels
            </span>
          </div>
        </Card>
      </div>
      <Dialog
        open={open.video}
        onClose={() => handleClose("video")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="custom-dialog"
        maxWidth="lg"
        fullWidth
        // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height:"90vh",
            width: "100%",
          },
        }}      >
        <Box sx={style}>
          <div style={{ padding: "20px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update Photos</span>
              <CloseIcon onClick={() => handleClose("video")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("video")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdatePhotos
              defaultvalue={13}
              type="video"
              business_id={business_id}
              primaryDetail={primaryDetail}
              selectReso={selectReso}
            />
          </div>
        </Box>
      </Dialog>

      <div className="select-box-hover col-md-6 col-lg-3  p-1" onClick={() => handleClickOpen("coverphoto", "paper")}>
        <Card className="detail-card-item detail-special-card cursor-pointer ">
          <div className="detail-card-content">
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div style={{ width: "28px" }}>
              {basic_detail_data?.profile_photo === true ? (
                <img src={success_svg} height="28px" alt="" className="successImg" />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>Profile & Cover Photo</span>
            <span className="detail-business-card-review">Add logo & cover photo in your profile</span>
          </div>
        </Card>
      </div>
      <Dialog
        open={open.coverphoto}
        onClose={() => handleClose("coverphoto")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="custom-dialog"
        maxWidth="lg"
        fullWidth
        // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height:"90vh",
            width: "100%",
          },
        }}      >
        <Box sx={style}>
          <div style={{ padding: "20px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update Photos</span>
              <CloseIcon onClick={() => handleClose("coverphoto")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("coverphoto")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdatePhotos
              defaultvalue={2}
              type="coverphoto"
              business_id={business_id}
              primaryDetail={primaryDetail}
              selectReso={selectReso}
            />
          </div>
        </Box>
      </Dialog>
    </div>
  );
};

export default LogoPhotoVideo;
