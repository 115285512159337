import React, { useState } from "react";
import moment from "moment";
import LoaderComponent from "../common/loaderComponent";
import { FPButton, FPToolTip } from "../common/new";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import deleteIcon from "../../images/svg/trash.svg";
import editIcon from "../../images/svg/EditPen.svg";
import ConfirmationModal from "../common/ConfirmationModal";
import PaginationComponent from "../common/Pagination";

const CompetitorsList = (props) => {
  const { toggle, competitorsList, deleteCompetitor, disabled, loader, pageSizeCookie, location, count } = props;
  const [isConfirm, setIsConfirm] = useState(false);
  const [competitorsId, setCompetitorsId] = useState(null);
  const toggleConfirm = () => {
    deleteCompetitor(competitorsId);
    setIsConfirm(false);
  };

  const handleClick = (id) => {
    setCompetitorsId(id);
    setIsConfirm(!isConfirm);
  };

  return (
    <div>
      <LoaderComponent loader={loader} />
      <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
        <span
          style={{
            fontSize: "18px",
            fontWeight: "400",
            color: "rgba(100, 101, 101, 1)",
          }}
        >
          Competitors List
        </span>
        <div>
          <FPButton
            label="Add Competitor"
            type="button"
            disabled={disabled}
            onClick={() => toggle()}
            style={{ height: "30px" }}
            endIcon={<AddIcon style={{ height: 16, width: 16, color: "white" }} />}
          />
        </div>
      </div>
      <CardWrapper showDivider={false} contentClass="p-3">
        <div className="ui-table-responsive">
          <Table className="fp-table fp-table-center">
            <TableHead>
              <TableRow>
                <TableCell>S No.</TableCell>
                <TableCell>Competitor Brand Name</TableCell>
                <TableCell>Created at</TableCell>
                {/* <TableCell>Avg Rating</TableCell>
                <TableCell>No. of Reviews</TableCell> */}
                <TableCell>No. of Locations</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {competitorsList && competitorsList.length
                ? competitorsList.map((competitor, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{competitor.name}</TableCell>
                      <TableCell>{moment(competitor.created_at).format("DD-MM-YYYY")}</TableCell>
                      {/* <TableCell>{competitor.avg_rating}</TableCell>
                      <TableCell>{competitor.review_count}</TableCell> */}
                      <TableCell>{competitor.branch_count}</TableCell>
                      <TableCell>
                        <div className="actionButton">
                          <FPToolTip title={"Edit"}>
                            <img
                              src={editIcon}
                              onClick={() => {
                                toggle({
                                  id: competitor.id,
                                  name: competitor.name,
                                });
                              }}
                              role="button"
                              alt="edit"
                            />
                          </FPToolTip>

                          <FPToolTip title={"Delete"}>
                            <img
                              src={deleteIcon}
                              onClick={() => {
                                handleClick(competitor.id);
                              }}
                              role="button"
                              alt="delete"
                            />
                          </FPToolTip>
                        </div>
                      </TableCell>
                      {/* <TableCell>
                        <Dropdown icon='ellipsis vertical' pointing='right' floating className='icon dropdown-absolute'>
                          <Dropdown.Menu>
                            <Dropdown.Item icon='edit' text='Edit' onClick={() => { toggle({ id: competitor.id, name: competitor.name }); }} />
                            <Dropdown.Item icon='trash alternate' text='Delete' onClick={() => { deleteCompetitor(competitor.id); }} />
                          </Dropdown.Menu>
                        </Dropdown></TableCell> */}
                    </TableRow>
                  ))
                : []}
            </TableBody>
          </Table>
        </div>
        <PaginationComponent count={count} location={location} pageSizeCookie={pageSizeCookie} />
        <ConfirmationModal
          header="Are you sure!"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          subHeader={`Are you sure you want to delete this staff?`}
          isOpen={isConfirm}
          toggle={() => setIsConfirm(!isConfirm)}
          onConfirm={() => toggleConfirm()}
        />
      </CardWrapper>
    </div>
  );
};

export default CompetitorsList;
