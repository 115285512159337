import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart({ data, type = "default" }) {

  const colorSchemes = {
    service_request: ["#4caf50", "#66bb6a", "#81c784", "#a5d6a7"],
    complaints: ["#fbc02d", "#fdd835", "#ffee58", "#fff176"],
    default: ["#4e73df", "#1cc88a", "#36b9cc", "#f6c23e", "#ff6384"],
  };

  const chartColors = colorSchemes[type] || colorSchemes["default"];

  const transformData = (rawData) => {
    const labels = rawData?.map((item) => item.title || "Unknown");
    const values = rawData?.map((item) => item.count);
    return { labels, values };
  };

  const { labels, values } = transformData(data);

  const totalValue = values?.reduce((acc, value) => acc + value, 0);
  const chartData = {
    labels,
    datasets: [
      {
        data: values, 
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors?.map((color) => color + "CC"),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const percentage = ((tooltipItem.raw / totalValue) * 100).toFixed(2);
            return `${tooltipItem.raw} (${percentage}%)`;
          },
        },
      },
    },
  };

  return (
    <div style={{ width: "200px", height: "200px", margin: "auto" }}>
      <Pie data={chartData} options={options} />
    </div>
  );
}

export default PieChart;
