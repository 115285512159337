import React from "react";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import ellipse_I from "../../images/svg/Ellipse.svg";
import ellipse_II from "../../images/svg/Ellipse_Gray.svg";
import infoSvg from "../../images/svg/successfull_circle.svg";
import alertSvg from "../../images/svg/alert-svg.svg";
import markedIcon from "../../images/svg/check-square-broken.svg";
import {
  Box,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";

function NotificationUI(props) {
  const {
    dateTimeI,
    dateTimeII,
    ellipse = true,
    tableBorder,
    tableTop,
    contentClass,
    tableHead = true,
    dividerClass,
    tableContainerClass,
    headerTitle = true,
    tableBodyRowI,
    tableBodyRowII,
    notificationHeading,
    viewBtn,
    onClick
  } = props;

  const notificationList = [
    {
      img_I: ellipse_I,
      img_II: infoSvg,
      ticketMsg: "Ticket Raised",
      view: "View",
      time: "10:56:42",
      date: "18 Jan 2023",
    },
    {
      img_I: ellipse_II,
      img_II: alertSvg,
      ticketMsg: "New message",
      time: "10:56:42",
      date: "18 Jan 2023",
    },
    {
      img_I: ellipse_I,
      img_II: infoSvg,
      ticketMsg: "Assigned Ticket to Mughal Delight",
      view: "View",
      time: "10:56:42",
      date: "18 Jan 2023",
    },
    {
      img_I: ellipse_II,
      img_II: alertSvg,
      ticketMsg: "Ticket Raised",
      time: "10:56:42",
      date: "18 Jan 2023",
    },
  ];

  const markText = {
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "left",
    marginLeft: "-6px",
    pointerEvents: "none",
  };
  
  return (
    <div className={tableTop}>
      <CardWrapper
        showDivider={false}
        contentClass={contentClass}
        dividerClass={dividerClass}
        headerTitle={headerTitle ? <div>Notifications</div> : ""}
      >
        <Box
          className={`ui-table-responsive ${tableContainerClass}`}
          sx={tableBorder}
        >
          <Table className="fp-table fp-table-center">
            {tableHead && (
              <TableHead>
                <TableRow>
                  <span className="align-center ml-4">
                    <img src={markedIcon} />
                    <TableCell className="" style={markText}>
                      <Box sx={{ color: "#646565 !important" }}>
                        Mark all as read
                      </Box>
                    </TableCell>
                  </span>
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {notificationHeading && (
                <TableRow>
                  <div className="align-center justify-content-between mb-3">
                    <Box
                      sx={{
                        fontSize: "15px",
                        color: "#1B1C1D",
                        fontWeight: 500,
                      }}
                    >
                      Notifications
                    </Box>
                    <Box
                      sx={{
                        color: "#F13A88",
                        fontSize: "15px",
                        cursor: "pointer",
                      }}
                      onClick={onClick}
                    >
                      See all
                    </Box>
                  </div>
                </TableRow>
              )}
              {notificationList && notificationList.length
                ? notificationList.map((item) => (
                    <TableRow className="hideLastBorder">
                      <div className={`align-center py-3 ${tableBodyRowI}`}>
                        <div
                          className="align-center gap-15"
                          style={{ marginRight: "5px" }}
                        >
                          {ellipse && <img src={item.img_I} alt="Image 1" />}
                          <img src={item.img_II} alt="Image 2" />
                        </div>

                        <div className={`flex-grow-1 ${tableBodyRowII}`}>
                          <div className="align-center justify-content-between">
                            <div className="d-flex flex-column w-100">
                              <Box
                                sx={{
                                  color: "#1B1C1D",
                                  fontSize: "13px",
                                }}
                              >
                                {item.ticketMsg}
                              </Box>
                              {dateTimeI && (
                                <div className="align-center justify-content-between gap-20">
                                  <div className="align-center gap-10">
                                    <Box
                                      sx={{
                                        color: "#7A8193",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {item.time}
                                    </Box>
                                    <Box
                                      sx={{
                                        color: "#7A8193",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {item.date}
                                    </Box>
                                  </div>
                                  <Box
                                    component="button"
                                    className={`border-0 bg-transparent`}
                                    sx={{ color: "#F13A88", fontSize: "13px" }}
                                  >
                                    {item.view}
                                  </Box>
                                </div>
                              )}
                            </div>
                            {viewBtn && (
                              <Box
                                component="button"
                                className={`border-0 bg-transparent`}
                                sx={{ color: "#F13A88", fontSize: "13px" }}
                              >
                                {item.view}
                              </Box>
                            )}
                          </div>
                        </div>
                        {dateTimeII && (
                          <div className="align-center justify-content-end gap-10">
                            <Box sx={{ color: "#7A8193", fontSize: "13px" }}>
                              {item.time}
                            </Box>
                            <Box sx={{ color: "#7A8193", fontSize: "13px" }}>
                              {item.date}
                            </Box>
                          </div>
                        )}
                      </div>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </Box>
      </CardWrapper>
    </div>
  );
}

export default NotificationUI;