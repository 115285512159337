import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Row,
  Col,
} from "reactstrap";
import { Button } from "semantic-ui-react";
import classnames from "classnames";
import _ from "lodash";
import cookie from "react-cookies";

import BusinessSetup from "../../components/business/BusinessSetup";
import BusinessReviewsSetup from "../../components/business/BusinessReviewsSetup";
import LimitModal from "../../components/common/LimitModal";
import SetupFBSocialAccounts from "../../components/business/SetupFBSocialAccounts";
import SetupGGLSocialAccounts from "../../components/business/SetupGGLSocialAccounts";
import CompetitorsLinkForm from "../../components/competitors/CompetitorsLinkForm";
import SetupBKNGSocialAccounts from "../../components/business/SetupBKNGSocialAccounts";
import FormModal from "../../components/common/FormModal";
import LockedComponent from "../../components/common/LockedComponent/LockedComponent";

import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import * as SocialSetupActions from "../../actions/business/socialSetupActions";
import * as CompetitorActions from "../../actions/competitors/competitorsActions";
import * as CommonActions from "../../actions/common/commonAction";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";

import LoaderComponent from "../../components/common/loaderComponent";
import axios_instance from "../../libs/interseptor";
import BranchCompetitors from "../../components/competitors/BranchCompetitors";
import BranchCompetitorsForm from "../../components/competitors/BranchCompetitorsForm";
import BranchRootHeader from "../setup/BranchRootHeader";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import BranchSelector from "../../components/business/BranchSelector";
import FPLabel from "../../components/common/new/forms/ui/FPLabel";

class BusinessProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleTabSocial = this.toggleTabSocial.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.postCompLinks = this.postCompLinks.bind(this);
    this.handleBusinessSetup = this.handleBusinessSetup.bind(this);
    this.submitSocialLinks = this.submitSocialLinks.bind(this);
    this.resetFormState = this.resetFormState.bind(this);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.connectSocial = this.connectSocial.bind(this);
    this.handleBranchCompetitors = this.handleBranchCompetitors.bind(this);
    this.addBranchCompetitors = this.addBranchCompetitors.bind(this);
    this.editBranchCompetitor = this.editBranchCompetitor.bind(this);
    this.toggleBranchComp = this.toggleBranchComp.bind(this);
    this.toggleBranchLimitModal = this.toggleBranchLimitModal.bind(this);
    this.deleteBranch = this.deleteBranch.bind(this);
    this.editCompetitorLinks = this.editCompetitorLinks.bind(this);
    this.rerenderParentCallback = this.rerenderParentCallback.bind(this);
    this.state = {
      toggleRR: true,
      isLimitModalOpen: false,
      limitsMessage: "",
      editLinksForm: false,
      activeTab: (props.location.state && props.location.state.tab) || "1",
      activeTabSocial:
        (props.location.state && props.location.state.social) || "ggl",
      isLoading: false,
      isAddCompetitorOpen: false,
      compInitialValues: null,
      initialCompetitorBranch: null,
      isAddCompetitorLinkOpen: false,
      isFormEditable: {
        isProfile: false,
        isBranchProfile: false,
        isAbout: false,
        isPhotos: false,
        isHours: false,
        isIdSetup: false,
        isSocialProfiles: false,
      },
      message: null,
      showMessage: false,
      messageType: "success",
      showLocationListModal: false,
      reviewDetail: {},
      locks: {},
      currentBranch: null,
      branchTagList: [],
    };
    this.toggleMessageBox = this.toggleMessageBox.bind(this);
    this.getAuthUrl = this.getAuthUrl.bind(this);
    this.postCode = this.postCode.bind(this);
    this.checkLoggedIn = this.checkLoggedIn.bind(this);
    this.addCompetitorsLinks = this.addCompetitorsLinks.bind(this);
    this.toggleCompetitorsLinks = this.toggleCompetitorsLinks.bind(this);
    this.getCompLinks = this.getCompLinks.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-line
    if (nextProps.match.params !== this.props.match.params) {
      const {
        match: {
          params: { branch_id, business_id },
        },
      } = nextProps;
      this.setState({ currentBranch: branch_id });
      const data = {
        business: business_id,
        corporate_branch: branch_id,
      };
      this.props.actions.businessBranchDetails(data);
      this.props.actions.getSocialLinksAction(data);
      this.props.compActions.getCompetitorsOptions(data.business);
      this.props.compActions.getBranchCompetitors(data.corporate_branch);
      this.props.compActions.competitorSourceOptions();
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleTabSocial(tabSocial) {
    if (this.state.activeTabSocial !== tabSocial) {
      this.setState({
        activeTabSocial: tabSocial,
        message: null,
        showMessage: false,
        messageType: "success",
      });
    }
    /*else {
      this.setState({
        toggleRR: !this.state.toggleRR
      });
    }*/
  }

  handleBusinessSetup(data) {
    const {
      match: {
        params: { branch_id, business_id },
      },
    } = this.props;
    const userData = { ...data };
    userData.business = business_id;
    userData.corporate_branch = branch_id;
    return this.props.actions.businessSetupAction(userData).then((res) => {
      // eslint-disable-line
      this.resetFormState();
    });
  }

  toggleState(key) {
    let isFormEditable = { ...this.state.isFormEditable };
    isFormEditable[key] = !this.state.isFormEditable[key];
    this.setState({ isFormEditable });
  }

  resetFormState(key) {
    // eslint-disable-line
    const isFormEditable = {
      isProfile: false,
      isAbout: false,
      isPhotos: false,
      isHours: false,
      isSocialProfiles: false,
    };
    this.setState({ isFormEditable });
  }
  submitSocialLinks(data) {
    const {
      match: {
        params: { branch_id, business_id },
      },
    } = this.props;
    const buissnessData = {};
    buissnessData.business = business_id;
    buissnessData.corporate_branch = branch_id;
    return this.props.actions
      .postSocialLinksAction(data, buissnessData)
      .then(() => {
        this.resetFormState();
        this.props.actions.getSocialLinksAction({
          business: business_id,
          corporate_branch: branch_id,
        });
      });
  }

  handleBranchChange(val) {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    const url = "/" + business_id + "/" + val.value + "/business-profile";
    history.push(url);
  }

  rerenderParentCallback() {
    this.setState({ toggleRR: !this.state.toggleRR, showMessage: false });
  }

  componentDidMount() {
    const {
      match: {
        params: { branch_id, business_id },
      },
    } = this.props;
    const data = {
      business: business_id,
      corporate_branch: branch_id,
    };
    let locks = {};
    this.props.actions.businessBranchDetails(data);
    this.props.actions.businessBranchOptions(data);
    this.props.actions.getSocialLinksAction(data);
    this.props.compActions
      .getCompetitorsOptions(data.business)
      .catch(
        (err) =>
          err === "lock" && (locks["4"] = "Competitors module is locked.")
      );
    this.props.compActions.getBranchCompetitors(data.corporate_branch);
    this.props.compActions.competitorSourceOptions();
    this.setState({ locks });
    //Set Title
    document.title = "Branch Setup";
    this.setState({ currentBranch: branch_id });
    axios_instance
      .get(`${URLS.BRANCH_TAG}?business_id=${business_id}`)
      .then((res) => {
        this.setState({ branchTagList: res.data.results });
      });
  }

  toggleMessageBox() {
    const { showMessage } = this.state;
    this.setState({ showMessage: !showMessage });
  }

  connectSocialEvent = (detail) => {
    window.removeEventListener("message", this.connectSocial);
    this.postCode(detail);
    this.newWindow.close();
  };

  connectSocial(data) {
    const { provider } = this.state;
    if (provider === "google" && "code" in data.data) {
      const detail = { code: data.data.code, provider: "google" };
      this.connectSocialEvent(detail);
    } else if (provider === "twitter" && "oauth_verifier" in data.data) {
      const detail = { code: data.data.oauth_verifier, provider: "twitter" };
      this.connectSocialEvent(detail);
    } else if (provider === "booking" && "code" in data.data) {
      const detail = { code: data.data.code, provider: "booking" };
      this.connectSocialEvent(detail);
    }
  }

  postCode(data) {
    const {
      match: {
        params: { branch_id },
      },
    } = this.props;
    this.props.SocialSetupActions.postSocialCode({ ...data, branch: branch_id })
      .then((res) => {
        if (res.data && res.data.select_link) {
          this.setState({
            showLocationListModal: res.data && res.data.select_link,
            reviewDetail: res.data,
            links: res.data ? res.data.links : [],
          });
        } else {
          this.checkLoggedIn(data.provider);
          this.setState({
            message: "Social Account successfully added.",
            messageType: "success",
            showMessage: true,
            reviewDetail: res.data,
          });
        }
      })
      .catch((res) => {
        let message = "Oops ! Something went wrong. Please try again later.";
        if (res.detail) message = res.detail;
        this.setState({
          message: message,
          messageType: "faliure",
          showMessage: true,
        });
      });
  }

  handleBranchCompetitors(data) {
    const {
      match: {
        params: { branch_id, business_id },
      },
    } = this.props;
    return this.props.compActions
      .postBranchCompetitor(branch_id, data)
      .then((res) => {
        // eslint-disable-line
        this.props.compActions.getBranchCompetitors(branch_id);
        this.props.compActions.getCompetitorsOptions(business_id);
        this.toggleBranchComp();
      });
  }
  addCompetitorsLinks() {
    this.setState({ initialCompetitorBranch: null });
    this.toggleCompetitorsLinks();
  }

  postCompLinks(compLoc, data) {
    const {
      match: {
        params: { branch_id },
      },
    } = this.props;
    const compData = { ...data, branch_id, compLoc };
    this.toggleCompetitorsLinks();
    return this.props.compActions.postCompetitorLinks(compData).then((res) => {
      // eslint-disable-line
      this.props.compActions.getBranchCompetitors(branch_id);
    });
  }

  getCompLinks(value) {
    const {
      match: {
        params: { branch_id },
      },
    } = this.props;
    this.setState({ initialCompetitorBranch: value });
    this.props.compActions.getCompetitorLinksDetails(value, branch_id);
  }
  toggleCompetitorsLinks() {
    const { isAddCompetitorLinkOpen, editLinksForm } = this.state;
    if (isAddCompetitorLinkOpen && editLinksForm)
      this.setState({ editLinksForm: false });
    this.setState({
      isAddCompetitorLinkOpen: !isAddCompetitorLinkOpen,
    });
  }
  editCompetitorLinks(id) {
    this.getCompLinks(id);
    this.setState({ initialCompetitorBranch: id, editLinksForm: true });
    this.toggleCompetitorsLinks();
  }

  addBranchCompetitors() {
    const {
      match: {
        params: { branch_id },
      },
    } = this.props;
    this.toggleBranchComp();
    return this.props.compActions.getBranchCompetitors(branch_id);
  }

  editBranchCompetitor(data) {
    const {
      match: {
        params: { branch_id },
      },
    } = this.props;
    return this.props.compActions
      .editBranchCompetitor(branch_id, data)
      .then((res) => {
        // eslint-disable-line
        this.props.compActions.getBranchCompetitors(branch_id);
        this.toggleBranchComp();
      });
  }

  toggleBranchLimitModal(error) {
    const { isLimitModalOpen } = this.state;
    const {
      match: {
        params: { branch_id },
      },
    } = this.props;
    !isLimitModalOpen && this.toggleBranchComp();
    this.setState({
      isLimitModalOpen: !isLimitModalOpen,
      limitsMessage: error,
    });
    this.props.compActions.getBranchCompetitors(branch_id);
  }

  deleteBranch(id) {
    const {
      match: {
        params: { branch_id },
      },
    } = this.props;
    return this.props.compActions
      .deleteBranchCompetitor(branch_id, id)
      .then(() => {
        this.props.compActions.getBranchCompetitors(branch_id);
      });
  }
  toggleBranchComp(id) {
    const { isAddCompetitorOpen } = this.state;
    const {
      match: {
        params: { branch_id },
      },
    } = this.props;
    if (!isAddCompetitorOpen && id) {
      this.setState({ isAddCompetitorOpen: !isAddCompetitorOpen });

      this.props.compActions.getBranchCompDetails(branch_id, id).then((res) => {
        // eslint-disable-line
        this.setState({ compInitialValues: "notNull" });
      });
    } else {
      this.setState({ isAddCompetitorOpen: !isAddCompetitorOpen });
      return this.props.compActions.getBranchCompetitors(branch_id).then(
        (
          res // eslint-disable-line
        ) => {
          this.setState({ compInitialValues: null });
        }
      );
    }
  }

  getAuthUrl(data) {
    const { isLoggedIn } = this.props;
    if (isLoggedIn && isLoggedIn.connected) {
      this.logOut(data);
    } else {
      this.props.SocialSetupActions.getAuthUrl({ provider: data }).then(() => {
        const { socialSetupList } = this.props;
        const authUrl = socialSetupList.auth_url;
        this.newWindow = window.open(
          authUrl,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
        );
        this.setState({ provider: data });
        window.addEventListener("message", this.connectSocial);
      });
    }
  }

  checkLoggedIn(provider) {
    const {
      match: {
        params: { branch_id },
      },
    } = this.props;
    this.props.SocialSetupActions.checkLoggedIn({ branch_id, provider });
  }

  logOut(provider) {
    const {
      match: {
        params: { branch_id },
      },
    } = this.props;
    this.props.SocialSetupActions.logOut({ branch_id, provider });
  }

  serachGoogleLocation = (event) => {
    let links = this.state.links;
    links = links.filter(utils.isContainsubString(event.target.value));
    let reviewDetail = this.state.reviewDetail;
    reviewDetail.links = links;
    this.setState({ reviewDetail: reviewDetail });
  };

  businessLocationList = () => {
    let list = [];
    list.push(
      <div className="ui small icon input" style={{ margin: "5px" }}>
        <input
          type="text"
          placeholder="Search..."
          onChange={this.serachGoogleLocation}
          className="small"
        />
        <i className="search icon"></i>
      </div>
    );
    const { reviewDetail, showLocationListModal } = this.state;
    !_.isEmpty(reviewDetail) &&
      showLocationListModal &&
      reviewDetail.links.map((link, i) => {
        list.push(
          <Button
            key={i}
            onClick={() => {
              this.submitBusinessLocation({
                id: reviewDetail.id,
                provider: reviewDetail.provider,
                ...link,
              });
            }}
            className="mb-2"
            color="teal"
            style={{ textAlign: "inherit", width: "100%" }}
          >
            {link.address}
          </Button>
        );
      });
    return list;
  };

  toggleLocationListModal = () => {
    this.setState({ showLocationListModal: !this.state.showLocationListModal });
  };

  submitBusinessLocation = (data) => {
    const {
      match: {
        params: { branch_id },
      },
    } = this.props;
    const payload = {
      location_id: data.location_id,
      review_link: data.review_link,
    };
    axios_instance
      .post(
        `${URLS.SOCIAL}${data.id}/branch/${branch_id}/${"google"}/link/`,
        payload
      )
      .then(() => {
        this.checkLoggedIn(data.provider);
        this.setState({
          showLocationListModal: false,
          reviewDetail: {},
          message: "Social Account successfully added.",
          messageType: "success",
          showMessage: true,
        });
      });
  };

  branchTagDataPost = async (data) => {
    await axios_instance.post(URLS.BRANCH_TAG, data).then((res) => {
      if (res.data.success === true) {
        let latest_tags = this.state.branchTagList;
        latest_tags.push(res.data.results);
        this.setState({ branchTagList: latest_tags });
      } else {
        alert("tag already exist");
      }
    });
  };

  getTitle = () => {
    switch (this.state.activeTab) {
      case "1":
        return "Manage your Business Profile";
      case "2":
        return "Review Links";
      case "3":
        return "";
      case "4":
        return "Branch Competitors";
      default:
        return "";
    }
  };

  render() {
    let {
      match: {
        params: { branch_id },
      },
      business: { detail },
      competitorOptions,
      competitorBranchDetails,
      competitorLinksDetails,
      competitorSourceOptions,
    } = this.props;
    const {
      isAddCompetitorOpen,
      isLimitModalOpen,
      limitsMessage,
      isAddCompetitorLinkOpen,
      editLinksForm,
      initialCompetitorBranch,
      compInitialValues,
      locks,
    } = this.state;

    const competitorLinksKey =
      competitorLinksDetails &&
      Object.entries(competitorLinksDetails).map((key) => key[0]);
    const competitorLinksKeyObject = {};
    const comp_location = initialCompetitorBranch;
    competitorLinksKey &&
      competitorLinksKey.map((value) => {
        competitorLinksKeyObject[value] =
          competitorLinksDetails[value] &&
          competitorLinksDetails[value].link &&
          competitorLinksDetails[value].link;
      });
    const links =
      editLinksForm || (!editLinksForm && initialCompetitorBranch)
        ? { ...competitorLinksKeyObject }
        : {};

    const initialValues = { comp_location, ...links };
    const { location_address, competition_id, ...rest } =
      competitorBranchDetails ? competitorBranchDetails : {};
    const compBranchInitialValues = {
      brand_name: competitorBranchDetails && competition_id,
      ...rest,
      branch_name: competitorBranchDetails && location_address,
    };

    const activeUser = cookie.load("business_user");
    const bookingDetails =
      this.props.socialLinks &&
      this.props.socialLinks.find((item) => {
        return item.web_portal.provider === "booking";
      });
    const toShowBooking =
      bookingDetails &&
      (bookingDetails.web_portal.source_type.includes("api") &&
      bookingDetails.web_portal.is_auth_required === true
        ? true
        : false);

    return (
      <div>
        <Row className="content-area">
          <LoaderComponent loader={this.props.loader} />
          <Col xs={12} sm={12} className="business-setup p-3">
          <BranchRootHeader defaultValue={1} history={this.props.history} />
            {!this.props.location?.state?.isHideTabs && (
              <Row className="m-0">
                <Col xs={12} sm={12} className="p-0">
                  <Nav className="Central_nav">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggleTab("1");
                        }}
                      >
                        Business Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggleTab("2");
                        }}
                      >
                        Review Links
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "3",
                        })}
                        onClick={() => {
                          this.toggleTab("3");
                        }}
                      >
                        Social Accounts
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "4",
                        })}
                        onClick={() => {
                          this.toggleTab("4");
                        }}
                      >
                        Branch Competitors
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            )}

            {!locks[this.state.activeTab] ? (
              <TabContent activeTab={this.state.activeTab}>
                <div>
                  {this.state.activeTab === "3" && (
                    <Nav
                      className="Setup_Sub_Nav pt-4"
                      style={{ fontSize: "1rem" }}
                    >
                      <NavItem>
                        <NavLink
                          active={this.state.activeTabSocial === "ggl"}
                          onClick={() => {
                            this.toggleTabSocial("ggl");
                          }}
                        >
                          Google
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={this.state.activeTabSocial === "fb"}
                          onClick={() => {
                            this.toggleTabSocial("fb");
                          }}
                        >
                          Facebook
                        </NavLink>
                      </NavItem>
                      
                      {detail &&
                        toShowBooking &&
                        detail.category === "Hotel" && (
                          <NavItem>
                            <NavLink
                              active={this.state.activeTabSocial === "bkng"}
                              onClick={() => {
                                this.toggleTabSocial("bkng");
                              }}
                            >
                              Booking
                            </NavLink>
                          </NavItem>
                        )}
                    </Nav>
                  )}
                </div>

                <div className="mt-3 mb-4">
                  <div className="mb-3">
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "400",
                        color: "rgba(100, 101, 101, 1)",
                      }}
                    >
                      {this.getTitle()}
                    </span>
                  </div>
                  <div className="selectLocation">
                    <div className="col-6">
                      <FPLabel style={{ color: "#00B5AD", fontSize: "15px" }}>
                        Select Location
                      </FPLabel>
                      <BranchSelector
                        rerenderParentCallback={this.rerenderParentCallback}
                        blankField={true}
                      />
                    </div>
                  </div>
                </div>

                <TabPane tabId="1">
                  <BusinessSetup
                    isEditable={this.state.isFormEditable}
                    toggleState={this.toggleState}
                    handleBusinessSetup={this.handleBusinessSetup}
                    submitSocialLinks={this.submitSocialLinks}
                    errors={this.props.errors}
                    isBusinessOwner={activeUser.is_business_owner}
                    business_id={detail && detail.id}
                    currentBranch={this.state.currentBranch}
                    branchTagList={this.state.branchTagList}
                    branchTagDataPost={this.branchTagDataPost}
                    rerenderParentCallback={this.rerenderParentCallback}
                    blankField={true}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <BusinessReviewsSetup
                    socialLinks={this.props.socialLinks}
                    submitSocialLinks={this.submitSocialLinks}
                    rerenderParentCallback={this.rerenderParentCallback}
                    blankField={true}
                  />
                </TabPane>
                <TabPane tabId="3">
                  <FormModal
                    isOpen={this.state.showLocationListModal}
                    toggle={this.toggleLocationListModal}
                    heading="Select any location"
                  >
                    {this.businessLocationList()}
                  </FormModal>

                  <div className="">
                    <CardWrapper showDivider={false} className="p-5">
                      <TabContent activeTab={this.state.activeTabSocial}>
                        <TabPane tabId="fb">
                          {this.state.activeTabSocial === "fb" && (
                            <SetupFBSocialAccounts
                              branch_id={branch_id}
                              postCode={this.postCode}
                              isLoggedIn={this.props.isLoggedIn}
                              checkLoggedIn={this.checkLoggedIn}
                              logOut={this.logOut}
                            />
                          )}
                        </TabPane>
                        <TabPane tabId="ggl">
                          {this.state.activeTabSocial === "ggl" && (
                            <SetupGGLSocialAccounts
                              branch_id={branch_id}
                              getAuthUrl={this.getAuthUrl}
                              postCode={this.postCode}
                              isLoggedIn={this.props.isLoggedIn}
                              checkLoggedIn={this.checkLoggedIn}
                              // hideTab={this.props.location.state && this.props.location.state.isHideTabs}
                            />
                          )}
                        </TabPane>
                        {detail &&
                          toShowBooking &&
                          detail.category === "Hotel" && (
                            <TabPane tabId="bkng">
                              {this.state.activeTabSocial === "bkng" && (
                                <SetupBKNGSocialAccounts
                                  branch_id={branch_id}
                                  getAuthUrl={this.getAuthUrl}
                                  postCode={this.postCode}
                                  isLoggedIn={this.props.isLoggedIn}
                                  checkLoggedIn={this.checkLoggedIn}
                                  logOut={this.logOut}
                                />
                              )}
                            </TabPane>
                          )}
                      </TabContent>
                    </CardWrapper>
                  </div>
                  {this.state.showMessage && (
                    <Card className="mt-1">
                      <div>
                        <div
                          className={
                            this.state.messageType === "success"
                              ? "ui success message"
                              : "ui negative message"
                          }
                        >
                          <i
                            className="close icon"
                            onClick={() => this.toggleMessageBox()}
                          ></i>
                          <div className="header">
                            {this.state.messageType === "success"
                              ? "Success!"
                              : "Error!"}
                          </div>
                          <p>{this.state.message}</p>
                        </div>
                      </div>
                    </Card>
                  )}
                </TabPane>
                <TabPane tabId="4">
                  <BranchCompetitors
                    addBranchCompetitors={this.addBranchCompetitors}
                    addCompetitorsLinks={this.addCompetitorsLinks}
                    branchCompetitors={
                      this.props.competitorsBranchList &&
                      this.props.competitorsBranchList
                    }
                    deleteBranch={this.deleteBranch}
                    editLinks={this.editCompetitorLinks}
                    toggle={this.toggleBranchComp}
                    rerenderParentCallback={this.rerenderParentCallback}
                    blankField={true}
                    location={this.props.location}
                  />
                </TabPane>
              </TabContent>
            ) : (
              <LockedComponent message={locks[this.state.activeTab]} hideDim />
            )}
          </Col>
          {/* <Col xs={12} sm={3} className="p-2 pt-3">
            <BranchSelector rerenderParentCallback={this.rerenderParentCallback} blankField={true} />
          </Col> */}
        </Row>
          <FormModal
            isOpen={isAddCompetitorOpen}
            toggle={this.toggleBranchComp}
            size="lg"
            heading={
              compInitialValues === "notNull"
                ? "Edit Competitor Branch "
                : "Add Competitors to your Selected Branch"
            }
            width={"700px"}
            headerClass="d-flex align-items-center"
          >
            <BranchCompetitorsForm
              competitorOptions={competitorOptions && competitorOptions}
              editBranchCompetitor={this.editBranchCompetitor}
              //toggle={this.toggleBranchComp}
              toggleLimitModal={this.toggleBranchLimitModal}
              initialValues={
                compInitialValues === "notNull" ? compBranchInitialValues : null
              }
              handleBranchCompetitors={this.handleBranchCompetitors}
              hideLogo
            />
          </FormModal>
          <FormModal
            isOpen={isAddCompetitorLinkOpen}
            toggle={this.toggleCompetitorsLinks}
            size="md"
            heading={"Add Competitor Links"}
            width={"700px"}
            headerClass="d-flex align-items-center"
          >
            <CompetitorsLinkForm
              match={this.props.match}
              postCompLinks={this.postCompLinks}
              initialValues={initialValues && initialValues}
              getCompLinks={this.getCompLinks}
              competitorSourceOptions={competitorSourceOptions}
            />
          </FormModal>
        {isLimitModalOpen && (
          <div>
            <LimitModal
              isOpen={isLimitModalOpen && isLimitModalOpen}
              toggle={this.toggleBranchLimitModal}
              message={limitsMessage}
            />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    business: state.business,
    errors: state.business.errors,
    socialSetupList: state.socialSetup.list,
    isLoggedIn: state.socialSetup.isLoggedIn,
    socialLinks: state.business.social_links,
    loader: state.common.loader,
    competitorOptions: state.competitors.competitor_options,
    competitorLinksDetails: state.competitors.competitor_links,
    competitorBranchDetails: state.competitors.competitors_branch_details,
    competitorsBranchList: state.competitors.competitors_branch_list,
    competitorSourceOptions: state.competitors.competitor_source_options,
  };
};

BusinessProfilePage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  CommonActions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  SocialSetupActions: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch),
    compActions: bindActionCreators(CompetitorActions, dispatch),
    CommonActions: bindActionCreators(CommonActions, dispatch),
    SocialSetupActions: bindActionCreators(SocialSetupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(BusinessProfilePage);
