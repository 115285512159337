import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import * as commonAction from "../common/commonAction";

export function getTicketEscalationSuccess(data) {
  return { type: types.GET_TICKET_ESCALATION_SUCCESS, data };
}

export function getTicketEscalationFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_TICKET_ESCALATION_FAILURE, data };
}

export const getTicketEscalation = (businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${utils.format(URLS.GET_TICKET_ESCALATION, [businessId])}`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getTicketEscalationSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getTicketEscalationFailure(err));
        throw err.response.data;
      });
  };
};

export function ticketEscalationAddSuccess(data) {
  return { type: types.CREATE_TICKET_ESCALATION_SUCCESS, data };
}

export function ticketEscalationAddFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.CREATE_TICKET_ESCALATION_FAILURE, data };
}

export const ticketEscalationAdd = (data, businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(utils.format(URLS.GET_TICKET_ESCALATION, [businessId]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketEscalationAddSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketEscalationAddFailure(err));
        throw err.response.data;
      });
  };
};

export function ticketEscalationEditSuccess(data) {
  return { type: types.EDIT_TICKET_ESCALATION_SUCCESS, data };
}

export function ticketEscalationEditFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.EDIT_TICKET_ESCALATION_FAILURE, data };
}

export const ticketEscalationEdit = (data, businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .put(utils.format(URLS.TICKET_ESCALATION, [businessId, data.id]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketEscalationEditSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketEscalationEditFailure(err));
        throw err.response.data;
      });
  };
};

export function ticketEscalationDeleteSuccess(data) {
  return { type: types.DELETE_TICKET_ESCALATION_SUCCESS, data };
}

export function ticketEscalationDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.DELETE_TICKET_ESCALATION_FAILURE, data };
}

export const ticketEscalationDelete = (data, businessId) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance
      .delete(utils.format(URLS.TICKET_ESCALATION, [businessId, data.id]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketEscalationDeleteSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketEscalationDeleteFailure(err));
        throw err.response.data;
      });
  };
};
