import React, { useState } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { ListDescription } from "semantic-ui-react";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import "./listing.css";
import google_svg from "../../../images/googlephoto.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getGmbListingUpdateListing } from "../../../actions/listing/listingLocationAction";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import * as listingUtils from "../../../libs/listingToast";

const UpdateBusinessStatus = ({ primaryDetail, business_id, selectReso, getGmbListingUpdateListing, actions }) => {
  const [businessStatus, setBusinessStatus] = useState("OPEN");

  React.useEffect(() => {
    if (primaryDetail?.openInfo_status) {
      setBusinessStatus(primaryDetail?.openInfo_status);
    } else {
      setBusinessStatus("OPEN");
    }
  }, [primaryDetail?.openInfo_status]);

  const updateBusinessHandler = (e) => {
    const { value } = e.target;
    setBusinessStatus(value);
  };
  const handleSaveClick = async () => {
    const data = {
      type: "openInfo",
      openInfoStatus: businessStatus,
    };
    const restodetail = {
      business_id,
      selectReso,
    };
    await getGmbListingUpdateListing(data, restodetail);
    listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
    await actions.getGmbListingRefreshListing(business_id, selectReso);

    listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    listingUtils?.displayMessage("positive", "Listing updated successfully.");
    await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };
  return (
    <>
      <div className="listing-container" style={{ overflow: "hidden", gap: "15px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <div className="listing-header">
            <div className="listing-info">
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="listing-name">{primaryDetail?.title}</div>
                <img src={verified_svg} alt="verifiedImage" />
              </div>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(2)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>
                  {/* {formatReviews(
                  location_primary_detail?.results?.[0]?.totalReviewCount
                )}{" "} */}
                  {primaryDetail?.totalReviewCount} Reviews
                </span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>
              {primaryDetail?.addressLines.join(", ")} {primaryDetail?.locality} {primaryDetail?.postalCode}
            </ListDescription>
          </div>
        </div>
      </div>
      <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "calc(100% - 120px)",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "15px",
                paddingBottom: "5px",
              }}
            >
              <div className="listing-name">Specify your Business Status</div>
              <div
                style={{
                  padding: "16px",
                  backgroundColor: "#F6F6F8",
                  borderRadius: "8px",
                }}
              >
                <form action="/action_page.php">
                  <div style={{ display: "grid", gap: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#646565",
                      }}
                    >
                      <label for="OPEN" style={{ cursor: "pointer", width: "100%" }}>
                        Open with Main Hours
                      </label>
                      <input
                        style={{ color: "#F13A88" }}
                        type="radio"
                        id="OPEN"
                        name="bussinessStatus"
                        value="OPEN"
                        checked={businessStatus === "OPEN"}
                        onChange={updateBusinessHandler}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#646565",
                      }}
                    >
                      <label for="CLOSED_TEMPORARILY" style={{ cursor: "pointer", width: "100%" }}>
                        Temporarily Closed
                      </label>
                      <input
                        style={{ accentColor: "#F13A88" }}
                        type="radio"
                        id="CLOSED_TEMPORARILY"
                        name="bussinessStatus"
                        value="CLOSED_TEMPORARILY"
                        checked={businessStatus === "CLOSED_TEMPORARILY"}
                        onChange={updateBusinessHandler}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#646565",
                      }}
                    >
                      <label for="CLOSED_PERMANENTLY" style={{ cursor: "pointer", width: "100%" }}>
                        Permanently Closed
                      </label>
                      <input
                        style={{ color: "#F13A88" }}
                        type="radio"
                        id="CLOSED_PERMANENTLY"
                        name="bussinessStatus"
                        value="CLOSED_PERMANENTLY"
                        checked={businessStatus === "CLOSED_PERMANENTLY"}
                        onChange={updateBusinessHandler}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                // paddingBottom: "10px",
                // paddingTop: "10px",
                paddingBlock: "15px",
              }}
            >
              <FPButton label="Update" size="small" disabled={primaryDetail?.is_suspend} onClick={handleSaveClick} />
            </div>
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

// export default UpdateBusinessStatus;
const mapDispatchToProps = (dispatch) => ({
  getGmbListingUpdateListing: bindActionCreators(getGmbListingUpdateListing, dispatch),
  actions: bindActionCreators(locationActions, dispatch),
});

// const mapDispatchToProps = (dispatch) => ({
//   getGmbListingUpdateListing: bindActionCreators(
//     getGmbListingUpdateListing,
//     dispatch
//   ),
//   actions: bindActionCreators(locationActions, dispatch),
// });

export default connect(null, mapDispatchToProps)(UpdateBusinessStatus);
