import React, { useEffect } from "react";
import { FPFilter, FPToolTip } from "../../common/new";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import * as filterListActions from "../../../actions/common/filterListActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as URLS from "../../../libs/apiUrls";
import { getAllParamsInArrayForm } from "../../../libs/utils";
import ListingFPFilter from "../../common/new/FPFilter/Listing/ListingFPFilter";
import { IconButton } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../../images/svg/Download.svg";
import * as locationActions from "../../../actions/listing/listingLocationAction";

const ListingFilter = (props) => {
  const { history, pageSizeCookie, filterAction, filter } = props;
  const fetchFilterList = async () => {
    try {
      await Promise.all([
        filterAction.getDateTypeAccount(
          "",
          `${URLS.GMB_LISTING_LOCATION}/${props?.business_id}/accounts/`
        ),
        filterAction.getBranch(
          "",
          `${URLS.BUSINESS_SETUP}${props?.business_id}/branch/?is_gmb_locations=true`
        ),
      ]);
    } catch (error) {
      console.error("Error in fetching filter list:", error);
    }
  };

  useEffect(() => {
    fetchFilterList();
  }, []);

  const handleDefaultFilter = (list, key) => {
    const filter = props.location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable =
      defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return (list || []).map((item, index) => {
      if (isKeyFilterAvailable)
        item["isChecked"] =
          isKeyFilterAvailable &&
          defaultFilter[key].includes(item.value.toString());
      // else if (index === 0 && key === "date_type") item["isChecked"] = true;
      else item["isChecked"] = false;
      item["parentKey"] = key;

      return item;
    });
  };

  const formattedAccountData = (filter?.account_date_type?.results || []).map(
    (item) => ({
      value: item.id,
      display_name: item.account_name,
      ...item,
    })
  );

  const filterList = [
    {
      id: 1,
      label: "GMB Location Group",
      key: "gmb_location_type",
      type: "radio",
      isSearchable: false,
      list: handleDefaultFilter(formattedAccountData, "gmb_location_type"),
    },
    {
      id: "2",
      label: "Location",
      key: "branch",
      type: "radio",
      isSearchable: false,
      list:
        filter.branch && handleDefaultFilter(filter.branch.choices, "branch"),
    },
    {
      id: 2,
      label: "GMB Status",
      key: "status_type",
      type: "radio",
      isSearchable: false,
      list: handleDefaultFilter(
        [
          { value: "verified", display_name: "Verified" },
          { value: "suspended", display_name: "Suspended" },
          { value: "duplicate", display_name: "Duplicate" },
        ],
        "status_type"
      ),
    },
    {
      id: 3,
      label: "Completion Status",
      key: "completion_status",
      type: "radio",
      isSearchable: false,
      list: handleDefaultFilter(
        [
          { value: "very_low", display_name: "Very Low" },
          { value: "low", display_name: "Low" },
          { value: "moderate", display_name: "Moderate" },
          { value: "high", display_name: "High" },
          { value: "very_high", display_name: "Very High" },
        ],
        "completion_status"
      ),
    },
    {
      id: 4,
      label: "Open Status",
      key: "openstatus",
      type: "radio",
      isSearchable: false,
      list: handleDefaultFilter(
        [
          { value: "OPEN", display_name: "Open" },
          { value: "CLOSED_PERMANENTLY", display_name: "Closed Permanently" },
          { value: "CLOSED_TEMPORARILY", display_name: "Closed Temporarily" },
        ],
        "openstatus"
      ),
    },
  ].filter((item) => !!item);

  const handleExportListingDetail = () => {
    props.actions.getGmbListingLoaction(props.business_id, "", "", "", "","","","","","","","",true); // Replace 'yourProvider' with the actual provider
  }
  
  return (
    // <ListingFPFilter
    //   data={filterList}
    //   className="mt-1"
    //   history={history}
    //   // pageSizeCookie={pageSizeCookie}
    //   ticketFilter="OVERVIEW_FILTER"
    //   applyBtnText={"Apply"}
    //   lightResetBtn={true} // customOnApply={(value) => {
    //   //   handleSubmit(value);
    //   // }}
    // />
    <React.Fragment>
      <FPFilter
        data={filterList}
        className="mt-3"
        pageSizeCookie={pageSizeCookie}
        history={history}
        // getData={getData}
        // match={match}
        actionRender={() => (
          <FPToolTip title={"Download"}>
            <IconButton component="span" className="circular-button">
              <DownloadIcon onClick={() => handleExportListingDetail()}/>
            </IconButton>
          </FPToolTip>
        )}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    filter: state.filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    filterAction: bindActionCreators(filterListActions, dispatch),
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ListingFilter);
