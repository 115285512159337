import React from "react";
import LoaderComponent from "../../components/common/loaderComponent";
import { Link } from "react-router-dom";
import * as agencyActions from "../../actions/agency/agencyActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PaginationComponent from "../../components/common/Pagination";
import cookie from "react-cookies";
import queryString from "query-string";
import { FPButton, FPToolTip } from "../../components/common/new";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Divider } from "semantic-ui-react";
import PropTypes from "prop-types";
import FormModal from "../../components/common/FormModal";
import TeamMappingModal from "../../components/Agency/TeamMappingModal";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";

class AgencyTeamMapping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agency_id: this.props.match.params.agency_id,
      mappingLoading: false,
      isTeamMappingOpen: false,
      mappingData: null,
    };
    this.addTeamMapping = this.addTeamMapping.bind(this);
    this.getMappingData = this.getMappingData.bind(this);
    this.editTeamMapping = this.editTeamMapping.bind(this);
    const pageSize = cookie.load("pageSize") || {};
    const {
      history,
      location: { pathname, search },
    } = props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        page_size: pageSize.agency_staff_mapping || 10,
      }),
    });
  }

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    this.getMappingData(this.state.agency_id, search);
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-line
    const {
      location: { search: prevSearch },
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;
    if (prevSearch !== nextSearch) {
      this.getMappingData(this.state.agency_id, nextSearch);
    }
  }

  getMappingData(agency_id, query_search) {
    if (query_search) {
      this.setState({ mappingLoading: true });
      this.props.actions
        .agencyMappingList(agency_id, query_search)
        .then(() => this.setState({ mappingLoading: false }));
    }
  }

  addTeamMapping() {
    this.setState({ mappingData: null, isTeamMappingOpen: !this.state.isTeamMappingOpen });
  }

  editTeamMapping(item) {
    this.setState({ mappingData: item, isTeamMappingOpen: !this.state.isTeamMappingOpen });
  }

  // addTeamMapping() {
  //   const {
  //     history,
  //     match: {
  //       params: { agency_id },
  //     },
  //   } = this.props;
  //   history.push(`/${agency_id}/add-team-mapping`);
  // }

  render() {
    const { mappingListResults } = this.props;
    const { isTeamMappingOpen, mappingData } = this.state;

    return (
      <div>
        <LoaderComponent loader={this.state.mappingLoading} />
        {/* <Col xs={12} className="p-0">
            <NavBar match={this.props.match} history={this.props.history} />
          </Col> */}
        <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
          <span
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "rgba(100, 101, 101, 1)",
            }}
          >
            Team Mapping
          </span>
          <div>
            <FPButton
              label="Add Team Mapping"
              type="button"
              onClick={this.addTeamMapping}
              // onClick={this.toggleTeamMapping}
              style={{ height: "35px" }}
              endIcon={<AddIcon style={{ height: 16, width: 16, color: "white" }} />}
            />
          </div>
        </div>
        <Divider />
        <div className="ui-table-responsive">
          <Table className="fp-table fp-table-center">
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell>Business</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Locations</TableCell>
                <TableCell>Assigned Team Members</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mappingListResults && mappingListResults.results.length > 0 ? (
                mappingListResults.results.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <FPToolTip title={item.business.name}>
                          <span
                            onClick={() => this.editTeamMapping(item)}
                            className="link-text font-dark d-inline-block text-truncate width-sm"
                          >
                            {item.business.name}
                          </span>
                        </FPToolTip>
                      </TableCell>
                      <TableCell
                        style={{
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <FPToolTip title={item.name}>
                          <span className="d-inline-block text-truncate width-sm">{item.name}</span>
                        </FPToolTip>
                      </TableCell>
                      <TableCell
                        style={{
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <FPToolTip title={item.description}>
                          <span className="d-inline-block text-truncate width-sm">{item.description}</span>
                        </FPToolTip>
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: "350px",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <div className="d-flex align-items-center gap-6 flex-wrap justify-content-center">
                          {item.branch.slice(0, 2).map((a) => (
                            <FPToolTip title={a.alias ? a.alias : a.location_address}>
                              <Box
                                sx={{
                                  border: "1px solid #00B5AD",
                                  backgroundColor: "#DDEFF0",
                                  padding: "4px 14px",
                                  borderRadius: 99,
                                }}
                                className="d-inline-block text-truncate width-sm"
                              >
                                {a.alias ? a.alias : a.location_address}
                              </Box>
                            </FPToolTip>
                          ))}
                          {item.branch.length > 2 && (
                            <Box
                              sx={{
                                backgroundColor: "#EAECF0",
                                color: "#1B1C1D",
                                padding: "4px 6px",
                                borderRadius: 99,
                              }}
                            >
                              +{item.branch.length - 2}
                            </Box>
                          )}
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          minWidth: "350px",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        <div className="d-flex align-items-center gap-6 flex-wrap justify-content-center">
                          {item.staff.slice(0, 2).map((a) => (
                            <FPToolTip title={a.first_name + " " + a.last_name}>
                              <Box
                                sx={{
                                  border: "1px solid #00B5AD",
                                  backgroundColor: "#DDEFF0",
                                  padding: "4px 14px",
                                  borderRadius: 99,
                                }}
                                className="d-inline-block text-truncate width-sm"
                              >
                                {a.first_name + " " + a.last_name}
                              </Box>
                            </FPToolTip>
                          ))}
                          {item.staff.length > 2 && (
                            <Box
                              sx={{
                                backgroundColor: "#EAECF0",
                                color: "#1B1C1D",
                                padding: "4px 6px",
                                borderRadius: 99,
                              }}
                            >
                              +{item.staff.length - 2}
                            </Box>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell className="text-center" colSpan={10}>
                    No records Found{" "}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {mappingListResults && mappingListResults.results.length > 0 && (
          <PaginationComponent
            count={mappingListResults && mappingListResults.count}
            location={this.props.location}
            pageSizeCookie="agency_staff_mapping"
          />
        )}
        {isTeamMappingOpen && (
          <FormModal
            isOpen={isTeamMappingOpen}
            toggle={() => this.setState({ isTeamMappingOpen: false })}
            heading={mappingData ? "Edit Team" : "Add Team"}
            width={"800px"}
          >
            <TeamMappingModal
              agency_id={this.state.agency_id}
              location={this.props.location}
              onSuccess={() => {
                this.setState({ isTeamMappingOpen: false });
                this.getMappingData(this.state.agency_id, this.props.location.search);
              }}
              mappingData={mappingData}
            />
          </FormModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mappingListResults: state.agency.mapping_list,
  };
};
AgencyTeamMapping.propTypes = {
  actions: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(agencyActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AgencyTeamMapping);
