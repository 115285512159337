/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import Flatpickr from "react-flatpickr";
import { bindActionCreators } from "redux";
import * as OverviewActions from "../../../actions/overview/OverviewActions";
import moment from "moment";
import { getParams, removeLifetimeFilter } from "../../../libs/utils";
import classNames from "classnames";

const DateSelector = ({ onChange, className, mainClass, filter, isForLifeTimeSupported = true, dropDownClassName }) => {
  const { date_type: filters } = filter;
  const [dateOption, setDateOption] = useState(filters);

  useEffect(() => {
    if (isForLifeTimeSupported) {
      setDateOption(() => filters);
    } else {
      const newFilter =
        filters && filters.choices
          ? {
              default: filters.default,
              choices: filters.choices.filter((item) => item.value),
            }
          : null;
      setDateOption(() => newFilter);
    }
  }, [filters]);

  const setDateFormate = (date, format) => moment(date).format(format || "YYYY-MM-DD");
  const dateType = getParams(location.search, "date_type");
  const startDate = getParams(location.search, "start_date");
  const endDate = getParams(location.search, "end_date");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDate, setSelected] = useState("");
  const [dateRange, setDateRange] = useState({
    start_date: setDateFormate(startDate || new Date()),
    end_date: setDateFormate(endDate || new Date()),
  });

  const dateFilterRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const setRange = (startDate, endDate) => {
    return `${setDateFormate(startDate, "DD/MM/YYYY")} to ${setDateFormate(endDate, "DD/MM/YYYY")}`;
  };

  useEffect(() => {
    if (dateType === "other" && startDate && endDate) {
      const range = setRange(startDate, endDate);
      setSelected(range);
    } else if (!dateType) {
      setSelected("Lifetime");
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dateType !== "other") {
      const defaultDate = dateOption ? dateOption.choices?.filter((item) => item.value === dateType) : [];
      if (defaultDate?.length && defaultDate[0].value === "" && !isForLifeTimeSupported) {
        setSelected("Past 30 days");
      } else {
        defaultDate?.length && setSelected(defaultDate[0].display_name);
      }
    }
  }, [dateOption]);

  const handleClickOutside = (event) => {
    if (
      dateFilterRef?.current &&
      startDateRef?.current &&
      endDateRef?.current &&
      !dateFilterRef?.current?.contains(event.target) &&
      !startDateRef?.current?.flatpickr?.calendarContainer?.contains(event.target) &&
      !endDateRef?.current?.flatpickr?.calendarContainer?.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
  };

  const handleChange = (filterItem) => {
    setSelected(filterItem.display_name);
    onChange && onChange({ date_type: filterItem.value });
  };

  const handleDataRange = (value, type) => {
    const formattedValue = setDateFormate(value);
    if (type === "start_date") {
      setDateRange((preValue) => {
        return { ...preValue, start_date: formattedValue };
      });
    } else {
      setDateRange((preValue) => {
        return { ...preValue, end_date: formattedValue };
      });
      setTimeout(() => {
        setDropdownOpen(false);
        const range = setRange(dateRange.start_date, formattedValue);
        onChange &&
          onChange({
            date_type: "other",
            start_date: dateRange.start_date,
            end_date: formattedValue,
          });
        setSelected(range);
      }, 100);
    }
  };

  return (
    <div ref={dateFilterRef} className={classNames("date-select", mainClass)}>
      <div
        role="listbox"
        aria-expanded={dropdownOpen}
        className={`ui dropdown select-range ${(dropdownOpen && "active", className && className)}`}
        tabIndex="0"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <span style={selectedDate ? { opacity: 1 } : { opacity: 0.4 }} className="fp-flex-grow-1">
          {selectedDate ? selectedDate : "Select Date"}
        </span>
        <i aria-hidden="true" className="dropdown icon"></i>
        <Dropdown.Menu
          className={classNames("scroll50Y full-width", dropDownClassName)}
          open={dropdownOpen}
          style={{ paddingTop: "10px" }}
        >
          <div
            className="other-date"
            onClick={(e) => e.stopPropagation()}
            style={{ margin: "auto", display: "initial", marginTop: "100px" }}
          >
            <Flatpickr
              ref={(node) => (startDateRef.current = node)}
              className="input-width fp-date-range"
              defaultValue={dateRange.start_date}
              value={dateRange.start_date}
              onChange={([date]) => handleDataRange(date, "start_date")}
            />
            <span style={{ lineHeight: "36px" }} className="to-text">
              To
            </span>
            <Flatpickr
              ref={(node) => (endDateRef.current = node)}
              className="input-width fp-date-range"
              defaultValue={dateRange.end_date}
              value={dateRange.end_date}
              onChange={([date]) => handleDataRange(date, "end_date")}
              options={{
                minDate: setDateFormate(dateRange.start_date),
              }}
            />
          </div>
          {dateOption &&
            dateOption.choices &&
            removeLifetimeFilter(dateOption.choices).map((item, key) => {
              return (
                <Dropdown.Item
                  className="text-center"
                  key={key}
                  text={item.display_name}
                  onClick={() => handleChange(item)}
                />
              );
            })}
        </Dropdown.Menu>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    overview: state.overview,
    filter: state.filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    OverviewActions: bindActionCreators(OverviewActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(DateSelector);
