import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as commonAction from "../common/commonAction";
import * as URLS from "../../libs/apiUrls";

export function getAuthUrlSuccess(data) {
  return { type: types.GET_AUTH_LOGIN_URL_SUCCESS, data };
}

export function getAuthUrlFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_AUTH_LOGIN_URL_FAILURE, data };
}

export const getGmailLoginUrl = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    // return axios_instance
      // .get(`${URLS.GMB_SOCIAL}${data.provider}/auth_url/`)
      return axios_instance.get(`${URLS.SOCIAL}${data.provider}/auth_url/`)

      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getAuthUrlSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getAuthUrlFailure(err));
      });
  };
};
