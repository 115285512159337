/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import cookie from "react-cookies";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import * as ProfileActions from "../../actions/accounts/profileAction";
import * as commonAction from "../../actions/common/commonAction";
import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import PaginationComponent from "../../components/common/Pagination";
import ReviewsList from "../../components/business/ReviewsList";
import UploadReviewModal from "../../components/business/UploadReviewModal";
import FormModal from "../../components/common/FormModal";
import LoaderComponent from "../../components/common/loaderComponent";
import LimitModal from "../../components/common/LimitModal";
import ReviewsFrom from "../../components/business/ReviewsForm";
import TagEditModal from "../../components/common/TagEditModal";
import TagAddForm from "../../components/mentions/TagAddForm";
import ManageTags from "../../components/business/ManageTags";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import * as TeamAction from "../../actions/team/teamAction";
import * as ticketActions from "../../actions/ticket/ticketActions";

import TicketingReviewsModal from "../../components/tickets/TicketingReviewModal";
import axios_instance from "../../libs/interseptor";
import { ReviewActionBar, ReviewsFilter } from "../../modules/review";
import { TagManageModalWrapper } from "../../components/common/new";
import { Box } from "@material-ui/core";

class ReviewsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      isTagEditOpen: false,
      tagDetail: {},
      isConfirmOpen: false,
      isManageTagModalOpen: false,
      tagFilter: "",
      newSelectedReview: null,
      modalOf: "",
      updated: false,
      isCustomerReview: false,
      data: null,
      isOpen: false,
      isDownloadModalOpen: false,
      isFetching: false,
      downloadLoader: false,
      fameAiAnalysis: true,
      survey_pages:
        this.props.resolutionDetail &&
        this.props.resolutionDetail.results &&
        this.props.resolutionDetail.results.length > 0
          ? this.props.resolutionDetail.results[0].survey_pages_data
          : null,
      survey_page_length:
        this.props.resolutionDetail &&
        this.props.resolutionDetail.results &&
        this.props.resolutionDetail.results.length > 0
          ? this.props.resolutionDetail.results[0].survey_pages_data.length
          : null,
      ticketResolutionEnabled:
        this.props.businessInfo &&
        this.props.businessInfo.enable_ticket_resolution
          ? true
          : false,
      getSurveyResponse: false,
      surveySubmitError: "",
      surveyPageQuestion: [],
      reviewDownloadRequestExist: false,
      surveyFormLoading: false,
      productIssueReasonsMappingEnabled:
        this.props.businessInfo &&
        this.props.businessInfo.enable_product_issue_reasons_mapping_questions
          ? true
          : false,
      issueReasonError: "",
      productMappingSubmitted: false,
      disableTicketCommentBox:
        this.props.businessInfo &&
        this.props.businessInfo.disable_comment_section_in_ticket_review_modal
          ? true
          : false,
      restrictTicketClosingOnSurvey:
        this.props.businessInfo &&
        this.props.businessInfo
          .restrict_ticket_closing_without_submitting_ticket_survey
          ? true
          : false,
    };
    this.saveComment = this.saveComment.bind(this);
    this.getData = this.getData.bind(this);

    this.toggleUploadReview = this.toggleUploadReview.bind(this);
    this.handleReplyOption = this.handleReplyOption.bind(this);
    this.toggleDownloadModal = this.toggleDownloadModal.bind(this);
    this.handleDownloadModal = this.handleDownloadModal.bind(this);
    this.handleTagFilter = this.handleTagFilter.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleAiAnalysis = this.handleAiAnalysis.bind(this);
    this.getSurveyResponse = this.getSurveyResponse.bind(this);
    this.finalSubmitSurveyQuestin = this.finalSubmitSurveyQuestin.bind(this);
    this.handleSurveyPageChange = this.handleSurveyPageChange.bind(this);
    this.getTagDetails = this.getTagDetails.bind(this);
    this.submitProductIssueReasonsMappingQuestions =
      this.submitProductIssueReasonsMappingQuestions.bind(this);
    //PageSize Handling
    const pageSize = cookie.load("pageSize") || {};
    const {
      history,
      location: { pathname, search },
    } = props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        page_size: pageSize.reviews_page || 10,
        date_type: "prior_30_day",
      }),
    });
  }
  componentDidMount() {
    // eslint-disable-line
    const {
      location: { search },
      match: {
        params: { business_id },
      },
      match: { params },
    } = this.props;
    this.setState((prevState) => ({
      ...prevState.jasper,
      isFetching: true,
    }));
    const newQuery = queryString.stringify({
      ...queryString.parse(search),
      date_type: "prior_30_day",
    });
    Promise.all([
      this.props.commonActions.replyOptions(),
      this.props.commonActions.rationsOptions(),
      this.props.commonActions.sentimentOptions(),
      this.props.commonActions.cityOptions(business_id),
      this.props.commonActions.stateOptions(business_id),
      this.props.commonActions.countryOptions(business_id),
      this.props.commonActions.branchTagOptions(business_id),
      this.props.actions.reviewSourceOptions({ business_id }),
      this.props.businessActions.getCategoryTagsList(business_id),
      this.props.businessActions.getProductNameList(business_id),
      this.props.businessActions.getTagsList({
        businessId: business_id,
        type: "review",
      }),
      this.props.teamActions.teamList(params, business_id),
      this.props.profileActions.getProfileInfo(),
      this.props.actions.sourceSystemOrderDetailSetting(business_id),
      this.props.actions.getTagOptions(business_id),
    ]);
    this.props.ticketActions.ticketResolution(business_id).then((res) => {
      if (res.data && res.data.results && res.data.results.length > 0) {
        this.setState({
          survey_pages: res.data.results[0].survey_pages_data,
          survey_current_page: 0,
          survey_page_length: res.data.results[0].survey_pages_data.length,
        });
      }
    });
    this.getData({ ...queryString.parse(newQuery), businessId: business_id })
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState.jasper,
          isFetching: false,
        }));
        return res;
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState.jasper,
          isFetching: false,
        }));
        console.log(err);
      });

    //Set Title
    document.title = "Reviews";
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-line
    const {
      location: { search: prevSearch },
    } = this.props;
    const {
      location: { search: nextSearch },
      match: { params: nextParams },
    } = nextProps;

    if (prevSearch !== nextSearch) {
      // start fetching
      this.setState((prevState) => ({
        ...prevState.jasper,
        isFetching: true,
      }));

      this.getData({
        ...queryString.parse(nextSearch),
        businessId: nextParams.business_id,
      })
        .then((res) => {
          this.setState((prevState) => ({
            ...prevState.jasper,
            isFetching: false,
          }));
          return res;
        })
        .catch((err) => {
          this.setState((prevState) => ({
            ...prevState.jasper,
            isFetching: false,
          }));
          console.log(err);
        });
    }
    if (this.props.resolutionDetail != nextProps.resolutionDetail) {
      if (
        nextProps.resolutionDetail &&
        nextProps.resolutionDetail.results &&
        nextProps.resolutionDetail.results.length > 0
      ) {
        this.setState({
          survey_pages: nextProps.resolutionDetail.results[0].survey_pages_data,
          survey_current_page: 0,
          survey_page_length:
            nextProps.resolutionDetail.results[0].survey_pages_data.length,
        });
      }
    }
    if (this.props.businessInfo != nextProps.businessInfo) {
      this.setState({
        ticketResolutionEnabled:
          nextProps.businessInfo &&
          nextProps.businessInfo.enable_ticket_resolution
            ? true
            : false,
        productIssueReasonsMappingEnabled:
          nextProps.businessInfo &&
          nextProps.businessInfo.enable_product_issue_reasons_mapping_questions
            ? true
            : false,
      });
    }
  }
  getData(data) {
    this.setState({ data: data });
    return this.props.actions.reviewsAction(data);
  }

  toggelCustomerReview = () => {
    this.setState({
      isCustomerReview: !this.state.isCustomerReview,
    });
  };

  toggleUploadReview(type) {
    this.setState({ isOpen: !this.state.isOpen, type });
  }

  handleFilter() {
    const {
      location: { search: search },
      match: { params: params },
    } = this.props;
    this.setState((prevState) => ({
      ...prevState.jasper,
      isFetching: true,
    }));

    this.getData({
      ...queryString.parse(search),
      businessId: params.business_id,
    })
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState.jasper,
          isFetching: false,
        }));
        return res;
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState.jasper,
          isFetching: false,
        }));
        console.log(err);
      });
  }

  saveComment(data, callBack) {
    const {
      location: { search: search },
      match: { params: params },
    } = this.props;
    const query = queryString.parse(search);
    query["businessId"] = params.business_id;
    this.setState({ isFetching: true });
    return this.props.actions
      .postComments(data)
      .then(() => {
        this.getData(query).then(() => this.setState({ isFetching: false }));
        callBack && callBack();
        // this.setState({isFetching: false}
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isFetching: false });
      });
  }

  downloadReviewTemplate() {
    const { type } = this.state;
    const url = URLS.REVIEW_TEMPLATE + `?action=${type}`;
    return this.props.actions.downloadTemplate(url, "review-format.csv");
  }

  handleReplyOption(status, id) {
    const data = { status: status };
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.actions
      .statusUpdate(id, data)
      .then(() => {
        this.getData({ ...queryString.parse(search), businessId: business_id });
      })
      .catch((err) => {
        utils.displayMessage("negative", err.status);
      });
  }

  toggleDownloadModal() {
    const { isDownloadModalOpen } = this.state;
    if (!isDownloadModalOpen) {
      let url = `${URLS.REVIEWS_DETAILS}open-review-download-request`;
      axios_instance.get(url).then((res) => {
        if (res.data && res.data.results.length > 0) {
          this.setState({ reviewDownloadRequestExist: true });
        }
      });
    }
    this.setState({ isDownloadModalOpen: !isDownloadModalOpen });
  }

  handleDownloadModal(downloadFlag) {
    this.setState(
      (prevState) => ({ downloadLoader: !prevState.downloadLoader }),
      () => {
        this.toggleDownloadModal();
  
        if (!this.state.reviewDownloadRequestExist) {
          const { data } = this.props;
          const {
            match: {
              params: { business_id },
            },
            location: { search },
          } = this.props;
  
          if (data.count < 1500) {
            if (downloadFlag) {
              const url = `${URLS.REVIEWS_DETAILS}${business_id}/review/download/${search}&celery_download=false`;
  
              this.props.actions
                .downloadTemplate(url, "famepilot-review-report.csv")
                .finally(() => {
                  this.setState({ downloadLoader: false });
                });
            } else {
              this.setState({ downloadLoader: false });
            }
          } else {
            if (downloadFlag) {
              const url = `${URLS.REVIEWS_DETAILS}${business_id}/review/download/${search}&celery_download=true`;
  
                axios_instance.get(url).finally(() => {
                  this.setState({ downloadLoader: false });
                });
            } else {
              this.setState({ downloadLoader: false });
            }
          }
        }
      }
    );
  }

  addReviewsFormData = (data) => {
    const {
      form_data: {
        detail: { business_uuid },
      },
    } = this.props;
    const snitizeFormData = utils.sanitizeObject(data);
    return this.props.actions
      .postReviewsForm(snitizeFormData, business_uuid)
      .then(() => {
        this.props.actions.reviewsAction(this.state.data);
        this.toggelCustomerReview();
      });
  };

  postTag = (tagData) => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const { object_id, tagMethod, tag_id, name, tag_name } = tagData;
    const data = { businessId: business_id, type: "review" };
    const query = queryString.parse(search);
    return this.props.businessActions
      .postTag({ ...data, object_id, tag_name, name, tag_id, tagMethod })
      .then((res) => {
        //eslint-disable-line
        this.props.businessActions.getTagsList(data);
        if (tagMethod) {
          this.getData({ ...query, businessId: business_id });
        }
        return res;
      });
  };

  handleTagFilter = (value) => {
    const {
      history,
      location: { pathname, search },
    } = this.props;
    const query = queryString.parse(search);
    const tagArray = query.tag && query.tag.split(",");
    delete query.page;
    if (tagArray && tagArray.includes(value)) {
      query.tag = tagArray.filter((item) => item !== value).join(",");
      !query.tag && delete query.tag;
    } else {
      query.tag = query.tag ? query.tag + `,${value}` : value;
    }
    history.push({ pathname, search: queryString.stringify({ ...query }) });
  };

  toggleConfirm = (functionToRun) => {
    this.setState({ isConfirmOpen: !this.state.isConfirmOpen, functionToRun });
  };

  toggleTagEditModal = (tag) => {
    const { isTagEditOpen } = this.state;
    if (tag) this.setState({ tagDetail: { ...tag }, isTagEditOpen: true });
    else this.setState({ isTagEditOpen: !isTagEditOpen, tagDetail: {} });
  };

  toggleManageTagModal = () => {
    this.setState({ isManageTagModalOpen: !this.state.isManageTagModalOpen });
  };

  addOrEditTag = (data) => {
    const {
      history,
      match: {
        params: { business_id },
      },
      location: { pathname, search },
    } = this.props;
    const {
      tagDetail,
      tagDetail: { id },
    } = this.state;
    const { name } = data;
    const query = queryString.parse(search);
    let functionToRun;
    let getMentions = true;
    const selectedTag = query.tag && query.tag.split(",");

    if (tagDetail.id) {
      if (selectedTag && selectedTag.includes(tagDetail.name)) {
        delete query.tag;
        getMentions = false;
        history.push({ pathname, search: queryString.stringify({ ...query }) });
      }
      functionToRun = this.props.businessActions.editTagName({
        businessId: business_id,
        type: "review",
        id,
        name,
      });
    } else
      functionToRun = this.props.businessActions.postTag({
        businessId: business_id,
        type: "review",
        name,
      });
    return functionToRun.then((res) => {
      //eslint-disable-line
      getMentions && this.getData({ ...query, businessId: business_id });
      this.props.businessActions
        .getTagsList({ businessId: business_id, type: "review" })
        .then((res) => {
          //eslint-disable-line
          this.toggleTagEditModal();
        });
    });
  };

  deleteTag = ({ id, name }) => {
    const {
      match: {
        params: { business_id },
      },
      history,
      location: { pathname, search },
    } = this.props;
    const query = queryString.parse(search);
    const tagArray = query.tag && query.tag.split(",");
    return this.props.businessActions
      .deleteTag({ businessId: business_id, id, type: "review" })
      .then((res) => {
        //eslint-disable-line
        if (tagArray && tagArray.includes(name)) {
          query.tag = tagArray.filter((item) => item !== name).join(",");
          !query.tag && delete query.tag;
          history.push({
            pathname,
            search: queryString.stringify({ ...query }),
          });
        } else {
          this.getData({ ...query, businessId: business_id });
        }
        this.props.businessActions.getTagsList({
          businessId: business_id,
          type: "review",
        });
      });
  };

  //Ticket Details Modal Functions
  manageTeamList = () => {
    this.props.history.push({
      pathname: "central-setup",
      state: { tab: "2" },
    });
  };

  getTeamList = (query) => {
    const {
      match: { params },
    } = this.props;
    return this.props.teamActions.teamList(
      { ...params, ...query },
      params.business_id
    );
  };

  toggleTicketModal = async (item) => {
    this.setState({ isTicketLoading: true });
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const { isTicketModalOpen } = this.state;
    if (isTicketModalOpen) {
      this.setState({
        isTicketModalOpen: !isTicketModalOpen,
        survey_current_page: 0,
      });
      this.getTeamList({});
      await this.getData({
        ...queryString.parse(search),
        businessId: business_id,
      });
      this.setState({ isTicketLoading: false });
    } else {
      this.props.ticketActions
        .singleTicketDetails(business_id, item.t_id)
        .then(async (res) => {
          await this.getTeamList({ branch: res.data.source_data.branch_id });
          this.setState({ isTicketModalOpen: !isTicketModalOpen });
          this.setState({ isTicketLoading: false });
        });
    }
  };

  patchTicket = (data, ticket_id) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.ticketActions.patchTicket(data, business_id, ticket_id);
  };

  addComment = (description, ticket_id) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.ticketActions.addComment(
      description,
      business_id,
      ticket_id
    );
  };

  createTicket = (item) => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const payload = {
      source: "review",
      source_id: item.id,
      branch: item.branch_id,
    };
    return this.props.ticketActions
      .createTicket(payload, business_id)
      .then(() => {
        utils.displayMessage("positive", "Ticket Generated");
        this.getData({ ...queryString.parse(search), businessId: business_id });
      });
  };

  handleAiAnalysis = () => {
    const { fameAiAnalysis } = this.state;
    this.setState({ fameAiAnalysis: !fameAiAnalysis });
  };

  getSurveyResponse(data) {
    const req_que = [];
    const not_answered = [];
    this.state.survey_pages[this.state.survey_current_page]["allQuestions"].map(
      (que, que_index) => {
        if (que.question_required) {
          req_que.push(que_index);
        }
      }
    );
    req_que.map((qu_index) => {
      const result = data.SurveyQuestions.findIndex(
        (question) => question.index == qu_index
      );
      if (result < 0) {
        not_answered.push(qu_index + 1);
      }
    });
    if (not_answered.length > 0) {
      this.setState({
        surveySubmitError:
          "Question No:- " + not_answered.join() + " need to be answered.",
      });
    } else {
      let d = this.state.surveyPageQuestion;

      d.push(data);
      this.setState({
        survey_current_page: this.state.survey_current_page + 1,
        surveyPageQuestion: d,
        surveySubmitError: "",
      });
    }
  }

  finalSubmitSurveyQuestin(new_data) {
    var data = new_data.data;
    var ticket_id = new_data.ticket_id;
    var attached_files = new_data.attached_files;
    const req_que = [];
    const not_answered = [];
    this.state.survey_pages[this.state.survey_current_page]["allQuestions"].map(
      (que, que_index) => {
        if (que.question_required) {
          req_que.push(que_index);
        }
      }
    );
    req_que.map((qu_index) => {
      const result = data.SurveyQuestions.findIndex(
        (question) => question.index == qu_index
      );
      if (result < 0) {
        not_answered.push(qu_index + 1);
      }
    });
    if (not_answered.length > 0) {
      this.setState({
        surveyFormLoading: false,
        surveySubmitError:
          "Question No:- " + not_answered.join() + " need to be answered.",
      });
    } else {
      const final_data_to_be_save = [...this.state.surveyPageQuestion, data];
      if (
        final_data_to_be_save.length > 0 &&
        final_data_to_be_save[0].SurveyQuestions &&
        final_data_to_be_save[0].SurveyQuestions.length === 0
      ) {
        this.setState({
          surveyFormLoading: false,
          surveySubmitError: "None of the Questions is answered.",
        });
      } else {
        let survey_response_d = [...this.state.surveyPageQuestion, data];
        const formData = new FormData();
        formData.append("survey_response", JSON.stringify(survey_response_d));
        formData.append("ticket_id", ticket_id);
        formData.append("businessId", this.props.match.params.business_id);

        if (attached_files) {
          attached_files.map((file_it) => {
            formData.append("attached_file", file_it, file_it.name);
          });
        }
        this.props.ticketActions
          .createTicketResolutionResponse(formData)
          .then((res) => {
            if (res.data.success) {
              this.props.ticketActions
                .singleTicketDetails(
                  this.props.match.params.business_id,
                  ticket_id
                )
                .then((res) => {
                  if (res.data.resolution_data.survey_response) {
                    this.setState({
                      surveyFormLoading: false,
                      survey_current_page: 0,
                      surveySubmitError: "",
                      surveyPageQuestion: [],
                      productMappingSubmitted: false,
                    });
                  }
                });
            }
          });
      }
    }
  }

  handleSurveyPageChange(selected_page, mapping_length) {
    if (this.state.productIssueReasonsMappingEnabled && mapping_length > 0) {
      if (selected_page == 0) {
        this.setState({
          survey_current_page: selected_page,
          productMappingSubmitted: false,
        });
      } else {
        this.setState({
          survey_current_page: selected_page - 1,
          productMappingSubmitted: true,
        });
      }
    } else {
      this.setState({ survey_current_page: selected_page });
    }
  }

  submitProductIssueReasonsMappingQuestions(new_data) {
    this.setState({ surveyFormLoading: true });
    var mapping_data = new_data.data;
    var ticket_id = new_data.ticket_id;
    let allProductQuesAnswered = true;
    for (let i = 0; i < mapping_data.length; i++) {
      let product_obj = mapping_data[i];
      let selected_issue_index = product_obj.product_issue_list.findIndex(
        (issue_obj) => issue_obj.selected == true
      );
      if (selected_issue_index < 0) {
        allProductQuesAnswered = false;
        this.setState({
          issueReasonError:
            "Issue and Reason need to be selected for all Questions",
          surveyFormLoading: false,
        });
        break;
      } else {
        let selected_reason_index = product_obj.product_issue_list[
          selected_issue_index
        ].product_issue_reasons_list.findIndex(
          (res_obj) => res_obj.selected == true
        );
        if (selected_reason_index < 0) {
          allProductQuesAnswered = false;
          this.setState({
            issueReasonError:
              "Issue and Reason need to be selected for all Questions",
            surveyFormLoading: false,
          });
          break;
        }
      }
    }
    if (allProductQuesAnswered) {
      const formData = new FormData();
      formData.append("product_mapping_response", JSON.stringify(mapping_data));
      formData.append("ticket_id", ticket_id);
      formData.append("businessId", this.props.match.params.business_id);

      return axios_instance
        .post(URLS.SUBMIT_MAPPING_QUESTIONS, formData)
        .then((res) => {
          if (res.data.success) {
            this.props.ticketActions
              .singleTicketDetails(
                this.props.match.params.business_id,
                ticket_id
              )
              .then(() => {
                this.setState({
                  productMappingSubmitted: true,
                  surveyFormLoading: false,
                });
              });
          }
        });
    }
  }

  getTagDetails() {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.actions.getTagOptions(business_id);
  }

  render() {
    const {
      ratingOptions,
      data,
      match: {
        params: { business_id },
      },
      replyOptions,
      responseOptions,
      responseDetail,
      reviewSourceOptions,
      tagsList,
      categoryTagsList,
      teamList,
      lockedModules,
      ticketDetails,
      tagOptions,
      // branchTagOptions,
      orderDetailSettingObj,
      productNameList,
    } = this.props;

    const user = cookie.load("user");

    const {
      enable_review_reply,
      enable_ticket_creation,
      enable_customer_care_forms,
      enable_magic_pin_forms,
      enable_offline_forms,
      enable_swiggy_forms,
      enable_zomato_forms,
      enable_ticket_activity,
    } = user || {};
    const toShowSwiggy =
      reviewSourceOptions &&
      reviewSourceOptions.choices.some(
        (source) => source.display_name === "Swiggy"
      ) &&
      enable_swiggy_forms;
    const toShowZomato =
      reviewSourceOptions &&
      reviewSourceOptions.choices.some(
        (source) => source.display_name === "Zomato"
      ) &&
      enable_zomato_forms;
    const toShowMagicpin =
      reviewSourceOptions &&
      reviewSourceOptions.choices.some(
        (source) => source.display_name === "Magicpin"
      ) &&
      enable_magic_pin_forms;
    const tagsListing = tagsList && tagsList.length ? tagsList : [];
    const categoryTagsListing =
      categoryTagsList && categoryTagsList.length ? categoryTagsList : [];
    const {
      isOpen,
      isDownloadModalOpen,
      tagDetail,
      isTagEditOpen,
      isManageTagModalOpen,
      survey_pages,
      ticketResolutionEnabled,
      survey_page_length,
      productIssueReasonsMappingEnabled,
    } = this.state;

    const reviewDetails = data;
    const ratingList =
      ratingOptions && ratingOptions.choices.length
        ? ratingOptions.choices.map((item) => ({
            label: utils.snakeToCapital(item.display_name),
            value: item.value,
          }))
        : [];

    const uploadFilesOption = [
      {
        label: "Swiggy",
        enable: toShowSwiggy,
        onClick: () => {
          this.toggleUploadReview("swiggy"),
            this.setState({ modalOf: "swiggy" });
        },
      },
      {
        label: "Zomato",
        enable: toShowZomato,
        onClick: () => {
          this.toggleUploadReview("zomato_ors"),
            this.setState({ modalOf: "zomato" });
        },
      },
      {
        label: "Magic Pin",
        enable: toShowMagicpin,
        onClick: () => {
          this.toggleUploadReview("magicpin"),
            this.setState({ modalOf: "magicpin" });
        },
      },
      {
        label: "Offline",
        enable: enable_offline_forms,
        onClick: () => {
          this.toggleUploadReview("offline"),
            this.setState({ modalOf: "offline" });
        },
      },
    ];
    return (
      <div className="">
        <LoaderComponent
          loader={
            this.state.isFetching ||
            this.state.isTicketLoading ||
            this.state.downloadLoader
          }
        />
        <div>
          <ReviewsFilter
            history={this.props.history}
            match={this.props.match}
            downloadExcel={this.toggleDownloadModal}
            pageSizeCookie="reviews_page"
            tagsListing={tagsListing}
            location={this.props.location}
            categoryTagsListing={categoryTagsListing}
            productNameList={productNameList}
            getData={this.getData}
          />

          <ReviewActionBar
            handleFilter={this.handleFilter}
            history={this.props.history}
            location={this.props.location}
            onManageTagClick={this.toggleManageTagModal}
            onCustomerCareClick={this.toggelCustomerReview}
            enableCustomerCare={enable_customer_care_forms}
            uploadFilesOption={uploadFilesOption}
            // TODO : manage fameAiAnalysis
            // fameAiAnalysis = {fameAiAnalysis}
            handleAiAnalysis={this.handleAiAnalysis}
            isReviewPage={true}
            getData={this.getData}
          />
          <ReviewsList
            postTag={this.postTag}
            tagOptions={tagOptions}
            getTagDetails={this.getTagDetails}
            tags={tagsListing}
            handleTagFilter={this.handleTagFilter}
            data={reviewDetails}
            saveComment={this.saveComment}
            location={this.props.location}
            history={this.props.history}
            getData={this.getData}
            businessId={business_id}
            replyOptions={replyOptions ? replyOptions.choices : null}
            handleReplyOption={this.handleReplyOption}
            responseOptions={responseOptions && responseOptions.choices}
            getResponseOptions={(review_id) =>
              this.props.businessActions.responseOptions(business_id, {
                review_id,
              })
            }
            fetchResponse={(review_id, id) =>
              this.props.businessActions.getResponseDetails({
                review_id,
                business_id,
                id,
              })
            }
            responseDetail={responseDetail}
            createTicket={this.createTicket}
            toggleTicketModal={
              lockedModules["ticket"] &&
              enable_ticket_creation &&
              this.toggleTicketModal
            }
            // TODO : manage toggleViewTicketModal modal
            toggleViewTicketModal={this.toggleTicketModal}
            replyDisabled={!enable_review_reply}
            ticketActivity={enable_ticket_activity}
            patchTicket={this.patchTicket}
            // TODO : manage fameAiAnalysis modal
            // fameAiAnalysis = {fameAiAnalysis}
            orderDetailSettingObj={
              orderDetailSettingObj &&
              orderDetailSettingObj.results &&
              orderDetailSettingObj.results[0]
            }
          />

          <PaginationComponent
            count={reviewDetails && reviewDetails.count}
            location={this.props.location}
            pageSizeCookie="reviews_page"
            className=""
          />

          {isDownloadModalOpen && (
            <LimitModal
              isOpen={isDownloadModalOpen && isDownloadModalOpen}
              isReviewsPage={true}
              toggle={this.toggleDownloadModal}
              HeaderText={
                this.state.reviewDownloadRequestExist
                  ? "Attention!"
                  : data.count < 1500
                  ? "Alrighty"
                  : "Attention!"
              }
              message={
                this.state.reviewDownloadRequestExist
                  ? [
                      "You have already requested to download reviews, Please request again once your existing request has been processed. Check your email for reviews CSV file.",
                    ]
                  : data.count < 1500
                  ? ["Hey you are good to go. Click ok to download reviews"]
                  : [
                      "You can only download 1500 reviews at a time .Click ok to continue and you get CSV in your mail.",
                    ]
              }
              handleDownloadModal={this.handleDownloadModal}
            ></LimitModal>
          )}

          <TagManageModalWrapper
            isOpen={isManageTagModalOpen}
            toggle={this.toggleManageTagModal}
            onAddNewTagClick={this.toggleTagEditModal}
          >
            <Box
              sx={{
                margin: "-0px -20px -12px -5px",
              }}
            >
              <ManageTags
                reviewsPage={true}
                location={this.props.location}
                toggleTagEditModal={this.toggleTagEditModal}
                deleteTag={this.deleteTag}
                handleTagFilter={this.handleTagFilter}
                toggleConfirm={this.toggleConfirm}
                tagsListing={tagsListing}
                bodyClass={true}
              />
            </Box>
          </TagManageModalWrapper>

          {isTagEditOpen && (
            <TagEditModal
              header={!tagDetail.id ? "Add a tag" : "Edit tag name"}
              isOpen={isTagEditOpen}
              toggle={this.toggleTagEditModal}
              showCloseBtn={false}
              width={"300px"}
            >
              <TagAddForm
                toggle={this.toggleTagEditModal}
                initialValues={tagDetail}
                addOrEditTag={this.addOrEditTag}
              />
            </TagEditModal>
          )}

          <ConfirmationModal
            header="Are you sure you want to delete?"
            subHeader="This cannot be undone."
            isOpen={this.state.isConfirmOpen}
            toggle={this.toggleConfirm}
            onConfirm={this.state.functionToRun}
          />

          <TicketingReviewsModal
            isOpen={this.state.isTicketModalOpen}
            size={"lg"}
            toggle={this.toggleTicketModal}
            data={ticketDetails}
            teamList={teamList && teamList.results}
            manageTeamList={this.manageTeamList}
            patchTicket={this.patchTicket}
            addComment={this.addComment}
            singleTicketDetails={this.props.ticketActions.singleTicketDetails}
            businessId={business_id}
            survey_pages={survey_pages}
            ticketResolutionEnabled={ticketResolutionEnabled}
            survey_page_length={survey_page_length}
            survey_current_page={this.state.survey_current_page}
            surveySubmitError={this.state.surveySubmitError}
            getSurveyResponse={this.getSurveyResponse}
            finalSubmitSurveyQuestin={this.finalSubmitSurveyQuestin}
            handleSurveyPageChange={this.handleSurveyPageChange}
            surveyFormLoading={this.state.surveyFormLoading}
            productIssueReasonsMappingEnabled={
              productIssueReasonsMappingEnabled
            }
            submitProductIssueReasonsMappingQuestions={
              this.submitProductIssueReasonsMappingQuestions
            }
            issueReasonError={this.state.issueReasonError}
            productMappingSubmitted={this.state.productMappingSubmitted}
            disableTicketCommentBox={this.state.disableTicketCommentBox}
            restrictTicketClosingOnSurvey={
              this.state.restrictTicketClosingOnSurvey
            }
            replyOptions={replyOptions ? replyOptions.choices : null}
            handleReplyOption={this.handleReplyOption}
          />

          <FormModal
            isOpen={this.state.isCustomerReview}
            toggle={this.toggelCustomerReview}
            heading="Customer support form"
          >
            <ReviewsFrom
              reviewSourceOptions={
                reviewSourceOptions && reviewSourceOptions.choices
              }
              ratingOptions={ratingList}
              options={replyOptions}
              match={this.props.match}
              onSubmit={this.addReviewsFormData}
            />
          </FormModal>

          <FormModal
            isOpen={isOpen}
            toggle={this.toggleUploadReview}
            maxWidth={"md"}
            width={"480px"}
            heading={
              this.state.modalOf === "swiggy"
                ? "Upload Swiggy Data"
                : this.state.modalOf === "zomato"
                ? "Upload Zomato Data"
                : this.state.modalOf === "magicpin"
                ? "Upload Magicpin Data"
                : "Select Branch"
            }
          >
            <UploadReviewModal
              history={this.props.history}
              location={this.props.location}
              type={this.state.type}
              downloadReviewTemplate={() => {
                this.downloadReviewTemplate();
              }}
              toggle={this.toggleUploadReview}
            />
          </FormModal>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  tagOptions: state.review.tag_options,
  data: state.review.list,
  ratingOptions: state.common.ratings,
  replyOptions: state.common.reply_options,
  responseOptions: state.business.response_options,
  responseDetail: state.business.response_details,
  businessInfo: state.business.detail,
  reviewSourceOptions: state.review.review_source_options,
  loader: state.common.loader,
  overview: state.overview,
  business_userq: state.profile.business_user,
  form_data: state.business,
  tagsList: state.business.tags_list,
  categoryTagsList: state.business.category_tags_list || [],
  productNameList: state.business.productNameList || [],
  teamList: state.team,
  lockedModules: state.business.lockedModules || {},
  ticketDetails: state.tickets.ticket_details,
  branchTagOptions: state.common.branch_tag_options,
  orderDetailSettingObj: state.review.orderDetailSetting,
  resolutionDetail: state.tickets.ticketResolution,
});

ReviewsPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  ratingOptions: PropTypes.instanceOf(Object),
  replyOptions: PropTypes.instanceOf(Object),
  responseOptions: PropTypes.instanceOf(Object),
  sentimentOptions: PropTypes.instanceOf(Object),
  cityOptions: PropTypes.instanceOf(Object),
  stateOptions: PropTypes.instanceOf(Object),
  countryOptions: PropTypes.instanceOf(Object),
  reviewSourceOptions: PropTypes.instanceOf(Object),
  branchTagOptions: PropTypes.instanceOf(Object),
  orderDetailSettingObj: PropTypes.instanceOf(Object),
  tagOptions: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ReviewsActions, dispatch),
    commonActions: bindActionCreators(commonAction, dispatch),
    businessActions: bindActionCreators(BusinessSetupActions, dispatch),
    profileActions: bindActionCreators(ProfileActions, dispatch),
    teamActions: bindActionCreators(TeamAction, dispatch),
    ticketActions: bindActionCreators(ticketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ReviewsPage);
