import { Box } from "@material-ui/core";
import React from "react";
import { FPButton } from "../../common/new";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

const InboxConnections = ({ connections, onClick, isLoggedIn, responseFacebook, logout }) => {
  const mappedIsLoggedIn = {
    whatsapp: isLoggedIn.whatsapp,
    facebook: isLoggedIn.facebook,
    instagram: isLoggedIn.instagram,
    twitter: isLoggedIn.twitter,
    google: isLoggedIn.google,
  };

  return (
    <div>
      <div className="d-flex flex-wrap gap-y-20">
        {connections.map((item, index) => {
          const isConnected = mappedIsLoggedIn[item.id] === true;
          
          return (
            <Box
              className="d-flex flex-column text-center"
              key={index}
              sx={{
                padding: "0 10px",
                borderRight: (index + 1) % 3 === 2 ? "1px solid #ccc" : "none",
                borderLeft: (index + 1) % 3 === 2 ? "1px solid #ccc" : "none",
                width: "33%",
              }}
            >
              <div>
                <img width={item.width} height={item.height} src={item.icon} alt={`${item.id}-icon`} />
              </div>
              <Box component="span" className="mb-3 mt-1" sx={{ color: "#1B1C1D" }}>
                {item.id}
              </Box>

              <div>
                {item.id === "facebook" ? (
                  <FacebookLogin
                    appId={process.env.REACT_APP_SOCIAL_AUTH_FACEBOOK_KEY}
                    fields="name,email,picture"
                    scope='email, pages_show_list, pages_manage_metadata, pages_messaging, pages_read_engagement'
                    enable_profile_selector={true}
                    callback={(response) => {
                      if (response.status === "unknown" || !response.accessToken) {
                        return;
                      }
                      responseFacebook(response);
                    }}
                    render={(renderProps) =>
                      isConnected ? (
                        <FPButton
                          size="small"
                          onClick={() => logout("facebook")}
                          backgroundColor="#F04438"
                          textColor="white"
                        >
                          Disconnect
                        </FPButton>
                      ) : (
                        <FPButton
                          size="small"
                          onClick={renderProps.onClick}
                          backgroundColor="#2E90FA"
                          textColor="white"
                        >
                          Connect
                        </FPButton>
                      )
                    }
                    version="7.0"
                  />
                ) : (
                  <FPButton
                    size="small"
                    variant="outline"
                    label={isConnected ? "Disconnect" : "Connect"}
                    backgroundColor={isConnected ? "#F04438" : "#2E90FA"}
                    textColor="white"
                    onClick={() => onClick(item.id)}
                    borderColor="none"
                  />
                )}
              </div>

              {isLoggedIn.hasOwnProperty(item.id) && isLoggedIn[item.id].name && (
                <div className="mt-1">
                  <Box component="p" sx={{ color: "#1B1C1D", fontSize: "14px", wordWrap: "break-word" }}>
                    {isLoggedIn[item.id].name}
                  </Box>
                </div>
              )}
            </Box>
          );
        })}
      </div>
    </div>
  );
};

export default InboxConnections;
