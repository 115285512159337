import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import {
  Row,
  Col,
  NavItem,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
} from "reactstrap";
import { Dropdown, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import AddKeywordForm from "../../components/mentions/AddKeywordForm";
import MentionsDashboard from "../../components/mentions/MentionsDashboard";
import * as businessActions from "../../actions/business/businessSetupActions";
import * as mentionsActions from "../../actions/mentions/mentionsActions";
import * as CommonActions from "../../actions/common/commonAction";
import * as filterListActions from "../../actions/common/filterListActions";
import * as utils from "../../libs/utils";
import queryString from "query-string";
import cookie from "react-cookies";
import DateFilter from "../../components/common/DateFilter";
import PaginationComponent from "../../components/common/Pagination";
import SearchComponent from "../../components/common/SearchComponent";
import classnames from "classnames";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import LoaderComponent from "../../components/common/loaderComponent";
import LockedComponent from "../../components/common/LockedComponent/LockedComponent";
import DownloadProgressComponent from "../../components/mentions/DownloadProgress/DownloadProgress";
import TagEditModal from "../../components/common/TagEditModal";
import TagAddForm from "../../components/mentions/TagAddForm";
import ManageTags from "../../components/business/ManageTags";
import FormModal from "../../components/common/FormModal";
import SocialConnect from "../../components/mentions/SocialConnect/SocialConnect";
import moment from "moment";
import "./mention.css";
import closeB from "../../images/svg/x.svg";
import closeW from "../../images/svg/Close_white.svg";
import {
  Box,
  FormControlLabel,
  Radio,
  Badge,
  RadioGroup,
  IconButton,
} from "@material-ui/core";
import plusicon from "../../images/svg/plus_green.svg";
import { FPButton, FPToolTip } from "../../components/common/new";
import tagimg from "../../images/svg/Tag.svg";
import Instagram from "../../images/instagram_logo.png";
import Facebook from "../../images/facebook.png";
import Youtube from "../../images/youtubeNewLogo.png";
// import Twitter from "../../images/twitterNewIcon.png";
import Linkedin from "../../images/linkedin-logo.png";
import XLogo from "../../images/X.jpg";
import { ReactComponent as DownloadIcon } from "../../images/svg/Download.svg";
import {
  All,
  Blog,
  InstagramNew,
  News,
  Pinterest,
  Quora,
  // TwitterIcon,
  Video,
  Web,
  FaceBookIcon,
  LinkedinSvg,
  XSvg,
} from "../../images/svg";
import check_Circle from "../../images/svg/check-successfull.svg";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import DateSelector from "../../modules/dashboard/component/DateSelector";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import LimitModal from "../../components/common/LimitModal";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";


const FilterIcons = {
  all: { icon: <All />, text: "All" },
  blogs: { icon: <Blog />, text: "Blogs" },
  facebook: { icon: <FaceBookIcon />, text: "Facebook" },
  instagram: { icon: <InstagramNew />, text: "Instagram" },
  youtube: { icon: <Video />, text: "Youtube" },
  twitter: { icon: <XSvg />, text: "X" },
  news: { icon: <News />, text: "News" },
  web: { icon: <Web />, text: "Web" },
  quora: { icon: <Quora />, text: "Quora" },
  pinterest: { icon: <Pinterest />, text: "Pinterest" },
  linkedin: { icon: <LinkedinSvg />, text: "Linkedin" },
};

const orderByOptions = [
  { text: "By Latest", value: "-created" },
  { text: "By Oldest", value: "created" },
];

const getIcon = [
  { img: Facebook, name: "facebook" },
  { img: Instagram, name: "instagram" },
  { img: Youtube, name: "youtube" },
  { img: XLogo, name: "X" },
  { img: Linkedin, name: "linkedin" },
];

class MentionsPage extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      history,
      location: { pathname, search },
      match: {
        params: { business_id },
      },
    } = props;
    const page_size = cookie.load("pageSize") || {};
    const query = queryString.parse(search);
    this.state = {
      activeTab: "Timeline",
      isConfirmOpen: false,
      provider: "All",
      socialStatusData: [],
      tagDetail: {},
      isTagEditOpen: false,
      date: {
        start_date: query.start_date ? query.start_date : new Date(),
        end_date: query.end_date ? query.end_date : new Date(),
        date_type: query.date_type ? query.date_type : "prior_30_day",
      },
      downloadState: false,
      view: "all",
      tagAddSuccess: false,
      keywordComfirm: false,
      keywordId: null,
      isFormOpen: true,
      isAnySocialConnected: true,
      isMoreThanNinety: false,
      isDownloadModalOpen: false,
      reviewDownloadRequestExist: false,
    };

    history.replace({
      pathname,
      search: queryString.stringify({
        page_size: page_size.mentions_page || 10,
        date_type: "prior_30_day",
      }),
    });

    this.toggleTab = this.toggleTab.bind(this);
    this.addKeyword = this.addKeyword.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.handleKeywordDelete = this.handleKeywordDelete.bind(this);
    this.toggleDownloadModal = this.toggleDownloadModal.bind(this);
    this.postTag = this.postTag.bind(this);
    // this.startGetStatus();
  }

  componentDidMount() {
    this.props.actionsSelectTab.setSelectedTab(1);
    const {
      match: {
        params: { business_id },
      },
      filterAction,
    } = this.props;
    this.props.commonActions.sentimentOptions();
    this.props.businessActions.getTagsList({
      businessId: business_id,
      type: "mentionsocial",
    });
    filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`);
    this.getMentionsSocialStatus();
    this.callSocialProfileApi();
    this.props.actions
      .getMentionsKeywords(business_id)
      .then(({ data }) => {
        if (data.results[0].keyword) {
          // const keyword = data.results[0].keyword;
          const keyword = "Timeline";
          this.props.actions.getMentionsData(business_id, { keyword });
          this.setState({ activeTab: keyword });
        } else this.setState({ activeTab: "new$#" });
      })
      .catch((err) => {
        if (err === "lock") {
          this.setState({ lock: "Social Mentions Module is locked." });
          this.props.commonActions.hideLoader();
        }
      });
    //Set Title
    document.title = "Mentions";
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeTab, date } = this.state;
    const { activeTab: prevActiveTab } = prevState;
    const {
      match: {
        params: { business_id },
      },
      mentionsKeywordsList,
      location: { search },
    } = this.props;
    const {
      mentionsKeywordsList: prevMentionskeywordsList,
      location: { search: prevSearch },
    } = prevProps;
    const query = queryString.parse(search);
    const prevQuery = queryString.parse(prevSearch);
    if (
      activeTab !== prevActiveTab ||
      (prevMentionskeywordsList &&
        mentionsKeywordsList !== prevMentionskeywordsList) ||
      (prevSearch && search !== prevSearch)
    ) {
      if (activeTab !== prevActiveTab) this.setState({ provider: "All" });
      if (prevQuery.date_type !== query.date_type) {
        this.setState({ date: { ...date, date_type: query.date_type } });
      }
      if (activeTab !== "new$#") {
        query.keyword = activeTab;
        this.props.actions.getMentionsData(business_id, { ...query });
      }
    }
    if (mentionsKeywordsList?.count === 0) {
      this.setState({ activeTab: "new$#" });
    }
  }

  componentWillUnmount() {
    this.stopGetStatus();
  }

  getMentions = () => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const { activeTab } = this.state;
    let params = { keyword: activeTab, ...queryString.parse(search) };
    return this.props.actions.getMentionsData(business_id, params);
  };

  getMentionsAllData = () => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    const { activeTab } = this.state;
    this.getMentions();
    this.props.actions.getMentionsReport(business_id, {
      keyword: activeTab,
      date_type: query.date_type,
    });
  };

  handleDateFilter = (date, key) => {
    const {
      history,
      location: { pathname, search },
    } = this.props;

    let newData = { ...queryString.parse(search), ...date };

    ["start_date", "end_date"].forEach((dateKey) => {
      if (newData["date_type"] === "other") {
        newData[dateKey] = moment(date[dateKey]).format("YYYY-MM-DD");
      } else {
        delete newData[dateKey];
      }
    });

    this.setState({ date }, () => {
      history.push({ pathname, search: queryString.stringify(newData) });
    });
  };

  clearFilters() {
    const {
      history,
      location: { search, pathname },
    } = this.props;
    const query = queryString.parse(search);
    this.setState({ provider: "All" });
    for (let x in query) if (x !== "page_size" && x !== "page") delete query[x];
    history.push({ pathname, search: queryString.stringify({ ...query }) });
  }

  toggleTab(tab) {
    const { activeTab } = this.state;
    const {
      history,
      location: { pathname, search },
    } = this.props;
    const query = queryString.parse(search);
    this.setState({ isFormOpen: tab !== "new$#" });
    if (activeTab !== tab) {
      this.setState({ provider: "All", activeTab: tab });
      history.push({
        pathname,
        search: queryString.stringify({
          page_size: query.page_size,
          date_type: "prior_30_day",
        }),
      });
    }
  }

  appendToQuery = (value, type) => {
    const {
      history,
      location: { pathname, search },
    } = this.props;
    const query = queryString.parse(search);
    this.props.actions.getStatusSuccess({});
    if (type === "sentiment") {
      query[type] = value;
      value === "all" && delete query.sentiment;
    } else {
      query[type] = value;
    }
    delete query.page;
    history.push({ pathname, search: queryString.stringify({ ...query }) });
  };

  handleTagFilter = (value) => {
    const {
      history,
      location: { pathname, search },
    } = this.props;
    const query = queryString.parse(search);
    const tagArray = query.tag && query.tag.split(",");
    delete query.page;
    if (tagArray && tagArray.includes(value)) {
      query.tag = tagArray.filter((item) => item !== value).join(",");
      !query.tag && delete query.tag;
    } else {
      query.tag = query.tag ? query.tag + `,${value}` : value;
    }
    history.push({ pathname, search: queryString.stringify({ ...query }) });
  };

  deleteMention = (id) => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    const keyword = this.state.activeTab;
    query.keyword = keyword && keyword;
    this.props.actions.deleteMentionReview(business_id, id).then((res) => {
      //eslint-disable-line
      this.props.actions.getMentionsData(business_id, { ...query });
    });
  };

  addKeyword(keyword) {
    const {
      match: {
        params: { business_id },
      },
      history,
      isLoggedIn,
    } = this.props;
    this.setState(
      {
        isAnySocialConnected: Object.values(isLoggedIn).some(
          (platform) => platform.status === "connected"
        ),
      },
      () => {
        const data = { business_id, keyword };
        if (this.state.isAnySocialConnected) {
          // Uncomment to call the API when ready
          this.props.actions.addKeyword(data).then((res) => {
            this.setState({ activeTab: keyword, downloadState: true });
            this.props.actions.getMentionsKeywords(business_id);
            this.setState({ isFormOpen: this.state.activeTab !== "new$#" });

            // Uncommented code blocks as per original instructions
            // if (isAnyPlatformConnected) {
            //   this.props.actions.getMentionsKeywords(business_id);
            // } else {
            //   history.push(`/${business_id}/social-account`);
            // }

            // this.startGetStatus();
            // setTimeout(
            //   () => {
            //     this.props.actions.getMentionsData(business_id, { keyword: keyword });
            //     this.setState({ downloadState:false });
            //     clearInterval(this.getStatus);
            //   }, 30000 );
          });
        }
      }
    );
  }

  handleSocialFilter = (item) => {
    const provider = FilterIcons[item].text;
    const {
      history,
      location: { search, pathname },
    } = this.props;
    this.setState({ provider });
    const query = queryString.parse(search);
    query.page && delete query.page;
    if (provider === "All") {
      delete query.provider;
    } else {
      query.provider = item;
    }
    history.push({ pathname, search: queryString.stringify({ ...query }) });
  };

  handleKeywordDelete(id) {
    const {
      match: {
        params: { business_id },
      },
      mentionsKeywordsList,
    } = this.props;

    this.props.actions.deleteMentionKeyword(business_id, id).then(() => {
      mentionsKeywordsList?.results?.map((data, index) => {
        if (index === 0)
          this.setState({ activeTab: data?.keyword, downloadState: false });
      });
      this.props.actions.getMentionsKeywords(business_id);
    });
    if (mentionsKeywordsList.count === 1) {
      this.setState({ activeTab: "new$#", downloadState: false });
    }
  }

  toggleConfirm(functionToRun) {
    this.setState({ isConfirmOpen: !this.state.isConfirmOpen, functionToRun });
  }

  keywordComfirmToggle = (id) => {
    // this.setState({ keywordComfirm: false });
    this.setState({
      keywordComfirm: !this.state.keywordComfirm,
      keywordId: id,
    });
  };

  startGetStatus = () => {
    const getMentionStatus = () => {
      const {
        match: {
          params: { business_id },
        },
        location: { search },
      } = this.props;
      const { date_type, sentiment } = queryString.parse(search);
      this.props.actions.getStatus(business_id, { date_type, sentiment });
    };
    getMentionStatus();
    this.getStatus = setInterval(getMentionStatus, 3000);
  };

  stopGetStatus = () => {
    clearInterval(this.getStatus);
  };

  handleReviewSentiment = (value, details) => {
    const { id, sentiment } = details;
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const { activeTab } = this.state;
    const query = { keyword: activeTab, ...queryString.parse(search) };
    if (sentiment !== value) {
      this.props.actions
        .updateReviewStatus({ sentiment: value, business_id, id })
        .then(() => {
          this.props.actions.getMentionsData(business_id, { ...query });
        });
    }
  };

  postTag = async (tagData) => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;

    const { object_id, tagMethod, tag_id, name, tag_name } = tagData;
    const data = { businessId: business_id, type: "mentionsocial" };
    const query = queryString.parse(search);
    query.keyword = this.state.activeTab;

    try {
      const res = await this.props.businessActions.postTag({
        ...data,
        object_id,
        tag_name,
        name,
        tag_id,
        tagMethod,
      });
      // Trigger additional actions after posting the tag
      await this.props.businessActions.getTagsList(data);

      if (tagMethod) {
        await this.props.actions.getMentionsData(
          business_id,
          { ...query },
          { notLoad: true }
        );
      }
    } catch (error) {
      throw error; // Propagate the error for further handling
    }
  };

  redirectToSocialLinks = () => {
    const { history } = this.props;
    const corporate_branch = cookie.load("user").corporate_branch || null;
    history.push({
      pathname: `${corporate_branch}/business-profile`,
      state: { tab: "2" },
    });
  };

  toggleTagEditModal = (tag) => {
    const { isTagEditOpen } = this.state;
    if (tag) this.setState({ tagDetail: { ...tag }, isTagEditOpen: true });
    else this.setState({ isTagEditOpen: !isTagEditOpen, tagDetail: {} });
  };

  addOrEditTag = (data) => {
    const {
      history,
      match: {
        params: { business_id },
      },
      location: { pathname, search },
    } = this.props;
    const {
      tagDetail,
      tagDetail: { id },
      activeTab,
    } = this.state;
    const { name } = data;
    const query = queryString.parse(search);
    query.keyword = activeTab;
    let functionToRun;
    let getMentions = true;
    const selectedTag = query.tag && query.tag.split(",");

    if (tagDetail.id) {
      if (selectedTag && selectedTag.includes(tagDetail.name)) {
        delete query.tag;
        getMentions = false;
        history.push({ pathname, search: queryString.stringify({ ...query }) });
      }
      functionToRun = this.props.businessActions.editTagName({
        businessId: business_id,
        type: "mentionsocial",
        id,
        name,
      });
    } else
      functionToRun = this.props.businessActions
        .postTag({
          businessId: business_id,
          type: "mentionsocial",
          name,
        })
        .then((res) => {
          this.setState({ tagAddSuccess: true });
        });

    return functionToRun.then((res) => {
      //eslint-disable-line
      getMentions &&
        this.props.actions.getMentionsData(business_id, { ...query });
      this.props.businessActions
        .getTagsList({ businessId: business_id, type: "mentionsocial" })
        .then((res) => {
          //eslint-disable-line
          this.toggleTagEditModal();
        });
    });
  };

  deleteTag = ({ id, name }) => {
    const {
      match: {
        params: { business_id },
      },
      history,
      location: { pathname, search },
    } = this.props;
    const query = queryString.parse(search);
    const tagArray = query.tag && query.tag.split(",");
    return this.props.businessActions
      .deleteTag({ businessId: business_id, id, type: "mentionsocial" })
      .then((res) => {
        //eslint-disable-line
        if (tagArray && tagArray.includes(name)) {
          query.tag = tagArray.filter((item) => item !== name).join(",");
          !query.tag && delete query.tag;
          history.push({
            pathname,
            search: queryString.stringify({ ...query }),
          });
        } else {
          query.keyword = this.state.activeTab;
          this.props.actions.getMentionsData(business_id, { ...query });
        }
        this.props.businessActions.getTagsList({
          businessId: business_id,
          type: "mentionsocial",
        });
      });
  };

  postCode = (code, provider) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    if (provider == "twitter") {
      return this.props.actions
        .postMentionsSocialCode(code, business_id, provider)
        .then(() => {
          utils.displayMessage(
            "positive",
            `${provider} Connected Successfully`
          );
        })
        .catch(() => {
          utils.displayMessage("negative", "Error");
        });
    } else {
      return this.props.actions
        .postMentionsSocialCode({ code }, business_id, provider)
        .then(() => {
          utils.displayMessage(
            "positive",
            `${provider} Connected Successfully`
          );
        })
        .catch(() => {
          utils.displayMessage("negative", "Error");
        });
    }
  };

  toggleSocialConnect = () => {
    this.props.history.push({
      pathname: "central-setup",
      state: { tab: "8" },
    })
  };
  toggleManageTag = () => {
    this.setState({ isManageTagOpen: !this.state.isManageTagOpen });
  };

  connectSocial = (data, provider) => {
    const code = provider === "twitter" ? data : data.code;
    window.removeEventListener("message", this.connectSocialRef);
    this.postCode(code, provider);
    this.newWindow.close();
  };

  getAuthUrl = (provider) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions.getAuthUrl(business_id, provider).then((res) => {
      this.newWindow = window.open(
        res.auth_url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
      );
      this.connectSocialRef = (response) =>
        this.connectSocial(response.data, provider);
      window.addEventListener("message", this.connectSocialRef);
    });
  };

  getMentionsSocialStatus = () => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions.getMentionsSocialStatus(business_id);
  };

  callSocialProfileApi = () => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;

    axios_instance
      .get(`${URLS.SOCIAL_PROFILE_INTEGRATION}?business_id=${business_id}`)
      // .get(`${URLS.SOCIAL_PROFILE_INTEGRATION}?business_id=${business_id}`)
      .then((res) => {
        const profileCount = {};
        res.data &&
          Array.isArray(res.data) &&
          res.data.map((value) => {
            if (value.provider == "twitter"){
              profileCount['X'] = value.no_of_profiles;
            }
            profileCount[value.provider] = value.no_of_profiles;
            return profileCount;
          });
        this.setState({ socialStatusData: profileCount });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  mentionsSocialLogout = (provider) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions
      .mentionSocialLogout(business_id, provider)
      .then(() => {
        utils.displayMessage(
          "positive",
          `${provider} Disconnected Successfully`
        );
      })
      .catch(() => {
        utils.displayMessage("negative", "Error");
      });
  };

  TagEditToggleModal = () => {
    this.setState({ isTagEditOpen: !this.state.isTagEditOpen });
  };

  renderTabPane = () => {
    const user = cookie.load("user");
    const {
      match: {
        params: { business_id },
      },
      mentionsKeywordsList,
      mentions,
      tagsList,
      sentimentOptions,
      socialLinks,
      loader,
      error,
    } = this.props;
    const { isAnySocialConnected } = this.state;
    const tagsListing = tagsList && tagsList.length ? tagsList : [];
    let keywordsList =
      mentionsKeywordsList && mentionsKeywordsList.results.length
        ? mentionsKeywordsList.results
        : [];
    keywordsList = [
      {
        id: "###",
        keyword: "Timeline",
        approved: true,
        is_timeline: true,
      },
      ...keywordsList,
    ];
    const facebookLink =
      socialLinks &&
      socialLinks.find((item) => item.web_portal.provider === "facebook");
    const twitterLink =
      socialLinks &&
      socialLinks.find((item) => item.web_portal.provider === "twitter");
    const shareLinks = {
      twitter: twitterLink && twitterLink.link,
      facebook: facebookLink && facebookLink.link,
    };

    return (
      <TabContent activeTab={this.state.activeTab} className="mt-2 mx-3">
        {
          <TabPane tabId={this.state.activeTab}>
            {this.state.activeTab === "new$#" ? (
              user.enable_mention_action ? (
                <AddKeywordForm
                  serverError={error && error}
                  handleKeywordChange={this.handleKeywordChange}
                  addKeyword={this.addKeyword}
                  isAnySocialConnected={isAnySocialConnected}
                  isLoggedIn={this.props.isLoggedIn}
                  postCode={this.postCode}
                  logout={this.mentionsSocialLogout}
                  getStatus={this.callSocialProfileApi}
                  // getStatus={this.getMentionsSocialStatus}
                  getAuthUrl={this.getAuthUrl}
                />
              ) : (
                <div>
                  <Card body className="p-3">
                    No keyword is added, Please ask your business owner to add
                    keywords.
                  </Card>
                </div>
              )
            ) : this.state.downloadState ? (
              <CardWrapper
                showDivider={false}
                className="mb-4 text-center"
                style={{ borderRadius: "15px" }}
              >
                <Box
                  sx={{
                    color: "#1A2544",
                    padding: "0px 10px",
                    fontSize: "14px",
                  }}
                >
                  Keyword has been added successfully. The data will be fetched
                  as soon as Famepilot administration approve your keyword.
                </Box>
              </CardWrapper>
            ) : (
              <div>
                <MentionsDashboard
                  postTag={this.postTag}
                  tags={tagsListing}
                  handleTagFilter={this.handleTagFilter}
                  deleteMention={(id) => this.deleteMention(id)}
                  sentimentOptions={
                    sentimentOptions && sentimentOptions.choices
                  }
                  handleReviewSentiment={this.handleReviewSentiment}
                  keyword={keywordsList.find((item) => {
                    return item.keyword == this.state.activeTab;
                  })}
                  shareLinks={shareLinks}
                  location={this.props.location}
                  loader={loader}
                  mentions={mentions}
                  business_id={business_id}
                ></MentionsDashboard>{" "}
                {!!mentions && !!mentions.count && (
                  <Row>
                    <Col>
                      <PaginationComponent
                        count={mentions && mentions.count}
                        location={this.props.location}
                        pageSizeCookie="mentions_page"
                      />
                    </Col>
                  </Row>
                )}
              </div>
            )}
          </TabPane>
        }
      </TabContent>
    );
  };
  toggleView(view) {
    this.setState({ view });
  }

  toggleAddTagSuccess = () => {
    this.setState({ tagAddSuccess: false });
  };

  toggleDownloadModal(downloadFlag) {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const { isDownloadModalOpen } = this.state;

    if (isDownloadModalOpen && downloadFlag) {
      this.props.commonActions.showLoader();
      const url = `${URLS.MENTION_DATA_DOWNLOAD.replace(
        "{}",
        business_id
      )}download/${search || ""}`;
      this.props.reviewActions.downloadTemplate(
        url,
        "famepilot-mention-report.csv"
      );
      this.setState({ isDownloadModalOpen: !isDownloadModalOpen });
      this.props.commonActions.hideLoader();
    }
    return;
  }

  handleReviewDownloadFlow = () => {
    const dateType = utils.getParams(window.location.search, "date_type");
    const startDate = utils.getParams(window.location.search, "start_date");
    const endDate = utils.getParams(window.location.search, "end_date");

    if (dateType === "other") {
      const M_startDate = moment(startDate);
      const M_endDate = moment(endDate);
      const daysDifference = M_endDate.diff(M_startDate, "days");

      if (daysDifference > 90) {
        this.setState({ isMoreThanNinety: true });
      } else {
        this.setState({ isMoreThanNinety: false });
        this.setState({ isDownloadModalOpen: true });
      }
    } else {
      if (
        ["prior_6_month", "current_year", "prior_12_months"].indexOf(dateType) >
        -1
      ) {
        this.setState({ isMoreThanNinety: true });
      } else {
        this.setState({ isMoreThanNinety: false });
        this.setState({ isDownloadModalOpen: true });
      }
    }
  };

  render() {
    const {
      mentionsKeywordsList,
      mentions,
      loader,
      status,
      history,
      location: { search },
      isLoggedIn,
      tagsList,
    } = this.props;
    const {
      tagDetail,
      isTagEditOpen,
      activeTab,
      date,
      downloadState,
      view,
      tagAddSuccess,
      keywordId,
      isFormOpen,
      isAnySocialConnected,
      isDownloadModalOpen,
    } = this.state;
    const query = queryString.parse(search);
    let keywordsList =
      mentionsKeywordsList && mentionsKeywordsList.results.length
        ? mentionsKeywordsList.results
        : [];
    keywordsList = [
      {
        id: "###",
        keyword: "Timeline",
        approved: true,
        is_timeline: true,
      },
      ...keywordsList,
    ];
    const hasApprovedKeywords = keywordsList.find((item) => {
      return item.keyword == this.state.activeTab;
    });
    const tagsListing = tagsList && tagsList.length ? tagsList : null;
    const orderByValue =
      query.ordering &&
      orderByOptions.filter((item) => {
        return item.value === query.ordering;
      })[0];
    // const sentimentValue =
    //   query.sentiment === "Negative" ? "Negative" : query.sentiment === "Positive" ? "Positive" : "All";
    const keywordStatus = status[activeTab];
    const updatedMentions =
      keywordStatus && mentions
        ? keywordStatus.mentions_collected - mentions.total
        : 0;
    const user_obj = cookie.load("user");

    return (
      <div className="my-4">
        <LockedComponent message={this.state.lock} />
        <div>
          <LoaderComponent loader={loader}></LoaderComponent>
          <div className="align-center justify-content-between mx-3">
            <Box className="mb-0 fp-card-header">Mentions</Box>
            {user_obj.enable_mention_action && mentions && (
              <div className="d-flex gap-20" onClick={this.toggleSocialConnect}>
                {getIcon.map((icon) => (
                  <FPToolTip title={""}>
                    <Badge
                      color={
                        this.state.socialStatusData[icon.name] >= 1
                          ? "Primary"
                          : "Error"
                        // this.props.isLoggedIn[icon.name]?.status === "connected"
                        // ? "Primary"
                        // : "Error"
                      }
                      variant="dot"
                    >
                      <img
                        src={icon.img}
                        alt=""
                        style={{ width: 20, height: 20 }}
                        className="cursor-pointer"
                      ></img>
                    </Badge>
                  </FPToolTip>
                ))}
              </div>
            )}
          </div>

          <Row className="content-area">
            <Col sm={12} xs={12} className="business-setup px-3 pb-3">
              <Row className="m-0 sticky-header mt-1">
                <div className="align-center col-12 justify-content-between">
                  <div>
                    <Nav className="align-center">
                      {keywordsList.map(({ id, keyword }) => {
                        return (
                          <FPToolTip title={keyword}>
                            <NavItem key={id}>
                              <NavLink
                                style={{
                                  color:
                                    this.state.activeTab === keyword &&
                                    "#FFFFFF",
                                  background:
                                    this.state.activeTab === keyword &&
                                    "#00B5AD",
                                }}
                                className={classnames("btnui", {
                                  active: this.state.activeTab === keyword,
                                })}
                                onClick={() => {
                                  this.toggleTab(keyword);
                                }}
                              >
                                <div>
                                  {keyword.substring(0, 25)} &nbsp;
                                  {user_obj.enable_mention_action && keyword !== 'Timeline' && (
                                    <>
                                      {this.state.activeTab === keyword ? (
                                        <img
                                          src={closeW}
                                          alt="close"
                                          // onClick={() => this.keywordComfirmToggle(() => this.handleKeywordDelete(id))}
                                          onClick={() =>
                                            this.keywordComfirmToggle(id)
                                          }
                                        />
                                      ) : (
                                        <img
                                          src={closeB}
                                          alt="close"
                                          // onClick={() => this.keywordComfirmToggle(() => this.handleKeywordDelete(id))}
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                              </NavLink>
                            </NavItem>
                          </FPToolTip>
                        );
                      })}
                      {keywordsList.length > 0 &&
                        keywordsList.length < 3 &&
                        user_obj.enable_mention_action &&
                        mentions &&
                        isFormOpen && (
                          <div className="addKeyWord">
                            <NavLink
                              // style={{ fontSize: '18px', fontWeight: this.state.activeTab === 'new$#' && 'bold' }}
                              className={classnames("text-center", {
                                active: this.state.activeTab === "new$#",
                              })}
                              onClick={() => {
                                this.toggleTab("new$#");
                              }}
                            >
                              <div className="addKeywordBtn">
                                <img src={plusicon} alt="plus" />
                                Add Keyword
                              </div>
                            </NavLink>
                          </div>
                        )}
                    </Nav>
                  </div>

                  <div className="d-flex gap-10">
                    {/* {user_obj.enable_mention_action && (
                      <FPButton
                        size="small"
                        label="Manage Connections"
                        variant="outline"
                        borderColor="#BFC2CB"
                        textColor="#1B1C1D"
                        startIcon={<img src={tagimg} />}
                        onClick={this.toggleSocialConnect}
                      />
                    )} */}
                    {/* {user_obj.enable_mention_action && 
                      <Button onClick={this.toggleSocialConnect} color='teal'> Manage Connections</Button>
                    } */}
                    {this.state.activeTab !== "new$#" && (
                      <FPButton
                        label="Manage Tags"
                        variant="outline"
                        borderColor="#BFC2CB"
                        textColor="#1B1C1D"
                        startIcon={<img src={tagimg} alt="tag" />}
                        onClick={this.toggleManageTag}
                      />
                    )}
                  </div>
                </div>
              </Row>
              <Row>
                <Col sm={12} xs={12}>
                  {/* {keywordStatus && mentions && mentions.keyword === activeTab && keywordStatus.status == 'loading' &&  */}
                  {downloadState && (
                    <Row>
                      <Col className="p-3">
                        <DownloadProgressComponent
                          updatedCount={updatedMentions}
                          refresh={this.getMentionsAllData}
                          keyword={keywordsList}
                        />
                      </Col>
                    </Row>
                  )}
                  {this.state.activeTab !== "new$#" && (
                    <Row>
                      <Col sm={12} xs={12} className="px-3 pt-3">
                        <Card body className="p-3 mx-3">
                          <Row sm={12} xs={12}>
                            {Object.keys(FilterIcons).map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{ width: `${100 / 11}%` }}
                                  className="cursor-pointer px-2"
                                >
                                  <Box
                                    className={
                                      this.state.provider ===
                                      FilterIcons[item].text
                                        ? "active-provider"
                                        : null
                                    }
                                    onClick={() => {
                                      this.handleSocialFilter(item);
                                    }}
                                    sx={{
                                      display: "flex",
                                      padding: "10px",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      color: "darkgrey",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: "40px",
                                        height: "40px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {FilterIcons[item].icon}
                                    </Box>
                                    <Box sx={{ margin: "6px auto" }}>
                                      {item === "youtube"
                                        ? "Video"
                                        : FilterIcons[item].text}
                                    </Box>
                                    {mentions && (
                                      <div style={{ fontSize: "16px" }}>
                                        {item === "all"
                                          ? mentions["total"].toString()
                                          : mentions[item] &&
                                            mentions[item].toString()}
                                      </div>
                                    )}
                                  </Box>
                                </div>
                              );
                            })}
                          </Row>
                        </Card>
                        {hasApprovedKeywords?.approved && !downloadState && (
                          <div className="d-flex justify-content-between mt-3 mb-2">
                            <div className="col-12 col-md-6">
                              <RadioGroup
                                className="d-flex flex-row"
                                onChange={(e, value) => {
                                  this.appendToQuery(value, "sentiment");
                                }}
                              >
                                <FormControlLabel
                                  value="all"
                                  label="All"
                                  control={<Radio />}
                                  className="mb-0"
                                />
                                <FormControlLabel
                                  value="Neutral"
                                  label="Neutral"
                                  control={<Radio />}
                                  className="mb-0"
                                />
                                <FormControlLabel
                                  value="Negative"
                                  label="Negative"
                                  control={<Radio />}
                                  className="mb-0"
                                />
                                <FormControlLabel
                                  value="Positive"
                                  label="Positive"
                                  control={<Radio />}
                                  className="mb-0"
                                />
                              </RadioGroup>
                            </div>
                            <div className="col-12 col-md-6 d-flex gap-10 align-items-center justify-content-end">
                              <div>
                                <Dropdown
                                  className="mentions-sentiment order-by-mentions ui dropdown select-range"
                                  text={
                                    query.ordering
                                      ? orderByValue.text
                                      : "Sort By"
                                  }
                                  value={query.ordering}
                                  icon={
                                    <i
                                      aria-hidden="true"
                                      className="dropdown icon"
                                    ></i>
                                  }
                                  // style={{padding: '20px'}}
                                >
                                  <Dropdown.Menu>
                                    {orderByOptions &&
                                      orderByOptions.map((option) => {
                                        return (
                                          <Dropdown.Item
                                            key={option.value}
                                            onClick={(e, { value }) => {
                                              this.appendToQuery(
                                                value,
                                                "ordering"
                                              );
                                            }}
                                            {...option}
                                          />
                                        );
                                      })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="dateField">
                                <DateSelector
                                  history={history}
                                  blank_req={true}
                                  onChange={this.handleDateFilter}
                                  date={date}
                                  // className="dateField"
                                  // style={{width: '160px !important'}}
                                />
                              </div>
                              <SearchComponent
                                {...this.props}
                                size="small"
                                placeholder="Search"
                                endIcon
                              />
                              <FPToolTip title={"Download"}>
                                <IconButton
                                  component="span"
                                  className="circular-button"
                                  onClick={() =>
                                    this.handleReviewDownloadFlow()
                                  }
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </FPToolTip>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col> {this.renderTabPane()}</Col>
                  </Row>
                </Col>
                {/* Manage Tag Right-Side */}
                {/* {query.provider !== "youtube" && (
                  <Col
                    sm={3}
                    xs={3}
                    className="py-3 pl-0 pr-3 mentions-sidefilters"
                  >
                    <div className="pr-3">
                      <Card body className="p-3 mb-3">
                        <div className="cursor-pointer">
                          <Label
                            onClick={() => this.clearFilters()}
                            className="float-right clear-mentions"
                          >
                            Reset All
                          </Label>
                        </div>
                        <input
                          className="mt-3"
                          type="range"
                          min="1"
                          max="3"
                          value={sentimentValue}
                          step="1"
                          onChange={(e) => {
                            this.appendToQuery(e.target.value, "sentiment");
                          }}
                        ></input>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            color: "darkgrey",
                          }}
                        >
                          <div style={{ color: "red" }}>Negative</div>
                          <div>All</div>
                          <div style={{ color: "green" }}>Positive</div>
                        </div>

                        {this.state.activeTab !== "new$#" && (
                          <div className="mt-3">
                            <ManageTags
                              tagsListing={tagsListing}
                              location={this.props.location}
                              handleTagFilter={this.handleTagFilter}
                              toggleTagEditModal={this.toggleTagEditModal}
                              toggleConfirm={this.toggleConfirm}
                              deleteTag={this.deleteTag}
                            />
                            <FPButton
                              label="Add New Tag"
                              size="small"
                              style={{ height: "37px" }}
                              onClick={this.toggleTagEditModal}
                              className="float-right mt-3"
                            />
                          </div>
                        )}
                      </Card>
                    </div>
                  </Col>
                )} */}
              </Row>
            </Col>
          </Row>

          {this.state.isMoreThanNinety && (
            <FormModal
              isOpen={this.state.isMoreThanNinety}
              toggle={() => this.setState({ isMoreThanNinety: false })}
              heading="Attention !"
              maxWidth="xs"
              showDivider={true}
              dialogActions={
                <FPButton
                  size="small"
                  onClick={() => this.setState({ isMoreThanNinety: false })}
                  label="OK"
                />
              }
            >
              <p className="fp-size-16">
                Please contact to support to download data older than 90 days.
              </p>
            </FormModal>
          )}

          {isDownloadModalOpen && (
            <LimitModal
              isOpen={isDownloadModalOpen && isDownloadModalOpen}
              isReviewsPage={true}
              toggle={() =>
                this.setState({ isDownloadModalOpen: !isDownloadModalOpen })
              }
              HeaderText={
                query.date_type !== "prior_6_month" ||
                query.date_type !== "current_year" ||
                query.date_type !== "prior_12_months"
                  ? "Alrighty"
                  : "Attention!"
              }
              message={
                query.date_type !== "prior_6_month" ||
                query.date_type !== "current_year" ||
                query.date_type !== "prior_12_months"
                  ? ["Hey you are good to go. Click ok to download reviews"]
                  : [" you can not download mentions data more then 90 days."]
              }
              handleDownloadModal={this.toggleDownloadModal}
            ></LimitModal>
          )}
          {/* Manage Tag Model*/}
          <FormModal
            heading={"Manage Tags"}
            isOpen={this.state.isManageTagOpen}
            size="lg"
            toggle={this.toggleManageTag}
            width={"600px"}
            headerClass="d-flex align-items-center"
            paddingDense={true}
            bodyClass={"p-0"}
          >
            {query.provider !== "youtube" && (
              <div className="">
                <Card body className="mb-3">
                  {this.state.activeTab !== "new$#" && (
                    <div className="">
                      <ManageTags
                        tagsListing={tagsListing}
                        location={this.props.location}
                        handleTagFilter={this.handleTagFilter}
                        toggleTagEditModal={this.toggleTagEditModal}
                        toggleConfirm={this.toggleConfirm}
                        deleteTag={this.deleteTag}
                        bodyClass={true}
                      />
                      <FPButton
                        label="Add New Tag"
                        size="small"
                        style={{ height: "37px" }}
                        onClick={this.toggleTagEditModal}
                        className="float-right mt-3"
                      />
                    </div>
                  )}
                </Card>
              </div>
            )}
          </FormModal>

          <FormModal
            heading={"Connect Social Accounts"}
            isOpen={this.state.isSocialConnectOpen}
            size="lg"
            toggle={this.toggleSocialConnect}
            width={"700px"}
            headerClass="d-flex align-items-center"
          >
            <SocialConnect
              getStatus={this.getMentionsSocialStatus}
              getAuthUrl={this.getAuthUrl}
              isLoggedIn={isLoggedIn}
              logout={this.mentionsSocialLogout}
              postCode={this.postCode}
            />
          </FormModal>
        </div>
        {isTagEditOpen && (
          <TagEditModal
            header={!tagDetail.id ? "Add a tag" : "Edit tag name"}
            isOpen={isTagEditOpen}
            toggle={this.TagEditToggleModal}
            headerClass="d-flex align-items-center"
            width="400px"
          >
            <TagAddForm
              toggle={this.toggleTagEditModal}
              initialValues={tagDetail}
              addOrEditTag={this.addOrEditTag}
              // showCancelButton={false}
            />
          </TagEditModal>
        )}
        {tagAddSuccess && (
          <TagEditModal
            isOpen={tagAddSuccess}
            toggle={this.toggleAddTagSuccess}
            width="380px"
            showCloseBtn={false}
          >
            <div className="text-center">
              <Box
                component="img"
                src={check_Circle}
                sx={{ textAlign: "center" }}
              />
              <Box
                className="mt-2"
                sx={{ color: "#1B1C1D", fontWeight: 500, fontSize: "17px" }}
              >
                Tag Added Successfully
              </Box>
            </div>
          </TagEditModal>
        )}
        <ConfirmationModal
          header="Are you sure!"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          subHeader={
            "Do you think this mention is not relevant? This action can’t be undone."
          }
          isOpen={this.state.isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={this.state.functionToRun}
        />

        <ConfirmationModal
          header="Are you sure!"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          subHeader={`Are you sure you want to delete the keyword ${this.state.activeTab}? This action can’t be undone.`}
          isOpen={this.state.keywordComfirm}
          toggle={this.keywordComfirmToggle}
          onConfirm={() => this.handleKeywordDelete(keywordId)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mentions: state.mentions.mentionsData,
    status: state.mentions.status || {},
    mentionsKeywordsList: state.mentions.keywordsList,
    sentimentOptions: state.common.sentiment_options,
    mentionProviders: state.mentions.mention_providers,
    socialLinks: state.business.social_links,
    loader: state.common.loader,
    isLoggedIn: state.mentions.isLoggedIn || {},
    socialSetupList: state.socialSetup.list,
    tagsList: state.business.tags_list,
    error: state.mentions.error,
    mentionsNumber: state.mentions.mentions,
  };
};

MentionsPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  commonActions: PropTypes.instanceOf(Object),
  businessActions: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(mentionsActions, dispatch),
    commonActions: bindActionCreators(CommonActions, dispatch),
    businessActions: bindActionCreators(businessActions, dispatch),
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
    reviewActions: bindActionCreators(ReviewsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(MentionsPage);
