// eslint-disable-next-line react/no-deprecated
import React from 'react';
import { bindActionCreators } from 'redux';
// import { Card, CardTitle } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString  from 'query-string';
import InsightsCategoryForm from '../../components/business/InsightsCategoryForm';
import LoaderComponent from '../../components/common/loaderComponent';

import * as BusinessSetupActions from '../../actions/business/businessSetupActions';

class InsightsCategoryEditPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { }
  } 
  
  submitData = (data) => {
    const {location: { search }, match: { params: { business_id } } } = this.props;
    const query = queryString.parse(search);
    const id = query.id ? query.id : null;
    return this.props.actions.postInsights(business_id, {...data, id});
  };

  componentDidMount() {
    const { match: { params: { business_id} } } = this.props;
    const { route: { location: { search} } } = this.context.router;
    const { id } = queryString.parse(search);
    if (id) this.props.actions.getInsightsDetails({business_id, id});
    this.props.actions.getRecommendedKeywords(business_id);
  }

  render() {
    const { insightDetail, recommended_keywords } = this.props;
    const { route: { location: { search } } } = this.context.router;
    const { id } = queryString.parse(search);
    const newData = {
      name: '',
      words: []
    };

    const formData = id ? insightDetail : {...newData};

    return (
      <div>
        <LoaderComponent loader={this.props.loader}/>
        {formData && <InsightsCategoryForm
          initialValues={formData}
          words={insightDetail && insightDetail.words}
          submitData={this.submitData}
          id={id}
          recommendedKeywords={recommended_keywords && recommended_keywords}
        />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    insightDetail : state.business.insight_detail,
    recommended_keywords: state.business.recommended_keywords
  };
};

InsightsCategoryEditPage.contextTypes = {
  router: PropTypes.instanceOf(Object)
};

InsightsCategoryEditPage.prototypes = {
  actions: PropTypes.instanceOf(Object)
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(InsightsCategoryEditPage);