import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import FPLabel from "../../../../components/common/new/forms/ui/FPLabel";
import { DeleteOutLineIcon } from "../../../../images/svg";

const FeedbackImageUpload = ({
  onChange,
  value = [],
  name,
  maxFiles = 10,
  maxSize = 5242880,
  defaultValue,
}) => {
  const [previewImages, setPreviewImages] = useState(
    value.map((file) => ({
      file,
      preview: typeof file === "string" ? file : URL.createObjectURL(file),
    }))
  );
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (Array.isArray(defaultValue) && defaultValue.length > 0) {
      const updatedImages = defaultValue.map((file) => ({
        file,
        preview:
          typeof file === "string"
            ? file 
            : file instanceof Blob 
            ? URL.createObjectURL(file)
            : "", 
      }));

      setPreviewImages(updatedImages);

        // if (onChange) {
        //   onChange({
        //     target: {
        //       name,
        //       value: updatedImages.map((item) => item.file),
        //     },
        //   });
        // }
    }
  }, [defaultValue, onChange, name]);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    const validFiles = files.filter((file) => file.size <= maxSize);

    const totalFiles = [
      ...previewImages,
      ...validFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      })),
    ].slice(0, maxFiles);

    setPreviewImages(totalFiles);

    if (onChange) {
      onChange({
        target: {
          name,
          value: totalFiles,
        },
      });
    }

    event.target.value = "";
  };

  const handleDelete = (index) => {
    const newImages = [...previewImages];

    if (
      newImages[index].preview &&
      !newImages[index].preview.startsWith("http")
    ) {
      URL.revokeObjectURL(newImages[index].preview);
    }

    newImages.splice(index, 1);
    setPreviewImages(newImages);

    if (onChange) {
      onChange({
        target: {
          name,
          value: newImages,
        },
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <FPLabel isRequired={false} className="mt-3">Attach any pictures or video you would like to share.</FPLabel>

      <Paper
        variant="outlined"
        sx={{
          py: 1,
          border: "1px dashed #ccc",
          borderRadius: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          mb: 1,
        }}
        onClick={handleUploadClick}
      >
        <Box
          sx={{
            background: "#F6F6F8",
            borderRadius: "50px",
            width: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "30px",
          }}
        >
          <AddCircleOutlineIcon
            sx={{
              width: "16px",
              height: "16px",
              fontSize: 48,
              color: "text.secondary",
            }}
          />
        </Box>
        <Typography variant="body1" sx={{ color: "#858D9D" }}>
          Click to upload
        </Typography>
        <Typography variant="body2" sx={{ color: "#989FAD", fontSize: "10px" }}>
          SVG, PNG, JPG (max. 800x400px)
        </Typography>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
          accept="image/*, video/*"
          multiple
        />
      </Paper>

      {previewImages.length > 0 && (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            borderRadius: 1,
            pt: 0,
          }}
        >{console.log(previewImages)}
          {previewImages.map((image, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(index)}
                >
                  <DeleteOutLineIcon style={{ color: "#00bcd4" }} />
                </IconButton>
              }
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: 2,
                mb: 1,
                py: 0,
              }}
            >
              <ListItemAvatar sx={{ minWidth: "35px !important" }}>
                <Avatar
                  src={image?.file?.preview}
                  variant="rounded"
                  sx={{
                    width: 25,
                    height: 25,
                    border: "1px solid #FAFAFB",
                    borderRadius: "50px",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                sx={{
                  "& .MuiListItemText-primary": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                primary={image?.file?.file?.name || `image ${index + 1}`}
                secondary={`${Math.round(image?.file?.file?.size / 1024)} KB`}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default FeedbackImageUpload;
