import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import * as ForgotPasswordActions from '../../actions/accounts/forgotPasswordActions';
import ForgotPasswordForm from '../../components/accounts/forgotPassword/ForgotPasswordForm';
import LoaderComponent from '../../components/common/loaderComponent';

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.mailSentSuccessful = this.mailSentSuccessful.bind(this);
  }

  componentDidMount() {
    document.title = 'Forgot Password';
  }

  handleForgotPassword(values) {
    return this.props.actions.forgotPasswordAction(values).then(() => {
      if (this.props.isMailSent) {
        this.mailSentSuccessful();
      }
    });
  }
  
  mailSentSuccessful() {
    this.props.history.push('/mail-sent-successful');
  }

  render() {
    // if(this.props.isMailSent) {
    //   this.mailSentSuccessful();
    // }
    return (
      <Row className="align-items-center justify-content-center login-bg full-height" >
        <LoaderComponent loader={this.props.loader}/>
        
        <Col xs={12}>
        <ForgotPasswordForm handleForgotPassword={this.handleForgotPassword} errors={this.props.errors} />
          {/* <Row className="justify-content-center">
            <Col xs={10} sm={10}>
              <ForgotPasswordForm handleForgotPassword={this.handleForgotPassword} errors={this.props.errors} />
            </Col>
          </Row> */}
        </Col>
      </Row>
    );
  }
}


const mapStateToProps = (state) => ({
  isMailSent: state.forgotPassword.isMailSent,
  errors:  state.forgotPassword.errors,
  loader:state.common.loader
});


ForgotPasswordPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object)
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ForgotPasswordActions, dispatch)
  };
}


export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(ForgotPasswordPage);
