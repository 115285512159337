import React from "react";
import LocationsList from "./LocationsList";
import { Link } from "react-router-dom";
import LoaderComponent from "../common/loaderComponent";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import {
  insightsLocationBgColors,
  insightsLocationColors,
} from "../../libs/constant";
import { gradingData } from "../../libs/utils";

const LocationsPage = (props) => {
  const { locationGrading, loader } = props;

  return (
    <div>
      <LoaderComponent loader={loader}></LoaderComponent>
      <CardWrapper
        headerTitle="Categories Wise Location Performance"
        className="py-2"
      >
        <div className="mx-20 mb-4 mt-4 d-flex align-items-center gap-20 justify-content-between flex-wrap">
          {gradingData.map((item) => {
            return (
              <div className="d-flex gap-6 align-items-center">
                <span
                  style={{
                    backgroundColor: `${
                      insightsLocationBgColors[item.grading]
                    }`,
                    border: `1px solid ${insightsLocationColors[item.grading]}`,
                    color: `${insightsLocationColors[item.grading]}`,
                  }}
                  className="grading-view"
                >
                  {item.grading}
                </span>
                <div className="d-flex flex-column align-items-center">
                  <span
                    style={{
                      color: "rgba(122, 129, 147, 1)",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    {item.status}
                  </span>
                  <span
                    style={{
                      color: "rgba(122, 129, 147, 1)",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    {item.count}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        {locationGrading && Object.keys(locationGrading).length ? (
          <LocationsList locationGrading={locationGrading} />
        ) : (
          <div className="mb-4" style={{ textAlign: "center" }}>
            <span>Click </span>
            <Link
              to={{
                pathname: "central-setup",
                state: { tab: "6" },
              }}
            >
              <span> here </span>
            </Link>
            <span>to Setup Insights First.</span>
          </div>
        )}
      </CardWrapper>
    </div>
  );
};

export default LocationsPage;
