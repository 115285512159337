import React from "react";
import { Line } from "react-chartjs-2";

const COLORS = ["#E697FF", "#FFA5CB", "#63ABFD", "#FF7373", "#FEC27C"];

const orderedKeys = [
  "CALL_CLICKS",
  "BUSINESS_BOOKINGS",
  "BUSINESS_DIRECTION_REQUESTS",
  "WEBSITE_CLICKS",
  "BUSINESS_FOOD_MENU_CLICKS",
];

const nameMapping = {
  CALL_CLICKS: "Calls Made",
  BUSINESS_BOOKINGS: "Bookings Made",
  BUSINESS_DIRECTION_REQUESTS: "Directions Requests",
  WEBSITE_CLICKS: "Website Clicks",
  BUSINESS_FOOD_MENU_CLICKS: "Menu Views",
};

const LineChartListing = ({ data, selectedBox }) => {
  const labels = Object.keys(data)
  .sort((a, b) => new Date(a) - new Date(b));

  const generateDatasets = () =>
    orderedKeys.map((key, index) => ({
      label: nameMapping[key],
      borderColor: COLORS[index % COLORS.length],
      backgroundColor: COLORS[index % COLORS.length],
      pointBackgroundColor: COLORS[index % COLORS.length],
      pointBorderColor: COLORS[index % COLORS.length],
      pointRadius: 3,
      fill: false,
      borderWidth: 1.5,
      tension: 0,
      data: labels.map((date) => data[date]?.[key] || 0),
    }));

  const datasets =
    selectedBox === "TOTAL_INTERACTIONS"
      ? generateDatasets()
      : generateDatasets().filter(
          (dataset) => dataset.label === nameMapping[selectedBox]
        );

  const chartData = { labels, datasets };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: { display: true, text: "Counts" },
        grid: {
          borderDash: [7],
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      x: {
        grid: { display: false },
        ticks: {
          callback: function (value) {
            const dateLabel = this.getLabelForValue(value);
            const [year, month, day] = dateLabel.split("-");
            return `${day}-${month}-${year}`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: selectedBox === "TOTAL_INTERACTIONS" ? true : false,
        position: "bottom",
        labels: {
          fontSize: 16,
          fontStyle: "normal",
          fontFamily: "Outfit",
          boxWidth: 6,
          boxHeight: 6,
          padding: 10,
          usePointStyle: true,
        },
      },
    },
    maintainAspectRatio: false,
    animation: { duration: 1000 },
  };

  return (
      <div style={{ height: "500px", width: "100%" }}>
        <Line data={chartData} options={options} />
      </div>
  );
};

export default LineChartListing;
