import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
// import { BsArrowDownUp } from 'react-icons/bs';
import moment from "moment";
import PaginationComponent from "../common/Pagination";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";

class CouponList extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { couponListData, location } = this.props;
    return (
      <>
        <CardWrapper showDivider={false}>
          <div className="ui-table-responsive">
            <Table className="fp-table fp-table-center">
              <TableHead>
                <TableRow textAlign="center">
                  <TableCell className="text-nowrap">Code </TableCell>
                  <TableCell className="text-nowrap">Discount Type </TableCell>
                  <TableCell className="text-nowrap">Discount </TableCell>
                  <TableCell className="text-nowrap">Expiry Date </TableCell>
                  <TableCell className="text-nowrap">Created at Branch</TableCell>
                  <TableCell className="text-nowrap">Created By </TableCell>
                  <TableCell className="text-nowrap">Created At </TableCell>
                  <TableCell className="text-nowrap">Customer</TableCell>
                  <TableCell className="text-nowrap">Issued Date </TableCell>
                  <TableCell className="text-nowrap">Redeemed</TableCell>
                  <TableCell className="text-nowrap">Redeem Date</TableCell>
                  <TableCell className="text-nowrap">Redeem at Branch</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {couponListData && couponListData.results && couponListData.results.length > 0 ? (
                  couponListData.results.map((coupon_item, coupon_index) => {
                    return (
                      <TableRow key={coupon_index}>
                        <TableCell>{coupon_item["code"]}</TableCell>
                        <TableCell>{coupon_item["discount_type"]}</TableCell>
                        <TableCell>{coupon_item["discount"]}</TableCell>
                        <TableCell>
                          {coupon_item["expiry_date"] ? moment(coupon_item["expiry_date"]).format("DD-MM-YYYY") : "-"}
                        </TableCell>
                        <TableCell>{coupon_item["branch"] ? coupon_item["branch"] : "-"}</TableCell>
                        <TableCell>{coupon_item["created_by"]}</TableCell>
                        <TableCell>{moment(coupon_item["created_at"]).format("DD-MM-YYYY")}</TableCell>
                        <TableCell>{coupon_item["customer"] ? coupon_item["customer"] : "-"}</TableCell>
                        <TableCell>
                          {coupon_item["issue_date"] ? moment(coupon_item["issue_date"]).format("DD-MM-YYYY") : "-"}
                        </TableCell>
                        <TableCell>{coupon_item["redeemed"] ? coupon_item["redeemed"] : "-"}</TableCell>
                        <TableCell>
                          {coupon_item["redeemed_date"]
                            ? moment(coupon_item["redeemed_date"]).format("DD-MM-YYYY")
                            : "-"}
                        </TableCell>
                        <TableCell>{coupon_item["redeem_branch"] ? coupon_item["redeem_branch"] : "-"}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell className="text-center" colSpan={11}>
                      No records Found{" "}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <PaginationComponent
            count={couponListData && couponListData.count}
            location={location}
            pageSizeCookie="offer_page"
            className="pb-0"
          />
        </CardWrapper>
      </>
    );
  }
}
export default CouponList;
