import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import FPLabel from "../common/new/forms/ui/FPLabel";
import { FPButton } from "../common/new";
import { renderAutoSelectField } from "../common/RenderAutoSelectField";

const validate = (values) => {
  // eslint-disable-line
  const errors = {};
  return errors;
};

class EnterpriseUserForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const { saveOwner } = this.props;
    return saveOwner(data).catch((err) => {
      const errobj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errobj);
    });
  }

  render() {
    const { submitting, handleSubmit, errors, userList } = this.props;

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        {errors && errors.non_field_errors
          ? errors.non_field_errors.map((error, index) => (
              <p key={index} className="text-danger">
                {error}
              </p>
            ))
          : null}
        <div>
          <FPLabel>Owner</FPLabel>
          <Field name="user" component={renderAutoSelectField} placeholder="Owner" options={userList} />
        </div>
        <FPButton disabled={submitting} type="submit" fullWidth className="mt-4" size="small">
          Submit
        </FPButton>
      </form>
    );
  }
}

EnterpriseUserForm = reduxForm({
  // eslint-disable-line
  form: "EnterpriseUserForm",
  validate,
  enableReinitialize: true,
})(EnterpriseUserForm);

EnterpriseUserForm = connect((state) => {
  // eslint-disable-line
  return {};
})(EnterpriseUserForm);

export default EnterpriseUserForm;
