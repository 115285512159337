import React, { useEffect, useState } from "react";
import { Card, CardTitle, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import LoaderComponent from "../common/loaderComponent";
import { connect } from "react-redux";
import * as profileAction from "../../actions/accounts/profileAction";
import { bindActionCreators } from "redux";
// import { render } from 'enzyme';
import cookie from "react-cookies";
import { FPButton, FPToolTip } from "../common/new";
import AddIcon from "@material-ui/icons/Add";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import deleteIcon from "../../images/svg/trash.svg";
import editIcon from "../../images/svg/EditPen.svg";
import ConfirmationModal from "../common/ConfirmationModal";
import classnames from "classnames";
import CentralInsightsFame from "./CentralInsightsFame";
import PaginationComponent from "../common/Pagination";

const SetupInsightsComponent = (props) => {
  const { addCategory, categoryList, deleteInsightsList, businessId, loader, location } = props;
  let list = categoryList && categoryList.results;
  const user = cookie.load("business_user");
  const [tab, setTab] = useState("1");
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmationFunction, setConfirmationFunction] = useState(() => console.log(""));

  useEffect(() => {
    props.actions.getProfileInfo();
  }, []); //eslint-disable-line

  const toggleTab = (val) => {
    setTab(val);
  };

  const toggleConfirm = (confirmFunction) => {
    setIsConfirmOpen(!isConfirmOpen);
    setConfirmationFunction(() => confirmFunction);
  };

  return (
    <div>
      {/* <p className="mb-0 fp-card-header my-4">Setup Insights</p> */}
      <LoaderComponent loader={loader} />
      <CardWrapper showDivider={false}>
        <Nav className="Setup_Sub_Nav">
          <NavItem>
            <NavLink
              className={classnames({ active: tab === "1" })}
              onClick={() => {
                toggleTab("1");
              }}
            >
              Standard Categories
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: tab === "2" })}
              onClick={() => {
                toggleTab("2");
              }}
            >
              Fame Ai Categories
            </NavLink>
          </NavItem>
        </Nav>
      </CardWrapper>
      {tab === "1" && (
        <div className="d-flex ml-1 justify-content-between align-items-center mt-4 mb-4">
          <span style={{ fontSize: "18px", fontWeight: "400", color: "rgba(100, 101, 101, 1)" }}>Setup Categories</span>
          <div>
            <FPButton
              label="Add Category"
              type="button"
              onClick={addCategory}
              style={{ height: "30px" }}
              endIcon={<AddIcon style={{ height: 16, width: 16, color: "white" }} />}
            />
          </div>
        </div>
      )}

      {tab === "1" &&
        (list && Array.isArray(list) ? (
          list.map((category, key) => {
            const word = category.name[0].toUpperCase() + category.name.slice(1);
            return (
              <CardWrapper
                key={key}
                style={{ border: "1px solid #d0d0d0" }}
                showDivider={false}
                className="mb-4"
                contentClass="pt-2 pb-3"
              >
                <Row className="pl-3 align-center mt-1 pb-2" style={{ borderBottom: "1px solid #d0d0d0" }}>
                  <Col sm="2" className="px-0" style={{ color: "grey" }}>
                    <span>Category Name</span>
                  </Col>
                  <Col sm="8" className="pl-0" style={{ fontWeight: "bold" }}>
                    <div>{word}</div>
                  </Col>
                  {user && user.is_business_owner && (
                    <Col sm="2">
                      <div className="actionButton justify-content-end">
                        <FPToolTip title={"Edit"}>
                          <Link to={`/${businessId}/setup-insights?id=${category.id}`}>
                            <img src={editIcon} role="button" alt="edit" />
                          </Link>
                        </FPToolTip>
                        <FPToolTip title={"Delete"}>
                          <img
                            src={deleteIcon}
                            onClick={() => toggleConfirm(() => deleteInsightsList(category))}
                            role="button"
                            alt="delete"
                          />
                        </FPToolTip>
                      </div>
                    </Col>
                  )}
                </Row>
                <Row className="mt-2">
                  <Col sm="2" className="pt-2" style={{ color: "grey" }}>
                    <span>Keywords</span>
                  </Col>
                  <Col sm="10" className="">
                    <div>
                      {category.words &&
                        category.words.map((keyword, key) => {
                          return (
                            <div className="category-word" key={key}>
                              {keyword}
                            </div>
                          );
                        })}
                    </div>
                  </Col>
                </Row>
              </CardWrapper>
            );
          })
        ) : (
          <Card body className="py-2 mt-2">
            <CardTitle className="mb-2">
              <span className="mt-2 d-inline-block" style={{ color: "grey" }}>
                No categories found
              </span>
            </CardTitle>
          </Card>
        ))}
      {tab === "2" && <CentralInsightsFame businessId={businessId} loading={false} />}

      {tab === "1" && (
        <PaginationComponent
          count={categoryList && categoryList.count}
          location={location}
          pageSizeCookie="c_insights"
        />
      )}

      <ConfirmationModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader={`Are you sure you want to delete this staff?`}
        isOpen={isConfirmOpen}
        toggle={toggleConfirm}
        onConfirm={confirmationFunction}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.profile.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(profileAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SetupInsightsComponent);
