import React from "react";
import { Bar } from "react-chartjs-2";

const COLORS = {
  LEAST_BUSY: "#EDE7F6",
  LITTLE_BUSY: "#D1C4E9",
  MODERATELY_BUSY: "#B39DDB",
  VERY_BUSY: "#7E57C2",
  BUSIEST: "#5E35B1",
};

const nameMapping = {
  LEAST_BUSY: "Least Busy",
  LITTLE_BUSY: "Little Busy",
  MODERATELY_BUSY: "Moderately Busy",
  VERY_BUSY: "Very Busy",
  BUSIEST: "Busiest",
};

const hourLabels = [
  "12AM", "1AM", "2AM", "3AM", "4AM", "5AM", "6AM", "7AM", "8AM", "9AM", "10AM", "11AM",
  "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM"
];

const HoursBarChart = (props) => {
  const { listing_popular_times } = props;

  // Use the provided hours data from the API
  const busynessData = listing_popular_times?.hours || [];

  // Find the minimum and maximum values from busynessData
  const minFootfall = Math.min(...busynessData);
  const maxFootfall = Math.max(...busynessData);

  // Function to map a value to a busyness level
  const getBusynessLevel = (value) => {
    const range = maxFootfall - minFootfall;
    const percent = (value - minFootfall) / range;

    if (percent < 0.2) return "LEAST_BUSY";
    if (percent < 0.4) return "LITTLE_BUSY";
    if (percent < 0.6) return "MODERATELY_BUSY";
    if (percent < 0.8) return "VERY_BUSY";
    return "BUSIEST";
  };

  // Generate the color array based on busyness levels for each hour
  const colors = busynessData.map((value) => COLORS[getBusynessLevel(value)]);

  const datasets = [
    {
      label: "Footfall Traffic Level",
      backgroundColor: colors,
      borderColor: colors,
      borderWidth: 3,
      borderRadius: 3,
      barThickness: 25,
      data: busynessData,
    },
  ];

  const chartData = { labels: hourLabels, datasets };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          borderDash: [7],
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      x: {
        grid: { display: false },
        ticks: {
          callback: function (value, index, values) {
            const label = hourLabels[index];
            if (
              label === "12AM" ||
              label === "6AM" ||
              label === "12PM" ||
              label === "6PM" ||
              label === "11PM"
            ) {
              return label;
            }
            return "";
          },
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const hour = context.label;
            const level = nameMapping[getBusynessLevel(busynessData[hourLabels.indexOf(hour)])];
            return level;
            // return `${label}: ${level}`;
          },
        },
      },
    },
    maintainAspectRatio: false,
    animation: { duration: 1000 },
  };

  return (
    <div style={{ height: "500px", width: "100%", position: "relative" }}>
      <Bar data={chartData} options={options} />
      {/* Custom Legend */}
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        {Object.entries(nameMapping).map(([key, name]) => (
          <span key={key} style={{ margin: "0 15px", display: "inline-block" }}>
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: COLORS[key],
                borderRadius: "50%",
                marginRight: "5px",
              }}
            ></span>
            {name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default HoursBarChart;
