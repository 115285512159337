import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ErrorModal = (props) => {
  const {showModal, error, modalCloseCallBack} = props;
  const [modal, setModal] = useState(false);
  useEffect(()=>{
    if(showModal === true){
      setModal(true);
    }
  },[showModal]);

  function toggle(){
    setModal(false);
    modalCloseCallBack(false);
  }
  
  return(
    <div className="messenger-assign-modal">
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} className="messenger-assign-modal-title">Info detail</ModalHeader>
        <ModalBody>
          <p>{error}</p>
        </ModalBody>
        <ModalFooter>
          <Button type='button' color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ErrorModal.propTypes = {
  showModal:PropTypes.bool.isRequired,
  error:PropTypes.string.isRequired,
  modalCloseCallBack:PropTypes.func.isRequired,
};

export default ErrorModal;
