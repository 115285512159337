import React from 'react';
// import { ReactMediaRecorder } from 'react-media-recorder';
// import { BsMic, BsMicMute } from 'react-icons/bs';
// import { MdOutlinePlayCircleOutline, MdPauseCircleOutline, MdOutlineDelete, MdSend } from 'react-icons/md';
import * as URLS from '../../libs/apiUrls';
// import * as utils from '../../libs/utils';
import axios_instance from '../../libs/interseptor';

class RecordView extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      recording: false,
      playing: false,
      minute: 0,
      second: 0,
      time: '00:00',
      timer: true,
      interval: null
    };
  }

    playAudio = () => {
      this.state.playing === false && this.setState({ playing: true });
      document.getElementById('recording').play();
      document.getElementById('recording').addEventListener('ended', () => {
        this.setState({ playing: false });
      });
    }

    stopAudio = () => {
      this.setState({ playing: false });
      document.getElementById('recording').pause();
    }

    manageTimer = () => {
      if (this.state.timer) {
        const interval = setInterval(() => {
          this.setState({interval:interval});
          this.setState({ second: this.state.second + 1 }, () => {
            if (this.state.second === 59) {
              this.setState({ second: 0, minute: this.state.minute + 1 });
            }
            this.setState({ time: `${this.state.minute < 10 ? '0' + this.state.minute : this.state.minute}:${this.state.second < 10 ? '0' + this.state.second : this.state.second}` });
          });
        }, 1000);
      }
    }

    start = () => {
      this.setState({ recording: true, timer: true },()=>this.manageTimer());
    }

    stop = () => {
      clearInterval(this.state.interval);
      this.setState({interval: null, minute: 0, second: 0, time: '00:00'});
    }

    uploadRecording = async (blobURL, clearBlobUrl) => {
      console.log('Recorder.js file ', this.props);
      const audioBlob = await fetch(blobURL).then((r) => r.blob());
      const audioFile = new File([audioBlob], 'voice.mp3', { type: 'audio/mp3' });
      const formData = new FormData(); 
      formData.append('description', '');
      formData.append('voice_comment', audioFile);
      axios_instance.post(`${URLS.TICKET}${this.props.businessId}/${this.props.ticketId}/comment/`,formData)
        .then(() =>{ 
          this.props.enableInput();
          this.props.singleTicketDetails(this.props.businessId, this.props.ticketId);
          clearBlobUrl();
        }
        )
        .catch(err => console.log(err));
    }

    render() {
      return (
        <div>
          {/* <ReactMediaRecorder
            audio
            onStop={() => this.setState({ recording: false, timer: false })}
            render={({ startRecording, stopRecording, mediaBlobUrl, clearBlobUrl }) => (
              <div style={{marginRight:'10px', marginTop: '7px'}}>
                {this.state.recording && <span >{this.state.time}</span>}
                {mediaBlobUrl === null ?
                  this.state.recording === false ?
                    <span onClick={this.props.enableInput} title="Record your voice comment"><span onClick={this.start}><BsMic onClick={startRecording} style={{ fontSize: '22px', cursor: 'pointer' }}></BsMic></span></span> :
                    <span onClick={this.stop}><BsMicMute onClick={stopRecording} style={{ fontSize: '22px', cursor: 'pointer' }}></BsMicMute></span>
                  :
                  <div>
                    {
                      this.state.playing === false ?
                        <MdOutlinePlayCircleOutline style={{ fontSize: '22px', paddingRight: '3px', cursor: 'pointer' }} onClick={() => this.playAudio()}></MdOutlinePlayCircleOutline>
                        :
                        <MdPauseCircleOutline style={{ fontSize: '22px', paddingRight: '3px', cursor: 'pointer' }} onClick={() => this.stopAudio()}></MdPauseCircleOutline>
                    }
                    <span onClick={this.props.enableInput}><MdOutlineDelete style={{ fontSize: '22px', paddingRight: '3px', cursor: 'pointer' }} onClick={clearBlobUrl}></MdOutlineDelete></span>
                    <MdSend style={{ fontSize: '22px', cursor: 'pointer' }} onClick={()=>this.uploadRecording(mediaBlobUrl, clearBlobUrl)}></MdSend>
                  </div>
                }
                <audio src={mediaBlobUrl} id='recording' />
              </div>
            )}
          /> */}
        </div>
      );
    }
}

export default RecordView;