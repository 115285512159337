import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Donut from '../common/new/Donut';
import { Card } from 'reactstrap';
import { setColorInChart } from '../../libs/helper';
import { useMediaQuery } from '@material-ui/core';

const SurveyGraph = ({ tableData }) => {
  const [groupedData, setGroupedData] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width: 1512px)');
  useEffect(() => {
    const groupArray = [];
    tableData && tableData.ques && tableData.ques.forEach((arr) => {
      if (arr.count !== 0) {
        const { question,count, option } = arr;
        
        const groupIndex = groupArray.findIndex((group) => group.question === question);
        if (groupIndex !== -1) {
          groupArray[groupIndex].items.push(arr);
          groupArray[groupIndex].datasets[0].data.push(count=='-'?1:count);
          groupArray[groupIndex].labels.push(option.toString());
          groupArray[groupIndex].legends.push(option.toString());
        } else {
          groupArray.push({
            question,
            items: [arr],
            legends: [option.toString()],
            labels: [option.toString()],
            datasets: [{ data: [count=='-'?1:count],backgroundColor: [] }],
          });
        }
      }
    });
    setGroupedData(groupArray);
    if(groupArray.length>0){
      groupArray.forEach((item,index)=>{
        item.datasets.forEach((data)=>{
          const backgroundColors = setColorInChart(index); 
          data.backgroundColor = backgroundColors;
        });
      });
    }
  }, [tableData]);

  return (
    <Row>
      {groupedData.map((item, index) => {
        const backgroundColors = item.datasets[0].backgroundColor;
        const lastColor = backgroundColors && backgroundColors[backgroundColors.length - 1];
        
        return(
          <Col xs={6} sm={6} key={index}>
            <Card body className="pt-3 mb-3">
              <div className="text-center mb-3">
                <h2 className="text-capitalize" style={{color:lastColor,fontWeight:500, fontSize: '22px'}}>{item.question}</h2>
              </div>
              <Donut 
                key={index}
                isLoading={false}
                cutout={isSmallScreen ? 95 : 105}
                 data={item}
                 width={isSmallScreen ? 450 : 600}
                 height={isSmallScreen ? 240 : 300}
                options={{
                   plugins: {
                    legend: {
                      display: true,
                      position: 'right',
                      labels:{
                        fontStyle: "normal",
                        fontSize: 16,
                        fontFamily: "Outfit",
                        boxWidth: 25,
                        boxHeight: 10,
                      }
                      
                    },
                    tooltip: {
                       callbacks: {
                         label: (context) => {
                           return `${context.label} : ${context.formattedValue}%`;
                        },
                    },
                    },
                   },
                 }}
              />
            </Card>
          </Col>);
      })}
    </Row>
  );
};

export default SurveyGraph;