import React from "react";
// import './FeedbackImage.module.css';
// import ChildFeedbackImage from './ChildFeedbackImage';
import { Avatar } from "antd";
// import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
// import { Avatar, Tooltip } from 'antd';
import ImagesViewer from "react-images-viewer";
import { useState } from "react";
const FeedbackImage = (props) => {
  let images = [];
  const IMG_SET = [];
  for (var i = 0; i < props.reviews.image_links.length; i++) {
    images.push(props.reviews.image_links[i]);
  }

  const image_object = [];
  images.map((item, idx) => {
    let obj = {
      id: `${idx + 1}`,
      count: `${idx + 1}`,
      target_link: item,
    };
    let objImg = {
      src: item,
    };
    image_object.push(obj);
    IMG_SET.push(objImg);
  });

  let temp = [];
  for (let i = 0; i < image_object.length; i++) {
    if (i < 5) {
      temp.push(image_object[i]);
    }
  }
  // const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  // const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleImage = () => {
    // setCurrentImageIndex(index);
    setViewerIsOpen(true);
  };

  const gotoPrevImg = () => {
    setCurrentImageIndex(currentImageIndex - 1);
  };

  const gotoNextImg = () => {
    setCurrentImageIndex(currentImageIndex + 1);
  };

  const closeImgsViewer = () => {
    setViewerIsOpen(false);
  };
  return (
    <div className="d-flex align-items-center position-relative mt-2">
      <div className="d-flex flex-column align-items-center position-relative">
        <Avatar.Group
          maxCount={3}
          maxPopoverTrigger="click"
          size="large"
          maxStyle={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            cursor: "pointer",
          }}
          style={{
            height: "42px",
          }}
        >
          {temp.map((user, index) => (
            <Avatar
              key={user.id}
              src={user.target_link}
              style={{
                position: "absolute",
                top: 0,
                left: `${index * 25}px`,
                zIndex: temp.length - index,
                border: "2px solid #fff",
                lineHeight: "normal",
              }}
              onClick={() => handleImage(index)}
            />
          ))}
        </Avatar.Group>
      </div>
      {temp.length > 3 ? (
        <h6
          style={{
            position: "absolute",
            top: "12px",
            left: temp.length > 2 ? "100px" : temp.length > 1 ? "70px" : "45px",
          }}
        >{`+${temp.length - 3} Photos`}</h6>
      ) : null}
      {/* ImagesViewer component */}
      {viewerIsOpen && (
        <ImagesViewer
          imgs={IMG_SET}
          thumbnail={IMG_SET}
          showThumbnails={true}
          isOpen={viewerIsOpen}
          currImg={currentImageIndex}
          onClickPrev={gotoPrevImg}
          onClickNext={gotoNextImg}
          onClose={closeImgsViewer}
          showImgCount={false}
          theme={{}}
          backdropCloseable={true}
          width={1024}
        />
      )}
    </div>
  );
};

export default FeedbackImage;
