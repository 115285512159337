import * as types from "../actionTypes";
import * as commonAction from "../common/commonAction";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import cookie from "react-cookies";

export const competitorsActions = (data, businessId) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.COMPETITORS_DETAILS, [businessId]), { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(competitorsDetailsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(competitorsDetailsFailure(err));
        throw err.reponse.data;
      });
  };
};

const competitorsDetailsSuccess = (data) => {
  return { type: types.GET_COMPETITORS_DETAIL_SUCCESS, data };
};

const competitorsDetailsFailure = (error) => {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_COMPETITORS_DETAIL_FAILURE, error };
};

export const competitorsInsights = (businessId, data) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.COMPETITORS_INSIGHTS, [businessId]), { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(competitorsInsightsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(competitorsInsightsFailure(err));
        throw err.response.data;
      });
  };
};

const competitorsInsightsSuccess = (data) => {
  return { type: types.GET_COMPETITORS_INSIGHTS_SUCCESS, data };
};

const competitorsInsightsFailure = (error) => {
  return { type: types.GET_COMPETITORS_INSIGHTS_FAILURE, error };
};

export const competitorsRating = (businessId, data) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.COMPETITORS_RATING, [businessId]), { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(competitorsRatingSucess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(competitorsRatingFaliure(err));
        throw err.response.data;
      });
  };
};
const competitorsRatingSucess = (data) => {
  return { type: types.GET_COMPETITORS_RATING_SUCCESS, data };
};

const competitorsRatingFaliure = (error) => {
  return { type: types.GET_COMPETITORS_RATING_FALIURE, error };
};

export const competitorsOverview = (businessId, data) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.COMPETITORS_OVERVIEW, [businessId]), { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(competitorsOverviewSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(competitorsOverviewFailure(err));
        console.log(err);
        throw err.response.data;
      });
  };
};

const competitorsOverviewSuccess = (data) => {
  return { type: types.GET_COMPETITORS_OVERVIEW_SUCCESS, data };
};

const competitorsOverviewFailure = (error) => {
  return { type: types.GET_COMPETITORS_OVERVIEW_FAILURE, error };
};

export function addCompetitorsSuccess(data) {
  return {
    type: types.ADD_COMPETITORS_SUCCESS,
    data,
  };
}

export function addCompetitorsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return {
    type: types.ADD_COMPETITORS_FAILURE,
    data,
  };
}

export function addCompetitors(data, businessId) {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(`${utils.format(URLS.COMPETITORS_LIST, [businessId])}`, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(addCompetitorsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(addCompetitorsFailure(err));
        throw err.response.data;
      });
  };
}

export function editCompetitorSuccess(data) {
  return {
    type: types.EDIT_COMPETITOR_SUCCESS,
    data,
  };
}

export function editCompetitorFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return {
    type: types.EDIT_COMPETITOR_FAILURE,
    data,
  };
}

export function editCompetitor(compData) {
  const { comps: data, business_id: businessId, compId: id } = compData;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .patch(utils.format(URLS.COMPETITOR_DATA, [businessId, id]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(editCompetitorSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(editCompetitorFailure(err));
        throw err.response.data;
      });
  };
}

export function getCompetitorsListSuccess(data) {
  return { type: types.GET_COMPETITORS_SUCCESS, data };
}
export function getCompetitorsListfailure(err) {
  let data = {};
  data.errors = err.response.data;
  return { type: types.GET_COMPETITORS_FAILURE, data };
}
export function getCompetitorsList(businessID, params) {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${utils.format(URLS.COMPETITORS_LIST, [businessID])}`, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getCompetitorsListSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getCompetitorsListfailure(err));
        throw err.response.data;
      });
  };
}

export function competitorSourceOptionsSuccess(data) {
  return { type: types.COMPETITOR_SOURCE_OPTIONS_SUCCESS, data };
}

export function competitorSourceOptionsFailure(err) {
  let data = {};
  data.errors = err.response.data;
  return { type: types.COMPETITOR_SOURCE_OPTIONS_FAILURE, data };
}

export function competitorSourceOptions() {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(URLS.COMPETITOR_SOURCE_OPTIONS)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(competitorSourceOptionsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(competitorSourceOptionsFailure(err));
        throw err.response.data;
      });
  };
}
export function deleteCompetitorSuccess(data) {
  return { type: types.DELETE_COMEPETITOR_SUCCESS, data };
}
export function deleteCompetitorFailure(err) {
  let data = {};
  data.errors = err.response.data;
  return { type: types.DELETE_COMPETITOR_FAILURE, data };
}
export function deleteCompetitor(businessID, id) {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .delete(utils.format(URLS.COMPETITOR_DATA, [businessID, id]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(deleteCompetitorSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(deleteCompetitorFailure(err));
        throw err.response.data;
      });
  };
}

export function postBranchCompetitorSuccess(data) {
  return { type: types.POST_BRANCH_COMPETITOR_SUCCESS, data };
}

export function postBranchCompetitorFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.POST_BRANCH_COMPETITOR_FAILURE, data };
}

export function postBranchCompetitor(branchId, data) {
  const { ...rest } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(utils.format(`${URLS.COMPETITORS_BRANCH_LIST}`, [branchId]), rest)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(postBranchCompetitorSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(postBranchCompetitorFailure(err));
        throw err.response.data;
      });
  };
}

export function editBranchCompetitorSuccess(data) {
  return { type: types.EDIT_BRANCH_COMPETITOR_SUCCESS, data };
}

export function editBranchCompetitorFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.EDIT_BRANCH_COMPETITOR_FAILURE, data };
}

export function editBranchCompetitor(branchId, data) {
  const { id, ...rest } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .patch(utils.format(URLS.COMPETITOR_LOCATION_DATA, [branchId, id]), rest)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(editBranchCompetitorSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(editBranchCompetitorFailure(err));
        throw err.response.data;
      });
  };
}

export function getCompetitorsOptionsSuccess(data) {
  return {
    type: types.GET_COMPETITORS_OPTIONS_SUCCESS,
    data,
  };
}

export function getCompetitorsOptionsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_COMPETITORS_OPTIONS_FAILURE, data };
}

export function getCompetitorsOptions(businessId) {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.COMPETITORS_OPTIONS, [businessId]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getCompetitorsOptionsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getCompetitorsOptionsFailure(err));
        throw err.response.data;
      });
  };
}

export function getCompetitorLinksDetailsSuccess(data) {
  return {
    type: types.GET_COMPETITOR_LINKS_DETAILS_SUCCESS,
    data,
  };
}

export function getCompetitorLinksDetailsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_COMPETITOR_LINKS_DETAILS_FAILURE, data };
}
export function getCompetitorLinksDetails(comp_id, branch_id) {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(`${URLS.COMPETITOR_LINKS_DETAILS}`, [branch_id, comp_id]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getCompetitorLinksDetailsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getCompetitorLinksDetailsFailure(err));
        throw err.response.data;
      });
  };
}

export function postCompetitorLinksSuccess(data) {
  return {
    type: types.POST_COMPETITOR_LINKS_SUCCESS,
    data,
  };
}

export function postCompetitorLinksFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.POST_COMPETITOR_LINKS_FAILURE, data };
}
export function postCompetitorLinks(data) {
  const { branch_id, compLoc, ...rest } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .patch(utils.format(`${URLS.COMPETITOR_LINKS_DETAILS}`, [branch_id, compLoc]), rest)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(postCompetitorLinksSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(postCompetitorLinksFailure(err));
        throw err.response.data;
      });
  };
}

export function getBranchCompetitorsSuccess(data) {
  return { type: types.GET_BRANCH_COMPETITORS_SUCCESS, data };
}

export function getBranchCompetitorsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_BRANCH_COMPETITORS_FAILURE, data };
}
export function getBranchCompetitors(branchId) {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(`${URLS.COMPETITORS_BRANCH_LIST}`, [branchId]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getBranchCompetitorsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getBranchCompetitorsFailure(err));
      });
  };
}

export function getBranchCompDetailssSuccess(data) {
  return { type: types.GET_BRANCH_COMP_DETAILS_SUCCESS, data };
}

export function getBranchCompDetailsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_BRANCH_COMP_DETAILS_FAILURE, data };
}
export function getBranchCompDetails(branchId, id) {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.COMPETITOR_LOCATION_DATA, [branchId, id]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getBranchCompDetailssSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getBranchCompDetailsFailure(err));
      });
  };
}

export function deleteBranchCompetitorSuccess(data) {
  return { type: types.DELETE_BRANCH_COMEPETITOR_SUCCESS, data };
}
export function deleteBranchCompetitorFailure(err) {
  let data = {};
  data.errors = err.response.data;
  return { type: types.DELETE_BRANCH_COMPETITOR_FAILURE, data };
}
export function deleteBranchCompetitor(branchId, id) {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .delete(utils.format(URLS.COMPETITOR_LOCATION_DATA, [branchId, id]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(deleteBranchCompetitorSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(deleteBranchCompetitorFailure(err));
        throw err.response.data;
      });
  };
}

export const downloadReport = (url) => {
  const token = cookie.load("authToken");
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        dispatch(commonAction.hideLoader());
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "competitor_rating_file.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        return err;
      });
  };
};
