/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PropTypes from "prop-types";
import cookie from "react-cookies";
import * as utils from "../../libs/utils";
import * as ProfileActions from "../../actions/accounts/profileAction";
import * as commonAction from "../../actions/common/commonAction";

import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import * as ChangePasswordActions from "../../actions/accounts/changePasswordActions";
import * as CampaignActions from "../../actions/campaign/campaignActions";
import ReviewInviteForm from "../../components/campaign/ReviewInviteForm";

import FormModal from "./FormModal";
import UserForm from "../accounts/profile/profileForm";
import ChangePasswordForm from "../accounts/changePassword/ChangePasswordForm";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, CssBaseline, IconButton, Popover, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import { FPButton, FPMenu } from "./new";
import axios_instance from "../../libs/interseptor";
import SideNav from "./SideNav";
import { Query, RestaurantProvider, UserAvatar } from "../../modules/navbar";
import PageSummary from "../../modules/navbar/pagesummary/PageSummary";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import * as URLS from "../../libs/apiUrls";
import { ReactComponent as NotificationsOutlinedIcon } from "../../images/svg/NotificationsOutlinedIcon.svg";
import { ReactComponent as HomeOutlinedIcon } from "../../images/svg/HomeOutlinedIcon.svg";
import NotificationUI from "../../containers/Notifications/NotificatioinUI";
import * as routerStateAction from "../../actions/common/routerStateAction";

const drawerWidth = 230;
const barHeight = 75;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#ffffff",
    boxShadow: "none",
    minHeight: `${barHeight}px`,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    "& .MuiToolbar-regular": {
      minHeight: `${barHeight}px`,
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    minHeight: `${barHeight}px !important`,
  },

  content: {
    flexGrow: 1,
    padding: "0",
    overflow: "auto",
    paddingLeft: "24px",
    paddingRight: "18px",
    marginRight: "6px",
  },
}));

const NavBar = (props) => {
  const {
    business,
    match,
    actions,
    businessAction,
    commonActions,
    changePasswordActions,
    campaignActions,
    userq,
    business_userq,
    history,
    branchId,
    children,
    isShowSideBar = false,
    businessList,
    selectedTabActions,
    selectedTab,
    lockedModules,
    isStaff
  } = props;
  const {
    path,
    params: { branch_id, agency_id, business_id },
  } = match;
  const [isOpen, setIsOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [isGuestOpen, setIsGuestOpen] = useState(false);
  const [isManagerCardOpen, setIsManagerCardOpen] = useState(false);
  const [isBusinessOpen, setIsBusinessOpen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = React.useState(isShowSideBar);
  const [isHomePage, setIsHomePage] = useState(false);
  const [businessDetails, setBusinessDetails] = useState(null);

  const user = userq || cookie.load("user");
  const business_user = business_userq || cookie.load("business_user") || {};
  const { is_superuser } = cookie.load("user") || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (business_id) {
      businessAction.businessDetail({ id: business_id }).then((res) => {
        setBusinessDetails(res);
      });
    }
  }, [business_id]);

  useEffect(() => {
    if (+branch_id) {
      cookie.save("branch", branch_id, 
        { 
          path: '/',
          maxAge: 365 * 24 * 60 * 60,
        }
      );
    }
  }, [match]);

  const ManageTeamsPage = () => {
    let agency = agency_id;
    if (agency === undefined) {
      agency = user.marketing_id;
    }
    const url = "/" + agency + "/" + "agency-team";
    history.push(url);
  };
  const service_category = cookie.load("service_category");

  const handleRootTabChange = (value) => {
    selectedTabActions.setSelectedTab(value);
    if (user?.enable_sr_module) {
      if (value === 0) {
        history.push(`/${business_id}/ticket-service-overview?date_type=prior_30_day`);
      }
    } else {
      if (service_category !== "Restaurant") {
        if (value === 0) {
          history.push(`/${business_id}/dashboard?date_type=prior_30_day`);
        } else if (value === 1) {
          history.push(`/${business_id}/messenger`);
        } else if (value === 2) {
          history.push(`/${business_id}/listing-overview`);
        } else if (value === 3) {
          console.log("123");
          history.push(`/${business_id}/ticket-service-overview?date_type=prior_30_day`);
        }
      } else {
        if (value === 0) {
          history.push(`/${business_id}/dashboard?date_type=prior_30_day`);
        } else if (value === 1) {
          history.push(`/${business_id}/messenger`);
        } else if (value === 2) {
          history.push(`/${business_id}/listing-overview`);
        } else if (value === 3) {
          history.push(`/${business_id}/restro-pilot?date_type=prior_30_day&type=${"daily"}`);
        } else if (value === 4) {
          history.push(`/${business_id}/ticket-service-overview?date_type=prior_30_day`);
        }
      }
    }
  };

  const toggle = () => {
    if (!isOpen) {
      Promise.all([
        actions.getProfileInfo(),
        commonActions.rationsOptions(),
        commonActions.webportalOptions(),
        commonActions.scheduleOptions(),
      ]).then(() => setIsOpen(true));
    }
    setIsOpen(!isOpen);
  };

  const toggleBusiness = () => {
    Promise.all([
      actions.getBusinessProfile(business_id),
      commonActions.rationsOptions(),
      commonActions.webportalOptions(),
      commonActions.scheduleOptions(),
    ]);
  };

  const saveProfile = (values, businessId) => {
    return actions.profileAction(values, businessId).then(() => {
      businessId ? toggleBusiness() : toggle();
    });
  };

  const togglePasswordModal = () => {
    setPasswordModalOpen(!passwordModalOpen);
  };

  const changePassword = (values) => {
    return changePasswordActions.changePasswordAction(values).then(() => togglePasswordModal());
  };

  const toggleGuestModal = () => {
    setIsGuestOpen(!isGuestOpen);
  };

  const sendInvite = (data, branchId) => {
    return campaignActions.reviewInvite(branchId, data).then(() => toggleGuestModal());
  };

  const uploadImage = (image, businessId) => {
    return actions
      .uploadProfileImage(image, businessId)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const openPayments = () => {
    history.push({
      pathname: `${path.replace(":business_id", business_id)}/payments`,
    });
  };

  const logOut = async function (history) {
    try {
      await axios_instance.post(`${URLS.LOGOUT}`);
      utils.clearCookie();
      history.push("/login");
    } catch (err) {
      throw err;
    }
  };

  const classes = useStyles();

  const handleDrawerOpen = () => {
    setIsSideBarOpen(true);
  };

  const handleDrawerClose = () => {
    setIsSideBarOpen(false);
  };

  const [userEasyAccessList, setUerEasyAccessList] = useState([]);

  const myProfilePage = () => {
    const agency = agency_id === undefined ? user.marketing_id : agency_id;
    let url;
    if (!business_id && agency) {
      url = "/" + agency + "/" + "account-settings";
    } else if (business_id && agency) {
      toggleBusiness();
      url = "/" + business_id + "/" + agency + "/" + "account-settings";
    } else {
      toggleBusiness();
      url = "/" + business_id + "/" + "account-settings";
    }
    history.push(url);
  };

  useEffect(() => {
    setUerEasyAccessList(() => []);
    const addItem = (label, onClick) => ({
      label,
      onClick,
    });

    let updatedList = [];

    // if (user.is_business_owner) {
    //   updatedList.push(addItem("Payments", openPayments));
    // }

    // if (user.business_type === "marketing") {
    //   updatedList.push(addItem("Manage Teams", ManageTeamsPage));
    //   if (business_id) {
    //     updatedList.push(addItem("Business Settings", toggleBusiness));
    //   }
    // }

    // updatedList = [
    //   addItem("User Settings", toggle),
    //   ...updatedList,
    //   addItem("Reset Password", togglePasswordModal),
    //   addItem("Log Out", () => logOut(history)),
    // ];

    updatedList = [
      addItem(
        <Box
          sx={{
            color: "#7A8193",
            fontWeight: "500",
            padding: "40px auto !important",
          }}
          className="paddingTest"
        >
          Account Settings
        </Box>,
        myProfilePage
      ),
      addItem(<Box sx={{ color: "#F04438", fontWeight: "500" }}>Log Out</Box>, () => logOut(history)),
    ];

    setUerEasyAccessList((prevValue) => [...prevValue, ...updatedList]);
  }, [business_id]);

  useEffect(() => {
    if (
      business &&
      ((!!business.detail && !!business.detail.marketing_id) || (!!business.list && !!business.list.choices))
    ) {
      return setIsHomePage(() => utils.isActivePage("agency-brands"));
    }else if (user?.enable_sr_module) {
      setIsHomePage(() => utils.isActivePage("ticket-service-overview"))
    }else {
      return setIsHomePage(() => utils.isActivePage("dashboard"));
    }
  }, [business]);

  const handleClick = (event) => {
    setAnchorEl(!anchorEl);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const seeAllNotification = () => {
    props.routerStateAction.setRouterState({ activeTab: "6" });
    myProfilePage();
    setAnchorEl(null);
  };

  const hideProvider =
    path === "/:agency_id/account-settings" ||
    utils.isActivePage("agency-brands") ||
    utils.isActivePage("agency-staff-dashboard") ||
    location.pathname === "/admin";

  const uploadLogo = (event) => {
    event.preventDefault();
    const userImage = event.target.files[0];
    let data = new FormData();
    data.append("logo", userImage, userImage.fileName);
    axios_instance
      .patch(`${URLS.BUSINESS_SETUP}${business_id}/`, data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then(() => {
        utils.displayMessage("positive", "Business Logo Updated");
        businessAction.businessDetail({ id: business_id }).then((res) => {
          setBusinessDetails(res);
        });
      })
      .catch((err) => {
        utils.displayMessage("negative", err || "Error Updating Logo");
      });
  };
  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={`${classes.appBar} ${isSideBarOpen ? classes.appBarShift : ""}`}>
        <Toolbar>
          <nav className={"d-flex w-100 justify-content-between align-items-center gap-10"}>
            <div>
              {isShowSideBar && (
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={`${classes.menuButton} ${isSideBarOpen ? classes.hide : ""}`}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <PageSummary
                isSideBarOpen={isSideBarOpen}
                history={history}
                location={location}
                business_id={business_id}
                handleRootTabChange={handleRootTabChange}
                defaultTab={selectedTab}
                match={match}
                lockedModules={lockedModules}
              />
            </div>
            <section className="d-flex align-items-center gap-10">
              {!hideProvider && (
                <RestaurantProvider
                  history={history}
                  businessList={businessList}
                  showList={user?.multi_business_access}
                  uploadLogo={uploadLogo}
                  isStaff={isStaff}
                />
              )}
              {(business_user.single_location || branchId) && (
                <div className="mt-1 mr-2">
                  <FPButton
                    onClick={toggleGuestModal}
                    label="Guest Review Invite"
                    className="ml-auto"
                    backgroundColor="#00B5AD"
                  />
                </div>
               )}
              <NavLink to={"/"} className={`${isHomePage ? "active-button" : ""}`}>
                <IconButton component="span" className="circular-button">
                  {/* <img src={HomeOutlinedIcon} /> */}
                  <HomeOutlinedIcon />
                </IconButton>
              </NavLink>
              <div>
                <IconButton component="span" className="circular-button">
                  {/* <img src={NotificationsOutlinedIcon} /> */}
                  <NotificationsOutlinedIcon />
                </IconButton>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: { width: "350px", marginTop: "45px" },
                  }}
                >
                  <NotificationUI
                    notificationHeading={true}
                    headerTitle={false}
                    ellipse={false}
                    dateTimeI={true}
                    contentClass=""
                    tableHead={false}
                    dividerClass="mb-0"
                    tableBodyRow="ml-2"
                    ticketMsgClass="mr-3"
                    tableBodyRowII="ml-2"
                    onClick={() => seeAllNotification()}
                  />
                </Popover>
              </div>
              <Query
                isManagerCardOpen={isManagerCardOpen}
                onClientClick={() => setIsManagerCardOpen(() => !isManagerCardOpen)}
              />
              <UserAvatar user={user} userEasyAccessList={userEasyAccessList} />
            </section>
          </nav>
        </Toolbar>
      </AppBar>

      {isShowSideBar && (
        <SideNav
          businessDetails={businessDetails}
          match={match}
          showSideNav={isSideBarOpen}
          handleDrawerClose={handleDrawerClose}
          isSideBarOpen={isSideBarOpen}
          classes={classes}
          location={location}
          defaultTab={selectedTab}
          history={history}
        />
      )}

      {children && (
        <main className={`${classes.content}`}>
          {/* <div className={classes.toolbar} /> */}
          {children}
        </main>
      )}

      {isOpen && (
        <FormModal isOpen={isOpen} toggle={toggle} heading="User Profile" size="lg">
          <UserForm
            hideHeading
            profileSave={saveProfile}
            initialValues={user}
            uploadImage={(image) => uploadImage(image)}
            showEmailAlerts={user.business_type !== "marketing"}
            showScheduleAlerts={user.business_type !== "marketing"}
          />
        </FormModal>
      )}
      {isBusinessOpen && (
        <FormModal isOpen={isBusinessOpen} toggle={toggleBusiness} heading="Business Profile" size="lg">
          <UserForm
            hideHeading
            profileSave={(data) => saveProfile(data, business_user.business)}
            initialValues={business_user}
            uploadImage={(image) => uploadImage(image, business_user.business)}
            showScheduleAlerts
            showEmailAlerts
          />
        </FormModal>
      )}
      {passwordModalOpen && (
        <FormModal isOpen={passwordModalOpen} toggle={togglePasswordModal} heading="Reset Password">
          <ChangePasswordForm handleChangePassword={changePassword} />
        </FormModal>
      )}
      {isGuestOpen && (
        <FormModal isOpen={isGuestOpen} toggle={toggleGuestModal} heading="Guest Review Invite" width={"762px"} height={"335px"} paddingDense={true}>
          <ReviewInviteForm sendInvite={(data) => sendInvite(data, business_id)} businessId={business_id} />
        </FormModal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    business: state.business,
    loader: state.common.loader,
    userq: state.profile.user,
    business_userq: state.profile.business_user,
    selectedTab: state.selectedTab.selectedTab,
    lockedModules: state.business.lockedModules || {},
  };
};

NavBar.propTypes = {
  actions: PropTypes.instanceOf(Object),
  businessAction: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  business: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ProfileActions, dispatch),
    businessAction: bindActionCreators(BusinessSetupActions, dispatch),
    commonActions: bindActionCreators(commonAction, dispatch),
    changePasswordActions: bindActionCreators(ChangePasswordActions, dispatch),
    campaignActions: bindActionCreators(CampaignActions, dispatch),
    selectedTabActions: bindActionCreators(setSelectedTab, dispatch),
    routerStateAction: bindActionCreators(routerStateAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(NavBar);
