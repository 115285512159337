import { Box } from "@material-ui/core";
import React from "react";
import { Col, Row } from "reactstrap";
import heroImage from "../../images/heroImage.png";
import backIcon from "../../images/svg/backIcon.svg";
import { Link } from "react-router-dom";

function AuthLayout(props) {
  const { logoIcon, title, textMessage, linkText, form } = props;

  const linkStyle = {
    display: "flex",
    justifyContent: "center",
    color: "#667085",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "#667085",
    },
  };

  return (
    <Row className="bg-white vh-100 max-vw-100 overflow-hidden">
      <Col
        sm={12}
        md={6}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        {logoIcon && 
        <div className="mb-3">
          <img src={logoIcon} alt="" />
        </div>
        }
        <h3 className="text-center mb-0 font-blue mb-4" style={{}}>
          {title}
        </h3>
        <Box sx={{ width: "390px" }}>
          {textMessage && (
            <Box
              sx={{
                color: "#7A8193",
                fontSize: "14px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              {textMessage}
            </Box>
          )}
          {form && <Box sx={{ marginTop: "" }}>{form}</Box>}
          {linkText && (
            <Link to="/login" style={linkStyle}>
              {" "}
              <img src={backIcon} /> &nbsp;&nbsp; {linkText}
            </Link>
          )}
        </Box>
      </Col>
      <Col
        sm={12}
        md={6}
        className="d-md-block d-none h-100  max-vw-100 hero-image-wrapper"
      >
        <img className="h-100 object-fill hero-image" src={heroImage} alt="" />
      </Col>
    </Row>
  );
}

export default AuthLayout;
