import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import { Collapse } from "reactstrap";
import { Card, Icon } from "semantic-ui-react";
import { IconButton } from "@material-ui/core";
import { FPMenu } from "../../components/common/new";
import FPMenuItem from "../../components/common/new/FPMenu/FPMenuItem";
import {ReactComponent as QueryOutlinedIcon} from "../../images/svg/QueryOutlinedIcon.svg";

export default function Query({ isManagerCardOpen, onClientClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        component="span"
        onClick={handleClick}
        className="circular-button"
      >
        {/* <img src={QueryOutlinedIcon} /> */}
        <QueryOutlinedIcon />
      </IconButton>
      <FPMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <FPMenuItem
          onClick={() => window.open("http://famepilot.com/knowledge%20base")}
          showDivider={false}
        >
          <ListItemText primary="Knowledge Base" />
        </FPMenuItem>
        <FPMenuItem
          onClick={onClientClick}
          className="flex-column"
          showDivider={false}
        >
          <ListItemText primary="Contact Client Manager" />
          <Collapse isOpen={isManagerCardOpen}>
            <Card link className="account-mngr-card mt-8">
              <Card.Header>
                <div className="text-truncate">
                  <i className="fas fa-user-cog side-bar-icon" />
                  <span className="sub-heading ml-1">Contact to Admin</span>
                </div>
              </Card.Header>
              <Card.Meta>
                <div>Client success manager</div>
                <div>
                  <Icon name="mobile alternate" />
                  <span className="ml-1">+919354044413</span>
                </div>
                <div className="text-truncate">
                  <Icon name="mail outline" />
                  <a href="mailto:info@famepilot.com" className="ml-1">
                    info@famepilot.com
                  </a>
                </div>
              </Card.Meta>
            </Card>
          </Collapse>
        </FPMenuItem>
      </FPMenu>
    </div>
  );
}
