import * as types from '../../actions/actionTypes';

export default function contact(state=[], action) {
  switch (action.type) {
    case types.NEWS_LETTER_LIST_SUCCESS:
      return Object.assign({}, state, { list: action.data });
    case types.NEWS_LETTER_LIST_FAILURE:
      return Object.assign({}, state, action.data);
    case types.GET_NEWS_LETTER_DETAILS_SUCCESS:
      return Object.assign({}, state, { detail: action.data });
    case types.GET_NEWS_LETTER_DETAILS_FAILURE:
      return Object.assign({}, state, { detail: action.data });
    case types.POST_NEWS_LETTER_SUCCESS:
      return Object.assign({}, state, { detail: {...action.data}});
    case types.NEWS_LETTER_DELETE_SUCCESS:
      return Object.assign({}, state, { detail: {...action.data}});
    case types.NEWS_LETTER_DELETE_FAILURE:
      return Object.assign({}, state, { detail: {}});
    case types.NEWS_LETTER_CLONE_SUCCESS:
      return Object.assign({}, state, { detail: {...action.data}});
    case types.NEWS_LETTER_CLONE_FAILURE:
      return Object.assign({}, state, { detail: {}});
    default:
      return state;
  }
}