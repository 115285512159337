/* eslint-disable */
import React from "react";
import moment from "moment";
import { Icon } from "semantic-ui-react";
import Googlebusiness from "../../images/google.png";
import { Avatar, Divider } from "antd";
import { MessageBox } from "../common/new";
import { Redirect } from "../../images/svg";
import { Box, IconButton } from "@material-ui/core";

// const showUserAvatar = () => {
//   return (

//   )
// }

const Answer = ({ question }) => {
  let len = Object.keys(question).length;
  return len > 0 ? (
    <Box>
      <div className="d-flex justify-content-between">
        <div className="userChatRow">
          {question.author.profilePhotoUri ? (
            <Avatar
              className="mr-2"
              size={40}
              src={question.author.profilePhotoUri}
            />
          ) : (
            <Avatar>
              {question.author.displayName
                ? avatarText(question.author.displayName)
                : "Anonymous"}
            </Avatar>
          )}
          <div className="topusernamecol">
            <h5 className="">{question?.author?.displayName || "Anonymous"}</h5>
            <div className="imagetop">
              <img src={Googlebusiness} alt="logo" />
              <span className="line-height-1">Google Q&A</span>
              <p className="line-height-1">({question.branch})</p>
            </div>
          </div>
        </div>
        <span
          onClick={() => {
            question.provider_link
              ? window.open(question.provider_link, "_blank")
              : null;
          }}
          role="button"
          className="cursor-pointer"
        >
          <Redirect />
        </span>
      </div>

      <MessageBox isSelfBox={false} className={"mt-4"}>
        {question.text}
      </MessageBox>
      <div className="my-3">
        <p className="font-blue-gray mb-1 text-right">
          {question.answers.length}
          {question.answers.length === 1 ? " Answer  |  " : " Answers  |  "}
          {moment(question.create_time).format("YYYY-MM-DD")}
        </p>
        <Divider className="m-0" />
      </div>

      <Box
        sx={{ height: "39vh", overflow: "auto" }}
        className="d-flex flex-column gap-20 mb-2"
      >
        {Array.isArray(question.answers) &&
          question.answers.map((answer, index) => (
            <div className="d-flex" key={answer.id}>
              {answer.author.profilePhotoUri ? (
                <Box sx={{ width: 30, height: 30 }}>
                  <Avatar
                    className="mr-2"
                    size={30}
                    src={answer.author.profilePhotoUri}
                  />
                </Box>
              ) : (
                <Box sx={{ width: 30, height: 30 }}>
                  <Avatar>
                    {answer.author.displayName
                      ? avatarText(answer.author.displayName)
                      : "Anonymous"}
                  </Avatar>
                </Box>
              )}

              <div className="fp-flex-grow-1 ml-2">
                <MessageBox
                  isSelfBox={false}
                  time={`${moment(answer.create_time).fromNow(true)} ago`}
                  author={answer.author.displayName}
                >
                  {answer.text}
                </MessageBox>
              </div>
            </div>
          ))}
      </Box>
    </Box>
  ) : (
    <div
      className="card"
      style={{ height: "58.5vh", marginTop: "2px", backgroundColor: "white" }}
    ></div>
  );
};

export default Answer;
