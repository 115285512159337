import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';



class RedirectPage extends React.Component {

  constructor(props){ super(props); }  // eslint-disable-line

  render(){
    console.log(this.props, 'props of redirect');
    const { location} = this.props;
    console.log(location, 'location of page props of redirect');
    const data = { ...queryString.parse(location.search), provider: 'google' };
    console.log(data, 'data in redirect props of redirect');
    try{
      localStorage.setItem('x_data', JSON.stringify(data));
      if (window.opener) {
        window.opener.postMessage(data, '*');
      }
      window.close();
    }catch(error){
      window.close();
      return(
        <h1>You are not allowed to open this page. Please try again later.</h1>
      );
    }
    return (
      <h1>Please wait, we are doing some awsm work for you.123</h1>
    );
  }
}


RedirectPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};

RedirectPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default RedirectPage;
