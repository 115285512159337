import React, { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import moment from "moment";
import { BiSortAlt2 } from "react-icons/bi";
import { MdFilterAlt } from "react-icons/md";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import LoaderComponent from "../../common/loaderComponent";
import axios_instance from "../../../libs/interseptor";
import * as URLS from "../../../libs/apiUrls";
import { displayMessage } from "../../../libs/utils";
import { Popover } from "@material-ui/core";
import { Divider } from "semantic-ui-react";

const ConnectedProfile = ({
  data,
  filterOptions,
  handleFilterApi,
  sortOptions,
  loader,
  callSocialProfileApi,
  // socialProfile,
  // handleConnectProfile,
}) => {
  const [mainData, setMainData] = useState([]);
  const [totalShownProfiles, setTotalShownProfiles] = useState(9);
  const [filterSelected, setFilterSelected] = useState(null);
  const [sortSelected, setSortSelected] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSort, setIsSort] = useState(false);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMainData(data);
  }, [data]);

  useEffect(() => {
    setFilterSelected(null);
    setSortSelected(null);
  }, []); 

  const handleLogout = (event, value, index) => {
    event.preventDefault();
    setLoading(true);
    const payload = {
      business_id: value.business_id,
      profile_id: value.id,
      web_portal_id: value.web_portal_id,
    };
    axios_instance
      .post(`${URLS.SOCIAL_PROFILE_INTEGRATION}logout/`, payload)
      .then((res) => {
        displayMessage("positive", "Logout Successfully !");
        callSocialProfileApi();
        handleFilterApi(filterSelected, sortSelected);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        displayMessage("negative", "Error !");
        setLoading(false);
      });
  };

  const handleClickSort = (id) => {
    setSortSelected(id);
    handleFilterApi(filterSelected, id);
    setIsSort(!isSort);
    setAnchorElSort(null);
  }

  const handleClickFilter = (id) => {
    setFilterSelected(id);
    handleFilterApi(id, sortSelected);
    setIsFilter(!isFilter);
    setAnchorEl(null);
  }

  return (
    <div>
      <LoaderComponent loader={loading} />
      <div
        className="mx-2 mb-4"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        <span>Connected Profiles</span>
        <span style={{ display: "flex", gap: "10px" }}>
          <MdFilterAlt
            onClick={(e) => {
              setIsFilter(!isFilter);
              setAnchorEl(anchorEl ? null : e.currentTarget);
            }}
            style={{ cursor: "pointer", fontSize: "16px", fontWeight: "400", color: "gray" }}
          />

          <BiSortAlt2
            onClick={(e) => {
              setIsSort(!isSort);
              setAnchorElSort(anchorEl ? null : e.currentTarget);
            }}
            style={{ cursor: "pointer", fontSize: "16px", fontWeight: "400", color: "gray" }}
          />
        </span>
      </div>

      {isSort && (
        <Popover
          open={isSort}
          anchorEl={anchorElSort}
          onClose={() => {
            setIsSort(!isSort);
            setAnchorElSort(null);
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            style: { borderRadius: 10, width: "150px", margin: 0, padding: 0 },
          }}
          className="Report-Filter-Popup"
        >
          <div
            style={{
              margin: 0,
              justifyContent: "center",
              maxHeight: "250px",
              height: "auto",
              overflowY: "auto",
              padding: "5px",
            }}
          >
            {sortOptions ? (
              sortOptions.map((sort) => {
                return (
                  <span key={sort} >
                    <span
                      className="mx-2 "
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "5px",
                        textTransform: "capitalize",
                      }}
                      onClick={() => handleClickSort(sort.id)}
                    >
                      <span>{sort}</span>
                      {sort === sortSelected && (
                        <IoCheckmarkCircleOutline style={{ color: "green", fontSize: "16px" }} />
                      )}
                    </span>
                    <Divider style={{ width: "100%", marginTop: "0px" }} />
                  </span>
                );
              })
            ) : (
              <span className="my-2 mx-2">No data found</span>
            )}
          </div>
        </Popover>
      )}

      {isFilter && (
        <Popover
          open={isFilter}
          anchorEl={anchorEl}
          onClose={() => {
            setIsFilter(!isFilter);
            setAnchorEl(null);
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            style: { borderRadius: 10, width: "250px" },
          }}
          className="Report-Filter-Popup"
        >
          <div
            style={{
              margin: 0,
              justifyContent: "center",
              maxHeight: "250px",
              height: "250px",
              overflowY: "auto",
              padding: "5px",
            }}
          >
            {filterOptions ? (
              filterOptions.map((source) => {
                return (
                  <span key={source.id} >
                    <span
                      className="mx-2 "
                      style={{
                        cursor: "pointer",
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "5px",
                        textTransform: "capitalize",
                      }}
                      onClick={() => handleClickFilter(source.id)}
                    >
                      <span>{source.provider}</span>
                      {source.id === filterSelected && (
                        <IoCheckmarkCircleOutline style={{ fontSize: "16px", color: "green" }} />
                      )}
                    </span>
                    <Divider style={{ width: "100%", marginTop: "0px" }} />
                  </span>
                );
              })
            ) : (
              <span className="my-2 mx-2">No data found</span>
            )}
          </div>
        </Popover>
      )}

      {mainData.length > 0 ? (
        mainData.map((value, i) => {
          return (
            <div key={i}>
              <div
                className="m-2"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <div>
                  <span
                    className="cursor-pointer"
                    style={{
                      display: "flex",
                      gap: "10px",
                      borderRadius: "30px",
                      justifyContent: "start",
                      alignItems: "center",
                      fontSize: "16px",
                      fontWeight: "600",
                      textDecoration: "underline",
                    }}
                    onClick={() => console.log("Clicked")}
                  >
                    <img src={value.icon} alt="Gmail Icon" height="32" style={{ padding: "4px" }} />
                    <span style={{ textTransform: "capitalize" }}>{value?.provider}</span>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "gray",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  <span>Signed in as {value?.email ? value?.email : value?.username} </span>
                  <span>Connected on {moment(value.created_at).format("ddd, MMM DD, YYYY h:mm A")} </span>
                </div>
                <div>
                  <button
                    onClick={(event) => handleLogout(event, value, i)}
                    style={{
                      border: "2px solid lightgray",
                      background: "white",
                      padding: "5px 60px",
                      fontSize: "16px",
                      borderRadius: "25px",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    {value.is_active ? "Logout" : "Login"}
                  </button>
                </div>
              </div>
              {i < totalShownProfiles && (
                <hr className="mx-4" style={{ color: "#d3d3d370", height: "2px", borderWidth: "0" }}></hr>
              )}
              {i === totalShownProfiles && (
                <div
                  className="text-center mt-4 cursor-pointer"
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    alignItems: "center",
                  }}
                  onClick={() => setTotalShownProfiles(totalShownProfiles + 10)}
                >
                  <b>View More</b>
                  <FaChevronDown style={{ color: "lightgray" }} />
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <span style={{ fontSize: "18px", fontWeight: "500" }}>No Profile Connected</span>
        </div>
      )}
    </div>
  );
};

export default ConnectedProfile;
