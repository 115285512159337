import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Icon,
  Image,
} from "semantic-ui-react";
import CustomRatingComponent from "../common/customRating";
import { Row, Col, Label } from "reactstrap";
import Rater from "react-rater";
import starActive from "../../images/reviewMethods/star_Color.png";
import starInActive from "../../images/reviewMethods/star_B_W.png";
import SemiCircleProgressBar from "react-progressbar-semicircle";
// import { Field } from 'redux-form';
// import TextAreaComponent from '../common/TextAreaComponent';
import FaceFour from "../../images/svg/FaceFour";
import FaceFillFour from "../../images/svg/FaceFillFour";
import FaceFive from "../../images/svg/FaceFive";
import FaceFillFive from "../../images/svg/FaceFillFive";
import FaceSix from "../../images/svg/FaceSix";
import FaceFillSix from "../../images/svg/FaceFillSix";
import FaceSeven from "../../images/svg/FaceSeven";
import FaceFillSeven from "../../images/svg/FaceFillSeven";
import FaceEight from "../../images/svg/FaceEight";
import FaceFillEight from "../../images/svg/FaceFillEight";

class NewSurveyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageform: { pageIndex: props.survey_current_page, SurveyQuestions: [] },
      paragraphInput: "",
      shortAnswerInput: "",
      dropDownSelect: "",
      checkBoxArray: [],
      starRating: 0,
      npsScore: 0,
      attachedFiles: null,
      setAllFiles: [],
      emojiRating: 0,
      fiveFaceHover: null,
    };
    this.inputChoiceHandle = this.inputChoiceHandle.bind(this);
    this.surveyParagraphInputQuestion =
      this.surveyParagraphInputQuestion.bind(this);
    this.surveyShortAnswerInputQuestion =
      this.surveyShortAnswerInputQuestion.bind(this);
    this.surveyDropDownQuestion = this.surveyDropDownQuestion.bind(this);
    this.surveyCheckBoxHandle = this.surveyCheckBoxHandle.bind(this);
    this.setStarRating = this.setStarRating.bind(this);
    this.setNpsScore = this.setNpsScore.bind(this);
    this.setEmojiRating = this.setEmojiRating.bind(this);
    this.setFiveFaceHover = this.setFiveFaceHover.bind(this);
    this.setAttachedFiles = this.setAttachedFiles.bind(this);
  }

  handleHover = (stateName, value) => {
    this.setState({ [stateName]: value });
  };

  setFiveFaceHover(que_index, current_page_index, hover_emoji, hover_action) {
    const new_key = current_page_index + "_" + que_index;
    if (!this.state.fiveFaceHover) {
      this.setState({
        fiveFaceHover: hover_action
          ? { [new_key]: hover_emoji }
          : { [new_key]: null },
      });
    } else {
      const new_five_face_hover = this.state.fiveFaceHover;
      new_five_face_hover[new_key] = hover_action ? hover_emoji : null;
      this.setState({ fiveFaceHover: new_five_face_hover });
    }
  }

  setAttachedFiles(event, que_index, question, current_page_index) {
    let file_name_list = [];
    let all_files = event.target.files;
    Object.keys(all_files).map((file_item) => {
      file_name_list.push(all_files[file_item].name);
      let existing_files = this.state.setAllFiles;
      existing_files.push(all_files[file_item]);
      this.setState({ setAllFiles: existing_files });
    });
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex(
      (question) => question.index == que_index
    );
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: file_name_list }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: file_name_list }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      });
    }

    const new_key = current_page_index + "_" + que_index;
    if (this.state.attachedFiles === null) {
      this.setState({ attachedFiles: { [new_key]: event.target.files } });
    } else {
      const new_attach = this.state.attachedFiles;
      new_attach[new_key] = event.target.files;
      this.setState({ attachedFiles: new_attach });
    }
    this.setState({ choiceValues });
  }

  setNpsScore(que_index, question, val, current_page_index) {
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex(
      (question) => question.index == que_index
    );
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: val }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: val }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      });
    }
    const new_key = current_page_index + "_" + que_index;
    if (this.state.npsScore == 0) {
      this.setState({ npsScore: { [new_key]: val } });
    } else {
      const new_rating = this.state.npsScore;
      new_rating[new_key] = val;
      this.setState({ npsScore: new_rating });
    }
    // this.setState({ npsScore: val });
    this.setState({ choiceValues });
    console.log(
      "choiceValueschoiceValueschoiceValues",
      this.state.choiceValues
    );
  }

  setStarRating(que_index, question, val, current_page_index) {
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex(
      (question) => question.index == que_index
    );
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: val }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: val }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      });
    }
    const new_key = current_page_index + "_" + que_index;
    if (this.state.starRating == 0) {
      this.setState({ starRating: { [new_key]: val } });
    } else {
      const new_rating = this.state.starRating;
      new_rating[new_key] = val;
      this.setState({ starRating: new_rating });
    }
    this.setState({ choiceValues });
  }

  setEmojiRating(que_index, question, val, current_page_index) {
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex(
      (question) => question.index == que_index
    );
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: val }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: val }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      });
    }
    const new_key = current_page_index + "_" + que_index;
    if (this.state.emojiRating == 0) {
      this.setState({ emojiRating: { [new_key]: val } });
    } else {
      const new_rating = this.state.emojiRating;
      new_rating[new_key] = val;
      this.setState({ emojiRating: new_rating });
    }
    this.setState({ choiceValues });
  }

  nextSurveyPage = () => {
    this.props.getSurveyResponsefn(this.state.pageform);
  };

  submitSurveyPage = () => {
    var new_data = { data: this.state.pageform };
    if (this.state.attachedFiles) {
      new_data["attached_files"] = this.state.setAllFiles;
    }
    this.props.finalSubmitSurveyQuestin(new_data);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.pageform.pageIndex !== nextProps.survey_current_page) {
      this.setState({
        dropDownSelect: "",
        checkBoxArray: [],
        shortAnswerInput: "",
        pageform: {
          pageIndex: nextProps.survey_current_page,
          SurveyQuestions: [],
        },
        paragraphInput: "",
      });
    }
  }

  inputChoiceHandle(event, qi, question) {
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == qi);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      });
    }
    this.setState({ choiceValues });
  }

  surveyParagraphInputQuestion(event, qi, question) {
    // this.setState({ paragraphInput: event.target.value });
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == qi);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      });
    }
    this.setState({ choiceValues });
  }

  surveyShortAnswerInputQuestion(event, qi, question) {
    // this.setState({ shortAnswerInput: event.target.value });
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == qi);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      });
    }
    this.setState({ choiceValues });
  }

  surveyDropDownQuestion(event, qi, question, current_page_index) {
    // this.setState({ dropDownSelect: event.target.value });
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == qi);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      });
    }
    const new_key = current_page_index + "_" + qi;
    if (this.state.dropDownSelect == "") {
      this.setState({ dropDownSelect: { [new_key]: event.target.value } });
    } else {
      const new_dropdown = this.state.dropDownSelect;
      new_dropdown[new_key] = event.target.value;
      this.setState({ dropDownSelect: new_dropdown });
    }
    this.setState({ choiceValues });
  }

  surveyCheckBoxHandle(event, qi, question) {
    let checkBoxArray = this.state.checkBoxArray;
    if (event.target.checked) {
      checkBoxArray.push(event.target.value);
    } else {
      const checkb = checkBoxArray.findIndex(
        (question) => question == event.target.value
      );
      if (checkb > -1) {
        checkBoxArray.splice(checkb, 1);
      }
    }
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == qi);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: checkBoxArray,
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: checkBoxArray,
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      });
    }
    this.setState({ choiceValues });
  }

  render() {
    const {
      survey_pages,
      survey_current_page,
      surveySubmitError,
      totalSurveyPageCount,
      nextPageCount,
      business,
      designConfig,
    } = this.props;
    const { attachedFiles, emojiRating, fiveFaceHover } = this.state;

    return (
      <Grid
        textAlign="center"
        style={{ width: "415px" }}
        verticalAlign="middle"
      >
        <Grid.Column textAlign="left" text style={{ maxWidth: 410 }}>
          <Form size="large">
            <Segment
              stacked
              rounded
              style={{
                height: "auto",
                borderRadius: "20px",
                backgroundColor: "white",
                padding: "15px",
              }}
            >
              {business && business.logo && (
                <Header
                  as="h2"
                  textAlign="center"
                  style={{
                    marginBottom: "20px",
                    color: "darkgreen",
                    fontSize: "32px",
                  }}
                >
                  <Image src={business ? business.logo : ""} />
                </Header>
              )}
              <h4
                style={{ textAlign: "center", fontFamily: designConfig.font }}
              >
                {survey_pages.title}
              </h4>
              {survey_pages.allQuestions.map((question, que_index) => {
                return (
                  <Form.Field
                    required={question.question_required}
                    key={`Question${survey_current_page}${que_index}`}
                    style={{
                      backgroundColor: designConfig.que_bg_color,
                      fontFamily: designConfig.font,
                      borderRadius: "20px",
                      padding: "10px 20px",
                      color: designConfig.que_text_color,
                    }}
                  >
                    <Row className="mt-3 mb-3">
                      <Col sm="12">
                        <p
                          style={{
                            color: "black",
                            fontSize: "1rem",
                            fontWeight: "bold",
                            fontFamily: designConfig.font,
                            color: designConfig.que_text_color,
                          }}
                        >
                          {question.input}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      {question.question_type === "Multiple Choice" &&
                          question.options.map((option, option_index) => {
                            return (
                              <Row
                                key={option_index}
                                style={{
                                  width: "90%",
                                  padding: "0 40px",
                                  fontFamily: designConfig.font,
                                  color: designConfig.que_text_color,
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom:".5rem",
                                }}
                              >
                                <Col
                                  sm="12"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom:".5rem",
                                    fontFamily: designConfig.font,
                                    color: designConfig.que_text_color,
                                    fontSize: "1rem",
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <input
                                    id={`mccQuestion${survey_current_page}${que_index}${option_index}`}
                                    name={question.input}
                                    type="radio"
                                    value={option.title}
                                    onChange={(e) =>
                                      this.inputChoiceHandle(
                                        e,
                                        que_index,
                                        question
                                      )
                                    }
                                    style={{ flexShrink: 0 }}
                                  />
                                  <Label
                                    for={`mccQuestion${survey_current_page}${que_index}${option_index}`}
                                    style={{
                                      fontFamily: designConfig.font,
                                      color: designConfig.que_text_color,
                                      fontSize: "1rem",
                                      marginLeft: "10px",
                                      marginBottom:"0px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {option.title}
                                  </Label>
                                </Col>
                              </Row>
                            );
                          })}
                      {question.question_type === "Checkbox" &&
                        question.options.map((option, option_index) => {
                          return (
                            <Row
                              key={option_index}
                              style={{
                                width: "90%",
                                padding: "0 40px",
                                fontFamily: designConfig.font,
                                color: designConfig.que_text_color,
                              }}
                            >
                              <Col
                                sm="12"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom:".5rem",
                                }}
                              >
                                <input
                                  id={`checkBoxx${survey_current_page}${que_index}${option_index}`}
                                  name={question.input}
                                  type="checkbox"
                                  value={option.title}
                                  onChange={(e) =>
                                    this.surveyCheckBoxHandle(
                                      e,
                                      que_index,
                                      question
                                    )
                                  }
                                  style={{ flexShrink: 0 }}
                                />
                                <Label
                                  for={`checkBoxx${survey_current_page}${que_index}${option_index}`}
                                  style={{
                                    fontFamily: designConfig.font,
                                    color: designConfig.que_text_color,
                                    fontSize: "1rem",
                                    marginLeft: "10px",
                                    marginBottom:"0px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {option.title}
                                </Label>
                              </Col>
                            </Row>
                          );
                        })}
                      {question.question_type === "Drop-down" && (
                        <div style={{ width: "80%", marginLeft: "20px" }}>
                          <select
                            name="select"
                            value={
                              this.state.dropDownSelect == ""
                                ? this.state.dropDownSelect
                                : this.state.dropDownSelect[
                                    `${survey_current_page}_${que_index}`
                                  ]
                            }
                            onChange={(e) =>
                              this.surveyDropDownQuestion(
                                e,
                                que_index,
                                question,
                                survey_current_page
                              )
                            }
                          >
                            <option value="">Select</option>
                            {question.options.map((option, option_index) => (
                              <option
                                value={option.title}
                                key={option_index}
                                style={{
                                  padding: "15px 0",
                                  fontFamily: designConfig.font,
                                  color: designConfig.que_text_color,
                                }}
                              >
                                {option.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {question.question_type === "SHORT ANSWER" && (
                        <textarea
                          name="text"
                          type="textarea"
                          onChange={(e) =>
                            this.surveyShortAnswerInputQuestion(
                              e,
                              que_index,
                              question
                            )
                          }
                          className="custom-textArea"
                          placeholder={question.question_short_form}
                          rows={3}
                          cols={3}
                          style={{
                            fontFamily: designConfig.font,
                            color: "#000",
                            fontSize: "1rem",
                            marginLeft: "20px",
                            width: "85%",
                            height: "70px",
                            whiteSpace: "pre-line",
                          }}
                        />
                      )}
                      {question.question_type === "PARAGRAPH" && (
                        <textarea
                          name="text"
                          type="textarea"
                          id="description"
                          onChange={(e) =>
                            this.surveyParagraphInputQuestion(
                              e,
                              que_index,
                              question
                            )
                          }
                          className="custom-textArea"
                          placeholder={question.question_short_form}
                          rows={5}
                          cols={5}
                          style={{
                            fontFamily: designConfig.font,
                            color: "#000",
                            fontSize: "1rem",
                            marginLeft: "20px",
                            width: "85%",
                            height: "70px",
                            whiteSpace: "pre-line",
                          }}
                        />
                      )}
                      {question.question_type === "RATING" && (
                        <Row className="">
                          <Col>
                            <Col
                              sm="12"
                              className="no-label"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "30px",
                                marginLeft: "25px",
                              }}
                            >
                              <Rater
                                total={5}
                                rating={
                                  this.state.starRating == 0
                                    ? this.state.starRating
                                    : this.state.starRating[
                                        `${survey_current_page}_${que_index}`
                                      ]
                                }
                                onRate={({ rating }) =>
                                  this.setStarRating(
                                    que_index,
                                    question,
                                    rating,
                                    survey_current_page
                                  )
                                }
                              >
                                <CustomRatingComponent
                                  activeImg={starActive}
                                  inActiveImg={starInActive}
                                  customClass="review-method-img"
                                  imageWidth={35}
                                  style={{ marginRight: "12px" }}
                                />
                              </Rater>
                            </Col>
                            <Header
                              as="h6"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "15px",
                                alignItems: "center",
                                marginTop: "5px",
                                marginLeft: "44px",
                              }}
                            >
                              <h6
                                style={{
                                  fontFamily: designConfig.font,
                                  color: designConfig.que_text_color,
                                }}
                              >
                                {question.lowRatingInput}
                              </h6>
                              <p
                                style={{
                                  marginTop: "3px",
                                  fontFamily: designConfig.font,
                                  color: designConfig.que_text_color,
                                }}
                              >
                                <Icon name="caret right" size="small" />
                                ----------------------
                                <Icon name="caret left" size="small" />
                              </p>
                              <h6
                                style={{
                                  fontFamily: designConfig.font,
                                  color: designConfig.que_text_color,
                                }}
                              >
                                {question.HighRatingInput}
                              </h6>
                            </Header>
                          </Col>
                        </Row>
                      )}
                      {question.question_type === "NPS QUESTION" && (
                        <Row className="" style={{ margin: "auto" }}>
                          {/* <Col sm="12" className="nps-score-center" style={{ }}> */}
                          {/* <div className='npsRatingDiv'> */}
                       
                          <div className="nps-meter">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => {
                              const selectedScore = this.state.npsScore == 0 ? 0 : this.state.npsScore[`${survey_current_page}_${que_index}`]
                              let colorClass;

                              if (selectedScore && num <= selectedScore) {
                                if (selectedScore <= 6)
                                  colorClass = "nps-rating-colour-red filled newDesignNpsExp";
                                else if (selectedScore <= 8)
                                  colorClass =
                                    "nps-rating-colour-yellow filled newDesignNpsExp";
                                else
                                  colorClass = "nps-rating-colour-green filled newDesignNpsExp";
                              } else {
                                if (num <= 6)
                                  colorClass = "nps-rating-colour-red newDesignNpsExp";
                                else if (num <= 8)
                                  colorClass = "nps-rating-colour-yellow newDesignNpsExp";
                                else colorClass = "nps-rating-colour-green newDesignNpsExp";
                              }

                              return (
                                <span
                                  key={num}
                                  className={colorClass}
                                  onClick={() =>
                                    this.setNpsScore(
                                      que_index,
                                      question,
                                      num,
                                      survey_current_page
                                    )
                                  }
                                >
                                  {num}
                                </span>
                              );
                            })}
                          </div>
                          {/* <span style={{position:'absolute', width:'30px', textAlign:'center',bottom:'0', left:'-29px', fontSize:'10px'}}>{question.lowRatingInput}</span> */}
                          {/* <div id="speedometer" style={{marginLeft:'-10px'}}>
                                <div className="wrapper">
                                  <div className="indicator-wrapper">
                                    <div className="indicator-wrapper-inner">
                                      <div id="indicator"></div>
                                    </div>
                                  </div>
                                  <div className="bar bar-1" onClick={() => this.setNpsScore(que_index, question,1, survey_current_page)}><span>1</span></div>
                                  <div className="bar bar-2" onClick={() => this.setNpsScore(que_index, question,2,survey_current_page)}><span>2</span></div>
                                  <div className="bar bar-3" onClick={() => this.setNpsScore(que_index, question,3,survey_current_page)}><span>3</span></div>
                                  <div className="bar bar-4" onClick={() => this.setNpsScore(que_index, question,4,survey_current_page)}><span>4</span></div>
                                  <div className="bar bar-5" onClick={() => this.setNpsScore(que_index, question,5,survey_current_page)}><span>5</span></div>
                                  <div className="bar bar-6" onClick={() => this.setNpsScore(que_index, question,6,survey_current_page)}><span>6</span></div>
                                  <div className="bar bar-7" onClick={() => this.setNpsScore(que_index, question,7,survey_current_page)}><span>7</span></div>
                                  <div className="bar bar-8" onClick={() => this.setNpsScore(que_index, question,8,survey_current_page)}><span>8</span></div>
                                  <div className="bar bar-9" onClick={() => this.setNpsScore(que_index, question,9,survey_current_page)}><span>9</span></div>
                                  <div className="bar bar-10" onClick={() => this.setNpsScore(que_index, question,10,survey_current_page)}><span>10</span></div>
                                </div>
                                <div className="needle" style={{transform: this.state.npsScore == 0 ? 'rotate(0deg)' :`rotate(${ this.state.npsScore[`${survey_current_page}_${que_index}`] > 2 ? this.state.npsScore[`${survey_current_page}_${que_index}`] > 5 ?((this.state.npsScore[`${survey_current_page}_${que_index}`])*1.2)*15 : ((this.state.npsScore[`${survey_current_page}_${que_index}`])*1.8)*9 :((this.state.npsScore[`${survey_current_page}_${que_index}`])*1.5)*7}deg)`, left:'75px'}}></div>
                                <div className='semiCircleProgressBar' style={{left:'76px'}}>
                                  <SemiCircleProgressBar percentage={this.state.npsScore == 0 ? (this.state.npsScore)*0 :  this.state.npsScore[`${survey_current_page}_${que_index}`] < 6 ? this.state.npsScore[`${survey_current_page}_${que_index}`] < 3 ? (this.state.npsScore[`${survey_current_page}_${que_index}`])*7 : (this.state.npsScore[`${survey_current_page}_${que_index}`])*9.3 : (this.state.npsScore[`${survey_current_page}_${que_index}`])*10} diameter={160} stroke={'#0f62fe'} strokeWidth={5} background={'#fff'} />
                                </div>
                              </div> */}

                          {/* <span style={{position:'absolute', width:'35px', textAlign:'center',bottom:'0', right:'-23px', fontSize:'10px'}}>{question.HighRatingInput}</span> */}
                          {/* </div> */}
                          {/* </Col> */}
                          <input
                            name="text"
                            type="text"
                            id="rating"
                            className="custom-textArea"
                            value={this.state.starRating}
                            style={{ display: "none" }}
                          />
                        </Row>
                      )}
                      {question.question_type === "fiveFacesEmoji" && (
                        <Col
                          sm="12"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "30px",
                          }}
                        >
                          <span
                            className="mr-4"
                            onClick={() =>
                              this.setEmojiRating(
                                que_index,
                                question,
                                "1",
                                survey_current_page
                              )
                            }
                            onMouseOver={() =>
                              this.setFiveFaceHover(
                                que_index,
                                survey_current_page,
                                "1",
                                true
                              )
                            }
                            onMouseLeave={() =>
                              this.setFiveFaceHover(
                                que_index,
                                survey_current_page,
                                "1",
                                false
                              )
                            }
                          >
                            {emojiRating[
                              `${survey_current_page}_${que_index}`
                            ] === "1" ||
                            (fiveFaceHover &&
                              fiveFaceHover[
                                `${survey_current_page}_${que_index}`
                              ] === "1") ? (
                              <FaceFillFour />
                            ) : (
                              <FaceFour />
                            )}
                          </span>
                          <span
                            className="mr-4"
                            onClick={() =>
                              this.setEmojiRating(
                                que_index,
                                question,
                                "2",
                                survey_current_page
                              )
                            }
                            onMouseOver={() =>
                              this.setFiveFaceHover(
                                que_index,
                                survey_current_page,
                                "2",
                                true
                              )
                            }
                            onMouseLeave={() =>
                              this.setFiveFaceHover(
                                que_index,
                                survey_current_page,
                                "2",
                                false
                              )
                            }
                          >
                            {emojiRating[
                              `${survey_current_page}_${que_index}`
                            ] === "2" ||
                            (fiveFaceHover &&
                              fiveFaceHover[
                                `${survey_current_page}_${que_index}`
                              ] === "2") ? (
                              <FaceFillFive />
                            ) : (
                              <FaceFive />
                            )}
                          </span>
                          <span
                            className="mr-4"
                            onClick={() =>
                              this.setEmojiRating(
                                que_index,
                                question,
                                "3",
                                survey_current_page
                              )
                            }
                            onMouseOver={() =>
                              this.setFiveFaceHover(
                                que_index,
                                survey_current_page,
                                "3",
                                true
                              )
                            }
                            onMouseLeave={() =>
                              this.setFiveFaceHover(
                                que_index,
                                survey_current_page,
                                "3",
                                false
                              )
                            }
                          >
                            {emojiRating[
                              `${survey_current_page}_${que_index}`
                            ] === "3" ||
                            (fiveFaceHover &&
                              fiveFaceHover[
                                `${survey_current_page}_${que_index}`
                              ] === "3") ? (
                              <FaceFillSix />
                            ) : (
                              <FaceSix />
                            )}
                          </span>
                          <span
                            className="mr-4"
                            onClick={() =>
                              this.setEmojiRating(
                                que_index,
                                question,
                                "4",
                                survey_current_page
                              )
                            }
                            onMouseOver={() =>
                              this.setFiveFaceHover(
                                que_index,
                                survey_current_page,
                                "4",
                                true
                              )
                            }
                            onMouseLeave={() =>
                              this.setFiveFaceHover(
                                que_index,
                                survey_current_page,
                                "4",
                                false
                              )
                            }
                          >
                            {emojiRating[
                              `${survey_current_page}_${que_index}`
                            ] === "4" ||
                            (fiveFaceHover &&
                              fiveFaceHover[
                                `${survey_current_page}_${que_index}`
                              ] === "4") ? (
                              <FaceFillSeven />
                            ) : (
                              <FaceSeven />
                            )}
                          </span>
                          <span
                            className=""
                            onClick={() =>
                              this.setEmojiRating(
                                que_index,
                                question,
                                "5",
                                survey_current_page
                              )
                            }
                            onMouseOver={() =>
                              this.setFiveFaceHover(
                                que_index,
                                survey_current_page,
                                "5",
                                true
                              )
                            }
                            onMouseLeave={() =>
                              this.setFiveFaceHover(
                                que_index,
                                survey_current_page,
                                "5",
                                false
                              )
                            }
                          >
                            {emojiRating[
                              `${survey_current_page}_${que_index}`
                            ] === "5" ||
                            (fiveFaceHover &&
                              fiveFaceHover[
                                `${survey_current_page}_${que_index}`
                              ] === "5") ? (
                              <FaceFillEight />
                            ) : (
                              <FaceEight />
                            )}
                          </span>
                        </Col>
                      )}
                      {question.question_type === "ATTACHMENT" && (
                        <Row style={{ width: "100%", padding: "0 40px" }}>
                          <Col
                            sm="12"
                            style={{
                              display: "flex",
                              width: "100% !important",
                            }}
                          >
                            <label
                              className="text-center"
                              htmlFor={`survey_attachment${survey_current_page}${que_index}`}
                              style={{
                                width: "100%",
                                minHeight: "50px",
                                border: "1px dashed black",
                                backgroundColor: "rgb(235 235 235)",
                                padding: "11px 0",
                                cursor: "pointer",
                                color: "black",
                              }}
                            >
                              Drag & Drop Images here, OR click to Browse.
                            </label>
                            <input
                              id={`survey_attachment${survey_current_page}${que_index}`}
                              type="file"
                              accept=".png, .jpg, .jpeg .pdf"
                              style={{ display: "none" }}
                              onChange={(event) =>
                                this.setAttachedFiles(
                                  event,
                                  que_index,
                                  question,
                                  survey_current_page
                                )
                              }
                              multiple
                            />
                          </Col>
                          {attachedFiles &&
                            attachedFiles[
                              `${survey_current_page}_${que_index}`
                            ] &&
                            attachedFiles[`${survey_current_page}_${que_index}`]
                              .length > 0 &&
                            Object.keys(
                              attachedFiles[
                                `${survey_current_page}_${que_index}`
                              ]
                            ).map((file_key) => {
                              const file =
                                attachedFiles[
                                  `${survey_current_page}_${que_index}`
                                ][file_key];
                              return (
                                file_key !== "length" &&
                                (file.name.endsWith(".png") ||
                                  file.name.endsWith(".jpg") ||
                                  file.name.endsWith(".jpeg")) && (
                                  <Col sm="12" key={file_key}>
                                    <p
                                      className="text-truncate"
                                      style={{
                                        width: "230px",
                                        cursor: "pointer",
                                        border: "1px dashed black",
                                        padding: "3px 5px",
                                        borderRadius: "10px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <Icon name="image" size="large" />{" "}
                                      {file.name}
                                    </p>
                                  </Col>
                                )
                              );
                            })}
                        </Row>
                      )}
                    </Row>
                  </Form.Field>
                );
              })}
              <Row className="mt-3 mb-3">
                <Col sm="12">
                  <p style={{ color: "red", fontSize: "1rem" }}>
                    {surveySubmitError}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className="mt-4 text-center">
                  {totalSurveyPageCount === nextPageCount ? (
                    <Form.Field
                      control={Button}
                      color="teal"
                      type="submit"
                      style={{
                        color: "white",
                        background: "#0f62fe",
                        float: "right",
                        borderRadius: "25px",
                      }}
                      onClick={this.submitSurveyPage.bind(this)}
                    >
                      Submit
                    </Form.Field>
                  ) : (
                    <Form.Field
                      control={Button}
                      color="teal"
                      type="button"
                      style={{
                        color: "white",
                        background: "#0f62fe",
                        float: "right",
                        borderRadius: "25px",
                      }}
                      onClick={this.nextSurveyPage.bind(this)}
                    >
                      Next
                      <Icon name="right arrow" />
                    </Form.Field>
                  )}
                </Col>
              </Row>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default NewSurveyPage;
