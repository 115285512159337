import { Box } from "@material-ui/core";
import React from "react";
import FPChip from "../common/new/FPChip/FPChip";
import { Assign, Reply, User } from "../../images/svg";
import PropTypes from "prop-types";
import { Divider } from "antd";
import moment from "moment";
import MessegeCount from "./Common/MessegeCount";

const ChatUserCard = ({
  selected,
  key,
  platFormImg,
  assignTo,
  countArray,
  userName,
  inboxCategoryIcon,
  lastMessage,
  lastReplyDate,
  tags,
  onLogoClick,
  ...rest
}) => {
  const formatDate = (dateString) => {
    if (!dateString) return "";

    // Parse the date string into a moment object
    const date = moment(dateString);

    // Get today's and yesterday's date
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "day").startOf("day");

    // Check if the date matches today or yesterday
    if (date.isSame(today, "day")) {
      return "Today";
    } else if (date.isSame(yesterday, "day")) {
      return "Yesterday";
    } else {
      return date.format("MMM, DD YYYY");
    }
  };

  return (
    <div>
      <Box
        className="d-flex flex-column gap-10 p-3 cursor-pointer"
        sx={{
          borderRadius: "8px",
          backgroundColor: selected ? "#DDEFF0" : "transparent",
          "&:hover": {
            backgroundColor: "#82cbcf1a",
          },
        }}
        {...rest}
      >
        <div>
          {tags && tags?.length > 0 && (
            <div className="d-flex gap-6 align-items-center flex-wrap">
              {tags?.map((tag, index) => (
                <FPChip
                  key={`${index}-${tag}`}
                  style={{
                    backgroundColor: selected ? "#ffffff" : "#BFC2CB66",
                  }}
                  label={tag}
                />
              ))}
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-15 align-items-center">
            <div className="d-flex gap-6 align-items-center">
              <div style={{ width: 25, height: 25 }}>
                <img
                  src={platFormImg}
                  alt="platform"
                  style={{ width: 25, height: 25, objectFit: "contain" }}
                  onClick={onLogoClick}
                />
              </div>
              <Box
                component="p"
                sx={{
                  color: "#1B1C1D",
                  fontWeight: 400,
                  fontSize: 18,
                }}
              >
                {userName || "Anonymous"}
              </Box>
            </div>
            {/* <spna className="chat-notification-count">1</spna> */}
            <MessegeCount mId={key} cArray={countArray} />
          </div>

          <div className="d-flex gap-6 align-items-center">
            {inboxCategoryIcon && (
              <span className="d-inline-flex">{inboxCategoryIcon}</span>
            )}
            {assignTo && (
              <span className="d-inline-flex">
                <Assign />
              </span>
            )}
          </div>
        </div>

        {(lastMessage || lastReplyDate) && (
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0 fp-size-16 font-blue-gray text-truncate">
              {lastMessage && lastMessage}
            </p>
            {lastReplyDate && (
              <p className="font-blue-gray d-flex gap-6 align-items-center cursor-pointer ml-3">
                <span className="d-inline-flex">
                  <Reply />
                </span>
                <span>{formatDate(lastReplyDate)}</span>
              </p>
            )}
          </div>
        )}

        {assignTo && (
          <div className="d-flex align-items-center">
            <span className="d-inline-flex align-items-center">
              <User style={{ width: 18, height: 18 }} />
            </span>
            <p>{assignTo}</p>
          </div>
        )}
      </Box>

      <Divider className="my-2" />
    </div>
  );
};

export default ChatUserCard;

ChatUserCard.propTypes = {
  selected: PropTypes.bool,
  platFormImg: PropTypes.string,
  assignTo: PropTypes.string,
  userName: PropTypes.string,
  inboxCategoryIcon: PropTypes.string,
  lastMessage: PropTypes.string,
  lastReplyDate: PropTypes.string,
  onLogoClick: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.string),
};

ChatUserCard.defaultProps = {
  selected: false,
  assignTo: null,
  inboxCategoryIcon: false,
  lastMessage: "",
  lastReplyDate: "",
};
