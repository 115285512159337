import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import InputComponent from "../common/InputComponent";
import TextAreaComponent from "../common/TextAreaComponent";
import MultiSelect from "../common/SelectComponent";
import * as utils from "../../libs/utils";
import cookie from "react-cookies";
import { FPBreadcrumbs, FPButton } from "../common/new";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import FPLabel from "../common/new/forms/ui/FPLabel";
import information from "../../images/Information.png";
import SwitchToggleComponent from "../common/SwitchToggleComponent";
import SmartRadioComponent from "../common/SmartRadioComponent";
import { daysList, hourList } from "../../libs/constant";
import CheckboxWrapper from "../common/CheckboxWrapper";

const validate = (values) => {
  const errors = {};
  if (!values.title) errors.title = "Enter Title";
  if (!values.first_line) errors.first_line = "Enter first line";
  if (!values.last_line) errors.last_line = "Enter last line";
  if (!values.review_type) errors.review_type = "Select Review Type";
  if (!values.allowed_for) errors.allowed_for = "Select a Provider ";

  if (values.enable_delay) {
    if (!values.delay_unit) {
      errors.delay_unit = "Select Delay Unit (Hours/Days)";
    }
    if (!values.delay_value) {
      errors.delay_value = "Select Delay Value";
    }
  }
  return errors;
};

class AiResponseForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      responseBodyChar: 1000,
      businessType:
        cookie.load("business_user") &&
        cookie.load("business_user").business_type,
    };
  }

  submit(data) {
    const {
      router: { history },
    } = this.context;
    let newData = { ...data };
    if (typeof data.allowed_for === "string")
      newData.allowed_for = data.allowed_for.split(",");
    if (typeof data.branches === "string")
      newData.branches = data.branches.split(",");
    if (data?.enable_delay) {
      newData.delay_unit = data.delay_unit?.value || data.delay_unit || null;
      newData.delay_value = data.delay_value?.value || data.delay_value || null;
    } else {
      newData.delay_unit = null;
      newData.delay_value = null;
    }
    
    if (newData.include_ticket_id && !newData?.last_line?.includes('{ticket_id}')) {
      utils.displayMessage("negative", "Please include {ticket_id} in Last Line");
      return;
    }

    if (!newData.include_ticket_id && newData?.last_line?.includes('{ticket_id}')) {
      utils.displayMessage("negative", "Please exclude {ticket_id} from Last Line");
      return;
    }

    return this.props
      .submitData(newData)
      .then(() =>
        history.push({
          pathname: "central-setup",
          state: { tab: "3" },
        })
      )
      .catch((err) => {
        err &&
          err.non_field_errors &&
          err.non_field_errors.map((err) =>
            utils.displayMessage("negative", err)
          );
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
  }

  componentDidUpdate() {
    // check for max charcter

    let { responseBody, change } = this.props;
    let responseBodyChar;

    if (responseBody && responseBody.length > 1000) {
      responseBody = responseBody.substring(0, 1000);
      responseBodyChar = 0;
      change("content", responseBody);
    } else {
      responseBodyChar = 1000 - (responseBody ? responseBody.length : 0);
    }
    this.setState({ responseBodyChar });
  }

  render() {
    const {
      submitting,
      handleSubmit,
      errors,
      initialValues,
      allowedForOptions,
      reviewTypeOptions,
      deleteResponse,
      autoReply,
      locationsList,
      businessId,
      enableDelay,
      moreThenUnit,
    } = this.props;
    const { responseBodyChar } = this.state;

    const breadCrumbList = [
      {
        label: "Central Setup",
        link: `/${businessId}/central-setup?page_size=10`,
      },
      {
        label: "Smart Reply",
        onClick: () =>
          this.context.router.history.push({
            pathname: "central-setup",
            state: { tab: "3" },
          }),
      },
      {
        label: "Add AI Reply",
        link: "",
      },
    ];

    const moreThanFirstList = [
      {
        display_name: "Hours",
        value: "hour",
      },
      {
        display_name: "Days",
        value: "day",
      },
    ];

    const isHour = moreThenUnit === "hour" || moreThenUnit?.value === "hour";
    const isDay = moreThenUnit === "day" || moreThenUnit?.value === "day";

    return (
      <>
        <FPBreadcrumbs list={breadCrumbList} className="my-4" />
        <div
          className="px-3 py-4 mb-4 d-flex"
          style={{
            backgroundColor: "#ECFDF3",
            gap: "10px",
            borderRadius: "10px",
          }}
        >
          <div>
            <img alt="information" src={information} />
          </div>
          <div>
            <span
              style={{ color: "#039855", fontSize: "10px", display: "block" }}
            >
              <span style={{ fontWeight: 600 }}>Note: </span>
              Insert dynamic content using variables. Variables are replaced
              with actual content in the final email.
            </span>
            <span
              style={{ color: "#039855", fontSize: "10px", fontWeight: 400 }}
            >
              {"{reviewer_name}"} The reviewer name of the recipient, you can
              provide this with the syntax {"{reviewer_name}"}
            </span>
            
            <span
              style={{ color: "#039855", fontSize: "10px", fontWeight: 400,  display: "block" }}
            >
              {"{ticket_id}"} - Generate Ticket ID will shown using this. User can add the text along with this macro in Last Line Input box.
            </span>
          </div>
        </div>

        <CardWrapper headerTitle="AI Reply" contentClass="p-4">
          <form className="grid-form" onSubmit={handleSubmit(this.submit)}>
            {errors && errors.non_field_errors
              ? errors.non_field_errors.map((error, index) => (
                  <p key={index} className="text-danger">
                    {error}
                  </p>
                ))
              : null}
            <div
              style={{
                border: "1px solid #F86EAD",
                borderRadius: "12px",
                padding: "12px",
                marginBottom: "15px",
              }}
            >
              <div
                className={`d-flex justify-content-between align-items-center ${
                  autoReply && "mb-3"
                }`}
              >
                <FPLabel required={false}>Auto Reply</FPLabel>
                <Field
                  name="auto"
                  checked={initialValues.auto}
                  component={SwitchToggleComponent}
                  serverError={errors && errors.sms_alert}
                />
              </div>

              {autoReply && (
                <>
                  <FPLabel>Allowed For</FPLabel>
                  <Field
                    name="allowed_for"
                    component={MultiSelect}
                    labelKey="display_name"
                    valueKey="value"
                    placeholder="Allowed For"
                    options={allowedForOptions && allowedForOptions.choices}
                    serverError={errors && errors.allowed_for}
                    multi
                    required
                    simpleValue
                  />
                </>
              )}
              {autoReply && (<div
                className={`d-flex justify-content-between align-items-center mt-4`}
              >
                <FPLabel required={false}>Enable Delay Reply</FPLabel>
                <Field
                  name="enable_delay"
                  checked={initialValues.enable_delay}
                  component={SwitchToggleComponent}
                  serverError={errors && errors.sms_alert}
                  enableDelay
                />
              </div>)}
              {enableDelay && autoReply &&(
                <>
                  <div className="d-flex gap-20" style={{ marginTop: "10px" }}>
                    <Field
                      name="delay_unit"
                      component={MultiSelect}
                      placeholder="Select Hours/Days"
                      options={moreThanFirstList}
                      labelKey="display_name"
                      valueKey="value"
                      style={{ width: "350px" }}
                      onChange={(value) => {
                        this.props.change("delay_value", null);
                      }}
                    />

                    <Field
                      name="delay_value"
                      component={MultiSelect}
                      placeholder="Select"
                      labelKey="display_name"
                      valueKey="value"
                      options={
                        initialValues?.delay_value
                          ? isHour
                            ? hourList
                            : isDay
                            ? daysList
                            : []
                          : isHour
                          ? hourList
                          : isDay
                          ? daysList
                          : []
                      }
                      disabled={!moreThenUnit}
                      style={{ width: "100px" }}
                    />
                  </div>
                </>
              )}
            </div>
            <FPLabel>Name</FPLabel>
            <Field
              name="title"
              type="text"
              component={InputComponent}
              serverError={errors && errors.title}
              disabled={initialValues.is_default}
            />

            <div style={{ border: "1px solid #F86EAD", borderRadius: "12px", padding: "12px", marginBottom: "15px", }}>
              <div
                className={`d-flex justify-content-between align-items-center ${
                  autoReply && "mb-3"
                }`}
              >
                <FPLabel required={false}>Enable Auto Ticket ID Creation and Assignment</FPLabel>
                <Field
                  name="include_ticket_id"
                  component={CheckboxWrapper}
                  toggle
                />
              </div>
            </div>

            <FPLabel>First Line</FPLabel>
            <Field
              name="first_line"
              type="text"
              component={TextAreaComponent}
              serverError={errors && errors.body}
            />
            <small className="mt-1 d-block">
              {responseBodyChar} characters left.
            </small>

            <div className="my-4">
              <FPLabel>Last Line</FPLabel>
              <Field
                name="last_line"
                type="text"
                component={TextAreaComponent}
                serverError={errors && errors.body}
              />
              <small className="mt-1 d-block">
                {responseBodyChar} characters left.
              </small>
            </div>

            <FPLabel required={false}>Instructions</FPLabel>
            <Field
              name="instructions"
              type="text"
              component={TextAreaComponent}
              serverError={errors && errors.body}
            />

            {this.state.businessType !== "business" && (
              <div className="mt-4">
                <FPLabel required={false}>Locations</FPLabel>
                <Field
                  name="branches"
                  component={MultiSelect}
                  labelKey="display_name"
                  valueKey="value"
                  placeholder="Locations"
                  options={locationsList && locationsList}
                  multi
                  simpleValue
                  className={"h-100"}
                />
              </div>
            )}

            <div className="mt-4 mb-3">
              {/* <FPLabel className="my-3">Review Type</FPLabel> */}
              <Field
                name="review_type"
                component={SmartRadioComponent}
                label="Review Type"
                checked={initialValues.review_type}
                options={reviewTypeOptions ? reviewTypeOptions : []}
              />
            </div>

            {/* <Row className="py-3">
              <Col sm="3"><Label>Review Content Type</Label></Col>
              <Col sm="5" className="no-label">
                <Field
                  name='content_choice'
                  component={MultiSelect}
                  labelKey="display_name"
                  valueKey="value"
                  placeholder="Content Type"
                  options={reviewContentTypeOptions && reviewContentTypeOptions}
                  multi
                  simpleValue
                />
              </Col>
            </Row> */}

            <div className="mt-3 d-flex align-items-center justify-content-between">
              <div className="mt-2">
                {initialValues.id && (
                  <FPButton
                    size="small"
                    variant="outline"
                    onClick={() => deleteResponse(initialValues.id)}
                    label="Delete"
                  />
                )}
              </div>
              <div className="d-flex align-items-center gap-6 mt-2">
                <FPButton
                  size="small"
                  variant="outline"
                  onClick={() =>
                    this.context.router.history.push({
                      pathname: "central-setup",
                      state: { tab: "3" },
                    })
                  }
                  label="Cancel"
                />

                <FPButton
                  type="submit"
                  size="small"
                  label="Save"
                  disabled={submitting}
                />
              </div>
            </div>
          </form>
        </CardWrapper>
      </>
    );
  }
}

AiResponseForm.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

AiResponseForm = reduxForm({
  // eslint-disable-line
  form: "AiResponseForm",
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AiResponseForm);

const selector = formValueSelector("AiResponseForm");

AiResponseForm = connect((state) => {
  // eslint-disable-line
  const responseBody = selector(state, "content");
  const autoReply = selector(state, "auto");
  const enableDelay = selector(state, "enable_delay");
  const moreThenUnit = selector(state, "delay_unit");
  return {
    responseBody,
    autoReply,
    enableDelay,
    moreThenUnit,
  };
})(AiResponseForm);

export default AiResponseForm;
