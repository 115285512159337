import React, { useState } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import CategoryList from "./CategoryList";
import LoaderComponent from "../common/loaderComponent";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { Checkbox } from "semantic-ui-react";
// import InsightsCategoriesGraph from "../../containers/insights/InsightsCategoriesGraph";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import CategoryListGraph from "./CategoryListGraph";
import queryString from "query-string";
import PaginationComponent from "../common/Pagination";

const CategoriesPage = (props) => {
  const {
    wordCloudData,
    loader,
    isKeywords,
    isLocationWise,
    isCityWise,
    isStateWise,
    categoriesGraphData,
    handleLocation,
    handleKeywords,
    handleCityWise,
    handleStateWise,
    history,
    location,
    // match,
    pageSize,
    handleCategoryTable,
    businessId
  } = props;
  const [viewType, setViewType] = useState("Graph");
  // const handleViewChange = (type) => {
  //   const { pathname } = location;
  //   setViewType(type);
  //   handleCategoryTable();
  //   let params = {};

  //   if (type === 'Table' ) {
  //     params = {
  //       date_type: "prior_30_day",
  //       page_size: pageSize.insight_categories_table || "10",
  //       page: 1,
  //       is_graph: false
  //     }
  //   } else {
  //     params = {
  //       date_type: "prior_30_day",
  //       is_graph: true
  //     }
  //   }

  //   history.push({
  //     pathname,
  //     search: queryString.stringify(params),
  //   });
  // };

  const handleViewChange = (type) => {
    const { pathname, search } = location;

    const currentParams = queryString.parse(search);
    setViewType(type);
    handleCategoryTable();

    const updatedParams = {
      ...currentParams,
      is_graph: type !== "Table",
    };

    if (type === "Table") {
      updatedParams.page_size = pageSize.insight_categories_table || "10";
      updatedParams.page = 1;
    } else {
      delete updatedParams.page_size;
      delete updatedParams.page;
    }

    history.push({
      pathname,
      search: queryString.stringify(updatedParams),
    });
  };

  return (
    <div>
      <LoaderComponent loader={loader}></LoaderComponent>

      <RadioGroup
        value={viewType}
        className="d-flex flex-row mb-2"
        onChange={(event, value) => handleViewChange(value)}
      >
        <FormControlLabel
          value="Graph"
          label="Graph"
          control={<Radio />}
          className="mb-0"
        />
        <FormControlLabel
          value="Table"
          label="Table"
          control={<Radio />}
          className="mb-0"
        />
      </RadioGroup>

      {wordCloudData && Object.keys(wordCloudData).length ? (
        viewType === "Table" ? (
          <CardWrapper
            headerTitle={
              <div className="d-flex justify-content-between align-items-center">
                <span>Categories Performance</span>
                <div className="d-flex align-items-center gap-10">
                  
                  <div className="d-flex align-items-center gap-6">
                    <span style={{ fontSize: "14px", fontWeight: "400", color: "rgba(27, 28, 29, 1)", }} >
                      State Wise
                    </span>
                    <Checkbox
                      toggle
                      value="state"
                      checked={isStateWise}
                      onChange={(e, checked) => handleStateWise(e, checked)}
                    ></Checkbox>
                  </div>
                  
                  <div className="d-flex align-items-center gap-6">
                    <span style={{ fontSize: "14px", fontWeight: "400", color: "rgba(27, 28, 29, 1)", }} >
                      City Wise
                    </span>
                    <Checkbox
                      toggle
                      value="city"
                      checked={isCityWise}
                      onChange={(e, checked) => handleCityWise(e, checked)}
                    ></Checkbox>
                  </div>
                  
                  <div className="d-flex align-items-center gap-6">
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "rgba(27, 28, 29, 1)",
                      }}
                    >
                      Location Wise
                    </span>
                    <Checkbox
                      toggle
                      value="location"
                      checked={isLocationWise}
                      onChange={(e, checked) => handleLocation(e, checked)}
                    ></Checkbox>
                  </div>
                  <div className="d-flex align-items-center gap-6">
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "rgba(27, 28, 29, 1)",
                      }}
                    >
                      Keywords
                    </span>
                    <Checkbox
                      toggle
                      value="keyword"
                      checked={isKeywords}
                      onChange={(e, checked) => handleKeywords(e, checked)}
                    ></Checkbox>
                  </div>
                </div>
              </div>
            }
            className="py-2"
          >
            <CategoryList
              wordCloudData={wordCloudData && wordCloudData?.results}
              isLocationWise={isLocationWise}
              isKeywords={isKeywords}
              isCityWise={isCityWise}
              isStateWise={isStateWise}
              loading={loader}
              businessId = {businessId}
              location = {location}
            />
            <PaginationComponent
              count={wordCloudData?.count}
              location={location}
              pageSizeCookie={"insight_categories_table"}
              className="pb-2"
            />
          </CardWrapper>
        ) : (
          <>
            <CategoryListGraph 
              key={JSON.stringify(categoriesGraphData)} 
              data={categoriesGraphData && Array.isArray(categoriesGraphData) ? categoriesGraphData : []} 
            />
          </>
        )
      ) : (
        <Card className="mt-2" style={{ textAlign: "center" }}>
          <Link
            to={{
              pathname: "central-setup",
              state: { tab: "6" },
            }}
            className="mt-4 mb-4"
          >
            Click<span> here </span> to Setup Insights First.
          </Link>
        </Card>
      )}
    </div>
  );
};

export default CategoriesPage;
