import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from "reactstrap";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI
import "./slider.css";
import SliderCard from "./SliderCard";
import { snakeToCapital } from "../../../../libs/utils";

function ReviewSlider(props) {
  const { data, redirectTo, loader, selectedOptions } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [sliderData, setSliderData] = useState([]);

  // Filter data to remove unwanted keys
  const filteredData = Object.keys(data || {})
    .filter(key => !["social_total_count", "since_joining_count", "avg"].includes(key))
    .reduce((obj, key) => {
      obj[key] = data[key];
      return obj;
    }, {});

  useEffect(() => {
    if (data) {
      const sliderConfigs = Object.entries(data)
        .filter(([, value]) => value?.hasOwnProperty("avg"))
        .sort(([, a], [, b]) => b.count - a.count);

      const modifiedSliderData = sliderConfigs.reduce(
        (result, config, index) => {
          const [key, value] = config;
          const updatedConfig = {
            ...value,
            name: snakeToCapital(key),
          };
          if (index % 6 === 0) {
            result.push([updatedConfig]);
          } else {
            result[result.length - 1].push(updatedConfig);
          }
          return result;
        },
        []
      );
      setSliderData(() => modifiedSliderData);
    }
  }, [data]);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === sliderData.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? sliderData.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  if (loader) {
    return (
      <div className="sliderCard">
        {Array.from({ length: 6 }).map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={"96%"}
            height={97}
            style={{ margin: "10px", borderRadius: "8px" }}
          />
        ))}
        <div className="w-100">
          <Skeleton width="25%" height={30} style={{ margin: "0 auto" }} />
        </div>
      </div>
    );
  }

  const slides = sliderData.map((providerList, parent_index) => (
    <CarouselItem
      key={`${parent_index}-slider-section`}
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
    >
      <div className="sliderCard">
        {providerList.map((provider, index) => (
          <SliderCard
            key={`${parent_index}-${index}-provider-slider`}
            data={provider}
            redirectTo={() =>
              redirectTo(
                provider.type === "Review"
                  ? "reviews"
                  : provider.type === "Social"
                  ? "social"
                  : "complaints",
                [provider.provider]
              )
            }
          />
        ))}
      </div>
    </CarouselItem>
  ));

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous} ride="carousel" className="reviewSlider">
      {slides}
      {Object.keys(filteredData).length > 6 && (
        <>
          <CarouselIndicators
            items={slides.map((_screenArray, index) => ({
              key: index,
            }))}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
            className={"customIndicator"}
          />
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </>
      )}
    </Carousel>
  );
}

export default ReviewSlider;
