import React from "react";

const SmartIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="16"
        height="16"
        fill="white"
        fillOpacity="0.01"
        style={{ mixBlendMode: "multiply" }}
      />
      <path d="M13 5H9.5V6H13V5Z" fill="#1877F2" />
      <path d="M13 7.5H9.5V8.5H13V7.5Z" fill="#1877F2" />
      <path d="M13 10H9.5V11H13V10Z" fill="#1877F2" />
      <path
        d="M14 2.5H2C1.73486 2.50026 1.48066 2.60571 1.29319 2.79319C1.10571 2.98066 1.00026 3.23486 1 3.5V12.5C1.0003 12.7651 1.10576 13.0193 1.29323 13.2068C1.4807 13.3942 1.73488 13.4997 2 13.5H14C14.2651 13.4996 14.5193 13.3942 14.7067 13.2067C14.8942 13.0193 14.9996 12.7651 15 12.5V3.5C14.9997 3.23488 14.8942 2.9807 14.7068 2.79323C14.5193 2.60576 14.2651 2.5003 14 2.5ZM2 3.5H7.5V12.5H2V3.5ZM8.5 12.5V3.5H14L14.001 12.5H8.5Z"
        fill="#1877F2"
      />
    </svg>
  );
};

export default SmartIcon;
