import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Button, Form, Input } from "semantic-ui-react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import InputComponent from "../common/InputComponent";
import PhoneInputField from "../common/phonefields";
import FormModal from "../common/FormModal";
import MultiSelect from "../common/SelectComponent";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { FPButton } from "../common/new";
import { Box } from "@material-ui/core";
import FPLabel from "../common/new/forms/ui/FPLabel";
import plus from "../../images/svg/plus-color.svg";

let BranchProfileSetup = (props) => {
  const [open, setOpen] = React.useState(false);
  const [branchTagVal, setBranchTagVal] = useState(null);
  const [errorMsg, setErrorMsg] = useState(true);
  const [tag, setTag] = useState([]);
  const {
    isEditable,
    handleBusinessSetup,
    handleSubmit,
    submitting,
    errors,
    business_id,
    currentBranch,
    branchTagList,
    branchTagDataPost,
    initialValues,
  } = props;

  useEffect(() => {
    setTag([]);
  }, [currentBranch]);

  useEffect(() => {
    if (initialValues) {
      setTag(initialValues.branch_tag);
    }
  }, [initialValues])

  const branchTagDataSubmit = async () => {
    let data = new FormData();
    if (branchTagVal.includes(",")) {
      let tagArr = branchTagVal.split(",");
      for (let val of tagArr) {
        data.append("title", val);
        data.append("business", business_id);
        await branchTagDataPost(data);
      }
    } else {
      data.append("title", branchTagVal);
      data.append("business", business_id);
      branchTagDataPost(data);
    }
  };

  const handleSelectBranchTag = (e, val) => {
    let tagArr;
    if (val.includes(",") && val !== "") {
      tagArr = val.split(",");
    } else if (val !== "") {
      tagArr = [Number(val)];
    } else {
      tagArr = [];
    }
    setTag(tagArr);
  };

  const submitProfile = async (data) => {
    const newData = { ...data };
    delete newData["location_address"];
    if (tag[0] === 0) {
      setTag([]);
      newData["branch_tag"] = [];
    } else {
      newData["branch_tag"] = tag;
    }

    return handleBusinessSetup(newData).catch((err) => {
      const errobj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errobj);
    });
  };
  const handleBranchTagModal = () => {
    setOpen(false);
  };

  return (
    <Form className="grid-form mt-2" onSubmit={handleSubmit(submitProfile)}>
      {errors && errors.non_field_errors
        ? errors.non_field_errors.map((error, index) => (
            <p key={index} className="text-danger">
              {error}
            </p>
          ))
        : null}
      <CardWrapper
        headerTitle={
          <div className="d-flex justify-content-between">
            <Box>Branch profile</Box>
            <div>
              {/* {isEditable ? (
                <FPButton label="Save" size="small" type="submit" disabled={submitting} />
              ) : (
                <FPButton
                  size="small"
                  variant="outline"
                  onClick={() => props.toggleState("isBranchProfile")}
                  label="Edit"
                />
              )} */}
              {isEditable ? (
                <Form.Field
                  control={Button}
                  type="submit"
                  disabled={submitting}
                  style={{
                    borderRadius: "80px",
                    backgroundColor: "#F13A88",
                    color: "white",
                    fontWeight: 400,
                    padding: "8px 33px",
                  }}
                >
                  Save
                </Form.Field>
              ) : (
                <Button basic type="button" onClick={() => props.toggleState("isBranchProfile")} className="edit-btn">
                  Edit
                </Button>
              )}
            </div>
          </div>
        }
      >
        <div className="mb-2 d-flex gap-15">
          <div className="w-100">
            <FPLabel isRequired={false}>Branch Tag</FPLabel>
            <Field
              name="branch_tag"
              component={MultiSelect}
              placeholder="Select Branch Tag"
              options={branchTagList}
              val={tag}
              onChange={handleSelectBranchTag}
              disabled={!isEditable}
              labelKey="title"
              valueKey="id"
              multi
              simpleValue
              className={"h-100"}
            />
          </div>

          <div
            className="d-flex align-items-center justify-content-end gap-6 mt-4"
            type="button"
            disabled={!isEditable}
            onClick={() => {
              setOpen(true);
            }}
            style={{ pointerEvents: !isEditable ? "none" : "auto" }}
          >
            <img src={plus} />
            <Box component="span" sx={{ fontSize: "15px", color: "#F13A88", textWrap: "nowrap" }}>
              Add Tag
            </Box>
          </div>
          {open && (
            <FormModal
              isOpen={open}
              toggle={handleBranchTagModal}
              heading="Enter the Branch Tag"
              width={"580px"}
              headerClass="d-flex align-items-center"
            >
              <Input
                fluid
                placeholder="Branch Tag"
                onChange={(e) => {
                  setBranchTagVal(e.target.value);

                  if (e.target.value.length === 0) setErrorMsg(true);
                  else {
                    setErrorMsg(false);
                  }
                }}
              />

              <FPButton
                type="button"
                disabled={errorMsg}
                style={{ margin: "1rem auto", float: "right" }}
                onClick={() => {
                  if (branchTagVal === null) {
                    setErrorMsg(true);
                  } else {
                    branchTagDataSubmit();
                    setOpen(false);
                  }
                }}
              >
                {" "}
                Add
              </FPButton>
            </FormModal>
          )}
        </div>
        <Row className="mb-2">
          <Col sm="6">
            <FPLabel isRequired={false}>Alias/Short Name</FPLabel>
            <Field
              name="alias"
              placeholder="Enter Short Location Name"
              type="text"
              component={InputComponent}
              disabled={!isEditable}
              serverError={errors && errors.alias}
              minHeight="0px"
            />
          </Col>
          <Col sm="6">
            <FPLabel isRequired={false}>Address</FPLabel>
            <Field name="location_address" type="text" component={InputComponent} disabled minHeight="0px" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm="6">
            <FPLabel isRequired={false}>State</FPLabel>
            <Field name="address_state" type="text" component={InputComponent} disabled={!isEditable} minHeight="0px" />
          </Col>
          <Col sm="6">
            <FPLabel isRequired={false}>City</FPLabel>
            <Field name="address_city" type="text" component={InputComponent} disabled={!isEditable} minHeight="0px" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm="6">
            <FPLabel isRequired={false}>Phone</FPLabel>
            <Field
              name="contact_number"
              placeholder="Enter Store Phone Number"
              type="text"
              component={PhoneInputField}
              disabled={!isEditable}
              // serverError={errors && errors.contact_number}
              // minHeight="0px"
            />
          </Col>
          <Col sm="6">
            <FPLabel isRequired={false}>Email</FPLabel>
            <Field
              name="email"
              placeholder="Enter Email Id"
              type="text"
              disabled={!isEditable}
              component={InputComponent}
              minHeight="0px"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm="6">
            <FPLabel isRequired={false}>Website</FPLabel>
            <Field
              name="website"
              placeholder="http://"
              type="text"
              component={InputComponent}
              disabled={!isEditable}
              serverError={errors && errors.website}
              minHeight="0px"
            />
          </Col>
          <Col sm="6"></Col>
        </Row>
      </CardWrapper>
    </Form>
  );
};

BranchProfileSetup = reduxForm({
  form: "BranchProfileSetup",
  enableReinitialize: true,
})(BranchProfileSetup);

BranchProfileSetup = connect((state) => ({
  initialValues: state.business.info, // pull initial values from account reducer
  categories: state.common.categories,
}))(BranchProfileSetup);

export default BranchProfileSetup;
