import React from "react";
import queryString from "query-string";
import { Row, Card } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { FPButton } from "../../../components/common/new";
import ResetIcon from "../../../images/Reset.png";
import { renderAutoSelectField } from "../../../components/common/RenderAutoSelectField";
import DateInputField from "../../../components/common/new/DateInputField";

class PerformanceFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleFilterApply = this.handleFilterApply.bind(this);
    this.resetSource = this.resetSource.bind(this);
  }

  handleFilterApply(values) {
    const {
      history,
      location: { pathname, search },
      onClose,
    } = this.props;
    const payload = queryString.parse(search);
    if (values?.date?.date_type === "other") {
      delete values?.date.date_type;
    }
    history.push({
      pathname,
      search: queryString.stringify({ ...payload, location_id: values.location_id, ...values.date }),
    });
    onClose?.();
  }

  resetSource() {
    const {
      history,
      location: { pathname, search },
      onClose,
    } = this.props;

    let filters = queryString.parse(search);
    const fieldsToDelete = ["location_id", "date_type", "end_date", "start_date", "type"];

    fieldsToDelete.forEach((field) => delete filters[field]);

    filters.type = "overtime";
    filters.date_type = "prior_30_day";
    history.push(pathname + "?" + queryString.stringify(filters));
    onClose?.();
  }

  render() {
    const { dateOptions, handleSubmit, locationOptions } = this.props;

    return (
      <Card body className="py-3 form-label performanceFilter">
        <Row className="m-0">
          <form className="w-100" onSubmit={handleSubmit(this.handleFilterApply)}>
            <div className="mt-3">
              <Field name="date" component={DateInputField} options={dateOptions} />
            </div>

            <div className="mt-3">
              <Field
                name="location_id"
                type="text"
                component={renderAutoSelectField}
                placeholder="Location"
                options={locationOptions}
                sx={{
                  "& fieldset": { borderRadius: 2 },
                  "& .MuiInputBase-input": {
                    height: 20,
                    fontSize: "12px",
                    color: "#646565 !important",
                    "&::placeholder": {
                      color: "#646565",
                    },
                  },
                }}
              />
            </div>

            <div className="d-flex gap-10 mt-3">
              <FPButton type="submit" label="Apply" size="large" fontSize="14px" className="w-50 button" />
              <FPButton
                type="button"
                onClick={this.resetSource}
                label="Reset"
                size="large"
                fontSize="14px"
                backgroundColor="#00B5AD"
                className="button w-50"
                startIcon={<img src={ResetIcon} alt="Reset" />}
              />
            </div>
          </form>
        </Row>
      </Card>
    );
  }
}

export default reduxForm({
  form: "reportFilterForm",
  enableReinitialize: true,
})(PerformanceFilter);
