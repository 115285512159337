import React from "react";
import { Field, useFormikContext } from "formik";
import classNames from "classnames";
import AutoSelect from "../../AutoSelect";
import { FormFeedback } from "reactstrap";

const FormAutoComplete = ({ name, className, hideDefaultError, onChange, alertProps, ...rest }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={name}>
      {({ field, meta: { touched, error } }) => {
        return (
          <>
          
          <AutoSelect
            className={classNames("!mb-0", className)}
            onChange={(event, option) => {              
              setFieldValue(name, option === null ? "" : option);
              onChange && onChange(event, option);
            }}
            name={name}
            value={field?.value || []}
            {...rest}
          />
          {touched && error && <FormFeedback>{error}</FormFeedback>}
          </>
        );
      }}
    </Field>
  );
};

export default FormAutoComplete;
