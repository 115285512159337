import React, { Fragment, useState, useRef, useEffect } from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoPrintOutline } from "react-icons/io5";
import { FPBadge, FPToolTip } from "../../components/common/new";
import Radio from "@material-ui/core/Radio";
import OfferChart from "../../components/restro-pilot/OfferChart";
import classnames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Table } from "antd";
import Donut from "../../components/common/new/Donut";
import { Checkbox } from "semantic-ui-react";

const chartColors = {
  "all" : '#ff1493', 
  "lapsed": '#9d75f2', 
  "new": '#b793f5', 
  "repeat": '#d8bafe'
};

const AdsEngagement = ({ adsEngagementData,engagementTableOvertime, engagementTableLocation, handleDownload, isProviderWise, handleProviderWise, selectedCard }) => {
  const [activeSection, setActiveSection] = useState("overtime");
  const [view, setView] = useState("Graph");
  const [chartLegends, setChartLegends] = useState({});
  const [chartLegendsLocation, setChartLegendsLocation] = useState({});
  const [overtimeData, setOvertimeData] = useState([]);
  const [overtimeLabels, setOvertimeLabels] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [locationLabels, setLocationLabels] = useState([]);

  useEffect(() => {
    if(adsEngagementData) {
      setChartLegends(adsEngagementData?.total_overtime);
      setChartLegendsLocation(adsEngagementData?.total_location)
      const over_label = [];
      adsEngagementData?.overtime?.all && Object.keys(adsEngagementData?.overtime?.all).map((val) => over_label.push(val));
      
      const location_label = [];
      adsEngagementData?.location?.all && Object.keys(adsEngagementData?.location?.all).map((val) => location_label.push(val));

      const location_graph = [
        {
          "label": "All",
          "data": location_label.map((val) => adsEngagementData?.location?.all[val]),
          "borderColor": "#ff1493",
          "backgroundColor": "#ff1493",
          "tension": 0,
        },
        {
          "label": "Lapsed",
          "data": location_label.map((val) => adsEngagementData?.location?.lapsed[val]),
          "borderColor": "#9d75f2",
          "backgroundColor": "#9d75f2",
          "tension": 0,
          "borderWidth": 2
        },
        {
          "label": "New",
          "data": location_label.map((val) => adsEngagementData?.location?.new[val]),
          "borderColor": "#b793f5",
          "backgroundColor": "#b793f5",
          "tension": 0,
          "borderWidth": 2
        },
        {
          "label": "Repeat",
          "data": location_label.map((val) => adsEngagementData?.location?.repeat[val]),
          "borderColor": "#d8bafe",
          "backgroundColor": "#d8bafe",
          "tension": 0,
          "borderWidth": 2
        },
      ];

      const overtime_graph = [
        {
          "label": "All",
          "data": over_label.map((val) => adsEngagementData?.overtime?.all[val]),
          "borderColor": "#ff1493",
          "backgroundColor": "#ff1493",
          "tension": 0,
        },
        {
          "label": "Lapsed",
          "data": over_label.map((val) => adsEngagementData?.overtime?.lapsed[val]),
          "borderColor": "#9d75f2",
          "backgroundColor": "#9d75f2",
          "tension": 0,
          "borderWidth": 2
        },
        {
          "label": "New",
          "data": over_label.map((val) => adsEngagementData?.overtime?.new[val]),
          "borderColor": "#b793f5",
          "backgroundColor": "#b793f5",
          "tension": 0,
          "borderWidth": 2
        },
        {
          "label": "Repeat",
          "data": over_label.map((val) => adsEngagementData?.overtime?.repeat[val]),
          "borderColor": "#d8bafe",
          "backgroundColor": "#d8bafe",
          "tension": 0,
          "borderWidth": 2
        },
      ];

      setLocationData(location_graph);
      setOvertimeData(overtime_graph);
      setOvertimeLabels(over_label);
      setLocationLabels(location_label);
      
    }
  }, [adsEngagementData]);

  return <div>
      <div className="report-ThirdRow mb-4">
        <Nav tabs className="py-1 box-shadow-none" style={{ borderBottom: "none" }}>
          <Fragment>
            <NavItem>
              <NavLink
                className={classnames({ active: activeSection === "overtime" })}
                onClick={() => setActiveSection("overtime")}
              >
                Overtime
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeSection === "location" })}
                onClick={() => setActiveSection("location")}
              >
                Location
              </NavLink>
            </NavItem>
          </Fragment>
        </Nav>
      </div>

    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "gray" }}>
        <FormControlLabel
          value="Graph"
          label="Graph"
          control={<Radio />}
          onClick={() => setView("Graph")}
          checked={view === "Graph"}
        />
        <FormControlLabel
          value="Table"
          label="Table"
          control={<Radio />}
          onClick={() => setView("Table")}
          checked={view === "Table"}
        />
      </div>
      <div style={{ display: "flex", gap: "20px", alignItems: 'center' }}>
        {view=== 'Table' && <div className="d-flex align-items-center gap-6">
          <span style={{ fontSize: "14px", fontWeight: "400", color: "rgba(27, 28, 29, 1)" }} > Provider Wise </span>
          <Checkbox
            toggle
            value="Provider wise"
            checked={isProviderWise}
            onChange={(e, checked) => handleProviderWise(e, checked)}
          ></Checkbox>
        </div>}
        <FPToolTip title={"Download"} placement={"top"}>
          <MdOutlineFileDownload style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => handleDownload('engagement', activeSection==='location')} />
        </FPToolTip>
        <FPToolTip title={"Print"} placement={"top"}>
          <IoPrintOutline style={{ fontSize: "18px", cursor: "pointer" }} onClick={() =>  window.print()} />
        </FPToolTip>
      </div>
    </div>

    {activeSection === "overtime" && view === "Graph" &&  <div className="mt-4" style={{ paddingRight: "15px" }}>
      <div className="d-flex flex-wrap gap-20 mt-2 p-4 mt-md-0 justify-content-center" style={{ textTransform: 'uppercase' }} >
        {chartLegends && Object.keys(chartLegends).map((item, index) => {
          return (
            <FPBadge
              color={chartColors[item]}
              label={<span>{item} { (selectedCard==="sales" || selectedCard==="spends") && "₹"  } {chartLegends[item] ? Intl.NumberFormat('en-IN').format( chartLegends[item] ) : 0} </span>}
              textClass="font-blue-gray fp-size-16"
              key={`${index}-ads-legend`}
            />
          );
        })}
      </div>
    </div>}
    {activeSection === "location" && view === "Graph" &&  <div className="mt-4" style={{ paddingRight: "15px" }}>
      <div className="d-flex flex-wrap gap-20 mt-2 p-4 mt-md-0 justify-content-center" style={{ textTransform: 'uppercase' }} >
        {chartLegendsLocation && Object.keys(chartLegendsLocation).map((item, index) => {
          return (
            <FPBadge
              color={chartColors[item] || "#ff1493"}
              label={<span>{item} { (selectedCard==="sales" || selectedCard==="spends") ? "₹" : "" }  {chartLegendsLocation[item] ? Intl.NumberFormat('en-IN').format( chartLegendsLocation[item] ) : 0} </span>}
              textClass="font-blue-gray fp-size-16"
              key={`${index}-ads-legend`}
            />
          );
        })}
      </div>
    </div>}

    {activeSection === "overtime" && view === "Table" && <AdsEngagementTable adsList={engagementTableOvertime || []} isProviderWise={isProviderWise} /> }
    {activeSection === "overtime" && view === "Graph" &&  <div className="mb-4 " style={{ display: 'grid', gridTemplateColumns: '4fr 3fr', gap: '15px', overflowX: 'auto', width: '100%' }}>
      <div style={{ background: '#f5f5f57d', borderRadius: '20px', padding: '16px'}}>
        <OfferChart isAdsPage={true} labels={overtimeLabels || []} overtimeData={overtimeData || []} primaryYLabel={"Ads Sales(In ₹)"} />
      </div>
      <div style={{ background: '#f5f5f57d', borderRadius: '20px', padding: '16px'}}>
        <AdsPieChart chartLegends={chartLegends || {}} selectedCard={selectedCard} />
      </div>
    </div>}

    {activeSection === "location" && view === "Table" && <AdsEngagementTable adsList={engagementTableLocation || []} isLocation={true} isProviderWise={isProviderWise} />}
    {activeSection === "location" && view === "Graph" &&  <div className="mb-4 " style={{ display: 'grid', gridTemplateColumns: '4fr 3fr', gap: '15px', overflowX: 'auto', width: '100%' }}>
      <div style={{ background: '#f5f5f57d', borderRadius: '20px', padding: '16px'}}>
        <OfferChart isAdsPage={true} labels={locationLabels || []} overtimeData={locationData || []} primaryYLabel={"Ads Sales(In ₹)"} />
      </div>
      <div style={{ background: '#f5f5f57d', borderRadius: '20px', padding: '16px'}}>
        <AdsPieChart chartLegends={chartLegendsLocation || {}} selectedCard={selectedCard} />
      </div>
    </div>}
  </div>;
}

const AdsEngagementTable = ({ adsList, isLocation, isProviderWise }) => {
  let columns = [];

  columns.push({
    title: isLocation ? "Locations" : "Date",
    dataIndex: isLocation ? "location" : "date",
    sorter: isLocation && { compare: (a, b) => a.location ? a.location.localeCompare(b.location) : 0 },
  });

  if(isProviderWise) {
    columns.push({
      title: "Provider",
      dataIndex: "provider",
      sorter:  { compare: (a, b) =>  a.provider.localeCompare(b.provider)  },
    });
  }

  columns.push({
    title: "All",
    dataIndex: "all",
    sorter: { compare: (a, b) => b.all - a.all },
  });

  columns.push({
    title: "Lapsed",
    dataIndex: "lapsed",
    sorter: { compare: (a, b) => b.lapsed - a.lapsed },
  });

  columns.push({
    title: "New",
    dataIndex: "new",
    sorter: { compare: (a, b) => b.new - a.new },
  });

  columns.push({
    title: "Repeat",
    dataIndex: "repeat",
    sorter: { compare: (a, b) => b.repeat - a.repeat },
  });

  const onChange = (filters, sorter, extra) => {
    console.log("params", filters, sorter, extra);
  };

  return <div className="mt-3" >
  {adsList && Array.isArray(adsList) && adsList.length > 0 && <div className="ui-table-responsive">
    <Table
      // loading={loading}
      columns={columns}
      dataSource={adsList}
      onChange={onChange}
      pagination={false}
      showSorterTooltip={false}
    />
  </div>}
  </div>
  }



const AdsPieChart = ({ chartLegends, selectedCard }) => {
  const [lapsedPercentage, setLapsedPercentage] = useState(0);
  const [newPercentage, setNewPercentage] = useState(0);
  const [repeatPercentage, setRepeatPercentage] = useState(0);
  const [totalReview, setTotalReviews] = useState(0);
  useEffect(() => {
    if (chartLegends.all > 0 ) {
      chartLegends?.lapsed && setLapsedPercentage((chartLegends.lapsed / chartLegends.all) *100);
      chartLegends?.new && setNewPercentage((chartLegends.new / chartLegends.all) *100);
      chartLegends?.repeat && setRepeatPercentage((chartLegends.repeat / chartLegends.all) *100);
      chartLegends?.all && setTotalReviews(chartLegends.all);
    } else {
      setLapsedPercentage(0);
      setNewPercentage(0);
      setRepeatPercentage(0);
      setTotalReviews(0);
    }
  }, [chartLegends]);
  
  const staticChartData = {
    labels: [ "Lapsed", "New", "Repeat"],
    legends: ["Lapsed", "New", "Repeat"],
    datasets: [ 
      {
        data: [ lapsedPercentage, newPercentage, repeatPercentage],
        backgroundColor: [ '#9d75f2', '#b793f5', '#d8bafe']
      },
    ],
  };

  const chartRef = useRef(null);
  const legendRef = useRef(null);

  const [chartDimension, setChartDimension] = useState({ height: 0, width: 0 });
  const [legendDimension, setLegendDimension] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      if (chartRef.current) {
        const { offsetHeight, offsetWidth } = chartRef.current;
        setChartDimension({ height: offsetHeight, width: offsetWidth });
      }

      if (legendRef.current) {
        const { clientHeight, clientWidth } = legendRef.current;
        setLegendDimension({ height: clientHeight, width: clientWidth });
      }
    });
  }, [chartRef, legendRef]);

  return (
    <section style={{ height: '100%', display: 'flex', justifyContent: 'center' }} ref={chartRef}>
      <div className="d-flex flex-column justify-content-center p-4 " style={{ height: "100%" }}>
        <Donut
          isAdsPage={true}
          key={`${chartDimension.height} - ${legendDimension.height} - ${totalReview}`}
          isLoading={false}
          cutout={150}
          data={staticChartData}
          width={400}
          centerText={{ text: `${(selectedCard==="sales" || selectedCard==="spends") ? "₹" : "" } ${totalReview ? Intl.NumberFormat('en-IN').format(totalReview) : 0}`, label: "" }}
          height={400}
          options={{
            plugins: {
              legend: { display: false },
              tooltip: {
                enabled: totalReview > 0,
                callbacks: {
                  label: (context) => `${context.label} : ${context.formattedValue}%`,
                },
              },
            },
          }}
        />
      </div>
    </section>
  );
};


export default AdsEngagement;