import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import './style.css';

const StyledMenu = withStyles({
  paper: {
    border: (props) => (props.hideBorder ? 'none' : '1px solid #BFC2CB'),
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    borderRadius: '8px',
    minWidth: ({wrapperMinWidth}) =>  wrapperMinWidth ? wrapperMinWidth : 'auto',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: props.isLeft ? 'left' : 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: props.isLeft ? 'left' : 'right',
    }}
    className='fp-menu'
    {...props}
  />
));

const FPMenu = ({ children, ...rest }) => {
  return <StyledMenu {...rest}>{children}</StyledMenu>;
};

export default FPMenu;
