/* eslint-disable */
import React, { useEffect, useState } from "react";
import moment from "moment";
import fpIcon from "../../images/fp-icon.png";
import { Box } from "@material-ui/core";
import location from "../../images/svg/LocationColor.svg";
import { FPToolTip } from "../common/new";
import { SwiggyIcon, WithMediaIcon, ZomatoIcon } from "../../images/svg";

const ComplaintsList = ({ toggle, complaintList, handleComplaint }) => {
  const [complaints, setcomplaints] = useState(null);
  const [selectedComplaint, setSelectedComplaint] = useState(null);

  const handleClick = (event, complaint) => {
    setSelectedComplaint(complaint);
    toggle(complaint);
  };
  useEffect(() => {
    if (complaintList.results && complaintList.results.length > 0) {
      toggle(complaintList.results[0]);
    } else {
      handleComplaint();
    }
  }, [JSON.stringify(complaintList)]);

  useEffect(() => {
    setcomplaints(complaintList);
    setSelectedComplaint(
      complaintList &&
        complaints &&
        Array.isArray(complaints.results) &&
        complaints.results[0]
    );
  }, [complaintList]);

  return (
    complaints &&
    Array.isArray(complaints.results) &&
    complaints.results.length > 0 && (
      <Box
        sx={{
          height: "610px",
          overflowY: "auto",
          marginTop: "7px",
          marginBottom: "0px",
          paddingLeft: "0px",
          paddingRight: "7px",
        }}
      >
        {complaints && Array.isArray(complaints.results) && (
          <div className="d-flex flex-column gap-20 pb-3">
            {complaints.results?.map((complaint) => {
              return (
                <div
                  onClick={(event) => handleClick(event, complaint)}
                  style={{
                    padding: "10px 15px",
                    borderRadius: "9px",
                    backgroundColor:
                      selectedComplaint &&
                      selectedComplaint.id === complaint?.id
                        ? "#F2F9F9"
                        : "white",
                    border:
                      selectedComplaint &&
                      selectedComplaint?.id === complaint?.id
                        ? "2px solid #F13A88"
                        : "white",
                    boxShadow:
                      selectedComplaint?.id !== complaint?.id &&
                      (complaint?.is_resolved
                        ? "rgb(18, 183, 106) 1px 1px 0px 1px, 0px 1px 28px 0px #00000026"
                        : "#F04438 1px 1px 0px 1px, 0px 1px 28px 0px #00000026"),
                    // boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  key={complaint.id}
                >
                  <div className="d-flex justify-content-between">
                    <Box className=" d-flex gap-10">
                      <Box
                        component="span"
                        sx={{
                          color: "#F04438",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {complaint.complaint_type.replace(/_/g, " ")}
                      </Box>
                      {(complaint?.extra_data?.complaint_images?.length > 0 ||
                        complaint?.extra_data?.complaint_videos?.length >
                          0) && (
                        <FPToolTip title={"With Media"}>
                          <Box
                            component="span"
                            sx={{
                              fontSize: "10px",
                            }}
                          >
                            <WithMediaIcon />
                          </Box>
                        </FPToolTip>
                      )}
                    </Box>
                    <div
                      style={{
                        cursor: "pointer",
                        display: "inline-block",
                        height: "25px",
                        width: "25px",
                      }}
                      onClick={() => {
                        if (
                          complaint.reviewer &&
                          complaint.reviewer.profile_url
                        ) {
                          window.open(complaint.reviewer.profile_url, "_blank");
                        }
                      }}
                    >
                      {complaint.provider === "zomato" ? (
                        <ZomatoIcon />
                      ) : complaint.provider === "swiggy" ? (
                        <SwiggyIcon />
                      ) : (
                        "FpIcon"
                      )}
                    </div>
                  </div>
                  <Box
                    sx={{
                      marginBottom: "6px",
                      marginTop: "4px",
                      color: "#7A8193",
                      fontSize: "14px",
                    }}
                  >
                    Order ID:{" "}
                    {complaint.extra_data.order_id
                      ? complaint.extra_data.order_id
                      : "Empty Order Id"}
                    {/* {complaint.complaint_create_date &&<span>&nbsp;|&nbsp;
                  <span style={{ color: '#1397b7', fontWeight: '400', fontSize: '13.5px'}}>{moment(complaint.complaint_create_date).fromNow(true)} {' ago'}</span>
                </span>}   */}
                  </Box>

                  <Box
                    className="align-center justify-content-between"
                    sx={{ fontSize: "10px", fontWeight: 300 }}
                  >
                    <div>
                      <Box sx={{ color: "gray", textWrap: "wrap" }}>
                        <FPToolTip
                          title={"Complaint Create Date"}
                        >
                          <Box
                            component="span"
                            sx={{
                              display: "inline-block",
                              maxWidth: { xs: "100%" },
                              "@media (min-width: 1200px) and (max-width: 1630px)":
                                {
                                  maxWidth: "140px", // Apply 140px between 1290px and 1630px
                                },
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              verticalAlign: "middle",
                            }}
                          >
                            {complaint.complaint_create_date
                              ? moment(complaint.complaint_create_date).format(
                                  "DD MMM YYYY, hh:mm A"
                                )
                              : ""}
                          </Box>
                        </FPToolTip>
                      </Box>

                      <Box sx={{ color: "gray", textWrap: "wrap" }}>
                        <FPToolTip
                          title={"Complaint Pull Date"}
                        >
                          <Box
                            component="span"
                            sx={{
                              display: "inline-block",
                              maxWidth: { xs: "100%" },
                              "@media (min-width: 1200px) and (max-width: 1630px)":
                                {
                                  maxWidth: "140px", // Apply 140px between 1290px and 1630px
                                },
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              verticalAlign: "middle",
                            }}
                          >
                            {complaint.complaint_pull_date
                              ? moment(complaint.complaint_pull_date).format(
                                  "DD MMM YYYY, hh:mm A"
                                )
                              : ""}
                          </Box>
                        </FPToolTip>
                      </Box>
                    </div>

                    {complaint.provider && (
                      <div
                        className="d-flex align-items-center "
                        style={{ width: "fit-content" }}
                      >
                        <img src={location} style={{ marginRight: "5px" }} />
                        <Box
                          component="span"
                          sx={{ color: "black" }}
                          className="text-truncate complaintsListLocation"
                        >
                          <FPToolTip title={complaint.branch}>
                            {complaint.branch}{" "}
                          </FPToolTip>
                        </Box>
                      </div>
                    )}
                  </Box>
                </div>
              );
            })}
          </div>
        )}
      </Box>
    )
  );
};

export default ComplaintsList;
