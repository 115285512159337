import moment from "moment";
import React, { useState } from "react";
import CustomStarRatingComponent from "./StarComponent";
import { CSSTransitionGroup } from "react-transition-group";
import ReactPaginate from "react-paginate";
import apiUrls from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import LimitModal from "../../components/common/LimitModal";
import Highlighter from "react-highlight-words";
import LoaderComponent from "../messenger/Common/LoaderComponent";
import { downloadCsvHandler } from "../../actions/business/reviewsActions";
import FormModal from "./FormModal";
import { FPAvatar, FPButton, FPToolTip, MessageBox } from "./new";
import downloadIcon from "../../images/Download.png";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import classNames from "classnames";
import "../../components/business/reviewList.css";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import FPChip from "./new/FPChip/FPChip";
import { colorList } from "../../libs/constant";
import { snakeToCapital } from "../../libs/utils";
import FPSelect from "./new/forms/ui/FPSelect";

const sentimentItem = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Positive",
    value: "2",
  },
  {
    label: "Negative",
    value: "1",
  },
  {
    label: "Neutral",
    value: "0",
  },
];
function getLabelByValue(value) {
  const item = sentimentItem.find(item => item.value === value);
  return item ? item.label : null;
}

const color = {
  Positive: "2px solid green",
  Negative: "2px solid red",
};

const TopicsReviewCard = ({ data, adjectives }) => {
  const item = {
    branch: data.branch,
    branch_address: data.branch_address,
    provider: data.provider,
    provider_name: data?.provider_name,
    reviewer: data.reviewer,
    review_create: data.review_create,
    review_rating: data.rating,
    sentiment: data.sentiment,
    chunk: data.description,
    review_id: data.id,
    tags: data.tag,
    adjectives: adjectives,
  };

  function findAll(regex, text) {
    const matches = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.push({
        start: match.index,
        end: regex.lastIndex,
      });
    }
    return matches;
  }

  const date = moment(item.review_create).format("LL");
  return (
    <CardWrapper
      className={classNames(
        "fp-card-outline review-card-wrapper ticket-card-header"
      )}
      showDivider={false}
      style={{ border: "2px solid #dee2e6" }}
    >
      <header className="review-card-header">
        <div className="d-flex justify-content-between">
          <section className="d-flex align-item-center gap-10">
            <FPToolTip
              title={item?.provider_name || ""}
              className="text-capitalize"
            >
              <div className="provider-logo-wrapper">
                <img
                  src={item.provider}
                  alt={item?.provider_name || "provider"}
                  height="24"
                  width="24"
                />
              </div>
            </FPToolTip>
            <div className="d-flex align-items-start gap-10">
              <div>
                <FPToolTip title={item.branch_address}>
                  <span className="review-title">{item.branch}</span>
                </FPToolTip>
                <span className="d-block font-blue-gray">{date}</span>
              </div>
            </div>
            <div className="review-time">
              <div className="rating">
                <CustomStarRatingComponent
                  name="rate1"
                  starCount={5}
                  className="align-bottom"
                  starColor="#FBBD08"
                  emptyStarColor="#FFFFFF"
                  value={+item.review_rating}
                  editing={false}
                />
              </div>
            </div>
          </section>
        </div>
      </header>
      <section className="review-card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <FPAvatar
              alt={item?.reviewer?.name || "Reviewer"}
              src={item?.reviewer?.image}
              labelClass={"user-name"}
              userName={item?.reviewer?.name?.toString()}
            />
          </div>
        </div>
        <MessageBox isSelfBox={false} className={"max-w-100"}>
          <Highlighter
            highlightStyle={{
              fontWeight: "bold",
              borderBottom: `${color[item.sentiment]}`,
              background: "none",
            }}
            searchWords={item.adjectives || []}
            autoEscape={true}
            findChunks={(data) => {
              const regex = new RegExp(
                `\\b(${data.searchWords.join("|")})\\b`,
                "gi"
              );
              return findAll(regex, data.textToHighlight);
            }}
            textToHighlight={item.chunk}
          ></Highlighter>
        </MessageBox>

        {item.tags && item.tags.length > 0 && (
          <div className="d-flex gap-10 mt-15">
            <FPButton
              startIcon={
                <LocalOfferOutlinedIcon
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "-5px",
                  }}
                />
              }
              className="px-3"
              backgroundColor="#161616"
              size="small"
              fontWeight="400"
              fontSize="13px"
              label="Tags"
              style={{
                height: 32,
              }}
            />
            <CSSTransitionGroup
              transitionName="reviews-tags"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}
              className="d-flex flex-wrap gap-10"
            >
              {item.tags.map((tag, index) => {
                return (
                  <FPChip
                    key={`${index}-review-tags`}
                    size="small"
                    variant="outlined"
                    style={{
                      borderColor:
                        colorList.length !== item.tags.length
                          ? colorList[index]
                          : "#BFC2CB",
                      borderWidth: "2px",
                    }}
                    className="review-tag-chip fp-size-13"
                    label={snakeToCapital(tag)}
                  />
                );
              })}
            </CSSTransitionGroup>
          </div>
        )}
      </section>
    </CardWrapper>
  );
};

const ReviewModel = (props) => {
  const {
    isOpen,
    toggle,
    headerText,
    reviewData,
    staffId,
    businessId,
    type,
    staffName,
  } = props;
  const { search } = props.location;
  const [reviewsData, setReviewsData] = useState(reviewData);
  const [loading, setloading] = useState(false);
  const [downloadReviewModal, setDownloadReviewModal] = useState(false);
  const [sentimentType, setSentimentType] = useState(type);

  const getReview = (page, type) => {
    const url = `${apiUrls.STAFF_INSIGHTS_REVIEW}${search ? search+'&' : '?'}`;
    const params = {
      staff_id: staffId,
      business_id: businessId,
      page: page,
    };
    type !== "all" && (params["type"] = type);
    setloading(true);
    axios_instance
      .get(url, { params })
      .then((res) => {
        setReviewsData(res.data);
        setloading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handlePageClick = (selectedPage) => {
    getReview(selectedPage.selected + 1, sentimentType);
  };

  const handleDownloadReviewModal = async (download) => {
    if (download && reviewsData.reviews.count > 0) {
      let baseUrl = `${apiUrls.STAFF_REVIEWS_DOWNLOAD}${search ? search+'&' : '?'}type=${
        sentimentType > -1 ? sentimentType : "all"
      }&business_id=${businessId}&staff_id=${staffId}`;
      downloadCsvHandler(
        baseUrl,
        `${staffName} ${headerText}.csv` || "famepilor-staffReviews-report.csv"
      );
    }
    setDownloadReviewModal(!downloadReviewModal);
  };

  return (
    <div>
      <FormModal
        open={isOpen}
        toggle={toggle}
        closeIcon
        size="small"
        width={960}
        heading={
          <div>
            <p className="m-0">
              Reviews{" "}
              <span className="font-blue-gray fp-size-16">({staffName})</span>
            </p>
          </div>
        }
        headerClass="mb-20"
        bodyClass="pt-0 pb-0 mb-20"
        actionClass="justify-content-center pt-0"
        subHeading={
          <>
            <div className="d-flex justify-content-end mb-20 gap-10">
              {reviewsData.reviews.count > 0 && (
                <FPButton
                  variant="outline"
                  label="Download Report"
                  startIcon={
                    <img
                      src={downloadIcon}
                      alt="download"
                      width={15}
                      height={15}
                    />
                  }
                  onClick={() => setDownloadReviewModal(!downloadReviewModal)}
                  size="small"
                />
              )}
              <FPSelect
                className="sentiment-filter"
                options={sentimentItem}
                placeHolder="Sort By"
                onChange={(e) => {
                  setSentimentType(e.target.value);
                  getReview("1", e.target.value);
                }}
              />
            </div>
          </>
        }
        dialogActions={
          reviewsData.reviews.total_pages > 1 && (
            <ReactPaginate
              key={sentimentType}
              previousLabel={"← Previous"}
              nextLabel={"Next →"}
              breakLabel="..."
              pageCount={reviewsData.reviews.total_pages}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          )
        }
      >
        <>
          {reviewsData &&
          reviewsData.reviews.results &&
          reviewsData.reviews.results.length > 0 ? (
            <>
              {loading ? (
                <div
                  style={{
                    minHeight: "80vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoaderComponent showLoader={loading} />
                </div>
              ) : (
                <div className="d-flex flex-column gap-20">
                  {reviewsData.reviews.results.map((data, index) => {
                    return (
                      <TopicsReviewCard
                        key={index}
                        data={data}
                        adjectives={reviewsData.adjectives}
                      />
                    );
                  })}
                </div>
              )}
            </>
          ) : (
            <div className="text-center my-4">
              <h4 className="font-blue text-capitalize">No {!!sentimentType.toString() ? getLabelByValue(sentimentType) : ''} Reviews Found</h4>
            </div>
          )}
        </>
      </FormModal>

      {downloadReviewModal && (
        <LimitModal
          isOpen={downloadReviewModal}
          isReviewsPage={true}
          toggle={handleDownloadReviewModal}
          HeaderText={"Alrighty"}
          message={[
            "Hey you are good to go. Click ok to download Reviews Report",
          ]}
          handleDownloadModal={handleDownloadReviewModal}
        />
      )}
    </div>
  );
};

export default ReviewModel;
