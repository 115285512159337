import React, { useState, useRef, useEffect } from "react";
import { MentionsInput, Mention } from "react-mentions";
import CommentCard from "../../components/common/CommentCardComponent";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import AddIcon from "@mui/icons-material/Add";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ClearIcon from "@mui/icons-material/Clear";
import DescriptionIcon from "@mui/icons-material/Description";
import attachment from "../../images/svg/attachment.svg";
import SrAudio from "../../images/svg/SrAudio.svg";
import SrFile from "../../images/svg/SrFile.svg";
import SrPhoto from "../../images/svg/SrPhoto.svg";
import SrVideo from "../../images/svg/SrVideo.svg";
import SrSend from "../../images/svg/SrSend.svg";
import { Box } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import SrCommentCard from "./SrCommentCard";

const SrCommentComponents = ({
  item,
  handleAddComment,
  handleCommentChange,
  handleFileChange,
  comment,
  selectedFile,
  getColor,
  setFileAttachments,
  setOpenComment,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [newAttachment, setNewAttachment] = useState([]);
  const attachmentInputRef = useRef(null);


  const statusOptions = [
    {
      label: "Photo",
      value: "photo",
      icon: SrPhoto,
      accept: ".png, .jpg, .svg",
    },
    { label: "Video", value: "video", icon: SrVideo, accept: ".mp4" },
    { label: "Audio", value: "audio", icon: SrAudio, accept: ".mp3, .wav" },
    { label: "File", value: "file", icon: SrFile, accept: ".pdf, .xml" },
  ];
  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAttachmentUpload = (event) => {
    const files = Array.from(event.target.files);
    setNewAttachment((prev) => [...prev, ...files]);
    event.target.value = "";
    setDropdownOpen(false)
  };

  const openImagePreview = (file, index) => {
    setFileAttachments(file)
    setOpenComment(true);
  };

  const removeAttachment = (index) => {
    setNewAttachment((prev) => prev.filter((_, i) => i !== index));
  };

  const getFileIcon = (file) => {
    if (file.type.includes("audio"))
      return <AudiotrackIcon className="file-icon" />;
    if (file.type.includes("video"))
      return <PlayCircleIcon className="file-icon" />;
    if (file.type.includes("image"))
      return <DescriptionIcon className="file-icon" />;
    return <DescriptionIcon className="file-icon" />;
  };


  
  return (
    <div>
      <Row className="d-flex" style={{ padding: "24px 14px" }}>
        <Col className="d-flex gap-10 p-0">
          <div style={{ flexGrow: 1 }}>
            <div className="position-relative">
              <div
                className="position-absolute cursor-pointer"
                style={{
                  right: "5px",
                  top: "4px",
                  pointerEvents: "auto",
                  zIndex: "2",
                }}
                onClick={() => handleAddComment(item.id, attachment)}
              >
                <img src={SrSend} alt="Send Icon" />
              </div>
              <div className="upload-area">
                <div
                  className="sr-fp-attachment-icon"
                  ref={dropdownRef}
                >
                  <label
                    htmlFor="comment_attachment"
                    className="cursor-pointer m-0"
                    onClick={toggleDropdown}
                  >
                    <img src={attachment} alt="attachment-icon" />
                  </label>

                  {isDropdownOpen && (
                    <div className="sr-dropdown-menu">
                      {statusOptions.map((option) => (
                        <label
                          key={option.value}
                          className="sr-dropdown-item"
                          htmlFor={option.value}
                        >
                          <img src={option.icon} alt={`${option.label} Icon`} />
                          <span>{option.label}</span>
                          <input
                            id={option.value}
                            ref={attachmentInputRef}
                            type="file"
                            accept={option.accept}
                            style={{ display: "none" }}
                            onChange={handleAttachmentUpload}
                          />
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              </div>             

              <MentionsInput
                value={comment}
                placeholder="Write a comment."
                className="sr-comments-textarea"
                onChange={handleCommentChange}
              >
                <Mention className="comments-textarea__mention" />
              </MentionsInput>
              
            </div>
            <div className="attachment-preview-container mt-2">
                {newAttachment?.map((file, index) => {
                 return (
                  <div
                    className="attachment-preview"
                    // style={{
                    //   marginLeft: index === 1 || index === 2 ? "-10px" : "",
                    //   zIndex: newAttachment.length - index,
                    // }}
                    key={index}
                    onClick={() => openImagePreview(newAttachment)}
                  >
                    {file.type.includes("image") ? (
                      <img
                        src={file?.url ? file?.url : URL.createObjectURL(file)}
                        alt={`attachment-${index}`}
                        className="attachment-thumbnail"
                      />
                    ) : (
                      <div
                        className="file-icon-wrapper"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          backgroundColor: "#f4f4f4",
                          borderRadius: "5px",
                        }}
                      >
                        {getFileIcon(file)}
                      </div>
                    )}

                    <ClearIcon
                      className="clear-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeAttachment(index);
                      }}
                      sx={{
                        fontSize: "12px",
                        backgroundColor: "red",
                        color: "#fff"
                      }}
                    />
                  </div>
                )})}
                {/* {newAttachment?.length > 3 && (
                  <h6
                    style={{
                      position: "absolute",
                      top: "5px",
                      left:
                        newAttachment.length > 2
                          ? "75px"
                          : newAttachment.length > 1
                          ? "70px"
                          : "45px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`+${newAttachment.length - 3} More`}
                  </h6>
                )} */}
              </div>
          </div>
        </Col>
      </Row>
      {item?.service_request_comment?.length > 0 && (
        <div style={{ border: "1px solid #BFC2CB", borderRadius: "16px", padding: "15px" }}>
          {item?.service_request_comment.map((data, ind) => (
            <div
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              className="p-2 b-radius-8 d-flex flex-column gap-10"
              key={ind}
            >
              <SrCommentCard
                description={data?.description}
                createdByName={data?.created_by}
                created_at={data?.created_at}
                backgroundColor={data?.backgroundColor}
                attached_files={data?.attached_file}
                profile_pic={data?.profile_pic}
                attached_Image={data?.attached_image}
                setFileAttachments={setFileAttachments}
                setOpenComment={setOpenComment}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SrCommentComponents;
