import * as types from '../../actions/actionTypes';

export default function socialLoginSetup(state=[], action) {
  switch (action.type) {
    case types.GET_AUTH_LOGIN_URL_SUCCESS:
      return Object.assign({}, state, { list: {...action.data }});
    case types.GET_AUTH_LOGIN_URL_FAILURE:
      return Object.assign({}, state, { list: {...action.data }});
    case types.POST_SOCIAL_CODE_SUCCESS:
      return Object.assign({}, state, { list: {...action.data }, isLoggedIn: { connected: true }});
    case types.POST_SOCIAL_CODE_FAILURE:
      return Object.assign({}, state, { list: {...action.data }});
    case types.CHECK_LOGGED_IN_SUCCESS:
      return Object.assign({}, state, { isLoggedIn: {...action.data }});
    case types.CHECK_LOGGED_IN_FAILURE:
      return Object.assign({}, state, { isLoggedIn: {...action.data }});
    case types.LOG_OUT_SUCCESS:
      return Object.assign({}, state, { isLoggedIn: { connected: false }});
    case types.LOG_OUT_FAILURE:
      return Object.assign({}, state, { detail: {...action.data }});
    default:
      return state;
  }
}
