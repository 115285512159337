import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as commonAction from "../common/commonAction";
import * as URLS from "../../libs/apiUrls";

// Utility function for API calls
const apiCall = async (dispatch, url, params, successAction, failureAction, downloadFileName = null) => {
  dispatch(commonAction.showLoader());
  try {
    const response = await axios_instance.get(url, {
      params,
      responseType: params?.download ? "blob" : "json",
    });

    dispatch(commonAction.hideLoader());

    if (params?.download) {
      const blob = response.data;
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = downloadFileName || "download.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      dispatch(successAction(response.data));
    }

    return response;
  } catch (error) {
    dispatch(commonAction.hideLoader());
    const errorData = error?.response?.data || {};
    dispatch(failureAction({ errors: errorData }));
    throw error;
  }
};

const createSuccessAction = (type) => (data) => ({ type, data });
const createFailureAction = (type) => (error) => ({ type, data: { errors: error } });

const getListingPerformanceMetricsSuccess = createSuccessAction(types.GET_LISTING_PERFORMANCE_METRICS_SUCCESS);
const getListingPerformanceMetricsFailure = createFailureAction(types.GET_LISTING_PERFORMANCE_METRICS_FAILURE);

const getListingPerformanceOverallCountSuccess = createSuccessAction(types.GET_LISTING_OVERALL_COUNT_SUCCESS);
const getListingPerformanceOverallCountFailure = createFailureAction(types.GET_LISTING_OVERALL_COUNT_FAILURE);

const getListingPerformancePopularTimesSuccess = createSuccessAction(types.GET_LISTING_POPULAR_TIMES_SUCCESS);
const getListingPerformancePopularTimesFailure = createFailureAction(types.GET_LISTING_POPULAR_TIMES_FAILURE);

const getListingPerformanceSearchKeywordsSuccess = createSuccessAction(types.GET_LISTING_SEARCH_KEYWORD_SUCCESS);
const getListingPerformanceSearchKeywordsFailure = createFailureAction(types.GET_LISTING_SEARCH_KEYWORD_FAILURE);

export const getListingPerformanceMetrics = (business_id, params) => (dispatch) => {
  const url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/performance/metrics/`;
  const downloadFileName =
    params?.action_type === "discovery" ? "discovery.csv" : params?.action_type === "interactions" ? "interactions.csv" : "";
  return apiCall(dispatch, url, params, getListingPerformanceMetricsSuccess, getListingPerformanceMetricsFailure, downloadFileName);
};

export const getListingOverallCount = (business_id, params) => (dispatch) => {
  const url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/performance/overall-count/`;
  return apiCall(dispatch, url, params, getListingPerformanceOverallCountSuccess, getListingPerformanceOverallCountFailure);
};

export const getListingPopularTimes = (business_id, params) => (dispatch) => {
  const url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/popular-times/`;
  return apiCall(dispatch, url, params, getListingPerformancePopularTimesSuccess, getListingPerformancePopularTimesFailure, "popular_times.csv");
};

export const getListingSearchKeywords = (business_id, params) => (dispatch) => {
  const url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/search_keywords/`;
  return apiCall(dispatch, url, params, getListingPerformanceSearchKeywordsSuccess, getListingPerformanceSearchKeywordsFailure, "search_keywords.csv");
};
