import React from "react";
import { formatReviews } from "../../../libs/helper";
import { Box, Dialog, DialogActions, IconButton } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import AllGoogleUpdates from "./allgoogleupdates";

const BusinessSummary = (props) => {
  const [open, setOpen] = React.useState({
    all_google_updates: false,
  });

  const handleClickOpen = (dialog, scrollType) => {
    setOpen((prevState) => ({
      ...prevState,
      [dialog]: true,
    }));
    setScroll(scrollType);
  };

  const handleClose = (dialog) => {
    setOpen((prevState) => ({
      ...prevState,
      [dialog]: false,
    }));
  };

  const [scroll, setScroll] = React.useState("paper");

  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <>
      <div
        className="business-summary-grid"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(220px, 1fr))",
          gap: "24px",
          marginBottom: "24px",
          padding: "10px 0",
        }}
      >
        <div className="card-item special-card shadow-sm">
          <div className="card-content">
            <div className="card-heading">Rating</div>
            <h2 className="business-card-number">
              {props?.average_rating ? props?.average_rating?.toFixed(1) : "0"}
            </h2>
            <span className="business-card-review">
              {formatReviews(props?.total_reviews)} of reviews
            </span>
          </div>
        </div>
        <div className="card-item special-card shadow-sm">
          <div className="card-content">
            <div className="card-heading">Categories</div>
            <h2 className="business-card-number">
              {props?.average_category
                ? props?.average_category?.toFixed(1)
                : "0"}
            </h2>
            <span className="business-card-review">Average Categories</span>
          </div>
        </div>
        <div className="card-item special-card shadow-sm">
          <div className="card-content">
            <div className="card-heading">Photos</div>
            <div className="flex-container">
              <h2 className="business-card-number">
                {props?.photos?.total_photos}
              </h2>
              <div className="badge badge-added">
                {props?.photos?.added} added
              </div>
            </div>
            <span className="business-card-review">Total Photos</span>
            {/* <span className="business-card-review">Today</span> */}

          </div>
        </div>
        {props?.services && (
          <div className="card-item special-card shadow-sm">
            <div className="card-content">
              <div className="card-heading">Services</div>
              <div className="flex-container">
                <h2 className="business-card-number">
                  {props?.services?.total_services}
                </h2>
                <div className="badge badge-new">
                  {props?.services?.new} New
                </div>
              </div>
              <span className="business-card-review">Lifetime</span>
            </div>
          </div>
        )}
        {/* <div className="card-item special-card shadow-sm">
          <div className="card-content">
            <div className="card-heading">Q & A's</div>
            <div className="flex-container">
              <h2 className="business-card-number">{props?.questions?.total_questions}</h2>
              <div className="badge badge-new">{props?.questions?.new} New</div>
            </div>
            <span className="business-card-review">Lifetime</span>
          </div>
        </div> */}

        <div
          className="card-item special-card shadow-sm"
          style={{ cursor: "pointer" }}
          onClick={() => handleClickOpen("all_google_updates", "paper")}
        >
          <div className="card-content">
            <div className="card-heading">Google Updates</div>
            <div className="flex-container">
              <h2 className="business-card-number">
                {props?.google_updates?.total_updates}
              </h2>
              <div className="badge badge-added">
                {props?.google_updates?.rejected} Rejected
              </div>
            </div>
            <span className="business-card-review">&nbsp;</span>
            {/* <span className="business-card-review">Last week</span> */}
          </div>
        </div>
      </div>

      <Dialog
        open={open.all_google_updates}
        onClose={() => handleClose("all_google_updates")}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <Box sx={style}>
          <div style={{ padding: "25px" }}>
            <div
              className="d-flex mt-10 mb-15"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "24px",
                color: "#1A2544",
              }}
            >
              <p>All Google Updates</p>
              <DialogActions>
                <IconButton onClick={() => handleClose("all_google_updates")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
            </div>
            <AllGoogleUpdates business_id={props?.business_id} />
          </div>
        </Box>
      </Dialog>
    </>
  );
};

export default BusinessSummary;
