import React from 'react';
import './fpBadge.css';
import classNames from 'classnames';

const FPBadge = (props) => {
  const { className, label, inside = false, textClass, size = 15, width , color ,height, onClick} = props;
  return (
    <span className={classNames('fp-badge-wrapper', className)} onClick={onClick}>
      <span className='fp-badge' style={{width: width || `${size}px`, height: height || `${size}px`, backgroundColor: color}}>
        {label && inside && <label className={`fp-badge-label mb-0 ${textClass && textClass}`}>{label}</label>}
      </span>
      {label && !inside && <label className={`fp-badge-label mb-0 ${textClass && textClass}`}>{label}</label>}
    </span>
  );
};

export default FPBadge;
