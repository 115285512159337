import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as RegisterActions from '../../actions/accounts/registerActions';


class RegisterConfirmPage extends React.Component {

  constructor(props){
    super(props);
  }

  componentDidMount(){
    const { router: { route: { match: { params } } } } = this.context;
    this.props.actions.registerConfirm(params);
  }

  render(){

    const { isRegisterConfirm } = this.props;
    return (
      <Row className="align-items-center justify-content-center login-bg full-height" >
        <Col xs={12} sm={6}>
          <Row className="justify-content-center">
            <Col xs={10} sm={10}>
              <Card body outline>
                <div className="mx-5 py-3">
                  <h2 className="text-center mt-4 heading-text">
                    { isRegisterConfirm ? 'Registration confirmation!' : 'Registration faliure!' }
                  </h2>
                  <h4 className="text-center mt-4 fw3">
                    { isRegisterConfirm ? 'You have successfully confirmed your registration.'
                      : 'You have not confirmed your registration. Please try again.'}
                  </h4>
                  <div className="text-center mt-4">
                    <p>Go back to <a href="/login">Login</a></p>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    isRegisterConfirm: state.register.isRegisterConfirm,
  };
};

RegisterConfirmPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};

RegisterConfirmPage.contextTypes = {
  router: PropTypes.object.isRequired,
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(RegisterActions, dispatch)
  };
}


export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(RegisterConfirmPage);
