import React from 'react';
import {
  FormControlLabel,
  Radio,
  makeStyles,
  withStyles,
} from '@material-ui/core';

const DefaultRadio = withStyles({
  root: {
    // color: '#1B1C1D',
    color:'#BFC2CB',
    '&$checked': {
      color: '#F13A88',
    },
  },
  disabled:{
    color: 'rgba(0, 0, 0, 0.26) !important',
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles({
  fromControl: {
    '&.MuiFormControlLabel-root': {
      margin: '0',
    },

    '&.MuiFormControlLabel-root .MuiButtonBase-root': {
      padding: '0',
      paddingRight: '4px',
    },

    '&.MuiFormControlLabel-root .MuiTypography-root': {
      color: 'var(--fp-secondary-font-dark)',
    },

    '&.MuiFormControlLabel-root .MuiTypography-root.Mui-disabled' :{
      color: 'rgba(0, 0, 0, 0.26) !important',
    }
  },
});

const FPRadio = ({ onChange, label, labelClassName, value, ...rest }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={`${classes.fromControl} ${
        labelClassName ? labelClassName : ''
      }`}
      value={value}
      control={<DefaultRadio {...rest} />}
      label={label}
      onChange={onChange}
    />
  );
};

export default FPRadio;
