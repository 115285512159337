import React, { useEffect, useState } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { ListDescription } from "semantic-ui-react";
import FPInput from "../../common/new/forms/ui/FPInput";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import "./listing.css";
import { Checkbox } from "semantic-ui-react";
import google_svg from "../../../images/googlephoto.svg";
import duplicate_svg from "../../../images/dusplicate.svg";
import { formatReviews } from "../../../libs/helper";
import validator from "validator";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import * as listingUtils from "../../../libs/listingToast";

const UpdateWebsitelink = ({ primaryDetail, business_id, selectReso, actions, attribute_data }) => {
  const [checked, setChecked] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState(primaryDetail?.websiteUri || "");
  const [orderUrl, setOrderUrl] = useState("");
  const [orderUrl2, setOrderUrl2] = useState("");
  const [isOrderSameAsWebsite, setIsOrderSameAsWebsite] = useState(false);
  const [menuUrl, setMenuUrl] = useState("");
  const [menuUrl2, setMenuUrl2] = useState("");
  const [isMenuSameAsWebsite, setIsMenuSameAsWebsite] = useState(false);
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");

  useEffect(() => {
    if (!attribute_data) {
      console.error("attribute_data is undefined or null");
      return;
    }

    const updatedAttributeData = attribute_data ? JSON.parse(JSON.stringify(attribute_data)) : [];

    Object.keys(updatedAttributeData)?.forEach((attribute) => {
      if (attribute === "Place page URLs") {
        updatedAttributeData[attribute]?.forEach((item) => {
          if (item.displayName === "Menu link") {
            if (item?.selected === false) {
              setMenuUrl("");
              setMenuUrl2("");
            } else {
              item?.selected_data?.uriValues?.map((menu_link) => {
                setMenuUrl(menu_link?.uri);
                setMenuUrl2(menu_link?.uri);
              });
            }
          }
          if (item.displayName === "Order ahead links") {
            if (item?.selected === false) {
              setOrderUrl("");
              setOrderUrl2("");
            } else {
              item?.selected_data?.uriValues?.map((order_link) => {
                setOrderUrl(order_link?.uri);
                setOrderUrl2(order_link?.uri);
              });
            }
          }
        });
      }
    });
  }, [attribute_data]);

  const addressString = [primaryDetail?.addressLines, primaryDetail?.locality, primaryDetail?.postalCode].join(", ");

  const [errorMessage, setErrorMessage] = useState({
    websiteUrl: "",
    orderUrl: "",
    menuUrl: "",
  });

  useEffect(() => {
    actions.getGmbListingAttribute(business_id, selectReso);
  }, [actions, business_id, selectReso]);

  // const [params, setParams] = useState({ source: "", medium: "" });
  const handleSwitch = (event, value) => {
    event.preventDefault();
    setChecked(!value);
  };

  useEffect(() => {
    if (!checked) {
      setUtmSource("");
      setUtmMedium("");

      if (websiteUrl) {
        try {
          let url = new URL(websiteUrl);
          url.search = "";

          setWebsiteUrl(url.toString());
        } catch (error) {
          console.error("Invalid URL:", websiteUrl);
        }
      }
    }
  }, [checked, websiteUrl]);

  const handleWebsiteUrlChange = (event) => {
    const newWebsiteUrl = event.target.value;

    setWebsiteUrl(newWebsiteUrl);

    if (newWebsiteUrl && !validator.isURL(newWebsiteUrl)) {
      setErrorMessage({
        ...errorMessage,
        websiteUrl: "Please enter a valid website URL",
      });
      return;
    }
    setErrorMessage({ ...errorMessage, websiteUrl: "" });
    if (isOrderSameAsWebsite) {
      setOrderUrl(newWebsiteUrl);
    }
    if (isMenuSameAsWebsite) {
      setMenuUrl(newWebsiteUrl);
    }
  };
  const handleMenuUrlChange = (event) => {
    const newMenuUrl = event.target.value;
    setMenuUrl(newMenuUrl);
    if (newMenuUrl && !validator.isURL(newMenuUrl)) {
      setErrorMessage({
        ...errorMessage,
        menuUrl: "Please enter a valid menu URL",
      });
      return;
    }
    setErrorMessage({ ...errorMessage, menuUrl: "" });
  };

  const handleOrderAheadUrlChange = (event) => {
    const newOrderUrl = event.target.value;
    setOrderUrl(newOrderUrl);
    if (newOrderUrl && !validator.isURL(newOrderUrl)) {
      setErrorMessage({
        ...errorMessage,
        orderUrl: "Please enter a valid order URL",
      });
      return;
    }
    setErrorMessage({ ...errorMessage, orderUrl: "" });
  };

  const handleOrderCheckboxChange = (event) => {
    const { checked } = event.target;
    setIsOrderSameAsWebsite(checked);
    if (checked) {
      setOrderUrl(websiteUrl);
    } else {
      setOrderUrl(orderUrl2);
    }
  };

  const handleMenuCheckboxChange = (event) => {
    const { checked } = event.target;
    setIsMenuSameAsWebsite(checked);
    if (checked) {
      setMenuUrl(websiteUrl);
    } else {
      setMenuUrl(menuUrl2);
    }
  };

  const handleUtmSourceChange = (event) => {
    setUtmSource(event.target.value);
    updateWebsiteUrlWithUtm(websiteUrl, event.target.value, utmMedium);
  };

  const handleUtmMediumChange = (event) => {
    setUtmMedium(event.target.value);
    updateWebsiteUrlWithUtm(websiteUrl, utmSource, event.target.value);
  };

  // const updateWebsiteUrlWithUtm = (awebsiteUrl, source, medium) => {
  //   let url = awebsiteUrl;
  //   // if (source) {
  //   url += "?";
  //   // if (source || medium) {
  //   url += `${source}=${medium}`;
  //   // }
  //   // if (source && medium) url += "&";
  //   // if (medium) {
  //   //   url += ``;
  //   // }
  //   // }
  //   setWebsiteUrl(url);
  //   // if (isOrderSameAsWebsite) setOrderUrl(url);
  //   // if (isMenuSameAsWebsite) setMenuUrl(url);
  // };

  const updateWebsiteUrlWithUtm = (awebsiteUrl, source, medium) => {
    let url = new URL(awebsiteUrl);

    // Remove all existing query parameters
    url.search = "";

    // Append the source and medium as query parameters in the desired format
    if (source || medium) {
      url.search = `${source}=${medium}`;
    }

    setWebsiteUrl(url.toString());
  };

  const handleParamChange = (e, type) => {
    const { value } = e.target;
    if (type === "source") {
      setUtmSource(value);
    } else if (type === "medium") {
      setUtmMedium(value);
    }
  };

  // useEffect(() => {
  //   // how to add searchParams to URL
  //   const url = new URL(websiteUrl);
  //   if (utmSource && utmMedium) {
  //     url.searchParams.set(utmSource, utmMedium);
  //   } else if (utmSource && !utmMedium) {
  //     url.searchParams.delete(utmSource);
  //   }
  //   setWebsiteUrl(url.toString());
  // }, [websiteUrl, utmSource, utmMedium]);

  const handleWebsiteLinkUpdate = async () => {
    const restodetail = { business_id, selectReso };
    let url = websiteUrl;
    let menuurl = menuUrl;
    let orderurl = orderUrl;
    if (url && utmSource && utmMedium) {
      url = new URL(websiteUrl);
      url.searchParams.set(utmSource, utmMedium);
      url = url.toString();
    }
    if (menuurl && utmSource && utmMedium) {
      menuurl = new URL(menuUrl);
      menuurl.searchParams.set(utmSource, utmMedium);
      menuurl = menuurl.toString();
    }
    if (orderurl && utmSource && utmMedium) {
      orderurl = new URL(orderUrl);
      orderurl.searchParams.set(utmSource, utmMedium);
      orderurl = orderurl.toString();
    }
    const attributes = [];

    // Add the attributes conditionally based on their values
    if (menuurl) {
      attributes.push({
        valueType: "URL",
        name: "attributes/url_menu",
        uriValues: [{ uri: menuurl }],
      });
    }

    if (orderurl) {
      attributes.push({
        valueType: "URL",
        name: "attributes/url_order_ahead",
        uriValues: [{ uri: orderurl }],
      });
    }

    const updatedCode = {
      type: "updateAttributes",
      ...(url && { website: url }),
      ...(attributes?.length > 0 && { attributes: attributes }), // Only send if there are valid attributes
    };
    // const updatedCode = {
    //   type: "updateAttributes",
    //   website: url,
    //   attributes: [
    //     {
    //       valueType: "URL",
    //       name: "attributes/url_menu",
    //       uriValues: [{ uri: menuurl }],
    //     },
    //     {
    //       valueType: "URL",
    //       name: "attributes/url_order_ahead",
    //       uriValues: [{ uri: orderurl }],
    //     },
    //   ],
    // };
    await actions.getGmbListingUpdateListing(updatedCode, restodetail);
    listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
    await actions.getGmbListingRefreshListing(business_id, selectReso);

    listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    listingUtils?.displayMessage("positive", "Listing updated successfully.");
    await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };

  return (
    <>
      <div className="listing-container" style={{ overflow: "hidden", gap: "15px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <div className="listing-header">
            <div className="listing-info">
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="listing-name">{primaryDetail?.title}</div>
                <img
                  src={
                    primaryDetail?.is_varified
                      ? verified_svg
                      : primaryDetail?.is_suspend
                      ? duplicate_svg
                      : primaryDetail?.is_duplicate
                      ? duplicate_svg
                      : ""
                  }
                  alt="verifiedImage"
                />
              </div>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(1)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{formatReviews(primaryDetail?.totalReviewCount)} Reviews</span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>{addressString}</ListDescription>
          </div>
        </div>
      </div>
      <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "calc(100% - 120px)",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <div className="listing-name">Complete your Business Information & Sync</div>
              <div style={{ display: "grid", gap: "10px" }}>
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#1B1C1D",
                  }}
                >
                  Website URL{" "}
                </div>
                <FPInput
                  type="text"
                  placeholder={"Enter valid website URL"}
                  value={websiteUrl}
                  onChange={handleWebsiteUrlChange}
                />
                <span style={{ color: "red" }}>{errorMessage.websiteUrl}</span>
              </div>
              <div style={{ display: "grid", gap: "12px" }}>
                <div style={{ display: "grid", gap: "4px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Menu Link{" "}
                  </div>
                  <FPInput
                    type="text"
                    placeholder={"Enter menu link URL"}
                    value={menuUrl}
                    onChange={handleMenuUrlChange}
                    disabled={isMenuSameAsWebsite}
                    error={errorMessage.menuUrl}
                    helperText={errorMessage.menuUrl}
                  />
                  <span style={{ color: "red" }}>{errorMessage.menuUrl}</span>
                </div>
                <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    id="menu_link"
                    checked={isMenuSameAsWebsite}
                    onChange={handleMenuCheckboxChange}
                  />
                  <label
                    htmlFor="menu_link"
                    style={{
                      cursor: "pointer",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#7A8193",
                      marginBottom: 0,
                    }}
                  >
                    Same as website URL
                  </label>
                </div>
              </div>
              <div style={{ display: "grid", gap: "12px" }}>
                <div style={{ display: "grid", gap: "4px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Order ahead links{" "}
                  </div>
                  <FPInput
                    type="text"
                    placeholder={"Enter Order ahead links URL"}
                    value={orderUrl}
                    onChange={handleOrderAheadUrlChange}
                    disabled={isOrderSameAsWebsite}
                    error={errorMessage.orderUrl}
                  />
                  <span style={{ color: "red" }}>{errorMessage.orderUrl}</span>
                </div>
                <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    id="order_link"
                    checked={isOrderSameAsWebsite}
                    onChange={handleOrderCheckboxChange}
                  />
                  <label
                    htmlFor="order_link"
                    style={{
                      cursor: "pointer",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#7A8193",
                      marginBottom: 0,
                    }}
                  >
                    Same as website URL
                  </label>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="listing-name">Add UTM source & Medium</div>
                <Checkbox
                  toggle
                  checked={checked}
                  className="toggle-btn"
                  onChange={(event) => handleSwitch(event, checked)}
                />
              </div>
              <div className="row">
                <div className="col-6" style={{ display: "grid", gap: "4px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    UTM Source{" "}
                  </div>
                  <FPInput
                    disabled={!checked}
                    name="source"
                    type="text"
                    placeholder={"Add UTM source eg. Google"}
                    value={utmSource}
                    onChange={(e) => handleUtmSourceChange(e, "source")}
                  />
                </div>
                <div className="col-6" style={{ display: "grid", gap: "4px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    UTM Medium{" "}
                  </div>
                  <FPInput
                    disabled={!checked}
                    type="text"
                    placeholder={"Add UTM medium eg. GMB"}
                    value={utmMedium}
                    name="medium"
                    onChange={(e) => handleUtmMediumChange(e, "medium")}
                  />
                </div>
              </div>
              {/* </div> */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "10px",
              }}
            >
              <FPButton
                label="Update URL's"
                size="small"
                disabled={primaryDetail?.is_suspend || (!menuUrl && !websiteUrl && !orderUrl)}
                onClick={handleWebsiteLinkUpdate}
              />
            </div>
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

// export default UpdateWebsitelink;
const mapStateToProps = (state) => {
  return {
    attribute_data: state.gmbListingLocation.attribute_data,
    attribute_error: state.gmbListingLocation.attribute_error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateWebsitelink);
