import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import LoaderComponent from "../../common/loaderComponent";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import ListingFPInput from "../../common/new/Listing/ListingFPInput";
import AddIcon from "@material-ui/icons/Add";
import emoji_svg from "../../../images/emoji.svg";
import * as URLS from "../../../libs/apiUrls";

const UpdateQuestionList = ({
  questionAnswerData,
  primaryDetail,
  actions,
  selectReso,
  business_id,
  loader,
  setHideBusinessScreen,
  setQuestionAnsewrList,
}) => {
  // const  = props

  const handleHideBusinessScreen = (question) => {
    setHideBusinessScreen("update_question");
    setQuestionAnsewrList(question);
  };

  useEffect(() => {
    actions.getGmbLocationQusetionAnswer(business_id, selectReso);
  }, [business_id, selectReso]);
  return (
    <>
      <LoaderComponent loader={loader} />
      {/* <div className="listing-container" style={{ display: "flex", flexDirection: "column", gap: "20px", paddingBottom: "10px" }}>
                <div style={{ overflowY: "scroll", overflowX: "hidden", height: "400px", display: "grid", gap: "32px", paddingBottom: "5px" }}>
                    <div
                        className="d-flex "
                        style={{ display: "flex", justifyContent: "space-between" }}
                    >
                        <FPButton
                            label="Add Question"
                            size="small"
                            endIcon={<AddIcon />}
                        />
                        <FPButton
                            label="AI suggested Q&A's"
                            size="small"
                            startIcon={<img src={emoji_svg} alt="emojiSvg" />}
                            star
                        />
                    </div> */}

      {questionAnswerData?.results?.length > 0
        ? questionAnswerData?.results?.map((question) => {
            console.log(
              "images>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
              "https:" + question?.author?.profilePhotoUri
            );
            return (
              <>
                <div
                  style={{ display: "flex", cursor: "pointer" }}
                  onClick={() => handleHideBusinessScreen(question)}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      border: "1px solid #6e6d6d",
                      width: "100%",
                      borderRadius: "20px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <img
                              style={{ height: "auto", width: "25px " }}
                              src={question?.author?.profilePhotoUri}
                              alt=""
                              // src={`${URLS.GMB_LISTING_LOCATION}/proxy-image?url=https:${question?.author?.profilePhotoUri}`}
                            />
                            <span
                              style={{
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "#1B1C1D",
                              }}
                            >
                              {question?.author?.displayName}
                            </span>
                          </div>
                          <div
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              color: "#1B1C1D",
                            }}
                          >
                            Question
                          </div>
                        </div>
                      </div>
                      {/* <ListingFPInput
                                            type="text"
                                            value={question?.question}
                                            placeholder={
                                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
                                            }
                                        /> */}
                      <h2
                        style={{
                          color: "#000000d9",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginBottom: "2px",
                          paddingInline: "30px",
                        }}
                      >
                        {question?.question?.length > 100
                          ? `${question?.question?.slice(0, 100)}...`
                          : question?.question}
                      </h2>
                    </div>

                    <div
                      style={{
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <div style={{ paddingTop: "3px" }}></div>
                    </div>
                    {/* </div> */}
                  </div>
                  {/* <div>
                                    <FPButton label="Edit" onClick={() => handleHideBusinessScreen(question)} />
                                </div> */}
                </div>
              </>
            );
          })
        : ""}

      {/* </div>
            </div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    questionAnswerData: state.gmbListingLocation.questionAnswerData,
    questionAnswerError: state.gmbListingLocation.questionAnswerError,
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateQuestionList);
