import React from "react";
import Wrapper from "./Wrapper";
import SentimentTable from "./SentimentTable";
import SentimentGraph from "./SentimentGraph";

const Sentiment = ({ ratingDetails,loader, location, history, match ,businessDetails}) => {
  return (
    <Wrapper
      title={"Sentiment Rating Count"}
      subHeading={`* Above details is as on ${ratingDetails.fetch_date}`}
      location={location}
      history={history}
      match={match}
    >
      {(viewType) => (
        <>
          {viewType === "Graph" ? (
            <SentimentGraph
              ratingDetails={ratingDetails}
              loader={loader}
              businessDetails={businessDetails}
            />
          ) : (
              <SentimentTable
                ratingDetails={
                  ratingDetails &&
                  ratingDetails.data &&
                  ratingDetails.data.star_ratings
                }
                loader={loader}
                businessDetails={businessDetails}
              />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Sentiment;
