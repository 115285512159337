import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import LoaderComponent from "../../components/common/loaderComponent";
import MessageTypeComponent from "../../components/messenger/MessageTypeComponent";
import moment from "moment";
import ChatFileShow from "../../components/messenger/Common/ChatFileShow";
// import { Icon } from "semantic-ui-react";
import { saveAs } from "file-saver";
import { CiImageOn } from "react-icons/ci";
import { MdOutlineGifBox } from "react-icons/md";
import { FaVideo } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { AiTwotoneAudio } from "react-icons/ai";
import { Box } from "@material-ui/core";

const ChatBoxComponent = (props) => {
  const { messages, showLoader, hideMsgLoader } = props;
  const [showUserFileModal, setUserFileModal] = useState(false);
  const [selectAttchFile, setSelectAttchFile] = useState({});

  const currentDate = moment().format("DD/MM/YYYY");

  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".chatBox")?.scrollTo({
        top: document.querySelector(".chatBox")?.scrollHeight + 100,
        behavior: "smooth",
      });
    }, 1000);
  });
  useEffect(() => {
    hideMsgLoader();
  }, []); //eslint-disable-line

  function showUserFileModalFn(chat, event) {
    if (
      chat.msg_type === "video" ||
      chat.msg_type === "file" ||
      chat.msg_type === "image" ||
      chat.is_story_reply
    ) {
      setSelectAttchFile(chat);
      setUserFileModal(true);
    } else {
      setUserFileModal(false);
    }
  }

  function attchmentDownload(data) {
    let url = "";
    let filename = "";

    if (data.attached_file !== null) {
      url = data.attached_file;
      if (data.msg_type === "video") {
        filename = data.attached_file.split("messenger_attachement/")[1];
      } else if (data.msg_type === "image") {
        filename = data.attached_file.split("messenger_attachement/")[1];
      } else if (data.msg_type === "audio") {
        filename = data.attached_file.split("messenger_attachement/")[1];
      } else if (data.msg_type === "file") {
        filename = data.attached_file.split("messenger_attachement/")[1];
      }
    } else {
      url = data.file_url;
      if (data.msg_type === "video") {
        // filename = data.file_url.split(".mp4/")[1].split("?")[0];
        filename = "video";
      } else if (data.msg_type === "image") {
        filename = "image.png";
      } else if (data.msg_type === "audio") {
        filename = "audio.mp3";
      } else if (data.msg_type === "file") {
        filename = "file";
        // filename = data.attached_file.split("messenger_attachement/")[1];
        if (data.file_url.includes(".pdf/")) {
          // filename = data.file_url.split(".pdf/")[1].split("?")[0];
          filename = "pdf";
        } else if (data.file_url.includes(".docx/")) {
          // filename = data.file_url.split(".docx/")[1].split("?")[0];
          filename = "document";
        }
      }
    }
    if (url !== "") {
      saveAs(url, filename);
    }
  }

  function closeUserFileModal() {
    setUserFileModal(false);
  }

  return (
    <div className="chatbox-contentt">
      {messages?.length > 0 ? (
        <Row className="chatBox">
          <LoaderComponent
            loader={showLoader}
            inline={true}
            borderRadius={20}
          />
          {messages &&
            messages.map((msg) => {
              return (
                <Col sm="12" key={msg.date}>
                  {msg.date && (
                    <Row className="mb-3 mt-3 date-wise-chat">
                      <Col sm="12" key={msg.date}>
                        <p className="chat-date">
                          <span>{moment(msg.date).format("MMM DD,YYYY")}</span>
                        </p>
                      </Col>
                    </Row>
                  )}
                  <Box sx={{color:"#7A8193", textAlign:"center", fontSize:"14px", marginTop: "-11px"}}>
                  <p>marked this conversations as "Unassigned" | 05:10 PM</p>
                  </Box>
                  <Row className={"gap-20"}>
                    {msg &&
                      msg.data.map((data) => {
                        if (data.message_direction === "incoming") {
                          return (
                            <Col sm="12" key={data.id}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "left",
                                  gap: "10px",
                                }}
                                onClick={(e) => showUserFileModalFn(data, e)}
                              >
                                {data.msg_type === "video" ? (
                                  <FaVideo
                                    style={{ fontSize: "30px" }}
                                    onClick={() => attchmentDownload(data)}
                                  />
                                ) : data.msg_type === "image" ||
                                  data.msg_type === "photo" ? (
                                  <CiImageOn
                                    style={{ fontSize: "34px" }}
                                    onClick={() => attchmentDownload(data)}
                                  />
                                ) : data.msg_type === "file" ? (
                                  <FaFileAlt
                                    style={{ fontSize: "34px" }}
                                    onClick={() => attchmentDownload(data)}
                                  />
                                ) : data.msg_type === "animated_gif" ? (
                                  <MdOutlineGifBox
                                    style={{ fontSize: "34px" }}
                                    onClick={() => attchmentDownload(data)}
                                  />
                                ) : data.msg_type === "audio" ? (
                                  <AiTwotoneAudio
                                    style={{ fontSize: "34px" }}
                                    onClick={() => attchmentDownload(data)}
                                  />
                                ) : // <div className="de-messages-file-download">
                                //   <Icon style={{color: 'blue'}} name="cloud download" size="large" onClick={() => attchmentDownload(data)} />
                                //  </div>
                                null}
                                <MessageTypeComponent
                                  messageData={data}
                                  isSelfBox={false}
                                  messageBoxProps={{
                                    wrapperClassName: 'w-100'
                                  }}
                                />
                              </div>
                              {
                                (
                                  data.msg_type === "audio" 
                                  || data.msg_type === "animated_gif" 
                                  || data.msg_type === "file"  
                                  || data.msg_type === "image" 
                                  ||  data.msg_type === "video" 
                                  || data.msg_type === "photo" 
                                )
                                ? <span>{data.message_text}</span> : ''
                              }
                              <div className="receiveMsgDateBox">
                                <p>
                                  {currentDate ===
                                  moment(data.message_time).format("DD/MM/YYYY")
                                    ? moment(data.message_time).format(
                                        "HH:mm a"
                                      )
                                    : moment(data.message_time).format(
                                        "DD-MM-YYYY"
                                      )}
                                </p>
                              </div>
                            </Col>
                          );
                        } else {
                          return (
                            <Col sm="12" key={data.id} className={"d-flex"}>
                              <Col sm="2"></Col>
                              <Col
                                sm="10"
                                onClick={(e) => showUserFileModalFn(data, e)}
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "right",
                                    gap: "10px",
                                  }}
                                >
                                  <MessageTypeComponent
                                    messageData={data}
                                    isSelfBox={true}
                                    messageBoxProps={{
                                      wrapperClassName: 'w-100'
                                    }}
                                  />

                                  {data.msg_type === "video" ? (
                                    <FaVideo
                                      style={{ fontSize: "30px" }}
                                      onClick={() => attchmentDownload(data)}
                                    />
                                  ) : data.msg_type === "image" ||
                                    data.msg_type === "photo" ? (
                                    <CiImageOn
                                      style={{ fontSize: "34px" }}
                                      onClick={() => attchmentDownload(data)}
                                    />
                                  ) : data.msg_type === "file" ? (
                                    <FaFileAlt
                                      style={{ fontSize: "34px" }}
                                      onClick={() => attchmentDownload(data)}
                                    />
                                  ) : data.msg_type === "animated_gif" ? (
                                    <MdOutlineGifBox
                                      style={{ fontSize: "34px" }}
                                      onClick={() => attchmentDownload(data)}
                                    />
                                  ) : data.msg_type === "audio" ? (
                                    <AiTwotoneAudio
                                      style={{ fontSize: "34px" }}
                                      onClick={() => attchmentDownload(data)}
                                    />
                                  ) : // <div className="messages-file-download">
                                  //   <Icon
                                  //     name="cloud download"
                                  //     size="large"
                                  //     style={{color: 'blue'}}
                                  //     onClick={() => attchmentDownload(data)}
                                  //   />
                                  // </div>
                                  null}
                                </div>
                                <div className="sentMsgDateBox">
                                  <p className="text-right">
                                    {currentDate ===
                                    moment(data.message_time).format(
                                      "DD/MM/YYYY"
                                    )
                                      ? moment(data.message_time).format(
                                          "HH:mm a"
                                        )
                                      : moment(data.message_time).format(
                                          "DD-MM-YYYY"
                                        )}
                                  </p>
                                </div>
                              </Col>
                            </Col>
                          );
                        }
                      })}
                  </Row>
                </Col>
              );
            })}
        </Row>
      ) : (
        <Row className="chatBox">
          <LoaderComponent
            loader={showLoader}
            inline={true}
            borderRadius={20}
          />
        </Row>
      )}
      <ChatFileShow
        showModal={showUserFileModal}
        closeModal={closeUserFileModal}
        selectAttchFile={selectAttchFile}
      />
    </div>
  );
};

ChatBoxComponent.propTypes = {
  messages: PropTypes.array.isRequired,
  showLoader: PropTypes.bool.isRequired,
  hideMsgLoader: PropTypes.func.isRequired,
};

export default ChatBoxComponent;
