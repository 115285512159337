import React, { useEffect, useState } from "react";
import GeoGridFilter from "../../components/listing/GeoGridRanker/GeoGridFilter";
import * as filterListActions from "../../actions/common/filterListActions";
import * as listingGeoGridRankerAction from "../../actions/listing/GeoGridRankerAction";
// import * as filterListActions from "../../../../actions/common/filterListActions";
import * as URLS from "../../libs/apiUrls";
import RootHeader from "../../modules/listing/GeoGridRanker/RootHeader";
import "./GeoGridRanker.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { PiWarningCircle } from "react-icons/pi";
import GeoGridScan from "../../components/listing/GeoGridRanker/GeoGridScan";
import LoaderComponent from "../../components/common/loaderComponent";
import ggogle_imae from "../../images/google.png";
import ScanHistory from "../../components/listing/GeoGridRanker/ScanHistory";

const GeoGridRanker = (props) => {
  const {
    actions,
    location: { search, pathname },
    location,
    loader,
    history,
    match,
    match: {
      params: { business_id },
    },
    filterAction,
    filter,
  } = props;

  const queryParams = new URLSearchParams(search);
  const location_id = queryParams.get("branch");
  const page = queryParams.get("page");
  const page_size = queryParams.get("page_size");
  const branch_tag = queryParams.get("branch_tag");
  const city = queryParams.get("city");
  const state = queryParams.get("state");
  const date_type = queryParams.get("date_type");

  // if (
  //   pathname === `${business_id}/geo-grid-ranker` ||
  //   `${business_id}/geo-grid-scan`
  // ) {
  //   console.log("Geo Grid Scan");
  // } else if (pathname === `${business_id}/scan-history`) {
  //   console.log("Scan History");
  // }

  const locationId = location_id
    ? location_id
    : filter?.branch?.choices[1]?.value;

  useEffect(() => {
    if (locationId) {
      history.push(pathname + `?branch=${locationId}`);
    }
  }, [location_id, history, business_id, locationId, pathname]);

  useEffect(() => {
    if (location_id || filter?.branch?.choices[1]?.value) {
      props.actions.getGmbLocationTopKeyword(
        location_id
          ? location_id
          : filter?.branch?.choices[1]?.value
          ? filter?.branch?.choices[1]?.value
          : 0
      );
    }
  }, [filter?.branch?.choices, location_id, props.actions, props.location_id]);

  // const fetchFilterList = async () => {
  //   try {
  //     await Promise.all([
  //       filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
  //       filterAction
  //         .getBranch("", `${URLS.BUSINESS_SETUP}${business_id}/branch/?is_gmb_locations=true`)
  //         .then((data) => {
  //           console.log("datatatatattaattatatatata", data);
  //         })
  //         .error((err) => {
  //           console.log("errrrrrrrrrrrrrrrr", err);
  //         }),
  //     ]);
  //   } catch (error) {
  //     console.error("Error in on fetch filter list:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchFilterList();
  // }, []);

  useEffect(() => {
    document.title = "Geo Grid Ranker";
    props.actionsSelectTab.setSelectedTab(2);
  }, [props.actionsSelectTab]);

  return (
    <>
      <LoaderComponent loader={loader} />
      <div style={{ display: "grid", gap: "15px", paddingBottom: "40px" }}>
        <GeoGridFilter history={history} location={location} match={match} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <RootHeader
            defaultValue={
              pathname === `/${business_id}/geo-grid-ranker`
                ? 0
                : pathname === `/${business_id}/scan-history`
                ? 1
                : 0
            }
            history={history}
          />
          {/* <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <span>Walkthrough</span>
            <span>See Demo</span>
          </div> */}
        </div>
        <div
          className="current-listing-oprn-status"
          style={{
            border: `1px solid ${
              props?.top_keyword_data?.listing_status === "OPEN"
                ? "#57e32c"
                : props?.top_keyword_data?.listing_status ===
                  "CLOSED_TEMPORARILY"
                ? "#ff4545"
                : "#ff4545"
            }`,
          }}
        >
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <span
              style={{ color: "#4bc54b", fontSize: "14px", fontWeight: "500" }}
            >
              Current Listing Open Status:
            </span>
            <button
              style={{
                backgroundColor:
                  props?.top_keyword_data?.listing_status === "OPEN"
                    ? "#57e32c"
                    : props?.top_keyword_data?.listing_status ===
                      "CLOSED_TEMPORARILY"
                    ? "#ff4545"
                    : "#ff4545",
                color: "white",
                borderRadius: "5px",
                border: "0px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {props?.top_keyword_data?.listing_status === "OPEN"
                ? "Open"
                : props?.top_keyword_data?.listing_status ===
                  "CLOSED_TEMPORARILY"
                ? "Closed Temporarily"
                : "Closed Permanently"}
            </button>
          </div>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <PiWarningCircle size={15} color="#4bc54b" />
            <span
              style={{ color: "#4bc54b", fontSize: "14px", fontWeight: "400" }}
              // style={{ color:props?.top_keyword_data?.listing_status === "OPEN" ?  "#4bc54b" : "#c54b4b", fontSize: "14px", fontWeight: "400" }}
            >
              Ranking varies based on Listing Opening Hours
            </span>
          </div>
        </div>
        <div style={{ display: "grid", gap: "15px" }}>
          <div>
            {pathname === `/${business_id}/scan-history` ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#060658",
                    }}
                  >
                    Scan History
                  </h2>
                </div>
                <ScanHistory
                  history={history}
                  business_id={business_id}
                  page={page}
                  page_size={page_size}
                  location={location}
                  location_id={
                    location_id
                      ? location_id
                      : filter?.branch?.choices[1]?.value
                      ? filter?.branch?.choices[1]?.value
                      : 0
                  }
                  branch_tag={branch_tag}
                  city={city}
                  state={state}
                  date_type={date_type}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#060658",
                    }}
                  >
                    Geo Grid Scan
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ height: "10px", width: "auto" }}
                      src={ggogle_imae}
                      alt=""
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        color: "#7e918a",
                        fontWeight: "500",
                      }}
                    >
                      Official Google Maps Ranking
                    </p>
                  </div>
                </div>
                <GeoGridScan
                  business_id={business_id}
                  location_id={
                    location_id
                      ? location_id
                      : filter?.branch?.choices[1]?.value
                      ? filter?.branch?.choices[1]?.value
                      : 0
                  }
                  history={history}
                  top_keyword_data={props?.top_keyword_data?.keywords_list}
                  branch_tag={branch_tag}
                  city={city}
                  state={state}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

// export default GeoGridRanker;

const mapStateToProps = (state) => {
  return {
    filter: state.filter,
    loader: state.common.loader,
    listing_search_keywords: state.listingPerformance.listing_search_keywords,
    top_keyword_data: state.listingGeoGridRanker.top_keyword_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // filterAction: bindActionCreators(filterListActions, dispatch),
  actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
  actions: bindActionCreators(listingGeoGridRankerAction, dispatch),
});

// export default connect(mapStateToProps, mapDispatchToProps, null, {
//   forwardRef: true,
// })(GeoGridRanker);

export default GoogleApiWrapper({
  apiKey: "AIzaSyDtiQSYOJ6tdOSXoM6ZWbpNAOcQgmLWDxY",
})(connect(mapStateToProps, mapDispatchToProps)(GeoGridRanker));
