import React from "react";
import { Nav, NavLink, NavItem, TabPane, TabContent } from "reactstrap";
import classNames from "classnames";
import TopicsReviews from "./TopicsReviews";
import LimitModal from "../../components/common/LimitModal";

class TopicsReport extends React.Component {
  state = {
    checked: false,
    showButton: false,
    activeTab: "1",
    params: {
      1: "",
      2: "",
      3: "",
    },
    isDownloadReviewModalOpen: false,
  };

  toggleTab = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  handleReviewDownloadModal = (downloadFlag) => {
    this.setState({
      isDownloadReviewModalOpen: !this.state.isDownloadReviewModalOpen,
    });
    if (!downloadFlag) return;
    this.props.handleTopicsReviewDownload();
  };

  render() {
    const { reviews, nouns, reviewDetails, loader, checked, getChunks } =
      this.props;
    const { isDownloadReviewModalOpen } = this.state;

    return (
      <div className="w-100">
        <Nav>
          <NavItem>
            <NavLink
              className={classNames("font-dark weight-500", {
                active: this.state.activeTab === "1",
              })}
              onClick={() => {
                this.toggleTab("1");
              }}
            >
              Reviews
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent
          activeTab={this.state.activeTab}
          className="mt-2 mr-2 h-100"
        >
          <TabPane tabId="1" className={"h-100"}>
            {checked && checked.nouns && checked.nouns.size === 0 ? (
              <div className="position-relative h-100">
                <div className="topic-mask"></div>
                <h4 className="topic-mask-text">
                  Select a keyword to view reviews
                </h4>
              </div>
            ) : (
              <>
                {nouns && Object.keys(nouns).length ? (
                  <TopicsReviews
                    reviews={reviews}
                    fullReview={this.props.fullReview}
                    reviewDetails={reviewDetails && reviewDetails.description}
                    loader={loader}
                    checked={checked}
                    getChunks={getChunks}
                    onChange={() => {
                      this.setState({ checked: !checked });
                      this.props.getChunks(checked);
                    }}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </TabPane>
        </TabContent>

        {isDownloadReviewModalOpen && (
          <LimitModal
            isOpen={isDownloadReviewModalOpen}
            isReviewsPage={true}
            toggle={() =>
              this.setState({
                isDownloadReviewModalOpen: !isDownloadReviewModalOpen,
              })
            }
            HeaderText={"Alrighty"}
            message={[
              "Hey you are good to go. Click ok to download Insights Review Report",
            ]}
            handleDownloadModal={this.handleReviewDownloadModal}
          />
        )}
      </div>
    );
  }
}

export default TopicsReport;
