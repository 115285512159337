import React, { useState, useRef, useEffect } from "react";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";
import { Chip, Stack } from "@mui/material";
import "./Addmenu.css"; // Create a CSS file for your styles
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import ListingFPInput from "../../../../../components/common/new/Listing/ListingFPInput";

const EditMenu = (props) => {
    const { location_food_menu_data, actions, selectReso, business_id, sectionIndexValue, menuIndexValue } = props;
    console.log(">>>>>>>>>>>>>>>>>>>>.index", sectionIndexValue, menuIndexValue)
    // console.log("indexValueindexValueindexValueindexValueindexValue", location_food_menu_data, "sdfasdfasdf", indexValue, sectionIndexValue, menuIndexValue)
    const fileInputRef = useRef(null);

    // Get the existing item data
    const existingItem = location_food_menu_data?.menus?.[menuIndexValue]?.sections?.[sectionIndexValue] || {};
    console.log("existingItemexistingItemexistingItemexistingItemexistingItem", existingItem)

    const [sectionName, setSectionName] = useState(existingItem?.labels?.[0]?.displayName || "");
    console.log("sectionNamesectionNamesectionNamesectionNamesectionName", sectionName)


    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        console.log("Selected files:", files);
    };

    const updateMenuItem = async () => {
        const restodetail = { business_id, selectReso };

        // New section to be added
        const newSection = {
            displayName: sectionName,
        };

        // Create a copy of the existing food menu data to avoid mutating the original state
        const updatedMenuData = { ...location_food_menu_data };

        // Check if the path exists before updating the value
        if (updatedMenuData.menus?.[menuIndexValue]?.sections?.[sectionIndexValue]?.labels?.[0]) {
            updatedMenuData.menus[menuIndexValue].sections[sectionIndexValue].labels[0].displayName = sectionName;
        }

        // Create the payload with updated menu data
        const updateCode = {
            type: "updateMenu",
            updateMenuData: updatedMenuData,
        };

        // Dispatch the action or make the API call
        await actions?.getGmbListingUpdateListing(updateCode, restodetail);
        await actions.getGmbLocationFoodmenu(business_id, selectReso);

    };


    return (
        <>
            <div className="add-menu-container">
                {/* <div className="menu-header">
                    <h3>Edit menu item</h3>
                    <p className="menu-subtext">This menu will be publicly visible on your profile</p>
                </div> */}
                <div className="menu-section">
                    {/* <label className="section-label">Edit menu section</label> */}
                    <div className="section-input">
                        <ListingFPInput
                            placeholder={"Section Name"}
                            value={sectionName}
                            onChange={(e) => setSectionName(e.target.value)}
                        />
                        <p>{sectionName?.length} / 40</p>
                    </div>
                </div>
                {/* <div className="menu-items">
                    <div className="menu-item-header">
                        <label className="item-label">Edit menu item</label>
                        <p className="item-subtext">Modify the details of the selected item</p>
                    </div>
                    <div className="menu-item-grid">
                        <div className="menu-item-inputs">
                            <div className="input-group">
                                <ListingFPInput
                                    placeholder={"Item name*"}
                                    value={itemName}
                                    onChange={(e) => setItemName(e.target.value)}
                                />
                                <p>{itemName.length} / 40</p>
                            </div>
                            <div className="input-group">
                                <ListingFPInput
                                    placeholder={"Item price (INR)"}
                                    value={itemPrice}
                                    onChange={(e) => setItemPrice(e.target.value)}
                                />
                                <p>{itemPrice.length} / 40</p>
                            </div>
                            <div className="input-group">
                                <textarea
                                    className="textAreaUI"
                                    placeholder={"Item description"}
                                    value={itemDescription}
                                    onChange={(e) => setItemDescription(e.target.value)}
                                />
                                <p>{itemDescription.length} / 40</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="dietary-restrictions">
                    <label className="dietary-label">Dietary restrictions</label>
                    <p className="dietary-subtext">Select all that apply</p>
                </div>
                <Stack direction="row" spacing={1}>
                    <Chip label="Vegetarian" variant="outlined" />
                    <Chip label="Vegan" variant="outlined" />
                </Stack> */}
            </div>
            <div className="menu-footer">
                <FPButton label="Save" size="Small" onClick={updateMenuItem} />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        location_food_menu_data: state.gmbListingLocation.location_food_menu_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(locationActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMenu);
