import React from "react";
import WordCloudTable from "./WordCloudTable";
// import LoaderComponent from "../common/loaderComponent";
import { Skeleton } from "@mui/material";

class WordCloudComponent extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data && nextProps.data !== prevState.data) {
      return { data: nextProps.data };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        positive: {},
        neutral: {},
        negative: {},
      },
    };
  }

  render() {
    const { data } = this.state;
    const { dataLoad, worldCloudApiCalled, fetchWordCloud } = this.props;

    const colorUI = (type) => {
      switch (type) {
        case "positive":
          return {
            textColor: "#81E050",
            backgroundColor: "rgba(129, 224, 80, 0.06)",
          };
        case "neutral":
          return {
            textColor: "#FFD800",
            backgroundColor: "rgba(255, 216, 0, 0.04)",
          };
        case "negative":
          return {
            textColor: "#FF0000",
            backgroundColor: "rgba(255, 0, 0, 0.04)",
          };
        default:
          return {}
      }
    };

    return (
      <div>
        {dataLoad ? (
          <>
            <div style={{ borderBottom: `2px dashed #bfc2cb` }}>
              <Skeleton variant="text" width="90%" height={30} />
              <Skeleton variant="text" width="70%" height={30} />
            </div>
            <div style={{ borderBottom: `2px dashed #bfc2cb` }}>
              <Skeleton variant="text" width="90%" height={30} />
              <Skeleton variant="text" width="70%" height={30} />
            </div>
            <div>
              <Skeleton variant="text" width="90%" height={30} />
              <Skeleton variant="text" width="70%" height={30} />
            </div>
          </>
        ) : (
          <section>
            {worldCloudApiCalled ?
              <>
                <WordCloudTable type="positive" data={data.positive} />
                <WordCloudTable type="neutral" data={data.neutral} />
                <WordCloudTable type="negative" data={data.negative} />
              </>
              : <div 
                  className="text-center p-4 fp-size-16 underline" 
                  style={{ textDecoration: 'underline', color: 'deeppink', cursor: 'pointer' }}
                  onClick={() => fetchWordCloud()} 
                >Click to Load Data</div>
            }
          </section>
        )}
      </div>
    );
  }
}

export default WordCloudComponent;
