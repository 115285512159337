import React from "react";
import "antd/dist/antd.css";
import "./styles/index.global.css";
import "./styles/styles.css";
import "./styles/select-box.css";
import "./styles/loader.css";
import "./styles/utility.css";
import "./styles/toaster.css";
import "./styles/switch.css";
import "./styles/multiselect.css";
import "./styles/progress.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/flatpickr/dist/themes/material_blue.css";
import "./styles/campaigns-template.css";
import "./styles/table.css";
import 'react-virtualized/styles.css';
import configureStore from "./store/configureStore";
import { Provider as ReduxProvider} from "react-redux";
import routes from "./routes";
import { SideNavProvider } from "./Context/sideNavContext";

const store = configureStore();
const composeProviders = (providers) =>
  providers.reduce(
    (Prev, Curr) =>
      ({ children }) =>
        (
          <Prev>
            <Curr>{children}</Curr>
          </Prev>
        ),
    ({ children }) => <>{children}</>
  );

const Providers = composeProviders([
  ({ children }) => <ReduxProvider store={store}>{children}</ReduxProvider>,
  SideNavProvider
]);

function App() {
  return <Providers>{routes}</Providers>;
}
export default App;