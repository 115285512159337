import React from "react";
import { MessageBox } from "../../components/common/new";
import Highlighter from "react-highlight-words";
import Linkify from "react-linkify";
import moment from "moment";

const TicketText = ({ item, searchText }) => {
  const replyTime = item.source_data.reviews_comment
    ? moment(item.source_data.reviews_comment.created_at).format("llll")
    : "";
  return (
    <div className="d-flex flex-column gap-10">
      {item.description && (
        <MessageBox
          isSelfBox={false}
          time={moment(item.source_data.created_at).format("llll")}
        >
          <Linkify
            properties={{ target: "_blank" }}
            className="font-blue-gray fp-size-13"
          >
            <Highlighter
              highlightStyle={{
                color: +item.source_data.rating > 3 ? "green" : "red",
                fontWeight: "bold",
              }}
              searchWords={[searchText]}
              textToHighlight={item.description}
            ></Highlighter>
          </Linkify>
        </MessageBox>
      )}
      {item.source_data && item.source_data.reviews_comment && (
        <MessageBox
          backgroundColor="#FDE6F1"
          time={replyTime}
          author={item.source_data.reviews_comment.added_by}
        >
          <p className="mb-0 font-dark fp-size-13">
            {item.source_data.reviews_comment.comments}
          </p>
        </MessageBox>
      )}
    </div>
  );
};
export default TicketText;
