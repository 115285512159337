import React from 'react';
import { Form, Grid, Header, Segment,  Image ,Button} from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
// import ReviewWithThanks from '../../images/reviewMethods/ReviewWithThanks.png';
import SocialButtonsGroup from '../common/SocialButtonsGroup';
import offerImage from '../../images/black-friday.png';
import { BiSolidDiscount } from 'react-icons/bi';
import moment from 'moment';

class SurveyMoreReview extends React.Component {
  constructor(props) {
    super(props);
    this.copyReview = this.copyReview.bind(this);
    this.copyCode = this.copyCode.bind(this);
    this.state = {reviewBtnText:'copy', copyCodeBtnText:'Copy Code'};
      
  }

  copyReview() {
    const copyText = document.getElementById('copyReviewInput');
    function listener(e) {
      e.clipboardData.setData('text/plain', copyText.value);
      e.preventDefault();
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.setState({ reviewBtnText: 'Review Copied' });
  }

  copyCode() {
    const copyText = document.getElementById('couponCodeButton');
    function listener(e) {
      e.clipboardData.setData('text/plain', copyText.innerHTML);
      e.preventDefault();
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.setState({ copyCodeBtnText: 'Code Copied' });
  }

  render() {
    const {submittedReview, business, review_gating , review_channels, initialValue, include_comment, include_offer, offer_data, thanks_line} = this.props;
   
    
    let final_line_new = thanks_line && thanks_line.replace('{customer name}', initialValue.name);
    
    return (
      <Grid textAlign='center' style={{}} verticalAlign='middle'>
        <Grid.Column textAlign='left' text style={{ maxWidth: 400 }}>
          <Form size='large'>
            <Segment stacked rounded style={{ height: 'auto', borderRadius: '20px' }}>
              <Header as='h2' textAlign='center' style={{ marginBottom: '20px', color: 'darkgreen', fontSize: '32px' }}>
                <Image src={business ? business.logo : ''} style={{maxHeight:'100px', margin:'0 auto'}}/>
              </Header>
              {(include_offer && offer_data) ? 
                <div className='dashedBorderThankYouPage' style={{border: '1px dashed', borderRadius:'40px', padding:'5px', height:'auto'}}>
                  <Row className='mb-3'>
                    <Col md='7' style={{textAlign:'left', marginTop:'30px', fontSize:'20px', fontWeight:'bold', paddingLeft:'25px'}}>
                      {offer_data.offer}
                    </Col>
                    <Col md='5'>
                      <Image src={offerImage}  style={{height:'150px'}} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md='3' style={{textAlign:'center'}}>
                      <BiSolidDiscount color='green' style={{width: '40px', height: '40px', marginTop:'7px'}}/>
                    </Col>
                    <Col md='4' style={{border:'1px solid black', borderRadius:'5px'}}>
                      <p id='couponCodeButton' style={{marginBottom:'-10px', marginTop:'8px'}}>{offer_data.coupon_code}</p>
                      <small style={{fontSize:'6px'}}>{moment(offer_data.issued_at).format('[on] MMM Do[,] YY [at] hh:mm A')}</small>
                    </Col>
                    <Col md='5' style={{textAlign:'center', marginTop:'6px'}}>
                      <Button style={{backgroundColor:'green', color:'white'}} onClick={this.copyCode}>{this.state.copyCodeBtnText}</Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12' style={{padding:'23px'}}>
                      Instructions & Terms - <br />
                      {offer_data.terms}
                    </Col>
                  </Row>
                  {offer_data.terms_url && 
                    <Row style={{padding:'10px'}}>
                      <Col md='2'>
                        T&C:-
                      </Col>
                      <Col md='10'>
                        <a rel='noreferrer' href={offer_data.terms_url} target='_blank'>{offer_data.terms_url}</a>
                      </Col>
                    </Row>
                  }
                </div>
                :
                <div>
                  <Header as="h3" style={{color:'#0F62FE'}} textAlign='center'>
                    {final_line_new && final_line_new.split('\n').map((item, index)=>{
                      return (
                        <p key={index} style={{marginBottom:'0'}}>{item}</p>
                      );
                    })}
                  </Header>
                </div>
              }
              {(review_gating) &&
                <Row className="" style={{padding:'15px',display:Object.keys(review_channels)?.length === 1  ? 'flex' : "", justifyContent:Object.keys(review_channels)?.length === 1  ? "center" : ""}}>
                  <Header as="h5" textAlign='center' style={{color:'grey'}}>
                    We have one last request, would you please share you feedback with other on the platform linked below.
                  </Header>
                  <Row className="pt-3" style={{padding:'0 10px'}}>
                    <Col className="col-12">
                      <SocialButtonsGroup links={review_channels} />
                    </Col>
                  </Row>
                  { (include_comment) && 
                    <div>
                      <br />
                      <Header as="h5" style={{color:'grey'}}>
                        You can copy your previous feedback
                      </Header>
                      <br />
                      <textarea name="text" type="textarea" id="copyReviewInput" className="custom-textArea" value={submittedReview} rows={2} cols={2} style={{ border: 'none', outline: 'none', borderBottom: '1.1px solid black', marginBottom: '20px',borderRadius:'0px', width:'300px' }}/>
                      <Button circular style={{ color: 'white', backgroundColor: '#0F62FE', position:'absolute', bottom:'10px', right:'20px' }} floated='right' onClick={this.copyReview}>
                        {this.state.reviewBtnText}
                      </Button>
                    </div>
                  }
                </Row>
              }
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default SurveyMoreReview;