import React from "react";
import ChatInputComponent from "../../../components/messenger/ChatInputComponent";
import WpEmptyChat from "../../../images/WpEmptyChat.png";
import ChatHeader from "./ChatHeader";
import ChatArea from "./ChatArea";
// import LoaderComponent from "../../../components/messenger/Common/LoaderComponent";

function WhatsUpChatComponent({ selectedChat, contactChat, isLoader, business_id, ws }) {
    // function hasRecentIncomingMessage(messages) {
    //     const now = new Date();  // Get current time
    //     const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
    
    //     return messages.some(msg => {
    //         const msgTime = new Date(msg.msg_time);  // Convert msg_time to Date
    //         return msg.incoming && msgTime >= twentyFourHoursAgo; // Check if within 24 hours
    //     });
    // }

    // const enableButton = hasRecentIncomingMessage(contactChat)

  return (
    <>
      {/* {isLoader ? (
        <div className="my-5">
          <LoaderComponent showLoader={isLoader} />
        </div>
      ) : (
      )} */}
        <React.Fragment>
          <div className="whatApp-chat-wrapper">
            {selectedChat === "" ? (
              <div className="empty-chat-container">
                <img
                  alt="Empty chat"
                  src={WpEmptyChat}
                  className="empty-chat-image"
                />
                <p className="empty-chat-title">Start a Conversation</p>
                <p className="empty-chat-subtitle">
                  Select a chat to begin sending & receiving messages
                </p>
              </div>
            ) : (
              <>
                <ChatHeader selectedChat={selectedChat} />
                <ChatArea selectedChat={contactChat} />
                <div style={{ padding: "0px 16px 10px 16px" }}>
                  <ChatInputComponent
                   className="whatsapp-chat-input"  
                    messsageCallback={() => {
                      console.log("====================================");
                      console.log("dbscfghjdvcgcfv");
                      console.log("====================================");
                    }}
                    showDivider={false}
                    textAreaRows={1}
                    active_user={{}}
                    sendButtonText="Send Reply"
                    placeholder="Type a message"
                    ws = {ws}
                    business_id={business_id}
                    selectedChat={selectedChat}
                    // enableButton = {enableButton}
                  />
                </div>
              </>
            )}
          </div>
        </React.Fragment>
    </>
  );
}

export default WhatsUpChatComponent;
