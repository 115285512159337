import classNames from "classnames";
import React from "react";

const NoData = ({ title, caption, className, height= true }) => {
  return (
    <div
      className={classNames(
        `d-flex justify-content-center align-items-center flex-column w-100 ${height && "h-100"}`,
        className
      )}
    >
      <h1 className="text-center">{title || "No record found"}</h1>
      {caption && <p className="text-center">{caption}</p>}
    </div>
  );
};

export default NoData;
