import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, SubmissionError } from "redux-form";
import { Form, Grid, Header } from "semantic-ui-react";
import { CheckboxField } from "react-semantic-redux-form";
import LocationSearchInputComponent from "../common/LocationSearchInput";
import LogoIcon from "../../images/fp-icon.png";
import { GoogleApiWrapper } from "google-maps-react";
import MapComponent from "../../components/common/googleMap/Map";
import MarkerComponent from "../../components/common/googleMap/Marker";
import { FPButton } from "../common/new";

const validate = (values) => {
  const errors = {};
  if (!values.location_address) errors.location_address = "Select Branch Location";
  return errors;
};

class BranchSetupForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const { handleBranchSetup, editLocation, initialValues } = this.props;
    const newData = {
      location_address: data.location_address.address,
      location_coordinates: `${data.location_address.coordinates.lat}, ${data.location_address.coordinates.lng}`,
      is_corporate: data.is_corporate ? data.is_corporate : false,
    };
    if (initialValues && initialValues.id) {
      newData.id = initialValues.id;
      return editLocation(newData).catch((err) => {
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
    }
    return handleBranchSetup(newData).catch((err) => {
      const errobj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errobj);
    });
  }

  render() {
    const { handleSubmit, submitting, hideLogo, loaded, google, locationAddress } = this.props;
    let modifiedAddress;
    if (locationAddress && locationAddress.coordinates) {
      modifiedAddress = {
        shape_type: "Point",
        lat_long: {
          coordinates: [locationAddress.coordinates.lng, locationAddress.coordinates.lat],
        },
      };
    }
    return (
      <Grid textAlign="center" style={{ height: "100%", ...this.props.style }} verticalAlign="middle">
        <Grid.Column style={this.props.colStyle}>
          {!hideLogo && (
            <div>
              <div className="text-center">
                <img src={LogoIcon} alt="" />
              </div>
              <Header
                as="h2"
                // color="teal"
                textAlign="center"
                className="mt-3 pb-1"
              >
                Setup Your Branch
              </Header>
            </div>
          )}
          <Form onSubmit={handleSubmit(this.submit)}>
            <div className="text-left">
              <Field
                name="location_address"
                component={LocationSearchInputComponent}
                placeholder="Search branch location"
                label="Branch location"
                required
                height={"80px"}
              />
              <Field name="is_corporate" component={CheckboxField} label="Corporate branch" />
              {loaded && (
                <MapComponent
                  google={google}
                  height={hideLogo ? "48vh" : "42vh"}
                  locations={modifiedAddress ? [modifiedAddress] : []}
                >
                  {locationAddress && locationAddress.coordinates && (
                    <MarkerComponent position={locationAddress.coordinates} />
                  )}
                </MapComponent>
              )}
              <FPButton className="mt-4" label="Submit" type="submit" disabled={submitting} fullWidth />
            </div>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

const withReduxForm = reduxForm({
  form: "BranchSetupForm",
  validate,
})(BranchSetupForm);

const selector = formValueSelector("BranchSetupForm");

const BranchForm = connect((state) => {
  // eslint-disable-line
  const locationAddress = selector(state, "location_address");
  return {
    locationAddress,
  };
})(withReduxForm);

export default GoogleApiWrapper({
  apiKey: "AIzaSyDtiQSYOJ6tdOSXoM6ZWbpNAOcQgmLWDxY",
  libraries: ["places", "drawing"],
  version: 3.34,
})(BranchForm);
