import React, { useState, useEffect } from "react";
import FormModal from "./FormModal";
import moment from "moment";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import { Box, Collapse, IconButton } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import { format, snakeToCapital } from "../../libs/utils";
import calenderLogo from "../../images/svg/calender.svg";
import classNames from "classnames";

const ViewTagActivityModal = (props) => {
  const { open, toggle, review_tag_id } = props;
  const [isOpen, setIsOpen] = useState(true);
  const [activity, setActivity] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios_instance
      .get(format(`${URLS.GET_TAG_ACTIVITY}`, [review_tag_id]))
      .then((res) => {
        setActivity(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [review_tag_id]);
  return (
    <FormModal isOpen={open} showDivider={false} toggle={toggle} heading={"View Tag Activity"} width="700px">
      <div id="activity-log">
        <div className="d-flex justify-content-between align-items-center">
          <span style={{ fontSize: "22px", fontWeight: "600", color: "#1B1C1D" }}>Activity Log</span>
          <IconButton onClick={() => setIsOpen(!isOpen)} size="small">
            {isOpen ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
          </IconButton>
        </div>
        <Collapse in={isOpen}>
          <div id="activity-log-content" style={{ marginTop: "15px", overflowX: "hidden" }}>
            {Array.isArray(activity) && activity.length > 0 ? (
              <ul className="timeline">
                {activity.map((item, index) => {
                  return (
                    <li key={index}>
                      <Row>
                        <Col className="action-box">
                          <div className="direction-r">
                            <div className={classNames("flag flag-wrapper", item.action, item.action_item)}>
                              {item.action === "add" && (
                                <Box className="d-flex align-items-center gap-6">
                                  <span style={{ fontSize: "15px", fontWeight: "500", color: "#1B1C1D" }}>Added</span>
                                  <span
                                    style={{ border: "1px solid #BFC2CB", borderRadius: "8px", padding: "2px 6px" }}
                                  >
                                    {snakeToCapital(item.current_value)}
                                  </span>
                                </Box>
                              )}
                              {item.action === "remove" && (
                                <Box className="d-flex align-items-center gap-6">
                                  <span style={{ fontSize: "15px", fontWeight: "500", color: "#1B1C1D" }}>Deleted</span>
                                  <span
                                    style={{ border: "1px solid #BFC2CB", borderRadius: "8px", padding: "2px 6px" }}
                                  >
                                    {snakeToCapital(item.previous_value)}
                                  </span>
                                </Box>
                              )}
                              {item.action === "edit" && (
                                <Box className="d-flex align-items-center gap-6">
                                  <span style={{ fontSize: "15px", fontWeight: "500", color: "#1B1C1D" }}>
                                    Edited {item.action_item}
                                  </span>
                                  <span
                                    style={{
                                      border: "1px solid ",
                                      borderColor:
                                        item.previous_value === "positive"
                                          ? "#81E050"
                                          : item.previous_value === "negative"
                                          ? "#EC554E"
                                          : item.previous_value === "neutral"
                                          ? "#FAE25A"
                                          : "#BFC2CB",
                                      borderRadius: "8px",
                                      padding: "2px 6px",
                                    }}
                                  >
                                    {snakeToCapital(item.previous_value)}
                                  </span>
                                  <span style={{ fontSize: "15px", fontWeight: "500", color: "#1B1C1D" }}>To</span>
                                  <span
                                    style={{
                                      border: "1px solid",
                                      borderColor:
                                        item.current_value === "positive"
                                          ? "#81E050"
                                          : item.current_value === "negative"
                                          ? "#EC554E"
                                          : item.current_value === "neutral"
                                          ? "#FAE25A"
                                          : "#BFC2CB",
                                      borderRadius: "8px",
                                      padding: "2px 6px",
                                    }}
                                  >
                                    {snakeToCapital(item.current_value)}
                                  </span>
                                </Box>
                              )}
                              <div className="d-flex my-2 fp-size-12 align-items-center gap-6">
                                <img src={calenderLogo} />
                                <p className="font-blue-gray m-0" style={{ linHeight: 1 }}>
                                  {moment(item.created_at).format("llll")}
                                </p>
                              </div>
                              <p className="font-dark m-0 fp-size-13 d-inline">{item.user_name}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  background: "#FAFAFB",
                  padding: "5px 11px",
                  border: "1px solid #BFC2CB",
                  borderRadius: "8px",
                  marginTop: "10px",
                }}
              >
                No Activity Logs
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </FormModal>
  );
};

export default ViewTagActivityModal;
