import React, { useState } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { ListDescription } from "semantic-ui-react";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import "./listing.css";
import google_svg from "../../../images/googlephoto.svg";
import { makeStyles } from "@material-ui/core/styles";
import duplicate_svg from "../../../images/dusplicate.svg";
import { formatReviews } from "../../../libs/helper";
import { getGmbListingUpdateListing } from "../../../actions/listing/listingLocationAction";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import * as listingUtils from "../../../libs/listingToast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const UpdateOpenDate = ({ primaryDetail, getGmbListingUpdateListing, business_id, selectReso, actions }) => {
  const addressString = [primaryDetail?.addressLines, primaryDetail?.locality, primaryDetail?.postalCode].join(", ");
  const classes = useStyles();

  const [openingDate, setOpeningDate] = useState(primaryDetail?.openInfo_openingDate);

  React.useEffect(() => {
    if (primaryDetail?.openInfo_openingDate) {
      setOpeningDate(dayjs(primaryDetail?.openInfo_openingDate));
    } else {
      setOpeningDate(dayjs(new Date()));
    }
  }, [primaryDetail?.openInfo_openingDate]);

  const businessOpeningDateHandler = async () => {
    const year = dayjs(openingDate).year();
    const month = dayjs(openingDate).month() + 1;
    const day = dayjs(openingDate).date();

    const data = {
      type: "openInfo_openingDate",
      openingDate: {
        year,
        month,
        day,
      },
    };
    const restodetail = {
      business_id,
      selectReso,
    };
    await getGmbListingUpdateListing(data, restodetail);
    listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
    await actions.getGmbListingRefreshListing(business_id, selectReso);

    listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    listingUtils?.displayMessage("positive", "Listing updated successfully.");
    await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };

  return (
    <>
      <div className="listing-container" style={{ overflow: "hidden", gap: "32px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <div className="listing-header">
            <div className="listing-info">
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="listing-name">{primaryDetail?.title}</div>
                <img
                  src={
                    primaryDetail?.is_varified
                      ? verified_svg
                      : primaryDetail?.is_suspend
                      ? duplicate_svg
                      : primaryDetail?.is_duplicate
                      ? duplicate_svg
                      : ""
                  }
                  alt="verifiedImage"
                />
              </div>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(1)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{formatReviews(primaryDetail?.totalReviewCount)} Reviews</span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>{addressString}</ListDescription>
          </div>
        </div>
      </div>
      <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "calc(100% - 120px)",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "15px",
                paddingBottom: "5px",
              }}
            >
              <div className="listing-name">Add Business Opening date to define age of Business</div>
              <div
                style={{
                  padding: "10px",
                  backgroundColor: "#F6F6F8",
                  borderRadius: "8px",
                }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  {/* <div style={{ width: "100px" }}> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      //   label="Controlled picker"
                      value={dayjs(openingDate)}
                      onChange={(newValue) => setOpeningDate(newValue)}
                      maxDate={dayjs(new Date())}
                      format="DD/MM/YYYY"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": { width: "100%" },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
              }}
            >
              <FPButton
                label="Update"
                size="small"
                onClick={businessOpeningDateHandler}
                disabled={openingDate === primaryDetail?.openInfo_openingDate || primaryDetail?.is_suspend}
              />
            </div>
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
//     actions: bindActionCreators(locationActions, dispatch),
//   };
// };

// export default UpdateOpenDate;
const mapDispatchToProps = (dispatch) => ({
  getGmbListingUpdateListing: bindActionCreators(getGmbListingUpdateListing, dispatch),
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(UpdateOpenDate);
