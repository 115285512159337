import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Checkbox, Card, Dropdown, Menu } from "semantic-ui-react";
import GenericTemplateTable from "../campaign/genericTemplate/genericTemplateTable";
import PaginationComponent from "../common/Pagination";
import { TabPane, TabContent, Row, Col } from "reactstrap";
import {
  Box,
  ClickAwayListener,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import SortIcon from "../../images/svg/Sort.svg";
import { FPToolTip } from "../common/new";
import deleteIcon from "../../images/svg/trash.svg";
import editIcon from "../../images/svg/EditPen.svg";
import LoadIcon from "../../images/load.png";
import downloadIcon from "../../images/Download.png";
import deleteRed from "../../images/deleteRed.png";
import "../contacts/contact.css";
import ContactsExtraFilters from "./ContactsExtraFIlters";
import queryString from "query-string";
import { formValueSelector } from "redux-form";
import { FPTab } from "../../components/common/new/tabs";
import { sortChangeColor } from "../../libs/utils";
import classNames from "classnames";
import tagIcon from "../../images/svg/reviewTag.svg";
import { Filter, NewFilter } from "../../images/svg";
import SearchComponent from "../common/SearchComponent";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmationModal from "../common/ConfirmationModal";
import ClearIcon from "@material-ui/icons/Clear";

import { withStyles } from "@material-ui/core/styles";

// Define your styles
const styles = (theme) => ({
  popoverContainer: {
    maxHeight: "130px",
    overflowY: "auto",
    width: "250px",
    padding: theme.spacing(2),
    borderRadius: "10px",
  },
  popoverContent: {
    fontSize: "14px",
    fontWeight: 500,
  },
});

class ContactsListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: (this.props.location.state && this.props.location.state.tab) || "1",
      showFilters: false,
      initialFilterData: null,
      assignTags: [],
      anchorEl: null,
    };
  }

  toggleTab = (tab) => {
    const {
      location: { search },
      handleTabParams,
      params,
    } = this.props;
    const { activeTab } = this.state;
    let query = { ...params };
    query[activeTab] = search;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        params: { ...query },
      });
    }
    handleTabParams(query, tab);
  };

  deleteTag = (id, assigned_tags, groupIdx, tagIdx) => {
    assigned_tags[groupIdx].tags.splice(tagIdx, 1);
    this.props.editContact({ id, tag_list: assigned_tags });
  };

  getTags = (assigned_tags) => {
    let new_tags = [];

    assigned_tags.map((item, groupIdx) => {
      const { group, tags, id } = item;
      tags.map((tag, tagIdx) => {
        new_tags.push({ group, tag, groupIdx, tagIdx, id });
      });
    });
    return new_tags;
  };

  handleClose = () => {
    this.setState({ showFilters: false });
  };

  handleReset = () => {
    // const { setInitialFilters } = this.props;
    // setInitialFilters && setInitialFilters({ filters: [{}] });
    this.setState({
      initialFilterData: null,
    });
  };

  onFilterBtnClick = (e) => {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  };

  extraFiltersSubmit = (data) => {
    this.setState({ showFilters: null });
    const { history } = this.props;
    const params = queryString.parse(history.location.search);
    let query = { search: params.search, page: 1, page_size: params.page_size };
    data.filters.map((item) => {
      query[`${item.filter.value}`] = Array.isArray(item.field)
        ? item.field.map((i) => i.value).join()
        : item.field.value || item.field;
      query[`${item.filter.value}_type`] = item.type.value;
    });
    this.setState({
      initialFilterData: data,
    });
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify(query),
    });
    // this.setState((prevState) => ({
    //   extraFilters: prevState.extraFilters + 1,
    // }));
  };

  handlePagination = () => {
    const pageSize = queryString.parse(this.props.history.location.search).pageSize || "10";
    if (Number(pageSize) === this.props.selectedContacts.length) {
      this.props.clearAll();
    }
  };

  toggleConfirm = (confirmationFunction) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      confirmationFunction,
      anchorEl: null,
    });
  };

  showAllTags = (event, item, id) => {
    const assingTagDataWithId = item.map((i) => {
      return {
        ...i,
        id,
      };
    });

    this.setState({
      anchorEl: event.currentTarget,
      assignTags: assingTagDataWithId,
    });
  };

  closePopover = () => {
    console.log("CLose Icon ");
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      contactList,
      showTabs = true,
      toggle,
      sortFunction,
      genericContactDelete,
      contactsCount,
      genericContactsCount,
      ordering,
      handleCheckbox,
      genericTemplateDetails,
      selectedContacts,
      lockedModules,
      contactsSelector,
      deleteContact,
      toggleAllSelected,
      clearContacts,
      deleteAllContacts,
      extraFilters,
      match: {
        params: { business_id },
      },
      initialFilters,
      history,
      location,
      gotToContactManageTags,
      toggleBulkUpload,
      downloadContacts,
      handleMoreOptions,
    } = this.props;
    const sort =
      ordering && ordering[0] === "-" ? { on: ordering.slice(1), by: "descending" } : { on: ordering, by: "ascending" };

    const { showFilters, initialFilterData } = this.state;
    const open = Boolean(showFilters);

    const getTabs = ["Contacts"];
    const condition = lockedModules["advance_campaign"] && !contactsSelector;

    if (condition) {
      getTabs.push(["Generic Contacts"]);
    }
    const pageSize = queryString.parse(history.location.search).page_size || "10";

    // const classes = useStyles();
    const { classes } = this.props;
    const { anchorEl, assignTags } = this.state;
    const openPopover = Boolean(anchorEl);

    return (
      <div>
        {showTabs && (
          <Row>
            <Col sm={12} xs={12} className="d-flex justify-content-between">
              <FPTab
                tabLabels={getTabs}
                minTabWidth={100}
                defaultValue={this.state.activeTab - 1}
                onChange={(value) => this.toggleTab((value + 1).toString())}
              />
              <div className="">
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="rightContent d-flex align-items-center gap-10">
                      <div className="d-flex align-items-center gap-10">
                        <SearchComponent
                          location={location}
                          history={history}
                          className="contacts-filter-search"
                          placeholder="Search here"
                          startIcon
                        />
                        {!contactsSelector && (
                          <Menu compact className="addUploadDropDown">
                            <Dropdown text="Add/Upload" className="text-light" pointing item>
                              <Dropdown.Menu>
                                <Dropdown.Item key={"1"} value={"5"} onClick={() => handleMoreOptions("5")} text>
                                  {" "}
                                  Add Contacts
                                </Dropdown.Item>
                                <Dropdown.Item key={"2"} value={"6"} onClick={() => handleMoreOptions("6")} text>
                                  {" "}
                                  Upload Contacts
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Menu>
                        )}
                        <div className="d-flex align-items-center">
                          <span>
                            <FPToolTip title={"Download Contacts"}>
                              <img
                                className={classNames({
                                  "fp-disabled": selectedContacts.length === 0,
                                })}
                                src={downloadIcon}
                                style={{ height: "18px", width: "18px" }}
                                onClick={() => downloadContacts()}
                                role="button"
                              />
                            </FPToolTip>
                          </span>
                          <div
                            style={{
                              height: "20px",
                              width: "1px",
                              backgroundColor: "#ccc",
                              margin: "0 10px",
                            }}
                          ></div>
                          <span>
                            <FPToolTip title={"Block Communication"}>
                              <img
                                src={LoadIcon}
                                // onClick={() => deleteCampaign()}
                                role="button"
                                alt="delete"
                                style={{ height: "18px", width: "18px" }}
                              />
                            </FPToolTip>
                          </span>
                          <div
                            style={{
                              height: "20px",
                              width: "1px",
                              backgroundColor: "#ccc",
                              margin: "0 10px",
                            }}
                          ></div>
                          <span>
                            <FPToolTip title={"Delete Contacts"} className="ms-2">
                              <img
                                className={classNames({
                                  "fp-disabled": selectedContacts.length === 0,
                                })}
                                src={deleteRed}
                                style={{ height: "18px", width: "18px" }}
                                onClick={() => deleteContact()}
                                role="button"
                                alt="delete"
                              />
                            </FPToolTip>
                          </span>
                        </div>
                      </div>

                      {/* style={{ backgroundColor: '#28314F',borderRadius: '20px', color: 'white',margin: '0 10px' }} */}
                      <Box
                        sx={{
                          position: "relative",
                        }}
                      >
                        <FPToolTip title="Filter">
                          <div className="contact-filter-view" onClick={(e) => this.onFilterBtnClick(e)}>
                            <NewFilter
                              color="white"
                              style={{
                                height: "15px",
                                width: "15px",
                              }}
                            />
                          </div>
                        </FPToolTip>
                        {showFilters && (
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <Box
                              sx={{
                                right: "36px",
                                top: "36px",
                                position: "absolute",
                                zIndex: 107,
                                boxShadow: "0px 1px 6px 0px",
                                borderRadius: "12px",
                                background: "white",
                                pointerEvents: open ? "auto" : "none",
                                // zIndex: open ? 100 : -100,
                                // opacity: open ? 1 : 0,
                              }}
                            >
                              <ContactsExtraFilters
                                className="p-3"
                                onSubmit={this.extraFiltersSubmit}
                                close={this.handleClose}
                                business_id={business_id}
                                initialValues={initialFilterData || { filters: [{}] }}
                                handleReset={this.handleReset}
                                history={history}
                                location={location}
                              />
                            </Box>
                          </ClickAwayListener>
                        )}
                      </Box>
                      <FPToolTip title={"Manage Tags"}>
                        <img
                          src={tagIcon}
                          onClick={() => gotToContactManageTags()}
                          style={{
                            height: "22px",
                            width: "22px",
                            cursor: "pointer",
                          }}
                        />
                      </FPToolTip>
                    </div>
                  </TabPane>
                </TabContent>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="2">
                    <div className="rightContent d-flex justify-content-end align-items-center gap-10">
                      <SearchComponent
                        location={location}
                        history={history}
                        className="contacts-filter-search"
                        placeholder="Search here"
                        startIcon
                      />
                      {!contactsSelector && (
                        <Menu compact className="addUploadDropDown">
                          <Dropdown text="Add/Upload" className="text-light" pointing item>
                            <Dropdown.Menu>
                              <Dropdown.Item key={"1"} value={"5"} onClick={() => handleMoreOptions("5")} text>
                                {" "}
                                Add Contacts
                              </Dropdown.Item>
                              <Dropdown.Item key={"2"} value={"6"} onClick={() => handleMoreOptions("6")} text>
                                {" "}
                                Upload Contacts
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Menu>
                      )}
                      <FPToolTip title="Filter">
                        <div className="contact-filter-view" onClick={(e) => this.onFilterBtnClick(e)}>
                          <NewFilter
                            color="white"
                            style={{
                              height: "15px",
                              width: "15px",
                            }}
                          />
                        </div>
                      </FPToolTip>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        )}
        <Row className="mt-3 ">
          <Col sm={12} xs={12}>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="ui-table-responsive">
                  <Table className="fp-table fp-table-center">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            id="all"
                            checked={
                              selectedContacts &&
                              contactList?.length > 0 &&
                              contactList?.every((obj) => selectedContacts.some((id) => obj.id === id))
                            }
                            onChange={handleCheckbox}
                          >
                            Select All
                          </Checkbox>
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell
                          onClick={() => sortFunction("email")}
                          style={
                            sort.on === "email"
                              ? {
                                  ...sortChangeColor("#1f9095", "#d9f0f0"),
                                  minWidth: "110px",
                                }
                              : null
                          }
                        >
                          Email
                          <SortIcon />
                        </TableCell>
                        <TableCell
                          onClick={() => sortFunction("contact_number")}
                          style={sort.on === "contact_number" ? sortChangeColor("#1f9095", "#d9f0f0") : null}
                        >
                          Phone
                          <SortIcon />
                        </TableCell>
                        <TableCell
                          className="text-nowrap"
                          onClick={() => sortFunction("last_visit")}
                          style={
                            sort.on === "last_visit"
                              ? {
                                  ...sortChangeColor("#1f9095", "#d9f0f0"),
                                  minWidth: "140px",
                                }
                              : null
                          }
                        >
                          Last Visited At
                          <SortIcon />
                        </TableCell>
                        <TableCell
                          className="text-nowrap"
                          onClick={() => sortFunction("visites")}
                          style={
                            sort.on === "visites"
                              ? {
                                  ...sortChangeColor("#1f9095", "#d9f0f0"),
                                  minWidth: "85px",
                                }
                              : null
                          }
                        >
                          Visits
                          <SortIcon />
                        </TableCell>
                        <TableCell>Branch</TableCell>
                        <TableCell style={{ minWidth: "125px", textWrap: "nowrap" }}>Assigned Tags</TableCell>

                        {!contactsSelector && <TableCell>Action</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedContacts?.length >= Number(pageSize) && (
                        <TableRow>
                          <TableCell colSpan={10} style={{ backgroundColor: "#DDEFF0" }}>
                            {!clearContacts ? (
                              <div>
                                <span
                                  className="ml-10"
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {Number(pageSize) === selectedContacts.length &&
                                    `All ${selectedContacts.length} contacts on this page are selected.`}
                                </span>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "#00B5AD",
                                    cursor: "pointer",
                                    marginLeft: "4px",
                                  }}
                                  onClick={toggleAllSelected}
                                >
                                  {Number(pageSize) === selectedContacts.length &&
                                    `Select all ${contactsCount} contacts`}
                                </span>
                              </div>
                            ) : (
                              <div>
                                <span
                                  className="ml-10"
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {`All ${selectedContacts.length} contacts are selected.`}
                                </span>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "#00B5AD",
                                    cursor: "pointer",
                                    marginLeft: "4px",
                                  }}
                                  onClick={toggleAllSelected}
                                >
                                  Clear Selection
                                </span>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {contactList && contactList.length ? (
                        contactList.map((item) => {
                          return (
                            <TableRow key={item.id}>
                              <TableCell>
                                <Checkbox
                                  id={item.id}
                                  checked={selectedContacts && selectedContacts.indexOf(item.id) > -1}
                                  onChange={handleCheckbox}
                                />
                              </TableCell>
                              <TableCell style={{ whiteSpace: "nowrap" }}>{item.name}</TableCell>
                              <TableCell onClick={() => sortFunction("email")}>
                                <FPToolTip title={item.email}>
                                  <span
                                    className="d-inline-block text-truncate width-sm"
                                    style={sort.on === "email" ? sortChangeColor("#1f9095") : null}
                                  >
                                    {item.email}
                                  </span>
                                </FPToolTip>
                              </TableCell>

                              <TableCell
                                onClick={() => sortFunction("contact_number")}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <span style={sort.on === "contact_number" ? sortChangeColor("#1f9095") : null}>
                                  {item.contact_number}
                                </span>
                              </TableCell>
                              <TableCell onClick={() => sortFunction("last_visit")}>
                                <span style={sort.on === "last_visit" ? sortChangeColor("#1f9095") : null}>
                                  {item.last_visit ? (
                                    moment(item.last_visit).format("DD-MM-YYYY")
                                  ) : (
                                    <span style={{ fontWeight: "bold" }}>-</span>
                                  )}
                                </span>
                              </TableCell>
                              <TableCell onClick={() => sortFunction("visites")}>
                                <span style={sort.on === "visites" ? sortChangeColor("#1f9095") : null}>
                                  {item.visites}
                                </span>
                              </TableCell>
                              <TableCell>
                                <FPToolTip title={item.location_address}>
                                  <span className="d-inline-block text-truncate width-sm">{item.location_address}</span>
                                </FPToolTip>
                              </TableCell>
                              {/* <TableCell>
                                {this.getTags(item.assigned_tags)
                                  .slice(0, 2)
                                  .map((i, index) => (
                                    <span key={index} className="contact-list-tags ml-1" title={`${i.group}: ${i.tag}`}>
                                      {i.tag}
                                      {!contactsSelector && (
                                        <i
                                          className="fa fa-times ml-1"
                                          onClick={() =>
                                            this.deleteTag(item.id, item.assigned_tags, i.groupIdx, i.tagIdx)
                                          }
                                        />
                                      )}
                                    </span>
                                  ))}
                                <span className="ml-1 font-weight-bold">
                                  {this.getTags(item.assigned_tags).length === 0
                                    ? "-"
                                    : this.getTags(item.assigned_tags).length > 2
                                    ? `+${this.getTags(item.assigned_tags).length - 2}`
                                    : ""}
                                </span>
                              </TableCell> */}
                              <TableCell
                                style={{
                                  whiteSpace: "break-spaces",
                                }}
                              >
                                <div className="d-flex align-items-center gap-6 flex-wrap justify-content-center">
                                  {this.getTags(item.assigned_tags)
                                    .slice(0, 2)
                                    .map((i, index) => {
                                      return (
                                        <FPToolTip title={i.tag}>
                                          <Box
                                            sx={{
                                              border: "1px solid #00B5AD",
                                              backgroundColor: "#DDEFF0",
                                              padding: "4px 10px",
                                              borderRadius: 99,
                                            }}
                                            className="d-inline-block text-truncate"
                                            key={index}
                                          >
                                            <span
                                              style={{ cursor: "pointer" }}
                                              onClick={(event) => this.showAllTags(event, item.assigned_tags, item.id)}
                                            >
                                              {i.tag}
                                            </span>
                                            <CloseIcon
                                              style={{
                                                height: "12px",
                                                width: "12px",
                                                marginLeft: "5px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                this.closePopover();
                                                this.toggleConfirm(() =>
                                                  this.deleteTag(item.id, item.assigned_tags, i.groupIdx, i.tagIdx)
                                                );
                                              }}
                                            />
                                          </Box>
                                        </FPToolTip>
                                      );
                                    })}
                                  {this.getTags(item.assigned_tags).length === 0 ? (
                                    "-"
                                  ) : this.getTags(item.assigned_tags).length > 2 ? (
                                    <Box
                                      sx={{
                                        backgroundColor: "#EAECF0",
                                        color: "#1B1C1D",
                                        padding: "4px 15px",
                                        borderRadius: 99,
                                        cursor: "pointer",
                                      }}
                                      onClick={(event) => this.showAllTags(event, item.assigned_tags, item.id)}
                                    >
                                      <span>{`+${this.getTags(item.assigned_tags).length - 2}`}</span>
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </TableCell>
                              {!contactsSelector && (
                                <TableCell>
                                  <div className="actionButton">
                                    <div>
                                      <FPToolTip title={"Edit"}>
                                        <img
                                          src={editIcon}
                                          onClick={() => toggle(item, "All_contacts")}
                                          role="button"
                                          alt="edit"
                                        />
                                      </FPToolTip>
                                    </div>
                                    <div>
                                      <FPToolTip title={"Delete"}>
                                        <img
                                          src={deleteIcon}
                                          onClick={() => deleteContact(item)}
                                          role="button"
                                          alt="delete"
                                        />
                                      </FPToolTip>
                                    </div>
                                  </div>

                                  {/* <span style={{ marginLeft: "" }} >
                                    
                                  </span> */}

                                  {/* <Popup
                                    content="Edit"
                                    trigger={
                                      <Icon
                                        name="edit"
                                        size="small"
                                        className="cursor-pointer"
                                        onClick={() =>
                                          toggle(item, 'All_contacts')
                                        }
                                      />
                                    }
                                  /> */}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell className="text-center" colSpan={10}>
                            No records Found{" "}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                <PaginationComponent
                  count={contactsCount && contactsCount}
                  location={this.props.location}
                  pageSizeCookie="contacts_page_all"
                  onPageNumberChange={this.handlePagination}
                  onPageSizeChange={this.handlePagination}
                  largeSizes
                />
              </TabPane>
            </TabContent>
            {lockedModules["advance_campaign"] && (
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="2">
                  {genericTemplateDetails && (
                    <Card className="mt-2" fluid>
                      <GenericTemplateTable
                        genericContactDelete={genericContactDelete}
                        toggle={toggle}
                        location={this.props.location}
                        genericTemplateDetails={genericTemplateDetails && genericTemplateDetails}
                      ></GenericTemplateTable>
                    </Card>
                  )}
                  <PaginationComponent
                    count={genericContactsCount && genericContactsCount}
                    location={this.props.location}
                    pageSizeCookie="contacts_page_generic"
                    largeSizes
                  />
                </TabPane>
              </TabContent>
            )}
          </Col>
        </Row>
        <ConfirmationModal
          header="Are you sure!"
          subHeader="Are you sure you want to delete the assigned tag"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          isOpen={this.state.isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={this.state.confirmationFunction}
        />

        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.popoverContainer}>
            <Typography className={`${classes.popoverContent}`}>
              Assigned Tags ({this.getTags(assignTags).length})
            </Typography>

            {this.getTags(assignTags)?.map((i, index) => {
              return (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <Typography className="mt-2">{i.tag}</Typography>
                    <ClearIcon
                      onClick={() => this.toggleConfirm(() => this.deleteTag(i.id, assignTags, i.groupIdx, i.tagIdx))}
                      style={{
                        height: "15px",
                        width: "15px",
                        color: "#1A2C32",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </>
              );
            })}
          </div>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lockedModules: state.business.lockedModules || {},
    // Number increment in filter button
    extraFilters: formValueSelector("contactsExtraFilters")(state, "filters"),
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(withStyles(styles)(ContactsListComponent));
