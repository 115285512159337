import React from "react";
import { FPButton } from "../../common/new";
import CoinUsageHistory from "./CoinUsageHistory";
import coin_img from "../../../images/coin_img.png";
import FPInput from "../../common/new/forms/ui/FPInput";
import { Progress } from "reactstrap";
import "./geoscancreadit.css"

const CoinDashboard = (props) => {
  const { coin_usage_history } = props;
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <h2 style={{ fontSize: "21px", fontWeight: "500" }}>Coins Dashboard</h2>
        <div
          style={{
            display: "flex",
            marginLeft: "0px",
            marginRight: "0px",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div
            style={{
              background: "white",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              border: "1px solid #d0d0d0",
              padding: "15px",
              width: "30%",
            }}
          >
            <img
              src={coin_img}
              alt="coin_img"
              style={{ height: "50px", width: "50px" }}
            />
            <span style={{ fontSize: "18px", fontWeight: "500" }}>
              Requests
            </span>
            <span style={{ fontSize: "14px", fontWeight: "500" }}>
              {coin_usage_history?.total_consumed} out of {coin_usage_history?.total_purchased} Requests are used
            </span>
            <Progress barClassName="coin-dashboard-progress-bar" value={coin_usage_history?.total_consumed} max={coin_usage_history?.total_purchased} />
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              background: "white",
              border: "1px solid #d0d0d0",
              padding: "15px",
              width: "30%",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img
                src={coin_img}
                alt="coin_img"
                style={{ height: "50px", width: "50px" }}
              />
              <FPButton
                type="submit"
                label="Buy Coins"
                className="buy-coin-button"
              />
            </div>
            <span style={{ fontSize: "18px", fontWeight: "500" }}>
              Add Coins to Wallet
            </span>
            <div
              style={{
                display: "flex",
                gap: "3px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  color: "#f13a88",
                  fontWeight: "500",
                }}
              >
                Buy
              </span>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <span style={{ fontSize: "18px", fontWeight: "500" }}>+</span>
                <input value={"100"} style={{ width: "70px" }} />
                <span style={{ fontSize: "18px", fontWeight: "500" }}>-</span>
              </div>
              <div style={{ display: "flex", gap: "4px" }}>
                <span
                  style={{
                    fontSize: "18px",
                    color: "#f13a88",
                    fontWeight: "500",
                  }}
                >
                  Magic Coins For
                </span>
                <input value={"$0.50"} style={{ width: "70px" }} />
              </div>
            </div>
          </div> */}
        </div>
        <CoinUsageHistory coin_usage_history={coin_usage_history} />
      </div>
    </>
  );
};

export default CoinDashboard;
