import React from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import PaginationComponent from "../common/Pagination";
import { FPBreadcrumbs, FPButton, FPToolTip } from "../common/new";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import SortIcon from "../../images/svg/Sort.svg";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import SearchComponent from "../common/SearchComponent";

class RecurringCampaignList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isNewsletterRecurringList: this.props.location.pathname.includes("/news-letter/") };
  }

  onCampaignNameClick(item) {
    const { history, business_id } = this.props;
    const { isNewsletterRecurringList } = this.state;
    isNewsletterRecurringList
      ? history.push(`/${business_id}/recurring-campaigns/news-letter/add?id=${item.id}`)
      : history.push(`/${business_id}/recurring-campaigns/review/add?id=${item.id}`);
  }

  render() {
    const { list, location, pageSizeCookie, sortFunction, ordering, goToAddCampaign, business_id } = this.props;
    const { isNewsletterRecurringList } = this.state;
    const breadCrumbList = [
      {
        label: "Campaigns",
        link: `/${business_id}/campaigns/review/list?page_size=10`,
      },
      {
        label: isNewsletterRecurringList ? "News Letter" : "Review Request",
        link: isNewsletterRecurringList
          ? `/${business_id}/campaigns/news-letter/list?page_size=10`
          : `/${business_id}/campaigns/review/list?page_size=10`,
      },
      {
        label: "Manage Recurring Campaign",
        link: "",
      },
    ];

    return (
      <Row>
        <Col sm="12">
          <div className="d-flex justify-content-between mb-3 align-center">
            <FPBreadcrumbs list={breadCrumbList} />
            <FPButton
              onClick={() => goToAddCampaign()}
              label={isNewsletterRecurringList ? "Create Newsletter Recurring Campaign" : "Create Recurring Campaign"}
              startIcon={<AddOutlinedIcon style={{ width: 16, height: 16 }} />}
              backgroundColor="#1A2544"
              size="small"
              fontSize="12px"
            />
          </div>
        </Col>
        <Col sm="12" className="mt-1">
          <CardWrapper
            contentClass="p-4"
            headerTitle={
              <div className="d-flex justify-content-between align-items-center">
                {
                  <span className="mt-2 d-inline-block">
                    {isNewsletterRecurringList ? "Manage Newsletter Recurring Campaign" : "Manage Recurring Campaign"}
                  </span>
                }
                <SearchComponent {...this.props} className="common-search" startIcon />
              </div>
            }
          >
            <div className="ui-table-responsive">
              <Table className="fp-table fp-table-center">
                <TableHead>
                  <TableRow>
                    <TableCell className="width-md-th">Campaign Name</TableCell>
                    <TableCell>Template</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Will Go</TableCell>
                    <TableCell>Day</TableCell>
                    <TableCell>Event</TableCell>
                    <TableCell>Schedule Time</TableCell>
                    <TableCell>Created by</TableCell>
                    <TableCell
                      className={`${ordering === "created_at" ? "sortCellStyleHeader" : ""} cursor-pointer`}
                      onClick={() => sortFunction("created_at")}
                    >
                      Created on
                      {sortFunction && <SortIcon />}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list &&
                    list.results &&
                    list.results.map((item, key) => (
                      <TableRow key={key} active={item.is_default}>
                        <TableCell className="width-md">
                          <FPToolTip title={item.name}>
                            <div
                              className=""
                              style={{
                                width: "160px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <span className="link-text" onClick={() => this.onCampaignNameClick(item)}>
                                {item.name}
                              </span>
                            </div>
                          </FPToolTip>
                        </TableCell>
                        <TableCell>{item.template_name}</TableCell>
                        <TableCell>{item.template_type}</TableCell>
                        <TableCell>{item.will_go}</TableCell>
                        <TableCell>{item.day}</TableCell>
                        <TableCell>{item.event}</TableCell>
                        <TableCell>{item.scheduled_time}</TableCell>
                        <TableCell>{item.created_by}</TableCell>
                        <TableCell className={`${ordering === "created_at" ? "sortCellStyle" : ""}`}>
                          {moment(item.created_at).format("DD-MM-YYYY")}
                        </TableCell>
                      </TableRow>
                    ))}
                  {list && list.results && !list.results.length && (
                    <TableRow>
                      <TableCell className="text-center" colSpan={9}>
                        No records Found{" "}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <PaginationComponent
              className="pb-0"
              count={list && list.count}
              location={location}
              pageSizeCookie={pageSizeCookie}
            />
          </CardWrapper>
        </Col>
      </Row>
    );
  }
}

export default RecurringCampaignList;
