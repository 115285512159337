import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import FPChip from "../new/FPChip/FPChip";
import { Box } from "@material-ui/core";

const Dropdown = (props) => {
  const {
    labelKey,
    valueKey,
    options: initialOptions,
    multi,
    input,
    onChange,
    searchFilter,
    placeholder,
    meta: { touched, error },
  } = props;
  const wrapperRef = useRef();
  const [selectedValue, setSelectedValue] = useState(multi ? [] : {});
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState(initialOptions);
  let timeout = 0;
  let blurTimeout = 0;

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions, isOpen]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () =>
      document.removeEventListener("click", handleClickOutside, false);
  }, []);

  useEffect(() => {
    setSelectedValue(multi ? (!input.value ? [] : input.value) : input.value);
  }, [input.value, multi]);

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleChange = (item) => {
    let updatedValue = multi
      ? selectedValue.filter((i) => i[valueKey] === item[valueKey]).length > 0
        ? selectedValue.filter((i) => i[valueKey] !== item[valueKey])
        : [...selectedValue, item]
      : item;
    input && input.onChange && input.onChange(updatedValue);
    onChange && onChange(updatedValue);
  };

  const checked = (value) => {
    return multi
      ? selectedValue.filter((i) => i[valueKey] === value).length > 0
      : selectedValue[valueKey] === value;
  };

  const handleSearch = ({ target: { value } }) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      const updatedOptions = initialOptions.filter((i) =>
        i[labelKey].toLowerCase().includes(value.toLowerCase())
      );
      setOptions(updatedOptions);
    }, 500);
  };

  const onBlurHandler = (onBlur) => {
    blurTimeout = setTimeout(() => {
      onBlur();
    });
  };

  const onFocusHandler = () => {
    clearTimeout(blurTimeout);
  };

  return (
    <div className="fp-dropdown">
      <div
        className={`border rounded w-100 h-75 pt-2 fp-dropdown-wrapper ${
          error && touched && "border-danger"
        }`}
        ref={wrapperRef}
        onClick={(e) => {
          setIsOpen(!isOpen);
          e.preventDefault();
        }}
        onBlur={() => onBlurHandler(input.onBlur)}
        onFocus={onFocusHandler}
        tabIndex="0"
      >
        <div
          className="fp-dropdown-header d-flex justify-content-between px-2"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="fp-dropdown-title d-flex">
            {((multi && selectedValue.length === 0) ||
              (!multi && !selectedValue)) && (
              <div className="fp-dropdown-placeholder">{placeholder}</div>
            )}
            {multi ? (
              <div className="fp-dropdown-multi">
                <>
                  {selectedValue.length > 0 && (
                    <Box
                      className={"text-truncate"}
                      sx={{
                        maxWidth: "110px",
                        border: "solid 1px #2185D0",
                        backgroundColor: "#EBF5FF",
                        padding: "0px 10px",
                        borderRadius: "99px",
                      }}
                    >
                      {selectedValue[0][labelKey]}
                    </Box>
                  )}

                  <p className="m-0 pl-1">
                    {selectedValue.length > 1 && `+${selectedValue.length - 1}`}
                  </p>
                </>
              </div>
            ) : (
              <p className="m-0">{selectedValue[labelKey]}</p>
            )}
          </div>
          <i
            className={`float-right fa fa-caret-${isOpen ? "up" : "down"} py-1`}
          />
        </div>
        {isOpen && (
          <ul className="fp-dropdown-list shadow mt-2">
            {searchFilter && (
              <div className="p-2 search" onClick={(e) => e.stopPropagation()}>
                <input
                  type="text"
                  placeholder="Search"
                  onChange={handleSearch}
                ></input>
              </div>
            )}
            {options.length === 0 && (
              <div className="p-2 text-center">No Results</div>
            )}
            {options.map((i) => {
              return (
                <li
                  key={i[valueKey]}
                  onClick={(e) => {
                    multi && e.stopPropagation();
                    handleChange(i);
                  }}
                  className={`${
                    checked(i[valueKey]) && "fp-dropdown-item-selected"
                  }`}
                >
                  {i[labelKey]}
                  {checked(i[valueKey]) && <i className="fa fa-check ml-2" />}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      {error && touched && <div className="fp-dropdown-error">{error}</div>}
    </div>
  );
};

export default Dropdown;
