import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import * as commonAction from "../common/commonAction";
import * as utils from "../../libs/utils";

export function getSocialProfileSuccess(data) {
  return { type: types.GET_SOCIAL_PROFILE_SUCCESS, data };
}

export function getSocialProfileFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_SOCIAL_PROFILE_FAILURE, data };
}

export const getSocialProfile = (params) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${URLS.SOCIAL_PROFILE_INTEGRATION}details/`, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getSocialProfileSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getSocialProfileFailure(err));
        throw err.response.data;
      });
  };
};

export const getSocialAuthUrl = (business_id,provider) => {
  return () => {
    return axios_instance.get(`${utils.format(URLS.ALL_MENTIONS_SOCIAL_CONNECTIONS,[business_id,provider])}auth_url/`)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw err.response.data;
      });
  };
};

const postAllMentionsSocialCodeSuccess = (data) => {
  return { type: types.ALL_MENTIONS_SOCIAL_CONNECT_SUCCESS, data };
};

export const postAllMentionsSocialCode = (data,business_id,provider) => {
  return dispatch => {
    return axios_instance.post(utils.format(URLS.ALL_MENTIONS_SOCIAL_CONNECTIONS,[business_id,provider]),data)
      .then(res => {
        dispatch(postAllMentionsSocialCodeSuccess(res.data,provider));
        return res;
      })
      .catch(err => {
        throw err.response.data;
      });
  };
};
