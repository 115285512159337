import React from "react";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FPButton } from "../common/new";
//import { GoogleLogin } from 'react-google-login';
import Logo from "../../images/fp-logo-sm.png";
class Home extends React.Component {
  responseGoogle() {
    //anything else you want to do(save to localStorage)...
  }

  render() {
    return (
      <>
        <div className="login-Card">
          <Box className="text-center d-flex flex-column justify-content-center">
            <Box
              sx={{
                width: "425px",
                marginBottom: "10px",marginTop:"20px" 
                
                // position: "absolute",
                // top: "30px",
                // left: "50px",
              }}
              component="img"
              src={Logo}
              alt="famepilot logo"
            />
            <Box
              sx={{ fontSize: "38px", fontWeight: 500, marginBottom: "20px", }}
            >
              Welcome to Famepilot
            </Box>
            <div style={{width:"100%"}}>

            <Link to="/login">
              <FPButton
                label="Login"
                style={{ marginBottom: "20px", width: "90%" }}
              />
            </Link>
            </div>
            <Box component="span" sx={{ color: "#1B1C1D", fontSize: "14px" }}>
              Don’t have an account?&nbsp;
            <span>
              <Link to="/register" className="signUpLink">
                Sign up
              </Link>
            </span>
            </Box>
          </Box>
        </div>

        {/* <div className="text-center">
        <img src={Logo} alt="famepilot logo" />
        <h1 className="heading-text mb-5 mt-4">Welcome to famepilot</h1>
        <Button animated color='teal' as="a" href="/login">
          <Button.Content visible>Login</Button.Content>
          <Button.Content hidden>
            <Icon name='arrow right' />
          </Button.Content>
        </Button>
        <p className="mt-3">Dont have an account? <a href="/register">Sign Up</a></p> */}

        {/* <GoogleLogin clientId="1069668251711-5g1pll0pvdusv35g352ujfjvekhpnppk.apps.googleusercontent.com"
          scope="profile"
          prompt="select_account"
          fetchBasicProfile={false}
          buttonText="Login"
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogle}/> //}
      </div> */}
      </>
    );
  }
}

export default Home;
