import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as commonAction from "../common/commonAction";
import * as utils from "../../libs/utils";

export function getLocationsSuccess(data) {
  return { type: types.GET_LOCATIONS_SUCCESS, data };
}

export function getLocationsFailure(data) {
  return { type: types.GET_LOCATIONS_FAILURE, data };
}

export const getLocations = (id, filters) => {
  const url = `${URLS.BUSINESS_SETUP}${id}/map/dashboard/`;
  return (dispatch) => {
    return axios_instance
      .get(url, { params: { ...filters } })
      .then((res) => {
        dispatch(getLocationsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(getLocationsFailure(err));
        throw err.response.data;
      });
  };
};

export function getDateFilterSuccess(data) {
  return { type: types.GET_DASHBOARD_FILTERS_SUCCESS, data };
}

export function getDateFilterFailure(data) {
  return { type: types.GET_DASHBOARD_FILTERS_FAILURE, data };
}

export const getDateFilter = (params) => {
  const url = `${URLS.BUSINESS_SETUP}dashboard-filter/${params.blank_req ? "?blank_req=false" : ""}`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getDateFilterSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getDateFilterFailure(err));
        throw err.response.data;
      });
  };
};

export function getStatsSuccess(data) {
  return { type: types.GET_STATS_SUCCESS, data };
}

export function getStatsFailure(data) {
  return { type: types.GET_STATS_FAILURE, data };
}

export const getStats = (id, filters) => {
  const url = `${URLS.BUSINESS_SETUP}${id}/dashboard/`;
  return (dispatch) => {
    return axios_instance
      .get(url, { params: { ...filters } })
      .then((res) => {
        dispatch(getStatsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(getStatsFailure(err));
        throw err.response.data;
      });
  };
};

export function getTrendsSuccess(data) {
  return { type: types.GET_TRENDS_SUCCESS, data };
}

export function getTrendsFailure(data) {
  return { type: types.GET_TRENDS_FAILURE, data };
}

export const getTrends = (id, filters) => {
  const url = `${URLS.BUSINESS_SETUP}dashboard/${id}/comparative_performance/`;
  return (dispatch) => {
    return axios_instance.get(url, { params: { ...filters } }).then(
      (res) => {
        dispatch(getTrendsSuccess(res.data));
        return res;
      },
      (err) => {
        dispatch(getTrendsFailure(err));
      }
    );
  };
};

// Action Creator Template
function createAsyncAction(success, failure) {
  function successAction(data) {
    return { type: success, data };
  }

  function failureAction(data) {
    return { type: failure, data };
  }

  return (id, filters) => {
    const url = `${URLS.BUSINESS_SETUP}${id}/dashboard/`;
    return (dispatch) => {
      return axios_instance
        .get(url, { params: { ...filters } })
        .then((res) => {
          dispatch(successAction(res.data));
          return res;
        })
        .catch((err) => {
          dispatch(failureAction(err));
          throw err.response.data;
        });
    };
  };
}

// Single Review Summary
export const getReviewSummary = createAsyncAction(types.GET_REVIEW_SUMMARY_SUCCESS, types.GET_REVIEW_SUMMARY_FAILURE);

// Single Net Promoter Score
export const getNPSSummary = createAsyncAction(types.GET_NPS_SUMMARY_SUCCESS, types.GET_NPS_SUMMARY_FAILURE);
