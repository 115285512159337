import { Box } from "@material-ui/core";
import FPLabel from "../../../components/common/new/forms/ui/FPLabel";
import { FormCheckbox, FormInput } from "../../../components/common/new/forms";
import FeedBackPhoneInput from "./FormContainer/FeedbackPhoneInput";
import FeedbackAutoComplete from "./FormContainer/FeedbackAutoComplete";

const UserDetailsForm = () => {
  const genderOPtions = [
    { display_name: "Male", value: "male" },
    { display_name: "Female", value: "female" },
    { display_name: "Other", value: "other" },
  ];
  return (
    <div className="d-flex flex-column gap-10">
      <div>
        <FPLabel isRequired={true}>Name</FPLabel>
        <Box
          component="div"
          className="form-group mb-0"
          sx={{
            "& .MuiInputBase-input": {
              border: "none !important",
              backgroundColor: "#F6F6F8 !important",
              borderRadius: "10px !important",
              padding: "11px 12px",
              fontSize: "13px !important",
            },
          }}
        >
          <FormInput
            name="user_name"
            placeholder="Enter Name"
            type="text"
            wrapperClass="mb-0"
          />
        </Box>
      </div>
      <div>
        <FPLabel isRequired={true}>Email Address</FPLabel>
        <Box
          component="div"
          className="form-group mb-0"
          sx={{
            "& .MuiInputBase-input": {
              border: "none !important",
              backgroundColor: "#F6F6F8 !important",
              borderRadius: "10px !important",
              padding: "11px 12px",
              fontSize: "13px !important",
            },
          }}
        >
          <FormInput
            name="user_email"
            placeholder="Enter Email"
            type="email"
            wrapperClass="mb-0"
          />
        </Box>
      </div>
      <div>
        <FPLabel isRequired={true}>Mobile Number</FPLabel>
        <FeedBackPhoneInput
          name="user_number"
          placeholder="Enter Phone Number"
          required
        />
      </div>
      <div>
        <FPLabel isRequired={true}>Gender</FPLabel>
        <FeedbackAutoComplete
          options={genderOPtions}
          placeholder="Select Gender"
          name="user_gender"
          sx={{
            "& .MuiOutlinedInput-input": {
              fontSize: "13px !important",
            },
            "& .MuiInputBase-root": {
              paddingTop: "7px !important",
              paddingBottom: "7px !important",
            },
          }}
        />
      </div>
      <div>
        <FormCheckbox
          name="user_terms"
          value={true}
          mainDivClassName={"mb-0"}
          label={
            <div style={{ marginLeft: "5px" }}>
              By Checking this box, you agree to the brand T&Cs and agree to
              receive marketing communications from the brand.
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default UserDetailsForm;
