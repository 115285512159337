import React, { useEffect, useState } from "react";
import { ListItem, ListContent, ListDescription } from "semantic-ui-react";
import { Progress } from "reactstrap";
import CustomStarRatingComponent from "../../common/StarComponent";
import "./listing.css";
import verified_svg from "../../../images/verified.svg";
import duplicate_svg from "../../../images/dusplicate.svg";
import notification_png from "../../../images/notification.png";
import { formatReviews, truncatedNumber } from "../../../libs/helper";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import WebsiteTable from "./WebsiteTable";
import CompletionStatus from "./CompletionStatus";
import OpenStatus from "./OpenStatus";
import PhoneNumberList from "./PhoneNumberList";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import NotificationList from "./NotificationList";
import { FPToolTip } from "../../common/new";
import FormModal from "../../common/FormModal";

const ListingTable = ({ listing, business_id, history }) => {
  const addressString = [listing?.addressLines, listing?.locality, listing?.postalCode].join(", ");

  const [statusColor, setStatusColor] = useState("");
  const [statusTextColor, setStatusTextColor] = useState("");
  const [statusText, setStatusText] = useState("");

  useEffect(() => {
    if (listing?.overall_progress <= 20) {
      setStatusColor("very-low-color");
      setStatusTextColor("#ff4545");
      setStatusText("Very Low");
    } else if (listing?.overall_progress <= 40) {
      setStatusColor("low-color");
      setStatusTextColor("#ffa534");
      setStatusText("Low");
    } else if (listing?.overall_progress <= 60) {
      setStatusColor("moderate-color");
      setStatusTextColor("#ffe234");
      setStatusText("Moderate");
    } else if (listing?.overall_progress <= 80) {
      setStatusColor("high-color");
      setStatusTextColor("#b7dd29");
      setStatusText("High");
    } else {
      setStatusColor("very-high-color");
      setStatusTextColor("#57e32c");
      setStatusText("Very High");
    }
  }, [listing?.overall_progress]);

  const handleViewDetailsClick = (selectedRestaurant) => {
    history.push(`/${business_id}/primary-listing-detail`, {
      restoId: selectedRestaurant?.id,
    });
  };

  return (
    <div className="listing-container" style={{ overflow: "hidden", gap: "15px" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <div className="listing-header">
          <div className="listing-info">
            <div style={{ display: "flex", gap: "8px" }}>
              <div className="listing-name listing-title-tooltip">
                {listing.title?.slice(0, 30)}
                {listing.title?.length > 30 ? "..." : ""}
                {listing.title?.length > 30 ? <span class="listing-title-tooltiptext">{listing.title}</span> : ""}
              </div>
              <img
                src={
                  listing?.is_varified
                    ? verified_svg
                    : listing?.is_suspend
                    ? duplicate_svg
                    : listing?.is_duplicate
                    ? duplicate_svg
                    : ""
                  // listing?.recommendationReason === "" && listing?.duplicateLocation === "False"
                  //   // || listing?.duplicateLocation === "False"
                  //   ? verified_svg
                  //   : (listing?.recommendationReason ===
                  //     "BUSINESS_LOCATION_SUSPENDED" || listing?.duplicateLocation === "True" || listing?.duplicateLocation?.includes("location")
                  //     ? duplicate_svg
                  //     : duplicate_svg
                  //   )
                }
                alt="verifiedImage"
              />
            </div>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <div className="rating-container">
                <CustomStarRatingComponent
                  name="rate"
                  starCount={5}
                  starColor="#FBBD08"
                  emptyStarColor="white"
                  value={listing.averageRating}
                  editing={false}
                />
              </div>
              <span>{listing.averageRating?.toFixed(1)}</span>
              <div
                style={{
                  height: "5px",
                  width: "5px",
                  backgroundColor: "#D9D9D9",
                  borderRadius: "50%",
                }}
              ></div>
              <span>{formatReviews(listing.totalReviewCount)} Reviews</span>
            </div>
          </div>
          <div className="view-listing-button-notification">
            {
              listing.is_googele_update && (
                // <Popup
                //   trigger={
                <>
                  <FPToolTip
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#00B5AD",
                    }}
                    title={"Google Updates Availables"}
                    className="templateName"
                  >
                    <div style={{ position: "relative", display: "inline-block" }}>
                      <img style={{ height: "20px", width: "auto" }} src={notification_png} alt="Notification" />
                      <div
                        style={{
                          position: "absolute",
                          top: "1px",
                          right: "3px",
                          height: "10px",
                          width: "10px",
                          backgroundColor: "red",
                          borderRadius: "50%",
                          border: "1px solid white",
                        }}
                      ></div>
                    </div>
                  </FPToolTip>
                </>
              )
              //   }
              //   position="bottom center"
              //   on="hover"
              //   contentStyle={{ width: "max-content" }}
              // >
              //   <div>
              //     <NotificationList business_id={business_id} selectReso={listing.id} />
              //   </div>
              // </Popup>
            }

            <FPButton
              label="View Listing"
              size="small"
              style={{ height: "30px" }}
              onClick={() => handleViewDetailsClick(listing)}
            />
          </div>
        </div>
        <div className="review-address">
          <ListDescription>{addressString}</ListDescription>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <div className="categories-container">
          <ListItem>
            <ListContent>
              <div>Categories</div>
            </ListContent>
          </ListItem>
          <ListItem>
            <ListContent>
              <div className="category-item">
                <div>{listing?.category_name?.primary}</div>
              </div>
            </ListContent>
          </ListItem>
          <ListItem>
            {listing?.category_name?.additional_count > 0 ? (
              <ListContent>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#646565",
                  }}
                >
                  +{listing?.category_name?.additional_count} more categories
                </div>
              </ListContent>
            ) : (
              ""
            )}
          </ListItem>
        </div>
        <div
          style={{
            borderRadius: "8px",
          }}
        >
          <div className="status-grid">
            <div className="status-label">GMB Status</div>
            <div className="status-label">Phone Number</div>
            <div className="status-label">Website Link</div>
            <div className="status-label">Open Status</div>
            <div className="status-label">Completion Status</div>

            <div className="status-value">
              {listing?.is_varified
                ? "Verified"
                : listing?.is_suspend
                ? "Suspended"
                : listing?.is_duplicate
                ? "Duplicate"
                : ""}
              {/* {listing?.recommendationReason === ""
                ? "Verified"
                : listing?.recommendationReason ===
                  "BUSINESS_LOCATION_SUSPENDED"
                  ? "Suspended"
                  : "Duplicate"} */}

              <img
                src={
                  listing?.is_varified
                    ? verified_svg
                    : listing?.is_suspend
                    ? duplicate_svg
                    : listing?.is_duplicate
                    ? duplicate_svg
                    : ""

                  // listing?.recommendationReason === "" && listing?.duplicateLocation === "False"
                  //   // || listing?.duplicateLocation === "False"
                  //   ? verified_svg
                  //   : (listing?.recommendationReason ===
                  //     "BUSINESS_LOCATION_SUSPENDED"
                  //     ? duplicate_svg
                  //     : (listing?.duplicateLocation === "True" || listing?.duplicateLocation?.includes("location") || listing?.ResolveOwnershipConflict === true ? duplicate_svg : "")
                  //   )
                }
                alt=""
              />
            </div>
            <div className="status-value">
              <Popup
                trigger={<div className="cursor-pointer">{listing.phoneNumbers?.primaryPhone}</div>}
                position="bottom center"
                contentStyle={{ width: "260px" }}
                className="bs-fp-popup"
                arrow={false}
              >
                <div>
                  {listing?.phoneNumbers ? (
                    <PhoneNumberList phoneNumbers={listing?.phoneNumbers} />
                  ) : (
                    <PhoneNumberList />
                  )}
                </div>
              </Popup>
            </div>

            <div className="status-value">
              <Popup
                trigger={
                  // <div>
                  //   {listing.websiteUri?.slice(0, 30)}
                  //   {listing.websiteUri?.length > 30 ? "..." : ""}
                  // </div>
                  <div className="cursor-pointer">
                    {listing.websiteUri && listing.websiteUri.length > 0
                      ? listing.websiteUri.length > 30
                        ? `${listing.websiteUri.slice(0, 30)}...`
                        : listing.websiteUri
                      : "---"}
                  </div>
                }
                position="bottom center"
                contentStyle={{ width: "max-content" }}
                className="bs-fp-popup"
                arrow={false}
              >
                <div>
                  <WebsiteTable websiteUri={listing} />
                </div>
              </Popup>
            </div>
            <div className="status-value">
              <Popup
                trigger={
                  <div
                    style={{
                      color:
                        listing.openInfo_status === "OPEN"
                          ? "#57e32c"
                          : listing.openInfo_status === "CLOSED_TEMPORARILY"
                          ? "#ff4545"
                          : "#ff4545",
                    }}
                    className="cursor-pointer"
                  >
                    {listing.openInfo_status === "OPEN"
                      ? "Open"
                      : listing.openInfo_status === "CLOSED_TEMPORARILY"
                      ? "Closed Temporarily"
                      : "Closed Permanently"}
                  </div>
                }
                position="bottom center"
                contentStyle={{ width: "auto" }}
                className="bs-fp-popup"
                arrow={false}
              >
                <div>
                  <OpenStatus regularHours={listing?.regularHours} />
                </div>
              </Popup>
            </div>
            <div className="status-value">
              <Popup
                trigger={
                  <div className="block px-2 cursor-pointer">
                    <div className="d-flex align-center gap-10">
                      <div>
                        {truncatedNumber(listing?.overall_progress) ? truncatedNumber(listing?.overall_progress) : 0}%
                      </div>
                      <span style={{ color: statusTextColor }}>{statusText}</span>
                    </div>
                    <div>
                      <Progress
                        style={{ height: "8px", width: "100px" }}
                        barClassName={statusColor}
                        value={truncatedNumber(listing?.overall_progress)}
                      />
                    </div>
                  </div>
                }
                position="left center"
                className="bs-fp-popup mr-2"
                // on="hover"
                contentStyle={{ width: "fit-content" }}
                arrow={false}
              >
                <div style={{ display: "grid", gap: "18px", padding: "12px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "18px",
                      borderBottom: "1px solid #BFC2CB",
                      paddingBottom: "12px",
                    }}
                  >
                    <div
                      style={{
                        color: "#646565",
                        display: "flex",
                        fontSize: "18px",
                      }}
                    >
                      Completion Status
                    </div>
                    <div
                      style={{
                        color: statusTextColor,
                        display: "flex",
                        fontSize: "18px",
                      }}
                    >
                      {truncatedNumber(listing?.overall_progress) ? truncatedNumber(listing?.overall_progress) : 0}%
                    </div>
                    <div
                      className={statusColor}
                      style={{
                        padding: "10px",
                        borderColor: "#989FAD",
                        paddingInline: "10px",
                        color: "white",
                        borderRadius: "20px",
                        height: "25px",
                        fontSize: "14px",
                        fontWeight: "400",
                        display: "flex",
                        alignItems: "center",
                        flexShrink: "0",
                      }}
                    >
                      {statusText}
                    </div>
                  </div>
                  <CompletionStatus
                    completionStatus={listing?.completion_score}
                    canModifyServiceList={listing?.canModifyServiceList}
                    canHaveFoodMenus={listing?.canHaveFoodMenus}
                  />
                </div>
              </Popup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingTable;
