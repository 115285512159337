import React, { useState } from "react";
import google_svg from "../../../../../images/googlephoto.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getGmbListingUpdateListing } from "../../../../../actions/listing/listingLocationAction";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import * as listingUtils from "../../../../../libs/listingToast";
import BusinessList from "../BusinessList";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";

const UpdateBusinessStatus = ({
  primaryDetail,
  business_id,
  selectReso,
  getGmbListingUpdateListing,
  actions,
  queryStringData,
  busines_status,
  bulk_update_listing_data
}) => {
  const [businessStatus, setBusinessStatus] = useState("OPEN");

  React.useEffect(() => {
    if (primaryDetail?.openInfo_status) {
      setBusinessStatus(primaryDetail?.openInfo_status);
    } else {
      setBusinessStatus("OPEN");
    }
  }, [primaryDetail?.openInfo_status]);

  const updateBusinessHandler = (e) => {
    const { value } = e.target;
    setBusinessStatus(value);
  };
  const handleSaveClick = async () => {
    const data = {
      type: "openInfo",
      location_ids: selectedBusinessCount,
      openInfoStatus: businessStatus,
    };
    const restodetail = {
      business_id,
      selectReso,
    };
    listingUtils?.displayMessage(
      "info",
      "Listing getting updated, It may take a few minutes."
    );
    await actions.getGmbListingBulkUpdateListing(data, restodetail);
    listingUtils?.displayMessage("close");
    if (bulk_update_listing_data) {
      listingUtils?.displayMessage("positive", "updated Successfully.");
    }
    // listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
    // await actions.getGmbListingRefreshListing(business_id, selectReso);

    // listingUtils?.displayMessage("close",); // Close the "refresh process" toast

    // listingUtils?.displayMessage("positive", "Listing updated successfully.");
    // await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };

  const [selectedBusinessList, setSelectedBusinessList] = useState([]);
  const [showBusiness, setShowBusiness] = useState(true);
  const handleHideBusinessScreen = () => {
    setShowBusiness(false);
  };

  const handleShowBusinessScreen = () => {
    setShowBusiness(true);
  };
  const [selectedBusinessCount, setSelectedBusinessCount] = useState([]); // State to hold the selected businesses count
  console.log("==================================", selectedBusinessCount);

  const handleSelectedBusinessCount = (selectBusiness) => {
    setSelectedBusinessCount(selectBusiness);
  };

  return (
    <>
      {showBusiness ? (
        <div>
          <BusinessList
            type=""
            business_id={business_id}
            busines_status={busines_status}
            queryStringData={queryStringData}
            selectedBusinessList={selectedBusinessList}
            onSelectedBusinessCountChange={handleSelectedBusinessCount}
          />
          {selectedBusinessCount?.length > 0 ? (
            <div
              className="d-flex mt-10 mb-15"
              style={{
                display: "flex",
                justifyContent: "end",
                fontWeight: "600",
                fontSize: "24px",
                color: "#1A2544",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "20px",
              }}
            >
              <FPButton label="Next" onClick={handleHideBusinessScreen} />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-9">
            <div
              className="listing-container"
              style={{ display: "grid", gap: "24px" }}
            >
              <div className="listing-name">Specify your Business Status</div>
              <div
                style={{
                  padding: "16px",
                  backgroundColor: "#F6F6F8",
                  borderRadius: "8px",
                }}
              >
                <form action="/action_page.php">
                  <div style={{ display: "grid", gap: "15px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#646565",
                      }}
                    >
                      <label
                        for="OPEN"
                        style={{ cursor: "pointer", width: "100%" }}
                      >
                        Open with Main Hours
                      </label>
                      <input
                        style={{ color: "#F13A88" }}
                        type="radio"
                        id="OPEN"
                        name="bussinessStatus"
                        value="OPEN"
                        checked={businessStatus === "OPEN"}
                        onChange={updateBusinessHandler}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#646565",
                      }}
                    >
                      <label
                        for="CLOSED_TEMPORARILY"
                        style={{ cursor: "pointer", width: "100%" }}
                      >
                        Temporarily Closed
                      </label>
                      <input
                        style={{ accentColor: "#F13A88" }}
                        type="radio"
                        id="CLOSED_TEMPORARILY"
                        name="bussinessStatus"
                        value="CLOSED_TEMPORARILY"
                        checked={businessStatus === "CLOSED_TEMPORARILY"}
                        onChange={updateBusinessHandler}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#646565",
                      }}
                    >
                      <label
                        for="CLOSED_PERMANENTLY"
                        style={{ cursor: "pointer", width: "100%" }}
                      >
                        Permanently Closed
                      </label>
                      <input
                        style={{ color: "#F13A88" }}
                        type="radio"
                        id="CLOSED_PERMANENTLY"
                        name="bussinessStatus"
                        value="CLOSED_PERMANENTLY"
                        checked={businessStatus === "CLOSED_PERMANENTLY"}
                        onChange={updateBusinessHandler}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  // paddingBottom: "10px",
                  // paddingTop: "10px",
                  paddingBlock: "20px",
                }}
              >
                <div>
                  <FPButton
                    label="Back"
                    size="small"
                    onClick={handleShowBusinessScreen}
                  />
                </div>
                <div>
                  <FPButton
                    onClick={handleSaveClick}
                    label="Update"
                    size="small"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <img src={google_svg} alt="verifiedImage" width={"100%"} />
          </div>
        </div>
      )}
    </>
  );
};

// export default UpdateBusinessStatus;
const mapDispatchToProps = (dispatch) => ({
  // getGmbListingUpdateListing: bindActionCreators(
  //     getGmbListingUpdateListing,
  //     dispatch
  // ),
  actions: bindActionCreators(locationActions, dispatch),
});

const mapStateToProps = (state) => {
    return {
      bulk_update_listing_data: state.gmbListingLocation.bulk_update_listing_data,
    };
  };

// const mapDispatchToProps = (dispatch) => ({
//   getGmbListingUpdateListing: bindActionCreators(
//     getGmbListingUpdateListing,
//     dispatch
//   ),
//   actions: bindActionCreators(locationActions, dispatch),
// });

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBusinessStatus);
