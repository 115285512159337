import React from "react";
import { Checkbox } from "semantic-ui-react";


const CheckboxWrapper = ({ input, label, ...rest }) => (
  <Checkbox
    {...rest}
    label={label}
    checked={!!input.value} 
    onChange={(e, { checked }) => input.onChange(checked)}
  />
);

export default CheckboxWrapper;