import React, { useEffect, useState, Fragment } from "react";
import { Card, Divider } from "semantic-ui-react";
import { Line } from "react-chartjs-2";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FunnelChart from "../../components/restro-pilot/FunnelChart";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoPrintOutline } from "react-icons/io5";
import RestroFilter from "../../components/restro-pilot/RestroFilter";
import { Popover } from "@material-ui/core";
import cookie from "react-cookies";
import LoaderComponent from "../../components/common/loaderComponent";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import { Table } from "antd";
import { FPToolTip } from "../../components/common/new";
import CustomerFunnelFilter from "./CustomerFunnelFilter";
import { ReactComponent as DropdownIcon } from "../../images/svg/dropdownUpIcon.svg";
import AutoSelect from "../../components/common/AutoSelect";
import LocationLineChart from "./LocationLineChart";

const RestroPilotPage = (props) => {
  const search = props.location.search;
  // const printEl = useRef(null);

  const {
    match: {
      params: { business_id },
    },
    history,
    location: { pathname },
  } = props;
  const [mainData, setMainData] = useState(null);
  const [impressSet, setImpressSet] = useState({});
  const [impressMenu, setImpressMenu] = useState({});
  const [menuCart, setMenuCart] = useState({});
  const [cartOrder, setCartOrder] = useState({});
  const [view, setView] = useState("Graph");
  const [activeSection, setActiveSection] = useState("overtime");
  const [lineChartLabels, setLineChartLabels] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [tableDataOvertime, setTableDataOvertime] = useState([]);
  const [tableDataLocation, setTableDataLocation] = useState([]);
  const [selectedOrdering, setSelectedOrdering] = useState("");

  useEffect(() => {

    // const date_val = {
    //   date_type: "prior_30_day",
    //   type:"daily"
    // };
    // history.push({ pathname, search: queryString.stringify(date_val) });

    let querKey = Object.keys(queryString.parse(search));

    if (querKey.includes("type")) {
      setSelectedOrdering(queryString.parse(search).type);
    } else {
      setSelectedOrdering(null);
    }

  }, []); //eslint-disable-line

  useEffect(() => {
    setLoader(true);
    axios_instance
      .get(`${utils.format(URLS.RESTRO_PILOT, [business_id])}${search}`)
      .then((res) => {
        setMainData(res.data);
        setImpressMenu({
          label: "Impressions To Menu",
          data: Object.keys(res.data.impressions_to_menu).map((key) => res.data.impressions_to_menu[key]),
          fill: false,
          borderColor: "#FB7B5E",
          tension: 0,
          borderWidth: 2,
          backgroundColor: "#FB7B5E",
        });
        setImpressSet({
          label: "Impresssions ",
          data: Object.keys(res.data.impressions).map((val) => res.data.impressions[val]),
          fill: false,
          borderColor: "#FBBD66",
          tension: 0,
          backgroundColor: "#FBBD66",
          borderWidth: 2,
        });
        setMenuCart({
          label: "Menu To Cart ",
          data: Object.keys(res.data.menu_to_cart).map((val) => res.data.menu_to_cart[val]),
          fill: false,
          borderColor: "#E4A7E0",
          backgroundColor: "#E4A7E0",
          tension: 0,
          borderWidth: 2,
        });
        setCartOrder({
          label: "Cart To Order ",
          data: Object.keys(res.data.cart_to_order).map((val) => res.data.cart_to_order[val]),
          fill: false,
          borderColor: "#82B8E4",
          tension: 0,
          backgroundColor: "#82B8E4",
          borderWidth: 2,
        });

        const overtime_table_data = [];
        Object.keys(res.data.impressions_to_menu).map((key, index) => {
          overtime_table_data.push({
            date: key,
            impressions: res.data.impressions[key],
            impressions_to_menu: res.data.impressions_to_menu[key],
            menu_to_cart: res.data.menu_to_cart[key],
            cart_to_order: res.data.cart_to_order[key],
          });
          return overtime_table_data;
        });
        setTableDataOvertime(overtime_table_data);

        const location_table_data = [];
        res.data?.locations_metrics &&
          Array.isArray(res.data?.locations_metrics) &&
          res.data?.locations_metrics.map((val) => {
            location_table_data.push(val);
            return val;
          });
        setTableDataLocation(location_table_data);
        const label = [];
        Object.keys(res.data.impressions).map((val) => {
          label.push(val);
          return label;
        });
        setLineChartLabels(label);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, [business_id, search]);

  useEffect(() => {
    document.title = "Market Place";
    props.actions.setSelectedTab(3);
  }, []); //eslint-disable-line

  useEffect(() => {
    axios_instance
      .get(`${URLS.REVIEW_SOURCE}?business_id=${business_id}`)
      .then((res) => {
        const test = [];
        res.data && res.data.choices.map((data) => test.push({ value: data.value, display_name: data.display_name }));
        setSourceOptions(test);
      })
      .catch((err) => {
        console.log(err);
      });

    axios_instance
      .get(utils.format(`${URLS.BUSINESS_SETUP}{}/branch/`, [business_id]))
      .then((res) => {
        const test = [];
        res.data && res.data.choices.map((data) => test.push({ value: data.value, display_name: data.display_name }));
        setLocationOptions(test);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [business_id]);

  const getColumnsOvertime = () => {
    const baseColumns = [
      { title: "Date", dataIndex: "date", align: "center", justify: "center" },
      {
        title: "Impressions",
        dataIndex: "impressions",
        sorter: { compare: (a, b) => b["impressions"] - a["impressions"], multiple: 3 },
        align: "center",
        justify: "center",
      },
      {
        title: "Impressions to Menu",
        dataIndex: "impressions_to_menu",
        sorter: { compare: (a, b) => b["impressions_to_menu"] - a["impressions_to_menu"], multiple: 3 },
        align: "center",
        justify: "center",
      },
      {
        title: "Menu to Cart",
        dataIndex: "menu_to_cart",
        sorter: { compare: (a, b) => b["menu_to_cart"] - a["menu_to_cart"], multiple: 3 },
        align: "center",
        justify: "center",
      },
      {
        title: "Cart to Order",
        dataIndex: "cart_to_order",
        sorter: { compare: (a, b) => b["cart_to_order"] - a["cart_to_order"], multiple: 3 },
        align: "center",
        justify: "center",
      },
    ];
    return baseColumns;
  };

  const getColumnsLocations = () => {
    const baseColumns = [
      { title: "Locations", dataIndex: "branch__alias", align: "center", justify: "center" },
      {
        title: "Impressions",
        dataIndex: "total_impressions_count",
        sorter: { compare: (a, b) => b["total_impressions_count"] - a["total_impressions_count"], multiple: 3 },
        align: "center",
        justify: "center",
      },
      {
        title: "Impressions to Menu",
        dataIndex: "total_impressions_to_menu_count",
        sorter: {
          compare: (a, b) => b["total_impressions_to_menu_count"] - a["total_impressions_to_menu_count"],
          multiple: 3,
        },
        align: "center",
        justify: "center",
      },
      {
        title: "Menu to Cart",
        dataIndex: "total_menu_to_cart_count",
        sorter: { compare: (a, b) => b["total_menu_to_cart_count"] - a["total_menu_to_cart_count"], multiple: 3 },
        align: "center",
        justify: "center",
      },
      {
        title: "Cart to Order",
        dataIndex: "total_cart_to_order_count",
        sorter: { compare: (a, b) => b["total_cart_to_order_count"] - a["total_cart_to_order_count"], multiple: 3 },
        align: "center",
        justify: "center",
      },
    ];
    return baseColumns;
  };

  const handleDownload = (download_tab) => {
    const is_overtime_download = download_tab === "overtime" ? true : false;
    const is_location_download = download_tab === "location" ? true : false;
    const url = `${utils.format(URLS.RESTRO_PILOT, [business_id])}${
      search ? search + "&" : "?"
    }is_download=${true}&is_overtime_download=${is_overtime_download}&is_location_download=${is_location_download}`;
    const token = cookie.load("authToken");
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/csv",
      },
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        let winUrl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = winUrl;
        a.download = "market-place-report.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        type: "linear",
        position: "left",
        grid: {
          borderDash: [7],
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          fontSize: 16,
          fontStyle: "normal",
          fontFamily: "Outfit",
          boxWidth: 6,
          boxHeight: 6,
          padding: 10,
          usePointStyle: true,
        },
      },
    },
    animation: {
      duration: 2000,
    },
    maintainAspectRatio: false,
  };

  const printLineChart = () => {
    window.print();
  };

  const onChange = (filters, sorter, extra) => {
    console.log("params", filters, sorter, extra);
  };
  const sortOptions = [
    {
      display_name: "Daily",
      value: "daily",
    },
    {
      display_name: "Weekly",
      value: "weekly",
    },
    {
      display_name: "Monthly",
      value: "monthly",
    },
  ];

  const updateOrdering = (sortOrder) => {
    setSelectedOrdering(sortOrder);

    let filters = { ...queryString.parse(search), type: sortOrder };

    history.push({ pathname, search: queryString.stringify(filters) });
  };

  const cardStyle = {
    padding: "22px",
    borderRadius: "8px",
    backgroundColor: "white",
    position: "relative",
    cursor: "pointer",
    height: "auto",
    minWidth: "auto",
    borderBottom: "2px solid #f13a88",
  };
  return (
    <>
    <LoaderComponent loader={loader} />
    {mainData && (
      <div style={{ padding: "16px" }}>
      
        <CustomerFunnelFilter 
          history={props?.history} 
          location={props?.location} 
          match={props?.match} 
          setLoader={(val) => setLoader(val)}
        />

        {isFilter && (
          <div>
            <Popover
              open={isFilter}
              anchorEl={anchorEl}
              onClose={() => {
                setIsFilter(!isFilter);
                setAnchorEl(null);
              }}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                style: { borderRadius: 10 },
              }}
              className="Report-Filter-Popup"
            >
              <RestroFilter
                history={props?.history}
                location={props?.location}
                onApply={() => {
                  setIsFilter(false);
                  setAnchorEl(null);
                }}
                branchList={locationOptions}
                reviewSourceOptions={sourceOptions}
                businessId={business_id}
                match={props.match}
                showLocationFilter={true}
                showSourceFilter={true}
              />
            </Popover>
          </div>
        )}
        <div className="my-4" style={{ display: "grid", gap: "20px", gridTemplateColumns: "1fr 1fr" }}>
          <Card style={{ width: "100%" }}>
            <div style={{ padding: "10px", overflowX: "auto" }}>
              <FunnelChart mainData={mainData} />
            </div>
          </Card>
          <div
      style={{
        display: "grid",
        gap: "15px",
        marginBottom: "10px",
        gridTemplateColumns: "1fr 1fr",
      }}
    >
      {/* First Column */}
      <div style={{ display: "grid", gap: "15px" }}>
        <div style={{...cardStyle, borderBottom: "2px solid #FBBD66" }}>
          <div style={{ fontSize: "18px", fontWeight: 500 }}>Total Impressions</div>
          <div
            style={{ fontSize: "42px", marginTop: "15px", fontWeight: 600 }}
          >
            {mainData?.total_impressions || 0}
          </div>
        </div>
        <div style={{...cardStyle, borderBottom: "2px solid #9467BC" }}>
          <div style={{ fontSize: "18px", fontWeight: 500 }}>Total Menu To Orders</div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              fontSize: "36px",
              fontWeight: 600,
              gap: "10px",
              marginTop: "15px"
            }}
          >
            {mainData?.total_menu_to_order || 0}
            <span style={{ fontSize: "16px", marginTop: "5px", fontWeight: 400 }}>
              ({mainData?.total_menu_to_order_pct}%)
            </span>
          </div>
        </div>
        
      </div>

      {/* Second Column */}
      <div style={{ display: "grid", gap: "15px" }}>
      <div style={{...cardStyle, borderBottom: "2px solid #FB7B5E" }}>
          <div style={{ fontSize: "18px", fontWeight: 500 }}>Total Impressions to Menu</div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              fontSize: "36px",
              marginTop: "15px",
              fontWeight: 600,
              gap: "10px",
            }}
          >
            {mainData?.total_impressions_to_menu || 0}
            <span style={{ fontSize: "16px", marginTop: "5px", fontWeight: 400 }}>
              ({mainData?.total_impressions_to_menu_pct}%)
            </span>
          </div>
        </div>
        <div style={{...cardStyle, borderBottom: "2px solid #E4A7E0" }}>
          <div style={{ fontSize: "18px", fontWeight: 500 }}>Total Menu to Cart</div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              fontSize: "36px",
              fontWeight: 600,
              gap: "10px",
              marginTop: "15px"
            }}
          >
            {mainData?.total_menu_to_cart || 0}
            <span style={{ fontSize: "16px", marginTop: "5px", fontWeight: 400 }}>
              ({mainData?.total_menu_to_cart_pct}%)
            </span>
          </div>
        </div>
        <div style={{...cardStyle, borderBottom: "2px solid #82B8E4" }}>
          <div style={{ fontSize: "18px", fontWeight: 500 }}>Total Cart to Order</div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              fontSize: "36px",
              fontWeight: 600,
              gap: "10px",
              marginTop: "15px"
            }}
          >
            {mainData?.total_cart_to_order || 0}
            <span style={{ fontSize: "16px", marginTop: "5px", fontWeight: 400 }}>
              ({mainData?.total_cart_to_order_pct}%)
            </span>
          </div>
        </div>

      </div>
    </div>
        </div>
        <Card style={{ width: "100%" }} className="p-3">
          <div className="report-ThirdRow mb-4 d-flex justify-content-between align-items-center">
            <Nav tabs className="py-1 box-shadow-none" style={{ borderBottom: "none" }}>
              <Fragment>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeSection === "overtime" })}
                    onClick={() => setActiveSection("overtime")}
                  >
                    Overtime
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeSection === "location" })}
                    onClick={() => setActiveSection("location")}
                  >
                    Location
                  </NavLink>
                </NavItem>
              </Fragment>
            </Nav>
            <div>
                <AutoSelect
                  options={sortOptions}
                  onChange={(event, option) => {
                    updateOrdering(option);
                  }}
                  value={selectedOrdering}
                  className="sortingBox"
                  sx={{
                    width: 100,
                    "& fieldset": { borderRadius: 33 },
                    "& .MuiInputBase-input": {
                      height: 10,
                      minWidth: "70px !important",
                      fontSize: "12px",
                      color: "#646565 !important",
                      "&::placeholder": {
                        color: "#646565",
                      },
                    },
                  }}
                  disableClearable
                  popupIcon={<DropdownIcon className="dropDownICon" />}
                />
              </div>
          </div>


            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "gray" }}>
                <FormControlLabel
                  value="Graph"
                  label="Graph"
                  control={<Radio />}
                  onClick={() => setView("Graph")}
                  checked={view === "Graph"}
                />
                <FormControlLabel
                  value="Table"
                  label="Table"
                  control={<Radio />}
                  onClick={() => setView("Table")}
                  checked={view === "Table"}
                />
              </div>
              <div style={{ display: "flex", gap: "20px" }}>
                <FPToolTip title={"Download"} placement={"top"}>
                  <MdOutlineFileDownload
                    style={{ fontSize: "20px", cursor: "pointer" }}
                    onClick={() => handleDownload("overtime")}
                  />
                </FPToolTip>
                <FPToolTip title={"Print"} placement={"top"}>
                  <IoPrintOutline onClick={() => printLineChart()} style={{ fontSize: "18px", cursor: "pointer" }} />
                </FPToolTip>
              </div>
            </div>

          {activeSection === "overtime" && view === "Graph" && (
            <div className="mx-4">
              <div id="restro-print" style={{ height: "500px", overflowY: "auto" }}>
                <Line
                  datasetIdKey="id"
                  data={{ datasets: [impressSet, impressMenu, menuCart, cartOrder], labels: lineChartLabels }}
                  options={options}
                />
              </div>
            </div>
          )}
          {activeSection === "overtime" && view === "Table" && (
            <div className="mx-4">
              <div id="restro-print" style={{ height: "500px", overflowY: "auto" }}>
                <Table
                  columns={getColumnsOvertime()}
                  dataSource={tableDataOvertime}
                  onChange={onChange}
                  pagination={true}
                  showSorterTooltip={false}
                />
              </div>
            </div>
          )}

          {activeSection === "location" && view === "Graph"  && (
            <div className="mx-4">

              <div id="restro-print" style={{ height: "500px", overflowY: "auto" }}>
              <LocationLineChart tableDataLocation={tableDataLocation}/>
              </div>
            </div>
          )}
          {activeSection === "location" && view === "Table" && (
            <div className="mx-4">

              <div id="restro-print" style={{ height: "500px", overflowY: "auto" }}>
                <Table
                  columns={getColumnsLocations()}
                  dataSource={tableDataLocation}
                  onChange={onChange}
                  pagination={true}
                  showSorterTooltip={false}
                />
              </div>
            </div>
          )}
        </Card>
      </div>
    )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(setSelectedTab, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
  };
};

RestroPilotPage.propTypes = {
  loader: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RestroPilotPage);
