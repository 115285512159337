
import * as types from '../../actions/actionTypes';
export default function ticketDetails(state = [], action) {
  switch (action.type) {
    case types.TICKET_DETAILS_SUCCESS:
      return Object.assign({}, state, { tickets: { ...action.data } });
    case types.TICKET_DETAILS_FAILURE:
      return Object.assign({}, state, { errors: { ...state.errors, tickets: { ...action.data } }});
    case types.CREATE_TICKET_SUCCESS:
      return Object.assign({}, state, { new_ticket: { ...action.data }});
    case types.CREATE_TICKET_FAILURE:
      return Object.assign({}, state, { errors: { ...state.errors, new_ticket: { ...action.data } }});
    case types.TICKET_STATUS_OPTIONS_SUCCESS:
      return Object.assign({}, state, { status_options: { ...action.data } });
    case types.TICKET_STATUS_OPTIONS_FAILURE:
      return Object.assign({}, state, { errors: { ...state.errors, status_options: { ...action.data } }});
    case types.GET_TICKET_SOURCE_OPTIONS_SUCCESS:
      return Object.assign({}, state, { source_options: { ...action.data } });
    case types.GET_TICKET_SOURCE_OPTIONS_FAILURE:
      return Object.assign({}, state, { errors: { ...state.errors, source_options: { ...action.data } }});
    case types.SINGLE_TICKET_DETAILS_SUCCESS:
      return Object.assign({}, state, { ticket_details: { ...action.data }, tickets: { ...state.tickets } });
    case types.SINGLE_TICKET_DETAILS_FAILURE:
      return Object.assign({}, state, { errors: { ...state.errors, ticket_details: { ...action.data } }});
    case types.TICKET_RESOLUTION_SUCCESS:
      return Object.assign({}, state, { ticketResolution: { ...action.data } });
    case types.TICKET_RESOLUTION_FAILURE:
      return Object.assign({}, state, { ticketResolution: { ...action.data } });
    case types.CREATE_TICKET_RESOLUTION_RESPONSE_SUCCESS:
      return Object.assign({}, state, { ticketResolutionResponseDetail: { ...action.data } });
    case types.CREATE_TICKET_RESOLUTION_RESPONSE_FAILURE:
      return Object.assign({}, state, { ticketResolutionResponseDetail: { ...action.data } });
    case types.GET_TICKET_RESOLUTION_RESPONSE_SUCCESS:
      return Object.assign({}, state, { ticketResolutionResponse: { ...action.data } });
    case types.GET_TICKET_RESOLUTION_RESPONSE_FAILURE:
      return Object.assign({}, state, { ticketResolutionResponse: { ...action.data } });
    case types.CREATE_TICKET_RESOLUTION_SUCCESS:
      return Object.assign({}, state, { ticketResolution: { ...action.data } });
    case types.CREATE_TICKET_RESOLUTION_FAILURE:
      return Object.assign({}, state, { ticketResolution: { ...action.data } });
    default:
      return state;
  }
}





