import classNames from "classnames";
import React from "react";

const ErrorMessage = ({ message, children, className, ...rest }) => {
  return (
    <p className={classNames("mb-0 text-left invalid-feedback mt-10", className)} {...rest}>
      {message ? message : children}
    </p>
  );
};

export default ErrorMessage;
