import React from "react";
import { Button, Form, Grid, Header } from "semantic-ui-react";
import { Row, Col } from "reactstrap";
import LoaderComponent from "../common/loaderComponent";
import { Box } from "@material-ui/core";
import FPSelect from "../common/new/forms/ui/FPSelect";
import { FPMenuItem } from "../common/new";

class TicketProductIssueReasonsMappings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productIssueReasonForm: this.props.productIssueReasonsMappingData
        ? this.props.productIssueReasonsMappingData
        : null,
      selectedProductIssueReasons: null,
    };
    this.handleSelectIssue = this.handleSelectIssue.bind(this);
    this.handleSelectReason = this.handleSelectReason.bind(this);
  }

  handleSelectIssue(event, product_index) {
    let productIssueReasonValues = this.state.productIssueReasonForm;
    let product_obj = productIssueReasonValues[product_index];
    let product_issues_array = product_obj.product_issue_list;
    product_issues_array.forEach(function (issue_item) {
      issue_item["selected"] = false;
    });
    const product_issue_index = product_issues_array.findIndex(
      (product_issue) => product_issue.product_issue_id == event.target.value
    );
    let product_selected_issue = product_issues_array[product_issue_index];
    product_selected_issue["selected"] = true;
    product_issues_array[product_issue_index] = product_selected_issue;
    product_obj["product_issue_list"] = product_issues_array;
    productIssueReasonValues[product_index] = product_obj;
    this.setState({ productIssueReasonForm: productIssueReasonValues });
  }

  handleSelectReason(event, product_index, product_issue_index) {
    let productIssueReasonValues = this.state.productIssueReasonForm;
    let product_obj = productIssueReasonValues[product_index];
    let product_issue_obj = product_obj.product_issue_list[product_issue_index];
    if (product_issue_obj) {
      product_issue_obj.product_issue_reasons_list.forEach(function (
        reason_item
      ) {
        reason_item["selected"] = false;
      });
      let product_reason_index =
        product_issue_obj.product_issue_reasons_list.findIndex(
          (reason_obj) =>
            reason_obj.product_issue_reasons_id == event.target.value
        );
      if (product_reason_index > -1) {
        let product_reason_obj =
          product_issue_obj.product_issue_reasons_list[product_reason_index];
        product_reason_obj["selected"] = true;
        product_issue_obj.product_issue_reasons_list[product_reason_index] =
          product_reason_obj;
      }
      product_obj.product_issue_list[product_issue_index] = product_issue_obj;
      productIssueReasonValues[product_index] = product_obj;
      this.setState({ productIssueReasonForm: productIssueReasonValues });
    }
  }

  submitMappingQuestions = () => {
    var new_data = {
      data: this.state.productIssueReasonForm,
      ticket_id: this.props.single_ticket_id,
    };
    this.props.submitProductIssueReasonsMappingQuestions(new_data);
  };

  render() {
    const {
      productIssueReasonsMappingData,
      issueReasonError,
      isTicketClosed,
      isUserNotAssignee,
    } = this.props;
    return (
      <div>
        <LoaderComponent loader={this.props.surveyFormLoading} />
        <Grid
          textAlign="center"
          style={{ width: "415px" }}
          verticalAlign="middle"
        >
          <Grid.Column textAlign="left" text style={{ maxWidth: 410 }}>
            <Form size="large">
              <Box
                className="productIssueReasonsMappingSegment"
                sx={{
                  height: "500px",
                  overflowX: "hidden",
                  overflowY: "auto",
                  margin: "10px 5px",
                }}
              >
                <Header
                  as="h4"
                  textAlign="center"
                  style={{ marginBottom: "20px" }}
                >
                  Product Issue Reasons
                </Header>
                {productIssueReasonsMappingData &&
                  productIssueReasonsMappingData.map(
                    (product, product_index) => {
                      return (
                        product.product_issue_list.length > 0 && (
                          <Form.Field
                            required={true}
                            key={`Issue${product_index}`}
                            style={{ padding: "0 10px" }}
                          >
                            <Row className="mt-3 mb-3">
                              <Col sm="12">
                                <p
                                  style={{
                                    color: "black",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {product.product_name}
                                </p>
                              </Col>
                            </Row>
                            <Row className="mt-3 mb-3">
                              <div style={{ width: "80%", marginLeft: "20px" }}>
                                <p style={{ marginBottom: "0" }}>
                                  Product Issues
                                </p>
                                <FPSelect
                                  name="select"
                                  placeHolder="Select Product Issue"
                                  value={
                                    product && product.product_issue_list && product.product_issue_list.find(
                                      (pro_issu) => pro_issu.selected === true
                                    )
                                      ? product.product_issue_list.find(
                                          (pro_issu) =>
                                            pro_issu.selected === true
                                        ).product_issue_id
                                      : ""
                                  }
                                  style={{ fontWeight: "bold" }}
                                  onChange={(e) =>
                                    this.handleSelectIssue(e, product_index)
                                  }
                                >
                                  {product.product_issue_list.map(
                                    (product_issue, product_issue_index) => (
                                      <FPMenuItem
                                        value={product_issue.product_issue_id}
                                        key={product_issue_index}
                                      >
                                        {product_issue.product_issue_text}
                                      </FPMenuItem>
                                    )
                                  )}
                                </FPSelect>
                              </div>
                            </Row>
                            {product.product_issue_list.find(
                              (pro_issu) => pro_issu.selected === true
                            ) && (
                              <Row className="mt-3 mb-3">
                                <div
                                  style={{ width: "73%", marginLeft: "48px" }}
                                >
                                  <p style={{ marginBottom: "0" }}>
                                    Product Issues Reasons
                                  </p>

                                  <FPSelect
                                    name="select"
                                    placeHolder="Select Product Issues Reason"
                                    value={
                                      product && product.product_issue_list && product.product_issue_list
                                        .find(
                                          (pro_issu) =>
                                            pro_issu.selected === true
                                        )
                                        .product_issue_reasons_list.find(
                                          (issu_res) =>
                                            issu_res.selected === true
                                        )
                                        ? product.product_issue_list
                                            .find(
                                              (pro_issu) =>
                                                pro_issu.selected === true
                                            )
                                            .product_issue_reasons_list.find(
                                              (issu_res) =>
                                                issu_res.selected === true
                                            ).product_issue_reasons_id
                                        : ""
                                    }
                                    style={{ fontWeight: "bold" }}
                                    onChange={(e) =>
                                      this.handleSelectReason(
                                        e,
                                        product_index,
                                        product.product_issue_list.find(
                                          (pro_issu) =>
                                            pro_issu.selected === true
                                        ).product_issue_index
                                      )
                                    }
                                  >
                                    {product?.product_issue_list
                                      .find(
                                        (pro_issu) => pro_issu.selected === true
                                      )
                                      .product_issue_reasons_list.map(
                                        (
                                          issue_reasons,
                                          issue_reasons_index
                                        ) => (
                                          <FPMenuItem
                                            value={
                                              issue_reasons.product_issue_reasons_id
                                            }
                                            key={issue_reasons_index}
                                          >
                                            {
                                              issue_reasons.product_issue_reasons_text
                                            }
                                          </FPMenuItem>
                                        )
                                      )}
                                    </FPSelect>
                                </div>
                              </Row>
                            )}
                          </Form.Field>
                        )
                      );
                    }
                  )}
                <Row className="mt-2 mb-2">
                  <Col sm="12">
                    <p style={{ color: "red", fontSize: "1rem" }}>
                      {issueReasonError}
                    </p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col sm="12" className="mt-2 text-center">
                    <Form.Field
                      control={Button}
                      color="teal"
                      type="button"
                      onClick={this.submitMappingQuestions.bind(this)}
                      style={{
                        color: "white",
                        background: "black",
                        float: "right",
                        borderRadius: "25px",
                      }}
                      disabled={
                        isTicketClosed === false ? isUserNotAssignee : true
                      }
                    >
                      Save & Continue
                    </Form.Field>
                  </Col>
                </Row>
              </Box>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default TicketProductIssueReasonsMappings;
