import React from "react";
import queryString from "query-string";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as RegisterActions from "../../actions/accounts/registerActions";
import * as commonAction from "../../actions/common/commonAction";
import * as ProfileActions from "../../actions/accounts/profileAction";
import * as AgencyActions from "../../actions/agency/agencyActions";

import RegisterForm from "../../components/accounts/register/RegisterForm";
import FormModal from "../../components/common/FormModal";
import AgencyFilters from "../../components/Agency/AgencyFilters";
import LoaderComponent from "../../components/common/loaderComponent";
import NavBar from "../../components/common/NavBar";
import * as utils from "../../libs/utils";
import { AgencyCard } from "../../modules/agency";
import { Divider, Grid } from "@material-ui/core";
import CustomStarRatingComponent from "../../components/common/StarComponent";
import { FPButton, NoData } from "../../components/common/new";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import PaginationComponent from "../../components/common/Pagination";
import * as dashboardActions from "../../actions/common/dashboardAction";

class AgencyPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAddBusinessModalOpen: false,
      activeIndex: "",
    };
    this.toggleBusinessForm = this.toggleBusinessForm.bind(this);
    this.businessDashboard = this.businessDashboard.bind(this);
    this.handleHomeButton = this.handleHomeButton.bind(this);
    this.handleBusinessRegister = this.handleBusinessRegister.bind(this);
  }

  componentDidMount() {
    const {
      location: { search, pathname },
      match: {
        params: { agency_id },
      },
      history,
    } = this.props;

    document.title = "Agency";

    let queryParams = queryString.parse(search);

    if (!queryParams.page_size) queryParams.page_size = 10;
    if (!queryParams.page) queryParams.page = 1;

    const updatedSearch = queryString.stringify(queryParams);

    history.push(`${pathname}?${updatedSearch}`);
    this.props.actions.getUserType();
    this.props.common.getCategoryOptions();
    this.props.agencyActions.agencyList(queryParams, agency_id);
    this.props.agencyActions.agencyAllBusiness(queryParams, agency_id);

  }

  componentDidUpdate(nextProps) {
    const {
      location: { search },
      match: {
        params: { agency_id },
      },
    } = this.props;
    if (this.props.location.search !== nextProps.location.search) {
      this.props.agencyActions.agencyList(queryString.parse(search), agency_id);
    }
    this.props.profileActions.removeBusinessProfile();
  }
  handleBusinessRegister(values) {
    const {
      location: { search },
      match: {
        params: { agency_id },
      },
    } = this.props;

    return this.props.actions.registerAction(values, agency_id).then(() => {
      //eslint-disable-line
      this.toggleBusinessForm();
      this.props.agencyActions.agencyList(queryString.parse(search), agency_id);
    });
  }

  handleClickedBusiness(id) {
    this.setState({ activeIndex: id });
  }

  handleHomeButton() {
    const {
      history,
      match: {
        params: { agency_id },
      },
    } = this.props;
    const url = "/" + agency_id + "/agency-brands";
    history.push(url);
  }

  businessDashboard(businessId) {
    this.props.dashboardActions.clearDashboardApiData();
    this.props.profileActions.getBusinessProfile(businessId).then((res) => {
      let {
        business,
        corporate_branch,
        business_type,
        marketing_id,
        screen_name,
        is_superuser,
        is_business_owner,
      } = res.data;
      let nextPageUrl = utils.nextPageUrl(
        business_type,
        is_superuser,
        screen_name,
        business,
        corporate_branch,
        marketing_id,
        is_business_owner
      );
      this.props.history.push(nextPageUrl);
    });
  }

  toggleBusinessForm() {
    const { isAddBusinessModalOpen } = this.state;
    this.props.actions.getUserType();
    this.props.common.getCategoryOptions();
    this.setState({ isAddBusinessModalOpen: !isAddBusinessModalOpen });
  }

  render() {
    const { businessOptions, categoryOptions, businessList } = this.props;
    const { isAddBusinessModalOpen } = this.state;
    const businessElements =
      businessList && businessList.results.length ? businessList.results : null;

    const businessSummaryData = businessElements
      ? [
          {
            label: "Number of Brands",
            value: businessList.count || 0,
          },
          {
            label: "Total Locations",
            value: businessList.total_no_of_locations || 0,
          },
          {
            label: "Total Reviews",
            value: businessList.total_review || 0,
          },
          {
            label: "Avg Rating",
            value: (
              <div className="d-flex align-center">
                <CustomStarRatingComponent
                  name="agency-rate"
                  starCount={1}
                  className="fp-size-18"
                  value={utils.setDecimalIndex(businessList.total_avg_rating)}
                  editing={false}
                />
                <span className="ml-1">
                  {utils.setDecimalIndex(businessList.total_avg_rating)}
                </span>
              </div>
            ),
          },
        ]
      : [];

    return (
      <div
        className="text-container mx-15"
        style={{ paddingTop: "40px", height: "calc(100vh - 75px)" }}
      >
        <NavBar
          match={this.props.match}
          history={this.props.history}
          businessList={businessList}
        >
          <div>
            <header className="mb-4 d-flex justify-content-between row">
              <div className="col-12 col-md-6">
                <h2 className="font-dark h1 headerText">
                  {businessElements && businessElements[0].marketing_name}
                </h2>
              </div>
              <div className="col-12 col-md-6">
                <div className="d-flex align-items-center justify-content-between justify-content-md-end">
                  {businessSummaryData.map((summary, index) => (
                    <Grid
                      className="d-flex"
                      key={`${index}-agency-summary-data`}
                    >
                      <div>
                        <p className="font-gray mb-0">{summary.label}</p>
                        <p className="font-blue mb-0 fp-size-18">
                          {summary.value}
                        </p>
                      </div>
                      <Divider
                        orientation="vertical"
                        className="mx-md-4 mx-2"
                        flexItem
                      />
                    </Grid>
                  ))}

                  <FPButton
                    backgroundColor="#00B5AD"
                    startIcon={<AddOutlinedIcon />}
                    label="Add Business"
                    onClick={() => this.toggleBusinessForm()}
                  />
                </div>
              </div>
            </header>

            {/* FILTER */}
            {((businessList && businessList.results.length) ||
              this.props.location.search) && (
              <AgencyFilters
                location={this.props.location}
                history={this.props.history}
                userOptionList={
                  businessOptions &&
                  businessOptions.choices
                    .map((item) => ({
                      text: item.display_name,
                      value: item.value,
                    }))
                    .filter((item) => item.value !== "marketing")
                }
                categoryOptionList={
                  categoryOptions &&
                  categoryOptions.choices.map((item) => ({
                    text: item.display_name,
                    value: item.value,
                  }))
                }
              />
            )}

            {this.props.loader ? (
              <LoaderComponent loader={this.props.loader} />
            ) : (
              <>
                {businessElements ? (
                  <>
                    <section className="agency-card-details-container mb-4">
                      {businessElements.map((item) => (
                        <AgencyCard
                          key={item.id}
                          details={item}
                          onClick={() => this.businessDashboard(item.id)}
                        />
                      ))}
                    </section>
                  </>
                ) : (
                  <NoData height={false} />
                )}
              </>
            )}
          </div>
          <div>
            {businessElements?.length > 0 && (
              <PaginationComponent
                count={businessList && businessList.count}
                location={this.props.location}
                pageSizeCookie="agency_page"
              />
            )}
          </div>
        </NavBar>

        {isAddBusinessModalOpen && (
          <FormModal
            isOpen={isAddBusinessModalOpen}
            toggle={() => this.setState({ isAddBusinessModalOpen: false })}
            heading={
              <div>
                <h3 className="font-blue">Add New Business Account</h3>
                <span className="font-gray-500 ">
                  Please enter your business account details.
                </span>
              </div>
            }
          >
            <RegisterForm
              handleRegister={this.handleBusinessRegister}
              toggle={this.toggleBusinessForm}
              userOptionList={
                businessOptions &&
                businessOptions.choices
                  .map((item) => ({
                    label: item.display_name,
                    value: item.value,
                  }))
                  .filter((item) => item.value !== "marketing")
              }
              categoryOptionList={
                categoryOptions &&
                categoryOptions.choices.map((item) => ({
                  label: item.display_name,
                  value: item.value,
                }))
              }
            ></RegisterForm>
          </FormModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  register: state.register.isRegister,
  businessOptions: state.register.businessOptions,
  categoryOptions: state.common.categories,
  businessList: state.agency.business_list,
  loader: state.common.loader,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(RegisterActions, dispatch),
    common: bindActionCreators(commonAction, dispatch),
    agencyActions: bindActionCreators(AgencyActions, dispatch),
    profileActions: bindActionCreators(ProfileActions, dispatch),
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AgencyPage);
