import React, { useEffect, useRef } from 'react';
// import { FaReply } from "react-icons/fa";
import { getFormattedDate } from '../../../libs/utils';
import { WpReceiveMessageArrow, WpSendMessageArrow } from '../../../images/svg';

const ChatArea = ({ selectedChat }) => {
  let lastDisplayedDate = null;
  const chatEndRef = useRef(null);

  useEffect(() => {
    // Scroll to bottom whenever selectedChat updates
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [selectedChat]);

  return (
    <div className="chat-messages">
      {selectedChat.slice().reverse().map((msg, index) => {
        const msgDate = getFormattedDate(msg.msg_time);
        const showDate = msgDate !== lastDisplayedDate;
        lastDisplayedDate = msgDate;

        return (
          <React.Fragment key={msg.id}>
            {showDate && (
              <div className='d-flex gap-10 date-section'>
                <div className="wpdate-marker"></div>
                <span style={{ marginTop: "20px" }} className='wpChate-date'>{msgDate}</span>
                <div className="wpdate-marker"></div>
              </div>
            )}

            <div className={`wp-message ${msg?.incoming ? 'received' : 'sent'}`}>
              {/* Show the correct SVG icon based on the message type */}

              <div className="message-bubble">
                {msg?.incoming ? <WpSendMessageArrow className='wp-arrow-right' /> : <WpReceiveMessageArrow className='wp-arrow-left' />}
                {(() => {
                  let lines = msg?.message?.split('\n');

                  // Remove trailing empty lines
                  while (lines.length && lines[lines.length - 1].trim() === "") {
                    lines.pop();
                  }

                  return lines.map((line, idx) => (
                    <React.Fragment key={idx}>
                      {line} {/* Keep spacing intact, only trim trailing empty lines */}
                      {idx < lines.length - 1 && <br />} {/* Preserve breaks between meaningful lines */}
                    </React.Fragment>
                  ));
                })()}


                <div className="message-time">
                  {getFormattedDate(msg.msg_time, "time")}
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
      {/* Invisible div to scroll to bottom */}
      <div ref={chatEndRef} />
    </div>
  );
};

export default ChatArea;
