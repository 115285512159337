import React from "react";
import FormModal from "../common/FormModal";
import LoaderComponent from "../messenger/Common/LoaderComponent";
import moment from "moment";
import calendar from "../../images/svg/pinkCalendar.svg";
import quantityTag from "../../images/svg/quantityTag.svg";
import priceTag from "../../images/svg/priceTag.svg";
import { Divider } from "@material-ui/core";

const OrderDetailsModal = (props) => {
  const { open, toggle, orderDetailData, isOrderDetailFetching } = props;
  return (
    <FormModal isOpen={open} showDivider={false} toggle={toggle} heading="Order Details" width="800px">
      <div>
        {orderDetailData && Array.isArray(orderDetailData) && orderDetailData.length > 0 ? (
          orderDetailData.map((order, index) => {
            return (
              <div className="order-bg-view d-flex align-items-center justify-content-between mb-10 gap-10" key={index}>
                <span style={{ color: "#1B1C1D", fontSize: "14px", fontWeight: "500", maxWidth: "400px" }}>
                  {order.ordered_product_name}
                </span>
                <div className="d-flex align-items-center gap-6">
                  <div className="d-flex align-items-center gap-6">
                    <img src={calendar} />
                    <span style={{ fontWeight: "300", color: "#7A8193", fontSize: "12px" }}>
                      {moment(order.order_date).format("lll")}
                    </span>
                  </div>
                  <Divider
                    orientation="vertical"
                    style={{ background: "#BFC2CB", margin: "0px" }}
                    variant="middle"
                    flexItem
                  />
                  <div className="d-flex align-items-center gap-6">
                    <img src={quantityTag} />
                    <span style={{ fontWeight: "300", color: "#7A8193", fontSize: "12px" }}>
                      {`${order.ordered_product_quantity} Qty`}
                    </span>
                  </div>
                  <Divider
                    orientation="vertical"
                    style={{ background: "#BFC2CB", margin: "0px" }}
                    variant="middle"
                    flexItem
                  />
                  <div className="d-flex align-items-center gap-6">
                    <img src={priceTag} />
                    <span style={{ fontWeight: "300", color: "#7A8193", fontSize: "12px" }}>
                      {`₹ ${order.ordered_product_price.toFixed(1)}`}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : !isOrderDetailFetching ? (
          <h1 style={{ textAlign: "center", width: "700px" }}> No data found</h1>
        ) : (
          <div style={{ width: "700px", textAlign: "center" }}>
            <LoaderComponent showLoader={isOrderDetailFetching} />
          </div>
        )}
      </div>
    </FormModal>
  );
};
export default OrderDetailsModal;
