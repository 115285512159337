import React from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Row, Col, Nav, NavLink, NavItem, TabPane, TabContent } from "reactstrap";
import PaginationComponent from "../../components/common/Pagination";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cookie from "react-cookies";

import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import * as InsightsActions from "../../actions/insights/insightsAction";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import * as commonAction from "../../actions/common/commonAction";

// import ReviewsFilter from "../../components/business/ReviewsFilter";
import CategoriesPage from "../../components/insights/CategoriesPage";
import LocationsPage from "../../components/insights/LocationsPage";
import TopicsPage from "../../components/insights/TopicsPage";
import LockedComponent from "../../components/common/LockedComponent/LockedComponent";
import LimitModal from "../../components/common/LimitModal";
import * as URLS from "../../libs/apiUrls";
import StaffPerformanceComponent from "../../components/insights/StaffPerformance";
import * as utils from "../../libs/utils";
import InsightsFilter from "./InsightsFilter";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import MenuPerformance from "../../components/insights/MenuPerformance";

class InsightsPage extends React.Component {
  constructor(props) {
    super(props);
    const pageSize = cookie.load("pageSize") || {};
    const {
      history,
      location: { pathname },
    } = props;

    this.state = {
      activeTab: (props.location.state && props.location.state.tab) || props.routerState?.activeTab || "1",
      isDownloadModalOpen: false,
      dataLoad: false,
      params: {
        1: "date_type=prior_30_day&sentiment=All",
        2: `date_type=prior_30_day&is_graph=${true}`,
        3: `date_type=prior_30_day&page_size=${pageSize.insights_locations || "10"}`,
        4: "date_type=prior_30_day",
        5: `date_type=prior_30_day&page_size=${pageSize.menu_performance || "10"}`,
      },
      loader: {
        1: {},
      },
      loadMoreChunks: false,
      checked: {
        nouns: new Map(),
        adjectives: {
          positive: new Map(),
          negative: new Map(),
        },
        nouns_id: new Map(),
        adjectives_id: {
          positive_id: new Map(),
          negative_id: new Map(),
        },
      },
      isLocationWise: false,
      isKeywords: false,
      isStateWise: false,
      isCityWise: false,
      categoriesGraphData: null,
    };
    history.replace({
      pathname,
      search: "sentiment=All&date_type=prior_30_day",
    });
  }

  toggleTab(tab) {
    const { history } = this.props;
    const { activeTab, params } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
      history.push({ pathname: "insights", search: params[tab] });
    }
  }

  componentDidMount() {
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    const { lock } = this.state;
    this.setState({ loader: { 1: true, 2: true, 3: true, 4: true, 5: true } });
    this.props.reviewsActions.reviewSourceOptions({ business_id });
    this.props.commonActions.sentimentOptions();
    this.props.commonActions.cityOptions(business_id);
    this.props.commonActions.stateOptions(business_id);
    this.props.commonActions.countryOptions(business_id);
    this.props.commonActions.branchTagOptions(business_id);
    this.props.actions.getBranchList(business_id, true);
    // this.getNouns().catch(
    //   (err) =>
    //     err === "lock" &&
    //     !lock &&
    //     this.setState({
    //       lock: "Insights Module is locked",
    //       loader: { ...this.state.loader, 1: false },
    //     })
    // );
    // this.getWordCloudData({
    //   ...queryString.parse(search),
    //   businessId: business_id,
    // })
    //   .then((data) => {
    //     this.setState({
    //       loader: { ...this.state.loader, 2: false },
    //       categoriesGraphData: data?.data,
    //     });
    //   })
    //   .catch(
    //     (err) =>
    //       err === "lock" &&
    //       !lock &&
    //       this.setState({
    //         lock: "Insights Module is locked",
    //         loader: { ...this.state.loader, 2: false },
    //       })
    //   );
    // this.getLocationData({
    //   ...queryString.parse(search),
    //   businessId: business_id,
    // })
    //   .then(() => this.setState({ loader: { ...this.state.loader, 3: false } }))
    //   .catch(
    //     (err) =>
    //       err === "lock" &&
    //       !lock &&
    //       this.setState({
    //         lock: "Insights Module is locked",
    //         loader: { ...this.state.loader, 3: false },
    //       })
    //   );
    // this.getStaffPerformanceData({
    //   ...queryString.parse(search),
    //   businessId: business_id,
    // })
    //   .then(() => this.setState({ loader: { ...this.state.loader, 4: false } }))
    //   .catch(
    //     (err) =>
    //       err === "lock" &&
    //       !lock &&
    //       this.setState({
    //         lock: "Insights Module is locked",
    //         loader: { ...this.state.loader, 4: false },
    //       })
    //   );
    // this.getMenuPerformance({ ...queryString.parse(search), businessId: business_id })
    //   .then(() => this.setState({ loader: { ...this.state.loader, 5: false } }))
    //   .catch(
    //     (err) =>
    //       err === "lock" &&
    //       !lock &&
    //       this.setState({ lock: "Insights Module is locked", loader: { ...this.state.loader, 5: false } })
    //   );
    //Set Title
    document.title = "Insights";
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-line
    const {
      location: { search: nextSearch },
      match: { params: nextParams },
    } = this.props;
    const {
      location: { search: prevSearch },
      match: { params: prevParams },
    } = prevProps;
    const {
      activeTab,
      checked: {
        nouns,
        adjectives: { positive, negative },
        nouns_id,
        adjectives_id: { positive_id, negative_id },
      },
    } = this.state;
    if (prevSearch !== nextSearch || prevParams !== nextParams) {
      this.setState({ loader: { 1: true, 2: true, 3: true, 4: true, 5: true } });
      switch (activeTab) {
        case "1":
          nouns.clear();
          positive.clear();
          negative.clear();
          nouns_id.clear();
          positive_id.clear();
          negative_id.clear();
          this.setState(
            {
              checked: {
                nouns,
                adjectives: { positive, negative },
                nouns_id,
                adjectives_id: { positive_id, negative_id },
              },
              loadMoreChunks: false,
            },
            () => {
              this.getNouns();
              this.getChunks();
              this.getAdjectives();
            }
          );
          break;
        case "2":
          this.getWordCloudData({
            ...queryString.parse(nextSearch),
            businessId: nextParams.business_id,
          }).then((data) =>
            this.setState({
              loader: { ...this.state.loader, 2: false },
              categoriesGraphData: data?.data,
            })
          );
          break;
        case "3":
          this.getLocationData({
            ...queryString.parse(nextSearch),
            businessId: nextParams.business_id,
          }).then(() => this.setState({ loader: { ...this.state.loader, 3: false } }));
          break;
        case "4":
          this.getStaffPerformanceData({
            ...queryString.parse(nextSearch),
            businessId: nextParams.business_id,
          }).then(() => this.setState({ loader: { ...this.state.loader, 4: false } }));
          break;
        case "5":
          this.getMenuPerformance({ ...queryString.parse(nextSearch), businessId: nextParams.business_id })
            .then(() => this.setState({ loader: { ...this.state.loader, 5: false } }))
            .catch((err) => {
              this.setState({ loader: { ...this.state.loader, 5: false } });
              console.log(err);
            });
          break;
        default:
          break;
      }
    }
  }

  //Categories function starts from here
  getWordCloudData = (data) => {
    const { provider: provider_id, branch: branch_id } = data;
    delete data.provider;
    delete data.branch;
    const newData = { ...data, provider_id, branch_id };
    return this.props.insightsActions.getWordCloudData(newData);
  };

  getMenuPerformance = (data) => {
    const { provider: provider_id, branch: location_id, businessId, ...rest } = data;
    const newData = { ...rest, provider_id, location_id, business_id: businessId };
    return this.props.insightsActions.getMenuPerformance(newData);
  };

  handleLocation = (event, { checked }) => {
    const isLocationWise = checked;
    const {
      // location: { search: nextSearch },
      match: { params: nextParams },
    } = this.props;
    this.setState({
      isLocationWise: isLocationWise,
      loader: { 1: true, 2: true, 3: true, 4: true, 5: true },
    });
    const {
      history,
      location: { pathname, search },
    } = this.props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        businessId: nextParams.business_id,
        with_state: this.state.isStateWise,
        with_city: this.state.isCityWise,
        with_keywords: this.state.isKeywords,
        with_locations: isLocationWise,
        page: 1
      }),
    });
    // this.getWordCloudData({ ...queryString.parse(nextSearch), businessId: nextParams.business_id, sentiment: "all", with_locations: isLocationWise, with_keywords: this.state.isKeywords, })
    //   .then(() => this.setState({ loader: { ...this.state.loader, 2: false } }));
  };

  handleKeywords = (event, { checked }) => {
    const isKeywords = checked;
    const {
      // location: { search: nextSearch },
      match: { params: nextParams },
    } = this.props;
    this.setState({
      isKeywords: isKeywords,
      loader: { 1: true, 2: true, 3: true, 4: true, 5: true },
    });
    const {
      history,
      location: { pathname, search },
    } = this.props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        businessId: nextParams.business_id,
        with_locations: this.state.isLocationWise,
        with_keywords: isKeywords,
        with_state: this.state.isStateWise,
        with_city: this.state.isCityWise,
        page: 1
      }),
    });
    // this.getWordCloudData({ ...queryString.parse(nextSearch), businessId: nextParams.business_id, sentiment: "All", with_locations: this.state.isLocationWise, with_keywords: isKeywords, with_state: this.state.isStateWise, with_city: this.state.isCityWise, })
    //   .then(() => this.setState({ loader: { ...this.state.loader, 2: false } }));
  };

  handleCityWise = (event, { checked }) => {
    const isCity = checked;
    const {
      // location: { search: nextSearch },
      match: { params: nextParams },
    } = this.props;
    this.setState({
      isCityWise: isCity,
      loader: { 1: true, 2: true, 3: true, 4: true, 5: true },
    });
    const {
      history,
      location: { pathname, search },
    } = this.props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        businessId: nextParams.business_id,
        with_locations: this.state.isLocationWise,
        with_keywords: this.state.isKeywords,
        with_state: this.state.isStateWise,
        with_city: isCity,
        page: 1
      }),
    });
    // this.getWordCloudData({ ...queryString.parse(nextSearch), businessId: nextParams.business_id, sentiment: "All", with_locations: this.state.isLocationWise, with_keywords: this.state.isKeywords, with_state: this.state.isStateWise, with_city: isCity, })
    //   .then(() => this.setState({ loader: { ...this.state.loader, 2: false } }));
  };


  handleStateWise = (event, { checked }) => {
    const isState = checked;
    const {
      // location: { search: nextSearch },
      match: { params: nextParams },
    } = this.props;
    this.setState({
      isStateWise: isState,
      loader: { 1: true, 2: true, 3: true, 4: true, 5: true },
    });
    const {
      history,
      location: { pathname, search },
    } = this.props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        businessId: nextParams.business_id,
        with_locations: this.state.isLocationWise,
        with_keywords: this.state.isKeywords,
        with_state: isState,
        with_city: this.state.isCityWise,
        page: 1
      }),
    });
    // this.getWordCloudData({ ...queryString.parse(nextSearch), businessId: nextParams.business_id, sentiment: "All", with_locations: this.state.isLocationWise, with_keywords: this.state.isKeywords, with_state: isState, with_city: this.state.isCityWise, })
    //   .then(() => this.setState({ loader: { ...this.state.loader, 2: false } }));
  };

  //Locations Function starts from here

  getLocationData = (data, grading) => {
    const { provider: provider_id, branch: branch_id } = data;
    delete data.provider;
    delete data.branch;
    const newData = { ...data, provider_id, branch_id };
    return this.props.insightsActions.getLocationGrading(newData, grading);
  };

  // get staff performance data
  getStaffPerformanceData = (data) => {
    const { provider: provider_id, branch: location_id, businessId, ...rest } = data;
    const newData = {
      ...rest,
      provider_id,
      location_id,
      business_id: businessId,
    };
    return this.props.insightsActions.getStaffPerformance(newData);
  };

  //Topics functions starts from here
  getNouns = () => {
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    const params = queryString.parse(search);
    const { provider: provider_id, branch: branch_id } = params;
    delete params.provider;
    delete params.branch;
    const newData = {
      ...params,
      provider_id,
      branch_id,
      businessId: business_id,
    };
    this.setState({
      loader: {
        ...this.state.loader,
        1: { ...this.state.loader["1"], nouns: true },
      },
    });
    return this.props.insightsActions.nounList(newData).then(() =>
      this.setState({
        loader: {
          ...this.state.loader,
          1: { ...this.state.loader["1"], nouns: false },
        },
      })
    );
  };

  getAdjectives = () => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const {
      checked: { nouns_id },
    } = this.state;
    const params = queryString.parse(search);
    const { provider: provider_id, branch: branch_id } = params;
    delete params.provider;
    delete params.branch;
    const newData = {
      ...params,
      nouns_id: [...nouns_id.keys()].toString(),
      provider_id,
      branch_id,
      businessId: business_id,
    };
    this.setState({
      loader: {
        ...this.state.loader,
        1: { ...this.state.loader["1"], adjectives: true },
      },
    });
    this.props.insightsActions.getAdjList(newData).then(() =>
      this.setState({
        loader: {
          ...this.state.loader,
          1: { ...this.state.loader["1"], adjectives: false },
        },
      })
    );
  };

  getChunks = () => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const {
      checked: {
        nouns_id,
        adjectives_id: { positive_id, negative_id },
      },
      loadMoreChunks,
    } = this.state;
    const params = queryString.parse(search);
    const { provider: provider_id, branch: branch_id } = params;
    delete params.provider;
    delete params.branch;
    const newData = {
      ...params,
      provider_id,
      branch_id,
      businessId: business_id,
      positive_id: [...positive_id.keys()].toString(),
      negative_id: [...negative_id.keys()].toString(),
      nouns_id: [...nouns_id.keys()].toString(),
    };
    this.setState({
      loader: {
        ...this.state.loader,
        1: { ...this.state.loader["1"], chunks: true },
      },
    });
    return this.props.insightsActions
      .chunkList(newData, loadMoreChunks)
      .then(() =>
        this.setState({
          loader: {
            ...this.state.loader,
            1: { ...this.state.loader["1"], chunks: false },
          },
          loadMoreChunks: true,
        })
      )
      .catch((error) => console.error(error));
  };

  nounClick = (noun, checked, all, nouns_data) => {
    const {
      checked: {
        nouns,
        adjectives: { positive, negative },
        nouns_id,
        adjectives_id: { positive_id, negative_id },
      },
    } = this.state;
    if (all) {
      noun.map((key) => {
        checked ? nouns.set(key, checked) : nouns.delete(key);
        const id = nouns_data[key]?.id;
        checked ? nouns_id.set(id, checked) : nouns_id.delete(id);
        return key;
      });
    } else {
      const id = nouns_data[noun]?.id;
      checked ? nouns.set(noun, checked) : nouns.delete(noun);
      checked ? nouns_id.set(id, checked) : nouns_id.delete(id);
    }
    positive.clear();
    negative.clear();
    positive_id.clear();
    negative_id.clear();
    this.setState(
      {
        checked: { nouns, adjectives: { positive, negative }, nouns_id, adjectives_id: { positive_id, negative_id } },
        loadMoreChunks: false,
      },
      () => {
        this.getAdjectives();
        this.getChunks();
      }
    );
  };

  adjectiveClick = (adjective, checked, type, all) => {
    console.log(adjective, checked, type, all);
    const {
      checked: {
        adjectives: { positive, negative },
        adjectives_id: { positive_id, negative_id },
      },
    } = this.state;
    const { adjectives } = this.props;
    if (type === "positive") {
      if (all) {
        adjective.map((key) => {
          checked ? positive.set(key, checked) : positive.delete(key);
          return key;
        });
        adjective.map((key) => {
          const adj_id = adjectives?.pos_adj[key]?.id;
          checked ? positive_id.set(adj_id, checked) : positive_id.delete(adj_id);
          return key;
        });
      } else {
        const adj_id = adjectives?.pos_adj[adjective]?.id;
        checked ? positive.set(adjective, checked) : positive.delete(adjective);
        checked ? positive_id.set(adj_id, checked) : positive_id.delete(adj_id);
      }
    } else {
      if (all) {
        adjective.map((key) => {
          checked ? negative.set(key, checked) : negative.delete(key);
          return key;
        });
        adjective.map((key) => {
          const adj_id = adjectives?.neg_adj[key]?.id;
          checked ? negative_id.set(adj_id, checked) : negative_id.delete(adj_id);
          return key;
        });
      } else {
        const adj_id = adjectives?.neg_adj[adjective]?.id;
        checked ? negative.set(adjective, checked) : negative.delete(adjective);
        checked ? negative_id.set(adj_id, checked) : negative_id.delete(adj_id);
      }
    }
    this.setState(
      { adjectives: { positive, negative }, adjectives_id: { positive_id, negative_id }, loadMoreChunks: false },
      this.getChunks
    );
  };

  getFullReview = (id) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    let businessId = business_id;
    this.setState({ reviewsLoader: true });
    return this.props.reviewsActions.reviewsAction({ id, businessId });
  };

  toggleDownloadModal = () => {
    const { isDownloadModalOpen } = this.state;
    this.setState({ isDownloadModalOpen: !isDownloadModalOpen });
  };

  handleDownloadModal = (downloadFlag) => {
    this.setState({ isDownloadModalOpen: !this.state.isDownloadModalOpen });
    if (!downloadFlag) return;
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const search_field = search;
    const new_params = new URLSearchParams(search_field);
    let insight_city_options = new_params.get("city");
    let insight_state_options = new_params.get("state");
    const insights_date_options = new_params.get("date_type");
    insight_city_options = insight_city_options === null ? "" : insight_city_options;
    insight_state_options = insight_state_options === null ? "" : insight_state_options;
    let insight_location_branch = new_params.get("branch");
    let insight_start_date = new_params.get("start_date");
    insight_start_date = insight_start_date === null ? "" : insight_start_date;
    let insight_end_date = new_params.get("end_date");
    insight_end_date = insight_end_date === null ? "" : insight_end_date;
    let service_type = new_params.get("service_type");
    service_type = service_type === null ? "" : service_type;
    let provider = new_params.get("provider");
    provider = provider === null ? "" : provider;
    let insight_branch_tag = new_params.get("branch_tag");
    insight_branch_tag = insight_branch_tag === null ? "" : insight_branch_tag;
    let insight_branch_id = insight_location_branch === null ? "" : insight_location_branch;
    let allLocations = [];
    this.props.branchOptions.choices &&
      this.props.branchOptions.choices.map((item) => {
        allLocations.push({
          label: item.display_name,
          value: item.value,
        });
        return item
      });
    const { isLocationWise, isKeywords, isCityWise, isStateWise } = this.state;
    let locationMap = {};
    allLocations.map((item) => (locationMap[item.value] = item.label));
    let insight_location_option = insight_location_branch === null ? "" : locationMap[insight_location_branch];
    let date_format = "";
    if (insights_date_options) {
      date_format =
        insights_date_options === "other"
          ? `${new_params.get("start_date")},${new_params.get("end_date")}`
          : insights_date_options;
    }
    date_format = date_format.includes("30") ? `${date_format}` : date_format;
    let url = `${
      URLS.INS
    }${business_id}/topicset/download?date_type=${insights_date_options}&business_id=${business_id}&provider_id=${provider}&branch_id=${insight_branch_id}&branch_tag=${insight_branch_tag}&city=${insight_city_options}&state=${insight_state_options}&location=${insight_location_option}&with_locations=${isLocationWise}&with_keywords=${isKeywords}&with_city=${isCityWise}&with_state=${isStateWise}&start_date=${insight_start_date}&end_date=${insight_end_date}&${
      service_type && service_type !== "" ? "service_type=" + service_type : ""
    }`;
    const staffPerformanceUrl = insight_location_option
      ? `${URLS.STAFF_PERFORMANCE_DOWNLOAD}?date_type=${date_format}&business_id=${business_id}&location_id=${insight_location_branch}`
      : `${URLS.STAFF_PERFORMANCE_DOWNLOAD}?date_type=${date_format}&business_id=${business_id}`;
    const menuPerformanceUrl = `${utils.format(URLS.INSIGHTS_MENU_PERFORMANCE_DOWNLOAD, [business_id])}${search}&location_id=${insight_location_branch || '' }`;
    const categoryUrl = `${URLS.INSIGHTS_CATEGORIES_DOWNLOAD_CATEGORY.replace('{}', business_id)}${search ? search + '&' : '?'}with_locations=${isLocationWise}&with_keywords=${isKeywords}&with_city=${isCityWise}&with_state=${isStateWise}`
    const finalUrl = this.state.activeTab === "4" ? staffPerformanceUrl 
      : this.state.activeTab === "5" ? menuPerformanceUrl 
      : this.state.activeTab === "2" ?  categoryUrl
      : url;
    const fileName =
      this.state.activeTab === "4" ? "famepilot-staff-performance-report.csv"
      : (this.state.activeTab === '2' || this.state.activeTab === '3') ? "famepilot-insights-report.xlsx"
      : this.state.activeTab === '5' ? 'famepilot-menu-performance.csv'
      : "famepilot-insights-report.csv";
    if (this.state.activeTab === '2' || this.state.activeTab === '3') {
      return this.props.reviewsActions.downloadTemplateXlsx(finalUrl, fileName);
    }
    return this.props.reviewsActions.downloadTemplate(finalUrl, fileName);
  };

  handleTopicsReviewDownload = () => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const {
      checked: {
        nouns,
        adjectives: { positive, negative },
        nouns_id,
        adjectives_id: { positive_id, negative_id },
      },
    } = this.state;
    const params = queryString.parse(search);
    delete params.provider;
    delete params.branch;
    const newData = {
      ...params,
      businessId: business_id,
      positive: [...positive.keys()].toString(),
      negative: [...negative.keys()].toString(),
      nouns: [...nouns.keys()].toString(),
      positive_id: [...positive_id.keys()].toString(),
      negative_id: [...negative_id.keys()].toString(),
      nouns_id: [...nouns_id.keys()].toString(),
    };
    let new_url = queryString.stringify(newData);
    let api_url = utils.format(URLS.INSIGHTS_REVIEW_DOWNLOAD, [business_id]);
    let final_url = api_url + "&" + new_url;
    return this.props.reviewsActions.downloadTemplate(final_url, "Insights_Review_Reports.csv");
  };

  handleMenuCheck = (isLocationWise, isCategoryWise, isSubCategory, isNonVeg, hideItemName) => {
    const {
      match: { params: nextParams },
    } = this.props;
    this.setState({ loader: { 1: true, 2: true, 3: true, 4: true, 5: true } });
    const {
      history,
      location: { pathname, search },
    } = this.props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        businessId: nextParams.business_id,
        sentiment: "all",
        show_location_wise: isLocationWise,
        show_category_wise: isCategoryWise,
        show_sub_category_wise: isSubCategory,
        show_veg_non_veg_wise: isNonVeg,
        hide_item_name: hideItemName,
      }),
    });
    // this.getMenuPerformance({ ...queryString.parse(nextSearch), businessId: nextParams.business_id, sentiment: 'all', with_locations: isLocationWise, with_keywords: this.state.isKeywords })
    //   .then(() => this.setState({ loader: { ...this.state.loader, '5': false } }));
  };

  handleCategoryTable =  () => {
    this.setState({ isCityWise: false, isStateWise: false, isLocationWise: false, isKeywords: false });
  }

  render() {
    const {
      location,
      history,
      reviewSourceOptions,
      // sentimentOptions,
      nouns,
      adjectives,
      chunks,
      wordCloud,
      locationGrading,
      reviewDetails,
      // cityOptions,
      // stateOptions,
      // countryOptions,
      // branchTagOptions,
      staffPerformance,
      menu_performance,
    } = this.props;
    const { isLocationWise, isKeywords, isStateWise, isCityWise, loader, checked, activeTab, isDownloadModalOpen, categoriesGraphData } =
      this.state;
    const {
      match: {
        params: { business_id },
      },
    } = this.props; 
    const {
      location: { search },
    } = this.props;
    const service_category = cookie.load("service_category");
    const pageSize = cookie.load("pageSize") || {};
    return (
      <div className="first">
        <Row className="content-area dashboard-page second">
          <LockedComponent message={this.state.lock} />
          <Col sx={12} sm={12} className="business-setup p-3 mt-3">
            <CardWrapper showDivider={false} contentClass="p-0" className="pb-3 px-4">
              <div className="insights-NavRow">
                <Nav tabs className="box-shadow-none" style={{ borderBottom: "none" }}>
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggleTab("1");
                      }}
                    >
                      Topics
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggleTab("2");
                      }}
                    >
                      Categories
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={() => {
                        this.toggleTab("3");
                      }}
                    >
                      Locations
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={() => {
                        this.toggleTab("4");
                      }}
                    >
                      Staff Performance
                    </NavLink>
                  </NavItem>
                  {service_category === "Restaurant" && (
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: this.state.activeTab === "5",
                        })}
                        onClick={() => {
                          this.toggleTab("5");
                        }}
                      >
                        Menu Performance
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
              </div>
            </CardWrapper>
            {/* {this.state.activeTab === "2" || this.state.activeTab === "3" ? (
            <Card body className="py-2 mt-2">
              <span className="mt-1 mb-1 d-inline-block bold-heading">
                {this.state.activeTab === "2"
                  ? "Categories Performance"
                  : "Categories Wise Location Performance"}
              </span>
            </Card>
          ) : (
            ""
          )} */}
            <div className="mt-2">
              <InsightsFilter
                history={this.props.history}
                match={this.props.match}
                downloadExcel={this.toggleDownloadModal}
                reviewSourceOptions={reviewSourceOptions && reviewSourceOptions.choices}
                activeTab={activeTab}
                getData={this.props.insightsActions.getInsightsData}
              />
            </div>
            {isDownloadModalOpen && (
              <LimitModal
                isOpen={isDownloadModalOpen}
                isReviewsPage={true}
                toggle={() => this.setState({ isDownloadModalOpen: !isDownloadModalOpen })}
                HeaderText={"Alrighty"}
                message={["Hey you are good to go. Click ok to download Insights Report"]}
                handleDownloadModal={this.handleDownloadModal}
              />
            )}
            <TabContent activeTab={this.state.activeTab} className="mt-2">
              <TabPane tabId="1">
                {activeTab === "1" && (
                  <TopicsPage
                    location={this.props.location}
                    history={history}
                    match={this.props.match}
                    nouns={nouns}
                    adjectives={adjectives}
                    chunks={chunks}
                    nounClick={this.nounClick}
                    adjectiveClick={this.adjectiveClick}
                    getChunks={this.getChunks}
                    checked={checked}
                    loader={loader["1"]}
                    reviewDetails={reviewDetails}
                    fullReview={this.getFullReview}
                    handleTopicsReviewDownload={this.handleTopicsReviewDownload}
                  />
                )}
              </TabPane>
            </TabContent>
            <TabContent activeTab={this.state.activeTab} className="mt-2">
              <TabPane tabId="2">
                {activeTab === "2" && (
                  <CategoriesPage
                    isLocationWise={isLocationWise}
                    isKeywords={isKeywords}
                    isStateWise={isStateWise}
                    isCityWise={isCityWise}
                    handleCityWise={this.handleCityWise} 
                    handleStateWise={this.handleStateWise}
                    location={location}
                    history={history}
                    match={this.props.match}
                    businessId={business_id}
                    wordCloudData={wordCloud}
                    categoriesGraphData={categoriesGraphData}
                    graphData={wordCloud}
                    loader={loader["2"]}
                    handleLocation={this.handleLocation}
                    handleKeywords={this.handleKeywords}
                    pageSize={pageSize}
                    handleCategoryTable={this.handleCategoryTable}
                  />
                )}
              </TabPane>
            </TabContent>
            <TabContent activeTab={this.state.activeTab} className="mt-2">
              <TabPane tabId="3">
                {activeTab === "3" && (
                  <>
                    <LocationsPage
                      location={location}
                      history={history}
                      match={this.props.match}
                      businessId={business_id}
                      locationGrading={locationGrading}
                      loader={loader["3"]}
                    />
                    <PaginationComponent
                      count={locationGrading.count}
                      location={this.props.location}
                      pageSizeCookie="insights_locations"
                    />
                  </>
                )}
              </TabPane>
            </TabContent>

            <TabContent activeTab={this.state.activeTab} className="mt-2">
              <TabPane tabId="4">
                {activeTab === "4" && (
                  <StaffPerformanceComponent
                    staffPerformance={staffPerformance}
                    location={this.props.location}
                    history={this.props.history}
                    match={this.props.match}
                    loader={loader["4"]}
                  />
                )}
              </TabPane>
            </TabContent>
            <TabContent activeTab={this.state.activeTab} className="mt-2">
              <TabPane tabId="5">
                {activeTab === "5" && (
                  <MenuPerformance
                    handleMenuCheck={this.handleMenuCheck}
                    location={this.props.location}
                    menu_performance={menu_performance}
                    loader={loader["5"]}
                    businessId={business_id}
                    search={search}
                  />
                )}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch),
    insightsActions: bindActionCreators(InsightsActions, dispatch),
    reviewsActions: bindActionCreators(ReviewsActions, dispatch),
    commonActions: bindActionCreators(commonAction, dispatch),
  };
}

InsightsPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  reviewSourceOptions: PropTypes.instanceOf(Object),
  sentimentOptions: PropTypes.instanceOf(Object),
  cityOptions: PropTypes.instanceOf(Object),
  stateOptions: PropTypes.instanceOf(Object),
  countryOptions: PropTypes.instanceOf(Object),
  branchOptions: PropTypes.instanceOf(Object),
  branchTagOptions: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  return {
    nouns: state.insights.noun_list || {},
    adjectives: state.insights.adj_list || {},
    chunks: state.insights.chunk_list || {},
    wordCloud: state.insights.word_cloud || {},
    menu_performance: state.insights.menu_performance || [],
    locationGrading: state.insights.location_grading || {},
    staffPerformance: state.insights.staff_performance || [],
    reviewSourceOptions: state.review.review_source_options,
    sentimentOptions: state.common.sentiment_options,
    reviewDetails: state.review.list,
    cityOptions: state.common.city_options,
    stateOptions: state.common.state_options,
    countryOptions: state.common.country_options,
    branchOptions: state.business.branch_list,
    branchTagOptions: state.common.branch_tag_options,
    routerState: state.routerState.state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsPage);
