/* eslint-disable */
import React from "react";
import {
  Avatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { FPMenu } from "../../components/common/new";
import FPMenuItem from "../../components/common/new/FPMenu/FPMenuItem";
import UserImg from "../../images/user-icon.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// import { LogoutIcon, UserIcon } from "../../images/svg";
import userAcLogo  from "../../images/svg/userAcLogo.svg";
// import logOutIcon from "../../images/svg/LogoutIcon.svg";
import logOutIcon from "../../images/svg/logOutIcon.svg";

const useStyles = makeStyles(() => ({
  large: {
    width: "40px",
    height: "40px",
    border: "1px solid var(--fp-secondary-outline)",
  },
}));

export default function UserAvatar({ user, userEasyAccessList }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  return (
    <div>
      <div
        onClick={handleClick}
        className="d-flex gap-10 align-center cursor-pointer navBar-user profile-user-display svgMobile"
      >
        <Avatar
          alt={`${user.first_name} ${user.last_name}`}
          src={user.avtar ? user.avtar : UserImg}
          className={classes.large}
        />
        <span>User</span>
        <KeyboardArrowDownIcon style={{ marginLeft: "-10px" }} />
      </div>

      <FPMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        hideBorder={true}
      >
        <FPMenuItem
          className="user-easy-access-wrapper bg-white"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="d-flex align-items-center gap-10">
          <Avatar
          alt={`${user.first_name} ${user.last_name}`}
          src={user.avtar ? user.avtar : UserImg}
          className={classes.large}
        />          
          <div>
            <p className="mb-1">{`${user.first_name} ${user.last_name}`}</p>
            <p>{`${user.email}`}</p>
          </div>
          </div>
        </FPMenuItem>

        {userEasyAccessList.map((easyAccess, index) => (
          <FPMenuItem
            key={`${index}-easyAccess`}
            onClick={() => {
              easyAccess.onClick(), setAnchorEl(null);
            }}
            showDivider={index + 1 < userEasyAccessList.length}
            className="user-easy-access-wrapper"
          >
            <ListItemIcon className="p-0 m-0" style={{minWidth: '30px'}}>
              {index === 0 ? <img src={userAcLogo} /> : <img src={logOutIcon} />}
            </ListItemIcon>
            <ListItemText primary={easyAccess.label}  />
          </FPMenuItem>
        ))}
      </FPMenu>
    </div>
  );
}
