import * as types from '../../actions/actionTypes';

export default function contact(state = [], action) {
  switch (action.type) {
    case types.GENERIC_TEMPLATES_LIST_SUCCSESS:
      return Object.assign({}, state, { list: action.data });
    case types.GENERIC_TEMPLATES_LIST_FAILURE:
      return Object.assign({}, state, action.data);
    case types.GET_GENERIC_TEMPLATE_DETAILS_SUCCESS:
      return Object.assign({}, state, { detail: action.data });
    case types.GET_GENERIC_TEMPLATE_DETAILS_FAILURE:
      return Object.assign({}, state, { detail: action.data });
    case types.POST_GENERIC_TEMPLATE_SUCCESS:
      return Object.assign({}, state, { detail: {...action.data}});
    case types.GENERIC_TEMPLATE_DELETE_FAILURE:
      return Object.assign({}, state, { detail: {} });
    case types.GENERIC_TEMPLATE_CLONE_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.GENERIC_TEMPLATE_CLONE_FAILURE:
      return Object.assign({}, state, { detail: {} });
    case types.GENERIC_TEMPLATES_CONTACTS_SUCCESS:
      return Object.assign({}, state, { genericContacts: { ...action.data } });
    case types.GENERIC_TEMPLATES_CONTACTS_FAILURE:
      return Object.assign({}, state, action.data);
    case types.GENERIC_CONTACT_DELETE_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.GENERIC_CONTACT_DELETE_FAILURE:
      return Object.assign({}, state, { detail: {} });
    case types.GET_GENERIC_CONTACT_DETAILS_SUCCESS:
      return Object.assign({}, state, { genericContact: { ...action.data } });
    case types.GET_GENERIC_CONTACT_DETAILS_FAILURE:
      return Object.assign({}, state, action.data);
    case types.GENERIC_CONTACT_EDIT_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.GENERIC_CONTACT_EDIT_FAILURE:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
}