import React, { useState } from "react";
import moment from "moment";
import "./styles.css";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import { Collapse, IconButton } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import { FPToolTip } from "../../common/new";
import { snakeToCapital } from "../../../libs/utils";
import newCalendar from "../../../images/svg/newCalendar.svg";

export const ActivityLogComponent = (props) => {
  const { logs } = props;
  // const [isOpen, setIsOpen] = useState(false);
  return (
    <div id="activity-log">
      <div className="">
        <h5 className="font-dark">Activity Log</h5>
        {/* <IconButton onClick={() => setIsOpen(!isOpen)} size="small">
          {isOpen ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}
        </IconButton> */}
      </div>
      <Collapse 
      in={true}
      // in={isOpen}
      >
        <div id="activity-log-content" style={{ marginTop: "15px" }}>
          <ul className="timeline">
            {logs.map((item, index) => {
              const {
                created_at,
                action,
                data: { description, title },
              } = item;
              return (
                <li key={index} className={`timeline-item ${action}`}>
                  <Row>
                    <Col className="action-box">
                      <div className="direction-l">
                        <div className="flag flag-wrapper">
                          <p className="h6 font-dark fp-size-13 mb-1">{action}</p>
                          <div className="d-flex mb-2 fp-size-12 align-items-center gap-6">
                            <img src={newCalendar} />
                            <p className="font-blue-gray m-0" style={{ linHeight: 1 }}>
                              {moment(created_at).format("llll")}
                            </p>
                          </div>
                          <FPToolTip title={title}>
                            <p className="font-dark m-0 fp-size-13 d-inline">{snakeToCapital(description)}</p>
                          </FPToolTip>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>
              );
            })}
          </ul>
        </div>
      </Collapse>
    </div>
  );
};
