import React, { useEffect } from "react";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";

ReactFC.fcRoot(FusionCharts, Charts, Widgets, FusionTheme);

const FunnelChart = ({ mainData }) => {
  useEffect(() => {
    if (
      !mainData ||
      !mainData.total_impressions ||
      !mainData.total_impressions_to_menu ||
      !mainData.total_menu_to_cart ||
      !mainData.total_cart_to_order
    ) {
      return;
    }

    const dynamicData = [
      { label: "Total Impressions", value: mainData.total_impressions || 0 },
      {
        label: "Total Impressions to Menu",
        value: mainData.total_impressions_to_menu || 0,
      },
      { label: "Total Menu to Cart", value: mainData.total_menu_to_cart || 0 },
      {
        label: "Total Cart to Order",
        value: mainData.total_cart_to_order || 0,
      },
    ];

    const mergedData = dynamicData.map((item, index) => ({
      label: item.label,
      value: item.value,
      toolText: `<b>${item.label}</b>: ${dynamicData[index].value}`,
    }));

    const conversionChart = new FusionCharts({
      type: "funnel",
      renderAt: "chart-container",
      width: "100%",
      height: "100%",
      dataFormat: "json",
      dataSource: {
        chart: {
          decimals: "1",
          is2D: "0",
          streamlinedData: "0",
          showLabels: "1",
          theme: "fusion",
          labelFontSize: "14",
          showPercentValues: "0",
          showValues: "1",
          toolTipSepChar: " - ",
          showToolTip: "1",
          toolTipBgColor: "#000000",
          toolTipColor: "#ffffff",
          toolTipBorderRadius: "5",
          toolTipBorderColor: "#ffffff",
          chartLeftMargin: "-110",
          chartRightMargin: "110",
          showValues: "0",
          paletteColors: "#FBBD66, #FB7B5E, #E4A7E0, #82B8E4, #9467BC",
          isSliced: "1",
        },
        data: mergedData,
      },
    });

    conversionChart.render();
  }, [mainData]); // Runs only when mainData changes

  return (
    <div>
      {mainData &&
      mainData.total_impressions &&
      mainData.total_impressions_to_menu &&
      mainData.total_menu_to_cart &&
      mainData.total_cart_to_order ? (
        <div id="chart-container" style={{ width: "100%", height: "400px" }}></div>
      ) : (
        <h2 style={{ color: "gray", marginTop: "150px", textAlign: "center" }}>
          No Data Found
        </h2>
      )}
    </div>
  );
};

export default FunnelChart;
