import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import cookie from 'react-cookies';

import { connect } from 'react-redux';
import { Row, Col} from 'reactstrap';
import * as NewsLetterActions from '../../actions/newsLetter/newsLetterActions';

import TemplateList from '../../components/campaign/templates/TemplateList';
import * as utils from '../../libs/utils';

import LockedComponent from '../../components/common/LockedComponent/LockedComponent';
import LoaderComponent from '../../components/common/loaderComponent';
import ConfirmationModal from '../../components/common/ConfirmationModal';


class NewsLetterPage extends React.PureComponent {
  constructor(props)
  {
    super(props);
    this.switchToAddNewsLetter = this.switchToAddNewsLetter.bind(this);
    this.deleteNewsLetter = this.deleteNewsLetter.bind(this);
    this.cloneNewsLetter = this.cloneNewsLetter.bind(this);
    this.getNewsLetterList = this.getNewsLetterList.bind(this);

    this.state = {
      lock: ''
    };
    //PageSize Handling
    const pageSize = cookie.load('pageSize') || {};
    const { history, location: { pathname,search }} = props;
    history.replace({ pathname, search: queryString.stringify({ ...queryString.parse(search), page_size: pageSize.templates_newsletter || 10 }) });
  }

  componentDidMount(){
    this.getNewsLetterList(this.props, queryString.parse(this.props.location.search));
    //Set Title
    document.title = 'Templates|NewsLetter';
  }

  componentWillReceiveProps(nextProps) { // eslint-disable-line
    const { location: { search: prevSearch } } = this.props;
    const { location: { search: nextSearch } } = nextProps;
    if (prevSearch !== nextSearch) {
      this.getNewsLetterList(nextProps, queryString.parse(nextSearch));
    }
  }

  switchToAddNewsLetter(val) {
    const { history, match: { params: { branch_id, business_id }} } = this.props;
    history.push(`/${business_id}/${branch_id}/templates/news-letter/edit?${val}`);
  }
  getNewsLetterList(props,data){
    const { match: { params: { business_id }} } = props;
    this.props.actions.newsLetterList({ ...data, branch_id: business_id })
      .catch((err)=>err==='lock' && this.setState({lock: 'NewsLetter Module is locked.'}));
  }
  toggleConfirm = (confirmationFunction) => {
    this.setState({ isConfirmOpen: !this.state.isConfirmOpen, confirmationFunction });
  }
  deleteNewsLetter(data){
    const { history, location: { pathname, search },  match: { params: { business_id }} } = this.props;
    return this.props.actions.newsLetterDelete(data, business_id)
      .then(() => {
        const query = queryString.parse(search);
        if(query.page){
          history.push({pathname, search: `?page_size=${query.page_size}`});
        }
        else{
          this.getNewsLetterList(this.props,{page_size: query.page_size});
        }
      });
  }

  cloneNewsLetter(data){
    const { match: { params: { business_id }}, location: { search } } = this.props;
    return this.props.actions.newsLetterClone(data, business_id)
      .then(() => this.getNewsLetterList(this.props, queryString.parse(search)));
  }


  render()
  {
    let { location:{ search } } = this.props;
    const querySearch = queryString.parse(search);
    const { lock } = this.state;
    return(
      <Row className="content-area">
        <LockedComponent message={lock}/>
        <LoaderComponent loader={this.props.loader}/>
        <Col xs={12} sm={12} className="business-setup p-3">
          <TemplateList
            list ={this.props.newsLetterList}
            deleteTemplate={(item) => this.toggleConfirm(() =>this.deleteNewsLetter(item))}
            cloneTemplate={this.cloneNewsLetter}
            switchToAddTemplate={this.switchToAddNewsLetter}
            location={this.props.location}
            history={this.props.history}
            sortFunction={(on) => utils.toggleSort(this.props.history,this.props.location,on)}
            newsLetter={true}
            ordering={querySearch.ordering}
            searchValue={querySearch.search}
            pageSizeCookie={'templates_newsletter'}
          />
        </Col>
        <ConfirmationModal
          header='Are you sure!'
          subHeader='Are you sure you want to delete the selected items? This action cannot be undone.'
          isOpen={this.state.isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={this.state.confirmationFunction}
        />
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    newsLetterList:state.newsLetter.list,
    errors: state.business.errors,
    teamDetail: state.team.detail,
    loader: state.common.loader
  };
};


NewsLetterPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  CommonActions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  business:PropTypes.instanceOf(Object)
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(NewsLetterActions, dispatch),
  };
}


export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(NewsLetterPage);