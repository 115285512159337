import React from "react";
import { Row, Col } from "reactstrap";
import WhatsappIcon from "../../images/whatsappicon.png";
import LoaderComponent from "../common/loaderComponent";
import * as URLS from "../../libs/apiUrls";
import cookie from "react-cookies";
import axios_instance from "../../libs/interseptor";
import FormModal from "../common/FormModal";
import SetupWhatsappForm from "../business/SetupWhatsappForm";

class SetupWhatsappAccounts extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.business_id !== prevState.business_id) {
      return { business_id: nextProps.business_id };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      business_id: this.props.business_id,
      btnText: "Connect to Whatsapp",
      name: this.props.whatsapp_status ? this.props.whatsapp_status.name : "",
      connected: this.props.whatsapp_status
        ? this.props.whatsapp_status.connected
        : false,
      loader: false,
      initial_check: false,
      isSetupWhatsappModalOpen: false,
    };
    this.toggleSetupWhatsappModal = this.toggleSetupWhatsappModal.bind(this);
    this.saveWhatsappDetail = this.saveWhatsappDetail.bind(this);
  }

  removeSocialConnect(business_id) {
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    return axios_instance
      .get(`${URLS.MESSENGER}waba-logout/?business_id=${business_id}`, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.connected === false) {
          this.setState({ connected: false, initial_check: true, name: "", btnText: "Login to Whatsapp", business_id: business_id });
        }
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  handleClick() {
    if (this.state.connected === false) {
      this.setState({ isSetupWhatsappModalOpen: true });
    } else {
      this.removeSocialConnect(this.state.business_id);
    }
  }
  toggleSetupWhatsappModal() {
    this.setState({
      isSetupWhatsappModalOpen: !this.state.isSetupWhatsappModalOpen,
    });
  }

  saveWhatsappDetail(data) {
    this.setState({ loader: true });
    const req_data = {
      access_token: data.parmanent_token,
      name: data.name,
      waba_id: data.waba_id,
      waba_phone_id: data.waba_phone_id,
      business_id: this.state.business_id,
    };
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    return axios_instance
      .post(`${URLS.MESSENGER}waba-setup/`, req_data, { headers: headers })
      .then((res) => {
        if (res.data.connected === true) {
          this.setState({ loader: false, 
            connected: true, 
            initial_check: true, 
            name: res.data.name,
            btnText: "Log Out",
            business_id: this.state.business_id,
            isSetupWhatsappModalOpen: false,
          });
          this.props.callSocialProfileApi();
          this.props.handleConnectProfile();
        } else {
          this.setState({ initial_check: true, loader: false, isSetupWhatsappModalOpen: false });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        throw err.response.data;
      });
  }
  render() {
    const { connectedStatus, toggleTab } = this.props;
    return (
      <div>
        <LoaderComponent loader={this.state.loader} />

        <div  className="my-2">
          <Row>
            <Col>
              <div className="text-center mb-2">
                <span
                  className="text-center google-button cursor-pointer"
                  style={{ display: 'flex', gap: '10px', borderRadius: '25px', justifyContent: 'center', alignItems: 'center', background: '#25D366'}}
                  onClick={() => this.handleClick()}
                >
                  <div style={{ background: 'white', borderRadius: '4px'}}>
                    <img src={WhatsappIcon} alt='Whatsapp Icon' height="25"  style={{ padding: '4px'}}/>
                  </div>
                  <span>Connect to Whatsapp</span>
                </span>
                <div className='mt-2' onClick={toggleTab} style={{ textAlign: 'center', fontWeight: '600', textDecoration: 'underline', fontSize: '14px', cursor: 'pointer' }}>
                  {connectedStatus ? connectedStatus : this.state.connected ? 1 : 0} profile already connected
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {this.state.isSetupWhatsappModalOpen && (
          <FormModal
            isOpen={this.state.isSetupWhatsappModalOpen}
            toggle={this.toggleSetupWhatsappModal}
            heading="Add Whatsapp Business Details"
          >
            <SetupWhatsappForm saveWhatsappDetail={this.saveWhatsappDetail} />
          </FormModal>
        )}
      </div>
    );
  }
}

export default SetupWhatsappAccounts;
