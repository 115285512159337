import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TeamPopup from "./TeamPopup";
import FormModal from "../common/FormModal";
import PaginationComponent from "../common/Pagination";
import { FPToolTip } from "../common/new";
import editIcon from "../../images/svg/EditPen.svg";
import deleteIcon from "../../images/svg/trash.svg";

const TeamList = (props) => {
  const {
    members,
    count,
    location,
    pageSizeCookie,
    deleteMember,
    toggle,
    department,
    designation,
    handleTeamItemParams,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [memberList, setMemberList] = useState(null);

  const toggleOpen = (member) => {
    setMemberList(member);
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="ui-table-responsive">
        <Table className="fp-table fp-table-center">
          <TableHead>
            <TableRow>
              <TableCell>
                {/* <Checkbox label='S.No' id="all" className="d-flex">Select All</Checkbox> */}
                S.No
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Contact No.</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Designation</TableCell>
              <TableCell>Location</TableCell>
              <TableCell className="">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <span className="tableSpecificDataBold d-flex">
                      {/* <Checkbox label={index + 1} /> */}
                    </span>
                    <label className="text-center">{index + 1}</label>
                  </TableCell>
                  <TableCell>
                    <Box
                      component={"span"}
                      onClick={() => toggleOpen(member)}
                      sx={{ cursor: "pointer" }}
                    >
                      {member.first_name + " " + member.last_name}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <FPToolTip title={member.email}>
                      <span className="d-inline-block text-truncate width-sm">
                        {member.email}
                      </span>
                    </FPToolTip>
                  </TableCell>
                  <TableCell>{member.contact_number}</TableCell>
                  <TableCell>
                    {moment(member.date_joined).format("DD-MM-YYYY")}
                  </TableCell>

                  <TableCell>
                    <FPToolTip
                      title={
                        department?.find((item) => item.id == member.department)
                          ?.name
                      }
                    >
                      <span className="d-inline-block text-truncate width-sm">
                        {
                          department?.find(
                            (item) => item.id == member.department
                          )?.name
                        }
                      </span>
                    </FPToolTip>
                  </TableCell>
                  <TableCell>
                    <FPToolTip
                      title={
                        designation?.find(
                          (item) => item.id == member.designation
                        )?.name
                      }
                    >
                      <span className="d-inline-block text-truncate width-sm">
                        {
                          designation?.find(
                            (item) => item.id == member.designation
                          )?.name
                        }
                      </span>
                    </FPToolTip>
                  </TableCell>
                  <TableCell>
                    <FPToolTip>
                      <span className="d-inline-block text-truncate width-sm">
                        {member?.location?.length > 0
                          ? `${member?.location?.length} Locations`
                          : "No Locations"}
                      </span>
                    </FPToolTip>
                  </TableCell>
                  {(member.user_type === "team_member" || member.user_type === "business_user" || member.user_type === "marketing_user" || member.user_type === "sr_only") && (
                    <TableCell>
                      <div className="actionButton">
                        <FPToolTip title={"Edit"} className="me-3">
                          <img
                            src={editIcon}
                            onClick={() => toggle(member)}
                            role="button"
                            alt="edit"
                          />
                        </FPToolTip>

                        <FPToolTip title={"Delete"} className="me-3">
                          <img
                            src={deleteIcon}
                            onClick={() => deleteMember(member)}
                            role="button"
                            alt="delete"
                          />
                        </FPToolTip>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {!members.length && (
              <TableRow>
                <TableCell colSpan={9} className="text-center">
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <PaginationComponent
        count={count}
        location={location}
        pageSizeCookie={pageSizeCookie}
        onPageChange={(params) => handleTeamItemParams("Department", params)}
      />
      {isOpen && (
        <FormModal
          isOpen={isOpen}
          toggle={toggleOpen}
          heading={"Team"}
          width="900px"
          contentClass="mb-0"
          bodyClass="mb-0"
        >
          <TeamPopup
            memberList={memberList}
            department={department}
            designation={designation}
          />
        </FormModal>
      )}
    </div>
  );
};

export default TeamList;
