import React from 'react';
import { FPTab } from '../../../components/common/new/tabs';
import { connect } from 'react-redux';

const Header = ({businessProfile, history, defaultValue}) => {
  const redirectTo = (subUrl) => {
    setTimeout(() => {
      history.push(`/${businessProfile.id}/campaigns${subUrl}`);
    }, 400);
  };

  const handleRootTabChange = (value) => {
    switch (value) {
      case 0:
        redirectTo('/review/list?page_size=10');
        break;
      case 1:
        redirectTo('/news-letter/list?page_size=10');
        break;
      default:
        break;
    }
  };

  return (
    <FPTab
      variant="round-fill"
      theme="secondary"
      tabLabels={['Review Request', 'News Letter']}
      minTabWidth={'auto'}
      size="small"
      defaultValue={defaultValue}
      onChange={(value) => handleRootTabChange(value)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    businessProfile: state.business.detail,
  };
};

export default connect(mapStateToProps,null,null,{forwardRef: true})(Header);
