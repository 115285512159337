import React, { Component } from "react";
import RuleCreationForm from "../../components/business/RuleCreationForm";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import { connect } from "react-redux";
import LoaderComponent from "../../components/common/loaderComponent";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import queryString from "query-string";

class NewRulesAdd extends Component {
  constructor(props) {
    super(props);
    this.submitRuleData = this.submitRuleData.bind(this);
    this.filterOwnersForSelectedBranch = this.filterOwnersForSelectedBranch.bind(this);
    this.state = {
      dataEnt: [],
    };
  }

  submitRuleData(data) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions.addNewRule(data, business_id);
  }

  dataFetch = async (business_id) => {
    const url = `${URLS.BUSINESS_SETUP}${business_id}/all_enterprise/`;
    await axios_instance
      .get(url)
      .then((res) => res.data)
      .then((data) => {
        this.setState({ dataEnt: data });
      })
      .catch((err) => console.log(err));
  };
  componentDidMount() {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const {
      route: {
        location: { search },
      },
    } = this.context.router;
    const { id } = queryString.parse(search);
    this.props.actions.getBranchList(business_id, true);
    // this.props.actions.allowedForOptions(business_id);
    this.props.actions.ticketRuleProviders(business_id);
    this.props.actions.reviewContentTypeOptions(business_id);
    this.props.actions.branchOwners({ businessId: business_id });
    if (id) this.props.actions.ruleDetail({ business_id, id });
    this.dataFetch(business_id);
  }

  filterOwnersForSelectedBranch(data) {
    this.props.actions.branchOwners({
      businessId: this.props.match.params.business_id,
      branches: data,
    });
  }

  render() {
    const { ruleDetail } = this.props;
    const {
      route: {
        location: { search },
      },
    } = this.context.router;
    const { id } = queryString.parse(search);
    if (id && +id !== (ruleDetail && ruleDetail.id)) return null;
    const newData = {
      content_choice: ["WITH_CONTENT", "WITHOUT_CONTENT"],
    };
    const formData = id ? ruleDetail : newData;
    return (
      <div>
        <LoaderComponent loader={this.state.dataEnt.length === 0 && this.props.branchOwnersList} />
        {this.state.dataEnt.length !== 0 && this.props.branchOwnersList && (
          <RuleCreationForm
            initialValues={formData}
            submitData={this.submitRuleData}
            businessId={parseInt(this.props.match.params.business_id)}
            details={this.state.dataEnt}
            branchList={this.props.branchList && this.props.branchList.choices}
            branchOwnersList={this.props.branchOwnersList && this.props.branchOwnersList.results}
            filterOwnersForSelectedBranch={this.filterOwnersForSelectedBranch}
            // providerOptions = {this.props.providerOptions && this.props.providerOptions.choices}
            providerOptions={this.props.ticketProviderOptions && this.props.ticketProviderOptions.choices}
            reviewContentTypeOptions={
              this.props.reviewContentTypeOptions && this.props.reviewContentTypeOptions.choices
            }
            isEditMode={!!id}
          />
        )}
      </div>
    );
  }
}

NewRulesAdd.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  return {
    ruleDetail: state.business.rule_details,
    enterpriseData: state.business.enterprise_list,
    branchList: state.business.branch_list,
    branchOwnersList: state.business.branch_owners_list,
    // providerOptions: state.business.allowed_for_options,
    reviewContentTypeOptions: state.business.review_content_type_options,
    ticketProviderOptions: state.business.ticket_provider_options,
  };
};

// NewRulesAdd.propTypes = {
//   actions: PropTypes.instanceOf(Object),
// };

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(NewRulesAdd);
