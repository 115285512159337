import React from "react";
import {
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  TabPane,
  TabContent,
} from "reactstrap";
import TopicsKeywordListing from "./TopicsKeywordListing";
import classNames from "classnames";
import queryString from "query-string";
import "../../components/insights/insight.css";

class TopicsKeywordList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        1: "",
        2: "",
        3: "",
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      location: { search: nextSearch },
    } = nextProps;
    const { sentiment: nextSentiment } = queryString.parse(nextSearch);
    if (nextSentiment !== prevState.activeTab) {
      return { activeTab: nextSentiment };
    }
    return null;
  }

  toggleTab(sentiment) {
    const {
      location: { pathname, search },
      history,
    } = this.props;
    const queryparams = { ...queryString.parse(search), sentiment };
    history.push({ pathname, search: queryString.stringify(queryparams) });
  }

  render() {
    const { adjectives, nouns, nounClick, adjectiveClick, checked } =
      this.props;
    return (
      <div>
        <div className="ml-2 topicsNav">
          <Col md={12} xs={12} className="">
            <Nav tabs className="topic-review-type-tab">
              <NavItem>
                <NavLink
                  className={classNames({
                    active: this.state.activeTab === "All",
                  })}
                  onClick={() => {
                    this.toggleTab("All");
                  }}
                >
                  All
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: this.state.activeTab === "Positive",
                  })}
                  onClick={() => {
                    this.toggleTab("Positive");
                  }}
                >
                  Positive
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: this.state.activeTab === "Negative",
                  })}
                  onClick={() => {
                    this.toggleTab("Negative");
                  }}
                >
                  Negative
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </div>
        <Row className="ml-2">
          <Col sm={12} xs={12}>
            <TabContent activeTab={this.state.activeTab || "All"}>
              <TabPane tabId="All">
                <TopicsKeywordListing
                  location={this.props.location}
                  nouns={nouns}
                  adjectives={adjectives}
                  nounClick={nounClick}
                  adjectiveClick={adjectiveClick}
                  checked={checked}
                  showPositive
                  showNegative
                />
              </TabPane>
              <TabPane tabId="Positive">
                <TopicsKeywordListing
                  location={this.props.location}
                  nouns={nouns}
                  adjectives={adjectives}
                  checked={checked}
                  nounClick={nounClick}
                  adjectiveClick={adjectiveClick}
                  showPositive
                />
              </TabPane>
              <TabPane tabId="Negative">
                <TopicsKeywordListing
                  location={this.props.location}
                  nouns={nouns}
                  adjectives={adjectives}
                  checked={checked}
                  nounClick={nounClick}
                  adjectiveClick={adjectiveClick}
                  showNegative
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

export default TopicsKeywordList;
