import React from "react";

const PhoneNumberList = (props) => {
  return (
    <>
      <div
        style={{
          display: "grid",
          gap: "6px",
          padding: "10px",
          fontSize: "14px",
          fontWeight: "400",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "18px",
            borderBottom: "1px solid #BFC2CB",
            paddingBottom: "12px",
          }}
        >
          <div
            style={{
              color: "#646565",
              display: "flex",
              fontSize: "18px",
            }}
          >
            Phone Numbers
          </div>
        </div>
        <div>
          <div style={{ color: "#1B1C1D" }}>{props?.phoneNumbers?.primaryPhone} (Primary Number)</div>
        </div>

        {props?.phoneNumbers?.additionalPhones?.length > 0
          ? props?.phoneNumbers?.additionalPhones?.map((number) => {
              return (
                <div>
                  <div>{number}</div>
                </div>
              );
            })
          : ""}
        {/* <div>
          <div>(225) 555-0118</div>
        </div>
        <div>
          <div>(205) 555-0100</div>
        </div>
        <div>
          <div>(209) 555-0104</div>
        </div>
        <div>
          <div>(480) 555-0103</div>
        </div> */}
      </div>
    </>
  );
};

export default PhoneNumberList;
