import React from "react";
import { Formik, Form } from "formik";
import { Row, Col } from "reactstrap";
import * as yup from "yup";
import {
  FormCountryPicker,
  FormInput,
  FormSelect,
  FormTextArea,
} from "../common/new/forms";
import { FPButton } from "../common/new";
import { connect } from "react-redux";
import { snakeToCapital } from "../../libs/utils";
import FormAutoComplete from "../common/new/forms/FormAutoSelect";
import FPLabel from "../common/new/forms/ui/FPLabel";
import { Box } from "@material-ui/core";
import ErrorMessage from "../common/new/forms/ui/ErrorMessage";

const validationSchema = yup.object().shape({
  branch_id: yup.string().required("Please Select a Branch"),
  rating: yup.string().required("Please Select a Rating"),
  reviewer_email: yup.string().required("Enter Email").email().label("Email"),
  reviewer_name: yup
    .string()
    .required("Enter Name")
    .matches(/^[a-zA-Z\s]+$/i, "Enter a valid Name"),
  reviewer_phone: yup.string().required("Enter Mobile Number"),
  heading: yup.string(),
  description: yup.string().required().label("Description"),
});

const ReviewsForm = ({ ratingOptions, filter, onSubmit}) => {
  return (
    <Formik
      initialValues={{
        branch_id: "",
        rating: "",
        reviewer_email: "",
        reviewer_name: "",
        reviewer_phone: "",
        heading: "",
        description: "",
      }}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validationSchema={validationSchema}
    >
       {({ errors, touched, handleChange, handleBlur }) => (
      <Form>
        <Row>
          <Col sm="6" className="mt-1 mb-0">
            <Box>
              <FPLabel isRequired={true}>Branch</FPLabel>
              <FormAutoComplete
                name={"branch_id"}
                placeholder="Branch"
                options={filter.branch.choices && filter.branch.choices}
                sx={{
                  "& .MuiInputBase-input": {
                    height: 19.25,
                    color: "#646565 !important",
                    "&::placeholder": {
                      color: "#646565",
                    },
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                }}
              />
              {errors.branch_id && touched.branch_id && (
                <ErrorMessage className="mt-10">{errors.branch_id}</ErrorMessage>
              )}
            </Box>
            <Box className="mt-4">
              <FPLabel isRequired={true}>Rating</FPLabel>
              <FormAutoComplete
                name={"rating"}
                placeholder="Rating"
                options={
                  ratingOptions &&
                  ratingOptions?.map((item) => ({
                    display_name: item.label,
                    value: item.value,
                  }))
                }
                isRequired={true}
                sx={{
                  "& .MuiInputBase-input": {
                    height: 19.25,
                    color: "#646565 !important",
                    "&::placeholder": {
                      color: "#646565",
                    },
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                }}
              />
              {errors.rating && touched.rating && (
                <ErrorMessage className="mt-10">{errors.rating}</ErrorMessage>
              )}
            </Box>
            <Box className="mt-4">
              <FormInput
                name="reviewer_email"
                type="email"
                label="Email"
                isRequired={true}
              />
            </Box>
          </Col>
          <Col sm="6" className="mt-1">
            <FormInput name="reviewer_name" label="Name" />
            <FormCountryPicker
              name="reviewer_phone"
              label="Mobile Number"
              isRequired={true}
            />
            <FormInput name="heading" label="Heading" isRequired={false} />
          </Col>
          <Col sm="12" className="">
            <FormTextArea name="description" label="Description" />
          </Col>
        </Row>
        <FPButton className="w-100 b-radius-8" type="submit" label="Save" />
      </Form>
       )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    filter: state.filter,
    source: state.review.review_source_options,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  ReviewsForm
);
