import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const KeywordRankingGraph = () => {
  // Data for the vertical stacked bar chart
  const data = {
    labels: ["30 Apr", "04 May", "08 May"], // New Y-axis labels
    datasets: [
      {
        label: "1 - 3",
        data: [6, 5, 6], // Green
        backgroundColor: "#009688",
      },
      {
        label: "4 - 6",
        data: [5, 6, 5], // Light Green
        backgroundColor: "#4CAF50",
      },
      {
        label: "7 - 11",
        data: [2, 3, 2], // Yellow
        backgroundColor: "#FFC107",
      },
      {
        label: "12 - 15",
        data: [1, 0, 1], // Orange
        backgroundColor: "#FF9800",
      },
      {
        label: "16 - 20",
        data: [0, 0, 0], // Light Red
        backgroundColor: "#E53935",
      },
      {
        label: "20+",
        data: [10, 10, 10], // Red
        backgroundColor: "#B71C1C",
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Total Keywords", // New X-axis label
        },
        grid: {
          display: false, 
        },

      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Date", // New Y-axis label
        },
        grid: {
          display: false,
        },

      },
    },
  };

  return (
    <div style={{ width: "100%", padding: "20px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)", borderRadius: "10px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
        {/* <h3 style={{ margin: 0 }}>Keyword Ranking Graph</h3> */}
        <h4 style={{ margin: "0 0 10px", fontWeight: "bold" }}>
        Keyword Ranking Graph{" "}
          <span style={{ color: "#aaa", fontSize: "14px" }}>
            in 2 Categories
          </span>
        </h4>
      </div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default KeywordRankingGraph;
