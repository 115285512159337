import React from "react";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import ServiceCardHeader from "./ServiceCardHeader";
import ServiceActionBar from "./ServiceActionBar";
import { MessageBox, NoData } from "../../components/common/new";
import Highlighter from "react-highlight-words";
import PaginationComponent from "../../components/common/Pagination";
import { getParams } from "../../libs/utils";

const ServiceRequestCard = (props) => {
  const {
    ticketRequestTypeList,
    departmentList,
    actions,
    match,
    ticketService,
    locationList,
    teamList,
    location,
    count,
    pageSizeCookie,
    profileId,
  } = props;

  const search = getParams(location.search, "search") || "";

  return (
    <>
      {ticketService?.results?.length > 0 ? (
        <>
          <div className="d-flex flex-column gap-20">
            {ticketService?.results?.map((item, index) => {
              return (
                <CardWrapper className="fp-card-outline review-card-wrapper" showDivider={false} key={index}>
                  <ServiceCardHeader item={item} />
                  <div className="review-card-body">
                    <ServiceActionBar
                      item={item}
                      departmentList={departmentList}
                      ticketRequestTypeList={ticketRequestTypeList}
                      actions={actions}
                      match={match}
                      locationList={locationList}
                      teamList={teamList}
                      location={location}
                      profileId={profileId}
                    />
                    {item.description && (
                      <MessageBox isSelfBox={false}>
                        <Highlighter
                          highlightStyle={{
                            color: "green",
                            fontWeight: "bold",
                          }}
                          searchWords={[search]}
                          textToHighlight={item.description}
                        ></Highlighter>
                      </MessageBox>
                    )}
                  </div>
                </CardWrapper>
              );
            })}
          </div>
          <PaginationComponent count={count} location={location} pageSizeCookie={pageSizeCookie} />
        </>
      ) : (
        <NoData />
      )}
    </>
  );
};
export default ServiceRequestCard;
