import * as types from "../../actions/actionTypes";

export default function adsPerformanceReducer(state=[], action) {
  switch (action.type) {
    case types.GET_ENGAGEMENT_TABLE_OVERTIME_SUCCESS:
      return Object.assign({}, state, { engagementTableOvertime: action.data });
    case types.GET_ENGAGEMENT_TABLE_OVERTIME_FAILURE:
      return Object.assign({}, state, { engagementTableOvertime: []});
    case types.GET_ENGAGEMENT_TABLE_LOCATION_SUCCESS:
        return Object.assign( {}, state, { engagementTableLocation: action.data });
    case types.GET_ENGAGEMENT_TABLE_LOCATION_FAILURE:
        return Object.assign({}, state, { engagementTableLocation: []});
    case types.GET_SPENDING_TABLE_OVERTIME_SUCCESS:
      return Object.assign({}, state, { spendingTableOvertime: action.data });
    case types.GET_SPENDING_TABLE_OVERTIME_FAILURE:
      return Object.assign({}, state, { spendingTableOvertime: []});
    case types.GET_SPENDING_TABLE_LOCATION_SUCCESS:
        return Object.assign({}, state, { spendingTableLocation: action.data });
    case types.GET_SPENDING_TABLE_LOCATION_FAILURE:
        return Object.assign({}, state, { spendingTableLocation: []});

    case types.GET_DIETARY_TABLE_OVERTIME_SUCCESS:
      return Object.assign({}, state, { dietaryTableOvertime: action.data });
    case types.GET_DIETARY_TABLE_OVERTIME_FAILURE:
      return Object.assign({}, state, { dietaryTableOvertime: []});
    case types.GET_DIETARY_TABLE_LOCATION_SUCCESS:
        return Object.assign({}, state, { dietaryTableLocation: action.data });
    case types.GET_DIETARY_TABLE_LOCATION_FAILURE:
        return Object.assign({}, state, { dietaryTableLocation: []});
    default:
      return state;
  }
}