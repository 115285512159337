/* eslint-disable */
// import classNames from 'classnames';
import React from "react";
import { Label, FormGroup } from "reactstrap";
import { Input } from "semantic-ui-react";
import ErrorMessage from "./new/forms/ui/ErrorMessage";
import classNames from "classnames";

const InputComponent = (field) => {
  const {
    input,
    label,
    className,
    serverError,
    meta: { touched, error },
    minHeight,
    placeholder
  } = field;

  const handleChange = () => {
    this.props.onChange(event.target.value);
  };
  return (
    // <FormGroup style={height ? {height}: {}} className={classNames('fg-height', {className : className})}>
    <FormGroup
      style={minHeight ? { minHeight } : {}}
      className={classNames(
        {
          "with-error": (touched && error) || serverError,
        },
        className
      )}
    >
      {label && <Label>{label}</Label>}
      <div className="w-100">
        <Input
        placeholder={placeholder}
          {...field}
          {...input}
          onChange={(event) => {
            handleChange(event);
          }}
        />
        {touched && error && <ErrorMessage>{error}</ErrorMessage>}
        {serverError && <ErrorMessage>{serverError}</ErrorMessage>}
      </div>
    </FormGroup>
  );
};

export default InputComponent;
