import React from "react";
import { Popup } from "semantic-ui-react";
import "../../styles/styles.css";
import FormFeedback from "reactstrap/lib/FormFeedback";
import FPChip from "./new/FPChip/FPChip";

class TagInputComponent extends React.Component {
  state = { isOpen: false };

  handleOpen = () => {
    this.setState({ isOpen: true });

    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false });
    }, 3000);
  };

  handleClose = () => {
    this.setState({ isOpen: false });
    clearTimeout(this.timeout);
  };

  componentDidUpdate(prevProps) {
    const { word, input } = this.props;
    if (word && prevProps.word !== word) {
      if (input.value && input.value.includes(word)) {
        return null;
      }
      input.onChange([...input.value, word]);
    }
  }

  inputKeyDown = (e) => {
    const { input } = this.props;
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (
        input.value &&
        input.value.find((tag) => tag.toLowerCase() === val.toLowerCase())
      ) {
        return null;
      }
      input.onChange([...input.value, val]);
      console.log(input.value);
      this.tagInput.value = null;
    }
  };

  removeTag = (i) => {
    const { input } = this.props;
    let newTags = input.value.filter((item) => {
      return item !== input.value[i];
    });
    input.onChange(newTags);
    this.props.handleWordsChange(newTags);
  };

  render() {
    const {
      input,
      meta: { touched, error },
    } = this.props;

    return (
      <div className="input-tag">
        <Popup
          trigger={
            <input
              type="text"
              name="words"
              placeholder="Press Enter after typing a keyword"
              onKeyPress={this.inputKeyDown}
              ref={(input) => (this.tagInput = input)}
              style={{ border: "1px solid #BFC2CB" }}
              className="inputKeyword"
            />
          }
          content={"Press ENTER after typing one keyword."}
          on="click"
          size="mini"
          open={this.state.isOpen}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          position="top left"
        />
        <FormFeedback>{touched && error}</FormFeedback>
        <div className="d-flex flex-wrap align-items-center gap-15 mt-3">
          {input.value &&
            input.value.map((tag, i) => (
              <FPChip
                label={tag}
                key={i}
                onDelete={() => this.removeTag(i)}
                style={{
                  backgroundColor: "#F2F9F9",
                  color: "#438E96",
                }}
              />
            ))}
        </div>
      </div>
    );
  }
}

export default TagInputComponent;
