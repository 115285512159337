import React, { useEffect, useState } from "react";
import ListingFPInput from "../../../../../components/common/new/Listing/ListingFPInput";
import { Checkbox } from "semantic-ui-react";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";
import google_svg from "../../../../../images/googlephoto.svg";
import BusinessList from "../BusinessList";
import FPInput from "../../../../../components/common/new/forms/ui/FPInput";
import validator from "validator";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import LoaderComponent from "../../../../../components/common/loaderComponent";
import * as listingUtils from "../../../../../libs/listingToast";
const UpdateWebsiteLink = ({
  primaryDetail,
  actions,
  business_id,
  queryStringData,
  busines_status,
  loader,
  bulk_update_listing_data,
}) => {
  const selectReso = 21;

  const [showBusiness, setShowBusiness] = useState(true);
  const handleHideBusinessScreen = () => {
    setShowBusiness(false);
  };

  const handleShowBusinessScreen = () => {
    setShowBusiness(true);
  };

  const addressString = [
    primaryDetail?.addressLines,
    primaryDetail?.locality,
    primaryDetail?.postalCode,
  ].join(",");
  const [checked, setChecked] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState(primaryDetail?.websiteUri || "");
  const [orderUrl, setOrderUrl] = useState("");
  const [isOrderSameAsWebsite, setIsOrderSameAsWebsite] = useState(false);
  const [menuUrl, setMenuUrl] = useState("");
  const [isMenuSameAsWebsite, setIsMenuSameAsWebsite] = useState(false);
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    websiteUrl: "",
    orderUrl: "",
    menuUrl: "",
  });
  // const [params, setParams] = useState({ source: "", medium: "" });
  const handleSwitch = (event, value) => {
    event.preventDefault();
    setChecked(!value);
  };

  useEffect(() => {
    if (!checked) {
      setUtmSource("");
      setUtmMedium("");

      if (websiteUrl) {
        try {
          let url = new URL(websiteUrl);
          url.search = "";

          setWebsiteUrl(url.toString());
        } catch (error) {
          console.error("Invalid URL:", websiteUrl);
        }
      }
    }
  }, [checked, websiteUrl]);

  const handleWebsiteUrlChange = (event) => {
    const newWebsiteUrl = event.target.value;
    console.log("newWebsiteUrl", newWebsiteUrl);

    setWebsiteUrl(newWebsiteUrl);

    if (newWebsiteUrl && !validator.isURL(newWebsiteUrl)) {
      setErrorMessage({
        ...errorMessage,
        websiteUrl: "Please enter a valid website URL",
      });
      return;
    }
    setErrorMessage({ ...errorMessage, websiteUrl: "" });
    if (isOrderSameAsWebsite) {
      setOrderUrl(newWebsiteUrl);
    }
    if (isMenuSameAsWebsite) {
      setMenuUrl(newWebsiteUrl);
    }
  };
  const handleMenuUrlChange = (event) => {
    const newMenuUrl = event.target.value;
    setMenuUrl(newMenuUrl);
    if (newMenuUrl && !validator.isURL(newMenuUrl)) {
      setErrorMessage({
        ...errorMessage,
        menuUrl: "Please enter a valid menu URL",
      });
      return;
    }
    setErrorMessage({ ...errorMessage, menuUrl: "" });
  };

  const handleOrderAheadUrlChange = (event) => {
    const newOrderUrl = event.target.value;
    setOrderUrl(newOrderUrl);
    if (newOrderUrl && !validator.isURL(newOrderUrl)) {
      setErrorMessage({
        ...errorMessage,
        orderUrl: "Please enter a valid order URL",
      });
      return;
    }
    setErrorMessage({ ...errorMessage, orderUrl: "" });
  };

  const handleOrderCheckboxChange = (event) => {
    const { checked } = event.target;
    setIsOrderSameAsWebsite(checked);
    if (checked) {
      setOrderUrl(websiteUrl);
    } else {
      setOrderUrl("");
    }
  };

  const handleMenuCheckboxChange = (event) => {
    const { checked } = event.target;
    setIsMenuSameAsWebsite(checked);
    if (checked) {
      setMenuUrl(websiteUrl);
    } else {
      setMenuUrl("");
    }
  };

  const handleUtmSourceChange = (event) => {
    setUtmSource(event.target.value);
    updateWebsiteUrlWithUtm(websiteUrl, event.target.value, utmMedium);
  };

  const handleUtmMediumChange = (event) => {
    setUtmMedium(event.target.value);
    updateWebsiteUrlWithUtm(websiteUrl, utmSource, event.target.value);
  };

  const updateWebsiteUrlWithUtm = (awebsiteUrl, source, medium) => {
    let url = new URL(awebsiteUrl);

    url.search = "";
    if (source || medium) {
      url.search = `${source}=${medium}`;
    }

    setWebsiteUrl(url.toString());
  };

  const handleParamChange = (e, type) => {
    console.log(e.target, "targetttttt");
    console.log("typetypetypetypetypetypetype----------", type);
    const { value } = e.target;
    console.log(
      "valuevaluevaluevaluevaluevalue------------------------",
      value
    );
    if (type === "source") {
      setUtmSource(value);
    } else if (type === "medium") {
      setUtmMedium(value);
    }
  };

  // useEffect(() => {
  //   // how to add searchParams to URL
  //   const url = new URL(websiteUrl);
  //   if (utmSource && utmMedium) {
  //     url.searchParams.set(utmSource, utmMedium);
  //   } else if (utmSource && !utmMedium) {
  //     url.searchParams.delete(utmSource);
  //   }
  //   setWebsiteUrl(url.toString());
  // }, [websiteUrl, utmSource, utmMedium]);

  const [selectedBusinessCount, setSelectedBusinessCount] = useState([]); // State to hold the selected businesses count
  console.log("==================================", selectedBusinessCount);

  const handleSelectedBusinessCount = (selectBusiness) => {
    setSelectedBusinessCount(selectBusiness);
  };

  const handleWebsiteLinkUpdate = async () => {
    const restodetail = { business_id };
    let url = websiteUrl;
    let menuurl = menuUrl;

    let orderurl = orderUrl;

    // if (!menuUrl) {

    //   setErrorMessage(prevState => ({
    //     ...prevState,
    //     menuUrl: "Menu URL is required",
    //   }));
    // }
    // if (!url) {
    //   setErrorMessage(prevState => ({
    //     ...prevState,
    //     websiteUrl: "Website URL is required",
    //   }));
    // }
    // if (!orderUrl) {
    //   setErrorMessage(prevState => ({
    //     ...prevState,
    //     orderUrl: "Order ahead URL is required",
    //   }));
    // }

    if (url && utmSource && utmMedium) {
      url = new URL(websiteUrl);
      url.searchParams.set(utmSource, utmMedium);
      url = url.toString();
    }
    if (menuurl && utmSource && utmMedium) {
      menuurl = new URL(menuUrl);
      menuurl.searchParams.set(utmSource, utmMedium);
      menuurl = menuurl.toString();
    }
    if (orderurl && utmSource && utmMedium) {
      orderurl = new URL(orderUrl);
      orderurl.searchParams.set(utmSource, utmMedium);
      orderurl = orderurl.toString();
    }

    const attributes = [];

    // Add the attributes conditionally based on their values
    if (menuurl) {
      attributes.push({
        valueType: "URL",
        name: "attributes/url_menu",
        uriValues: [{ uri: menuurl }],
      });
    }

    if (orderurl) {
      attributes.push({
        valueType: "URL",
        name: "attributes/url_order_ahead",
        uriValues: [{ uri: orderurl }],
      });
    }

    const updatedCode = {
      type: "updateAttributes",
      ...(url && { website: url }),
      location_ids: selectedBusinessCount,
      ...(attributes?.length > 0 && { attributes: attributes }), // Only send if there are valid attributes
    };

    // Dispatch the action or make the API call with updatedCode
    listingUtils?.displayMessage(
      "info",
      "Listing getting updated, It may take a few minutes."
    );
    await actions?.getGmbListingBulkUpdateListing(updatedCode, restodetail);
    listingUtils?.displayMessage("close");
    if (bulk_update_listing_data) {
      listingUtils?.displayMessage("positive", "updated Successfully.");
    }

    // await actions?.getGmbListingBulkUpdateListing(updatedCode, restodetail);
    // }

    // const updatedCode = {
    //   type: "updateAttributes",
    //   website: url,
    //   location_ids: selectedBusinessCount,
    //   attributes: [
    //     {
    //       valueType: "URL",
    //       name: "attributes/url_menu",
    //       uriValues: [{ uri: menuurl }],
    //     },
    //     {
    //       valueType: "URL",
    //       name: "attributes/url_order_ahead",
    //       uriValues: [{ uri: orderurl }],
    //     },
    //   ],
    // };

    // await actions.getGmbListingBulkUpdateListing(updatedCode, restodetail);

    // listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
    // await actions.getGmbListingRefreshListing(business_id, selectReso);

    // listingUtils?.displayMessage("close",); // Close the "refresh process" toast

    // listingUtils?.displayMessage("positive", "Listing updated successfully.");
    // await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };

  return (
    <>
      <LoaderComponent loader={loader} />
      {showBusiness ? (
        <div>
          <BusinessList
            type="website_links"
            queryStringData={queryStringData}
            busines_status={busines_status}
            business_id={business_id}
            onSelectedBusinessCountChange={handleSelectedBusinessCount}
          />
          {selectedBusinessCount?.length > 0 ? (
            // <div
            //   style={{
            //     display: "flex",
            //     justifyContent: "end",
            //     marginTop: "20px",
            //   }}
            // >
            <div
              className="d-flex mt-10 mb-15"
              style={{
                display: "flex",
                justifyContent: "end",
                fontWeight: "600",
                fontSize: "24px",
                color: "#1A2544",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "20px",
              }}
            >
              <FPButton label="Next" onClick={handleHideBusinessScreen} />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-9">
            <div
              className="listing-container"
              style={{ display: "grid", gap: "32px" }}
            >
              <div style={{ display: "grid", gap: "32px" }}>
                <div className="listing-name">
                  Complete your Business Information & Sync
                </div>
                <div style={{ display: "grid", gap: "10px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Website URL{" "}
                  </div>
                  <FPInput
                    type="text"
                    placeholder={"Enter valid website URL"}
                    value={websiteUrl}
                    onChange={handleWebsiteUrlChange}
                  />
                  <span style={{ color: "red" }}>
                    {errorMessage.websiteUrl}
                  </span>
                </div>
                <div style={{ display: "grid", gap: "12px" }}>
                  <div style={{ display: "grid", gap: "4px" }}>
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#1B1C1D",
                      }}
                    >
                      Menu Link{" "}
                    </div>
                    <FPInput
                      type="text"
                      placeholder={"Enter menu link URL"}
                      value={menuUrl}
                      onChange={handleMenuUrlChange}
                      disabled={isMenuSameAsWebsite}
                      error={errorMessage.menuUrl}
                      helperText={errorMessage.menuUrl}
                    />
                    <span style={{ color: "red" }}>{errorMessage.menuUrl}</span>
                  </div>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <input
                      type="checkbox"
                      id="menu_link"
                      checked={isMenuSameAsWebsite}
                      onChange={handleMenuCheckboxChange}
                    />
                    <label
                      htmlFor="menu_link"
                      style={{
                        cursor: "pointer",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#7A8193",
                      }}
                    >
                      Same as website URL
                    </label>
                  </div>
                </div>
                <div style={{ display: "grid", gap: "12px" }}>
                  <div style={{ display: "grid", gap: "4px" }}>
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#1B1C1D",
                      }}
                    >
                      Order ahead links{" "}
                    </div>
                    <FPInput
                      type="text"
                      placeholder={"Enter Order ahead links URL"}
                      value={orderUrl}
                      onChange={handleOrderAheadUrlChange}
                      disabled={isOrderSameAsWebsite}
                      error={errorMessage.orderUrl}
                    />
                    {errorMessage.orderUrl && (
                      <span style={{ color: "red" }}>
                        {errorMessage.orderUrl}
                      </span>
                    )}
                  </div>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <input
                      type="checkbox"
                      id="order_link"
                      checked={isOrderSameAsWebsite}
                      onChange={handleOrderCheckboxChange}
                    />
                    <label
                      htmlFor="order_link"
                      style={{
                        cursor: "pointer",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#7A8193",
                      }}
                    >
                      Same as website URL
                    </label>
                  </div>
                </div>
              </div>
              <div style={{ display: "grid", gap: "32px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="listing-name">Add UTM source & Medium</div>
                  <Checkbox
                    toggle
                    checked={checked}
                    className="toggle-btn"
                    onChange={(event) => handleSwitch(event, checked)}
                  />
                </div>
                <div className="row">
                  <div
                    className="col-6"
                    style={{ display: "grid", gap: "4px" }}
                  >
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#1B1C1D",
                      }}
                    >
                      UTM Source{" "}
                    </div>
                    <FPInput
                      disabled={!checked}
                      name="source"
                      type="text"
                      placeholder={"Add UTM source eg. Google"}
                      value={utmSource}
                      onChange={(e) => handleUtmSourceChange(e, "source")}
                    />
                  </div>
                  <div
                    className="col-6"
                    style={{ display: "grid", gap: "4px" }}
                  >
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#1B1C1D",
                      }}
                    >
                      UTM Medium{" "}
                    </div>
                    <FPInput
                      disabled={!checked}
                      type="text"
                      placeholder={"Add UTM medium eg. GMB"}
                      value={utmMedium}
                      name="medium"
                      onChange={(e) => handleUtmMediumChange(e, "medium")}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "#fff",
                    zIndex: 1000,
                    // paddingBottom: "10px",
                    // paddingTop: "10px",
                    paddingBlock: "20px",
                  }}
                >
                  <div>
                    <FPButton
                      label="Back"
                      size="small"
                      onClick={handleShowBusinessScreen}
                    />
                  </div>
                  <div>
                    <FPButton
                      disabled={!menuUrl && !websiteUrl && !orderUrl}
                      label="Update URL's"
                      size="small"
                      onClick={handleWebsiteLinkUpdate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <img src={google_svg} alt="verifiedImage" width={"100%"} />
          </div>
        </div>
      )}
    </>
  );
};

// export default UpdateWebsiteLink bulk_update_listing_data

const mapStateToProps = (state) => {
  return {
    bulk_update_listing_data: state.gmbListingLocation.bulk_update_listing_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // getGmbListingUpdateListing: bindActionCreators(
  //   getGmbListingUpdateListing,
  //   dispatch
  // ),
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateWebsiteLink);
