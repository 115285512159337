import { useEffect } from "react";

export function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const handle = setTimeout(() => {
      fn(); 
    }, waitTime);

    return () => {
      clearTimeout(handle);
    };
  }, [deps]); 
}
