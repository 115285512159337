import * as types from "../actionTypes";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";

import * as commonAction from "../common/commonAction";

export function getWordCloudDataSuccess(data) {
  return { type: types.GET_WORD_CLOUD_DATA_SUCCESS, data };
}

export function getWordCloudDataFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_WORD_CLOUD_DATA_FAILURE, data };
}

export const getWordCloudData = (data) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${utils.format(URLS.INSIGHTS_WORD_CLOUD, [data.businessId])}`, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getWordCloudDataSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getWordCloudDataFailure(err));
        throw err.response.data;
      });
  };
};

export function getLocationGradingSuccess(data) {
  return { type: types.GET_LOCATION_GRADING_SUCCESS, data };
}

export function getLocationGradingFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_LOCATION_GRADING_FAILURE, data };
}

export const getLocationGrading = (data) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${utils.format(URLS.LOCATION_GRADING, [data.businessId])}`, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getLocationGradingSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getLocationGradingFailure(err));
        throw err.response.data;
      });
  };
};

export function getStaffPerformanceSuccess(data) {
  let finalData = [];

  if (data.result && data.result.length > 0) {
    finalData = data.result.map((item) => {
      const totalPositive = item.staff_members.reduce((a, b) => a + b.positive, 0) || 0;
      const totalNegative = item.staff_members.reduce((a, b) => a + b.negative, 0) || 0;
      const totalNeutral = item.staff_members.reduce((a, b) => a + b.neutral, 0) || 0;
      const total = item.staff_members.reduce((a, b) => a + b.total, 0) || 0;
      return {
        ...item,
        totalPositive,
        totalNegative,
        totalNeutral,
        total,
        totalPositivePercentage: total === 0 ? 0 : ((totalPositive / total) * 100).toFixed(2),
        totalNegativePercentage: total === 0 ? 0 : ((totalNegative / total) * 100).toFixed(2),
        totalNeutralPercentage: total === 0 ? 0 : ((totalNeutral / total) * 100).toFixed(2),
      };
    });
  }

  return { type: types.GET_STAFF_PERFORMANCE_SUCCESS, data: { ...data, result: finalData } };
}

export function getStaffPerformanceFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_STAFF_PERFORMANCE_FAILURE, data };
}

export const getStaffPerformance = (data) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(URLS.STAFF_PERFORMANCE_LIST, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getStaffPerformanceSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getStaffPerformanceFailure(err));
        throw err.response.data;
      });
  };
};

export function getNounListSuccess(data) {
  return { type: types.GET_NOUN_LIST_SUCCESS, data };
}

export function getNounListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_NOUN_LIST_FAILURE };
}

export const nounList = (data) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${utils.format(URLS.INSIGHTS_NOUN_LIST, [data.businessId])}`, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getNounListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getNounListFailure(err));
        throw err.response.data;
      });
  };
};

export function getAdjListSuccess(data) {
  return { type: types.GET_ADJ_LIST_SUCCESS, data };
}

export function getAdjListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_ADJ_LIST_FAILURE };
}

export const getAdjList = (data) => {
  const { ...params } = data;
  // const { ...nouns } = noun;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${utils.format(URLS.INSIGHTS_ADJECTIVE_LIST, [data.businessId])}`, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getAdjListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getAdjListFailure(err));
        throw err.response.data;
      });
  };
};

export function getChunkListSuccess(data) {
  return { type: types.GET_CHUNK_LIST_SUCCESS, data };
}

export function getChunkListFailure(data) {
  return { type: types.GET_CHUNK_LIST_FAILURE, data };
}

export const chunkList = (data, loadMore) => {
  let { ...params } = data;
  let url = `${utils.format(URLS.INSIGHTS_CHUNK_LIST, [data.businessId])}`;

  return (dispatch, getState) => {
    dispatch(commonAction.showLoader());
    const {
      insights: { chunk_list },
    } = getState();
    const chunks = chunk_list || { results: [] };
    if (loadMore) {
      if (!chunk_list.next) {
        throw { data: "No more reviews" };
      }
      params = {};
      url = chunk_list.next;
    }
    return axios_instance
      .get(url, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(
          getChunkListSuccess({ ...res.data, results: [...(loadMore ? chunks.results : []), ...res.data.results] })
        );
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getChunkListFailure(err));
        throw err.response.data;
      });
  };
};


export const getInsightsData = (data) => {
  getAdjList(data);
  chunkList(data, false);
  nounList(data)
}

export function getMenuPerformanceSuccess(data) {
  return { type: types.GET_MENU_PERFORMANCE_SUCCESS, data };
}

export function getMenuPerformanceFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_MENU_PERFORMANCE_FAILURE, data };
}

export const getMenuPerformance = (data) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${utils.format(URLS.INSIGHTS_MENU_PERFORMANCE, [data.business_id])}`, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getMenuPerformanceSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getMenuPerformanceFailure(err));
        throw err.response.data;
      });
  };
};
