import * as types from "../../actions/actionTypes";

const productFilterReducer = (state = [], action) => {
  switch (action.type) {
    case types.GET_PRODUCT_ITEM_SUCCESS:
      return Object.assign({}, state, { ["product_item"]: { ...action.data } });
    case types.GET_PRODUCT_ITEM_FAILURE:
      return Object.assign({}, state, { ["product_item"]: [] });

    default:
      return state;
  }
};

export default productFilterReducer;
