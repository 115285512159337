import * as types from "../../actions/actionTypes";

export default function whatsAppSection(
  state = [],
  action
) {
  switch (action.type) {
    case types.WHATS_APP_LIST_SUCCESS:
      return Object.assign({}, state, {contactList: {...action.data}});
    case types.WHATSAPP_CONTACT_DETAILS_SUCCESS:
      return Object.assign({}, state, {contactChat: {...action.data}});
    default:
      return state;
  }
}
