import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Checkbox, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SearchComponent from "../../components/common/SearchComponent";
import LoaderComponent from "../common/loaderComponent";
import "./enterpriseList.css";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SortIcon from "../../images/svg/Sort.svg";
import { FPButton, FPToolTip } from "../common/new";
import { FaPlus } from "react-icons/fa6";
import LocationFilter from "./LocationFilter";
import editIcon from "../../images/svg/Edit.svg";
import addIcon from "../../images/svg/plus.svg";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import * as URLS from "../../libs/apiUrls";
import cookie from "react-cookies";
import { displayMessage } from "../../libs/utils";
import eazydinerPreview from "../../images/eazydinerPreview.png";
import opentablePreview from "../../images/opentablePreview.png";
import deliverooPreview from "../../images/deliverooPreview.png";
const useStyles = makeStyles((theme) => ({
  popoverContainer: {
    maxHeight: "130px",
    overflowY: "auto",
    width: "250px",
    padding: theme.spacing(2),
    borderRadius: "10px",
  },
  popoverContent: {
    fontSize: "14px",
    fontWeight: 500,
  },
}));

const EnterpriseListComponent = (props) => {
  const {
    enterpriseList,
    portalData,
    businessId,
    toggle,
    toggleUserModal,
    deleteOwner,
    getStateOptions,
    sortFunction,
    ordering,
    activeUser,
    countryOption,
    stateOption,
    loader,
  } = props;

  const [teamMembers, setTeamMember] = useState([]);
  const [branchTitle, setBranchTitle] = useState([]);
  const [showProvider, setShowProvider] = useState(false);

  const sort =
    ordering && ordering[0] === "-" ? { on: ordering.slice(1), by: "descending" } : { on: ordering, by: "ascending" };
  // const getMedal = (rank) => {
  //   if (rank > 3)
  //     return rank;
  //   const iconSrc = rank === 1 ? GoldMedal : (rank === 2 ? SilverMedal : BronzeMedal);
  //   return (
  //     <img
  //       src={iconSrc}
  //       alt="" height="15"
  //       width="15"
  //       title={rank}
  //     />
  //   );
  // };
  const portalOptions = portalData.map((item) => {
    return { key: item.portal, text: item.portal, value: item.portal };
  });
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setTeamMember(item);
  };

  const [branchAnchorEl, setBranchAnchorEl] = useState(null);
  const branchOpen = Boolean(branchAnchorEl);

  const branchPopupClose = () => {
    setBranchAnchorEl(null);
  };

  const handleBranch = (event, branch) => {
    setBranchAnchorEl(event.currentTarget);
    setBranchTitle(branch);
  };

  const toggleDownloadModal = async () => {
    const {
      location: { search },
    } = props;
    const token = cookie.load("authToken");
    const url = `${URLS.BUSINESS_SETUP}${businessId}/download/${search}`;
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);

      const bodyData = JSON.stringify({
        option: "ALL",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: bodyData,
        redirect: "follow",
      };
      const res = await fetch(url, requestOptions);
      const blob = await res.blob();
      let winUrl = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = winUrl;
      a.download = "enterprise.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {
      console.log(err);
      displayMessage("negative", "Error !");
    }
  };

  return (
    <div>
      <LoaderComponent loader={loader} />
      <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
        <span
          style={{
            fontSize: "18px",
            fontWeight: "400",
            color: "rgba(100, 101, 101, 1)",
          }}
        >
          {enterpriseList && enterpriseList.length ? enterpriseList[0].business_name : ""}
        </span>
        <div className="d-flex align-items-center gap-15">
          <SearchComponent {...props} className="contacts-filter-search" startIcon placeholder="Search here" />
          {activeUser.is_business_owner &&
            activeUser.business_type !== "business" &&
            activeUser.business_type !== "marketing" && (
              <FPButton
                label="Add Location"
                size="small"
                onClick={() => toggle()}
                endIcon={<FaPlus style={{ fontSize: "14px" }} />}
              />
            )}
        </div>
      </div>
      {activeUser.business_type !== "business" && (
        <LocationFilter
          countryOption={countryOption && countryOption}
          stateOption={stateOption && stateOption}
          getStateOptions={getStateOptions}
          location={props.location}
          history={props.history}
          portalData={portalOptions}
          businessId={businessId}
          pageSizeCookie="location_page"
          downloadExcel={toggleDownloadModal}
        />
      )}

      <CardWrapper
        className="mt-4"
        showDivider={false}
        headerTitle={
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-end gap-15 my-3">
              <div className="d-flex align-items-center">
                <Box
                  component="span"
                  className="statusDot"
                  sx={{ background: "#17B26A !important" }}
                />
                <span style={{ fontSize: 14 }}>Connected</span>
              </div>
              <div className="d-flex align-items-center">
                <Box
                  component="span"
                  className="statusDot"
                  sx={{ background: "#F04438 !important" }}
                />
                <span style={{ fontSize: 14 }}>Setup Review Link</span>
              </div>
              <div className="d-flex align-items-center">
                <Box
                  component="span"
                  className="statusDot"
                  sx={{ background: "#FDB022 !important" }}
                />
                <span style={{ fontSize: 14 }}>Disconnected</span>
              </div>
            </div>

           <div style={{ display: 'flex', alignItems:'center', justifyContent: 'center', gap: 10 }}>
           <div
              style={{ fontSize: "14px", paddingBottom: "2px", color: "grey" }}
            >
              Show Provider Wise Connection Status :
            </div>
            <Checkbox
              toggle
              checked={showProvider}
              onClick={(e, value) => {
                  setShowProvider(value.checked)}
              }
            />
           </div>
          </div>
        }
      >
        <div className="my-2">
          <div className="ui-table-responsive">
            <Table className="fp-table fp-table-center">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {/* <Checkbox label='S.No' id="all" className="d-flex">Select All</Checkbox> */}
                    S.No
                  </TableCell>
                  <TableCell
                    className={`cursor-pointer ${sort.on === "location_address" ? "sortCellStyleHeader" : ""}`}
                    onClick={() => sortFunction("location_address")}
                  >
                    Name <SortIcon />
                  </TableCell>
                  <TableCell className="text-nowrap">Created By</TableCell>
                  <TableCell
                    onClick={() => sortFunction("created_at")}
                    className={`cursor-pointer text-nowrap ${
                      sort.on === "created_at" ? "sortCellStyleHeader" : ""
                    }`}
                  >
                    Created On
                    <SortIcon />
                  </TableCell>
                  <TableCell className="text-nowrap">Associated Team Member</TableCell>
                  <TableCell className="text-nowrap">Branch Tag</TableCell>
                  {showProvider && portalData &&
                    portalData.map((item, index) => {
                      return (
                        <TableCell key={index} textAlign="center">
                          <img
                            src={ item.portal === "eazydiner" ? eazydinerPreview  : item.portal === "opentable" ? opentablePreview : item.portal === "deliveroo" ? deliverooPreview : item.icon}
                            alt=""
                            height={item.portal === "foodpanda" || item.portal === "eazydiner" || item.portal === "opentable" ||item.portal === "deliveroo"  ? (item.portal === "deliveroo" ? "30":"40") :"16"}
                            width={item.portal === "foodpanda" || item.portal === "eazydiner" || item.portal === "opentable"  || item.portal === "deliveroo" ? (item.portal === "deliveroo" ? "30":"40")  :"16"}
                            className="cursor-pointer"
                            title={item.portal[0].toUpperCase() + item.portal.substr(1)}
                            style={{
                              ...((item.portal === "foodpanda" || item.portal === "eazydiner" || item.portal === "opentable" || item.portal === "deliveroo") && { objectFit: "contain" })
                            }}
                          ></img>
                        </TableCell>
                      );
                    })}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enterpriseList.map((item, index) => {
                  const owners = item.branch_owner;
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <span className="tableSpecificDataBold d-flex">{/* <Checkbox label={index + 1} /> */}</span>
                        <label className="text-center">{index + 1}</label>
                      </TableCell>
                      <TableCell>
                        <FPToolTip title={item.location_address}>
                          <Link
                            className="width-md d-flex justify-content-center"
                            to={`/${businessId}/reviews?branch=${item.id}`}
                            style={{ width: '100%' }}
                          >
                            <div
                              className={`setupLocationName ${sort.on === "location_address" ? "sortCellStyle" : ""}`}
                              style={{
                                width: showProvider ? "160px" : '300px',
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                // textAlign: "justify",
                                color: "#1B1C1D",
                              }}
                              onMouseEnter={(e) => (e.target.style.color = "blue")}
                              onMouseLeave={(e) => (e.target.style.color = "#1B1C1D")}
                            >
                              {item.branch_name}
                            </div>
                          </Link>
                        </FPToolTip>
                      </TableCell>
                      <TableCell>{item.added_by}</TableCell>
                      <TableCell className={`text-nowrap ${sort.on === "created_at" ? "sortCellStyle" : ""}`}>
                        {moment(item.created_at).format("DD-MM-YYYY")}
                      </TableCell>

                      <TableCell>
                        <div
                          className="d-flex justify-content-center cursor-pointer"
                          onClick={(e) => handleClick(e, item)}
                        >
                          {owners.slice(0, 3).map((i) => {
                            const nameParts = i.name.split(" ");
                            const initials =
                              nameParts.length > 1
                                ? nameParts[0][0] + nameParts[1][0]
                                : nameParts[0][0] + nameParts[0][1];
                            return (
                              <div className="teamMember" key={i.id}>
                                <span>{initials.toUpperCase()}</span>
                              </div>
                            );
                          })}
                          {owners.length > 3 && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: "10px",
                              }}
                            >
                              +{owners.length - 3}
                            </Box>
                          )}
                        </div>
                      </TableCell>

                      <TableCell className="width-md">
                        <div onClick={(e) => handleBranch(e, item.branch_tag_detail)}>
                          <span className="threeDot" style={{ width: "120px" }}>
                            {item.branch_tag_detail &&
                              item.branch_tag_detail.slice(0, 3).map((tags, index) => {
                                if (item.branch_tag_detail.length - 1 === index) {
                                  return tags.title;
                                } else {
                                  return `${tags.title},`;
                                }
                              })}
                          </span>
                          {item.branch_tag_detail.length > 3 && (
                            <span
                              style={{
                                marginLeft: "8px",
                                color: "#646565",
                              }}
                            >
                              {`+${item.branch_tag_detail.length - 3}`}
                            </span>
                          )}
                        </div>
                      </TableCell>
                      {showProvider && portalData &&
                        portalData.map((portal, index) => {
                          return (
                            <TableCell key={index}>
                              <Popup
                                trigger={
                                  <div className="d-flex align-items-center justify-content-center">
                                    <Link
                                      className="d-flex"
                                      to={{
                                        pathname: `${item.id}/business-profile`,
                                        state: {
                                          tab: portal.is_auth_req
                                            ? item.connections[portal.portal] === "active" ||
                                              item.connections[portal.portal] === "inactive"
                                              ? "3"
                                              : "2"
                                            : "2",
                                          social:
                                            portal.portal === "facebook"
                                              ? "fb"
                                              : portal.portal === "google"
                                              ? "ggl"
                                              : portal.portal === "booking"
                                              ? "bkng"
                                              : "twt",
                                          isHideTabs: portal.portal === "google",
                                        },
                                      }}
                                    >
                                      <span
                                        className={`status-dot ${
                                          item.connections[portal.portal] === "active"
                                            ? "bg-Green"
                                            : item.connections[portal.portal] === "inactive"
                                            ? "bg-Orange"
                                            : "bg-Red"
                                        }`}
                                      />
                                    </Link>
                                  </div>
                                }
                                content={
                                  item.connections[portal.portal] === "active"
                                    ? "Connected"
                                    : item.connections[portal.portal] === "inactive"
                                    ? "Disconnected"
                                    : "Setup Review Link"
                                }
                              />
                            </TableCell>
                          );
                        })}
                      <TableCell>
                        <div className="actionButton">
                          <FPToolTip title={"Add Owner"}>
                            <img src={addIcon} onClick={() => toggleUserModal(item)} role="button" alt="add" />
                          </FPToolTip>
                          <FPToolTip title={"Edit Location"}>
                            <img src={editIcon} onClick={() => toggle(item)} role="button" alt="delete" />
                          </FPToolTip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {!enterpriseList.length && (
                  <tr>
                    <td colSpan={10} className="text-center">
                      No Records Found
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </div>
        </div>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.popoverContainer}>
            <Typography className={`${classes.popoverContent}`}>
              Associated Team Member ({teamMembers?.branch_owner?.length})
            </Typography>
            {teamMembers?.branch_owner?.map((member, index) => {
              return (
                <div className="d-flex align-items-center justify-content-between">
                  <Typography className="mt-2">{member.name}</Typography>
                  {
                  // activeUser.id !== teamMembers.branch_owner[index].id &&
                  // teamMembers.branch_owner[index].user_type === "team_member" && 
                    activeUser.is_business_owner &&(
                      <ClearIcon
                        onClick={() => deleteOwner(teamMembers, teamMembers.branch_owner[index], handleClose)}
                        style={{
                          height: "15px",
                          width: "15px",
                          color: "#1A2C32",
                          cursor: "pointer",
                        }}
                      />
                    )}
                </div>
              );
            })}
          </div>
        </Popover>

        <Popover
          open={branchOpen}
          anchorEl={branchAnchorEl}
          onClose={branchPopupClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.popoverContainer}>
            <Typography className={`${classes.popoverContent}`}>Branch Tags ({branchTitle.length})</Typography>
            {branchTitle.map((branch) => {
              return (
                <div>
                  <Typography className="mt-2">{branch.title}</Typography>
                </div>
              );
            })}
          </div>
        </Popover>
      </CardWrapper>
    </div>
  );
};

EnterpriseListComponent.propTypes = {
  enterpriseList: PropTypes.array.isRequired,
};

export default EnterpriseListComponent;
