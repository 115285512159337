import * as types from '../../actions/actionTypes';

export default function forgotPasswordReducer(state=[], action) {
  switch (action.type) {
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        isMailSent:true
      };
    case types.FORGOT_PASSWORD_FAILURE:
      return action.data;
    default:
      return state;
  }
}
