import * as types from "../../actions/actionTypes";

export default function serviceRequestOverview(
  state = [],
  action
) {
  switch (action.type) {
    case types.SERVICE_REQUEST_OVERVIEW_SUCCESS:
      return Object.assign({}, state, {serviceRequestOverview: {...action.data}});
    case types.SERVICE_REQUEST_OVERVIEW_FAILURE:
      return Object.assign({}, state, { errors: { ...state.errors, serviceRequestOverview: { ...action.data } }});
    default:
      return state;
  }
}
