import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import cookie from 'react-cookies';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import * as CampaignActions from '../../actions/campaign/campaignActions';

import InvitedMemberList from '../../components/campaign/InvitedMemberList';
import ReviewInviteForm from '../../components/campaign/ReviewInviteForm';
import FormModal from '../../components/common/FormModal';
import LoaderComponent from '../../components/common/loaderComponent';

class InvitedMembersPage extends React.Component {
  constructor(props) {
    const pageSize = cookie.load('pageSize') || {};
    const { history, location: { pathname, search } } = props;
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.sendInvite = this.sendInvite.bind(this);
    this.followUp = this.followUp.bind(this);
    this.state = {
      isOpen: false,
      followUp: false,
    };
    const querySearch = { ...queryString.parse(search), page_size: pageSize.invited_members || 10 };
    history.replace({ pathname, search: queryString.stringify(querySearch) });
  }

  componentDidMount() {
    const { match: { params: { business_id, id, type}} } = this.props;
    if(type==='review')
    {
      this.props.actions.getInvitedMembers({ id, branch_id: business_id });
    }
    else {
      this.props.actions.getNewsLetterInvitedMembers({ id, branch_id: business_id });

    }
  }

  componentWillReceiveProps(nextProps) { // eslint-disable-line
    const { location: { search: prevSearch } } = this.props;
    const { match: { params: { business_id, id, type}},location: { search: nextSearch }  } = nextProps;
    if (prevSearch !== nextSearch) {
      if(type==='review') this.props.actions.getInvitedMembers({ id, branch_id: business_id }, queryString.parse(nextSearch));
      else this.props.actions.getNewsLetterInvitedMembers({ id, branch_id: business_id });
    }
  }

  toggleModal(){
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  sendInvite(data){
    return this.props.actions.reviewInvite(data)
      .then(() => this.toggleModal());
  }

  followUp(data){
    const { match: { params: { business_id, id }} } = this.props;
    return (this.props.actions.followUp(data,business_id,id)
      .then((res) => {
        this.props.actions.getInvitedMembers({ id, branch_id: business_id });
        return res;
      })
      .catch((err) => {
        throw err;
      })
    );
  }

  render() {
    const { campaign: { list }, match: { params: { business_id, id, type }} } = this.props;
    const { isOpen } = this.state;
    return (
      <div>
        <Row className="content-area">
          <LoaderComponent loader={this.props.loader}/>
          <Col xs={12} sm={12} className="p-3 business-setup">
            <InvitedMemberList
              list={list}
              location={this.props.location}
              type={type}
              businessId={business_id}
              campaignId={id}
              followUp={this.followUp}
            />
          </Col>
        </Row>
        {isOpen &&
          <FormModal
            isOpen={isOpen}
            toggle={this.toggleModal}
            heading="Guest Review Invite"
          >
            <ReviewInviteForm
              sendInvite={this.sendInvite}
              businessId={business_id}
            />
          </FormModal>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaign : state.campaign,
    loader: state.common.loader
  };
};


InvitedMembersPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  campaign:PropTypes.instanceOf(Object),
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(CampaignActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(InvitedMembersPage);