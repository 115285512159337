import React from "react";
import PropTypes from "prop-types";
import { isValid, isSubmitting, submit } from "redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment/moment";
import queryString from "query-string";
import { displayMessage } from "../../libs/utils";

import * as CampaignActions from "../../actions/campaign/campaignActions";
import * as ContactActions from "../../actions/contact/contactActions";
import CampaignForm from "../../components/campaign/CampaignForm";
import Contacts from "../../components/contacts/Contacts";
import ScheduleCampaign from "../../components/campaign/ScheduleCampaign";
import LoaderComponent from "../../components/common/loaderComponent";
import * as TemplateActions from "../../actions/template/templateActions";
import * as NewsLetterActions from "../../actions/newsLetter/newsLetterActions";
import { FPBreadcrumbs, FPButton, FPStepper } from "../../components/common/new";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";

const sections = ["General Details", "Add Contacts", "Review Contacts", "Send Schedule"];

class CampaignFormPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { type },
      },
      history,
    } = props;
    this.state = {
      step: 1,
      initialFilters: { filters: [{}] },
      formData: {
        name: (type === "review" ? "Review_Campaign" : "NewsLetter_Campaign") + moment().format("_DD-MM(HH:mm:ss)"),
        action: "send_immediately",
        scheduled_time: new Date(),
      },
      selectedTemplate: {},
    };
    this.contactsRef = React.createRef();
    this.getTemplateList = this.getTemplateList.bind(this);
    history.replace({ ...window.location, search: "?page_size=25" });
  }

  componentDidMount() {
    const {
      match: {
        params: { type },
      },
    } = this.props;
    if (type === "review") {
      this.getTemplateList(this.props, queryString.parse(this.props.location.search));
    } else {
      this.getNewsLetterList(this.props, queryString.parse(this.props.location.search));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location.search !== this.props.location.search ||
      (this.state.step !== prevState.step && this.state.step == 2)
    ) {
      this.getContactList();
    }
    if (this.state.step !== prevState.step && this.state.step == 3) {
      this.getSelectedContactsData();
    }
  }

  getTemplateList(props, data) {
    const {
      match: {
        params: { business_id },
      },
    } = props;
    this.props.templateAction.templateList({ ...data, branch_id: business_id }).catch((err) => console.log(err));
  }

  getNewsLetterList(props, data) {
    const {
      match: {
        params: { business_id },
      },
    } = props;
    this.props.newsletterActions
      .newsLetterList({ ...data, branch_id: business_id })
      .catch((err) => err === "lock" && this.setState({ lock: "NewsLetter Module is locked." }));
  }

  getContactList = () => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    this.props.contactActions.contactList(queryString.parse(search), business_id);
    this.props.contactActions.contactIds(queryString.parse(search), business_id);
  };

  getSelectedContactsData = () => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const {
      formData: { contacts },
    } = this.state;
    this.props.contactActions.contactList(
      {
        contacts: contacts.join(","),
        ...queryString.parse({ page_size: search.page_size || 10 }),
      },
      business_id
    );
  };

  setSelectedTemplate = (val) => {
    this.setState({ selectedTemplate: val });
  };

  submit = () => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const { formData } = this.state;
    this.props.actions
      .postCampaign(formData, business_id)
      .then(() => {
        this.setState({ step: 5 });
        // history.push(`/${business_id}/campaigns/${type}/list`);
      })
      .catch((err) => {
        this.setState({ step: 1 });
        throw err;
      });
  };

  validateContactsSelection = () => {
    const { step, formData } = this.state;
    if (this.contactsRef.current.state.allSelected || this.contactsRef.current.state.selectedContacts.length > 0) {
      this.setState({
        step: step + 1,
        formData: {
          ...formData,
          contacts: this.contactsRef.current.state.selectedContacts,
        },
      });
      return;
    }
    displayMessage("negative", "Please select atleast one contact.");
  };

  renderBtn({ label, onClick }) {
    return (
      <FPButton
        className="d-flex ml-auto"
        label={label}
        size="small"
        onClick={onClick}
        endIcon={<ArrowForwardOutlinedIcon />}
      />
    );
  }

  isNewsLatterPage() {
    return window.location.pathname.includes("/campaigns/news-letter/");
  }

  render() {
    const {
      match: { params },
      contact,
      loader,
      contactIds,
      list,
      newsLetterList,
    } = this.props;
    const { step, formData, selectedTemplate } = this.state;
    const breadCrumbList = [
      {
        label: "Campaigns",
        link: `/${params.business_id}/campaigns/review/list?page_size=10`,
      },
      {
        label: this.isNewsLatterPage() ? "News Letter" : "Review Request",
        link: this.isNewsLatterPage()
          ? `/${params.business_id}/campaigns/news-letter/list?page_size=10`
          : `/${params.business_id}/campaigns/review/list?page_size=10`,
      },
      {
        label: "Create Campaign",
        link: "",
      },
    ];

    return (
      <div style={{ maxHeight: "91vh", overflowX: "hidden" }} className="mt-2">
        <LoaderComponent loader={loader} fullScreen />
        <div>
          <FPBreadcrumbs list={breadCrumbList} className="mb-3" />
          <div className="fp-full-card bottom-radius-0">
            <p className="mb-0 fp-card-header">Create Campaign</p>
            <div className="ui divider mt-2"></div>
            <FPStepper
              steps={sections}
              activeStep={step - 1}
              onStepClick={(index) => {
                this.setState({ step: index + 1 });
              }}
            />
          </div>
          <div className="business-setup mb-3">
            {step === 1 && (
              <React.Fragment>
                <CampaignForm
                  {...params}
                  selectedTemplate={selectedTemplate}
                  setSelectedTemplate={this.setSelectedTemplate}
                  list={list ? list.results : []}
                  newsLetterList={newsLetterList ? newsLetterList.results : []}
                  initialValues={formData}
                  onSubmit={(data) =>
                    this.setState({
                      step: 2,
                      formData: { ...formData, ...data },
                    })
                  }
                  onSelectBtnContactsClick={this.props.actions.submitForm}
                />
              </React.Fragment>
            )}
            {(step === 2 || step === 3) && (
              <div className="fp-full-card top-radius-0 pt-0">
                <Contacts
                  ref={this.contactsRef}
                  location={this.props.location}
                  history={this.props.history}
                  match={this.props.match}
                  contactIds={contactIds && contactIds.ids}
                  contactList={contact && contact.results}
                  contactsCount={contact && contact.count}
                  selectedContacts={formData.contacts}
                  initialFilters={this.state.initialFilters}
                  setInitialFilters={(data) => this.setState({ initialFilters: data })}
                  contactsSelector
                  reviewContacts={step === 3}
                  tableBodyClass="top-radius-0"
                  showTabs={false}
                  campaignContacts
                />
                {step == 2 &&
                  this.renderBtn({
                    label: "Review Recipients",
                    onClick: () => this.validateContactsSelection(),
                  })}
                {step == 3 &&
                  this.renderBtn({
                    label: "Schedule",
                    onClick: () => this.validateContactsSelection(),
                  })}
              </div>
            )}
            {step === 4 && (
              <div className="fp-full-card top-radius-0">
                <ScheduleCampaign
                  schedule={formData.action}
                  scheduleTime={formData.scheduled_time}
                  onScheduleChange={(action) => this.setState({ formData: { ...formData, action } })}
                  onTimeChange={(scheduled_time) => this.setState({ formData: { ...formData, scheduled_time } })}
                  campaignTitle={formData.name}
                  goToTab={(step) => this.setState({ step })}
                  selectedContacts={formData.contacts}
                />
                {this.renderBtn({
                  label: formData.action === "send_immediately" ? "Send Campaign" : "Schedule Campaign",
                  onClick: () => this.submit(),
                })}
              </div>
            )}
            {step === 5 && (
              <div className="fp-full-card top-radius-0 text-center">
                <p style={{ fontWeight: "500", fontSize: "17px" }}>Campaign sent successfully!</p>
                <span className="font-blue-gray fp-size-14">
                  Your email campaign will be sent to your selected contacts.
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CampaignFormPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  campaign: PropTypes.instanceOf(Object),
};

function mapStateToProps(state) {
  return {
    formEnabled: isValid("CampaignForm")(state) && !isSubmitting("CampaignForm")(state),
    contact: state.contact.list,
    contactIds: state.contact.ids,
    loader: state.common.loader,
    list: state.template.list,
    newsLetterList: state.newsLetter.list,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...CampaignActions,
        submitForm: () => submit("CampaignForm"),
      },
      dispatch
    ),
    templateAction: bindActionCreators(TemplateActions, dispatch),
    newsletterActions: bindActionCreators(NewsLetterActions, dispatch),
    contactActions: bindActionCreators(ContactActions, dispatch),
  };
}

export default connect(() => mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CampaignFormPage);
