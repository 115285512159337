import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import SearchIcon from "@material-ui/icons/Search";
// import { Button, Icon } from "semantic-ui-react";
// import classNames from "classnames";
import { getParams } from "../../libs/utils";
import { FilterLines } from "../../images/svg";
import { Menu, MenuItem, ClickAwayListener, Paper, Checkbox } from "@mui/material";
import FPInput from "../../components/common/new/forms/ui/FPInput";
// import { FPToolTip } from "../../components/common/new";
// import classNames from "classnames";

const ReviewSearchBar = ({ placeholder, className, ticketPage, isSorting, startIcon, history, endIcon, size, filterIcon, location, onFilterChange }) => {

    const queryParams = queryString.parse(location.search);

    const [searchValue, setSearchValue] = useState(queryParams.search || "");
    const [ordering, setOrdering] = useState(getParams(location.search, "ordering"));
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState(
        queryParams.search_type ? queryParams.search_type.split(",") : ["ALL"]
    );

    useEffect(() => {
        setOrdering(getParams(location.search, "ordering"));
    }, [location.search]);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const toggleFilter = (value) => {
        setSelectedFilters((prevFilters) => {
            let newFilters;
            if (value === "ALL") {
                newFilters = prevFilters.includes("ALL") ? [] : ["ALL"];
            } else {
                newFilters = prevFilters.includes(value)
                    ? prevFilters.filter((filter) => filter !== value)
                    : [...prevFilters.filter((filter) => filter !== "ALL"), value];

                if (newFilters.length === 0) newFilters = ["ALL"];
            }

            updateURL(searchValue, newFilters);

            if (onFilterChange) {
                onFilterChange(newFilters);
            }

            return newFilters;
        });
    };

    const handleChange = (event) => {
        const searchText = event.target.value;
        setSearchValue(searchText);
        setTimeout(() => updateURL(searchText), 500);
    };

    const updateURL = (searchText = searchValue, filters = selectedFilters) => {
        const query = queryString.parse(location.search);
        if (query.page) delete query.page;
        const finalFilters = filters.length > 0 ? filters : ["ALL"];
        history.push({
            pathname: location.pathname,
            search: queryString.stringify({ ...query, search: searchText, search_type: finalFilters.join(",") }),
        });
    };

    // const updateOrdering = (sortOrder) => {
    //     setOrdering(sortOrder);
    //     const filters = { ...queryString.parse(location.search), ordering: sortOrder };
    //     history.push({ pathname: location.pathname, search: queryString.stringify(filters) });
    // };

    // const handleActive = (sorting) => ordering === sorting;

    const filterOptions = [
        { label: "All", value: "ALL" },
        { label: "Reviews", value: "Reviews", type: "text" },
        { label: "Order ID", value: "OrderID", type: "number" },
        { label: "Reviewer Name", value: "ReviewerName", type: "text" },
        { label: "Ticket ID", value: "TicketID", type: "number" },
    ];

    const selectedFilter = filterOptions.find((filter) => selectedFilters.includes(filter.value));
    const inputType = selectedFilter ? selectedFilter.type || "text" : "text";

    return (
        <div className={`d-flex align-items-center gap-10 fp-search-wrapper ${className}`}>
            <FPInput
                type={inputType}
                placeholder={placeholder || "Search here..."}
                onChange={handleChange}
                value={searchValue}
                style={{ fontSize: "14px" }}
                startIcon={startIcon && <SearchIcon style={{ color: "#BFC2CB", fontSize: "18px" }} />}
                endIcon={
                    filterIcon ? (
                        <div style={{ cursor: "pointer", display: "flex", alignItems: "center", position: "relative" }} onClick={handleClick}>
                            <FilterLines style={{ color: "#BFC2CB", fontSize: "18px" }} />
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} sx={{
                                "& .MuiPaper-elevation": {
                                    right: '221px',
                                    top: '238px !important',
                                    left: 'auto !important',
                                },
                                "& .MuiMenu-list": {
                                    padding: '0px'
                                },
                                "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
                                    padding: '0px',
                                }
                            }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Paper style={{ height: "auto", width: "250px" }}>
                                        {filterOptions.map((option) => (
                                            <MenuItem key={option.value} onClick={() => toggleFilter(option.value)}>
                                                <Checkbox
                                                    checked={selectedFilters.includes(option.value)}
                                                    icon={<span style={{ width: 18, height: 18, border: "2px solid #bfc2cb", borderRadius: 4 }} />}
                                                    checkedIcon={
                                                        <span style={{
                                                            width: 18,
                                                            height: 18,
                                                            borderRadius: 4,
                                                            border: "2px solid #00B5AD",
                                                            backgroundColor: "#FFFFFF",
                                                            color: "#00B5AD",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            fontWeight: "700"
                                                        }}>
                                                            ✓
                                                        </span>
                                                    }
                                                />
                                                <span>{option.label}</span>
                                            </MenuItem>
                                        ))}
                                    </Paper>
                                </ClickAwayListener>
                            </Menu>
                        </div>
                    ) : (
                        endIcon && <SearchIcon style={{ color: "#BFC2CB", fontSize: "18px" }} />
                    )
                }
                size={size}
            />
            {/* {isSorting && (
                <div className="d-flex align-items-center gap-10">
                    <FPToolTip title="Oldest">
                        <Button
                            className={classNames("review-sort-btn", { "fp-active-btn": handleActive(ticketPage ? "created_at" : "review_create") })}
                            onClick={() => updateOrdering(ticketPage ? "created_at" : "review_create")}
                        >
                            <Icon name="sort amount down" />
                        </Button>
                    </FPToolTip>
                    <FPToolTip title="Newest">
                        <Button
                            className={classNames("review-sort-btn", { "fp-active-btn": handleActive(ticketPage ? "-created_at" : "-review_create") })}
                            onClick={() => updateOrdering(ticketPage ? "-created_at" : "-review_create")}
                        >
                            <Icon name="sort amount up" />
                        </Button>
                    </FPToolTip>
                </div>
            )} */}
        </div>
    );
};

ReviewSearchBar.propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    ticketPage: PropTypes.bool,
    // isSorting: PropTypes.bool,
    startIcon: PropTypes.bool,
    endIcon: PropTypes.bool,
    size: PropTypes.string,
    filterIcon: PropTypes.bool,
};

export default ReviewSearchBar;
