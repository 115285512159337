/* eslint-disable no-unused-vars */
import React, { memo } from "react";
import { Switch, Route } from "react-router";
import { Redirect } from "react-router-dom";
import cookie from "react-cookies";
import SmartResponseEditPage from "./containers/business/SmartResponseEditPage";
import StaffEditPage from "./containers/business/StaffEditPage";
import SmartAiResponseEditPage from "./containers/business/SmartAiResponseEditPage";
import InsightsCategoryEditPage from "./containers/business/InsightsCategoryEditPage";
import ReviewsPage from "./containers/business/ReviewsPage";
import ComplaintsPage from "./containers/business/ComplaintsPage";
import MentionsPage from "./containers/business/MentionsPage";
import CompetitorsPage from "./containers/competitors/CompetitorsPage";
import ReportsPage from "./containers/reports/ReportsPage";
import BranchPages from "./containers/business/BranchPages";
import InsightsPage from "./containers/insights/InsightsPage";
import TicketsPage from "./containers/tickets/ticketsPage";
import PaymentsPage from "./containers/payments/PaymentsPage";
import ContactsPage from "./containers/contacts/ContactsList";
import ContactManageTags from "./containers/contacts/ContactsManageTags";
import CampaignPage from "./containers/campaign/CampaignPage";
import RecurringCampaignPage from "./containers/campaign/RecurringCampaignPage";
import NewsletterRecurringCampaignPage from "./containers/campaign/NewsletterRecurringCampaignPage";
import CampaignNewsLetterPage from "./containers/campaign/CampaignNewsLetterPage";
import CampaignFormPage from "./containers/campaign/CampaignFormPage";
import InvitedMembersPage from "./containers/campaign/InvitedMembers";
import NewRulesAdd from "./containers/business/NewRulesAddPage";
import Messenger from "./containers/business/MessengerPage";
import SocialPost from "./containers/business/SocialPost";
import DashboardPage from "./containers/common/DashboardPage";
import CentralUserPage from "./containers/business/CentralUserPage";
import RecurringCampaignEditPage from "./components/campaign/RecurringCampaignEditPage";
import NewsletterRecurringCampaignEditPage from "./components/campaign/NewsletterRecurringCampaignEditPage";
import MenuEditPage from "./containers/business/MenuEditPage";
import CouponsPage from "./containers/coupons/couponsPage";
import GuestReviewInvitePage from "./containers/campaign/GuestReviewInvitePage";
import ComingSoonPage from "./containers/listing/ComingSoonPage";
// import Overview from './components/listing/Overview/Overview';
import ListingDetail from "./containers/listing/ListingDetail";
import ListingOverview from "./containers/listing/ListingOverview";
import BulkUpdate from "./containers/listing/BulkUpdate";
import LocationListingDetailMenu from "./containers/listing/LocationListingDetailMenu";
import Bulkchanges from "./containers/listing/bulkupdate/bulkchangeslog/Bulkchanges";
import PerformanceComponent from "./containers/listing/Performance/PerformanceComponent";
import ConsolePage from "./containers/listing/bulkupdate/console/ConsolePage";
import BulkProduct from "./containers/listing/bulkupdate/bulkproductupdate/BulkProduct";
import BulkMenu from "./containers/listing/bulkupdate/bulkmenuupdate/BulkMenu";
// import UpdateCategory from "./components/listing/ListingDetail/UpdateCategory";
// import UpdatePhoneNumber from "./components/listing/ListingDetail/UpdatePhoneNumber";
import UpdateLocation from "./components/listing/ListingDetail/UpdateLocation";
// import UpdateBusinessStatus from "./components/listing/ListingDetail/UpdateBusinessStatus";
// import UpdateQuestion from "./components/listing/ListingDetail/UpdateQuestion";
// import UpdateAttribute from "./components/listing/ListingDetail/UpdateAttribute";
// import UpdateDescription from "./components/listing/ListingDetail/UpdateDescription";
// import UpdateOpenHour from "./containers/listing/UpdateOpenHour";
// import UpdateMorehoureTag from "./components/listing/ListingDetail/UpdateMorehoureTag";
// import UpdateOpenDate from "./components/listing/ListingDetail/UpdateOpenDate";
import UpdatePhotos from "./containers/listing/UpdatePhotos";
// import UpdateWebsitelink from "./components/listing/ListingDetail/UpdateWebsitelink";
// import UpdateAppointment from "./components/listing/ListingDetail/UpdateAppointment";
// import UpdateSocialMedia from "./components/listing/ListingDetail/UpdateSocialMedia";
import SocialComment from "./containers/business/SocialComment";
import MyProfile from "./components/accountSettings/MyProfile";
import RestroPilot from "./containers/restro-pilot/RestroPilotPage";
import SalesPage from "./containers/restro-pilot/SalesPage";
import SocialAccount from "./components/mentions/SocialAccount";
import TicketServiceRequest from "./containers/serviceRequest/TicketServiceRequest";
import DiscoveryComponent from "./containers/listing/Discovery/DiscoveryComponent";
import DaysHours from "./containers/listing/Days-Hours/DaysHours";
import KeywordsComponent from "./containers/listing/Keywords/KeywordsComponent";
import MenuMarket from "./components/restro-pilot/MenuMarket";
import OfferDiscountPage from "./containers/restro-pilot/OfferDiscountPage";
import AdsPage from "./containers/restro-pilot/AdsPage";
import ServiceRequestOverView from "./containers/serviceRequest/OverView/ServiceRequestOverView";
import AdsCampaignsPage from "./containers/restro-pilot/AdsCampaignsPage";
import GeoGridRanker from "./containers/listing/GeoGridRanker";
import KeywordPosition from "./containers/listing/keywordPosition/KeywordPosition";
// import FameAIAdsPlannerComponents from "./containers/FameAIAdsPlanner/FameAIAdsPlannerComponents";
// import SearchHistory from "./containers/FameAIAdsPlanner/SearchHistory";
import OfflineStoreAlertsComponents from "./containers/OfflineStoreAlerts/OfflineStoreAlertsComponents";
import GioScanCreadit from "./containers/listing/gioScanCreadit/GioScanCreadit";
import LockedComponent from "./components/common/LockedComponent";
import EmailComponent from "./containers/ReviewEmail/EmailComponent";
import WhatsAppMessages from "./modules/whatsApp/WhatsUAppMessages";
import whatsAppMessagesTemplate from "./modules/whatsAppMessagesTemplate/whatsAppMessagesTemplate";

const AppTemplateRouter = ({ match }) => {
  const user = cookie.load("user");
  const getComponent = (Component, key) => { return (user?.[key] === false)  ? LockedComponent : Component }
  return (
    <Switch>
      <Route exact path="/:business_id/dashboard" component={getComponent(DashboardPage, 'enable_review')} />
      <Route path="/:business_id/central-setup" component={getComponent(CentralUserPage, "enable_review")} />
      <Route path="/:business_id/smart-response" component={SmartResponseEditPage} />
      <Route path="/:business_id/staff" component={StaffEditPage} />
      <Route path="/:business_id/menu" component={MenuEditPage} />
      <Route path="/:business_id/ai-response" component={SmartAiResponseEditPage} />
      <Route path="/:business_id/new-rule" component={NewRulesAdd} />
      <Route path="/:business_id/messenger" component={Messenger} />
      <Route path="/:business_id/restro-pilot" component={getComponent(RestroPilot, 'enable_marketplace')} />
      <Route path="/:business_id/menu-market" component={getComponent(MenuMarket, 'enable_marketplace')} />
      <Route path="/:business_id/offers-discount" component={getComponent(OfferDiscountPage, 'enable_marketplace')} />
      <Route path="/:business_id/ads" component={getComponent(AdsPage, 'enable_marketplace')} />
      <Route path="/:business_id/ads-campaign" component={getComponent(AdsCampaignsPage, 'enable_marketplace')} />
      <Route path="/:business_id/sales" component={getComponent(SalesPage, 'enable_marketplace')} />
      <Route path="/:business_id/reviews" component={getComponent(ReviewsPage, "enable_review")} />
      <Route path="/:business_id/contacts/list" component={getComponent(ContactsPage, 'enable_contact_page')} />
      <Route path="/:business_id/coming-soon" component={ComingSoonPage} />
      <Route path="/:business_id/listing-overview" component={getComponent(ListingOverview, 'enable_listing_product')} />
      <Route path="/:business_id/listing-detail" component={getComponent(ListingDetail, 'enable_listing_product')} />
      <Route path="/:business_id/bulk-update" component={BulkUpdate} />
      <Route path="/:business_id/console-list" component={getComponent(ConsolePage, 'enable_listing_product')} />
      <Route path="/:business_id/bulk-changes-log" component={getComponent(Bulkchanges, 'enable_listing_product')} />
      <Route path="/:business_id/bulk-product" component={getComponent(BulkProduct, 'enable_listing_product')} />
      <Route path="/:business_id/bulk-menu" component={getComponent(BulkMenu, 'enable_listing_product')} />
      <Route path="/:business_id/Update-photos" component={UpdatePhotos} />
      <Route path="/:business_id/Update-location" component={UpdateLocation} />
      <Route path="/:business_id/social-account" component={SocialAccount} />
      <Route path="/:business_id/ticket-service-request" component={TicketServiceRequest} />
      <Route path="/:business_id/ticket-service-overview" component={ServiceRequestOverView} />

      <Route path="/:business_id/primary-listing-detail" component={LocationListingDetailMenu} />

      <Route path="/:business_id/whatsapp-messages" component={WhatsAppMessages} />
      <Route path="/:business_id/messages-template" component={whatsAppMessagesTemplate} />

      {/* Geo Grid Ranker */}
      <Route path="/:business_id/geo-grid-ranker" component={getComponent(GeoGridRanker, 'enable_listing_product')} />
      <Route path="/:business_id/geo-grid-scan" component={GeoGridRanker} />
      <Route path="/:business_id/scan-credit-history" component={getComponent(GioScanCreadit, 'enable_listing_product')} />
      <Route path="/:business_id/scan-history" component={getComponent(GeoGridRanker, 'enable_listing_product')} />
      <Route path="/:business_id/keyword-position" component={getComponent(KeywordPosition, 'enable_listing_product')} />
      <Route path="/:business_id/traffic-analysis" component={getComponent(KeywordPosition, 'enable_listing_product')} />

      {/* {Performance Component} */}
      <Route path="/:business_id/performance" component={getComponent(PerformanceComponent, 'enable_listing_product')} />
      <Route path="/:business_id/discovery" component={getComponent(DiscoveryComponent, 'enable_listing_product')} />
      <Route path="/:business_id/days-hours" component={getComponent(DaysHours, 'enable_listing_product')} />
      <Route path="/:business_id/keywords" component={getComponent(KeywordsComponent, 'enable_listing_product')} />

      {/* New Setup-Page */}
      <Route path="/:business_id/contacts/manage-tags" component={ContactManageTags} />
      <Route path="/:business_id/complaints" component={getComponent(ComplaintsPage, "enable_review")} />
      <Route path="/:business_id/mentions" component={MentionsPage} />
      <Route path="/:business_id/campaigns/review/list" component={getComponent(CampaignPage, "enable_review")} />
      <Route path="/:business_id/campaigns/news-letter/list" component={CampaignNewsLetterPage} />
      <Route path="/:business_id/campaigns/:type/add" component={CampaignFormPage} />
      <Route path="/:business_id/campaigns/:type/:id/members" component={InvitedMembersPage} />
      <Route path="/:business_id/campaigns/review/guest_Review_invite" component={GuestReviewInvitePage} />
      <Route path="/:business_id/setup-insights" component={InsightsCategoryEditPage} />
      <Route path="/:business_id/insights" component={getComponent(InsightsPage, "enable_review")} />
      <Route path="/:business_id/competitors" component={getComponent(CompetitorsPage, "enable_review")} />
      <Route path="/:business_id/tickets" component={getComponent(TicketsPage, "enable_review")} />
      <Route path="/:business_id/payments" component={PaymentsPage} />
      <Route path="/:business_id/scheduler" component={SocialPost} />
      <Route path="/:business_id/social-comments" component={SocialComment} />
      <Route path="/:business_id/coupons" component={getComponent(CouponsPage, "enable_review")} />
      <Route path="/:business_id/recurring-campaigns/review/list" component={RecurringCampaignPage} />
      <Route path="/:business_id/recurring-campaigns/news-letter/list" component={NewsletterRecurringCampaignPage} />
      <Route path="/:business_id/recurring-campaigns/review/add" component={RecurringCampaignEditPage} />
      <Route path="/:business_id/recurring-campaigns/news-letter/add" component={NewsletterRecurringCampaignEditPage} />
      <Route path="/:business_id/review-email" component={getComponent(EmailComponent, "enable_review")} />
      <Redirect exact from="/:business_id/contacts" to="/:business_id/contacts/list" />
      <Redirect exact from="/:business_id/campaigns/review" to="/:business_id/campaigns/review/list" />
      <Redirect exact from="/:business_id/bulk-update" to="/:business_id/bulk-changes-log" />
      <Route path="/:business_id/:agency_id/account-settings" component={MyProfile} />

      {/* Market place */}
      {/* <Route path="/:business_id/fameai-ads-planner" component={FameAIAdsPlannerComponents} /> */}
      {/* <Route path="/:business_id/search-history" component={SearchHistory} /> */}
      <Route path="/:business_id/offline-store-alerts" component={getComponent(OfflineStoreAlertsComponents, 'marketplace')} />

      {/* <Redirect
        exact
        from="/:business_id/campaigns/review"
        to="/:business_id/campaigns/review/list"
      />
         <Redirect
        exact
        from="/:business_id/campaigns/review"
        to="/:business_id/campaigns/review/list"
      /> */}
      <Redirect exact from="/:business_id/campaigns/news-letter" to="/:business_id/campaigns/news-letter/list" />
      <Redirect exact from="/:business_id/reports" to="/:business_id/reports/review-section/reviews/overtime" />
      <Redirect
        exact
        from="/:business_id/reports/review-section"
        to="/:business_id/reports/review-section/reviews/overtime/graph"
      />
      {/* <Redirect exact from="/:business_id/listing-detail" to="/:business_id/primary-listing-detail" /> */}
      <Redirect
        exact
        from="/:business_id/reports/review-section/reviews/overtime"
        to="/:business_id/reports/review-section/reviews/overtime/graph"
      />
      <Redirect
        exact
        from="/:business_id/reports/review-section/star/overtime"
        to="/:business_id/reports/review-section/star/overtime/graph"
      />
      <Route path="/:business_id/reports/:sectionType/:report/:reportType/:view" component={getComponent(ReportsPage, "enable_review")} />
      <Route path="/:business_id/:branch_id" component={BranchPages} />
    </Switch>
  );
};

export default memo(AppTemplateRouter);
