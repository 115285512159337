import * as types from '../../actions/actionTypes';

export default function complaintDetails(state=[], action) {
  switch (action.type) {
    case types.GET_COMPLAINT_DETAIL_SUCCESS:
      return Object.assign({},state,{ list: {...action.data}});
    case types.GET_COMPLAINT_DETAIL_FAILURE:
      return Object.assign({},state,{ list: {}});
    case types.GET_COMPLAINT_SOURCE_OPTIONS_SUCCESS:
      return Object.assign({},state,{ sources: {...action.data}});
    case types.GET_COMPLAINT_SOURCE_OPTIONS_FAILURE:
      return Object.assign({},state,{ sources: { choices: []}});
    default:
      return state;
  }
}