import React from "react";
import { Row, Col } from "reactstrap";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import cookie from "react-cookies";
import LoaderComponent from "../../components/common/loaderComponent";
import { images } from "../../images";

const APP_ID = process.env.REACT_APP_SOCIAL_AUTH_FACEBOOK_KEY;

class SetupFBMsgAccounts extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.business_id !== prevState.business_id) {
      return { business_id: nextProps.business_id };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      business_id: this.props.business_id,
      // btnText: this.props.fb_status && this.props.fb_status.connected ? "LogOut" : "Connect to Facebook",
      btnText: "Connect to Facebook",
      name: this.props.fb_status ? this.props.fb_status.name : "",
      // connected: this.props.fb_status ? this.props.fb_status.connected : false,
      connected: false,
      loader: false,
      initial_check: false,
    };
  }

  componentDidMount() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: APP_ID,
        cookie: true,
        xfbml: true,
        version: "v14.0",
      });
    }.bind(this); //eslint-disable-line

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fb_status !== this.props.fb_status) {
      this.setState({
        // btnText: this.props.fb_status && this.props.fb_status.connected ? "Log Out" : "Connect to Facebook",
        btnText: "Connect to Facebook",
        name: this.props.fb_status ? this.props.fb_status.name : "",
        // connected: this.props.fb_status ? this.props.fb_status.connected : false,
        connected: false,
        loader: false,
        initial_check: false,
      });
    }
  }

  fbLogin() {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          this.createSocialConnect(response.authResponse);
        }
      },
      {
        scope:
          "email, pages_show_list, pages_manage_metadata, pages_read_user_content, pages_manage_posts, pages_manage_engagement, pages_messaging, pages_read_engagement",
        return_scopes: true,
      }
    );
  }

  createSocialConnect(data) {
    this.setState({ loader: true });
    const req_data = {
      access_token: data.accessToken,
      user_id: data.userID,
      business_id: this.state.business_id,
    };
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    return axios_instance
      .post(`${URLS.SOCIAL_PROFILE_INTEGRATION}facebook/setup/`, req_data, { headers: headers })
      .then((res) => {
        if (res.data.connected === true) {
          this.setState({
            loader: false,
            connected: true,
            initial_check: true,
            name: res.data.name,
            btnText: "Log Out",
            business_id: this.state.business_id,
          });
          this.props.callSocialProfileApi();
          this.props.handleConnectProfile();
        } else {
          this.setState({ initial_check: true, loader: false });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        throw err.response.data;
      });
  }

  fbLogOut() {
    window.FB.logout(function (response) {
      console.log(response);
    });
    this.removeSocialConnect(this.state.business_id);
  }

  removeSocialConnect(business_id) {
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    return axios_instance
      .get(`${URLS.MESSENGER}logout/?business_id=${business_id}`, { headers: headers })
      .then((res) => {
        if (res.data.connected === false) {
          this.setState({
            connected: false,
            initial_check: true,
            name: "",
            btnText: "Connect to Facebook",
            business_id: business_id,
          });
        }
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  handleClick() {
    if (this.state.connected === false) {
      this.fbLogin();
    } else {
      this.fbLogOut();
    }
  }

  render() {
    const { connectedStatus, toggleTab } = this.props;
    const { loader } = this.state;
    return (
      <div className="my-2">
        <Row>
          <LoaderComponent loader={loader} />
          <Col>
            <div className="text-center mb-2">
              <span
                className="text-center google-button cursor-pointer"
                style={{ display: "flex", gap: "10px", borderRadius: "25px", justifyContent: "center", alignItems: "center", background: "#3975e8" }}
                onClick={() => this.handleClick()}
              >
                <div style={{ background: "white", borderRadius: "50%" }}>
                  <img src={images.facebookIcon} alt="Facebook Icon" height="25" style={{ padding: "6px" }} />
                </div>
                <span>Connect to Facebook</span>
              </span>
              <div
                className="mt-2"
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  textDecoration: "underline",
                  fontSize: "14px",
                  cursor: connectedStatus > 0 ? "pointer" : "",
                }}
                onClick={toggleTab}
              >
                {connectedStatus ? connectedStatus : this.state.connected ? 1 : 0 } profile already connected
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SetupFBMsgAccounts;
