/* eslint-disable */
import React from "react";
import { Row, Col } from "reactstrap";
import { TabContent, TabPane, Button } from "reactstrap";
import Connections from "./Connections";
import YoutubeIcon from "../../../images/youtubeNewLogo.png";
import InstagramIcon from "../../../images/instagram_logo.png";
import TwitterIcon from "../../../images/twitterNewIcon.png";
import InstagramImage1 from "../../../images/Instagram-1.png";
import InstagramImage2 from "../../../images/Instagram-2.png";
import YoutubeConnectImage from "../../../images/youtube-connection.png";
import TwitterConnectImage from "../../../images/Twitter-new.png";
import InstagramLogin from "react-facebook-login/dist/facebook-login-render-props";
import "./styles.css";
import LinkedInIcon from "../../../images/linkedin-logo.png";
import FaceBookIcon from "../../../images/facebook.png";
// import InstagramLogin from 'react-instagram-login';

class SocialConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "connections",
    };
    this.setConnectionState = this.setConnectionState.bind(this);
  }

  componentDidMount() {
    this.props.getStatus();
    window.addEventListener("message", this.setConnectionState);
  }

  componentWillUnMount() {
    window.removeEventListener("message", this.setConnectionState);
  }
  setConnectionState(event) {
    if (event.data && event.data.type === "updateTab" && event.data.tab) {
      this.setState({ activeTab: event.data.tab });
    }
  }

  responseInstagram = (response) => {
    const { postCode } = this.props;
    return postCode(response.accessToken, "instagram").then(() => {
      this.setState({ activeTab: "connections" });
    });
  };

  responseFacebook = (response) => {
    const { postCode, isLoggedIn, logout } = this.props;
    if (isLoggedIn["facebook"].status === "connected") {
      return logout("facebook");
    } else {
      return postCode(response.accessToken, "facebook").then(() => {
        this.setState({ activeTab: "connections" });
      });
    }
  };

  onConnectionClick = (id) => {
    const { isLoggedIn, logout } = this.props;
    if (isLoggedIn[id].status === "connected") {
      return logout(id);
    }
    this.setState({ activeTab: id });
  };

  onConnect = (provider) => {
    this.props.getAuthUrl(provider);
    this.setState({ activeTab: provider });
  };

  render() {
    const { isLoggedIn } = this.props;
    const connections = [
      { id: "instagram", icon: InstagramIcon, width: "55", height: "55" },
      { id: "youtube", icon: YoutubeIcon, width: "55", height: "55" },
      { id: "twitter", icon: TwitterIcon, width: "55", height: "55" },
      { id: "linkedin", icon: LinkedInIcon, width: "55", height: "55" },
      { id: "facebook", icon: FaceBookIcon, width: "55", height: "55" },
    ];
    return (
      <TabContent activeTab={this.state.activeTab} className="social-connect-buttons">
        <TabPane tabId="connections">
          <Connections
            onClick={this.onConnectionClick}
            connections={connections}
            isLoggedIn={isLoggedIn}
            responseFacebook={this.responseFacebook}
            logout={this.props.logout}
          />
        </TabPane>
        <TabPane tabId="instagram">
          <Row>
            <Col xs="12" sm="5" className="pt-10">
              <h className="card-h">HAVE YOU CONVERTED YOUR INSTAGRAM ACCOUNT TO A BUSINESS PROFILE?</h>
              <div className="h-line"></div>
              <div className="card-div">
                <p style={{ fontSize: "15px", fontWeight: "600" }}>
                  Make sure you have completed these steps before continuing
                </p>
                <ul>
                  <li>You have converted your profile to a Business Profile in the Instagram app</li>
                  <li>You are an Admin on your Facebook Page</li>
                  <li>You have attached your Facebook Page to your Instagram Business Profile</li>
                </ul>
              </div>
            </Col>
            <Col style={{ padding: "0 5px 0 0" }} xs="12" sm="7">
              <img src={InstagramImage1} width="100%" height="328px" alt="Instagram Step 1" />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="text-right">
              <Button
                onClick={() => this.setState({ activeTab: "connections" })}
                style={{ background: "#F8F0F0", color: "#8D44A1" }}
              >
                Go Back
              </Button>
              <Button
                className="ml-2"
                onClick={() => this.setState({ activeTab: "instagram1" })}
                style={{ background: "#8D44A1" }}
              >
                Yes Please!
              </Button>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="instagram1">
          <Row>
            <Col sm="7" xs="12">
              <img src={InstagramImage2} width="100%" height="100%" alt="Instagram Step 2" />
            </Col>
            <Col xs="12" sm="5" className="pt-10">
              <h className="card-h">AUTHORIZE FAMEPILOT ON FACEBOOK FOR INSTAGRAM</h>
              <div className="instagram-line"></div>
              <div className="card-div">
                {
                  "Famepilot needs permission from Facebook in order to access and publish content to Instagram on your behalf. To grant permission, you must be an admin for your brands Facebook page.We are sending you to Facebook for authorization. This is required to connect an Instagram Business Profile. Keep in mind you may need to sign out and switch to the account you want to connect."
                }
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="text-right">
              <Button
                onClick={() => this.setState({ activeTab: "instagram" })}
                style={{ background: "#F8F0F0", color: "#8D44A1" }}
              >
                Go Back
              </Button>
              <Button className="ml-2" onClick={() => this.onConnect("instagram")} style={{ background: "#FF0000" }}>
                Connect
              </Button>
              {/* <InstagramLogin
                appId={process.env.REACT_APP_SOCIAL_AUTH_FACEBOOK_KEY}
                fields="name,email,picture"
                scope="instagram_basic,pages_read_user_content,pages_manage_engagement,pages_read_engagement"
                responseType='code'
                enable_profile_selector={true}
                state={process.env.REACT_APP_SOCIAL_AUTH_FACEBOOK_SECRET}
                callback={this.responseInstagram}
                render={(renderProps) => (
                  <Button className="ml-2" onClick={renderProps.onClick} style={{ background: "#8D44A1" }}>
                    Connect
                  </Button>
                )}
                version="21.0"
              /> */}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="youtube">
          <Row>
            <Col sm="7" xs="12">
              <img src={YoutubeConnectImage} width="100%" height="100%" alt="Youtube Step" />
            </Col>
            <Col sm="5" xs="12" className="pt-10">
              <h className="card-h">AUTHORIZE FAMEPILOT ON YOUTUBE</h>
              <div className="youtube-line"></div>
              <div className="card-div">
                {"Famepilot needs permission to access and publish content to Youtube on your behalf."}
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="text-right">
              <Button
                onClick={() => this.setState({ activeTab: "connections" })}
                style={{ background: "#F8F0F0", color: "#FF0000" }}
              >
                Go Back
              </Button>
              <Button className="ml-2" onClick={() => this.onConnect("youtube")} style={{ background: "#FF0000" }}>
                Connect
              </Button>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="twitter">
          <Row>
            <Col sm="7" xs="12">
              <img src={TwitterConnectImage} width="90%" height="100%" alt="Twitter Step" />
            </Col>
            <Col sm="5" xs="12" className="pt-10">
              <h className="card-h">AUTHORIZE FAMEPILOT ON TWITTER</h>
              <div className="twitter-line"></div>
              <div className="card-div">
                {
                  "Famepilot needs permission to access and publish content to Twitter on your behalf. Add your twitter profile to monitor activity and send tweets."
                }
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="text-right">
              <Button
                onClick={() => this.setState({ activeTab: "connections" })}
                style={{ background: "#F8F0F0", color: "#55ACEE" }}
              >
                Go Back
              </Button>
              <Button className="ml-2" onClick={() => this.onConnect("twitter")} style={{ background: "#55ACEE" }}>
                Connect
              </Button>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    );
  }
}

export default SocialConnect;
