import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SortIcon from "../../../images/svg/Sort.svg";

const AssigneeStatusTable = ({ data }) => {
  const [countClick, setCountClick] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  const [currentSortKey, setCurrentSortKey] = useState("");

  const sortWithKey = (data, key) => {
    const sorted = [...data].sort((a, b) => {
      if (countClick === 0) {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });
    setCountClick(countClick === 0 ? 1 : 0);
    return sorted;
  };

  const handleSort = (key) => {
    const sorted = sortWithKey(data, key);
    setSortedData(sorted);
    setCurrentSortKey(key);
  };

  const getSortIconStyle = (key) => {
    return key === currentSortKey ? { fontWeight: "bold" } : {};
  };

  return (
    <section className="mt-3">
      <Table className="fp-table dashbord-table fp-table-center">
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: "0.6%" }} className="commonHeader">
              #
            </TableCell>
            <TableCell style={{ padding: "0.6%" }} className="commonHeader">
              Team Member
            </TableCell>
            <TableCell
              style={{ padding: "0.6%" }}
              className="commonHeader"
              onClick={() => handleSort("assigned_count")}
            >
              Assigned <SortIcon style={getSortIconStyle("assigned_count")} />
            </TableCell>
            <TableCell
              style={{ padding: "0.6%" }}
              className="commonHeader"
              onClick={() => handleSort("in_queue_count")}
            >
              In Queue <SortIcon style={getSortIconStyle("in_queue_count")} />
            </TableCell>
            <TableCell
              style={{ padding: "0.6%" }}
              className="commonHeader"
              onClick={() => handleSort("in_progress_count")}
            >
              In Progress{" "}
              <SortIcon style={getSortIconStyle("in_progress_count")} />
            </TableCell>
            <TableCell
              style={{ padding: "0.6%" }}
              className="commonHeader"
              onClick={() => handleSort("waiting_for_customer_count")}
            >
              Waiting For Customer{" "}
              <SortIcon
                style={getSortIconStyle("waiting_for_customer_count")}
              />
            </TableCell>
            <TableCell
              style={{ padding: "0.6%" }}
              className="commonHeader"
              onClick={() => handleSort("resolved_count")}
            >
              Resolved <SortIcon style={getSortIconStyle("resolved_count")} />
            </TableCell>
            <TableCell
              style={{ padding: "0.6%" }}
              className="commonHeader"
              onClick={() => handleSort("reopened_count")}
            >
              Reopened <SortIcon style={getSortIconStyle("reopened_count")} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(sortedData.length > 0 ? sortedData : data)?.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell style={{ padding: "0.5%" }}>{index + 1}</TableCell>
                <TableCell style={{ padding: "0.5%" }}>
                  {item.assignee_name}
                </TableCell>
                <TableCell style={{ padding: "0.5%" }}>
                  {item.assigned_count} ({item.assigned_percentage.toFixed(2)}%)
                </TableCell>
                <TableCell style={{ padding: "0.5%" }}>
                  {item.in_queue_count} ({item.in_queue_percentage.toFixed(2)}%)
                </TableCell>
                <TableCell style={{ padding: "0.5%" }}>
                  {item.in_progress_count} ({item.in_progress_percentage.toFixed(2)}%)
                </TableCell>
                <TableCell style={{ padding: "0.5%" }}>
                  {item.waiting_for_customer_count} (
                  {item.waiting_for_customer_percentage.toFixed(2)}%)
                </TableCell>
                <TableCell style={{ padding: "0.5%" }}>
                  {item.resolved_count} ({item.resolved_percentage.toFixed(2)}%)
                </TableCell>
                <TableCell style={{ padding: "0.5%" }}>
                  {item.reopened_count} ({item.reopened_percentage.toFixed(2)}%)
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </section>
  );
};

export default AssigneeStatusTable;
