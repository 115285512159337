import React from "react";
import LoaderComponent from "../loaderComponent";
import { Doughnut } from "react-chartjs-2";

const Donut = (props) => {
  const { isLoading, options, data, centerText, isAdsPage, cutout, ...rest } = props;

  const plugins = [
    {
      beforeDraw: function (chart) {
        if (!!centerText?.toString()) {
          let ctx = chart.ctx;
          let chartArea = chart.chartArea;
          let text = centerText.text;
          let caption = centerText.label;

          // Calculate the font size based on the chart size
          let fontSizeText = Math.min(
            (chartArea.right - chartArea.left) / 10,
            25
          );
          let fontSizeCaption = Math.min(
            (chartArea.right - chartArea.left) / 20,
            16
          );

          ctx.restore();
          ctx.font = `500 ${fontSizeText}px Outfit`;
          ctx.fillStyle = isAdsPage ? "#ff1493" : "#1B1C1D" ;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";

          let textX = (chartArea.left + chartArea.right) / 2;
          let textY = (chartArea.top + chartArea.bottom) / 2 - 5;
          ctx.fillText(text, textX, textY);
          ctx.save();

          let ctx2 = chart.ctx;
          ctx2.restore();
          ctx2.font = `400 ${fontSizeCaption}px Outfit`;
          ctx2.fillStyle = "#7A8193";
          ctx2.textAlign = "center";
          ctx2.textBaseline = "middle";

          let captionX = (chartArea.left + chartArea.right) / 2;
          let captionY = (chartArea.top + chartArea.bottom + 30) / 2 + 5;
          if (centerText.label) {
            ctx2.fillText(caption, captionX, captionY);
            ctx2.save();
          }
        }
      },
    },
  ];
  return (
    <React.Fragment>
      {isLoading ? (
        <LoaderComponent loader={isLoading} rounded inline />
      ) : (
        <Doughnut
          data={data}
          plugins={plugins}
          options={{
            aspectRatio: 2,
            cutout: cutout,
            responsive: false,
            maintainAspectRatio: false,
            ...options,
          }}
          {...rest}
        />
      )}
    </React.Fragment>
  );
};

export default Donut;
