import React, { useEffect, useState, forwardRef } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Box, Dialog, DialogActions, IconButton, Slide } from "@mui/material";
import AddMenu from "./AddMenu";
import CloseIcon from "@material-ui/icons/Close";
import google_image from "../../../../images/google.png";
import burger_image from "../../../../images/burger.jpg";
import vegetarian_img from "../../../../images/greenLeaf.jpeg";
import AddIcon from "@mui/icons-material/Add";
import FPButton from "../../../common/new/FPFilter/Listing/FPButton";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../../actions/listing/listingLocationAction";
import AddMenuSection from "./AddMenuSection";
import { getCurrencySymbol } from "../../../../libs/helper";
import EditMenu from "./EditMenu";
import { FaEdit } from "react-icons/fa";
import EditMenuSection from "./EditMenuSection";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import CreateMenu from "./CreateMenu";
import * as URLS from "../../../../libs/apiUrls";
import EditIconSVG from "../../../../images/svg/EditIconSVG.svg";
import deleteIconSVG from "../../../../images/svg/deleteIconSVG.svg";
import { FPToolTip } from "../../../common/new";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import MuiAccordion from "@material-ui/core/Accordion";
import { withStyles } from "@material-ui/core/styles";

import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const FullMenu = (props) => {
  const { business_id, selectReso, actions, location_food_menu_data } = props;

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    actions.getGmbLocationFoodmenu(business_id, selectReso);
  }, []);

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [openTab, setOpenTab] = React.useState(null);
  const [indexValue, setIndexValue] = React.useState(0);
  const [sectionIndexValue, setSectionIndexValue] = React.useState(0);
  const [menuIndexValue, setMenuIndexValue] = React.useState(0);

  const handleClickOpen = (dialog, index) => {
    setOpenTab(dialog);
    setIndexValue(index);
    setSectionIndexValue(index);
    setOpen(true);
  };

  const handleOpenEdit = (dialog, itemindex, sectionIndex, menuIndex) => {
    setOpenTab(dialog);
    setIndexValue(itemindex);
    setSectionIndexValue(sectionIndex);
    setMenuIndexValue(menuIndex);
    setOpen(true);
  };

  // const handleDeleteMenuItem = async (itemIndex, sectionIndex, menuIndex) => {
  //     console.log("Deleting item", itemIndex, sectionIndex, menuIndex);

  //     const updatedMenuData = { ...location_food_menu_data };
  //     const restodetail = { business_id, selectReso };

  //     if (
  //         updatedMenuData?.menus?.[menuIndex]?.sections?.[sectionIndex]?.items?.[itemIndex]
  //     ) {
  //         // itemIndex > 0 ?
  //         //     updatedMenuData.menus[menuIndex].sections[sectionIndex].items.splice(itemIndex, 1)
  //         //     : updatedMenuData.menus[menuIndex].sections.splice(sectionIndex, 1)
  //         // : updatedMenuData.menus[menuIndex].sections[sectionIndex].items.splice(itemIndex, 1)
  //         console.log("updatedMenuData.menus[menuIndex].sections[sectionIndex]?.length", updatedMenuData.menus[menuIndex].sections?.length)
  //         console.log("updatedMenuData.menus[menuIndex].sections[sectionIndex].items", updatedMenuData.menus[menuIndex].sections[sectionIndex].items)
  //         updatedMenuData.menus[menuIndex].sections[sectionIndex].items === 1 ?
  //             updatedMenuData.menus[menuIndex].sections.splice(sectionIndex, 1)
  //             : updatedMenuData.menus[menuIndex].sections[sectionIndex].items.splice(itemIndex, 1)

  //         const updateCode = {
  //             type: "updateMenu",
  //             updateMenuData: updatedMenuData,
  //         };

  //         Swal.fire({
  //             title: 'Are you sure?',
  //             text: `Do you want to delete this Item?`,
  //             icon: 'warning',
  //             showCancelButton: true,
  //             confirmButtonText: 'Ok',
  //             cancelButtonText: 'Cancel'
  //         }).then(async (result) => {
  //             if (result.isConfirmed) { // Clear selected photos after deletion
  //                 await actions?.getGmbListingUpdateListing(updateCode, restodetail);
  //                 await actions.getGmbLocationFoodmenu(business_id, selectReso);
  //             }
  //         });

  //     } else {
  //         console.log("Item not found for deletion");
  //     }
  // };

  const handleDeleteMenuItem = async (itemIndex, sectionIndex, menuIndex) => {
    console.log("Deleting item", itemIndex, sectionIndex, menuIndex);

    const updatedMenuData = { ...location_food_menu_data?.food_menus };
    const restodetail = { business_id, selectReso };

    // if (
    //     updatedMenuData?.menus?.[menuIndex]?.sections?.[sectionIndex]?.items?.[itemIndex]
    // ) {
    const sectionItems = updatedMenuData.menus[menuIndex].sections[sectionIndex].items;
    const menuSection = updatedMenuData.menus[menuIndex].sections;

    if (menuSection.length === 1 && sectionItems.length === 1) {
      updatedMenuData.menus.splice(menuIndex, 1);
    }
    // If the section has only one item, delete the entire section
    else if (sectionItems.length === 1) {
      updatedMenuData.menus[menuIndex].sections.splice(sectionIndex, 1);
    } else {
      // Otherwise, just delete the specific item
      sectionItems.splice(itemIndex, 1);
    }

    const updateCode = {
      type: "updateMenu",
      updateMenuData: updatedMenuData,
    };

    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to delete this Item?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await actions?.getGmbListingUpdateListing(updateCode, restodetail);
        await actions.getGmbLocationFoodmenu(business_id, selectReso);
      }
      // else {
      //     await actions.getGmbLocationFoodmenu(business_id, selectReso);
      // }
    });

    // } else {
    //     console.log("Item not found for deletion");
    // }
  };

  const handleOpenEditMenuSection = (dialog, sectionIndex, menuIndex) => {
    setOpenTab(dialog);
    setSectionIndexValue(sectionIndex);
    setMenuIndexValue(menuIndex);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };
  const [expandedSection, setExpandedSection] = useState(null);

  const handleAccordionChange = (index) => {
    setExpandedSection(expandedSection === index ? null : index);
  };

  const Accordion = withStyles({
    root: {
      boxShadow: "none",
      position: "unset",
    },
    expanded: {
      margin: "0 !important",
    },
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      boxShadow: "none",
      border: "unset",
      borderRadius: "5px",
      height: "45px !important",
      minHeight: "auto !important",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiSvgIcon-root.minus": {
        display: "none",
      },
      "& .MuiSvgIcon-root.add": {
        display: "block",
      },
    },
    content: {
      display: "flex",
      alignItems: "center",
      margin: "0px !important",
      fontSize: "14px",
      fontWeight: "400",
      width: "100%",
    },
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "10px",
      alignItems: "flex-start",
      borderTop: "1px solid #dee2e6",
      "& .MuiPaper-root": {
        margin: 0,
      },
    },
  }))(MuiAccordionDetails);

  return (
    <>
      {!location_food_menu_data?.food_menus?.menus ? (
        <div
          className="card-item special-card shadow-sm"
          style={{ cursor: "pointer", maxWidth: "fit-content", margin: "10px" }}
          onClick={() => handleClickOpen("create_menu")}
        >
          <div
            style={{
              padding: "10px 0px 10px 0px",
              display: "flex",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                background: "#e9e9e9",
                borderRadius: "5px",
                padding: "3px",
              }}
            >
              <AddIcon style={{ width: "20px", height: "20px", color: "gray" }} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                color: "gray",
              }}
            >
              <span style={{ fontSize: "16px", fontWeight: "500" }}>Create a Menu</span>
              {/* <span style={{ fontSize: "20px", fontWeight: "500" }}>Menu</span> */}
            </div>
          </div>
        </div>
      ) : (
        location_food_menu_data?.food_menus?.menus?.map((menus, menuIndex) => {
          return (
            <div key={menuIndex}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  border: "1px solid #dee2e6",
                  borderRadius: "10px",
                  padding: "5px",
                  marginTop: "20px",
                }}
              >
                <div style={{ border: "1px solid #dee2e6", borderRadius: "10px" }}>
                  <img style={{ height: "40px", width: "auto", padding: "5px" }} src={google_image} alt="GoogleImage" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    gap: "5px",
                  }}
                >
                  <div>
                    <span style={{ fontSize: "15px", fontWeight: "400" }}>{menus?.labels?.[0]?.displayName}</span>
                  </div>
                  <div style={{ fontSize: "15px", fontWeight: "400" }}>{menus?.labels?.[0]?.description}</div>
                </div>
              </div>

              {/* <div style={{ border: "1px solid #dee2e6", borderRadius: "10px"}}></div> */}
              {menus?.sections?.map((sections, sectionIndex) => {
                return (
                  <Accordion
                    expanded={expandedSection === sectionIndex}
                    onChange={() => handleAccordionChange(sectionIndex)}
                    style={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      borderBottom: "1px solid #dee2e6",
                    }}
                    sx={{
                      "& .Mui-expanded": {
                        minHeight: "30px !important",
                      },
                      '&[class*="MuiPaper-root-MuiAccordion-root"]::before': {
                        backgroundColor: "transparent !important",
                      },
                    }}
                  >
                    <AccordionSummary
                      aria-controls={`menu-section-${sectionIndex}-content`}
                      id={`menu-section-${sectionIndex}-header`}
                      classes={{
                        content: "accordion-summary-content",
                      }}
                      sx={{
                        backgroundColor: "transparent",
                        "& .Mui-expanded": {
                          margin: "0px !important",
                        },
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div className="menu-section-header">
                          <h2 className="menu-section-title">{sections?.labels?.[0]?.displayName}</h2>
                          <FPToolTip title="Edit">
                            <FaEdit
                              className="menu-section-edit-icon"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleOpenEditMenuSection("edit_menu_section", sectionIndex, menuIndex);
                              }}
                            />
                          </FPToolTip>
                        </div>

                        <div>
                          {expandedSection === sectionIndex && (
                            <FPButton
                              startIcon={<AddIcon />}
                              onClick={() => handleClickOpen("menu_item", sectionIndex)}
                              label="Add menu item"
                              size="small"
                            />
                          )}
                        </div>
                      </div>
                    </AccordionSummary>

                    <AccordionDetails>
                      {sections?.items?.map((items, itemindex) => {
                        const foundItem = location_food_menu_data?.media_data?.find(
                          (item) => item.mediakey === items?.attributes?.mediaKeys?.[0]
                        );

                        return (
                          <React.Fragment key={itemindex}>
                            <div
                              className="full-menu-header"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                borderTop: itemindex > 0 ? "1px solid #dee2e6" : "none",
                                paddingTop: itemindex > 0 ? "6px" : "0px",
                              }}
                            >
                              <div>
                                <h2 className="menu-title">{items?.labels?.[0]?.displayName}</h2>
                              </div>
                              <div className="menu-actions" style={{ display: "flex", gap: "10px" }}>
                                <div
                                  onClick={() => handleOpenEdit("edit_menu", itemindex, sectionIndex, menuIndex)}
                                  className="menu-icon"
                                >
                                  <FPToolTip title="Edit">
                                    <img src={EditIconSVG} role="button" alt="Edit" className="menu-icon-image" />
                                  </FPToolTip>
                                </div>

                                <div
                                  onClick={() => handleDeleteMenuItem(itemindex, sectionIndex, menuIndex)}
                                  className="menu-icon"
                                >
                                  <FPToolTip title="Delete">
                                    <img src={deleteIconSVG} role="button" alt="Delete" className="menu-icon-image" />
                                  </FPToolTip>
                                </div>
                              </div>
                            </div>

                            <div className="menu-content" style={{ display: "flex", gap: "10px" }}>
                              <div className="menu-description" style={{ flexGrow: 1 }}>
                                <div className="menu-description-inner">
                                  <span className="menu-subtitle">{items?.labels?.[0]?.description}</span>

                                  <div className="menu-dietary-info">
                                    {items?.attributes?.dietaryRestriction?.[0] && (
                                      <>
                                        <img className="dietary-icon" src={vegetarian_img} />
                                        <span className="dietary-text">
                                          {capitalizeFirstLetter(items?.attributes?.dietaryRestriction?.[0])}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="menu-price">
                                  <h2 style={{ fontSize: "17px" }}>
                                    {getCurrencySymbol(items?.attributes?.price?.currencyCode)}
                                    {items?.attributes?.price?.units}
                                  </h2>
                                </div>
                              </div>

                              <div>
                                {foundItem?.thumbnailUrl && (
                                  <img
                                    className="menu-thumbnail"
                                    src={foundItem?.thumbnailUrl ? foundItem?.thumbnailUrl : ""}
                                    // src={`${URLS.GMB_LISTING_LOCATION}/proxy-image?url=${foundItem?.thumbnailUrl}`}
                                    alt="Menu Item"
                                    referrerPolicy="no-referrer"
                                  />
                                )}
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}

              {/* <div style={{ border: "1px solid #dee2e6", borderRadius: "10px", marginTop: "20px" }}></div> */}

              <div style={{ marginTop: "20px" }}>
                <FPButton
                  startIcon={<AddIcon />}
                  onClick={() => handleClickOpen("menu_section", menuIndex)}
                  label="Add menu section"
                  size="small"
                />
              </div>
            </div>
          );
        })
      )}

      {openTab === "create_menu" && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          fullWidth
          TransitionComponent={Transition}
          sx={{
            "& .MuiDialog-paper": {
              position: "fixed",
              right: 0,
              margin: 0,
              maxHeight: "100vh",
              width: "80%",
            },
          }}
        >
          <Box sx={style}>
            <div style={{ padding: "20px" }}>
              <div
                className="d-flex mb-10"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "600",
                  fontSize: "20px",
                  color: "#1A2544",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  alignItems: "center",
                  // paddingBlock: "20px"
                }}
              >
                <span>Create Menu</span>
                <CloseIcon onClick={handleClose} />
                {/* <DialogActions>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions> */}
              </div>
              <CreateMenu
                business_id={business_id}
                handleClose={handleClose}
                indexValue={indexValue}
                selectReso={selectReso}
                location_food_menu_data={location_food_menu_data?.food_menus}
              />
            </div>
          </Box>
        </Dialog>
      )}

      {openTab === "menu_item" && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          fullWidth
          TransitionComponent={Transition}
          sx={{
            "& .MuiDialog-paper": {
              position: "fixed",
              right: 0,
              margin: 0,
              maxHeight: "100vh",
              width: "80%",
            },
          }}
        >
          <Box sx={style}>
            <div style={{ padding: "20px" }}>
              <div
                className="d-flex mb-15"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontWeight: "600",
                  fontSize: "22px",
                  color: "#1A2544",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  // paddingBlock: "20px"
                }}
              >
                <span>Add Menu Item</span>
                <CloseIcon onClick={handleClose} />
                {/* <DialogActions>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions> */}
              </div>
              <AddMenu
                business_id={business_id}
                handleClose={handleClose}
                indexValue={indexValue}
                sectionIndexValue={sectionIndexValue}
                menuIndexValue={menuIndexValue}
                selectReso={selectReso}
                location_food_menu_data={location_food_menu_data?.food_menus}
              />
            </div>
          </Box>
        </Dialog>
      )}
      {openTab === "edit_menu" && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          fullWidth
          TransitionComponent={Transition}
          sx={{
            "& .MuiDialog-paper": {
              position: "fixed",
              right: 0,
              margin: 0,
              maxHeight: "100vh",
              width: "80%",
            },
          }}
        >
          <Box sx={style}>
            <div style={{ padding: "20px" }}>
              <div
                className="d-flex mb-15"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "600",
                  fontSize: "20px",
                  color: "#1A2544",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  // paddingBlock: "20px"
                }}
              >
                <span>Edit Menu Item</span>
                <CloseIcon onClick={handleClose} />
                {/* <DialogActions>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions> */}
              </div>
              <EditMenu
                business_id={business_id}
                handleClose={handleClose}
                indexValue={indexValue}
                sectionIndexValue={sectionIndexValue}
                menuIndexValue={menuIndexValue}
                selectReso={selectReso}
                location_food_menu_data={location_food_menu_data?.food_menus}
              />
            </div>
          </Box>
        </Dialog>
      )}
      {openTab === "edit_menu_section" && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          fullWidth
          TransitionComponent={Transition}
          sx={{
            "& .MuiDialog-paper": {
              position: "fixed",
              right: 0,
              margin: 0,
              maxHeight: "100vh",
              width: "80%",
            },
          }}
        >
          <Box sx={style}>
            <div style={{ padding: "20px" }}>
              <div
                className="d-flex mt-10 mb-15"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "600",
                  fontSize: "20px",
                  color: "#1A2544",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  alignItems: "center",
                  // paddingBlock: "20px"
                }}
              >
                <span>Edit Menu Section</span>
                <CloseIcon onClick={handleClose} />
                {/* <DialogActions>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions> */}
              </div>
              <EditMenuSection
                business_id={business_id}
                handleClose={handleClose}
                indexValue={indexValue}
                sectionIndexValue={sectionIndexValue}
                menuIndexValue={menuIndexValue}
                selectReso={selectReso}
                location_food_menu_data={location_food_menu_data?.food_menus}
              />
            </div>
          </Box>
        </Dialog>
      )}
      {openTab === "menu_section" && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          fullWidth
          TransitionComponent={Transition}
          sx={{
            "& .MuiDialog-paper": {
              position: "fixed",
              right: 0,
              margin: 0,
              maxHeight: "100vh",
              width: "80%",
            },
          }}
        >
          <Box sx={style}>
            <div style={{ padding: "20px" }}>
              <div
                className="d-flex mb-10"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "600",
                  fontSize: "20px",
                  color: "#1A2544",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  alignItems: "center",
                  // paddingBlock: "20px"
                }}
              >
                <span>Add Menu Section</span>
                <CloseIcon onClick={handleClose} />
                {/* <DialogActions>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions> */}
              </div>
              <AddMenuSection
                business_id={business_id}
                handleClose={handleClose}
                indexValue={indexValue}
                sectionIndexValue={sectionIndexValue}
                menuIndexValue={menuIndexValue}
                selectReso={selectReso}
                location_food_menu_data={location_food_menu_data}
              />
            </div>
          </Box>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    location_food_menu_data: state.gmbListingLocation.location_food_menu_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FullMenu);
