import React, { useState } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { ListDescription } from "semantic-ui-react";
import FPInput from "../../common/new/forms/ui/FPInput";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import "./listing.css";
import google_svg from "../../../images/googlephoto.svg";
import instagram_png from "../../../images/instagram_logo.png";
import facebook_png from "../../../images/facebook.png";
import twitter_png from "../../../images/twitter.png";
import youtube_png from "../../../images/youtube-logo.png";
import linkedin_png from "../../../images/linkedin-logo.png";
import { formatReviews } from "../../../libs/helper";
import duplicate_svg from "../../../images/dusplicate.svg";
import validator from "validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import * as listingUtils from "../../../libs/listingToast";

const UpdateSocialMedia = ({ primaryDetail, business_id, selectReso, actions }) => {
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [youtube, setYoutube] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [twitter, setTwitter] = useState("");
  const [errors, setErrors] = useState({});

  const isValidUrl = (url) => {
    return validator.isURL(url);
  };

  const handleInputChange = (platform, value) => {
    const newErrors = { ...errors };

    if (value && !isValidUrl(value)) {
      newErrors[platform] = "Invalid URL";
    } else {
      delete newErrors[platform];
    }

    setErrors(newErrors);

    switch (platform) {
      case "instagram":
        setInstagram(value);
        break;
      case "facebook":
        setFacebook(value);
        break;
      case "youtube":
        setYoutube(value);
        break;
      case "linkedin":
        setLinkedin(value);
        break;
      case "twitter":
        setTwitter(value);
        break;
      default:
        break;
    }
  };

  const handleUpdate = async () => {
    const restodetail = { business_id, selectReso };
    if (Object.keys(errors).length === 0) {
      // const actionLinks = {
      //   instagram,
      //   facebook,
      //   youtube,
      //   linkedin,
      //   twitter,
      // };
      const actionLinks = [];
      if (facebook) {
        actionLinks?.push({
          name: "attributes/url_facebook",
          valueType: "URL",
          // values: [facebook],
          uriValues: [{ uri: facebook }],
        });
      }
      if (instagram) {
        actionLinks?.push({
          name: "attributes/url_instagram",
          valueType: "URL",
          uriValues: [{ uri: instagram }],
        });
      }
      if (linkedin) {
        actionLinks?.push({
          name: "attributes/url_linkedin",
          valueType: "URL",
          // values: [linkedin],
          uriValues: [{ uri: linkedin }],
        });
      }
      if (twitter) {
        actionLinks?.push({
          name: "attributes/url_twitter",
          valueType: "URL",
          // values: [twitter],
          uriValues: [{ uri: twitter }],
        });
      }
      if (youtube) {
        actionLinks?.push({
          name: "attributes/url_youtube",
          valueType: "URL",
          // values: [youtube],
          uriValues: [{ uri: youtube }],
        });
      }

      const updatedCode = {
        type: "updateAttributes",
        ...(actionLinks?.length > 0 && { attributes: actionLinks }),
      };

      // const result = {
      //   type: "actionLinks",
      //   actionLinks,
      // };

      // console.log("Payload:", result);
      await actions.getGmbListingUpdateListing(updatedCode, restodetail);
      listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
      await actions.getGmbListingRefreshListing(business_id, selectReso);

      listingUtils?.displayMessage("close"); // Close the "refresh process" toast

      listingUtils?.displayMessage("positive", "Listing updated successfully.");
      await actions.getGmbListingPrimaryDetail(business_id, selectReso);
    }
  };

  const addressString = [primaryDetail?.addressLines, primaryDetail?.locality, primaryDetail?.postalCode].join(", ");

  return (
    <>
      <div className="listing-container" style={{ overflow: "hidden", gap: "15px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <div className="listing-header">
            <div className="listing-info">
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="listing-name">{primaryDetail?.title}</div>
                <img
                  src={
                    primaryDetail?.is_varified
                      ? verified_svg
                      : primaryDetail?.is_suspend
                      ? duplicate_svg
                      : primaryDetail?.is_duplicate
                      ? duplicate_svg
                      : ""
                  }
                  alt="verifiedImage"
                />
              </div>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(1)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{formatReviews(primaryDetail?.totalReviewCount)} Reviews</span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>{addressString}</ListDescription>
          </div>
        </div>
      </div>
      <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "calc(100% - 120px)",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "15px",
                paddingBottom: "5px",
              }}
            >
              <div className="listing-name">Complete your Business Information & Sync All Social Media Links</div>
              <div style={{ display: "grid", gap: "6px" }}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <img src={instagram_png} style={{ height: "20px", width: "20px" }} alt="instagramPng" />
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Instagram
                  </div>
                </div>
                <FPInput
                  type="text"
                  placeholder={"Enter your Instagram"}
                  value={instagram}
                  onChange={(e) => handleInputChange("instagram", e.target.value)}
                  error={errors.instagram}
                />
                {errors.instagram && <p style={{ color: "red" }}>Please enter a valid URL</p>}
              </div>
              <div style={{ display: "grid", gap: "4px" }}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <img src={facebook_png} style={{ height: "20px", width: "20px" }} alt="facebookPng" />
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Facebook
                  </div>
                </div>
                <FPInput
                  type="text"
                  placeholder={"Enter your Facebook"}
                  value={facebook}
                  onChange={(e) => handleInputChange("facebook", e.target.value)}
                  error={errors.facebook}
                />
                {errors.facebook && <p style={{ color: "red" }}>Please enter a valid URL</p>}
              </div>
              <div style={{ display: "grid", gap: "4px" }}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <img src={youtube_png} style={{ height: "20px", width: "20px" }} alt="youtubePng" />
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Youtube
                  </div>
                </div>
                <FPInput
                  type="text"
                  placeholder={"Enter your Youtube"}
                  value={youtube}
                  onChange={(e) => handleInputChange("youtube", e.target.value)}
                  error={errors.youtube}
                />
                {errors.youtube && <p style={{ color: "red" }}>Please enter a valid URL</p>}
              </div>
              <div style={{ display: "grid", gap: "4px" }}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <img src={linkedin_png} style={{ height: "20px", width: "20px" }} alt="linkedinPng" />
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Linkedin
                  </div>
                </div>
                <FPInput
                  type="text"
                  placeholder={"Enter your Linkedin"}
                  value={linkedin}
                  onChange={(e) => handleInputChange("linkedin", e.target.value)}
                  error={errors.linkedin}
                />
                {errors.linkedin && <p style={{ color: "red" }}>Please enter a valid URL</p>}
              </div>
              <div style={{ display: "grid", gap: "4px" }}>
                <div style={{ display: "flex", gap: "8px" }}>
                  <img src={twitter_png} style={{ height: "20px", width: "20px" }} alt="twitterPng" />
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Twitter
                  </div>
                </div>
                <FPInput
                  type="text"
                  placeholder={"Enter your Twitter"}
                  value={twitter}
                  onChange={(e) => handleInputChange("twitter", e.target.value)}
                  error={errors.twitter}
                />
                {errors.twitter && <p style={{ color: "red" }}>Please enter a valid URL</p>}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
              }}
            >
              <FPButton
                label="Update"
                size="small"
                onClick={handleUpdate}
                disabled={Object.keys(errors).length > 0 || primaryDetail?.is_suspend}
              />
            </div>
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

// export default UpdateSocialMedia;
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(UpdateSocialMedia);
