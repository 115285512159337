import React from 'react';
import { Button } from '@material-ui/core';
import { alpha, withStyles } from '@material-ui/core/styles';

const DefaultButton = withStyles({
  root: {
    background: ({ backgroundColor, variant }) =>
      backgroundColor
        ? backgroundColor
        : variant === 'outline' || variant === 'default'
          ? 'transparent'
          : '#F13A88',
    borderRadius: '50px',
    height: ({ size }) => (size === 'small' ? '32px' : '38px'),
    padding: '0 15px',
    textTransform: 'none',
    minWidth: ({ size }) => (size === 'small' ? '60px' : '100px'),

    color: ({ borderColor, textColor, variant }) =>
      textColor
        ? textColor
        : variant === 'outline' || variant === 'default'
          ? borderColor ? borderColor : '#1A2544'
          : 'white',
    border: ({ borderColor, borderWidth = 1, variant }) =>
      variant === 'outline' ? `${borderWidth}px solid ${borderColor || '#BFC2CB'}` : 'unset',
    fontWeight: ({fontWeight}) => fontWeight ? fontWeight : '500',

    '&:hover': {
      background: ({ backgroundColor, variant }) =>
        `${backgroundColor
          ? alpha(backgroundColor, 0.95)
          : variant === 'outline' ||  variant === 'default'
            ? 'transparent'
            : alpha('#F13A88', 0.95)} !important`,
      transition: '0.3s',
    },
  },
  label: {
    // fontSize: ({fontSize, size}) => fontSize ? fontSize : size === 'small' ? '13' : '14px',
    fontSize: ({fontSize}) => fontSize ? fontSize : '12px',
    fontFamily: 'Outfit',
    lineHeight: 1,
    textWrap: 'nowrap',
  },
  disabled: {
    opacity: 0.7,
    background: ({ backgroundColor, variant }) =>
      `${backgroundColor
        ? backgroundColor
        : variant === 'outline' ||  variant === 'default'
          ? 'transparent'
          : '#F13A88'} !important`,
    color: ({ borderColor, textColor, variant }) =>
      `${textColor
        ? textColor
        : variant === 'outline' || variant === 'default'
          ? borderColor ? borderColor : '#1A2544'
          : 'white'} !important`,       
  }
})((props) => <Button color="default" {...props} />);

const FPButton = (props) => {
  const {
    className,
    borderColor,
    backgroundColor,
    fontWeight,
    label,
    size = 'default',
    textColor,
    borderWidth,
    children,
    variant,
    ...rest
  } = props;

  return (
    <DefaultButton
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      textColor={textColor}
      fontWeight={fontWeight}
      borderWidth={borderWidth}
      variant={variant || 'contained'}
      disableElevation
      className={`fp-button ${className}`}
      size={size}
      {...rest}
    >
      {children ? children : label}
    </DefaultButton>
  );
};

export default FPButton;
