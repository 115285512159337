/* eslint-disable */
// * WE MANAGE DATE RANGE FILTER INTO COMMON FILTER COMPONENT
import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import CardWrapper from "../../../../../modules/dashboard/component/CardWrapper";
import { getQueryParams, setDateFormat, getParams, areObjectsEqualJSON } from "../../../../../libs/utils";
import "../fpfilter.css";
import ActionButton from "../ActionButton";
import SelectedItems from "../SelectedItems";
// import ItemSelectionList from "./ItemSelectionList";
import cookie from "react-cookies";
import queryString from "query-string";
import classNames from "classnames";
import ItemSelectionList from "../ItemSelectionList";

const ListingFPFilter = (props) => {
  const {
    data,
    className,
    history,
    actionRender,
    pageSizeCookie,
    ticketFilter,
    onApply,
    customResetFuc,
    applyBtnText,
    hideResetBtn = false,
    customOnApply,
    onChange,
    lightResetBtn,
  } = props;
  
  const [allFilterList, setAllFilterList] = useState(data || []);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [currentOpenFilterItem, setCurrentOpenFilterItem] = useState(null);

  const [showAll, setShowAll] = useState(false);
  const [initialValues, setInitialValues] = useState();

  // * set current open filter
  const handelFilterItem = (item) => {
    const isSameClicked = areObjectsEqualJSON(currentOpenFilterItem, item);

    isSameClicked ? setCurrentOpenFilterItem(null) : setCurrentOpenFilterItem(item);
  };

  const activeItemKey = currentOpenFilterItem ? currentOpenFilterItem.key : "";

  const dateType = getParams(location.search, "date_type");
  const startDate = getParams(location.search, "start_date");
  const endDate = getParams(location.search, "end_date");

  // * for update list when dateFilter and data update
  useEffect(() => {
    setAllFilterList(data);

    //* set Default value
    let initialFormValues = {};
    allFilterList.map((filterItem) => {
      const defaultList = filterItem.list && filterItem.list.filter((listItem) => listItem.isChecked);
      initialFormValues[filterItem.key] = defaultList;
      return filterItem;
    });

    //* SET default date Range value
    if (dateType === "other") {
      initialFormValues["date_type"] = [
        {
          display_name: `${setDateFormat(startDate)} to ${setDateFormat(endDate)}`,
          parentKey: "date_type",
          value: "other",
        },
      ];
    }
    setInitialValues(initialFormValues);
  }, [data]);

  const handelApply = (formValue) => {
    const oldParams = queryString.parse(window.location.search);
    const params = getQueryParams(formValue);
    // const formValueKeys = Object.keys(formValue);
    // if(formValueKeys.includes('gmb_location_type')){
    //   getGmbListingLoaction()
    // }else{
    const { pathname } = location;
    const queryObject = Object.assign(oldParams, queryString.parse(params));
    const newQueryString = queryString.stringify(queryObject);
    onApply?.({
      queryObject,
      queryString: newQueryString,
    });
    history.push({
      pathname,
      search: newQueryString,
    });
    setCurrentOpenFilterItem(null);
  };

  const onResetCookie = () => {
    let pageSize = cookie.load("pageSize") || {};
    pageSize[pageSizeCookie] = 10;
    cookie.save("pageSize", pageSize, { path: "/" });
  };

  // const handelReset = (formikConfig) => {
  //   setIsSelectAll(false);
  //   onResetCookie();
  //   const { pathname } = location;
  //   customResetFuc
  //     ? customResetFuc({ pathname, location, history })
  //     : history.push({
  //         pathname,
  //         search: "date_type=prior_30_day&page_size=10",
  //       });
  //   setCurrentOpenFilterItem(null);
  // };
  const handelReset = (formikConfig) => {
    setIsSelectAll(false);
    onResetCookie();
    const { pathname } = location;
    history.push({ pathname, search: "page_size=10" });
    setCurrentOpenFilterItem(null);
  };


  const isFirstRendered = useRef(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <CardWrapper showDivider={false} className={`fp-filter ${className ? className : ""}`}>
      <Formik
        initialValues={{
          ...initialValues,
          start_date: [],
          end_date: [],
        }}
        enableReinitialize={true}
        onSubmit={(formValue) => customOnApply?.(formValue) || handelApply(formValue)}
      >
        {(formikConfig) => {
          useEffect(() => {
            if (isFirstRendered.current) {
              onChange?.(formikConfig.values, formikConfig.setFieldValue);
            } else {
              isFirstRendered.current = true;
            }
          }, [formikConfig.values]);
          return (
            <Form>
              <React.Fragment>
                {/* <div
                  className={classNames(
                    "justify-content-between flex-row-reverse",
                    showAll ? "d-block" : "align-center"
                  )}
                  style={{ gap: "100px" }}
                >
                  <div
                    className={classNames(
                      "d-flex justify-content-end",
                      showAll ? "mb-1" : ""
                    )}
                  >
                    <ActionButton
                      applyBtnText={applyBtnText}
                      hideResetBtn={hideResetBtn}
                      onResetClick={() => handelReset(formikConfig)}
                      actionRender={actionRender}
                      lightResetBtn={!!lightResetBtn}
                    />
                  </div>
                  <ItemSelectionList
                    allFilterList={allFilterList}
                    formikConfig={formikConfig}
                    handelFilterItem={handelFilterItem}
                    activeItemKey={activeItemKey}
                    showAll={showAll}
                    toggleShowAll={toggleShowAll}
                  />
                </div> */}
                <ItemSelectionList
                  allFilterList={allFilterList}
                  formikConfig={formikConfig}
                  handelFilterItem={handelFilterItem}
                  activeItemKey={activeItemKey}
                  showAll={showAll}
                  toggleShowAll={toggleShowAll}
                />

                {/* // *FORM control */}
                <SelectedItems
                  allFilterList={allFilterList}
                  currentOpenFilterItem={currentOpenFilterItem}
                  formikConfig={formikConfig}
                  isSelectAll={isSelectAll}
                  setIsSelectAll={setIsSelectAll}
                  ticketFilter={ticketFilter}
                  applyBtnText={applyBtnText}
                  hideResetBtn={hideResetBtn}
                  onResetClick={() => handelReset(formikConfig)}
                  actionRender={actionRender}
                  lightResetBtn={!!lightResetBtn}
                />
              </React.Fragment>
            </Form>
          );
        }}
      </Formik>
    </CardWrapper>
  );
};

export default ListingFPFilter;
