import React from 'react';
import { snakeToCapital } from '../../../../libs/utils';
import FPRadio from '../FPRadio';
import FPChip from '../FPChip/FPChip';
import FPBadge from '../FPBadge/FPBadge';

const RadioWithTooltip = React.forwardRef(function MyComponent(
  props,
  ref
) {
  
  const { item, arrayHelpers, handleRadioChange,isNotEllipsisLabel,ticketFilter, ...rest  } = props;
  
  const getPriorityColor = (status) => {
    const statusColor = {
      low: "gray",
      medium: "#FDB022",
      high: "#EF6820",
      urgent: "#D92D20",
    };
    return statusColor[status];
  };
  const getPriorityBorderColor = (status) => {
    const statusColor = {
      low: "gray",
      medium: "#feb130",
      high: "#f16824",
      urgent: "#db2c1f",
    };
    return statusColor[status];
  };
  const getPriorityBackGroundColor = (status) => {
    const statusColor = {
      low: "#d1d1d1",
      medium: "#fdeed3",
      high: "#fbe4da",
      urgent: "#FEE4E2",
    };
    return statusColor[status];
  };

  return (
    <div {...rest} ref={ref}>
      <FPRadio
        value={item.value}
        label={item.parentKey === "priority" ? (
            <FPChip
              size="small"
              variant="outlined"
              style={{
                height: "22px",
                borderColor: getPriorityBorderColor(item.value),
                backgroundColor: getPriorityBackGroundColor(item.value),
              }}
              label={
                <div className="d-flex align-items-center justify-content-center">
                  <FPBadge color={getPriorityColor(item.value)} size="9" />
                  &nbsp;&nbsp;
                    {item.display_name}
                </div>
              }
            />
          ) : (
            snakeToCapital(item.display_name)
          )}
        onChange={() => handleRadioChange(item, arrayHelpers)}
        labelClassName={isNotEllipsisLabel === true ? '' : 'available-filter-item-label'}
      />
    </div>
  );
});
export default RadioWithTooltip;